import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { TaskService } from "../../../services/task.service";
import { ToastrService } from "../../../services/toastr.service";
import { ApplicationEnum } from "../../../enums/enums";
import { IReassignVisitDetails, IReassignVisitPayload } from "../../../interfaces/i-reassign-visit";

import VisitDetailsHomeHealth from "../../../pages-vue/CentralCalendar/PatientCalendar/components/VisitDetailsHomeHealth/VisitDetailsHomeHealth.vue";
import VisitDetailsHomeCare from "../../../pages-vue/CentralCalendar/PatientCalendar/components/VisitDetailsHomeCare/VisitDetailsHomeCare.vue";
import VisitDetailsHosPal from "../../../pages-vue/CentralCalendar/PatientCalendar/components/VisitDetailsHosPal/VisitDetailsHosPal.vue";

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        VisitDetailsHomeHealth,
        VisitDetailsHomeCare,
        VisitDetailsHosPal
    },

    data() {
        return {
            dialogReference: this.dialogRef as any,
            _taskService: null,
            _toastrService: null,
            options: {} as IReassignVisitDetails,
            applicationEnum: ApplicationEnum,
            employeeList: [],
            isLoading: false,
            isSaving: false
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this.options = Object.assign({}, this.dialogReference.data.visitDetails);
    },

    methods: {
        async reassignVisit(reassignDetails: IReassignVisitDetails) {
            if (!!reassignDetails) {
                try {
                    this.isSaving = true;
                    let reassignPayload: IReassignVisitPayload = {
                        AccountId: reassignDetails.AgencyId,
                        UserId: reassignDetails.EmpId,
                        Application: reassignDetails.Application,
                        EpisodeId: reassignDetails.EpisodeId,
                        PatientId: reassignDetails.PatientId,
                        TaskId: reassignDetails.Id,
                        TypeId: reassignDetails.TypeId,
                        ReasonId: reassignDetails.ReasonId
                    };
                    await this._taskService.reassignVisit(reassignPayload);
                    this._toastrService.success({
                        title: `Visit Reassigned`,
                        message: `Visit has been successfully Reassigned.`
                    });
                    // trigger callback
                    this.dialogReference.close(reassignDetails);
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Reassigning Failed`,
                        message: `Reassigning Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.isSaving = false;
                }
            }
        },
        cancel() {
            this.dialogReference.close();
        },

        ok() {
            this.dialogReference.close("success");
        }
    }
});
