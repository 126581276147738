import { autoinject, bindingMode, computedFrom } from "aurelia-framework";
import { bindable, containerless } from "aurelia-templating";
import { PhoneTypeEnum } from "../../../enums/enums";
import type { IPhoneNumber } from "../../../interfaces/i-phone-number";

@autoinject
@containerless
export class PhoneNumbersComponent {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public primaryPhone: IPhoneNumber = {
        type: 1,
        number: "",
        extension: "",
        isPrimary: true,
        country: "",
        afterHours: false
    };
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryPhone: IPhoneNumber = {
        type: 1,
        number: "",
        extension: "",
        isPrimary: false,
        country: "",
        afterHours: false
    };
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public tertiaryPhone: IPhoneNumber = {
        type: 1,
        number: "",
        extension: "",
        isPrimary: false,
        country: "",
        afterHours: false
    };
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public phoneNumberTypeOptionsConfig: PhoneTypeEnum[] = [PhoneTypeEnum.Mobile, PhoneTypeEnum.Facility];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public hasPhoneNumber: boolean[] = [true, false, false];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public vendorId: string;

    @computedFrom("secondaryPhone.number", "tertiaryPhone.number")
    public get canDeletePrimary(): boolean {
        let secondaryPhone = this.secondaryPhone.number;
        let tertiaryPhone = this.tertiaryPhone.number;
        return secondaryPhone.length > 0 || tertiaryPhone.length > 0;
    }

    public removePhoneNumber(type: number) {
        switch (type) {
            case 0:
                // move secondary to primary
                this.primaryPhone = Object.assign(this.primaryPhone, this.movePhone(1));
                if (this.primaryPhone.number.length == 0) {
                    // if secondary is empty, move tertiary
                    this.primaryPhone = Object.assign(this.primaryPhone, this.movePhone(2));
                    // reset tertiary
                    this.resetTertiaryPhoneNumber();
                } else {
                    // else secondary is already in primary, tertiary goes to secondary and reset tertiary
                    this.secondaryPhone = Object.assign(this.secondaryPhone, this.movePhone(2));
                    // reset tertiary
                    this.resetTertiaryPhoneNumber();
                }
                this.showHidePhoneNumbers();
                break;
            case 1:
                // move tertiary to secondary
                this.secondaryPhone = Object.assign(this.secondaryPhone, this.movePhone(2));
                // reset tertiary
                this.resetTertiaryPhoneNumber();
                this.showHidePhoneNumbers();
                break;
            case 2:
                // reset tertiary
                this.resetTertiaryPhoneNumber();
                this.setHasPhoneNumber(2, false);
                break;
        }
    }

    public movePhone(type: number) {
        switch (type) {
            case 0:
                return {
                    number: this.primaryPhone.number,
                    extension: this.primaryPhone.extension,
                    type: this.primaryPhone.type,
                    country: this.primaryPhone.country,
                    afterHours: this.primaryPhone.afterHours
                };
            case 1:
                return {
                    number: this.secondaryPhone.number,
                    extension: this.secondaryPhone.extension,
                    type: this.secondaryPhone.type,
                    country: this.secondaryPhone.country,
                    afterHours: this.secondaryPhone.afterHours
                };
            case 2:
                return {
                    number: this.tertiaryPhone.number,
                    extension: this.tertiaryPhone.extension,
                    type: this.tertiaryPhone.type,
                    country: this.tertiaryPhone.country,
                    afterHours: this.tertiaryPhone.afterHours
                };
            default:
                return null;
        }
    }

    public showHidePhoneNumbers() {
        this.setHasPhoneNumber(1, this.secondaryPhone.number.length > 0);
        this.setHasPhoneNumber(2, this.tertiaryPhone.number.length > 0);
    }

    public setHasPhoneNumber(type: number, value: boolean = true) {
        this.hasPhoneNumber = this.hasPhoneNumber
            .splice(0, type)
            .concat(value)
            .concat(this.hasPhoneNumber.splice(type + 1));
    }

    private resetTertiaryPhoneNumber() {
        this.tertiaryPhone = Object.assign(this.tertiaryPhone, {
            type: 1,
            number: "",
            extension: "",
            isPrimary: false,
            country: "",
            afterHours: false
        });
    }
}
