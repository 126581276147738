import { defineComponent, PropType } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import { ApplicationEnum, ConflictTypeEnum } from "../../../../enums/enums";
import type { IGetAllUsersResponse } from "../../../vue-interfaces/i-user";
import {
    IEmployeeDetails,
    ITrackingReasonDetails,
    ITrackingTypeDetails
} from "../../../vue-interfaces/i-homecare-reassign";
import type { ITypeaheadOptions } from "../../../vue-interfaces/i-typeahead";
import type { IEmployeeBulkReassignVisitPayload } from "../../../vue-interfaces/i-bulk-reassign-visit";
import { BulkReassign } from "../../../vue-models/bulk-reassign";
import { UserService } from "../../../../services/user-service";
import { TaskService } from "../../../../services/task.service";
import { ToastrService } from "../../../../services/toastr.service";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    props: {
        application: { type: Number },
        agencyId: { type: String },
        userId: { type: String },
        branchId: { type: String },
        dateFrom: { type: String },
        dateTo: { type: String },
        selectedTaskId: { type: Array as PropType<string[]> },
        closeReassignForm: { type: Function },
        pageReset: { type: Function }
    },
    components: {
        FormSection,
        TypeaheadInput
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            visitDetails: {
                EmployeeTo: { required },
                ReasonId: { required: requiredIf(() => this.isApplicationHomeCare) },
                TypeId: { required: requiredIf(() => this.isApplicationHomeCare) }
            }
        };
    },
    data() {
        return {
            isLoading: false,
            isSaving: false,
            isApplicationHomeCare: false,
            isEmployeeListLoading: false,
            showAllByDefault: true,
            employeeLists: [] as IEmployeeDetails[],
            employeeList: [] as IGetAllUsersResponse[],
            cpyTrackingReason: [] as ITrackingReasonDetails[],
            trackingReasons: [] as ITrackingReasonDetails[],
            trackingTypes: [] as ITrackingTypeDetails[],
            selectedConflictTypes: [ConflictTypeEnum.NoConflict] as number[],
            selectedEmployeeTo: null as ITypeaheadOptions,
            visitDetails: null as BulkReassign,
            _userService: null,
            _taskService: null,
            _toastrService: null
        };
    },
    watch: {
        selectedEmployeeTo(newValue, oldValue) {
            if (newValue) {
                this.visitDetails.EmployeeTo = newValue.value;
            }
        },
        branchId() {
            if (this.application == ApplicationEnum.HomeCare) {
                this.getHomeCareEmployee();
            } else {
                this.getEmployee("");
            }
        }
    },
    created() {
        this._userService = Container.instance.get(UserService);
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },
    async mounted() {
        this.visitDetails = new BulkReassign();
        this.visitDetails.ReasonId = null;
        this.visitDetails.TypeId = null;

        if (this.application == ApplicationEnum.HomeCare) {
            this.isApplicationHomeCare = true;
        }

        if (this.isApplicationHomeCare) {
            this.trackingReasons = await this._taskService.getTrackingReasons({
                AgencyId: this.agencyId,
                Application: this.application,
                CategoryId: 1
            });

            this.trackingTypes = await this._taskService.getTrackingTypes({
                AgencyId: this.agencyId,
                Application: this.application,
                CategoryId: 1
            });
        }
        if (this.application == ApplicationEnum.HomeCare) {
            this.getHomeCareEmployee();
        }
    },
    methods: {
        async getEmployee(term: string) {
            const employees = await this._userService.getAllUsers({
                agencyId: this.agencyId,
                application: this.application,
                userId: this.userId,
                branchIds: [],
                term: term
            });
            this.employeeList = employees.filter((employee: IGetAllUsersResponse) => {
                return employee.id != this.userId;
            });
        },
        async getHomeCareEmployee() {
            try {
                this.isEmployeeListLoading = true;
                const employees = await this._taskService.homecareEmployeePreference({
                    AgencyId: this.agencyId,
                    PatientId: this.userId,
                    LocationId: this.branchId,
                    SchedulerConflictTypes: this.selectedConflictTypes,
                    Application: ApplicationEnum.HomeCare,
                    ignoreConflictTypes: true
                });

                this.employeeLists = employees.filter((employee: IEmployeeDetails) => {
                    return employee.Id != this.userId;
                });
            } catch (e) {
                throw e;
            } finally {
                this.isEmployeeListLoading = false;
            }
        },
        filterEmployee(filter: string) {
            if (filter) {
                if (this.application == ApplicationEnum.HomeCare) {
                    const filterEmployeeList = this.employeeLists.filter((employee) => {
                        const fullName = `${employee.FirstName} ${employee.LastName}`;
                        return fullName.toLowerCase().includes(filter.toLowerCase());
                    });

                    return filterEmployeeList?.map((item) => {
                        return {
                            name: `${item.FirstName} ${item.LastName} ${item.Credentials}`,
                            value: item.Id
                        };
                    });
                } else {
                    this.getEmployee(filter);
                    const filterEmployeeList = this.employeeList.filter((employee) => {
                        const fullName = `${employee.firstName} ${employee.lastName}`;
                        return fullName.toLowerCase().includes(filter.toLowerCase());
                    });

                    return filterEmployeeList?.map((item) => {
                        return {
                            name: `${item.firstName} ${item.lastName} ${item.credential}`,
                            value: item.id
                        };
                    });
                }
            } else {
                if (this.application == ApplicationEnum.HomeCare) {
                    return this.employeeLists?.map((item) => {
                        return {
                            name: `${item.FirstName} ${item.LastName} ${item.Credentials}`,
                            value: item.Id
                        };
                    });
                } else {
                    this.getEmployee("");
                    return this.employeeList?.map((item) => {
                        return {
                            name: `${item.firstName} ${item.lastName} ${item.credential}`,
                            value: item.id
                        };
                    });
                }
            }
        },
        selectedType() {
            if (!!this.visitDetails.TypeId) {
                this.cpyTrackingReason = this.trackingReasons.filter((reasonType) => {
                    return reasonType.TypeId == this.visitDetails.TypeId;
                });
            } else {
                this.visitDetails.ReasonId = null;
                this.cpyTrackingReason = [];
            }
        },
        selectedEmployeeToChanged(data: ITypeaheadOptions) {
            this.selectedEmployeeTo = data;
        },
        async reassignVisit() {
            if (!!this.visitDetails) {
                try {
                    this.isSaving = true;
                    const validated = await this.v$.$validate();
                    if (validated) {
                        const reassignPayload: IEmployeeBulkReassignVisitPayload = {
                            AgencyId: this.agencyId,
                            Application: this.application,
                            EmployeeFrom: this.userId,
                            EmployeeTo: this.visitDetails.EmployeeTo,
                            StartDate: this.dateFrom,
                            EndDate: this.dateTo,
                            TaskIds: this.selectedTaskId,
                            ReasonId: this.visitDetails.ReasonId,
                            TypeId: this.visitDetails.TypeId
                        };
                        await this._taskService.employeeBulkReassign(reassignPayload);
                        this._toastrService.success({
                            title: `Visit Reassigned`,
                            message: `Visit has been successfully Reassigned.`
                        });
                        this.cancel();
                        this.pageReset();
                    } else {
                        this._toastrService.error({
                            title: `Validation Failed`,
                            message: `Fill all required fields.`
                        });
                    }
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Reassigning Failed`,
                        message: `Reassigning Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.isSaving = false;
                }
            }
        },
        cancel() {
            this.closeReassignForm();
        }
    }
});
