import { PropType, computed, defineComponent } from "vue";

import { formatDisplayUsers, formatUserInitials } from "./../../../../../common/vue-helpers/modifiers/format-message";
import type {
    IConversationNavOptions,
    ISharedAssetDialogOptions
} from "../../../../../resources-vue/vue-interfaces/messaging-service/i-message";

import ConversationSharedFiles from "../ConversationSharedFiles/ConversationSharedFiles.vue";
import TablePrompt from "../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import AudioVideoConferencing from "../AudioVideoConferencing/AudioVideoConferencing.vue";

export default defineComponent({
    components: {
        TablePrompt,
        AudioVideoConferencing
    },
    data() {
        return {
            formatUserInitials,
            formatDisplayUsers,
            dialogRef: null,
            hiddenList: "" as string,
            visibleList: "" as string,
            hiddenCount: 0 as number,
            characterLimit: 100 as number,
            userOs: "" as string,
            roomNameTemplate: "" as string
        };
    },

    props: {
        itemCount: {
            type: Number,
            default: 0
        },
        isViewDeletedMessagesRequested: {
            type: Boolean,
            default: false
        },
        isBulkDeleteRequested: {
            type: Boolean,
            default: false
        },
        isBulkDeleteInProgress: {
            type: Boolean,
            default: false
        },
        selectedMessageCount: {
            type: Number,
            default: 0
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        selectedConversation: {
            default: null,
            type: Object as PropType<IConversationNavOptions>
        },
        deleteMessages: { type: Function, default: null }
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    watch: {
        selectedConversation: {
            handler() {
                this.selectedConversationChanged();
            },
            deep: true
        }
    },

    emits: ["isBulkDeleteRequested", "isViewDeletedMessagesRequested"],

    methods: {
        selectedConversationChanged() {
            this.getValues();
        },

        getValues() {
            if (!this.selectedConversation?.users) {
                return;
            }
            let userList = this.selectedConversation.users;
            if (userList?.length > 1) {
                let visibleFirstNames: string[] = [];
                let visibleListLength: number = 0;
                // Get visible names
                userList.forEach((user) => {
                    // Add 2 for space and commas
                    visibleListLength += user.firstName.length + 2;
                    if (visibleListLength > this.characterLimit) {
                        return;
                    }
                    visibleFirstNames.push(user.firstName);
                });
                this.visibleList = visibleFirstNames.join(", ");
                // Get hidden names count
                this.hiddenCount = userList.length - visibleFirstNames.length;
                // Get hidden names
                this.hiddenList = userList
                    .slice(visibleFirstNames.length)
                    .map((user) => user.firstName)
                    .join(", ");
            } else {
                this.visibleList = userList[0].firstName + (userList[0].lastName ? ` ${userList[0].lastName}` : "");
                this.hiddenCount = 0;
                this.hiddenList = "";
            }
            if (this.selectedConversation.title) {
                this.roomNameTemplate = this.selectedConversation.title.split(" ").join("-");
            } else {
                this.roomNameTemplate = this.visibleList.split(", ").join(" - ");
            }
        },

        async viewSharedFiles() {
            this.userOs = window.sessionStorage.getItem("userOs");
            let sharedFilesDialogOptions: ISharedAssetDialogOptions = {
                title: "Shared Files",
                conversation: this.selectedConversation
            };
            this.dialogRef = this.$dialog.open(ConversationSharedFiles, {
                props: {
                    modal: true,
                    closeOnEscape: false,
                    contentClass: this.userOs == "Windows" ? "custom-scrollbar" : "",
                    showHeader: false
                },
                data: {
                    sharedFilesDialogOptions
                }
            });
        },

        openBulkDelete() {
            this.$emit("isBulkDeleteRequested", true);
        },

        closeBulkDelete() {
            this.$emit("isBulkDeleteRequested", false);
        },

        viewDeletedMessages() {
            this.$emit("isViewDeletedMessagesRequested", true);
        },

        closeDeletedMessages() {
            this.$emit("isViewDeletedMessagesRequested", false);
        }
    }
});
