import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "central-background" }
const _hoisted_2 = { class: "content-layout card px-0 list-section mt-3 position-relative" }
const _hoisted_3 = { class: "w-100 h-100 py-2" }
const _hoisted_4 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelTwo = _resolveComponent("LevelTwo")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_LevelTwo, { router: _ctx.router }, null, 8 /* PROPS */, ["router"]),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("section", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ])
    ])
  ]))
}