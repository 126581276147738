import { computed, defineComponent, PropType } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";
import moment, { Moment } from "moment";
import draggable from "vuedraggable";
import "bootstrap-datepicker";

import config from "../../../../common/config";
import { NavigationService } from "../../../../services/navigation.service";
import { AnalyticsService } from "../../../../services/analytics.service";
import { TaskService } from "../../../../services/task.service";
import type { ILogOptions } from "../../../../resources-vue/vue-interfaces/i-analytics";
import type { INavigateRequestParams, INavigateResponse } from "../../../../resources-vue/vue-interfaces/i-navigation";
import type { ITask, ITaskReorderRequestBody } from "../../../../resources-vue/vue-interfaces/i-task";
import { formatDate, formatPhone } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { getApplicationId } from "../../../../common/vue-helpers/modifiers/application-modifiers";
import { ToastrService } from "../../../../services/toastr.service";
import { useLoadingIndicator } from "../../../Common/LoadingIndicator/store/useLoadingIndicator";
import type { ILocation } from "./../../../../resources-vue/vue-interfaces/i-location";
import eventBus from "../../../../utilities/eventBus";
import { GeoLocationEvents } from "../../../../services-vue/geolocation.service";
import { GoogleMapsService } from "./../../../../services/google-maps.service";

import CardTemplate from "../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";
import ProductPill from "../../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";
import Addendum from "../../../../resources-vue/vue-dialogs/AddAddendum/AddAddendum.vue";
import TasksMap from "../TasksMap/TasksMap.vue";

export default defineComponent({
    components: {
        CardTemplate,
        ProductPill,
        TasksMap,
        draggable
    },

    props: {
        myTasksDateProp: {
            type: Object as PropType<Moment>,
            default: moment()
        },
        taskListProp: {
            type: Array as PropType<ITask[]>,
            default: []
        },
        selectedTaskProp: {
            type: Object as PropType<ITask>,
            default: {}
        },
        listUpdate: {
            type: Number
        }
    },

    data() {
        return {
            myTasksDate: this.myTasksDateProp,
            taskList: this.taskListProp,
            selectedTask: this.selectedTaskProp,
            _permissionManager: null,
            _navigateService: null,
            _analyticsService: null,
            _googleMapsService: null,
            _toastrService: null,
            _taskService: null,
            _dpDateFormat: "MM-DD-YYYY",
            _dpStartDate: moment().subtract(60, "days").format("MM-DD-YYYY") as string,
            _dpEndDate: moment().add(14, "days").format("MM-DD-YYYY") as string,
            _logCategory: "Visit-Schedule",
            _visits: "visits",
            _noVisits: "noVisits",
            isReopenAllowed: false,
            taskCountByDateList: [] as Array<any>,
            noVisitDatePicker: null,
            datepicker: null,
            container: null,
            decrementDisabled: false,
            incrementDisabled: false,
            showCalendar: false,
            isLoading: true,
            notesLimit: 60,
            hasError: false,
            taskListString: "",
            tasksContainerRef: null,
            scroll: null as any,
            dialogRef: null,
            _locationData: [] as ILocation,
            zipNotEntered: false,
            tooltip: `<p>This section displays all visits for the chosen date.</p>
                            <ul class="list-style-disc">
                                <li>Visit List
                                    <ul class="list-style-circle">
                                        <li>Click 'START' to open and document the visit note.</li>
                                        <li>Click 'Patient Chart' to view more details.</li>
                                    </ul>
                                </li>
                                <li> Visit Map
                                    <ul class="list-style-circle">
                                    <li><i class="fas fa-map-marker-alt user-location"></i> - Current Location</li>
                                    <li><i class="fas fa-map-marker-alt task-location"></i> - Visits' Location</li>
                                    <li><i class="fas fa-map-marker-alt selected-task"></i> - Selected Visit</li>
                                    <li>Click on a marker to view visit details.</li>
                                    <li>Once selected, click on 'Get Directions' to view routes to selected location.</li>
                                    </ul>
                                </li>
                            </ul>`,
            userOs: "",
            formatDate,
            formatPhone,
            getApplicationId,
            dragging: false
        };
    },

    computed: {
        isPresent: function (): boolean {
            let today = moment().format("YYYYMMDD");
            let tasksDate = moment(this.myTasksDate).format("YYYYMMDD");
            return today === tasksDate;
        },

        fwdNavTooltip: function (): string {
            let nextDate = moment(this.myTasksDate).add(1, "days").format("MMMM DD, YYYY");
            return `Click to view visits on ${nextDate}.`;
        },

        bwdNavTooltip: function (): string {
            let prevDate = moment(this.myTasksDate).subtract(1, "days").format("MMMM DD, YYYY");
            return `Click to view visits on ${prevDate}.`;
        },
        routeVisitTooltip(): string {
            return `<p>Route Visits:</p>
                    <ul>
                        <li>Drag and drop visits in the desired completion order</li>
                        <li>Select Route Visits to map all of today's visits in the selected order</li>
                    </ul>`;
        }
    },

    async created() {
        this._taskService = Container.instance.get(TaskService);
        this._navigateService = Container.instance.get(NavigationService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._toastrService = Container.instance.get(ToastrService);
        this._googleMapsService = Container.instance.get(GoogleMapsService);
        this.initSubscriptions();
        this.userOs = window.sessionStorage.getItem("userOs");
    },

    async mounted() {
        await this.taskDatePicker(this.$refs.noVisitDatePicker as HTMLElement, this._noVisits);
        await this.taskDatePicker(this.$refs.noVisitDatePicker as HTMLElement, this._visits);
        await this.getTasks();
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),

        async myTasksDateChanged(newValue: Moment) {
            if (newValue) {
                this.taskList = [];
                await this.getTasks();
                this.$emit("updateProps", this.taskList, this.myTasksDate, this.selectedTask);
                const newValueDate = newValue.format("l");
                $(this.$refs.noVisitDatePicker).datepicker("update", newValueDate);
                await this.datePickerUpdate("", this._noVisits);
            }
        },

        checkPermission(status: string, application: ApplicationEnum) {
            if (status == "Reopen") {
                if (application == ApplicationEnum.AgencyCore) {
                    const isReopenAllowed = this._permissionManager.checkPermissionByProduct(
                        ParentPermissionEnum.clinician,
                        PermissionActionEnum.canReopen,
                        ApplicationEnum.AgencyCore
                    );
                    return isReopenAllowed;
                } else if (application == ApplicationEnum.HomeCare) {
                    const isReopenAllowed = this._permissionManager.checkPermissionByProduct(
                        ParentPermissionEnum.clinician,
                        PermissionActionEnum.canReopen,
                        ApplicationEnum.HomeCare
                    );
                    return isReopenAllowed;
                } else if (
                    application == ApplicationEnum.AxxessHospiceFE ||
                    application == ApplicationEnum.AxxessPalliative
                ) {
                    return false;
                }
            }
            return true;
        },

        selectedTaskChanged(task: ITask) {
            Object.assign(this.selectedTask, task);
        },

        // Date Picker for the Visits and NoVisits Section
        // Param (datePicker): datepicker or noVisitDatePicker
        // Param (isVisitsExist): visits (dropdown datepicker) or noVisits(inline datepicker)
        async taskDatePicker(datepicker: HTMLElement, isVisitsExist: string) {
            let myTasksDate: Moment = this.myTasksDate;

            $(datepicker)
                .datepicker({
                    keyboardNavigation: false,
                    startDate: this._dpStartDate, // 60 days before today
                    endDate: this._dpEndDate, // 2 weeks from today
                    autoclose: true,
                    todayBtn: "linked",
                    maxViewMode: 0
                })
                .datepicker("setDate", moment(myTasksDate).format(this._dpDateFormat))
                .off("changeDate")
                .on("changeDate", (e: DatepickerEventObject) => {
                    this.changeDate(e.date);
                    e.preventDefault();
                    e.stopPropagation();
                })
                .on("changeMonth", async (e: DatepickerEventObject) => {
                    await this.datePickerUpdate(e.date, isVisitsExist);
                });

            if (isVisitsExist === this._noVisits) {
                await this.datePickerUpdate("", isVisitsExist, true);
                let $dayHeader: JQuery<HTMLElement> = $(".datepicker-inline .datepicker-days thead>tr:nth-child(2)");
                $dayHeader.find("th:nth-child(3)").attr("colspan", "3");
            }
        },

        async getTasks() {
            try {
                const taskDate: string = moment(this.myTasksDate).format("MM/DD/YYYY");
                this.hasError = false;
                this.taskList = [];
                this.isLoading = true;
                this.taskList = await this._taskService.findAll({
                    date: taskDate
                });
                this.indexOrder(this.taskList);
            } catch (e) {
                console.error(e);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        async indexOrder(taskList: ITask[]) {
            const tasks = taskList?.map((item: any, index: number) => {
                item.key = (index + 1).toString();
                return item;
            });
            return tasks;
        },

        changeDate(selectedDate: Date | string) {
            this.myTasksDate = moment(selectedDate);
            this.taskDateNavigation();
            this.myTasksDateChanged(this.myTasksDate);

            this._analyticsService.logEvent({
                category: this._logCategory,
                action: "DatePicker-Date-Changed"
            });
        },

        // Update the datepicker
        // Param(monthSelected): month selected in the datepicker
        // Param(isVisitsExist): visits(dropdowndatepicker) or noVisits(inlinedatepicker)
        async datePickerUpdate(monthSelected: Date | string, isVisitsExist: string, getUpdatedList?: boolean) {
            let myTasksDate = moment(this.myTasksDate).format(this._dpDateFormat);
            let month: Date | string = monthSelected || myTasksDate;
            // If the user has visits, then it's the datepicker-dropdown on the header
            let datePickerOrient: string =
                isVisitsExist === this._visits ? ".datepicker-orient-bottom" : ".datepicker-inline";
            let monthFormat = moment(month, "MM-DD-YYYY").format("l");
            let dateRangeOptions = this.dateRange(monthFormat);
            try {
                if (getUpdatedList) {
                    this.taskCountByDateList = await this._taskService.getUpdatedTaskDates(dateRangeOptions);
                } else {
                    this.taskCountByDateList = await this._taskService.getTaskByDates(dateRangeOptions);
                }

                if (this.taskCountByDateList?.length > 0) {
                    this.markTaskCountsInDatePicker(this.taskCountByDateList, datePickerOrient);
                }
            } catch (e) {
                console.error(e);
            }
        },

        dateRange(month: string): { startDate: string; endDate: string } {
            // let date = new Date(month);
            let firstDay = moment(month, "MM-DD-YYYY").startOf("month").format("L");
            let lastDay = moment(month, "MM-DD-YYYY").endOf("month").format("L");

            return {
                startDate: firstDay,
                endDate: lastDay
            };
        },

        markTaskCountsInDatePicker(tasksCountByDate: Array<any>, datePickerOrient: string): void {
            $('[role="tooltip"]').remove();
            let self = this;
            let activeDates: Array<string> = [];
            let count: Array<number> = [];

            if (tasksCountByDate) {
                tasksCountByDate.forEach((task) => {
                    let dateFormat = moment(task.Date, "MM/DD/YYYY");
                    activeDates.push(dateFormat.format("D"));
                    count.push(task.Count);
                });
            }

            $(datePickerOrient + " .datepicker-days tbody tr td.day").each(function () {
                let dateInCalendarElement = $(this);
                let selectedDateText = dateInCalendarElement.text();
                if ($.inArray(selectedDateText, activeDates) > -1) {
                    if (!dateInCalendarElement.hasClass("old") && !dateInCalendarElement.hasClass("new")) {
                        let dateIndex = activeDates.indexOf(selectedDateText);
                        let visitsCount = count[dateIndex];
                        let visitSingular = visitsCount > 1 ? "visits" : "visit";
                        dateInCalendarElement
                            .addClass("visits-exist")
                            .wrapInner(`<span class="visit-count visit-date"></span>`)
                            .append(`<span class="visit-count-circle"></span>`)
                            .wrapInner(
                                `<span class="tooltip-wrapper" data-toggle="tooltip"
                                        title="You have ${visitsCount} ${visitSingular}."
                                        data-placement="right"></span>`
                            )
                            .on("click", function (e) {
                                let date = $(this).find(".visit-date").text(); // Example: 1
                                let monthAndYear = $(datePickerOrient + " .datepicker-switch").html(); // Example: June 2017
                                let dateSelected = date + " " + monthAndYear; // 1 June 2017
                                self.changeDate(dateSelected);
                                if (datePickerOrient === ".datepicker-orient-bottom") {
                                    $(datePickerOrient).hide();
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            });
                    }
                }
            });

            $('[data-toggle="tooltip"]').tooltip();
        },

        isVisitCompleted(status: string) {
            const isCompleted: number = status.toLowerCase().indexOf("completed");
            if (isCompleted >= 0) {
                return true;
            }
            return false;
        },

        checkStatusName(statusName: string, status: string): boolean {
            if (status === "410" && !this.isFuture) {
                return false;
            } else if (statusName) {
                return true;
            }
            return false;
        },

        toggleVisitCard(task: ITask): void {
            let action: string = "Visit-Card-Close";

            this.hideOtherTasks(task);
            task.showDetails = !task.showDetails;

            if (task.showDetails) {
                action = "Visit-Card-Open";
            }

            this._analyticsService.logEvent({
                category: this._logCategory,
                action: action
            });

            if (task.showDetails) {
                this.selectedTask = task;
            } else {
                this.selectedTask = null;
            }
        },

        hideOtherTasks(task: ITask): void {
            this.taskList?.forEach((item: any) => {
                if (task.Id !== item.Id) {
                    item.showDetails = false;
                }
            });
        },

        decrementTaskDate(): void {
            if (!this.isLoading) {
                this.myTasksDate = moment(this.myTasksDate).subtract(1, "days");
                this._analyticsService.logEvent({
                    category: this._logCategory,
                    action: "Date-Backward-navigation"
                });
            }

            this.taskDateNavigation();
            this.myTasksDateChanged(this.myTasksDate);
        },

        incrementTaskDate(): void {
            if (!this.isLoading) {
                this.myTasksDate = moment(this.myTasksDate).add(1, "days");
                this._analyticsService.logEvent({
                    category: this._logCategory,
                    action: "Date-Forward-navigation"
                });
            }

            this.taskDateNavigation();
            this.myTasksDateChanged(this.myTasksDate);
        },

        taskDateNavigation(): void {
            let taskDate = moment(this.myTasksDate, this._dpDateFormat);
            let incrementedDate = moment(this._dpEndDate, this._dpDateFormat);
            let decrementDate = moment(this._dpStartDate, this._dpDateFormat);

            if (taskDate >= incrementedDate) {
                this.incrementDisabled = true;
            } else {
                this.incrementDisabled = false;
            }

            if (taskDate <= decrementDate) {
                this.decrementDisabled = true;
            } else {
                this.decrementDisabled = false;
            }
        },

        async startTask(task: ITask): Promise<void> {
            try {
                let analyticsOptions: ILogOptions = {
                    category: this._logCategory,
                    action: "Start-task"
                };
                let navParams: INavigateRequestParams = {
                    applicationId: task.Application,
                    agencyId: task.AgencyId,
                    userId: task.UserId,
                    isClinician: true,
                    patientId: task.PatientId,
                    episodeId: task.EpisodeId,
                    taskId: task.Id,
                    locationId: task.LocationId,
                    status: task.PatientStatus ? Number(task.PatientStatus) : null,
                    payerId: task.PayerId
                };
                this.showLoading(true);
                let response: INavigateResponse = await this._navigateService.navigateToTask(navParams);
                this._navigateService.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        },

        async viewPatient(task: ITask): Promise<void> {
            let analyticsOptions: ILogOptions = {
                category: this._logCategory,
                action: "View-Patient-Chart"
            };
            let navParams: INavigateRequestParams = {
                applicationId: task.Application,
                agencyId: task.AgencyId,
                userId: task.UserId,
                isClinician: true,
                patientId: task.PatientId
            };

            try {
                this.showLoading(true);
                let response: INavigateResponse = await this._navigateService.navigateToPatient(navParams);
                this._navigateService.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        },

        formatVisitTime(startTime: Date, endTime: Date) {
            if (startTime && endTime && !moment(startTime).isSame(endTime)) {
                if (moment(startTime).isBefore(endTime, "day")) {
                    if (moment(startTime).isSame(this.myTasksDate, "day")) {
                        return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format(
                            "MM/DD/YYYY hh:mm A"
                        )}`;
                    } else
                        return `${moment(startTime).format("MM/DD/YYYY hh:mm A")} - ${moment(endTime).format(
                            " hh:mm A"
                        )}`;
                } else {
                    return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("hh:mm A")}`;
                }
            } else return "";
        },

        toggleCalendar(): void {
            this.showCalendar = !this.showCalendar;
        },

        async orderChanged(changedOrder: ITask[]) {
            const response = this.indexOrder(changedOrder);
            this.$emit("updateTaskList", response);

            try {
                const newOrder: ITaskReorderRequestBody[] = changedOrder.map((item: ITask, index: number) => ({
                    agencyId: item.AgencyId,
                    application: item.Application,
                    taskId: item.Id,
                    order: index
                }));
                await this._taskService.changeTaskOrder(newOrder);
            } catch (err) {
                console.error(err);
                this._toastrService.error({
                    title: `Reordering Failed`,
                    message: `There was a problem while reordering your visits. Please try again.`
                });
                await this.getTasks();
            } finally {
                if (this.isLoading) this.isLoading = false;
            }
        },

        isFutureDocumentationPreventedHC(task: ITask): boolean {
            if (this.isFuture()) {
                if (task.Application === ApplicationEnum.HomeCare) {
                    return task.IsFutureDocumentationPrevented ? false : true;
                }
                return true;
            } else {
                return true;
            }
        },

        isHomeCare(task: ITask) {
            if (task.Application == ApplicationEnum.HomeCare) {
                return true;
            }
            return false;
        },

        isFuture(): boolean {
            return moment(this.myTasksDate).isAfter(moment());
        },
        async startHosPalTask(task: ITask) {
            if (task.StatusName == "Completed") {
                const data = {
                    taskDetails: JSON.parse(JSON.stringify(task))
                };

                this.dialogRef = this.$dialog.open(Addendum, {
                    props: {
                        modal: true,
                        showHeader: false
                    },
                    data: {
                        data
                    },
                    onClose: (response: any) => {
                        if (response.data === "success") {
                            if (task.Application === ApplicationEnum.AxxessHospiceFE) {
                                window.open(`${config.hospiceUrl}/note/${task.Id}`);
                            } else {
                                window.open(`${config.palliativeUrl}/note/${task.Id}`);
                            }
                        }
                    }
                });
            } else if (task.StatusName == "Pending Addendum") {
                if (task.Application === ApplicationEnum.AxxessHospiceFE) {
                    window.open(`${config.hospiceUrl}/note/${task.Id}`);
                } else {
                    window.open(`${config.palliativeUrl}/note/${task.Id}`);
                }
            } else {
                try {
                    const analyticsOptions: ILogOptions = {
                        category: this._logCategory,
                        action: "Start-task"
                    };
                    const navParams: INavigateRequestParams = {
                        applicationId: task.Application,
                        agencyId: task.AgencyId,
                        userId: task.UserId,
                        isClinician: true,
                        patientId: task.PatientId,
                        episodeId: task.EpisodeId,
                        taskId: task.Id,
                        locationId: task.LocationId,
                        status: task.PatientStatus ? Number(task.PatientStatus) : null,
                        payerId: task.PayerId
                    };
                    this.showLoading(true);
                    const response: INavigateResponse = await this._navigateService.navigateToTask(navParams);
                    this._navigateService.redirect(response, analyticsOptions);
                } catch (e) {
                    console.error(e);
                    this.showLoading(false);
                }
            }
        },
        isHosOrPal(application: ApplicationEnum) {
            if (application == ApplicationEnum.AxxessHospiceFE || application == ApplicationEnum.AxxessPalliative) {
                return true;
            }
            return false;
        },

        routeVisits() {
            const arrangedRoutes: string[] = [];
            const selfCords: string = `'${this._locationData.lat},${this._locationData.lon}'`;
            for (let task of this.taskList) {
                let address: string = "";
                if (task.PatientAddress.AddressLatitude && task.PatientAddress.AddressLongitude) {
                    address = `'${task.PatientAddress.AddressLatitude},${task.PatientAddress.AddressLongitude}'`;
                } else {
                    address = task.fullAddress;
                }
                if (!arrangedRoutes.includes(address)) {
                    arrangedRoutes.push(address);
                }
            }
            window.open(`https://www.google.com/maps/dir/${selfCords}/${arrangedRoutes.join("/")}`, "_blank");
        },

        initSubscriptions() {
            eventBus.on(GeoLocationEvents.Retrieved, async (data: ILocation) => {
                Object.assign(this._locationData, data);
                await this._init();
            });
        },

        async _init(): Promise<void> {
            await this._googleMapsService.loadMapsApi();
        }
    }
});
