import { autoinject } from "aurelia-framework";
import type { IBranchResponse } from "../interfaces/i-branch";
import { FetchClient } from "../common/fetch-client";
import { ApplicationEnum } from "../enums/enums";

@autoinject()
export class BranchesService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/meta/branches";
    private _branches: IBranchResponse[] = [];
    private branchesDetailsPromise: Promise<IBranchResponse[]>;

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getAllBranches() {
        if (this._branches.length === 0) {
            this._branches = await this.getBranchDetails();
        }
        return this._branches;
    }

    public async getBranchDetails(query?: { applicationId: ApplicationEnum }) {
        this.branchesDetailsPromise = this._httpClient
            .build(`${this._baseUrl}`)
            .useQueryString(query)
            .fetch<IBranchResponse[]>();
        return await this.branchesDetailsPromise;
    }
}
