import { ApplicationEnum } from "../enums/enums";
import { IPatientTask } from "../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import { ValidationRules } from "aurelia-validation";

export class PatientTask implements IPatientTask {
    public taskId: string;
    public patientId: string;
    public userId: string;
    public metaId: string;
    public startDates: string[] = [];
    public endDates: string[] = [];
    public comments: string;
    public onCall: boolean;
    public prn: boolean;
    public patientPayerId: string;
    public agencyId: string;
    public application: ApplicationEnum;

    public initValidation() {
        ValidationRules.ensure((x: PatientTask) => x.startDates)
            .required()
            .withMessage("Date is required")
            .ensure((x: PatientTask) => x.taskId)
            .required()
            .withMessage("Task is required.")
            .ensure((x: PatientTask) => x.patientPayerId)
            .required()
            .withMessage("Payer is required.")
            .on(this);
    }
}
