import { valueConverter } from "aurelia-framework";

import { ApplicationNameEnum } from "../../enums/enums";

@valueConverter("getProductName")
export class GetProductName {
    public toView(id: number): string {
        return ApplicationNameEnum[id];
    }
}
