import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapState, mapActions } from "pinia";

import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { IGetCityStateByZipcodeResponse } from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { LookupService } from "../../../../../services/lookup-service";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ReferralAddressForm from "../../ReferralAddressForm/ReferralAddressForm.vue";

export default defineComponent({
    components: {
        FormSection,
        ReferralAddressForm
    },

    data() {
        return {
            Options: [
                {
                    label: "Endangers health or well being of a home health provider",
                    value: 1
                },
                {
                    label: "State regulated patient (a condition or illness for which the state in which the patient resides has regulations or laws restricting the release of patient information for patients with that condition e.g., patients with HIV/AIDS)",
                    value: 2
                },
                {
                    label: "Patient request not to be contacted for surveys (without any prompting)",
                    value: 3
                },
                {
                    label: "Other",
                    value: 4
                }
            ],
            _lookupService: null
        };
    },
    created() {
        this._lookupService = Container.instance.get(LookupService);
        if (!this.referral.Contacts.SameAsEmergencyContact) {
            this.getChapsCountyData();
        }
    },
    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            accountId: "accountId",
            referralOptions: "referralOptions",
            referral: "referral",
            primaryEmergency: "primaryEmergencyCountyData",
            chapsCountyData: "chapsCountyData"
        })
    },

    watch: {
        primaryEmergency: {
            handler() {
                if (
                    this.referral.Contacts.SameAsEmergencyContact &&
                    this.referral.Contacts.PrimaryEmergencyContact.Address.Zip ===
                        this.referral.Contacts.PrimaryCahpsContact.Address.Zip
                ) {
                    this.setChapsData(this.primaryEmergency);
                }
            },
            deep: true
        }
    },

    methods: {
        ...mapActions(useEditReferralHH, { setChapsData: "SET_CHAPS_COUNTY" }),
        markChapsAsEmergencyContact() {
            if (this.referral.Contacts.SameAsEmergencyContact) {
                this.setChapsData(this.primaryEmergency);
                this.referral.Contacts.PrimaryCahpsContact.Address.AddressLine1 =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.AddressLine1;
                this.referral.Contacts.PrimaryCahpsContact.Address.AddressLine2 =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.AddressLine2;
                this.referral.Contacts.PrimaryCahpsContact.Address.City =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.City;
                this.referral.Contacts.PrimaryCahpsContact.Address.County =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.County;
                this.referral.Contacts.PrimaryCahpsContact.Address.State =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.State;
                this.referral.Contacts.PrimaryCahpsContact.Address.Zip =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.Zip;
                this.referral.Contacts.PrimaryCahpsContact.Address.ZipCodeFour =
                    this.referral.Contacts.PrimaryEmergencyContact.Address.ZipCodeFour;
                this.referral.Contacts.PrimaryCahpsContact.FirstName =
                    this.referral.Contacts.PrimaryEmergencyContact.FirstName;
                this.referral.Contacts.PrimaryCahpsContact.LastName =
                    this.referral.Contacts.PrimaryEmergencyContact.LastName;
                this.referral.Contacts.PrimaryCahpsContact.PrimaryPhone =
                    this.referral.Contacts.PrimaryEmergencyContact.PrimaryPhone;
                this.referral.Contacts.PrimaryCahpsContact.AlternatePhone =
                    this.referral.Contacts.PrimaryEmergencyContact.AlternatePhone;
                this.referral.Contacts.PrimaryCahpsContact.EmailAddress =
                    this.referral.Contacts.PrimaryEmergencyContact.EmailAddress;
                this.referral.Contacts.PrimaryCahpsContact.RelationOption =
                    this.referral.Contacts.PrimaryEmergencyContact.RelationOption;
                this.referral.Contacts.PrimaryCahpsContact.IsPrimary =
                    this.referral.Contacts.PrimaryEmergencyContact.IsPrimary;
            } else {
                this.setChapsData([]);
                this.referral.Contacts.PrimaryCahpsContact.Address.AddressLine1 = null;
                this.referral.Contacts.PrimaryCahpsContact.Address.AddressLine2 = null;
                this.referral.Contacts.PrimaryCahpsContact.Address.City = null;
                this.referral.Contacts.PrimaryCahpsContact.Address.County = null;
                this.referral.Contacts.PrimaryCahpsContact.Address.State = null;
                this.referral.Contacts.PrimaryCahpsContact.Address.Zip = null;
                this.referral.Contacts.PrimaryCahpsContact.Address.ZipCodeFour = null;
                this.referral.Contacts.PrimaryCahpsContact.FirstName = null;
                this.referral.Contacts.PrimaryCahpsContact.LastName = null;
                this.referral.Contacts.PrimaryCahpsContact.PrimaryPhone = null;
                this.referral.Contacts.PrimaryCahpsContact.AlternatePhone = null;
                this.referral.Contacts.PrimaryCahpsContact.EmailAddress = null;
                this.referral.Contacts.PrimaryCahpsContact.RelationOption = null;
                this.referral.Contacts.PrimaryCahpsContact.IsPrimary = false;
            }
        },

        async getChapsCountyData() {
            if (this.referral.Contacts.PrimaryCahpsContact.Address.Zip) {
                const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                    zipCode: this.referral.Contacts.PrimaryCahpsContact.Address.Zip,
                    agencyId: this.accountId,
                    application: this.application
                });
                const county: string[] = await this._lookupService.getCountyByZipcode(
                    this.referral.Contacts.PrimaryCahpsContact.Address.Zip,
                    this.accountId
                );
                this.setChapsData(county);
                this.referral.Contacts.PrimaryCahpsContact.Address.County = county ? county[0] : null;
                this.referral.Contacts.PrimaryCahpsContact.Address.State = result.state
                    ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                    : null;
                this.referral.Contacts.PrimaryCahpsContact.Address.City = result.city ? result.city : null;
            }
        },
        handleZipChange() {
            this.referral.Contacts.SameAsEmergencyContact = false;
            this.getChapsCountyData();
        }
    }
});
