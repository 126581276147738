import { defineComponent } from "vue";

export default defineComponent({
    props: {
        colSpan: {
            type: Number
        },
        primaryMessage: {
            type: String
        },
        secondaryMessage: {
            type: String
        },
        component: {
            type: String
        }
    }
});
