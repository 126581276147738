import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { IMessage } from "../../../../resources-vue/vue-interfaces/i-message";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { AnalyticsService } from "../../../../services/analytics.service";
import { MessageService } from "../../../../services/message.service";
import { NavigationService } from "../../../../services/navigation.service";
import { TaskService } from "../../../../services/task.service";
import { ILogOptions } from "../../../../resources-vue/vue-interfaces/i-analytics";
import { INavigateRequestParams, INavigateResponse } from "../../../../resources-vue/vue-interfaces/i-navigation";
import { formatDate } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { getApplicationId } from "../../../../common/vue-helpers/modifiers/application-modifiers";
import { useLoadingIndicator } from "../../../Common/LoadingIndicator/store/useLoadingIndicator";

import CardTemplate from "../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";
import ScrollPagination from "../../../../resources-vue/vue-elements/ScrollPagination/ScrollPagination.vue";
import ProductPill from "../../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";

export default defineComponent({
    components: {
        CardTemplate,
        ScrollPagination,
        ProductPill
    },

    props: {
        isNewScroll: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            videoData: "",
            isLoading: true,
            hasError: false,
            myMessagesTotal: null,
            pageNumber: 1,
            pageSize: 20,
            allPagesLoaded: false,
            myMessagesList: [] as Array<IMessage>,
            page: null,
            isAscending: true,
            _taskService: null,
            _messageService: null as MessageService,
            _navigate: null as NavigationService,
            _analytics: null as AnalyticsService,
            _permissionManager: null as PermissionManager,
            tooltip: `This section displays all unread messages.To view an entire message, click on the message subject.`,
            logCategory: "Unread-Messages-List",
            userOs: "",
            formatDate,
            getApplicationId
        };
    },

    created() {
        this._messageService = Container.instance.get(MessageService);
        this._navigate = Container.instance.get(NavigationService);
        this._analytics = Container.instance.get(AnalyticsService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._taskService = Container.instance.get(TaskService);
        this.userOs = window.sessionStorage.getItem("userOs");
    },

    async mounted() {
        await this.getTotalMessages();
        await this.getData();
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        async getTotalMessages() {
            try {
                this.myMessagesTotal = await this._messageService.getTotal();
            } catch (error) {
                console.error(error);
            }
        },
        async getData() {
            if (this.allPagesLoaded) {
                return;
            }
            try {
                this.hasError = false;
                this.isLoading = true;
                let response: Array<IMessage> = await this._messageService.findAll({
                    page: this.pageNumber,
                    pageSize: this.pageSize
                });
                this.updateMessageList(response);
                if (this.myMessagesTotal > 0 && !this.allPagesLoaded && response.length <= 0) {
                    this.hasError = true;
                    console.error("Total > 0, all pages not loaded but APi returned no result", this.pageNumber);
                }
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        updateMessageList(receivedList: Array<IMessage>) {
            if (this.pageNumber === 1) {
                this.myMessagesList = receivedList;
                return;
            }
            if (receivedList.length < this.pageSize) {
                this.allPagesLoaded = true;
            }
            this.myMessagesList = this.myMessagesList.concat(receivedList);
        },
        pageNumberUpdated(pageToLoad: number) {
            if (!pageToLoad) {
                return;
            }
            if (pageToLoad != this.pageNumber) {
                this.pageNumber = pageToLoad;
            } else {
                return;
            }
            this.allPagesLoaded = this.pageNumber > this.page?.pageCount;
            if (!this.allPagesLoaded) {
                this.getData();
            }
        },

        async viewMessage(message: IMessage) {
            let analyticsOptions: ILogOptions = {
                category: this.logCategory,
                action: "View-Message"
            };
            const isClinician = this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView
            );
            let navParams: INavigateRequestParams = {
                agencyId: message.AgencyId,
                applicationId: message.Application,
                userId: message.UserId,
                isClinician: isClinician,
                messageId: message.MessageId,
                messageType: message.MessageType
            };
            try {
                this.showLoading(true);
                let response: INavigateResponse = await this._navigate.navigateToMessage(navParams);
                this._navigate.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        },

        sortByScheduleDate() {
            this.isAscending = !this.isAscending;
            this.myMessagesList = this._taskService.sortTasks(this.myMessagesList, "Sent", "date", !this.isAscending);
        }
    }
});
