<template>
    <div class="border-0 missed-visit-box w-100">
        <section v-if="isLoading">
            <section class="p-5 d-flex flex-column text-center text-primary">
                <i class="fa fa-spinner fa-spin font-size-5xl mr-2 animated slow flash infinite"></i>
                <span class="mt-1 big-text">
                    <b>Loading...</b>
                </span>
            </section>
        </section>
        <section v-else class="m-3 card pb-2">
            <div class="dialog container row m-0 p-0">
                <h6 class="font-weight-bold p-2 border-0 heading-text col-12 px-3 d-flex align-items-center">
                    Missed Visit Details
                    <ProductPill
                        class="align-self-start ml-2 mb-1"
                        :product-id="getApplicationId(taskDetails.Application)"
                    />
                </h6>
                <div class="pb-2 px-3 col-12 au-target missed-visit-container overflow-auto">
                    <div class="d-flex">
                        <p class="mb-1">
                            <span class="font-weight-bold"> Patient Name: </span>
                            <span class="ml-2">
                                {{ taskDetails.PatientName?.FirstName }} {{ taskDetails.PatientName?.LastName }}
                            </span>
                        </p>
                        <p class="mb-1 ml-4">
                            <span class="font-weight-bold"> Type of Visit: </span>
                            <span class="ml-2"> {{ taskDetails.VisitName }} </span>
                        </p>
                    </div>

                    <div class="py-2 w-50">
                        <label class="font-weight-bold"> Date of Visit: </label>
                        <div class="input-group date w-60">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="MM/DD/YYYY"
                                :value="formatDateInput(taskDetails.Date)"
                                autocomplete="off"
                                disabled
                                number-only
                            />
                            <div class="input-group-addon input-group-append">
                                <div class="input-group-text">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="!isHomeHealthHomeCare()">
                        <div class="w-100 pt-2">
                            <div class="d-flex justify-content-between">
                                <div class="col-6 pl-0 w-40 pr-3">
                                    <div class="form-group" v-if="isHospice()">
                                        <label> Reason: <span class="text-danger">*</span> </label>
                                        <div>
                                            <select
                                                class="form-control"
                                                v-model="startMissedVisit.ReasonId"
                                                @blur="v$.startMissedVisit.ReasonId.$touch"
                                                :class="`${
                                                    !v$.startMissedVisit.ReasonId.$error ? '' : 'field-invalid'
                                                }`"
                                            >
                                                <option :value="null">Select Reason</option>
                                                <option
                                                    v-for="[key, value] in enumToMap(MissedVisitHospiceReasons)"
                                                    :key="key"
                                                    :value="value.value"
                                                >
                                                    {{ value.name }}
                                                </option>
                                            </select>
                                            <span v-if="v$.startMissedVisit.ReasonId.$error" class="field-invalid"
                                                >Reason is required.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="form-group" v-else>
                                        <label class="font-weight-bold">
                                            Reason: <span class="text-danger">*</span>
                                        </label>
                                        <div>
                                            <select
                                                class="form-control"
                                                v-model="startMissedVisit.ReasonId"
                                                @blur="v$.startMissedVisit.ReasonId.$touch"
                                                :class="`${
                                                    !v$.startMissedVisit.ReasonId.$error ? '' : 'field-invalid'
                                                }`"
                                            >
                                                <option :value="null">Select Reason</option>
                                                <option
                                                    v-for="[key, value] in enumToMap(MissedVisitPalliativeReasons)"
                                                    :key="key"
                                                    :value="value.value"
                                                >
                                                    {{ value.name }}
                                                </option>
                                            </select>
                                            <span v-if="v$.startMissedVisit.ReasonId.$error" class="field-invalid"
                                                >Reason is required.</span
                                            >
                                        </div>
                                    </div>
                                    <div class="py-2 form-group missed-visit-comment">
                                        <label for="reason-comments"
                                            >Narrative
                                            <span v-if="startMissedVisit.ReasonId === 9" class="text-danger">*</span>
                                        </label>
                                        <textarea
                                            class="form-control"
                                            name="reason-comments"
                                            id="reason-comments"
                                            v-model="startMissedVisit.Comments"
                                            rows="2"
                                            maxlength="2000"
                                            :class="`${!v$.startMissedVisit.Comments.$error ? '' : 'field-invalid'}`"
                                            @blur="v$.startMissedVisit.Comments.$touch"
                                        ></textarea>
                                        <span v-if="v$.startMissedVisit.Comments.$error" class="field-invalid"
                                            >Narrative is required.</span
                                        >
                                    </div>
                                </div>
                                <div class="col-6 pl-0 w-40 pl-3" v-if="isHospice()">
                                    <div class="form-check mb-2">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="physicianNotified"
                                            v-model="startMissedVisit.IsPhysicianOfficeNotified"
                                        />
                                        <label class="form-check-label" for="physicianNotified"
                                            >Physician Notified</label
                                        >
                                    </div>
                                    <div class="form-group mt-2" v-if="startMissedVisit.IsPhysicianOfficeNotified">
                                        <label for="physician">Physician</label>
                                        <TypeaheadInput
                                            placeholder="Start Typing"
                                            :getData="getPhysicianList"
                                            :valueChanged="physicianValue"
                                        />
                                    </div>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="rnCaseManagerNotified"
                                            v-model="startMissedVisit.IsRnCaseManagerNotified"
                                        />
                                        <label class="form-check-label" for="rnCaseManagerNotified"
                                            >RN Case Manager Notified</label
                                        >
                                    </div>
                                    <div class="form-group mt-2" v-if="startMissedVisit.IsRnCaseManagerNotified">
                                        <label for="rnCaseManager">RN Case Manager</label>
                                        <TypeaheadInput
                                            placeholder="Start Typing"
                                            :getData="usersFetch"
                                            :valueChanged="usersValue"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 pl-0 w-40 pl-3" v-else>
                                    <div class="form-check mb-2">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="providerNotified"
                                            v-model="startMissedVisit.IsPhysicianOfficeNotified"
                                        />
                                        <label class="form-check-label" for="providerNotified">Provider Notified</label>
                                    </div>
                                    <div class="form-group mt-2" v-if="startMissedVisit.IsPhysicianOfficeNotified">
                                        <label for="provider">Provider</label>
                                        <TypeaheadInput
                                            placeholder="Start Typing"
                                            :getData="getPhysicianList"
                                            :valueChanged="physicianValue"
                                        />
                                    </div>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="caseManagerNotified"
                                            v-model="startMissedVisit.IsRnCaseManagerNotified"
                                        />
                                        <label class="form-check-label" for="caseManagerNotified"
                                            >Case Manager Notified</label
                                        >
                                    </div>
                                    <div class="form-group mt-2" v-if="startMissedVisit.IsRnCaseManagerNotified">
                                        <label for="caseManager">Case Manager</label>
                                        <TypeaheadInput
                                            placeholder="Start Typing"
                                            :getData="usersFetch"
                                            :valueChanged="usersValue"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-100 py-2">
                            <div class="d-flex justify-content-between">
                                <div class="col-6 form-group pr-3 pl-0">
                                    <label for="signatureTime">
                                        Signature Time
                                        <span class="text-danger">*</span>
                                    </label>
                                    <TimePicker @update-props="changeTime">
                                        <input
                                            type="text"
                                            class="form-control timepicker"
                                            v-model="startMissedVisit.SignatureTime"
                                            autocomplete="off"
                                            maxlength="8"
                                            :class="`${
                                                !v$.startMissedVisit.SignatureTime.$error ? '' : 'field-invalid mt-0'
                                            }`"
                                            @blur="v$.startMissedVisit.SignatureTime.$touch"
                                        />
                                    </TimePicker>
                                    <span
                                        v-for="error in v$.startMissedVisit.SignatureTime.$errors"
                                        :key="error.$uid"
                                        class="field-invalid"
                                        >{{ error.$message }}</span
                                    >
                                </div>
                                <div class="col-6 form-group pl-3">
                                    <label class="font-weight-bold" for="signatureDate">
                                        Signature Date <span class="text-danger">*</span>
                                    </label>
                                    <DatePicker :getDate="changeDate">
                                        <div class="input-group date datepickerInput">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="MM/DD/YYYY"
                                                :value="formatDateInput(startMissedVisit.SignatureDate)"
                                                @change="handleDateInputChange"
                                                autocomplete="off"
                                                :class="`${
                                                    !v$.startMissedVisit.SignatureDate.$error ? '' : 'field-invalid'
                                                }`"
                                                @blur="v$.startMissedVisit.SignatureDate.$touch"
                                            />
                                            <div class="input-group-addon input-group-append">
                                                <div class="input-group-text">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="v$.startMissedVisit.SignatureDate.$error" class="field-invalid"
                                            >Signature Date is required.</span
                                        >
                                    </DatePicker>
                                </div>
                            </div>
                            <div class="form-check form-group mt-2">
                                <input type="checkbox" class="form-check-input" id="signature" v-model="signature" />
                                <label class="form-check-label" for="signature">Click here to sign the document</label>
                            </div>
                        </div>
                    </template>
                    <div v-else class="form-group">
                        <div class="w-100 pt-2">
                            <div class="d-flex">
                                <div class="pl-0 col-3">
                                    <label> Order Generated: </label>
                                    <div class="form-group d-flex">
                                        <div class="col-group mr-4">
                                            <input
                                                type="radio"
                                                id="order-generated-yes"
                                                name="order-generated"
                                                v-model="startMissedVisit.IsOrderGenerated"
                                                :value="true"
                                            />
                                            <label for="order-generated-yes" class="font-weight-normal pl-1">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="col-group">
                                            <input
                                                type="radio"
                                                id="order-generated-no"
                                                name="order-generated"
                                                v-model="startMissedVisit.IsOrderGenerated"
                                                :value="false"
                                            />
                                            <label for="order-generated-no" class="font-weight-normal pl-1"> No </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label>Physician Office Notified: </label>
                                    <div class="form-group d-flex">
                                        <div class="col-group mr-4">
                                            <input
                                                type="radio"
                                                id="office-notified-yes"
                                                name="office-notified"
                                                v-model="startMissedVisit.IsPhysicianOfficeNotified"
                                                :value="true"
                                            />
                                            <label for="office-notified-yes" class="font-weight-normal pl-1">
                                                Yes
                                            </label>
                                        </div>
                                        <div class="col-group">
                                            <input
                                                type="radio"
                                                id="office-notified-no"
                                                name="office-notified"
                                                v-model="startMissedVisit.IsPhysicianOfficeNotified"
                                                :value="false"
                                            />
                                            <label for="office-notified-no" class="font-weight-normal pl-1"> No </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" v-if="showHCFields">
                            <div class="py-2 form-group w-40">
                                <label> Tracking Type: <span class="text-danger">*</span> </label>
                                <div>
                                    <select
                                        @change="updateReasons()"
                                        class="form-control"
                                        v-model="startMissedVisit.VisitTracking.TypeId"
                                        :class="`${
                                            !v$.startMissedVisit.VisitTracking.TypeId.$error ? '' : 'field-invalid'
                                        }`"
                                        @blur="v$.startMissedVisit.VisitTracking.TypeId.$touch"
                                    >
                                        <option :value="null">Select Tracking Type</option>
                                        <option
                                            class="text-capitalize"
                                            v-for="trackingtype in trackingTypes"
                                            :value="trackingtype.Id"
                                            :key="trackingtype"
                                        >
                                            {{ trackingtype.Name }}
                                        </option>
                                    </select>
                                    <span v-if="v$.startMissedVisit.VisitTracking.TypeId.$error" class="field-invalid"
                                        >Tracking Type is required.</span
                                    >
                                </div>
                            </div>
                            <div class="py-2 form-group ml-4 w-40">
                                <label> Reason: <span class="text-danger">*</span> </label>
                                <div>
                                    <select
                                        class="form-control"
                                        @change="setReasonString()"
                                        v-model="startMissedVisit.Reason"
                                        :class="`${!v$.startMissedVisit.Reason.$error ? '' : 'field-invalid'}`"
                                        @blur="v$.startMissedVisit.Reason.$touch"
                                    >
                                        <option :value="null">Select Reason</option>
                                        <template v-if="startMissedVisit.VisitTracking?.TypeId">
                                            <option
                                                v-for="reason in missedVisitsReasonsToShow"
                                                :value="reason.Reason"
                                                :key="reason"
                                            >
                                                {{ reason.Reason }}
                                            </option>
                                        </template>
                                    </select>
                                    <span v-if="v$.startMissedVisit.Reason.$error" class="field-invalid"
                                        >Reason is required.</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div v-else class="form-group">
                            <label> Reason: <span class="text-danger">*</span> </label>
                            <div>
                                <select
                                    class="form-control"
                                    v-model="startMissedVisit.Reason"
                                    :class="`${!v$.startMissedVisit.Reason.$error ? '' : 'field-invalid'}`"
                                    @blur="v$.startMissedVisit.Reason.$touch"
                                >
                                    <option :value="null">Select Reason</option>
                                    <option v-for="reason in missedVisitReasonsHH" :value="reason" :key="reason">
                                        {{ reason === "Other" ? "Other (Specify)" : reason }}
                                    </option>
                                </select>
                                <span v-if="v$.startMissedVisit.Reason.$error" class="field-invalid"
                                    >Reason is required.</span
                                >
                            </div>
                        </div>

                        <div class="py-2 form-group missed-visit-comment">
                            <label for="reason-comments"
                                >Comments
                                <span v-if="startMissedVisit.Reason === 'Other'" class="text-danger">*</span>
                            </label>
                            <textarea
                                class="form-control"
                                name="reason-comments"
                                id="reason-comments"
                                v-model="startMissedVisit.Comments"
                                rows="2"
                                maxlength="2000"
                                placeholder="Enter Comments"
                                :class="`${!v$.startMissedVisit.Comments.$error ? '' : 'field-invalid'}`"
                                @blur="v$.startMissedVisit.Comments.$touch"
                            ></textarea>
                            <span v-if="v$.startMissedVisit.Comments.$error" class="field-invalid"
                                >Comment is required.</span
                            >
                        </div>
                        <div v-if="showHCFields">
                            <div>
                                <label class="font-weight-bold">Attachments </label>
                            </div>
                            <div class="p1-1 pb-2">
                                <label>
                                    Use the upload fields below to upload files associated with this missed visit.
                                </label>
                                <div class="py-2 d-flex flex-column w-50">
                                    <div class="custom-file mb-2">
                                        <input
                                            @change="attachmentAdded($event, 'attachment1Ref')"
                                            type="file"
                                            class="custom-file-input"
                                            name="attachment1"
                                            accept=".xlsx, .xls,.png,.jpg,.jpeg,.pdf,.doc,.docx"
                                            id="attachment1"
                                        />
                                        <label class="custom-file-label h-95" for="attachment1">{{
                                            attachment1Ref?.[0]?.name
                                        }}</label>
                                    </div>
                                    <!-- <div class="custom-file mb-2">
                                        <input
                                            @change="attachmentAdded($event, 'attachment2Ref')"
                                            type="file"
                                            class="custom-file-input"
                                            name="attachment2"
                                            accept=".xlsx, .xls,.png,.jpg,.jpeg,.pdf,.doc,.docx"
                                            id="attachment2"
                                        />
                                        <label class="custom-file-label h-95" for="attachment2">{{
                                            attachment2Ref?.[0]?.name
                                        }}</label>
                                    </div>
                                    <div class="custom-file">
                                        <input
                                            @change="attachmentAdded($event, 'attachment3Ref')"
                                            type="file"
                                            class="custom-file-input"
                                            name="attachment3"
                                            accept=".xlsx, .xls,.png,.jpg,.jpeg,.pdf,.doc,.docx"
                                            id="attachment3"
                                        />
                                        <label class="custom-file-label h-95" for="attachment3">{{
                                            attachment3Ref?.[0]?.name
                                        }}</label>
                                    </div> -->
                                </div>
                                <p class="text-danger" v-if="attachmentError">Attachment size exceeds limit</p>
                            </div>
                        </div>
                        <div class="w-100 py-2">
                            <div class="d-flex justify-content-between">
                                <div class="col-5 form-group pl-0">
                                    <label for="signature">
                                        Staff Signature
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="signature"
                                        v-model="startMissedVisit.Signature"
                                        class="form-control"
                                        type="password"
                                        :class="`${!v$.startMissedVisit.Signature.$error ? '' : 'field-invalid'}`"
                                        @blur="v$.startMissedVisit.Signature.$touch"
                                    />
                                    <span v-if="v$.startMissedVisit.Signature.$error" class="field-invalid"
                                        >Signature is required.</span
                                    >
                                </div>
                                <div class="col-5 form-group pl-0">
                                    <label> Signature Date <span class="text-danger">*</span> </label>
                                    <DatePicker :getDate="changeDate">
                                        <div class="input-group date datepickerInput">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="MM/DD/YYYY"
                                                :value="formatDateInput(startMissedVisit.SignatureDate)"
                                                @change="handleDateInputChange"
                                                autocomplete="off"
                                                number-only
                                                :class="`${
                                                    !v$.startMissedVisit.SignatureDate.$error ? '' : 'field-invalid'
                                                }`"
                                                @blur="v$.startMissedVisit.SignatureDate.$touch"
                                            />
                                            <div class="input-group-addon input-group-append">
                                                <div class="input-group-text">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <span v-if="v$.startMissedVisit.SignatureDate.$error" class="field-invalid"
                                            >Signature Date is required.</span
                                        >
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary mr-2 border-0" @click="postMissedVisit()" :disabled="!signature">
                            Submit
                        </button>
                        <button class="btn btn-outline-primary" @click="closeDialog()">Cancel</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script src="./MissedVisit.ts"></script>
