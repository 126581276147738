import { valueConverter } from "aurelia-framework";
import { IMessageUser } from "../../../interfaces/messaging-service/i-message";
import * as moment from "moment";

@valueConverter("formatUserInitials")
export class FormatUserInitials {
    public toView(value: IMessageUser) {
        if (value) {
            let firstInitial = value.firstName == null ? "" : value.firstName.trim().substring(0, 1);
            let lastInitial = value.lastName == null ? "" : value.lastName.trim().substring(0, 1);
            let initials = `${firstInitial}${lastInitial}`;
            return initials;
        } else {
            return null;
        }
    }
}
