import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import type { IPermissionWithAgencies } from "../resources-vue/vue-interfaces/i-permissions";

@autoinject
export class PermissionsService {
    private _fetchClient: FetchClient;

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async getPermission(): Promise<IPermissionWithAgencies> {
        return await this._fetchClient.build("/api/v2/meta/permissions").fetch<IPermissionWithAgencies>();
    }
}
