import { defineComponent, PropType } from "vue";
import jquery from "jquery";

import { ConflictTypeEnum } from "../../../enums/enums";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";

export default defineComponent({
    props: {
        selectedItems: { type: Array, default: [] },
        hasCustomView: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        activeClasses: { type: String, default: "" },
        options: { type: Array as PropType<ITypeaheadOptions[]>, default: [] },
        title: { type: String, default: "" },
        hasActions: { type: Boolean, default: false },
        name: { type: String, default: "Options" },
        showIcons: { type: Boolean, default: false },
        placeholder: { type: String, default: "Select Options" }
    },
    data() {
        return {
            message: "",
            isActive: false,
            _bindingEngine: null
        };
    },
    emits: ["selectedItemsUpdated"],
    watch: {
        options() {
            this.updateMessage();
        },
        selectedItems() {
            this.updateMessage();
        }
    },
    mounted() {
        this.updateMessage();
        jquery(this.$refs.dropdownElement).on("click.bs.dropdown", (e: any) => {
            if (e.target.nodeName != "BUTTON") {
                e.stopPropagation();
            }
        });
        jquery(this.$refs.dropdown).on("hide.bs.dropdown", (e) => {
            this.toggleActiveClasses(false);
        });
    },
    methods: {
        selectedItem(value: string) {
            if (this.selectedItems.includes(value)) {
                return true;
            } else {
                return false;
            }
        },
        toggleActiveClasses(value: boolean) {
            this.isActive = value;
        },
        selectAll() {
            this.$emit(
                "selectedItemsUpdated",
                this.options.map((option) => option.value)
            );
        },
        deselectAll() {
            this.$emit("selectedItemsUpdated", []);
        },
        getView(rowItem: ITypeaheadOptions) {
            let result: string = "";
            switch (rowItem.type) {
                case "conflict-type":
                    if (Number(rowItem.value) == ConflictTypeEnum.NoConflict) {
                        result = `<i class="fa fa-check-circle icon-green"></i>`;
                    } else if (Number(rowItem.value) == ConflictTypeEnum.PartialConflict) {
                        result = `<i class="fa fa-exclamation-circle icon-gold"></i>`;
                    } else if (Number(rowItem.value) == ConflictTypeEnum.FullConflict) {
                        result = `<i class="fa fa-times-circle icon-red"></i>`;
                    }
                    break;
            }
            return result;
        },
        updateSelectedItems(value: any, event: any) {
            this.$emit("selectedItemsUpdated", value);
            this.updateMessage();
        },
        updateMessage() {
            if (!this.selectedItems || this.selectedItems.length === 0) {
                this.message = this.placeholder;
            } else if (this.selectedItems.length === 1) {
                let selectedOption = this.options.find((option) => option.value === this.selectedItems[0]);
                this.message = selectedOption?.name;
            } else if (this.selectedItems.length > 1) {
                this.message = `${this.selectedItems.length} Selected`;
            }
        }
    }
});
