import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import config from "../../../../../common/config";
import { IEnumResponse } from "../../../../../resources-vue/vue-interfaces/i-enum";
import { IMultiSelectDropDownOption } from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect";
import { EnumsService } from "../../../../../services/enums.service";
import { AgencyService, IGetAgency, IGetOrganization } from "../../../../../services/agency.service";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PatientsScheduledFilter,
    PermissionActionEnum
} from "../../../../../enums/enums";
import { adjustDate } from "../../../../../common/adjust-date";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";

import MultiSelect from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect.vue";

export default defineComponent({
    components: {
        MultiSelect
    },

    data() {
        return {
            _enumsService: null,
            _agencyService: null,
            _permissionManager: null,
            patientStatusEnums: [] as IEnumResponse[],
            weekDayRangeEnums: [] as IEnumResponse[],
            ScheduledFilterEnums: PatientsScheduledFilter,
            scheduleFilter: 0,
            agencyMultiSelectOptions: {} as IMultiSelectDropDownOption,
            selectedAgencies: [] as string[],
            term: "" as string,
            selectedPatientStatus: null,
            agencyList: [] as IGetAgency[],
            organizations: [] as IGetOrganization[],
            organizationName: "" as string,
            startDate: null as Date,
            endDate: null as Date,
            selectedWeekdayRange: null,
            isWeekRangeChanged: false,
            enumToMap
        };
    },

    created() {
        this._enumsService = Container.instance.get(EnumsService);
        this._agencyService = Container.instance.get(AgencyService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this.initAgencyMultiSelect();
    },

    async mounted() {
        let patientStatusEnums = await this._enumsService.getPatientStatus();
        this.patientStatusEnums = patientStatusEnums.getJson();
        let weekDayRangeEnums = await this._enumsService.getWeekDayRange();
        this.weekDayRangeEnums = weekDayRangeEnums.getJson();
        this.organizations = await this._agencyService.getOrganizations();
        this.organizationName = window.localStorage.getItem(config.organizationName);
        this.agencyList = this.organizations.find(
            (organization: { name: string }) => organization.name === this.organizationName
        ).agencies;
        this.agencyList = this.agencyList.map((agency) => {
            let agencyAndApplicationIds = agency.id.split("_");
            let application = parseInt(agencyAndApplicationIds[1], 10);
            if (application == 256) {
                application = ApplicationEnum.AxxessHospice;
            }
            return {
                ...agency,
                application: application
            };
        });
        this.selectedPatientStatus = patientStatusEnums.getEnumValue("active") as number;
        this.selectedWeekdayRange = Number(this.weekDayRangeEnums[0].value);
        await this.initAgencyMultiSelect();
    },

    emits: ["patientRefresh"],

    watch: {
        term: async function (newValue, oldValue) {
            if (newValue !== oldValue) {
                await this.emitPatientRefresh();
            }
        },

        selectedWeekdayRange: function (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.isWeekRangeChanged = true;
                let today = new Date(Date.now());
                let dayRequested = newValue - 1;
                if (dayRequested < 0) {
                    dayRequested = 0;
                }

                let todayDay = today.getDay();
                let offset = dayRequested - todayDay;
                if (offset > 0) {
                    offset -= 7;
                }
                this.startDate = adjustDate(today, offset);
                this.endDate = adjustDate(this.startDate, 6);
                this.emitPatientRefresh();
            }
            this.isWeekRangeChanged = false;
        },

        selectedPatientStatus: async function (newValue, oldValue) {
            if (newValue !== oldValue && this.startDate) {
                await this.emitPatientRefresh();
            }
        },

        scheduleFilter() {
            this.emitPatientRefresh();
        }
    },

    methods: {
        async initAgencyMultiSelect() {
            try {
                this.agencyMultiSelectOptions = {};
                this.selectedAgencies.length = 0;
                if (!this.agencyList || this.agencyList?.length === 0) {
                    return;
                }
                let allowedProducts = [
                    ApplicationEnum.AgencyCore,
                    ApplicationEnum.HomeCare,
                    ApplicationEnum.AxxessHospice,
                    ApplicationEnum.AxxessPalliative
                ];
                const schedulerViewPermissions = this._permissionManager.getPermmission(
                    ParentPermissionEnum.scheduler,
                    PermissionActionEnum.canView
                );
                let schedulerAgencies = schedulerViewPermissions
                    .filter((item: any) => {
                        if (!item) {
                            return false;
                        }
                        let application = item.application;
                        if (application === ApplicationEnum.AxxessHospiceFE) {
                            application = ApplicationEnum.AxxessHospice;
                        }
                        return allowedProducts.includes(application);
                    })
                    .map((x: any) => x.agencyId);
                let filterableProducts = this.agencyList.filter((agency) => {
                    let isAllowed = allowedProducts.includes(agency.application);
                    let agencyId = agency.id?.split("_")[0];
                    return isAllowed && schedulerAgencies.includes(agencyId);
                });
                filterableProducts?.forEach((agency) => {
                    let appName = ApplicationNameEnum[agency.application];

                    if (!this.agencyMultiSelectOptions[appName]) {
                        this.agencyMultiSelectOptions[appName] = [];
                    }
                    this.agencyMultiSelectOptions[appName].push({
                        displayText: agency.name,
                        value: agency.id
                    });
                });

                Object.values(this.agencyMultiSelectOptions).forEach((agency: any) => {
                    let values = agency.map((item: any) => item.value);
                    this.selectedAgencies.push(...values);
                });
                // For select agencies change
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },

        updateBranches(branches: any) {
            this.selectedAgencies.length = 0;
            this.selectedAgencies.push(...branches);
            this.emitPatientRefresh();
        },

        async emitPatientRefresh() {
            this.$emit(
                "patientRefresh",
                {
                    term: this.term,
                    agencies: this.selectedAgencies,
                    patientStatus: this.selectedPatientStatus,
                    scheduleFilter: this.scheduleFilter,
                    startDate: this.startDate,
                    endDate: this.endDate
                },
                this.isWeekRangeChanged
            );
        }
    }
});
