import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "py-2 px-2",
  id: "Dashboard__Header"
}
const _hoisted_2 = { class: "row no-gutters" }
const _hoisted_3 = { class: "col-md-4 col-12 font-weight-bold" }
const _hoisted_4 = { class: "m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.formatingDateOrTime(_ctx.today, _ctx.dateFormat)), 1 /* TEXT */)
      ])
    ])
  ]))
}