import "../../../visit-details/visit-details.scss";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { observable, computedFrom } from "aurelia-binding";
import { ValidationController } from "aurelia-validation";

import type { IEmployeeList, IReassignVisitDetails } from "../../../../../interfaces/i-reassign-visit";
import { type IScheduleEvent, VisitDetails } from "../../../../../models/visit-details";
import { IFrequencyListRequestParams, IFrequencyListResponse, TaskService } from "../../../../../services/task.service";
import type {
    ITaskDetailsPayload,
    INewVisitDetailsResponse
} from "../../../../../resources-vue/vue-interfaces/i-task-details";
import { ToastrService } from "../../../../../services/toastr.service";
import nameOf from "../../../../../common/name-of";
import { ScheduleVisitHomeHealthModel } from "../../../../../models/schedule-visits/schedule-visit-home-health";
import {
    IDisciplineTaskHH,
    IGetDisciplineTaskHHParams
} from "../../../../../resources-vue/vue-interfaces/i-discipline-task-hh";
import { LookupService } from "../../../../../services/lookup-service";
import { IValidateCustomElement } from "../../../../../interfaces/i-validate-custom-element";
import { User } from "oidc-client";
import {
    ICurrentEpisodeRequestParams,
    ICurrentEpisodeResponse,
    PatientService
} from "../../../../../services/patient.service";
import { IGetPatientByBranchResponse, IPatientByAgencyRequest } from "../../../../../interfaces/i-patient-service";
import type { ITypeaheadOptions } from "../../../../../interfaces/i-typeahead";
import moment from "moment";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export interface IVisitOrders {
    discipline: string;
    frequency: string;
    effectiveDate: string;
}

@autoinject
@customElement("schedule-visit-home-health")
export class ScheduleVisitHomeHealth {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public visitDetails: IReassignVisitDetails;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public cancel: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public ok: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public reassign: (params: { reassignDetails: IReassignVisitDetails }) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isSaving: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public employeeList: IEmployeeList[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showAllByDefault: boolean = true;
    private _taskService: TaskService;
    private _toastrService: ToastrService;
    private _lookupService: LookupService;
    private _patientService: PatientService;
    private _user: User;
    public controller: ValidationController = null;
    public commentInputClass: { input: string; textarea?: string } = { input: "w-35" };
    public taskDetails: VisitDetails;
    public isSavingReason: boolean = false;
    public showAssignedToList: boolean = false;
    public assignedToName: string = "";
    public newVisitDetails: ScheduleVisitHomeHealthModel = new ScheduleVisitHomeHealthModel();
    public disciplineTasks: IDisciplineTaskHH[] = [];
    public patients: IGetPatientByBranchResponse[] = [];
    public SaveEnum = SaveEnum;
    public currentUserId: string = "";
    public currentEpisodeDetails: ICurrentEpisodeResponse = null;
    public patientFrequencyList: IFrequencyListResponse[] = [];
    public visitOrders: IVisitOrders[] = [];
    public showVisitDateValidation: boolean = false;
    public isDateTouched: boolean = false;
    @observable({ changeHandler: nameOf<ScheduleVisitHomeHealth>("visitDatesChanged") })
    public visitDates: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHomeHealth>("patientIdChanged") })
    public patientId: ITypeaheadOptions;

    public constructor(
        validationController: ValidationController,
        taskService: TaskService,
        toastrService: ToastrService,
        lookupService: LookupService,
        patientService: PatientService
    ) {
        this.controller = validationController;
        this._taskService = taskService;
        this._toastrService = toastrService;
        this._lookupService = lookupService;
        this._patientService = patientService;
    }

    public async attached() {
        Object.assign(this.visitDetails, {});
        try {
            this.isLoading = true;
            const payload: ITaskDetailsPayload = {
                application: this.visitDetails.Application,
                agencyId: this.visitDetails.AgencyId,
                eventId: this.visitDetails.Id,
                episodeId: this.visitDetails.EpisodeId,
                patientId: this.visitDetails.PatientId
            };
            const visitDetails = await this._taskService.getHHVisitDetails(payload);
            this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
            this.newVisitDetails.application = this.visitDetails.Application;
            await this.getDisciplineTasks();
            await this.getPatient("");
        } catch (error) {
            console.log(error);
        } finally {
            this.isLoading = false;
        }
    }

    public patientValidation: IValidateCustomElement = {
        required: true,
        displayName: "Patient"
    };

    @computedFrom(
        `${nameOf<ScheduleVisitHomeHealth>("taskDetails")}.${nameOf<VisitDetails>(
            "scheduleEvent"
        )}.${nameOf<IScheduleEvent>("scheduleStatus")}`
    )
    public get isReturnedWithSignature(): boolean {
        return this.taskDetails?.scheduleEvent?.scheduleStatus == 135;
    }

    public formatTime(dateTime: Date) {
        return dateTime
            ? new Date(dateTime).toLocaleTimeString("en-us", {
                  hour: "2-digit",
                  minute: "2-digit"
              })
            : "";
    }

    public patientIdChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            this.visitDetails.PatientId = newValue.value;
            this.getCurrentEpisode();
            this.getPatientFrequencyList();
            this.visitOrders = [];
        }
    }

    private async getDisciplineTasks() {
        try {
            const payload: IGetDisciplineTaskHHParams = {
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application
            };
            this.disciplineTasks = await this._lookupService.getDisciplineTasks(payload);
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Visit Type Failed`,
                message: `Fetching Visit Type Operation Failed, Please Contact Axxess if issue still persists.`
            });
        }
    }

    private async getCurrentEpisode() {
        try {
            const payload: ICurrentEpisodeRequestParams = {
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application,
                patientId: this.visitDetails.PatientId
            };
            this.currentEpisodeDetails = await this._patientService.getCurrentEpisode(payload);
            this.newVisitDetails.episodeId = this.currentEpisodeDetails.id;
            this.getPatientFrequencyList();
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Current Episode Failed`,
                message: `Fetching Current Episode Operation Failed, Please Contact Axxess if issue still persists.`
            });
        }
    }

    private async getPatientFrequencyList() {
        try {
            const payload: IFrequencyListRequestParams = {
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application,
                patientId: this.visitDetails.PatientId,
                episodeId: this.currentEpisodeDetails?.id,
                episodeStartDate: this.currentEpisodeDetails?.startDate
            };
            this.patientFrequencyList = await this._taskService.getFrequencyList(payload);

            this.patientFrequencyList.map((patientFrequency) => {
                let visitOrder: IVisitOrders = null;
                if (patientFrequency.frequencyHHA) {
                    visitOrder = {
                        discipline: "HHA",
                        frequency: patientFrequency.frequencyHHA,
                        effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                    };
                    this.visitOrders.push(visitOrder);
                }
                if (patientFrequency.frequencyMSW) {
                    visitOrder = {
                        discipline: "MSW",
                        frequency: patientFrequency.frequencyMSW,
                        effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                    };
                    this.visitOrders.push(visitOrder);
                }
                if (patientFrequency.frequencyNursing) {
                    visitOrder = {
                        discipline: "SN",
                        frequency: patientFrequency.frequencyNursing,
                        effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                    };
                    this.visitOrders.push(visitOrder);
                }
                if (patientFrequency.frequencyOT) {
                    visitOrder = {
                        discipline: "OT",
                        frequency: patientFrequency.frequencyOT,
                        effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                    };
                    this.visitOrders.push(visitOrder);
                }
                if (patientFrequency.frequencyPT) {
                    visitOrder = {
                        discipline: "PT",
                        frequency: patientFrequency.frequencyPT,
                        effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                    };
                    this.visitOrders.push(visitOrder);
                }
                if (patientFrequency.frequencyST) {
                    visitOrder = {
                        discipline: "ST",
                        frequency: patientFrequency.frequencyST,
                        effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                    };
                    this.visitOrders.push(visitOrder);
                }

                this.visitOrders = this.visitOrders.reduce((acc, curr) => {
                    const existingEntry = acc.find((item) => item.discipline === curr.discipline);
                    if (existingEntry) {
                        existingEntry.frequency += ` \n${curr.frequency}`;
                        existingEntry.effectiveDate += ` \n${curr.effectiveDate}`;
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, []);

                return this.visitOrders;
            });
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Frequency List Failed`,
                message: `Fetching Frequency List Operation Failed, Please Contact Axxess if issue still persists.`
            });
        }
    }

    private async getPatient(term: string) {
        const patientPayload: IPatientByAgencyRequest = {
            agencyId: this.visitDetails.AgencyId,
            application: this.visitDetails.Application,
            searchTerm: term,
            page: 1,
            pageLength: 20
        };
        this.patients = await this._patientService.getPatientsByAgency(patientPayload);

        return this.patients?.map((item) => {
            return {
                name: `${item.PatientName.FirstName} ${item.PatientName.LastName}`,
                value: item.Id
            };
        });
    }

    public visitDatesChanged() {
        if (this.visitDates) this.newVisitDetails.visitDates = this.visitDates.split(", ");
        else this.newVisitDetails.visitDates = [];
        this.checkVisitDateValidation();
    }

    public checkVisitDateValidation() {
        if (this.newVisitDetails.visitDates.length > 0) {
            this.isDateTouched = true;
            this.showVisitDateValidation = false;
        } else {
            this.showVisitDateValidation = true;
        }
    }

    public async scheduleVisit(saveType: string) {
        const res = await this.controller.validate();
        this.isDateTouched = true;
        this.checkVisitDateValidation();
        if (!res.valid || this.showVisitDateValidation) {
            return;
        }
        if (!!this.newVisitDetails) {
            try {
                this.isSaving = true;
                this.newVisitDetails.userId = this.visitDetails.UserId;
                this.newVisitDetails.patientId = this.visitDetails.PatientId;
                this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
                this.newVisitDetails.application = this.visitDetails.Application;
                const newVisitDetailsResp: INewVisitDetailsResponse = await this._taskService.scheduleVisitHH(
                    this.newVisitDetails
                );
                const isWarning = newVisitDetailsResp.Message.includes("Warning");
                const respMessage = {
                    title: `Visit Scheduled`,
                    message: newVisitDetailsResp.Message
                };
                if (isWarning) {
                    this._toastrService.warning(respMessage);
                } else {
                    this._toastrService.success(respMessage);
                }

                if (saveType == SaveEnum.SaveAndExit) {
                    // trigger callback
                    this.ok();
                } else {
                    this.newVisitDetails = new ScheduleVisitHomeHealthModel();
                    this.visitDates = "";
                    this.isDateTouched = false;
                    this.patientId = null;
                    $("td").removeClass("active");
                }
            } catch (e) {
                this._toastrService.error({
                    title: `Visit Scheduled Failed`,
                    message: `Scheduling Visit Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isSaving = false;
            }
        }
    }
}
