import EmployeeCalendar from "./EmployeeCalendar/EmployeeCalendar.vue";
import PatientCalendar from "./PatientCalendar/PatientCalendar.vue";

const routes = [
    {
        path: "/axxess-central/calendar/employee",
        component: EmployeeCalendar,
        name: "employee",
        meta: {
            menuName: "Employee Calendar",
            navItem: "tabEmployee"
        }
    },
    {
        path: "/axxess-central/calendar/patient",
        component: PatientCalendar,
        name: "patient",
        meta: {
            menuName: "Patient Calendar",
            navItem: "tabPatient"
        }
    }
];

export default routes;
