import moment, { Moment } from "moment";

export class Weather {
    public updateTooltip: boolean = true;
    public temp: number;
    public hi: number;
    public lo: number;
    public id: number;
    public date: Moment;
    public weatherDescription: string;
    public tooltip: string;

    public constructor(data: any) {
        let temperature: any = data.main ? data.main : data.temp ? data.temp : null;
        if (temperature) {
            this.temp = Math.round(temperature.temp);
            this.hi = temperature.max && Math.round(temperature.max);
            this.lo = temperature.min && Math.round(temperature.min);
        }
        this.id = data.weather[0].id;
        this.date = moment.unix(data.dt);
        this.initProps();
    }

    public updateHiLo(data: any, updateTooltip: boolean): void {
        this.hi = Math.round(data.hi);
        this.lo = Math.round(data.lo);
        this.updateTooltip = updateTooltip;
    }

    private initProps() {
        this.weatherDescription = this.getWeatherDescription();
        this.tooltip = this.getTooltip();
    }

    public getWeatherDescription(): string {
        let id: number = this.id;
        if (id >= 200 && id < 300) {
            return "Thunderstorm";
        }

        if (id >= 300 && id < 400) {
            return "Showers";
        }

        if (id >= 500 && id < 600) {
            return "Rain";
        }

        if ((id >= 600 && id < 700) || id === 903) {
            return "Snow";
        }

        if ((id >= 700 && id < 800) || id === 905) {
            return "Windy";
        }

        if (id === 800 || id === 801 || id === 904) {
            return "Sunny";
        }

        if (id >= 802 && id < 900) {
            return "Cloudy";
        }

        if (id === 900) {
            return "Tornado";
        }

        if (id === 901 || id === 902) {
            return "Hurricane";
        }

        if (id === 906) {
            return "Hail";
        }

        return "";
    }

    public getTooltip(): string {
        let tooltip: string = `
            <span class="font-size-md">${this.weatherDescription}</span>
            <span>${this.date.format("dddd MMM DD, YYYY")}</span>`;

        if (this.hi && this.lo && this.updateTooltip) {
            tooltip += `
                <span>High: ${this.hi}</span>
                <span>Low: ${this.lo}</span>`;
        }

        return `<div class="w-100 d-flex flex-column">${tooltip}</div>`;
    }
}
