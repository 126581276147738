import type { IPriorHospice } from "../vue-interfaces/i-prior-hospice";
import type { IAddress } from "../vue-interfaces/i-address";
import type { IPhoneNumber } from "../vue-interfaces/i-phone-number";
import { Address } from "./address";
import { PhoneNumber } from "./phone-number";

export class PriorHospice implements IPriorHospice {
    public name: string = "";
    public admissionDate: string = "";
    public transferDate: string = "";
    public benefitPeriodStartDate: string = "";
    public benefitPeriodEndDate: string = "";
    public daysUsed: number = null;
    public faceToFaceObtained: boolean = false;
    public transferHospiceAddress: IAddress = new Address();
    public contactPersonFirstName: string = "";
    public contactPersonLastName: string = "";
    public contactPersonPhoneNumber: IPhoneNumber = new PhoneNumber();

    public constructor(data?: IPriorHospice) {
        if (!!data) {
            Object.assign(this, data);
            if (!!data.transferHospiceAddress) {
                this.transferHospiceAddress = new Address(data.transferHospiceAddress);
            }
            if (!!data.contactPersonPhoneNumber) {
                this.contactPersonPhoneNumber = new PhoneNumber(data.contactPersonPhoneNumber);
            }
        }
    }
}
