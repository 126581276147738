import mitt, { Emitter } from "mitt";
import { ILocation } from "../resources-vue/vue-interfaces/i-location";
import {
    IHomeHealthReferralExtension,
    IHomeHealthReferralOld
} from "../resources-vue/vue-interfaces/i-homehealth-referral";
import { Marker } from "../resources-vue/vue-models/marker.model";
import { ITask } from "../resources-vue/vue-interfaces/i-task";
import { INotification } from "../interfaces/messaging-service/i-notification";
import { LoadNavbarNotificationsEvent } from "../enums/NotificationsHubEvents";

type Events = {
    "app:location:success": ILocation;
    "app:agency:loaded": void;
    addressChangedforPrimary: IHomeHealthReferralExtension | IHomeHealthReferralOld;
    "marker:created": Marker;
    "marker:task:changed": ITask;
    "marker:info:close": number;
    "app:zip:changed": string;
    "app:zip:canceled": boolean;
    conversationSelected: any;
    loadConversation: any;
    "message:new": any;
    highlightPatientNameMarker: any;
    highlightPatientLocationMarker: any;
    highlightEmployeeMarker: any;
    roomCreated: string;
    joinRoomClicked: string;
    dialogOpened: boolean;
    messageSent: boolean,
    LoadConversationMessages: INotification,
    LoadConversationList: INotification,
    LoadNavbarNotifications: LoadNavbarNotificationsEvent,
    joinMeeting: string,
    newMessage: string,
    jitsiMessage: INotification
};

const emitter: Emitter<Events> = mitt<Events>();

export default emitter;
