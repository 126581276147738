import { Container } from "aurelia-dependency-injection";
import AuthService from "../services/auth.service";
import moment from "moment";
import idleTimeout from "../resources-vue/vue-dialogs/IdleTimeout/IdleTimeout.vue";

export default class {
    public _auth: AuthService;
    public _allowedIdleTime: number = 10;
    public idleWarningTimeInMinutes: number = 2;
    public isIdleWarningOpen: boolean = false;
    public idleInterval: any;
    public logoutTimer: any;
    public dialogRef: any;

    public constructor() {
        this._auth = Container.instance.get(AuthService);
        this.resetIdleTime();
    }

    public async timer(dialogReference: any) {
        this.idleInterval = setInterval(async () => {
            await this.checkIdleTime(dialogReference);
        }, 1000); // 1 second

        $(window)
            .mousemove(
                this.debounce(() => {
                    this.resetIdleExpiryTime();
                }, 500)
            )

            .click(
                this.debounce(() => {
                    this.resetIdleExpiryTime();
                }, 500)
            );
    }

    private async checkIdleTime(dialogReference: any) {
        let idleTime = JSON.parse(localStorage.getItem("idleExpiry"));
        let a = moment(idleTime);
        let b = moment();
        let difference = a.diff(b, "s");

        if (difference > 0) {
            if (difference <= this.idleWarningTimeInMinutes * 60) {
                if (!this.isIdleWarningOpen) {
                    this.isIdleWarningOpen = true;

                    this.dialogRef = dialogReference.open(idleTimeout, {
                        props: {
                            modal: true,
                            showHeader: false
                        },
                        data: {
                            idleWarningTimeInMinutes: this.idleWarningTimeInMinutes
                        },
                        onClose: (options: any) => {
                            if (options.data === "success") {
                                this.resetIdleTime();
                                this.isIdleWarningOpen = false;
                            } else {
                                this._auth.logout();
                            }
                        }
                    });
                }
            } else {
                this.isIdleWarningOpen = false;
            }
        }
    }

    public debounce(fn: () => void, delay: number): () => void {
        this.logoutTimer = null;
        return () => {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = setTimeout(() => {
                fn();
            }, delay);
        };
    }

    public resetIdleTime() {
        localStorage.setItem("idleExpiry", JSON.stringify(moment().add(this._allowedIdleTime, "m")));
    }

    public resetIdleExpiryTime() {
        let idleTime = JSON.parse(localStorage.getItem("idleExpiry"));
        let a = moment(idleTime);
        let b = moment();
        let difference = a.diff(b, "s");

        if (difference > this.idleWarningTimeInMinutes * 60) {
            this.resetIdleTime();
        }
    }
}
