import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PaymentSourcesEnum {
    MedicareTraditional = 1 as any,
    MedicareManaged = 2 as any,
    MedicaidTraditional = 4 as any,
    MedicaidManaged = 8 as any,
    OtherGovernment = 16 as any,
    PrivateInsurance = 32 as any,
    PrivateManagedCare = 64 as any,
    SelfPay = 128 as any,
    NoPayerSource = 256 as any,
    Unknown = 512 as any,
    Other = 1024 as any
}
class PaymentSourcesClass extends EnumWithDescription<PaymentSourcesEnum> {
    public readonly enum = PaymentSourcesEnum;
    public readonly enumName: string = "PaymentSourcesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PaymentSourcesEnum>> = new Map([
        [
            "MedicareTraditional",
            {
                value: PaymentSourcesEnum.MedicareTraditional,
                name: "Medicare (traditional fee-for-service)"
            }
        ],
        [
            "MedicareManaged",
            {
                value: PaymentSourcesEnum.MedicareManaged,
                name: "Medicare (managed care/Part C/Medicare Advantage)"
            }
        ],
        [
            "MedicaidTraditional",
            {
                value: PaymentSourcesEnum.MedicaidTraditional,
                name: "Medicaid (traditional fee-for-service)"
            }
        ],
        [
            "MedicaidManaged",
            {
                value: PaymentSourcesEnum.MedicaidManaged,
                name: "Medicaid (managed care)"
            }
        ],
        [
            "OtherGovernment",
            {
                value: PaymentSourcesEnum.OtherGovernment,
                name: "Other government (e.g., TRICARE, VA, etc.)"
            }
        ],
        [
            "PrivateInsurance",
            {
                value: PaymentSourcesEnum.PrivateInsurance,
                name: "Private Insurance/Medigap"
            }
        ],
        [
            "PrivateManagedCare",
            {
                value: PaymentSourcesEnum.PrivateManagedCare,
                name: "Private managed care"
            }
        ],
        [
            "SelfPay",
            {
                value: PaymentSourcesEnum.SelfPay,
                name: "Self-pay"
            }
        ],
        [
            "NoPayerSource",
            {
                value: PaymentSourcesEnum.NoPayerSource,
                name: "No payer source"
            }
        ],
        [
            "Unknown",
            {
                value: PaymentSourcesEnum.Unknown,
                name: "Unknown"
            }
        ],
        [
            "Other",
            {
                value: PaymentSourcesEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const PaymentSources = new PaymentSourcesClass();
