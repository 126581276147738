import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import { Address } from "../../vue-models/address";
import { LookupService } from "../../../services/lookup-service";
import { formatZipCode } from "../../../common/vue-helpers/modifiers/value-modifier";
import { IUpdatedPlacesAddress } from "../../../resources-vue/vue-interfaces/i-places-autocomplete";

import StateTypeahead from "../StateTypeahead/StateTypeahead.vue";
import PlacesAutocomplete from "../PlacesAutocomplete/PlacesAutocomplete.vue";
import CountryTypeahead from "../CountryTypeahead/CountryTypeahead.vue";

export default defineComponent({
    components: {
        StateTypeahead,
        PlacesAutocomplete,
        CountryTypeahead
    },

    validations() {
        return {
            address: {
                country: {
                    required: requiredIf(() => {
                        return !this.disableInputs && this.enableValidation;
                    })
                },
                addressLine1: {
                    required: requiredIf(() => {
                        return !this.disableInputs && this.enableValidation;
                    })
                },
                city: {
                    required: requiredIf(() => {
                        return !this.disableInputs && this.enableValidation;
                    })
                },
                state: {
                    required: requiredIf(() => {
                        return !this.disableInputs && this.enableValidation;
                    })
                },
                zipCode: {
                    required: requiredIf(() => {
                        return !this.disableInputs && this.enableValidation;
                    })
                },
                county: {
                    required: requiredIf(() => {
                        return this.showCounty && !this.disableInputs && this.enableValidation && this.isUnitedStates;
                    })
                }
            }
        };
    },

    props: {
        address: {
            default: new Address()
        },
        updateAddress: {
            type: Function
        },
        disableInputs: {
            type: Boolean,
            default: false
        },
        showCounty: {
            type: Boolean,
            default: true
        },
        enableValidation: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedState: null,
            selectedCountry: null,
            _lookupService: null,
            _autoCompleteAddress: new Address(),
            unitedStatesCountryCode: "",
            autoCompleteCountry: "",
            addressValidation: null,
            stateValidation: null,
            formatZipCode,
            v$: useVuelidate()
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
    },

    computed: {
        isUnitedStates() {
            return this.selectedCountry?.name.toLowerCase() === "united states of america";
        }
    },

    async beforeMount() {
        await this.initCountry();
        if (this.enableValidation) {
            this.addressValidation = {
                required: true,
                displayName: "Address Line 1",
                matches: true
            };
            this.stateValidation = {
                required: true,
                displayName: "State"
            };
            this.updateAddress?.(new Address(this.address));
        }
    },

    watch: {
        address: {
            handler(newValue) {
                this._autoCompleteAddress = Object.assign({}, newValue);
            },
            deep: true
        },
        selectedState(newValue, oldValue) {
            if (newValue == oldValue) {
                return;
            }
            if (!!this.selectedState) {
                this.address.state = this.selectedState.value;
            } else {
                this.address.state = "";
            }
        }
    },

    methods: {
        async initCountry() {
            this.unitedStatesCountryCode = await this._lookupService.getUnitedStatesCode();
            if (!this.address.country) {
                this.address.country = this.unitedStatesCountryCode;
            }
        },

        placesChangedCallBack(params: IUpdatedPlacesAddress) {
            if (!params) {
                return;
            }
            Object.assign(this._autoCompleteAddress, params);
            this.address.addressLine1 = params.addressLine1;
            this.address.city = params.city;
            this.address.county = params.county;
            this.address.state = params.state;
            this.address.zipCode = params.zipCode;
            this.address.latitude = params.latitude;
            this.address.longitude = params.longitude;
            this.address.isValidated = true;
            this.address.isBypassed = false;
        },

        updateSelectedCountry(value: any) {
            this.selectedCountry = value;
            let temp = { ...this.address };
            if (!!value) {
                temp.country = this.selectedCountry.value;
            } else {
                temp.country = "";
            }
            this.updateAddress?.(temp);
        }
    }
});
