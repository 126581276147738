import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { Videos } from "../../../../services/video.service";

import CardTemplate from "../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";

export default defineComponent({
    components: { CardTemplate },

    data() {
        return {
            videoData: null,
            isLoading: true,
            hasError: false,
            videos: null as Videos
        };
    },

    created() {
        this.videos = Container.instance.get(Videos);
        this.getVideo();
    },

    methods: {
        async getVideo() {
            try {
                this.hasError = false;
                this.videoData = await this.videos?.getVideo();
            } catch (e) {
                console.error(e);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
