import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import {
    AgencyRoles,
    ApplicationEnum,
    InternalReferralSource,
    PalliativeSourceOfReferral,
    SourceOfReferral
} from "../../../../../enums/enums";
import { EnumsService } from "../../../../../services/enums.service";
import { UserService } from "../../../../../services/user-service";
import { VendorsService } from "../../../../../services/vendor-service";
import { PhysiciansService } from "../../../../../services/physician-service";
import { EnumMap } from "../../../../../common/enum-map";
import { IGetUsersByRoleQuery, IUserResult } from "../../../../../resources-vue/vue-interfaces/i-user";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { formatName } from "../../../../../common/format-name";
import { IGetVendorSlim } from "../../../../../resources-vue/vue-interfaces/i-vendor";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";

export default defineComponent({
    components: {
        TypeaheadInput,
        CommentInput
    },

    props: {
        providerId: {
            type: String,
            default: ""
        }
    },

    setup() {
        const storeReferral = useCreateReferralHosPal();
        const referral: any = storeReferral.referral;
        return { referral };
    },

    data() {
        return {
            _enumService: null,
            _usersService: null,
            _vendorsService: null,
            _physicianService: null,
            _relationshipsEnumMap: null, // will be removed
            _subscription: null,
            _hospiceReferralSourceOrder: ["Physician", "Facility", "Internal", "Other"],
            _palliativeReferralSourceOrder: ["Provider", "Facility", "Internal", "Other"],
            communityLiaison: null,
            internalReferralSource: null,
            physician: null,
            facility: null,
            ignoreFacilityChange: false,
            ignorePhysicianChange: false,
            referralSources: [],
            relationships: [],
            showRelationshipDescription: false,
            InternalReferralSource: Array.from(InternalReferralSource.enumWithDescriptions.values()),
            SourceOfReferrals: Array.from(SourceOfReferral.enumWithDescriptions.values()),
            AgencyRoles: Array.from(AgencyRoles.enumWithDescriptions.values()),
            PalliativeSourceOfReferrals: Array.from(PalliativeSourceOfReferral.enumWithDescriptions.values()),
            commentInputClass: { input: "w-35" },
            roles: null
        };
    },

    async created() {
        this.roles = this.AgencyRoles;
        this._enumService = Container.instance.get(EnumsService);
        this._usersService = Container.instance.get(UserService);
        this._vendorsService = Container.instance.get(VendorsService);
        this._physicianService = Container.instance.get(PhysiciansService);
        await this.initOptions();
        if (!!this.referral) {
            this.initValuesFromModel();
        }
    },

    computed: {
        isApplicationPalliative(): boolean {
            return this.referral.application == ApplicationEnum.AxxessPalliative;
        }
    },

    watch: {
        async facility() {
            if (!this.facility || this.ignoreFacilityChange) {
                this.ignoreFacilityChange = false;
                return;
            }
            this.referral.facilityReferralSourceId = this.facility.value;
            this.referral.facilityReferralSourceName = this.facility.name;
            if (!this.facility.value) {
                return;
            }
            const facilityPayload = {
                id: this.facility.value,
                accountId: this.referral.accountId,
                application: this.referral.application
            };
            let selectedFacility = await this._vendorsService.getVendorById(facilityPayload);
            this.communityLiaison = {
                name: selectedFacility.communityLiaisonName,
                value: selectedFacility.communityLiaisonId
            };
        },
        physician() {
            if (!this.physician || this.ignorePhysicianChange) {
                this.ignorePhysicianChange = false;
                return;
            }
            let npiIndex = this.physician?.name?.indexOf(" - NPI");
            if (npiIndex > 0 && this.physician?.name) {
                let name = this.physician.name?.slice(0, npiIndex + 1);
                this.referral.physicianReferralSourceName = name;
            } else {
                this.referral.physicianReferralSourceName = this.physician.name;
            }
            this.autofillCommunityLiaison(this.physician.value);
        },
        communityLiaison() {
            if (this.communityLiaison) {
                this.referral.communityLiaisonId = this.communityLiaison.value as string;
                this.referral.communityLiaisonName = this.communityLiaison.name as string;
            } else {
                this.referral.communityLiaisonId = null;
                this.referral.communityLiaisonName = null;
            }
        },
        internalReferralSource() {
            if (this.internalReferralSource) {
                this.referral.internalReferralSourceId = this.internalReferralSource.value;
                this.referral.internalReferralSourceName = this.internalReferralSource.name;
            }
        }
    },

    methods: {
        async initOptions() {
            this.getSourceOfReferral();
            this.relationships = await this._enumService.getReferralSourceRelationship(); // update once code is merged to main branch
            this._relationshipsEnumMap = new EnumMap(this.relationships); // this too
        },
        async getSourceOfReferral() {
            if (this.referral.application == ApplicationEnum.AxxessPalliative) {
                this.referralSources = this._palliativeReferralSourceOrder.map((referralSource) => {
                    return PalliativeSourceOfReferral.enumWithDescriptions.get(referralSource);
                });
            } else {
                this.referralSources = this._hospiceReferralSourceOrder.map((referralSource) => {
                    return SourceOfReferral.enumWithDescriptions.get(referralSource);
                });
            }
        },
        initValuesFromModel() {
            if (!!this.referral) {
                this.handleRelationshipSelection();
                if (!this.referral.sourceOfReferral && this.referralSources.length > 0) {
                    this.referral.sourceOfReferral = this.referralSources[0].value as number;
                }
                if (this.referral.physicianReferralSourceId && this.referral.physicianReferralSourceName) {
                    this.ignorePhysicianChange = true;
                    this.physician = {
                        name: this.referral.physicianReferralSourceName,
                        value: this.referral.physicianReferralSourceId
                    };
                }
                if (this.referral.facilityReferralSourceId && this.referral.facilityReferralSourceName) {
                    this.ignoreFacilityChange = true;
                    this.facility = {
                        name: this.referral.facilityReferralSourceName,
                        value: this.referral.facilityReferralSourceId
                    };
                }
                if (this.referral.internalReferralSourceId && this.referral.internalReferralSourceName) {
                    this.internalReferralSource = {
                        name: this.referral.internalReferralSourceName,
                        value: this.referral.internalReferralSourceId
                    };
                }
                if (this.referral.communityLiaisonId && this.referral.communityLiaisonName) {
                    this.communityLiaison = {
                        name: this.referral.communityLiaisonName,
                        value: this.referral.communityLiaisonId
                    };
                }
            }
        },
        handleRelationshipSelection() {
            if (!!this.referral.otherReferralSourceRelationship) {
                // update dynamically later
                let enumName = this._relationshipsEnumMap.getEnumName(this.referral.otherReferralSourceRelationship);
                this.showRelationshipDescription = this._relationshipsEnumMap.checkEnumName(
                    enumName.toString(),
                    "other"
                );
            } else {
                this.showRelationshipDescription = false;
            }
        },
        async getUsersByRole(filter: string, limit: number) {
            return this.userToTypeahead(
                await this._usersService.getUsersByRole(this.getUserQuery("Community Liaison Officer", true, filter))
            );
        },
        userToTypeahead(users: IUserResult[]): ITypeaheadOptions[] {
            return users.map((user) => ({
                name: formatName(user.firstName, user.lastName),
                value: user.id
            }));
        },
        getUserQuery(userRole: string, include: boolean, filter: string): IGetUsersByRoleQuery {
            let roleCode = this.roles.filter((role: any) => role.name === userRole)[0].value;
            return {
                role: [roleCode] as number[],
                include: include,
                term: filter,
                providerIds: [this.providerId],
                application: this.referral.application
            };
        },
        async getPhysicianList(filter: string, limit: number) {
            let data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                providerIds: [this.providerId],
                application: this.referral.application,
                accountId: this.referral.accountId
            });
            return data.items?.map((item: any) => {
                let splitName = item.name.split(" ");
                return {
                    name: formatName(splitName[0], splitName[1]) + (item.npi ? ` - NPI: ${item.npi}` : ""),
                    value: item.id
                };
            });
        },
        async getVendorList(filter: string, limit: number) {
            const payload = {
                accountId: this.referral.accountId,
                name: filter,
                providerIds: [this.providerId],
                application: this.referral.application
            };
            let facilityList = await this._vendorsService.getVendorByTerm(payload);
            return this.facilityToTypeahead(facilityList);
        },
        sourceOfReferralUpdated() {
            // Facility Referral Source
            this.facility = {
                name: null,
                value: null
            };
            this.referral.facilityReferralSourceContactName = null;
            this.communityLiaison = {
                name: null,
                value: null
            };
            // Physician Referral Source
            this.physician = {
                name: null,
                value: null
            };
            this.referral.physicianReferralSourceContactName = null;
            this.referral.sameAsAttendingPhysician = false;
            // Other Referral Source
            this.referral.otherReferralSourceName = null;
            this.referral.otherReferralSourceRelationship = null;
            this.referral.otherReferralSourceRelationshipDescription = null;
            // Internal Referral Source
            this.internalReferralSource = {
                name: null,
                value: null
            };
        },
        async autofillCommunityLiaison(physicianId: string) {
            if (!physicianId) {
                return;
            }
            const payload = {
                physicianId,
                application: this.referral.application,
                accountId: this.referral.accountId
            };
            let physician = await this._physicianService.getPhysicianById(payload);
            this.communityLiaison = {
                name: physician?.communityLiaisonName,
                value: physician?.communityLiaisonId
            };
        },
        facilityToTypeahead(facilities: IGetVendorSlim[]): ITypeaheadOptions[] {
            return facilities.map((facility) => ({
                name: facility.name,
                value: facility.id
            }));
        },
        resultUpdatedAppended(value: string) {
            this.referral.comments.concat(value);
        },
        resultUpdated(value: string) {
            this.referral.comments = value;
        },
        updateFacility(newValue: any) {
            this.facility = newValue;
        },
        updateCommunityLiaison(newValue: any) {
            this.communityLiaison = newValue;
        },
        updatePhysician(newValue: any) {
            this.physician = newValue;
        },
        updateInternalReferralSource(newValue: any) {
            this.internalReferralSource = newValue;
        }
    }
});
