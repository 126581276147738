import { defineComponent } from "vue";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TemplateInput from "../../../../../resources-vue/vue-custom-elements/TemplateInput/TemplateInput.vue";

export default defineComponent({
    components: {
        FormSection,
        TemplateInput
    },

    setup() {
        const storeReferral = useEditReferralHH();
        const application = storeReferral.currentApplication;
        const referralOptions = storeReferral.referralOptions;
        const templates = storeReferral.referralOptions.template;
        const accountId = storeReferral.accountId;
        const referral = storeReferral.referral;
        return { application, referralOptions, templates, accountId, referral };
    },

    methods: {
        handleResultUpdated(value: string) {
            this.referral.EmergencyPreparedness.EmergencyPreparednessInfo = value;
        }
    }
});
