import { computed, defineComponent, reactive, ref } from "vue";
import { Container } from "aurelia-dependency-injection";

import { MessageService } from "../../../../../services/message-service";
import { IConversationUser } from "./../../../../../resources-vue/vue-interfaces/messaging-service/i-message";
import { UserManager } from "oidc-client";
import { ICreateRoomRequest, IMeetingParticipant } from "../../../../../interfaces/messaging-service/i-message";
import eventBus from "../../../../../utilities/eventBus";
import { mapActions } from "pinia";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { jitsiRoomHandler } from "../../../../../utilities/jitsiRoomHandler";

export enum MeetingTypeEnum {
    Audio = "Audio",
    Video = "Video"
}

export default defineComponent({
    props: {
        users: {
            type: Array<IConversationUser>,
            default: () => [] as IConversationUser[]
        },
        roomNameTemplate: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            _messageService: null,
            dialogRef: ref(null),
            MeetingType: MeetingTypeEnum,
            _userManager: null,
            visible: false,
            dialogOpened: false
        };
    },

    async created() {
        this._messageService = Container.instance.get(MessageService);
        this._userManager = Container.instance.get(UserManager);
        eventBus.on("joinRoomClicked", this.handleJoinRoom);
        eventBus.on("dialogOpened", this.handleJitsiDialogOpened);
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef.value)
        };
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        generateRoomName() {
            return `${this.roomNameTemplate}-${Date.now()}`;
        },
        handleJitsiDialogOpened(isJitsiOpen: boolean) {
            this.dialogOpened = isJitsiOpen
        },

        async startMeeting(meetingType: MeetingTypeEnum) {
            /** Create a reactive object to store dialog data */
            const meetingParticipants: IMeetingParticipant[] = this.users.map((userDetail: IConversationUser) => ({
                firstName: userDetail.firstName,
                lastName: userDetail.lastName,
                subjectId: userDetail.subjectId,
                email: userDetail.email,
                isRoomOwner: false
            }));

            const user = await this._userManager.getUser();

            const meetingOwner = {
                firstName: user.profile.given_name,
                lastName: user.profile.family_name,
                subjectId: user.profile.sub,
                email: user.profile.email,
                isRoomOwner: true
            };

            const dialogData = reactive({
                roomInfo: null,
                users: [...meetingParticipants, meetingOwner],
                participants: [],
                meetingType,
                loading: true
            });

            const today = new Date();
            const startTime = today.toISOString();
            const twoHoursLater = new Date(today.getTime() + 2 * 60 * 60 * 1000);
            const endTime = twoHoursLater.toISOString();
            const differenceInMilliseconds = twoHoursLater.getTime() - today.getTime();
            const durationInSeconds = differenceInMilliseconds / 1000;

            const payload: ICreateRoomRequest = {
                roomName: this.generateRoomName(),
                startTime,
                endTime,
                durationInSeconds,
                participants: [...meetingParticipants, meetingOwner]
            };
            this.showLoading(true);
            const response = await this._messageService.createRoom(payload);

            if (response.id) {
                await jitsiRoomHandler(this, response.id, this.users)
                eventBus.emit("roomCreated", response.id);
            }
        },

        async handleJoinRoom(roomId: string) {
            await jitsiRoomHandler(this, roomId, this.users)
        }
    },

    beforeUnmount() {
        eventBus.off("joinRoomClicked", this.handleJoinRoom);
        eventBus.off("dialogOpened", this.handleJitsiDialogOpened);
    }
});
