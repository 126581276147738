import { defineStore } from "pinia";
import { ApplicationEnum } from "../../../../enums/enums";
import { HospiceReferral } from "../../../../resources-vue/vue-models/hospice-referral";
import { SET_REFERRAL, SET_CONTACT, SET_COUNTRY_LIST } from "./types";
import { IReferralState } from "./i-state";
import { AuthorizedContact } from "../../../../resources-vue/vue-models/authorized-contact";

export const useCreateReferralHosPal = defineStore("referral-hos-pal", {
    state: () =>
        ({
            referral: new HospiceReferral(ApplicationEnum.AxxessHospiceFE),
            countryList: []
        } as IReferralState),
    actions: {
        [SET_REFERRAL](payload: any) {
            this.referral = payload;
        },
        [SET_COUNTRY_LIST](payload: Array<any>) {
            this.countryList = payload;
        },
        [SET_CONTACT](payload: any) {
            this.contact = payload;
        }
    }
});
