import { valueConverter } from "aurelia-framework";
import * as moment from "moment";

@valueConverter("formatTime")
export class FormatTime {
    public toView(value: string, format = "hh:mm A"): string {
        if (value) {
            return moment(value).format(format);
        } else {
            return null;
        }
    }
}
