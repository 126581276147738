import "./patient-list.scss";
import { bindable, customElement, autoinject } from "aurelia-framework";

import { UserService } from "../../services/user-service";
import type { IGetUserByName } from "../../interfaces/i-user";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { NavigationService } from "../../services/navigation.service";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../enums/enums";
import type { INavigateRequestParams, INavigateResponse } from "../../resources-vue/vue-interfaces/i-navigation";
import config from "../../common/config";

@customElement("employee-list")
@autoinject()
export class EmployeeList {
    @bindable public visible: boolean = false;
    private _userService: UserService;
    private _navigationService: NavigationService;
    private _permissionManager: PermissionManager;
    public isLoading: boolean = null;
    public hasError: boolean = false;
    public searchQuery: string = "";
    public employeeList: IGetUserByName[] = [];
    public isNavigationUnderProcess: boolean = false;

    public constructor(
        userService: UserService,
        navigationService: NavigationService,
        permissionManager: PermissionManager
    ) {
        this._userService = userService;
        this._navigationService = navigationService;
        this._permissionManager = permissionManager;
    }

    public async attached() {
        this.resetFields();
    }

    public closeEmployees(): void {
        this.visible = false;
        this.resetFields();
    }

    private get disableForm() {
        return this.searchQuery.trim().length < 3 || this.isLoading;
    }

    private submitForm() {
        if (!this.disableForm) {
            this.searchEmployee();
        }
    }

    public resetFields() {
        this.employeeList = [];
        this.hasError = false;
        this.isLoading = null;
        this.searchQuery = "";
    }

    public async handleNavigation(employee: IGetUserByName) {
        if (this.isNavigationUnderProcess) {
            return;
        } else {
            this.isNavigationUnderProcess = true;
        }

        const analyticsCategory = "Central-Clinician-Navigation";
        const path = `/employees/employee-center/${employee.id}?accountId=${employee.agencyId}`;
        let action = "";
        let baseUrl = "";

        if (employee.application === ApplicationEnum.AxxessHospiceFE) {
            baseUrl = `${config.hospiceUrl}${path}`;
            action = "AxxessHospice";
        } else if (employee.application == ApplicationEnum.AxxessPalliative) {
            baseUrl = `${config.palliativeUrl}${path}`;
            action = "AxxessPaliative";
        } else if ([ApplicationEnum.AgencyCore, ApplicationEnum.HomeCare].includes(employee.application)) {
            const redirectUrl = await this.getNavigation(employee);
            if (redirectUrl) {
                baseUrl = redirectUrl;
                action = employee.application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
            }
        }

        const redirectResponse = {
            redirectUrl: baseUrl,
            isSuccessful: true
        };
        this.isNavigationUnderProcess = false;
        if (action && baseUrl) {
            this._navigationService.redirect(redirectResponse, {
                category: analyticsCategory,
                action
            });
        }
    }

    public async getNavigation(employee: IGetUserByName) {
        let response: INavigateResponse;
        const isClinician = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canView,
            employee.agencyId
        );
        const navParams: INavigateRequestParams = {
            applicationId: employee.application,
            agencyId: employee.agencyId,
            employeeId: employee.id,
            isClinician
        };

        try {
            response = await this._navigationService.navigateToEmployee(navParams);
        } catch (e) {
            console.error(e);
        }
        return response?.redirectUrl || null;
    }

    public getStatusText(status: string): string {
        if (!status) {
            return "";
        }
        const result = status.toLowerCase();
        if (result.includes("pending")) {
            return "Pending";
        } else if (result.includes("terminate")) {
            return "Terminated";
        }
        return status;
    }

    public async searchEmployee() {
        if (this.searchQuery.trim().length < 3) {
            return;
        }
        this.employeeList = [];
        this.isLoading = true;
        this.hasError = false;
        try {
            const data: IGetUserByName[] = await this._userService.getUserByName({ term: this.searchQuery.trim() });
            // this is-loading condition is to check if the user has closed the search box or not till request completes
            if (this.isLoading) {
                this.employeeList = data;
            }
        } catch (error) {
            if (this.isLoading) {
                this.hasError = true;
            }
            throw new Error(error);
        } finally {
            if (this.isLoading) {
                this.isLoading = false;
            }
        }
    }
}
