
import { defineComponent, onMounted, ref, watch, onBeforeUnmount, computed } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { Container } from "aurelia-dependency-injection";
import { SignalRService } from "../../../../services/signal-r-service";
import { MessageService } from "../../../../services/message-service";

import { SignalRHubs } from "../../../../enums/SignalRHubs";
import config from "../../../../common/config";
import { NotificationsHubEvents } from "../../../../enums/NotificationsHubEvents";
import eventBus from "../../../../utilities/eventBus";
import { INotification } from "../../../../interfaces/messaging-service/i-notification";
import { CallEventEnum } from "../../../../enums/CallEventEnum";
import callRingToneSrc from '../../../../assets/audio/call-ringtone.mp3';
import notificationSoundSrc from '../../../../assets/audio/notification.mp3';
import { ISendMessageQuery } from "../../../../resources-vue/vue-interfaces/messaging-service/i-message";
import { NotificationTypeEnum } from "../../../../enums/NotificationTypeEnum";
import { truncateText } from "../../../../utilities/truncateString";
import CallNotification from "./call-notification.vue";



const NOTIFICATION_TIMEOUT = 7000;

export default defineComponent({
    name: "Notification",
    components: {
        CallNotification
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const notifications = ref<INotification[]>([]);
        const signalRService = ref<SignalRService | null>(null);
        const showAudio = ref(false);
        const audio = ref<HTMLAudioElement | null>(null);
        const hasJoinMeetingBtn = ref(false);
        const mutationObserver = ref<MutationObserver | null>(null);
        const messageService = ref<MessageService | null>(null)
        const chatType = ref<NotificationTypeEnum | null>(null)

        watch(notifications, (newNotifications) => {
            if (newNotifications.length < 1) {
                stopAudio();
            }
        }, { deep: true });


        const scheduleNotificationRemoval = (_conversationId: string, fromPopup: boolean = false) => {
            stopAudio();
            setTimeout(() => {
                const index = notifications.value.findIndex(notification => notification.conversationId === _conversationId);
                if (index > -1) {
                    notifications.value.splice(index, 1);
                }
            }, fromPopup ? 0 : NOTIFICATION_TIMEOUT);
        };

        const createAndClickButton = async () => {
            return new Promise<void>((resolve) => {
                const newButton = document.createElement('button');
                newButton.style.display = 'none';
                document.body.appendChild(newButton);
                newButton.addEventListener('click', () => {
                    resolve();
                });
                newButton.click();
                document.body.removeChild(newButton);
            });
        };

        const navigateToConversation = (notification: INotification) => {
            router.push(`/central-messaging/clinicians/conversation/${notification.conversationId}`);
            scheduleNotificationRemoval(notification.conversationId, true);
        };

        const playAudioMultipleTimes = async (times: number) => {
            await createAndClickButton();
            let playCount = 0;
            audio.value = new Audio(callRingToneSrc);

            audio.value.addEventListener('ended', async () => {
                playCount++;
                if (playCount < times) {
                    audio.value!.currentTime = 0;
                    audio.value!.play().catch(console.error);
                } else {
                    handleMissedCall()
                    stopAudio();
                }
            });

            audio.value.play().catch(console.error);
        };

        const playNotificationSound = async (volume: number = 1.0) => {
            await createAndClickButton();
            const notificationAudio = new Audio(notificationSoundSrc);
            notificationAudio.volume = volume;
            notificationAudio.play().catch(console.error);
        };

        const stopAudio = () => {
            if (audio.value) {
                audio.value.pause();
                audio.value.currentTime = 0;
            }
        };

        const initialiseSignalR = async () => {
            signalRService.value = Container.instance.get(SignalRService);

            await signalRService.value.addHub(SignalRHubs.NotificationsHub, `${config.messagingServiceServerURL}notification`);
            console.info(`Starting ${SignalRHubs.NotificationsHub}...`);
            await signalRService.value.startHub(SignalRHubs.NotificationsHub);
            console.info(`${SignalRHubs.NotificationsHub} started successfully.`);
        }

        const  extractRoomId = (htmlString: string): string | null => {
            const regex = /data-id=['"]([^'"]*)['"]/;
            const match = htmlString.match(regex);
            
            return match ? match[1] : null;
        }

        const setUpNotificationEvents = async () => {
            try {
                signalRService.value.on(
                    SignalRHubs.NotificationsHub,
                    NotificationsHubEvents.ReceiveMessage,
                    async (notification: INotification) => {
                        console.log("Notification => ", notification)
                        eventBus.emit(NotificationsHubEvents.LoadConversationList, notification);

                        if (!notification || !notification.conversationId) {
                            return;
                        }

                        let routeIncludesConversationId = route.fullPath?.includes(notification.conversationId);

                        if (!routeIncludesConversationId && typeof notification === 'object' && !notification.messageBody.includes(CallEventEnum.JoinMeeting)) {
                            notification.messageBody = truncateText(notification.messageBody, 100)
                            notification.chatType = NotificationTypeEnum.Text
                            chatType.value = NotificationTypeEnum.Text
                            notifications.value.push(notification);
                            notification.clicked = false;
                            if (!notification.messageBody || !notification.messageBody.includes(CallEventEnum.JoinMeeting)) {
                                await playNotificationSound();
                                scheduleNotificationRemoval(notification.conversationId);
                                hasJoinMeetingBtn.value = false;
                            }
                        }

                        if (notification.messageBody && notification.messageBody.includes(CallEventEnum.JoinMeeting)) {
                            const clonedNotification = { ...notification }
                            clonedNotification.roomId = extractRoomId(clonedNotification.messageBody)
                            clonedNotification.chatType = NotificationTypeEnum.Call
                            chatType.value = NotificationTypeEnum.Call
                            const newNotification = clonedNotification;
                            await playAudioMultipleTimes(3);
                            console.log("newNotification", newNotification)
                            notifications.value.push(newNotification);
                            hasJoinMeetingBtn.value = true;
                        }

                        if (routeIncludesConversationId) {
                            eventBus.emit(NotificationsHubEvents.LoadConversationMessages, notification);
                        }
                    }
                );
            } catch (error) {
                console.error("Error running SignalR service:", error);
            }
        };

        const handleCallButtonClick = (event: Event) => {
            let routePathIncludesCentralMessaging = route.fullPath?.includes("/central-messaging");

            stopAudio();
            const button = event.currentTarget as HTMLButtonElement;
            const roomId = button.getAttribute('data-id');
            if (roomId) {
                if (!routePathIncludesCentralMessaging) {
                    eventBus.emit("joinMeeting", roomId);
                } else {
                    eventBus.emit("joinRoomClicked", roomId);
                }
                notifications.value = [];
            }
        };

        const emitMessage = (message: string) => {
            eventBus.emit("newMessage", message);
        }

        const sendMessage = async (notification: INotification, messageQuery: ISendMessageQuery) => {
            try {
                if (!messageService.value) {
                    throw new Error("messageService is not initialized");
                }
                await messageService.value.sendMessage(
                    notification.conversationId,
                    messageQuery
                );
            } catch (error) {
                console.error("Error sending message:", error);
                throw error;
            }
        };

        const sendCallButton = async (message: string, sendToOwner: boolean = true) => {
            if (notifications.value.length > 0) {
                const notification = notifications.value.pop()
                const [senderDisplayFirstname, SenderDisplayLastname] = notification.senderName.split(" ")

                let routeIncludesConversationId = route.fullPath?.includes(notification.conversationId);

                let messageQuery: ISendMessageQuery = {
                    message: message,
                    isImportant: false,
                    assetIds: [],
                    senderDisplayFirstname,
                    SenderDisplayLastname
                };

                if (routeIncludesConversationId) {
                    if (sendToOwner) {
                        emitMessage(message)
                        return;
                    }
                }

                await sendMessage(notification, messageQuery)
                notifications.value = []
            }
        }

        const handleCallDecline = async () => {
            stopAudio();
            const declineBtnStr = `<button type="button" class="btn btn-danger text-white jitsi-decline-btn disabled"><i class="fa fa-phone-alt text-white mr-1"></i>Declined</button> `

            await sendCallButton(declineBtnStr, true)
        }

        const handleMissedCall = async () => {
            stopAudio();
            const missedCallBtnStr = `<button type="button" class="btn btn-default text-white jitsi-decline-btn disabled"><i class="fa fa-phone-alt text-white mr-1"></i>Missed</button> `

            await sendCallButton(missedCallBtnStr, false)
        }

        const fullNameToInitials = (fullName: string) => {
            const words = fullName.trim().split(/\s+/);
            return words.map(word => word.charAt(0).toUpperCase()).join('');
        };

        onBeforeUnmount(() => {
            stopAudio();
        });

        const handleJitsiMessage = (notification: INotification) => {
            notifications.value.push(notification)
        }

        const handleCallAccept = (notification: INotification) => {
            eventBus.emit("joinMeeting", notification.roomId);
            notifications.value = []
            stopAudio();
        }

        const closeNotificationPopup = () => {
            notifications.value = []
            stopAudio();
        }

        onMounted(async () => {
            await initialiseSignalR();
            await setUpNotificationEvents()
            messageService.value = Container.instance.get(MessageService);

            eventBus.on("jitsiMessage", handleJitsiMessage)
        });

        onBeforeUnmount(() => {
            eventBus.off("jitsiMessage", handleJitsiMessage)
        })



        return {
            notifications,
            showAudio,
            hasJoinMeetingBtn,
            NotificationTypeEnum,
            chatType,
            closeNotificationPopup,
            handleCallDecline,
            handleCallAccept,
            fullNameToInitials,
            navigateToConversation,
            scheduleNotificationRemoval,
        };
    }
});


