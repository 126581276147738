import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "mr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _createElementVNode("i", {
        class: _normalizeClass(['far', 'shared-file-type-icon', _ctx.getFileIcon(_ctx.asset.mimeType)])
      }, null, 2 /* CLASS */)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("a", {
        href: _ctx.asset.url,
        target: "_blank"
      }, _toDisplayString(_ctx.asset.fileName), 9 /* TEXT, PROPS */, _hoisted_2)
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(_ctx.asset.modifiedDate, "MMM DD")), 1 /* TEXT */),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.asset.uploaderFirstName) + " " + _toDisplayString(_ctx.asset.uploaderLastName), 1 /* TEXT */)
  ]))
}