import { valueConverter } from "aurelia-framework";

import { ProductService } from "../../services/product.service";

@valueConverter("getProductPillClass")
export class GetProductClass {
    public toView(id: number): string {
        let productService: ProductService = new ProductService();
        let className: string = productService.getProductMarkupClassName(id);
        if (className.length > 0) {
            return `pill-${className}`;
        }
        return "";
    }
}
