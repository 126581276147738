import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import type { INewMessage } from "../../../resources-vue/vue-interfaces/messaging-service/i-message";
import type { ITypeaheadOptions } from "../../../resources-vue/vue-interfaces/i-typeahead";
import type { IGetUserByName, IGetUserSlimInfoResult } from "../../../resources-vue/vue-interfaces/i-user";
import { MessageService } from "../../../services/message-service";
import { UserService } from "../../../services/user-service";
import { EnumsService } from "../../../services/enums.service";
import { EnumMap } from "../../../common/enum-map";
import { mockCurrentUser } from "../MockData";

import TagsInput from "../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import MessageInputHandler from "../../../resources-vue/vue-custom-elements/MessagingService/MessageInputHandler/MessageInputHandler.vue";

export default defineComponent({
    components: {
        TagsInput,
        MessageInputHandler
    },

    data() {
        return {
            _messageService: null,
            _usersService: null,
            _enumsService: null,
            users: [] as ITypeaheadOptions[],
            currentUser: null as IGetUserSlimInfoResult,
            newMessage: {
                userIds: [],
                message: "",
                isImportant: false
            } as INewMessage,
            files: [] as File[],
            _activeStatus: null as number
        };
    },

    async created() {
        this._messageService = Container.instance.get(MessageService);
        this._usersService = Container.instance.get(UserService);
        this._enumsService = Container.instance.get(EnumsService);
        const params = this.$route.params;
        this.$watch(
            () => this.$route.params,
            (toParams: any, previousParams: any) => {
                // react to route changes...
                if (previousParams !== toParams) {
                    return;
                }
            },
            { deep: true }
        );
        if (params.id) {
            let user = await this._usersService.getUserSlimInfo(params.id);
            if (user) {
                this.users.push({
                    name: `${user.lastName ? user.lastName + ", " : ""}${user.firstName}`,
                    value: params.id as string
                });
            }
        }
        // TODO: use api instead of mockdata once api works
        // this.currentUser = await this._usersService.getCurrentUser();
        this.currentUser = mockCurrentUser;
        let patientStatusEnum = await this._enumsService.getPatientStatusNew();
        let patientStatusEnumMap = new EnumMap(patientStatusEnum);
        this._activeStatus = patientStatusEnumMap.getEnumValue("active") as number;
    },

    watch: {
        users: {
            handler() {
                this.usersChanged();
            },
            deep: true
        }
    },

    methods: {
        async getUsersList(filter: string, limit: number) {
            const data = await this._messageService.getUserByName({
                page: 1,
                pageLength: 25,
                term: filter.trim(),
                agencyIds: []
            });
            return this.getDisplayUsers(data.items);
        },

        getDisplayUsers(data: IGetUserByName[]) {
            if (data) {
                let filteredItems = data.filter((user) => user.id !== this.currentUser.id);
                return filteredItems.map((user) => ({
                    name: `${user.lastName ? user.lastName + ", " : ""}${user.firstName}`,
                    value: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    application: user.application,
                    agencyName: user.agencyName,
                    agencyId: user.agencyId
                }));
            }
            return [];
        },

        usersChanged() {
            if (this.newMessage) {
                this.newMessage.userIds = this.users.map((user) => user.value);
            }
        },

        async sendMessage(value: any) {
            try {
                this.newMessage.message = value.message;
                this.newMessage.assetIds = [];
                if (this.files?.length > 25) {
                    this.files.splice(25);
                }
                if (!!this.files?.length) {
                    this.newMessage.assetIds = await this._messageService.uploadMessageFiles(this.files);
                }
                let conversation = await this._messageService.newConversation(this.newMessage);
                this.$router.push(`/central-messaging/clinicians/conversation/${conversation.id}`);
            } catch (e) {
                console.error(e);
            }
        },

        async toggleImportant() {
            if (this.newMessage) {
                this.newMessage.isImportant = !this.newMessage.isImportant;
            }
        },

        updateTags(value: any) {
            this.users = value;
        },

        updateTitle(value: string) {
            this.newMessage.title = value;
        }
    }
});
