import { defineComponent, PropType, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf, required } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import {
    ApplicationEnum,
    CAHPSSurveyMethod,
    ContactRoles,
    HttpStatusCodeEnum,
    Relationships
} from "../../../../../enums/enums";
import { EmergencyContact } from "../../../../../resources-vue/vue-models/emergency-contact";
import type {
    IContactsOrder,
    IContactsOrderQueryParam
} from "../../../../../resources-vue/vue-interfaces/i-get-patient";
import { ToastrService } from "../../../../../services/toastr.service";
import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import { ReferralService } from "../../../../../services/referral-service";
import { HCContact } from "../../../../../resources-vue/vue-models/hc-contact";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import EmergencyContactsList from "./EmergencyContactsList/EmergencyContactsList.vue";
import EmergencyContactsForm from "./EmergencyContactsForm/EmergencyContactsForm.vue";

export default defineComponent({
    components: {
        FormSection,
        EmergencyContactsForm,
        EmergencyContactsList
    },
    props: {
        accountId: { type: String, default: null },
        patientId: { type: String, default: "" },
        application: { typeof: ApplicationEnum, default: null },
        referral: { type: HomeCareReferral, default: null },
        patientPrimaryAddress: { type: Object as PropType<any>, default: null }
    },

    data() {
        return {
            isEditing: false,
            isReOrderRequested: false,
            showContactForm: false,
            isSavingReOrder: false,
            isLoading: false,
            totalContacts: 0,
            contactLimit: 10,
            cahpsSurveyRecipientIndex: -1,
            cachedContact: [] as EmergencyContact[],
            contact: null,
            _toastrService: null,
            _referralService: null,
            relationshipEnum: Array.from(Relationships.enumWithDescriptions.values()),
            contactRolesEnum: Array.from(ContactRoles.enumWithDescriptions.values()),
            cahpsSurveyContactEnum: Array.from(CAHPSSurveyMethod.enumWithDescriptions.values()),
            v$: useVuelidate()
        };
    },
    validations() {
        return {
            contact: {
                firstName: {
                    required: requiredIf(this.showContactForm)
                },
                lastName: {
                    required: requiredIf(this.showContactForm)
                },
                relationship: {
                    required: requiredIf(this.showContactForm)
                }
            }
        };
    },

    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
    },
    computed: {
        isApplicationPalliative(): boolean {
            return this.application == ApplicationEnum.AxxessPalliative;
        }
    },
    methods: {
        requestReOrder() {
            this.cachedContact =
                (this.$refs.contactList as any)?.contacts.length > 0
                    ? [...(this.$refs.contactList as any).contacts]
                    : [];
            this.isReOrderRequested = true;
        },
        openAddContact() {
            this.showContactForm = true;
            this.contact = new HCContact();
            this.contact.contactOrder = Number(this.totalContacts);
            this.contact.application = this.application;
        },
        cancelReOrder() {
            (this.$refs.contactList as any).contacts = this.cachedContact;
            (this.$refs.contactList as any)?.updateIndices();
            this.isReOrderRequested = false;
        },
        closeAddContact() {
            this.showContactForm = false;
            this.contact = new HCContact();
        },

        async confirmReOrder() {
            try {
                this.isSavingReOrder = true;
                let contactsOrder: IContactsOrder[] = (this.$refs.contactList as any)?.contacts.map(
                    (contact: EmergencyContact) => {
                        return {
                            contactId: contact.id,
                            contactOrder: contact.contactOrder
                        };
                    }
                );
                await this.reorderContacts(contactsOrder);
                (this.$refs.contactList as any)?.getContacts();
            } catch (e) {
                if (e.status === HttpStatusCodeEnum.NotModified) {
                    this._toastrService.info({
                        title: "No Changes Detected",
                        message: "There were no changes made to the contacts order."
                    });
                } else {
                    console.error(e);
                    this._toastrService.error({
                        title: `Error`,
                        message: "There was a problem while re-ordering the contacts. Please try again."
                    });
                }
            } finally {
                this.isReOrderRequested = false;
                this.isSavingReOrder = false;
            }
        },
        async addContact() {
            const validated = await (this.$refs.contactForm as any).v$.$validate();
            if (validated) {
                try {
                    this.isLoading = true;
                    if (this.totalContacts === 0) {
                        this.contact.isPrimary = true;
                    }
                    this.contact.accountId = this.accountId;
                    this.contact.agencyId = this.accountId;
                    this.contact.application = this.application;
                    await this.addNewContact(this.patientId, this.contact);
                    this._toastrService.success({
                        title: "Success",
                        message: "Contact has been successfully added!"
                    });
                    this.closeAddContact();
                    (this.$refs.contactList as any)?.getContacts();
                } catch (e) {
                    console.error(e);
                    this._toastrService.error({
                        title: `Error`,
                        message: "There was a problem while adding a contact. Please try again."
                    });
                } finally {
                    this.isLoading = false;
                }
            } else {
                this._toastrService.error({
                    title: `Validation Failed`,
                    message: `Fill all required fields.`
                });
            }
        },
        updateIsEditing(value: boolean) {
            this.isEditing = value;
        },
        reorderContacts(contactOrder: IContactsOrder[]) {
            const contactsOrderQuery: IContactsOrderQueryParam = {
                contactsOrder: contactOrder,
                application: this.referral.application,
                agencyId: this.referral.accountId,
                accountId: this.referral.accountId
            };
            return this._referralService.reorderHCEmergencyContacts(contactsOrderQuery);
        },
        async addNewContact(id: string, contact: HCContact) {
            const response = await this._referralService.addHCEmergencyContact(id, contact);
            return response;
        }
    }
});
