import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col col-12" }
const _hoisted_2 = { class: "col py-3 pb-0 no-gutters" }
const _hoisted_3 = { class: "custom-control custom-checkbox pb-1 col-12" }
const _hoisted_4 = ["id", "value", "checked", "onChange"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 0,
  class: "mt-2 mb-2 ml-3"
}
const _hoisted_7 = { class: "custom-control custom-radio pb-1 col-12" }
const _hoisted_8 = ["id", "value", "checked", "onChange"]
const _hoisted_9 = ["for"]
const _hoisted_10 = {
  key: 1,
  class: "mt-2 mb-2 ml-3"
}
const _hoisted_11 = { class: "custom-control custom-radio pb-1 col-12" }
const _hoisted_12 = ["id", "value", "checked", "onChange"]
const _hoisted_13 = ["for"]
const _hoisted_14 = {
  key: 2,
  class: "mt-2 mb-2 ml-3"
}
const _hoisted_15 = { class: "custom-control custom-radio pb-1 col-12" }
const _hoisted_16 = ["id", "value", "checked", "onChange"]
const _hoisted_17 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, {
    title: "Patient Representative Coordination",
    type: "his-section",
    "full-height": false,
    "application-id": Number(_ctx.application)
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${option.value}-${option.label}`
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("input", {
                  type: "checkbox",
                  class: "custom-control-input",
                  id: `${option.value}-${option.label}`,
                  value: option.value,
                  checked: option.value === _ctx.referral.Contacts.PatientRepresentative,
                  onChange: ($event: any) => (_ctx.handlePatientRepresentative(option.value))
                }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_4),
                _createElementVNode("label", {
                  class: "custom-control-label ml-1 col-12",
                  for: `${option.value}-${option.label}`
                }, _toDisplayString(option.label), 9 /* TEXT, PROPS */, _hoisted_5)
              ]),
              (_ctx.referral.Contacts.PatientRepresentative === 2 && option.value === 2)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.LegalReprentativeOptions, (data) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `${data.value}-${data.label}`
                      }, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("input", {
                            type: "radio",
                            class: "custom-control-input",
                            id: `${data.value}-${data.label}`,
                            value: data.value,
                            checked: data.value === _ctx.referral.Contacts.LegalRepresentative,
                            onChange: ($event: any) => (_ctx.handleLegalRepresentative(data.value))
                          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_8),
                          _createElementVNode("label", {
                            class: "custom-control-label ml-1 col-12",
                            for: `${data.value}-${data.label}`
                          }, _toDisplayString(data.label), 9 /* TEXT, PROPS */, _hoisted_9)
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.referral.Contacts.PatientRepresentative === 4 && option.value === 4)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.PatientSelectedRepresentativeOptions, (data) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `${data.value}-${data.label}`
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("input", {
                            type: "radio",
                            class: "custom-control-input",
                            id: `${data.value}-${data.label}`,
                            value: data.value,
                            checked: data.value === _ctx.referral.Contacts.PatientSelectedRepresentative,
                            onChange: ($event: any) => (_ctx.handlePatientSelectedRepresentative(data.value))
                          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_12),
                          _createElementVNode("label", {
                            class: "custom-control-label ml-1 col-12",
                            for: `${data.value}-${data.label}`
                          }, _toDisplayString(data.label), 9 /* TEXT, PROPS */, _hoisted_13)
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.referral.Contacts.PatientRepresentative === 5 && option.value === 5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.LegalRepresentativeNotInAgreementOptions, (data) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `${data.value}-${data.label}`
                      }, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("input", {
                            type: "radio",
                            class: "custom-control-input",
                            id: `${data.value}-${data.label}`,
                            value: data.value,
                            checked: data.value === _ctx.referral.Contacts.LegalRepresentativeNotInAgreement,
                            onChange: ($event: any) => (_ctx.handleNotInAgreement(data.value))
                          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_16),
                          _createElementVNode("label", {
                            class: "custom-control-label ml-1 col-12",
                            for: `${data.value}-${data.label}`
                          }, _toDisplayString(data.label), 9 /* TEXT, PROPS */, _hoisted_17)
                        ])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["application-id"]))
}