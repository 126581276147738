import { computed, defineComponent, PropType } from "vue";
import { CalendarOptions, EventContentArg } from "@fullcalendar/core";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";
import { mapActions } from "pinia";

import { NavigationService } from "../../../../../services/navigation.service";
import { ITaskByPatientResponse, TaskService } from "../../../../../services/task.service";
import { ToastrService } from "../../../../../services/toastr.service";
import { PatientCalendarModel } from "../../PatientCalendar.model";
import { RescheduleTask } from "../../../../../models/task.model";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum,
    VisitStatusEnum,
    TestingAgenciesEnum
} from "../../../../../enums/enums";
import {
    IVisitDetails,
    IRescheduleVisitHosPal,
    IReassignVisitHC,
    IHandleTaskReschuleIssue
} from "../../../../../resources-vue/vue-interfaces/i-visit-details";
import { IReassignVisitDetails } from "../../../../../resources-vue/vue-interfaces/i-reassign-visit";
import { adjustDate } from "../../../../../common/adjust-date";
import { FormatPhoneNumber } from "../../../../../resources/value-converters/format-phone";
import { GetProductClass } from "../../../../../resources/value-converters/get-product-class";
import { formatName } from "../../../../../common/format-name";
import { BulkReassign } from "../../../../../resources-vue/vue-models/bulk-reassign";
import config from "../../../../../common/config";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { IRescheduleValidations } from "../../../../../resources-vue/vue-dialogs/RescheduleValidations/RescheduleValidation";
import { formatScheduledTime, formatTimeInOut } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import VisitDetails from "../../../../../resources-vue/vue-dialogs/VisitDetails/VisitDetails.vue";
import RescheduleValidation from "../../../../../resources-vue/vue-dialogs/RescheduleValidations/RescheduleValidation.vue";
import ScheduleVisitDialog from "../../../../../resources-vue/vue-dialogs/ScheduleVisit/ScheduleVisit.vue";
import FullCalendar from "../../../../../resources-vue/vue-elements/FullCalendar/FullCalendar.vue";
import PostToAxxesscare from "../../../../../resources-vue/vue-dialogs/PostToAxxesscare/PostToAxxesscare.vue";
import ReassignVisit from "../../../../../resources-vue/vue-dialogs/ReassignVisit/ReassignVisit.vue";

enum ScheduleLegendState {
    info = 1,
    success = 2,
    danger = 3
}

export default defineComponent({
    props: {
        startDate: { type: Date },
        endDate: { type: Date },
        patientsList: { type: Array as PropType<PatientCalendarModel[]>, default: [] },
        tasksList: { type: Array, default: [] },
        tasksMap: { default: null },
        onCalendarRefresh: { type: Function }
    },

    components: {
        FullCalendar
    },

    data() {
        return {
            _navigationService: null,
            _permissionManager: null,
            _taskService: null,
            _toastrService: null,
            _dialogService: null,
            dialogRef: null,
            isReassignAllowed: false as boolean,
            isEditVisitDetailsAllowed: false as boolean,
            showEditBtn: false as boolean,
            isTestAgency: false as boolean,
            tasksMap: null,
            // NOTE: eventLimit be one more than the required events, as to account for dummy event
            eventLimit: 4 as number
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._navigationService = Container.instance.get(NavigationService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    mounted() {
        this.isReassignAllowed = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canReassign
        );
        this.isEditVisitDetailsAllowed = this._permissionManager.checkPermission(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canEdit
        );
        this.showEditBtn = this.isReassignAllowed || this.isEditVisitDetailsAllowed;
    },

    computed: {
        patientDetails() {
            return (
                this.patientsList?.map((patient) => {
                    return {
                        ...patient,
                        id: patient.Id
                    };
                }) ?? []
            );
        },

        visitActions() {
            this.tasksMap = new Map();
            this.tasksList.forEach((task: any) => {
                let startDate = new Date(Date.parse(task.StartDate));
                let key = `${task.PatientId}${startDate.toLocaleDateString()}`;
                if (!this.tasksMap.has(key)) {
                    this.tasksMap.set(key, [task]);
                } else {
                    let currentTasks = this.tasksMap.get(key);
                    this.tasksMap.set(key, [...currentTasks, task]);
                }
            });
            let tasksList: ITaskByPatientResponse[] = [];
            this.tasksMap.forEach((value: any) => {
                tasksList.push(...value);
                // not removing it as would still look for any better solution

                // let list = value.slice(0, this.eventLimit);
                // tasksList = tasksList.concat([...list]);
                // if (value.length > this.eventLimit) {
                //     let extraTask = {
                //         Id: value[0].Id,
                //         PatientId: value[0].PatientId,
                //         StartDate: moment(value[0].StartDate).endOf("day").format(),
                //         EndDate: value[0].StartDate,
                //         isExtraEvent: true
                //     } as ITaskByPatientResponse;
                //     tasksList.push(extraTask);
                // }
            });
            return (
                tasksList?.map((task) => ({
                    ...task,
                    id: task.Id,
                    start: task.StartDate,
                    end: moment(task.EndDate).endOf("day").format(),
                    resourceId: task.PatientId
                })) ?? []
            );
        },
        calendarOptions(): CalendarOptions {
            return {
                resources: this.patientDetails,
                resourceOrder: "LastName, FirstName",
                events: this.visitActions,
                resourceAreaHeaderContent: "Patients",
                droppable: true,
                eventDragStart: (event: any) => {
                    $('[role="tooltip"]')?.remove();
                },
                drop: (event: any) => {
                    $('[role="tooltip"]')?.remove();
                    const patientId = event.draggedEl.id.split("@@")[1] || null;
                    if (!patientId || !(event.resource.id == patientId)) {
                        throw new Error();
                    }
                    const eventId = event.draggedEl.id.split("@@")[0];
                    const currentTask =
                        (this.tasksList?.find((task: ITaskByPatientResponse) => task.Id == eventId) as any) || null;
                    if (!currentTask) {
                        this._toastrService.error({
                            title: `Error - Rescheduling task`,
                            message: `There was an error rescheduling the visit. Please Try again later.`
                        });
                        this.$emit("calendarRefresh");
                        return;
                    }
                    this.rescheduleVisit(currentTask, moment(event.date).format("MM/DD/YYYY"));
                },
                eventDrop: async (event: any) => {
                    $('[role="tooltip"]')?.remove();
                    const rowChanged = !(
                        JSON.stringify(event.oldEvent._def.resourceIds) == JSON.stringify(event.event._def.resourceIds)
                    );
                    if (rowChanged) {
                        event.revert();
                        return;
                    }
                    if (!event?.event?.extendedProps || !event?.event?.startStr) {
                        this._toastrService.error({
                            title: `Error - Rescheduling task`,
                            message: `There was an error rescheduling the visit. Please Try again later.`
                        });
                        this.$emit("calendarRefresh");
                        return;
                    }
                    const visitDetails = event.event.extendedProps;
                    const lastDay = moment(visitDetails.StartDate).endOf("week").format("YYYYMMDD");

                    if (
                        visitDetails.StartDate != visitDetails.EndDate &&
                        moment(event.event.start).format("YYYYMMDD") == lastDay
                    ) {
                        this._toastrService.error({
                            title: `Error - Rescheduling task`,
                            message: `Midnight split visit will not get reschedule to the last day of the week`
                        });
                        this.$emit("calendarRefresh");
                        return;
                    }

                    if (
                        [ApplicationEnum.AxxessHospiceFE, ApplicationEnum.AxxessPalliative].includes(
                            visitDetails.Application
                        )
                    ) {
                        let startTime = visitDetails.StartTime
                            ? moment(visitDetails.StartTime).format("HH:mm:ss")
                            : "00:00:00";
                        let endTime = visitDetails.EndTime
                            ? moment(visitDetails.EndTime).format("HH:mm:ss")
                            : "00:00:00";
                        this.rescheduleVisitHosPal(
                            visitDetails,
                            `${moment(event.event.start).format("MM/DD/YYYY")}  ${startTime}`,
                            `${moment(event.event.end).format("MM/DD/YYYY")} ${endTime}`
                        );
                    } else if (visitDetails.Application == ApplicationEnum.HomeCare) {
                        let timeIn = visitDetails.StartTime || visitDetails.timeIn;
                        timeIn = timeIn ? moment(timeIn).format("HH:mm:ss") : "00:00:00";
                        let timeOut = visitDetails.EndTime || visitDetails.timeOut;
                        timeOut = timeOut ? moment(timeOut).format("HH:mm:ss") : "00:00:00";
                        const newStart = moment(event.event.start).format("YYYY-MM-DD");
                        const newEnd = moment(event.event.end).format("YYYY-MM-DD");
                        this.rescheduleVisitHomeCare(
                            event,
                            visitDetails,
                            `${newStart}T${timeIn}`,
                            `${newEnd}T${timeOut}`
                        );
                    } else {
                        this.rescheduleVisit(visitDetails, moment(event.event.start).format("MM/DD/YYYY"));
                    }
                },
                eventContent: (arg: EventContentArg) => {
                    const eventData = arg?.event?.extendedProps;
                    let extraEvents = 0;
                    if (![VisitStatusEnum.NotStarted, VisitStatusEnum.NotYetDue].includes(eventData.Status)) {
                        arg.event._def.ui.constraints = [false];
                    }
                    if (eventData?.isExtraEvent) {
                        let startDate = new Date(Date.parse(eventData?.StartDate));
                        let key = `${eventData.PatientId}${startDate.toLocaleDateString()}`;
                        let currentTaskList = this.tasksMap.get(key);
                        extraEvents = currentTaskList.length - this.eventLimit;
                    }
                    return {
                        html: this.generateEvent(arg, extraEvents)
                    };
                },
                eventDidMount: (arg: any) => {
                    $('[role="tooltip"]')?.remove();
                    const self = this;
                    if (!!$(arg?.el).find(".more-btn").length) {
                        this.attachPopover(arg);
                        return;
                    }
                    this.attachTooltip(arg);
                    const scheduledEvent = arg?.event?.extendedProps;
                    const reassignBtn = `btn-reassign-${scheduledEvent.Id}`;
                    const postToAxxessCareBtn = `btn-posttocare-${scheduledEvent.Id}`;
                    const visitDetailsBtn = `btn-visitdetails-${scheduledEvent.Id}`;

                    if (!!document.getElementById(reassignBtn)) {
                        document.getElementById(reassignBtn).addEventListener("click", () => {
                            // adding this click event listener manually because calling onclick event directly causes issue since DOM loads first before the JS.
                            self.scheduledVisit(arg);
                        });
                    }

                    if (!!document.getElementById(postToAxxessCareBtn)) {
                        document.getElementById(postToAxxessCareBtn).addEventListener("click", () => {
                            self.showPostToAxxessCare(arg);
                        });
                    }

                    if (!!document.getElementById(visitDetailsBtn)) {
                        document.getElementById(visitDetailsBtn).addEventListener("click", () => {
                            self.showVisitDetails(arg);
                        });
                    }

                    // click event listener for edit icon
                    const editButtonId = `edit-btn-${scheduledEvent.Id}`;
                    if (!!document.getElementById(editButtonId)) {
                        document.getElementById(editButtonId).addEventListener("click", () => {
                            $('[role="tooltip"]')?.remove();

                            self.tasksList.forEach((task: any) => {
                                const selectorElement = $(`#scheduled-event-${task.Id}`).closest("a")[0];
                                if (selectorElement) {
                                    if (task.Id != scheduledEvent.Id) {
                                        selectorElement.style.zIndex = "0";
                                    } else {
                                        selectorElement.style.zIndex = "5";
                                    }
                                }
                            });
                        });
                    }

                    // Permission Logic
                    const editBtnId = `edit-icon-${scheduledEvent.Id}`;
                    let showEditButtonForVisit: boolean = false;
                    let showReassignButtonForVisit: boolean = false;
                    showReassignButtonForVisit =
                        self.isReassignAllowed &&
                        (scheduledEvent.Status == VisitStatusEnum.NotStarted ||
                            scheduledEvent.Status == VisitStatusEnum.NotYetDue);
                    let showVisitDetailsButtonForVisit: boolean = false;
                    showVisitDetailsButtonForVisit =
                        self.isEditVisitDetailsAllowed &&
                        (scheduledEvent.Application == ApplicationEnum.AgencyCore ||
                            scheduledEvent.Application == ApplicationEnum.HomeCare ||
                            scheduledEvent.Application == ApplicationEnum.AxxessPalliative ||
                            scheduledEvent.Application == ApplicationEnum.AxxessHospiceFE) &&
                        Object.values(TestingAgenciesEnum).includes(scheduledEvent.AgencyId);
                    showEditButtonForVisit =
                        self.showEditBtn && (showReassignButtonForVisit || showVisitDetailsButtonForVisit);
                    if (!!document.getElementById(editBtnId)) {
                        if (!showEditButtonForVisit) {
                            document.getElementById(editBtnId).style.display = "none";
                            return;
                        }

                        if (!!document.getElementById(reassignBtn) && !showReassignButtonForVisit) {
                            document.getElementById(reassignBtn).style.display = "none";
                        }
                        if (!!document.getElementById(visitDetailsBtn) && !showVisitDetailsButtonForVisit) {
                            document.getElementById(visitDetailsBtn).style.display = "none";
                        }
                    }
                },
                eventWillUnmount: (arg: any) => {
                    let scheduledEvent = arg?.event?.extendedProps;
                    let reassignBtn = `btn-reassign-${scheduledEvent.Id}`;
                    if (!!document.getElementById(reassignBtn)) {
                        document.getElementById(reassignBtn).removeEventListener("click", () => {
                            console.log("Removed Reassign button Click Event Listener");
                        });
                    }

                    let editButtonId = `edit-btn-${scheduledEvent.Id}`;
                    if (!!document.getElementById(editButtonId)) {
                        document.getElementById(editButtonId).removeEventListener("click", () => {
                            console.log("Removed Edit Button Click Event Listener");
                        });
                    }
                },
                // NOTE: Uncomment when task navigation is needed
                // eventClick: (event: any) => {
                //     if (!$(event.el).find(".more-btn").length) {
                //         this.handleEventClick(event);
                //     }
                // },
                resourceLabelContent: (arg: any) => {
                    $('[role="tooltip"]')?.remove();
                    return {
                        html: this.generateResource(arg)
                    };
                },
                resourceLabelDidMount: (arg: any) => {
                    this.registerClick(arg);
                },
                visibleRange: {
                    start: this.startDate,
                    end: adjustDate(this.endDate, 1)
                }
            };
        }
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        testingAgencyCheck(agencyId: string, application?: ApplicationEnum): boolean {
            if (!agencyId) {
                return false;
            }

            const testingAgencies = Object.values(TestingAgenciesEnum) as string[];
            const allowedAgencies = [
                ApplicationEnum.AgencyCore,
                ApplicationEnum.AxxessHospiceFE,
                ApplicationEnum.AxxessPalliative,
                ApplicationEnum.HomeCare
            ];
            const isTesting = testingAgencies.includes(agencyId);
            if (application) {
                return allowedAgencies.includes(application) && isTesting;
            } else {
                return isTesting;
            }
        },
        async rescheduleVisitHomeCare(dropEvent: any, event: IVisitDetails, StartDate: string, EndDate: string) {
            const { Id, AgencyId, Application, UserId, EpisodeId, PatientId, TimeIn, TimeOut } = event;

            const rescheduleDetails: IReassignVisitHC = {
                AgencyId,
                PatientId,
                EventId: Id,
                EpisodeId,
                Application,
                UserId,
                TimeIn,
                TimeOut,
                VisitStartTime: StartDate,
                VisitEndTime: EndDate,
                isDragDrop: true
            };
            this.showLoading(true);

            try {
                const firstResponse = await this._taskService.rescheduleTaskHC(rescheduleDetails);

                if (!firstResponse.isSuccessful && firstResponse.errorDetails) {
                    this.showLoading(false);
                    if (!firstResponse.errorDetails.taskIssues.length) {
                        throw new Error(firstResponse.errorMessage);
                    }

                    const validationList: IRescheduleValidations[] = firstResponse.errorDetails.taskIssues.map(
                        (item: any) => {
                            return {
                                title: item.title as string,
                                description: [item.message] as string[],
                                taskIssueSurpressKey: item.taskIssueSurpressKey as string
                            };
                        }
                    );

                    const hasError = firstResponse.errorDetails.allTasksHaveErrors;

                    this.dialogRef = this.$dialog.open(RescheduleValidation, {
                        props: {
                            modal: true,
                            closeOnEscape: false,
                            showHeader: false,
                            style: {
                                width: "60vw"
                            }
                        },

                        data: {
                            validations: validationList,
                            name: "Patient",
                            component: "Schedule-visit",
                            hasError
                        },

                        onClose: async (options) => {
                            if (options.data == "cancel") {
                                return;
                            } else {
                                const issuesResponseObj: IHandleTaskReschuleIssue = {};
                                for (let issue of validationList) {
                                    issuesResponseObj[issue.taskIssueSurpressKey] = "ScheduleAllAnyways";
                                }

                                rescheduleDetails.HandleTaskIssues = issuesResponseObj;
                                const secondResponse = await this._taskService.rescheduleTaskHC(rescheduleDetails);
                                if (!secondResponse.isSuccessful) {
                                    throw new Error(secondResponse.errorMessage);
                                }

                                if (secondResponse.isSuccessful) {
                                    this._toastrService.success({
                                        title: `Visit Scheduled`,
                                        message: `Visit has been successfully Scheduled.`
                                    });

                                    this.$emit("calendarRefresh");
                                }
                            }
                        }
                    });
                }

                if (firstResponse.isSuccessful && !firstResponse.errorDetails) {
                    this._toastrService.success({
                        title: `Success`,
                        message: `The visit has been successfully rescheduled.`
                    });
                    this.$emit("calendarRefresh");
                    this.showLoading(false);
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: error.message || `There was an error rescheduling the visit. Please Try again later.`
                });
            } finally {
                this.$emit("calendarRefresh");
                this.showLoading(false);
            }
        },

        async rescheduleVisitHosPal(event: IVisitDetails, StartDate: string, EndDate: string) {
            const { Id, AgencyId, Application, UserId } = event;
            const rescheduleDetails: IRescheduleVisitHosPal = {
                TaskId: Id,
                AccountId: AgencyId,
                StartDate,
                EndDate,
                UserId,
                Application
            };
            this.showLoading(true);
            try {
                await this._taskService.rescheduleTaskHosPal(rescheduleDetails);
                this._toastrService.success({
                    title: `Success`,
                    message: `The visit has been successfully rescheduled.`
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: `There was an error rescheduling the visit. Please Try again later.`
                });
            } finally {
                this.$emit("calendarRefresh");
                this.showLoading(false);
            }
        },

        async rescheduleVisit(event: IVisitDetails, date: string) {
            const visitDetails = event;
            const rescheduleVisitDetails = new RescheduleTask();
            rescheduleVisitDetails.fillRequiredDetails(visitDetails);
            rescheduleVisitDetails.EventDate = rescheduleVisitDetails.VisitDate = date || null;
            if (rescheduleVisitDetails.EventDate) {
                this.showLoading(true);
                try {
                    const response = await this._taskService.rescheduleTask(rescheduleVisitDetails);
                    if (response?.isSuccessful) {
                        this._toastrService.success({
                            title: `Success`,
                            message: `The visit has been successfully rescheduled.`
                        });
                    } else {
                        throw new Error();
                    }
                } catch (error) {
                    this._toastrService.error({
                        title: `Error - Rescheduling task`,
                        message: `There was an error rescheduling the visit. Please Try again later.`
                    });
                } finally {
                    this.$emit("calendarRefresh");
                    this.showLoading(false);
                }
            } else {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: `There was an error rescheduling the visit. Please Try again later.`
                });

                this.$emit("calendarRefresh");
            }
        },

        async showPostToAxxessCare(arg: any) {
            const taskDetails = arg?.event?.extendedProps as IVisitDetails;
            const visitDetails: IVisitDetails = Object.assign({}, taskDetails);

            this.dialogRef = this.$dialog.open(PostToAxxesscare, {
                props: {
                    modal: true,
                    showHeader: false,
                    contentStyle: {
                        width: "700px",
                        overflowX: "hidden"
                    }
                },
                data: {
                    visitDetails
                },
                onClose: (response) => {
                    this.$emit("calendarRefresh");
                }
            });
        },

        async showVisitDetails(arg: any) {
            const taskDetails = arg?.event?.extendedProps as IVisitDetails;
            const visitDetails: IVisitDetails = Object.assign({}, taskDetails);
            this.dialogRef = this.$dialog.open(VisitDetails, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    visitDetails: visitDetails
                },
                onClose: (response) => {
                    if (response.data === "success") {
                        this.$emit("calendarRefresh");
                    }
                }
            });
        },

        async scheduledVisit(arg: any) {
            const taskDetails = arg?.event?.extendedProps;
            const patientDetails = this.patientsList.find(
                (patient: PatientCalendarModel) => taskDetails.PatientId == patient.Id
            );
            const patientInfo = (({ AgencyName, FirstName, LastName, Mrn }) => ({
                AgencyName,
                FirstName,
                LastName,
                Mrn
            }))(patientDetails);
            let visitDetails: IReassignVisitDetails = Object.assign({}, patientInfo, taskDetails);

            this.dialogRef = this.$dialog.open(ReassignVisit, {
                props: { modal: true, showHeader: false },
                data: {
                    visitDetails
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        this.$emit("calendarRefresh");
                    }
                }
            });
        },

        showPostToAxxessCareBtn(event: IVisitDetails): boolean {
            const testingAgency = Object.values(TestingAgenciesEnum) as string[];
            if (event.Application != ApplicationEnum.AgencyCore) {
                return false;
            } else if (!testingAgency.includes(event.AgencyId)) {
                return false;
            } else if (event.hasOwnProperty("ShowPostToAxxessCareLink")) {
                return event.ShowPostToAxxessCareLink;
            }
            return true;
        },

        axxessCareHeart(event: IVisitDetails) {
            if (event?.IsAxxessCareNote) {
                if (event.PrivateJob) {
                    return `<div class="axxesscare-heart">
                <i
                    class="fa fa-heart axcare-internal-job-color"
                    tooltip="<b>Internal visit</b> (Visible only to your staff clinicians)"
                ></i>
                </div>
            `;
                } else {
                    return `<div class="axxesscare-heart">
                <i
                    class="fa fa-heart axcare-external-job-color"
                    tooltip="<b>External visit</b> (Visible to all qualified clinicians nearby)"
                 ></i>
                </div>
            `;
                }
            } else {
                return "";
            }
        },

        generateEvent(arg: EventContentArg, extraEvents: number) {
            const event = arg?.event?.extendedProps as IVisitDetails;
            const date = new Date(event.EndDate);
            const day = date.getDay();
            const saturday = 6;
            let eventContent = "";
            const eventColor: string =
                !event.UserFirstName && !event.UserLastName ? "warning" : ScheduleLegendState[event.State];
            const showPostToAxxessCareBtn: boolean = this.showPostToAxxessCareBtn(event) || false;
            if (!!event) {
                eventContent = `<div class="ax-calendar-event ${eventColor}" id = "scheduled-event-${event.Id}">
                <span class = "w-100 d-flex">
                    <span class="font-weight-bold text-truncate">
                            ${event.TaskName}
                    </span>
                    <span class="btn-group table-btn-group ml-auto" id = "edit-icon-${event.Id}">
                        <button type="button" class="border-0 btn px-1 py-0 btn-outline-info dropdown-toggle" data-toggle="dropdown" data-display="static" id = "edit-btn-${
                            event.Id
                        }">
                            <i class="fas fa-edit"></i>
                        </button>
                        <div class="dropdown-menu ${
                            day === saturday ? "dropdown-transform-left" : "dropdown-transform-right"
                        }">
                            <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                id = "btn-reassign-${event.Id}"
                            >
                            Reassign
                            </a>
                            ${
                                showPostToAxxessCareBtn
                                    ? `<a
                                    class="dropdown-item"
                                    href="javascript:void(0);"
                                    id="btn-posttocare-${event.Id}"
                                >
                                Post To AxxessCare
                                </a>`
                                    : ""
                            }
                            <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                id = "btn-visitdetails-${event.Id}"
                            >
                            Details
                            </a>
                        </div>
                    </span>
                </span>
                    <span class="w-100 font-size-3xs text-truncate">
                        ${formatTimeInOut(formatScheduledTime(event.StartTime), formatScheduledTime(event.EndTime))}
                    </span>
                    <span class="d-flex align-items-center font-size-2xs w-100 justify-content-between">
                        <span>
                            <i class="fas fa-user mr-1"></i>
                            <span>
                                ${this.getUserName(event.UserFirstName, event.UserLastName)}
                            </span>
                        </span>
                        <span class="pr-2">
                            ${this.axxessCareHeart(event)}
                        </span>
                   </span>
                    <span class="w-100 font-size-3xs text-truncate">
                        ${event.Status}
                    </span>
                </div>`;
            }
            if (!!extraEvents) {
                eventContent = `<a class="cursor-pointer text-dark more-btn" tabindex="0">+ ${extraEvents} more</a>`;
            }
            return eventContent;
        },

        generateResource(arg: any) {
            const resource = arg?.resource?.extendedProps;
            let patientInfo = "";
            const phoneNumber = new FormatPhoneNumber().toView(resource.PhoneNumber?.toString());
            this.isTestAgency = this.testingAgencyCheck(resource.AgencyId);
            const isHomeCareAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.HomeCare
            );
            const isHosAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            );
            const isPalAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            );
            const isHomeHealthAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AgencyCore
            );

            if (!!resource) {
                let productClass = new GetProductClass();
                let application = resource.Application;
                if (application === ApplicationEnum.AxxessHospiceFE) {
                    application = ApplicationEnum.AxxessHospice;
                }
                patientInfo = `<div class="mx-2 d-flex flex-column align-items-start p-2">
                                <span class="font-weight-normal font-size-xs badge badge-pill pill mr-1 ${productClass.toView(
                                    application
                                )}">
                                    ${ApplicationNameEnum[application]}
                                </span>
                                <div class="position-absolute bulk-reassign-wrapper">
                                    ${
                                        this.isTestAgency
                                            ? `<button
                                                type="button"
                                                class="btn font-size-xs px-1 py-0 btn-outline-primary"
                                                data-toggle="dropdown"
                                                id="reassign-ellipsis-${resource.Id}"
                                            >
                                                <i class="fas fa-ellipsis-h"></i>
                                            </button>
                                            <div class="dropdown-menu py-1" aria-labelledby="reassign-ellipsis-${resource.Id}">
                                                <a
                                                    class="dropdown-item font-size-sm bulk-reassign"
                                                    href="javascript:void(0);"
                                                    id="task-manager-${resource.Id}">
                                                        Task Manager
                                                </a>
                                            </div>`
                                            : ``
                                    }
                                </div>
                                <a class="w-auto font-size-base text-truncate cursor-pointer patient-link" id="${
                                    resource.Id
                                }">
                                    ${formatName(resource.FirstName, resource.LastName)}
                                </a>
                                <span class="font-size-xs">${resource.Mrn}</span>
                                ${
                                    phoneNumber
                                        ? `<a href="tel:${phoneNumber}" class="mb-0 font-size-xs w-auto">${phoneNumber}</a>`
                                        : ""
                                }
                                <p class="w-100 mb-1 font-size-2xs text-truncate">${resource.AgencyName}</p>

                                ${
                                    (application == ApplicationEnum.AxxessHospiceFE && isHosAddAllowed) ||
                                    (application == ApplicationEnum.AxxessHospice && isHosAddAllowed) ||
                                    (application == ApplicationEnum.AxxessPalliative && isPalAddAllowed) ||
                                    (application == ApplicationEnum.HomeCare && isHomeCareAddAllowed) ||
                                    (application == ApplicationEnum.AgencyCore && isHomeHealthAddAllowed)
                                        ? `<span class="cursor-pointer font-size-xs text-primary py-1" id="schedule-visits-${resource.Id}"">
                                                <i class="fa fa-plus-circle"></i> Add Task
                                            </span>`
                                        : ""
                                }
                            </div>`;
            }
            return patientInfo;
        },

        registerClick(arg: any) {
            const element = arg?.el?.className.split(" ").join(".");
            const extendedProps = arg?.resource?.extendedProps;
            $(`.${element}`).on("click", `#${extendedProps?.Id}`, (event: any) => {
                this.handlePatientNameClick(extendedProps);
            });
            $(`.${element}`).on("click", `#task-manager-${extendedProps?.Id}`, (event: any) => {
                this.openTaskManager(extendedProps);
            });
            $(`.${element}`).on("click", `#schedule-visits-${extendedProps?.Id}`, (event: any) => {
                this.scheduleNewVisits(extendedProps);
            });
        },

        async openTaskManager(patient: BulkReassign) {
            // This has to be done because backend is sending -3 for hospice solution.
            let application;
            if (patient.Application === ApplicationEnum.AxxessHospice) {
                application = ApplicationEnum.AxxessHospiceFE;
            } else {
                application = patient.Application;
            }
            this.$router.push({
                path: "/axxess-central/task-manager-patient?",
                query: {
                    agencyId: patient.AgencyId,
                    fullname: `${patient.FirstName} ${patient.LastName}`,
                    application: application,
                    patientId: patient.Id,
                    branchId: patient.LocationId
                }
            });
        },

        async scheduleNewVisits(employee: BulkReassign) {
            const patientDetails = this.patientsList.find((patient: PatientCalendarModel) => employee.Id == patient.Id);
            const patientInfo = (({ AgencyName, FirstName, LastName, Mrn }) => ({
                AgencyName,
                FirstName,
                LastName,
                Mrn
            }))(patientDetails);
            const visitDetails: BulkReassign = Object.assign({}, patientInfo, employee);

            this.dialogRef = this.$dialog.open(ScheduleVisitDialog, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    visitDetails
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        this.$emit("calendarRefresh");
                    }
                    if (options.data === "reopen") {
                        this.scheduleNewVisits(employee);
                    }
                }
            });
        },

        async handlePatientNameClick(resource: PatientCalendarModel) {
            let redirectResponse;
            let analyticsCategory = "Central-Calendar-Navigation";
            let action = "";
            if (resource.Application === ApplicationEnum.AxxessHospice) {
                redirectResponse = {
                    redirectUrl: `${config.hospiceUrl}/patients/patient-chart/${resource.Id}?accountId=${resource.AgencyId}`,
                    isSuccessful: true
                };
                action = "AxxessHospice";
            } else {
                const isClinician = this._permissionManager.checkPermission(
                    ParentPermissionEnum.clinician,
                    PermissionActionEnum.canView
                );
                this.showLoading(true);
                redirectResponse = await this._navigationService.navigateToPatient({
                    agencyId: resource.AgencyId,
                    applicationId: resource.Application,
                    isClinician: isClinician,
                    patientId: resource.Id
                });
                action = resource.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
                this.showLoading(false);
            }

            this._navigationService.redirect(redirectResponse, {
                category: analyticsCategory,
                action
            });
        },

        // NOTE: Uncomment when task navigation is needed
        // private async handleEventClick(eventObjectInput: any | ITaskByPatientResponse) {
        //     let eventData = eventObjectInput?.event?.extendedProps;
        // await this.navigateToTask(eventData);
        // }

        // private async navigateToTask(eventData: ITaskByPatientResponse) {
        //     let redirectResponse;
        //     let analyticsCategory = "Calendar-Task-Navigation";
        //     let action = "";

        //     if (eventData.Application === 256) {
        //         redirectResponse = {
        //             redirectUrl: `${config.hospiceUrl}/note/${eventData.Id}?accountId=${eventData.AgencyId}`,
        //             isSuccessful: true
        //         };
        //         action = "AxxessHospice";
        //     } else {
        //         let accessRoles = await this._accessService.getAccessRoles();
        //         redirectResponse = await this._navigationService.navigateToTask({
        //             agencyId: eventData.AgencyId,
        //             applicationId: eventData.Application,
        //             userId: eventData.UserId,
        //             isClinician: accessRoles.isClinician,
        //             patientId: eventData.PatientId,
        //             taskId: eventData.Id,
        //             episodeId: eventData.EpisodeId
        //         });
        //         action = eventData.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
        //     }

        //     this._navigationService.redirect(redirectResponse, {
        //         category: analyticsCategory,
        //         action
        //     });
        // }

        attachTooltip(arg: any) {
            let element = $(arg?.el);
            let content = this.getTooltipContent(arg?.event?.extendedProps);
            (element as any).popover({
                html: true,
                content: content,
                trigger: "hover",
                placement: "top",
                container: "body"
                // delay: { show: 100, hide: 2000 },
                // sanitize: false //https://stackoverflow.com/questions/60001921/popover-html-content-with-button-and-input-not-rendering
            });
        },

        getTooltipContent(data: any) {
            let content = "";
            let evvContent = "";
            let scheduledTime = "";
            if (this.getEvvContent(data).length > 0) {
                evvContent = `<span>
                                <strong>EVV Time:</strong>
                                ${this.getEvvContent(data)}
                            </span>`;
            }
            if (!!data) {
                if (!!data.StartTime) {
                    scheduledTime = formatScheduledTime(data.StartTime);
                }
                content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                                <span><strong>Payer:</strong> ${data.Payer}</span>
                                <span><strong>Billable:</strong> ${!!data.IsBillable ? "Yes" : "No"}</span>
                                <span><strong>Payable:</strong> ${!!data.IsPayable ? "Yes" : "No"}</span>
                                <span>
                                    <strong>Scheduled By:</strong>
                                    ${this.getUserName(data.ScheduledByFirstName, data.ScheduledByLastName)}
                                </span>
                                ${evvContent}
                            <div>`;
            }
            return content;
        },

        getEvvContent(data: any) {
            let evvTime = "";
            if (data.Application === ApplicationEnum.AxxessHospiceFE) {
                return evvTime;
            } else if (!data.EvvTimeIn && !data.EvvTimeOut) {
                evvTime = "Not Yet Started";
            } else if (data.Status === "Completed") {
                const evvTimeIn = data.EvvTimeIn ? data.EvvTimeIn : "Not Collected";
                const evvTimeOut = data.EvvTimeIn ? data.EvvTimeOut : "Not Collected";
                evvTime = this.formatEVVTime(evvTimeIn, evvTimeOut);
            } else {
                const evvTimeIn = data.EvvTimeIn || "Not Collected";
                const evvTimeOut = data.EvvTimeOut || "Not Collected";
                evvTime = this.formatEVVTime(evvTimeIn, evvTimeOut);
            }
            return evvTime;
        },

        getUserName(firstName: string, lastName: string) {
            let userName = "";
            if (!firstName && !lastName) {
                userName = "Unassigned";
            } else {
                userName = formatName(firstName, lastName, "FN LN");
            }
            return userName;
        },

        attachPopover(arg: any) {
            let element = $(arg?.el).find(".more-btn");
            let extendedProps = arg?.event?.extendedProps;
            let startDate = new Date(Date.parse(extendedProps?.StartDate));
            let key = `${extendedProps.PatientId}${startDate.toLocaleDateString()}`;
            let tasksList = this.tasksMap?.get(key).slice(this.eventLimit, this.tasksList?.length);
            let content = "";

            tasksList.map((task: any) => {
                const isDraggable = [VisitStatusEnum.NotStarted, VisitStatusEnum.NotYetDue].includes(
                    task.Status as VisitStatusEnum
                );
                let taskContent = `<div class="fc-event ${isDraggable ? "fc-draggable" : ""} ax-calendar-event ${
                    ScheduleLegendState[task.State]
                } mb-1 more-event" id="${task.Id}@@${task.PatientId}@@${task.TaskName}" name=${task.TaskName} >
                    <span class="w-100 font-weight-bold text-truncate">
                        ${task.TaskName}
                    </span>
                    <span  class="d-flex align-items-center font-size-2xs">
                        <i class="fas fa-user mr-1"></i>
                        <span>
                            ${this.getUserName(task.UserFirstName, task.UserLastName)}
                        </span>
                    </span>
                    <span class="w-100 font-size-3xs text-truncate">
                        ${task.Status}
                    </span>
                </div>`;
                content = content + taskContent;
                // this.attachMoreEventsTooltip(task);
            });
            content = `<div id='external-events'>${content} </div>`;
            (element as any)
                .popover({
                    html: true,
                    content: content,
                    trigger: "click focus",
                    placement: "top",
                    container: "body"
                })
                // .on("shown.bs.popover", (eve: any) => {
                //     const popover = document.getElementById("external-events") || null;
                //     if (!popover || !this.tasksList.length) {
                //         return;
                //     }
                //     const Dragger = new Draggable(popover, {
                //         itemSelector: ".fc-draggable",
                //         eventData: (eventEl) => {
                //             const eventid = eventEl.id.split("@@")[0];
                //             const currentTask = this.tasksList.find((task: ITaskByPatientResponse) => task.Id == eventid);
                //             return currentTask;
                //         }
                //     });
                // })
                .on("mouseenter", () => {
                    let parentDiv = element.closest("td > div");
                    parentDiv.css("z-index", "auto");
                })
                .on("mouseleave", () => {
                    let parentDiv = element.closest("td > div");
                    parentDiv.css("z-index", "");
                });
            // $(".fc-timeline-event")
            //     .off("click")
            //     // tslint:disable-next-line: only-arrow-functions
            //     .on("click", ".more-event", async function (event: any) {
            //         event.stopPropagation();
            //         let taskId = $(this).prop("id");
            //         if (!!taskId) {
            //             let task = self.tasksList.find((item) => item.Id === taskId);

            //             if (!!task) {
            //                 await self.navigateToTask(task);
            //             }
            //         }
            //     });
        },

        attachMoreEventsTooltip(task: ITaskByPatientResponse) {
            let content = this.getTooltipContent(task);
            $("body").on("mouseenter", `#${task.Id}`, () => {
                $(`#${task.Id}`)
                    .popover({
                        html: true,
                        content: content,
                        trigger: "hover",
                        placement: "top",
                        container: "body"
                    })
                    .popover("show");
            });
        },

        formatEVVTime(startTime: Date | string, endTime: Date | string) {
            if (startTime || endTime) {
                if (startTime === "Not Collected" || endTime === "Not Collected") {
                    return `${
                        startTime === "Not Collected" ? "Not Collected" : moment(startTime).format("hh:mm A")
                    } - ${endTime === "Not Collected" ? "Not Collected" : moment(endTime).format("hh:mm A")}`;
                }
                if (moment(startTime).isBefore(endTime, "day")) {
                    return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("MM/DD/YYYY hh:mm A")}`;
                } else {
                    return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("hh:mm A")}`;
                }
            } else return "";
        }
    }
});
