// Libraries
import { DialogOpenResult, DialogService, DialogSettings } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import $ from "jquery";
import moment from "moment";

import { IdleTimeout, type IIdleTimeoutOptions } from "../resources/dialogs/idle-timeout/idle-timeout";
import AuthService from "../services/auth.service";

// Models
@autoinject
export class IdleTimer {
    private _dialogService: DialogService;
    private _auth: AuthService;
    private _allowedIdleTime: number = 10;
    public idleWarningTimeInMinutes: number = 2;
    public isIdleWarningOpen: boolean = false;
    public idleInterval: any;
    public logoutTimer: any;

    public constructor(authService: AuthService, dialogService: DialogService) {
        this._auth = authService;
        this._dialogService = dialogService;
        this.resetIdleTime();
    }

    public detached(): void {
        window.clearInterval(this.idleInterval);
    }

    public async timer() {
        this.idleInterval = setInterval(async () => {
            await this.checkIdleTime();
        }, 1000); // 1 second

        $(window)
            .mousemove(
                this.debounce(() => {
                    this.resetIdleExpiryTime();
                }, 500)
            )

            .click(
                this.debounce(() => {
                    this.resetIdleExpiryTime();
                }, 500)
            );
    }

    private async checkIdleTime() {
        let idleTime = JSON.parse(localStorage.getItem("idleExpiry"));
        let a = moment(idleTime);
        let b = moment();
        let difference = a.diff(b, "s");

        if (difference > 0) {
            if (difference <= this.idleWarningTimeInMinutes * 60) {
                if (!this.isIdleWarningOpen) {
                    let dialogOptions: DialogSettings = {
                        viewModel: IdleTimeout,
                        model: {
                            idleWarningTimeInMinutes: this.idleWarningTimeInMinutes
                        } as IIdleTimeoutOptions
                    };

                    this.isIdleWarningOpen = true;
                    let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
                    let closeResult = await dialog.closeResult;

                    if (closeResult.wasCancelled) {
                        this._auth.logout();
                    } else {
                        this.resetIdleTime();
                        this.isIdleWarningOpen = false;
                    }
                }
            } else {
                this.isIdleWarningOpen = false;
            }
        }
    }

    public debounce(fn: () => void, delay: number): () => void {
        this.logoutTimer = null;
        return () => {
            clearTimeout(this.logoutTimer);
            this.logoutTimer = setTimeout(() => {
                fn();
            }, delay);
        };
    }

    public resetIdleTime(): void {
        localStorage.setItem("idleExpiry", JSON.stringify(moment().add(this._allowedIdleTime, "m")));
    }

    public resetIdleExpiryTime(): void {
        let idleTime = JSON.parse(localStorage.getItem("idleExpiry"));
        let a = moment(idleTime);
        let b = moment();
        let difference = a.diff(b, "s");

        if (difference > this.idleWarningTimeInMinutes * 60) {
            this.resetIdleTime();
        }
    }
}
