import "./inquiry-form.scss";

import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";
import { ValidationController } from "aurelia-validation";

import nameOf from "../../../common/name-of";
import { ApplicationEnum } from "../../../enums/enums";
import type { IBranchResponse } from "../../../interfaces/i-branch";
import type { IEnumResponse } from "../../../interfaces/i-enum";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import type { IUserResult } from "../../../interfaces/i-user";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import type { Inquiry } from "../../../models/inquiry";
import { UserService } from "../../../services/user-service";

@autoinject
@customElement("inquiry-form") // For Hospice & Palliative Inquiry
export class InquiryForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public inquiry: Inquiry;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public serviceTypeEnums: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public branches: IBranchResponse[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateBranch: (params: { applicationId: ApplicationEnum }) => void;
    private _userService: UserService;
    @observable({
        changeHandler: nameOf<InquiryForm>("teamMemberChanged")
    })
    public teamMember: ITypeaheadOptions;
    public isError: boolean = false;
    public dateInputOption = {
        isReadOnly: false,
        name: "date",
        placeholderText: "MM/DD/YYYY"
    };
    public patientPhoneValidation: IValidateCustomElement = {
        required: true,
        displayName: "Phone Number",
        matches: false
    };
    public contactPhoneValidation: IValidateCustomElement = {
        matches: true
    };
    public controller: ValidationController = null;
    public isPhoneNumberInValid: boolean = false;

    public constructor(userService: UserService, validationController: ValidationController) {
        this._userService = userService;
        this.controller = validationController;
    }

    public async attached() {
        if (this.inquiry && this.inquiry.assignedUser && this.inquiry.assignedUserId) {
            this.teamMember = {
                name: this.inquiry.assignedUser,
                value: this.inquiry.assignedUserId
            };
        } else {
            this.teamMember = null;
        }
        this.controller.subscribe((x) => {
            this.isPhoneNumberInValid = !!x.errors.find(
                (error) => error.rule.property.displayName == "primary-phone-number"
            );
        });
        this.updateBranch({ applicationId: this.inquiry.application });
    }

    public async getTeamList(filter: string, limit: number) {
        return this.userToTypeahead(
            await this._userService.getUsersByRole({
                include: true,
                term: filter,
                application: this.inquiry?.application
            })
        );
    }

    public teamMemberChanged(teamMember: IUserResult): void {
        if (teamMember) {
            this.inquiry.assignedUserId = teamMember.value as string;
            this.inquiry.assignedUser = teamMember.name as string;
        }
    }

    private userToTypeahead(users: IUserResult[]): ITypeaheadOptions[] {
        return users.map((user) => ({
            name: `${user.lastName}, ${user.firstName}`,
            value: user.id
        }));
    }

    public selectedBranch(branchId: string) {
        let branchDetail: IBranchResponse = null;
        if (this.branches.length > 0) {
            branchDetail = this.branches.find((branch) => branch.id === branchId);
        }
        this.inquiry.accountId = branchDetail.accountId;
    }
}
