import "./loading-indicator.scss";

import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";

export enum PageTakeOverLoading {
    Show = "loading:show",
    Hide = "loading:hide"
}

@autoinject
export class LoadingIndicator {
    private _subscriptions: Subscription[] = [];
    private _ea: EventAggregator;
    public loading: boolean = false;

    public constructor(ea: EventAggregator) {
        this._ea = ea;
        this.initSubscriptions();
    }

    private initSubscriptions() {
        this._subscriptions.push(
            this._ea.subscribe(PageTakeOverLoading.Show, () => {
                this.loading = true;
            })
        );
        this._subscriptions.push(
            this._ea.subscribe(PageTakeOverLoading.Hide, () => {
                this.loading = false;
            })
        );
    }

    public detached() {
        this._subscriptions?.forEach((sub) => sub?.dispose());
    }
}
