import { autoinject } from "aurelia-framework";
import momentTz from "moment-timezone";
import type { IRequestHandler } from "./i-handlers";

@autoinject()
export class TimeZoneHandler implements IRequestHandler {
    public async handle(request: Request): Promise<void> {
        request.headers.append("X-Time-Zone", momentTz.tz.guess());
    }
}
