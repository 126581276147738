import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { PhysicianRequired } from "../../../../../resources-vue/vue-models/physician-required";
import { PhysiciansService } from "../../../../../services/physician-service";
import type { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";

import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        TypeaheadInput
    },
    props: {
        referral: { type: Object, default: null },
        physicianRequired: { type: PhysicianRequired, default: null },
        physicianIndex: { type: Number, default: null }
    },
    data() {
        return {
            physicians: null as ITypeaheadOptions,
            _physicianService: null
        };
    },
    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
    },
    watch: {
        physicians(newValue, oldValue) {
            if (newValue) {
                // Review this
                this.referral.physicians.push(newValue.value);
            }
        }
    },
    methods: {
        async getPhysicianList(filter: string, limit: number) {
            const data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                application: this.referral.application,
                accountId: this.referral.accountId,
                providerIds: []
            });

            return data.items?.map((item: any) => {
                return {
                    name: item.name,
                    value: item.id
                };
            });
        },
        changePhysician(data: any) {
            this.physicians = data;
        }
    }
});
