<template>
    <TypeaheadInput
        :data="stateList"
        :value="selectedState"
        v-on:valueChanged="changeSelectedState"
        placeholder="Select State"
        :init-value="selectedState"
        :results-limit="stateList.length"
        :disabled="disabled"
        :showAllByDefault="true"
        :open-on-focus="true"
        v-on:blurredInput="emitBlur"
    />
</template>

<script src="./StateTypeahead"></script>
