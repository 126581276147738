import moment from "moment";
import DOMPurify from "dompurify";
import { MomentValueConverter } from "../../../services/date-format-service";
import { IIntakeCountResponse } from "../../../resources-vue/vue-interfaces/i-get-inquiry";
import { IVisitCountResponse } from "../../../resources-vue/vue-interfaces/i-task";
import { IAddressLongLat } from "../../../resources-vue/vue-interfaces/i-address";

export const formatPhone = (value: string) => {
    if (value) {
        if (value.length >= 10) {
            value = value.replace(/[^\d]/g, "");
        }
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
    return "";
};

export const formatPhoneNumber = (value: string) => {
    if (value) {
        let result = value.replace(/^(\d{3})(\d+)+$/, "($1) $2");
        return result.replace(/^\((\d{3})\) (\d{3})(\d+)+$/, "($1) $2-$3");
    }
    return "";
};

export const formatDate = (value: Date | string, format = "MM/DD/YYYY") => {
    if (value) {
        return moment(value).format(format);
    } else {
        return null;
    }
};

export const formatDateFromDate = (value: Date | string, dateFormat: string = "MM/DD/YYYY") => {
    if (value) {
        let theMoment = MomentValueConverter.dateOrStringToMoment(value);
        return theMoment.format(dateFormat);
    }
    return ".";
};

export const formatFullAddress = (address: IAddressLongLat) => {
    let addressLine2: string = address.AddressLine2 ? `${address.AddressLine2}, ` : "";
    let addressZipCodeFour: string = address.AddressZipCodeFour ? `- ${address.AddressZipCodeFour}` : "";

    return `${address.AddressLine1} ${addressLine2} ${address.AddressCity}, ${address.AddressStateCode} ${address.AddressZipCodeFive} ${addressZipCodeFour}`;
};

export const formatScheduledTime = (time: string) => {
    if (!time) {
        return "";
    }
    return new Date(time).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit"
    });
};

export const formatTimeInOut = (startTime: string, endTime: string) => {
    if (!startTime && !endTime) {
        return "";
    }
    return `${startTime || ""} - ${endTime || ""}`;
};

export const ObjectKeys = (obj: object) => {
    return obj ? Object.keys(obj) : [];
};

export const formatingDateOrTime = (value: object, format: string) => {
    if (value) {
        return moment(value).format(format);
    } else {
        return null;
    }
};

export const formatDateInput = (value: string) => {
    let result: string = value;
    if (result) {
        result = result.replace(/([a-zA-Z ])/g, "");
        result = result.replace(/^(\d{2})(\d+)+$/, "$1/$2");
        result = result.replace(/^(\d{2})\/(\d{2})(\d+)+$/, "$1/$2/$3");
        if (/^(\d{4})\-(\d{2})\-(\d{2})/.test(result)) {
            result = moment(value).format("MM/DD/YYYY");
        }
    }
    return result;
};

export const weatherClass = (id: number): string => {
    // https://openweathermap.org/weather-conditions
    if (id) {
        if (id >= 200 && id < 300) {
            return "wi-thunderstorm";
        } else if (id >= 300 && id < 400) {
            return "wi-drizzle";
        } else if (id >= 500 && id < 600) {
            return "wi-rain";
        } else if (id >= 600 && id < 700) {
            return "wi-snow";
        } else if ((id >= 700 && id < 800) || id == 905) {
            return "wi-atmosphere";
        } else if (id == 800 || id == 904) {
            return "wi-sunny";
        } else if (id == 801) {
            return "wi-fewcloud";
        } else if (id >= 802 && id < 900) {
            return "wi-cloud";
        } else if (id == 900) {
            return "wi-tornado";
        } else if (id == 901 || id == 902 || id == 962) {
            return "wi-hurricane";
        } else if (id == 903) {
            return "wi-cold";
        } else if (id == 906) {
            return "wi-hail";
        } else if (id == 951) {
            return "wi-sunny";
        } else if (id >= 952 && id < 960) {
            return "wi-windy";
        } else if (id == 960 || id == 961) {
            return "wi-thunderstorm";
        } else {
            console.warn("GetWeatherIcon toView(): Get weather icon value id didn't match.");
            return "";
        }
    } else {
        console.error("GetWeatherIcon toView(): Get weather icon value converter did not receive an id.");
        return "";
    }
};

export const formatZipCode = (zip: string) => {
    if (!!zip) {
        let unformattedZip = zip?.split("-").join("");
        if (unformattedZip?.length > 5) {
            let zipLength = unformattedZip.length > 9 ? 9 : zip.length;
            return `${unformattedZip.substr(0, 5)}-${unformattedZip.substr(5, 4)}`;
        } else {
            return unformattedZip;
        }
    } else {
        return zip;
    }
};

export const substringHighlighter = (value: string, filter: string) => {
    if (!filter) {
        return value;
    }
    let result: string = "";
    let position: number = 0;
    while (true) {
        let index: number = value.toLowerCase().indexOf(filter.toLowerCase(), position);
        if (index == -1) {
            result += value.substring(position);
            return result;
        }
        result += value.substring(position, index) + `<strong>${value.substr(index, filter.length)}</strong>`;
        position = index + filter.length;

        if (position >= value.length) {
            return result;
        }
    }
};

export const sanitizeMarkup = (dom: string) => {
    DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
        // set all elements owning target to target=_blank
        if ("target" in node) {
            node.setAttribute("target", "_blank");
            // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
            node.setAttribute("rel", "noopener noreferrer");
        }
        // set non-HTML/MathML links to xlink:show=new
        if (!node.hasAttribute("target") && (node.hasAttribute("xlink:href") || node.hasAttribute("href"))) {
            node.setAttribute("xlink:show", "new");
        }
    });
    return DOMPurify.sanitize(dom);
};

export const formatToUppercase = (value: string) => {
    if (!value) {
        return "";
    }
    return value?.toUpperCase();
};

export const clubAgencies = (arr: any) => {
    return arr.reduce((acc: any, val: any) => {
        const index = acc.findIndex((el: any) => el.application === val.application);
        if (index !== -1) {
            let newVal: any;
            acc.forEach((element: any) => {
                if (element.application == val.application) {
                    let count = element.count + val.count;
                    newVal = {
                        agencyId: val.agencyId,
                        application: val.application,
                        count: count
                    };
                }
            });
            const key = Object.keys(newVal)[2];
            acc[index][key] = newVal[key];
        } else {
            acc.push(val);
        }
        return acc;
    }, []);
};

export const clubAgenciesScheduler = (arr: any) => {
    return arr.reduce((acc: any, val: any) => {
        const index = acc.findIndex((el: any) => el.Application === val.Application);
        if (index !== -1) {
            let newVal: any;
            acc.forEach((element: any) => {
                if (element.Application == val.Application) {
                    let missedVisitCount = element.Counts.MissedVisitCount + val.Counts.MissedVisitCount;
                    let notYetStartedVisitCount =
                        element.Counts.NotYetStartedVisitCount + val.Counts.NotYetStartedVisitCount;
                    let unassignedVisitCount = element.Counts.UnassignedVisitCount + val.Counts.UnassignedVisitCount;

                    newVal = {
                        AgencyId: val.AgencyId,
                        Application: val.Application,
                        Counts: {
                            MissedVisitCount: missedVisitCount,
                            NotYetStartedVisitCount: notYetStartedVisitCount,
                            UnassignedVisitCount: unassignedVisitCount
                        }
                    };
                }
            });
            const key = Object.keys(newVal)[2];
            acc[index][key] = newVal[key];
        } else {
            acc.push(val);
        }
        return acc;
    }, []);
};
