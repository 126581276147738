
import { defineComponent } from "vue";

import { CalendarOptions } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

export default defineComponent({
    props: {
        updatedOptions: { type: Object }
    },

    components: {
        FullCalendar
    },

    data() {
        return {
            editable: true as boolean
        };
    },

    computed: {
        calendarOptions(): CalendarOptions {
            return {
                headerToolbar: false,
                plugins: [resourceTimelinePlugin, interactionPlugin],
                schedulerLicenseKey: "0329665787-fcs-1602782908",
                initialView: "resourceTimeline",
                editable: this.editable,
                eventDurationEditable: false,
                eventResize: (event: any) => {
                    event.revert();
                },
                slotDuration: {
                    days: 1
                },
                dayMaxEvents: 2,
                dayMaxEventRows: 2,
                resourceAreaWidth: 200,
                slotLabelFormat: [{ weekday: "short", month: "short", day: "numeric" }],
                ...this.updatedOptions
            };
        }
    }
});
