import { defineComponent } from "vue";

import { Container } from "aurelia-dependency-injection";

import {
    IEmployeeList,
    IReassignModelPayload,
    IReassignVisitDetails,
    IReassignVisitPayload
} from "../../vue-interfaces/i-reassign-visit";
import { ApplicationEnum } from "../../../enums/enums";
import { TaskService } from "../../../services/task.service";
import { ToastrService } from "../../../services/toastr.service";

import ScheduleVisitHomeCare from "../../vue-forms/ScheduleVisitPatient/ScheduleVisitHomeCare/ScheduleVisitHomeCare.vue";
import ScheduleVisitHomeHealth from "../../vue-forms/ScheduleVisitPatient/ScheduleVisitHomeHealth/ScheduleVisitHomeHealth.vue";
import ScheduleVisitHosPal from "../../vue-forms/ScheduleVisitPatient/ScheduleVisitHosPal/ScheduleVisitHosPal.vue";

export default defineComponent({
    inject: ["dialogRef"],
    components: {
        ScheduleVisitHosPal,
        ScheduleVisitHomeHealth,
        ScheduleVisitHomeCare
    },
    data() {
        return {
            dialogReference: this.dialogRef as any,
            options: {} as IReassignVisitDetails,
            applicationEnum: ApplicationEnum,
            isLoading: false,
            isSaving: false,
            employeeList: [] as IEmployeeList[],
            _toastrService: null,
            _taskService: null
        };
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._taskService = Container.instance.get(TaskService);
        this.options = Object.assign({}, this.dialogReference.data.visitDetails);
    },

    methods: {
        async reassignVisit(reassignDetails: IReassignVisitDetails) {
            if (!!reassignDetails) {
                try {
                    this.isSaving = true;
                    let reassignPayload: IReassignVisitPayload = {
                        AccountId: reassignDetails.AgencyId,
                        UserId: reassignDetails.EmpId,
                        Application: reassignDetails.Application,
                        EpisodeId: reassignDetails.EpisodeId,
                        PatientId: reassignDetails.PatientId,
                        TaskId: reassignDetails.Id,
                        TypeId: reassignDetails.TypeId,
                        ReasonId: reassignDetails.ReasonId
                    };
                    await this._taskService.reassignVisit(reassignPayload);
                    this._toastrService.success({
                        title: `Visit Reassigned`,
                        message: `Visit has been successfully Reassigned.`
                    });
                    // trigger callback
                    this.dialogReference.close("success");
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Reassigning Failed`,
                        message: `Reassigning Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.isSaving = false;
                }
            }
        },
        cancel() {
            this.dialogReference.close();
        },
        ok() {
            this.dialogReference.close("success");
        },
        addAnother() {
            this.dialogReference.close("reopen");
        },
        handleLoadingChange(value: boolean) {
            this.isLoading = value;
        },
        handleSavingChange(value: boolean) {
            this.isSaving = value;
        }
    }
});
