import Conversation from "./Conversation/Conversation.vue";
import NewConversation from "./NewConversation/NewConversation.vue";

const ConversationListRoutes: Array<any> = [
    {
        path: "/",
        redirect: "/conversation"
    },
    {
        path: "/central-messaging/clinicians/conversation/:id?",
        name: "conversation",
        component: Conversation
    },
    {
        path: "/central-messaging/clinicians/new-conversation/:id?",
        name: "new-conversation",
        component: NewConversation
        // meta: {
        //     menuName: "Referral",
        //     navItem: "tabReferral"
        // }
    }
];

export default ConversationListRoutes;
