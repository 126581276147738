import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum ContactRolesEnum {
    HealthcarePOA = 1 as any,
    FinancialPOA = 2 as any,
    DurablePOA = 4 as any,
    Guardian = 8 as any,
    Surrogate = 16 as any,
    LegalRepresentative = 32 as any
}
class ContactRolesClass extends EnumWithDescription<ContactRolesEnum> {
    public readonly enum = ContactRolesEnum;
    public readonly enumName: string = "ContactRolesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<ContactRolesEnum>> = new Map([
        [
            "HealthcarePOA",
            {
                value: ContactRolesEnum.HealthcarePOA,
                name: "Healthcare POA"
            }
        ],
        [
            "FinancialPOA",
            {
                value: ContactRolesEnum.FinancialPOA,
                name: "Financial POA"
            }
        ],
        [
            "DurablePOA",
            {
                value: ContactRolesEnum.DurablePOA,
                name: "Durable POA"
            }
        ],
        [
            "Guardian",
            {
                value: ContactRolesEnum.Guardian,
                name: "Guardian"
            }
        ],
        [
            "Surrogate",
            {
                value: ContactRolesEnum.Surrogate,
                name: "Surrogate"
            }
        ],
        [
            "LegalRepresentative",
            {
                value: ContactRolesEnum.LegalRepresentative,
                name: "Legal Representative"
            }
        ]
    ]);
}

export const ContactRoles = new ContactRolesClass();
