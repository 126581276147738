import { defineComponent } from "vue";

import { LookupService } from "../../../services/lookup-service";
import { Container } from "aurelia-dependency-injection";
import centralIntakeRoutes from "../central-messaging-routes";

import PatientList from "../../Common/PatientList/PatientList.vue";
import EmployeeList from "../../Common/EmployeeList/EmployeeList.vue";
import LevelTwo from "../../LevelTwo/LevelTwo.vue";
import TabsAnimatedHeader from "../../../resources-vue/vue-custom-elements/TabsAnimatedHeader/TabsAnimatedHeader.vue";
import DashboardHeader from "../../../resources-vue/vue-elements/DashboardHeader/DashboardHeader.vue";

export default defineComponent({
    components: {
        PatientList,
        EmployeeList,
        DashboardHeader,
        LevelTwo,
        TabsAnimatedHeader
    },

    data() {
        return {
            router: null,
            _lookupService: null,
            showBorder: true as boolean,
            showPatients: false as boolean,
            showEmployees: false as boolean,
            barColour: "border-bottom: 5px solid #ba1111",
            activeItem: null
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
        this.$watch(
            () => this.$route.params,
            () => {
                this.activeItem = this.$route.meta.menuName;
            }
        );
    },

    computed: {},

    methods: {
        goTo(path: string) {
            this.$router.push(path);
        },

        isActivePath(path: string) {
            return this.$route.matched?.some((route) => route.path == path);
        }
    }
});
