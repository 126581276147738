import { defineComponent } from "vue";

export default defineComponent({
    inject: ["dialogRef"],
    data(vm) {
        return {
            dialogReference: this.dialogRef as any,
            options: {}
        };
    },
    mounted() {
        this.options = this.dialogReference.data.promptOptions;
    },
    methods: {
        cancel() {
            this.dialogReference.close();
        },
        ok() {
            this.dialogReference.close("success");
        }
    }
});

export interface IPromptOptions {
    title?: string;
    message: string;
    additionalMessage?: string;
    okText?: string;
    cancelText?: string;
}
