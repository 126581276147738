import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeCareReassign = _resolveComponent("HomeCareReassign")!
  const _component_ProductReassign = _resolveComponent("ProductReassign")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.options.Application == _ctx.applicationEnum.HomeCare)
      ? (_openBlock(), _createBlock(_component_HomeCareReassign, {
          key: 0,
          "visit-details": _ctx.options,
          "is-saving": _ctx.isSaving,
          reassign: _ctx.reassignVisit,
          cancel: _ctx.cancel
        }, null, 8 /* PROPS */, ["visit-details", "is-saving", "reassign", "cancel"]))
      : _createCommentVNode("v-if", true),
    (
            _ctx.options.Application == _ctx.applicationEnum.AgencyCore ||
            _ctx.options.Application == _ctx.applicationEnum.AxxessHospiceFE ||
            _ctx.options.Application == _ctx.applicationEnum.AxxessPalliative
        )
      ? (_openBlock(), _createBlock(_component_ProductReassign, {
          key: 1,
          "visit-details": _ctx.options,
          "is-loading": _ctx.isLoading,
          "is-saving": _ctx.isSaving,
          "employee-list": _ctx.employeeList,
          reassign: _ctx.reassignVisit,
          cancel: _ctx.cancel
        }, null, 8 /* PROPS */, ["visit-details", "is-loading", "is-saving", "employee-list", "reassign", "cancel"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}