
import { defineComponent } from "vue";

import EmergencyTriage from "./EmergencyTriage/EmergencyTriage.vue";
import AdditionalPrepInfo from "./AdditionalPrepInfo/AdditionalPrepInfo.vue";
import EmergencyEvacuation from "./Evacuation/EmergencyEvacuation.vue";
import EmergencyComments from "./EmergencyComments/EmergencyComments.vue";

export default defineComponent({
    components: {
        EmergencyTriage,
        AdditionalPrepInfo,
        EmergencyEvacuation,
        EmergencyComments
    }
});
