import MessageCenter from "./MessageCenter.vue";
import ConversationListRoutes from "./message-center-routes";
import { RouteRecordRaw } from "vue-router";

export const centralIntakeRoutes: Array<RouteRecordRaw> = [
    {
        path: "/axxess-central/central-messaging/clinicians",
        component: MessageCenter,
        meta: {
            title: "Clinicians",
            nav: true,
            menuName: "Clinicians",
            navItem: "tabMessaging"
        },
        children: ConversationListRoutes
    }
];

export default centralIntakeRoutes;
