import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

import { ApplicationEnum } from "../../../enums/enums";
import { IReassignModelPayload, IReassignVisitDetails } from "../../../interfaces/i-reassign-visit";

@autoinject()
export class ScheduleVisitEmployeeDialog {
    public dialogController: DialogController;
    public options: IReassignVisitDetails;
    public applicationEnum: typeof ApplicationEnum = ApplicationEnum;
    public isLoading: boolean = false;
    public isSaving: boolean = false;

    public constructor(dialogController: DialogController) {
        this.dialogController = dialogController;
    }

    public activate(options: IReassignModelPayload) {
        if (options && options?.visitDetails) {
            this.options = Object.assign({}, options.visitDetails);
        }
    }

    public cancel() {
        this.dialogController.cancel();
    }
    public ok() {
        this.dialogController.ok();
    }
}
