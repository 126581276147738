import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum ApplicationNamesEnum {
    HomeHealth = 1 as any,
    HomeCare = 2 as any,
    Therapy = 4 as any,
    AxxessCAHPS = 8 as any,
    AxxessDDE = 16 as any,
    AxxessNala = 4096 as any,
    DdeBlackScreen = 32 as any,
    AxxessCARE = 64 as any,
    AxxessEnterprise = 128 as any,
    PatientFamilyPortal = 1024 as any,
    HospiceCAHPS = 8192 as any,
    AxxessRCM = -2 as any,
    AxxessHospice = -3 as any,
    AxxessBI = -4 as any,
    AxxessPalliative = 65536 as any,
    AxxessHospiceFE = 256 as any // Only used on FE side to check against -4 value of Axxess Hospice which is being used at few places
}

class ApplicationNamesClass extends EnumWithDescription<ApplicationNamesEnum> {
    public readonly enum = ApplicationNamesEnum;
    public readonly enumName: string = "ApplicationNamesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<ApplicationNamesEnum>> = new Map([
        [
            "HomeHealth",
            {
                value: ApplicationNamesEnum.HomeHealth,
                name: "Home Health"
            }
        ],
        [
            "HomeCare",
            {
                value: ApplicationNamesEnum.HomeCare,
                name: "Home Care"
            }
        ],
        [
            "Therapy",
            {
                value: ApplicationNamesEnum.Therapy,
                name: "Therapy"
            }
        ],
        [
            "AxxessCAHPS",
            {
                value: ApplicationNamesEnum.AxxessCAHPS,
                name: "Axxess CAHPS"
            }
        ],
        [
            "AxxessDDE",
            {
                value: ApplicationNamesEnum.AxxessDDE,
                name: "Axxess DDE"
            }
        ],
        [
            "DdeBlackScreen",
            {
                value: ApplicationNamesEnum.DdeBlackScreen,
                name: "DDE Black Screen"
            }
        ],
        [
            "AxxessCARE",
            {
                value: ApplicationNamesEnum.AxxessCARE,
                name: "Axxess CARE"
            }
        ],
        [
            "AxxessEnterprise",
            {
                value: ApplicationNamesEnum.AxxessEnterprise,
                name: "Axxess Enterprise"
            }
        ],
        [
            "PatientFamilyPortal",
            {
                value: ApplicationNamesEnum.PatientFamilyPortal,
                name: "Patient/Family Portal"
            }
        ],
        [
            "HospiceCAHPS",
            {
                value: ApplicationNamesEnum.HospiceCAHPS,
                name: "Hospice CAHPS"
            }
        ],
        [
            "AxxessRCM",
            {
                value: ApplicationNamesEnum.AxxessRCM,
                name: "Axxess RCM"
            }
        ],
        [
            "AxxessHospice",
            {
                value: ApplicationNamesEnum.AxxessHospice,
                name: "Hospice"
            }
        ],
        [
            "AxxessHospiceFE",
            {
                value: ApplicationNamesEnum.AxxessHospiceFE,
                name: "Hospice"
            }
        ],
        [
            "AxxessBI",
            {
                value: ApplicationNamesEnum.AxxessBI,
                name: "Axxess BI"
            }
        ],
        [
            "AxxessPalliative",
            {
                value: ApplicationNamesEnum.AxxessPalliative,
                name: "Palliative"
            }
        ]
    ]);
}

export const ApplicationNames = new ApplicationNamesClass();
