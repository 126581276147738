import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "days-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (value) => {
      return (_openBlock(), _createElementBlock("button", {
        class: "day-btn btn btn-secondary",
        onClick: ($event: any) => (_ctx.handleDaySelect(value.value, $event))
      }, _toDisplayString(value.name), 9 /* TEXT, PROPS */, _hoisted_2))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}