import { defineComponent, PropType } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { HomeCareReferral } from "../../../../../../resources-vue/vue-models/homecare-referral";
import { Address } from "../../../../../../resources-vue/vue-models/address";
import type { IAddress } from "../../../../../../resources-vue/vue-interfaces/i-address";
import type { IPhoneNumber } from "../../../../../../resources-vue/vue-interfaces/i-phone-number";
import type { IEnumResponse } from "../../../../../../resources-vue/vue-interfaces/i-enum";
import { enumToMap } from "../../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { RelationToReferral, PhoneTypeEnum } from "../../../../../../enums/enums";

import FormSection from "../../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ReferralAddressForm from "../../ReferralAddressForm/ReferralAddressForm.vue";
import PhoneNumber from "../../../../../../resources-vue/vue-custom-elements/PhoneNumber/PhoneNumber.vue";

export default defineComponent({
    components: {
        FormSection,
        ReferralAddressForm,
        PhoneNumber
    },
    props: {
        contact: { type: Object, default: null },
        isLoading: { type: Boolean, default: false },
        isEditable: { type: Boolean, default: false },
        isPalliative: { type: Boolean, default: false },
        patientPrimaryAddress: { type: Object as PropType<IAddress>, default: null },
        referral: { type: HomeCareReferral, default: null },
        relationshipsEnum: { type: Array as PropType<IEnumResponse[]>, default: [] },
        contactRolesEnum: { type: Array as PropType<IEnumResponse[]>, default: [] },
        cahpsSurveyContactEnum: { type: Array as PropType<IEnumResponse[]>, default: [] }
    },

    data() {
        return {
            addrSameAsPatientAddr: false as boolean,
            RelationToReferral: RelationToReferral,
            phoneNumberOptions: [
                PhoneTypeEnum.Home,
                PhoneTypeEnum.Mobile,
                PhoneTypeEnum.Work,
                PhoneTypeEnum.Facility
            ] as PhoneTypeEnum[],
            v$: useVuelidate(),
            enumToMap
        };
    },

    validations() {
        return {
            contact: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                relationship: {
                    required
                }
            }
        };
    },

    watch: {
        addrSameAsPatientAddr(newValue, oldValue) {
            if (newValue === true) {
                this.setAddressToPrimaryAddress();
                this.contact.isUsingPrimaryAddress = true;
            } else {
                this.contact.address = new Address();
                this.contact.isUsingPrimaryAddress = false;
            }
        },
        patientPrimaryAddress() {
            if (!!this.patientPrimaryAddress && !!this.contact && this.contact.isUsingPrimaryAddress) {
                this.setAddressToPrimaryAddress();
            }
        }
    },

    created() {
        if (!!this.contact) {
            if (!this.contact.address) {
                this.contact.address = new Address();
            } else if (this.contact.isUsingPrimaryAddress) {
                this.addrSameAsPatientAddr = this.contact.isUsingPrimaryAddress;
            }

            if (this.primaryNumberIndex(this.contact.phoneNumbers) < 0) {
                this.contact.phoneNumbers = this.contact.phoneNumbers.concat({
                    number: "",
                    extension: "",
                    type: 1,
                    isPrimary: true,
                    country: null,
                    ordinal: 1
                });
            }

            if (this.alternativeNumberIndex(this.contact.phoneNumbers) < 0) {
                this.contact.phoneNumbers = this.contact.phoneNumbers.concat({
                    number: "",
                    extension: "",
                    type: 0,
                    isPrimary: false,
                    country: null,
                    ordinal: 2
                });
            }
        }
    },
    methods: {
        setAddressToPrimaryAddress() {
            if (!!this.patientPrimaryAddress && !!this.contact) {
                this.contact.address = Object.assign({}, this.patientPrimaryAddress);
            }
        },
        primaryNumberIndex(phoneNumbers: IPhoneNumber[]) {
            if (phoneNumbers) {
                return phoneNumbers.findIndex((e) => e.isPrimary);
            }
            return 0;
        },
        alternativeNumberIndex(phoneNumbers: IPhoneNumber[]) {
            if (phoneNumbers) {
                return phoneNumbers.findIndex((e) => !e.isPrimary);
            }
            return 0;
        },
        updateAddressDetails(details: Address) {
            let address = this.contact.address;
            address.addressLine1 = details.addressLine1;
            address.addressLine2 = details.addressLine2;
            address.city = details.city;
            address.state = details.state;
            address.zipCode = details.zipCode;
            address.county = details.county;
            address.country = details.country;
            address.isValidated = details.isValidated;
            address.latitude = details.latitude;
            address.longitude = details.longitude;
        },
        updateAddressCountry(country: string) {
            this.contact.address.country = country;
        },
        updateAddressState(state: string) {
            this.contact.address.state = state;
        }
    }
});
