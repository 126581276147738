import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { PermissionManager } from "../../../common/utilities/permission-manager";
import { ApplicationEnum, PermissionActionEnum, ParentPermissionEnum } from "../../../enums/enums";

import PersonalMessageList from "../../Personal/components/PersonalMessageList/PersonalMessageList.vue";
import CentralDashboardHeader from "../../../resources-vue/vue-custom-elements/CentralDashboardHeader/CentralDashboardHeader.vue";
import ActivePatients from "./components/ActivePatients/ActivePatients.vue";
import Intake from "./components/Intake/Intake.vue";
import UnassignedVisits from "./components/UnassignedVisits/UnassignedVisits.vue";
import MarketingVideo from "./components/MarketingVideo/MarketingVideo.vue";
import NewsAndUpdates from "./components/NewsAndUpdates/NewsAndUpdates.vue";
import Scheduled from "./components/Scheduled/Scheduled.vue";

export default defineComponent({
    components: {
        CentralDashboardHeader,
        PersonalMessageList,
        ActivePatients,
        Intake,
        UnassignedVisits,
        MarketingVideo,
        NewsAndUpdates,
        Scheduled
    },
    data() {
        return {
            _permissionManager: null,
            showScheduler: null,
            showIntake: null,
            rowSpan: null,
            colSpan: null,
            element: HTMLImageElement
        };
    },

    async created() {
        this._permissionManager = Container.instance.get(PermissionManager);
        this.changeIframe();
    },

    mounted() {
        this.checkPermission();
    },

    methods: {
        checkPermission() {
            const isSchedulerView = this._permissionManager.checkPermission(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canView
            );
            const isSchedulerEdit = this._permissionManager.checkPermission(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canEdit
            );
            const isAddHospiceInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            );
            const isViewHospiceInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessHospiceFE
            );
            const isEditHospiceInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessHospiceFE
            );
            const isAddHospiceReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            );
            const isViewHospiceReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessHospiceFE
            );
            const isEditHospiceReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessHospiceFE
            );
            const isAddPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            );
            const isViewPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessPalliative
            );
            const isEditPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessPalliative
            );
            const isAddPalliativeReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            );
            const isViewPalliativeReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessPalliative
            );
            const isEditPalliativeReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessPalliative
            );
            const isAddHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.HomeCare
            );
            const isViewHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.HomeCare
            );
            const isEditHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.HomeCare
            );
            const isViewHomeHealthInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.AgencyCore
            );
            const isEditHomeHealthInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AgencyCore
            );
            const isViewHomeHealthReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canView,
                ApplicationEnum.AgencyCore
            );

            const isEditHomeHealthReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AgencyCore
            );

            this.showIntake =
                isAddHospiceInquiry ||
                isEditHospiceInquiry ||
                isViewHospiceInquiry ||
                isAddHospiceReferral ||
                isEditHospiceReferral ||
                isViewHospiceReferral ||
                isAddPalliativeInquiry ||
                isEditPalliativeInquiry ||
                isViewPalliativeInquiry ||
                isAddPalliativeReferral ||
                isEditPalliativeReferral ||
                isViewPalliativeReferral ||
                isAddHomeCareInquiry ||
                isViewHomeCareInquiry ||
                isEditHomeCareInquiry ||
                isViewHomeHealthInquiry ||
                isEditHomeHealthInquiry ||
                isViewHomeHealthReferral ||
                isEditHomeHealthReferral;

            this.showScheduler = isSchedulerView || isSchedulerEdit;

            if (this.showIntake && !this.showScheduler) {
                this.colSpan = "col-span-1";
            } else {
                this.colSpan = "col-span-2";
            }
        },

        changeIframe() {
            let frames = [
                "https://www.youtube.com/embed/oaHGwPuYsnU?rel=0&amp;autohide=1&amp;showinfo=0&amp;theme=light&amp;color=b8223b&amp;wmode=opaque&amp;vq=hd720",
                "https://www.youtube.com/embed/PvexmWMy--E"
            ];

            setInterval(() => {
                let url = frames.shift();
                frames.push(url);
                const iframe = $("#hh-video") as any;
                iframe.attr("src", url);
            }, 15000);
        }
    }
});
