<template>
    <FormSection title="Preadmission Notes" :full-height="true" :application-id="application">
        <template #content>
            <div class="d-flex flex-column px-3 py-3" style="width: 900px">
                <HHPreadmissionForm
                    :application="application"
                    :accountId="accountId"
                    :show-note-form="showNoteForm"
                    :referral-id="referralId"
                    :reset-list="resetList"
                    :referral="referral"
                    :closeNoteForm="closeNoteForm"
                    ref="preadmissionForm"
                />
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary ml-2 mb-2" @click="openNoteForm()">Add Preadmission Note</button>
                </div>
                <Pagination ref="hhNotesList" :page-changed="pageChangedCallback" :is-loading="isLoading">
                    <table class="table table-responsive-md border border-gray">
                        <thead>
                            <tr class="bg-table-header">
                                <th>Title</th>
                                <th>Date</th>
                                <th>User</th>
                                <th class="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody v-if="isLoading || isError || !hasData">
                            <!-- LOADING -->
                            <tr v-for="i of loadingCount" class="table-loading" v-if="isLoading">
                                <td v-for="i of columns">
                                    <span class="shimmer-loading"></span>
                                </td>
                            </tr>
                            <!-- EMPTY -->
                            <tr class="table-empty" v-if="!isLoading && !isError && !hasData">
                                <td :colspan="columns">
                                    <h3>No Preadmission Notes.</h3>
                                </td>
                            </tr>
                            <!-- ERROR -->
                            <tr class="table-error" v-if="!isLoading && isError">
                                <td :colspan="columns">
                                    <ErrorMessageHandler title="Preadmission Notes" :try-again="resetList" />
                                </td>
                            </tr>
                        </tbody>
                        <HHPreadmissionNotesRow
                            v-else
                            v-for="note of page.items"
                            :note="note"
                            :columns="columns"
                            :reset-list="resetList"
                            :referral-id="referralId"
                            :referral="referral"
                            :accountId="accountId"
                            :application="application"
                        /></table
                ></Pagination>
                <footer class="d-flex justify-content-center">
                    <button
                        @click="closePreadmissionDialog()"
                        type="button"
                        class="btn btn-xs mr-2 btn-outline-primary"
                    >
                        Close
                    </button>
                </footer>
            </div>
        </template>
    </FormSection>
</template>

<script src="./HHPreadmissionNotes.ts"></script>
