import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, helpers, requiredIf } from "@vuelidate/validators";
import { mapState } from "pinia";

import {
    formatDateInput,
    formatDate,
    formatZipCode
} from "../../../../../../common/vue-helpers/modifiers/value-modifier";
import { useCreateReferralHosPal } from "../../../store/useCreateReferralHosPal";

import PlacesAutocomplete from "../../../../../../resources-vue/vue-custom-elements/PlacesAutocomplete/PlacesAutocomplete.vue";
import StateTypeahead from "../../../../../../resources-vue/vue-custom-elements/StateTypeahead/StateTypeahead.vue";
import PhoneNumber from "../../../../../../resources-vue/vue-custom-elements/PhoneNumber/PhoneNumber.vue";
import EmptyTextHandler from "../../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import DatePicker from "../../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        PlacesAutocomplete,
        StateTypeahead,
        PhoneNumber,
        EmptyTextHandler,
        DatePicker
    },

    validations() {
        return {
            patient: {
                priorHospice: {
                    name: {
                        required: requiredIf(() => {
                            return this.patient.isTransfer;
                        })
                    },
                    benefitPeriodStartDate: {
                        required: requiredIf(() => {
                            return this.patient.isTransfer;
                        })
                    }
                },
                benefitPeriodNumber: {
                    required: helpers.withMessage(
                        "Benefit Period Number is requried.",
                        requiredIf(() => {
                            return this.patient.isTransfer;
                        })
                    ),
                    maxLength: helpers.withMessage("Benefit Period Number is invalid.", maxLength(2))
                }
            }
        };
    },
    props: {
        benefitPeriodStartDate: {
            type: String
        },
        benefitPeriodEndDate: {
            type: String
        },
        homePhoneNumberOptions: {
            type: Array
        },
        isReadOnly: {
            type: Boolean
        },
        handleDateChange: {
            type: Function
        }
    },
    data() {
        return {
            formatDate,
            formatDateInput,
            formatZipCode,
            v$: useVuelidate()
        };
    },
    computed: {
        ...mapState(useCreateReferralHosPal, { patient: "referral" })
    },
    methods: {
        handleFaceToFace() {
            this.patient.priorHospice.faceToFaceObtained = !this.patient.priorHospice.faceToFaceObtained;
        },
        handleDate(date: any, name: any) {
            if (name === "admissionDate") {
                this.patient.priorHospice.admissionDate = date;
            }
            if (name === "transferDate") {
                this.patient.priorHospice.transferDate = date;
            }
        },
        updateSelectedState(value: any) {
            this.patient.priorHospice.transferHospiceAddress.primaryState = value;
        },
        updateprefillState(value: any) {
            this.patient.priorHospice.transferHospiceAddress.state = value;
        },
        handleDateInputChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.handleDate(value, name);
        }
    }
});
