import { Container } from "aurelia-dependency-injection";
import { defineComponent } from "vue";

import { AnalyticsService } from "../../../../services/analytics.service";

export default defineComponent({
    data() {
        return {
            logCategory: "help-center",
            helpSearch: "",
            analytics: null as AnalyticsService
        };
    },

    created() {
        this.analytics = Container.instance.get(AnalyticsService);
    },

    computed: {
        enableSearch(): string {
            return this.helpSearch.trimLeft();
        }
    },

    methods: {
        enterKeyPress() {
            if (this.enableSearch) {
                this.helpCenterSearch();
            }
        },

        helpCenterSearch() {
            const url = "http://www.axxess.com/help/search/?q=" + this.helpSearch;
            this.analytics.logEvent({
                category: this.logCategory,
                action: "User-Searched",
                name: this.helpSearch
            });
            window.open(encodeURI(url));
        },

        clearSearch() {
            this.helpSearch = "";
        }
    }
});
