
import { defineComponent } from "vue";

import { ITypeaheadOptions } from "../../../../../interfaces/i-typeahead";

import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        TypeaheadInput
    },

    props: {
        fileDetail: {
            type: Object,
            default: null
        },
        getFileIcon: {
            type: Function,
            default: () => {}
        },
        uploading: {
            type: Boolean,
            default: null
        },
        searchedTypes: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            selectedUploadType: undefined
        };
    },

    methods: {
        uploadTypeChanged(newValue: ITypeaheadOptions) {
            if (newValue) {
                this.fileDetail.type = newValue;
            }
        },

        removeFile() {
            this.$emit("remove-file");
        }
    }
});
