import { ApplicationEnum } from "../../enums/enums";

export class ScheduleVisitHomeHealthModel {
    public agencyId: string;
    public application: ApplicationEnum;
    public userId: string;
    public timeZone: string;
    public episodeId: string;
    public patientId: string;
    public disciplineTask: number;
    public customId: number;
    public version: number;
    public visitDates: string[];
    public discipline: number;
    public postToAxxessCare?: boolean;

    public constructor(data?: ScheduleVisitHomeHealthModel) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
