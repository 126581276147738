<template>
    <input
        ref="autocompleteField"
        type="text"
        autocomplete="off"
        class="form-control"
        :placeholder="placeholder"
        :value="street"
        @blur="emitBlur"
        @change="
            (e) => {
                $emit('update:street', e.target.value);
            }
        "
        :disabled="disabled"
    />
</template>

<style src="./PlacesAutocomplete.scss"></style>
<script src="./PlacesAutocomplete"></script>
