import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import { PreadmissionNote } from "../../../../../resources-vue/vue-models/preadmission-note-model";
import { ToastrService } from "../../../../../services/toastr.service";
import { ReferralService } from "../../../../../services/referral-service";
import { TemplateService } from "../../../../../services/template-service";
import { ApplicationEnum } from "../../../../../enums/enums";
import { Template } from "../../../../../models/template";

import NewTypeahead from "../../../../../resources-vue/vue-custom-elements/NewTypeahead/NewTypeahead.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        NewTypeahead,
        FormSection
    },

    props: {
        note: {
            default: null
        },
        referralId: {
            type: String,
            default: ""
        },
        isReadOnly: {
            type: Boolean,
            default: false
        },
        resetList: {
            type: Function
        },
        showNoteForm: {
            type: Boolean,
            default: false
        },
        closeNoteForm: {
            type: Function
        },
        application: {
            type: Number
        },
        accountId: {
            type: String
        }
    },
    data(vm) {
        return {
            _toastrService: null,
            _templateService: null,
            _referralService: null,
            selectedTemplate: null,
            newNote: new PreadmissionNote(),
            showTemplateTypeahead: false,
            v$: useVuelidate()
        };
    },
    validations() {
        return {
            newNote: {
                title: {
                    required: requiredIf(() => {
                        return this.showNoteForm;
                    })
                },
                notes: {
                    required: requiredIf(() => {
                        return this.showNoteForm;
                    })
                }
            }
        };
    },
    watch: {
        async selectedTemplate(newValue: Template) {
            let selectedTemplate = newValue;
            if (!selectedTemplate?.id) {
                return;
            }
            try {
                let queryParam = { accountId: selectedTemplate.accountId, application: this.application };
                let template = await this._templateService.getTemplateById(selectedTemplate.id, queryParam);
                if (this.newNote.notes?.length > 0) {
                    this.newNote.notes += `\n\n${template.description}`;
                } else {
                    this.newNote.notes = template.description;
                }
            } catch (e) {
                console.error(e);
            }
        }
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._templateService = Container.instance.get(TemplateService);
    },
    mounted() {
        this.showTemplateTypeahead = true;
        if (!this.note) {
            return;
        }
        this.newNote.title = this.note.title;
        this.newNote.notes = this.note.notes;
    },
    computed: {
        isApplicationPalliative(): boolean {
            return this.application == ApplicationEnum.AxxessPalliative;
        }
    },
    methods: {
        closeNote() {
            this.newNote = new PreadmissionNote();
            this.v$.$reset();
            this.closeNoteForm();
        },
        closeReadOnlyForm() {
            this.closeNoteForm();
        },
        async saveNote() {
            const validated = await this.v$.$validate();
            if (validated) {
                if (this.note?.id) {
                    this.updateNote();
                } else {
                    this.createNote();
                }
            }
        },
        async updateNote() {
            try {
                this.newNote.accountId = this.accountId;
                this.newNote.application = this.application;
                await this._referralService.updatePreadmissionNotes(this.referralId, this.note.id, this.newNote);
                this.resetList();
                this._toastrService.success({
                    title: "Success",
                    message: "Note has been successfully saved!"
                });
                this.closeNote();
            } catch (err) {
                console.error(err);
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while updating the note. Please try again.`
                });
            }
        },
        async createNote() {
            try {
                this.newNote.accountId = this.accountId;
                this.newNote.application = this.application;
                await this._referralService.createPreadmissionNotes(this.referralId, this.newNote);
                this.resetList();
                this._toastrService.success({
                    title: "Success",
                    message: "Note has been successfully saved!"
                });
                this.closeNote();
            } catch (err) {
                console.error(err);
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while adding the note. Please try again.`
                });
            }
        },
        async getTemplateList(term: string, limit: number = 10) {
            try {
                let templates = await this._templateService.getTemplateByPage({
                    term: term,
                    page: 1,
                    pageLength: limit,
                    application: this.application
                });
                return templates.items;
            } catch (e) {
                console.error(e);
                return [];
            }
        }
    }
});
