import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum ReferralReadiness {
    ConsentsCompleted = 1,
    HospiceOrderReceived = 2,
    FacetoFaceVisitObtained = 4
}
class ReferralReadinessClass extends EnumWithDescription<ReferralReadiness> {
    public readonly enum = ReferralReadiness;
    public readonly enumName: string = "ReferralReadiness";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<ReferralReadiness>> = new Map([
        [
            "ConsentsCompleted",
            {
                value: ReferralReadiness.ConsentsCompleted,
                name: "Consents Completed"
            }
        ],
        [
            "HospiceOrderReceived",
            {
                value: ReferralReadiness.HospiceOrderReceived,
                name: "Hospice Order Received"
            }
        ],
        [
            "FacetoFaceVisitObtained",
            {
                value: ReferralReadiness.FacetoFaceVisitObtained,
                name: "Face-to-Face Visit Obtained"
            }
        ]
    ]);
}

export const ReferralRead = new ReferralReadinessClass();
