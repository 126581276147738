import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import type { IPatientTags } from "../interfaces/i-patient-tags";
import type { IGetPatientTag, IGetProviderItem } from "../resources-vue/vue-interfaces/i-providers-service";
import type { IAddTag, IApplicationAndAccountQueryParam } from "../resources-vue/vue-interfaces/i-get-referral";
import { OperationSettings } from "../models/operation-settings";

@autoinject()
export class ProvidersService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/meta";
    private providerList: IGetProviderItem[] = [];

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async refreshProviders() {
        this.providerList = await this._httpClient.build(`${this._baseUrl}/providers`).fetch<IGetProviderItem[]>();
        return this.providerList;
    }

    public async getProvider() {
        if (this.providerList.length) {
            return this.providerList;
        }
        return await this.refreshProviders();
    }

    public async getActivePatientTags(patientTagsQuery: IPatientTags) {
        return await this._httpClient
            .build(`${this._baseUrl}/tag`)
            .useQueryString(patientTagsQuery)
            .fetch<IGetPatientTag[]>();
    }

    public async getOperationSettings(providerId: string, operationSettingsQuery: IApplicationAndAccountQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/providers/${providerId}/operational-settings`)
            .useQueryString(operationSettingsQuery)
            .fetch<OperationSettings>();
    }

    public async addTag(payload: IAddTag) {
        return await this._httpClient.build("/api/v1/agency/add-tag").useMethod("POST").useBody(payload).fetch();
    }
}
