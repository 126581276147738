import { ApplicationEnum, ApplicationNameEnum } from "../../enums/enums";
import type { IAddressLongLat } from "../vue-interfaces/i-address";
import type { ILocation } from "../vue-interfaces/i-location";
import type { IMarkerOptions } from "../vue-interfaces/i-marker";
import type { ITask } from "../vue-interfaces/i-task";
import type { IAddMarkerOptions, IGeoCodeResponse } from "../vue-interfaces/i-map";
import { AgencyService } from "../../services/agency.service";
import { GoogleMapsService } from "../../services/google-maps.service";
import { ProductService } from "../../services/product.service";
import eventBus from "../../utilities/eventBus";

import GoogleMaps = google.maps;

export class Marker implements IMarkerOptions {
    private _currentItem: number = 0;
    private _prodService: ProductService;
    public id: number;
    public tasks: Array<ITask> = [];
    public address: string;
    public gm: GoogleMapsService;
    public agencyService: AgencyService;
    public map: GoogleMaps.Map;
    public location: any;
    public bounds: GoogleMaps.LatLngBounds;
    public marker: GoogleMaps.Marker;
    public info: GoogleMaps.InfoWindow;
    public label: string;

    public constructor(options: IMarkerOptions) {
        Object.assign(this, options || {});
        this.id = Date.now();
        this._prodService = new ProductService();

        eventBus.on("marker:info:close", (id: number) => {
            if (this.id !== id) {
                this.closeInfoWindow();
            }
        });
    }

    public get _length(): number {
        return this.tasks.length;
    }

    public get _selectedTask(): ITask {
        if (this.tasks.length > 0) {
            return this.tasks[this._currentItem];
        }
        throw new Error("get _selectedTask(): Tasks list is empty.");
    }

    public getLocationFromCoords(coords: GoogleMaps.LatLngLiteral, label: string, allLocationsCoords: string[]) {
        this.location = {
            lat: Number(coords.lat),
            lng: Number(coords.lng)
        };
        this.label = label;
        this.createMarker(allLocationsCoords);
    }

    public async getLocationFromAddress(label: string, allLocationsCoords: string[]): Promise<void> {
        await this.gm.getCoordsFromAddress(this.address, (result: IGeoCodeResponse) => {
            this.location = {
                lat: Number(result.lat),
                lng: Number(result.lng)
            };
            this.label = label;
            this.createMarker(allLocationsCoords);
        });
    }

    public getUpdatedLatLng(lat: any, lng: any, allLocationCoords: string[]): any {
        let latLngStr = lat + "," + lng;

        if (!allLocationCoords.includes(latLngStr)) {
            return { lat, lng };
        }
        return this.getUpdatedLatLng(lat, lng + 0.0001, allLocationCoords);
    }

    public createMarker(allLocationCoords: string[]): void {
        try {
            if (allLocationCoords.includes(this.location.lat + "," + this.location.lng)) {
                const res = this.getUpdatedLatLng(this.location.lat, this.location.lng + 0.0001, allLocationCoords);
                this.location = {
                    lat: res.lat,
                    lng: res.lng
                };
            }
            if (this.location.lat && this.location.lng) {
                let options: IAddMarkerOptions = {
                    position: this.location,
                    map: this.map,
                    animationType: "drop",
                    label: { text: this.label, color: "white" },
                    type: "visit"
                };
                this.bounds.extend(this.location);
                this.marker = this.gm.addMarker(options);
                this.createInfoWindow();
                console.log(`MARKER: Created for ${this.address}`);
                allLocationCoords.push(this.location.lat + "," + this.location.lng);
                eventBus.emit("marker:created", this);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public deleteMarker(): void {
        if (this.marker) {
            this.marker.setMap(null);
        }
    }

    public createInfoWindow(): void {
        let content: string = this.getInfoWindowContent();
        this.info = this.gm.addInfoWindow({
            content: content
        });
    }

    public updateInfoWindow(): void {
        let content: string = this.getInfoWindowContent();
        this.info.setContent(content);
        this.openInfoWindow();
    }

    public getInfoWindowContent(): string {
        let task: ITask = this._selectedTask;
        let pa: IAddressLongLat = task.PatientAddress;
        let lat: number | (() => number) = this.location.lat; // latitude
        let lng: number | (() => number) = this.location.lng; // longitude
        let tempApplicationId: ApplicationEnum;

        if (typeof lat === "function") {
            lat = lat();
        }
        if (typeof lng === "function") {
            lng = lng();
        }
        if (task.Application === 256) {
            tempApplicationId = ApplicationEnum.AxxessHospice;
        } else {
            tempApplicationId = task.Application;
        }
        // latitude and longitude from local storage

        let userLocalStorage: ILocation = JSON.parse(localStorage["axxess-user-location"]);
        let productPillClassName: string = this._prodService.getProductMarkupClassName(tempApplicationId);
        let mapUrl: string = `https://www.google.com/maps/dir/'${userLocalStorage.lat},${userLocalStorage.lon}'/'${lat},${lng}'`;
        let addressLine2: string = pa.AddressLine2 ? `${pa.AddressLine2}, ` : "";
        let addressZipCodeFour: string = pa.AddressZipCodeFour ? `- ${pa.AddressZipCodeFour}` : "";
        let productPill = "";

        if (productPillClassName.length > 0) {
            productPillClassName = `pill-${productPillClassName}`;
        }

        if (this.agencyService.getHasMultipleClinicalProds()) {
            productPill = `<span class="pill ${productPillClassName}" > ${ApplicationNameEnum[tempApplicationId]} </span>`;
        }

        let content: string = ` <div id="${this.id}" class="tasks-marker">
                            <big><b>${task.patientFullName}</b></big>
                            ${productPill}
                            <span>${task.VisitName}</span>
                            <span>${pa.AddressLine1} ${addressLine2}</span>
                            <span>${pa.AddressCity}, ${pa.AddressStateCode} ${pa.AddressZipCodeFive} ${addressZipCodeFour}</span>
                            <a target="_blank" href="${mapUrl}">Get Directions</a>`;
        if (this._length > 1) {
            content += `<footer>
                            <i class="fas fa-chevron-circle-left"></i>
                            <span class="text-center">Visit ${this._currentItem + 1} of ${this._length}</span>
                            <i class="fas fa-chevron-circle-right"></i>
                        </footer>`;
        }
        content += `<div>`;
        return content;
    }

    public openInfoWindow(): void {
        let task: ITask = this._selectedTask;
        eventBus.emit("marker:info:close", this.id);
        task.showDetails = true;
        this.info.open(this.map, this.marker);
        eventBus.emit("marker:task:changed", this._selectedTask);
    }

    public closeInfoWindow(): void {
        let task: ITask = this._selectedTask;

        task.showDetails = false;
        this.info.close();
        this._currentItem = 0;
        this.gm.changeMarkerIcon(this.marker);
    }

    public toggleInfoWindow(): void {
        let task: ITask = this._selectedTask;

        if (!task.showDetails) {
            this.openInfoWindow();
        } else {
            this.closeInfoWindow();
        }
    }

    public showTask(task: ITask): void {
        this._currentItem = this.tasks.findIndex((item: ITask) => item.Id === task.Id);
        this.updateInfoWindow();
    }

    public handleBwdNavigation(): void {
        let ci: number = this._currentItem;
        ci--;

        if (ci < 0) {
            ci = this._length - 1;
        }

        this._currentItem = ci;
        this.updateInfoWindow();
    }

    public handleFwdNavigation(): void {
        let ci: number = this._currentItem;
        ci++;
        if (ci > this._length - 1) {
            ci = 0;
        }
        this._currentItem = ci;
        this.updateInfoWindow();
    }
}
