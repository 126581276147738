import { defineComponent } from "vue";
import Chart from "primevue/chart";
import { Container } from "aurelia-dependency-injection";

import { PatientService } from "../../../../../services/patient.service";
import { clubAgencies } from "./../../../../../common/vue-helpers/modifiers/value-modifier";
import {
    getProductFullName,
    getProductColorClass
} from "../../../../../common/vue-helpers/modifiers/application-modifiers";
import { ApplicationNames, ApplicationEnum } from "../../../../../enums/enums";

import CardTemplate from "../../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";

export default defineComponent({
    components: {
        Chart,
        CardTemplate
    },

    data() {
        return {
            chartData: null,
            chartOptions: null,
            _patientService: null,
            activePatientData: [],
            count: [],
            countPercentage: [],
            labels: [],
            colors: [],
            ApplicationNamesEnum: ApplicationNames,
            active: {},
            isLoading: true,
            hasError: false,
            getProductColorClass
        };
    },
    async created() {
        this._patientService = Container.instance.get(PatientService);
    },
    async mounted() {
        await this.getActivePatientsCount();
        this.chartData = this.setChartData();
        this.chartOptions = this.setChartOptions();
    },

    methods: {
        async getActivePatientsCount() {
            try {
                this.hasError = false;
                this.isLoading = true;

                this.activePatientData = clubAgencies(
                    await this._patientService.getPatientsCount({
                        Status: 1
                    })
                );

                this.activePatientData.forEach((item) => {
                    delete item.agencyId;
                    this.count.push(item.count);
                    if (item.application == ApplicationEnum.AxxessHospiceFE) {
                        item.application = ApplicationEnum.AxxessHospice;
                    }
                    this.labels.push(getProductFullName(item.application));

                    switch (item.application) {
                        case ApplicationEnum.AgencyCore:
                            this.colors.push("rgba(164, 26, 54, 1)");
                            break;
                        case ApplicationEnum.HomeCare:
                            this.colors.push("rgba(63, 92, 117, 1)");
                            break;
                        case ApplicationEnum.AxxessHospice:
                            this.colors.push("rgba(129, 0, 154, 1)");
                            break;
                        case ApplicationEnum.AxxessPalliative:
                            this.colors.push("rgba(226, 59, 137, 1)");
                            break;
                    }
                });
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.body);

            return {
                labels: this.labels,
                datasets: [
                    {
                        label: "Patients",
                        borderWidth: 1,
                        data: this.count,
                        backgroundColor: this.colors,
                        hoverOffset: 3
                    }
                ]
            };
        },

        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue("--text-color");

            return {
                responsive: true,
                angle: 360,
                plugins: {
                    legend: {
                        display: false,
                        onClick: null,
                        position: "right",
                        labels: {
                            boxWidth: 0,
                            usePointStyle: false,
                            color: textColor,
                            font: {
                                family: "Open sans",
                                size: 14
                            }
                        }
                    },

                    tooltip: {
                        enabled: true,
                        usePointStyle: false,
                        displayColors: false,
                        backgroundColor: "rgba(254, 254, 255, 0.85)",
                        borderColor: "rgba(0, 0, 0, 1)",
                        bodyColor: "rgba(0, 0, 0, 1)",
                        borderWidth: 1,
                        callbacks: {
                            label: (context: any) => {
                                this.active = context;
                                let label = [context.label];

                                label.push(context.dataset.label + ":" + context.formattedValue);

                                return label;
                            }
                        }
                    }
                }
            };
        }
    }
});
