import { ApplicationEnum } from "../enums/enums";

export class ProductService {
    public getProductMarkupClassName(id: number): string {
        const appId = Number(id);
        switch (appId) {
            case 1:
                return "agencycore";
            case 2:
                return "homecare";
            case -3:
                return "hospice";
            case 65536:
                return "palliative";
            default:
                return "";
        }
    }

    public getProductClassName(applicationName: string): string {
        switch (applicationName) {
            case "Home Health":
                return "agencycore";
            case "Home Care":
                return "homecare";
            case "Hospice":
                return "hospice";
            case "Palliative":
                return "palliative";
            case "Axxess CARE":
                return "axxess-care";
            case "Axxess DDE":
                return "axxess-dde";
            case "DDE Black Screen":
                return "axxess-dde";
            case "Axxess CAHPS":
                return "axxess-cahps";
            default:
                return "";
        }
    }

    public getApplicationId(applicationId: number): ApplicationEnum {
        if (applicationId === ApplicationEnum.AxxessHospiceFE) {
            applicationId = ApplicationEnum.AxxessHospice;
        }
        return applicationId;
    }
}
