import { defineComponent } from "vue";

import { DmeNeeded } from "../../../../../resources-vue/vue-models/dme-needed";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";
import CreateDmeNeededRow from "./CreateDmeNeededRow.vue";

export default defineComponent({
    components: {
        FormSection,
        CreateDmeNeededRow,
        CommentInput
    },
    props: {
        referral: { type: Object, default: null }
    },
    data() {
        return {
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string },
            showTemplate: false
        };
    },
    mounted() {
        this.addEquipment();
    },
    methods: {
        addEquipment() {
            let dmeNeeded: DmeNeeded = new DmeNeeded();
            this.referral.dmeNeeded.push(dmeNeeded);
        },
        resultUpdatedAppended(value: string) {
            this.referral.otherDME += value;
        },
        resultUpdated(value: string) {
            this.referral.otherDME = value;
        }
    }
});
