import { PropType, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapActions } from "pinia";
import { type CalendarOptions } from "@fullcalendar/core";

import { EmployeeCalendarModel } from "../../../EmployeeCalendar/EmployeeCalendar.model";
import { ITaskByUserResponse } from "../../../../../services/task.service";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum,
    EmployeeStatusEnum,
    TestingAgenciesEnum
} from "../../../../../enums/enums";
import { NavigationService } from "../../../../../services/navigation.service";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { EmployeeCalendarContent } from "../../../../../pages/central-calendar/employee-calendar-content";
import { formatName } from "../../../../../common/format-name";
import { GetProductClass } from "../../../../../resources/value-converters/get-product-class";
import { FormatPhoneNumber } from "../../../../../resources/value-converters/format-phone";
import { ScheduleLegendState } from "../../../../../pages/central-calendar/central-calendar";
import config from "../../../../../common/config";
import { BulkReassign } from "../../../../../resources-vue/vue-models/bulk-reassign";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { formatScheduledTime } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import FullCalendar from "../../../../../resources-vue/vue-elements/FullCalendar/FullCalendar.vue";

export const nonEvvVisitStatus = {
    notYetStarted: ["not yet due", "not yet started"],
    inProgress: ["saved"],
    completed: [
        "completed",
        "completed (pending qa review)",
        "submitted",
        "submitted pending co-signature",
        "submitted with signature",
        "sent to physician (electronically)",
        "to be sent to physician",
        "exported",
        "export ready",
        "not exported",
        "sent to physician",
        "missed visit",
        "missed visit(complete)",
        "missed visit(pending)",
        "missed visit(return)",
        "returned w/ physician signature",
        "returned for clinician signature",
        "returned w/ physician signature",
        "returned for review",
        "reopened"
    ]
};

export default defineComponent({
    props: {
        startDate: { type: Date },
        endDate: { type: Date },
        usersList: { type: Array as PropType<EmployeeCalendarModel[]>, default: [] },
        tasksList: { type: Array, default: [] },
        tasksMap: { default: null },
        onCalendarRefresh: { type: Function }
    },
    components: {
        FullCalendar
    },

    data(vm) {
        return {
            isLoading: false as boolean,
            _navigationService: null,
            _permissionManager: null,
            _taskService: null,
            _toastrService: null,
            _branchService: null,
            employeeCalendarContent: null,
            dialogRef: null,
            editable: true,
            eventLimit: 4 as number,
            userNamesMap: null,
            isTestAgency: false as boolean,
            employeeStatuses: Object.create(EmployeeStatusEnum)
        };
    },
    created() {
        this._navigationService = Container.instance.get(NavigationService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this.employeeCalendarContent = Container.instance.get(EmployeeCalendarContent);
    },
    computed: {
        calendarOptions(): CalendarOptions {
            return {
                resources: this.userDetails,
                resourceOrder: "LastName, FirstName",
                resourceGroupField: "groupId",
                resourceAreaWidth: "100%",
                resourceAreaColumns: [
                    {
                        headerContent: "Employees"
                    },
                    {
                        headerContent: "Not Yet Started",
                        cellContent: (arg) => {
                            return {
                                html: this.generateEvent(
                                    arg.resource.extendedProps.tasks.notYetStarted,
                                    arg.resource.extendedProps
                                )
                            };
                        },
                        cellDidMount: (arg) => {
                            this.mountVisitTooltip(arg, arg.resource.extendedProps.tasks.notYetStarted);
                        }
                    },
                    {
                        headerContent: "In Progress",
                        cellContent: (arg) => {
                            return {
                                html: this.generateEvent(
                                    arg.resource.extendedProps.tasks.inProgress,
                                    arg.resource.extendedProps
                                )
                            };
                        },
                        cellDidMount: (arg) => {
                            this.mountVisitTooltip(arg, arg.resource.extendedProps.tasks.inProgress);
                        }
                    },
                    {
                        headerContent: "Completed",
                        cellContent: (arg) => {
                            return {
                                html: this.generateEvent(
                                    arg.resource.extendedProps.tasks.completed,
                                    arg.resource.extendedProps
                                )
                            };
                        },
                        cellDidMount: (arg) => {
                            this.mountVisitTooltip(arg, arg.resource.extendedProps.tasks.completed);
                        }
                    }
                ],
                // NOTE: Uncomment when task navigation is needed
                // eventClick: (event: any) => {
                //     if (!$(event.el).find(".more-btn").length) {
                //         this.handleEventClick(event);
                //     }
                // },
                resourceLabelContent: (arg: any) => {
                    return {
                        html: this.generateResource(arg)
                    };
                },
                resourceLabelDidMount: (arg: any) => {
                    this.registerClick(arg);
                },
                resourceGroupLabelContent: (arg: any) => {
                    return {
                        html: this.generateGroupLabel(arg)
                    };
                }
            };
        },
        userDetails() {
            let userEntries: any[] = [];
            this.userNamesMap = new Map();
            this.usersList?.forEach((user) => {
                user.Agencies.forEach((op) => {
                    let tasks = {
                        notYetStarted: [] as ITaskByUserResponse[],
                        inProgress: [] as ITaskByUserResponse[],
                        completed: [] as ITaskByUserResponse[]
                    };
                    let userData = {
                        ...user,
                        ...op,
                        groupId: user.Id,
                        id: `${op.UserId}${op.Application}`,
                        tasks: tasks
                    };
                    this.tasksList.forEach((task: any) => {
                        if (task.UserId === op.UserId) {
                            if ([ApplicationEnum.AgencyCore, ApplicationEnum.HomeCare].includes(task.Application)) {
                                if (task.EvvStatus && task.EvvStatus !== "0") {
                                    if (["InProgress", "1", "3"].includes(task.EvvStatus)) {
                                        userData.tasks.inProgress.push(task);
                                    } else if (["Finished", "2", "4"].includes(task.EvvStatus)) {
                                        userData.tasks.completed.push(task);
                                    }
                                } else {
                                    this.addTask(task, userData);
                                }
                            } else {
                                this.addTask(task, userData);
                            }
                        }
                    });

                    userEntries.push(userData);
                });
                this.userNamesMap.set(user.Id, formatName(user.FirstName, user.LastName));
            });
            return userEntries ?? [];
        }
    },
    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        generateGroupLabel(arg: any) {
            const groupId = arg?.groupValue;
            const employeeName = this.userNamesMap?.get(groupId);
            let labelContent = `<span class="text-dark font-weight-bold"> ${employeeName} </span>`;
            return labelContent;
        },
        getStatusText(status: number): string {
            const result = this.employeeStatuses[status].toLowerCase();

            if (result.includes("pending")) {
                return "Pending";
            } else if (result.includes("terminate")) {
                return "Terminated";
            }
            return result;
        },
        generateResource(arg: any) {
            const resource = arg?.resource?.extendedProps;
            let userInfo = "";
            let userName = `<span class="font-weight-bold"> ${formatName(
                resource.FirstName,
                resource.LastName
            )} </span>`;
            const phoneNumber = new FormatPhoneNumber().toView(resource.PhoneNumber?.toString());
            this.isTestAgency = Object.values(TestingAgenciesEnum).includes(resource.AgencyId);

            if (!!resource) {
                const productClass = new GetProductClass();
                let application = resource.Application;
                if (application === 256) {
                    application = ApplicationEnum.AxxessHospice;
                }
                if (application !== ApplicationEnum.AgencyCore) {
                    userName = `<a class="w-100 font-size-base text-truncate cursor-pointer user-link" id="${
                        resource.Id
                    }">
                            ${formatName(resource.FirstName, resource.LastName)}
                        </a>`;
                }
                userInfo = `<div class="mx-2 d-flex flex-column align-items-start">
                    <span class="font-weight-normal font-size-xs badge badge-pill pill mr-1 ${productClass.toView(
                        application
                    )}">
                        ${ApplicationNameEnum[application]}
                    </span>
                    <div class="position-absolute bulk-reassign-wrapper">
                    ${
                        this.isTestAgency
                            ? `<button
                        type="button"
                        class="btn font-size-xs px-1 py-0 btn-outline-primary"
                        data-toggle="dropdown"
                        id="reassign-ellipsis-${resource.Id}"
                    >
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                <div class="dropdown-menu py-1" aria-labelledby="reassign-ellipsis-${resource.Id}">
                    <a
                        class="dropdown-item font-size-sm bulk-reassign"
                        href="javascript:void(0);"
                        id="task-manager-${resource.Id}"
                    >
                        Task Manager
                    </a>

                        </div>`
                            : ``
                    }
                </div>
                    <p class="mb-0 w-auto user-name">
                        ${userName}
                        <span class="font-size-xs">${resource.Credentials}</span>
                    </p>
                    ${
                        phoneNumber
                            ? `<a href="tel:${phoneNumber}" class="mb-0 font-size-xs w-auto">${phoneNumber}</a>`
                            : ""
                    }
                    <p class="w-100 font-size-2xs text-truncate mb-0">${resource.AgencyName}</p>
                    <p
                                class="font-weight-bold status-label mb-0 font-size-2xs ${this.getStatusText(
                                    resource.Status
                                ).toLowerCase()}"
                            >
                                Status : <span class="text-capitalize"> ${this.getStatusText(resource.Status)} </span>
                            </p>
                </div>`;
            }
            return userInfo;
        },
        generateEvent(events: any, userData?: any) {
            let eventContent = "";
            if (!events) {
                return eventContent;
            }
            events?.forEach((task: ITaskByUserResponse) => {
                const isNonPatientVisit = !task.PatientId;
                let patientName = "";
                if (!isNonPatientVisit) {
                    patientName = `<span class="d-flex align-items-center font-size-2xs">
                        <i class="fas fa-user mr-1"></i>
                        <span>
                            ${this.getUserName(task.PatientFirstName, task.PatientLastName)}
                        </span>
                    </span>`;
                }
                eventContent +=
                    userData.AgencyId == task.AgencyId
                        ? `<div class="ax-calendar-event mb-2 ${ScheduleLegendState[task.State]} " data-taskid="${
                              task.Id
                          }">
                    <span class="w-100 font-weight-bold text-truncate">
                        ${task.TaskName}
                    </span>
                    <span class="w-100 font-size-3xs text-truncate">
                        ${this.getTimeInOut(
                            task.State,
                            formatScheduledTime(task.TimeIn),
                            formatScheduledTime(task.TimeOut),
                            formatScheduledTime(task.StartTime),
                            formatScheduledTime(task.EndTime)
                        )}
                    </span>
                    ${patientName}
                    <span class="d-flex align-items-center font-size-2xs">
                        <i class="fas fa-building mr-1"></i>
                        <span>
                            ${task.BranchName || "Not Available"}
                        </span>
                    </span>
                    <span class="w-100 font-size-3xs text-truncate">
                        ${task.Status}
                    </span>
                </div>`
                        : "";
            });
            return eventContent;
        },
        addTask(task: ITaskByUserResponse, userData: any) {
            const taskStatus = task.Status.toLowerCase();
            if (nonEvvVisitStatus.notYetStarted.includes(taskStatus)) {
                userData.tasks.notYetStarted.push(task);
            } else if (nonEvvVisitStatus.inProgress.includes(taskStatus)) {
                userData.tasks.inProgress.push(task);
            } else {
                userData.tasks.completed.push(task);
            }
        },
        registerClick(arg: any) {
            const element = arg?.el?.className.split(" ").join(".");
            const extendedProps = arg?.resource?.extendedProps;
            $(`.${element}`).on("click", `#${extendedProps?.Id}`, (event: any) => {
                this.handleUserNameClick(extendedProps);
            });
            $(`.${element}`).on("click", `#task-manager-${extendedProps?.Id}`, (event: any) => {
                this.openTaskManager(extendedProps);
            });
        },
        async openTaskManager(employee: BulkReassign) {
            let application;
            if (employee.Application === ApplicationEnum.AxxessHospice) {
                application = ApplicationEnum.AxxessHospiceFE;
            } else {
                application = employee.Application;
            }
            this.$router.push({
                path: "/axxess-central/task-manager?",
                query: {
                    agencyId: employee.AgencyId,
                    fullname: `${employee.FirstName} ${employee.LastName} ${employee.Credentials}`,
                    application: application,
                    userId: employee.UserId
                }
            });
        },
        async handleUserNameClick(resource: any) {
            let redirectResponse;
            const analyticsCategory = "Central-Calendar-Navigation";
            let action = "";
            if (resource.Application === ApplicationEnum.AxxessHospice) {
                redirectResponse = {
                    redirectUrl: `${config.hospiceUrl}/employees/employee-center/${resource.UserId}?accountId=${resource.AgencyId}`,
                    isSuccessful: true
                };
                action = "AxxessHospice";
            } else if (resource.Application === ApplicationEnum.AxxessPalliative) {
                redirectResponse = {
                    redirectUrl: `${config.palliativeUrl}/employees/employee-center/${resource.UserId}?accountId=${resource.AgencyId}`,
                    isSuccessful: true
                };
                action = "AxxessPalliative";
            } else {
                const isClinician = this._permissionManager.checkPermission(
                    ParentPermissionEnum.clinician,
                    PermissionActionEnum.canView
                );
                this.showLoading(true);
                redirectResponse = await this._navigationService.navigateToUser({
                    agencyId: resource.AgencyId,
                    applicationId: resource.Application,
                    isClinician: isClinician,
                    userId: resource.UserId
                });
                action = resource.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
                this.showLoading(false);
            }
            this._navigationService.redirect(redirectResponse, {
                category: analyticsCategory,
                action
            });
        },
        mountVisitTooltip(arg: any, tasksArray: any) {
            $('[role="tooltip"]')?.remove();
            if (!arg?.el) {
                return;
            }
            let visitElements = $(arg?.el).find(".ax-calendar-event");
            visitElements.each((i, el) => {
                let currentTaskId = $(el).data("taskid");
                tasksArray?.forEach((task: any) => {
                    if (task.Id === currentTaskId) {
                        if ($(el).hasClass("non-visit-bg")) {
                            this.attachNonVisitTooltip(el, task);
                            return;
                        }
                        this.attachTooltip(el, task);
                    }
                });
            });
        },
        attachTooltip(el: any, arg: any) {
            const element = $(el);
            const content = this.getTooltipContent(arg);
            (element as any).popover({
                html: true,
                content: content,
                trigger: "hover",
                placement: "auto",
                container: "body"
            });
        },
        getTooltipContent(data: any) {
            let content = "";
            let evvContent = "";
            if (this.getEvvContent(data).length > 0) {
                evvContent = `<span>
                                <strong>EVV Time:</strong>
                                ${this.getEvvContent(data)}
                            </span>`;
            }
            if (!!data) {
                content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                                <span><strong>Payer:</strong> ${data.Payer}</span>
                                <span><strong>Billable:</strong> ${!!data.IsBillable ? "Yes" : "No"}</span>
                                <span><strong>Payable:</strong> ${!!data.IsPayable ? "Yes" : "No"}</span>
                                <span>
                                    <strong>Scheduled By:</strong>
                                    ${this.getUserName(data.ScheduledByFirstName, data.ScheduledByLastName)}
                                </span>
                                ${evvContent}
                            <div>`;
            }
            return content;
        },
        attachNonVisitTooltip(el: any, arg: any) {
            const element = $(el);
            const content = this.getNonVisitTooltipContent(arg);
            (element as any).popover({
                html: true,
                content: content,
                trigger: "hover",
                placement: "auto",
                container: "body"
            });
        },
        getNonVisitTooltipContent(data: any) {
            let content = "";
            let evvContent = "";
            if (this.getEvvContent(data).length > 0) {
                evvContent = `<span>
                                <strong>EVV Time</strong>
                                ${this.getEvvContent(data)}
                            </span>`;
            }
            if (!!data) {
                content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                                <span><strong>Mileage:</strong> ${data.Mileage || "Not Available"}</span>
                            <div>`;
            }
            return content;
        },
        getEvvContent(data: any) {
            let evvTime = "";
            if (data.Application === ApplicationEnum.AxxessHospiceFE) {
                return evvTime;
            } else if (!data.EvvTimeIn && !data.EvvTimeOut) {
                evvTime = "Not Yet Started";
            } else if (data.Status === "Completed") {
                const evvTimeIn = data.EvvTimeIn ? data.EvvTimeIn : "Not Collected";
                const evvTimeOut = data.EvvTimeIn ? data.EvvTimeOut : "Not Collected";
                evvTime = this.employeeCalendarContent.formatEVVTime(evvTimeIn, evvTimeOut);
            } else {
                const evvTimeIn = data.EvvTimeIn || "Not Collected";
                const evvTimeOut = data.EvvTimeOut || "Not Collected";
                evvTime = this.employeeCalendarContent.formatEVVTime(evvTimeIn, evvTimeOut);
            }
            return evvTime;
        },
        getUserName(firstName: string, lastName: string) {
            let userName = "";
            if (!firstName && !lastName) {
                userName = "Unassigned";
            } else {
                userName = formatName(firstName, lastName, "FN LN");
            }
            return userName;
        },
        getTimeInOut(state: number, timeIn: string, timeOut: string, startTime: string, endTime: string) {
            if (state != 4 && !startTime && !endTime) {
                return "";
            }
            if (state != 4) {
                return `${startTime || ""} - ${endTime || ""}`;
            }
            if (!timeIn && !timeOut) {
                return "";
            }
            return `${timeIn || ""} - ${timeOut || ""}`;
        }
    }
});
