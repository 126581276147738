
import { defineComponent } from "vue";

import AdvanceCarePlan from "./AdvanceCarePlan/AdvanceCarePlan.vue";
import AdditionalComments from "./AdditionalComments/AdditionalComments.vue";

export default defineComponent({
    components: {
        AdvanceCarePlan,
        AdditionalComments
    }
});
