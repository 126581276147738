import { defineComponent } from "vue";
import moment, { Moment } from "moment";

export interface IIdleTimeoutOptions {
    idleWarningTimeInMinutes: number;
}

export default defineComponent({
    inject: ["dialogRef"],

    data() {
        return {
            _idleInterval: null as NodeJS.Timer,
            seconds: null,
            minutes: null,
            options: {
                idleWarningTimeInMinutes: 2
            } as IIdleTimeoutOptions,
            dialogReference: this.dialogRef as any
        };
    },

    beforeMount() {
        let dialogOptions = this.dialogReference.data;
        if (!!dialogOptions) {
            Object.assign(this.options, dialogOptions);
        }

        this.startTimer();
    },

    methods: {
        startTimer() {
            this._idleInterval = setInterval(() => {
                let idleTime: string = JSON.parse(localStorage.getItem("idleExpiry"));
                let a: Moment = moment(idleTime);
                let b: Moment = moment();
                let duration: number = a.diff(b, "s");

                if (duration <= this.options.idleWarningTimeInMinutes * 60) {
                    if (duration > 0) {
                        this.minutes = (duration / 60) | 0;
                        this.seconds = duration % 60 | 0;

                        this.minutes = this.minutes < 10 ? `0${this.minutes}` : this.minutes;
                        this.seconds = this.seconds < 10 ? `0${this.seconds}` : this.seconds;
                    } else {
                        this.dialogReference.close();
                    }
                }
            }, 1000);
        },

        logout() {
            this.dialogReference.close();
        },

        stayLoggedIn() {
            this.dialogReference.close("success");
        }
    },

    unmounted() {
        window.clearInterval(this._idleInterval);
    }
});
