export function adjustDate(fullDate: Date, offset: number) {
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let actualDate = new Date(fullDate);
    let currentYear = actualDate.getFullYear();
    let currentMonth = actualDate.getMonth();
    let currentDate = actualDate.getDate();

    // Leap Year Gregorian calendar rule
    if (currentYear % 400 === 0 || (currentYear % 4 === 0 && currentYear % 100 !== 0)) {
        daysInMonth[1]++;
    }

    let noOfDaysInCurrentMonth = daysInMonth[currentMonth];
    let expectedDate = currentDate + offset;

    // Expected date is in the following month
    if (expectedDate > noOfDaysInCurrentMonth) {
        currentMonth++;

        if (currentMonth > 11) {
            currentMonth = 0;
            currentYear++;
        }

        currentDate = expectedDate - noOfDaysInCurrentMonth;
    } else {
        // Expected date is in the previous month
        if (expectedDate <= 0) {
            currentMonth--;

            if (currentMonth < 0) {
                currentMonth = 11;
                currentYear--;
            }

            noOfDaysInCurrentMonth = daysInMonth[currentMonth];
            currentDate = noOfDaysInCurrentMonth - Math.abs(expectedDate);
        } else {
            // Expected date is in the current month
            currentDate = expectedDate;
        }
    }

    return new Date(currentYear, currentMonth, currentDate, 0, 0, 0, 0);
}
