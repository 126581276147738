import "./places-autocomplete.scss";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";

import type { IPlacesCallBackParams } from "../../../resources-vue/vue-interfaces/i-places-autocomplete";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { GoogleMapsService } from "../../../services/google-maps.service";

// Get current geolocation
// Initialize autocomplete
// Listen to place_changed event
// Trigger the method when places changed
// On selection return the selected full address

@autoinject
@customElement("places-autocomplete")
export class PlacesAutocomplete {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public zip: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public street: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public county: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public state: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public country: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public city: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "Start Typing...";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public latitude: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public longitude: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public placesChangedCallBack: (params: IPlacesCallBackParams) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showValidateAddress: boolean = false;
    private _googleMapsWebService: GoogleMapsService;
    private _googleMapsWebApi: any;
    private _autocomplete: google.maps.places.Autocomplete;
    private _placeChangedListener: google.maps.MapsEventListener;
    private static _placesAutocompleteCount: number = 0;
    public autocompleteField: HTMLInputElement;

    public constructor(googleMapsWebService: GoogleMapsService) {
        this._googleMapsWebService = googleMapsWebService;
    }

    public async attached() {
        PlacesAutocomplete._placesAutocompleteCount++;
        try {
            this._googleMapsWebApi = await this._googleMapsWebService.getMapsApi();
            if (!!this.autocompleteField) {
                this._autocomplete = new this._googleMapsWebApi.places.Autocomplete(this.autocompleteField, {
                    types: ["geocode"]
                });

                this.getUsersLocation();
                this._placeChangedListener = this._autocomplete.addListener(
                    "place_changed",
                    this.handleAutocompleteResult.bind(this)
                );

                if (this.validation) {
                    this.initValidations();
                }
            }
        } catch (e) {
            console.error("Error initializing Places Autocomplete", e);
        }
    }

    private getUsersLocation() {
        // TODO: Move this to a separate service. Getting users location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                let geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                let circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                this._autocomplete.setBounds(circle.getBounds());
            });
        }
    }

    private handleAutocompleteResult() {
        let results: google.maps.places.PlaceResult = this._autocomplete.getPlace();
        let address: google.maps.GeocoderAddressComponent[] = results.address_components;
        let street = "";
        let zip = "";
        let city = "";
        let county = "";
        let country = "";
        let state = "";
        let latitude = null;
        let longitude = null;
        if (address?.length > 0) {
            for (let [i, v] of address?.entries()) {
                let type: string = address[i].types[0];
                switch (type) {
                    case "street_number":
                        street += address[i].short_name;
                        break;
                    case "route":
                        street += ` ${address[i].long_name}`;
                        break;
                    case "country":
                        country = address[i].short_name;
                        break;
                    case "postal_code":
                        zip = address[i].short_name;
                        break;
                    case "postal_code_suffix":
                        zip += `${address[i].short_name}`;
                        break;
                    case "locality":
                        city = address[i].short_name;
                        break;
                    case "administrative_area_level_1":
                        state = address[i].short_name;
                        break;
                    case "administrative_area_level_2":
                        county = address[i].short_name.replace(" County", "");
                        break;
                }
            }
        }
        if (results?.geometry?.location) {
            latitude = results?.geometry?.location?.lat();
            longitude = results?.geometry?.location?.lng();
        }
        if (this.showValidateAddress) {
            let updatedAddressParams = {
                addressLine1: street,
                state: state,
                city: city,
                zipCode: zip,
                county: county,
                latitude: latitude,
                longitude: longitude,
                isValidated: true
            };
            this.placesChangedCallBack({ params: updatedAddressParams });
            this.country = country;
        } else {
            this.state = state;
            this.street = street;
            this.zip = zip;
            this.city = city;
            this.county = county;
            this.country = country;
            this.latitude = latitude;
            this.longitude = longitude;
        }
        this.autocompleteField.value = this.street;
    }

    public detached() {
        PlacesAutocomplete._placesAutocompleteCount--;
        let placesElements = document.querySelectorAll(".pac-container");
        if (this._placeChangedListener) {
            google.maps.event.removeListener(this._placeChangedListener);
        }
        if (this._autocomplete) {
            google.maps.event.clearInstanceListeners(this._autocomplete);
        }
        if (!PlacesAutocomplete._placesAutocompleteCount) {
            Array.from(placesElements).forEach((ele: Element) => ele.remove());
        }
    }

    public initValidations() {
        let displayName = this.validation.displayName;
        let message = this.validation.message;
        ValidationRules.ensure((x: PlacesAutocomplete) => x.street)
            .displayName(displayName)
            .required()
            .when((typeahead: PlacesAutocomplete) => typeahead.validation.required)
            .withMessage(message ? message : `${displayName} is required.`)
            .on(this);
    }
}
