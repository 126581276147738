import { defineComponent } from "vue";
import moment from "moment";
import { mapState } from "pinia";

import {
    ApplicationEnum,
    PalliativeCareOrder,
    PalliativeReferralReadiness,
    ReferralRead
} from "../../../../../enums/enums";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker
    },

    data(vm) {
        return {
            ReferralReadiness: Array.from(ReferralRead.enumWithDescriptions.values()),
            f2fValue: null,
            orderReceivedValue: null,
            CareOrderOptions: Array.from(PalliativeCareOrder.enumWithDescriptions.values()),
            PalliativeReferralReadiness: Array.from(PalliativeReferralReadiness.enumWithDescriptions.values()),
            formatDateInput
        };
    },

    created() {
        this.orderReceivedValue = PalliativeReferralReadiness.enum.PalliativeCareOrderReceived;
        this.initEnums();
        if (!!this.referral) {
            if (!this.referral.referralReadiness) {
                this.referral.referralReadiness = [];
            }
        }
    },

    computed: {
        isApplicationPalliative(): boolean {
            return this.referral.application == ApplicationEnum.AxxessPalliative;
        },

        datePickerOptions(): DatepickerOptions {
            let baseDatePickerOptions = {
                autoclose: true
            };
            if (this.referral?.referralDate) {
                return Object.assign(baseDatePickerOptions, {
                    startDate: moment(this.referral.referralDate).subtract(30, "d").format("MM/DD/YYYY")
                });
            }
            return baseDatePickerOptions;
        },

        isF2fVisitObtained() {
            return this.referral?.referralReadiness?.includes(this.f2fValue as number);
        },

        isOrderReceived() {
            return this.referral?.palliativeReferralReadiness?.includes(this.orderReceivedValue as number);
        },
        ...mapState(useCreateReferralHosPal, ["referral"])
    },

    methods: {
        async initEnums() {
            this.ReferralReadiness?.forEach((readiness) => {
                const label = readiness.name.toLocaleLowerCase();
                if (label?.includes("face-to-face")) {
                    this.f2fValue = readiness.value;
                }
            });
        },
        completedDateChanged(name: string, date: any) {
            if (name === "completedDate") {
                this.referral.completedDate = date;
            }
        }
    }
});
