import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum HomecareAdmissionTypesEnum {
    Emergency = 1 as any,
    Urgent = 2 as any,
    Elective = 3 as any,
    Newborn = 4 as any,
    Trauma = 5 as any,
    InformationNotAvailable = 9 as any
}
class HomecareAdmissionTypesClass extends EnumWithDescription<HomecareAdmissionTypesEnum> {
    public readonly enum = HomecareAdmissionTypesEnum;
    public readonly enumName: string = "HomecareAdmissionTypesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<HomecareAdmissionTypesEnum>> = new Map([
        [
            "Emergency",
            {
                value: HomecareAdmissionTypesEnum.Emergency,
                name: "(1) Emergency"
            }
        ],
        [
            "Urgent",
            {
                value: HomecareAdmissionTypesEnum.Urgent,
                name: "(2) Urgent"
            }
        ],
        [
            "Elective",
            {
                value: HomecareAdmissionTypesEnum.Elective,
                name: "(3) Elective"
            }
        ],
        [
            "Newborn",
            {
                value: HomecareAdmissionTypesEnum.Newborn,
                name: "(4) Newborn"
            }
        ],
        [
            "Trauma",
            {
                value: HomecareAdmissionTypesEnum.Trauma,
                name: "(5) Trauma Center"
            }
        ],
        [
            "InformationNotAvailable",
            {
                value: HomecareAdmissionTypesEnum.InformationNotAvailable,
                name: "(9) Information Not Available"
            }
        ]
    ]);
}

export const HomecareAdmissionTypes = new HomecareAdmissionTypesClass();
