import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { ApplicationNames } from "../../../../../enums/enums";
import { NewsService } from "../../../../../services/news-service";
import { INewsResponse } from "../../../../../resources-vue/vue-interfaces/i-news";

import CardTemplate from "../../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";

export default defineComponent({
    components: {
        CardTemplate
    },

    data() {
        return {
            _permissionManager: null,
            _newsService: null as NewsService,
            newsAndUpdates: [] as INewsResponse[],
            ApplicationNamesEnum: ApplicationNames,
            isLoading: true,
            hasError: false
        };
    },
    async created() {
        this._permissionManager = Container.instance.get(PermissionManager);
        this._newsService = Container.instance.get(NewsService);

        this.getNewsAndUpdate();
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),

        async getNewsAndUpdate() {
            try {
                this.hasError = false;
                this.isLoading = true;
                this.newsAndUpdates = await this._newsService.getNewsAndUpdates({
                    pageLength: 3
                });
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
