import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import config from "../../common/config";
import { AgencyService, IGetOrganization } from "../../services/agency.service";
import AuthService from "../../services/auth.service";
import { AnalyticsService } from "../../services/analytics.service";

import Organizations from "../../resources-vue/vue-dialogs/Organizations/Organizations.vue";
import Alert from "../../resources-vue/vue-dialogs/Alert/Alert.vue";

const organizationId = "organization-id";
const organizationName = "organization-name";

export default defineComponent({
    data() {
        return {
            dialogReference: this.dialogRef as any,
            _agencyService: null,
            _authService: null,
            _analyticsService: null,
            _logCategory: "No-authorization",
            organizationsList: [],
            organizations: [] as IGetOrganization[],
            selectedOrganization: null,
            dialogRef: null,
            routeName: null
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._agencyService = Container.instance.get(AgencyService);
        this._authService = Container.instance.get(AuthService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this.routeName = this.$route?.query.name;
    },

    async mounted() {
        this.organizations = await this._agencyService.getOrganizations();
        this.organizationsList = this.organizations.map((organization, index) => {
            return {
                name: organization.name,
                id: organization.id,
                message: ""
            };
        });
        await this.switchOrganization();
    },

    methods: {
        cancel() {
            this.dialogReference.close("cancel");
        },
        ok() {
            this.selectedOrganization.message = "success";
            this.dialogReference.close(this.selectedOrganization);
        },

        async switchOrganization() {
            if (this.organizationsList.length <= 0) {
                this.handleNoAccounts();
            } else {
                if (this.organizationsList.length === 1) {
                    window.localStorage.setItem(organizationId, this.organizationsList[0].id.toString());
                    window.localStorage.setItem(organizationName, this.organizationsList[0].name);
                    this.$router.push({ name: this.routeName });
                } else {
                    await this.handleMultipleAccounts();
                }
            }
        },

        async handleNoAccounts() {
            let model = {
                title: `Authorization Failed`,
                message:
                    "You do not have any agencies linked to your account. If you believe this an error, please contact support."
            };

            this.dialogRef = this.$dialog.open(Alert, {
                props: {
                    modal: true,
                    contentStyle: {
                        width: "400px",
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    model
                },
                onClose: (response) => {
                    this._authService.logout();
                    this._analyticsService.logEvent({
                        category: this._logCategory,
                        action: "Log-Out"
                    });
                }
            });
        },

        async handleMultipleAccounts() {
            this.dialogRef = this.$dialog.open(Organizations, {
                props: {
                    modal: true,
                    contentStyle: {
                        width: "400px",
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                onClose: (response) => {
                    if (response.data.message == "success") {
                        window.localStorage.setItem(organizationId, response.data.id);
                        window.localStorage.setItem(organizationName, response.data.name);

                        this.$router.push({
                            name: this.routeName
                        });
                    } else if (response.data == "cancel") {
                        if (localStorage.getItem(config.organizationId) === null) {
                            this.$router.push("/clinician");
                        } else {
                            this.$router.push({ name: this.routeName });
                        }
                    } else {
                        this.$router.push({ name: this.routeName });
                    }
                }
            });
        }
    }
});
