import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import type { IGetTeamLean, IGetTeamsLeanQuery } from "../resources-vue/vue-interfaces/i-team";

@autoinject()
export class TeamsService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/team";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getTeamsLean(query: IGetTeamsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetTeamLean[]>();
    }
}
