import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import {
    IGetUsersByRoleQuery,
    IGetUsersLean,
    IGetUsersLeanQuery,
    IGetUserSlimInfoResult,
    IUserResult,
    IGetUserByName,
    IGetAllUsersQuery,
    IGetAllUsersResponse,
    IGetScheduleEmployee,
    IGetScheduleEmployeeResponse,
    IGetUsersByAgency,
    IGetUsersLeanResult,
    IGetUsersLeanMessagingQuery,
    IGetClientRequest,
    IGetClientResponse,
    IGetUserRequest,
    IGetUserResponse
} from "../interfaces/i-user";
import { User } from "../models/user";
import { IApplicationAndAccountQueryParam } from "../interfaces/i-get-referral";

@autoinject
export class UserService {
    private _user: IGetUserSlimInfoResult;
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/users";
    private _credentialsList: string[] = [];

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async getUserCredentials() {
        if (!this._credentialsList.length) {
            this._credentialsList = await this._fetchClient.build(`${this._baseUrl}/credentials`).fetch<string[]>();
        }
        return this._credentialsList;
    }

    public async getUsersForCalendar(options: IEmployeeCalendarRequestParams) {
        return await this._fetchClient
            .build(`${this._baseUrl}/for-calendar`)
            .useQueryString(options)
            .fetch<IPaginationNew<IEmployeeCalendarResponse>>();
    }

    public async getUsersByRole(query: IGetUsersByRoleQuery) {
        return await this._fetchClient.build(`${this._baseUrl}/role`).useQueryString(query).fetch<IUserResult[]>();
    }

    public async getCurrentUser() {
        return await this._fetchClient.build(`${this._baseUrl}/current`).fetch<IGetUserSlimInfoResult>();
    }

    public async getCurrentUserId(query: { agencyId: string }) {
        return await this._fetchClient
            .build(`${this._baseUrl}/current/id`)
            .useQueryString(query)
            .fetch<{ userId: string }>();
    }

    public async getUsersLean(query: IGetUsersLeanQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/lean`)
            .useQueryString(query)
            .fetch<IPaginationCamelCaseNew<IGetUsersLean>>();
    }

    public async getUsersLeanMessaging(query: IGetUsersLeanMessagingQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/lean`)
            .useQueryString(query)
            .fetch<IGetUsersLeanResult>();
    }

    public async getUser(userId: string) {
        return await this._fetchClient.build(`api/v1/${userId}`).fetch<User>();
    }

    public async getUserByName(query: { term: string }) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-name`)
            .useQueryString(query)
            .fetch<IGetUserByName[]>();
    }

    public async getAllUsers(query: IGetAllUsersQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/branch/all`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetAllUsersResponse[]>();
    }

    public async getScheduleEmployee(query: IGetScheduleEmployee) {
        return await this._fetchClient
            .build(`${this._baseUrl}/schedule-employees`)
            .useQueryString(query)
            .fetch<IGetScheduleEmployeeResponse[]>();
    }

    public async getUsersByAgency(query: IApplicationAndAccountQueryParam) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-agency`)
            .useQueryString(query)
            .fetch<IGetUsersByAgency[]>();
    }

    public async getUserSlimInfo(userId: string) {
        this._user = await this._fetchClient.build(`${this._baseUrl}/${userId}/slim`).fetch<IGetUserSlimInfoResult>();
        return Promise.resolve(this._user);
    }

    public async getClients(query: IGetClientRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/staff-preference-clients`)
            .useQueryString(query)
            .fetch<IGetClientResponse[]>();
    }

    public async getUserByBranch(query: IGetUserRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-branch`)
            .useQueryString(query)
            .fetch<IGetUserResponse[]>();
    }

    public async getUserDetails() {
        return await this._fetchClient.build("/api/v1/beta/getUserDetails").fetch<IGetuserDetailResponse>();
    }

    public async updateUserBetaDetails(isBetaOpted: boolean) {
        return await this._fetchClient
            .build("/api/v1/beta/updateBetaToggle")
            .useMethod("PUT")
            .useBody({ isBetaOpted })
            .fetch<IGetuserDetailResponse>();
    }

    public async addUserBetaDetails(isBetaOpted: boolean) {
        return await this._fetchClient
            .build("/api/v1/beta/addUserDetails")
            .useMethod("POST")
            .useBody({ isBetaOpted })
            .fetchNoContent();
    }
}
export interface IEmployeeCalendarRequestParams {
    pageLength: number;
    page: number;
    term?: string;
    agencies?: string[];
    credentials?: string[];
    scheduleFilter?: number;
    startDate?: Date | string;
    endDate?: Date | string;
}

export interface IPaginationCamelCaseNew<T> {
    itemCount: number;
    pageLength: number;
    currentPage: number;
    pageCount: number;
    items: T[];
}

export interface IPaginationNew<T> {
    ItemCount: number;
    PageLength: number;
    CurrentPage: number;
    PageCount: number;
    Items: T[];
}

export interface IEmployeeCalendarResponse {
    Id: string;
    FirstName: string;
    LastName: string;
    PhoneNumber: string;
    Agencies: IAgency[];
}

export interface IAgency {
    UserId: string;
    AgencyId: string;
    Application: number;
    AgencyName: string;
    Credentials: string;
}

export interface IGetuserDetailResponse {
    Data: IGetuserDetails;
    IsSuccess: boolean;
    Message?: string;
}

export interface IGetuserDetails {
    Id: string;
    EmailAddress: string;
    IsBetaOpted: boolean;
}

export interface IGetuserDetailsRequest {
    emailAddress: string;
    isBetaOpted: boolean;
}
