import { inject } from "aurelia-dependency-injection";
import { HttpClient } from "aurelia-fetch-client";
import { noView } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";
import type { IGetSurveyResponse, IUpdateSurvey } from "../resources-vue/vue-interfaces/i-survey";

@noView()
@inject(HttpClient)
export class SurveyService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/survey";

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async getSurveySubmission(): Promise<IGetSurveyResponse> {
        let surveyResponse = await this._fetchClient.build(this._baseUrl).fetch<IGetSurveyResponse>();
        return surveyResponse ?? null;
    }

    public async updateSurveySubmission(surveyResult: IUpdateSurvey) {
        await this._fetchClient.build(this._baseUrl).useMethod("POST").useBody(surveyResult).fetchNoContent();
    }
}
