import { valueConverter } from "aurelia-framework";
import * as moment from "moment";

import { MomentValueConverter } from "../../services/date-format-service";

@valueConverter("formatDate")
export class FormatDate {
    public toView(value: string, format = "MM/DD/YYYY"): string {
        if (value) {
            return moment(value).format(format);
        } else {
            return null;
        }
    }
}

@valueConverter("formatDateFromDate")
export class FormatDateFromDate {
    public toView(value: Date, dateFormat: string = "MM/DD/YYYY"): string {
        if (value) {
            let theMoment = MomentValueConverter.dateOrStringToMoment(value);
            return theMoment.format(dateFormat);
        }

        return ".";
    }
}

@valueConverter("formatDateInput")
export class FormatDateInput {
    public toView(value: string): string {
        let result: string = value;
        if (result) {
            result = result.replace(/([a-zA-Z ])/g, "");
            result = result.replace(/^(\d{2})(\d+)+$/, "$1/$2");
            result = result.replace(/^(\d{2})\/(\d{2})(\d+)+$/, "$1/$2/$3");
            if (/^(\d{4})\-(\d{2})\-(\d{2})/.test(result)) {
                result = moment(value).format("MM/DD/YYYY");
            }
        }
        return result;
    }
}
