import { defineComponent } from "vue";

import AxxessHomeRouter from "./AxxessHomeRouter";
import { helperTestingAgency } from "../../common/vue-helpers/testing-agency-check";

import DashboardHeader from "../../resources-vue/vue-elements/DashboardHeader/DashboardHeader.vue";
import PlannerLevelTwo from "./components/PlannerLevelTwo/PlannerLevelTwo.vue";
import PatientList from "../Common/PatientList/PatientList.vue";
import EmployeeList from "../Common/EmployeeList/EmployeeList.vue";

export default defineComponent({
    components: {
        DashboardHeader,
        PlannerLevelTwo,
        PatientList,
        EmployeeList
    },
    data(vm) {
        return {
            showPatients: false,
            showEmployees: false,
            router: []
        };
    },
    async mounted() {
        const isTestAgency = await helperTestingAgency.checkHasTestingAgency();

        this.router = AxxessHomeRouter?.filter((route: any) => {
            if (!!route.meta?.showCentralMessaging) {
                return isTestAgency;
            } else {
                return true;
            }
        });
    }
});
