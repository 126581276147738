<template>
    <div class="content-wrapper card material-card">
        <slot name="content"></slot>
    </div>
</template>

<style lang="scss">
.material-card {
    box-shadow: 2px 2px 4px 0 hsla(0, 0%, 64%, 0.37);
}
</style>
