import { RenderInstruction, ValidateResult } from "aurelia-validation";

// From http://aurelia.io/docs/plugins/validation#custom-renderers
// Updated to support bootstrap 4

const isValidClass = "is-valid";
export const isInvalidClass = "is-invalid";

export class BootstrapFormRenderer {
    public render(instruction: RenderInstruction) {
        for (let { result, elements } of instruction.unrender) {
            for (let element of elements) {
                this.remove(element, result);
            }
        }

        for (let { result, elements } of instruction.render) {
            for (let element of elements) {
                this.add(element, result);
            }
        }
    }

    public add(element: Element, result: ValidateResult) {
        const formGroup = element.closest(".form-group");
        if (!formGroup) {
            console.log("Form-group class not found! No validation error will be displayed.");
            return;
        }

        if (!result.valid) {
            if (!element.classList.contains(isInvalidClass)) {
                // element.classList.remove(isValidClass);
                formGroup.classList.add(isInvalidClass);
                element.classList.add(isInvalidClass);
                const message = document.createElement("div");
                message.className = "invalid-feedback d-block";
                message.textContent = result.message;
                message.id = `validation-feedback-${result.id}`;
                formGroup.appendChild(message);
            }
        }
    }

    public remove(element: Element, result: ValidateResult) {
        const formGroup = element.closest(".form-group");
        if (!formGroup) {
            console.log("Form-group class not found! No validation error will be removed.");
            return;
        }

        if (result.valid) {
            if (element.classList.contains(isValidClass)) {
                element.classList.remove(isValidClass);
            }
            if (formGroup.classList.contains(isValidClass)) {
                formGroup.classList.remove(isValidClass);
            }
        } else {
            const message = formGroup.querySelector(`#validation-feedback-${result.id}`);
            if (message) {
                formGroup.removeChild(message);
                formGroup.classList.remove(isInvalidClass);
                if (element.classList.contains(isInvalidClass)) {
                    element.classList.remove(isInvalidClass);
                }
            }
        }
    }
}
