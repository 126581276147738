export enum Ethnicity {
    Hispanic = "0",
    Mexican = "1",
    Puerto = "2",
    Cuban = "3",
    Another = "4",
    Unable = "5",
    Declines = "6"
}

export enum Race {
    White = "0",
    AfricanAmerican = "1",
    AmericanIndianAlaskaNative = "2",
    Asian_Indian = "3",
    Chinese = "4",
    Filipino = "5",
    Japanese = "6",
    Korean = "7",
    Vietnamese = "8",
    Other_Asian = "9",
    Native_Hawaiian = "10",
    Guamanian_Chamorro = "11",
    Samoan = "12",
    Other = "13",
    Unable = "14",
    Declines = "15",
    None = "16"
}
