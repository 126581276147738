import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmergencyTriage = _resolveComponent("EmergencyTriage")!
  const _component_AdditionalPrepInfo = _resolveComponent("AdditionalPrepInfo")!
  const _component_EmergencyEvacuation = _resolveComponent("EmergencyEvacuation")!
  const _component_EmergencyComments = _resolveComponent("EmergencyComments")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EmergencyTriage),
    _createVNode(_component_AdditionalPrepInfo),
    _createVNode(_component_EmergencyEvacuation),
    _createVNode(_component_EmergencyComments)
  ]))
}