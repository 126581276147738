
import { defineComponent } from "vue";

import { Container } from "aurelia-dependency-injection";

import { AgencyService } from "../../../services/agency.service";
import { ProductService } from "../../../services/product.service";
import { ApplicationEnum } from "../../../enums/enums";
import { getProductPillClass, getProductName } from "../../../common/vue-helpers/modifiers/application-modifiers";

export default defineComponent({
    props: {
        productId: {
            typeof: ApplicationEnum,
            default: null
        }
    },

    data() {
        return {
            _agencyService: null,
            _productService: null,
            id: null,
            getProductPillClass,
            getProductName
        };
    },

    created() {
        this._agencyService = Container.instance.get(AgencyService);
        this._productService = Container.instance.get(ProductService);
    },

    computed: {
        hasMultipleClinicalProducts() {
            return this._agencyService?.getHasMultipleClinicalProds();
        }
    }
});
