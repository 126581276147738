import { defineComponent, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";
import { UserManager } from "oidc-client";
import moment from "moment";

import {
    ApplicationEnum,
    HttpStatusCodeEnum,
    ReferralTypesEnum,
    Gender,
    MaritalStatuses,
    GenderEnum,
    DmeNeeded
} from "../../../enums/enums";
import { HomeCareReferral } from "./../../../resources-vue/vue-models/homecare-referral";
import { EmergencyContact } from "../../../resources-vue/vue-models/emergency-contact";
import { HospiceReferral } from "../../../resources-vue/vue-models/hospice-referral";
import type {
    IApplicationAndAccountQueryParam,
    IdeleteEmergencyContact
} from "../../../resources-vue/vue-interfaces/i-get-referral";
import { INonAdmitHHPatientQuery } from "../../../interfaces/i-get-referral";
import type { IContactsOrder, IContactsOrderQueryParam } from "../../../resources-vue/vue-interfaces/i-get-patient";
import { IHospicePatient } from "../../../resources-vue/vue-interfaces/i-hospice-patient";
import { INonAdmitPatientQuery } from "../../../resources-vue/vue-interfaces/i-get-referral";
import { type IPromptOptions } from "../../../resources/dialogs/prompt/prompt";
import { ReferralService } from "../../../services/referral-service";
import { ToastrService } from "../../../services/toastr.service";
import { PatientService } from "../../../services/patient.service";
import { UserService } from "../../../services/user-service";
import { CentralIntakeService } from "../../../services/central-intake.service";
import { useLoadingIndicator } from "../../Common/LoadingIndicator/store/useLoadingIndicator";
import { formatName } from "../../../common/format-name";

import ReferralSourceInformation from "./components/ReferralSourceInformation/ReferralSourceInformation.vue";
import DemographicsInformation from "./components/DemographicsInformation/DemographicsInformation.vue";
import CreateDmeNeeded from "./components/CreateDmeNeeded/CreateDmeNeeded.vue";
import ServicesRequired from "./components/ServicesRequired/ServicesRequired.vue";
import CreatePhysician from "./components/CreatePhysicians/CreatePhysician.vue";
import EditDmeNeeded from "./components/EditDmeNeeded/EditDmeNeeded.vue";
import EditPhysician from "./components/EditPhysicians/EditPhysician.vue";
import ReferralComments from "./components/ReferralComments/ReferralComments.vue";
import EmergencyContactsSection from "./components/EmergencyContacts/EmergencyContactsSection.vue";
import PageLayout from "../../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import FormSection from "../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import Prompt from "../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";

export default defineComponent({
    components: {
        PageLayout,
        ReferralSourceInformation,
        DemographicsInformation,
        CreateDmeNeeded,
        ServicesRequired,
        FormSection,
        ReferralComments,
        CreatePhysician,
        EditDmeNeeded,
        EditPhysician,
        EmergencyContactsSection
    },

    data() {
        return {
            isLoadError: false,
            isFormLoading: false,
            isSaveInProgress: false,
            isCreate: false,
            isNonMedical: false,
            isRefer: false,
            isNonAdmit: false,
            isPatient: false,
            hasErrorOnSave: false,
            referralId: "",
            accountId: "",
            inquiryId: "",
            providerId: "",
            currentApplication: ApplicationEnum.HomeCare,
            referral: {} as HomeCareReferral,
            patientData: null as IHospicePatient,
            referralData: null as HospiceReferral,
            currentUserId: null,
            patientId: null,
            patientApplication: null,
            patientAgencyId: null,
            patientAgencyName: null,
            application: null,
            patientFirstName: null,
            firstName: "",
            patientLastName: null,
            lastName: "",
            branchId: null,
            nonAdmitReasonId: "",
            nonAdmitDate: "",
            nonAdmitReason: "",
            dialogRef: null,
            _toastrService: null,
            _referralService: null,
            _patientService: null,
            _userService: null,
            _userManager: null,
            _user: null,
            _centralIntake: null,
            v$: useVuelidate(),
            formatName
        };
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef),
            v: computed(() => this.v$)
        };
    },
    validations() {
        return {
            referral: {
                firstName: { required },
                lastName: { required },
                agencyLocationId: { required },
                phoneNumbers: {
                    $each: helpers.forEach({
                        number: { required }
                    })
                },
                dob: { required },
                gender: { required },
                referralType: { required },
                referralDate: { required },
                requestedStartOfCareDate: {
                    required: requiredIf(!this.referral.isNonMedical)
                }
            }
        };
    },
    watch: {
        branchId(newValue, oldValue) {
            this.referral.locationId = this.branchId;
            this.referral.agencyLocationId = this.branchId;
        }
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._patientService = Container.instance.get(PatientService);
        this._userService = Container.instance.get(UserService);
        this._userManager = Container.instance.get(UserManager);
        this._centralIntake = Container.instance.get(CentralIntakeService);
    },
    async beforeMount() {
        this.referral = new HomeCareReferral();
        if (this.$route.query.isCreate == "true") {
            this.isCreate = true;
        }

        if (this.$route.query?.isNonMedical == "true") {
            this.isNonMedical = true;
        }

        if (this.$route.query?.fName) {
            this.firstName = this.$route.query.fName as string;
        }

        if (this.$route.query?.lName) {
            this.lastName = this.$route.query.lName as string;
        }

        if (this.$route.query?.locationId) {
            this.branchId = this.$route.query.locationId;
        }

        if (this.$route.query?.inquiryId) {
            this.inquiryId = this.$route.query.inquiryId as string;
        }

        if (this.$route.query?.account) {
            this.accountId = this.$route.query.account as string;
            this.referral.accountId = this.$route.query.account as string;
            this.referral.agencyId = this.$route.query.account as string;
        }
        if (this.$route.query?.id) {
            this.referralId = this.$route.query.id as string;
        }
        if (this.$route.query?.isRefer == "true") {
            this.isRefer = true;
        }
        if (this.$route.query?.isNonAdmit == "true") {
            this.isNonAdmit = true;
        }
        if (this.$route.query?.patientId) {
            this.patientId = this.$route.query?.patientId as string;
        }
        if (this.$route.query?.patientApplication) {
            this.patientApplication = this.$route.query?.patientApplication as string;
        }
        if (this.$route.query?.patientAgencyId) {
            this.patientAgencyId = this.$route.query?.patientAgencyId as string;
        }
        if (this.$route.query?.patientAgencyName) {
            this.patientAgencyName = this.$route.query?.patientAgencyName as string;
        }
        if (this.$route.query?.isPatient == "true") {
            this.isPatient = true;
        }
        if (this.$route.params?.application) this.application = this.$route.params?.application as string;

        if (this.isNonAdmit) {
            if (this.$route.query?.nonAdmitDate) {
                let nonAdmitDate = String(this.$route.query?.nonAdmitDate);
                this.nonAdmitDate = moment(nonAdmitDate, "MM-DD-YYYY").format("MM/DD/YYYY");
            }
            if (this.$route.query?.nonAdmitReasonId)
                this.nonAdmitReasonId = String(this.$route.query?.nonAdmitReasonId);
            if (this.$route.query?.nonAdmitReason) this.nonAdmitReason = String(this.$route.query?.nonAdmitReason);
        }
        this.referral.application = this.currentApplication;
        await this.loadReferral();
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        async loadReferral() {
            try {
                this.showLoading(true);
                this.isLoadError = false;
                this.isFormLoading = true;
                if (this.isCreate) {
                    this.referral = new HomeCareReferral();
                    this.referral.accountId = this.accountId;
                    this.referral.agencyId = this.accountId;
                    this.referral.application = this.currentApplication;
                    this.referral.firstName = this.firstName;
                    this.referral.lastName = this.lastName;
                    this.referral.locationId = this.branchId;
                    this.referral.agencyLocationId = this.branchId;
                    this.referral.isNonMedical = this.isNonMedical;
                    this.referral.inquiryId = this.inquiryId;
                    this.referral.referralType = null;
                    this.referral.admissionSource = null;
                    this.referral.admissionType = null;
                    this.referral.internalReferral = null;
                    this.referral.gender = null;
                    this.referral.payRateMunicipalityId = null;
                    this.referral.isDNR = false;
                    this.referral.maritalStatus = null;
                    this.referral.weightMetric = 0;
                    this.referral.heightMetric = 0;

                    if (this.isRefer && this.isPatient) {
                        if (
                            this.patientApplication ==
                                (ApplicationEnum.AxxessHospiceFE.toString() ||
                                    ApplicationEnum.AxxessHospice.toString()) ||
                            this.patientApplication == ApplicationEnum.AxxessPalliative.toString()
                        ) {
                            this.patientData = (await this.getHospicePatientData()).data.model;
                            let userName = "";
                            this.getCurrentUser();
                            this._user = await this._userManager.getUser();

                            if (this._user) {
                                userName = formatName(this._user.profile.given_name, this._user?.profile.family_name);
                            }

                            this.referral.referralType = ReferralTypesEnum.Other;
                            this.referral.referralSource = userName;
                            this.referral.referralDetails = this.patientAgencyName;
                            this.referral.referralDate = String(moment().format("MM/DD/YYYY"));
                            this.referral.firstName = this.patientFirstName = this.patientData.firstName;
                            this.referral.middleName = this.patientData.middleInitial;
                            this.referral.lastName = this.patientLastName = this.patientData.lastName;
                            this.referral.dob = this.patientData.dateOfBirth;
                            this.referral.gender = String(this.patientData.gender);
                            this.referral.ssn = this.patientData.socialSecurityNumber;
                            let phoneNumbers = [
                                {
                                    number: this.patientData.phoneNumbers[0].number,
                                    extension: this.patientData.phoneNumbers[0].extension,
                                    type: this.patientData.phoneNumbers[0].type,
                                    country: this.patientData.phoneNumbers[0].country,
                                    isPrimary: this.patientData.phoneNumbers[0].isPrimary,
                                    ordinal: 1,
                                    ordinalNumber: 1
                                },
                                {
                                    number: this.patientData.phoneNumbers[1].number
                                        ? this.patientData.phoneNumbers[1].number
                                        : "",
                                    extension: this.patientData.phoneNumbers[1].extension
                                        ? this.patientData.phoneNumbers[1].extension
                                        : "",
                                    type: this.patientData.phoneNumbers[1].type
                                        ? this.patientData.phoneNumbers[1].type
                                        : 1,
                                    country: this.patientData.phoneNumbers[1].country
                                        ? this.patientData.phoneNumbers[1].country
                                        : null,
                                    isPrimary: this.patientData.phoneNumbers[1].isPrimary
                                        ? this.patientData.phoneNumbers[1].isPrimary
                                        : false,
                                    ordinal: 2,
                                    ordinalNumber: 2
                                },
                                {
                                    number: "",
                                    extension: "",
                                    type: 1,
                                    country: "",
                                    isPrimary: false,
                                    ordinal: 3,
                                    ordinalNumber: 3
                                }
                            ];
                            this.referral.phoneNumbers = phoneNumbers;
                            this.referral.maritalStatus = this.patientData.maritalStatus;
                            this.referral.emailAddress = this.patientData.email;
                            this.referral.primaryAddress.addressLine1 = this.patientData.primaryAddress.addressLine1;
                            this.referral.primaryAddress.addressLine2 = this.patientData.primaryAddress.addressLine2;
                            this.referral.primaryAddress.city = this.patientData.primaryAddress.city;
                            this.referral.primaryAddress.country = this.patientData.primaryAddress.country;
                            this.referral.primaryAddress.state = this.patientData.primaryAddress.state;
                            this.referral.primaryAddress.zipCode = this.patientData.primaryAddress.zipCode;
                            this.referral.primaryAddress.county = this.patientData.primaryAddress.county;
                            this.referral.medicaidNumber = this.patientData.medicaidNumber;
                            this.referral.otherDME = this.patientData.dmEs.reduce(
                                (result, dmE: any) => result + (result ? ", " : "") + dmE.name,
                                ""
                            );
                            if (this.patientApplication == ApplicationEnum.AxxessPalliative.toString())
                                this.referral.isDNR = this.patientData.isDoNotResuscitate;
                        }
                    }
                    if (this.isRefer && this.patientApplication == ApplicationEnum.AgencyCore.toString()) {
                        let patientData = this.isPatient
                            ? await this.getHHReferPatientData()
                            : await this.getHHReferReferralData();
                        let userName = "";
                        this.getCurrentUser();
                        this._user = await this._userManager.getUser();

                        if (this._user) {
                            userName = formatName(this._user.profile.given_name, this._user?.profile.family_name);
                        }

                        this.referral.referralType = ReferralTypesEnum.Other;
                        this.referral.referralSource = userName;
                        this.referral.referralDetails = this.patientAgencyName;
                        this.referral.referralDate = String(moment().format("MM/DD/YYYY"));
                        this.referral.firstName = this.patientFirstName = patientData.Demographics.FirstName;
                        this.referral.middleName = patientData.Demographics.MiddleInitial;
                        this.referral.lastName = this.patientLastName = patientData.Demographics.LastName;
                        this.referral.dob = patientData.Demographics.DOB;
                        this.referral.gender = GenderEnum[patientData.Demographics.Gender];
                        this.referral.ssn = patientData.Demographics.SSN;
                        let phoneNumbers = [
                            {
                                number: patientData.Demographics.PhonePreferred,
                                extension: "",
                                type: 1,
                                country: null as any,
                                isPrimary: true,
                                ordinal: 1,
                                ordinalNumber: 1
                            },
                            {
                                number: patientData.Demographics.PhoneAlternate
                                    ? patientData.Demographics.PhoneAlternate
                                    : "",
                                extension: "",
                                type: 1,
                                country: null as any,
                                isPrimary: false,
                                ordinal: 2,
                                ordinalNumber: 2
                            },
                            {
                                number: "",
                                extension: "",
                                type: 1,
                                country: "",
                                isPrimary: false,
                                ordinal: 3,
                                ordinalNumber: 3
                            }
                        ];
                        this.referral.phoneNumbers = phoneNumbers;
                        if (patientData.Demographics.MaritalStatus && patientData.Demographics.MaritalStatus !== "0") {
                            this.referral.maritalStatus = MaritalStatuses.getValueUsingKey(
                                patientData.Demographics.MaritalStatus
                            );
                        }
                        this.referral.emailAddress = patientData.Demographics.EmailAddress;
                        this.referral.primaryAddress.addressLine1 =
                            patientData.Demographics.PrimaryAddress.AddressLine1;
                        this.referral.primaryAddress.addressLine2 =
                            patientData.Demographics.PrimaryAddress.AddressLine2;
                        this.referral.primaryAddress.city = patientData.Demographics.PrimaryAddress.City;
                        this.referral.primaryAddress.country = patientData.Demographics.PrimaryAddress.Country;
                        this.referral.primaryAddress.state = patientData.Demographics.PrimaryAddress.State;
                        this.referral.primaryAddress.zipCode = patientData.Demographics.PrimaryAddress.Zip;
                        this.referral.primaryAddress.county = patientData.Demographics.PrimaryAddress.County;
                        this.referral.medicaidNumber = patientData.Insurance.MedicaidNumber;
                        this.referral.alternateMedicaidNumber = patientData.Insurance.AlternateMedicaidNumber;
                        if (this.isPatient && !this.isNonMedical) {
                            this.referral.otherDME = patientData.Clinical.DMECollection.reduce(
                                (result: any, dmE: any) =>
                                    result + (result ? ", " : "") + DmeNeeded.getDescriptionUsingValue(Number(dmE)),
                                ""
                            );
                            if (patientData.AdvancedDirectives.AdvancedDirectivesIntentsCollection.includes("DNR"))
                                this.referral.isDNR = true;
                        }
                    }
                    if (this.isRefer && !this.isPatient) {
                        if (
                            this.patientApplication ==
                                (ApplicationEnum.AxxessHospiceFE.toString() ||
                                    ApplicationEnum.AxxessHospice.toString()) ||
                            this.patientApplication == ApplicationEnum.AxxessPalliative.toString()
                        ) {
                            this.referralData = (await this.getHosPalReferralData()) as any;
                            let userName = "";
                            this.getCurrentUser();
                            this._user = await this._userManager.getUser();

                            if (this._user) {
                                userName = formatName(this._user.profile.given_name, this._user?.profile.family_name);
                            }

                            this.referral.referralType = ReferralTypesEnum.Other;
                            this.referral.referralSource = userName;
                            this.referral.referralDetails = this.patientAgencyName;
                            this.referral.referralDate = String(moment().format("MM/DD/YYYY"));
                            this.referral.firstName = this.patientFirstName = this.referralData.firstName;
                            this.referral.middleName = this.referralData.middleInitial;
                            this.referral.lastName = this.patientLastName = this.referralData.lastName;
                            this.referral.dob = this.referralData.dateOfBirth;
                            this.referral.gender = String(this.referralData.gender);
                            this.referral.ssn = this.referralData.socialSecurityNumber;
                            let phoneNumbers = [
                                {
                                    number: this.referralData.phoneNumbers[0].number,
                                    extension: this.referralData.phoneNumbers[0].extension,
                                    type: this.referralData.phoneNumbers[0].type,
                                    country: this.referralData.phoneNumbers[0].country,
                                    isPrimary: this.referralData.phoneNumbers[0].isPrimary,
                                    ordinal: 1,
                                    ordinalNumber: 1
                                },
                                {
                                    number: this.referralData.phoneNumbers[1].number
                                        ? this.referralData.phoneNumbers[1].number
                                        : "",
                                    extension: this.referralData.phoneNumbers[1].extension
                                        ? this.referralData.phoneNumbers[1].extension
                                        : "",
                                    type: this.referralData.phoneNumbers[1].type
                                        ? this.referralData.phoneNumbers[1].type
                                        : 1,
                                    country: this.referralData.phoneNumbers[1].country
                                        ? this.referralData.phoneNumbers[1].country
                                        : null,
                                    isPrimary: this.referralData.phoneNumbers[1].isPrimary
                                        ? this.referralData.phoneNumbers[1].isPrimary
                                        : false,
                                    ordinal: 2,
                                    ordinalNumber: 2
                                },
                                {
                                    number: "",
                                    extension: "",
                                    type: 1,
                                    country: "",
                                    isPrimary: false,
                                    ordinal: 3,
                                    ordinalNumber: 3
                                }
                            ];
                            this.referral.phoneNumbers = phoneNumbers;
                            this.referral.maritalStatus = this.referralData.maritalStatus;
                            this.referral.emailAddress = this.referralData.email;
                            this.referral.primaryAddress.addressLine1 = this.referralData.primaryAddress.addressLine1;
                            this.referral.primaryAddress.addressLine2 = this.referralData.primaryAddress.addressLine2;
                            this.referral.primaryAddress.city = this.referralData.primaryAddress.city;
                            this.referral.primaryAddress.country = this.referralData.primaryAddress.country;
                            this.referral.primaryAddress.state = this.referralData.primaryAddress.state;
                            this.referral.primaryAddress.zipCode = this.referralData.primaryAddress.zipCode;
                            this.referral.primaryAddress.county = this.referralData.primaryAddress.county;
                            this.referral.medicaidNumber = this.referralData.medicaidNumber;
                        }
                    }
                } else {
                    if (this.referralId) {
                        // TODO - update once api available
                        let referral = (await this.getReferralData()) as HomeCareReferral;
                        this.referral = new HomeCareReferral(referral);
                        // adding this because BE not sending agencyId
                        this.referral.agencyId = this.referral.accountId;
                        if (this.referral.comments == null) {
                            this.referral.comments = "";
                        }
                        if (this.referral.otherDME == null) {
                            this.referral.otherDME = "";
                        }
                    } else {
                        throw new Error("Referral Id is missing");
                    }
                }
            } catch (e) {
                this.isLoadError = true;
                console.error(e);
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
                });
                this.$router.push("/axxess-central/intake/referral-list");
            } finally {
                this.showLoading(false);
                this.isFormLoading = false;
            }
        },
        async getReferralData() {
            const queryParam = {
                application: this.currentApplication,
                accountId: this.accountId,
                id: this.referralId
            };
            let referral = (await this._referralService.getHomeCareReferral(
                this.referralId,
                queryParam
            )) as HomeCareReferral;
            return referral;
        },
        async getHHReferPatientData() {
            try {
                const result = await this._patientService.getHHPatientAllData({
                    agencyId: this.patientAgencyId,
                    patientId: this.patientId
                });
                if (result) {
                    let tempReferral = result.data;
                    return tempReferral;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Home Health patient. Please try again. If the problem persists, please report it.`
                });
            }
        },
        async getHHReferReferralData() {
            try {
                const result = await this._patientService.getEditHHReferral({
                    referralId: this.patientId,
                    agencyId: this.patientAgencyId,
                    application: this.application
                });
                if (result) {
                    let tempReferral = result.data;
                    tempReferral.AgencyId = this.accountId;
                    return tempReferral;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Home Health patient. Please try again. If the problem persists, please report it.`
                });
            }
        },
        updateReferralSource(value: any) {
            this.referral.referralSource = value;
        },
        updateReferralDetails(value: any) {
            this.referral.referralDetails = value;
        },
        updateHasOtherServicesRequired(value: boolean) {
            this.referral.hasOtherServicesRequired = value;
        },
        updateServicesRequiredCollection(value: string) {
            if (this.referral.servicesRequiredCollection.includes(value)) {
                const index = this.referral.servicesRequiredCollection.indexOf(value);
                this.referral.servicesRequiredCollection.splice(index, 1);
                return;
            }
            this.referral.servicesRequiredCollection.push(value);
        },
        updateNonMedicalServicesRequiredCollection(value: string) {
            if (this.referral.nonMedicalServicesRequiredCollection.includes(value)) {
                const index = this.referral.nonMedicalServicesRequiredCollection.indexOf(value);
                this.referral.nonMedicalServicesRequiredCollection.splice(index, 1);
                return;
            }
            this.referral.nonMedicalServicesRequiredCollection.push(value);
        },
        async create() {
            try {
                this.hasErrorOnSave = false;
                const validated = await this.v$.$validate();
                if (validated) {
                    this.isSaveInProgress = true;
                    await this.createReferral();
                    this._toastrService.success({
                        title: `Referral Created`,
                        message: `The referral <b>${this.referral.lastName} ${this.referral.firstName}</b> has been successfully created.`
                    });
                    if (this.isRefer && this.isNonAdmit) {
                        this.doNonAdmit();
                    }
                    this.$router.push("/axxess-central/intake/referral-list");
                } else {
                    this._toastrService.error({
                        title: `Validation Failed`,
                        message: `Fill all required fields.`
                    });
                }
            } catch (err) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
                });
            } finally {
                this.isSaveInProgress = false;
            }
        },
        async createReferral() {
            try {
                this.referral.agencyId = this.accountId;
                this.referral.accountId = this.accountId;
                this.referral.inquiryId = this.inquiryId;

                await this._referralService.createHomeCareReferral(this.referral);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async goToReferralList() {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to leave the page? Please make sure you saved your work.",
                okText: "Yes, I'm Sure",
                cancelText: "Never Mind"
            };

            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: (options) => {
                    if (options.data === "success") {
                        this.$router.push("/axxess-central/intake/referral-list");
                    }
                }
            });
        },

        reorderContacts(contactOrder: IContactsOrder[]) {
            const contactsOrderQuery: IContactsOrderQueryParam = {
                contactsOrder: contactOrder,
                application: this.referral.application,
                agencyId: this.referral.accountId,
                accountId: this.referral.accountId
            };
            return this._referralService.reorderHCEmergencyContacts(contactsOrderQuery);
        },
        getEmergencyContacts(id: string) {
            const payload: IApplicationAndAccountQueryParam = {
                application: this.referral.application,
                agencyId: this.referral.accountId
            };
            return this._referralService.getHCEmergencyContacts(id, payload);
        },
        makeContactPrimary(contactId: string) {
            const payload: IApplicationAndAccountQueryParam = {
                application: this.referral.application,
                agencyId: this.referral.accountId
            };
            const referralId: string = this.referral.id;
            return this._referralService.makeHCEmergencyContactPrimary(referralId, contactId, payload);
        },

        async update() {
            const validated = await this.v$.$validate();
            if (validated) {
                try {
                    this.isSaveInProgress = true;
                    // adding this because BE not sending agencyId
                    this.referral.agencyId = this.referral.accountId;
                    await this._referralService.editHomeCareReferral(this.referral);
                    this._toastrService.success({
                        title: `Referral Updated`,
                        message: `The referral <b>${this.referral.lastName} ${this.referral.firstName} </b> has been successfully updated.`
                    });
                } catch (err) {
                    console.error(err);
                    if (err.status === HttpStatusCodeEnum.NotModified) {
                        this._toastrService.info({
                            title: `The referral <b>${this.referral.lastName} ${this.referral.firstName}</b> is up to date`,
                            message: `There were no changes made in ${this.referral.lastName} ${this.referral.firstName}'s profile.`
                        });
                    } else {
                        this._toastrService.error({
                            title: `Error`,
                            message: `There was a problem while saving this referral. Please try again. If the problem persists, please report it.`
                        });
                    }
                } finally {
                    this.isSaveInProgress = false;
                }
            } else {
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fix the validation errors in the form.`
                });
            }
        },
        async getCurrentUser() {
            const agencyId = this.accountId;

            try {
                let response = await this._userService.getCurrentUserId({ agencyId: agencyId });
                this.currentUserId = response.userId;
            } catch (err) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this User Id. Please try again. If the problem persists, please report it.`
                });
            }
        },
        async getHospicePatientData() {
            try {
                const queryParam = {
                    application: this.patientApplication,
                    patientId: this.patientId,
                    agencyId: this.patientAgencyId
                };
                const referral = await this._patientService.getHospicePatientById(queryParam);
                return referral;
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Hospice patient. Please try again. If the problem persists, please report it.`
                });
            }
        },
        async getHosPalReferralData() {
            const queryParam = {
                application: this.patientApplication,
                accountId: this.patientAgencyId,
                id: this.patientId
            };
            let referral = (await this._referralService.getReferral(this.patientId, queryParam)) as any;
            return referral;
        },
        doNonAdmit() {
            const patientAgencyId = this.patientAgencyId;
            const patientApplication = this.patientApplication;
            const patientId = this.patientId;
            let promptOptions = {
                message: "Are you sure you want to update this patient to Non-Admit?",
                okText: "Yes, I'm Sure",
                cancelText: "No"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            if (this.isPatient) {
                                if (patientApplication === ApplicationEnum.AgencyCore.toString()) {
                                    let queryObj: INonAdmitHHPatientQuery = {
                                        AgencyId: patientAgencyId.toString(),
                                        NonAdmitDate: this.nonAdmitDate,
                                        Reason: [this.nonAdmitReason],
                                        Application: ApplicationEnum.AgencyCore
                                    };
                                    await this._patientService.updatePatientToNonAdmit(patientId, queryObj);
                                }
                                if (
                                    patientApplication == ApplicationEnum.AxxessHospiceFE.toString() ||
                                    this.patientApplication == ApplicationEnum.AxxessPalliative.toString()
                                ) {
                                    let newNonAdmitData: INonAdmitPatientQuery = {
                                        nonAdmitDate: this.nonAdmitDate,
                                        nonAdmitReasonId: this.nonAdmitReasonId,
                                        nonAdmitComments: ""
                                    };

                                    let queryObj = JSON.parse(JSON.stringify(newNonAdmitData));
                                    queryObj.agencyId = patientAgencyId;
                                    queryObj.application = patientApplication;
                                    await this._patientService.updatePatientToNonAdmit(patientId, queryObj);
                                }
                            } else {
                                const queryObj = {
                                    accountId: patientAgencyId.toString(),
                                    nonAdmitDate: this.nonAdmitDate,
                                    nonAdmitReasonId: this.nonAdmitReasonId,
                                    nonAdmitReason: [this.nonAdmitReason],
                                    application: Number(this.patientApplication)
                                };
                                await this._centralIntake.updateReferralToNonAdmit(patientId, queryObj);
                            }
                            this._toastrService.success({
                                title: `${this.isPatient ? "Patient" : "Referral"} Converted to Non-Admit`,
                                message: `The ${this.isPatient ? "patient" : "referral"} <b>${this.patientLastName}, ${
                                    this.patientFirstName
                                }</b> has been converted to Non-Admit.`
                            });
                        } catch (error) {
                            this._toastrService.error({
                                title: `Error -${this.isPatient ? "Patient" : "Referral"} convert to Non-Admit`,
                                message: `There was a problem while converting the ${
                                    this.isPatient ? "patient" : "referral"
                                } <b>${this.patientLastName}, ${this.patientFirstName}</b>. Please try again.`
                            });
                        }
                    }
                }
            });
        }
    }
});
