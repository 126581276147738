import { defineComponent } from "vue";

import { Container } from "aurelia-dependency-injection";

import { AgencyService } from "../../services/agency.service";
import { AnalyticsService } from "../../services/analytics.service";
import { BranchesService } from "../../services/branches-service";
import { AgencyEvents } from "../App/components/AgencyList/AgencyList";
import eventBus from "../../utilities/eventBus";

import DashboardHeader from "../../resources-vue/vue-elements/DashboardHeader/DashboardHeader.vue";
import PersonalHelp from "./components/PersonalHelp/PersonalHelp.vue";
import PersonalTraining from "./components/PersonalTraining/PersonalTraining.vue";
import PersonalMessageList from "./components/PersonalMessageList/PersonalMessageList.vue";

export default defineComponent({
    components: {
        PersonalHelp,
        PersonalTraining,
        PersonalMessageList,
        DashboardHeader
    },

    data(vm) {
        return {
            ea: null,
            agencyService: null,
            analyticsService: null,
            _branchService: null,
            name: null,
            hasAgencyCore: true,
            hasHomeCare: true
        };
    },

    created() {
        this.agencyService = Container.instance.get(AgencyService);
        this.analyticsService = Container.instance.get(AnalyticsService);
        this._branchService = Container.instance.get(BranchesService);
        this.logUserApplications();
        eventBus.on(AgencyEvents.Loaded, () => {
            this.hasAgencyCore = this.agencyService.getHasAgencyCore();
            this.hasHomeCare = this.agencyService.getHasHomeCore();
            this.logUserApplications();
        });
    },

    methods: {
        logUserApplications(): void {
            if (this.hasAgencyCore && this.hasHomeCare) {
                this.analyticsService.logEvent({
                    category: "User-Applications",
                    action: "AC & HC"
                });
            }
        }
    }
});
