import { NumberOnlyEnum } from "../../enums/enums";
import { Key } from "ts-keycode-enum";

const directives = {
    "number-only": {
        created(element: HTMLInputElement) {
            let value = NumberOnlyEnum.Integer;
            if (!!element) {
                element.onkeydown = (e) => {
                    if (value === NumberOnlyEnum.Decimal) {
                        return checkIfEnteredKeyIsDecimal(e, element);
                    } else {
                        return checkIfEnteredKeyIsNumber(e);
                    }
                };
            }
        }
    }
};

function checkIfEnteredKeyIsNumber(e: any) {
    let charCode: number = e.which ? e.which : e.keyCode;
    let cutCopyPaste = e.ctrlKey && (e.keyCode == Key.C || e.keyCode == Key.V || e.keyCode == Key.X);
    if (cutCopyPaste) {
        return true;
    }
    let isKeyPad = e.keyCode >= Key.Numpad0 && e.keyCode <= Key.Numpad9;
    if ((charCode > Key.Nine && !isKeyPad) || e.shiftKey || e.keyCode == Key.Space) {
        return false;
    }
    return true;
}

function checkIfEnteredKeyIsDecimal(e: any, element: HTMLInputElement) {
    let checkKey = checkIfEnteredKeyIsNumber(e);
    let isDecimal = e.keyCode == Key.Period || e.keyCode == Key.DecimalPoint;
    if (!checkKey && !isDecimal) {
        return false;
    }
    let str = element.value?.toString();
    let hasTwoDecimals = isDecimal && str?.includes(".");
    let startsWithDecimal = isDecimal && str?.length === 0;
    if (hasTwoDecimals || startsWithDecimal) {
        return false;
    }
    return true;
}

export const numberOnlyDirectives = directives;
