import { ApplicationEnum } from "../../enums/ApplicationEnum";
import { IAddress } from "../vue-interfaces/i-address";
import { IPhoneNumber } from "../vue-interfaces/i-phone-number";

export class HCContact {
    public id: string;
    public patientId?: string; // is used for update endpoint.
    public referralId?: string; // is used for update endpoint.4
    public agencyId?: string;
    public accountId?: string;
    public application: ApplicationEnum;
    public firstName: string = "";
    public lastName: string = "";
    public mi: string = "";
    public email: string = "";
    public relationship: string = "";
    public isPrimary: boolean = false;
    public isUsingPrimaryAddress: boolean;
    public address: IAddress = {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        county: "",
        country: ""
    };
    public phoneNumbers: IPhoneNumber[] = [
        {
            number: "",
            extension: "",
            type: 1,
            isPrimary: true,
            country: null,
            ordinal: 1
        },
        {
            number: "",
            extension: "",
            type: 0,
            isPrimary: false,
            country: null,
            ordinal: 2
        }
    ];
    public contactOrder: number = null;
    public constructor(data?: HCContact) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
