import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PayerTypesEnum {
    Primary = 0 as any,
    Secondary = 1 as any,
    Tertiary = 2 as any,
    RoomAndBoard = 3 as any,
    PalliativeCare = 4 as any,
    VBID = 5 as any
}
class PayerTypesClass extends EnumWithDescription<PayerTypesEnum> {
    public readonly enum = PayerTypesEnum;
    public readonly enumName: string = "PayerTypesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PayerTypesEnum>> = new Map([
        [
            "Primary",
            {
                value: PayerTypesEnum.Primary,
                name: "Primary"
            }
        ],
        [
            "Secondary",
            {
                value: PayerTypesEnum.Secondary,
                name: "Secondary"
            }
        ],
        [
            "Tertiary",
            {
                value: PayerTypesEnum.Tertiary,
                name: "Tertiary"
            }
        ],
        [
            "RoomAndBoard",
            {
                value: PayerTypesEnum.RoomAndBoard,
                name: "Room & Board"
            }
        ],
        [
            "PalliativeCare",
            {
                value: PayerTypesEnum.PalliativeCare,
                name: "Palliative Care"
            }
        ],
        [
            "VBID",
            {
                value: PayerTypesEnum.VBID,
                name: "VBID"
            }
        ]
    ]);
}

export const PayerTypes = new PayerTypesClass();
