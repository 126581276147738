import "./inquiry-form.scss";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { ValidationController } from "aurelia-validation";

import { ApplicationEnum } from "../../../enums/enums";
import type { IBranchResponse } from "../../../interfaces/i-branch";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import type { Inquiry } from "../../../models/inquiry";

@autoinject
@customElement("home-care-inquiry-form")
export class HomeCareInquiryForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public inquiry: Inquiry;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public branches: IBranchResponse[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateBranch: (params: { applicationId: ApplicationEnum }) => void;
    public isError: boolean = false;
    public showTemplate: boolean = false;
    public dateInputOption = {
        isReadOnly: false,
        name: "date",
        placeholderText: "MM/DD/YYYY"
    };
    public patientPhoneValidation: IValidateCustomElement = {
        required: true,
        displayName: "Phone Number",
        matches: false
    };
    public contactPhoneValidation: IValidateCustomElement = {
        matches: true
    };
    public controller: ValidationController = null;
    public isPhoneNumberInValid: boolean = false;

    public constructor(validationController: ValidationController) {
        this.controller = validationController;
    }

    public async attached() {
        this.controller.subscribe((x) => {
            this.isPhoneNumberInValid = !!x.errors.find(
                (error) => error.rule.property.displayName == "primary-phone-number"
            );
        });
        this.updateBranch({ applicationId: this.inquiry.application });
    }

    public selectedBranch(branchId: string) {
        let branchDetail: IBranchResponse = null;
        if (this.branches.length > 0) {
            branchDetail = this.branches.find((branch) => branch.id === branchId);
        }
        this.inquiry.accountId = branchDetail ? branchDetail.accountId : "";
    }
}
