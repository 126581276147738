<template>
    <div class="card p-2">
        <div v-if="options.title">
            <h4 class="mb-0">{{ options.title }}</h4>
        </div>
        <div>
            <p>
                <span class="big-text p-2">
                    <span v-html="options.message"></span>
                    <br />
                    <span v-html="options.additionalMessage"></span>
                </span>
            </p>
        </div>
        <div class="footer">
            <button
                type="button"
                class="btn btn-light border border-secondary mr-2"
                @click="cancel()"
                v-if="options.cancelText"
            >
                {{ options.cancelText }}
            </button>
            <button type="button" class="btn btn-primary border border-primary" @click="ok()">
                {{ options.okText }}
            </button>
        </div>
    </div>
</template>

<style src="./Prompt.scss"></style>
<script src="./Prompt.ts"></script>
