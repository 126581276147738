import type { IEnumResponse } from "../interfaces/i-enum";
import { EnumMapType } from "./utilities/enum-map-type";

export class EnumMap {
    private _enumJson: IEnumResponse[];
    private _enumMapByValue: EnumMapType = new Map<number | string, string>();
    private _enumMapByName: EnumMapType = new Map<string, string | number>();
    private _enumMapObjectByValue: Map<string | number, IEnumResponse> = new Map();
    private _enumMapObjectByName: Map<string | number, IEnumResponse> = new Map();

    public constructor(enumJson: IEnumResponse[]) {
        this._enumJson = enumJson;
        this.constructEnumMap();
        return this;
    }

    private constructEnumMap() {
        this._enumJson.forEach((item) => {
            this._enumMapByValue.set(item.value, item.name);
            this._enumMapByName.set(item.name.toLocaleLowerCase(), item.value);
            this._enumMapObjectByValue.set(item.value, item);
            this._enumMapObjectByName.set(item.name.toLocaleLowerCase(), item);
        });
    }

    public getJson() {
        return this._enumJson;
    }

    public getMap() {
        return this._enumMapByValue;
    }

    public getEnumName(value: string | number) {
        if (this._enumMapByValue.size > 0) {
            return this._enumMapByValue.get(value) ?? "";
        }
        return "";
    }

    public getEnumValue(name: string) {
        if (this._enumMapByName.size > 0) {
            return this._enumMapByName.get(name.toLocaleLowerCase());
        }
        return null;
    }

    public getEnumObjectByValue(value: string | number) {
        if (this._enumMapObjectByValue.size > 0) {
            return this._enumMapObjectByValue.get(value);
        }
        return null;
    }

    public getEnumObjectByName(name: string) {
        if (this._enumMapObjectByName.size > 0) {
            return this._enumMapObjectByName.get(name.toLocaleLowerCase());
        }
        return null;
    }

    public checkEnumName(enumName: string, nameToTest: string) {
        // Just gets alphabets
        let onlyAlphabets = enumName.replace(/[^A-Za-z ]/g, "").toLowerCase();
        return onlyAlphabets.includes(nameToTest);
    }

    public checkEnumValue(enumName: string, value: string | number) {
        return this.getEnumValue(enumName) === value;
    }
}
