import { PropType, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import eventBus from "../../../../../utilities/eventBus";
import config from "../../../../../common/config";
import { UserService } from "../../../../../services/user-service";
import { AgencyService, IGetAgency, IGetOrganization } from "../../../../../services/agency.service";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { ITypeaheadOptions } from "../../../../../interfaces/i-typeahead";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum
} from "../../../../../enums/enums";
import { IMultiSelectDropDownOption } from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect";
import { IVisitMapDetails } from "../../../../../resources-vue/vue-interfaces/i-visit-map";
import moment from "moment";

import MultiSelectFilter from "../../../../../resources-vue/vue-elements/MultiSelectFilter/MultiSelectFilter.vue";
import MultiSelect from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect.vue";
import VisitStatusMultiSelect from "../../../components/VisitStatusMultiSelect/VisitStatusMultiSelect.vue";
import DailyDateNavigation from "../../../components/DailyDateNavigation/DailyDateNavigation.vue";

export default defineComponent({
    components: {
        MultiSelect,
        MultiSelectFilter,
        VisitStatusMultiSelect,
        DailyDateNavigation
    },

    props: {
        mapVisitsList: { type: Array as PropType<IVisitMapDetails[]>, default: [] },
        employeesList: { type: Object, default: {} },
        fetchUpdatedCount: { type: Number, default: 0 }
    },

    emits: ["mapRefresh"],

    data() {
        return {
            _userService: null,
            _agencyService: null,
            _permissionManager: null,
            selectedOrganization: null as number,
            agencyMultiSelectOptions: {} as IMultiSelectDropDownOption,
            selectedAgencies: [] as string[],
            selectedCredentials: [] as ITypeaheadOptions[],
            credentialsList: [] as string[],
            organizations: [] as IGetOrganization[],
            agencyList: [] as IGetAgency[],
            organizationName: "",
            selectedEmployees: [],
            statusList: [
                { name: "Not Yet Started", value: "notYetStarted" },
                { name: "In Progress", value: "inProgress" },
                { name: "Completed", value: "completed" }
            ] as ITypeaheadOptions[],
            selectedVisitStatus: [] as ITypeaheadOptions[],
            startDate: new Date(Date.now()) as Date,
            endDate: null as Date
        };
    },

    watch: {
        selectedVisitStatus() {
            this.emitMapRefresh();
        }
    },

    created() {
        this._agencyService = Container.instance.get(AgencyService);
        this._userService = Container.instance.get(UserService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },

    async mounted() {
        try {
            this.credentialsList = await this._userService.getUserCredentials();
            this.organizations = await this._agencyService.getOrganizations();
            this.organizationName = window.localStorage.getItem(config.organizationName);
            this.agencyList = this.organizations.find(
                (organization: { name: string }) => organization.name === this.organizationName
            ).agencies;
            this.agencyList = this.agencyList.map((agency) => {
                let agencyAndApplicationIds = agency.id.split("_");
                let application = parseInt(agencyAndApplicationIds[1], 10);
                if (application == 256) {
                    application = ApplicationEnum.AxxessHospice;
                }
                return {
                    ...agency,
                    application: application
                };
            });
            await this.setAgencyMultiSelect();
        } catch (e) {
            console.error(e);
        }
    },

    methods: {
        updateDate(options: any) {
            this.startDate = options.startDate;
            this.endDate = options.endDate;
            this.emitMapRefresh();
        },

        async setAgencyMultiSelect() {
            try {
                this.agencyMultiSelectOptions = {};
                this.selectedAgencies.length = 0;
                if (!this.agencyList || this.agencyList?.length === 0) {
                    return;
                }
                let allowedProducts = [
                    ApplicationEnum.AgencyCore,
                    ApplicationEnum.HomeCare,
                    ApplicationEnum.AxxessHospice,
                    ApplicationEnum.AxxessPalliative
                ];
                const schedulerViewPermissions = this._permissionManager.getPermmission(
                    ParentPermissionEnum.scheduler,
                    PermissionActionEnum.canView
                );
                let schedulerAgencies = schedulerViewPermissions
                    .filter((item: any) => {
                        if (!item) {
                            return false;
                        }
                        let application = item.application;
                        if (application === ApplicationEnum.AxxessHospiceFE) {
                            application = ApplicationEnum.AxxessHospice;
                        }
                        return allowedProducts.includes(application);
                    })
                    .map((x: any) => x.agencyId);
                let filterableProducts = this.agencyList.filter((agency) => {
                    let isAllowed = allowedProducts.includes(agency.application);
                    let agencyId = agency.id?.split("_")[0];
                    return isAllowed && schedulerAgencies.includes(agencyId);
                });
                filterableProducts?.forEach((agency) => {
                    let appName = ApplicationNameEnum[agency.application];

                    if (!this.agencyMultiSelectOptions[appName]) {
                        this.agencyMultiSelectOptions[appName] = [];
                    }

                    this.agencyMultiSelectOptions[appName].push({
                        displayText: agency.name,
                        value: agency.id
                    });
                });

                Object.values(this.agencyMultiSelectOptions).forEach((agency: any) => {
                    let values = agency.map((item: any) => item.value);
                    this.selectedAgencies.push(...values);
                });
                // For select agencies change
                await this.emitMapRefresh();
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },

        updateBranches(branches: any) {
            this.selectedAgencies.length = 0;
            this.selectedAgencies.push(...branches);
            this.emitMapRefresh();
        },

        userCredentialsFetch(filter: string) {
            return this.credentialsList
                .map((credential, index) => ({
                    name: credential,
                    value: index.toString()
                }))
                .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
        },

        selectedItemsUpdated(options: any) {
            this.selectedCredentials = options;
            this.emitMapRefresh();
        },

        selectEmployeeFetch(filter: string) {
            let employees = [];
            for (const [key, value] of Object.entries(this.employeesList)) {
                employees.push({ name: key, value: value });
            }
            return employees.filter(
                (emp) => !filter || emp.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            );
        },

        selectedEmployeesUpdated(options: any) {
            this.selectedEmployees = options;
            eventBus.emit("highlightEmployeeMarker", this.selectedEmployees);
        },

        selectedVisitStatusUpdated(value: any) {
            this.selectedVisitStatus = value;
            this.emitMapRefresh();
        },

        async emitMapRefresh() {
            this.selectedEmployees = [];
            this.$emit("mapRefresh", {
                visitStatus: this.selectedVisitStatus,
                date: moment(this.startDate).format("MM/DD/YYYY"),
                agencies: this.selectedAgencies,
                credentials: this.selectedCredentials?.map((credential) => credential.name).join(";")
            });
        }
    }
});
