import { ApplicationEnum } from "../../enums/enums";

export class PreadmissionNote {
    public title: string = "";
    public notes: string = "";
    public application?: ApplicationEnum = null;
    public accountId?: string = "";

    public constructor(data?: PreadmissionNote) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
