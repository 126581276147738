import { defineComponent } from "vue";

import TabsAnimatedHeader from "../../resources-vue/vue-custom-elements/TabsAnimatedHeader/TabsAnimatedHeader.vue";

type IRoute = {
    path: string;
    name: string;
    meta: {
        menuName: string;
        navItem: string;
    };
};
type ItemRef = {
    [key: string]: Element;
};

export default defineComponent({
    components: {
        TabsAnimatedHeader
    },

    props: ["router"],

    data() {
        return {
            itemRefs: {} as ItemRef
        };
    },

    computed: {
        getActiveItem() {
            return this.itemRefs[this.$route.meta.navItem as string];
        }
    },
    methods: {
        addItemRefs(el: Element, route: IRoute) {
            this.itemRefs[route.meta.navItem] = el;
        },

        isActivePath(path: string) {
            return this.$route.matched?.some((route) => route.path == path);
        },

        goTo(path: string) {
            this.$router.push(path);
        }
    }
});
