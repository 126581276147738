import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, observable } from "aurelia-framework";
import { NavModel, Router } from "aurelia-router";
import { User, UserManager } from "oidc-client";

import config from "../../common/config";
import { formatName } from "../../common/format-name";
import nameOf from "../../common/name-of";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../enums/enums";
import { AgencyService } from "../../services/agency.service";
import AuthService from "../../services/auth.service";
import { helperTestingAgency } from "../../common/vue-helpers/testing-agency-check";
import { IGetuserDetailResponse, UserService } from "../../services/user-service";
import { PageTakeOverLoading } from "./loading-indicator";
import { ToastrService } from "../../services/toastr.service";

@autoinject
export class AppNav {
    private _ea: EventAggregator;
    private _agencyService: AgencyService;
    private _user: User;
    private _routeProcessSubscription: Subscription;
    private _userManager: UserManager;
    private _authService: AuthService;
    private _userService: UserService;
    private _permissionManager: PermissionManager;
    private _toastrService: ToastrService;

    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameOf<AppNav>("areRolesLoadedChanged") })
    public areRolesLoaded: boolean = false;
    public hasCertificationAccess: boolean = false;
    public router: Router;
    public displayRoutes: NavModel[] = [];
    public defaultRoute: string = config.defaultRoute;
    public userName: string = "";
    public certificationUrl: string = `${config.certificationUrl}/auth/axxess_sso/login.php`;
    public isLoggedIn: boolean = false;
    public hasBetaTogglePermission: boolean = false;

    public constructor(
        router: Router,
        ea: EventAggregator,
        agencyService: AgencyService,
        userManager: UserManager,
        authService: AuthService,
        userService: UserService,
        permissionManager: PermissionManager,
        toastrService: ToastrService
    ) {
        this.router = router;
        this._ea = ea;
        this._agencyService = agencyService;
        this._userManager = userManager;
        this._authService = authService;
        this._userService = userService;
        this._permissionManager = permissionManager;
        this._toastrService = toastrService;
    }

    public async attached(): Promise<void> {
        this.loadUser();
        this.getCertificationAccess();
        this._routeProcessSubscription = this._ea.subscribe("router:navigation:processing", () => {
            this.checkCurrentRoute();
        });
        const userbetaInfo: IGetuserDetailResponse = await this._userService.getUserDetails();
        if (userbetaInfo.Data) this.hasBetaTogglePermission = true;
    }

    public async areRolesLoadedChanged() {
        const isClinician = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canView
        );
        const isScheduler = this._permissionManager.checkPermission(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canView
        );
        const isAddHospiceInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AxxessHospiceFE
        );
        const isViewHospiceInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canView,
            ApplicationEnum.AxxessHospiceFE
        );

        const isEditHospiceInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canEdit,
            ApplicationEnum.AxxessHospiceFE
        );

        const isAddHospiceReferral = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AxxessHospiceFE
        );
        const isViewHospiceReferral = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canView,
            ApplicationEnum.AxxessHospiceFE
        );

        const isEditHospiceReferral = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canEdit,
            ApplicationEnum.AxxessHospiceFE
        );

        const isAddPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AxxessPalliative
        );
        const isViewPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canView,
            ApplicationEnum.AxxessPalliative
        );

        const isEditPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canEdit,
            ApplicationEnum.AxxessPalliative
        );

        const isAddPalliativeReferral = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AxxessPalliative
        );
        const isViewPalliativeReferral = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canView,
            ApplicationEnum.AxxessPalliative
        );

        const isEditPalliativeReferral = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canEdit,
            ApplicationEnum.AxxessPalliative
        );

        const isAddHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canAdd,
            ApplicationEnum.HomeCare
        );
        const isViewHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canView,
            ApplicationEnum.HomeCare
        );

        const isEditHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.inquiries,
            PermissionActionEnum.canEdit,
            ApplicationEnum.HomeCare
        );

        const isTestAgency = await helperTestingAgency.checkHasTestingAgency();

        const showCentralIntake =
            isAddHospiceInquiry ||
            isEditHospiceInquiry ||
            isViewHospiceInquiry ||
            isAddHospiceReferral ||
            isEditHospiceReferral ||
            isViewHospiceReferral ||
            isAddPalliativeInquiry ||
            isEditPalliativeInquiry ||
            isViewPalliativeInquiry ||
            isAddPalliativeReferral ||
            isEditPalliativeReferral ||
            isViewPalliativeReferral ||
            isAddHomeCareInquiry ||
            isViewHomeCareInquiry ||
            isEditHomeCareInquiry;

        // Filtering routes to display tabs based on roles
        this.displayRoutes = this.router.navigation.filter((route) => {
            if (!!route.config.settings?.showIfScheduler) {
                return isScheduler;
            } else if (!!route.config.settings?.showCentralIntake) {
                return showCentralIntake;
            } else if (!!route.config.settings?.showAxxessPlanner) {
                return isClinician;
            } else if (!!route.config.settings?.showCentralMessaging) {
                return isTestAgency;
            } else {
                return true;
            }
        });
    }

    private async getCertificationAccess() {
        try {
            this.hasCertificationAccess = await this._agencyService.getHasCertificationAccess();
        } catch (e) {
            console.error(e);
        }
    }

    private async loadUser() {
        this._user = await this._userManager.getUser();
        if (this._user) {
            this.isLoggedIn = await this._authService.isLoggedIn();
            this.userName = formatName(this._user.profile.given_name, this._user.profile.family_name, "FN LN");
        }
    }

    private checkCurrentRoute(): void {
        let doesUrlTokenExist: boolean = window.location.href.indexOf("token=") > -1;

        if (doesUrlTokenExist) {
            let currentRoute: string = this.router.currentInstruction.config.name;
            this.router.navigateToRoute(currentRoute);
        }
    }

    private async switchToBetaCentral(): Promise<void> {
        try {
            this._ea.publish(PageTakeOverLoading.Show);
            const isBetaOpted = true;
            await this._userService.updateUserBetaDetails(isBetaOpted);
            const originURL = window.location.origin;
            window.location.href = originURL;
        } catch (e) {
            console.error(e);
            this._ea.publish(PageTakeOverLoading.Hide);
            this._toastrService.error({
                title: `Error - Switching to Beta`,
                message: `There was an error switching to Beta. Please Try again later.`
            });
        }
    }

    public detached(): void {
        this._routeProcessSubscription?.dispose();
    }
}
