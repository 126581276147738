import { defineComponent } from "vue";
import { mapState } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { ReferralService } from "../../../../../services/referral-service";
import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { ApplicationEnum } from "../../../../../enums/enums";
import { formatPhone } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { IGetCityStateByZipcodeResponse } from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { LookupService } from "../../../../../services/lookup-service";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ReferralAddressForm from "../../ReferralAddressForm/ReferralAddressForm.vue";

export default defineComponent({
    components: {
        FormSection,
        ReferralAddressForm
    },
    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId"
        })
    },
    data() {
        return {
            zones: [] as string[],
            stateClass: "col-lg-6",
            _referralServices: null,
            _lookupService: null,
            EvacuationCounty: [],
            cacheEvacuationAddress: {
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                County: "",
                Zip: "",
                ZipCodeFour: ""
            },
            formatPhone
        };
    },

    async created() {
        this._referralServices = Container.instance.get(ReferralService);
        this._lookupService = Container.instance.get(LookupService);
        await this.getCounty();
    },

    async mounted() {
        let obj = {
            agencyId: this.referral.AgencyId,
            application: this.application || ApplicationEnum.AgencyCore
        };
        const ZoneResponse = await this._referralServices.getEvacuationZone(obj);
        this.zones = ZoneResponse.zones;
    },

    methods: {
        SameAsEmergencyContactEvacChanged() {
            this.referral.EmergencyPreparedness.SameAsEmergencyContactEvac =
                !this.referral.EmergencyPreparedness.SameAsEmergencyContactEvac;
            if (this.referral.EmergencyPreparedness.SameAsEmergencyContactEvac) {
                this.referral.EmergencyPreparedness.EvacuationAddress = Object.assign(
                    {},
                    this.referral.Contacts.PrimaryEmergencyContact.Address
                );
                this.referral.EmergencyPreparedness.EvacuationPhoneMobile =
                    this.referral.Contacts.PrimaryEmergencyContact.PrimaryPhone;
                this.referral.EmergencyPreparedness.EvacuationPhoneHome =
                    this.referral.Contacts.PrimaryEmergencyContact.AlternatePhone;
                this.getCounty();
            } else {
                this.referral.EmergencyPreparedness.EvacuationAddress = this.cacheEvacuationAddress;
                this.referral.EmergencyPreparedness.EvacuationPhoneMobile = "";
                this.referral.EmergencyPreparedness.EvacuationPhoneHome = "";
                this.EvacuationCounty = [];
            }
        },

        async getCounty() {
            if (this.referral.EmergencyPreparedness.EvacuationAddress.Zip) {
                const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                    zipCode: this.referral.EmergencyPreparedness.EvacuationAddress.Zip,
                    agencyId: this.accountId,
                    application: this.application
                });
                const county: string[] = await this._lookupService.getCountyByZipcode(
                    this.referral.EmergencyPreparedness.EvacuationAddress.Zip,
                    this.accountId
                );
                this.EvacuationCounty = county;
                this.referral.EmergencyPreparedness.EvacuationAddress.County = county ? county[0] : null;
                this.referral.EmergencyPreparedness.EvacuationAddress.State = result.state
                    ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                    : null;
                this.referral.EmergencyPreparedness.EvacuationAddress.City = result.city ? result.city : null;
            }
        },

        setMobilePhone(value: any) {
            this.referral.EmergencyPreparedness.EvacuationPhoneMobile = value.toString();
        },

        setAlternatePhone(value: any) {
            this.referral.EmergencyPreparedness.EvacuationPhoneHome = value.toString();
        }
    }
});
