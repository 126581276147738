import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { GoogleMapsService } from "../../../services/google-maps.service";
import type { IGetCountryCode } from "../../../resources-vue/vue-interfaces/i-lookup";
import { LookupService } from "../../../services/lookup-service";
import { formatZipCode } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            validAddress: true,
            validVisitAddress: true,
            validMailingAddress: true,
            countryCodes: [] as IGetCountryCode[],
            options: null,
            dialogReference: this.dialogRef as any,
            _lookupService: null,
            _googleMapsWebService: null,
            _googleMapsWebApi: null,
            _geoCoder: null,
            formatZipCode
        };
    },
    created() {
        this._lookupService = Container.instance.get(LookupService);
        this._googleMapsWebService = Container.instance.get(GoogleMapsService);
        this.options = this.dialogReference.data.model;
    },
    async beforeMount() {
        this.options = this.dialogReference.data.model;
    },
    async mounted() {
        const address = this.options.originalAddress;
        this.countryCodes = await this._lookupService.getCountryCodes();
        this._googleMapsWebApi = await this._googleMapsWebService.getMapsApi();
        this._geoCoder = new this._googleMapsWebApi.Geocoder();
        this.validAddress =
            address.addressLine1.length > 0 &&
            address.city.length > 0 &&
            address.state.length > 0 &&
            address.zipCode.length > 0;
        if (this.validAddress) {
            let updatedCountryLabel = "";
            this._geoCoder.geocode(
                {
                    address:
                        address.addressLine1 +
                        " " +
                        address.addressLine2 +
                        " " +
                        (address.state === "0" ? "" : address.state) +
                        " " +
                        address.zipCode
                },
                (results: any, status: string) => {
                    if (status === "OK" && results.length > 0) {
                        if (results[0].address_components.length > 7) {
                            this.options.updatedAddress.addressLine2 =
                                results[0].address_components[0].short_name.toUpperCase();
                        } else if (address.addressLine2.length > 0) {
                            this.options.updatedAddress.addressLine2 = address.addressLine1.toUpperCase();
                        }
                        results[0].address_components.forEach((addressVal: google.maps.GeocoderAddressComponent) => {
                            let addressType = addressVal.types[0];
                            switch (addressType) {
                                case "street_number":
                                    this.options.updatedAddress.addressLine1 = addressVal.short_name.toUpperCase();
                                    break;
                                case "route":
                                    this.options.updatedAddress.addressLine1 =
                                        this.options.updatedAddress.addressLine1 +
                                        " " +
                                        addressVal.short_name.toUpperCase();
                                    break;
                                case "locality":
                                    this.options.updatedAddress.city = addressVal.short_name.toUpperCase();
                                    break;
                                case "administrative_area_level_1":
                                    this.options.updatedAddress.state = addressVal.short_name.toUpperCase();
                                    break;
                                case "administrative_area_level_2":
                                    this.options.updatedAddress.county = addressVal.short_name.replace(" County", "");
                                    break;
                                case "postal_code":
                                    this.options.updatedAddress.zipCode = addressVal.short_name.toUpperCase();
                                    break;
                                case "country":
                                    updatedCountryLabel = addressVal.long_name;
                                    break;
                                default:
                                    break;
                            }
                            let updatedCountry = this.countryCodes?.find(
                                (country) => country.name.toLowerCase() === updatedCountryLabel.toLowerCase()
                            );
                            if (updatedCountry) {
                                this.options.updatedAddress.country = updatedCountry.value;
                            }
                        });
                        this.options.updatedAddress.latitude = results[0].geometry.location.lat();
                        this.options.updatedAddress.longitude = results[0].geometry.location.lng();
                    }
                }
            );
        }
        this.validVisitAddress =
            address.visitAddressLine1?.length > 0 &&
            address.visitCity?.length > 0 &&
            address.visitState?.length > 0 &&
            address.visitZipCode?.length > 0;
        if (this.validVisitAddress) {
            let updatedCountryLabel = "";
            this._geoCoder.geocode(
                {
                    address:
                        address.visitAddressLine1 +
                        " " +
                        address.visitAddressLine2 +
                        " " +
                        (address.visitState === "0" ? "" : address.visitState) +
                        " " +
                        address.visitZipCode
                },
                (results: any, status: string) => {
                    if (status === "OK" && results.length > 0) {
                        if (results[0].address_components.length > 7) {
                            this.options.updatedAddress.visitAddressLine2 =
                                results[0].address_components[0].short_name.toUpperCase();
                        } else if (address.visitAddressLine2.length > 0) {
                            this.options.updatedAddress.visitAddressLine2 = address.visitAddressLine1.toUpperCase();
                        } else {
                            this.options.updatedAddress.visitAddressLine2 = "";
                        }
                        results[0].address_components.forEach((addressVal: google.maps.GeocoderAddressComponent) => {
                            let addressType = addressVal.types[0];
                            switch (addressType) {
                                case "street_number":
                                    this.options.updatedAddress.visitAddressLine1 = addressVal.short_name.toUpperCase();
                                    break;
                                case "route":
                                    this.options.updatedAddress.visitAddressLine1 =
                                        this.options.updatedAddress.visitAddressLine1 +
                                        " " +
                                        addressVal.short_name.toUpperCase();
                                    break;
                                case "locality":
                                    this.options.updatedAddress.visitCity = addressVal.short_name.toUpperCase();
                                    break;
                                case "administrative_area_level_1":
                                    this.options.updatedAddress.visitState = addressVal.short_name.toUpperCase();
                                    break;
                                case "administrative_area_level_2":
                                    this.options.updatedAddress.visitCounty = addressVal.short_name.replace(
                                        " County",
                                        ""
                                    );
                                    break;
                                case "postal_code":
                                    this.options.updatedAddress.visitZipCode = addressVal.short_name.toUpperCase();
                                    break;
                                case "country":
                                    updatedCountryLabel = addressVal.long_name;
                                    break;
                                default:
                                    break;
                            }
                            let updatedCountry = this.countryCodes?.find(
                                (country) => country.name.toLowerCase() === updatedCountryLabel.toLowerCase()
                            );
                            if (updatedCountry) {
                                this.options.updatedAddress.visitCountry = updatedCountry.value;
                            }
                        });
                        this.options.updatedAddress.visitLatitude = results[0].geometry.location.lat();
                        this.options.updatedAddress.visitLongitude = results[0].geometry.location.lng();
                    }
                }
            );
        }
        this.validMailingAddress =
            address.mailingAddressLine1.length > 0 &&
            address.mailingCity.length > 0 &&
            address.mailingState.length > 0 &&
            address.mailingZipCode.length > 0;
        if (this.validMailingAddress) {
            let updatedCountryLabel = "";
            this._geoCoder.geocode(
                {
                    address:
                        address.mailingAddressLine1 +
                        " " +
                        address.mailingAddressLine2 +
                        " " +
                        (address.mailingState === "0" ? "" : address.mailingState) +
                        " " +
                        address.mailingZipCode
                },
                (results: any, status: string) => {
                    if (status === "OK" && results.length > 0) {
                        if (results[0].address_components.length > 7) {
                            this.options.updatedAddress.mailingAddressLine2 =
                                results[0].address_components[0].short_name.toUpperCase();
                        } else if (address.mailingAddressLine2.length > 0) {
                            this.options.updatedAddress.mailingAddressLine2 = address.mailingAddressLine1.toUpperCase();
                        }
                        results[0].address_components.forEach((addressVal: google.maps.GeocoderAddressComponent) => {
                            let addressType = addressVal.types[0];
                            switch (addressType) {
                                case "street_number":
                                    this.options.updatedAddress.mailingAddressLine1 =
                                        addressVal.short_name.toUpperCase();
                                    break;
                                case "route":
                                    this.options.updatedAddress.mailingAddressLine1 =
                                        this.options.updatedAddress.mailingAddressLine1 +
                                        " " +
                                        addressVal.short_name.toUpperCase();
                                    break;
                                case "locality":
                                    this.options.updatedAddress.mailingCity = addressVal.short_name.toUpperCase();
                                    break;
                                case "administrative_area_level_1":
                                    this.options.updatedAddress.mailingState = addressVal.short_name.toUpperCase();
                                    break;
                                case "administrative_area_level_2":
                                    this.options.updatedAddress.mailingCounty = addressVal.short_name.replace(
                                        " County",
                                        ""
                                    );
                                    break;
                                case "postal_code":
                                    this.options.updatedAddress.mailingZipCode = addressVal.short_name.toUpperCase();
                                    break;
                                case "country":
                                    updatedCountryLabel = addressVal.long_name;
                                    break;
                                default:
                                    break;
                            }
                            let updatedCountry = this.countryCodes?.find(
                                (country) => country.name.toLowerCase() === updatedCountryLabel.toLowerCase()
                            );
                            if (updatedCountry) {
                                this.options.updatedAddress.mailingCountry = updatedCountry.value;
                            }
                        });
                        this.options.updatedAddress.mailingLatitude = results[0].geometry.location.lat();
                        this.options.updatedAddress.mailingLongitude = results[0].geometry.location.lng();
                    }
                }
            );
        }
    },
    computed: {
        originalAddressCountry() {
            return this.getCountryLabel(this.options?.originalAddress?.country);
        },
        updatedAddressCountry() {
            return this.getCountryLabel(this.options?.updatedAddress?.country);
        }
    },
    methods: {
        getCountryLabel(code: string) {
            let selectedCountry = this.countryCodes?.find((country) => country.value === code);
            return selectedCountry?.name;
        },
        checkEqualAddress(original: string, updated: string) {
            return original == updated ? "" : "text-danger";
        },
        cancel() {
            this.dialogReference.close("cancelled");
        },
        ok() {
            this.dialogReference.close(this.options);
        }
    }
});
