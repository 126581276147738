import { autoinject } from "aurelia-framework";

import config from "../common/config";
import { EnumMap } from "../common/enum-map";
import { FetchClient } from "../common/fetch-client";
import { ApplicationServicesEnum } from "../enums/enums";
import type { IEnumResponse } from "./../interfaces/i-enum";

@autoinject
export class EnumsService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/enums";
    private _enumsCacheMap: Map<string, IEnumResponse[]> = new Map();
    public enumCacheKey = config.enumCacheKey;
    public enums: ApplicationServicesEnum[] = [
        ApplicationServicesEnum.SourceOfReferral,
        ApplicationServicesEnum.Gender,
        ApplicationServicesEnum.LineOfService,
        ApplicationServicesEnum.WeekDayRange,
        ApplicationServicesEnum.PatientStatus,
        ApplicationServicesEnum.PhoneType
    ];

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    private async getEnum(resource: string): Promise<IEnumResponse[]> {
        // Local First
        let enumFromMap = this.getEnumFromMap(resource);
        if (!!enumFromMap) {
            return enumFromMap;
        }

        // LocalStorage next
        let cachedData = JSON.parse(localStorage.getItem(this.enumCacheKey));
        if (cachedData) {
            this._enumsCacheMap = new Map(cachedData);
            enumFromMap = this.getEnumFromMap(resource);
            if (!!enumFromMap) {
                return enumFromMap;
            }
        }
        // Server Last
        let enumData = await this._fetchClient.build(`${this._baseUrl}/${resource}`).fetch<IEnumResponse[]>();
        this._enumsCacheMap.set(resource, enumData);
        // https://stackoverflow.com/questions/28918232/how-do-i-persist-a-es6-map-in-localstorage-or-elsewhere
        let dataToCache = JSON.stringify(Array.from(this._enumsCacheMap.entries()));
        localStorage.setItem(this.enumCacheKey, dataToCache);
        return enumData;
    }

    private getEnumFromMap(resource: string) {
        if (this._enumsCacheMap.size > 0 && this._enumsCacheMap.has(resource)) {
            return this._enumsCacheMap.get(resource);
        }
        return null;
    }

    public clearCachedEnums() {
        localStorage.removeItem(this.enumCacheKey);
    }

    public async getAllEnums() {
        this.clearCachedEnums();
        // let enums = await this._fetchClient.build(this._baseUrl).fetch<string[]>();
        this.enums.forEach((item: string) => {
            this.getEnum(item);
        });
    }

    public async getPatientStatus() {
        let response = await this.getEnum("PatientStatus");
        return new EnumMap(response);
    }

    public async getPatientStatusNew() {
        return await this.getEnum("PatientStatus");
    }

    public async getWeekDayRange() {
        let response = await this.getEnum("WeekDayRange");
        return new EnumMap(response);
    }

    public async getLineOfService() {
        return await this.getEnum(ApplicationServicesEnum.LineOfService);
    }

    public async getGender() {
        return await this.getEnum(ApplicationServicesEnum.Gender);
    }

    public async getSourceOfReferral() {
        return await this.getEnum(ApplicationServicesEnum.SourceOfReferral);
    }

    public async getReferralReadiness() {
        // return await this.getEnum("ReferralReadiness");
        const enumResponse: IEnumResponse[] = [
            {
                name: "Consents Completed",
                value: "1"
            },
            {
                name: "Hospice Order Received",
                value: "2"
            },
            {
                name: "Face-to-Face Visit Obtained",
                value: "4"
            }
        ];
        return enumResponse;
    }

    public async getAgencyRoles() {
        // return await this.getEnum("AgencyRoles");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getReferralSourceRelationship() {
        // return await this.getEnum("ReferralSourceRelationship");
        const enumResponse: IEnumResponse[] = [
            {
                name: "Spouse",
                value: 1
            },
            {
                name: "Daughter",
                value: 2
            },
            {
                name: "Son",
                value: 3
            },
            {
                name: "Parent",
                value: 4
            },
            {
                name: "Sibling",
                value: 5
            },
            {
                name: "Friend",
                value: 6
            },
            {
                name: "In-Law",
                value: 7
            },
            {
                name: "Other Relative",
                value: 8
            },
            {
                name: "Committed Partner",
                value: 9
            },
            {
                name: "Court Appointed Advocate",
                value: 10
            },
            {
                name: "Other",
                value: 11
            }
        ];
        return enumResponse;
    }

    public async getInternalReferralSource() {
        // return await this.getEnum("InternalReferralSource");
        const enumResponse: IEnumResponse[] = [
            {
                name: "Hospice",
                value: 1
            },
            {
                name: "Palliative Care",
                value: 2
            }
        ];
        return enumResponse;
    }

    public async getPatientPendingReason() {
        // return await this.getEnum("PatientPendingReason");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getVeteranOptions() {
        // return await this.getEnum("veteran");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getEthnicities() {
        // return await this.getEnum("Ethnicities");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }
    public async getPainIntensityLevel() {
        // return await this.getEnum("PainIntensityLevel");
        const enumResponse: IEnumResponse[] = [
            {
                name: "0 - No Pain",
                value: 0
            },
            {
                name: "1 - Very Mild",
                value: 1
            },
            {
                name: "2 - Discomforting",
                value: 2
            },
            {
                name: "3 - Tolerable",
                value: 3
            },
            {
                name: "4 - Distressing",
                value: 4
            },
            {
                name: "5 - Very Distressing",
                value: 5
            },
            {
                name: "6 - Intense",
                value: 6
            },
            {
                name: "7 - Very Intense",
                value: 7
            },
            {
                name: "8 - Severe",
                value: 8
            },
            {
                name: "9 - Very Severe",
                value: 9
            },
            {
                name: "10 - Worst Pain Possible",
                value: 10
            }
        ];
        return enumResponse;
    }

    public async getParentPermission() {
        // return await this.getEnum("ParentPermission");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getPermissionActions() {
        // return await this.getEnum("PermissionActions");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getContactRole() {
        // return await this.getEnum("ContactRole");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }
    public async getCahpsSurveyContactType() {
        // return await this.getEnum("CahpsSurveyContactType");
        const enumResponse: IEnumResponse[] = [
            {
                name: "Receive CAHPS Survey",
                value: 1
            },
            {
                name: "No Publicity",
                value: 2
            }
        ];
        return enumResponse;
    }

    public async getDocumentTypeCategory() {
        // return await this.getEnum("DocumentTypeCategory");
        const enumResponse: IEnumResponse[] = [
            {
                name: "Employee",
                value: 1
            },
            {
                name: "Patient",
                value: 2
            }
        ];
        return enumResponse;
    }

    public async getPayorType() {
        // return await this.getEnum("PayorType");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getPayerSequenceCode() {
        // return await this.getEnum("payerSequenceCode");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getRelationshipToInsured() {
        // return await this.getEnum("RelationshipToInsured");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }

    public async getRoomAndBoardVendorType() {
        // return await this.getEnum("RoomAndBoardVendorType");
        const enumResponse: IEnumResponse[] = [
            {
                name: "dummy 2",
                value: "2"
            }
        ];
        return enumResponse;
    }
}
