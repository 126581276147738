import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { ReferralService } from "../../../../../services/referral-service";
import { PAGE_FAIL_RESPONSE } from "../../../Inquiry/CentralInquiry";

import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import Pagination from "../../../../../resources-vue/vue-elements/Pagination/Pagination.vue";
import HHPreadmissionForm from "./HHPreadmissionForm.vue";
import HHPreadmissionNotesRow from "./HHPreadmissionNotesRow.vue";

export default defineComponent({
    inject: ["dialogRef"],
    components: {
        FormSection,
        Pagination,
        ErrorMessageHandler,
        HHPreadmissionForm,
        HHPreadmissionNotesRow
    },
    data(vm) {
        return {
            dialogReference: this.dialogRef as any,
            application: null as number,
            accountId: null,
            referralId: null,
            referral: null,
            _referralService: null,
            isLoading: false,
            isError: false,
            columns: 4,
            notesList: null,
            page: null,
            showNoteForm: false
        };
    },
    created() {
        this._referralService = Container.instance.get(ReferralService);
    },

    mounted() {
        this.application = this.dialogReference.data.referral.application;
        this.accountId = this.dialogReference.data.referral.accountId;
        this.referralId = this.dialogReference.data.referral.id;
        this.referral = this.dialogReference.data.referral;
        this.notesList = this.$refs.hhNotesList;
        this.resetList();
    },
    computed: {
        loadingCount() {
            return this.page?.items?.length > 0 ? this.page.items.length() : 5;
        },
        hasData() {
            return this.page?.itemCount > 0;
        }
    },
    methods: {
        async pageChangedCallback(pageNumber: number, pageSize: number) {
            try {
                this.isError = false;
                this.isLoading = true;
                let data = await this._referralService.getPreadmissionNotes(
                    {
                        referralId: this.referralId,
                        page: pageNumber,
                        pageLength: pageSize,
                        application: this.referral.application,
                        accountId: this.referral.accountId
                    },
                    this.referralId
                );
                this.page = data;
                this.isLoading = false;
                return {
                    totalPageCount: data.pageCount,
                    success: data.itemCount > 0,
                    totalCount: data.itemCount
                };
            } catch (e) {
                console.error(e);
                this.isError = true;
                this.isLoading = false;
                return PAGE_FAIL_RESPONSE;
            }
        },
        resetList() {
            this.notesList.reset();
        },
        openNoteForm() {
            this.showNoteForm = true;
        },
        closePreadmissionDialog() {
            this.dialogReference.close();
        },
        closeNoteForm() {
            this.showNoteForm = false;
        }
    }
});
