import { ApplicationEnum, ApplicationNameEnum } from "../enums/enums";
import type { IAgency } from "../resources-vue/vue-interfaces/i-agency";

export class Agency implements IAgency {
    public AgencyId: string;
    public Application: ApplicationEnum;
    public Cluster: number;
    public Created: string;
    public Feature: number;
    public LoginId: string;
    public Name: string;
    public Roles: Array<number>;
    public TitleType: string;
    public UserId: string;
    public Url: string;

    public constructor(agency: IAgency) {
        Object.assign(this, agency || {});
    }

    public get applicationName(): string {
        return ApplicationNameEnum[this.Application];
    }
}

// TODO: THis should be a component instead of model
export class GroupedAgencies {
    public show: boolean = true;
    public data: Array<IAgency> = [];
    public applicationName: string;

    public get tooltip(): string {
        return `Click to ${this.show ? "hide" : "view"} all agencies using ${this.applicationName}.`;
    }

    public constructor(options: { applicationName: string }) {
        Object.assign(this, options || {});
    }

    public addData(data: IAgency): void {
        this.data.push(data);
    }
}
