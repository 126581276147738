import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

export const CalendarRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "employee"
    },
    {
        name: "employee",
        route: "employee",
        moduleId: PLATFORM.moduleName("./employee-calendar", "employee-calendar"),
        title: "Employee Central Calendar",
        nav: true,
        settings: {
            menuName: "Employee"
        }
    },
    {
        name: "daily",
        route: "daily",
        nav: true,
        moduleId: PLATFORM.moduleName("./daily-calendar", "daily-calendar"),
        title: "Daily Central Calendar",
        settings: {
            menuName: "Daily"
        }
    },
    {
        name: "patient",
        route: "patient",
        nav: true,
        moduleId: PLATFORM.moduleName("./patient-calendar", "patient-calendar"),
        title: "Patient Central Calendar",
        settings: {
            menuName: "Patient"
        }
    }
];
