import { ApplicationEnum } from "../../enums/enums";
import { EmergencyContact } from "./emergency-contact";
import { IHomeHealthReferralOld, IHomeHealthReferralExtension } from "../vue-interfaces/i-homehealth-referral";

export class HomeHealthReferral {
    public Referral: IHomeHealthReferralOld = {
        Id: "",
        AgencyId: "",
        BranchId: "",
        ReferrerPhysician: "",
        AdmissionSource: 0,
        ReferralDate: "",
        InternalReferral: "",
        CommunityLiaison: "",
        ClinicalManagerAssigned: "",
        FacilitySource: "",
        OtherReferralSource: "",
        FirstName: "",
        LastName: "",
        MiddleInitial: "",
        MaritalStatus: "",
        Height: null,
        HeightMetric: null,
        Weight: null,
        WeightMetric: null,
        MedicareNumber: "",
        MedicaidNumber: "",
        MbiNumber: "",
        SSN: "",
        IsMedicareNA: false,
        IsMedicaidNA: false,
        IsMbiNA: true,
        DOB: "",
        Gender: "",
        PhoneHome: "",
        PhoneMobile: "",
        EmailAddress: "",
        Physicians: "",
        AddressLine1: "",
        AddressLine2: "",
        AddressCity: "",
        AddressStateCode: "",
        AddressZipCode: "",
        AddressZipCodeFour: "",
        AddressCounty: "",
        ServicesRequired: "",
        ServicesRequiredCollection: [],
        PrimaryInsurance: null,
        SecondaryInsurance: null,
        TertiaryInsurance: null,
        DME: "",
        OtherDME: "",
        UserId: "",
        CreatedById: "",
        Status: null,
        VerificationDate: "",
        Created: "",
        Modified: "",
        Comments: "",
        NonAdmissionDate: "",
        NonAdmissionReason: "",
        IsDNR: null,
        PrimaryHealthPlanId: "",
        SecondaryHealthPlanId: "",
        TertiaryHealthPlanId: "",
        PrimaryGroupName: "",
        SecondaryGroupName: "",
        TertiaryGroupName: "",
        PrimaryGroupId: "",
        SecondaryGroupId: "",
        TertiaryGroupId: "",
        PrimaryRelationship: "2",
        SecondaryRelationship: "2",
        TertiaryRelationship: "2",
        ReferralSourceName: "",
        ServiceLocation: "",
        ForceHicNumber: true,
        HicNumber: "",
        NoContact: null,
        NoContactReasons: "",
        PreferredLanguage: "",
        AdditionalLanguage1: "",
        AdditionalLanguage2: "",
        AdditionalLanguage3: "",
        NoContactReasonOther: "",
        AdditionalBillingInfo: {
            PatientId: "",
            ReferralId: "",
            AgencyId: "",
            PrimaryInsuredDetails: {
                InsurnaceDisplayName: "",
                FirstName: "",
                MiddleInitial: "",
                LastName: "",
                InsuredDateOfBirth: "",
                EmployerName: "",
                InsuredGender: null,
                Address: {
                    AddressLine1: "",
                    AddressLine2: "",
                    AddressCity: "",
                    AddressStateCode: "",
                    AddressZipCode: "",
                    AddressZipFive: "",
                    AddressZipFour: ""
                },
                NameOnly: false,
                DeductibleAmount: null,
                DeductibleMet: null,
                CopayAmount: null
            },
            SecondaryInsuredDetails: {
                InsurnaceDisplayName: "",
                FirstName: "",
                MiddleInitial: "",
                LastName: "",
                InsuredDateOfBirth: "",
                EmployerName: "",
                InsuredGender: null,
                Address: {
                    AddressLine1: "",
                    AddressLine2: "",
                    AddressCity: "",
                    AddressStateCode: "",
                    AddressZipCode: "",
                    AddressZipFive: "",
                    AddressZipFour: ""
                },
                NameOnly: false,
                DeductibleAmount: null,
                DeductibleMet: null,
                CopayAmount: null
            },
            TertiaryInsuredDetails: {
                InsurnaceDisplayName: "",
                FirstName: "",
                MiddleInitial: "",
                LastName: "",
                InsuredDateOfBirth: "",
                EmployerName: "",
                InsuredGender: null,
                Address: {
                    AddressLine1: "",
                    AddressLine2: "",
                    AddressCity: "",
                    AddressStateCode: "",
                    AddressZipCode: "",
                    AddressZipFive: "",
                    AddressZipFour: ""
                },
                NameOnly: false,
                DeductibleAmount: null,
                DeductibleMet: null,
                CopayAmount: null
            },
            PatientConditionCodes: {
                Code18: "",
                Code19: "",
                Code20: "",
                Code21: "",
                Code22: "",
                Code23: "",
                Code24: "",
                Code25: "",
                Code26: "",
                Code27: "",
                Code28: ""
            },
            PayerComment: "",
            AdmissionType: 0,
            HCFAAdditionalBillingInfo: {
                IsEmergencyIndicatorRequired: true,
                Employment: true,
                AutoAccident: true,
                AutoAccidentState: "",
                AutoAccidentDate: "",
                OtherAccident: true,
                OtherAccidentDate: "",
                ClaimCodes: "",
                UnableToWorkFrom: "",
                UnableToWorkTo: "",
                HospitalizationBegin: "",
                HospitalizationEnd: ""
            },
            UB04AddtionalBillingInfo: {
                Locator31a: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator31b: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator32a: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator32b: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator33a: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator33b: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator34a: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                Locator34b: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    Date: ""
                },
                EmployerName: "",
                Locator35a: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    DateFrom: "",
                    DateThrough: ""
                },
                Locator35b: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    DateFrom: "",
                    DateThrough: ""
                },
                Locator36a: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    DateFrom: "",
                    DateThrough: ""
                },
                Locator36b: {
                    LocatorNumber: 0,
                    SubId: 0,
                    Code: "",
                    DateFrom: "",
                    DateThrough: ""
                }
            }
        },
        TagCollection: [],
        Assets: [],
        EmergencyContact: new EmergencyContact()
    };
    public ReferralExtension: IHomeHealthReferralExtension = {
        Id: "",
        ReferralId: "",
        AgencyId: "",
        IsPrimaryCarePhysician: null,
        IsPhysicianContacted: null,
        IsSigningPlanOfCare: false,
        CertifyingPhysician: "",
        FaceToFaceToBeCompletedWithin30Days: null,
        FaceToFaceInformationNA: null,
        DateOfFaceToFaceVisit: "",
        VisitAddressLine1: "",
        VisitAddressLine2: "",
        VisitCity: "",
        VisitStateCode: "",
        VisitZipCode: "",
        VisitZipCodeFour: "",
        VisitCounty: "",
        SameAsPhysicalAddress: null,
        MailingAddressLine1: "",
        MailingAddressLine2: "",
        MailingCity: "",
        MailingStateCode: "",
        MailingZipCode: "",
        MailingZipCodeFour: "",
        MailingCounty: "",
        PrimaryCarePhysician: "",
        IsReferringPhysician: null,
        PrimaryMedicarePartADate: "",
        PrimaryMedicarePartBDate: "",
        SecondaryMedicarePartADate: "",
        SecondaryMedicarePartBDate: "",
        TertiaryMedicarePartADate: "",
        TertiaryMedicarePartBDate: "",
        PrimaryExistingPriorEpisode: null,
        SecondaryExistingPriorEpisode: null,
        TertiaryExistingPriorEpisode: null,
        AlternateMedicaidNumber: "",
        MedicalRecordNumber: "",
        AdditionalEmergencyContact: [],
        CertifyingPhysicianName: ""
    };
    public CountyOptions: { [key: string]: string[] } = {};
    public accountId: string;
    public agencyId: string;
    public application: ApplicationEnum;
    public InquiryId: string;

    public constructor(data?: HomeHealthReferral) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
