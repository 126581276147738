import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { LookupService } from "../../../../../services/lookup-service";
import { IGetCityStateByZipcodeResponse } from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";

export default defineComponent({
    props: {
        referral: {
            type: HomeHealthReferral,
            default: null
        },
        address: {
            type: Object,
            default: null
        },
        accountId: {
            type: String,
            default: null
        },
        applicationId: {
            type: Number
        },
        addressType: {
            type: String,
            default: ""
        },
        showCode: {
            type: Boolean,
            default: false
        },
        countyData: {
            type: Array,
            default: [] as string[]
        },
        fieldsRequired: {
            type: Boolean,
            default: false
        },
        fieldsChanged: {
            type: Function,
            default: null
        },
        referralOptions: {
            type: Object,
            default: null
        },
        isPrimaryAddress: {
            type: Boolean,
            default: false
        },
        showCountyFrzip: {
            type: Boolean,
            default: true
        },
        className: {
            type: String,
            default: ""
        },
        isRefer: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            index: null,
            _lookupService: null
        };
    },

    async created() {
        this._lookupService = Container.instance.get(LookupService);
        if (this.isRefer && this.address.AddressZipCode) {
            await this.getCounty();
            this.fieldsChanged(this.addressType, ["AddressCounty"], this.index);
        }
    },

    watch: {
        countyData: {
            handler(newValue: []) {
                if (newValue) {
                    this.address.AddressCounty = (newValue as any[])[0] as string[];
                }
            },
            deep: true
        }
    },

    methods: {
        async addressChanged(fieldName: string = null) {
            if (!fieldName) return;
            else {
                let fields: string[] = [fieldName];
                if (fieldName == "AddressZipCode") {
                    if (this.index == null) {
                        await this.getCounty();
                    }
                    await this.getCityState();
                    fields = [fieldName, "AddressStateCode", "AddressCity", "AddressCounty"];
                }
                if (fields.length && this.fieldsChanged) {
                    this.fieldsChanged(this.addressType, fields, this.index);
                }
            }
        },

        async getCityState() {
            if (this.address && this.address.AddressZipCode) {
                const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                    zipCode: this.address.AddressZipCode,
                    agencyId: this.accountId,
                    application: this.applicationId || this.referral.application
                });
                this.address.AddressCity = result?.city || null;
                const UpdatedState = result?.state
                    ? this.referralOptions.addressStateCode.find((option: any) => option.value == result.state)
                    : null;
                if (UpdatedState) {
                    this.address.AddressStateCode = UpdatedState.value;
                } else {
                    this.address.AddressStateCode = null;
                }
            }
        },

        async getCounty() {
            const county: string[] = await this._lookupService.getCountyByZipcode(
                this.address.AddressZipCode,
                this.accountId
            );
            if (this.referral) {
                this.referral.CountyOptions = { [this.address.AddressZipCode]: county };
            }
            const countyinfo = {
                addressType: this.addressType,
                county
            };
            this.$emit("updatecountyData", countyinfo);
        }
    }
});
