import { defineComponent } from "vue";

import { Container } from "aurelia-dependency-injection";

import { TaskService } from "../../../services/task.service";
import { ToastrService } from "../../../services/toastr.service";
import { formatDateInput } from "../../../common/vue-helpers/modifiers/value-modifier";

import FormSection from "../../vue-custom-elements/FormSection/FormSection.vue";
import MultiSelectDropdown from "../../vue-custom-elements/MultiSelectDropdown/MultiSelectDropdown.vue";

export default defineComponent({
    inject: ["dialogRef"],
    components: {
        FormSection,
        MultiSelectDropdown
    },
    data() {
        return {
            dialogReference: this.dialogRef as any,
            options: {},
            _taskService: null,
            _toastrService: null,
            show: false,
            matchDetails: null,
            payload: null,
            isLoading: false,
            requiredAllSelected: false,
            preferredAllSelected: false,
            requiredMatches: [],
            preferredMatches: [],
            availability: 1,
            isOriented: false,
            tagsOptions: 0,
            requiredCriteria: {
                "License/Certifications": [],
                Skills: [],
                Transfers: [],
                "Personality Traits": [],
                Pets: [],
                General: []
            },
            desiredCriteria: {
                "License/Certifications": [],
                Skills: [],
                Transfers: [],
                "Personality Traits": [],
                Pets: [],
                General: []
            },
            caregiver: [
                { name: "License/Certifications", key: 1 },
                { name: "Skills", key: 2 },
                { name: "Transfers", key: 3 },
                { name: "Personality Traits", key: 4 },
                { name: "Pets", key: 5 },
                { name: "General", key: 6 }
            ],
            matchingResults: [],
            findingMatches: false,
            columns: 7,
            anyUserTags: [] as string[],
            allUserTags: [] as string[],
            userIds: [] as string[],
            temp: [],
            formatDateInput
        };
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._taskService = Container.instance.get(TaskService);
        this.payload = Object.assign({}, this.dialogReference.data.payload);
    },

    async mounted() {
        this.isLoading = true;
        try {
            this.matchDetails = await this._taskService.getScheduleMatch(this.payload);
            this.matchDetails = this.matchDetails.data;
            this.matchDetails.employeeTags = this.matchDetails.employeeTags.map((data: string) => {
                return { value: data, name: data };
            });
            this.getRequiredCriteria();
            this.getDesiredCriteria();
        } catch (error) {
            this._toastrService.error({
                title: `Error - Getting Schedule Match Details`,
                message: `Please try again.`
            });
            this.dialogReference.close();
        } finally {
            this.isLoading = false;
        }
    },

    methods: {
        cancel() {
            this.dialogReference.close();
        },

        ok() {
            this.dialogReference.close("success");
        },

        addAnother() {
            this.dialogReference.close("reopen");
        },

        handleLoadingChange(value: boolean) {
            this.isLoading = value;
        },

        closeFindMatch() {
            this.dialogReference.close();
        },

        requiredAllSelectedChanged() {
            if (this.requiredAllSelected) {
                this.requiredMatches = this.caregiver.map((data: any) => data.key);
            } else {
                this.requiredMatches = [];
            }
        },

        preferredAllSelectedChanged() {
            if (this.preferredAllSelected) {
                this.preferredMatches = this.caregiver.map((data: any) => data.key);
            } else {
                this.preferredMatches = [];
            }
        },

        checkRequiredMatches(option: any) {
            return this.requiredMatches.indexOf(option) > -1 ? true : false;
        },

        requiredSelectionChanged(value: any) {
            const index = this.requiredMatches.indexOf(value);
            if (index === -1) {
                this.requiredMatches.push(value);
            } else {
                this.requiredMatches.splice(index, 1);
            }
            if (this.requiredMatches.length !== this.caregiver.length) {
                this.requiredAllSelected = false;
            } else {
                this.requiredAllSelected = true;
            }
        },

        checkpreferredMatches(option: any) {
            return this.preferredMatches.indexOf(option) > -1 ? true : false;
        },

        preferredSelectionChanged(value: any) {
            const index = this.preferredMatches.indexOf(value);
            if (index === -1) {
                this.preferredMatches.push(value);
            } else {
                this.preferredMatches.splice(index, 1);
            }
            if (this.preferredMatches.length !== this.caregiver.length) {
                this.preferredAllSelected = false;
            } else {
                this.preferredAllSelected = true;
            }
        },

        anyUserTagsUpdated(value: any) {
            if (typeof value === "string") {
                if (this.anyUserTags.includes(value)) {
                    this.anyUserTags = this.anyUserTags.filter((tag) => tag !== value);
                    return;
                }
                this.anyUserTags.push(value);
            } else {
                this.anyUserTags = value;
            }
        },

        allUserTagsUpdated(value: any) {
            if (typeof value === "string") {
                if (this.allUserTags.includes(value)) {
                    this.allUserTags = this.allUserTags.filter((tag) => tag !== value);
                    return;
                }
                this.allUserTags.push(value);
            } else {
                this.allUserTags = value;
            }
        },

        getRequiredCriteria() {
            this.requiredCriteria["License/Certifications"] = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 1 && criteria.value !== 0 && criteria.value !== 1
            );
            this.requiredCriteria.Skills = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 2 && criteria.value !== 0 && criteria.value !== 1
            );
            this.requiredCriteria.Transfers = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 3 && criteria.value !== 0 && criteria.value !== 1
            );
            this.requiredCriteria["Personality Traits"] = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 4 && criteria.value !== 0 && criteria.value !== 1
            );
            this.requiredCriteria.Pets = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 5 && criteria.value !== 0 && criteria.value !== 1
            );
            this.requiredCriteria.General = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 6 && criteria.value !== 0 && criteria.value !== 1
            );
        },

        getDesiredCriteria() {
            this.desiredCriteria["License/Certifications"] = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 1 && criteria.value === 1
            );
            this.desiredCriteria.Skills = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 2 && criteria.value === 1
            );
            this.desiredCriteria.Transfers = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 3 && criteria.value === 1
            );
            this.desiredCriteria["Personality Traits"] = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 4 && criteria.value === 1
            );
            this.desiredCriteria.Pets = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 5 && criteria.value === 1
            );
            this.desiredCriteria.General = this.matchDetails.patientMatchCriteria.filter(
                (criteria: any) => criteria.groupId === 6 && criteria.value === 1
            );
        },

        async sendMatchMessage() {
            const payload = {
                application: this.payload.application,
                agencyId: this.payload.agencyId,
                patientId: this.payload.patientId,
                clientName: this.payload.clientName,
                userIds: this.userIds,
                startDate: this.payload.startDate,
                endDate: this.payload.endDate,
                isAllDay: this.payload.isAllDay
            };
            try {
                let response: any = await this._taskService.sendMatchMessage(payload);
                if (response.data.IsSuccessful) {
                    this._toastrService.success({
                        title: `Your message has been sent.`,
                        message: ""
                    });
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Sending Match message`,
                    message: error.message
                });
            }
        },

        async findAMatch() {
            this.findingMatches = true;
            let payload = {
                application: this.payload.application,
                agencyId: this.payload.agencyId,
                patientId: this.payload.patientId,
                clientName: this.payload.clientName,
                startDate: this.payload.startDate,
                endDate: this.payload.endDate,
                locationId: this.payload.locationId,
                requiredCriteria: this.requiredMatches,
                userTags: this.tagsOptions === 0 ? [] : this.tagsOptions === 1 ? this.anyUserTags : this.allUserTags,
                preferredCriteria: this.preferredMatches,
                isOriented: this.isOriented,
                availability: this.availability,
                tagOptions: this.tagsOptions
            };
            try {
                const response: any = await this._taskService.findAMatch(payload);
                this.matchingResults = response.data;
            } catch (error) {
                this._toastrService.error({
                    title: `Failed to find Matches`,
                    message: "Please try again"
                });
                this.findingMatches = false;
            } finally {
                this.findingMatches = false;
            }
        },
        checkUserIds(option: any) {
            return this.userIds.indexOf(option) > -1 ? true : false;
        },

        userIdsChanged(value: any) {
            const index = this.userIds.indexOf(value);
            if (index === -1) {
                this.userIds.push(value);
            } else {
                this.userIds.splice(index, 1);
            }
        },

        handleCaregiverSelection() {
            if (this.userIds.length > 1) {
                this._toastrService.error({
                    title: `Failed to select caregiver`,
                    message: "Only one caregiver can be selected from the grid above."
                });
            } else {
                let user = this.matchingResults.find((data: any) => data.userId === this.userIds[0]);
                this.dialogReference.close(user);
            }
        },

        tagOptionsChanged(option: number) {
            if (option === 0) {
                this.anyUserTags = [];
                this.allUserTags = [];
            } else if (option === 1) {
                this.allUserTags = [];
            } else {
                this.anyUserTags = [];
            }
        }
    }
});
