import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, maxLength, minLength } from "@vuelidate/validators";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import Medicaid from "./Medicaid/Medicaid.vue";
import Medicare from "./Medicare/Medicare.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection,
        Medicaid,
        Medicare
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId",
            errors: "errors"
        })
    },
    validations() {
        return {
            referral: {
                Insurance: {
                    MbiNumber: {
                        required: requiredIf(() => !this.referral.Insurance.IsMbiNA),
                        maxLength: maxLength(11),
                        minLength: minLength(11)
                    },
                    MedicaidNumber: {
                        required: requiredIf(() => !this.referral.Insurance.IsMedicaidNA)
                    },
                    PrimaryInsurance: { required }
                }
            }
        };
    },
    data() {
        return {
            medicareInsurance: ["1", "2", "3", "4", "5"],
            isPrimaryMedicare: null,
            isPrimaryMedicaid: null,
            isSecondaryMedicare: null,
            isSecondaryMedicaid: null,
            isTertiaryMedicare: null,
            isTertiaryMedicaid: null,
            v$: useVuelidate()
        };
    },
    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        setMedicareOrMedicaid(value: any, type: string) {
            if (type == "primary") {
                if (this.isMedicare(value)) {
                    this.isPrimaryMedicare = true;
                    this.isPrimaryMedicaid = false;
                } else {
                    this.isPrimaryMedicare = false;
                    this.isPrimaryMedicaid = true;
                }
                if (this.isMedicare(value) == null) {
                    this.isPrimaryMedicare = false;
                    this.isPrimaryMedicaid = false;
                }
            }
            if (type == "secondary") {
                if (this.isMedicare(value)) {
                    this.isSecondaryMedicare = true;
                    this.isSecondaryMedicaid = false;
                } else {
                    this.isSecondaryMedicare = false;
                    this.isSecondaryMedicaid = true;
                }
                if (this.isMedicare(value) == null) {
                    this.isSecondaryMedicare = false;
                    this.isSecondaryMedicaid = false;
                }
            }
            if (type == "tertiary") {
                if (this.isMedicare(value)) {
                    this.isTertiaryMedicare = true;
                    this.isTertiaryMedicaid = false;
                } else {
                    this.isTertiaryMedicare = false;
                    this.isTertiaryMedicaid = true;
                }
                if (this.isMedicare(value) == null) {
                    this.isTertiaryMedicare = false;
                    this.isTertiaryMedicaid = false;
                }
            }
            this.handleAllInput("PrimaryInsurance", value);
        },
        isMedicare(value: any) {
            const insurance = this.medicareInsurance;
            if (value == null) {
                return null;
            }
            if (insurance.includes(value)) {
                return true;
            } else {
                return false;
            }
        },
        handleAllInput(field: string, value: any) {
            if (value.length || value !== null) {
                const newErrors = [...new Set(this.errors.Insurance)].filter((val: any) => val !== field);
                const payload = {
                    property: "Insurance",
                    value: newErrors
                };
                this.setErrors(payload);
            }
        }
    }
});
