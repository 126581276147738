import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { Container } from "aurelia-dependency-injection";

import { formatDateInput } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { IReassignVisitDetails } from "../../../../interfaces/i-reassign-visit";
import { TaskService } from "../../../../services/task.service";
import { ToastrService } from "../../../../services/toastr.service";
import { PayerTypes, TaskRepeat, ApplicationEnum, PayerTypesEnum, VisitTypeEnum } from "../../../../enums/enums";
import { PatientTask } from "../../../vue-models/patient-task-hos-pal";
import {
    IAvailableTaskItems,
    IPayersByDateResponseData
} from "../../../../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import { PatientService } from "../../../../services/patient.service";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IEnumResponse } from "../../../../interfaces/i-enum";
import moment from "moment";
import { enumToMap } from "../../../../common/vue-helpers/modifiers/enum-modifiers";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../vue-custom-elements/DatePicker/DatePicker.vue";
import TypeaheadInput from "../../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import TimePicker from "../../../vue-custom-elements/TimePicker/TimePicker.vue";
import DateRangePicker from "../../../vue-custom-elements/DateRangePicker/DateRangePicker.vue";
import MultiDatePicker from "../../../vue-custom-elements/MultiDatePicker/MultiDatePicker.vue";
import FormatName from "../../../../resources-vue/vue-custom-elements/FormatName/FormatName.vue";
import FrequencyCheck from "../../../vue-elements/FrequencyCheck/FrequencyCheck.vue";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        TypeaheadInput,
        FormatName,
        TimePicker,
        DateRangePicker,
        FrequencyCheck,
        MultiDatePicker
    },

    validations() {
        return {
            newTaskSingleDate: this.newTaskRepeat == 0 ? { required } : { required: false },
            newTaskDateRange:
                this.newTaskRepeat == 1 || this.newTaskRepeat == 2 || this.newTaskRepeat == 4
                    ? { required }
                    : { required: false },
            newTaskFlexDates: this.newTaskRepeat == 3 ? { required } : { required: false },
            schedulePatientId: { required },
            taskList: { required },
            newTask: {
                patientPayerId: { required }
            }
        };
    },

    props: {
        visitDetails: { type: Object, default: {} as IReassignVisitDetails },
        isLoading: { type: Boolean, default: false },
        cancel: { type: Function, default: null },
        ok: { type: Function, default: null },
        addAnother: { type: Function, default: null }
    },
    data() {
        return {
            showAllByDefault: true,
            _taskService: null,
            _toastrService: null,
            _patientService: null,
            newTask: new PatientTask(),
            tasks: TaskRepeat,
            isDateSelected: false,
            isPatientSelected: false,
            isPalliativeSolution: null,
            availableTask: [] as IAvailableTaskItems[],
            patientPayers: [] as IPayersByDateResponseData[],
            payerTypes: [] as IEnumResponse[],
            patientList: [] as any,
            payerTypeTest: Array.from(PayerTypes.enumWithDescriptions.values()),
            recurrencesEnum: Array.from(TaskRepeat.enumWithDescriptions.values()),
            isOutsideFrequency: false,
            patient: null,
            shiftLength: 0,
            taskListItems: null,
            isFrequencyWarningRequested: null,
            patientName: "",
            saveType: "",
            visitTypeEnum: VisitTypeEnum,
            visitType: null,
            newTaskRepeat: 0,
            newTaskSingleDate: "",
            newTaskDateRange: "",
            newTaskFlexDates: "",
            visitDates: [],
            taskList: null,
            schedulePatientId: {},
            shiftStartTime: "",
            shiftStartTimePal: "",
            shiftEndTime: "",
            newTaskRangePickerOptions: {
                dateLimit: {
                    days: 90
                }
            },
            isScheduling: false,
            v$: useVuelidate(),
            formatDateInput,
            enumToMap
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._patientService = Container.instance.get(PatientService);
        this._toastrService = Container.instance.get(ToastrService);
        this.patient = [];
        this.taskList = {};
        this.taskListItems = {};
    },

    mounted() {
        if (this.visitDetails.Application == ApplicationEnum.AxxessHospice) {
            this.visitDetails.Application = ApplicationEnum.AxxessHospiceFE;
        }
        if (this.visitDetails.Application == ApplicationEnum.AxxessPalliative) {
            this.isPalliativeSolution = true;
        }
    },

    computed: {
        newTaskStartDates() {
            this.visitDates = this.newTask?.startDates?.filter((startDate) =>
                moment(startDate, "MM/DD/YYYY", true).isValid()
            );
            return this.visitDates;
        }
    },

    watch: {
        newTaskRepeat(newValue: number, oldValue: number) {
            this.newTaskSingleDate = "";
            this.newTaskDateRange = "";
            this.newTaskFlexDates = "";
            this.newTask.startDates = [];
            this.newTask.endDates = [];
        },
        newTaskSingleDate(newValue: string) {
            this.newTask.startDates = [];
            if (!!newValue) {
                this.newTask.startDates.push(newValue);
            }
            if (this.taskDateChanged) {
                this.taskDateChanged();
            }
        },
        newTaskDateRange(newValue: string) {
            this.newTask.startDates = [];
            const newTaskDates = newValue.split(" - ");
            const startDate = moment(newTaskDates[0]);
            const endDate = moment(newTaskDates[1]);
            let dateToAdd = startDate;
            while (dateToAdd <= endDate) {
                this.newTask.startDates.push(dateToAdd.format("MM/DD/YYYY"));
                if (this.newTaskRepeat == 4) {
                    dateToAdd = dateToAdd.add(1, "month");
                } else {
                    dateToAdd = dateToAdd.add(this.newTaskRepeat, "week");
                }
            }
            if (this.taskDateChanged) {
                this.taskDateChanged();
            }
        },
        newTaskFlexDates(newValue: string) {
            this.newTask.startDates = [];
            if (!!newValue) {
                this.newTask.startDates = newValue.split(", ");
            }
            if (this.taskDateChanged) {
                this.taskDateChanged();
            }
        },
        taskList(newValue: IAvailableTaskItems) {
            if (newValue) {
                this.newTask.taskId = newValue.id;
                this.taskListItems = newValue;
                this.updateAvailablePayors();
            }
        },
        schedulePatientId(newValue: ITypeaheadOptions) {
            if (newValue) {
                this.newTask.patientId = newValue.value;
                this.patientName = newValue?.name;
                const response = this.availableTasks();
                response
                    .then(() => {
                        if (!this.isPalliativeSolution) {
                            this.getCurrentBenefitPeriod();
                        }
                        this.updateAvailablePayors();
                        this.isPatientSelected = true;
                    })
                    .catch(() => {
                        this.isPatientSelected = false;
                    });
            }
        },
        shiftStartTime() {
            if (
                this.shiftLength > 0 &&
                this.newTask.startDates &&
                this.newTask.startDates.length > 0 &&
                !this.isPalliativeSolution
            ) {
                this.newTask.startDates = this.newTask.startDates?.map((item) => {
                    const startDate = new Date(`${item} ${this.shiftStartTime}`).toISOString();
                    return moment(startDate).format("MM/DD/YYYY HH:mm");
                });
                this.newTask.endDates = [];
                this.shiftEndTime = moment(this.shiftStartTime, "hh:mm A")
                    .add(this.shiftLength, "hours")
                    .format("hh:mm A");
                this.newTask.startDates.forEach((item) => {
                    const endDate = moment(item).add(this.shiftLength, "hours").format("MM/DD/YYYY HH:mm");
                    this.newTask.endDates.push(endDate);
                });
            }
        },
        shiftStartTimePal() {
            if (!this.shiftStartTimePal) {
                this.newTask.startDates = this.newTask.startDates.map((startDate) =>
                    moment(startDate).format("MM/DD/YYYY")
                );
                this.shiftEndTime = "";
                this.newTask.endDates = [];
                return;
            }
            let startDates = this.newTask.startDates.map((startDate) => moment(startDate).format("MM/DD/YYYY"));
            if (startDates && startDates.length > 0) {
                this.newTask.startDates = startDates.map((item) => {
                    let startDate = new Date(`${item} ${this.shiftStartTimePal}`).toISOString();
                    return moment(startDate).format("MM/DD/YYYY HH:mm");
                });
            }
        },
        shiftEndTime() {
            if (!this.shiftEndTime) {
                this.newTask.endDates = this.newTask.endDates.map((endDate) => moment(endDate).format("MM/DD/YYYY"));
                return;
            }
            let scheduledDates = this.newTask.startDates.map((startDate) => moment(startDate).format("MM/DD/YYYY"));
            if (scheduledDates && scheduledDates.length > 0 && this.isPalliativeSolution) {
                this.newTask.endDates = [];
                this.newTask.endDates = scheduledDates.map((item) => {
                    let startDate = new Date(`${item} ${this.shiftEndTime}`).toISOString();
                    return moment(startDate).format("MM/DD/YYYY HH:mm");
                });
            }
        }
    },

    methods: {
        updateVisitDates(dates: any) {
            this.visitDates = dates;
        },

        updateOutsideFrequency(frequency: any) {
            this.isOutsideFrequency = frequency;
        },

        taskDateChanged() {
            if (this.newTask.startDates?.length > 0) {
                this.isDateSelected = true;
                if (this.patientName) {
                    this.updateAvailablePayors();
                }
            } else {
                this.isDateSelected = false;
                this.resetShiftOnCall();
            }
        },
        resetShiftOnCall() {
            this.newTask.onCall = false;
            this.shiftStartTime = "";
            this.shiftLength = 0;
        },
        async availableTasks() {
            if (this.newTask.startDates.length) {
                const data = await this._taskService.getAvailableTasks({
                    patientId: this.newTask.patientId,
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application,
                    startDates: this.newTask.startDates
                });
                return (this.availableTask = data.Data);
            } else {
                return false;
            }
        },
        availableTasksFilter(filter: string) {
            if (filter) {
                const filterAvailableTasks = this.availableTask.filter((task) => {
                    return task?.name.toLowerCase().includes(filter.toLowerCase());
                });
                return filterAvailableTasks;
            } else {
                return this.availableTask;
            }
        },
        async patientListFilter(filter: string) {
            if (filter) {
                const patient = await this._patientService.findPatientsForCalendar({
                    pageLength: 15,
                    page: 1,
                    term: filter,
                    patientStatus: 1,
                    agencies: [`${this.visitDetails.AgencyId}_${this.visitDetails.Application}`]
                });
                const filterPatientList = patient.Items.filter((item: any) => {
                    const fullName = `${item.FirstName} ${item.LastName}`;
                    return fullName.toLowerCase().includes(filter.toLowerCase());
                });
                return filterPatientList?.map((item: any) => {
                    return {
                        name: `${item.FirstName} ${item.LastName}`,
                        value: item.Id
                    };
                });
            } else {
                return this.patientList?.map((item: any) => ({
                    value: item.Id,
                    name: `${item.FirstName} ${item.LastName}`
                }));
            }
        },
        schedulePatientIdChanged(newValue: object) {
            this.schedulePatientId = newValue;
        },
        taskListChanged(newValue: object) {
            this.taskList = newValue;
        },
        changeStartTime(time: any) {
            if (this.isPalliativeSolution) {
                this.shiftStartTimePal = time;
            } else {
                this.shiftStartTime = time;
            }
        },
        changeEndTime(time: any) {
            this.shiftEndTime = time;
        },
        setnewTaskDateRange(value: any) {
            this.newTaskDateRange = value;
        },
        async updateAvailablePayors() {
            if (!this.newTask.startDates.length) {
                this.patientPayers = [];
                return;
            }
            const startDates = this.newTask.startDates.map((date) => moment(date));
            const startDate = moment.min(startDates).format("MM/DD/YYYY");
            const endDate = moment.max(startDates).format("MM/DD/YYYY");
            const response = await this._taskService.getPayersByDate({
                patientId: this.newTask.patientId,
                accountId: this.visitDetails.AgencyId,
                startDate: startDate,
                endDate: endDate,
                application: this.visitDetails.Application,
                includeCharityPayer: true
            });
            this.patientPayers = response.data;
            this.payerTypes = this.payerTypeTest.filter((type) =>
                this.patientPayers.some((patientPayor) => patientPayor.payerType === type.value)
            );
            const primaryPayer = this.patientPayers.find((payer) => payer.payerType === PayerTypesEnum.Primary);
            this.newTask.patientPayerId = primaryPayer?.id ?? null;
        },
        async getCurrentBenefitPeriod() {
            const response = await this._patientService.getCurrentBenefitPeriod({
                patientId: this.newTask.patientId,
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application
            });
            this.patient = response.data;
        },
        async showFrequencyWarning(saveTask: boolean = true) {
            const res = this.v$.$validate();
            if (!res) {
                return;
            }
            this.isFrequencyWarningRequested = true;
            if (saveTask == true) {
                this.saveType = SaveEnum.SaveAndAddAnother;
            } else {
                this.saveType = SaveEnum.SaveAndExit;
            }
        },
        closeFrequencyWarning() {
            this.isOutsideFrequency = false;
            this.isFrequencyWarningRequested = false;
        },
        async saveAndAddAnothers(saveType: string) {
            await this.createTask(saveType);
        },
        async createTask(saveType: string) {
            const res = this.v$.$validate();
            if (!res) {
                return;
            }
            if (!!this.newTask) {
                try {
                    this.isScheduling = true;
                    this.newTask.metaId = "";
                    this.newTask.comments = "";
                    this.newTask.agencyId = this.visitDetails.AgencyId;
                    this.newTask.application = this.visitDetails.Application;
                    this.newTask.userId = this.visitDetails.UserId;
                    await this._taskService.createTask(this.newTask);
                    this._toastrService.success({
                        title: `New Task Created`,
                        message: `The Task has been successfully created.`
                    });
                    this.closeFrequencyWarning();
                    if (saveType == SaveEnum.SaveAndExit) {
                        this.ok();
                    } else {
                        this.addAnother();
                    }
                } catch (e) {
                    this._toastrService.error({
                        title: `Creating Task Failed`,
                        message: `Creating Task Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.isScheduling = false;
                }
            }
        },
        visitTypeRadioClick(value: VisitTypeEnum) {
            this.visitType = this.visitType === value ? null : value;
            if (!this.visitType) {
                // If none is selected
                this.newTask.onCall = false;
                this.newTask.prn = false;
            } else if (this.visitType === VisitTypeEnum.OnCall) {
                // If on call visit type
                this.newTask.onCall = true;
                this.newTask.prn = false;
            } else if (this.visitType === VisitTypeEnum.Prn) {
                // If prn visit type
                this.newTask.onCall = false;
                this.newTask.prn = true;
            }
            return true;
        },
        onDateChange(date: any, name: string) {
            if (name === "new-task-single-date") this.newTaskSingleDate = date;
        },
        updatenewTaskFlexDates(result: any) {
            this.newTaskFlexDates = result;
        }
    }
});
