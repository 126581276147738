import { defineStore } from "pinia";
import { IHHReferralState } from "./i-state";
import {
    SET_HH_REFERRAL,
    SET_REFERRAL_OPTIONS,
    SET_CURRENT_APPLICATION,
    SET_ACCOUNT_ID,
    SET_ERRORS,
    SET_ADDITIONAL_CONTACT_ERROR,
    REMOVE_ERRORS,
    RESET_ERRORS,
    SET_UPDATED,
    SET_COUNTY_DATA,
    SET_MAILING_COUNTY,
    SET_VISIT_COUNTY,
    SET_PRIMARY_EMERGENCY,
    SET_CHAPS_COUNTY,
    SET_ADDITION_COUNTY_1,
    SET_ADDITION_COUNTY_2,
    SET_ADDITION_COUNTY_3,
    SET_NEW_ERRORS
} from "./types";
import { IHomeHealthReferralNew } from "../../../resources-vue/vue-interfaces/i-homehealth-referral";
import { ApplicationEnum } from "../../../enums/enums";

export const useEditReferralHH = defineStore("edit-referral-HH", {
    state: () =>
        ({
            referral: null,
            referralOptions: null,
            currentApplication: ApplicationEnum.AxxessHospiceFE,
            accountId: "",
            errors: {
                Demographics: [],
                AdvancedDirectives: [],
                EmergencyPreparedness: [],
                Clinical: [],
                Contacts: [],
                Pharmacy: [],
                Physicians: [],
                ReferralInformation: [],
                Insurance: [],
                PrimaryAddress: [],
                MailingAddress: [],
                AdditionalBillingInfo: [],
                PrimaryInsuredDetails: [],
                SecondaryInsuredDetails: [],
                TertiaryInsuredDetails: []
            },
            updated: true,
            primaryCountyData: [],
            mailingCountyData: [],
            visitCountyData: [],
            primaryEmergencyCountyData: [],
            chapsCountyData: [],
            additionalEmergency1: [],
            additionalEmergency2: [],
            additionalEmergency3: []
        } as IHHReferralState),
    actions: {
        [SET_HH_REFERRAL](payload: IHomeHealthReferralNew) {
            this.referral = payload;
        },
        [SET_REFERRAL_OPTIONS](payload: any) {
            this.referralOptions = payload;
        },
        [SET_CURRENT_APPLICATION](payload: any) {
            this.currentApplication = payload;
        },
        [SET_ACCOUNT_ID](payload: any) {
            this.accountId = payload;
        },
        [SET_ERRORS](payload: any) {
            this.errors[payload.property].push(payload.error);
        },

        [RESET_ERRORS]() {
            this.errors.Demographics = [];
            this.errors.AdvancedDirectives = [];
            this.errors.EmergencyPreparedness = [];
            this.errors.Clinical = [];
            this.errors.Contacts = [];
            this.errors.Pharmacy = [];
            this.errors.Physicians = [];
            this.errors.ReferralInformation = [];
            this.errors.Insurance = [];
            this.errors.PrimaryAddress = [];
            this.errors.MailingAddress = [];
            this.errors.PrimaryEmergencyContact = [];
            this.errors.AdditionalBillingInfo = [];
        },
        [SET_NEW_ERRORS](payload: any) {
            this.errors[payload.property] = payload.value;
        },
        [SET_ADDITIONAL_CONTACT_ERROR](payload: any) {
            this.errors.AdditionalEmergencyContacts.push(payload);
        },
        [REMOVE_ERRORS]() {
            this.errors.AdditionalEmergencyContacts = [];
        },
        [SET_UPDATED](payload: boolean) {
            this.updated = payload;
        },
        [SET_COUNTY_DATA](payload: any) {
            this.primaryCountyData = payload;
        },
        [SET_MAILING_COUNTY](payload: any) {
            this.mailingCountyData = payload;
        },
        [SET_VISIT_COUNTY](payload: any) {
            this.visitCountyData = payload;
        },
        [SET_PRIMARY_EMERGENCY](payload: any) {
            this.primaryEmergencyCountyData = payload;
        },
        [SET_CHAPS_COUNTY](payload: any) {
            this.chapsCountyData = payload;
        },
        [SET_ADDITION_COUNTY_1](payload: any) {
            this.additionalEmergency1 = payload;
        },
        [SET_ADDITION_COUNTY_2](payload: any) {
            this.additionalEmergency2 = payload;
        },
        [SET_ADDITION_COUNTY_3](payload: any) {
            this.additionalEmergency3 = payload;
        }
    }
});
