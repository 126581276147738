import { defineComponent, PropType } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { ApplicationEnum } from "../../../enums/enums";
import { formatDateInput } from "../../../common/vue-helpers/modifiers/value-modifier";
import type { IBranchResponse } from "../../vue-interfaces/i-branch";

import FormSection from "../../vue-custom-elements/FormSection/FormSection.vue";
import PhoneNumber from "../../vue-custom-elements/PhoneNumber/PhoneNumber.vue";
import DatePicker from "../../vue-custom-elements/DatePicker/DatePicker.vue";
import CommentInput from "../../vue-custom-elements/CommentInput/CommentInput.vue";

export default defineComponent({
    components: {
        FormSection,
        PhoneNumber,
        DatePicker,
        CommentInput
    },
    props: {
        inquiry: { type: Object, default: null },
        isLoading: { type: Boolean, default: false },
        branches: { type: Array as PropType<IBranchResponse[]>, default: null }
    },
    data() {
        return {
            isPhoneNumberInValid: false,
            applicationEnum: ApplicationEnum,
            v$: useVuelidate(),
            formatDateInput
        };
    },
    validations() {
        return {
            inquiry: {
                contactFirstName: { required },
                contactLastName: { required },
                contactPhoneNumbers: [
                    {
                        number: { required }
                    }
                ],
                locationId: { required },
                date: { required }
            }
        };
    },
    mounted() {
        this.$emit("updateBranch", this.inquiry.application);
    },
    methods: {
        selectedBranch(branchId: string) {
            let branchDetail: IBranchResponse = null;
            if (this.branches.length > 0) {
                branchDetail = this.branches.find((branch) => branch.id === branchId);
            }
            this.inquiry.accountId = branchDetail ? branchDetail.accountId : "";
        },
        typeUpdated(value: number[]) {
            let name = value[0];
            (this.inquiry as any)[name][0].type = Number(value[1]);
        },
        countryUpdated(value: string[]) {
            let name = value[0];
            (this.inquiry as any)[name][0].country = value[1];
        },
        resultUpdatedAppended(value: string) {
            this.inquiry.comment += value;
        },
        resultUpdated(value: string) {
            this.inquiry.comment = value;
        },
        changeDate(date: any, name: string) {
            this.inquiry.date = date;
        }
    }
});
