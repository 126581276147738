import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker
    },
    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId"
        })
    },
    data() {
        return {
            showOccurenceSpan: false,
            showOccurenceCodes: false
        };
    },
    methods: {
        onDateChange(date: string, name: string) {
            if (name == "31a-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator31a>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "32a-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator32a>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "33a-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator33a>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "34a-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator34a>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "31b-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator31b>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "32b-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator32b>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "33b-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator33b>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "34b-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator34b>k__BackingField"][
                    "<Date>k__BackingField"
                ] = date;
            }
            if (name == "35a-start-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator35a>k__BackingField"][
                    "<DateFrom>k__BackingField"
                ] = date;
            }
            if (name == "35a-end-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator35a>k__BackingField"][
                    "<DateThrough>k__BackingField"
                ] = date;
            }
            if (name == "36a-start-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator36a>k__BackingField"][
                    "<DateFrom>k__BackingField"
                ] = date;
            }
            if (name == "36a-end-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator36a>k__BackingField"][
                    "<DateThrough>k__BackingField"
                ] = date;
            }
            if (name == "35b-start-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator35b>k__BackingField"][
                    "<DateFrom>k__BackingField"
                ] = date;
            }
            if (name == "35b-end-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator35b>k__BackingField"][
                    "<DateThrough>k__BackingField"
                ] = date;
            }
            if (name == "36b-start-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator36b>k__BackingField"][
                    "<DateFrom>k__BackingField"
                ] = date;
            }
            if (name == "36b-end-date") {
                this.referral.Insurance.AdditionalBillingInfo.UB04AddtionalBillingInfo["<Locator36b>k__BackingField"][
                    "<DateThrough>k__BackingField"
                ] = date;
            }
        },
        toogleShowOccurenceSpan() {
            this.showOccurenceSpan = !this.showOccurenceSpan;
        },
        toogleShowOccurenceCode() {
            this.showOccurenceCodes = !this.showOccurenceCodes;
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
        }
    }
});
