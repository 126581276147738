import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { UserService } from "../../../services/user-service";
import { IGetUserByName } from "../../../resources-vue/vue-interfaces/i-user";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { NavigationService } from "../../../services/navigation.service";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../../enums/enums";
import { INavigateRequestParams, INavigateResponse } from "../../../resources-vue/vue-interfaces/i-navigation";
import config from "../../../common/config";
import { formatPhone, formatDate } from "../../../common/vue-helpers/modifiers/value-modifier";
import { getApplicationId } from "../../../common/vue-helpers/modifiers/application-modifiers";

import ProductPill from "../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";

export default defineComponent({
    components: {
        ProductPill
    },
    props: {
        showEmployees: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            _userService: null,
            _navigationService: null,
            _permissionManager: null,
            isLoading: null,
            hasError: false,
            searchQuery: "",
            employeeList: [] as IGetUserByName[],
            isNavigationUnderProcess: false,
            userOs: "",
            formatPhone,
            formatDate,
            getApplicationId
        };
    },

    created() {
        this._userService = Container.instance.get(UserService);
        this._navigationService = Container.instance.get(NavigationService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this.userOs = window.sessionStorage.getItem("userOs");
    },

    mounted() {
        this.resetFields();
    },

    methods: {
        closeEmployees() {
            this.$emit("update:showEmployees", false);
        },

        disableForm() {
            return this.searchQuery.trim().length < 3 || this.isLoading;
        },

        submitForm(event: any) {
            if (!this.disableForm()) {
                this.searchEmployee();
            }
        },

        resetFields() {
            this.employeeList = [];
            this.hasError = false;
            this.isLoading = null;
            this.searchQuery = "";
        },

        async handleNavigation(employee: IGetUserByName) {
            if (this.isNavigationUnderProcess) {
                return;
            } else {
                this.isNavigationUnderProcess = true;
            }

            const analyticsCategory = "Central-Clinician-Navigation";
            const path = `/employees/employee-center/${employee.id}?accountId=${employee.agencyId}`;
            let action = "";
            let baseUrl = "";

            if (employee.application === ApplicationEnum.AxxessHospiceFE) {
                baseUrl = `${config.hospiceUrl}${path}`;
                action = "AxxessHospice";
            } else if (employee.application == ApplicationEnum.AxxessPalliative) {
                baseUrl = `${config.palliativeUrl}${path}`;
                action = "AxxessPaliative";
            } else if ([ApplicationEnum.AgencyCore, ApplicationEnum.HomeCare].includes(employee.application)) {
                const redirectUrl = await this.getNavigation(employee);
                if (redirectUrl) {
                    baseUrl = redirectUrl;
                    action =
                        employee.application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
                }
            }

            const redirectResponse = {
                redirectUrl: baseUrl,
                isSuccessful: true
            };
            this.isNavigationUnderProcess = false;
            if (action && baseUrl) {
                this._navigationService.redirect(redirectResponse, {
                    category: analyticsCategory,
                    action
                });
            }
        },

        async getNavigation(employee: IGetUserByName) {
            let response: INavigateResponse;
            const isClinician = this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView,
                employee.agencyId
            );
            const navParams: INavigateRequestParams = {
                applicationId: employee.application,
                agencyId: employee.agencyId,
                employeeId: employee.id,
                isClinician
            };

            try {
                response = await this._navigationService.navigateToEmployee(navParams);
            } catch (e) {
                console.error(e);
            }
            return response?.redirectUrl || null;
        },

        getStatusText(status: string): string {
            if (!status) {
                return "";
            }
            const result = status.toLowerCase();
            if (result.includes("pending")) {
                return "Pending";
            } else if (result.includes("terminate")) {
                return "Terminated";
            }
            return status;
        },

        async searchEmployee() {
            if (this.searchQuery.trim().length < 3) {
                return;
            }
            this.employeeList = [];
            this.isLoading = true;
            this.hasError = false;
            try {
                const data: IGetUserByName[] = await this._userService.getUserByName({ term: this.searchQuery.trim() });
                // this is-loading condition is to check if the user has closed the search box or not till request completes
                if (this.isLoading) {
                    this.employeeList = data;
                }
            } catch (error) {
                if (this.isLoading) {
                    this.hasError = true;
                }
                throw new Error(error);
            } finally {
                if (this.isLoading) {
                    this.isLoading = false;
                }
            }
        }
    }
});
