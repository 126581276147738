import { defineComponent } from "vue";
import moment from "moment";

import { formatingDateOrTime } from "./../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    data() {
        return {
            today: moment(),
            dateFormat: "dddd, MMM DD, YYYY",
            timeFormat: "hh:mm A",
            formatingDateOrTime
        };
    }
});
