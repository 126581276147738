import { defineComponent, computed } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, minLength, required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { IBranchResponse } from "../../../../../resources-vue/vue-interfaces/i-branch";
import { PhoneTypeEnum } from "../../../../../enums/enums";
import { BranchesService } from "../../../../../services/branches-service";
import { PatientService } from "../../../../../services/patient.service";
import { IPatientValidation } from "../../../../../resources-vue/vue-interfaces/i-patient";
import { IAddress, IValidateAddress } from "../../../../../resources-vue/vue-interfaces/i-address";
import { formatDateInput, formatPhone } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { IGetCityStateByZipcodeResponse } from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { LookupService } from "../../../../../services/lookup-service";

import ReferralAddressForm from "../../ReferralAddressForm/ReferralAddressForm.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ValidateAddressHomeHealth from "../../../../../resources-vue/vue-dialogs/ValidateAddress/ValidateAddressHomeHealth.vue";

export default defineComponent({
    components: {
        DatePicker,
        FormSection,
        ReferralAddressForm
    },

    validations() {
        return {
            referral: {
                Demographics: {
                    FirstName: { required },
                    LastName: { required },
                    DOB: { required },
                    PhonePreferred: { required },
                    Gender: { required },
                    SSN: {
                        required: requiredIf(() => !this.referral.Demographics.SSNUnknown),
                        minLength: minLength(9),
                        maxLength: maxLength(9)
                    },
                    ClinicalManagerAssigned: { required },
                    CaseManagerId: { required },
                    UserId: { required },
                    PatientIdNumber: { required },
                    AgencyLocationId: { required },
                    ServiceLocation: { required }
                }
            }
        };
    },

    props: {
        isEditing: {
            type: Boolean,
            default: false
        },

        branchId: {
            type: String,
            default: ""
        }
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId",
            errors: "errors",
            primaryCountyData: "primaryCountyData",
            mailingCountyData: "mailingCountyData",
            visitCountyData: "visitCountyData"
        }),
        primaryAddress() {
            return this.referral.Demographics.PrimaryAddress;
        }
    },

    data() {
        return {
            _branchesService: null,
            _patientsService: null,
            _lookupService: null,
            dialogRef: null,
            branches: [] as IBranchResponse[],
            phoneNumberTypeOptionsConfig: [PhoneTypeEnum.Home, PhoneTypeEnum.Mobile, PhoneTypeEnum.Facility],
            isAddressValidationInProgress: false,
            addressKeyNames: {
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                County: "",
                State: "",
                Zip: "",
                ZipCodeFour: ""
            },

            addressTypes: null,
            hasVisitAddress: false,
            ssnDuplicateCache: null,
            formatPhone,
            formatDateInput,
            v$: useVuelidate()
        };
    },

    created() {
        this._branchesService = Container.instance.get(BranchesService);
        this._patientsService = Container.instance.get(PatientService);
        this._lookupService = Container.instance.get(LookupService);

        this.ssnDuplicateCache = {};

        this.addressTypes = {
            PrimaryAddress: JSON.parse(JSON.stringify(this.addressKeyNames)),
            MailingAddress: JSON.parse(JSON.stringify(this.addressKeyNames)),
            VisitAddress: JSON.parse(JSON.stringify(this.addressKeyNames))
        };
        this.referral.Demographics.UserId =
            this.referral.Demographics.UserId === "00000000-0000-0000-0000-000000000000"
                ? null
                : this.referral.Demographics.UserId;
        this.referral.Demographics.CaseManagerId =
            this.referral.Demographics.CaseManagerId === "00000000-0000-0000-0000-000000000000"
                ? null
                : this.referral.Demographics.CaseManagerId;
        this.referral.Demographics.ClinicalManagerAssigned =
            this.referral.Demographics.ClinicalManagerAssigned === "00000000-0000-0000-0000-000000000000"
                ? null
                : this.referral.Demographics.ClinicalManagerAssigned;
        this.bind();
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    methods: {
        ...mapActions(useEditReferralHH, {
            setPrimaryCountyData: "SET_COUNTY_DATA",
            setMailingCounty: "SET_MAILING_COUNTY",
            setVisitCounty: "SET_VISIT_COUNTY",
            setErrors: "SET_NEW_ERRORS"
        }),
        handleSSNUnknown() {
            if (this.referral.Demographics.SSNUnknown) {
                this.referral.Demographics.SSN = "";
            }
        },

        async bind() {
            await this.initOptions();
            if (this.primaryCountyData.length === 0) {
                await this.getPrimaryCounty();
            }
            if (this.mailingCountyData.length === 0) {
                await this.checkMailingSameAsPrimary();
            }
            if (this.visitCountyData.length) {
                await this.getVisitCounty();
            }
        },

        async getPrimaryCounty() {
            if (this.referral.Demographics.PrimaryAddress.Zip) {
                const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                    zipCode: this.referral.Demographics.PrimaryAddress.Zip,
                    agencyId: this.accountId,
                    application: this.application
                });
                const county: string[] = await this._lookupService.getCountyByZipcode(
                    this.referral.Demographics.PrimaryAddress.Zip,
                    this.accountId
                );
                this.setPrimaryCountyData(county);
                this.referral.Demographics.PrimaryAddress.County = county ? county[0] : null;
                this.referral.Demographics.PrimaryAddress.State = result.state
                    ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                    : null;
                this.referral.Demographics.PrimaryAddress.City = result.city ? result.city : null;
                const newErrors = [...new Set(this.errors.PrimaryAddress)].filter(
                    (val: any) => val !== "County" && val !== "State" && val !== "City"
                );
                const payload = {
                    property: "PrimaryAddress",
                    value: newErrors
                };
                this.setErrors(payload);
            }
        },
        async checkMailingSameAsPrimary() {
            if (this.referral.Demographics.SameAsPhysicalAddress) {
                this.setMailingCounty(this.primaryCountyData);
            } else if (this.referral.Demographics.MailingAddress.Zip) {
                this.getMailingCountyData();
            }
        },

        async handleMailingZipChange() {
            this.referral.Demographics.SameAsPhysicalAddress = false;
            this.getMailingCountyData();
        },

        async getMailingCountyData() {
            const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                zipCode: this.referral.Demographics.MailingAddress.Zip,
                agencyId: this.accountId,
                application: this.application
            });
            const county: string[] = await this._lookupService.getCountyByZipcode(
                this.referral.Demographics.MailingAddress.Zip,
                this.accountId
            );
            this.setMailingCounty(county);
            this.referral.Demographics.MailingAddress.County = county ? county[0] : null;
            this.referral.Demographics.MailingAddress.State = result.state
                ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                : null;
            this.referral.Demographics.MailingAddress.City = result.city ? result.city : null;
            const newErrors = [...new Set(this.errors.MailingAddress)].filter(
                (val: any) => val !== "County" && val !== "State" && val !== "City"
            );
            const payload = {
                property: "MailingAddress",
                value: newErrors
            };
            this.setErrors(payload);
        },

        async getVisitCounty() {
            if (this.referral.Demographics.VisitAddress.Zip) {
                const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                    zipCode: this.referral.Demographics.VisitAddress.Zip,
                    agencyId: this.accountId,
                    application: this.application
                });
                const county: string[] = await this._lookupService.getCountyByZipcode(
                    this.referral.Demographics.VisitAddress.Zip,
                    this.accountId
                );
                this.setVisitCounty(county);
                this.referral.Demographics.VisitAddress.County = county ? county[0] : null;
                this.referral.Demographics.VisitAddress.State = result.state
                    ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                    : null;
                this.referral.Demographics.VisitAddress.City = result.city ? result.city : null;
            }
        },

        visitAddressRemoved() {
            this.referral.Demographics.VisitAddress.AddressLine1 = "";
            this.referral.Demographics.VisitAddress.AddressLine2 = "";
            this.referral.Demographics.VisitAddress.City = "";
            this.referral.Demographics.VisitAddress.County = "";
            this.referral.Demographics.VisitAddress.State = "";
            this.referral.Demographics.VisitAddress.Zip = "";
            this.referral.Demographics.VisitAddress.ZipCodeFour = "";
        },

        async initOptions() {
            const branches = await this._branchesService.getAllBranches();
            this.branches = branches.filter((branch: any) => branch.accountId == this.accountId);
        },

        async validateSSN(currentValue: string) {
            if (!(currentValue in this.ssnDuplicateCache)) {
                const payload: IPatientValidation = {
                    AgencyId: this.referral.AgencyId,
                    Application: this.application,
                    DuplicateCheck: {
                        SSN: currentValue
                    }
                };
                const data = await this._patientsService.checkDuplicates(payload);
                this.ssnDuplicateCache[currentValue] = !data.isDuplicate;
                return !data.isDuplicate;
            }
            return this.ssnDuplicateCache[currentValue];
        },

        isMailingSameAsPrimaryChanged() {
            if (this.referral.Demographics.SameAsPhysicalAddress) {
                this.setMailingCounty(this.primaryCountyData);
                this.referral.Demographics.MailingAddress.AddressLine1 =
                    this.referral.Demographics.PrimaryAddress.AddressLine1;
                this.referral.Demographics.MailingAddress.AddressLine2 =
                    this.referral.Demographics.PrimaryAddress.AddressLine2;
                this.referral.Demographics.MailingAddress.City = this.referral.Demographics.PrimaryAddress.City;
                this.referral.Demographics.MailingAddress.County = this.referral.Demographics.PrimaryAddress.County;
                this.referral.Demographics.MailingAddress.Zip = this.referral.Demographics.PrimaryAddress.Zip;
                this.referral.Demographics.MailingAddress.State = this.referral.Demographics.PrimaryAddress.State;
                this.referral.Demographics.MailingAddress.Zip = this.referral.Demographics.PrimaryAddress.Zip;
                this.referral.Demographics.MailingAddress.ZipCodeFour =
                    this.referral.Demographics.PrimaryAddress.ZipCodeFour;
                const newErrors = [...new Set(this.errors.MailingAddress)].filter(
                    (val: any) => val !== "State" && val !== "City" && val !== "Zip"
                );
                const payload = {
                    property: "MailingAddress",
                    value: newErrors
                };
                this.setErrors(payload);
            } else {
                this.referral.Demographics.MailingAddress.AddressLine1 = "";
                this.referral.Demographics.MailingAddress.AddressLine2 = "";
                this.referral.Demographics.MailingAddress.City = "";
                this.referral.Demographics.MailingAddress.County = "";
                this.referral.Demographics.MailingAddress.Zip = "";
                this.referral.Demographics.MailingAddress.State = "";
                this.referral.Demographics.MailingAddress.Zip = "";
                this.referral.Demographics.MailingAddress.ZipCodeFour = "";
                this.setMailingCounty([]);
            }
        },

        onDateChange(date: any, name: string) {
            if (name === "DOB") this.referral.Demographics.DOB = formatDateInput(date);
            if (date.length) {
                if ([...new Set(this.errors.Demographics)].includes(name)) {
                    const newErrors = [...new Set(this.errors.Demographics)].filter((val: any) => val !== name);
                    const payload = {
                        property: "Demographics",
                        value: newErrors
                    };
                    this.setErrors(payload);
                }
            }
        },

        setPhoneHome(event: any) {
            let value = event.target.value;
            this.referral.Demographics.PhonePreferred = value.toString();
            this.handleAllInput(event, "PhonePreferred");
        },

        setPhoneMobile(event: any) {
            let value = event.target.value;
            this.referral.Demographics.PhoneAlternate = value.toString();
        },

        validateAddress() {
            const model: IValidateAddress = {
                originalAddress: {
                    addressLine1: this.referral.Demographics.PrimaryAddress.AddressLine1,
                    addressLine2: this.referral.Demographics.PrimaryAddress.AddressLine2,
                    city: this.referral.Demographics.PrimaryAddress.City,
                    state: this.referral.Demographics.PrimaryAddress.State,
                    zipCode: this.referral.Demographics.PrimaryAddress.Zip,
                    county: this.referral.Demographics.PrimaryAddress.County,
                    mailingAddressLine1: this.referral.Demographics.MailingAddress.AddressLine1,
                    mailingAddressLine2: this.referral.Demographics.MailingAddress.AddressLine2,
                    mailingCity: this.referral.Demographics.MailingAddress.City,
                    mailingState: this.referral.Demographics.MailingAddress.State,
                    mailingZipCode: this.referral.Demographics.MailingAddress.Zip,
                    mailingCounty: this.referral.Demographics.MailingAddress.County,
                    visitAddressLine1: this.referral.Demographics.VisitAddress.AddressLine1,
                    visitAddressLine2: this.referral.Demographics.VisitAddress.AddressLine2,
                    visitCity: this.referral.Demographics.VisitAddress.City,
                    visitState: this.referral.Demographics.VisitAddress.State,
                    visitZipCode: this.referral.Demographics.VisitAddress.Zip,
                    visitCounty: this.referral.Demographics.VisitAddress.County
                },
                updatedAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    county: "",
                    country: "",
                    latitude: null,
                    longitude: null,
                    mailingAddressLine1: "",
                    mailingAddressLine2: "",
                    mailingCity: "",
                    mailingState: "",
                    mailingZipCode: "",
                    mailingCounty: "",

                    visitAddressLine1: "",
                    visitAddressLine2: "",
                    visitCity: "",
                    visitState: "",
                    visitZipCode: "",
                    visitCounty: "",

                    primaryUpdate: false,
                    mailingUpdate: false,
                    visitUpdate: false
                }
            };
            this.isAddressValidationInProgress = true;
            this.dialogRef = this.$dialog.open(ValidateAddressHomeHealth, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    model
                },
                onClose: (response) => {
                    const closeResult = response.data;
                    let updatedAddress: IAddress;
                    if (response.data !== "cancelled") {
                        updatedAddress = closeResult.updatedAddress;
                        if (updatedAddress.primaryUpdate) {
                            this.referral.Demographics.PrimaryAddress.AddressLine1 =
                                this.addressTypes.primary.AddressLine1 = updatedAddress.addressLine1;
                            this.referral.Demographics.PrimaryAddress.AddressLine2 =
                                this.addressTypes.primary.AddressLine2 = updatedAddress.addressLine2;
                            this.referral.Demographics.PrimaryAddress.City = this.addressTypes.primary.AddressCity =
                                updatedAddress.city;
                            this.referral.Demographics.PrimaryAddress.State = updatedAddress.state;
                            this.referral.Demographics.PrimaryAddress.Zip = this.addressTypes.primary.AddressZipCode =
                                updatedAddress.zipCode;
                            this.referral.Demographics.PrimaryAddress.County = this.addressTypes.primary.AddressCounty =
                                updatedAddress.county;
                        }
                        if (updatedAddress.visitUpdate) {
                            this.referral.Demographics.VisitAddress.AddressLine1 =
                                this.addressTypes.visit.AddressLine1 = updatedAddress.visitAddressLine1;
                            this.referral.Demographics.VisitAddress.AddressLine2 =
                                this.addressTypes.visit.AddressLine2 = updatedAddress.visitAddressLine2;
                            this.referral.Demographics.VisitAddress.City = this.addressTypes.visit.AddressCity =
                                updatedAddress.visitCity;
                            this.referral.Demographics.VisitAddress.State = this.addressTypes.visit.AddressStateCode =
                                updatedAddress.visitState;
                            this.referral.Demographics.VisitAddress.Zip = this.addressTypes.visit.AddressZipCode =
                                updatedAddress.visitZipCode;
                            this.referral.Demographics.VisitAddress.County = this.addressTypes.visit.AddressCounty =
                                updatedAddress.visitCounty;
                        }
                        if (updatedAddress.mailingUpdate) {
                            this.referral.Demographics.MailingAddress.AddressLine1 =
                                this.addressTypes.mailing.AddressLine1 = updatedAddress.mailingAddressLine1;
                            this.referral.Demographics.MailingAddress.AddressLine2 =
                                this.addressTypes.mailing.AddressLine2 = updatedAddress.mailingAddressLine2;
                            this.referral.Demographics.MailingAddress.City = this.addressTypes.mailing.AddressCity =
                                updatedAddress.mailingCity;
                            this.referral.Demographics.MailingAddress.State =
                                this.addressTypes.mailing.AddressStateCode = updatedAddress.mailingState;
                            this.referral.Demographics.MailingAddress.Zip = this.addressTypes.mailing.AddressZipCode =
                                updatedAddress.mailingZipCode;
                            this.referral.Demographics.MailingAddress.County = this.addressTypes.mailing.AddressCounty =
                                updatedAddress.mailingCounty;
                        }
                        if (updatedAddress.mailingUpdate !== updatedAddress.primaryUpdate) {
                            this.referral.Demographics.SameAsPhysicalAddress = false;
                            this.referral.Demographics.SameAsPhysicalAddress = false;
                        }
                        if (updatedAddress.primaryUpdate) {
                            this.referral.Contacts.PrimaryEmergencyContact.SameAsPatientAddress = false;
                            this.referral.Contacts.AdditionalEmergencyContacts.forEach((item: any) => {
                                item.SameAsPatientAddress = false;
                            });
                        }
                    }
                }
            });
            this.isAddressValidationInProgress = false;
        },
        handleDateInputChange(e: any) {
            this.referral.Demographics.DOB = e.target.value;
            this.handleAllInput(e, "DOB");
        },
        handleAllInput(e: any, field: string) {
            const value = e.target.value;
            if (value.length) {
                if ([...new Set(this.errors.Demographics)].includes(field)) {
                    const newErrors = [...new Set(this.errors.Demographics)].filter((val: any) => val !== field);
                    const payload = {
                        property: "Demographics",
                        value: newErrors
                    };
                    this.setErrors(payload);
                }
            }
        }
    }
});
