import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "central-background" }
const _hoisted_2 = { class: "page-content px-0 position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelTwo = _resolveComponent("LevelTwo")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_LevelTwo, { router: _ctx.router }, null, 8 /* PROPS */, ["router"]),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}