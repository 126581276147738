import moment from "moment";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        alignLeftStart: { type: Boolean, default: false },
        result: { type: String, default: "" },
        currentEpisodeDetails: { type: Object, default: {} }
    },

    emits: ["updateResult"],

    data() {
        return {
            debounce: 0 as number,
            element: null,
            _openListener: null,
            _outsideClickListener: null,
            _multiDatePickerInput: null,
            _multiDatePickerElements: null,
            _keyDownListener: null,
            showCalendar: false as boolean
        };
    },

    created() {
        this._openListener = () => this.openCalendar();
        this._outsideClickListener = (evt: MouseEvent) => this.handleBlur(evt);
        this._keyDownListener = (evt: KeyboardEvent) => this.onKeyDown(evt);
    },

    mounted() {
        this.element = this.$refs.multiDatePicker;
        this._multiDatePickerInput = this.$refs.multiDatePickerInput;

        this._multiDatePickerInput.addEventListener("focus", this._openListener);
        this._multiDatePickerInput.addEventListener("click", this._openListener);
        this._multiDatePickerInput.addEventListener("keydown", this._keyDownListener);
        document.addEventListener("click", this._outsideClickListener);
        this._multiDatePickerElements = $(".multi-calendar-element");
        this._multiDatePickerElements.map((index: any, calendarElement: Element) => {
            $(calendarElement).datepicker({
                defaultViewDate: {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + index,
                    day: 1
                },
                multidate: true,
                updateViewDate: false,
                startDate: moment(this.currentEpisodeDetails.startDate).format("MM/DD/YYYY"),
                endDate: moment(this.currentEpisodeDetails.endDate).format("MM/DD/YYYY")
            });
        });
        // keep month in sync
        this._multiDatePickerElements.on("changeMonth", (e: Event) => this.orderMonth(e));
        // keep dates in sync
        this._multiDatePickerElements.on("changeDate", (e: Event) => this.changeDate(e));
    },

    watch: {
        result: function () {
            if (!this.result) {
                this.clearResult();
            }
        }
    },

    methods: {
        clearResult() {
            this.$emit("updateResult", "");
            this._multiDatePickerElements.datepicker("clearDates");
            this.showCalendar = false;
        },

        onKeyDown(evt: KeyboardEvent) {
            setTimeout(() => {
                if (this.showCalendar) {
                    this.switchKeyCode(evt.keyCode);
                    return;
                }
            }, 0);
        },

        switchKeyCode(keyCode: number) {
            switch (keyCode) {
                case 27:
                    return this.handleScape();
                default:
                    return this.handleChange();
            }
        },

        openCalendar() {
            if (this.showCalendar) {
                return;
            }
            this.showCalendar = true;
        },

        handleChange() {
            this._multiDatePickerElements.datepicker(
                "setDates",
                this.result
                    .split(", ")
                    .filter((date) => this.isValidDate(date))
                    .map((date) => moment(date).toDate())
            );
        },

        handleBlur(evt: MouseEvent) {
            if (!this.showCalendar) {
                return;
            }

            setTimeout(() => {
                if (!this.element.contains(evt.target as Node)) {
                    this.handleScape();
                }
            }, this.debounce);
        },

        handleScape() {
            this.showCalendar = false;
        },

        orderMonth(e: any) {
            let target = e.target;
            let date = e.date;
            let calendars = this._multiDatePickerElements;
            let positionOfTarget = calendars.index(target);
            calendars.each((index: number, calendarElement: Element) => {
                if (calendarElement === target) {
                    return;
                }
                let newDate = new Date(date);
                newDate.setUTCDate(1);
                newDate.setMonth(date.getMonth() + index - positionOfTarget);
                ($(calendarElement) as any).datepicker("_setDate", newDate, "view");
            });
        },

        changeDate(e: Event) {
            let calendars = this._multiDatePickerElements;
            let target = e.target;
            let newDates = $(target).datepicker("getDates");
            calendars.each((index: number, calendarElement: Element) => {
                if (calendarElement === e.target) {
                    return;
                }
                // setUTCDates triggers changeDate event
                // could easily run into an infinite loop
                // therefore we check if currentDates equal newDates
                let currentDates = $(calendarElement).datepicker("getDates");
                if (
                    currentDates &&
                    currentDates.length === newDates.length &&
                    currentDates.every((currentDate: Date) => {
                        return newDates.some((newDate: Date) => {
                            return currentDate.toISOString() === newDate.toISOString();
                        });
                    })
                ) {
                    return;
                }
                $(calendarElement).datepicker("setDates", newDates);
                let inputInProgress = this.result.split(", ").filter((date) => !this.isValidDate(date) && date != "");
                const updatedResult = (newDates.map((date: Date) => moment(date).format("MM/DD/YYYY")) as string[])
                    .concat(inputInProgress)
                    .join(", ");
                this.$emit("updateResult", updatedResult);
            });
        },

        isValidDate(date: string) {
            return /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/.test(date);
        }
    },

    unmounted() {
        this._multiDatePickerElements.datepicker("destroy");
        document.removeEventListener("click", this._outsideClickListener);
        this._multiDatePickerInput.removeEventListener("keydown", this._keyDownListener);
        this._multiDatePickerInput.removeEventListener("focus", this._openListener);
        this._multiDatePickerInput.removeEventListener("click", this._openListener);
    }
});
