import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum DisciplineEnum {
    None = 0 as any,
    Nursing = 1 as any,
    LPN = 2 as any,
    Physician = 4 as any,
    MedicalSocialWorker = 8 as any,
    SpiritualCounselor = 16 as any,
    BereavementCounselor = 32 as any,
    VolunteerCoordinator = 64 as any,
    PhysicalTherapy = 128 as any,
    OccupationalTherapy = 256 as any,
    SpeechTherapy = 512 as any,
    Music = 2048 as any,
    Art = 4096 as any,
    Massage = 8192 as any,
    Aroma = 16384 as any,
    HealingTouch = 32768 as any,
    Hypnosis = 65536 as any,
    Pet = 131072 as any,
    Dietician = 262144 as any,
    Aide = 1048576 as any,
    ComplementaryTherapy = 4194304 as any,
    MedicalDirector = 8388608 as any,
    PhysicianAssistant = 16777216 as any,
    HomeMaker = 33554432 as any,
    NP = 67108864 as any
}
class DisciplineClass extends EnumWithDescription<DisciplineEnum> {
    public readonly enum = DisciplineEnum;
    public readonly enumName: string = "DisciplineEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<DisciplineEnum>> = new Map([
        [
            "None",
            {
                value: DisciplineEnum.None,
                name: "None"
            }
        ],
        [
            "Nursing",
            {
                value: DisciplineEnum.Nursing,
                name: "Skilled Nursing"
            }
        ],
        [
            "LPN",
            {
                value: DisciplineEnum.LPN,
                name: "LVN/LPN"
            }
        ],
        [
            "Physician",
            {
                value: DisciplineEnum.Physician,
                name: "Hospice Physician"
            }
        ],
        [
            "MedicalSocialWorker",
            {
                value: DisciplineEnum.MedicalSocialWorker,
                name: "Medical Social Work"
            }
        ],
        [
            "SpiritualCounselor",
            {
                value: DisciplineEnum.SpiritualCounselor,
                name: "Spiritual Counseling"
            }
        ],
        [
            "BereavementCounselor",
            {
                value: DisciplineEnum.BereavementCounselor,
                name: "Bereavement Counseling"
            }
        ],
        [
            "VolunteerCoordinator",
            {
                value: DisciplineEnum.VolunteerCoordinator,
                name: "Volunteer Coordinator"
            }
        ],
        [
            "PhysicalTherapy",
            {
                value: DisciplineEnum.PhysicalTherapy,
                name: "Physical Therapy"
            }
        ],
        [
            "OccupationalTherapy",
            {
                value: DisciplineEnum.OccupationalTherapy,
                name: "Occupational Therapy"
            }
        ],
        [
            "SpeechTherapy",
            {
                value: DisciplineEnum.SpeechTherapy,
                name: "Speech Therapy"
            }
        ],
        [
            "Music",
            {
                value: DisciplineEnum.Music,
                name: "Music Therapy"
            }
        ],
        [
            "Art",
            {
                value: DisciplineEnum.Art,
                name: "Art Therapy"
            }
        ],
        [
            "Massage",
            {
                value: DisciplineEnum.Massage,
                name: "Massage Therapy"
            }
        ],
        [
            "Aroma",
            {
                value: DisciplineEnum.Aroma,
                name: "Aroma Therapy"
            }
        ],
        [
            "HealingTouch",
            {
                value: DisciplineEnum.HealingTouch,
                name: "Healing Touch"
            }
        ],
        [
            "Hypnosis",
            {
                value: DisciplineEnum.Hypnosis,
                name: "Hypnotherapy"
            }
        ],
        [
            "Pet",
            {
                value: DisciplineEnum.Pet,
                name: "Pet Therapy"
            }
        ],
        [
            "Dietician",
            {
                value: DisciplineEnum.Dietician,
                name: "Dietary Counseling"
            }
        ],
        [
            "Aide",
            {
                value: DisciplineEnum.Aide,
                name: "Hospice Aide"
            }
        ],
        [
            "ComplementaryTherapy",
            {
                value: DisciplineEnum.ComplementaryTherapy,
                name: "Complementary Therapy"
            }
        ],
        [
            "MedicalDirector",
            {
                value: DisciplineEnum.MedicalDirector,
                name: "Hospice Medical Director"
            }
        ],
        [
            "PhysicianAssistant",
            {
                value: DisciplineEnum.PhysicianAssistant,
                name: "Physician’s Assistant"
            }
        ],
        [
            "HomeMaker",
            {
                value: DisciplineEnum.HomeMaker,
                name: "Home Maker"
            }
        ],
        [
            "NP",
            {
                value: DisciplineEnum.NP,
                name: "Nurse Practitioner"
            }
        ]
    ]);
}

export const Discipline = new DisciplineClass();
