import { PropType, defineComponent } from "vue";
import { Key } from "ts-keycode-enum";

import type { TypeaheadData, ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { HttpStatusCodeEnum } from "../../../enums/enums";
import { sanitizeMarkup, substringHighlighter } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    expose: ["clearFilter", "resetFilter"],
    props: {
        getData: {
            type: Function
        },
        value: {
            type: (Object as PropType<TypeaheadData>) || String
        },
        initValue: {
            type: (Object as PropType<TypeaheadData>) || String
        },
        key: {
            type: String,
            default: "name"
        },
        id: {
            type: String,
            default: ""
        },
        debounce: {
            type: Number,
            default: 300
        },
        characterLimit: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: false
        },
        openOnFocus: {
            type: Boolean,
            default: false
        },
        changeOpenOnFocus: {
            type: Function
        },
        loadingText: {
            type: String,
            default: "Loading..."
        },
        placeholder: {
            type: String,
            default: "Start Typing..."
        },
        noResultsText: {
            type: String,
            default: "No matches found..."
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        keyUpdated: {
            type: Function
        },
        showAllByDefault: {
            type: Boolean,
            default: false
        },
        selectSingleResult: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: []
        },
        customEntry: {
            type: Boolean,
            default: false
        },
        resultsLimit: {
            type: Number,
            default: 30
        },
        onSelect: {
            type: Function
        },
        instantCleanEmpty: {
            type: Boolean,
            default: false
        },
        closeOnSelection: {
            type: Boolean,
            default: true
        },
        focusFirst: {
            type: Boolean,
            default: true
        },
        isFocus: {
            type: Boolean,
            default: false
        },
        autoComplete: {
            type: Boolean,
            default: false
        },
        inputClass: {
            type: String,
            default: ""
        },
        wrapperClass: {
            type: String,
            default: ""
        },
        getAppendContent: {
            type: Function
        },
        shouldAppend: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            filter: "",
            isAuthorized: true,
            displayData: [],
            loading: false,
            focusedItem: null,
            notAuthorizedText: "Contact your administrator to request permission to view this data.",
            _promiseQueue: [],
            _focusedIndex: -1,
            _showClass: "show",
            _ignoreChange: null,
            sanitizeMarkup,
            substringHighlighter
        };
    },

    created() {
        if (typeof this.value == "object") {
            if ((this.value as any)?.[this.key]) {
                this.filter = this.getName(this.value);
            }
        }
        if (typeof this.value == "string") {
            this.filter = this.getName(this.value);
        }
    },

    mounted() {
        if (this.openOnFocus && !!this.$refs.inputRef) {
            (this.$refs.inputRef as HTMLInputElement)?.addEventListener("focus", () => this.openDropdown());
            (this.$refs.inputRef as HTMLInputElement).addEventListener("click", () => this.openDropdown());
        }
        this.initValueChanged();

        document?.addEventListener("click", (e) => this.handleBlur(e));
        (this.$refs.inputRef as HTMLInputElement)?.addEventListener("keydown", (e) => this.onkeydown(e));
    },
    watch: {
        filter(newValue) {
            this.filterChanged(newValue);
        },
        initValue() {
            this.initValueChanged();
        },
        value() {
            this.valueChanged();
        },
        data() {
            this.dataChanged();
        }
    },

    methods: {
        clearFilter() {
            this._ignoreChange = false;
            this.filter = "";
        },
        emitBlur() {
            this.$emit("blurredInput");
        },

        async filterChanged(value: any) {
            if (this._ignoreChange) {
                this._ignoreChange = false;
                return;
            }
            if (this.filter.length > 2) {
                await this.applyPlugins();
            }

            if (this.customEntry) {
                this.$emit("valueChanged", this.filter);
                if (typeof this.onSelect === "function") {
                    this.onSelect({ item: this.value });
                }
            } else if (this.selectSingleResult && this.displayData.length === 1) {
                this.itemSelected(this.displayData[0]);
            }
        },

        async dataChanged() {
            if (this.data.length > 0) {
                this.checkCustomEntry();
                await this.applyPlugins();
            }
        },
        valueChanged() {
            let newFilter = this.getName(this.value);
            if (newFilter !== this.filter) {
                this._ignoreChange = true;
                this.filter = newFilter;
            }
        },
        initValueChanged() {
            if (this.initValue) {
                let newFilter = this.getName(this.initValue);
                if (newFilter) {
                    this._ignoreChange = true;
                    this.filter = newFilter;
                }
            }
        },
        focusInput() {
            if (this.showAllByDefault) {
                this.openDropdown();
                return;
            }
            if (this.filter.length > this.characterLimit) {
                this.openDropdown();
            }
        },
        openDropdown() {
            if (this.openOnFocus && !this.disabled) {
                if ((this.$refs.dropdownMenuRef as HTMLElement)?.classList.contains(this._showClass)) {
                    return;
                }
                (this.$refs.dropdownMenuRef as HTMLElement)?.classList.add(this._showClass);
                this.focusNone();
                this.applyPlugins();
            }
        },
        doFocusFirst() {
            if (this.focusFirst && this.displayData.length > 0) {
                this._focusedIndex = 0;
                this.focusedItem = this.displayData[0];
            }
        },
        checkCustomEntry() {
            if (Array.isArray(this.data) && this.data.length > 0 && typeof this.data[0] !== "string") {
                this.$emit("updateCustomEntry", false);
            }
        },
        focusNone() {
            this.focusedItem = null;
            this._focusedIndex = -1;
        },
        doFilter(toFilter: ITypeaheadOptions[]) {
            let filteredSearch = toFilter.filter((item: ITypeaheadOptions) => {
                let searchKey = this.filter.toLowerCase();
                return item && this.getName(item).toLowerCase().indexOf(searchKey) > -1;
            });

            let allDataExceptFilteredSearch = toFilter.filter((task) => {
                return !filteredSearch.includes(task);
            });
            return [...filteredSearch, ...allDataExceptFilteredSearch];
        },
        getName(item: TypeaheadData) {
            if (!item) {
                return "";
            }

            if (typeof item === "object") {
                return !!item[this.key] ? item[this.key].toString() : "";
            }

            return item.toString();
        },
        resetFilter() {
            if (this.filter.length !== 0) {
                this.$emit("valueChanged", null);
            }
            let newFilter;
            if (this.value) {
                newFilter = this.getName(this.value);
            } else {
                newFilter = "";
            }

            if (newFilter !== this.filter) {
                this._ignoreChange = true;
                this.filter = newFilter;
            }
        },
        handleBlur(e: any) {
            if (
                this.$refs.dropdownMenuRef &&
                !(this.$refs.dropdownMenuRef as HTMLElement)?.classList.contains(this._showClass)
            ) {
                return;
            }

            setTimeout(() => {
                if (this.$refs.dropdownRef && !(this.$refs.dropdownRef as HTMLElement)?.contains(e.target as Element)) {
                    this.handleEscape();
                }
            }, this.debounce);
        },
        itemSelected(item: TypeaheadData) {
            this.$emit("valueChanged", item);
            if (this.closeOnSelection) {
                (this.$refs.dropdownMenuRef as HTMLElement)?.classList.remove(this._showClass);
            }
            let newFilter = this.getName(item);
            if (newFilter !== this.filter) {
                this.filter = newFilter;
            }
            if (typeof this.onSelect === "function") {
                this.onSelect({ item });
            }
            if (this.keyUpdated && this.value) {
                this.keyUpdated(this.value.toString());
            }
        },
        switchKeyCode(keyCode: number) {
            switch (keyCode) {
                case Key.Tab:
                case Key.Enter:
                    return this.handleEnter();
                case Key.Escape:
                    return this.handleEscape();
                case Key.UpArrow:
                    return this.handleUp();
                case Key.DownArrow:
                    return this.handleDown();
                default:
                    return;
            }
        },
        async applyPlugins() {
            this.focusNone();
            let localData;
            if (this.data.length === 0) {
                if (this.showAllByDefault || (this.filter !== "" && this.filter.length >= this.characterLimit)) {
                    this.displayData = [];
                    this.loading = true;
                    this.isAuthorized = true;
                    try {
                        let promisedData = this.getData(this.filter, this.resultsLimit);
                        let data = await promisedData;
                        if (this._promiseQueue.length > 1) {
                            this._promiseQueue.splice(0, 1);
                            return await new Promise((resolve) => resolve(null));
                        }

                        if (data.length > 0) {
                            this.displayData = data;
                            this.doFocusFirst();
                            this._promiseQueue.splice(0, 1);
                            this.loading = false;
                        } else {
                            this.loading = false;
                            this.displayData = [];
                            throw Error;
                        }
                        this._promiseQueue.push(promisedData);
                        return promisedData;
                    } catch (error) {
                        this.loading = false;
                        this.displayData = [];
                        if (error.status === HttpStatusCodeEnum.Forbidden) {
                            this.isAuthorized = false;
                        }
                        throw error;
                    }
                } else {
                    this.handleEscape();
                    this.loading = false;
                    this.displayData = [];
                    return new Promise((resolve) => resolve(null));
                }
            } else {
                localData = [].concat(this.data);
                if (this.filter && this.filter.length > 0) {
                    localData = this.doFilter(localData);
                }
                if (this.resultsLimit !== undefined && this.resultsLimit !== null && !isNaN(this.resultsLimit)) {
                    localData = localData.slice(0, this.resultsLimit);
                }
                this.displayData = localData;
                this.doFocusFirst();
            }
            return Promise.resolve({});
        },

        onkeydown(e: any) {
            setTimeout(async () => {
                (this.$refs.dropdownMenuRef as HTMLElement)?.classList.add(this._showClass);
                if (this.filter.length >= this.characterLimit) {
                    if (
                        this.$refs.dropdownMenuRef &&
                        (this.$refs.dropdownMenuRef as HTMLElement)?.classList.contains(this._showClass)
                    ) {
                        this.switchKeyCode(e.keyCode);
                        return;
                    }
                }
            }, 150);
        },

        handleEnter() {
            if (
                this.displayData.length === 0 ||
                this._focusedIndex < 0 ||
                (this.$refs.dropdownMenuRef &&
                    !(this.$refs.dropdownMenuRef as HTMLElement).classList.contains(this._showClass))
            ) {
                return;
            }

            this.itemSelected(this.displayData[this._focusedIndex]);
        },
        handleEscape() {
            (this.$refs.dropdownMenuRef as HTMLElement)?.classList.remove(this._showClass);
            this.focusNone();
            this.resetFilter();
        },
        handleUp() {
            if (this._focusedIndex === 0) {
                return;
            }

            this._focusedIndex--;
            this.focusedItem = this.displayData[this._focusedIndex];
        },
        handleDown() {
            if (this._focusedIndex >= this.displayData.length - 1) {
                return;
            }

            this._focusedIndex++;
            this.focusedItem = this.displayData[this._focusedIndex];
        }
    }
});
