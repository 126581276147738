import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { DocumentTypeCategory } from "../../../enums/enums";
import { IUploadHHDocument } from "../../vue-interfaces/i-get-referral";
import { ITypeaheadOptions } from "../../vue-interfaces/i-typeahead";
import { ReferralService } from "../../../services/referral-service";
import { CentralIntakeService } from "../../../services/central-intake.service";

import FormSection from "../../vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        FormSection,
        TypeaheadInput
    },

    data() {
        return {
            dialogReference: this.dialogRef as any,
            options: {} as any,
            fileName: "",
            searchedTypes: [],
            DocumentTypeCategoryEnum: DocumentTypeCategory,
            selectedUploadType: null,
            _referralService: null,
            _centralIntakeService: null,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            fileName: { required }
        };
    },

    created() {
        this._referralService = Container.instance.get(ReferralService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
    },

    async mounted() {
        this.options = this.dialogReference.data;
        this.fileName = this.options.document.DocumentName;
        this.searchedTypes = this.options.searchedTypes;
        this.selectedUploadType = {
            name: this.options.document.DocumentTypeName,
            value: this.options.document.UploadTypeId
        };
    },

    methods: {
        uploadTypeChanged(newValue: ITypeaheadOptions) {
            if (newValue) {
                this.selectedUploadType = newValue;
            }
        },

        cancel() {
            this.dialogReference.close();
        },

        async ok() {
            const valid = await this.v$.$validate();
            if (valid) {
                try {
                    const editDocumentPayload: IUploadHHDocument = {
                        referralId: this.options.referralId,
                        agencyId: this.options.agencyId,
                        uploadTypeId: this.selectedUploadType.value,
                        fileName: this.fileName,
                        application: this.options.application
                    };
                    const res = await this._centralIntakeService.editHHDocument(
                        this.options.document.Id,
                        editDocumentPayload
                    );
                    if (res.isSuccessful) {
                        this.dialogReference.close("success");
                    } else {
                        this.dialogReference.close("error");
                    }
                } catch (error) {
                    this.dialogReference.close("error");
                }
            }
        }
    }
});
