import { defineComponent, computed } from "vue";
import { UserManager } from "oidc-client";
import { Container } from "aurelia-dependency-injection";
import { mapActions } from "pinia";

import config from "../../../../common/config";
import AuthService from "../../../../services/auth.service";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { useLoadingIndicator } from "../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { ToastrService } from "../../../../services/toastr.service";
import { helperTestingAgency } from "../../../../common/vue-helpers/testing-agency-check";
import { AgencyService, IGetOrganization } from "../../../../services/agency.service";
import { IdentityService } from "../../../../services/identity-service";
import { IGetuserDetailResponse, UserService } from "../../../../services/user-service";

import MessagesPreview from "../../../../resources-vue/vue-custom-elements/MessagingService/MessagesPreview/MessagesPreview.vue";
import GetLiveLoginToken from "../../../../resources-vue/vue-dialogs/GetLiveLoginToken/GetLiveLoginToken.vue";

export default defineComponent({
    props: {
        areRolesLoaded: { type: Boolean, default: false },
        showEmployees: {
            type: Boolean,
            default: false
        },
        showPatients: {
            type: Boolean,
            default: false
        }
    },

    components: {
        MessagesPreview
    },

    data() {
        return {
            _userManager: null,
            _authService: null,
            _userService: null,
            _agencyService: null,
            _user: null,
            isLoggedIn: false,
            userFirstName: null,
            _permissionManager: null,
            _toastrService: null,
            displayRoutes: [],
            isSearchExpanded: false,
            showEmployeeSearch: false,
            showPatientSearch: false,
            showAxxessChat: false,
            organizationId: null,
            organizationName: null,
            dialogRef: null,
            isAxxessPlannerHeader: true,
            organizations: [] as IGetOrganization[],
            _identityService: null,
            liveLoginData: null,
            isTestAgency: false,
            hasBetaTogglePermission: false
        };
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },
    async created() {
        this._userManager = Container.instance.get(UserManager);
        this._authService = Container.instance.get(AuthService);
        this._userService = Container.instance.get(UserService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._toastrService = Container.instance.get(ToastrService);
        this._agencyService = Container.instance.get(AgencyService);
        this._identityService = Container.instance.get(IdentityService);

        this.$watch(
            () => this.$route.params,
            () => {
                // react to route changes...
                this.organizationName = window.localStorage.getItem(config.organizationName);
                this.organizationId = window.localStorage.getItem(config.organizationId);
            }
        );

        this.organizationName = window.localStorage.getItem(config.organizationName);
        this.organizationId = window.localStorage.getItem(config.organizationId);
        this.isTestAgency = await helperTestingAgency.checkHasTestingAgency();
    },
    watch: {
        areRolesLoaded() {
            this.areRolesLoadedChanged();
        }
    },
    async mounted() {
        this.loadUser();
        this.areRolesLoadedChanged();
        this.organizations = await this._agencyService.getOrganizations();
        const userbetaInfo: IGetuserDetailResponse = await this._userService.getUserDetails();
        if (userbetaInfo.Data) this.hasBetaTogglePermission = true;

        const employeeInfoViewAcess =
            this._permissionManager.getPermmission(
                ParentPermissionEnum.employeeInformation,
                PermissionActionEnum.canView
            ) || [];
        const hasTestAgency = await helperTestingAgency.checkHasTestingAgency();
        this.showEmployeeSearch = hasTestAgency && employeeInfoViewAcess?.length;
        this.showPatientSearch = hasTestAgency;
        this.showAxxessChat = hasTestAgency;

        this.liveLoginData = await this._identityService.getLiveLoginToken();
    },
    computed: {
        patientsTooltip() {
            return `Click to ${this.showPatients ? "hide" : "view"} your patients.`;
        },
        employeesTooltip() {
            return `Click to ${this.showEmployees ? "hide" : "view"} your employees.`;
        }
    },
    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        navigateToAxxessCentral() {
            this.$router.push("/clinician");
        },

        async navigateToOldCentral() {
            try {
                this.showLoading(true);
                const isBetaOpted = false;
                const updateResponse: IGetuserDetailResponse = await this._userService.updateUserBetaDetails(
                    isBetaOpted
                );
                if (updateResponse.Data) {
                    sessionStorage.setItem("isBetaOpted", isBetaOpted.toString());
                }
                const originURL = window.location.origin;
                window.location.href = originURL;
            } catch (e) {
                this.showLoading(false);
                this._toastrService.error({
                    title: `Error - Switching to Central`,
                    message: `There was an error switching to Central. Please Try again later.`
                });
            }
        },

        navigateToAxxessChat() {
            this.$router.push("/central-messaging/clinicians/conversation");
        },

        async loadUser() {
            this._user = await this._userManager.getUser();
            if (this._user) {
                // this.isLoggedIn = await this._authService.isLoggedIn();
                this.userFirstName = this._user.profile.given_name;
            }
        },
        logout(): void {
            this._authService.logout();
        },

        areRolesLoadedChanged() {
            const isScheduler = this._permissionManager.checkPermission(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canView
            );
            const isAddHospiceInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            );
            const isViewHospiceInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessHospiceFE
            );

            const isEditHospiceInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessHospiceFE
            );

            const isAddHospiceReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            );
            const isViewHospiceReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessHospiceFE
            );

            const isEditHospiceReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessHospiceFE
            );

            const isAddPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            );
            const isViewPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessPalliative
            );

            const isEditPalliativeInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessPalliative
            );

            const isAddPalliativeReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            );
            const isViewPalliativeReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canView,
                ApplicationEnum.AxxessPalliative
            );

            const isEditPalliativeReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AxxessPalliative
            );

            const isAddHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.HomeCare
            );
            const isViewHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.HomeCare
            );

            const isEditHomeCareInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.HomeCare
            );

            const isViewHHInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canView,
                ApplicationEnum.AgencyCore
            );

            const isEditHHInquiry = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AgencyCore
            );
            const isViewHHReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canView,
                ApplicationEnum.AgencyCore
            );

            const isEditHHReferral = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                ApplicationEnum.AgencyCore
            );

            const showCentralIntake =
                isAddHospiceInquiry ||
                isEditHospiceInquiry ||
                isViewHospiceInquiry ||
                isAddHospiceReferral ||
                isEditHospiceReferral ||
                isViewHospiceReferral ||
                isAddPalliativeInquiry ||
                isEditPalliativeInquiry ||
                isViewPalliativeInquiry ||
                isAddPalliativeReferral ||
                isEditPalliativeReferral ||
                isViewPalliativeReferral ||
                isAddHomeCareInquiry ||
                isViewHomeCareInquiry ||
                isEditHomeCareInquiry ||
                isViewHHInquiry ||
                isEditHHInquiry ||
                isViewHHReferral ||
                isEditHHReferral;

            const routes = this.getAllActualRoutes();

            // Filtering routes to display tabs based on roles
            this.displayRoutes = routes?.filter((route: any) => {
                if (!!route.meta?.showIfScheduler) {
                    return isScheduler;
                } else if (!!route.meta?.showCentralIntake) {
                    return showCentralIntake;
                } else {
                    return true;
                }
            });
        },
        getAllActualRoutes() {
            return this.$router.options.routes?.filter((route: any) => route.meta?.centralNav) || [];
        },

        isActivePath(path: string) {
            return this.$route.matched?.some((route) => route.path == path);
        },
        closeNotifications() {
            this._toastrService.clear();
        },
        goToRoute(path: string) {
            this.$router.push(path);
        },
        toggleEmployeesMenus() {
            this.$emit("update:showEmployees", !this.showEmployees);
            if (this.showPatients) {
                this.$emit("update:showPatients", false);
            }
        },

        togglePatientsMenu(): void {
            this.$emit("update:showPatients", !this.showPatients);
            if (this.showEmployees) {
                this.$emit("update:showEmployees", false);
            }
        },

        changeOrganization() {
            let name = this.$route.name.toString();
            this.$router.push(`/axxess-central/organizationLoading/?&name=${name}`);
        },

        async displayLiveLoginToken() {
            let response = this.liveLoginData;
            this.dialogRef = this.$dialog.open(GetLiveLoginToken, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    response
                }
            });
        }
    }
});
