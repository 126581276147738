<template>
    <div v-if="!!dropDownOptions && !!providerEnumMap && !!branchEnumMap" class="multi-select-filter">
        <GroupedMultiSelect
            :groupNameEnumMap="providerEnumMap"
            :optionEnumMap="branchEnumMap"
            :labelText="labelText"
            :searchText="searchText"
            :dropDownOptions="dropDownOptions"
            :selectedOptions="filterBranches"
            :isDisabled="isBranchLoading"
            v-on:updateFilterBranches="updateFilterBranches"
        />
    </div>
</template>

<script src="./BranchDefaultFilter"></script>
