import { defineComponent, PropType } from "vue";

import { IConversationNavOptions } from "../../../vue-interfaces/messaging-service/i-message";
import {
    formatUserInitials,
    formatDisplayUsers,
    formatMessageTime,
    formatDisplayMessage
} from "../../../../common/vue-helpers/modifiers/format-message";
import { markdownToHtml } from "../../../../common/vue-helpers/modifiers/markdown-to-html-modifier";
import { sanitizeMarkup } from "../../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    props: {
        conversations: { type: Array as PropType<IConversationNavOptions[]>, default: null }
    },
    data() {
        return {
            formatUserInitials,
            formatDisplayUsers,
            formatMessageTime,
            formatDisplayMessage,
            markdownToHtml,
            sanitizeMarkup
        };
    },

    methods: {
        handleMenuSelection(conversation: IConversationNavOptions) {
            this.$router.push({
                path: `/central-messaging/clinicians/conversation/${conversation.id}`,
                replace: true,
                force: true
            });
            const updatedConversations = this.conversations.filter((con) => con.id !== conversation.id);
            this.$emit("update:conversations", updatedConversations);
        }
    }
});
