import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum PatientsScheduledFilterEnum {
    AllPatients = 0,
    PatientsWithSchedules = 1,
    PatientsWithoutSchedules = 2
}

export class PatientsScheduledFilterClass extends EnumWithDescription<PatientsScheduledFilterEnum> {
    public readonly enum = PatientsScheduledFilterEnum;
    public readonly enumName: string = "PatientsScheduledFilterEnum";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PatientsScheduledFilterEnum>> = new Map([
        [
            "AllPatients",
            {
                value: PatientsScheduledFilterEnum.AllPatients,
                name: "All Patients"
            }
        ],
        [
            "PatientsWithSchedules",
            {
                value: PatientsScheduledFilterEnum.PatientsWithSchedules,
                name: "Patients with schedules"
            }
        ],
        [
            "PatientsWithoutSchedules",
            {
                value: PatientsScheduledFilterEnum.PatientsWithoutSchedules,
                name: "Patients without schedules"
            }
        ]
    ]);
}

export const PatientsScheduledFilter = new PatientsScheduledFilterClass();
