import { defineComponent, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { PhoneTypeEnum } from "../../../../../enums/enums";
import { IBranchResponse } from "../../../../../resources-vue/vue-interfaces/i-branch";
import { IPatientValidation } from "../../../../../resources-vue/vue-interfaces/i-patient";
import { IAddress, IValidateAddress } from "../../../../../resources-vue/vue-interfaces/i-address";
import { PatientService } from "../../../../../services/patient.service";
import { BranchesService } from "../../../../../services/branches-service";
import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";
import eventBus from "../../../../../utilities/eventBus";
import { formatDateInput, formatPhone } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ValidateAddressHomehealth from "../../../../../resources-vue/vue-dialogs/ValidateAddress/ValidateAddressHomeHealth.vue";
import ReferralAddressForm from "../ReferralAddressForm/ReferralAddressForm.vue";

export default defineComponent({
    components: {
        DatePicker,
        FormSection,
        ReferralAddressForm
    },

    validations() {
        return {
            referral: {
                Referral: {
                    FirstName: { required },
                    LastName: { required },
                    DOB: { required },
                    PhoneHome: { required, minLength: minLength(10) },
                    Gender: { required }
                }
            },
            changeBranchId: { required },
            ssnValue: !this.ssnUnknown ? { required, minLength: minLength(9) } : { required: false }
        };
    },

    props: {
        isEditing: {
            type: Boolean,
            default: false
        },
        isRefer: {
            type: Boolean,
            default: false
        },
        referral: {
            type: HomeHealthReferral,
            default: null
        },
        referralOptions: {
            type: Object,
            default: null
        },
        branchId: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            changeBranchId: this.branchId,
            _branchesService: null,
            _patientsService: null,
            dialogRef: null,
            branches: [] as IBranchResponse[],
            phoneNumberTypeOptionsConfig: [PhoneTypeEnum.Home, PhoneTypeEnum.Mobile, PhoneTypeEnum.Facility],
            isAddressValidationInProgress: false,
            mailingMatchPrimary: null,
            countyData: [] as string[],
            mailingCounties: [] as string[],
            primaryCounties: [] as string[],
            visitCounties: [] as string[],
            addressKeyNames: {
                AddressLine1: "",
                AddressLine2: "",
                AddressCity: "",
                AddressStateCode: "",
                AddressZipCode: "",
                AddressZipCodeFour: "",
                AddressCounty: ""
            },
            addressFields: null,
            addressTypes: null,
            hasVisitAddress: null,
            ssnUnknown: false,
            ssnValue: "",
            ssnDuplicateCache: null,
            formatPhone,
            formatDateInput,
            v$: useVuelidate()
        };
    },

    created() {
        this._branchesService = Container.instance.get(BranchesService);
        this._patientsService = Container.instance.get(PatientService);
        this.ssnDuplicateCache = {};
        this.addressFields = {
            primary: {
                AddressLine1: "AddressLine1",
                AddressLine2: "AddressLine2",
                AddressCity: "AddressCity",
                AddressStateCode: "AddressStateCode",
                AddressZipCode: "AddressZipCode",
                AddressZipCodeFour: "AddressZipCodeFour",
                AddressCounty: "AddressCounty"
            },
            mailing: {
                AddressLine1: "MailingAddressLine1",
                AddressLine2: "MailingAddressLine2",
                AddressCity: "MailingCity",
                AddressStateCode: "MailingStateCode",
                AddressZipCode: "MailingZipCode",
                AddressZipCodeFour: "MailingZipCodeFour",
                AddressCounty: "MailingCounty"
            },
            visit: {
                AddressLine1: "VisitAddressLine1",
                AddressLine2: "VisitAddressLine2",
                AddressCity: "VisitCity",
                AddressStateCode: "VisitStateCode",
                AddressZipCode: "VisitZipCode",
                AddressZipCodeFour: "VisitZipCodeFour",
                AddressCounty: "VisitCounty"
            }
        };
        this.addressTypes = {
            primary: JSON.parse(JSON.stringify(this.addressKeyNames)),
            mailing: JSON.parse(JSON.stringify(this.addressKeyNames)),
            visit: JSON.parse(JSON.stringify(this.addressKeyNames))
        };
        this.bind();
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    watch: {
        hasVisitAddress(newValue: boolean) {
            if (newValue == false) {
                for (let key of Object.keys(this.addressTypes.visit)) {
                    this.addressTypes.visit[key] = null;
                    this.referral.ReferralExtension[this.addressFields.visit[key]] = null;
                }
            }
        },

        ssnUnknown(value: boolean) {
            this.ssnValue = value ? null : "";
        },

        ssnValue(newValue: string) {
            this.referral.Referral.SSN = newValue;
        },

        changeBranchId(newValue: string) {
            this.$emit("changeBranchId", newValue);
        }
    },

    methods: {
        async bind() {
            if (this.isEditing || this.isRefer) {
                this.setReferralValues();
            }
            await this.initOptions();
        },

        setReferralValues() {
            if ([null, ""].includes(this.referral.Referral.SSN)) {
                this.ssnUnknown = true;
                this.referral.Referral.SSN = null;
            } else {
                this.ssnValue = this.referral.Referral.SSN;
            }

            Object.keys(this.addressFields).forEach((key1: string) => {
                let refType = key1 == "primary" ? this.referral.Referral : this.referral.ReferralExtension;
                let hasVisitAddress = false;
                Object.keys(this.addressFields[key1 as keyof object]).forEach((key2: string) => {
                    this.addressTypes[key1][key2] = refType[this.addressFields[key1][key2]];
                    if (key1 == "visit" && ![null, ""].includes(this.addressTypes[key1][key2])) {
                        hasVisitAddress = true;
                    }
                });

                if (hasVisitAddress) {
                    this.hasVisitAddress = true;
                }
            });
            this.primaryCounties = this.referral.CountyOptions[this.referral.Referral.AddressZipCode] || [];
            this.mailingCounties = this.referral.CountyOptions[this.referral.ReferralExtension.MailingZipCode] || [];
            if (this.referral.ReferralExtension.SameAsPhysicalAddress) {
                this.mailingMatchPrimary = true;
                this.mailingCounties = this.primaryCounties;
                this.addressTypes.mailing.AddressCounty = this.addressTypes.primary.AddressCounty;
            }
        },

        async initOptions() {
            const branches = await this._branchesService.getAllBranches();
            this.branches = branches.filter((branch: any) => branch.accountId == this.referral.accountId);
        },

        addressFieldsChanged(type: string, fieldNames: string[], index: string) {
            if (!type || !this.addressFields[type]) return;
            let refType = type == "primary" ? this.referral.Referral : this.referral.ReferralExtension;
            for (let name of fieldNames) {
                refType[this.addressFields[type][name]] = this.addressTypes[type][name];
            }
            if (type == "primary") {
                eventBus.emit("addressChangedforPrimary", refType);
            }
            if (["primary", "mailing"].includes(type)) {
                this.mailingMatchPrimary = false;
                this.referral.ReferralExtension.SameAsPhysicalAddress = false;
            }
        },

        async validateSSN(currentValue: string) {
            if (!(currentValue in this.ssnDuplicateCache)) {
                const payload: IPatientValidation = {
                    AgencyId: this.referral.agencyId,
                    Application: this.referral.application,
                    DuplicateCheck: {
                        SSN: currentValue
                    }
                };
                const data = await this._patientsService.checkDuplicates(payload);
                this.ssnDuplicateCache[currentValue] = !data.isDuplicate;
                return !data.isDuplicate;
            }
            return this.ssnDuplicateCache[currentValue];
        },

        hasVisitAddressChanged(newValue: boolean) {
            if (newValue == false) {
                for (let key of Object.keys(this.addressTypes.visit)) {
                    this.addressTypes.visit[key] = null;
                    this.referral.ReferralExtension[this.addressFields.visit[key]] = null;
                }
            }
        },

        isMailingSameAsPrimaryChanged() {
            if (this.mailingMatchPrimary == null) return;
            if (this.mailingMatchPrimary) {
                for (let key of Object.keys(this.addressTypes.visit)) {
                    this.addressTypes.mailing[key] = this.addressTypes.primary[key];
                    this.referral.ReferralExtension[this.addressFields.mailing[key]] =
                        this.referral.Referral[this.addressFields.primary[key]];
                }
                this.referral.ReferralExtension.SameAsPhysicalAddress = true;
                this.mailingCounties = this.referral.CountyOptions[this.referral.Referral.AddressZipCode] || [];
            } else {
                for (let key of Object.keys(this.addressTypes.visit)) {
                    this.addressTypes.mailing[key] = null;
                    this.referral.ReferralExtension[this.addressFields.mailing[key]] = null;
                }
                this.referral.ReferralExtension.SameAsPhysicalAddress = false;
                this.mailingCounties = [];
            }
        },

        updatecountyData(value: any) {
            if (value.addressType == "primary") {
                this.primaryCounties = value.county;
            }
            if (value.addressType == "mailing") {
                this.mailingCounties = value.county;
            }
            if (value.addressType == "visit") {
                this.visitCounties = value.county;
            }
        },

        onDateChange(date: any, name: string) {
            if (name === "Date-Of-Birth") this.referral.Referral.DOB = date;
        },

        setPhoneHome(value: any) {
            if (value.length >= 10) value = value.replace(/[^\d]/g, "");
            this.referral.Referral.PhoneHome = value.toString();
        },

        setPhoneMobile(value: any) {
            this.referral.Referral.PhoneMobile = value.toString();
        },

        validateAddress() {
            const model: IValidateAddress = {
                originalAddress: {
                    addressLine1: this.referral.Referral.AddressLine1,
                    addressLine2: this.referral.Referral.AddressLine2,
                    city: this.referral.Referral.AddressCity,
                    state: this.referral.Referral.AddressStateCode,
                    zipCode: this.referral.Referral.AddressZipCode,
                    county: this.referral.Referral.AddressCounty,
                    country: this.referral.Referral.country,
                    latitude: this.referral.Referral.latitude,
                    longitude: this.referral.Referral.longitude,
                    mailingAddressLine1: this.referral.ReferralExtension.MailingAddressLine1,
                    mailingAddressLine2: this.referral.ReferralExtension.MailingAddressLine2,
                    mailingCity: this.referral.ReferralExtension.MailingCity,
                    mailingState: this.referral.ReferralExtension.MailingStateCode,
                    mailingZipCode: this.referral.ReferralExtension.MailingZipCode,
                    mailingCounty: this.referral.ReferralExtension.MailingCounty,
                    mailingCountry: undefined,
                    mailingLatitude: undefined,
                    mailingLongitude: undefined,
                    visitAddressLine1: this.referral.ReferralExtension.VisitAddressLine1,
                    visitAddressLine2: this.referral.ReferralExtension.VisitAddressLine2,
                    visitCity: this.referral.ReferralExtension.VisitCity,
                    visitState: this.referral.ReferralExtension.VisitStateCode,
                    visitZipCode: this.referral.ReferralExtension.VisitZipCode,
                    visitCounty: this.referral.ReferralExtension.VisitCounty,
                    visitCountry: undefined,
                    visitLatitude: undefined,
                    visitLongitude: undefined
                },
                updatedAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    county: "",
                    country: "",
                    latitude: null,
                    longitude: null,
                    mailingAddressLine1: "",
                    mailingAddressLine2: "",
                    mailingCity: "",
                    mailingState: "",
                    mailingZipCode: "",
                    mailingCounty: "",
                    mailingLongitude: null,
                    mailingLatitude: null,
                    visitAddressLine1: "",
                    visitAddressLine2: "",
                    visitCity: "",
                    visitState: "",
                    visitZipCode: "",
                    visitCounty: "",
                    visitLongitude: null,
                    visitLatitude: null,
                    primaryUpdate: false,
                    mailingUpdate: false,
                    visitUpdate: false
                }
            };
            this.isAddressValidationInProgress = true;
            this.dialogRef = this.$dialog.open(ValidateAddressHomehealth, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    model
                },
                onClose: (response) => {
                    const closeResult = response.data;
                    let updatedAddress: IAddress;
                    if (response.data !== "cancelled") {
                        updatedAddress = closeResult.updatedAddress;
                        if (updatedAddress.primaryUpdate) {
                            this.referral.Referral.AddressLine1 = this.addressTypes.primary.AddressLine1 =
                                updatedAddress.addressLine1;
                            this.referral.Referral.AddressLine2 = this.addressTypes.primary.AddressLine2 =
                                updatedAddress.addressLine2;
                            this.referral.Referral.AddressCity = this.addressTypes.primary.AddressCity =
                                updatedAddress.city;
                            this.referral.Referral.AddressStateCode = updatedAddress.state;
                            this.referral.Referral.AddressZipCode = this.addressTypes.primary.AddressZipCode =
                                updatedAddress.zipCode;
                            this.referral.Referral.AddressCounty = this.addressTypes.primary.AddressCounty =
                                updatedAddress.county;
                            this.referral.Referral.latitude = updatedAddress.latitude;
                            this.referral.Referral.longitude = updatedAddress.longitude;
                            if (updatedAddress.country) {
                                this.referral.Referral.country = updatedAddress.country;
                            }
                        }
                        if (updatedAddress.visitUpdate) {
                            this.referral.ReferralExtension.VisitAddressLine1 = this.addressTypes.visit.AddressLine1 =
                                updatedAddress.visitAddressLine1;
                            this.referral.ReferralExtension.VisitAddressLine2 = this.addressTypes.visit.AddressLine2 =
                                updatedAddress.visitAddressLine2;
                            this.referral.ReferralExtension.VisitCity = this.addressTypes.visit.AddressCity =
                                updatedAddress.visitCity;
                            this.referral.ReferralExtension.VisitStateCode = this.addressTypes.visit.AddressStateCode =
                                updatedAddress.visitState;
                            this.referral.ReferralExtension.VisitZipCode = this.addressTypes.visit.AddressZipCode =
                                updatedAddress.visitZipCode;
                            this.referral.ReferralExtension.VisitCounty = this.addressTypes.visit.AddressCounty =
                                updatedAddress.visitCounty;
                        }
                        if (updatedAddress.mailingUpdate) {
                            this.referral.ReferralExtension.MailingAddressLine1 =
                                this.addressTypes.mailing.AddressLine1 = updatedAddress.mailingAddressLine1;
                            this.referral.ReferralExtension.MailingAddressLine2 =
                                this.addressTypes.mailing.AddressLine2 = updatedAddress.mailingAddressLine2;
                            this.referral.ReferralExtension.MailingCity = this.addressTypes.mailing.AddressCity =
                                updatedAddress.mailingCity;
                            this.referral.ReferralExtension.MailingStateCode =
                                this.addressTypes.mailing.AddressStateCode = updatedAddress.mailingState;
                            this.referral.ReferralExtension.MailingZipCode = this.addressTypes.mailing.AddressZipCode =
                                updatedAddress.mailingZipCode;
                            this.referral.ReferralExtension.MailingCounty = this.addressTypes.mailing.AddressCounty =
                                updatedAddress.mailingCounty;
                        }
                        if (updatedAddress.mailingUpdate !== updatedAddress.primaryUpdate) {
                            this.mailingMatchPrimary = false;
                            this.referral.ReferralExtension.SameAsPhysicalAddress = false;
                        }
                        if (updatedAddress.primaryUpdate) {
                            this.referral.Referral.EmergencyContact.SameAsPatientAddress = false;
                            this.referral.ReferralExtension.AdditionalEmergencyContact.forEach((item) => {
                                item.SameAsPatientAddress = false;
                            });
                        }
                    }
                }
            });
            this.isAddressValidationInProgress = false;
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
        }
    }
});
