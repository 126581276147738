import { defineComponent } from "vue";

import EmptyTextHandler from "../../vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";

import { formatDate, formatingDateOrTime } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    components: {
        "empty-text-handler": EmptyTextHandler
    },
    props: {
        detail: {
            type: Object
        },
        isAddRequested: {
            type: Boolean
        },
        id: {
            type: String
        },
        snapshotView: {
            type: Boolean,
            default: false
        },
        isNewTerminal: {
            type: Boolean,
            default: false
        },
        cancelSaveNewDetail: {
            type: Function
        },
        saveNewDetail: {
            type: Function
        }
    },
    data() {
        return {
            isEditRequested: false,
            columns: 0,
            originalDetail: null,
            selectedDetail: null,
            formatDate,
            formatingDateOrTime
        };
    },
    mounted() {
        this.originalDetail = { ...this.detail };
        this.columns = this.snapshotView ? 10 : 9;
    },
    methods: {
        openEditDetail() {
            if (this.detail.id !== null) {
                this.selectedDetail = {
                    ...this.detail
                };
            }
            this.isEditRequested = true;
        },

        cancelEditDetail() {
            this.isEditRequested = false;
            this.$emit("detailCanceled", { ...this.originalDetail });
            this.selectedDetail = null;
        },

        async saveEditDetail() {
            this.isEditRequested = false;
            this.originalDetail = { ...this.detail };
            this.selectedDetail = null;
        },

        async save() {
            this.$emit("addRequestedChanged", false);
            this.originalDetail = { ...this.detail };
            this.selectedDetail = null;
            this.saveNewDetail();
        }
    }
});
