export const AdmissionSources = {
    LongTermNursingFacility: {
        label: "1 - Long-term nursing facility (NF)",
        value: 1
    },
    SkilledNursingFacility: {
        label: "2 - Skilled nursing facility (SNF/TCU)",
        value: 2
    },
    ShortStayAcuteHospital: {
        label: "3 - Short-stay acute hospital (IPPS)",
        value: 4
    },
    LongTermCareHospital: {
        label: "4 - Long-term care hospital (LTCH)",
        value: 8
    },
    InpatientRehabilitationHospital: {
        label: "5 - Inpatient rehabilitation hospital or unit (IRF)",
        value: 16
    },
    PsychiatricHospital: {
        label: "6 - Psychiatric hospital or unit",
        value: 32
    },
    Other: {
        label: "7 - Other:",
        value: 64
    },
    NotApplicable: {
        label: "N/A - Patient was not discharged from an inpatient facility",
        value: 128
    }
};

export const episodeTiming = [
    {
        label: "1 - Early",
        value: 1
    },
    {
        label: "2 - Later",
        value: 2
    },
    {
        label: "UK - Unknown",
        value: 3
    },
    {
        label: "N/A - Not Applicable: No Medicare case-mix group to be defined by this assessment",
        value: 4
    }
];
