<template>
    <div class="call-popup-container" v-if="notification.chatType === NotificationTypeEnum.Call">
        <div class="call-popup">
            <div
                class="close-btn-container d-flex align-items-center justify-content-center"
                @click="closeNotificationPopup"
            >
                <img src="../../../../assets/images/Notification/call-noti-close-icon.svg" class="close-icon" />
            </div>
            <div class="call-popup-content d-flex align-items-center justify-content-center flex-column">
                <span class="caller-name">{{ notification.senderName }}</span>
                <p class="call-subtitlte">Calling...</p>
                <div class="d-flex justify-content-center align-items-center call-pad">
                    <img src="../../../../assets/images/Notification/call-noti-callpad.svg" class="callpad-icon" />
                </div>
            </div>
            <div class="call-popup-footer">
                <div
                    class="call-btn-wrapper call-btn d-flex align-items-center justify-content-center"
                    @click="handleCallDecline"
                >
                    <div
                        class="call-icon-container call-icon-container--decline d-flex justify-content-center align-items-center mr-2"
                    >
                        <img src="../../../../assets/images/Notification/call-decline-icon.svg" class="call-btn-icon" />
                    </div>
                    <span class="call-message">Decline</span>
                </div>
                <div class="divider"></div>
                <div
                    class="call-btn-wrapper call-btn d-flex align-items-center justify-content-center"
                    @click="handleCallAccept"
                >
                    <div
                        class="call-icon-container call-icon-container--accept d-flex justify-content-center align-items-center mr-2"
                    >
                        <img
                            src="../../../../assets/images/Notification/call-accepted-icon.svg"
                            class="call-btn-icon"
                        />
                    </div>
                    <span class="call-message">Join</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { NotificationTypeEnum } from "../../../../enums/NotificationTypeEnum";

const props = defineProps({
    notification: {
        type: Object,
        required: true
    }
});

const emits = defineEmits(["close-notification", "decline-call", "accept-call"]);

const closeNotificationPopup = () => {
    emits("close-notification");
};

const handleCallDecline = () => {
    emits("decline-call");
};

const handleCallAccept = () => {
    emits("accept-call", props.notification);
}
</script>

<style src="./call-notification.scss"></style>