import { HttpClient } from "aurelia-fetch-client";
import { autoinject, buildQueryString } from "aurelia-framework";

import type { IBlobResponse } from "../resources-vue/vue-interfaces/i-hospice-client";
import { HttpStatusCodeEnum } from "./http-status-code-enum";

@autoinject
export class FetchClient {
    private _client: HttpClient;

    public constructor(client: HttpClient) {
        this._client = client;
    }

    public build<TResponse>(path: string): FetchClientChain {
        return new FetchClientChain(path, this._client);
    }
}

export class FetchClientChain {
    private _path: string;
    private _init: RequestInit;
    private _client: HttpClient;

    public constructor(path: string, client: HttpClient) {
        this._path = path;
        this._init = {
            method: "GET"
        };
        this._client = client;
    }

    public useQueryString<TParam>(param: TParam): FetchClientChain {
        if (param == null) {
            return this;
        }

        let concat = this._path.indexOf("?") != -1;
        let anchorChar = concat ? "&" : "?";

        let queryString = buildQueryString(param);
        this._path += anchorChar + queryString;
        return this;
    }

    public useMethod(method: string): FetchClientChain {
        this._init.method = method;
        return this;
    }

    public useMode(mode: RequestMode): FetchClientChain {
        this._init.mode = mode;
        return this;
    }

    public useCredentials(credentials: RequestCredentials): FetchClientChain {
        this._init.credentials = credentials;
        return this;
    }

    public useBody<T>(body: T): FetchClientChain {
        let json = JSON.stringify(body);
        this._init.body = json;
        return this;
    }

    public useAuthorization(token: string): FetchClientChain {
        this._init.headers = { Authorization: token };
        return this;
    }

    public useHeaders(headers: HeadersInit): FetchClientChain {
        this._init.headers = this._init.headers ? this._init.headers : {};
        Object.assign(this._init.headers, headers);
        return this;
    }

    public useFormData(body: FormData): FetchClientChain {
        this._init.body = body;
        return this;
    }

    public async fetch<TResponse>(): Promise<TResponse> {
        let response = await this._client.fetch(this._path, this._init);
        if (!response.ok) {
            throw new FetchClientHttpError(response.status, response.statusText, response);
        }

        let json = await response.json();
        return json as TResponse;
    }

    public async fetchBlob(): Promise<IBlobResponse> {
        let response: Response = await this._client.fetch(this._path, this._init);
        if (!response.ok) {
            throw new FetchClientHttpError(response.status, response.statusText, response);
        }
        let blob = await response.blob();
        return {
            headers: response.headers,
            body: blob
        };
    }

    public async fetchNoContent(): Promise<void> {
        let response = await this._client.fetch(this._path, this._init);
        if (response.status !== HttpStatusCodeEnum.NoContent) {
            throw new FetchClientHttpError(
                response.status,
                `Expected '204 : No Content' but received '${response.status} : ${response.statusText}' instead.`,
                response
            );
        }
    }
}

export interface IFetchClientOptions {
    path: string;
    method?: string;
    body?: any;
    query?: any;
}

export class FetchClientHttpError {
    public statusMessage: string;
    public status: number;
    public response: Response;

    public constructor(status: number, statusMessage: string, response: Response) {
        this.status = status;
        this.statusMessage = statusMessage;
        this.response = response;
    }
}
