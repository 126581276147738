import { defineComponent, PropType } from "vue";
import "custom-event-polyfill";
import "daterangepicker";
import $ from "jquery";

import "./../../../../node_modules/daterangepicker/daterangepicker.css";

import type { IDateRangePickerOptions } from "../../vue-interfaces/i-date-range-picker";

export default defineComponent({
    props: {
        options: { type: Object as PropType<IDateRangePickerOptions>, default: null }
    },
    data() {
        return {
            pickerFields: null,
            _dateRangePicker: null
        };
    },
    mounted() {
        this.pickerFields = $('input[name="daterange"]');
        if (this.pickerFields == null || this.pickerFields.length != 1) {
            console.warn(
                "The date-range-picker custom element must contain one child of type input[type=text]. See http://www.daterangepicker.com/."
            );
        }
        this.initDateRangePicker();
    },

    methods: {
        initDateRangePicker() {
            let options = {
                autoUpdateInput: false,
                locale: {
                    cancelLabel: "Clear"
                }
            };
            if (this.options != null) {
                options = Object.assign(options, this.options);
            }
            this._dateRangePicker = this.pickerFields.daterangepicker(options);
            this._dateRangePicker.on("apply.daterangepicker", (event: any, picker: any) => {
                this.pickerFields.val(
                    picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY")
                );
                let changeEvent = new CustomEvent("change", {
                    detail: {},
                    bubbles: true
                });
                // Target = input(s), input(s) was modified outside of dom events, need to notify the binder.
                // Unfortunately, we must find the inputs here since the target is not always the input.
                let inputs = jQuery("input", event.target.parentElement);
                for (let input of inputs.toArray()) {
                    input.dispatchEvent(changeEvent);
                }
            });
            this._dateRangePicker.on("cancel.daterangepicker", (ev: any, picker: any) => {
                jQuery(this.pickerFields).val("");
            });
        }
    }
});
