<template>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="transferring-hospice"> Transferring Hospice <span class="text-danger ml-1">*</span> </label>
        <input
            class="form-control"
            type="text"
            name="transferring-hospice"
            placeholder="Enter Transferring Hospice"
            v-model="patient.priorHospice.name"
            @blur="v$.patient.priorHospice.name.$touch"
            :class="`${!v$.patient.priorHospice.name.$error ? '' : 'field-invalid'}`"
        />
        <span v-if="v$.patient.priorHospice.name.$error" class="field-invalid">Transferrig Hospice is required.</span>
    </div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="transferring-hospice-admission-date"> Transferring Hospice Admission Date </label>
        <DatePicker :getDate="handleDate">
            <div class="input-group date datepickerInput">
                <input
                    name="admissionDate"
                    type="text"
                    class="form-control"
                    placeholder="MM/DD/YYYY"
                    :value="formatDateInput(patient.priorHospice.admissionDate)"
                    autocomplete="off"
                    date-format-only
                />
                <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                        <i class="fa fa-calendar"></i>
                    </div>
                </div>
            </div>
        </DatePicker>
    </div>
    <div class="form-group col-12 col-lg-4">
        <label for="transferring-hospice-transfer-date"> Transfer Date </label>
        <DatePicker :getDate="handleDate">
            <div class="input-group date datepickerInput">
                <input
                    name="transferDate"
                    type="text"
                    class="form-control"
                    placeholder="MM/DD/YYYY"
                    :value="formatDateInput(patient.priorHospice.transferDate)"
                    @change="handleDateInputChange"
                    autocomplete="off"
                    date-format-only
                    :disabled="isReadOnly"
                />
                <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                        <i class="fa fa-calendar"></i>
                    </div>
                </div>
            </div>
        </DatePicker>
    </div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="benefit-period-number"> Benefit Period Number <span class="text-danger ml-1">*</span> </label>
        <input
            :readonly="isReadOnly"
            class="form-control"
            type="text"
            name="benefit-period-number"
            placeholder="Enter Benefit Period Number"
            v-model="patient.benefitPeriodNumber"
            v-number-only
            @blur="v$.patient.benefitPeriodNumber.$touch"
            :class="`${!v$.patient.benefitPeriodNumber.$error ? '' : 'field-invalid'}`"
        />
        <span v-if="v$.patient.benefitPeriodNumber.$error" class="field-invalid">{{
            v$.patient.benefitPeriodNumber.$errors[0].$message
        }}</span>
    </div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="transfer-benefit-period-start-date">
            Transfer Benefit Period Start Date <span class="text-danger ml-1">*</span>
        </label>
        <DatePicker :getDate="handleDateChange">
            <div class="input-group date datepickerInput">
                <input
                    name="benefitPeriodStartDate"
                    type="text"
                    class="form-control"
                    placeholder="MM/DD/YYYY"
                    :value="formatDateInput(benefitPeriodStartDate)"
                    @change="handleDateInputChange"
                    autocomplete="off"
                    date-format-only
                    :disabled="isReadOnly"
                    @blur="v$.patient.priorHospice.benefitPeriodStartDate.$touch"
                    :class="`${!v$.patient.priorHospice.benefitPeriodStartDate.$error ? '' : 'field-invalid'}`"
                />
                <div class="input-group-addon input-group-append">
                    <div class="input-group-text">
                        <i class="fa fa-calendar"></i>
                    </div>
                </div>
                <span v-if="v$.patient.priorHospice.name.$error" class="field-invalid"
                    >Benefit Period Date is required.</span
                >
            </div>
        </DatePicker>
    </div>
    <div class="form-group col-12 col-lg-4 d-flex flex-column">
        <label for="transfer-benefit-period-end-date"> Transfer Benefit Period End Date </label>
        <span class="font-size-base mt-1 ml-1">
            <EmptyTextHandler
                :text="`${benefitPeriodEndDate ? formatDate(benefitPeriodEndDate) : ''}`"
                exception-message="Waiting for Benefit Period Number & Start Date"
            />
        </span>
    </div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="days-used-in-current-benefit-period"> Days Used In Current Benefit Period </label>
        <input
            class="form-control"
            type="text"
            name="days-used-in-current-benefit-period"
            placeholder="Enter Days Used In Current Benefit Period"
            v-model="patient.priorHospice.daysUsed"
            v-number-only
        />
    </div>
    <div class="form-group col-12 col-lg-4">
        <div class="custom-control custom-checkbox custom-control-inline mt-4 pt-2">
            <input
                type="checkbox"
                id="FaceToFaceObtained"
                class="custom-control-input"
                :checked="patient.priorHospice.faceToFaceObtained"
                @change="handleFaceToFace()"
            />
            <label class="custom-control-label" for="FaceToFaceObtained">
                <span class="mr-1">Face to Face Obtained</span>
            </label>
        </div>
    </div>
    <div class="w-100"></div>
    <!-- Contact Info -->
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="contact-last-name"> Transferring Hospice Phone Number </label>
        <PhoneNumber
            :phone-type-options="homePhoneNumberOptions"
            v-model:value="patient.priorHospice.contactPersonPhoneNumber.number"
            v-model:extension="patient.priorHospice.contactPersonPhoneNumber.extension"
            v-model:type="patient.priorHospice.contactPersonPhoneNumber.type"
            :enable-country="true"
            v-model:country="patient.priorHospice.contactPersonPhoneNumber.country"
            v-number-only
        />
    </div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="contact-first-name"> Contact First Name </label>
        <input
            class="form-control"
            type="text"
            name="contact-first-name"
            placeholder="Enter Contact First Name"
            v-model="patient.priorHospice.contactPersonFirstName"
        />
    </div>
    <div class="form-group col-12 col-lg-4">
        <label for="contact-last-name"> Contact Last Name </label>
        <input
            class="form-control"
            type="text"
            name="contact-last-name"
            placeholder="Enter Contact Last Name"
            v-model="patient.priorHospice.contactPersonLastName"
        />
    </div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="referral-address-line-1"> Transferring Hospice Address Line 1 </label>
        <PlacesAutocomplete
            v-model:street="patient.priorHospice.transferHospiceAddress.addressLine1"
            v-model:city="patient.priorHospice.transferHospiceAddress.city"
            v-model:state="patient.priorHospice.transferHospiceAddress.state"
            v-model:zip="patient.priorHospice.transferHospiceAddress.zipCode"
            v-model:country="patient.priorHospice.transferHospiceAddress.country"
        />
    </div>
    <div class="form-group col-12 col-lg-4">
        <label for="referral-address-line-2"> Address Line 2 </label>
        <input
            class="form-control"
            maxlength="50"
            type="text"
            placeholder="Enter Address Line 2"
            v-model="patient.priorHospice.transferHospiceAddress.addressLine2"
        />
    </div>
    <div class="w-100"></div>
    <div class="form-group col-12 col-lg-4 pr-lg-2">
        <label for="referral-city"> City </label>
        <input
            class="form-control"
            maxlength="50"
            type="text"
            placeholder="Enter City"
            v-model="patient.priorHospice.transferHospiceAddress.city"
        />
    </div>
    <div class="form-group col-12 col-lg-2 pr-lg-2">
        <label for="referral-state"> State </label>
        <StateTypeahead
            :selected-state="patient.priorHospice.transferHospiceAddress.primaryState"
            v-on:updateSelectedState="updateSelectedState"
            :prefill-state="patient.priorHospice.transferHospiceAddress.state"
            v-on:updateprefillState="updateprefillState"
        >
        </StateTypeahead>
    </div>
    <div class="form-group col-12 col-lg-2">
        <label for="referral-zip"> ZIP </label>
        <input
            class="form-control"
            maxlength="10"
            type="text"
            placeholder="Enter Zip"
            :value="formatZipCode(patient.priorHospice.transferHospiceAddress.zipCode)"
            v-number-only
        />
    </div>
</template>

<script src="./HospiceTransferForm.ts"></script>
