import { defineComponent, PropType } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";

import type { IEmployeeList, IReassignVisitDetails } from "../../../vue-interfaces/i-reassign-visit";
import type {
    IAvailableTaskItems,
    IPayersByDateResponseData,
    ICurrentBenefitPeriodData
} from "../../../vue-interfaces/i-patient-task-hos-pal";
import type { IDateRangePickerOptions } from "../../../vue-interfaces/i-date-range-picker";
import type { ITypeaheadOptions } from "../../../vue-interfaces/i-typeahead";
import { IEnumResponse } from "../../../vue-interfaces/i-enum";
import { PatientTask } from "../../../vue-models/patient-task-hos-pal";
import { TaskService } from "../../../../services/task.service";
import { ToastrService } from "../../../../services/toastr.service";
import { PatientService } from "../../../../services/patient.service";
import { PayerTypes, TaskRepeat, PayerTypesEnum, ApplicationEnum, VisitTypeEnum } from "../../../../enums/enums";
import { enumToMap } from "../../../../common/vue-helpers/modifiers/enum-modifiers";
import { formatDate, formatDateInput } from "./../../../../common/vue-helpers/modifiers/value-modifier";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import FormatName from "../../../vue-custom-elements/FormatName/FormatName.vue";
import DateRangePicker from "../../../vue-custom-elements/DateRangePicker/DateRangePicker.vue";
import MultiDatePicker from "../../../vue-custom-elements/MultiDatePicker/MultiDatePicker.vue";
import TypeaheadInput from "../../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import TimePicker from "../../../vue-custom-elements/TimePicker/TimePicker.vue";
import DatePicker from "../../../vue-custom-elements/DatePicker/DatePicker.vue";
import FrequencyCheck from "../../../vue-elements/FrequencyCheck/FrequencyCheck.vue";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export default defineComponent({
    components: {
        FormSection,
        FormatName,
        DateRangePicker,
        MultiDatePicker,
        TypeaheadInput,
        TimePicker,
        FrequencyCheck,
        DatePicker
    },
    props: {
        visitDetails: { type: Object as PropType<IReassignVisitDetails>, default: null },
        isLoading: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        showAllByDefault: { type: Boolean, default: true },
        ok: { type: Function, default: null },
        cancel: { type: Function, default: null }
    },
    validations() {
        return {
            newTaskSingleDate: {
                required: requiredIf(() => {
                    return this.newTaskRepeat == 0;
                })
            },
            newTaskDateRange: {
                required: requiredIf(() => {
                    return this.newTaskRepeat == 1 || this.newTaskRepeat == 2 || this.newTaskRepeat == 4;
                })
            },
            newTaskFlexDates: {
                required: requiredIf(() => {
                    return this.newTaskRepeat == 3;
                })
            },
            taskList: { required },
            newTask: {
                patientPayerId: { required }
            },
            shiftEndTime: {
                required: requiredIf(() => {
                    return !!this.shiftStartTime || !!this.shiftStartTimePal;
                })
            }
        };
    },
    data() {
        return {
            newTaskRepeat: 0 as number,
            shiftLength: 0 as number,
            newTaskSingleDate: "" as string,
            newTaskDateRange: "" as string,
            newTaskFlexDates: "" as string,
            isDateSelected: false,
            isPatientPending: false,
            isTaskSelected: false,
            isOutsideFrequency: false,
            newTask: new PatientTask() as PatientTask,
            patientPayers: [] as IPayersByDateResponseData[],
            payerTypes: [] as IEnumResponse[],
            employeeList: [] as IEmployeeList[],
            visitTypeEnum: VisitTypeEnum as typeof VisitTypeEnum,
            payerTypeTest: Array.from(PayerTypes.enumWithDescriptions.values()),
            tasks: TaskRepeat,
            newTaskRangePickerOptions: {
                dateLimit: {
                    days: 90
                }
            } as IDateRangePickerOptions,
            scheduleEmployeeId: {} as ITypeaheadOptions,
            taskList: {} as IAvailableTaskItems,
            availableTask: null as IAvailableTaskItems[],
            patient: null as ICurrentBenefitPeriodData,
            taskListItems: null as IAvailableTaskItems,
            visitType: null as VisitTypeEnum,
            isPalliativeSolution: null as boolean,
            isFrequencyWarningRequested: null as boolean,
            shiftStartTime: null as string,
            shiftStartTimePal: null as string,
            shiftEndTime: null as string,
            employeeName: null as string,
            saveType: null as string,
            _taskService: null,
            _toastrService: null,
            _patientService: null,
            v$: useVuelidate(),
            enumToMap,
            formatDate,
            formatDateInput
        };
    },
    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._patientService = Container.instance.get(PatientService);
    },
    mounted() {
        if (this.visitDetails.Application == ApplicationEnum.AxxessHospice) {
            this.visitDetails.Application = ApplicationEnum.AxxessHospiceFE;
        }
        if (this.visitDetails.Application == ApplicationEnum.AxxessPalliative) {
            this.isPalliativeSolution = true;
        }
        if (!this.isPalliativeSolution) {
            this.getCurrentBenefitPeriod();
        }
        Object.assign(this.visitDetails, {});
    },
    computed: {
        newTaskStartDates() {
            return this.newTask?.startDates?.filter((startDate) => moment(startDate, "MM/DD/YYYY", true).isValid());
        }
    },
    watch: {
        newTaskRepeat() {
            this.newTaskSingleDate = "";
            this.newTaskDateRange = "";
            this.newTaskFlexDates = "";
            this.newTask.startDates = [];
            this.newTask.endDates = [];
        },
        newTaskSingleDate(newValue: string) {
            this.newTask.startDates = [];
            if (!!newValue) {
                this.newTask.startDates.push(newValue);
            }
            if (this.taskDateChanged) {
                this.taskDateChanged();
            }
        },
        shiftStartTime() {
            if (
                this.shiftLength > 0 &&
                this.newTask.startDates &&
                this.newTask.startDates.length > 0 &&
                !this.isPalliativeSolution
            ) {
                this.newTask.startDates = this.newTask.startDates.map((item) => {
                    const startDate = new Date(`${item} ${this.shiftStartTime}`).toISOString();
                    return moment(startDate).format("MM/DD/YYYY HH:mm");
                });
                this.newTask.endDates = [];
                this.shiftEndTime = moment(this.shiftStartTime, "hh:mm A")
                    .add(this.shiftLength, "hours")
                    .format("hh:mm A");
                this.newTask.startDates.forEach((item) => {
                    const endDate = moment(item).add(this.shiftLength, "hours").format("MM/DD/YYYY HH:mm");
                    this.newTask.endDates.push(endDate);
                });
            }
        },
        shiftEndTime() {
            if (!this.shiftEndTime) {
                this.newTask.endDates = this.newTask.endDates.map((endDate) => moment(endDate).format("MM/DD/YYYY"));
                return;
            }
            let scheduledDates = this.newTask.startDates.map((startDate) => moment(startDate).format("MM/DD/YYYY"));
            if (scheduledDates && scheduledDates.length > 0 && this.isPalliativeSolution) {
                this.newTask.endDates = [];
                this.newTask.endDates = scheduledDates.map((item) => {
                    let startDate = new Date(`${item} ${this.shiftEndTime}`).toISOString();
                    return moment(startDate).format("MM/DD/YYYY HH:mm");
                });
            }
        },
        shiftStartTimePal() {
            if (!this.shiftStartTimePal) {
                this.newTask.startDates = this.newTask.startDates.map((startDate) =>
                    moment(startDate).format("MM/DD/YYYY")
                );
                this.shiftEndTime = "";
                this.newTask.endDates = [];
                return;
            }
            let startDates = this.newTask.startDates.map((startDate) => moment(startDate).format("MM/DD/YYYY"));
            if (startDates && startDates.length > 0) {
                this.newTask.startDates = startDates.map((item) => {
                    let startDate = new Date(`${item} ${this.shiftStartTimePal}`).toISOString();
                    return moment(startDate).format("MM/DD/YYYY HH:mm");
                });
            }
        },
        newTaskDateRange(newValue: string) {
            this.newTask.startDates = [];
            const newTaskDates = newValue.split(" - ");
            const startDate = moment(newTaskDates[0]);
            const endDate = moment(newTaskDates[1]);
            let dateToAdd = startDate;
            while (dateToAdd <= endDate) {
                this.newTask.startDates.push(dateToAdd.format("MM/DD/YYYY"));
                if (this.newTaskRepeat == 4) {
                    dateToAdd = dateToAdd.add(1, "month");
                } else {
                    dateToAdd = dateToAdd.add(this.newTaskRepeat, "week");
                }
            }
            if (this.taskDateChanged) {
                this.taskDateChanged();
            }
        },
        newTaskFlexDates(newValue: string) {
            this.newTask.startDates = [];
            if (!!newValue) {
                this.newTask.startDates = newValue.split(", ");
            }
            if (this.taskDateChanged) {
                this.taskDateChanged();
            }
        },
        taskList(newValue: IAvailableTaskItems) {
            if (newValue) {
                this.newTask.taskId = newValue.id;
                this.taskListItems = newValue;
                const response = this.getEmployeeList("");
                response.then(() => {
                    this.isTaskSelected = true;
                });
                this.updateAvailablePayors();
            }
        },
        scheduleEmployeeId(newValue: ITypeaheadOptions) {
            if (newValue) {
                this.newTask.userId = newValue.value;
                this.employeeName = newValue.name;
            }
        }
    },
    methods: {
        taskDateChanged() {
            if (this.newTask.startDates?.length > 0) {
                const response = this.availableTasks();
                response.then(() => {
                    this.isDateSelected = true;
                });
            } else {
                this.isDateSelected = false;
                this.resetShiftOnCall();
            }
            this.updateAvailablePayors();
        },
        async availableTasks() {
            if (this.newTask.startDates.length) {
                const data = await this._taskService.getAvailableTasks({
                    patientId: this.visitDetails.Id,
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application,
                    startDates: this.newTask.startDates
                });
                return (this.availableTask = data.Data);
            } else {
                return false;
            }
        },
        resetShiftOnCall() {
            this.newTask.onCall = false;
            this.shiftStartTime = "";
            this.shiftLength = 0;
        },

        async updateAvailablePayors() {
            if (!this.newTask.startDates.length) {
                this.patientPayers = [];
                return;
            }
            const startDates = this.newTask.startDates.map((date) => moment(date));
            const startDate = moment.min(startDates).format("MM/DD/YYYY");
            const endDate = moment.max(startDates).format("MM/DD/YYYY");
            const response = await this._taskService.getPayersByDate({
                patientId: this.visitDetails.Id,
                accountId: this.visitDetails.AgencyId,
                startDate: startDate,
                endDate: endDate,
                application: this.visitDetails.Application,
                includeCharityPayer: true
            });
            this.patientPayers = response.data;

            this.payerTypes = this.payerTypeTest.filter((type) =>
                this.patientPayers.some((patientPayor) => patientPayor.payerType === type.value)
            );

            const primaryPayer = this.patientPayers.find((payer) => payer.payerType === PayerTypesEnum.Primary);

            this.newTask.patientPayerId = primaryPayer?.id ?? null;
        },
        availableTasksFilter(filter: string) {
            if (filter) {
                const filterAvailableTasks = this.availableTask.filter((task) => {
                    return task.name.toLowerCase().includes(filter.toLowerCase());
                });
                return filterAvailableTasks;
            } else {
                return this.availableTask;
            }
        },
        async getEmployeeList(filter: string) {
            const employees = await this._taskService.getAvailableUsers({
                term: filter,
                accountId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application,
                patientId: this.visitDetails.Id,
                taskId: this.newTask.taskId
            });
            return (this.employeeList = employees);
        },
        employeeListFilter(filter: string) {
            if (filter) {
                const filterEmployeeList = this.employeeList.filter((employee) => {
                    const fullName = `${employee.firstName} ${employee.lastName}`;
                    return fullName.toLowerCase().includes(filter.toLowerCase());
                });
                return filterEmployeeList?.map((item) => {
                    return {
                        name: `${item.firstName} ${item.lastName}`,
                        value: item.id
                    };
                });
            } else {
                return this.employeeList?.map((item) => ({
                    value: item.id,
                    name: `${item.firstName} ${item.lastName}`
                }));
            }
        },
        visitTypeRadioClick(value: VisitTypeEnum) {
            this.visitType = this.visitType === value ? null : value;
            if (!this.visitType) {
                // If none is selected
                this.newTask.onCall = false;
                this.newTask.prn = false;
            } else if (this.visitType === VisitTypeEnum.OnCall) {
                // If on call visit type
                this.newTask.onCall = true;
                this.newTask.prn = false;
            } else if (this.visitType === VisitTypeEnum.Prn) {
                // If prn visit type
                this.newTask.onCall = false;
                this.newTask.prn = true;
            }
            return true;
        },
        closeFrequencyWarning() {
            this.isOutsideFrequency = false;
            this.isFrequencyWarningRequested = false;
        },
        async createTask(saveType: string) {
            const validated = await this.v$.$validate();
            if (!validated) {
                return;
            }
            if (!!this.newTask) {
                try {
                    this.$emit("update:isSaving", true);
                    this.newTask.patientId = this.visitDetails.Id;
                    this.newTask.metaId = "";
                    this.newTask.comments = "";
                    this.newTask.agencyId = this.visitDetails.AgencyId;
                    this.newTask.application = this.visitDetails.Application;
                    await this._taskService.createTask(this.newTask);
                    this._toastrService.success({
                        title: `New Task Created`,
                        message: `The Task has been successfully created.`
                    });
                    this.closeFrequencyWarning();
                    if (saveType == SaveEnum.SaveAndExit) {
                        // trigger callback
                        this.ok();
                    } else {
                        (this.$refs.typeAheadRefTask as any)?.clearFilter();
                        (this.$refs.typeAheadRefEmp as any)?.clearFilter();
                        setTimeout(() => {
                            this.newTask = new PatientTask();
                            this.newTaskSingleDate = "";
                            this.newTaskDateRange = "";
                            this.newTaskFlexDates = "";
                            this.scheduleEmployeeId = null;
                            this.taskList = null;
                        });
                    }
                } catch (e) {
                    this._toastrService.error({
                        title: `Creating Task Failed`,
                        message: `Creating Task Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.$emit("update:isSaving", false);
                }
            }
        },
        async saveAndAddAnother(saveType: string) {
            await this.createTask(saveType);
        },
        async showFrequencyWarning(saveTask: boolean = true) {
            const validated = await this.v$.$validate();
            if (!validated) {
                return;
            }
            this.isFrequencyWarningRequested = true;
            if (saveTask == true) {
                this.saveType = SaveEnum.SaveAndAddAnother;
            } else {
                this.saveType = SaveEnum.SaveAndExit;
            }
        },
        async getCurrentBenefitPeriod() {
            const response = await this._patientService.getCurrentBenefitPeriod({
                patientId: this.visitDetails.Id,
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application
            });
            this.patient = response.data;
        },
        changeTaskList(data: any) {
            this.taskList = data;
        },
        changeScheduleEmployeeId(data: any) {
            this.scheduleEmployeeId = data;
        },
        changeNewTaskSingleDate(date: string, name: string) {
            if (name == "newTaskSingleDate") {
                this.newTaskSingleDate = date;
            }
        },
        setNewTaskDateRange(event: any) {
            this.newTaskDateRange = event.target.value;
        },
        changeStartTime(time: any) {
            if (this.isPalliativeSolution) {
                this.shiftStartTimePal = time;
            } else {
                this.shiftStartTime = time;
            }
        },
        changeEndTime(time: any) {
            this.shiftEndTime = time;
        },
        updatenewTaskFlexDates(result: any) {
            this.newTaskFlexDates = result;
        }
    }
});
