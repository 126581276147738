import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column px-3 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemplateInput = _resolveComponent("TemplateInput")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, {
    title: "Comments",
    type: "his-section",
    "full-height": false,
    "application-id": _ctx.application
  }, {
    content: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_TemplateInput, {
          templates: _ctx.referralOptions.template,
          application: _ctx.application,
          accountId: _ctx.accountId,
          onResultUpdated: _cache[0] || (_cache[0] = (e) => _ctx.onCommentSelect(e)),
          result: _ctx.referral.Clinical.Comments
        }, null, 8 /* PROPS */, ["templates", "application", "accountId", "result"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["application-id"]))
}