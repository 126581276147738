import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PayerSequenceCodesEnum {
    Primary = 1 as any,
    Secondary = 2 as any,
    Tertiary = 3 as any
}
class PayerSequenceCodesClass extends EnumWithDescription<PayerSequenceCodesEnum> {
    public readonly enum = PayerSequenceCodesEnum;
    public readonly enumName: string = "PayerSequenceCodesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PayerSequenceCodesEnum>> = new Map([
        [
            "Primary",
            {
                value: PayerSequenceCodesEnum.Primary,
                name: "Primary"
            }
        ],
        [
            "Secondary",
            {
                value: PayerSequenceCodesEnum.Secondary,
                name: "Secondary"
            }
        ],
        [
            "Tertiary",
            {
                value: PayerSequenceCodesEnum.Tertiary,
                name: "Tertiary"
            }
        ]
    ]);
}

export const PayerSequenceCodes = new PayerSequenceCodesClass();
