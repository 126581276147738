import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum DmeNeededEnum {
    BedsideCommode = 0 as any,
    Cane = 1 as any,
    ElevatedToiletSeat = 2 as any,
    GrabBars = 3 as any,
    HospitalBed = 4 as any,
    Nebulizer = 5 as any,
    Oxygen = 6 as any,
    TubShowerBench = 7 as any,
    Walker = 8 as any,
    Wheelchair = 9 as any,
    Other = 10 as any
}
class DmeNeededClass extends EnumWithDescription<DmeNeededEnum> {
    public readonly enum = DmeNeededEnum;
    public readonly enumName: string = "DmeNeededEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<DmeNeededEnum>> = new Map([
        [
            "BedsideCommode",
            {
                value: DmeNeededEnum.BedsideCommode,
                name: "Bedside Commode"
            }
        ],
        [
            "Cane",
            {
                value: DmeNeededEnum.Cane,
                name: "Cane"
            }
        ],
        [
            "ElevatedToiletSeat",
            {
                value: DmeNeededEnum.ElevatedToiletSeat,
                name: "Elevated Toilet Seat"
            }
        ],
        [
            "GrabBars",
            {
                value: DmeNeededEnum.GrabBars,
                name: "Grab Bars"
            }
        ],
        [
            "HospitalBed",
            {
                value: DmeNeededEnum.HospitalBed,
                name: "Hospital Bed"
            }
        ],
        [
            "Nebulizer",
            {
                value: DmeNeededEnum.Nebulizer,
                name: "Nebulizer"
            }
        ],
        [
            "Oxygen",
            {
                value: DmeNeededEnum.Oxygen,
                name: "Oxygen"
            }
        ],
        [
            "TubShowerBench",
            {
                value: DmeNeededEnum.TubShowerBench,
                name: "Tub Shower Bench"
            }
        ],
        [
            "Walker",
            {
                value: DmeNeededEnum.Walker,
                name: "Walker"
            }
        ],
        [
            "Wheelchair",
            {
                value: DmeNeededEnum.Wheelchair,
                name: "Wheelchair"
            }
        ],
        [
            "Other",
            {
                value: DmeNeededEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const DmeNeeded = new DmeNeededClass();
