import { PLATFORM } from "aurelia-pal";

import { CreateRoutes } from "../create/create-routes";

export enum RouteNames {
    Home = "home",
    Help = "help",
    Clinician = "clinician",
    Calendar = "calendar",
    OidcSignIn = "oidc-sign-in",
    OidcSignInRedirect = "oidc-sign-in-redirect",
    OidcSignInSilent = "oidc-sign-in-silent",
    Intake = "intake",
    Create = "create",
    CentralMessaging = "central-messaging"
}

const title: string = "Axxess - Complete Home Health Care Software";
export default [
    {
        name: RouteNames.Home,
        route: "",
        moduleId: PLATFORM.moduleName("../home/home", "home"),
        nav: false,
        title: title
    },
    {
        name: RouteNames.Clinician,
        route: RouteNames.Clinician,
        moduleId: PLATFORM.moduleName("../clinician/clinician", "clinician"),
        nav: true,
        title: title,
        settings: {
            menuName: "Axxess Planner",
            showAxxessPlanner: true
        }
    },
    {
        name: RouteNames.Intake,
        route: RouteNames.Intake,
        moduleId: PLATFORM.moduleName("../central-intake/central-intake", "central-intake"),
        nav: true,
        title: title,
        settings: {
            menuName: "Central Intake",
            showCentralIntake: true,
            hideTitle: true
        }
    },
    {
        name: RouteNames.Calendar,
        route: RouteNames.Calendar,
        moduleId: PLATFORM.moduleName("../central-calendar/central-calendar", "central-calendar"),
        nav: true,
        title: title,
        settings: {
            menuName: "Central Calendar",
            showIfScheduler: true
        }
    },
    {
        name: RouteNames.Help,
        route: RouteNames.Help,
        moduleId: PLATFORM.moduleName("../personal/personal", "personal"),
        nav: true,
        title: title,
        settings: {
            menuName: "Axxess Help Center"
        }
    },
    {
        name: RouteNames.OidcSignIn,
        route: "oidc/sign-in",
        moduleId: PLATFORM.moduleName("../oidc/sign-in-start"),
        title: "Oidc - Sign-In",
        settings: {
            anonymous: true
        }
    },
    {
        name: RouteNames.OidcSignInRedirect,
        route: "oidc/sign-in-redirect",
        moduleId: PLATFORM.moduleName("../oidc/sign-in-redirect"),
        title: "Oidc - Sign-In-Redirect",
        settings: {
            anonymous: true
        }
    },
    {
        name: RouteNames.OidcSignInSilent,
        route: "oidc/sign-in-silent",
        moduleId: PLATFORM.moduleName("../oidc/sign-in-silent"),
        title: "Oidc - Sign-In-Silent",
        settings: {
            anonymous: true
        }
    },
    {
        name: RouteNames.Create,
        route: RouteNames.Create,
        moduleId: PLATFORM.moduleName("../create/create", "create"),
        nav: false,
        settings: {
            subRoutes: CreateRoutes
        }
    },
    {
        name: "task-manager",
        route: "task-manager",
        nav: false,
        moduleId: PLATFORM.moduleName("../task-manager/task-manager", "employee-calendar"),
        title: "Task Manager",
        settings: {
            menuName: "Task Manager",
            hideTitle: true
        }
    },
    {
        name: "task-manager-patient",
        route: "task-manager-patient",
        nav: false,
        moduleId: PLATFORM.moduleName("../task-manager-patient/task-manager", "patient-calendar"),
        title: "Task Manager",
        settings: {
            menuName: "Task Manager",
            hideTitle: true
        }
    }
];
