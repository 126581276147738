import { defineComponent, PropType } from "vue";
import { Container } from "aurelia-dependency-injection";

import { formatPhoneNumber } from "../../../common/vue-helpers/modifiers/value-modifier";
import { PhoneTypeEnum } from "../../../enums/enums";
import { IPhoneNumberOption } from "../../../resources-vue/vue-interfaces/i-phone-number";
import type { IGetCountryPhoneCode } from "../../../resources-vue/vue-interfaces/i-lookup";
import { LookupService } from "../../../services/lookup-service";

export default defineComponent({
    props: {
        name: { type: String, default: "" },
        type: { type: Number, default: null },
        country: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        enableCountry: { type: Boolean, default: false },
        value: { type: String, default: null },
        placeholder: { type: String, default: "Enter Phone Number" },
        extension: { type: String, default: null },
        disableExtension: { type: Boolean, default: null },
        phoneTypeOptions: {
            type: Array as PropType<PhoneTypeEnum[]>,
            default: [PhoneTypeEnum.Home, PhoneTypeEnum.Mobile, PhoneTypeEnum.Facility]
        },
        options: {
            default: null,
            type: Array as PropType<IPhoneNumberOption[]>
        }
    },
    data() {
        return {
            phoneOptions: this.options || ([] as IPhoneNumberOption[]),
            _lookupService: null,
            _isCountryListLoadInProgress: false,
            _extensionCache: "",
            countryList: [] as IGetCountryPhoneCode[],
            selectedCountry: {} as IGetCountryPhoneCode,
            unitedStatesCountryCode: "",
            disableExtensionLocal: this.disableExtension,
            unformattedPhoneNumber: null,
            formatPhoneNumber
        };
    },
    watch: {
        country() {
            this.countryChanged();
        },
        type() {
            this.typeChanged();
        },
        disableExtensionLocal(newValue, oldValue) {
            this.$emit("disableExtensionUpdated", newValue);
        },
        disableExtension(newValue, oldValue) {
            this.disableExtensionLocal = newValue;
        }
    },
    computed: {
        formattedNumber() {
            return formatPhoneNumber(this.value);
        },
        singleOption() {
            return this.options ? this.options.length === 1 : this.phoneOptions.length === 1;
        },
        isUnitedStates() {
            return this.enableCountry && this.country && this.country === this.unitedStatesCountryCode;
        },
        showExtension() {
            if (!this.singleOption) {
                return true;
            }
            let phoneType = this.options || this.phoneOptions;
            return phoneType[0].hasExtension;
        }
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
    },

    async mounted() {
        this.unitedStatesCountryCode = await this._lookupService.getUnitedStatesCode();
        let phoneTypes = await this._lookupService.getPhoneTypeOptions();
        if (!!this.phoneTypeOptions?.length && phoneTypes?.length) {
            let optionsUpdated = phoneTypes.filter((op: any) => {
                return this.phoneTypeOptions.includes(op.name);
            });
            this.phoneOptions = optionsUpdated;
            this.$emit("update:options", optionsUpdated);
        }

        if (!!this.type) {
            this.typeChanged();
        }

        if (this.countryList?.length <= 0 && !this._isCountryListLoadInProgress) {
            await this.getCountryList();
        }

        if (!this.country) {
            this.$emit("update:country", this.unitedStatesCountryCode);
        }

        this.selectedCountry = this.countryList.find((country) => {
            return country.value === this.country;
        });
    },

    methods: {
        async countryChanged() {
            if (!!this.country) {
                if (this.countryList?.length <= 0 && !this._isCountryListLoadInProgress) {
                    await this.getCountryList();
                }

                this.selectedCountry = this.countryList.find((country) => {
                    return country.value === this.country;
                });
            } else {
                this.$emit("update:country", this.unitedStatesCountryCode);
            }
        },

        async getCountryList() {
            try {
                this._isCountryListLoadInProgress = true;
                this.countryList = await this._lookupService.getCountryPhoneCodes();
            } catch (e) {
                console.error(e);
            } finally {
                this._isCountryListLoadInProgress = false;
            }
        },
        typeChanged() {
            if (this.phoneOptions?.length > 0) {
                let selectedOption = this.getOption(this.type);
                this.disableExtensionLocal = selectedOption ? !(selectedOption as any)?.hasExtension : true;
                this.$emit("update:disabledExtension", this.disableExtensionLocal);
                let shouldLoadToCache = !this.disableExtensionLocal && this.extension != "";
                this._extensionCache = shouldLoadToCache ? this.extension : this._extensionCache;
                let extensionUpdated = this.disableExtensionLocal ? "" : this._extensionCache;
                this.$emit("update:extension", extensionUpdated);
            }
        },
        getOption(val: number) {
            return this.phoneOptions.find((option: IPhoneNumberOption) => option.value === val);
        },

        handleCountrySelection(selectedCountry: IGetCountryPhoneCode) {
            this.selectedCountry = Object.assign({}, selectedCountry);
            this.$emit("update:country", selectedCountry?.value);
        },

        updateType(event: any) {
            this.$emit("update:type", Number(event.target.value));
        },

        updateExtension(value: string) {
            this.$emit("update:extension", value);
        },

        valueUpdated(value: string) {
            let number =
                value
                    .split("")
                    ?.map((item) => (Number(item) || item == "0" ? item : ""))
                    .join("") || "";

            this.$emit("update:value", number);
        },
        emitBlur() {
            this.$emit("blurredInput");
        }
    }
});
