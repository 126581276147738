import { autoinject } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";
import { UserManager } from "oidc-client";
import { FetchClient } from "../common/fetch-client";
import { OidcSignOut } from "../common/oidc/oidc-event-handler";
import config from "../common/config";

export const accountIdKey = "hospice-account-id";
export const lineOfService = "hospice-line-of-service";

export const enum AccountEvent {
    Selected = "account:selected",
    ProvidersSelected = "account:providersSelected"
}
@autoinject()
export default class AuthService {
    private _fetchClient: FetchClient;
    private _ea: EventAggregator;
    private _userManager: UserManager;

    public constructor(ea: EventAggregator, fetchClient: FetchClient, userManager: UserManager) {
        this._ea = ea;
        this._fetchClient = fetchClient;
        this._userManager = userManager;
    }

    public async isLoggedIn(): Promise<boolean> {
        let user = await this._userManager.getUser();
        return user != null && !user.expired;
    }

    public logout(): void {
        this._ea.publish(OidcSignOut);
        window.localStorage.removeItem(config.organizationId);
        window.localStorage.removeItem(config.organizationName);
    }

    public getAccountId() {
        let cachedAccountData = window.localStorage.getItem(accountIdKey);
        return cachedAccountData;
    }

    public getLineOfService() {
        return Number(window.localStorage.getItem(lineOfService));
    }

    public needsAccountSelection() {
        return this.getAccountId() == null;
    }
}
