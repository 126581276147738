import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import {
    IGetPhysicianByIdRequestPayload,
    IGetPhysicianByName,
    IGetPhysicianByNameQuery,
    IGetPhysician,
    IGetPhysicianResponse,
    IPhysician,
    IPhysicianResponse,
    IAddNewPhysician,
    IAddNewPhysicianResponse
} from "../interfaces/i-get-physician";
import type { IPaginationNew } from "../interfaces/i-pagination";
import { Physician } from "../models/physician";

@autoinject()
export class PhysiciansService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/physicians";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getPhysiciansByName(query: IGetPhysicianByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/search-by-name`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IPaginationNew<IGetPhysicianByName>>();
    }

    public async getPhysicianById(payload: IGetPhysicianByIdRequestPayload) {
        const queryParam = { accountId: payload.accountId, application: payload.application };
        return await this._httpClient
            .build(`${this._baseUrl}/${payload.physicianId}`)
            .useQueryString(queryParam)
            .fetch<Physician>();
    }

    public async getPhysicianNameById(payload: IGetPhysicianByIdRequestPayload) {
        return await this._httpClient.build(`${this._baseUrl}/physician-name`).useQueryString(payload).fetch<string>();
    }

    public async getPhysician(params: IGetPhysician) {
        return await this._httpClient
            .build(`${this._baseUrl}/GetPhysiciansForReferral`)
            .useQueryString(params)
            .fetch<IPaginationNew<IGetPhysicianResponse>>();
    }

    public async addPhysician(physicianRequest: IPhysician) {
        return await this._httpClient
            .build(`${this._baseUrl}/AddPhysician`)
            .useMethod("POST")
            .useQueryString(physicianRequest)
            .fetch<IPhysicianResponse>();
    }

    public async deletePhysician(physicianRequest: IPhysician) {
        return await this._httpClient
            .build(`${this._baseUrl}/DeletePhysician`)
            .useMethod("DELETE")
            .useQueryString(physicianRequest)
            .fetch<IPhysicianResponse>();
    }

    public async setPrimaryPhysician(physicianRequest: IPhysician) {
        return await this._httpClient
            .build(`${this._baseUrl}/SetPrimaryPhysician`)
            .useMethod("PUT")
            .useQueryString(physicianRequest)
            .fetch<IPhysicianResponse>();
    }

    public async setPrimaryReferral(physicianRequest: IPhysician) {
        return await this._httpClient
            .build(`${this._baseUrl}/SetPrimaryReferral`)
            .useMethod("PUT")
            .useQueryString(physicianRequest)
            .fetch<IPhysicianResponse>();
    }

    public async addNewPhysicianHH(jsonPayload: IAddNewPhysician) {
        return await this._httpClient
            .build(`${this._baseUrl}/homehealth/add-physician`)
            .useMethod("POST")
            .useBody(jsonPayload)
            .fetch<IAddNewPhysicianResponse>();
    }
}
