import "./popover.scss";
import "popper.js";

function destroyPopover(element: any) {
    if (!element) {
        return;
    }
    element.popover("dispose").off("mouseenter").off("mouseleave");
}

function registerDocumentClick(element: any, data: any) {
    document.addEventListener("click", (evt: MouseEvent) => {
        setTimeout(() => {
            let isNotPopOver = $(evt.target as Element).parents(data?.defaultClassName).length == 0;
            let isNotElement = element.find(evt.target).length == 0;
            if (isNotPopOver && isNotElement) {
                element.popover("hide");
            }
        }, data.hideWait);
    });
}

function contentChanged(element: any, title: any, showOnChange = false) {
    element.popover("hide");
    element.attr("data-original-title", title);

    if (showOnChange?.toString() === "true") {
        element.popover("show");
    }
}

function attachPopover(element: any, data: any) {
    if (!data.content || !element) {
        return;
    }
    element = $(element);
    let options: any = {
        animation: data?.animation || true,
        container: data?.container || "body",
        delay: data?.delay || 0,
        html: data?.html || true,
        placement: data?.placement || "auto",
        title: data?.title || "",
        content: data?.content,
        trigger: data?.trigger || "title"
    };

    if (data?.className) {
        options.template = `<div class="popover ${data?.className}" role="tooltip">
                                <div class="arrow"></div>
                                <h3 class="popover-header"></h3>
                                <div class="popover-body"></div>
                            </div>`;
    }
    destroyPopover(element);

    if (options.trigger === "manual") {
        element
            .popover(options)
            .popover("hide")
            .on("mouseenter", () => {
                element.popover("show");
                let hideWait: number = data?.hideWait;
                // If hidewait is set, hide after hidewait seconds
                // even if the mouse is hovering over the content
                if (hideWait) {
                    let hideWaitTimer = setTimeout(() => {
                        element.popover("hide");
                        clearTimeout(hideWaitTimer);
                    }, hideWait);
                }
            })
            .on("mouseleave", () => {
                element.popover("hide");
            });
    } else {
        element.popover(options);
    }
    registerDocumentClick(element, data);
}

export const customPopover = {
    mounted(el: HTMLElement, binding: any) {
        attachPopover(el, binding.value);
    },

    updated: (el: HTMLElement, binding: any) => {
        if (binding.value?.title) {
            contentChanged($(el), binding.value?.title);
        }
    },

    unmounted: (el: HTMLElement, binding: any) => {
        destroyPopover($(el));
    }
};
