
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        tryAgain: { type: Function, default: null },
        title: { type: String, default: "" },
        showTryAgain: { type: Boolean, default: false },
        action: { type: String, default: "loading" }
    }
});
