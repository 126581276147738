import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { IPatientFileDetails } from "../../../../../resources-vue/vue-interfaces/i-get-patient";
import { AssetsService } from "../../../../../services/assets-service";
import { ToastrService } from "../../../../../services/toastr.service";
import { DocumentTypeCategory, ParentPermissionEnum, PermissionActionEnum } from "../../../../../enums/enums";
import { IGetDocumentTypeLean } from "../../../../../resources-vue/vue-interfaces/i-document-type";
import { ReferralService } from "../../../../../services/referral-service";
import { IPromptOptions } from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt";
import { formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";

import Prompt from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";
import AddDocumentRow from "../PatientDocuments/AddDocumentRow.vue";
import TableEmpty from "../../../../../resources-vue/vue-custom-elements/TableEmpty/TableEmpty.vue";

export default defineComponent({
    components: {
        AddDocumentRow,
        TableEmpty
    },

    props: {
        title: { type: String },
        patientId: { type: String },
        accountId: { type: String },
        application: { type: Number },
        uploadDocument: { type: Function },
        getDocuments: { type: Function },
        patientVersionId: { type: String },
        providerId: { type: String },
        deleteDocument: { type: Function }
    },

    data() {
        return {
            fileDetails: [],
            uploading: false,
            _assetsService: null,
            _toastrService: null,
            loadingDocuments: false,
            patientDocuments: [],
            addPatientDocumentsPermission: false,
            searchedTypes: [],
            DocumentTypeCategoryEnum: DocumentTypeCategory,
            _referralService: null,
            _permissionManager: null,
            deletePatientDocumentsPermission: false,
            uploadFile: [],
            moreFiles: [],
            loadingCount: 5,
            formatDate
        };
    },

    watch: {
        uploadFile(newValue: File[], oldValue: File[]) {
            let files: File[] = Array.from(newValue);
            this.fileDetails = files.map((file: File) => ({ name: file.name, type: null, file: file }));
        },

        moreFiles(newValue: File[], oldValue: File[]) {
            let files: File[] = Array.from(newValue);
            this.fileDetails = this.fileDetails.concat(
                files.map((file: File) => ({ name: file.name, type: null, file: file }))
            );
        }
    },

    created() {
        this._assetsService = Container.instance.get(AssetsService);
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },

    async mounted() {
        await this.loadSearchedTypes();
        this.addPatientDocumentsPermission = this._permissionManager.checkPermission(
            ParentPermissionEnum.documents,
            PermissionActionEnum.canAdd,
            this.accountId
        );
        this.deletePatientDocumentsPermission = this._permissionManager.checkPermission(
            ParentPermissionEnum.documents,
            PermissionActionEnum.canDelete,
            this.accountId
        );
        this.loadFilesList();
    },

    methods: {
        async loadSearchedTypes() {
            if (!this.patientId) {
                this.searchedTypes = [];
            }
            let category = Number(this.DocumentTypeCategoryEnum.enum.Patient);
            let searchedTypes: IGetDocumentTypeLean[] = await this._referralService.getDocumentTypesList({
                providerIds: [this.providerId],
                application: this.application,
                accountId: this.accountId,
                category
            });
            if (searchedTypes) {
                this.searchedTypes = searchedTypes.map((documentType: IGetDocumentTypeLean) => ({
                    value: documentType.id,
                    name: documentType.type
                }));
            } else {
                this.searchedTypes = [];
            }
        },

        removeFile(index: number) {
            this.fileDetails = this.fileDetails.filter((file, indx) => index != indx);
        },

        removeAllFiles() {
            this.fileDetails = [];
        },

        moreFilesChanged(newValue: File[], oldValue: File[]) {
            let files: File[] = Array.from(newValue);
            this.fileDetails = this.fileDetails.concat(
                files.map((file: File) => ({ name: file.name, type: null, file: file }))
            );
        },

        uploadFilesChanged(event: any) {
            this.uploadFile = event.target.files;
        },

        moreFilesAdded(event: any) {
            this.moreFiles = event.target.files;
        },

        async deleteSelectedDocument(documentId: string) {
            try {
                let promptOptions: IPromptOptions = {
                    message: "Are you sure you want to delete?",
                    okText: "Yes",
                    cancelText: "No"
                };

                this.$dialog.open(Prompt, {
                    props: {
                        modal: true,
                        showHeader: false
                    },
                    data: promptOptions,
                    onClose: async (confirm) => {
                        if (confirm.data) {
                            await this.deleteDocument(this.patientId, documentId);
                            this.loadFilesList();
                            this._toastrService.success({
                                message: `The document has been successfully deleted.`,
                                title: `Document Deleted`
                            });
                        }
                    }
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Document`,
                    message: `There was a problem while deleting the document. Please try again.`
                });
            }
        },

        getFileIcon(type: string) {
            if (!type) {
                return "fa-file";
            }
            if (type.match("image.*")) {
                return "fa-file-image";
            } else if (type.includes("wordprocessing")) {
                return "fa-file-word";
            } else if (type.includes("spreadsheet")) {
                return "fa-file-excel";
            } else if (type.includes("pdf")) {
                return "fa-file-pdf";
            } else {
                return "fa-file";
            }
        },

        async uploadDocuments() {
            // let res = await this.validationController.validate();
            if (this.patientId) {
                try {
                    this.uploading = true;
                    await this.asyncForEach(this.fileDetails, async (fileDetail) => {
                        if (fileDetail?.type) {
                            let assetId = await this._assetsService.uploadFile(
                                fileDetail.file,
                                this.accountId,
                                this.application
                            );
                            await this.uploadDocument(this.patientId, {
                                assetId: assetId,
                                documentName: fileDetail.name,
                                uploadType: fileDetail.type.value,
                                patientVersionId: this.patientVersionId,
                                application: this.application,
                                accountId: this.accountId
                            });
                        } else {
                            throw new Error("No Type");
                        }
                    });
                    this.removeAllFiles();
                    this.loadFilesList();
                    this._toastrService.success({
                        title: `Upload Successful`,
                        message: `The files have been successfully uploaded`
                    });
                } catch (error) {
                    await this.showUploadError();
                }
                this.uploading = false;
            } else {
                await this.showUploadError();
            }
        },

        async asyncForEach(array: IPatientFileDetails[], callback: (item: IPatientFileDetails) => void) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index]);
            }
        },

        async loadFilesList() {
            if (this.patientId) {
                this.loadingDocuments = true;
                this.patientDocuments = await this.getDocuments(this.patientId);
                this.loadingDocuments = false;
            }
        },

        async showUploadError() {
            this._toastrService.error({
                title: `Error`,
                message: `There was a problem while uploading files. Please try again.`
            });
        }
    }
});
