import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum RoomAndBoardReferralTypeEnum {
    LongTermNursingFacility = 8 as any,
    SkilledNursingFacility = 14 as any
}
class RoomAndBoardReferralTypeClass extends EnumWithDescription<RoomAndBoardReferralTypeEnum> {
    public readonly enum = RoomAndBoardReferralTypeEnum;
    public readonly enumName: string = "RoomAndBoardReferralTypeEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<RoomAndBoardReferralTypeEnum>> = new Map([
        [
            "LongTermNursingFacility",
            {
                value: RoomAndBoardReferralTypeEnum.LongTermNursingFacility,
                name: "Long-term nursing facility (NF)"
            }
        ],
        [
            "SkilledNursingFacility",
            {
                value: RoomAndBoardReferralTypeEnum.SkilledNursingFacility,
                name: "Skilled nursing facility (SNF/TCU)"
            }
        ]
    ]);
}

export const RoomAndBoardReferralTypes = new RoomAndBoardReferralTypeClass();
