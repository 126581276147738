<template>
    <div class="my-3 w-100">
        <h6 class="font-weight-bold">Eligibility</h6>
        <div class="w-100" v-if="firstName && lastName && gender && locationId && dateOfBirth && eligibilityNumber">
            <div class="w-100">
                <p class="mt-1 mb-1 animated flash" v-if="isCheckingEligibility">
                    <b>Checking Eligibility...</b>
                </p>
                <div v-else>
                    <div v-if="eligibility">
                        <span v-if="eligibility.request_Result.success !== 'Yes'">
                            <div class="big-text text-danger font-weight-bold">
                                <i class="fas fa-times-circle"></i> Error retrieving eligibility
                            </div>
                        </span>
                        <span v-else>
                            <div
                                class="big-text text-success font-weight-bold"
                                v-if="eligibility.medicare_Part_A.success == 'Yes'"
                            >
                                <i class="fas fa-check-circle"></i> Medicare
                            </div>
                            <div class="big-text text-danger font-weight-bold" v-else>
                                <i class="fas fa-times-circle"></i> Medicare
                            </div>
                        </span>
                        <button class="btn btn-xs btn-primary ml-3" @click="checkEligibility()">Check Again</button>
                        <p class="text-muted mb-0 mt-1" v-if="eligibility.request_Result.success === 'Yes'">
                            Last Checked Today |
                            <a href="#" @click="showReport()" v-if="!isReportExpanded">View Latest Report</a>
                            <a href="#" @click="hideReport()" v-else>Hide Report</a>
                        </p>
                        <div class="mt-2" v-if="isReportExpanded && eligibility.request_Result.success == 'Yes'">
                            <EligibilityPatientInfo :eligibility="eligibility" />
                            <EligibilityPayers :eligibility="eligibility" />
                            <EligibilityHomeHealth :eligibility="eligibility" />
                            <EligibilityHospice :eligibility="eligibility" />
                        </div>
                    </div>
                    <div v-else>
                        <button
                            class="btn btn-primary"
                            v-if="firstName && lastName && gender && locationId && dateOfBirth && eligibilityNumber"
                            @click="checkEligibility()"
                        >
                            Check Eligibility
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <i class="fas fa-info-circle"></i>
            <b v-if="!eligibilityNA">Enter the following fields to check Medicare eligibility:</b>
            <b v-else>N/A- Not Applicable</b>
            <ul class="list-unstyled ml-3 mt-1 mb-0">
                <li class="text-danger mt-2 border p-2 bg-white" v-if="!firstName">
                    <i class="fas fa-times-circle"></i>
                    <a v-if="missingUrl" :href="missingUrl.firstName" class="cursor-pointer">First Name</a>
                    <span v-else>First Name</span>
                </li>
                <li class="text-danger mt-2 border p-2 bg-white" v-if="!lastName">
                    <i class="fas fa-times-circle"></i>
                    <a v-if="missingUrl" :href="missingUrl.lastName" class="cursor-pointer">Last Name</a>
                    <span v-else>Last Name</span>
                </li>
                <li class="text-danger mt-2 border p-2 bg-white" v-if="!gender">
                    <i class="fas fa-times-circle"></i>
                    <a v-if="missingUrl" :href="missingUrl.gender" class="cursor-pointer">Gender</a>
                    <span v-else>Gender</span>
                </li>
                <li class="text-danger mt-2 border p-2 bg-white" v-if="!dateOfBirth">
                    <i class="fas fa-times-circle"></i>
                    <a v-if="missingUrl" :href="missingUrl.dateOfBirth" class="cursor-pointer">Date of Birth</a>
                    <span v-else>Date of Birth</span>
                </li>
                <li class="text-danger mt-2 border p-2 bg-white" v-if="!locationId">
                    <i class="fas fa-times-circle"></i>
                    <a v-if="missingUrl" :href="missingUrl.locationId" class="cursor-pointer">Agency Branch</a>
                    <span v-else>Agency Branch</span>
                </li>
                <li class="text-danger mt-2 border p-2 bg-white" v-if="!eligibilityNumber && !eligibilityNA">
                    <i class="fas fa-times-circle"></i>
                    <a v-if="missingUrl" :href="missingUrl.medicareNumber" class="cursor-pointer">
                        Medicare Beneficiary Identifier (MBI) or Medicare Number
                    </a>
                    <span v-else> Medicare Beneficiary Identifier (MBI) or Medicare Number</span>
                </li>
                <li class="text-danger mt-2 border p-2 bg-white" v-if="eligibilityNA">
                    <i class="fas fa-times-circle"></i>
                    <span
                        >Medicare eligibility can only be checked for patients who have a Medicare Number or Medicare
                        Beneficiary Identifier (MBI) entered above.
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script src="./EligibilityCheck"></script>
