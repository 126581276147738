import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";

import {
    IPatientDetailsAxxessCare,
    IPayRate,
    IPostAxxessCareResponse,
    ISpecialtyAxessCare,
    IVisitDetails
} from "../../../resources-vue/vue-interfaces/i-visit-details";
import { PostToAxessCareDetails } from "../../../models/task.model";
import { TaskService } from "../../../services/task.service";
import { ToastrService } from "../../../services/toastr.service";

import AddressModal from "../AddressModal/AddressModal.vue";
import PayrateModal from "../PayrateModal/PayrateModal.vue";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            _dialogService: null,
            _taskService: null,
            _toastrService: null,
            dialogController: null,
            dialogReference: this.dialogRef as any,
            isLoading: false as boolean,
            patientAddressDetailsAxxessCare: {} as IPatientDetailsAxxessCare,
            postToAxxessCareDetails: new PostToAxessCareDetails(),
            visitDetails: null,
            taskDetails: null,
            PayRates: [] as IPayRate[],
            dialogRef: null
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    async mounted() {
        this.visitDetails = Object.assign({}, this.dialogReference.data.visitDetails);
        this.isLoading = true;
        const specialties: Promise<ISpecialtyAxessCare[]> = this._taskService.getAxxessCareSpecialties({
            agencyId: this.visitDetails.agencyId
        });
        let details: Promise<IPatientDetailsAxxessCare>;
        let specialities: ISpecialtyAxessCare[];
        if (this.visitDetails.agencyId && this.visitDetails.PatientId) {
            details = this._taskService.getAxxessCareDetails(this.visitDetails.PatientId, {
                agencyId: this.visitDetails.agencyId,
                discipline: this.visitDetails.discipline,
                disciplineTask: this.visitDetails.DisciplineTaskId
            });
        }
        try {
            [specialities, this.patientAddressDetailsAxxessCare] = await Promise.all([specialties, details]);
            this.assignValues();
        } catch (error) {
            this._toastrService.error({
                title: `Error - Posting to AxessCare`,
                message: `There was a problem while posting this task to AxxessCare</b>. Please try again.`
            });
            this.dialogReference.cancel();
        } finally {
            this.isLoading = false;
        }
    },

    methods: {
        assignValues() {
            Object.assign(
                this.postToAxxessCareDetails,
                JSON.parse(JSON.stringify(this.patientAddressDetailsAxxessCare))
            );
            this.postToAxxessCareDetails.AgencyId = this.visitDetails.agencyId;
            this.postToAxxessCareDetails.EpisodeId = this.visitDetails.episodeId;
            this.postToAxxessCareDetails.PatientId = this.visitDetails.PatientId;
            this.postToAxxessCareDetails.EventId = this.visitDetails.eventId;
            this.postToAxxessCareDetails.TaskType = this.visitDetails.specialityName;
            this.postToAxxessCareDetails.ScheduleDate = this.visitDetails?.visitDates[0];
            this.postToAxxessCareDetails.SpecialtyId = this.visitDetails.specialityId;
            this.PayRates = this.postToAxxessCareDetails.PayRates;
        },

        async editAddress() {
            this.dialogRef = this.$dialog.open(AddressModal, {
                props: {
                    modal: true,
                    showHeader: false
                },

                data: {
                    address: this.patientAddressDetailsAxxessCare
                },

                onClose: (options) => {
                    if (options.data == "cancel") {
                        return;
                    } else {
                        if (options.data.Message == "success") {
                            Object.assign(this.patientAddressDetailsAxxessCare, options?.data);
                        }
                    }
                }
            });
        },

        async editPayRate() {
            this.dialogRef = this.$dialog.open(PayrateModal, {
                props: {
                    modal: true,
                    showHeader: false
                },

                data: {
                    payRates: this.postToAxxessCareDetails?.PayRates
                },

                onClose: (options) => {
                    if (options.data == "cancel") {
                        return;
                    } else {
                        const isSuccess = options.data.every((item: IPayRate) => item.Message === "success");
                        if (isSuccess) {
                            this.PayRates = options.data;
                        }
                    }
                }
            });
        },

        cancel() {
            this.dialogReference.close();
        },

        async postToAxxessCare() {
            let payload = this.visitDetails.visitDates.map((date: any) => {
                return {
                    UserId: this.visitDetails.userId,
                    Languages: "English",
                    CustomId: this.visitDetails.customId,
                    AgencyId: this.postToAxxessCareDetails.AgencyId,
                    EpisodeId: this.postToAxxessCareDetails.EpisodeId,
                    PatientId: this.postToAxxessCareDetails.PatientId,
                    EventId: this.postToAxxessCareDetails.EventId,
                    DisciplineTaskId: this.visitDetails.DisciplineTaskId,
                    VisitType: this.visitDetails.visitType,
                    ScheduleDate: `${moment(date).format("YYYY-MM-DD")}T00:00:00.000Z`,
                    TaskType: this.visitDetails.specialityName,
                    SpecialtyId: this.visitDetails.specialityId,
                    PrivateJob: this.postToAxxessCareDetails.PrivateJob,
                    AddressLine1: this.postToAxxessCareDetails.AddressLine1,
                    AddressLine2: this.postToAxxessCareDetails.AddressLine2,
                    StateCode: this.postToAxxessCareDetails.StateCode,
                    City: this.postToAxxessCareDetails.City,
                    ZipCode: this.postToAxxessCareDetails.ZipCode,
                    VisitDetails: this.postToAxxessCareDetails.VisitDetails,
                    HipaaInfo: this.postToAxxessCareDetails.HipaaInfo
                };
            });
            const result: IPostAxxessCareResponse = await this._taskService.multiplePostToAxxessCare({
                CareVisits: payload
            });
            if (!result || !result.isSuccessful) {
                this._toastrService.error({
                    title: `Error - Posting to AxessCare`,
                    message: result.message
                });
                this.dialogReference.cancel();
            } else {
                this._toastrService.success({
                    title: `Posted to AxxessCare`,
                    message: `The task was posted successfully to AxxessCare`
                });
                this.dialogReference.close("success");
            }
        }
    }
});
