import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { TaskService } from "../../../services/task.service";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            _taskService: null as TaskService,
            reason: "" as string,
            dialogReference: this.dialogRef as any,
            taskDetails: null
        };
    },
    created() {
        this._taskService = Container.instance.get(TaskService);
    },
    mounted() {
        this.taskDetails = this.dialogReference.data.data.taskDetails;
    },
    methods: {
        async startAddendum() {
            const payload = {
                agencyId: this.taskDetails.AgencyId,
                application: this.taskDetails.Application,
                reason: this.reason
            };
            const response = await this._taskService.startAddendum(payload, this.taskDetails.Id);
            this.dialogReference.close("success");
        },
        cancelAddAddendum() {
            this.dialogReference.close();
        }
    }
});
