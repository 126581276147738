import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import config from "../../common/config";
import { ToastrService } from "../../services/toastr.service";
import { AgencyService, IGetOrganization } from "../../services/agency.service";
import {
    ApplicationEnum,
    ApplicationNames,
    LineOfServices,
    ParentPermissionEnum,
    PermissionActionEnum
} from "../../enums/enums";
import { ProvidersService } from "../../services/providers-service";
import { BranchesService } from "../../services/branches-service";
import { IGetPatientsByNameQuery, PatientService } from "../../services/patient.service";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { CentralIntakeService } from "../../services/central-intake.service";
import { ReferralService } from "../../services/referral-service";
import { LineOfService } from "./LineOfService.model";
import { helperTestingAgency } from "../../common/vue-helpers/testing-agency-check";
import { ICreateInterimReferralPayload } from "../../resources-vue/vue-interfaces/i-get-referral";
import { formatPhone, formatDateFromDate } from "../../common/vue-helpers/modifiers/value-modifier";
import { formatName } from "../../common/format-name";
import { IGetPatientsByNameResponse } from "../../resources-vue/vue-interfaces/i-patient-service";
import { PAGE_FAIL_RESPONSE } from "../CentralIntake/Inquiry/CentralInquiry";
import { IGetProviderItem } from "../../resources-vue/vue-interfaces/i-providers-service";
import { IBranchResponse } from "../../resources-vue/vue-interfaces/i-branch";

import PageLayout from "../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import FormSection from "../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import Pagination from "../../resources-vue/vue-elements/Pagination/Pagination.vue";
import ErrorMessageHandler from "../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import FormatName from "../../resources-vue/vue-custom-elements/FormatName/FormatName.vue";
import EmptyTextHandler from "../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import CreateInterimReferralRow from "./CreateInterimReferralRow.vue";
import BranchDefaultFilter from "../../resources-vue/vue-custom-elements/BranchDefaultFilter/BranchDefaultFilter.vue";
import SortableTh from "../../resources-vue/vue-custom-elements/SortableTh/SortableTh.vue";

export default defineComponent({
    components: {
        PageLayout,
        FormSection,
        Pagination,
        ErrorMessageHandler,
        FormatName,
        EmptyTextHandler,
        CreateInterimReferralRow,
        BranchDefaultFilter,
        SortableTh
    },

    data() {
        return {
            name: "Create",
            _providersService: null,
            _referralService: null,
            _branchesService: null,
            _permissionManager: null,
            _centralIntakeService: null,
            _patientsService: null,
            _agencyService: null,
            _toastrService: null,
            searchPatientTerm: "",
            selectedBranch: null,
            inquiry: null,
            sortOrder: "asc",
            sortBy: "dateOfBirth",
            selectedPrimaryLineOfService: null,
            lineOfService: this.initNewPatientReferral(ApplicationEnum.AxxessHospiceFE),
            isLineOfServiceFormRequested: false,
            providerList: [],
            allProviderList: [],
            isAddingReferralLoading: false,
            accountId: "",
            primaryLineOfServiceList: [],
            showNewReferral: false,
            showNewReferralHospice: false,
            showNewReferralPalliative: false,
            allBranchList: [],
            selectedBranches: [] as string[],
            showNewReferralHomeHealth: false,
            showNewReferralHomeCare: false,
            hospiceApplicationId: ApplicationEnum.AxxessHospiceFE,
            palliativeApplicationId: ApplicationEnum.AxxessPalliative,
            homeHealthApplicationId: ApplicationEnum.AgencyCore,
            homeCareApplicationId: ApplicationEnum.HomeCare,
            lineOfServiceEnumMap: LineOfServices,
            isNonMedical: false,
            currentApplication: null,
            showPatientList: false,
            columns: 8,
            isSearchLoading: false,
            isSearchError: false,
            patientListPagination: null,
            organizations: [] as IGetOrganization[],
            isError: false,
            page: null,
            ApplicationNamesEnum: ApplicationNames,
            isTestAgency: false,
            pageSize: 10,
            isReferredToAnotherSolution: false as boolean,
            isNonAdmit: false as boolean,
            checkProductPermission: {},
            formatDateFromDate,
            formatPhone,
            v$: useVuelidate(),
            agencyService: [
                {
                    name: "Hospice",
                    show: this.showNewReferralHospice,
                    id: ApplicationEnum.AxxessHospiceFE,
                    isNonMedical: false
                },
                {
                    name: "Palliative",
                    show: this.showNewReferralPalliative,
                    id: ApplicationEnum.AxxessPalliative,
                    isNonMedical: false
                },
                {
                    name: "Home Health",
                    show: this.showNewReferralHomeHealth,
                    id: ApplicationEnum.AgencyCore,
                    isNonMedical: false
                },
                {
                    name: "Home Care (Medical)",
                    show: this.showNewReferralHomeCare,
                    id: ApplicationEnum.HomeCare,
                    isNonMedical: false
                },
                {
                    name: "Home Care (Non-Medical)",
                    show: this.showNewReferralHomeCare,
                    id: ApplicationEnum.HomeCare,
                    isNonMedical: true
                }
            ]
        };
    },

    validations() {
        return {
            selectedBranch: {
                id: {
                    required
                }
            },
            lineOfService: {
                firstName: { required },
                lastName: { required }
            },
            selectedPrimaryLineOfService: { required: requiredIf(() => this.selectedBranch) }
        };
    },

    async created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._providersService = Container.instance.get(ProvidersService);
        this._branchesService = Container.instance.get(BranchesService);
        this._patientsService = Container.instance.get(PatientService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._referralService = Container.instance.get(ReferralService);
        this._agencyService = Container.instance.get(AgencyService);
        this.patientListPagination = this.$refs.patientListPagination;
        const providers = await this._providersService.getProvider();
        this.allBranchList = await this._branchesService.getAllBranches();
        this.isTestAgency = await helperTestingAgency.checkHasTestingAgency();
        this.allProviderList = providers.filter((provider: any) => provider.branches.length > 0);

        if (!!this.$route.query?.inquiryId && !!this.$route.query?.application && !!this.$route.query?.accountId) {
            this.currentApplication = Number(this.$route.query.application);
            const inquiryByIdParams = {
                application: this.$route.query.application,
                agencyId: this.$route.query.accountId
            };
            this.inquiry = await this._centralIntakeService.getInquiryById(
                this.$route.query.inquiryId,
                inquiryByIdParams
            );
        }
    },

    async mounted() {
        this.organizations = await this._agencyService.getOrganizations();
        const organizationId = localStorage.getItem(config.organizationId);
        const selectOrganization = this.organizations.filter((organization) => organization.id == organizationId);

        const selectedAgencies = selectOrganization[0].agencies;
        const agencyList = selectedAgencies.map((agency) => {
            let agencyAndApplicationIds = agency.id.split("_");
            let application = parseInt(agencyAndApplicationIds[1], 10);

            return {
                ...agency,
                application: application
            };
        });

        const showNewReferralHos = agencyList.some((el: any) => el.application === ApplicationEnum.AxxessHospiceFE);
        const showNewReferralPal = agencyList.some((el: any) => el.application === ApplicationEnum.AxxessPalliative);
        const showNewReferralHc = agencyList.some((el: any) => el.application === ApplicationEnum.HomeCare);
        const showNewReferralHh = agencyList.some((el: any) => el.application === ApplicationEnum.AgencyCore);
        this.patientListPagination = this.$refs.patientListPagination;
        this.showNewReferralHospice =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                this.hospiceApplicationId
            ) && showNewReferralHos;
        this.showNewReferralPalliative =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                this.palliativeApplicationId
            ) && showNewReferralPal;
        this.showNewReferralHomeHealth =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                this.homeHealthApplicationId
            ) && showNewReferralHh;
        this.showNewReferralHomeCare =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                this.homeCareApplicationId
            ) && showNewReferralHc;

        this.showNewReferral =
            this.showNewReferralHospice ||
            this.showNewReferralPalliative ||
            this.showNewReferralHomeHealth ||
            this.showNewReferralHomeCare;

        this.checkProductPermission = {
            showNewReferralHospice: this.showNewReferralHospice,
            showNewReferralPalliative: this.showNewReferralPalliative,
            showNewReferralHomeHealth: this.showNewReferralHomeHealth,
            showNewReferralHomeCare: this.showNewReferralHomeCare
        };
    },

    computed: {
        isApplicationHospice(): boolean {
            return this.lineOfService.application == ApplicationEnum.AxxessHospiceFE;
        },

        hasData() {
            return this.page?.itemCount > 0;
        }
    },

    watch: {
        searchPatientTerm(newValue) {
            this.searchPatientTerm = newValue.trim();
            if (!this.searchPatientTerm) {
                this.showPatientList = false;
            }
        },

        selectedBranch(newValue: any, oldValue) {
            if (!this.lineOfService) {
                return;
            }
            if (!newValue) {
                this.lineOfService.lineOfService = null;
                this.lineOfService.locationId = "";
                return;
            }
            if (!this.providerList?.length) {
                this.primaryLineOfServiceList = [];
                return;
            }
            this.lineOfService.locationId = this.selectedBranch.id;
            this.accountId = this.selectedBranch.accountId;
            for (let provider of this.providerList) {
                let hasBranch = provider.branches.some((branch: any) => {
                    return this.selectedBranch.id === branch.id;
                });
                if (hasBranch) {
                    this.primaryLineOfServiceList = provider.lineOfService;
                    break;
                }
            }
        },

        inquiry: {
            handler() {
                if (!!this.inquiry) {
                    this.lineOfService.firstName = this.inquiry.firstName;
                    this.lineOfService.lastName = this.inquiry.lastName;
                    if (this.inquiry.firstName || this.inquiry.lastName) {
                        this.searchPatientTerm = formatName(this.inquiry.firstName, this.inquiry.lastName);
                        this.pageChangedCallback(1, 10);
                    }
                }
            },
            deep: true
        },

        sortBy() {
            if (this.patientListPagination && this.hasData) {
                this.patientListPagination.reset();
            }
        },

        selectedBranches: {
            handler() {
                this.resetList();
            },
            deep: true
        }
    },

    methods: {
        updateBranches(branches: string[]) {
            this.selectedBranches.length = 0;
            this.selectedBranches.push(...branches);
        },

        sortByUpdated(value: string) {
            if (value != this.sortBy) {
                this.sortBy = value;
                this.resetList();
            }
        },

        sortOrderUpdated(value: string) {
            if (value != this.sortOrder) {
                this.sortOrder = value;
                this.resetList();
            }
        },

        initNewPatientReferral(applcationId: ApplicationEnum) {
            return new LineOfService(applcationId);
        },

        async pageChangedCallback(pageNumber: number = 1, pageSize: number = 10) {
            if (this.selectedBranches.length === 0) {
                return PAGE_FAIL_RESPONSE;
            }
            try {
                this.isError = false;
                this.showPatientList = true;
                this.isSearchLoading = true;
                let sortBy = this.sortBy == "serviceLine" ? "application" : this.sortBy;
                const payload: IGetPatientsByNameQuery = {
                    page: pageNumber,
                    pageSize: pageSize,
                    sortBy: sortBy,
                    order: this.sortOrder,
                    term: this.searchPatientTerm,
                    locationIds: this.selectedBranches
                };
                const data: IGetPatientsByNameResponse = await this._patientsService.getPatientsByName(payload);
                this.page = data;
                return {
                    totalPageCount: data.pageCount,
                    success: data.itemCount > 0,
                    totalCount: data.itemCount
                };
            } catch (e) {
                console.error(e);
                this.isError = true;
                return PAGE_FAIL_RESPONSE;
            } finally {
                this.isSearchLoading = false;
            }
        },

        isFieldInvalid(field: string) {
            let properties = this.v$.$errors.map((error: any) => error.$property);
            if (field === "branch") {
                return properties.includes("id") ? true : false;
            } else {
                return properties.includes("selectedPrimaryLineOfService") ? true : false;
            }
        },

        async addReferralForSelectedPatient() {
            const validated = await this.v$.$validate();
            if (validated) {
                try {
                    this.isAddingReferralLoading = true;
                    let params = {
                        agencyId: this.accountId,
                        accountId: this.accountId,
                        application: this.lineOfService.application
                    };
                    this.lineOfService.lineOfService = +this.selectedPrimaryLineOfService;
                    if (
                        this.lineOfService.application == this.hospiceApplicationId ||
                        this.lineOfService.application == this.palliativeApplicationId
                    ) {
                        const payload: ICreateInterimReferralPayload = { ...this.lineOfService, ...params };
                        let referralId;
                        if (!!this.inquiry) {
                            if (this.currentApplication == ApplicationEnum.AxxessPalliative) {
                                payload.lineOfService = LineOfServices.enum.PalliativeCare;
                            }
                            const response = await this._centralIntakeService.convertToReferral(
                                this.inquiry.id,
                                payload
                            );
                            referralId = response.id;
                        } else {
                            const response = await this._referralService.createInterimReferral(payload);
                            referralId = response.referralId;
                        }
                        this.$router.push(
                            `/axxess-central/create/referral/${referralId}/account/${this.accountId}/application/${this.lineOfService.application}/?isCreate=true`
                        );
                    } else if (this.lineOfService.application == this.homeHealthApplicationId) {
                        this.$router.push(
                            `/axxess-central/create/homehealth-referral/0000000-0000-0000-0000-0000000000000/account/${
                                this.accountId
                            }/application/${this.lineOfService.application}/?isCreate=true&fName=${
                                this.lineOfService.firstName
                            }&lName=${this.lineOfService.lastName}&locationId=${
                                this.lineOfService.locationId
                            }&convertToReferral=true&inquiryId=${this.inquiry ? this.inquiry.id : ""}`
                        );
                    } else if (this.lineOfService.application == this.homeCareApplicationId) {
                        this.$router.push({
                            path: "/axxess-central/create/homecare-referral",
                            query: {
                                account: this.accountId,
                                isNonMedical: String(this.isNonMedical),
                                isCreate: "true",
                                fName: this.lineOfService.firstName,
                                lName: this.lineOfService.lastName,
                                locationId: this.lineOfService.locationId,
                                inquiryId: this.inquiry ? this.inquiry.id : ""
                            }
                        });
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    this.isAddingReferralLoading = false;
                }
            } else {
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fix the validation errors in the form.`
                });
            }
        },

        openLineOfService(applicationId: ApplicationEnum, isNonMedical?: boolean) {
            this.v$.$reset();
            if (typeof isNonMedical !== "undefined") {
                this.isNonMedical = isNonMedical;
            }
            this.isLineOfServiceFormRequested = true;
            if (!this.inquiry) {
                this.lineOfService = this.initNewPatientReferral(applicationId);
            }
            this.lineOfService.application = applicationId;
            this.providerList = [];

            if ([ApplicationEnum.AxxessHospiceFE, ApplicationEnum.AxxessPalliative].includes(applicationId)) {
                this.providerList = this.allProviderList.filter(
                    (provider) => provider.branches[0].application == applicationId
                );
            } else {
                let providers: { [key: string]: string } = {};
                this.allBranchList.forEach((item) => {
                    if (item.providerId && item.application == applicationId) {
                        providers[item.providerId] = item.providerName;
                    }
                });
                for (let providerItem of Object.keys(providers)) {
                    let obj: IGetProviderItem = { id: null, name: null, branches: null };
                    let branch: IBranchResponse[] = [];
                    this.allBranchList.forEach((item) => {
                        if (providerItem == item.providerId) branch.push(item);
                    });
                    obj.id = providerItem;
                    obj.branches = branch;
                    obj.name = providers[providerItem];
                    this.providerList.push(obj);
                }
            }
            if (!!this.inquiry) {
                const result = this.providerList.find((provider) =>
                    provider.branches.find((branch: any) => branch.id === this.inquiry.locationId)
                );
                if (result) {
                    this.selectedBranch = result.branches.find((branch: any) => branch.id === this.inquiry.locationId);
                }
            }
        },

        cancel() {
            this.$router.push("/axxess-central/intake/referral-list");
        },

        closeLineOfService() {
            this.isLineOfServiceFormRequested = false;
            this.lineOfService = this.initNewPatientReferral(this.lineOfService.application);
            this.selectedBranch = null;
            this.selectedPrimaryLineOfService = null;
        },

        search() {
            this.showPatientList = true;
            this.isError = false;
            this.$nextTick(() => {
                this.resetList();
            });
            return true;
        },

        resetList() {
            this.isError = false;
            this.patientListPagination = this.$refs.patientListPagination;
            this.patientListPagination?.reset();
        },

        updatePageSizeChanged(value: number) {
            this.pageSize = value;
        }
    }
});
