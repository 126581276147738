import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";
import { RelationshipToInsured } from "../../../../../enums/enums";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";

import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        DatePicker
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            referral: {
                Referral: {
                    [this.insurer?.memberID]: { required }
                }
            }
        };
    },

    props: {
        insurer: {
            type: Object,
            default: null
        },
        referral: {
            type: HomeHealthReferral,
            default: null
        },
        referralOptions: {
            type: Object,
            default: null
        },
        clearAddressfields: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            test: "",
            relationshipToInsuredEnum: RelationshipToInsured,
            relationshipInsuredTo: 2,
            showSelfFields: false,
            formatDateInput,
            enumToMap
        };
    },

    watch: {
        relationshipInsuredTo() {
            this.referral.Referral[this.insurer?.relationship] = this.relationshipInsuredTo;
            if (this.insurer?.relationship)
                this.showSelfFields = !(this.relationshipToInsuredEnum.enum.Self == this.relationshipInsuredTo);
            this.insurer.isRelationshipNotSelf = this.showSelfFields;

            if (this.showSelfFields) {
                this.clearAddressfields(this.insurer.name);
            }
        }
    },

    methods: {
        onDateChange(date: any, name: string) {
            this.referral.Referral.AdditionalBillingInfo[this.insurer.details].InsuredDateOfBirth =
                formatDateInput(date);
        }
    }
});
