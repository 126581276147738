import axxessMessagingLandingPage from "../MessageCenter/axxess-messaging-landing-page-routes";

import CentralMessaging from "../MessageCenter/CentralMessaging/CentralMessaging.vue";
import CentralClinician from "../Clinician/CentralClinician.vue";

const routes: any = [
    {
        name: "Axxess Planner",
        path: "/clinician",
        component: CentralClinician,
        meta: {
            menuName: "Axxess Planner",
            navItem: "tabClinician"
        }
    },
    {
        name: "Axxess Chat",
        path: "/central-messaging/clinicians/conversation",
        component: CentralMessaging,
        meta: {
            menuName: "Axxess Chat",
            navItem: "tabMessaging",
            showCentralMessaging: true
        },
        children: axxessMessagingLandingPage
    }
];

export default routes;
