import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";
import type { IGetMarketingHospiceResponse } from "../resources-vue/vue-interfaces/i-marketing";

@noView()
@autoinject
export class MarketingService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/marketing";

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async getHospiceInteraction(): Promise<IGetMarketingHospiceResponse> {
        let marketingResponse = await this._fetchClient.build(this._baseUrl).fetch<IGetMarketingHospiceResponse>();
        return marketingResponse ?? null;
    }

    public async updateHospiceInteraction() {
        let response = await this._fetchClient
            .build(this._baseUrl)
            .useMethod("POST")
            .useBody({ Interacted: true })
            .fetch<IGetMarketingHospiceResponse>();
        return response ?? null;
    }
}
