import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../store/useEditReferralHH";

import ServiceRequired from "./ServiceRequired/ServiceRequired.vue";
import PatientDiagnosis from "./PatientDiagnosis/PatientDiagnosis.vue";
import ClinicalComments from "./ClinicalComments/ClinicalComments.vue";

export default defineComponent({
    components: {
        ServiceRequired,
        PatientDiagnosis,
        ClinicalComments
    },
    props: {
        isEditing: {
            type: Boolean,
            default: false
        },
        referralOptions: {
            type: Object,
            default: null
        },
        branchId: {
            type: String,
            default: ""
        },
        accountId: {
            type: String,
            default: ""
        }
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            application: "currentApplication",
            accountId: "accountId",
            referralOptions: "referralOptions"
        })
    },

    methods: {
        updateServicesRequired(value: string) {
            if (this.referral.Clinical.ServicesRequiredCollection.includes(value)) {
                const index = this.referral.Clinical.ServicesRequiredCollection.indexOf(value);
                this.referral.Clinical.ServicesRequiredCollection.splice(index, 1);
                return;
            }
            this.referral.Clinical.ServicesRequiredCollection.push(value);
        }
    }
});
