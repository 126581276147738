import { defineComponent } from "vue";

export default defineComponent({
    props: {
        message: { type: String, default: "" },
        okText: { type: String, default: "" },
        name: { type: String, default: "" },
        operation: { type: String, default: "" },
        btnClass: { type: String, default: "danger" },
        cancelText: { type: String, default: "" },
        includeYes: { type: Boolean, default: true },
        isInProgress: { type: Boolean, default: false },
        ok: { type: Function, default: null },
        cancel: { type: Function, default: null }
    },

    methods: {
        performOperation() {
            this.ok?.();
        },

        cancelOperation() {
            this.cancel?.();
        }
    }
});
