import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum AdmissionSourcesEnum {
    NonHealthcareFacility = 1 as any,
    ClinicOrPhysiciansOffice = 2 as any,
    TransferFromHospital = 4 as any,
    TransferFromSNFOrICF = 5 as any,
    TransferFromAnotherHealthcareFacility = 6 as any,
    CourtLawEnforcement = 8 as any,
    InformationNotAvailable = 9 as any
}
class AdmissionSourcesClass extends EnumWithDescription<AdmissionSourcesEnum> {
    public readonly enum = AdmissionSourcesEnum;
    public readonly enumName: string = "AdmissionSourcesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<AdmissionSourcesEnum>> = new Map([
        [
            "NonHealthcareFacility",
            {
                value: AdmissionSourcesEnum.NonHealthcareFacility,
                name: "Non-Healthcare Facility"
            }
        ],
        [
            "ClinicOrPhysiciansOffice",
            {
                value: AdmissionSourcesEnum.ClinicOrPhysiciansOffice,
                name: "Clinic or Physician's Office"
            }
        ],
        [
            "TransferFromHospital",
            {
                value: AdmissionSourcesEnum.TransferFromHospital,
                name: "Transfer From Hospital"
            }
        ],
        [
            "TransferFromSNFOrICF",
            {
                value: AdmissionSourcesEnum.TransferFromSNFOrICF,
                name: "Transfer From SNF or ICF"
            }
        ],
        [
            "TransferFromAnotherHealthcareFacility",
            {
                value: AdmissionSourcesEnum.TransferFromAnotherHealthcareFacility,
                name: "Transfer From Another Healthcare Facility"
            }
        ],
        [
            "CourtLawEnforcement",
            {
                value: AdmissionSourcesEnum.CourtLawEnforcement,
                name: "Court/Law Enforcement"
            }
        ],
        [
            "InformationNotAvailable",
            {
                value: AdmissionSourcesEnum.InformationNotAvailable,
                name: "Information Not Available"
            }
        ]
    ]);
}

export const AdmissionSources = new AdmissionSourcesClass();
