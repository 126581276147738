import { defineComponent, computed } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapState } from "pinia";

import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { IEnumResponse } from "../../../../../resources-vue/vue-interfaces/i-enum";
import { ProvidersService } from "../../../../../services/providers-service";
import { IPatientTags } from "../../../../../resources-vue/vue-interfaces/i-patient-tags";
import { ApplicationEnum } from "../../../../../enums/enums";
import { IGetPatientTag } from "../../../../../resources-vue/vue-interfaces/i-providers-service";
import { sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TagsInput from "../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import newTag from "../../../../../resources-vue/vue-dialogs/NewTag/NewTag.vue";

export default defineComponent({
    components: {
        TagsInput,
        FormSection
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    data() {
        return {
            _providersService: null,
            dialogRef: null,
            selectedTags: [] as ITypeaheadOptions[],
            patientTags: [] as IEnumResponse[],
            tagsTooltip: `<strong>Title</strong>
                          <p>Tags</p>
                          <hr>
                          <strong>Description</strong>
                          <p>Add tags to categorize patients with specific characteristics. To add a tag, use the drop-down menu or search function.
                          </p><hr>
                          <strong>Intent</strong>
                          <p>An unlimited number of tags can be assigned to each patient. For example, a patient can be assigned to the Team A tag and the Surgical Wound tag. If a user wants to view all patients in Team A with a surgical wound, the user can select Team A and Surgical Wound in the Tags filter to view patients who are assigned to both tags.
                          </p><hr>
                          <strong>Impact</strong>
                          <p>Tags enable users to easily locate patients from anywhere that the Tags filter is available in the system (e.g., patient charts).</p>`,
            sanitizeMarkup
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            accountId: "accountId",
            referral: "referral"
        })
    },

    created() {
        this._providersService = Container.instance.get(ProvidersService);
    },

    mounted() {
        this.bind();
    },

    watch: {
        selectedTags() {
            if (!!this.referral) {
                this.referral.Demographics.TagCollection = this.selectedTags?.map((tags) => tags.value);
            }
        }
    },

    methods: {
        async bind() {
            const patientTagsQuery: IPatientTags = {
                isActive: true,
                application: ApplicationEnum.AgencyCore,
                accountId: this.accountId
            };

            let patientTags = await this._providersService.getActivePatientTags(patientTagsQuery);
            this.patientTags = patientTags.map((tag: IGetPatientTag) => ({
                name: tag.name,
                value: tag.id
            }));
            this.selectedTags = this.patientTags.filter((tag) => {
                return this.referral.Demographics.TagCollection.includes(String(tag.value));
            }) as ITypeaheadOptions[];
        },
        updateTags(newValue: any) {
            this.selectedTags = newValue;
        },
        newTagDialog() {
            this.dialogRef = this.$dialog.open(newTag, {
                props: {
                    modal: true,
                    contentStyle: {
                        width: "800px",
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    agencyId: this.accountId,
                    userId: this.referral.Demographics.UserId
                },
                onClose: (response) => {
                    if (response.data == "success") {
                        this.bind();
                    }
                }
            });
        }
    }
});
