import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { BranchesService } from "../../../../../services/branches-service";
import { CentralIntakeService } from "../../../../../services/central-intake.service";
import { ApplicationNames } from "../../../../../enums/enums";
import { IIntakeCountResponse } from "../../../../../resources-vue/vue-interfaces/i-get-inquiry";
import { clubAgencies } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import CardTemplate from "../../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";

export default defineComponent({
    components: {
        CardTemplate
    },

    data() {
        return {
            _permissionManager: null,
            _branchService: null,
            _centralIntakeService: null,
            selectedBranches: [],
            branchIds: [],
            intakeCount: [],
            inquiryCountData: [] as IIntakeCountResponse[],
            referralCountData: [] as IIntakeCountResponse[],
            ApplicationNamesEnum: ApplicationNames,
            isLoading: true,
            hasError: false,
            today: null
        };
    },
    async created() {
        this._permissionManager = Container.instance.get(PermissionManager);
        this._branchService = Container.instance.get(BranchesService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);

        let branches = await this._branchService.getBranchDetails();
        branches.forEach((branch: any) => {
            this.branchIds.push(branch.id);
        });
        this.today = new Date(Date.now());

        await this.getIntakeCount();
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),

        async getIntakeCount() {
            try {
                this.hasError = false;
                this.isLoading = true;

                this.inquiryCountData = clubAgencies(
                    await this._centralIntakeService.getInquiryCount({
                        LocationIds: this.branchIds,
                        Date: this.today
                    })
                );

                this.referralCountData = clubAgencies(
                    await this._centralIntakeService.getReferralCount({
                        LocationIds: this.branchIds,
                        Date: this.today
                    })
                );

                // We don't have HH inquiry and for table we need to match inquiry and referral, So added static data for HH
                let inquiryHhData = [
                    {
                        application: 1,
                        agencyId: "14c5e22f-3f32-44d4-8e6c-894b3e79a418",
                        count: 0
                    }
                ];

                let inquiryData = this.inquiryCountData.concat(inquiryHhData);

                inquiryData.forEach((inquiry: IIntakeCountResponse) => {
                    this.referralCountData.forEach((ref) => {
                        if (ref.application == inquiry.application) {
                            this.intakeCount.push({
                                application: ref.application,
                                inquiryCount: inquiry.count,
                                referralCount: ref.count
                            });
                        }
                    });
                });
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
