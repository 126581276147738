import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../../../store/useEditReferralHH";

import DatePicker from "../../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        DatePicker
    },
    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral"
        })
    },
    props: {
        type: { type: String }
    },

    data() {
        return {
            options: [
                {
                    label: "Patient within their sixty-day period on OUR services.",
                    value: 1
                },
                {
                    label: "Patient on services with Another Agency. Need Beneficiary Transfer Statement.",
                    value: 2
                }
            ]
        };
    },
    methods: {
        onDateChange(date: any, name: string) {
            if (name == "effective-date-a") {
                if (this.type == "Primary") {
                    this.referral.Insurance.PrimaryMedicarePartADate = date;
                }
                if (this.type == "Secondary") {
                    this.referral.Insurance.SecondaryMedicarePartADate = date;
                }
                if (this.type == "Tertiary") {
                    this.referral.Insurance.TertiaryMedicarePartADate = date;
                }
            }
            if (name == "effective-date-b") {
                if (this.type == "Primary") {
                    this.referral.Insurance.PrimaryMedicarePartBDate = date;
                }
                if (this.type == "Secondary") {
                    this.referral.Insurance.SecondaryMedicarePartBDate = date;
                }
                if (this.type == "Tertiary") {
                    this.referral.Insurance.TertiaryMedicarePartBDate = date;
                }
            }
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
        }
    }
});
