import { computed, defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

import {
    ApplicationEnum,
    ContactRoles,
    Gender,
    HttpStatusCodeEnum,
    InternalReferralSource,
    LineOfServices,
    MaritalStatuses,
    ParentPermissionEnum,
    PermissionActionEnum,
    SourceOfReferralEnum
} from "../../../enums/enums";
import { BranchesService } from "../../../services/branches-service";
import { ToastrService } from "../../../services/toastr.service";
import { ReferralService } from "../../../services/referral-service";
import { PatientService } from "../../../services/patient.service";
import { UserService } from "../../../services/user-service";
import { UserManager } from "oidc-client";
import { HomeHealthPatient } from "../../../resources-vue/vue-interfaces/i-homeHealth-patient";
import { INonAdmitHHPatientQuery, INonAdmitPatientQuery } from "../../../interfaces/i-get-referral";
import { IPhoneNumber } from "../../../interfaces/i-phone-number";
import { CentralIntakeService } from "../../../services/central-intake.service";

import { PermissionManager } from "../../../common/utilities/permission-manager";
import { HospiceReferral } from "../../../resources-vue/vue-models/hospice-referral";
import { HomeCareReferral } from "../../../resources-vue/vue-models/homecare-referral";
import { scrollToFirstError } from "../../../common/utilities/first-error-scroll";
import { useCreateReferralHosPal } from "./store/useCreateReferralHosPal";
import { useLoadingIndicator } from "../../Common/LoadingIndicator/store/useLoadingIndicator";
import { formatName } from "../../../common/format-name";

import PageLayout from "../../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import Prompt from "../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";
import HosPalReferralInformation from "./components/HosPalReferralInformation/HosPalReferralInformation.vue";
import PayorInformation from "./components/PayorInformation/PayorInformation.vue";
import AdmissionInformation from "./components/PatientPayorInformation/AdmissionInformation/AdmissionInformation.vue";
import PatientAuthorizedContacts from "./components/PatientAuthorizedContacts/PatientAuthorizedContacts.vue";
import PatientHealthHistory from "./components/PatientHealthHistory/PatientHealthHistory.vue";
import PatientPreadmissionNotes from "./components/PatientPreadmissionNotes/PatientPreadmissionNotes.vue";
import DemographicsInformation from "./components/DemographicsInformation/DemographicsInformation.vue";
import PatientTags from "./components/PatientTags/PatientTags.vue";
import ClinicianInformation from "./components/ClinicianInformation/ClinicianInformation.vue";
import ReferralReadiness from "./components/ReferralReadiness/ReferralReadiness.vue";
import ReferralDocuments from "./components/ReferralDocuments/ReferralDocuments.vue";

export default defineComponent({
    components: {
        PageLayout,
        HosPalReferralInformation,
        PayorInformation,
        AdmissionInformation,
        PatientAuthorizedContacts,
        PatientHealthHistory,
        PatientPreadmissionNotes,
        DemographicsInformation,
        PatientTags,
        ClinicianInformation,
        ReferralReadiness,
        ReferralDocuments
    },

    data() {
        return {
            _centralIntake: null,
            _branchesService: null,
            _toastrService: null,
            _patientService: null,
            _userService: null,
            _currentUsersService: null,
            _userManager: null,
            dialogRef: null,
            _referralService: null,
            _permissionManager: null,
            isFormLoading: false,
            isLoadError: false,
            isSaveInProgress: false,
            hasErrorOnSave: false,
            isCreate: false,
            canShowReferralDocuments: false,
            accountId: "" as string,
            referralId: "" as string,
            newPatientId: "" as string,
            locationId: "" as string,
            patientApplication: "" as string,
            patientBranchId: "" as string,
            patientFirstName: "" as string,
            patientLastName: "" as string,
            isNonAdmit: false,
            providerId: "",
            isHisSection: true,
            ContactRoles: Array.from(ContactRoles.enumWithDescriptions.values()),
            palliativeApplicationId: ApplicationEnum.AxxessPalliative,
            hospiceApplicationId: ApplicationEnum.AxxessHospiceFE,
            currentApplication: ApplicationEnum.AxxessHospiceFE,
            notesSection: null,
            referredPatient: null as HomeHealthPatient,
            referringAuthorizedContact: null,
            referringPatientDiagnosis: null,
            lineOfServiceEnumMap: LineOfServices,
            internalReferralSource: InternalReferralSource,
            error: false,
            errors: null,
            agencyId: null as string,
            applicationId: null as ApplicationEnum,
            patientId: null as string,
            currentUserId: null as string,
            _user: null,
            isPatient: false,
            isRefer: false,
            v$: useVuelidate(),
            formatName
        };
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },
    async created() {
        this._branchesService = Container.instance.get(BranchesService);
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._centralIntake = Container.instance.get(CentralIntakeService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._patientService = Container.instance.get(PatientService);
        this._userService = Container.instance.get(UserService);
        this._userManager = Container.instance.get(UserManager);

        if (this.$route.query?.isCreate === "true" && !this.$route.params.patientId) {
            this.isCreate = true;
        }
        if (this.$route.params?.isPatient == "true") {
            this.isPatient = true;
        }
        if (this.$route.query?.isCreate === "true" && this.$route.params.patientId) {
            this.patientId = this.$route.params?.patientId as string;
            this.applicationId = this.$route.params?.applicationId as any;
            this.agencyId = this.$route.params?.accountId as string;
            this.isCreate = true;
        }
        if (this.$route.query?.newPatientId) {
            this.newPatientId = this.$route.query?.newPatientId as string;
        }
        if (this.$route.params?.applicationId) {
            this.currentApplication = Number(this.$route.params.applicationId);
            if (this.currentApplication == ApplicationEnum.AxxessPalliative) {
                this.isHisSection = false;
            }
        }
        if (this.$route.params?.patientApplication) {
            this.patientApplication = this.$route.params?.patientApplication as string;
        }
        if (this.$route.params?.patientBranchId) {
            this.patientBranchId = this.$route.params?.patientBranchId as string;
        }
        if (this.$route.params?.locationId) {
            this.locationId = this.$route.params?.locationId as string;
        }
        if (this.$route.params?.accountId) {
            this.accountId = this.$route.params.accountId as string;
        }
        if (this.$route.params?.isRefer === "true") {
            this.isRefer = true as boolean;
        }
        if (this.$route.params?.isNonAdmit === "true") {
            this.isNonAdmit = true as boolean;
        }
        if (this.$route.params?.id) {
            this.referralId = this.$route.params.id as string;
            await this.loadReferral();
        } else {
            this._toastrService.error({
                title: `Error`,
                message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
            });
            this.$router.push("/axxess-central/intake/referral-list");
        }
        this.canShowReferralDocuments = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.documents,
            PermissionActionEnum.canView,
            this.currentApplication
        );
    },

    async mounted() {
        this.notesSection = this.$refs.notesSection;
        let hash = window.location.hash;

        if (!!hash) {
            this.notesSection.scrollIntoView();
        }
    },
    computed: {
        ...mapState(useCreateReferralHosPal, ["referral"])
    },

    methods: {
        ...mapActions(useCreateReferralHosPal, { setReferral: "SET_REFERRAL" }),
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        initReferral(application: ApplicationEnum) {
            return new HospiceReferral(application);
        },
        async loadReferral() {
            try {
                this.showLoading(true);
                this.isLoadError = false;
                this.isFormLoading = true;
                if (this.referralId && !this.isRefer) {
                    let referral = (await this.getReferralData()) as HospiceReferral;
                    let newReferral = new HospiceReferral(this.currentApplication, referral);
                    newReferral.phoneNumbers = referral.phoneNumbers.sort(
                        (item1: IPhoneNumber, item2: IPhoneNumber) => {
                            if (item1.isPrimary && !item2.isPrimary) {
                                return -1;
                            } else if (item1.isPrimary && !item2.isPrimary) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    );

                    this.setReferral(newReferral);
                    await this.setProvider();
                } else if (this.referralId && this.isRefer) {
                    let userName = "";
                    this.getCurrentUser();
                    this._user = await this._userManager.getUser();

                    if (this._user) {
                        userName = formatName(this._user.profile.given_name, this._user?.profile.family_name);
                    }
                    if (this.patientApplication === ApplicationEnum.AgencyCore.toString()) {
                        this.referredPatient = this.isPatient
                            ? await this.getHHReferPatientData()
                            : await this.getHHReferReferralData();
                        let newReferral = new HospiceReferral(this.currentApplication);
                        newReferral.id = String(this.referralId);
                        newReferral.patientId = String(this.newPatientId);
                        newReferral.accountId = String(this.accountId);
                        newReferral.application = Number(this.applicationId);
                        newReferral.referralDate = String(moment().format("MM/DD/YYYY"));
                        newReferral.locationId = String(this.locationId);
                        newReferral.lineOfService =
                            this.currentApplication == ApplicationEnum.AxxessPalliative
                                ? this.lineOfServiceEnumMap.enum.PalliativeCare
                                : this.lineOfServiceEnumMap.enum.Hospice;
                        newReferral.sourceOfReferral = SourceOfReferralEnum.Internal;
                        newReferral.internalReferralSource = this.internalReferralSource.enum.HomeHealth;
                        newReferral.agencyId = String(this.agencyId);
                        newReferral.internalReferralSourceId = this.currentUserId;
                        newReferral.internalReferralSourceName = userName;
                        newReferral.referralReason = "Internal referal from (Home Health) patient";

                        newReferral.firstName = this.patientFirstName = this.referredPatient.Demographics.FirstName;
                        newReferral.middleInitial = this.referredPatient.Demographics.MiddleInitial;
                        newReferral.lastName = this.patientLastName = this.referredPatient.Demographics.LastName;
                        newReferral.dateOfBirth = this.referredPatient.Demographics.DOB;
                        newReferral.gender = Gender.getValueUsingKey(this.referredPatient.Demographics.Gender);
                        newReferral.socialSecurityNumber = this.referredPatient.Demographics.SSN;
                        let phoneNumbersHH = [
                            {
                                number: this.referredPatient.Demographics.PhonePreferred,
                                extension: "",
                                type: 0,
                                country: null as any,
                                isPrimary: true
                            },
                            {
                                number: this.referredPatient.Demographics.PhoneAlternate,
                                extension: "",
                                type: 0,
                                country: null as any,
                                isPrimary: false
                            }
                        ];
                        newReferral.phoneNumbers = phoneNumbersHH;
                        newReferral.email = this.referredPatient.Demographics.EmailAddress;
                        if (
                            this.referredPatient.Demographics.MaritalStatus &&
                            this.referredPatient.Demographics.MaritalStatus !== "0"
                        ) {
                            newReferral.maritalStatus = MaritalStatuses.getValueUsingKey(
                                this.referredPatient.Demographics.MaritalStatus
                            );
                        }
                        // don't comment for Add Enum for this numbers , will gonna do in next connected pr of this one

                        let isHispanicOrLatinos = ["0", "5", "6"];
                        let isHispanicOrLatin = false;
                        if (
                            this.referredPatient.Demographics.EthnicitiesOnly &&
                            !this.referredPatient.Demographics.EthnicitiesOnlyCollection?.some((isHispanicOrLatino) =>
                                isHispanicOrLatinos.includes(isHispanicOrLatino)
                            )
                        ) {
                            newReferral.ethnicity.push(8);
                            isHispanicOrLatin = true;
                        }
                        if (this.referredPatient.Demographics.Races) {
                            if (this.referredPatient.Demographics.Races.includes("0")) {
                                newReferral.ethnicity.push(32);
                            }
                            if (this.referredPatient.Demographics.Races.includes("1")) {
                                newReferral.ethnicity.push(4);
                            }
                            if (this.referredPatient.Demographics.Races.includes("2")) {
                                newReferral.ethnicity.push(1);
                            }
                            let isAsians = ["3", "4", "5", "6", "7", "8", "9"];
                            if (
                                this.referredPatient.Demographics.RacesCollection?.some((isAsian) =>
                                    isAsians.includes(isAsian)
                                )
                            ) {
                                newReferral.ethnicity.push(2);
                            }
                            let isPacifics = ["10", "11", "12", "13"];
                            if (
                                this.referredPatient.Demographics.RacesCollection?.some((isPacific) =>
                                    isPacifics.includes(isPacific)
                                )
                            ) {
                                newReferral.ethnicity.push(16);
                            }
                            newReferral.declineToSpecifyRace = false;
                            let hasNoRaces = ["14", "15", "16"];
                            if (
                                this.referredPatient.Demographics.RacesCollection?.some((hasNoRace) =>
                                    hasNoRaces.includes(hasNoRace)
                                )
                            ) {
                                newReferral.ethnicity = [0];
                                newReferral.declineToSpecifyRace = true;
                            }
                        }
                        if (this.referredPatient.Demographics.PrimaryAddress) {
                            newReferral.primaryAddress.addressLine1 =
                                this.referredPatient.Demographics.PrimaryAddress.AddressLine1;
                            newReferral.primaryAddress.addressLine2 =
                                this.referredPatient.Demographics.PrimaryAddress.AddressLine2;
                            newReferral.primaryAddress.city = this.referredPatient.Demographics.PrimaryAddress.City;
                            newReferral.primaryAddress.county = this.referredPatient.Demographics.PrimaryAddress.County;
                            newReferral.primaryAddress.state = this.referredPatient.Demographics.PrimaryAddress.State;
                            newReferral.primaryAddress.zipCode = this.referredPatient.Demographics.PrimaryAddress.Zip;
                        }
                        newReferral.isVisitAddressSameAsPrimary = false;
                        if (this.referredPatient.Demographics.IsVisitAddress) {
                            newReferral.visitAddress.addressLine1 =
                                this.referredPatient.Demographics.VisitAddress.AddressLine1;
                            newReferral.visitAddress.addressLine2 =
                                this.referredPatient.Demographics.VisitAddress.AddressLine2;
                            newReferral.visitAddress.city = this.referredPatient.Demographics.VisitAddress.City;
                            newReferral.visitAddress.county = this.referredPatient.Demographics.VisitAddress.County;
                            newReferral.visitAddress.state = this.referredPatient.Demographics.VisitAddress.State;
                            newReferral.visitAddress.zipCode = this.referredPatient.Demographics.VisitAddress.Zip;
                        }
                        if (this.referredPatient.Demographics.MailingAddress) {
                            newReferral.mailingAddress.addressLine1 =
                                this.referredPatient.Demographics.MailingAddress.AddressLine1;
                            newReferral.mailingAddress.addressLine2 =
                                this.referredPatient.Demographics.MailingAddress.AddressLine2;
                            newReferral.mailingAddress.city = this.referredPatient.Demographics.MailingAddress.City;
                            newReferral.mailingAddress.county = this.referredPatient.Demographics.MailingAddress.County;
                            newReferral.mailingAddress.state = this.referredPatient.Demographics.MailingAddress.State;
                            newReferral.mailingAddress.zipCode = this.referredPatient.Demographics.MailingAddress.Zip;
                            newReferral.isMailingAddressSameAsPrimary =
                                this.referredPatient.Demographics.SameAsPhysicalAddress;
                        }
                        newReferral.medicareBeneficiaryIdentificationNA = this.referredPatient.Insurance.IsMbiNA;
                        newReferral.medicareBeneficiaryIdentification = this.referredPatient.Insurance.MbiNumber;
                        newReferral.medicaidNumber = this.referredPatient.Insurance.MedicaidNumber;
                        newReferral.notAMedicaid = this.referredPatient.Insurance.IsMedicaidNA;

                        this.referringAuthorizedContact = this.referredPatient.Contacts;

                        this.referringPatientDiagnosis = this.referredPatient.Clinical.PrimaryPatientDiagnosisCodes;
                        if (this.referredPatient.Clinical.PatientDiagnosisCodes.length) {
                            newReferral.diagnoses.push({
                                description: this.referredPatient.Clinical.PatientDiagnosisCodes[0]?.ShortDescription,
                                code: this.referredPatient.Clinical.PatientDiagnosisCodes[0]?.DiagnosisCode,
                                order: 1,
                                billable: true
                            });
                        }
                        this.setReferral(newReferral);
                        await this.setProvider();
                    }
                    if (
                        this.patientApplication === ApplicationEnum.AxxessHospiceFE.toString() ||
                        this.patientApplication === ApplicationEnum.AxxessPalliative.toString()
                    ) {
                        let referral = (await this.getHospicePatientData()).data.model;

                        let newReferral = new HospiceReferral(this.currentApplication, referral);
                        const filteredPhoneNumbers = referral.phoneNumbers?.map((item: IPhoneNumber) => {
                            delete item.id;
                            return item;
                        });
                        this.patientFirstName = newReferral.firstName;
                        this.patientLastName = newReferral.lastName;
                        newReferral.id = String(this.referralId);
                        newReferral.patientId = String(this.newPatientId);
                        newReferral.accountId = String(this.accountId);
                        newReferral.application = Number(this.applicationId);
                        newReferral.referralDate = String(moment().format("MM/DD/YYYY"));
                        newReferral.locationId = String(this.locationId);
                        newReferral.phoneNumbers = filteredPhoneNumbers;
                        newReferral.lineOfService =
                            this.currentApplication == ApplicationEnum.AxxessPalliative
                                ? this.lineOfServiceEnumMap.enum.PalliativeCare
                                : this.lineOfServiceEnumMap.enum.Hospice;
                        newReferral.sourceOfReferral = SourceOfReferralEnum.Internal;
                        newReferral.internalReferralSource =
                            this.currentApplication == ApplicationEnum.AxxessPalliative
                                ? this.internalReferralSource.enum.Hospice
                                : this.lineOfServiceEnumMap.enum.PalliativeCare;
                        newReferral.agencyId = String(this.agencyId);
                        newReferral.internalReferralSourceId = this.currentUserId;
                        newReferral.internalReferralSourceName = userName;
                        newReferral.referralReason =
                            this.currentApplication == ApplicationEnum.AxxessPalliative
                                ? "Internal referal from (Hospice) patient"
                                : "Internal referal from (Palliative) patient";

                        this.setReferral(newReferral);
                        await this.setProvider();
                    }
                    if (this.patientApplication === ApplicationEnum.HomeCare.toString()) {
                        let referral = this.isPatient
                            ? await this.getHomeCarePatientData()
                            : await this.getHomeCareReferralData();
                        let newReferral = new HospiceReferral(this.currentApplication);

                        let filteredHCPhoneNumbers: any[] = [];
                        referral.phoneNumbers.forEach((item: any) => {
                            let check: boolean = true;
                            if (this.isPatient) {
                                check = filteredHCPhoneNumbers
                                    ? filteredHCPhoneNumbers.some((hcNumber) => hcNumber.number == item.number)
                                    : false;
                            } else {
                                check = false;
                            }
                            if (!check && (item.ordinal == 1 || item.ordinal == 2)) {
                                let hcNumber: IPhoneNumber = {} as IPhoneNumber;
                                hcNumber.number = item.number;
                                hcNumber.extension = item.extension;
                                hcNumber.type = item.type !== 4 ? item.type : 0;
                                hcNumber.country = item.country;
                                hcNumber.isPrimary = item.ordinal == 1 ? true : false;
                                filteredHCPhoneNumbers.push(hcNumber);
                            }
                        });

                        await this.setProvider();

                        newReferral.id = String(this.referralId);
                        newReferral.patientId = String(this.newPatientId);
                        newReferral.accountId = String(this.accountId);
                        newReferral.application = Number(this.applicationId);
                        newReferral.referralDate = String(moment().format("MM/DD/YYYY"));
                        newReferral.locationId = String(this.locationId);
                        newReferral.lineOfService =
                            this.currentApplication == ApplicationEnum.AxxessPalliative
                                ? this.lineOfServiceEnumMap.enum.PalliativeCare
                                : this.lineOfServiceEnumMap.enum.Hospice;
                        newReferral.sourceOfReferral = SourceOfReferralEnum.Internal;
                        newReferral.internalReferralSource = this.internalReferralSource.enum.HomeCare;
                        newReferral.agencyId = String(this.agencyId);
                        newReferral.internalReferralSourceId = this.currentUserId;
                        newReferral.internalReferralSourceName = userName;
                        newReferral.referralReason = "Internal referal from (Home Care) patient";
                        newReferral.providerId = this.providerId;

                        newReferral.firstName = this.patientFirstName = referral.firstName;
                        newReferral.middleInitial = this.isPatient ? referral.middleInitial : referral.middleName;
                        newReferral.lastName = this.patientLastName = referral.lastName;
                        newReferral.dateOfBirth = String(moment(referral.dob).format("MM/DD/YYYY"));
                        newReferral.phoneNumbers = filteredHCPhoneNumbers;
                        newReferral.gender = this.isPatient
                            ? Gender.getValueUsingKey(referral.gender)
                            : Number(referral.gender);
                        newReferral.email = referral.emailAddress;

                        newReferral.socialSecurityNumber = this.referral.socialSecurityNumber;
                        if (newReferral.maritalStatus && referral.maritalStatus !== "0") {
                            newReferral.maritalStatus = this.isPatient
                                ? MaritalStatuses.getValueUsingKey(referral.maritalStatus)
                                : Number(referral.maritalStatus);
                        }
                        newReferral.primaryAddress.addressLine1 = this.isPatient
                            ? referral.address.line1
                            : referral.primaryAddress.addressLine1;
                        newReferral.primaryAddress.addressLine2 = this.isPatient
                            ? referral.address.line2
                            : referral.primaryAddress.addressLine2;
                        newReferral.primaryAddress.city = this.isPatient
                            ? referral.address.locality
                            : referral.primaryAddress.city;
                        newReferral.primaryAddress.county = this.isPatient
                            ? referral.address.subAdministrativeArea
                            : referral.primaryAddress.county;
                        newReferral.primaryAddress.state = this.isPatient
                            ? referral.address.subAdministrativeArea
                            : referral.primaryAddress.state;
                        newReferral.primaryAddress.zipCode = this.isPatient
                            ? referral.address.zipcode
                            : referral.primaryAddress.zipCode;

                        newReferral.isVisitAddressSameAsPrimary = referral.isVisitAddressSameAsPrimary;
                        newReferral.isMailingAddressSameAsPrimary = referral.isMailingAddressSameAsPrimary;

                        if (!referral.isVisitAddressSameAsPrimary && referral.visitAddress) {
                            newReferral.visitAddress.addressLine1 = this.isPatient
                                ? referral.address.line1
                                : referral.visitAddress.addressLine1;
                            newReferral.visitAddress.addressLine2 = this.isPatient
                                ? referral.address.line2
                                : referral.visitAddress.addressLine2;
                            newReferral.visitAddress.city = this.isPatient
                                ? referral.address.locality
                                : referral.visitAddress.city;
                            newReferral.visitAddress.county = this.isPatient
                                ? referral.address.subAdministrativeArea
                                : referral.visitAddress.county;
                            newReferral.visitAddress.state = this.isPatient
                                ? referral.address.subAdministrativeArea
                                : referral.visitAddress.state;
                            newReferral.visitAddress.zipCode = this.isPatient
                                ? referral.address.zipcode
                                : referral.visitAddress.zipCode;
                        }

                        if (!referral.isMailingAddressSameAsPrimary && referral.mailingAddress) {
                            newReferral.mailingAddress.addressLine1 = this.isPatient
                                ? referral.address.line1
                                : referral.mailingAddress.addressLine1;
                            newReferral.mailingAddress.addressLine2 = this.isPatient
                                ? referral.address.line2
                                : referral.mailingAddress.addressLine2;
                            newReferral.mailingAddress.city = this.isPatient
                                ? referral.address.locality
                                : referral.mailingAddress.city;
                            newReferral.mailingAddress.county = this.isPatient
                                ? referral.address.subAdministrativeArea
                                : referral.mailingAddress.county;
                            newReferral.mailingAddress.state = this.isPatient
                                ? referral.address.subAdministrativeArea
                                : referral.mailingAddress.state;
                            newReferral.mailingAddress.zipCode = this.isPatient
                                ? referral.address.zipcode
                                : referral.mailingAddress.zipCode;
                        }

                        newReferral.medicaidNumber = referral.medicaidNumber;
                        newReferral.medicaidPending = this.isPatient
                            ? referral.medicaidPending
                            : referral.medicaidPending;
                        newReferral.medicareBeneficiaryIdentificationNA = referral.medicareBeneficiaryIdentificationNA;
                        newReferral.medicareBeneficiaryIdentification = referral.medicareBeneficiaryIdentification;
                        referral.admissionSource = 0;
                        referral.admissionType = 0;
                        this.setReferral(newReferral);
                    }
                } else {
                    throw new Error("Referral Id is missing");
                }
            } catch (e) {
                this.isLoadError = true;
                console.error(e);
            } finally {
                this.showLoading(false);
                this.isFormLoading = false;
            }
        },

        async getCurrentUser() {
            const agencyId = this.accountId;

            try {
                let response = await this._userService.getCurrentUserId({ agencyId: agencyId });
                this.currentUserId = response.userId;
            } catch (err) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this User Id. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async getReferralData() {
            const queryParam = {
                application: this.currentApplication,
                accountId: this.accountId,
                id: this.referralId
            };
            let referral = (await this._referralService.getReferral(this.referralId, queryParam)) as HospiceReferral;
            return referral;
        },

        async getHospicePatientData() {
            const queryParam = {
                application: this.patientApplication,
                agencyId: this.patientBranchId,
                patientId: this.patientId
            };

            let referral = await this._patientService.getHospicePatientById(queryParam);
            return referral;
        },

        async getHomeCarePatientData() {
            const queryParam = {
                application: this.patientApplication,
                agencyId: this.patientBranchId,
                patientId: this.patientId
            };

            let referral = (await this._patientService.getHCPatientById(queryParam)).data;
            return referral;
        },

        async getHomeCareReferralData() {
            const queryParam = {
                application: this.patientApplication,
                accountId: this.patientBranchId,
                id: this.patientId
            };

            let referral = (await this._referralService.getHomeCareReferral(
                this.referralId,
                queryParam
            )) as HomeCareReferral;
            return referral;
        },

        async getHHReferPatientData() {
            try {
                const result = await this._patientService.getHHPatientAllData({
                    agencyId: this.patientBranchId,
                    patientId: this.patientId
                });
                if (result) {
                    let tempReferral = result.data;
                    return tempReferral;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Home Health patient. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async getHHReferReferralData() {
            try {
                const result = await this._patientService.getEditHHReferral({
                    referralId: this.patientId,
                    agencyId: this.patientBranchId,
                    application: this.$route.params?.application
                });
                if (result) {
                    let tempReferral = result.data;
                    tempReferral.AgencyId = this.accountId;
                    return tempReferral;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Home Health patient. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async setProvider() {
            if (!!this.referral.providerId) {
                this.providerId = this.referral.providerId;
            } else {
                let patientBranchId = this.locationId;
                let branches = await this._branchesService.getAllBranches();
                let patientBranch = branches.find((branch: any) => branch.id === patientBranchId);
                this.providerId = patientBranch?.providerId ?? "";
            }
        },
        checkLatAndLong() {
            return (
                !this.referral.primaryAddress.isValidated &&
                !this.referral.primaryAddress.isBypassed &&
                !this.referral.primaryAddress.longitude &&
                !this.referral.primaryAddress.latitude
            );
        },
        async create() {
            let addressNotValidated = this.checkLatAndLong();
            if (addressNotValidated) {
                return;
            }
            if (!this.referral.hasPreviousAddress && !!this.referral?.previousAddress) {
                this.referral.previousAddress = null;
            }

            let isModelValid = await this.v$.$validate();
            if (isModelValid) {
                try {
                    this.hasErrorOnSave = false;
                    this.isSaveInProgress = true;
                    const response = await this.createReferral();
                    if (response.referralId) {
                        this._toastrService.success({
                            title: `Referral Created`,
                            message: `The Referral <b>${this.referral.lastName} ${this.referral.firstName}</b> has been successfully created.`
                        });
                    }
                    if (this.isRefer && this.isNonAdmit) {
                        this.doNonAdmit();
                    }
                    this.$router.push("/axxess-central/intake/referral-list");
                } catch (err) {
                    this._toastrService.error({
                        title: `Error`,
                        message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
                    });
                } finally {
                    this.isSaveInProgress = false;
                }
            } else {
                scrollToFirstError();
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fix the validation errors in the form.`
                });
            }
        },

        async createReferral() {
            try {
                this.referral.id = this.referralId;
                this.referral.agencyId = this.accountId;
                if (this.currentApplication == ApplicationEnum.AxxessHospiceFE) {
                    const response = await this._referralService.createHospiceReferral(this.referral);
                    return response;
                }

                if (this.currentApplication == ApplicationEnum.AxxessPalliative) {
                    const response = await this._referralService.createPalliativeReferral(this.referral);
                    return response;
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async update() {
            if (!this.referral.hasPreviousAddress && !!this.referral?.previousAddress) {
                this.referral.previousAddress = null;
            }
            let isModelValid = await this.v$.$validate();
            if (isModelValid) {
                try {
                    this.isSaveInProgress = true;
                    await this.updateReferral();
                    this._toastrService.success({
                        title: `Referral Updated`,
                        message: `The Referral <b>${this.referral.lastName} ${this.referral.firstName}</b> has been successfully updated.`
                    });
                } catch (err) {
                    console.error(err);
                    if (err.status === HttpStatusCodeEnum.NotModified) {
                        this._toastrService.info({
                            title: `The referral <b>${this.referral.lastName} ${this.referral.firstName}</b> is up to date`,
                            message: `There were no changes made in ${this.referral.lastName} ${this.referral.firstName}'s profile.`
                        });
                    } else {
                        this._toastrService.error({
                            title: `Error`,
                            message: `There was a problem while saving this referral. Please try again. If the problem persists, please report it.`
                        });
                    }
                } finally {
                    this.isSaveInProgress = false;
                }
            } else {
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fix the validation errors in the form.`
                });
            }
        },
        async updateReferral() {
            if (this.currentApplication == ApplicationEnum.AxxessHospiceFE) {
                await this._referralService.editHospiceReferral(this.referral);
            } else {
                await this._referralService.editPalliativeReferral(this.referral);
            }
        },
        async goToReferralList() {
            let promptOptions = {
                message: "Are you sure you want to leave the page? Please make sure you saved your work.",
                okText: "Yes, I'm Sure",
                cancelText: "Never Mind"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: (options) => {
                    if (options.data === "success") {
                        this.$router.push("/axxess-central/intake/referral-list");
                    }
                }
            });
        },

        doNonAdmit() {
            let nonAdmitDate = String(this.$route.params.nonAdmitDate);
            nonAdmitDate = moment(nonAdmitDate, "MM-DD-YYYY").format("MM/DD/YYYY");
            const nonAdmitReasonId = String(this.$route.params.nonAdmitReasonId);
            const nonAdmitReason = String(this.$route.params.nonAdmitReason);
            const patientAgencyId = this.patientBranchId;
            const patientApplication = this.patientApplication;
            const patientId = this.patientId;
            let promptOptions = {
                message: "Are you sure you want to update this patient to Non-Admit?",
                okText: "Yes, I'm Sure",
                cancelText: "No"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            if (patientApplication === ApplicationEnum.AgencyCore.toString()) {
                                if (this.isPatient) {
                                    let queryObj: INonAdmitHHPatientQuery = {
                                        AgencyId: patientAgencyId.toString(),
                                        NonAdmitDate: nonAdmitDate,
                                        Reason: [nonAdmitReason],
                                        Application: ApplicationEnum.AgencyCore
                                    };
                                    await this._patientService.updatePatientToNonAdmit(patientId, queryObj);
                                } else {
                                    let queryObj = {
                                        accountId: patientAgencyId.toString(),
                                        nonAdmitDate: nonAdmitDate,
                                        nonAdmitReasonId: nonAdmitReasonId,
                                        nonAdmitReason: [nonAdmitReason],
                                        application: ApplicationEnum.AgencyCore
                                    };
                                    await this._centralIntake.updateReferralToNonAdmit(patientId, queryObj);
                                }
                            } else if (patientApplication === ApplicationEnum.HomeCare.toString()) {
                                if (this.isPatient) {
                                    let queryObj = {
                                        AgencyId: patientAgencyId.toString(),
                                        NonAdmitDate: nonAdmitDate,
                                        Reason: nonAdmitReason,
                                        Application: ApplicationEnum.HomeCare,
                                        ReasonId: nonAdmitReasonId
                                    };
                                    await this._patientService.updatePatientToNonAdmitHC(patientId, queryObj);
                                } else {
                                    let queryObj = {
                                        accountId: patientAgencyId.toString(),
                                        nonAdmitDate: nonAdmitDate,
                                        nonAdmitReasonId: nonAdmitReasonId,
                                        nonAdmitReason: [nonAdmitReason],
                                        application: ApplicationEnum.HomeCare
                                    };
                                    await this._centralIntake.updateReferralToNonAdmit(patientId, queryObj);
                                }
                            } else {
                                let newNonAdmitData: INonAdmitPatientQuery = {
                                    nonAdmitDate: nonAdmitDate,
                                    nonAdmitReasonId: nonAdmitReasonId,
                                    nonAdmitComments: ""
                                };
                                let queryObj = JSON.parse(JSON.stringify(newNonAdmitData));
                                queryObj.agencyId = patientAgencyId;
                                queryObj.application = patientApplication;
                                await this._patientService.updatePatientToNonAdmit(patientId, queryObj);
                            }
                            this._toastrService.success({
                                title: `${this.isPatient ? "Patient" : "Referral"} Converted to Non-Admit`,
                                message: `The ${this.isPatient ? "patient" : "referral"} <b>${this.patientLastName}, ${
                                    this.patientFirstName
                                }</b> has been converted to Non-Admit.`
                            });
                        } catch (error) {
                            this._toastrService.error({
                                title: `Error -${this.isPatient ? "Patient" : "Referral"} convert to Non-Admit`,
                                message: `There was a problem while converting the ${
                                    this.isPatient ? "patient" : "referral"
                                } <b>${this.patientLastName}, ${this.patientFirstName}</b>. Please try again.`
                            });
                        }
                    }
                }
            });
        }
    }
});
