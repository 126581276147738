import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";

import config from "../../common/config";
import { EnumsService } from "../../services/enums.service";
import { LookupService } from "../../services/lookup-service";

@autoinject
export class AppCache {
    private _enumsService: EnumsService;
    private _lookupService: LookupService;
    private _cacheRefreshMap: Map<string, () => void> = new Map([
        [config.enumCacheKey, this.refreshEnums.bind(this)],
        [config.countryListCacheKey, this.refreshCountryListData.bind(this)],
        [config.countryPhoneCodeListCacheKey, this.refreshCountryPhoneCodeData.bind(this)]
    ]);

    public constructor(enumsService: EnumsService, ea: EventAggregator, lookupService: LookupService) {
        this._enumsService = enumsService;
        this._lookupService = lookupService;
    }

    public async refreshCache() {
        this.clearCache();
        this._cacheRefreshMap.forEach((refreshMethod, cacheKey) => refreshMethod());
    }

    public async restoreMissingCache() {
        this._cacheRefreshMap.forEach((refreshMethod, cacheKey) => {
            let isEntityCached = !!localStorage.getItem(cacheKey);
            if (!isEntityCached) {
                refreshMethod();
            }
        });
    }

    private clearCache() {
        this._enumsService.clearCachedEnums();
    }

    private async refreshEnums() {
        await this._enumsService.getAllEnums();
    }

    private async refreshCountryListData() {
        localStorage.removeItem(config.countryListCacheKey);
        await this._lookupService.getCountryCodes();
    }

    private async refreshCountryPhoneCodeData() {
        localStorage.removeItem(config.countryPhoneCodeListCacheKey);
        await this._lookupService.getCountryPhoneCodes();
    }
}
