import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import moment from "moment";

import { FetchClient } from "../common/fetch-client";
import type { IApplicationAndAccountQueryParam } from "../interfaces/i-get-referral";
import {
    IEmployeeDetails,
    IEmployeePreferenceRequestPayload,
    IHomeCareReassignRequest,
    ITrackingReasonRequestPayload,
    ITrackingReasonResponse,
    ITrackingTypeDetails,
    ITrackingTypeRequestPayload
} from "../interfaces/i-home-care-reassign";
import type { IEmployeeList, IReassignVisitDetails, IReassignVisitPayload } from "../interfaces/i-reassign-visit";
import {
    ISpecialtyAxessCare,
    IPatientDetailsAxxessCare,
    IPostAxxessCareResponse,
    IGetAxxessCareDetails,
    IPostTaskToAxxessCareVisitDetails,
    IRescheduleVisitHosPal,
    IReassignVisitHC,
    IRescheduleVisitHCValidations
} from "../resources-vue/vue-interfaces/i-visit-details";
import { IScheduleVisitHCValidations } from "../models/schedule-visits/schedule-visit-home-care";
import {
    IEmployeeBulkReassignVisitPayload,
    IBulkReassignResponse,
    IPatientBulkReassignVisitPayload,
    IBulkDeletePayload
} from "../interfaces/i-bulk-reassign-visit";
import {
    ITaskReorderRequestBody,
    ITaskRequestParams,
    ITaskTotalRequestParams,
    IReasonCode,
    IDeleteReturnReason,
    IGetReturnReasons,
    IReasonCodeResponse,
    IGetTaskPayorList,
    ITaskPayorResponse,
    IGetTaskPayrollDetails,
    ITaskPayrollDetailsResponse
} from "../resources-vue/vue-interfaces/i-task";
import { Task } from "../models/task.model";
import type { ITask, IVisitCountResponse, IVisitCountRequest } from "./../resources-vue/vue-interfaces/i-task";
import type {
    IMulitplePostToAxxessCare,
    IRescheduleVisitDetails
} from "../resources-vue/vue-interfaces/i-visit-details";
import type {
    ITaskDetailsPayload,
    IAssignToListQueryParams,
    INewVisitDetailsResponse
} from "../resources-vue/vue-interfaces/i-task-details";
import type { IScheduleEvent, VisitDetails } from "../models/visit-details";
import { TaskDetails } from "../models/task-details";
import type {
    IAvailableTasksRequest,
    IAvailableTasksResponse,
    IPayersByDateRequest,
    IPayersByDateResponse,
    IGetAvailableUsersRequest,
    IGetTaskFrequencyRequest,
    IGetTaskFrequencyResponse
} from "../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import { PatientTask } from "../models/patient-task-hos-pal";
import { ScheduleVisitHomeHealthModel } from "../models/schedule-visits/schedule-visit-home-health";
import { ApplicationEnum } from "../enums/enums";
import { ScheduleVisitHomeCareModel } from "../models/schedule-visits/schedule-visit-home-care";
import { IAddressLongLat } from "../resources-vue/vue-interfaces/i-address";
import { IVisitMapRequestParams, IVisitMapResponse } from "../resources-vue/vue-interfaces/i-visit-map";

@noView()
@autoinject
export class TaskService {
    // TODO: Create a interface instead of any
    private _tasksCountLoaded: any = {}; // Tasks total by date range. API_CALL_PARAMS: boolean
    private _taskCountData: any = {}; // Tasks total by date range. API_CALL_PARAMS: response
    private _fetchClient: FetchClient;
    private _baseUrl: string = `/api/v1/tasks`;

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async findAll(options: ITaskRequestParams): Promise<Array<ITask>> {
        let tasks = await this._fetchClient.build(`${this._baseUrl}`).useQueryString(options).fetch<ITask[]>();
        if (tasks?.length === 0) {
            return [];
        }
        return tasks.map((task) => new Task(task));
    }

    public async scheduleVisitHH(payload: ScheduleVisitHomeHealthModel) {
        return await this._fetchClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<INewVisitDetailsResponse>();
    }

    public async scheduleVisitHC(payload: ScheduleVisitHomeCareModel) {
        return await this._fetchClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IScheduleVisitHCValidations>();
    }

    public async getPastDue(): Promise<Array<ITask>> {
        return await this.getTasksReport("pastdue");
    }

    public async getAlerts(): Promise<Array<ITask>> {
        return await this.getTasksReport("returnedbyqa");
    }

    public async getIncidentAlerts(): Promise<Array<ITask>> {
        return await this.getTasksReport("incidents-returnedbyqa");
    }

    private async getTasksReport(type: string): Promise<Array<ITask>> {
        let tasks = await this._fetchClient.build(`${this._baseUrl}/${type}`).fetch<ITask[]>();
        if (tasks?.length === 0) {
            return [];
        }
        let sortedTasks: Array<ITask> = this.sortTasks(tasks, "Date", "date");
        return sortedTasks.map((task) => new Task(task));
    }

    public async getUpdatedTaskDates(options: ITaskTotalRequestParams): Promise<Array<any>> {
        const params: string = $.param(options);
        const response = await this._fetchClient
            .build(`${this._baseUrl}/total`)
            .useQueryString(options)
            .fetch<ITask[]>();
        if (!response) {
            return [];
        }
        this._taskCountData[params] = response;
        this._tasksCountLoaded[params] = true;
        return this._taskCountData[params];
    }

    public async getTaskByDates(options: ITaskTotalRequestParams): Promise<Array<any>> {
        const params: string = $.param(options);
        if (this._tasksCountLoaded[params]) {
            return this._taskCountData[params];
        }
        return this.getUpdatedTaskDates(options);
    }

    public sortTasks(tasks: Array<any>, prop: string, type: string, desc: boolean = false): Array<any> {
        if (tasks.length > 0) {
            return tasks.sort((a, b) => {
                let op1 = a[prop];
                let op2 = b[prop];

                if (type === "date") {
                    op1 = moment(op1).format("YYYYMMDD");
                    op2 = moment(op2).format("YYYYMMDD");
                }

                if (desc) {
                    return op2 - op1;
                } else {
                    return op1 - op2;
                }
            });
        } else {
            return tasks;
        }
    }

    public async getTasksByPatients(params: ITaskByPatientRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-date-range`)
            .useMethod("POST")
            .useBody(params)
            .fetch<ITaskByPatientResponse[]>();
    }

    public async getTasksByUsers(params: ITaskByUserRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-date-range-and-users`)
            .useMethod("POST")
            .useBody(params)
            .fetch<ITaskByUserResponse[]>();
    }

    public async reassignVisit(payload: IReassignVisitPayload) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${payload.TaskId}/reassign`)
            .useMethod("PUT")
            .useBody(payload)
            .fetchNoContent();
    }

    public async employeeList(task: IReassignVisitDetails) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${task.CustomTaskId}/available-users`)
            .useQueryString({ accountId: task.AgencyId, application: task.Application, patientId: task.PatientId })
            .fetch<IEmployeeList[]>();
    }

    public async assignToList(query: IAssignToListQueryParams) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${query.CustomTaskId}/available-users`)
            .useQueryString({ accountId: query.AgencyId, application: query.Application })
            .fetch<IEmployeeList[]>();
    }

    public async homecareEmployeePreference(params: IEmployeePreferenceRequestPayload) {
        const response = await this._fetchClient
            .build(`${this._baseUrl}/${params.PatientId}/users-preference`)
            .useQueryString(params)
            .fetch<IHomeCareReassignRequest<IEmployeeDetails>>();
        return response.Data.length > 0 ? response.Data : [];
    }

    public async getTrackingTypes(params: ITrackingTypeRequestPayload) {
        const response = await this._fetchClient
            .build(`${this._baseUrl}/tracking-types`)
            .useQueryString(params)
            .fetch<IHomeCareReassignRequest<ITrackingTypeDetails>>();
        return response.Data?.length > 0 ? response.Data : [];
    }

    public async getTrackingReasons(params: ITrackingReasonRequestPayload) {
        const response = await this._fetchClient
            .build(`${this._baseUrl}/tracking-reason`)
            .useQueryString(params)
            .fetch<ITrackingReasonResponse>();
        return response.Reason?.length > 0 ? response.Reason : [];
    }

    public async changeTaskOrder(changedOrder: ITaskReorderRequestBody[]) {
        return await this._fetchClient
            .build(`${this._baseUrl}/order`)
            .useMethod("POST")
            .useBody({ Data: changedOrder })
            .fetch();
    }

    public async getAxxessCareDetails(patientId: string, payload: IGetAxxessCareDetails) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${patientId}/axxess-care`)
            .useQueryString(payload)
            .fetch<IPatientDetailsAxxessCare>();
    }

    public async getAxxessCareSpecialties(payload: IApplicationAndAccountQueryParam) {
        return await this._fetchClient
            .build(`${this._baseUrl}/axxess-care/specialty`)
            .useQueryString(payload)
            .fetch<ISpecialtyAxessCare[]>();
    }

    public async postTaskToAxxessCare(payload: IPostTaskToAxxessCareVisitDetails) {
        return await this._fetchClient
            .build(`${this._baseUrl}/axxess-care`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IPostAxxessCareResponse>();
    }

    public async multiplePostToAxxessCare(payload: IMulitplePostToAxxessCare) {
        return await this._fetchClient
            .build(`${this._baseUrl}/multi-day/axxess-care`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<any>();
    }

    public async employeeBulkReassign(payload: IEmployeeBulkReassignVisitPayload) {
        return await this._fetchClient
            .build(`${this._baseUrl}/bulk-reassign`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IBulkReassignResponse>();
    }

    public async rescheduleTask(payload: IRescheduleVisitDetails) {
        return await this._fetchClient
            .build(`${this._baseUrl}/update-task`)
            .useMethod("PUT")
            .useBody(payload)
            .fetch<ITaskUpdateResponse>();
    }

    public async rescheduleTaskHC(payload: IReassignVisitHC) {
        return await this._fetchClient
            .build(`${this._baseUrl}/update-task`)
            .useMethod("PUT")
            .useBody(payload)
            .fetch<IRescheduleVisitHCValidations>();
    }

    public async rescheduleTaskHosPal(payload: IRescheduleVisitHosPal) {
        return await this._fetchClient.build(`${this._baseUrl}`).useMethod("PUT").useBody(payload).fetchNoContent();
    }

    public async updateTasksDetails(payload: IScheduleEvent) {
        return await this._fetchClient
            .build(`${this._baseUrl}/update-task`)
            .useMethod("PUT")
            .useBody(payload)
            .fetch<{}>();
    }

    public async patientBulkReassign(payload: IPatientBulkReassignVisitPayload) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${payload.PatientId}/bulk-reassign`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IBulkReassignResponse>();
    }

    public async patientBulkDelete(payload: IBulkDeletePayload) {
        return await this._fetchClient
            .build(`${this._baseUrl}/delete-multiple-task`)
            .useMethod("DELETE")
            .useBody(payload)
            .fetch<IBulkReassignResponse>();
    }

    public async getTasksDetails(payload: ITaskDetailsPayload) {
        return await this._fetchClient.build(`${this._baseUrl}/details`).useQueryString(payload).fetch<TaskDetails>();
    }

    public async startMissedVisit(payload: FormData) {
        return await this._fetchClient
            .build(`${this._baseUrl}/add-missed-visit`)
            .useMethod("POST")
            .useFormData(payload)
            .fetch<IMissedVisitResponse>();
    }

    public async getHHVisitDetails(payload: ITaskDetailsPayload) {
        return await this._fetchClient.build(`${this._baseUrl}/details`).useQueryString(payload).fetch<VisitDetails>();
    }

    public async updateHHVisitDetails(payload: IScheduleEvent) {
        return await this._fetchClient
            .build(`${this._baseUrl}/update-task`)
            .useMethod("PUT")
            .useBody(payload)
            .fetch<{}>();
    }

    public async deleteReturnReason(payload: IDeleteReturnReason) {
        return await this._fetchClient
            .build(`${this._baseUrl}/return-reason/delete`)
            .useMethod("DELETE")
            .useQueryString(payload)
            .fetch<IReasonCode>();
    }

    public async deleteReturnReasonHC(payload: IDeleteReturnReason) {
        return await this._fetchClient
            .build(`${this._baseUrl}/return-reason-hc/delete`)
            .useMethod("DELETE")
            .useQueryString(payload)
            .fetch<IReasonCode>();
    }

    public async getReturnReasonList(payload: IGetReturnReasons) {
        return await this._fetchClient
            .build(`${this._baseUrl}/return-reasons`)
            .useQueryString(payload)
            .fetch<IReasonCodeResponse>();
    }

    public async getTaskPayorsList(payload: IGetTaskPayorList) {
        return await this._fetchClient
            .build(`/api/v1/profile/task-payor`)
            .useQueryString(payload)
            .fetch<ITaskPayorResponse>();
    }

    public async getTaskPayrollDetails(payload: IGetTaskPayrollDetails) {
        return await this._fetchClient
            .build(`${this._baseUrl}/payroll-task-shifts`)
            .useQueryString(payload)
            .fetch<ITaskPayrollDetailsResponse>();
    }

    public async getAvailableTasks(payload: IAvailableTasksRequest) {
        return await this._fetchClient
            .build(`/api/v1/${payload.patientId}/available-tasks`)
            .useQueryString(payload)
            .fetch<IAvailableTasksResponse>();
    }

    public async getPayersByDate(payload: IPayersByDateRequest) {
        const queryParam = {
            accountId: payload.accountId,
            startDate: payload.startDate,
            endDate: payload.endDate,
            includeCharityPayer: payload.includeCharityPayer,
            applications: payload.application
        };
        return await this._fetchClient
            .build(`/api/v1/${payload.patientId}/payers/by-date`)
            .useQueryString(queryParam)
            .fetch<IPayersByDateResponse>();
    }

    public async getAvailableUsers(payload: IGetAvailableUsersRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${payload.taskId}/available-users`)
            .useQueryString(payload)
            .fetch<IEmployeeList[]>();
    }

    public async getTaskFrequency(payload: IGetTaskFrequencyRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/frequency-of-task`)
            .useQueryString(payload)
            .fetch<IGetTaskFrequencyResponse[]>();
    }

    public async createTask(payload: PatientTask) {
        return await this._fetchClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IBulkReassignResponse>();
    }
    public async getFrequencyList(payload: IFrequencyListRequestParams) {
        return await this._fetchClient
            .build(`${this._baseUrl}/patient/frequency-list`)
            .useQueryString(payload)
            .fetch<IFrequencyListResponse[]>();
    }

    public async getHCTaskList(payload: IHomeCareTasksParams) {
        return await this._fetchClient
            .build(`${this._baseUrl}/task/custom-notes`)
            .useQueryString(payload)
            .fetch<IHomeCareTasksResponse>();
    }

    public async getScheduleMatch(payload: IScheduleMatchRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/schedule-match`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IScheduleMatchResponse>();
    }

    public async sendMatchMessage(payload: ISendMatchMessageRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/send-match-message`)
            .useMethod("POST")
            .useBody(payload)
            .fetch();
    }

    public async findAMatch(payload: IFindAMatchRequest) {
        return await this._fetchClient.build(`${this._baseUrl}/find-match`).useMethod("POST").useBody(payload).fetch();
    }

    public async startAddendum(payload: IStartAddendum, taskId: string) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${taskId}/addendum`)
            .useMethod("POST")
            .useBody(payload)
            .fetch();
    }

    public async getVisitCount(payload: IVisitCountRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/visit-counts/past`)
            .useMethod("GET")
            .useQueryString(payload)
            .fetch<IVisitCountResponse[]>();
    }

    public async getCalendarMapVisits(query: IVisitMapRequestParams) {
        return await this._fetchClient
            .build(`${this._baseUrl}/employee-tasks-for-map`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IVisitMapResponse>();
    }
}

export interface IStartAddendum {
    agencyId: string;
    application: ApplicationEnum;
    reason: string;
}

export interface IHomeCareTasksParams {
    agencyId: string;
    application: ApplicationEnum;
}

export interface IHomeCareTasksResponse {
    data: {
        data: IHomeCareTasksData[];
    };
}

export interface IHomeCareTasksData {
    id: string;
    label: string;
    children: IHomeCareTask[];
}

export interface IHomeCareTaskList {
    id: string;
    label: string;
    children: IHomeCareTask[];
}

export interface IHomeCareTask {
    id: string;
    label: string;
    disciplineTaskId: string;
}

export interface ITaskByPatientRequest {
    start: string;
    end: string;
    patientIds: string[];
    weekdayRange?: number;
}

export interface ITaskByUserRequest {
    start: string;
    end: string;
    userIds: string[];
    agencyIds: string[];
    weekdayRange?: number;
    locationId?: string;
    patientId?: string[];
}

export interface ITaskByPatientResponse {
    Id: string;
    UserId: string;
    PatientId: string;
    EpisodeId: string;
    AgencyId: string;
    PatientName: IFullName;
    PatientAddress: IAddressLongLat;
    Dob: string;
    PatientPhoneNumber: string;
    VisitName: string;
    AgencyName: string;
    IsAxxessCarePatient: true;
    Status: string;
    StatusName: string;
    EmergencyContactName: IFullName;
    EmergencyContactPhoneNumber: string;
    Date: string;
    EpisodeNotes: string;
    Comments: string;
    ReturnComments: "string";
    Application: number;
    StartDate: string;
    EndDate: string;
    TaskName: string;
    State: number;
    UserFirstName: string;
    UserLastName: string;
    isExtraEvent?: boolean;
    locationId?: string;
    patientStatus?: string;
    payerId?: string;
    StartTime: string;
    EndTime: string;
}

export interface ITaskByUserResponse {
    AgencyId: string;
    Application: number;
    BranchName: string;
    EndDate: string;
    EndTime: string;
    EpisodeId: string;
    EvvTimeIn: string;
    EvvTimeOut: string;
    EvvStatus: string;
    Id: string;
    IsBillable: boolean;
    IsPayable: boolean;
    Mileage: string;
    PatientFirstName: string;
    PatientId: string;
    PatientLastName: string;
    Payer: string;
    ScheduledByFirstName: string;
    ScheduledByLastName: string;
    StartDate: string;
    StartTime: string;
    State: number;
    Status: string;
    TaskName: string;
    TimeIn: string;
    TimeOut: string;
    UserCredentials: string[];
    UserId: string;
    isExtraEvent?: boolean;
}

export interface IFullName {
    FirstName: string;
    LastName: string;
    MiddleInitial: string;
}

export interface ITaskUpdateResponse {
    isSuccessful: boolean;
    message: string;
    data: any;
}

export interface IMissedVisitResponse {
    IsSuccessful: boolean;
    Message: string;
    Data: any;
    ErrorMessage: any;
}

export interface IFrequencyListRequestParams {
    agencyId: string;
    patientId: string;
    application: ApplicationEnum;
    episodeId: string;
    episodeStartDate: string;
}

export interface IFrequencyListResponse {
    agencyId: string;
    episodeId: string;
    id: string;
    patientId: string;
    eventId: string;
    eventTaskName: string;
    frequencyNursing: string;
    frequencyPT: string;
    frequencyOT: string;
    frequencyST: string;
    frequencyHHA: string;
    frequencyMSW: string;
    startDate: string;
}

export interface IScheduleMatchRequest {
    application: number;
    agencyId: string;
    locationId: string;
    startDate: string;
    endDate: string;
    isAllDay: boolean;
    task: string;
    payor: string;
    clientName: string;
    patientId: string;
}

export interface IScheduleMatchResponse {
    patientMatchCriteria: Array<any>;
    patientTags: Array<string>;
    employeeTags: Array<string>;
    locationId: string;
    startDate: string;
    endDate: string;
    isAllDay: boolean;
    task: string;
    payor: string;
    clientName: string;
    tags: Array<string>;
}

export interface ISendMatchMessageRequest {
    application: number;
    agencyId: string;
    patientId: string;
    clientName: string;
    userIds: Array<string>;
    startDate: string;
    endDate: string;
    isAllDay: boolean;
}

export interface IFindAMatchRequest {
    application: number;
    agencyId: string;
    patientId: string;
    locationId: string;
    startDate: string;
    endDate: string;
    requiredCriteria: Array<number>;
    userTags: Array<string>;
    preferredCriteria: Array<number>;
    isOriented: boolean;
    availability: number;
    tagOptions: number;
}
