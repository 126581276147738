import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { UserManager } from "oidc-client";

import { SignalRService } from "../../../services/signal-r-service";
import { MessageService } from "../../../services/message-service";
import { ToastrService } from "../../../services/toastr.service";

import type {
    IAsset,
    IConversationNavOptions,
    IMessageItem,
    ISendMessageQuery,
    ISendMessageResult
} from "../../../resources-vue/vue-interfaces/messaging-service/i-message";
import type { IPaginationNew } from "../../../resources-vue/vue-interfaces/i-pagination";
import { INotification } from "../../../interfaces/messaging-service/i-notification";
import { INotificationResponse } from "../../../interfaces/i-notification";
import { NotificationsHubEvents } from "../../../enums/NotificationsHubEvents";
import { MessageErrorEnum } from "../../../enums/enums";
import { mockDeletedMessages, mockMessages } from "../MockData";
import { SignalrEvent } from "../../../enums/SignalrEventEnum";
import eventBus from "../../../utilities/eventBus";

import MessagesRenderer from "./components/MessagesRenderer/MessagesRenderer.vue";
import ConversationHeader from "./components/ConversationHeader/ConversationHeader.vue";
import MessageInputHandler from "../../../resources-vue/vue-custom-elements/MessagingService/MessageInputHandler/MessageInputHandler.vue";

export default defineComponent({
    components: {
        ConversationHeader,
        MessagesRenderer,
        MessageInputHandler
    },
    data() {
        return {
            selectedConversation: null as IConversationNavOptions,
            isLoading: false as boolean,
            messagesResult: {} as IPaginationNew<IMessageItem>,
            messages: [] as IMessageItem[],
            deletedMessagesResult: {} as IPaginationNew<IMessageItem>,
            deletedMessages: [] as IMessageItem[],
            files: [] as File[],
            messageInputExpanded: false as boolean,
            showAttachmentLimitError: false as boolean,
            isImportant: false as boolean,
            isBulkDeleteInProgress: false as boolean,
            selectedMessageIds: [] as string[],
            selectAll: false as boolean,
            isBulkDeleteRequested: false as boolean,
            isError: false as boolean,
            isEmpty: true as boolean,
            newMessage: "" as string,
            isViewDeletedMessagesRequested: false as boolean,
            _messageService: null as MessageService,
            _toastrService: null,
            convBodyMarkupRef: null as HTMLElement,
            _signalRService: null as SignalRService,
            _userManager: null
        };
    },
    async created() {
        this._messageService = Container.instance.get(MessageService);
        this._toastrService = Container.instance.get(ToastrService);
        this._signalRService = Container.instance.get(SignalRService);
        this._userManager = Container.instance.get(UserManager);

        eventBus.on("conversationSelected", this.handleConversationSelected);

        eventBus.on("loadConversation", this.handleLoadConversation);

        eventBus.on(SignalrEvent.NewMessage, async (response: INotificationResponse) => {
            if (response.message == this.selectedConversation.id && this.$refs.messageRenderer) {
                await this.loadIncomingMessage();
            }
        });
        if (this.$refs.messageRenderer) {
            setTimeout(async () => {
                await (this.$refs.messageRenderer as typeof MessagesRenderer).reset();
            }, 0);
        }

        eventBus.on("roomCreated", this.handleRoomCreate);
        eventBus.on("newMessage", this.handleNewMessage);

        eventBus.on(NotificationsHubEvents.LoadConversationMessages, async (notification: INotification) => {
            const [firstName, lastName] = notification.senderName.split(" ");
            const user = this.findUserById(notification.senderId);
            this.readAllPreviousMessages();
            await this.markConversationAsRead(notification.conversationId);

            this.messages.push({
                id: "",
                text: notification.messageBody,
                isRead: true,
                isImportant: false,
                isFromSelf: false,
                createdOn: new Date(),
                modifiedOn: new Date(),
                user: {
                    userId: user?.userId || notification.senderId,
                    firstName: user?.firstName || firstName,
                    lastName: user?.lastName || lastName
                },
                isLoading: false,
                isError: false,
                assets: []
            });

            setTimeout(() => {
                (this.$refs.messageRenderer as typeof MessagesRenderer).scrollToBottom();
            }, 0);
        });
    },
    mounted() {
        this.convBodyMarkupRef = this.$refs.convBodyMarkupRef as HTMLElement;
    },
    watch: {
        isViewDeletedMessagesRequested: function (newValue) {
            this.isViewDeletedMessagesRequestedChanged(newValue);
        },
        async selectedConversation(selectedConversation) {
            if (selectedConversation) {
                await this.markConversationAsRead(selectedConversation.id);
            }
        }
    },
    computed: {
        allMessagesSelected() {
            if (!this.messages?.length) {
                return false;
            }
            return this.messages?.length === this.selectedMessageIds?.length;
        }
    },

    methods: {
        async markConversationAsRead(conversationId: string) {
            if (!this.selectedConversation) {
                return;
            }

            const conversationObj = { conversationId };

            try {
                await this._messageService.markConversationAsRead(conversationObj);
            } catch (error) {
                console.error("Error marking conversation as read:", error);
            }
        },
        readAllPreviousMessages() {
            this.messages.forEach((message) => {
                message.isRead = true;
            });
        },
        findUserById(userId: string) {
            for (const item of this.messages) {
                if (item?.user && item?.user?.userId === userId) {
                    return item.user;
                }
            }
            return null;
        },
        async fetchMessages(page: number, pageLength: number) {
            if (this.selectedConversation) {
                this.isLoading = true;
                this.messagesResult = await this._messageService.getMessages(this.selectedConversation.id, {
                    page,
                    pageLength: Math.max(pageLength, this.selectedConversation.unread)
                });
                // this.messagesResult = {
                //     itemCount: 0,
                //     currentPage: 1,
                //     pageCount: 1,
                //     items: [],
                //     pageLength: 1
                // };
                //  this.messagesResult = mockMessages;
                this.isLoading = false;
                let newMessages = this.messagesResult.items.reverse();
                // if conversation was reset, hence page 1 being fetched, then replace all messages with page 1
                this.messages = page === 1 ? newMessages : newMessages.concat(this.messages);
            }
        },

        async getDeletedMessages(page: number, pageLength: number) {
            if (!this.selectedConversation) {
                return;
            }
            try {
                this.isLoading = true;
                // TODO: uncomment API before pushing
                // this.deletedMessagesResult = await this._messageService.getDeletedMessages(this.selectedConversation.id, {
                //     page,
                //     pageLength: Math.max(pageLength, this.selectedConversation.unread)
                // });
                this.deletedMessagesResult = mockDeletedMessages;
                let newMessages = this.deletedMessagesResult.items;
                // if conversation was reset, hence page 1 being fetched, then replace all messages with page 1
                this.deletedMessages = page === 1 ? newMessages : newMessages.concat(this.deletedMessages);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },

        async loadIncomingMessage() {
            let newMessages = await this._messageService.getUnReadMessages(this.selectedConversation.id);
            let oldestUnreadDate = new Date(
                Math.min.apply(
                    null,
                    newMessages.map((message) => message.createdOn)
                )
            );
            let latestSentMessage = new Date(
                Math.min.apply(
                    null,
                    this.messages.filter((message) => message.isFromSelf).map((message) => message.createdOn)
                )
            );
            // Oldest unread message should be after latest sent message
            if (oldestUnreadDate > latestSentMessage) {
                this.messages = this.messages.concat(newMessages.reverse());
                setTimeout(() => {
                    (this.$refs.messageRenderer as typeof MessagesRenderer).scrollToBottom();
                }, 0);
            }
        },

        async sendMessage(value?: any) {
            const message = value.message;
            if (message || this.files.length > 0) {
                const user = await this._userManager.getUser();
                let messageQuery: ISendMessageQuery = {
                    message: message ? message : "",
                    isImportant: this.isImportant,
                    assetIds: [],
                    senderDisplayFirstname: user.profile.given_name,
                    SenderDisplayLastname: user.profile.family_name
                };
                let createdDateTime = new Date();
                let assets: IAsset[] = [];
                if (this.files?.length > 25) {
                    this.files.splice(25);
                }
                for (const file of this.files) {
                    assets.push({
                        fileName: "",
                        url: "",
                        mimeType: "",
                        file: file
                    });
                }
                this.files = [];
                this.messages.push({
                    id: "",
                    text: message,
                    isRead: true,
                    isImportant: this.isImportant,
                    isFromSelf: true,
                    createdOn: createdDateTime,
                    modifiedOn: createdDateTime,
                    user: null,
                    isLoading: true,
                    isError: false,
                    assets: assets
                });
                setTimeout(() => {
                    (this.$refs.messageRenderer as typeof MessagesRenderer).scrollToBottom();
                }, 0);
                let assetIds: string[] = [];
                try {
                    let isError: boolean = false;
                    try {
                        let files = assets.map((asset) => asset.file);
                        if (!!files?.length) {
                            assetIds = await this._messageService.uploadMessageFiles(files);
                        }
                    } catch (e) {
                        console.error(e);
                        isError = true;
                    }
                    if (!isError) {
                        messageQuery.assetIds = assetIds;

                        let messagesResult = await this._messageService.sendMessage(
                            this.selectedConversation.id,
                            messageQuery
                        );
                        const senderFullName = `${user.profile.given_name} ${user.profile.family_name}`;
                        const notification = {
                            senderId: user.profile.sub,
                            conversationId: this.selectedConversation.id,
                            messageId: "",
                            createdAt: new Date().toISOString(),
                            messageBody: message,
                            messageAssets: [] as string[],
                            senderName: senderFullName,
                            clicked: false
                        };
                        eventBus.emit(NotificationsHubEvents.LoadConversationList, notification);
                        this.readAllPreviousMessages();
                        this.updateSentMessage(messagesResult, createdDateTime);
                        if (assets.length > 0) {
                            await (this.$refs.messageRenderer as typeof MessagesRenderer).reset();
                        }
                    } else {
                        this.markMessageError(createdDateTime, MessageErrorEnum.FileUploadError);
                    }
                } catch (e) {
                    console.error(e);
                    this.markMessageError(createdDateTime, MessageErrorEnum.MessageSendingError);
                } finally {
                    this.messageInputExpanded = false;
                    this.showAttachmentLimitError = false;
                    this.isImportant = false;
                    this.files = [];
                }
            }
        },

        async toggleImportant() {
            this.isImportant = !this.isImportant;
        },

        updateSentMessage(messagesResult: ISendMessageResult, createdDateTime: Date) {
            let messages = this.messages.slice(0);
            messages.forEach((message) => {
                if (message.isLoading && message.createdOn == createdDateTime) {
                    message.isLoading = false;
                    message.id = messagesResult.id;
                }
            });
            this.messages = messages;
            eventBus.emit("messageSent", true);
        },

        markMessageError(createdDateTime: Date, messageErrorType: MessageErrorEnum) {
            let messages = this.messages.slice(0);
            messages.forEach((message) => {
                if (message.isLoading && message.createdOn == createdDateTime) {
                    message.isLoading = false;
                    message.isError = true;
                    message.errorMessage = messageErrorType;
                }
            });
            this.messages = messages;
        },

        async deleteMessages() {
            try {
                this.isBulkDeleteInProgress = true;
                let data = { messageIds: this.selectedMessageIds, isConversationDeleted: this.selectAll };
                // TODO - uncomment API before pushing
                // await this._messageService.deleteMessages(this.selectedConversation.id, data);
                const objWithIdIndex = mockMessages.items.findIndex((obj) => obj.id === this.selectedConversation.id);

                // TO - remove the dummy data
                if (objWithIdIndex > -1) {
                    mockMessages.items.splice(objWithIdIndex, 1);
                }
                this._toastrService.success({
                    title: `Message(s) Deleted`,
                    message: `${this.selectedMessageIds.length} message(s) have been successfully deleted.`
                });
                this.selectedMessageIds = [];
                this.isBulkDeleteRequested = false;
                this.messages = [];
                this.fetchMessages(1, 50);
            } catch (e) {
                console.error(e);
                this._toastrService.error({
                    title: `Error`,
                    message: "There was a problem deleting messages. Please try again."
                });
            } finally {
                this.isBulkDeleteInProgress = false;
            }
        },

        isViewDeletedMessagesRequestedChanged(newValue: boolean) {
            if (newValue) {
                this.deletedMessages = [];
            }
        },

        removeSelectedMessage(messageId: string) {
            if (!messageId) {
                return;
            }
            this.selectedMessageIds = this.selectedMessageIds.filter((id) => id !== messageId);

            if (this.selectedMessageIds.length === 0) {
                this.isBulkDeleteRequested = false;
            }
        },

        addSelectedMessage(messageId: string) {
            this.selectedMessageIds.push(messageId);
            if (this.allMessagesSelected) {
                this.selectAll = true;
            }
        },

        handleRoomCreate(roomId: string) {
            const message = `<button type="button" class='btn btn-primary jitsi-join-btn'  data-id='${roomId}'>Join Meeting</button>`;
            const value = { message };
            this.sendMessage(value);
        },

        handleNewMessage(message: string) {
            const value = { message };
            this.sendMessage(value);
        },

        async handleLoadConversation(conversation: IConversationNavOptions) {
            this.selectedConversation = conversation;
            this.isEmpty = false;
            this.messages = [];
            if (this.$refs.messageRenderer) {
                await (this.$refs.messageRenderer as typeof MessagesRenderer).reset();
            }
        },

        handleConversationSelected(conversation: IConversationNavOptions) {
            this.messages = [];
            this.selectedConversation = conversation;
            this.isEmpty = false;
        }
    },

    beforeUnmount() {
        eventBus.off("roomCreated", this.handleRoomCreate);
        eventBus.off("loadConversation", this.handleLoadConversation);
        eventBus.off("conversationSelected", this.handleConversationSelected);
    }
});
