import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Visit_Details",
  class: "p-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScheduleVisitHomeCare = _resolveComponent("ScheduleVisitHomeCare")!
  const _component_ScheduleVisitHosPal = _resolveComponent("ScheduleVisitHosPal")!
  const _component_ScheduleVisitHomeHealth = _resolveComponent("ScheduleVisitHomeHealth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options.Application == _ctx.applicationEnum.HomeCare)
      ? (_openBlock(), _createBlock(_component_ScheduleVisitHomeCare, {
          key: 0,
          "visit-details": _ctx.options,
          "is-loading": _ctx.isLoading,
          "is-saving": _ctx.isSaving,
          onLoadingChange: _ctx.handleLoadingChange,
          onSavingChange: _ctx.handleSavingChange,
          cancel: _ctx.cancel,
          ok: _ctx.ok,
          "add-another": _ctx.addAnother
        }, null, 8 /* PROPS */, ["visit-details", "is-loading", "is-saving", "onLoadingChange", "onSavingChange", "cancel", "ok", "add-another"]))
      : _createCommentVNode("v-if", true),
    (
                _ctx.options.Application == _ctx.applicationEnum.AxxessHospiceFE ||
                _ctx.options.Application == _ctx.applicationEnum.AxxessHospice ||
                _ctx.options.Application == _ctx.applicationEnum.AxxessPalliative
            )
      ? (_openBlock(), _createBlock(_component_ScheduleVisitHosPal, {
          key: 1,
          "visit-details": _ctx.options,
          "is-loading": _ctx.isLoading,
          cancel: _ctx.cancel,
          ok: _ctx.ok,
          "add-another": _ctx.addAnother
        }, null, 8 /* PROPS */, ["visit-details", "is-loading", "cancel", "ok", "add-another"]))
      : _createCommentVNode("v-if", true),
    (_ctx.options.Application == _ctx.applicationEnum.AgencyCore)
      ? (_openBlock(), _createBlock(_component_ScheduleVisitHomeHealth, {
          key: 2,
          "visit-details": _ctx.options,
          "is-loading": _ctx.isLoading,
          "is-saving": _ctx.isSaving,
          onLoadingChange: _ctx.handleLoadingChange,
          onSavingChange: _ctx.handleSavingChange,
          cancel: _ctx.cancel,
          ok: _ctx.ok,
          "add-another": _ctx.addAnother
        }, null, 8 /* PROPS */, ["visit-details", "is-loading", "is-saving", "onLoadingChange", "onSavingChange", "cancel", "ok", "add-another"]))
      : _createCommentVNode("v-if", true)
  ]))
}