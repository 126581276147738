import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import moment, { Moment } from "moment";
import config from "../common/config";
import { FetchClient } from "../common/fetch-client";
import { Weather } from "../models/weather.model";
import {
    IGetForecastResponse,
    IGetWeatherResponse,
    IWeatherCachedData,
    IWeatherRequestParams
} from "./../resources-vue/vue-interfaces/i-weather";

@noView()
@autoinject
export class WeatherService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/weather";
    public cacheKey: string = config.weatherCacheKey;
    public cachedData: IWeatherCachedData = JSON.parse(localStorage.getItem(this.cacheKey));

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    // Param: update (This Param gets updated only when the user tries to update the location
    //  from Weather Header and Tasks Map Section)
    public async getCurrentWeather(options: IWeatherRequestParams, update: boolean): Promise<Weather> {
        let cachedData: IWeatherCachedData = this.cachedData;
        let currentData: Weather = cachedData && cachedData.currentData;
        let useCachedData: boolean = true;
        let hasCurrentExpired: boolean = true;

        if (cachedData) {
            let currentCachedTime: Moment = moment(cachedData.currentCachedTime);
            let now: Moment = moment();
            hasCurrentExpired = moment.duration(now.diff(currentCachedTime)).asHours() > 1;
        }

        useCachedData = cachedData && currentData && !hasCurrentExpired;

        if (update || !useCachedData) {
            try {
                let weatherData = await this._fetchClient
                    .build(`${this._baseUrl}/current`)
                    .useQueryString(options)
                    .fetch<IGetWeatherResponse>();
                this.cacheData(weatherData, "current");
                return new Weather(weatherData);
            } catch (error) {
                console.error(error);
                throw error;
            }
        } else {
            return new Weather(currentData);
        }
    }

    // Param: update (This Param gets updated only when the user tries to update the location
    //  from Weather Header and Tasks Map Section)
    public async getWeatherForecast(options: IWeatherRequestParams, update: boolean): Promise<Array<Weather>> {
        let cachedData: IWeatherCachedData = this.cachedData;
        let forecastData: any = cachedData && cachedData.forecastData;
        let useCachedData: boolean =
            cachedData && forecastData && !moment().isAfter(moment(cachedData.forecastExpiresIn));

        if (update || !useCachedData) {
            try {
                let weatherData = await this._fetchClient
                    .build(`${this._baseUrl}/forecast`)
                    .useQueryString(options)
                    .fetch<IGetForecastResponse>();
                this.cacheData(weatherData, "forecast");
                return this.filterForecastList(weatherData);
            } catch (error) {
                console.error(error);
                return [];
            }
        } else {
            return this.filterForecastList(forecastData);
        }
    }

    public filterForecastList(data: any): Array<Weather> {
        let forecastData: Array<Weather> = [];
        // console.log('WEATHER SERVICE: filter forecast: ', data);
        for (let item of data.list) {
            forecastData.push(new Weather(item));
        }
        return forecastData;
    }

    public cacheData(weatherData: any, type: string): void {
        let cachedData: IWeatherCachedData = this.cachedData || {};
        if (type === "current") {
            cachedData.currentData = weatherData;
        } else {
            cachedData.forecastData = weatherData;
        }

        if (type === "current") {
            cachedData.currentCachedTime = new Date(); // Current weather expires in an hour
        } else {
            cachedData.forecastExpiresIn = moment().endOf("day"); // Forecast expires at the end of the day
        }

        localStorage.setItem(this.cacheKey, JSON.stringify(cachedData));
    }
}
