import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { minValue, maxValue } from "@vuelidate/validators";

import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { AdmissionSources, episodeTiming } from "./Constants";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    data(vm) {
        return {
            showOtherAdmissionSource: false,
            v$: useVuelidate(),
            AdmissionSources,
            episodeTiming
        };
    },

    validations() {
        return {
            referral: {
                Demographics: {
                    AdmissionSources: { minValue: minValue(1) },
                    EpisodeTiming: { minValue: minValue(1), maxValue: maxValue(4) }
                }
            }
        };
    },

    computed: {
        naSelected() {
            return (
                (this.referral.Demographics.AdmissionSources & this.AdmissionSources.NotApplicable.value) ===
                this.AdmissionSources.NotApplicable.value
            );
        },
        admissionOptionsToDisplay() {
            return this.naSelected ? [this.AdmissionSources.NotApplicable] : Object.values(this.AdmissionSources);
        },
        ...mapState(useEditReferralHH, { application: "currentApplication", referral: "referral", errors: "errors" })
    },
    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        showCheckbox(option: any) {
            return option.value === this.AdmissionSources.NotApplicable.value || !this.naSelected;
        },
        isAdmissionSourceChecked(option: any) {
            return (this.referral.Demographics.AdmissionSources & option.value) === option.value;
        },
        handleAdmissionSource(value: number) {
            if ((this.referral.Demographics.AdmissionSources & value) === value) {
                this.referral.Demographics.AdmissionSources -= value;
                if (value === 64) {
                    this.showOtherAdmissionSource = false;
                }
                this.handleAllInputChange("AdmissionSources", this.referral.Demographics.AdmissionSources);
            } else {
                this.referral.Demographics.AdmissionSources += value;
                if (value === 64) {
                    this.showOtherAdmissionSource = true;
                }
                this.handleAllInputChange("AdmissionSources", this.referral.Demographics.AdmissionSources);
            }
        },
        handleEpisodeTimingChange(value: number) {
            if (value === 1) {
                this.referral.Demographics.EpisodeTiming = this.referral.Demographics.EpisodeTiming === 1 ? 0 : value;
                this.handleAllInputChange("EpisodeTiming", value);
            } else if (value === 2) {
                this.referral.Demographics.EpisodeTiming = this.referral.Demographics.EpisodeTiming === 2 ? 0 : value;
                this.handleAllInputChange("EpisodeTiming", value);
            } else if (value === 3) {
                this.referral.Demographics.EpisodeTiming = this.referral.Demographics.EpisodeTiming === 3 ? 0 : value;
                this.handleAllInputChange("EpisodeTiming", value);
            } else {
                this.referral.Demographics.EpisodeTiming = this.referral.Demographics.EpisodeTiming === 4 ? 0 : value;
                this.handleAllInputChange("EpisodeTiming", value);
            }
        },
        handleAllInputChange(field: string, value: number) {
            if (value > 0) {
                const errors = [...new Set(this.errors.Demographics)].filter((val: any) => val !== field);
                const payload = {
                    property: "Demographics",
                    value: errors
                };
                this.setErrors(payload);
            }
        }
    }
});
