import { defineComponent, computed } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";
import { mapActions } from "pinia";

import config from "../../common/config";
import { useLoadingIndicator } from "../Common/LoadingIndicator/store/useLoadingIndicator";
import { ApplicationEnum } from "../../enums/enums";
import { formatDateInput, formatDate } from "../../common/vue-helpers/modifiers/value-modifier";
import { IPromptOptions } from "../../resources-vue/vue-dialogs/Prompt/Prompt";
import { IsReadOnlyTaskByName } from "../../common/vue-helpers/modifiers/read-only-tasks";
import {
    type ITaskByUserRequest,
    type ITaskByUserResponse,
    TaskService,
    ITaskByPatientResponse
} from "../../services/task.service";
import { ToastrService } from "../../services/toastr.service";

import DashboardHeader from "../../resources-vue/vue-elements/DashboardHeader/DashboardHeader.vue";
import ListLayout from "../../resources-vue/vue-custom-elements/ListLayout/ListLayout.vue";
import DatePicker from "../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import ErrorMessageHandler from "../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import Prompt from "../../resources-vue/vue-dialogs/Prompt/Prompt.vue";
import EmptyTextHandler from "../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import PatientBulkReassign from "../../resources-vue/vue-forms/ReassignForms/PatientBulkReassign/PatientBulkReassign.vue";

export default defineComponent({
    components: {
        DashboardHeader,
        ListLayout,
        PatientBulkReassign,
        DatePicker,
        ErrorMessageHandler,
        EmptyTextHandler
    },

    data() {
        return {
            _taskService: null,
            _toastrService: null,
            term: "" as string | string[],
            selectedTasks: [] as ITaskByUserResponse[],
            deletableTaskCount: null as number,
            selectedTaskId: [] as any[],
            selectedUserId: [] as string[],
            isAddItemRequested: false as boolean,
            columns: 6 as number,
            isError: false as boolean,
            moveListDown: false as boolean,
            routeTerm: null as string | string[],
            showDeleteButton: false as boolean,
            isLoading: false as boolean,
            hideTitle: false as boolean,
            selectAll: false as boolean,
            agencyId: null as string | string[],
            patientName: null as string | string[],
            patientId: null as string | string[],
            application: null as ApplicationEnum,
            branchId: null as string | string[],
            showPatients: false as boolean,
            showEmployees: false as boolean,
            tasks: [] as ITaskByPatientResponse[],
            filteredTasks: [] as ITaskByPatientResponse[],
            taskFilterOptions: {
                start: null,
                end: null,
                userIds: [],
                agencyIds: []
            } as ITaskByUserRequest,
            dateFrom: "" as string,
            dateTo: "" as string,
            isSelected: false as boolean,
            fullName: "" as string,
            visitTime: "" as string,
            id: "" as string,
            dialogRef: null,
            formatDateInput,
            formatDate
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    beforeMount() {
        try {
            this.showLoading(true);
            const params = this.$route.query;
            this.routeTerm = params.term ? params.term : "";
            this.term = this.routeTerm;
            if (params?.agencyId) {
                this.agencyId = params.agencyId;
            }
            if (params?.fullname) {
                this.patientName = params.fullname;
            }
            if (params?.application) {
                this.application = Number(params.application);
            }
            if (params?.patientId) {
                this.patientId = params.patientId;
            }
            if (params?.branchId) {
                this.branchId = params.branchId;
            }
            this.hideTitle = true;
            this.dateFrom = moment().startOf("isoWeek").format("MM/DD/YYYY");
            this.dateTo = moment().startOf("isoWeek").add(6, "weeks").format("MM/DD/YYYY");
            this.taskRefresh();
        } catch (e) {
            console.error(e);
        } finally {
            this.showLoading(false);
        }
    },

    computed: {
        getLoadingCount() {
            return this.tasks?.length > 0 ? this.tasks.length : config.pageSize;
        },

        getHasData() {
            return this.filteredTasks?.length > 0;
        },

        getNotAllSelected() {
            if (!!this.tasks && !!this.selectedTasks) {
                return this.selectedTasks.length > 0 && this.tasks.length !== this.selectedTasks.length;
            } else {
                return false;
            }
        }
    },

    watch: {
        async dateFrom(newValue) {
            this.taskFilterOptions = {
                ...this.taskFilterOptions,
                start: newValue
            };
            await this.taskRefresh();
        },

        async dateTo(newValue) {
            this.taskFilterOptions = {
                ...this.taskFilterOptions,
                end: newValue
            };
            await this.taskRefresh();
        },

        term(newValue, oldValue) {
            if (newValue.length > 2 || (newValue === "" && !!oldValue)) {
                const term = newValue.toLowerCase();
                this.filteredTasks = this.tasks.filter((task) => {
                    return (
                        task.UserFirstName.toLowerCase().indexOf(term) != -1 ||
                        task.UserLastName.toLowerCase().indexOf(term) != -1 ||
                        task.TaskName.toLowerCase().indexOf(term) != -1
                    );
                });
                return true;
            }
            this.filteredTasks = this.tasks;
            return true;
        },

        isLoading() {
            this.selectedTasks = [];
            this.filteredTasks = [];
            this.selectedTaskId = [];
            this.selectedUserId = [];
            this.selectAll = false;
        }
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),

        async taskRefresh() {
            // this.isLoading = true;
            this.selectAll = false;
            try {
                const tasks = await this._taskService.getTasksByPatients({
                    start: this.dateFrom,
                    end: this.dateTo,
                    patientIds: [this.patientId]
                });
                this.isLoading = false;
                this.selectAll = false;
                this.tasks = tasks;
                this.term = "";
                this.filteredTasks = this.tasks;
                this.selectedTaskId = [];
                this.selectedUserId = [];
            } catch (e) {
                console.error(e);
            }
        },

        openReassignForm() {
            this.isAddItemRequested = true;
            this.moveListDown = true;
        },

        redirectToEmployeeList() {
            this.$router.push("/axxess-central/calendar/patient");
        },

        closeReassignForm() {
            this.isAddItemRequested = false;
            this.moveListDown = false;
        },

        async dateFromChanged(startDate: string) {
            this.taskFilterOptions = {
                ...this.taskFilterOptions,
                start: startDate
            };
            await this.taskRefresh();
        },

        async dateToChanged(endDate: string) {
            this.taskFilterOptions = {
                ...this.taskFilterOptions,
                end: endDate
            };
            await this.taskRefresh();
        },

        changeDate(date: string, name: string) {
            if (name == "dateFrom") {
                this.dateFrom = date;
            }
            if (name == "dateTo") {
                this.dateTo = date;
            }
        },

        async pageReset() {
            this.isError = false;
            await this.taskRefresh();
        },

        changeAllSelection() {
            if (!this.selectAll) {
                this.selectedTaskId = [];
                this.selectedUserId = [];
            } else {
                this.selectedUserId = this.filteredTasks
                    .filter((task) => !IsReadOnlyTaskByName(task.Status))
                    .map((task) => task.UserId);

                this.selectedTaskId = this.filteredTasks
                    .filter((task) => !IsReadOnlyTaskByName(task.Status))
                    .map((task) => task);
            }
        },

        async deleteTask() {
            try {
                let promptOptions: IPromptOptions = {
                    message: "Are you sure you want to delete selected task?",
                    okText: "Yes",
                    cancelText: "No"
                };
                const eventIds = [] as string[];
                let episodeId = this.selectedTaskId[0].EpisodeId;

                this.selectedTaskId.map((task) => {
                    eventIds.push(task.Id);
                });

                this.dialogRef = this.$dialog.open(Prompt, {
                    props: {
                        modal: true,
                        contentStyle: {
                            overflowX: "hidden"
                        },
                        showHeader: false
                    },
                    data: {
                        promptOptions
                    },

                    onClose: async (options) => {
                        if (options.data == "success") {
                            const reassignPayload =
                                this.application == ApplicationEnum.AgencyCore
                                    ? {
                                          AgencyId: this.agencyId,
                                          Application: this.application,
                                          PatientId: this.patientId,
                                          EpisodeId: episodeId,
                                          EventId: eventIds,
                                          IsPatient: true
                                      }
                                    : {
                                          AgencyId: this.agencyId,
                                          Application: this.application,
                                          PatientId: this.patientId,
                                          EventId: eventIds,
                                          IsPatient: true
                                      };

                            await this._taskService.patientBulkDelete(reassignPayload);
                            this.taskRefresh();
                            this._toastrService.success({
                                message: `The task has been successfully deleted.`,
                                title: `Task Deleted`
                            });
                        }
                    }
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Task`,
                    message: `There was a problem while deleting the tasks. Please try again.`
                });
            }
        },

        isReadOnlyTask(task: any) {
            if (!!task.Status) {
                return IsReadOnlyTaskByName(task.Status);
            } else {
                return true;
            }
        },

        selectedTasksChanged() {
            if (this.selectedTaskId.length === this.filteredTasks.length) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
        },

        taskChanged(event: Event) {
            if (event) {
                this.isSelected = (event.target as HTMLInputElement)?.checked;
            }

            this.selectedTasksChanged();
        },

        getVisitTime(task: any) {
            if (task.StartTime && task.EndTime) {
                const startTime = moment(task.StartTime).format("hh:mm A");
                const endTime = moment(task.EndTime).format("hh:mm A");
                return (this.visitTime = startTime + " - " + endTime);
            }
            return "Not Available";
        },

        getFullName(task: any) {
            return (this.fullName = task.UserFirstName + " " + task.UserLastName);
        }
    }
});
