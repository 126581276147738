import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { ApplicationNames } from "../../../../../enums/enums";
import { TaskService } from "../../../../../services/task.service";
import { IVisitCountResponse } from "../../../../../resources-vue/vue-interfaces/i-task";
import { clubAgenciesScheduler } from "./../../../../../common/vue-helpers/modifiers/value-modifier";

import CardTemplate from "../../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";

export default defineComponent({
    components: {
        CardTemplate
    },

    data() {
        return {
            _permissionManager: null,
            _taskService: null as TaskService,
            visitCounts: [] as IVisitCountResponse[],
            ApplicationNamesEnum: ApplicationNames,
            isLoading: true,
            hasError: false
        };
    },
    async created() {
        this._permissionManager = Container.instance.get(PermissionManager);
        this._taskService = Container.instance.get(TaskService);

        await this.getVisitCount();
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),

        async getVisitCount() {
            try {
                this.hasError = false;
                this.isLoading = true;

                this.visitCounts = clubAgenciesScheduler(
                    await this._taskService.getVisitCount({
                        isPastOrFuture: true
                    })
                );
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
