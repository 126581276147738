import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "navbar navbar-expand-lg py-0",
  ref: "levelTwoContainer",
  id: "Level_Two_Navbar"
}
const _hoisted_2 = {
  class: "collapse navbar-collapse",
  id: "Level_Two_Menu"
}
const _hoisted_3 = { class: "navbar-nav mr-auto" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsAnimatedHeader = _resolveComponent("TabsAnimatedHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" DO NOT switch show.bind to if.bind. navItem will not work if if.bind is used "),
    _createElementVNode("nav", _hoisted_1, [
      _createCommentVNode(" Small screen - burger icon menu "),
      _createCommentVNode(" <button\r\n            class=\"navbar-toggler py-2\"\r\n            type=\"button\"\r\n            data-toggle=\"collapse\"\r\n            data-target=\"#Level_Two_Menu\"\r\n            aria-controls=\"Level_Two_Menu\"\r\n            aria-expanded=\"false\"\r\n            aria-label=\"Toggle navigation\"\r\n        >\r\n            <i class=\"fas fa-bars mr-2\"></i>{{ activeRouteName }}\r\n        </button> "),
      _createCommentVNode(" App Menu"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode(" Left Nav "),
        _createVNode(_component_TabsAnimatedHeader, { activeItem: _ctx.getActiveItem }, {
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.router, (route) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: route.meta.navItem,
                  class: _normalizeClass(`nav-item cursor-pointer ${_ctx.isActivePath(route.path) ? 'active' : ''}`)
                }, [
                  _createElementVNode("button", {
                    class: "link-btn nav-link menu-link",
                    onClick: ($event: any) => (_ctx.goTo(route.path)),
                    ref_for: true,
                    ref: (el) => _ctx.addItemRefs(el, route)
                  }, [
                    _createElementVNode("h6", null, _toDisplayString(route.meta.menuName), 1 /* TEXT */)
                  ], 8 /* PROPS */, _hoisted_4)
                ], 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _createCommentVNode(" <nav-menu-more-button\r\n                        routes.bind=\"allowableRoutes\"\r\n                        menu-container.bind=\"levelTwoContainer\"\r\n                        active-item.bind=\"activeItem\"\r\n                        if.bind=\"accountLoaded\"\r\n                    >\r\n                    </nav-menu-more-button> ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["activeItem"])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}