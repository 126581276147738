import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { ReferralTypes, HomecareAdmissionSources, HomecareAdmissionTypes } from "../../../../../enums/enums";
import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { UserService } from "../../../../../services/user-service";
import type { IUserResult } from "../../../../../resources-vue/vue-interfaces/i-user";
import { PhysiciansService } from "../../../../../services/physician-service";
import { CentralIntakeService } from "../../../../../services/central-intake.service";
import { VendorsService } from "../../../../../services/vendor-service";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput,
        DatePicker
    },
    props: {
        referral: { type: HomeCareReferral, default: null },
        providerId: { type: String, default: "" }
    },
    inject: ["v"],

    data() {
        return {
            referralSourceTypeahead: null,
            _physicianService: null,
            _centralIntakeService: null,
            _vendorService: null,
            _userService: null,
            showPhysician: false,
            showFamily: false,
            showFriend: false,
            showPowerOfAttorney: false,
            showCommunityProgram: false,
            showFacility: false,
            showOther: false,
            showReferralDetails: false,
            referralTypesEnum: ReferralTypes,
            admissionSourcesEnum: HomecareAdmissionSources,
            admissionTypesEnum: HomecareAdmissionTypes,
            users: null as any,
            notSelectedTooltip: `If not selected, system will default to (9) Information Not Available.`,
            v$: this.v as any,
            enumToMap,
            formatDateInput
        };
    },
    watch: {
        referralSourceTypeahead(newValue, oldValue) {
            if (newValue != null) {
                this.referral.referralSource = newValue.value;
            }
        }
    },

    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._vendorService = Container.instance.get(VendorsService);
        this._userService = Container.instance.get(UserService);
    },
    async mounted() {
        await this.initOptions();
        this.initValuesFromModel();

        if (this.referral.referralType) {
            this.referralType(this.referral.referralType);
        }
    },
    computed: {
        refSource() {
            if (
                this.showCommunityProgram ||
                this.showFamily ||
                this.showFacility ||
                this.showFriend ||
                this.showOther ||
                this.showPowerOfAttorney ||
                this.showPhysician
            ) {
                if (!this.referral.referralSource) {
                    return true;
                } else return false;
            } else return false;
        },
        refDetails() {
            if (this.showCommunityProgram || this.showFacility || this.showOther || this.showPhysician) {
                if (!this.referral.referralDetails) {
                    return true;
                } else return false;
            } else return false;
        }
    },
    methods: {
        refTypeChanged(event: number) {
            this.referralSourceTypeahead = null;
            this.referral.referralSource = null;
            this.$emit("updateReferralDetails", null);
            this.referralType(event);
        },
        async initOptions() {
            this.users = this.userFormated(
                await this._userService.getUsersByRole({
                    include: true,
                    application: this.referral.application
                })
            );
        },
        initValuesFromModel() {
            if (this.referral.referralSource && this.referral.referralSourceName) {
                this.referralSourceTypeahead = {
                    name: this.referral.referralSourceName,
                    value: this.referral.referralSource
                };
            }
        },
        userFormated(users: IUserResult[]) {
            return users.map((user) => ({
                name: `${user.firstName} ${user.lastName}`,
                value: user.id
            }));
        },
        async getPhysicianList(filter: string, limit: number) {
            const data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                application: this.referral.application,
                accountId: this.referral.accountId,
                providerIds: []
            });

            return data.items?.map((item: any) => {
                return {
                    name: item.name,
                    value: item.id
                };
            });
        },
        async getPayors(filter: string, limit: number) {
            const payors = await this._centralIntakeService.getPayorsByName({
                name: filter,
                includeCharityPayor: true,
                providerIds: [],
                application: this.referral.application,
                agencyId: this.referral.accountId,
                accountId: this.referral.accountId
            });

            return payors.map((payor: any) => ({
                name: payor.name,
                value: payor.id
            }));
        },
        async getFacilityList(filter: string, limit: number) {
            const facilities = await this._vendorService.getVendorByTerm({
                accountId: this.referral.accountId,
                name: filter,
                application: this.referral.application,
                providerIds: []
            });

            return facilities.map((facility: any) => ({
                name: facility.name,
                value: facility.id
            }));
        },
        changeDate(date: any, name: string) {
            if (name == "referralDate") {
                this.referral.referralDate = date;
            }
            if (name == "referralSOCDate") {
                this.referral.requestedStartOfCareDate = date;
            }
        },
        referralType(event: number) {
            this.showPhysician = false;
            this.showFamily = false;
            this.showFriend = false;
            this.showPowerOfAttorney = false;
            this.showCommunityProgram = false;
            this.showFacility = false;
            this.showOther = false;
            this.showReferralDetails = false;
            switch (event) {
                case this.referralTypesEnum.enum.Physician:
                    this.showPhysician = true;
                    this.showReferralDetails = true;
                    break;
                case this.referralTypesEnum.enum.Family:
                    this.showFamily = true;
                    break;
                case this.referralTypesEnum.enum.Friend:
                    this.showFriend = true;
                    break;
                case this.referralTypesEnum.enum.PowerOfAttorney:
                    this.showPowerOfAttorney = true;
                    break;
                case this.referralTypesEnum.enum.CommunityProgram:
                    this.showCommunityProgram = true;
                    this.showReferralDetails = true;
                    break;
                case this.referralTypesEnum.enum.Facility:
                    this.showFacility = true;
                    this.showReferralDetails = true;
                    break;
                case this.referralTypesEnum.enum.Other:
                    this.showOther = true;
                    this.showReferralDetails = true;
                    break;
            }
        },
        changeTeamMember(data: any) {
            this.referralSourceTypeahead = data;
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.changeDate(value, name);
        }
    }
});
