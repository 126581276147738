import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { IEmployeeList, IPatientList, MessageService } from "../../../services/message-service";
import { AgencyService, IGetOrganization } from "../../../services/agency.service";
import { SelectProfileEnum } from "../../../enums/enums";
import { getApplicationId } from "../../../common/vue-helpers/modifiers/application-modifiers";
import { capitalizeFirstLetter } from "../../../utilities/capitalizeFirstLetter";

import ScrollPagination from "../../../resources-vue/vue-elements/ScrollPagination/ScrollPagination.vue";
import ProductPill from "../../vue-elements/ProductPill/ProductPill.vue";

export default defineComponent({
    inject: ["dialogRef"],
    components: {
        ScrollPagination,
        ProductPill
    },
    data() {
        return {
            search: "" as string,
            open: false as boolean,
            dialogReference: this.dialogRef as any,
            lists: [] as IPatientList[] | IEmployeeList[],
            dialogData: null as any,
            name: "" as string,
            userOs: "",
            selectedOptions: [],
            _messageService: null,
            _agencyService: null,
            organizations: [] as IGetOrganization[],
            agencyIds: [],
            pageSize: 20,
            isLoading: true,
            pageNumber: 1,
            allPagesLoaded: false,
            hasError: false,
            page: null,
            getApplicationId,
            capitalizeFirstLetter,
            SelectProfileEnum
        };
    },
    created() {
        this._messageService = Container.instance.get(MessageService);
        this.userOs = window.sessionStorage.getItem("userOs");
        this._agencyService = Container.instance.get(AgencyService);
    },
    async mounted() {
        this.dialogData = this.dialogReference.data;
        this.name = this.dialogData.name;

        this.organizations = await this._agencyService.getOrganizations();
        this.organizations[0].agencies.forEach((agency) => {
            let agencyAndApplicationIds = agency.id.split("_");
            let agencyId = agencyAndApplicationIds[0];
            this.agencyIds.push(agencyId);
        });

        if (this.name == SelectProfileEnum.Employee) {
            let userList = this.getUsersList("");
            userList.then((users: IEmployeeList[]) => {
                this.lists = users;
            });
        } else if (this.name == SelectProfileEnum.Patient) {
            let results = this.getPatientsList("");
            results.then((users: IPatientList[]) => {
                this.lists = users;
            });
        }
    },

    computed: {
        getSearchPlaceholder(): string {
            return `Search for ${capitalizeFirstLetter(this.name)}`;
        }
    },
    methods: {
        cancel() {
            this.dialogReference.close("cancel");
        },

        async getUsersList(filter: string) {
            try {
                this.hasError = false;
                this.isLoading = true;
                const data = await this._messageService.getUserByName({
                    page: this.pageNumber,
                    pageLength: 20,
                    term: filter.trim(),
                    agencyIds: this.agencyIds
                });
                this.page = data;

                if (data.items.length > 0) {
                    this.lists = data.items;
                    return data.items;
                }
                return ["No Matches Found"];
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        async getPatientsList(filter: string) {
            try {
                this.hasError = false;
                this.isLoading = true;
                const data = await this._messageService.getPatientsByName({
                    agencyIds: this.agencyIds,
                    term: filter.trim(),
                    page: this.pageNumber,
                    pageLength: 25
                });
                this.page = data;
                if (data.response.length > 0) {
                    this.lists = data.response;
                    return data.response;
                }
                return ["No Matches Found"];
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        onProfileFetch() {
            if (this.name == SelectProfileEnum.Employee) {
                let results = this.getUsersList(this.search);
                results.then((users: IEmployeeList[]) => {
                    this.lists = users;
                });
            } else {
                let results = this.getPatientsList(this.search);
                results.then((users: IPatientList[]) => {
                    this.lists = users;
                });
            }
        },

        updateSelectedItems() {
            this.dialogReference.close(this.selectedOptions);
        },

        pageNumberUpdated(pageToLoad: number) {
            if (!pageToLoad) {
                return;
            }
            if (pageToLoad != this.pageNumber) {
                this.pageNumber = pageToLoad;
            } else {
                return;
            }
            this.allPagesLoaded = this.pageNumber > this.page?.pageCount;
            if (!this.allPagesLoaded) {
                if (this.name == SelectProfileEnum.Employee) {
                    this.getUsersList("");
                } else if (this.name == SelectProfileEnum.Patient) {
                    this.getPatientsList("");
                }
            }
        },
        getAgencyName(name: string, list: IEmployeeList | IPatientList) {
            let agencyName = "";
            if (name ===  SelectProfileEnum.Employee) {
                return (list as IEmployeeList).agencyName;
            } else {
                this.organizations[0].agencies.forEach((agency) => {
                    let agencyAndApplicationIds = agency.id.split("_");
                    if (agencyAndApplicationIds[0] === list.agencyId) {
                        agencyName = agency.name;
                    }
                });
                return agencyName;
            }
        }
    }
});
