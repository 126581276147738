import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import type {
    ICheckEligibilityQuery,
    ICheckEligibilityResult,
    IEligibilityIssueCount
} from "../resources-vue/vue-interfaces/i-eligibility";

@autoinject()
export class EligibilityService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async check(query: ICheckEligibilityQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/check`)
            .useQueryString(query)
            .fetch<ICheckEligibilityResult>();
    }

    public async getEligibilityIssues(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/issues`)
            .useQueryString({ locationIds })
            .fetch<IEligibilityIssueCount>();
    }
}
