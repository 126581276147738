import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import type {
    IGetVendorByTermQuery,
    IGetVendorSlim,
    IVendor,
    IVendorByIdRequestPayload
} from "../resources-vue/vue-interfaces/i-vendor";

@autoinject
export class VendorsService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/vendor";

    public constructor(httpClient: FetchClient) {
        this._fetchClient = httpClient;
    }

    public async getVendorById(payload: IVendorByIdRequestPayload) {
        const queryParam = { application: payload.application, accountId: payload.accountId };
        return await this._fetchClient
            .build(`${this._baseUrl}/${payload.id}`)
            .useQueryString(queryParam)
            .fetch<IVendor>();
    }

    public async getVendorByTerm(query: IGetVendorByTermQuery) {
        return await this._fetchClient.build(`${this._baseUrl}/slim`).useQueryString(query).fetch<IGetVendorSlim[]>();
    }
}
