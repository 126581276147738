import { ApplicationEnum } from "../../enums/enums";
import type { IHospiceReferral } from "../vue-interfaces/i-hospice-referral";
import type { IPhoneNumber } from "../../interfaces/i-phone-number";
import type { IPriorHospice } from "../vue-interfaces/i-prior-hospice";
import { Address } from "./address";
import { PriorHospice } from "./prior-hospice";
import { Referral } from "./referral";

export class HospiceReferral extends Referral implements IHospiceReferral {
    public isReAdmit: boolean = false;
    /*------------------ Start of Payor Information --------------------------*/
    public isTransfer: boolean = false;
    /*------------------ End of Payor Information --------------------------*/

    /*------------------ Start of Admission Information --------------------------*/
    public admissionType: number = null;
    public admissionSource: number = null;
    public benefitPeriodNumber: string = null;
    public application: ApplicationEnum;
    public accountId: string;
    public agencyId: string;
    public agencyLocationId: string;
    public priorHospice: IPriorHospice = new PriorHospice();
    /*------------------ End of Admission Information --------------------------*/

    /*------------------ Start of Clinical Information --------------------------*/
    public preadmissionEvaluationDate: string;
    public preadmissionEvaludationClinicianId: string;
    public isPreadmissionRequired: boolean;
    public tolerablePainLevel: number;
    /*------------------ End of Clinical Information --------------------------*/

    /*------------------ Start of Referral Readiness --------------------------*/
    public referralReadiness: number[] = [];
    public completedBy: string;
    public completedDate: string;
    public isCopyObtained: boolean = false;
    public contactPersonFirstName: string = "";
    public contactPersonLastName: string = "";
    public contactPersonPhoneNumber: IPhoneNumber = {
        number: "",
        extension: "",
        type: 0,
        country: null,
        isPrimary: true
    };
    /*------------------ End of Referral Readiness --------------------------*/

    public genderIdentity: number = 0;
    public otherGenderIdentity: string = "";
    public sexualOrientation: number = 0;
    public otherSexualOrientation: string = "";
    public declineToSpecifyEthnicity: boolean = false;
    public declineToSpecifyRace: boolean = false;
    /*------------------ Start of Health History ----------------------------*/
    public smokingStatus: number = 0;
    public smokingStartDate: string = "";
    public smokingEndDate: string = "";
    public historicalSmokingStatus: number = 0;
    public historicalSmokingStartDate: string = "";
    public historicalSmokingEndDate: string = "";
    /*------------------ End of Health History ----------------------------*/
    public palliativeReferralReadiness: number[] = [];
    public palliativeCareOrderOptions: number;

    public constructor(application: ApplicationEnum, data?: IHospiceReferral) {
        super();
        if (!!data) {
            Object.assign(this, data);
            this.primaryAddress = new Address(data.primaryAddress);
            this.mailingAddress = new Address(data.mailingAddress);
            this.visitAddress = new Address(data.visitAddress);
            if (this.phoneNumbers.length === 0) {
                this.phoneNumbers = [
                    {
                        number: "",
                        extension: "",
                        type: 0,
                        country: null,
                        isPrimary: true
                    },
                    {
                        number: "",
                        extension: "",
                        type: 0,
                        country: null,
                        isPrimary: false
                    }
                ];
            }
        }
        if (application == ApplicationEnum.AxxessHospiceFE) {
            this.priorHospice = new PriorHospice(data?.priorHospice);
        } else if (application == ApplicationEnum.AxxessPalliative) {
            if (!this.palliativeReferralReadiness) {
                this.palliativeReferralReadiness = [];
            }
        }
    }
}
