<template>
    <FormSection title="Clinical" :full-height="false" :application-id="referral?.application">
        <template #content>
            <section class="row my-2 col-12 px-5 row no-gutters">
                <section class="col-12 row">
                    <div class="form-group col-12 col-lg-6 pr-lg-2">
                        <label for="patient-middle-initial"> Services Required </label>
                        <TagsInput
                            :data="servicesRequired"
                            :tags="selectedServicesRequired"
                            placeholder="Select Option...."
                            aria-autocomplete="off"
                            :open-on-focus="true"
                            v-on:updateTags="updateTags"
                        />
                    </div>
                    <div class="form-group col-12 col-lg-6">
                        <label for="patient-first-name">
                            Attending Physician
                            <a
                                tabindex="0"
                                role="button"
                                v-custom-popover="{ content: sanitizeMarkup(attendingPhysicianTooltip) }"
                            >
                                <i class="fas fa-question-circle text-dark font-size-xs"></i>
                            </a>
                        </label>
                        <TypeaheadInput
                            :getData="getPhysicianList"
                            :value="attendingPhysician"
                            v-on:valueChanged="attendingPhysicianChanged"
                            :init-value="attendingPhysician"
                            placeholder="Start Typing..."
                        >
                        </TypeaheadInput>
                        <div class="form-group mt-1">
                            <div class="custom-control custom-checkbox custom-control-inline">
                                <input
                                    type="checkbox"
                                    id="physicianContacted"
                                    class="custom-control-input au-target"
                                    v-model="referral.ReferralExtension.IsPhysicianContacted"
                                />
                                <label class="custom-control-label" for="physicianContacted">
                                    <span class="mr-1"
                                        >The attending physician agreed to provide ongoing care for the patient.</span
                                    >
                                </label>
                                <a
                                    tabindex="0"
                                    role="button"
                                    v-custom-popover="{ content: sanitizeMarkup(attendingPhysicianContactedTooltip) }"
                                >
                                    <i class="fas fa-question-circle text-dark font-size-xs"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="col-12 row no-gutters">
                    <div class="col-12">
                        <div class="w-100">
                            <div class="row col-12">
                                <h6 class="mb-2 d-flex align-items-center">
                                    <b class="mr-2">Primary Emergency Contact</b>
                                </h6>
                            </div>
                            <div class="row col-12">
                                <div class="form-group mt-1 mr-4">
                                    <div>
                                        <input
                                            name="respresentative"
                                            type="checkbox"
                                            id="legalRepresentative"
                                            ref="legal"
                                            :checked="referral.Referral.EmergencyContact.Representative == 1"
                                            @change="representationChanged(1, referral.Referral.EmergencyContact, -1)"
                                        />
                                        <label class="px-2" for="legalRepresentative">Legal Representative </label>
                                    </div>
                                </div>
                                <div class="form-group mt-1">
                                    <div>
                                        <input
                                            name="respresentative"
                                            type="checkbox"
                                            id="patientRepresentative"
                                            ref="patient"
                                            :checked="referral.Referral.EmergencyContact.Representative == 2"
                                            @change="representationChanged(2, referral.Referral.EmergencyContact, -1)"
                                        />
                                        <label class="px-2" for="patientRepresentative"
                                            >Patient Selected Representative
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <section class="row pb-0 no-gutters">
                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                    <label for="auth-contact-first-name"> First Name </label>
                                    <input
                                        name="auth-contact-first-name"
                                        class="form-control"
                                        maxlength="50"
                                        type="text"
                                        placeholder="Enter First Name"
                                        v-model="referral.Referral.EmergencyContact.FirstName"
                                        :disabled="!isEditRequested"
                                    />
                                </div>
                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                    <label for="auth-contact-last-name"> Last Name </label>
                                    <input
                                        name="auth-contact-last-name"
                                        class="form-control"
                                        maxlength="50"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        v-model="referral.Referral.EmergencyContact.LastName"
                                        :disabled="!isEditRequested"
                                    />
                                </div>
                                <div class="w-100"></div>
                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                    <label for="auth-contact-relationship"> Relationship </label>
                                    <select
                                        class="form-control"
                                        name="auth-contact-relationship"
                                        v-model="referral.Referral.EmergencyContact.RelationOption"
                                        @change="isOtherOptionEmergencyContact()"
                                        :disabled="!isEditRequested"
                                    >
                                        <option
                                            v-for="(relation, index) in referralOptions.relation"
                                            :key="index"
                                            :value="relation.value"
                                        >
                                            {{ relation.text }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-12 col-lg-4 pr-lg-2"
                                    v-if="referral.Referral.EmergencyContact.Relation === 11"
                                >
                                    <label for="auth-contact-rel-desc"> Other </label>
                                    <input
                                        name="auth-contact-rel-desc"
                                        class="form-control"
                                        type="text"
                                        placeholder="Enter Relationship"
                                        v-model="referral.Referral.EmergencyContact.Relationship"
                                        :disabled="!isEditRequested"
                                    />
                                </div>
                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                    <label for="auth-contact-email"> Email </label>
                                    <input
                                        name="auth-contact-email"
                                        class="form-control"
                                        maxlength="50"
                                        type="text"
                                        placeholder="Enter Email"
                                        v-model="referral.Referral.EmergencyContact.EmailAddress"
                                        :disabled="!isEditRequested"
                                    />
                                </div>
                                <div class="form-group col-12 col-lg-3 pr-lg-2">
                                    <label for="referral-mobile-number">
                                        Mobile Phone
                                        <i
                                            class="fas fa-question-circle"
                                            v-tooltip="{
                                                title: `For emergency preparedness, patient will be contacted at this number. This will also populate to the emergency preparedness report.`
                                            }"
                                        ></i>
                                    </label>
                                    <input
                                        type="text"
                                        name="referral-mobile-number"
                                        class="form-control phone-number-input"
                                        :value="formatPhone(referral.Referral.EmergencyContact.PrimaryPhone)"
                                        @input="setEmPrimaryPhone($event.target.value)"
                                        maxlength="14"
                                        placeholder="Enter Number"
                                        v-number-only
                                    />
                                </div>
                                <div class="form-group col-12 col-lg-3 pr-lg-2">
                                    <label for="referral-alternate-phone"> Alternate Phone </label>
                                    <input
                                        type="text"
                                        name="referral-alternate-phone"
                                        class="form-control phone-number-input"
                                        :value="formatPhone(referral.Referral.EmergencyContact.AlternatePhone)"
                                        @input="setEmAlternatePhone($event.target.value)"
                                        maxlength="14"
                                        placeholder="Enter Number"
                                        v-number-only
                                    />
                                </div>
                                <div class="col-12 form-group d-flex">
                                    <div class="custom-control custom-checkbox custom-control-inline mt-2">
                                        <input
                                            type="checkbox"
                                            id="same-patient-addr"
                                            class="custom-control-input au-target"
                                            v-model="referral.Referral.EmergencyContact.SameAsPatientAddress"
                                            @change="sameAsPatientAddressChangedPrimary()"
                                        />
                                        <label class="custom-control-label" for="same-patient-addr">
                                            <span class="mr-1">Same as Patient Address</span>
                                        </label>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="col-lg-8 pl-0 row">
                            <ReferralAddressForm
                                :address="emergencyContactFields.primaryEmergency"
                                :application-id="referral.application"
                                :account-id="referral.accountId"
                                :address-type="'primaryEmergency'"
                                :county-data="primaryEmergencyCounties"
                                :show-code="false"
                                :fields-changed="addressFieldsChanged"
                                :referral-options="referralOptions"
                                :isRefer="isRefer"
                                v-on:updatecountyData="updatecountyData"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group col-12 pl-0">
                            <div v-for="(contact, index) in referral.ReferralExtension.AdditionalEmergencyContact">
                                <FormSection :application-id="referral.application">
                                    <template #content>
                                        <div class="d-flex flex-column px-3 py-2" slot="content">
                                            <div class="row pb-0 no-gutters">
                                                <div class="col-12">
                                                    <h6 class="mb-2 d-flex align-items-center">
                                                        <b class="mr-2">Additional Emergency Contact {{ index + 1 }}</b>
                                                        <button
                                                            class="btn table-btn btn-outline-danger mr-1 ml-auto"
                                                            @click="removeEmergencyContact(index)"
                                                        >
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </h6>
                                                </div>
                                                <div class="form-group mt-1 mr-4">
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            :name="`patientSelectedRepresentative-emergency-contact-${index}`"
                                                            :id="`legalRepresentative-emergency-contact-${index}`"
                                                            ref="legals"
                                                            :checked="contact.Representative == 1"
                                                            @change="representationChanged(1, contact, index)"
                                                        />
                                                        <label
                                                            class="px-2"
                                                            :for="`legalRepresentative-emergency-contact-${index}`"
                                                        >
                                                            <span class="mr-1">Legal Representative</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group mt-1">
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            :name="`patientSelectedRepresentative-emergency-contact-${index}`"
                                                            :id="`patientSelectedRepresentative-emergency-contact-${index}`"
                                                            ref="patients"
                                                            :checked="contact.Representative == 2"
                                                            @change="representationChanged(2, contact, index)"
                                                        />
                                                        <label
                                                            class="px-2"
                                                            :for="`patientSelectedRepresentative-emergency-contact-${index}`"
                                                        >
                                                            <span class="mr-1">Patient Selected Representative</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                                    <label for="auth-contact-first-name-add">
                                                        First Name
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        name="auth-contact-first-name-add"
                                                        class="form-control"
                                                        maxlength="50"
                                                        type="text"
                                                        placeholder="Enter First Name"
                                                        v-model="contact.FirstName"
                                                        :disabled="!isEditRequested"
                                                    />
                                                </div>
                                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                                    <label for="auth-contact-last-name-add">
                                                        Last Name
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        name="auth-contact-last-name-add"
                                                        class="form-control"
                                                        maxlength="50"
                                                        type="text"
                                                        placeholder="Enter Last Name"
                                                        v-model="contact.LastName"
                                                        :disabled="!isEditRequested"
                                                    />
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                                    <label for="auth-contact-relationship-add"> Relationship </label>
                                                    <select
                                                        class="form-control"
                                                        name="auth-contact-relationship-add"
                                                        v-model="contact.RelationOption"
                                                        :disabled="!isEditRequested"
                                                        @change="isOtherOptionAdditionalContacts(index)"
                                                    >
                                                        <option value="">Select Relationship</option>
                                                        <option
                                                            v-for="(relation, index) of referralOptions.relation"
                                                            :key="index"
                                                            :value="relation.value"
                                                        >
                                                            {{ relation.text }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div
                                                    class="form-group col-12 col-lg-4"
                                                    v-if="referral.Referral.EmergencyContact.Relation === 11"
                                                >
                                                    <label for="auth-contact-rel-desc"> Other </label>
                                                    <input
                                                        name="auth-contact-rel-desc"
                                                        class="form-control"
                                                        type="text"
                                                        placeholder="Enter Relationship"
                                                        v-model="contact.Relationship"
                                                        :disabled="!isEditRequested"
                                                    />
                                                </div>
                                                <div class="form-group col-12 col-lg-4 pr-lg-2">
                                                    <label for="auth-contact-email-add"> Email </label>
                                                    <input
                                                        name="auth-contact-email-add"
                                                        class="form-control"
                                                        maxlength="50"
                                                        type="text"
                                                        placeholder="Enter Email"
                                                        v-model="contact.EmailAddress"
                                                        :disabled="!isEditRequested"
                                                    />
                                                </div>
                                                <div class="form-group col-12 col-lg-3 pr-lg-2">
                                                    <label for="referral-mobile-number-add">
                                                        Mobile Phone
                                                        <i
                                                            class="fas fa-question-circle"
                                                            v-tooltip="{
                                                                title: 'For emergency preparedness, patient will be contacted at this number. This will also populate to the emergency preparedness report.'
                                                            }"
                                                        ></i>
                                                        <span class="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        name="referral-mobile-number-add"
                                                        type="text"
                                                        class="form-control phone-number-input"
                                                        :value="formatPhone(contact.PrimaryPhone)"
                                                        @input="setPrimaryPhone($event.target.value, index)"
                                                        maxlength="14"
                                                        placeholder="Enter Number"
                                                        v-number-only
                                                    />
                                                </div>
                                                <div class="form-group col-12 col-lg-3 pr-lg-2">
                                                    <label for="referral-alternate-phone-add"> Alternate Phone </label>
                                                    <input
                                                        name="referral-alternate-phone-add"
                                                        type="text"
                                                        class="form-control phone-number-input"
                                                        :value="formatPhone(contact.AlternatePhone)"
                                                        @input="setAlternatePhone($event.target.value, index)"
                                                        maxlength="14"
                                                        placeholder="Enter Number"
                                                        v-number-only
                                                    />
                                                </div>
                                                <div class="w-100"></div>
                                                <div class="col-lg-8 pl-0 row">
                                                    <div class="col-12 form-group d-flex">
                                                        <div
                                                            class="custom-control custom-checkbox custom-control-inline mt-2"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                :id="`same-patient-addr-add-${index}`"
                                                                class="custom-control-input au-target"
                                                                v-model="contact.SameAsPatientAddress"
                                                                :disabled="!isEditRequested"
                                                                @change="sameAddressChangedForAdditional(index)"
                                                            />
                                                            <label
                                                                class="custom-control-label"
                                                                :for="`same-patient-addr-add-${index}`"
                                                            >
                                                                <span class="mr-1">Same as Patient Address</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ReferralAddressForm
                                                        v-if="additionalEmergency"
                                                        :address="additionalEmergency[index].emergency"
                                                        :application-id="referral.application"
                                                        :index="index"
                                                        :account-id="referral.accountId"
                                                        :address-type="'emergency'"
                                                        className="pl-3"
                                                        :show-code="false"
                                                        :fields-changed="addressFieldsChanged"
                                                        :referral-options="referralOptions"
                                                        :show-county-frzip="false"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </FormSection>
                            </div>
                        </div>
                        <button class="btn btn-xs btn-primary mb-2" @click="addEmergencyContact()">
                            + Add Emergency Contact
                        </button>
                    </div>
                </section>
            </section>
        </template>
    </FormSection>
</template>

<script src="./ClinicalInformation.ts"></script>
