import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum RelationToReferralEnum {
    Spouse = 1 as any,
    Self = 2 as any,
    Child = 3 as any,
    Employee = 4 as any,
    Unknown = 5 as any,
    OrganDonor = 6 as any,
    CadaverDonor = 7 as any,
    LifePartner = 8 as any,
    Other = 9 as any
}
class RelationToReferralClass extends EnumWithDescription<RelationToReferralEnum> {
    public readonly enum = RelationToReferralEnum;
    public readonly enumName: string = "RelationToReferralEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<RelationToReferralEnum>> = new Map([
        [
            "Spouse",
            {
                value: RelationToReferralEnum.Spouse,
                name: "Spouse"
            }
        ],
        [
            "Self",
            {
                value: RelationToReferralEnum.Self,
                name: "Self"
            }
        ],
        [
            "Child",
            {
                value: RelationToReferralEnum.Child,
                name: "Child"
            }
        ],
        [
            "Employee",
            {
                value: RelationToReferralEnum.Employee,
                name: "Employee"
            }
        ],
        [
            "Unknown",
            {
                value: RelationToReferralEnum.Unknown,
                name: "Unknown"
            }
        ],
        [
            "OrganDonor",
            {
                value: RelationToReferralEnum.OrganDonor,
                name: "Organ donor"
            }
        ],
        [
            "CadaverDonor",
            {
                value: RelationToReferralEnum.CadaverDonor,
                name: "Cadaver donor"
            }
        ],
        [
            "LifePartner",
            {
                value: RelationToReferralEnum.LifePartner,
                name: "Life partner"
            }
        ],
        [
            "Other",
            {
                value: RelationToReferralEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const RelationToReferral = new RelationToReferralClass();
