import { DialogController } from "aurelia-dialog";
import { inject } from "aurelia-framework";
import moment, { Moment } from "moment";

export interface IIdleTimeoutOptions {
    idleWarningTimeInMinutes: number;
}

@inject(DialogController)
export class IdleTimeout {
    private _idleInterval: NodeJS.Timer;
    public seconds: string | number;
    public minutes: string | number;
    public controller: DialogController;
    public options: IIdleTimeoutOptions = {
        idleWarningTimeInMinutes: 2
    };

    public constructor(controller: DialogController) {
        this.controller = controller;
    }

    public activate(options: IIdleTimeoutOptions) {
        if (!!options) {
            Object.assign(this.options, options);
        }

        this.startTimer();
    }

    private startTimer(): void {
        this._idleInterval = setInterval(() => {
            let idleTime: string = JSON.parse(localStorage.getItem("idleExpiry"));
            let a: Moment = moment(idleTime);
            let b: Moment = moment();
            let duration: number = a.diff(b, "s");

            if (duration <= this.options.idleWarningTimeInMinutes * 60) {
                if (duration > 0) {
                    this.minutes = (duration / 60) | 0;
                    this.seconds = duration % 60 | 0;

                    this.minutes = this.minutes < 10 ? `0${this.minutes}` : this.minutes;
                    this.seconds = this.seconds < 10 ? `0${this.seconds}` : this.seconds;
                } else {
                    this.controller.cancel();
                }
            }
        }, 1000);
    }

    public detached(): void {
        window.clearInterval(this._idleInterval);
    }
}
