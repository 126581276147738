import { defineComponent } from "vue";

import { IPayRate } from "../../../resources-vue/vue-interfaces/i-visit-details";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            dialogReference: this.dialogRef as any,
            payRates: [] as IPayRate[]
        };
    },

    async mounted() {
        this.payRates = this.dialogReference.data.payRates;
    },

    methods: {
        areRatesAboveMin() {
            return this.payRates?.every((rate: IPayRate) => rate.Rate >= rate.MinRate);
        },
        isBelowMinRate(currentRate: number, minRate: number): boolean {
            return currentRate < minRate;
        },

        savePayRates() {
            if (this.areRatesAboveMin()) {
                this.payRates = this.payRates.map((payrate) => ({
                    ...payrate,
                    Message: "success"
                }));
            }
            this.dialogReference.close(this.payRates);
        },

        cancel() {
            this.dialogReference.close("cancel");
        }
    }
});
