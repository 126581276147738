import { DialogController } from "aurelia-dialog";
import { autoinject, PLATFORM } from "aurelia-framework";

PLATFORM.moduleName("./live-login-token");
@autoinject
export class LiveLoginToken {
    public controller: DialogController;
    public code: string;

    public constructor(controller: DialogController) {
        this.controller = controller;
    }

    public activate(data: any) {
        let response = data.response.data;
        this.code = response.code;
    }
}
