import { autoinject, bindable, bindingMode, containerless, customElement } from "aurelia-framework";
import * as moment from "moment";
import type {
    ICheckEligibilityQuery,
    ICheckEligibilityResult,
    IEligibilityMissingUrl
} from "../../../resources-vue/vue-interfaces/i-eligibility";
import { EligibilityService } from "../../../services/eligibility-service";
import { ApplicationEnum } from "./../../../enums/enums";

@containerless
@autoinject
@customElement("eligibility-check")
export class EligibilityCheck {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public firstName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public lastName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public gender: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public dateOfBirth: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public locationId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public medicareNumber: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public mbiNumber: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public mbiNumberNa: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public medicareNumberNa: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public application: ApplicationEnum;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public accountId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public missingUrl: IEligibilityMissingUrl = {
        locationId: null,
        firstName: null,
        lastName: null,
        gender: null,
        dateOfBirth: null,
        medicareNumber: null
    };
    private readonly _eligibilityService: EligibilityService;
    public eligibilityNumber: string;
    public eligibilityNA: boolean;
    public isCheckingEligibility: boolean = false;
    public eligibility: ICheckEligibilityResult = null;
    public eligibilityQuery: ICheckEligibilityQuery = null;
    public isReportExpanded: boolean = true;

    public constructor(eligibilityService: EligibilityService) {
        this._eligibilityService = eligibilityService;
    }

    public async attached() {
        this.eligibilityNumber = this.mbiNumber ? this.mbiNumber : this.medicareNumber;
        this.checkEligibility();
    }

    public async checkEligibility() {
        if (
            !(
                this.firstName &&
                this.lastName &&
                this.gender &&
                this.locationId &&
                this.dateOfBirth &&
                this.eligibilityNumber
            )
        ) {
            return;
        }
        try {
            this.isCheckingEligibility = true;
            this.eligibilityQuery = {
                locationId: this.locationId,
                firstName: this.firstName,
                lastName: this.lastName,
                gender: this.gender,
                dateOfBirth: this.dateOfBirth,
                medicareNumber: this.eligibilityNumber,
                application: this.application,
                accountId: this.accountId,
                agencyId: this.accountId
            };
            this.eligibility = await this._eligibilityService.check(this.eligibilityQuery);
            this.sortBenefitPeriods();
            this.sortEpisodes();
            this.isCheckingEligibility = false;
        } catch (e) {
            this.isCheckingEligibility = false;
            this.eligibility = {
                request_Result: {
                    success: "No",
                    response: "Server Error"
                }
            };
        }
    }

    public firstNameChanged() {
        if (this.eligibilityQuery && this.firstName != this.eligibilityQuery.firstName) {
            this.eligibility = null;
        }
    }

    public lastNameChanged() {
        if (this.eligibilityQuery && this.lastName != this.eligibilityQuery.lastName) {
            this.eligibility = null;
        }
    }

    public dateOfBirthChanged() {
        if (this.eligibilityQuery && this.dateOfBirth != this.eligibilityQuery.dateOfBirth) {
            this.eligibility = null;
        }
    }

    public locationIdChanged() {
        if (this.eligibilityQuery && this.locationId != this.eligibilityQuery.locationId) {
            this.eligibility = null;
        }
    }

    public mbiNumberChanged() {
        this.setEligibilityNumber();
    }

    public medicareNumberChanged() {
        this.setEligibilityNumber();
    }

    public medicareNumberNaChanged() {
        this.setEligibilityNumber();
    }

    public mbiNumberNaChanged() {
        this.setEligibilityNumber();
    }

    public sortEpisodes() {
        if (this.eligibility) {
            if (this.eligibility.episode.success == "Yes") {
                if (this.eligibility.episode.episodes && this.eligibility.episode.episodes.length > 0) {
                    this.eligibility.episode.episodes.sort((a, b) =>
                        moment(a.period_date_range?.split("-")[0]) < moment(b.period_date_range?.split("-")[0]) ? 1 : -1
                    );
                }
            }
        }
    }

    public sortBenefitPeriods() {
        if (this.eligibility) {
            if (this.eligibility.hospice.success == "Yes") {
                if (this.eligibility.hospice.periods && this.eligibility.hospice.periods.length > 0) {
                    this.eligibility.hospice.periods.sort((a, b) =>
                        moment(a.period_date_range?.split("-")[0]) < moment(b.period_date_range?.split("-")[0]) ? 1 : -1
                    );
                }
            }
        }
    }

    public showReport() {
        this.isReportExpanded = true;
    }

    public hideReport() {
        this.isReportExpanded = false;
    }

    private setEligibilityNumber() {
        let result = null;
        this.eligibilityNA = false;
        if (this.medicareNumberNa && this.mbiNumberNa) {
            this.eligibilityQuery = null;
            this.eligibility = null;
            this.eligibilityNA = true;
            result = null;
        } else if (this.medicareNumberNa && !!this.mbiNumber) {
            result = this.mbiNumber;
        } else if (this.mbiNumberNa && !!this.medicareNumber) {
            result = this.medicareNumber;
        } else if (!this.medicareNumberNa && !this.mbiNumberNa) {
            result = !!this.mbiNumber ? this.mbiNumber : this.medicareNumber;
        }

        if (!!this.eligibilityQuery && this.eligibilityQuery.medicareNumber !== result) {
            this.eligibilityQuery = null;
            this.eligibility = null;
        }
        this.eligibilityNumber = result;
    }
}
