import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum DocumentTypeCategoryEnum {
    Employee = 1 as any,
    Patient = 2 as any
}
class DocumentTypeCategoryClass extends EnumWithDescription<DocumentTypeCategoryEnum> {
    public readonly enum = DocumentTypeCategoryEnum;
    public readonly enumName: string = "DocumentTypeCategoryEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<DocumentTypeCategoryEnum>> = new Map([
        [
            "Employee",
            {
                value: DocumentTypeCategoryEnum.Employee,
                name: "Employee"
            }
        ],
        [
            "Patient",
            {
                value: DocumentTypeCategoryEnum.Patient,
                name: "Patient"
            }
        ]
    ]);
}
export const DocumentTypeCategory = new DocumentTypeCategoryClass();
