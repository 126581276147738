import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum SourceOfReferralEnum {
    Facility = 1,
    Physician = 2,
    Other = 3,
    Internal = 4
}
class SourceOfReferralClass extends EnumWithDescription<SourceOfReferralEnum> {
    public readonly enum = SourceOfReferralEnum;
    public readonly enumName: string = "SourceOfReferralEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<SourceOfReferralEnum>> = new Map([
        [
            "Facility",
            {
                value: SourceOfReferralEnum.Facility,
                name: "Facility"
            }
        ],
        [
            "Physician",
            {
                value: SourceOfReferralEnum.Physician,
                name: "Physician"
            }
        ],
        [
            "Other",
            {
                value: SourceOfReferralEnum.Other,
                name: "Other"
            }
        ],
        [
            "Internal",
            {
                value: SourceOfReferralEnum.Internal,
                name: "Internal"
            }
        ]
    ]);
}

export const SourceOfReferral = new SourceOfReferralClass();
