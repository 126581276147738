import "../../../visit-details/visit-details.scss";

import { autoinject, bindable, bindingMode, BindingEngine, customElement } from "aurelia-framework";
import { observable } from "aurelia-binding";
import { ValidationController, ValidationControllerFactory, validateTrigger } from "aurelia-validation";
import { Subscription } from "aurelia-event-aggregator";
import { DialogService, DialogSettings } from "aurelia-dialog";

import type { IReassignVisitDetails } from "../../../../../interfaces/i-reassign-visit";
import { IHomeCareTasksData, IHomeCareTasksParams, TaskService } from "../../../../../services/task.service";
import { ToastrService } from "../../../../../services/toastr.service";
import nameOf from "../../../../../common/name-of";
import { ApplicationEnum, RecurrenceType, RecurrenceTypeEnum } from "../../../../../enums/enums";
import { BulkReassign } from "../../../../../models/bulk-reassign";
import { IGetTaskPayorList, ITaskPayor } from "../../../../../resources-vue/vue-interfaces/i-task";
import { ScheduleVisitHomeCareModel } from "../../../../../models/schedule-visits/schedule-visit-home-care";
import moment from "moment";
import { ISelectDropDownOption } from "../../../../elements/group-dropdown-select/group-dropdown-select";
import { DateTimeModel } from "../../../../../models/schedule-visits/date-time";
import { UserService } from "../../../../../services/user-service";
import { IGetClientRequest, IGetClientResponse } from "../../../../../interfaces/i-user";
import {
    IRescheduleValidations,
    RescheduleValidations
} from "../../../../../resources/dialogs/reschedule-validations/reschedule-validations";
import { IHandleTaskReschuleIssue } from "../../../../../resources-vue/vue-interfaces/i-visit-details";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

@autoinject
@customElement("schedule-visit-home-care")
export class ScheduleVisitHomecare {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public visitDetails: BulkReassign;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public cancel: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public ok: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public reassign: (params: { reassignDetails: IReassignVisitDetails }) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isSaving: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    private _taskService: TaskService;
    private _toastrService: ToastrService;
    private _userService: UserService;
    private _subscriptions: Subscription[] = [];
    private _bindingEngine: BindingEngine;
    private _dialogService: DialogService;
    public controller: ValidationController = null;
    public commentInputClass: { input: string; textarea?: string } = { input: "w-35" };
    public isSavingReason: boolean = false;
    public newVisitDetails: ScheduleVisitHomeCareModel = new ScheduleVisitHomeCareModel();
    public newVisitDetailsReset: ScheduleVisitHomeCareModel;
    public disciplineTasks: IHomeCareTasksData[] = [];
    public SaveEnum = SaveEnum;
    public currentUserId: string = "";
    public clients: IGetClientResponse[] = [];
    public recurrenceType: typeof RecurrenceType = RecurrenceType;
    public recurrenceTypeEnum: typeof RecurrenceTypeEnum = RecurrenceTypeEnum;
    public taskDropdownSelectOptions: ISelectDropDownOption = {};
    public clientDropdownSelectOptions: ISelectDropDownOption = {};
    public selectedAgencies: string[] = [];
    public isSingleDate: boolean = true;
    public payorList: ITaskPayor[] = [];
    public isPaymentSourceLoading: boolean = false;
    public selectedDays: string[] = [];
    public isTasksLoading: boolean = false;
    public checkAllByDefault: boolean = false;
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("visitDatesChanged") })
    public visitDates: string = "";
    public recurrence: string;
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("shiftLengthChanged") })
    public shiftLength: number;
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("startTimeChanged") })
    public startTime: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("endTimeChanged") })
    public endTime: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("scheduleWithoutTimeChanged") })
    public scheduleWithoutTime: boolean = false;
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("clientChanged") })
    public client: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHomecare>("overrideShiftLengthChanged") })
    public overrideShiftLength: boolean = false;

    public constructor(
        validationController: ValidationController,
        taskService: TaskService,
        toastrService: ToastrService,
        bindingEngine: BindingEngine,
        controllerFactory: ValidationControllerFactory,
        userService: UserService,
        dialogService: DialogService
    ) {
        this.controller = validationController;
        this._taskService = taskService;
        this._toastrService = toastrService;
        this._bindingEngine = bindingEngine;
        this.controller = controllerFactory.createForCurrentScope();
        this.controller.validateTrigger = validateTrigger.changeOrBlur;
        this._userService = userService;
        this._dialogService = dialogService;
    }

    public async attached() {
        this.initSubscriptions();
        Object.assign(this.visitDetails, {});
        try {
            this.isLoading = true;
            await this.getDisciplineTasks();
            await this.getClient();
            this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
            this.newVisitDetails.application = this.visitDetails.Application;
            this.newVisitDetails.patientId = this.visitDetails.Id;
            this.newVisitDetailsReset = JSON.parse(JSON.stringify(this.newVisitDetails));
        } catch (error) {
            console.log(error);
        } finally {
            this.isLoading = false;
        }
    }

    public formatTime(dateTime: Date) {
        return dateTime
            ? new Date(dateTime).toLocaleTimeString("en-us", {
                  hour: "2-digit",
                  minute: "2-digit"
              })
            : "";
    }

    private initSubscriptions() {
        this._subscriptions.push(
            this._bindingEngine
                .propertyObserver(this.newVisitDetails.startDateTime, `${nameOf<DateTimeModel>("date")}`)
                .subscribe(() => {
                    this.startDateChanged();
                })
        );
        this._subscriptions.push(
            this._bindingEngine
                .propertyObserver(this.newVisitDetails.startDateTime, `${nameOf<DateTimeModel>("time")}`)
                .subscribe(() => {
                    this.startTimeChanged();
                })
        );
        this._subscriptions.push(
            this._bindingEngine
                .propertyObserver(this.newVisitDetails.endDateTime, `${nameOf<DateTimeModel>("date")}`)
                .subscribe(() => {
                    this.endDateChanged();
                })
        );
        this._subscriptions.push(
            this._bindingEngine
                .propertyObserver(this.newVisitDetails.endDateTime, `${nameOf<DateTimeModel>("time")}`)
                .subscribe(() => {
                    this.endTimeChanged();
                })
        );
    }

    private async getDisciplineTasks() {
        try {
            const payload: IHomeCareTasksParams = {
                agencyId: this.visitDetails.AgencyId,
                application: ApplicationEnum.HomeCare
            };
            this.disciplineTasks = [];
            this.disciplineTasks = (await this._taskService.getHCTaskList(payload)).data.data;
            this.setDisciplineMultiSelect();
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Visit Type Failed`,
                message: `Fetching Visit Type Operation Failed, Please Contact Axxess if issue still persists.`
            });
        }
    }

    private async setDisciplineMultiSelect() {
        try {
            this.taskDropdownSelectOptions = {};
            if (!this.disciplineTasks || this.disciplineTasks?.length === 0) {
                return;
            }
            this.disciplineTasks.forEach((taskObject) => {
                if (taskObject.label && Array.isArray(taskObject.children)) {
                    const labelKey = taskObject.label;
                    this.taskDropdownSelectOptions[labelKey] = taskObject.children.map((child) => {
                        return {
                            displayText: child.label,
                            value: child.id,
                            id: child.disciplineTaskId
                        };
                    });
                }
            });
        } catch (e) {
            console.warn(e);
            // Silent fail
        }
    }

    private async getClient() {
        try {
            if (!this.visitDetails) return;
            this.isPaymentSourceLoading = true;
            const clientListPayload: IGetClientRequest = {
                employeeId: this.visitDetails.Id,
                agencyId: this.visitDetails.AgencyId,
                application: ApplicationEnum.HomeCare
            };
            this.clients = await this._userService.getClients(clientListPayload);
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Client List Failed`,
                message: `Fetching CLient List Operation Failed, Please Contact Axxess if issue still persists.`
            });
        } finally {
            this.isPaymentSourceLoading = false;
        }
    }

    public clientChanged(newValue: any) {
        if (newValue) {
            this.newVisitDetails.patientId = newValue;
            this.getPaymentSource();
        }
    }
    private async getPaymentSource() {
        try {
            if (!this.visitDetails) return;
            this.isPaymentSourceLoading = true;
            const payorListPayload: IGetTaskPayorList = {
                patientId: this.newVisitDetails.patientId,
                agencyId: this.visitDetails.AgencyId,
                startDate: this.newVisitDetails.startDateTime.date,
                endDate: this.newVisitDetails.endDateTime.date,
                application: ApplicationEnum.HomeCare
            };
            const payors = await this._taskService.getTaskPayorsList(payorListPayload);
            this.payorList = payors.data;
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Payor List Failed`,
                message: `Fetching Payor List Operation Failed, Please Contact Axxess if issue still persists.`
            });
        } finally {
            this.isPaymentSourceLoading = false;
        }
    }

    public visitDatesChanged() {
        this.newVisitDetails.recurrence.flexibleDates = this.visitDates.split(", ");
    }

    public startTimeChanged() {
        if (this.shiftLength && Number(this.shiftLength) > 0) {
            this.shiftLengthChanged();
        }
        if (
            this.newVisitDetails.startDateTime.time.indexOf("PM") != -1 &&
            this.newVisitDetails.endDateTime.time.indexOf("AM") != -1
        ) {
            this.isSingleDate = false;
        } else {
            this.isSingleDate = true;
        }
    }

    public startDateChanged() {
        if (this.isSingleDate) {
            this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
        } else {
            this.newVisitDetails.endDateTime.date = moment(this.newVisitDetails.startDateTime.date, "MM/DD/YYYY")
                .add(1, "day")
                .format("MM/DD/YYYY");
        }
    }

    public endDateChanged() {
        if (this.newVisitDetails.startDateTime.date && this.newVisitDetails.endDateTime.date) {
            this.getPaymentSource();
        }
    }

    public endTimeChanged() {
        if (
            this.newVisitDetails.startDateTime.time.indexOf("PM") != -1 &&
            this.newVisitDetails.endDateTime.time.indexOf("AM") != -1
        ) {
            this.isSingleDate = false;
        } else {
            this.isSingleDate = true;
        }
        if (this.newVisitDetails.startDateTime.time && this.newVisitDetails.endDateTime.time) {
            if (this.isSingleDate) {
                this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
            } else {
                this.newVisitDetails.endDateTime.date = moment(this.newVisitDetails.startDateTime.date, "MM/DD/YYYY")
                    .add(1, "day")
                    .format("MM/DD/YYYY");
            }
        }
    }

    public shiftLengthChanged() {
        this.newVisitDetails.lengthOfShift = this.shiftLength;
        if (this.newVisitDetails.startDateTime.time)
            this.newVisitDetails.endDateTime.time = moment(this.newVisitDetails.startDateTime.time, "hh:mm A")
                .add(Number(this.shiftLength), "hours")
                .format("hh:mm A");
    }

    public overrideShiftLengthChanged() {
        if (!this.overrideShiftLength) this.shiftLengthChanged();
    }

    public scheduleWithoutTimeChanged() {
        this.newVisitDetails.isAllDay = this.scheduleWithoutTime;
        if (!this.scheduleWithoutTime) {
            if (this.newVisitDetails.startDateTime.date && this.newVisitDetails.endDateTime.date) {
                this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
            }
        }
    }

    public async scheduleVisit(saveType: string) {
        this.newVisitDetails.initValidation();
        const res = await this.controller.validate();
        if (!res.valid) {
            return;
        }
        if (!!this.newVisitDetails) {
            try {
                this.isSaving = true;
                if (this.scheduleWithoutTime) {
                    this.newVisitDetails.eventStartTime = moment(
                        this.newVisitDetails.startDateTime.date + " 00:00 AM",
                        "MM/DD/YYYY hh:mm A"
                    ).format("YYYY-MM-DDTHH:mm:ss");
                    this.newVisitDetails.eventEndTime = moment(
                        this.newVisitDetails.endDateTime.date + " 00:00 AM",
                        "MM/DD/YYYY hh:mm A"
                    ).format("YYYY-MM-DDTHH:mm:ss");
                } else {
                    this.newVisitDetails.eventStartTime = moment(
                        this.newVisitDetails.startDateTime.date + " " + this.newVisitDetails.startDateTime.time,
                        "MM/DD/YYYY hh:mm A"
                    ).format("YYYY-MM-DDTHH:mm:ss");
                    this.newVisitDetails.eventEndTime = moment(
                        this.newVisitDetails.endDateTime.date + " " + this.newVisitDetails.endDateTime.time,
                        "MM/DD/YYYY hh:mm A"
                    ).format("YYYY-MM-DDTHH:mm:ss");
                }
                this.newVisitDetails.userId = this.visitDetails.UserId;
                const firstResponse = await this._taskService.scheduleVisitHC(this.newVisitDetails);
                if (!firstResponse.IsSuccessful && firstResponse.ErrorDetails) {
                    // if no error list, then simply show the error and end
                    if (!firstResponse.ErrorDetails.TaskIssues.length) {
                        throw new Error(firstResponse.ErrorMessage);
                    }

                    const validationList: IRescheduleValidations[] = firstResponse.ErrorDetails.TaskIssues.map(
                        (item: any) => {
                            return {
                                title: item.Title as string,
                                description: [item.Message] as string[],
                                taskIssueSurpressKey: item.TaskIssueSurpressKey as string
                            };
                        }
                    );
                    const hasError = firstResponse.ErrorDetails.AllTasksHaveErrors;

                    const dialogOptions: DialogSettings = {
                        viewModel: RescheduleValidations,
                        model: {
                            validations: validationList,
                            name: "Employee",
                            component: "Schedule-visit",
                            hasError
                        }
                    };

                    let end = false;
                    await this._dialogService.open(dialogOptions).whenClosed((response) => {
                        if (response.wasCancelled) {
                            end = true;
                        }
                    });
                    if (end) {
                        return;
                    }

                    const issuesResponseObj: IHandleTaskReschuleIssue = {};
                    for (let issue of validationList) {
                        issuesResponseObj[issue.taskIssueSurpressKey] = "ScheduleAllAnyways";
                    }

                    this.newVisitDetails.HandleTaskIssues = issuesResponseObj;
                    const secondResponse = await this._taskService.scheduleVisitHC(this.newVisitDetails);
                    if (!secondResponse.IsSuccessful) {
                        throw new Error(secondResponse.ErrorMessage);
                    }
                    this._toastrService.success({
                        title: `Visit Scheduled`,
                        message: `Visit has been successfully Scheduled.`
                    });
                } else if (!firstResponse.IsSuccessful) {
                    throw new Error(firstResponse.ErrorMessage);
                } else {
                    this._toastrService.success({
                        title: `Visit Scheduled`,
                        message: `Visit has been successfully Scheduled.`
                    });
                }

                if (saveType == SaveEnum.SaveAndExit) {
                    // trigger callback
                    this.ok();
                } else {
                    this.newVisitDetails = new ScheduleVisitHomeCareModel();
                    this.initSubscriptions();
                    this.visitDates = "";
                }
            } catch (e) {
                console.warn(e);
                this._toastrService.error({
                    title: `Visit Scheduled Failed`,
                    message: e ? e : `Scheduling Visit Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isSaving = false;
            }
        }
    }

    public detached() {
        this._subscriptions?.forEach((sub) => sub.dispose());
    }
}
