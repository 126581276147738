import { PropType, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";

import config from "../../../../../common/config";
import { IEnumResponse } from "../../../../../resources-vue/vue-interfaces/i-enum";
import { IMultiSelectDropDownOption } from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect";
import { EnumsService } from "../../../../../services/enums.service";
import { AgencyService, IGetAgency, IGetOrganization } from "../../../../../services/agency.service";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum
} from "../../../../../enums/enums";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import eventBus from "../../../../../utilities/eventBus";

import MultiSelect from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect.vue";
import MultiSelectFilter from "../../../../../resources-vue/vue-elements/MultiSelectFilter/MultiSelectFilter.vue";
import DailyDateNavigation from "../../../components/DailyDateNavigation/DailyDateNavigation.vue";
import { IVisitMapDetails } from "../../../../../resources-vue/vue-interfaces/i-visit-map";

export default defineComponent({
    components: {
        MultiSelect,
        MultiSelectFilter,
        DailyDateNavigation
    },

    props: {
        patientsList: { type: Object, default: {} },
        locationsList: { type: Object, default: {} },
        fetchUpdatedCount: { type: Number, default: 0 }
    },

    emits: ["mapRefresh"],

    data() {
        return {
            _enumsService: null,
            _agencyService: null,
            _permissionManager: null,
            patientStatusEnums: [] as IEnumResponse[],
            agencyMultiSelectOptions: {} as IMultiSelectDropDownOption,
            selectedAgencies: [] as string[],
            selectedPatientStatus: null,
            agencyList: [] as IGetAgency[],
            organizations: [] as IGetOrganization[],
            organizationName: "" as string,
            selectedLocations: [],
            selectedPatients: [],
            startDate: new Date(Date.now()) as Date,
            endDate: null as Date,
            enumToMap
        };
    },

    created() {
        this._enumsService = Container.instance.get(EnumsService);
        this._agencyService = Container.instance.get(AgencyService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this.initAgencyMultiSelect();
    },

    async mounted() {
        let patientStatusEnums = await this._enumsService.getPatientStatus();
        this.patientStatusEnums = patientStatusEnums.getJson();
        this.organizations = await this._agencyService.getOrganizations();
        this.organizationName = window.localStorage.getItem(config.organizationName);
        this.agencyList = this.organizations.find(
            (organization: { name: string }) => organization.name === this.organizationName
        ).agencies;
        this.agencyList = this.agencyList.map((agency) => {
            let agencyAndApplicationIds = agency.id.split("_");
            let application = parseInt(agencyAndApplicationIds[1], 10);
            if (application == 256) {
                application = ApplicationEnum.AxxessHospice;
            }
            return {
                ...agency,
                application: application
            };
        });
        await this.initAgencyMultiSelect();
    },

    watch: {
        selectedPatientStatus: async function (newValue, oldValue) {
            if (newValue !== oldValue) {
                await this.emitPatientMapRefresh();
            }
        }
    },

    methods: {
        updateDate(options: any) {
            this.startDate = options.startDate;
            this.endDate = options.endDate;
            this.emitPatientMapRefresh();
        },

        async initAgencyMultiSelect() {
            try {
                this.agencyMultiSelectOptions = {};
                this.selectedAgencies.length = 0;
                if (!this.agencyList || this.agencyList?.length === 0) {
                    return;
                }
                let allowedProducts = [
                    ApplicationEnum.AgencyCore,
                    ApplicationEnum.HomeCare,
                    ApplicationEnum.AxxessHospice,
                    ApplicationEnum.AxxessPalliative
                ];
                const schedulerViewPermissions = this._permissionManager.getPermmission(
                    ParentPermissionEnum.scheduler,
                    PermissionActionEnum.canView
                );
                let schedulerAgencies = schedulerViewPermissions
                    .filter((item: any) => {
                        if (!item) {
                            return false;
                        }
                        let application = item.application;
                        if (application === ApplicationEnum.AxxessHospiceFE) {
                            application = ApplicationEnum.AxxessHospice;
                        }
                        return allowedProducts.includes(application);
                    })
                    .map((x: any) => x.agencyId);
                let filterableProducts = this.agencyList.filter((agency) => {
                    let isAllowed = allowedProducts.includes(agency.application);
                    let agencyId = agency.id?.split("_")[0];
                    return isAllowed && schedulerAgencies.includes(agencyId);
                });
                filterableProducts?.forEach((agency) => {
                    let appName = ApplicationNameEnum[agency.application];

                    if (!this.agencyMultiSelectOptions[appName]) {
                        this.agencyMultiSelectOptions[appName] = [];
                    }
                    this.agencyMultiSelectOptions[appName].push({
                        displayText: agency.name,
                        value: agency.id
                    });
                });

                Object.values(this.agencyMultiSelectOptions).forEach((agency: any) => {
                    let values = agency.map((item: any) => item.value);
                    this.selectedAgencies.push(...values);
                });
                // For select agencies change
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },

        updateBranches(branches: any) {
            this.selectedAgencies.length = 0;
            this.selectedAgencies.push(...branches);
            this.emitPatientMapRefresh();
        },

        selectPatientFetch(filter: string) {
            let patients = [];
            for (const [key, value] of Object.entries(this.patientsList)) {
                patients.push({ name: key, value: value });
            }
            return patients.filter(
                (emp) => !filter || emp.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            );
        },

        selectedPatientsUpdated(options: any) {
            this.selectedPatients = options;
            const patients = this.selectedPatients.map((patient) => patient.name);
            eventBus.emit("highlightPatientNameMarker", patients);
        },

        selectLocationFetch(filter: string) {
            let locations = [];
            for (const [key, value] of Object.entries(this.locationsList)) {
                locations.push({ name: key, value: value });
            }
            return locations.filter(
                (emp) => !filter || emp.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            );
        },

        selectedLocationsUpdated(options: any) {
            this.selectedLocations = options;
            const locations = this.selectedLocations.map((loc) => loc.name);
            eventBus.emit("highlightPatientLocationMarker", locations);
        },

        async emitPatientMapRefresh() {
            this.selectedLocations = [];
            this.selectedPatients = [];
            this.$emit("mapRefresh", {
                agencies: this.selectedAgencies,
                patientStatus: this.selectedPatientStatus,
                date: moment(this.startDate).format("MM/DD/YYYY")
            });
        }
    }
});
