import CentralInquiry from "./Inquiry/CentralInquiry.vue";
import CentralReferral from "./Referral/CentralReferral.vue";

const routes = [
    {
        path: "/axxess-central/intake/inquiry-list",
        component: CentralInquiry,
        name: "inquiry-list",
        meta: {
            menuName: "Inquiry",
            navItem: "tabInquiry"
        }
    },
    {
        path: "/axxess-central/intake/referral-list",
        component: CentralReferral,
        name: "referral-list",
        meta: {
            menuName: "Referral",
            navItem: "tabReferral"
        }
    }
];
export default routes;
