export class DateTimeModel {
    public date: string = "";
    public time: string = "";

    public constructor(data?: DateTimeModel) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
