
import { defineComponent } from "vue";

import PageLayout from "../PageLayout/PageLayout.vue";

export default defineComponent({
    components: {
        PageLayout
    },

    props: {
        pageTitle: { type: String, default: "" },
        formTitle: { type: String, default: "" },
        showForm: { type: Boolean, default: false },
        hasTitle: { type: Boolean, default: false },
        hasHeader: { type: Boolean, default: true },
        hasListScroll: { type: Boolean, default: true }
    }
});
