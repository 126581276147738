import { Router } from "aurelia-router";
import "./employee-calendar-content.scss";

import { type CalendarOptions, EventContentArg } from "@fullcalendar/core";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";
import { DialogService, DialogSettings } from "aurelia-dialog";
import moment from "moment";

import { adjustDate } from "../../common/adjust-date";
import config from "../../common/config";
import { formatName } from "../../common/format-name";
import nameOf from "../../common/name-of";
import { PermissionManager } from "../../common/utilities/permission-manager";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    EmployeeStatusEnum,
    ParentPermissionEnum,
    PermissionActionEnum,
    TestingAgenciesEnum,
    VisitStatusEnum
} from "../../enums/enums";
import { GetProductClass } from "../../resources/value-converters/get-product-class";
import { NavigationService } from "../../services/navigation.service";
import { ProductService } from "../../services/product.service";
import type { ITaskByUserResponse } from "../../services/task.service";
import { PageTakeOverLoading } from "../app/loading-indicator";
import { CalendarEmployeeModel } from "./calendar-employee-model";
import { BulkReassign } from "../../models/bulk-reassign";
import { ScheduleVisitEmployeeDialog } from "../../resources/dialogs/schedule-visit/schedule-visit-employee";
import { FormatPhoneNumber } from "../../resources/value-converters/format-phone";
import { ScheduleLegendState } from "./central-calendar";
import { ToastrService } from "../../services/toastr.service";
import { type ITaskByPatientResponse, TaskService } from "../../services/task.service";
import {
    IVisitDetails,
    IRescheduleVisitHosPal,
    IReassignVisitHC,
    IHandleTaskReschuleIssue
} from "../../resources-vue/vue-interfaces/i-visit-details";
import {
    IRescheduleValidations,
    RescheduleValidations
} from "../../resources/dialogs/reschedule-validations/reschedule-validations";
import { RescheduleTask } from "../../models/task.model";
import { IDisciplineTaskHH, IGetDisciplineTaskHHParams } from "../../resources-vue/vue-interfaces/i-discipline-task-hh";
import { LookupService } from "../../services/lookup-service";

@autoinject
export class EmployeeCalendarContent {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public startDate: Date;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public endDate: Date;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public usersList: CalendarEmployeeModel[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public tasksList: any[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public calendarRefresh: () => void;
    public tasksMap: Map<string, ITaskByUserResponse[]> = new Map();
    private _navigationService: NavigationService;
    private _productService: ProductService;
    private _ea: EventAggregator;
    private _permissionManager: PermissionManager;
    private _dialogService: DialogService;
    private _taskService: TaskService;
    private _toastrService: ToastrService;
    public disciplineTasks: IDisciplineTaskHH[] = [];
    // NOTE: eventLimit be one more than the required events, as to account for dummy event
    public eventLimit: number = 4;
    public userNamesMap: Map<string, string> = new Map();
    public isTestAgency: boolean = false;
    public employeeStatuses: typeof EmployeeStatusEnum = Object.create(EmployeeStatusEnum);
    public router: Router;
    public _lookupService: LookupService;

    @computedFrom(
        `${nameOf<EmployeeCalendarContent>("usersList")}.length`,
        `${nameOf<EmployeeCalendarContent>("tasksList")}.length`
    )
    public get calendarOptions(): CalendarOptions {
        return {
            resources: this.userDetails,
            resourceOrder: "LastName, FirstName",
            events: this.taskActions,
            resourceGroupField: "groupId",
            resourceAreaHeaderContent: "Employees",
            resourceAreaWidth: 220,
            droppable: true,
            eventDragStart: (event: any) => {
                $('[role="tooltip"]')?.remove();
            },
            eventDrop: async (event: any) => {
                $('[role="tooltip"]')?.remove();
                const rowChanged = !(
                    JSON.stringify(event.oldEvent._def.resourceIds) == JSON.stringify(event.event._def.resourceIds)
                );
                if (rowChanged) {
                    event.revert();
                    return;
                }
                if (!event?.event?.extendedProps || !event?.event?.startStr) {
                    this._toastrService.error({
                        title: `Error - Rescheduling task`,
                        message: `There was an error rescheduling the visit. Please Try again later.`
                    });
                    this.calendarRefresh();
                    return;
                }
                const visitDetails = event.event.extendedProps;
                const lastDay = moment(visitDetails.StartDate).endOf("week").format("YYYYMMDD");

                if (
                    visitDetails.StartDate != visitDetails.EndDate &&
                    moment(event.event.start).format("YYYYMMDD") == lastDay
                ) {
                    this._toastrService.error({
                        title: `Error - Rescheduling task`,
                        message: `Midnight split visit will not get reschedule to the last day of the week`
                    });
                    this.calendarRefresh();
                    return;
                }

                if (
                    [
                        ApplicationEnum.AxxessHospiceFE,
                        ApplicationEnum.AxxessHospice,
                        ApplicationEnum.AxxessPalliative
                    ].includes(visitDetails.Application)
                ) {
                    let startTime = visitDetails.StartTime
                        ? moment(visitDetails.StartTime).format("HH:mm:ss")
                        : "00:00:00";
                    let endTime = visitDetails.EndTime ? moment(visitDetails.EndTime).format("HH:mm:ss") : "00:00:00";
                    this.rescheduleVisitHosPal(
                        visitDetails,
                        `${moment(event.event.start).format("MM/DD/YYYY")} ${startTime}`,
                        `${moment(event.event.end).format("MM/DD/YYYY")} ${endTime}`
                    );
                } else if (visitDetails.Application == ApplicationEnum.HomeCare) {
                    let timeIn = visitDetails.StartTime || visitDetails.timeIn;
                    timeIn = timeIn ? moment(timeIn).format("HH:mm:ss") : "00:00:00";
                    let timeOut = visitDetails.EndTime || visitDetails.timeOut;
                    timeOut = timeOut ? moment(timeOut).format("HH:mm:ss") : "00:00:00";
                    const newStart = moment(event.event.start).format("YYYY-MM-DD");
                    const newEnd = moment(event.event.end).format("YYYY-MM-DD");
                    this.rescheduleVisitHomeCare(event, visitDetails, `${newStart}T${timeIn}`, `${newEnd}T${timeOut}`);
                } else {
                    let timeIn = visitDetails.StartTime || visitDetails.timeIn;
                    timeIn = timeIn ? moment(timeIn).format("HH:mm:ss") : "00:00:00";
                    let timeOut = visitDetails.EndTime || visitDetails.timeOut;
                    timeOut = timeOut ? moment(timeOut).format("HH:mm:ss") : "00:00:00";
                    const newStart = moment(event.event.start).format("YYYY-MM-DD");
                    const newEnd = moment(event.event.end).format("YYYY-MM-DD");
                    const status = moment().format("YYYY-MM-DD") < newStart ? "410" : "";
                    const customId = visitDetails.CustomId;
                    const taskName = visitDetails.TaskName;

                    this.rescheduleVisit(
                        visitDetails,
                        moment(event.event.start).format("MM/DD/YYYY"),
                        `${newStart}T${timeIn}`,
                        `${newEnd}T${timeOut}`,
                        status,
                        customId,
                        taskName
                    );
                }
            },

            eventContent: (arg: EventContentArg) => {
                const eventData = arg?.event?.extendedProps;
                let extraEvents = 0;
                if (![VisitStatusEnum.NotStarted, VisitStatusEnum.NotYetDue].includes(eventData.Status)) {
                    arg.event._def.ui.constraints = [false];
                }
                if (eventData?.isExtraEvent) {
                    let startDate = new Date(Date.parse(eventData?.StartDate));
                    let key = `${eventData.UserId}${startDate.toLocaleDateString()}`;
                    let currentTaskList = this.tasksMap.get(key);
                    extraEvents = currentTaskList.length - this.eventLimit;
                }
                return {
                    html: this.generateEvent(arg, extraEvents)
                };
            },
            eventDidMount: (arg: any) => {
                $('[role="tooltip"]')?.remove();
                if (!!$(arg?.el).find(".more-btn").length) {
                    this.attachMorePopover(arg);
                    return;
                }
                if (!!$(arg?.el).find(".non-visit-bg").length) {
                    this.attachNonVisitTooltip(arg);
                    return;
                }
                this.attachTooltip(arg);
            },
            // NOTE: Uncomment when task navigation is needed
            // eventClick: (event: any) => {
            //     if (!$(event.el).find(".more-btn").length) {
            //         this.handleEventClick(event);
            //     }
            // },
            resourceLabelContent: (arg: any) => {
                $('[role="tooltip"]')?.remove();
                return {
                    html: this.generateResource(arg)
                };
            },
            resourceLabelDidMount: (arg: any) => {
                this.registerClick(arg);
            },
            resourceGroupLabelContent: (arg: any) => {
                return {
                    html: this.generateGroupLabel(arg)
                };
            },
            visibleRange: {
                start: this.startDate,
                end: adjustDate(this.endDate, 1)
            }
        };
    }

    @computedFrom(`${nameOf<EmployeeCalendarContent>("usersList")}.length`)
    public get userDetails() {
        let userEntries: any[] = [];
        this.userNamesMap = new Map();
        this.usersList?.forEach((user) => {
            user.Agencies.forEach((op) => {
                let userData = {
                    ...user,
                    ...op,
                    groupId: user.Id,
                    id: `${op.UserId}${op.Application}`
                };
                userEntries.push(userData);
            });
            this.userNamesMap.set(user.Id, formatName(user.FirstName, user.LastName));
        });
        return userEntries ?? [];
    }

    @computedFrom(`${nameOf<EmployeeCalendarContent>("tasksList")}.length`)
    public get taskActions() {
        this.tasksMap = new Map();
        this.tasksList.forEach((task) => {
            let startDate = new Date(Date.parse(task.StartDate));
            if (task.Application === 256) {
                task.Application = ApplicationEnum.AxxessHospice;
            }
            let key = `${task.UserId}${startDate.toLocaleDateString()}`;
            if (!this.tasksMap.has(key)) {
                this.tasksMap.set(key, [task]);
            } else {
                let currentTasks = this.tasksMap.get(key);
                this.tasksMap.set(key, [...currentTasks, task]);
            }
        });
        let tasksList: ITaskByUserResponse[] = [];
        this.tasksMap.forEach((value, key) => {
            tasksList.push(...value);
            // let list = value.slice(0, this.eventLimit);
            // tasksList = tasksList.concat([...list]);
            // if (value.length > this.eventLimit) {
            //     let extraTask = {
            //         Id: value[0].Id,
            //         UserId: value[0].UserId,
            //         StartDate: moment(value[0].StartDate).endOf("day").format(),
            //         EndDate: value[0].StartDate,
            //         Application: value[0].Application,
            //         isExtraEvent: true
            //     } as ITaskByUserResponse;
            //     tasksList.push(extraTask);
            // }
        });
        return (
            tasksList?.map((task) => ({
                ...task,
                id: task.Id,
                start: task.StartDate,
                end: moment(task.EndDate).endOf("day").format(),
                resourceId: `${task.UserId}${task.Application}`
            })) ?? []
        );
    }

    public constructor(
        navigationService: NavigationService,
        productService: ProductService,
        ea: EventAggregator,
        permissionManager: PermissionManager,
        dialogService: DialogService,
        router: Router,
        taskService: TaskService,
        toastrService: ToastrService,
        lookupService: LookupService
    ) {
        this._navigationService = navigationService;
        this._productService = productService;
        this._ea = ea;
        this._permissionManager = permissionManager;
        this._dialogService = dialogService;
        this.router = router;
        this._taskService = taskService;
        this._toastrService = toastrService;
        this._lookupService = lookupService;
    }

    public generateGroupLabel(arg: any) {
        let groupId = arg?.groupValue;
        let employeeName = this.userNamesMap?.get(groupId);
        let labelContent = "";
        labelContent = `<span class="text-dark font-weight-bold"> ${employeeName} </span>`;
        return labelContent;
    }

    public getStatusText(status: number): string {
        let result = this.employeeStatuses[status].toLowerCase();

        if (result.includes("pending")) {
            return "Pending";
        } else if (result.includes("terminate")) {
            return "Terminated";
        }
        return result;
    }

    private async rescheduleVisit(
        event: IVisitDetails,
        date: string,
        StartDate: string,
        EndDate: string,
        status: string,
        customId: number,
        taskName: string
    ) {
        const visitDetails = event;
        const rescheduleVisitDetails = new RescheduleTask();
        rescheduleVisitDetails.fillRequiredDetails(visitDetails);
        rescheduleVisitDetails.EventDate = rescheduleVisitDetails.VisitDate = date || null;
        rescheduleVisitDetails.Status = status;
        rescheduleVisitDetails.TimeIn = StartDate;
        rescheduleVisitDetails.TimeOut = EndDate;
        await this.getDisciplineTasks(visitDetails);
        const disciplineData = this.disciplineTasks.filter(
            (item: any) => item.customId === customId && item.taskName === taskName
        )[0];
        rescheduleVisitDetails.Discipline = disciplineData.discipline;
        rescheduleVisitDetails.DisciplineTask = disciplineData.disciplineTask;
        rescheduleVisitDetails.CustomId = Number(disciplineData.customId);

        if (rescheduleVisitDetails.EventDate) {
            try {
                await this._taskService.rescheduleTask(rescheduleVisitDetails);
                this._toastrService.success({
                    title: `Success`,
                    message: `The visit has been successfully rescheduled.`
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: `There was an error rescheduling the visit. Please Try again later.`
                });
            } finally {
                this.calendarRefresh();
            }
        } else {
            this._toastrService.error({
                title: `Error - Rescheduling task`,
                message: `There was an error rescheduling the visit. Please Try again later.`
            });

            this.calendarRefresh();
        }
    }

    public generateResource(arg: any) {
        let resource = arg?.resource?.extendedProps;
        let userInfo = "";
        let userName = `<span class="font-weight-bold"> ${formatName(resource.FirstName, resource.LastName)} </span>`;
        const phoneNumber = new FormatPhoneNumber().toView(resource.PhoneNumber?.toString());
        this.isTestAgency = Object.values(TestingAgenciesEnum).includes(resource.AgencyId);
        const isHomeCareAddAllowed = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canAdd,
            ApplicationEnum.HomeCare
        );
        const isHosAddAllowed = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AxxessHospiceFE
        );
        const isPalAddAllowed = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AxxessPalliative
        );
        const isHomeHealthAddAllowed = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canAdd,
            ApplicationEnum.AgencyCore
        );

        if (!!resource) {
            let productClass = new GetProductClass();
            let application = resource.Application;
            if (application === 256) {
                application = ApplicationEnum.AxxessHospice;
            }
            let product = this._productService.getProductMarkupClassName(application);
            if (application !== ApplicationEnum.AgencyCore) {
                userName = `<a class="w-100 font-size-base text-truncate cursor-pointer user-link" id="${
                    resource.UserId
                }">
                        ${formatName(resource.FirstName, resource.LastName)}
                    </a>`;
            }
            userInfo = `<div class="mx-2 d-flex flex-column align-items-start">
                <span class="font-weight-normal font-size-xs badge badge-pill pill mr-1 ${productClass.toView(
                    application
                )}">
                    ${ApplicationNameEnum[application]}
                </span>
                <div class="position-absolute bulk-reassign-wrapper">
                    ${
                        this.isTestAgency
                            ? `<button
                                type="button"
                                class="btn font-size-xs px-1 py-0 btn-outline-primary"
                                data-toggle="dropdown"
                                id="reassign-ellipsis-${resource.UserId}-${resource.AgencyId}"
                            >
                                <i class="fas fa-ellipsis-h"></i>
                            </button>
                        <div class="dropdown-menu py-1" aria-labelledby="reassign-ellipsis-${resource.UserId}-${
                                  resource.AgencyId
                              }">
                            <a
                                class="dropdown-item font-size-sm bulk-reassign"
                                href="javascript:void(0);"
                                id="task-manager-${resource.UserId}-${resource.AgencyId}"
                            >
                                Task Manager
                            </a>

                            ${
                                (application == ApplicationEnum.AxxessHospiceFE && isHosAddAllowed) ||
                                (application == ApplicationEnum.AxxessHospice && isHosAddAllowed) ||
                                (application == ApplicationEnum.AxxessPalliative && isPalAddAllowed) ||
                                (application == ApplicationEnum.HomeCare && isHomeCareAddAllowed) ||
                                (application == ApplicationEnum.AgencyCore && isHomeHealthAddAllowed)
                                    ? `<a
                                    class="dropdown-item font-size-sm bulk-reassign"
                                    href="javascript:void(0);"
                                    id="add-task-${resource.UserId}-${resource.AgencyId}"
                                >
                                    Add Task
                                </a>`
                                    : ""
                            }

                        </div>`
                            : ``
                    }
                </div>
                <p class="mb-0 w-auto user-name">
                    ${userName}
                    <span class="font-size-xs">${resource.Credentials}</span>
                </p>
                ${phoneNumber ? `<a href="tel:${phoneNumber}" class="mb-0 font-size-xs w-auto">${phoneNumber}</a>` : ""}
                <p class="w-100 font-size-2xs text-truncate mb-0">${resource.AgencyName}</p>
                <p
                            class="font-weight-bold status-label mb-0 font-size-2xs ${this.getStatusText(
                                resource.Status
                            ).toLowerCase()}"
                        >
                            Status : <span class="text-capitalize"> ${this.getStatusText(resource.Status)} </span>
                        </p>
            </div>`;
        }
        return userInfo;
    }

    public generateEvent(arg: EventContentArg, extraEvents: number) {
        let event = arg?.event?.extendedProps;
        let eventContent = "";
        if (!event) {
            return eventContent;
        }
        let isNonPatientVisit = !event.PatientId;
        let patientName = "";
        if (!isNonPatientVisit) {
            patientName = `<span class="d-flex align-items-center font-size-2xs">
                <i class="fas fa-user mr-1"></i>
                <span>
                    ${this.getUserName(event.PatientFirstName, event.PatientLastName)}
                </span>
            </span>`;
        }
        eventContent = `<div class="ax-calendar-event ${ScheduleLegendState[event.State]}">
            <span class="w-100 font-weight-bold text-truncate">
                ${event.TaskName}
            </span>
            <span class="w-100 font-size-3xs text-truncate">
                ${this.getTimeInOut(this.getScheduledTime(event.StartTime), this.getScheduledTime(event.EndTime))}
            </span>
            ${patientName}
            <span class="d-flex align-items-center font-size-2xs">
                <i class="fas fa-building mr-1"></i>
                <span>
                    ${event.BranchName || "Not Available"}
                </span>
            </span>
            <span class="w-100 font-size-3xs text-truncate">
                ${event.Status}
            </span>
        </div>`;
        if (!!event.isExtraEvent) {
            eventContent = `<a class="cursor-pointer text-dark more-btn" tabindex="0">+ ${extraEvents} more</a>`;
        }
        return eventContent;
    }

    public registerClick(arg: any) {
        let element = arg?.el?.className.split(" ").join(".");
        let extendedProps = arg?.resource?.extendedProps;
        $(`.${element}`).on("click", `#${extendedProps?.UserId}-${extendedProps.AgencyId}`, (event: any) => {
            this.handleUserNameClick(extendedProps);
        });
        $(`.${element}`).on(
            "click",
            `#task-manager-${extendedProps?.UserId}-${extendedProps.AgencyId}`,
            (event: any) => {
                this.openTaskManager(extendedProps);
            }
        );
        $(`.${element}`).on("click", `#add-task-${extendedProps?.UserId}-${extendedProps.AgencyId}`, (event: any) => {
            this.scheduleNewTasks(extendedProps);
        });
    }

    public async scheduleNewTasks(employee: BulkReassign) {
        const employeeDetails = this.usersList.find((user: CalendarEmployeeModel) => employee.Id == user.Id);
        const employeeInfo = (({ FirstName, LastName }) => ({ FirstName, LastName }))(employeeDetails);
        const visitDetails: BulkReassign = Object.assign({}, employeeInfo, employee);
        if (!!visitDetails) {
            const dialogOptions: DialogSettings = {
                viewModel: ScheduleVisitEmployeeDialog,
                model: {
                    visitDetails
                }
            };
            await this._dialogService.open(dialogOptions).whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.calendarRefresh();
                }
            });
        }
    }

    public async openTaskManager(employee: BulkReassign) {
        // This has to be done because backend is sending -3 for hospice solution.
        let application;
        if (employee.Application === ApplicationEnum.AxxessHospice) {
            application = ApplicationEnum.AxxessHospiceFE;
        } else {
            application = employee.Application;
        }
        this.router.navigate(
            `/task-manager?agencyId=${employee.AgencyId}&name=${employee.FirstName} ${employee.LastName} ${employee.Credentials}&application=${application}&userId=${employee.UserId}`
        );
    }

    private async getDisciplineTasks(visitDetails: IVisitDetails) {
        try {
            const payload: IGetDisciplineTaskHHParams = {
                agencyId: visitDetails.AgencyId,
                application: visitDetails.Application
            };
            this.disciplineTasks = await this._lookupService.getDisciplineTasks(payload);
        } catch (e) {
            console.log(e);
            this._toastrService.error({
                title: `Fetching Discipline Task Failed`,
                message: `Fetching Discipline Task Operation Failed, Please Contact Axxess if issue still persists.`
            });
        }
    }

    private async handleUserNameClick(resource: any) {
        let redirectResponse;
        let analyticsCategory = "Central-Calendar-Navigation";
        let action = "";
        if (resource.Application === ApplicationEnum.AxxessHospice) {
            redirectResponse = {
                redirectUrl: `${config.hospiceUrl}/employees/employee-center/${resource.UserId}?accountId=${resource.AgencyId}`,
                isSuccessful: true
            };
            action = "AxxessHospice";
        } else if (resource.Application === ApplicationEnum.AxxessPalliative) {
            redirectResponse = {
                redirectUrl: `${config.palliativeUrl}/employees/employee-center/${resource.UserId}?accountId=${resource.AgencyId}`,
                isSuccessful: true
            };
            action = "AxxessPalliative";
        } else {
            const isClinician = this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView
            );
            this._ea.publish(PageTakeOverLoading.Show);
            redirectResponse = await this._navigationService.navigateToUser({
                agencyId: resource.AgencyId,
                applicationId: resource.Application,
                isClinician: isClinician,
                userId: resource.UserId
            });
            action = resource.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
            this._ea.publish(PageTakeOverLoading.Hide);
        }
        this._navigationService.redirect(redirectResponse, {
            category: analyticsCategory,
            action
        });
    }

    // NOTE: Uncomment when task navigation is needed
    // private async handleEventClick(eventObjectInput: any | ITaskByPatientResponse) {
    //     let eventData = eventObjectInput?.event?.extendedProps;
    // await this.navigateToTask(eventData);
    // }

    // private async navigateToTask(eventData: ITaskByPatientResponse) {
    //     let redirectResponse;
    //     let analyticsCategory = "Calendar-Task-Navigation";
    //     let action = "";

    //     if (eventData.Application === 256) {
    //         redirectResponse = {
    //             redirectUrl: `${config.hospiceUrl}/note/${eventData.Id}?accountId=${eventData.AgencyId}`,
    //             isSuccessful: true
    //         };
    //         action = "AxxessHospice";
    //     } else {
    //         let accessRoles = await this._accessService.getAccessRoles();
    //         redirectResponse = await this._navigationService.navigateToTask({
    //             agencyId: eventData.AgencyId,
    //             applicationId: eventData.Application,
    //             userId: eventData.UserId,
    //             isClinician: accessRoles.isClinician,
    //             patientId: eventData.PatientId,
    //             taskId: eventData.Id,
    //             episodeId: eventData.EpisodeId
    //         });
    //         action = eventData.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
    //     }

    //     this._navigationService.redirect(redirectResponse, {
    //         category: analyticsCategory,
    //         action
    //     });
    // }

    public attachTooltip(arg: any) {
        let element = $(arg?.el);
        let content = this.getTooltipContent(arg?.event?.extendedProps);
        (element as any).popover({
            html: true,
            content: content,
            trigger: "hover",
            placement: "auto",
            container: "body"
        });
    }

    public getTooltipContent(data: any) {
        let content = "";
        let evvContent = "";
        let scheduledTime = "";
        if (this.getEvvContent(data).length > 0) {
            evvContent = `<span>
                            <strong>EVV Time:</strong>
                            ${this.getEvvContent(data)}
                        </span>`;
        }
        if (!!data) {
            content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                            <span><strong>Payer:</strong> ${data.Payer}</span>
                            <span><strong>Billable:</strong> ${!!data.IsBillable ? "Yes" : "No"}</span>
                            <span><strong>Payable:</strong> ${!!data.IsPayable ? "Yes" : "No"}</span>
                            <span>
                                <strong>Scheduled By:</strong>
                                ${this.getUserName(data.ScheduledByFirstName, data.ScheduledByLastName)}
                            </span>
                            ${evvContent}
                        <div>`;
        }
        return content;
    }

    public attachNonVisitTooltip(arg: any) {
        let element = $(arg?.el);
        let content = this.getNonVisitTooltipContent(arg?.event?.extendedProps);
        (element as any).popover({
            html: true,
            content: content,
            trigger: "hover",
            placement: "auto",
            container: "body"
        });
    }

    public getNonVisitTooltipContent(data: any) {
        let content = "";
        let evvContent = "";
        if (this.getEvvContent(data).length > 0) {
            evvContent = `<span>
                            <strong>EVV Time</strong>
                            ${this.getEvvContent(data)}
                        </span>`;
        }
        if (!!data) {
            content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                            <span><strong>Mileage:</strong> ${data.Mileage || "Not Available"}</span>
                        <div>`;
        }
        return content;
    }

    public getScheduledTime(time: string) {
        if (!time) {
            return "";
        }
        return new Date(time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit"
        });
    }

    public formatEVVTime(startTime: Date | string, endTime: Date | string) {
        if (startTime || endTime) {
            if (startTime === "Not Collected" || endTime === "Not Collected") {
                return `${startTime === "Not Collected" ? "Not Collected" : moment(startTime).format("hh:mm A")} - ${
                    endTime === "Not Collected" ? "Not Collected" : moment(endTime).format("hh:mm A")
                }`;
            }
            if (moment(startTime).isBefore(endTime, "day")) {
                return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("MM/DD/YYYY hh:mm A")}`;
            } else {
                return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("hh:mm A")}`;
            }
        } else return "";
    }
    public getEvvContent(data: any) {
        let evvTime = "";
        if (data.Application === ApplicationEnum.AxxessHospiceFE) {
            return evvTime;
        } else if (!data.EvvTimeIn && !data.EvvTimeOut) {
            evvTime = "Not Yet Started";
        } else {
            const evvTimeIn = data.EvvTimeIn || "Not Collected";
            const evvTimeOut = data.EvvTimeOut || "Not Collected";
            evvTime = this.formatEVVTime(evvTimeIn, evvTimeOut);
        }
        return evvTime;
    }

    public getUserName(firstName: string, lastName: string) {
        let userName = "";
        if (!firstName && !lastName) {
            userName = "Unassigned";
        } else {
            userName = formatName(firstName, lastName, "FN LN");
        }
        return userName;
    }

    private async rescheduleVisitHomeCare(dropEvent: any, event: IVisitDetails, StartDate: string, EndDate: string) {
        const { Id, AgencyId, Application, UserId, EpisodeId, PatientId, TimeIn, TimeOut } = event;

        const rescheduleDetails: IReassignVisitHC = {
            AgencyId,
            PatientId,
            EventId: Id,
            EpisodeId,
            Application,
            UserId,
            TimeIn,
            TimeOut,
            VisitStartTime: StartDate,
            VisitEndTime: EndDate,
            isDragDrop: true
        };

        try {
            const firstResponse = await this._taskService.rescheduleTaskHC(rescheduleDetails);
            if (!firstResponse.isSuccessful && firstResponse.errorDetails) {
                // if no error list, then simply show the error and end
                if (!firstResponse.errorDetails.taskIssues.length) {
                    throw new Error(firstResponse.errorMessage);
                }

                let validationList: IRescheduleValidations[] = firstResponse.errorDetails.taskIssues.map(
                    (item: any) => {
                        return {
                            title: item.title as string,
                            description: [item.message] as string[],
                            taskIssueSurpressKey: item.taskIssueSurpressKey as string
                        };
                    }
                );

                const hasError = firstResponse.errorDetails.allTasksHaveErrors;

                const dialogOptions: DialogSettings = {
                    viewModel: RescheduleValidations,
                    model: {
                        validations: validationList,
                        name: "Employee",
                        hasError
                    }
                };

                let end = false;
                await this._dialogService.open(dialogOptions).whenClosed((response) => {
                    if (response.wasCancelled) {
                        dropEvent.revert();
                        end = true;
                    }
                });
                if (end) {
                    return;
                }

                const issuesResponseObj: IHandleTaskReschuleIssue = {};
                for (let issue of validationList) {
                    issuesResponseObj[issue.taskIssueSurpressKey] = "ScheduleAllAnyways";
                }

                rescheduleDetails.HandleTaskIssues = issuesResponseObj;
                const secondResponse = await this._taskService.rescheduleTaskHC(rescheduleDetails);
                if (!secondResponse.isSuccessful) {
                    throw new Error(secondResponse.errorMessage);
                }
            }

            this._toastrService.success({
                title: `Success`,
                message: `The visit has been successfully rescheduled.`
            });
        } catch (error) {
            this._toastrService.error({
                title: `Error - Rescheduling task`,
                message: error.message || `There was an error rescheduling the visit. Please Try again later.`
            });
        } finally {
            this.calendarRefresh();
        }
    }

    private async rescheduleVisitHosPal(event: IVisitDetails, StartDate: string, EndDate: string) {
        let application;
        if (event.Application === ApplicationEnum.AxxessHospice) {
            application = ApplicationEnum.AxxessHospiceFE;
        } else {
            application = event.Application;
        }
        const { Id, AgencyId, UserId } = event;
        const rescheduleDetails: IRescheduleVisitHosPal = {
            TaskId: Id,
            AccountId: AgencyId,
            StartDate,
            EndDate,
            UserId,
            Application: application
        };
        try {
            await this._taskService.rescheduleTaskHosPal(rescheduleDetails);
            this._toastrService.success({
                title: `Success`,
                message: `The visit has been successfully rescheduled.`
            });
        } catch (error) {
            this._toastrService.error({
                title: `Error - Rescheduling task`,
                message: `There was an error rescheduling the visit. Please Try again later.`
            });
        } finally {
            this.calendarRefresh();
        }
    }

    public attachMorePopover(arg: any) {
        let element = $(arg?.el).find(".more-btn");
        let extendedProps = arg?.event?.extendedProps;
        let startDate = new Date(Date.parse(extendedProps?.StartDate));
        let key = `${extendedProps.UserId}${startDate.toLocaleDateString()}`;
        let tasksList = this.tasksMap?.get(key)?.slice(this.eventLimit, this.tasksList?.length);
        let content = "";
        tasksList?.map((task) => {
            let isNonPatientVisit = !task.PatientId;
            let patientName = "";
            if (!isNonPatientVisit) {
                patientName = `<span class="d-flex align-items-center font-size-2xs">
                <i class="fas fa-user mr-1"></i>
                <span>
                    ${this.getUserName(task.PatientFirstName, task.PatientLastName)}
                </span>
            </span>`;
            }
            let taskContent = `<div class="ax-calendar-event ${ScheduleLegendState[task.State]} mb-1 more-event" id="${
                task.Id
            }">
                <span class="w-100 font-weight-bold text-truncate">
                    ${task.TaskName}
                </span>
                ${patientName}
                <span class="w-100 font-size-3xs text-truncate">
                    ${task.Status}
                </span>
            </div>`;
            content = content + taskContent;
            this.attachMoreEventsTooltip(task);
        });
        (element as any)
            .popover({
                html: true,
                content: content,
                trigger: "click focus",
                placement: "auto",
                container: "body"
            })
            .on("mouseenter", () => {
                let parentDiv = element.closest("td > div");
                parentDiv.css("z-index", "auto");
            })
            .on("mouseleave", () => {
                let parentDiv = element.closest("td > div");
                parentDiv.css("z-index", "");
            });

        // $(".fc-timeline-event")
        //     .off("click")
        //     // tslint:disable-next-line: only-arrow-functions
        //     .on("click", ".more-event", async function (event: any) {
        //         event.stopPropagation();
        //         let taskId = $(this).prop("id");
        //         if (!!taskId) {
        //             let task = self.tasksList.find((item) => item.Id === taskId);
        //             if (!!task) {
        //                 await self.navigateToTask(task);
        //             }
        //         }
        //     });
    }

    public attachMoreEventsTooltip(task: ITaskByUserResponse) {
        let isNonPatientVisit = !task.PatientId;
        let content = isNonPatientVisit ? this.getNonVisitTooltipContent(task) : this.getTooltipContent(task);
        $("body").on("mouseenter", `#${task.Id}`, () => {
            $(`#${task.Id}`)
                .popover({
                    html: true,
                    content: content,
                    trigger: "hover",
                    placement: "auto",
                    container: "body"
                })
                .popover("show");
        });
    }

    public getTimeInOut(startTime: string, endTime: string) {
        if (!startTime && !endTime) {
            return "";
        }
        return `${startTime || ""} - ${endTime || ""}`;
    }
}
