
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        allPagesLoaded: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        nextPageOffset: {
            type: Number,
            default: 50
        },
        pageNumber: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            scrollableElement: null,
            count: 0 as number
        };
    },
    mounted() {
        this.scrollableElement = $(this.$el).find(".scroll-paginate")[0];
        this.count = this.$props.pageNumber;
        setTimeout(() => {
            this.handleScrolling();
        }, 100);
    },
    methods: {
        handleScrolling() {
            let scrollTimer: NodeJS.Timer;
            let $scrollableElement: JQuery<Element> = $(this.scrollableElement);
            let self = this;
            $scrollableElement.off("scroll").on("scroll", (e) => {
                if (scrollTimer) {
                    clearTimeout(scrollTimer); // clear any previous pending timer
                }
                scrollTimer = setTimeout(() => {
                    let st = $scrollableElement.scrollTop() || 0;
                    let sh = $scrollableElement[0].scrollHeight || 0;
                    let ht = $scrollableElement.height() || 0;
                    let nextCallHt = sh - self.$props.nextPageOffset;
                    if (st + ht >= nextCallHt && !self.$props.isLoading && !self.$props.allPagesLoaded) {
                        this.$emit("pageNumberUpdate", ++this.count);
                    }
                }, 100); // set new timer
            });
        }
    }
});
