import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import {
    IGetConversationChunkQuery,
    IGetConversationQuery,
    IGetConversationResult,
    IGetMessageQuery,
    IGetMessageResult,
    IGetSharedAssetQuery,
    IGetSharedAssetResult,
    IMessageItem,
    INewMessage,
    ISendMessageQuery,
    ISendMessageResult,
    IDeleteMessages,
    ICreateRoomRequest,
    ICreateRoomResponse,
    IJoinRoomResponse,
    IGetRoomStatusResponse
} from "../interfaces/messaging-service/i-message";
import config from "../common/config";
import { ApplicationEnum } from "../enums/enums";
import {
    ICreateAssetForMessagingResult,
    ICreateAssetItem,
    IFileDetails
} from "../resources-vue/vue-interfaces/i-asset";

@autoinject()
export class MessageService {
    private _httpClient: FetchClient;
    private _baseUrl: string = `/api/v1/conversations`;
    private _baseMessagingUrl: string = `${config.messagingServiceServerURL}api/v1`;
    private queryHeaders = {
        "X-Chat-AgencyId": "122ec20a-705b-4974-9437-8f6233a6e953"
    };

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getConversations(query: IGetConversationQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations`)
            .useMode("cors")
            .useQueryString(query)
            .useHeaders(this.queryHeaders)
            .fetch<IGetConversationResult>();
    }

    public async getUnreadConversations(query: IGetConversationQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/unread`)
            .useQueryString(query)
            .useHeaders(this.queryHeaders)
            .fetch<IGetConversationResult>();
    }

    public async getMessages(conversationId: string, query: IGetMessageQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/messages`)
            .useQueryString(query)
            .useHeaders(this.queryHeaders)
            .fetch<IGetMessageResult>();
    }

    public async getUnReadMessages(conversationId: string) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/unread`)
            .fetch<IMessageItem[]>();
    }

    public async getConversationChunk(conversationId: string, query: IGetConversationChunkQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/chunk`)
            .useQueryString(query)
            .fetch<IMessageItem[]>();
    }

    public async newConversation(newMessage: INewMessage) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations`)
            .useHeaders(this.queryHeaders)
            .useMethod("POST")
            .useBody(newMessage)
            .fetch<any>();
    }

    public async markConversationAsRead(conversation: {conversationId: string}) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/messages/read-message`)
            .useHeaders(this.queryHeaders)
            .useMethod("PATCH")
            .useBody(conversation)
            .fetch();
    }

    public async sendMessage(conversationId: string, query: ISendMessageQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/message`)
            .useMethod("PUT")
            .useHeaders(this.queryHeaders)
            .useBody(query)
            .fetch<ISendMessageResult>();
    }

    public async getSharedAssets(conversationId: string, query: IGetSharedAssetQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/assets`)
            .useQueryString(query)
            .fetch<IGetSharedAssetResult[]>();
    }

    public async deleteMessages(conversationId: string, data: IDeleteMessages) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/messages`)
            .useMethod("DELETE")
            .useBody(data)
            .fetchNoContent();
    }

    public async getDeletedMessages(conversationId: string, query: IGetMessageQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/conversations/${conversationId}/deleted-messages`)
            .useQueryString(query)
            .fetch<IGetMessageResult>();
    }

    public async getUserByName(query: IGetUsersQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/users/filter`)
            .useMethod("POST")
            .useBody(query)
            .useHeaders(this.queryHeaders)
            .fetch<IPaginationCamelCaseNew<IEmployeeList>>();
    }

    public async getPatientsByName(query: IGetPatientsQuery) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/patients/active/by-agencyId`)
            .useMethod("POST")
            .useBody(query)
            .useHeaders(this.queryHeaders)
            .fetch<IPatientListResponse<IPatientList>>();
    }

    public async uploadMessageFiles(files: File[]) {
        if (!files?.length) {
            return [];
        }
        let fileDetails = files.map((file, index) => ({
            fileName: file.name,
            fileSize: file.size,
            attachmentNumber: index
        }));
        let assets: ICreateAssetForMessagingResult = await this.createMessageAssets(fileDetails);
        if (!assets?.assetDetails?.length) {
            throw new Error("Asset Creation Unsuccessful.");
        }
        let successfulAssetIds: string[] = [];
        assets.assetDetails?.forEach((asset) => {
            if (asset.isSuccessful) {
                let file = files[asset.attachmentNumber];
                this.upload(asset.token, file);
                successfulAssetIds.push(asset.assetId);
            }
        });
        return successfulAssetIds;
    }

    private async createMessageAssets(fileDetails: IFileDetails[]) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/assets`)
            .useMethod("POST")
            .useBody({ assets: fileDetails })
            .useHeaders(this.queryHeaders)
            .fetch<ICreateAssetForMessagingResult>();
    }

    public async uploadFile(file: File) {
        let asset: ICreateAssetItem = await this.createAsset({
            fileName: file.name,
            fileSize: file.size
        });
        if (!asset) {
            throw new Error("Asset Creation Unsuccessful.");
        }
        await this.upload(asset.token, file);
        return asset.assetId;
    }

    private async createAsset(fileDetails: IFileDetails) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/assets`)
            .useMethod("POST")
            .useBody(fileDetails)
            .useHeaders(this.queryHeaders)
            .fetch<ICreateAssetItem>();
    }

    public async upload(token: string, file: File) {
        let formData = new FormData();
        formData.append("File", file);
        return await this._httpClient
            .build(`http://52.43.224.85:9085/api/v1/assets`)
            .useMode("cors")
            .useMethod("POST")
            .useAuthorization("Bearer " + token)
            .useFormData(formData)
            .fetchNoContent();
    }

    public async createRoom(query: ICreateRoomRequest) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/vcc/create/room`)
            .useMethod("POST")
            .useBody(query)
            .useHeaders(this.queryHeaders)
            .fetch<ICreateRoomResponse>();
    }

    public async joinRoom(roomId: string) {
        return await this._httpClient
            .build(`${config.AudioVideoServiceServerURL}api/v1/User/join-room/${roomId}`)
            .fetch<IJoinRoomResponse>();
    }

    public async getRoomStatus(roomId: string, subjectId: string) {
        return await this._httpClient
            .build(`${this._baseMessagingUrl}/vcc/room/${roomId}/${subjectId}`)
            .fetch<IGetRoomStatusResponse>();
    }
}

export interface IGetPatientByName {
    patientId: string;
    agencyId: string;
    firstName: string;
    lastName: string;
    application: ApplicationEnum;
    organization: string;
    status: string;
    mrn?: string;
}

export interface IPatientList {
    patientId: string;
    firstName: string;
    lastName: string;
    mrn: string;
    agencyId: string;
    dateOfBirth: string;
    application: number;
    phoneNumber: string;
    [key: string]: string | number;
}

export interface IEmployeeList {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    agencyId: string;
    loginId: string;
    email: string;
    agencyName: string;
    application: number;
    status: number;
    [key: string]: string | number;
}

export interface IGetUsersQuery {
    page: number;
    pageLength: number;
    term: string;
    agencyIds: string[];
}

export interface IGetPatientsQuery {
    agencyIds: string[];
    term: string;
    page: number;
    pageLength: number;
}

export interface IPaginationCamelCaseNew<T> {
    itemCount: number;
    pageLength: number;
    currentPage: number;
    pageCount: number;
    items: T[];
}

export interface IPatientListResponse<T> {
    response: T[];
}
