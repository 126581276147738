import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import "summernote/dist/summernote-bs4.js";
import * as $ from "jquery";
import { UserManager } from "oidc-client";

import config from "../../../../common/config";
import { IGetPatientByName, MessageService } from "../../../../services/message-service";
import { IGetUserByName } from "../../../../interfaces/i-user";
import { ApplicationEnum, ApplicationNames } from "../../../../enums/enums";

export default defineComponent({
    props: {
        options: {
            type: Object,
            default: null
        },
        initialValue: {
            default: "",
            type: String
        },
        result: {
            default: "",
            type: String
        },
        selectedProfile: {
            type: Array,
            default: null
        },
        allSelectedProfiles: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            editorMarkupRef: null as HTMLElement,
            editor: null as JQuery<HTMLElement>,
            editorLoaded: false as boolean,
            filteredPatients: [] as IGetPatientByName[],
            _messageService: null,
            ApplicationNamesEnum: ApplicationNames,
            filteredUsers: [] as IGetUserByName[],
            _userManager: null,
            userSubId: "" as string,
            onLoadData: "" as string,
            names: [],
            lastRemovedProfileId: "" as string,
            isRemoveIconClicked: false as boolean
        };
    },
    created() {
        this._messageService = Container.instance.get(MessageService);
        this._userManager = Container.instance.get(UserManager);
        this.editorMarkupRef = this.$refs?.editorMarkupRef as HTMLElement;
    },

    async mounted() {
        this.editorMarkupRef = this.$refs?.editorMarkupRef as HTMLElement;
        this.create();
        if (!!this.onLoadData && this.onLoadData.length > 0) {
            this.editor.summernote("code", this.onLoadData);
        }
        this.editorLoaded = true;
        this.getUserSubId();
    },

    watch: {
        initialValue: function (newValue) {
            this.initialValueChanged(newValue);
        },

        selectedProfile: function (newValue) {
            this.selectedProfileUpdated(newValue);
        }
    },

    computed: {
        defaultOptions(): any {
            let styleOptions: Summernote.toolbarStyleGroupOptions[] = ["bold", "italic", "style"];
            let paraOptions: Summernote.toolbarParaGroupOptions[] = ["ul", "ol"];
            let insertOptions: Summernote.toolbarInsertGroupOptions[] = ["link"];
            // TODO: Using Summernote.toolbarDef keeps throwing an error
            // even though the definition was followed
            let toolbarOptions: any = [
                ["style", styleOptions],
                ["para", paraOptions],
                ["insert", insertOptions],
                ["custom", ["attach", "important", "shareProfile"]]
            ];
            let hintOptions: any = [
                {
                    mentions: this.filteredPatients,
                    match: /\B#(\w*)$/,
                    search: (keyword: string, callback: any) => {
                        if (!!keyword) {
                            callback(["Loading"]);
                        }
                        this.getPatientsList(keyword)
                            .then((users) => {
                                this.filteredPatients = users as any;
                                callback(users);
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    },
                    template: (item: IGetPatientByName) => {
                        if ((item as any) == "Loading") {
                            return `<div class="text-center name-text loading-text">Loading...</div>`;
                        }
                        if ((item as any) == "No Matches Found") {
                            return `<div class="text-center name-text loading-text">No Matches Found</div>`;
                        }
                        let productPillClassName: string = this.getProductMarkupClassName(item.application);
                        if (productPillClassName.length > 0) {
                            productPillClassName = `pill-${productPillClassName}`;
                        }
                        const productName: string = this.ApplicationNamesEnum.getDescriptionUsingValue(
                            Number(item.application)
                        );
                        let nameInitials = "";
                        if (item.firstName) nameInitials += item.firstName.trim().charAt(0);
                        if (item.lastName) nameInitials += item.lastName.trim().charAt(0);
                        return `
                        <div class="m-1">
                            <div class="d-flex">
                                <div class="ava-initials mr-1">
                                    <span>${nameInitials}</span>
                                </div>
                                <div>
                                <div class="d-flex justify-content-between">
                                    <h6 class ="name-text">
                                        ${item.firstName} ${item.lastName}
                                        <span class="pill ${productPillClassName}" >
                                            ${productName}
                                        </span>
                                    <h6>
                                </div>
                                <p class="text-muted text-cred">MRN: ${item.mrn}</p>
                                </div>
                            </div>
                        <div>`;
                    },
                    content: (item: IGetPatientByName) => {
                        const redirectURL: string = this.getUrlFromApplication(item.application);
                        const link = document.createElement("a");
                        link.href = `${redirectURL}`;
                        link.target = "_blank";
                        link.textContent = `${item.firstName} ${item.lastName}`;
                        return link;
                    }
                },
                {
                    mentions: this.filteredUsers,
                    match: /\B@(\w*)$/,
                    search: (keyword: any, callback: any) => {
                        if (!!keyword) {
                            callback(["Loading"]);
                        }
                        this.getUsersList(keyword)
                            .then((users) => {
                                this.filteredUsers = users as any;
                                callback(users);
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    },
                    template: (item: IGetUserByName) => {
                        if ((item as any) == "Loading") {
                            return `<div class="text-center name-text loading-text">Loading...</div>`;
                        }
                        if ((item as any) == "No Matches Found") {
                            return `<div class="text-center name-text loading-text">No Matches Found</div>`;
                        }
                        let productPillClassName: string = this.getProductMarkupClassName(
                            item.application as ApplicationEnum
                        );
                        if (productPillClassName.length > 0) {
                            productPillClassName = `pill-${productPillClassName}`;
                        }
                        const productName: string = this.ApplicationNamesEnum.getDescriptionUsingValue(
                            Number(item.application)
                        );
                        let nameInitials = "";
                        if (item.firstName) nameInitials += item.firstName.trim().charAt(0);
                        if (item.lastName) nameInitials += item.lastName.trim().charAt(0);
                        return `
                        <div class="m-1">
                            <div class="d-flex">
                                <div class="ava-initials mr-1">
                                    <span>${nameInitials}</span>
                                </div>
                                <div>
                                <div class="d-flex justify-content-between">
                                    <h6 class ="name-text">
                                        ${item.firstName} ${item.lastName}
                                        <!--<span class="pill ${productPillClassName}" >
                                            ${productName}
                                        </span>-->
                                    <h6>
                                </div>
                                <small class="text-muted text-cred">${item.agencyName}</small>
                                </div>
                            </div>
                        <div>`;
                    },
                    content: (item: IGetUserByName) => {
                        const redirectURL: string = this.getUrlFromApplication(item.application);
                        const link = document.createElement("a");
                        link.href = `${redirectURL}`;
                        link.target = "_blank";
                        link.textContent = `${item.firstName} ${item.lastName}`;
                        return link;
                    }
                }
            ];
            return {
                toolbar: toolbarOptions,
                styleTags: ["h1", "h2", "h3", "h4", "h5", "h6"],
                placeholder: "Start typing here...",
                height: 50,
                maxHeight: 50,
                focus: true,
                hint: hintOptions,
                dialogsInBody: true
            };
        }
    },

    methods: {
        create() {
            let $markupRef = $(this.editorMarkupRef);
            let editorOptions = Object.assign({}, this.options, this.defaultOptions);
            if (!!editorOptions.buttons && Object.keys(editorOptions.buttons).length > 0) {
                if (!this.hasCustomButtons(editorOptions.toolbar)) {
                    editorOptions.toolbar.push(["customButtons", ["send"]]);
                }
            }
            this.editor = $markupRef.summernote(editorOptions);
            $(this.editorMarkupRef).summernote("code", "");
            this.editor.on("summernote.change", () => {
                this.handleChange();
            });
        },

        hasCustomButtons(toolbar: any) {
            for (let i = 0; i < toolbar.length; i++) {
                if (toolbar[i][0] === "customButtons") {
                    return true;
                }
            }
            return false;
        },

        handleChange() {
            let content = $(this.editorMarkupRef).summernote("code") as unknown;
            if (this.result !== content) {
                this.$emit("update:result", content);
            }
        },

        async getUserSubId() {
            const _user = await this._userManager.getUser();
            if (_user) {
                this.userSubId = _user.profile.sub;
            }
        },

        async getPatientsList(filter: string) {
            if (!!filter) {
                const data = await this._messageService.getPatientsByName({
                    agencyIds: [],
                    term: filter.trim(),
                    page: 1,
                    pageLength: 25
                });
                if (data.response.length > 0) return data.response;
                return ["No Matches Found"];
            }
            return [];
        },

        async getUsersList(filter: string) {
            if (!!filter) {
                const data = await this._messageService.getUserByName({
                    page: 1,
                    pageLength: 25,
                    term: filter.trim(),
                    agencyIds: []
                });
                if (data.items.length > 0) return data.items;
                return ["No Matches Found"];
            }
            return [];
        },

        getProductMarkupClassName(id: ApplicationEnum): string {
            switch (id) {
                case 1:
                    return "agencycore";
                case 2:
                    return "homecare";
                case 256:
                    return "hospice";
                case 65536:
                    return "palliative";
            }
            return "";
        },

        initialValueChanged(newValue: string) {
            if (newValue !== this.result) {
                if (this.editorLoaded && !!this.editor) {
                    this.onLoadData = "";
                    if (!!newValue && newValue.length > 0) {
                        this.editor.summernote("code", newValue);
                    } else {
                        this.editor.summernote("code", "");
                    }
                } else {
                    if (!!newValue && newValue.length > 0) {
                        this.onLoadData = newValue;
                    }
                }
            }
        },

        selectedProfileUpdated(newValue: any) {
            if (this.isRemoveIconClicked) {
                const previousText = this.editor.summernote("code");
                const profileId = `#profile_${this.lastRemovedProfileId}`;
                //below code finds the profile from the editor and removes it
                const updatedHtml = $(previousText).find(profileId).remove().end()[0].outerHTML;
                this.editor.summernote("code", updatedHtml);
                this.isRemoveIconClicked = false;
                this.lastRemovedProfileId = "";
            } else {
                this.names = [];
                newValue.forEach((element: any) => {
                    const elementId = element.hasOwnProperty("id") ? element.id : element.patientId;
                    const redirectURL: string = this.getUrlFromApplication(element.application);
                    const names = `<span contenteditable="false" class="d-inline-flex align-items-center justify-content-center share-profile ml-1 py-1 px-2" id="profile_${elementId}">
                    <a href="${redirectURL}"><i class="fas fa-user-circle fa-lg pr-1"></i> ${
                        element.lastName + " " + element.firstName
                    }  </a> <i class="fas fa-times-circle fa-sm remove-profile-icon pl-2 cursor-pointer" id="${elementId}" ></i>
                    </span> `;
                    this.names.push(names);
                });
                // below code adds the selected profiles at current position of the cursor
                this.editor.summernote("editor.saveRange");
                this.editor.summernote("editor.restoreRange");
                this.editor.summernote("editor.focus");
                this.editor.summernote("pasteHTML", this.names.join(" "));
            }
            this.attachRemoveIconEvent();
        },

        attachRemoveIconEvent() {
            const profileElements = document.getElementsByClassName("remove-profile-icon");
            if (!!profileElements) {
                for (let i = 0; i < profileElements.length; i++) {
                    const elementId = profileElements[i].id;
                    document.getElementById(elementId).addEventListener("click", () => {
                        const updatedProfileList = this.selectedProfile.filter(
                            (profile: any) =>
                                (profile.hasOwnProperty("id") ? profile.id : profile.patientId) !== elementId
                        );
                        const updatedAllProfileList = this.allSelectedProfiles.filter((profile: any) => {
                            if ((profile.hasOwnProperty("id") ? profile.id : profile.patientId) !== elementId) {
                                return profile;
                            } else {
                                this.lastRemovedProfileId = elementId;
                                this.isRemoveIconClicked = true;
                            }
                        });

                        this.$emit("update:selectedProfile", updatedProfileList, updatedAllProfileList);
                    });
                }
            }
        },

        getUrlFromApplication(applicationId: ApplicationEnum) {
            let redirectURL: string = "";
            switch (applicationId) {
                case ApplicationEnum.AxxessHospiceFE:
                    redirectURL = `${config.hospiceUrl}`;
                    break;
                case ApplicationEnum.AxxessPalliative:
                    redirectURL = `${config.palliativeUrl}`;
                    break;
                case ApplicationEnum.AgencyCore:
                    // TODO: need to replace it with config file
                    redirectURL = `http://agencycore60.test.axxess.tech/`;
                    break;
                case ApplicationEnum.HomeCare:
                    // TODO: need to replace it with config file
                    redirectURL = `http://homecare1.test.axxess.tech/`;
                    break;
                default:
                    console.error("Unknown Application Id");
            }
            return redirectURL;
        },

        reset() {
            if (this.editorLoaded) {
                this.destroy();
                // summernote leaves the html message behind after destruction, this removes it
                this.editorMarkupRef.innerHTML = "";
                this.create();
            }
        },

        destroy() {
            $(this.editorMarkupRef).summernote("destroy");
            this.editor = null;
        }
    },
    unmounted() {
        this.destroy();
    }
});
