import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";

@noView()
@autoinject
export class Videos {
    private _fetchClient: FetchClient;

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    // TODO: Need to update implementation to avoid HTML in response
    public async getVideo(): Promise<any> {
        let url = `api/v1/dashboardmessage`;
        let videos = await this._fetchClient.build("api/v1/dashboardmessage").fetch<IGetVideoResponse>();
        return videos || [];
    }
}

export interface IGetVideoResponse {
    Created: string;
    CreatedBy: string;
    Id: string;
    Text: string;
    Title: string;
}
