import { defineComponent } from "vue";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";

import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        DatePicker
    },
    props: {
        referral: {
            type: HomeHealthReferral,
            default: null
        },
        referralOptions: {
            type: Object,
            default: null
        },
        insurer: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            formatDateInput,
            patientWithin60Days: false,
            patientsOnAnotherServices: false
        };
    },

    methods: {
        onDateChange(date: any, name: string) {
            if (name === "medicare-part-a-effective-date") {
                this.referral.ReferralExtension[this.insurer?.partADate] = date;
            }
            if (name === "medicare-part-b-effective-date") {
                this.referral.ReferralExtension[this.insurer?.partBDate] = date;
            }
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
        }
    }
});
