import { defineComponent } from "vue";

export interface IAlertOptions {
    title?: string;
    message: string;
    actionId?: string;
}

export default defineComponent({
    inject: ["dialogRef"],

    data() {
        return {
            dialogReference: this.dialogRef as any,
            options: {} as IAlertOptions
        };
    },

    created() {
        this.options = {
            title: "Alert",
            message: "This is a default message."
        };
    },

    async mounted() {
        Object.assign(this.options, this.dialogReference.data.model);
    },

    methods: {
        ok() {
            this.dialogReference.close();
        }
    }
});
