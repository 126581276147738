import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import config from "../../../common/config";
import { CentralIntakeService } from "../../../services/central-intake.service";
import { EnumsService } from "../../../services/enums.service";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { BranchesService } from "../../../services/branches-service";
import { EnumMap } from "../../../common/enum-map";
import { ParentPermissionEnum, PermissionActionEnum, TestingAgenciesEnum } from "../../../enums/enums";
import { IBranchResponse } from "../../../resources-vue/vue-interfaces/i-branch";
import { PAGE_FAIL_RESPONSE } from "../../../resources/elements/pagination";
import { IGetReferralResult } from "../../../resources-vue/vue-interfaces/i-get-referral";
import { IPageCallbackResult } from "../../../resources-vue/vue-interfaces/i-page-container";
import { helperTestingAgency } from "../../../common/vue-helpers/testing-agency-check";

import ListLayout from "../../../resources-vue/vue-custom-elements/ListLayout/ListLayout.vue";
import BranchDefaultFilter from "../../../resources-vue/vue-custom-elements/BranchDefaultFilter/BranchDefaultFilter.vue";
import Pagination from "../../../resources-vue/vue-elements/Pagination/Pagination.vue";
import SortableTh from "../../../resources-vue/vue-custom-elements/SortableTh/SortableTh.vue";
import ErrorMessageHandler from "../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import CentralReferralRow from "./CentralReferralRow.vue";

export default defineComponent({
    components: {
        ListLayout,
        BranchDefaultFilter,
        Pagination,
        SortableTh,
        ErrorMessageHandler,
        CentralReferralRow
    },

    data() {
        return {
            term: "",
            searchTimer: null,
            _bindingEngine: null,
            _centralIntakeService: null,
            _enumsService: null,
            _branchService: null,
            _permissionManager: null,
            sortBy: "referralDate",
            sortOrder: "asc",
            isError: false,
            columns: 11,
            router: null,
            page: null,
            isLoading: false,
            routeTerm: "",
            referralListPagination: null,
            hideTitle: false,
            selectedBranches: [],
            branchRef: null,
            genderTypesEnum: [],
            lineOfServiceEnumMap: null,
            lineOfServiceEnum: [],
            canViewHospiceReferral: false,
            canViewPalliativeReferral: false,
            branches: [],
            isTestAgency: false,
            referralsEditable: [],
            referralsDeleteable: [],
            responseHandler: {
                data: null,
                stamp: null,
                requests: []
            },
            nonAdmitReasonsData: {} as any,
            pageSize: 10
        };
    },

    async created() {
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._enumsService = Container.instance.get(EnumsService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._branchService = Container.instance.get(BranchesService);
        this.lineOfServiceEnum = await this._enumsService.getLineOfService();
        this.branches = await this._branchService.getAllBranches();
        const testingAgencies = Object.values(TestingAgenciesEnum);
        this.isTestAgency = await helperTestingAgency.checkHasTestingAgency();
        this.lineOfServiceEnumMap = new EnumMap(this.lineOfServiceEnum);
        this.genderTypesEnum = await this._enumsService.getGender();
        this.referralListPagination = this.$refs?.referralListPagination as typeof Pagination;
    },

    async mounted() {
        this.branches = await this._branchService.getAllBranches();
        this.referralListPagination = this.$refs?.referralListPagination as typeof Pagination;
        const canViewReferrals =
            this._permissionManager.getPermmission(ParentPermissionEnum.referrals, PermissionActionEnum.canView) || [];

        // if (!canViewReferrals?.length) {
        //     this.router.navigate("not-authorized");
        //     return;
        // }
        this.referralsEditable = this.branches.filter((branch) => this.isReferralEditAllowed(branch));
        this.referralsDeleteable = this.branches.filter((branch) => this.isReferralDeleteAllowed(branch));
    },

    computed: {
        hasData() {
            return this.page?.itemCount > 0;
        },

        loadingCount() {
            return this.page?.items?.length > 0 ? this.page.items.length : config.pageSize;
        }
    },

    watch: {
        term() {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(
                (term) => {
                    if (term?.length > 2 || term === "") {
                        this.search();
                    }
                },
                500,
                this.term
            );
        },

        selectedBranches: {
            handler() {
                this.pageReset();
            },
            deep: true
        }
    },

    methods: {
        search() {
            if (!this.isLoading) {
                this.pageReset();
            }
        },

        isReferralEditAllowed(branch: IBranchResponse): boolean {
            if (!branch.application || !branch.accountId) return false;
            return this._permissionManager.checkPermission(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canEdit,
                branch.accountId
            );
        },

        isReferralDeleteAllowed(branch: IBranchResponse): boolean {
            if (!branch.application || !branch.accountId) return false;
            return this._permissionManager.checkPermission(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canDelete,
                branch.accountId
            );
        },

        updateBranches(branches: any) {
            this.selectedBranches.length = 0;
            this.selectedBranches.push(...branches);
        },

        pageReset() {
            this.referralListPagination?.reset();
        },

        async pageChangedCallback(pageNumber: number, pageSize: number): Promise<IPageCallbackResult> {
            if (this.selectedBranches.length === 0) {
                return PAGE_FAIL_RESPONSE;
            }
            try {
                let callTime = Date.now();
                this.responseHandler.stamp = callTime;
                this.isLoading = true;
                this.responseHandler.data = null;
                this.isError = false;
                let payLoad = {
                    page: pageNumber,
                    pageLength: pageSize,
                    sortBy: this.sortBy,
                    order: this.sortOrder,
                    term: this.term,
                    locationIds: this.selectedBranches
                };
                let response = this._centralIntakeService.referrals(payLoad);
                this.responseHandler.requests.push(response);
                let data: IGetReferralResult = await response;
                if (this.responseHandler.stamp == callTime) {
                    this.responseHandler.data = data;
                } else {
                    data =
                        this.responseHandler.data ||
                        (await this.responseHandler.requests[this.responseHandler.requests.length - 1]);
                }
                this.page = data;
                return {
                    totalPageCount: data.pageCount,
                    success: data.itemCount > 0,
                    totalCount: data.itemCount
                };
            } catch (e) {
                this.isError = true;
                return PAGE_FAIL_RESPONSE;
            } finally {
                this.isLoading = false;
            }
        },

        newReferral() {
            this.$router.push("/axxess-central/create/interim/referral");
        },

        updatePageSizeChanged(value: number) {
            this.pageSize = value;
        },

        sortByUpdated(value: string) {
            if (value != this.sortBy) {
                this.sortBy = value;
                this.pageReset();
            }
        },

        sortOrderUpdated(value: string) {
            if (value != this.sortOrder) {
                this.sortOrder = value;
                this.pageReset();
            }
        },

        getGenderTypes(id: number) {
            let genderType = this.genderTypesEnum.filter((value, index) => value.value == id)[0];
            if (genderType) {
                return genderType.name ? genderType.name : "";
            } else {
                return "";
            }
        },

        resetList() {
            this.isError = false;
            this.pageReset();
        },

        updateNonAdmitReasonsData(reasons: any, referral: any) {
            this.nonAdmitReasonsData[referral.accountId] = reasons;
        }
    }
});
