import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmergencyContactPrimary = _resolveComponent("EmergencyContactPrimary")!
  const _component_EmergencyContactAdditional = _resolveComponent("EmergencyContactAdditional")!
  const _component_PatientRepresentativeCordination = _resolveComponent("PatientRepresentativeCordination")!
  const _component_ChapsSurvey = _resolveComponent("ChapsSurvey")!
  const _component_ContactsComments = _resolveComponent("ContactsComments")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_EmergencyContactPrimary),
    _createVNode(_component_EmergencyContactAdditional),
    _createVNode(_component_PatientRepresentativeCordination),
    _createVNode(_component_ChapsSurvey),
    _createVNode(_component_ContactsComments)
  ]))
}