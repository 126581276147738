import "./central-calendar.scss";

import { autoinject, computedFrom } from "aurelia-framework";
import { NavModel, Router, RouterConfiguration } from "aurelia-router";

import nameOf from "../../common/name-of";
import { CalendarRoutes } from "./calendar-routes";
import { BranchesService } from "../../services/branches-service";
import { TestingAgenciesEnum } from "../../enums/enums";

export enum ScheduleLegendState {
    "info" = 1,
    "success" = 2,
    "danger" = 3,
    "non-visit-bg" = 4
}

@autoinject
export class CentralCalendar {
    private _branchService: BranchesService;
    public router: Router;
    public showBorder: boolean = true;

    public constructor(router: Router, branchService: BranchesService) {
        this.router = router;
        this._branchService = branchService;
    }

    @computedFrom(`${nameOf<CentralCalendar>("router")}.${nameOf<Router>("navigation")}`)
    public get routes(): NavModel[] {
        return this.router.navigation || [];
    }

    @computedFrom(
        `${nameOf<CentralCalendar>("routes")}.length`,
        `${nameOf<CentralCalendar>("router")}.${nameOf<Router>("currentInstruction")}`
    )
    public get activeRoute() {
        const activeRoute: any = this.routes
            .filter((route) => !!route.config && route.config.nav)
            .find((route) => route.isActive);
        return activeRoute;
    }

    @computedFrom(
        `${nameOf<CentralCalendar>("routes")}.length`,
        `${nameOf<CentralCalendar>("activeRoute")}`,
        `${nameOf<CentralCalendar>("activeRoute")}.navItem`,
        `${nameOf<CentralCalendar>("router")}.${nameOf<Router>("currentInstruction")}`
    )
    public get activeItem() {
        if (this.activeRoute && this.activeRoute.navItem) {
            return this.activeRoute.navItem;
        } else {
            return null;
        }
    }

    public async configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        const branches = await this._branchService.getAllBranches();
        const testingAgencies = Object.values(TestingAgenciesEnum);
        const hasTestAgency = branches.some((branch) => {
            return testingAgencies.includes(branch.accountId as TestingAgenciesEnum);
        });
        let calRoutes = CalendarRoutes;
        if (!hasTestAgency) {
            calRoutes = CalendarRoutes.filter((route) => route.name != "daily");
        }
        config.map(calRoutes);
    }
}
