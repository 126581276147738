import { defineComponent } from "vue";

import { Days } from "../../../enums/enums";
import { enumToMap } from "../../../common/vue-helpers/modifiers/enum-modifiers";

export default defineComponent({
    props: {
        selectedDaysString: { type: String, default: "" }
    },

    emits: ["updateSelectedDays"],

    data() {
        return {
            selectedDays: [] as string[],
            days: Array.from(Days.enumWithDescriptions.values()),
            enumToMap
        };
    },

    methods: {
        handleDaySelect(value: any, event: any) {
            if (this.selectedDays.includes(value)) {
                const index = this.selectedDays.indexOf(value);
                this.selectedDays.splice(index, 1);
                event.target.classList.remove("selected-day");
            } else {
                this.selectedDays.push(value);
                event.target.classList.add("selected-day");
            }
            this.$emit("updateSelectedDays", this.selectedDays.toString());
        }
    }
});
