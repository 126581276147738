import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum DaysEnum {
    Sunday = 1 as any,
    Monday = 2 as any,
    Tuesday = 4 as any,
    Wednesday = 8 as any,
    Thursday = 16 as any,
    Friday = 32 as any,
    Saturday = 64 as any
}
class DaysClass extends EnumWithDescription<DaysEnum> {
    public readonly enum = DaysEnum;
    public readonly enumName: string = "DaysEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<DaysEnum>> = new Map([
        [
            "Sunday",
            {
                value: DaysEnum.Sunday,
                name: "S"
            }
        ],
        [
            "Monday",
            {
                value: DaysEnum.Monday,
                name: "M"
            }
        ],
        [
            "Tuesday",
            {
                value: DaysEnum.Tuesday,
                name: "T"
            }
        ],
        [
            "Wednesday",
            {
                value: DaysEnum.Wednesday,
                name: "W"
            }
        ],
        [
            "Thursday",
            {
                value: DaysEnum.Thursday,
                name: "T"
            }
        ],
        [
            "Friday",
            {
                value: DaysEnum.Friday,
                name: "F"
            }
        ],
        [
            "Saturday",
            {
                value: DaysEnum.Saturday,
                name: "S"
            }
        ]
    ]);
}

export const Days = new DaysClass();
