import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-100 input-group multiselect-dropdown" }
const _hoisted_2 = {
  class: "dropdown w-100",
  ref: "dropdown"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "message-container text-left" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  class: "dropdown-menu p-0",
  ref: "dropdownElement"
}
const _hoisted_7 = {
  key: 0,
  class: "content"
}
const _hoisted_8 = {
  key: 0,
  class: "border-bottom p-1 pl-2 title text-muted"
}
const _hoisted_9 = {
  key: 1,
  class: "py-1 d-flex justify-content-between border-bottom text-primary cursor-pointer"
}
const _hoisted_10 = { class: "custom-control custom-checkbox" }
const _hoisted_11 = ["id", "value", "onChange", "checked", "disabled"]
const _hoisted_12 = ["for"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("button", {
        class: _normalizeClass(`btn border bg-white dropdown-toggle ${_ctx.disabled ? 'disabled' : ''} w-100`),
        type: "button",
        "data-toggle": "dropdown",
        "aria-haspopup": "true",
        "aria-expanded": "false",
        disabled: _ctx.disabled
      }, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.message), 1 /* TEXT */)
      ], 10 /* CLASS, PROPS */, _hoisted_3), [
        [_vShow, !_ctx.hasCustomView]
      ]),
      _withDirectives(_createElementVNode("button", {
        class: _normalizeClass(`btn btn-custom px-2 ${_ctx.isActive ? _ctx.activeClasses : 'text-muted'}`),
        "data-toggle": "dropdown",
        "aria-haspopup": "true",
        "aria-expanded": "false",
        disabled: _ctx.disabled,
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleActiveClasses(true)))
      }, [
        _renderSlot(_ctx.$slots, "custom-view")
      ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_5), [
        [_vShow, _ctx.hasCustomView]
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.options && _ctx.options.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.title.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.title), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (_ctx.hasActions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("a", {
                      href: "javascript:void(0)",
                      class: "pl-3 text-decoration",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectAll()))
                    }, " Check all "),
                    _createElementVNode("a", {
                      href: "javascript:void(0)",
                      class: "pr-2 text-decoration",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deselectAll()))
                    }, " Uncheck all ")
                  ]))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "dropdown-item pl-3",
                  key: item.value
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      class: "custom-control-input",
                      id: `${_ctx.name}option${item.value}${index}`,
                      value: item.value,
                      onChange: ($event: any) => (_ctx.updateSelectedItems(item.value, $event)),
                      checked: _ctx.selectedItem(item.value),
                      disabled: _ctx.disabled
                    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_11),
                    _createElementVNode("label", {
                      class: "font-weight-light custom-control-label",
                      for: `${_ctx.name}option${item.value}${index}`
                    }, [
                      (_ctx.showIcons)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            innerHTML: _ctx.getView(item)
                          }, null, 8 /* PROPS */, _hoisted_13))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("span", {
                        class: "label-content",
                        title: `${item.name}`
                      }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, _hoisted_14)
                    ], 8 /* PROPS */, _hoisted_12)
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : _createCommentVNode("v-if", true)
      ], 512 /* NEED_PATCH */)
    ], 512 /* NEED_PATCH */)
  ]))
}