import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { GoogleMapsService } from "../../../services/google-maps.service";
import { LookupService } from "./../../../services/lookup-service";
import { formatZipCode } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            options: {
                originalAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    county: "",
                    country: "",
                    latitude: null,
                    longitude: null
                },
                updatedAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    county: "",
                    country: "",
                    latitude: null,
                    longitude: null
                }
            },
            validAddress: true,
            countryCodes: [],
            _geoCoder: null,
            _googleMapsWebApi: null,
            _googleMapsWebService: null,
            _lookupService: null,
            dialogReference: this.dialogRef as any,
            formatZipCode
        };
    },
    created() {
        this._lookupService = Container.instance.get(LookupService);
        this._googleMapsWebService = Container.instance.get(GoogleMapsService);
    },
    beforeMount() {
        Object.assign(this.options, this.dialogReference.data.model);
    },
    async mounted() {
        let address = this.options.originalAddress;
        this.countryCodes = await this._lookupService.getCountryCodes();
        this._googleMapsWebApi = await this._googleMapsWebService.getMapsApi();
        console.info("::Validate-Address:: Received Google Maps API: ", this._googleMapsWebApi);
        this._geoCoder = new this._googleMapsWebApi.Geocoder();
        this.validAddress =
            address.addressLine1.length > 0 &&
            address.city.length > 0 &&
            address.state.length > 0 &&
            address.zipCode.length > 0;

        if (this.validAddress) {
            let updatedCountryLabel = "";
            this._geoCoder.geocode(
                {
                    address:
                        address.addressLine1 +
                        " " +
                        address.addressLine2 +
                        " " +
                        (address.state === "0" ? "" : address.state) +
                        " " +
                        address.zipCode
                },
                (results: any, status: string) => {
                    if (status === "OK" && results.length > 0) {
                        console.info("addr", results);
                        results[0].address_components.forEach((addressVal: google.maps.GeocoderAddressComponent) => {
                            let addressType = addressVal.types[0];
                            switch (addressType) {
                                case "street_number":
                                    this.options.updatedAddress.addressLine1 = addressVal.short_name;
                                    break;
                                case "route":
                                    this.options.updatedAddress.addressLine1 =
                                        this.options.updatedAddress.addressLine1 + " " + addressVal.short_name;
                                    break;
                                case "subpremise":
                                    this.options.updatedAddress.addressLine2 = addressVal.short_name;
                                    break;
                                case "locality":
                                    this.options.updatedAddress.city = addressVal.short_name;
                                    break;
                                case "administrative_area_level_1":
                                    this.options.updatedAddress.state = addressVal.short_name;
                                    break;
                                case "administrative_area_level_2":
                                    this.options.updatedAddress.county = addressVal.short_name.replace(" County", "");
                                    break;
                                case "postal_code":
                                    this.options.updatedAddress.zipCode = addressVal.short_name;
                                    break;
                                case "country":
                                    updatedCountryLabel = addressVal.long_name;
                                    break;
                                default:
                                    break;
                            }
                            let updatedCountry = this.countryCodes?.find(
                                (country) => country.name.toLowerCase() === updatedCountryLabel.toLowerCase()
                            );
                            if (updatedCountry) {
                                this.options.updatedAddress.country = updatedCountry.value;
                            }
                        });
                        this.options.updatedAddress.latitude = results[0].geometry.location.lat();
                        this.options.updatedAddress.longitude = results[0].geometry.location.lng();
                    } else {
                        console.info("Google Maps Service is currently unavailable.");
                    }
                }
            );
        }
    },
    computed: {
        originalAddressCountry() {
            return this.getCountryLabel(this.options?.originalAddress?.country);
        },
        updatedAddressCountry() {
            return this.getCountryLabel(this.options?.updatedAddress?.country);
        }
    },
    methods: {
        getCountryLabel(code: string) {
            let selectedCountry = this.countryCodes?.find((country) => country.value === code);
            return selectedCountry?.name;
        },
        cancel() {
            return this.dialogReference.close("cancelled");
        },

        success() {
            return this.dialogReference.close({ success: true, updatedAddress: this.options.updatedAddress });
        },

        checkEqualAddress(original: string, updated: string) {
            return original == updated ? "" : "text-danger";
        }
    }
});
