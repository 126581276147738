import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { "with.bind": "physician" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyTextHandler = _resolveComponent("EmptyTextHandler")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", null, [
      _createVNode(_component_EmptyTextHandler, {
        text: _ctx.physician.FirstName
      }, null, 8 /* PROPS */, ["text"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_EmptyTextHandler, {
        text: _ctx.physician.LastName
      }, null, 8 /* PROPS */, ["text"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_EmptyTextHandler, {
        text: _ctx.physician.WorkPhone
      }, null, 8 /* PROPS */, ["text"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_EmptyTextHandler, {
        text: _ctx.physician.FaxNumber
      }, null, 8 /* PROPS */, ["text"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_EmptyTextHandler, {
        text: _ctx.physician.Email
      }, null, 8 /* PROPS */, ["text"])
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_EmptyTextHandler, {
        text: _ctx.physician.SpecialtyName
      }, null, 8 /* PROPS */, ["text"])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("a", {
        type: "button",
        class: "text-decoration-none",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deletePhysician(_ctx.physician.Id)))
      }, "Delete"),
      (!_ctx.physician.IsPrimary)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            type: "button",
            class: "action-link text-decoration-none",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.makePrimary(_ctx.physician.Id)))
          }, " Make Primary "))
        : _createCommentVNode("v-if", true),
      (!_ctx.physician.IsReferral)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            type: "button",
            class: "action-link text-decoration-none",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.makeReferral(_ctx.physician.Id)))
          }, " Make Referral "))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}