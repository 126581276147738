import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, Disposable } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";
import moment from "moment";
import { UserManager } from "oidc-client";
import jwtDecode from "jwt-decode";
import { setTimeout } from "timers";
import { DialogController, DialogService, DialogSettings } from "aurelia-dialog";

import { AccessService } from "../../services/access.service";
import AuthService from "../../services/auth.service";
import { UserService } from "../../services/user-service";
import { HttpService } from "../../services/http.service";
import { IdleTimer } from "../../services/idle-timer.service";
import { RolesService } from "../../services/roles.service";

import { SurveyModal } from "../../resources/dialogs/survey-modal/survey-modal";
import type { IRoles } from "../../resources-vue/vue-interfaces/i-roles";
import { ITitles } from "../../resources-vue/vue-interfaces/i-titles";
import { Role } from "../../models/role.model";
import { UserTitlesSurveyEnum } from "../../enums/UserTitlesSurveyEnum";

import config from "../../common/config";
import { OidcConfiguration } from "../../common/oidc-configuration";
import { UserLoaded } from "../../common/oidc/oidc-event-handler";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { TestingAgencyHelper, helperTestingAgency } from "../../common/vue-helpers/testing-agency-check";

import { AppCache } from "./app-cache";
import defaultRoutes from "./app-routes";
import { AuthorizeStep } from "./authorize-step";
import { PageTakeOverLoading } from "./loading-indicator";
import { MarketingContent } from "./marketing-content";

import "./app.scss";

export interface ILocationSave {
    fragment: string;
    query: string;
}
@autoinject
export class App {
    private _ea: EventAggregator;
    private _rolesService: RolesService;
    private _authService: AuthService;
    private _userService: UserService;
    private _idleTimerService: IdleTimer;
    private _httpService: HttpService;
    private _marketingContent: MarketingContent;
    private _oidcConfiguration: OidcConfiguration;
    private _accessService: AccessService;
    private _subscription: Disposable = null;
    private _appCache: AppCache;
    private _permissionManager: PermissionManager;
    private _userManager: UserManager;
    private readonly _dialogService: DialogService;
    public dialogController: DialogController;
    public roles: IRoles[] = [];
    public router: Router;
    public isLoggedIn: boolean = false;
    public areRolesLoaded: boolean = false;
    public isShowingVue: boolean = false;
    public titles: ITitles[] = [];

    public constructor(
        ea: EventAggregator,
        idleTimerService: IdleTimer,
        rolesService: RolesService,
        authService: AuthService,
        userService: UserService,
        httpService: HttpService,
        marketingContent: MarketingContent,
        accessService: AccessService,
        oidcConfiguration: OidcConfiguration,
        appCache: AppCache,
        permissionsManager: PermissionManager,
        dialogController: DialogController,
        dialogService: DialogService,
        userManager: UserManager
    ) {
        this._ea = ea;
        this.dialogController = dialogController;
        this._dialogService = dialogService;
        this._idleTimerService = idleTimerService;
        this._rolesService = rolesService;
        this._authService = authService;
        this._userService = userService;
        this._accessService = accessService;
        this._httpService = httpService;
        this._marketingContent = marketingContent;
        this._userManager = userManager;
        this._httpService.configureFetch();
        this._oidcConfiguration = oidcConfiguration;
        this._appCache = appCache;
        this.initSubscriptions();
        this._permissionManager = permissionsManager;
    }

    public async activate() {
        this.getToken();
        // If the user is already loaded
        await this.initUser();
    }

    public initSubscriptions() {
        this._subscription = this._ea.subscribe(UserLoaded, async () => {
            // When redirecting from identity, User will be loaded after app initiates
            await this.initUser();
        });
    }

    private async initUser() {
        let isUserLoggedIn = await this._authService.isLoggedIn();
        if (!isUserLoggedIn) {
            return;
        }
        if (TestingAgencyHelper?.isVueAppMounted) {
            return;
        }
        const canShowVueApp = await helperTestingAgency.shouldMountVue();
        let isBetaOpted = false;
        const userToggleInfo = await this._userService.getUserDetails();
        if (userToggleInfo.Data) {
            isBetaOpted = userToggleInfo.Data.IsBetaOpted;
        }
        sessionStorage.setItem("isBetaOpted", isBetaOpted.toString());
        if (isBetaOpted) {
            const vueMounted = await helperTestingAgency.mountVueApp();
            if (vueMounted) {
                this.isShowingVue = true;
                return;
            }
        }
        this.isLoggedIn = true;
        await this._permissionManager.refreshUserPermissionData();
        this.roles = await this.getUserRoles();
        this.titles = await this._rolesService.getTitles();
        this._accessService.initAccessRoles(this.roles);
        let accessRoles = this._accessService.getAccessRoles();
        await this._appCache.restoreMissingCache();
        // Adding this property to pass to app-nav to know when roles are loaded
        this.areRolesLoaded = true;
        const dialogOptions: DialogSettings = {
            viewModel: SurveyModal
        };
        const isAfter = moment("2024-05-27", "YYYY-MM-DD").isAfter(moment(), "day");
        let isBefore = moment().isBefore(moment("2024-05-14", "YYYY-MM-DD"), "day");
        let titleMatchedForSurvey = this.checkTitles(this.titles);
        const user = await this._userManager.getUser();
        const userEmail = user.profile.email;
        let isTestUser: boolean = false;
        /* the below if condition is implemented to help QA test this survey
            popup before the actual survey time starts */
        if (
            userEmail === "qatesteraxxess@yahoo.com" ||
            userEmail === "ptale@axxess.com" ||
            userEmail === "tpn3k@msn.com"
        ) {
            isTestUser = true;
            isBefore = moment().isBefore(moment("2024-04-16", "YYYY-MM-DD"), "day");
            titleMatchedForSurvey = true;
        }
        // Check if any titles exist in the backend response
        if (!isBefore && isAfter && (accessRoles.canUseSurvey || titleMatchedForSurvey)) {
            setTimeout(() => {
                const surveyCancelCount = localStorage.getItem("surveyCancelCount-" + userEmail);
                let surveyCancelCountParsed = surveyCancelCount ? JSON.parse(surveyCancelCount) : 0;
                const surveyReopenTime = localStorage.getItem("surveyReopenTime-" + userEmail);
                let surveyReopenTimeParsed = surveyReopenTime ? JSON.parse(surveyReopenTime) : null;
                const canShowModal = surveyReopenTimeParsed ? moment().isAfter(moment(surveyReopenTimeParsed)) : true;
                if (surveyCancelCountParsed < 3 && canShowModal) {
                    this._dialogService.open(dialogOptions).whenClosed((response) => {
                        if (response.wasCancelled) {
                            surveyReopenTimeParsed = moment().add(2, "days").toDate();
                            if (isTestUser) {
                                surveyReopenTimeParsed = moment().add(30, "minutes").toDate();
                            }
                            if (response.output === "RemindMeLater") {
                                surveyCancelCountParsed = 0;
                                surveyReopenTimeParsed = moment().add(1, "days").toDate();
                                if (isTestUser) {
                                    surveyReopenTimeParsed = moment().add(15, "minutes").toDate();
                                }
                            }
                            localStorage.setItem("surveyCancelCount-" + userEmail, "1");
                            localStorage.setItem(
                                "surveyReopenTime-" + userEmail,
                                JSON.stringify(surveyReopenTimeParsed)
                            );
                        } else {
                            localStorage.setItem("surveyReopenTime-" + userEmail, null);
                            localStorage.setItem("surveyCancelCount-" + userEmail, "3");
                            window.open("https://www.surveymonkey.com/r/PNTZPMN", "_blank");
                        }
                    });
                }
            }, 5000);
        }
    }

    private async getUserRoles() {
        try {
            let roles: Array<IRoles> = await this._rolesService.getRoles();
            return roles.map((role) => new Role(role));
        } catch (e) {
            // Roles endpoint should not be a bottle neck.
            // By returning an empty array, the user will at least see the help page.
            // Where they can select an agency.
            return [];
        }
    }

    public async configureRouter(routerConfig: RouterConfiguration, router: Router) {
        this.router = router;
        routerConfig.title = "Axxess Router";
        routerConfig.options.pushState = true;
        routerConfig.map(defaultRoutes);
        routerConfig.mapUnknownRoutes("/help");
        routerConfig.fallbackRoute("/help");
        routerConfig.options.root = "/";
        this._oidcConfiguration.configureRouter(routerConfig, router);
        routerConfig.addAuthorizeStep(AuthorizeStep);
    }

    public async attached() {
        try {
            this._ea.publish(PageTakeOverLoading.Hide);
            await this._idleTimerService.timer();
            await this.startMarketingContent();
        } catch (error) {
            console.error(error);
        } finally {
            this._ea.publish(PageTakeOverLoading.Hide);
        }
    }

    public getToken() {
        const url = window.location.href;
        const isTokenPresent = url.indexOf("live_token") > -1;

        if (isTokenPresent) {
            const accessToken = url.split("live_token=")[1];

            let save: ILocationSave = {
                fragment: "",
                query: accessToken
            };

            window.sessionStorage.setItem(config.locationSaveKey, JSON.stringify(save));

            const decoded = jwtDecode(accessToken);

            let openIdObject = {
                id_token: accessToken,
                session_state: "sEULhiGvQLQ7OifmmXk32YsGycaCuidJSeBDMqt88Ew.E6B978FD5942BF43FAFFA89485E08F02",
                access_token: accessToken,
                token_type: "Bearer",
                scope: "openid profile email",
                profile: decoded,
                expires_at: ""
            };

            window.sessionStorage.setItem(
                config.isDev || config.isStage
                    ? "oidc.user:https://identity.test.axxess.tech/.well-known/openid-configuration:Axxess-Router-Frontend"
                    : "oidc.user:https://identity.axxessweb.com/.well-known/openid-configuration:Axxess-Router-Frontend",
                JSON.stringify(openIdObject)
            );
        }
    }

    private async startMarketingContent() {
        await this._marketingContent?.start();
    }

    public titlesExistInEnum(titles: string[]): boolean {
        return titles.some((title) => Object.values(UserTitlesSurveyEnum).includes(title as UserTitlesSurveyEnum));
    }

    public checkTitles(response: any[]): boolean {
        for (const entry of response) {
            if (this.titlesExistInEnum(entry.titles)) {
                return true; // If match found, return true
            }
        }
        return false; // If no match found, return false
    }

    public deactivate() {
        this._subscription?.dispose();
    }
}
