import { defineComponent, computed } from "vue";
import { Container } from "aurelia-dependency-injection";

import type { IBranchResponse } from "../../../../../resources-vue/vue-interfaces/i-branch";
import { BranchesService } from "../../../../../services/branches-service";
import { Gender, GenderPronoun, MaritalStatuses, PhoneTypeEnum } from "../../../../../enums/enums";
import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import { Address } from "../../../../../resources-vue/vue-models/address";
import { CentralIntakeService } from "../../../../../services/central-intake.service";
import { ReferralService } from "../../../../../services/referral-service";
import type { IGetMunicipalityPayRates } from "../../../../../resources-vue/vue-interfaces/i-get-referral";
import type { IAddress } from "../../../../../resources-vue/vue-interfaces/i-address";
import { ToastrService } from "../../../../../services/toastr.service";
import type { IPhoneNumber } from "../../../../../resources-vue/vue-interfaces/i-phone-number";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { IValidateAddress } from "../../../../../resources-vue/vue-interfaces/i-validate-address";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import PhoneNumber from "../../../../../resources-vue/vue-custom-elements/PhoneNumber/PhoneNumber.vue";
import TagsInput from "../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import MultiSelectDropdown from "../../../../../resources-vue/vue-custom-elements/MultiSelectDropdown/MultiSelectDropdown.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import conflictPrompt from "../../../../../resources-vue/vue-dialogs/ConflictPrompt/ConflictPrompt.vue";
import ValidateAddress from "../../../../../resources-vue/vue-dialogs/ValidateAddress/ValidateAddress.vue";
import ReferralAddressForm from "../ReferralAddressForm/ReferralAddressForm.vue";

export default defineComponent({
    components: {
        FormSection,
        ReferralAddressForm,
        PhoneNumber,
        MultiSelectDropdown,
        TagsInput,
        DatePicker
    },
    props: {
        referral: { type: HomeCareReferral, default: null },
        providerId: { type: String, default: "" },
        branchId: { type: String, default: "" }
    },
    inject: ["v"],

    data() {
        return {
            hasNoConflict: false,
            isAddressValidationInProgress: false,
            GenderPronouns: Array.from(GenderPronoun.enumWithDescriptions.values()),
            phoneNumberTypeOptionsConfig: [
                PhoneTypeEnum.Home,
                PhoneTypeEnum.Mobile,
                PhoneTypeEnum.Facility,
                PhoneTypeEnum.Work
            ] as PhoneTypeEnum[],
            tags: [] as ITypeaheadOptions[],
            selectedTags: [] as ITypeaheadOptions[],
            muncipalityPayRates: {} as any,
            branches: null as IBranchResponse[],
            dialogRef: null,
            _branchesService: null,
            _centralIntakeService: null,
            _referralService: null,
            _toastrService: null,
            GendersEnum: Gender,
            validateNumberTouch: [false, false, false],
            MaritalStatusesEnum: MaritalStatuses,
            formatDateInput,
            enumToMap,
            referralAddressRef: null,
            v$: this.v as any
        };
    },
    computed: {
        formattedDob() {
            return formatDateInput(this.referral.dob);
        },
        genderPronounOptions() {
            return this.GenderPronouns?.map((option) => ({
                name: option.name,
                value: option.value
            }));
        }
    },
    created() {
        this._branchesService = Container.instance.get(BranchesService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._referralService = Container.instance.get(ReferralService);
        this._toastrService = Container.instance.get(ToastrService);
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },
    async mounted() {
        this.referralAddressRef = this.$refs.address;
        await this.initOptions();
        this.initFields();
    },
    watch: {
        async selectedTags() {
            if (!!this.selectedTags && !!this.referral) {
                const referralDetails = {
                    agencyId: this.referral.accountId,
                    referralId: this.referral.id,
                    application: this.referral.application
                };
                if (this.referral.tags) {
                    const prevTagsArr = this.referral.tags.split(",");
                    if (prevTagsArr.length > this.selectedTags.length) {
                        const removedTag = prevTagsArr.filter(
                            (item: any) => !this.selectedTags.map((tag) => tag.name).includes(item)
                        );
                        await this._referralService.deleteHCReferralTags({
                            tagName: removedTag[0],
                            ...referralDetails
                        });
                    } else if (prevTagsArr.length < this.selectedTags.length) {
                        const addedTag = this.selectedTags.filter((tag) => !prevTagsArr.includes(tag.name));
                        await this._referralService.addHCReferralTags({
                            tagName: addedTag[0].name,
                            ...referralDetails
                        });
                    }
                } else {
                    await this._referralService.addHCReferralTags({
                        tagName: this.selectedTags[0].name,
                        ...referralDetails
                    });
                }

                this.referral.tags = this.selectedTags.map((tag) => tag.name).join(",");
            }
        }
    },
    methods: {
        updateAddressDetails(details: Address) {
            let address = this.referral.primaryAddress;
            address.addressLine1 = details.addressLine1;
            address.addressLine2 = details.addressLine2;
            address.city = details.city;
            address.state = details.state;
            address.zipCode = details.zipCode;
            address.county = details.county;
            address.country = details.country;
            address.isValidated = details.isValidated;
            address.latitude = details.latitude;
            address.longitude = details.longitude;
        },
        updateAddressCountry(country: string) {
            this.referral.primaryAddress.country = country;
        },
        updateAddressState(state: string) {
            this.referral.primaryAddress.state = state;
        },
        selectedItemsUpdated(value: any) {
            if (this.referral.genderPronounsArray.includes(value)) {
                const index = this.referral.genderPronounsArray.indexOf(value);
                this.referral.genderPronounsArray.splice(index, 1);
                return;
            }
            this.referral.genderPronounsArray.push(value);
        },
        updateTags(value: any) {
            this.selectedTags = value;
        },
        changeDate(date: any, name: string) {
            if (name == "referralHomeCareDOB") this.referral.dob = date;
        },
        async initOptions() {
            const branches = await this._branchesService.getAllBranches();
            this.branches = branches.filter((branch: IBranchResponse) => branch.accountId == this.referral.accountId);
            const muncipalityPayRatesResult = await this.getMunicipalPayRates();
            this.setmuncipalityPayRates(muncipalityPayRatesResult);
            let referralTags = await this._referralService.getHCReferralTags({
                agencyId: this.referral.accountId,
                application: this.referral.application
            });
            this.tags = referralTags.map((tag: any, index: any) => ({ name: tag.name, value: String(index) }));
        },
        async getMunicipalPayRates() {
            const result = await this._centralIntakeService.getMunicipalityPayRate({
                application: this.referral.application,
                agencyId: this.referral.accountId
            });
            return result;
        },
        async checkClientConflict() {
            if (this.referral.firstName && this.referral.lastName && this.referral.dob) {
                {
                    const clientConflictStatus = await this._centralIntakeService.checkClientConflict({
                        application: this.referral.application,
                        agencyId: this.referral.accountId,
                        firstName: this.referral.firstName,
                        lastName: this.referral.lastName,
                        dateOfBirth: this.referral.dob,
                        id: this.referral.patientId
                    });
                    if (clientConflictStatus == null) {
                        this.hasNoConflict = true;
                        this._toastrService.info({
                            message: `There is no conflict!`
                        });
                    } else {
                        this.hasNoConflict = false;
                        this.dialogRef = this.$dialog.open(conflictPrompt, {
                            props: {
                                modal: true,
                                contentStyle: {
                                    width: "350px",
                                    overflowX: "hidden"
                                },
                                showHeader: false
                            },
                            data: {
                                clientConflictStatus
                            },
                            onClose: (response) => {
                                if (response.data.isChecked) {
                                    this.referral.isPatientConflictIgnored = response.data.isChecked;
                                }
                            }
                        });
                    }
                }
            }
        },
        setmuncipalityPayRates(municipalities: IGetMunicipalityPayRates[]) {
            const payRates: { [branchId: string]: IGetMunicipalityPayRates[] } = {};
            this.branches.forEach((branch) => {
                const items: IGetMunicipalityPayRates[] = [];
                municipalities.forEach((muncipality: IGetMunicipalityPayRates) => {
                    if (muncipality.Branches.includes(branch.id)) {
                        items.push(muncipality);
                    }
                });
                payRates[branch.id] = items;
            });
            this.muncipalityPayRates = payRates;
        },
        addAlternateContact(index: number) {
            let contact: IPhoneNumber = {
                number: "" as string,
                extension: "",
                type: index == 0 ? 2 : 4,
                country: "840",
                isPrimary: false,
                ordinal: index + 2,
                ordinalNumber: index + 2
            };
            this.referral.phoneNumbers.push(contact);
        },
        showAddAlternatebtn(index: number) {
            if (index == 0 && this.referral.phoneNumbers.length == 1) {
                return true;
            }
            if (index == 1 && this.referral.phoneNumbers.length == 2) {
                return true;
            }
            return false;
        },
        removeAlternateContact(index: number) {
            if (index == 1) {
                this.referral.phoneNumbers.splice(index, 1);
                (this.referral.phoneNumbers[index].ordinal = 2), (this.referral.phoneNumbers[index].ordinalNumber = 2);
            } else {
                this.referral.phoneNumbers.pop();
            }
        },
        resetIsNoConflictValue() {
            this.hasNoConflict = false;
        },
        initFields() {
            if (!!this.referral) {
                if (this.referral.tags) {
                    const arr = this.referral.tags.split(",");
                    this.selectedTags = this.tags.filter((tag) => {
                        return arr.some((item: any) => {
                            return tag.name == item;
                        });
                    }) as any;
                }
            }
        },

        async validateAddress() {
            let model: IValidateAddress = {
                originalAddress: {
                    addressLine1: this.referral.primaryAddress.addressLine1,
                    addressLine2: this.referral.primaryAddress.addressLine2,
                    city: this.referral.primaryAddress.city,
                    state: this.referral.primaryAddress.state,
                    zipCode: this.referral.primaryAddress.zipCode,
                    county: this.referral.primaryAddress.county,
                    country: this.referral.primaryAddress.country,
                    latitude: this.referral.primaryAddress.latitude,
                    longitude: this.referral.primaryAddress.longitude
                },
                updatedAddress: {
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    zipCode: "",
                    county: "",
                    country: "",
                    latitude: null,
                    longitude: null
                }
            };
            this.isAddressValidationInProgress = true;
            this.dialogRef = this.$dialog.open(ValidateAddress, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    model
                },
                onClose: (response) => {
                    let closeResult = response.data;
                    let updatedAddress: IAddress;
                    if (response.data !== "cancelled") {
                        updatedAddress = closeResult.updatedAddress;
                        this.referral.primaryAddress.addressLine1 = updatedAddress.addressLine1;
                        this.referral.primaryAddress.addressLine2 = updatedAddress.addressLine2;
                        this.referral.primaryAddress.city = updatedAddress.city;
                        this.referral.primaryAddress.state = updatedAddress.state;
                        this.referral.primaryAddress.zipCode = updatedAddress.zipCode;
                        this.referral.primaryAddress.county = updatedAddress.county;
                        this.referral.primaryAddress.latitude = updatedAddress.latitude;
                        this.referral.primaryAddress.longitude = updatedAddress.longitude;
                        this.referral.primaryAddress.isValidated = true;
                        this.referral.primaryAddress.isBypassed = false;
                        if (updatedAddress.country) {
                            this.referral.primaryAddress.country = updatedAddress.country;
                        }
                    } else {
                        this.referral.primaryAddress.isValidated = false;
                        this.referral.primaryAddress.isBypassed = true;
                    }
                }
            });

            this.isAddressValidationInProgress = false;
        }
    }
});
