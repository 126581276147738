import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HospiceReferral } from "../../../../../models/hospice-referral";
import { PayerTypes, PatientPayorTypeEnum } from "../../../../../enums/enums";
import { EnumMap } from "../../../../../common/enum-map";
import { EnumsService } from "../../../../../services/enums.service";
import { CentralIntakeService } from "../../../../../services/central-intake.service";
import { ToastrService } from "../../../../../services/toastr.service";
import { PatientPayor } from "../../../../../resources-vue/vue-models/patient-payor";
import { IGetMedicareIdsQuery } from "../../../../../resources-vue/vue-interfaces/i-get-payor";
import { PAGE_FAIL_RESPONSE } from "../../../../../resources/elements/pagination";
import { IPageCallbackResult } from "../../../../../resources-vue/vue-interfaces/i-page-container";

import PayorForm from "./PayorForm/PayorForm.vue";
import PayorRow from "./PayorRow/PayorRow.vue";
import Pagination from "../../../../../resources-vue/vue-elements/Pagination/Pagination.vue";
import SortableTh from "../../../../../resources-vue/vue-custom-elements/SortableTh/SortableTh.vue";
import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";

export default defineComponent({
    components: {
        Pagination,
        SortableTh,
        PayorForm,
        ErrorMessageHandler,
        PayorRow
    },
    props: {
        providerId: {
            type: String,
            default: ""
        },
        referral: {
            type: Object || HospiceReferral
        },
        addNewPayer: {
            type: Function
        },
        getCurrentPatientPayors: {
            type: Function
        },
        getPatientPayors: {
            type: Function
        },
        updatePatientPayor: {
            type: Function
        },
        deletePatientPayor: {
            type: Function
        }
    },
    data() {
        return {
            _enumsService: null,
            _centralIntakeService: null,
            _toastrService: null,
            selectedType: PatientPayorTypeEnum.All,
            sortBy: "",
            sortOrder: "desc",
            payersPagination: null,
            isLoading: false,
            isError: false,
            page: null,
            payorTypeEnum: PayerTypes,
            payerSequenceCodeEnumMap: EnumMap,
            columns: 7,
            patientPayerOptions: Object.values(PatientPayorTypeEnum),
            newPatientPayer: null,
            isAddPayerRequested: false,
            isAddPayerInProgress: false,
            isCurrent: true,
            medicareIds: []
        };
    },

    created() {
        this._enumsService = Container.instance.get(EnumsService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    async mounted() {
        this.newPatientPayer = new PatientPayor({});
        try {
            const getMedicareIdQuery: IGetMedicareIdsQuery = {
                application: this.referral.application,
                agencyId: this.referral.accountId,
                providerIds: [this.providerId]
            };
            this.medicareIds = (await this._centralIntakeService.getMedicareIds(getMedicareIdQuery)).map(
                (med: any) => med.id
            );
        } catch (e) {
            console.error(e);
        }
        this.payersPagination = this.$refs.payersPagination;
        this.resetList();
    },

    computed: {
        loadingCount() {
            return this.page && this.page.items.length > 0 ? this.page.items.length : 3;
        },

        hasData() {
            return this.page && this.page.itemCount > 0;
        }
    },

    watch: {
        selectedType() {
            this.selectedTypeChanged();
        },
        sortBy() {
            this.sortChanged();
        },
        sortOrder() {
            this.sortChanged();
        }
    },

    methods: {
        async pageChangedCallback(pageNumber: number, pageSize: number): Promise<IPageCallbackResult> {
            try {
                this.isLoading = true;
                let data;
                let query = {
                    page: pageNumber,
                    pageLength: pageSize,
                    sortBy: this.sortBy,
                    order: this.sortOrder,
                    application: this.referral.application,
                    accountId: this.referral.accountId,
                    agencyId: this.referral.accountId
                };
                if (this.isCurrent) {
                    data = await this.getCurrentPatientPayors({ query });
                } else {
                    data = await this.getPatientPayors({ query: { ...query, payorTypes: this.getQueryPayerTypes() } });
                }
                this.page = data;
                if (!this.page.items) {
                    this.page.items = [];
                }
                this.isLoading = false;
                return {
                    totalPageCount: data.pageCount,
                    success: data.itemCount > 0,
                    totalCount: data.itemCount
                };
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return PAGE_FAIL_RESPONSE;
            }
        },
        setIsCurrent(value: boolean) {
            this.isCurrent = value;
            this.resetList();
        },
        openAddPayer() {
            this.isAddPayerRequested = true;
        },
        async saveNewPayer() {
            const validated = await (this.$refs.payerForm as any).v$.$validate();
            if (validated) {
                try {
                    this.isAddPayerInProgress = true;
                    this.newPatientPayer.accountId = this.referral.accountId;
                    this.newPatientPayer.agencyId = this.referral.accountId;
                    this.newPatientPayer.application = Number(this.referral.application);
                    const response = await this.addNewPayer(this.newPatientPayer);
                    if (response.isSuccess) {
                        this._toastrService.success({
                            title: `Payer Added`,
                            message: `The payer ${this.newPatientPayer.agencyPayorName} has been successfully added.`
                        });
                        this.closeAddPayer();
                        await this.resetList();
                    } else {
                        this._toastrService.error({
                            title: `Failed to add Payer`,
                            message: `${JSON.parse(response.message).message}`
                        });
                    }
                } catch (e) {
                    this.openAddPayer();
                } finally {
                    this.isAddPayerInProgress = false;
                }
            }
        },
        closeAddPayer() {
            this.isAddPayerRequested = false;
            this.newPatientPayer = new PatientPayor({});
        },
        selectedTypeChanged() {
            this.resetList();
        },
        async sortChanged() {
            if (this.payersPagination && this.hasData) {
                await this.payersPagination?.reset();
            }
        },
        resetList() {
            this.payersPagination?.reset();
        },
        getQueryPayerTypes() {
            let primaryPayerValue = this.payorTypeEnum.getValueUsingKey("Primary") as number;
            let secondaryPayerValue = this.payorTypeEnum.getValueUsingKey("Secondary") as number;
            let tertiaryPayerValue = this.payorTypeEnum.getValueUsingKey("Tertiary") as number;
            let roomAndBoardPayerValue = this.payorTypeEnum.getValueUsingKey("RoomAndBoard") as number;
            let palliativePayerValue = this.payorTypeEnum.getValueUsingKey("PalliativeCare") as number;
            let vbidPayerValue = this.payorTypeEnum.getValueUsingKey("VBID") as number;
            switch (this.selectedType) {
                case PatientPayorTypeEnum.Primary:
                    return [primaryPayerValue];
                case PatientPayorTypeEnum.Secondary:
                    return [secondaryPayerValue];
                case PatientPayorTypeEnum.Tertiary:
                    return [tertiaryPayerValue];
                case PatientPayorTypeEnum.RoomAndBoard:
                    return [roomAndBoardPayerValue];
                case PatientPayorTypeEnum.PalliativeCare:
                    return [palliativePayerValue];
                case PatientPayorTypeEnum.Vbid:
                    return [vbidPayerValue];
                case PatientPayorTypeEnum.All:
                    return [
                        primaryPayerValue,
                        secondaryPayerValue,
                        tertiaryPayerValue,
                        roomAndBoardPayerValue,
                        palliativePayerValue,
                        vbidPayerValue
                    ];
                default:
                    return [];
            }
        },
        sortByUpdated(value: string) {
            if (value != this.sortBy) {
                this.sortBy = value;
                this.resetList();
            }
        },
        sortOrderUpdated(value: string) {
            if (value != this.sortOrder) {
                this.sortOrder = value;
                this.resetList();
            }
        }
    }
});
