<template>
    <div :class="`mb-2 mt-2`">
        <select class="form-control" v-model="selectedTemplate">
            <option selected :value="null">Select Template</option>
            <option v-for="template in options" :value="template" :key="template.id">{{ template.text }}</option>
        </select>
    </div>
    <div class="form-group">
        <p :class="`mb-0 ${maxLength - result?.length < 0 ? 'text-danger' : ''}`" v-if="maxLength !== maxCharLimit">
            You have {{ maxLength - result?.length < 0 ? 0 : maxLength - result?.length }} remaining characters.
        </p>
        <textarea :class="`form-control`" rows="4" v-model="description" :maxlength="maxLength"></textarea>
        <div class="validation-error" v-if="hasErrors">
            <p class="text-danger">
                <strong>{{ shortErrorMessage }}</strong>
            </p>
        </div>
    </div>
</template>

<script src="./TemplateInput.ts"></script>
