export const paymentOptions=[
    {
        label: "None; no charge for current services",
        value: "0"
    },
    {
        label: "Medicare (traditional fee-for-service)",
        value: "1"
    },
    {
        label: "Medicare (HMO/Managed Care)",
        value: "2"
    },
    {
        label: "Medicaid (traditional fee-for-service)",
        value: "3"
    },
    {
        label: "Medicaid (HMO/Managed Care)",
        value: "4"
    },
    {
        label: "Workers' Compensation",
        value: "5"
    },
    {
        label: "Title Programs (e.g., Title III, V, or XX)",
        value: "6"
    },
    {
        label: "Other government (e.g.,CHAMPUS,VA,etc)",
        value: "7"
    },
    {
        label: "Private insurance",
        value: "8"
    },
    {
        label: "Private HMO/ managed care",
        value: "9"
    },
    {
        label: "Self-pay",
        value: "10"
    },
    {
        label: "Unknown",
        value: "11"
    },
    {
        label: "Other (Specify)",
        value: "12"
    }
];