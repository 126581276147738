import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, helpers, requiredIf, minValue } from "@vuelidate/validators";
import { mapState } from "pinia";
import moment from "moment";

import { enumToMap } from "../../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { AdmissionSources, AdmissionTypes, PhoneTypeEnum } from "../../../../../../enums/enums";
import { PriorHospice } from "../../../../../../resources-vue/vue-models/prior-hospice";
import { useCreateReferralHosPal } from "../../../store/useCreateReferralHosPal";

import FormSection from "../../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import HospiceTransferForm from "../HospiceTransferForm/HospiceTransferForm.vue";

export default defineComponent({
    components: {
        FormSection,
        HospiceTransferForm
    },

    props: {
        isReadOnly: {
            type: Boolean
        },
        showBenefitPeriodManager: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            benefitPeriodStartDate: "",
            homePhoneNumberOptions: [PhoneTypeEnum.Home],
            hasViewBenefitPeriodPermission: false,
            AdmissionSources: AdmissionSources,
            AdmissionTypes: AdmissionTypes,
            enumToMap,
            v$: useVuelidate()
        };
    },
    validations() {
        return {
            patient: {
                admissionType: { minValue: minValue(1) },
                admissionSource: { minValue: minValue(1) },
                benefitPeriodNumber: {
                    required: helpers.withMessage(
                        "Benefit Period Number is required.",
                        requiredIf(() => {
                            return !this.patient.isTransfer;
                        })
                    ),
                    maxLength: helpers.withMessage("Benefit Period Number is invalid.", maxLength(2))
                }
            }
        };
    },
    mounted() {
        this.hasViewBenefitPeriodPermission = true;
        if (!!this.patient && !!this.patient.priorHospice) {
            this.benefitPeriodStartDate = this.patient.priorHospice.benefitPeriodStartDate;
        }
    },
    computed: {
        benefitPeriodEndDate() {
            let result;
            if (this.benefitPeriodStartDate && this.patient && Number(this.patient.benefitPeriodNumber) > 0) {
                let endDate = moment(this.benefitPeriodStartDate).add(59, "days");
                if (Number(this.patient.benefitPeriodNumber) <= 2) {
                    endDate.add(30, "days");
                }
                result = endDate.format("MM/DD/YYYY");
                this.patient.priorHospice.benefitPeriodEndDate = result;
            }
            return result;
        },
        ...mapState(useCreateReferralHosPal, { patient: "referral" })
    },
    methods: {
        handleAdmissionSourceTypeSelection() {
            this.patient.isTransfer = !this.patient.isTransfer;
            if (!this.patient.isTransfer) {
                this.patient.priorHospice = new PriorHospice();
                this.benefitPeriodStartDate = this.patient.priorHospice.benefitPeriodStartDate;
            }
        },
        handleBenfitPeriodStartDate(date: any, name: any) {
            if (name === "benefitPeriodStartDate") {
                this.benefitPeriodStartDate = date;
                this.patient.priorHospice.benefitPeriodStartDate = this.benefitPeriodStartDate;
            }
        }
    }
});
