import { DialogController } from "aurelia-dialog";
import { autoinject, PLATFORM } from "aurelia-framework";

import "./survey-modal.scss";

PLATFORM.moduleName("./survey-modal");
@autoinject()
export class SurveyModal {
    public dialogController: DialogController;

    public constructor(dialogController: DialogController) {
        this.dialogController = dialogController;
    }
}
