import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import { UserManager, User } from 'oidc-client';
import { autoinject } from "aurelia-framework";

@autoinject()
export class SignalRService {
    private connections: { [key: string]: HubConnection } = {};
    private user: User | null = null;

    public constructor(private userManager: UserManager) {}

    public async addHub(hubName: string, hubUrl: string): Promise<void> {
        this.user = await this.userManager.getUser();

        const connection = new HubConnectionBuilder()
            .withUrl(hubUrl, {
                accessTokenFactory: async () => {
                    if (this.user !== null) {
                        return this.user.access_token;
                    } else {
                        console.log("SignalR: User is not logged in, no Authorization set.");
                        return null;
                    }
                },
                headers: {
                    "X-Chat-User":  this.user.profile.sub
                },
                transport: HttpTransportType.WebSockets,
                logger: LogLevel.Information
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        connection.onreconnected(() => {
            console.log(`${hubName} reconnected`);
        });

        connection.onreconnecting((error) => {
            console.log(`${hubName} reconnecting`, error);
        });

        connection.onclose(() => {
            console.log(`${hubName} connection closed`);
        });

        this.connections[hubName] = connection;
    }

    public async startHub(hubName: string): Promise<void> {
        const connection = this.connections[hubName];
        if (!connection) {
            throw new Error(`Hub with name ${hubName} does not exist`);
        }

        try {
            await connection.start();
            console.log(`${hubName} is now connected with id: ${connection.connectionId}`);
        } catch (err) {
            console.log(`Error while establishing connection to ${hubName}: `, err);
            setTimeout(() => this.startHub(hubName), 5000);
        }
    }

    public stopHub(hubName: string): void {
        const connection = this.connections[hubName];
        if (connection) {
            connection.stop();
        }
    }

    public on(hubName: string, event: string, callback: (...args: any[]) => void): void {
        const connection = this.connections[hubName];
        if (connection) {
            connection.on(event, callback);
        }
    }

    public off(hubName: string, event: string, callback: (...args: any[]) => void): void {
        const connection = this.connections[hubName];
        if (connection) {
            connection.off(event, callback);
        }
    }

    public send(hubName: string, event: string, data: any): void {
        const connection = this.connections[hubName];
        if (connection) {
            connection.send(event, data);
        }
    }

    public async invoke(hubName: string, methodName: string, ...args: any[]): Promise<any> {
        const connection = this.connections[hubName];
        if (!connection) {
            throw new Error(`Hub with name ${hubName} does not exist`);
        }
        try {
            const result = await connection.invoke(methodName, ...args);
            return result;
        } catch (err) {
            console.error(`Error invoking ${methodName} on ${hubName}: `, err);
            throw err;
        }
    }
}
