
import { defineComponent } from "vue";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";

export default defineComponent({
    components: {
        FormSection,
        CommentInput
    },

    props: {
        referral: { type: Object, default: null }
    },
    data() {
        return {
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string }
        };
    },
    methods: {
        resultUpdatedAppended(value: string) {
            this.referral.comments += value;
        },
        resultUpdated(value: string) {
            this.referral.comments = value;
        }
    }
});
