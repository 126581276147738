import { defineComponent, PropType } from "vue";
import { Container } from "aurelia-dependency-injection";

import { EmergencyContact } from "../../../../../../resources-vue/vue-models/homecare-emergency-contact";
import type { IAddress } from "../../../../../../resources-vue/vue-interfaces/i-address";
import type { IEnumResponse } from "../../../../../../resources-vue/vue-interfaces/i-enum";
import { HomeCareReferral } from "../../../../../../resources-vue/vue-models/homecare-referral";
import { ReferralService } from "../../../../../../services/referral-service";
import {
    IApplicationAndAccountQueryParam,
    IdeleteEmergencyContact
} from "../../../../../../resources-vue/vue-interfaces/i-get-referral";

import ErrorMessageHandler from "../../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import EmergencyContactsRow from "../EmergencyContactsRow/EmergencyContactsRow.vue";

export default defineComponent({
    components: {
        ErrorMessageHandler,
        EmergencyContactsRow
    },
    props: {
        patientId: { type: String, default: null },
        isReOrderRequested: { type: Boolean, default: false },
        isPalliative: { type: Boolean, default: false },
        isEditing: { type: Boolean, default: false },
        totalContacts: { type: Number, default: null },
        referral: { type: HomeCareReferral, default: null },
        patientPrimaryAddress: { type: Object as PropType<IAddress>, default: [] },
        relationshipsEnum: { type: Array as PropType<IEnumResponse[]>, default: [] },
        contactRolesEnum: { type: Array as PropType<IEnumResponse[]>, default: [] },
        cahpsSurveyContactEnum: { type: Array as PropType<IEnumResponse[]>, default: [] }
    },
    data() {
        return {
            isLoading: false,
            isError: false,
            loadingCount: 3,
            columns: 9,
            contacts: [] as EmergencyContact[],
            _referralService: null
        };
    },
    computed: {
        hasData() {
            return this.contacts?.length > 0;
        }
    },
    mounted() {
        this._referralService = Container.instance.get(ReferralService);
        this.getContacts();
    },
    emits: ["updateIsEditing", "update:totalContacts"],
    methods: {
        async getContacts() {
            try {
                this.isLoading = true;
                const payload: IApplicationAndAccountQueryParam = {
                    application: this.referral.application,
                    agencyId: this.referral.accountId
                };
                this.contacts = await this._referralService.getHCEmergencyContacts(this.patientId, payload);
                this.$emit("update:totalContacts", this.contacts?.length);
            } catch (e) {
                console.error(e);
                this.isError = true;
            } finally {
                this.isLoading = false;
            }
        },
        moveUp(currentPosition: number) {
            let newPosition = currentPosition - 1;
            this.moveContact(currentPosition, newPosition);
        },
        moveDown(currentPosition: number) {
            let newPosition = currentPosition + 1;
            this.moveContact(currentPosition, newPosition);
        },
        moveContact(currentPosition: number, newPosition: number) {
            if (!!this.contacts) {
                if (currentPosition >= this.contacts.length || currentPosition < 0) {
                    return;
                }

                if (newPosition >= this.contacts.length || newPosition < 0) {
                    return;
                }

                this.contacts.splice(newPosition, 0, this.contacts.splice(currentPosition, 1)[0]);
                this.updateIndices();
            }
        },
        updateIndices() {
            this.contacts = this.contacts?.map((contact: EmergencyContact, index: number) => {
                contact.isPrimary = index === 0;
                contact.contactOrder = index;
                return contact;
            });
        },

        async updateEmergencyContact(contact: EmergencyContact) {
            contact.referralId = this.referral.id;
            contact.accountId = this.referral.accountId;
            contact.application = this.referral.application;
            contact.agencyId = this.referral.accountId;
            return await this._referralService.updateHCEmergencyContact(contact);
        },
        async deleteEmergencyContact(contactId: string) {
            const payload: IdeleteEmergencyContact = {
                id: contactId,
                application: this.referral.application,
                agencyId: this.referral.accountId,
                referralId: this.referral.id
            };
            await this._referralService.deleteHCEmergencyContact(payload);
        },
        async makeEmergencyContactPrimary(contactId: string) {
            const payload: IApplicationAndAccountQueryParam = {
                application: this.referral.application,
                agencyId: this.referral.accountId
            };
            const referralId: string = this.referral.id;
            await this._referralService.makeHCEmergencyContactPrimary(referralId, contactId, payload);
        },
        updateIsEditing(value: boolean) {
            this.$emit("updateIsEditing", value);
        }
    }
});
