export function timeout<T>(timeoutMs: number) {
    return new Promise<T>((_, reject) => setTimeout(() => reject(new CancellationException()), timeoutMs));
}

export class CancellationException extends Error {
    public constructor() {
        super("The promise timed-out while attempting to resolve, the promised has been rejected.");
        Object.setPrototypeOf(this, CancellationException.prototype);
    }
}
