import { defineComponent, PropType, computed } from "vue";
import * as $ from "jquery";
import turndownService from "turndown";
import { Container } from "aurelia-dependency-injection";

import { IToolbarCustomButtons } from "../../../vue-interfaces/messaging-service/i-summer-editor";
import { markdownToHtml } from "../../../../common/vue-helpers/modifiers/markdown-to-html-modifier";
import { sanitizeMarkup } from "./../../../../common/vue-helpers/modifiers/value-modifier";
import { IGetPatientByName, MessageService } from "../../../../services/message-service";

import SummerEditor from "../SummerEditor/SummerEditor.vue";
import SelectProfile from "../../../vue-dialogs/SelectProfile/SelectProfile.vue";

interface IFileOption {
    fileName: string;
    fileUrl: string;
    iconClass: string;
}

interface IDropDownOption {
    value: string;
    customLabel: string;
    subTitle: string;
    onClick: () => {};
}

export default defineComponent({
    components: {
        SummerEditor
    },
    props: {
        expand: { type: Boolean, default: false },
        files: { type: Array as PropType<File[]>, default: [] },
        showAttachmentLimitError: { type: Boolean, default: false },
        isImportant: { type: Boolean, default: false },
        toggleImportant: { type: Function, default: null },
        send: { type: Function, default: null },
        initialMessage: { type: String, default: "" },
        selectedConvId: { type: String, default: null }
    },
    data() {
        return {
            result: null as string,
            uploadFile: null as FileList,
            editorOptions: {},
            filesUI: null as any,
            expandFilePreview: false as boolean,
            filesPreview: [] as File[],
            customButtons: {} as IToolbarCustomButtons,
            _turndownService: new turndownService(),
            dialogRef: null,
            _messageService: null,
            isShowSelectProfile: false,
            selectedProfile: [],
            allSelectedProfiles: [],
            markdownToHtml,
            sanitizeMarkup
        };
    },
    watch: {
        uploadFile(newValue: FileList) {
            this.$emit("update:showAttachmentLimitError", newValue.length > 25);
            this.filesPreview = [...this.files, ...Array.from(newValue)];
            this.expandFilePreview = this.filesPreview.length > 0;
            this.$emit("update:files", this.filesPreview);
            this.$emit("update:expand", this.expandFilePreview);
        },
        expand(newValue: boolean) {
            this.expandFilePreview = newValue;
        },
        files: {
            async handler() {
                let filesUI: IFileOption[] = [];

                for (const file of this.filesPreview) {
                    let fileOption: IFileOption = {
                        fileName: "",
                        fileUrl: "",
                        iconClass: "fa-file"
                    };

                    if (!file.type.match("image.*")) {
                        fileOption.iconClass = this.getFileIcon(file.type);
                        fileOption.fileName = file.name;
                        filesUI.push(fileOption);
                    } else {
                        const fileUrl = await new Promise<string>((resolve) => {
                            const reader = new FileReader();
                            reader.onload = (e: any) => {
                                resolve(e.target.result);
                            };
                            reader.readAsDataURL(file);
                        });

                        fileOption.fileUrl = fileUrl;
                        fileOption.fileName = file.name;
                        filesUI.push(fileOption);
                    }
                }

                this.filesUI = filesUI;
            },
            deep: true
        },
        selectedConvId() {
            (this.$refs.summerEditorRef as typeof SummerEditor).reset();
        }
    },

    created() {
        this.customButtons = {
            important: this.markAsImportantButton,
            attach: this.attachFileButton,
            shareProfile: this.shareProfile,
            send: this.sendMessageButton
        };
        this.editorOptions = {
            buttons: this.customButtons
        };
        this._messageService = Container.instance.get(MessageService);
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    methods: {
        getFileIcon(type: string) {
            if (type.includes("wordprocessing")) {
                return "fa-file-word";
            } else if (type.includes("spreadsheet")) {
                return "fa-file-excel";
            } else if (type.includes("pdf")) {
                return "fa-file-pdf";
            } else {
                return "fa-file";
            }
        },
        uploadFilesChanged(event: any) {
            this.uploadFile = event.target.files;
        },
        removeFile(fileName: string) {
            this.filesPreview = this.filesPreview.filter((file) => file.name !== fileName);
            this.expandFilePreview = this.filesPreview.length > 0;
            this.$emit("update:files", this.filesPreview);
            this.$emit("update:expand", this.expandFilePreview);
        },
        markAsImportantButton() {
            let ui = ($ as any).summernote.ui;

            // create button
            let button = ui.button({
                contents: `<i class="fas fa-exclamation"/> Mark as Important`,
                tooltip: "Mark as Important",
                click: () => {
                    this.toggleImportant();
                }
            });

            return button.render(); // return button as jquery object
        },
        attachFileButton() {
            let ui = ($ as any).summernote.ui;

            // create button
            let button = ui.button({
                contents: `<i class="fas fa-paperclip" />`,
                tooltip: "Click to Attach a File",
                click: () => {
                    this.triggerFileUpload();
                }
            });

            return button.render(); // return button as jquery object
        },
        shareProfile() {
            let ui = ($ as any).summernote.ui;

            let button = ui.buttonGroup([
                ui.button({
                    className: "dropdown-toggle",
                    contents: '<i class="share-user" />',
                    tooltip: "Click to Share a Profile",
                    data: { toggle: "dropdown" }
                }),
                ui.dropdown({
                    className: "dropdown-variable",
                    items: [
                        {
                            value: "Employee Profile",
                            customLabel: "employee",
                            subTitle: "Share Contact",
                            onClick: this.employeeList
                        },
                        {
                            value: "Patient Profile",
                            customLabel: "patient",
                            subTitle: "Share Contact",
                            onClick: this.patientList
                        }
                    ],
                    template: (item: IDropDownOption) => {
                        return `
                            <div class="cursor-pointer" data-custom-label="${item.customLabel}">
                                <p class="cursor-pointer mb-0 weight-300">${item.value}<i class="fa fa-share pl-2"></i></p>
                                <p class="mb-0 grey-secondary-text">${item.subTitle}</p>
                            </div>
                        `;
                    },
                    callback: (items: any) => {
                        const self = this;
                        $(items)
                            .find("[data-custom-label]")
                            .each(function () {
                                let customLabel = $(this).data("custom-label");
                                if (customLabel === "employee") {
                                    $(this).on("click", self.employeeList);
                                } else if (customLabel === "patient") {
                                    $(this).on("click", self.patientList);
                                }
                            });
                    }
                })
            ]);
            return button.render();
        },
        triggerFileUpload() {
            $(this.$refs.uploadMarkupRef).trigger("click");
        },

        updateResult(newResult: string) {
            this.result = newResult;
        },

        updateSelectedProfile(newProfiles: any, allUpdatedProfiles: any) {
            this.selectedProfile = newProfiles;
            this.allSelectedProfiles = allUpdatedProfiles;
        },

        sendMessageButton() {
            let ui = ($ as any).summernote.ui;

            // create button
            let button = ui.button({
                contents: `<i class="far fa-paper-plane" id="Send__Message""/>`,
                tooltip: "Click to Send Message",
                click: () => {
                    this.sendMessage();
                }
            });

            return button.render(); // return button as jquery object
        },
        async sendMessage() {
            let params: {
                message?: string;
            } = {};
            if (!!this.result && this.result.length > 0) {
                let message = this.getMarkdownFromHtml(this.result);
                this.selectedProfile = [];
                this.allSelectedProfiles = [];
                (this.$refs.summerEditorRef as typeof SummerEditor).reset();
                if (!!message && message.length > 0) {
                    params.message = message;
                }
            }
            if (Object.keys(params).length > 0 || this.filesPreview.length > 0) {
                await this.send(params);
            }
        },
        getMarkdownFromHtml(html: string) {
            return this._turndownService.turndown(html);
        },

        employeeList() {
            this.isShowSelectProfile = false;
            this.dialogRef = this.$dialog.open(SelectProfile, {
                props: {
                    modal: true,
                    showHeader: false,
                    style: {
                        width: "400px",
                        height: "500px"
                    }
                },
                data: {
                    name: "employee"
                },
                onClose: (response: any) => {
                    if (response.data !== "cancel") {
                        this.selectedProfile = response.data;
                        this.allSelectedProfiles.push(...response.data);
                    }
                }
            });
        },
        patientList() {
            this.isShowSelectProfile = false;
            this.dialogRef = this.$dialog.open(SelectProfile, {
                props: {
                    modal: true,
                    showHeader: false,
                    style: {
                        width: "400px",
                        height: "505px"
                    }
                },

                data: {
                    name: "patient"
                },
                onClose: (response: any) => {
                    if (response.data !== "cancel") {
                        this.selectedProfile = response.data;
                        this.allSelectedProfiles.push(...response.data);
                    }
                }
            });
        },

        toggleSelectProfile() {
            this.isShowSelectProfile = !this.isShowSelectProfile;
        }
    }
});
