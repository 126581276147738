import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import { IGetLiveLoginResponse } from "../resources-vue/vue-interfaces/i-identity";

@autoinject()
export class IdentityService {
    private _httpClient: FetchClient;
    private _baseUrl: string = " /api/v1/identity";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getLiveLoginToken() {
        return await this._httpClient.build(`${this._baseUrl}`).fetch<IGetLiveLoginResponse>();
    }
}
