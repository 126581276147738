export class FuneralHome {
    public name: string = "";
    public address: string = "";
    public city: string = "";
    public state: string;
    public zipCode: string = "";
    public phoneNumber: string = "";
    public afterHrsPhoneNumber: string = "";
    [index: string]: string;
}
