import { defineComponent } from "vue";

export default defineComponent({
    props: {
        sortBy: { type: String, default: "" },
        sortOrder: { type: String, default: "" },
        sortString: { type: String, default: "" }
    },

    data() {
        return {
            _mouseupListener: null
        };
    },

    computed: {
        iconClass() {
            if (this.sortBy == this.sortString) {
                return this.sortOrder == "asc" ? "fa-sort-up" : "fa-sort-down";
            } else {
                return "fa-sort";
            }
        }
    },

    methods: {
        _handleMouseUp(e: Event) {
            if (this.sortBy == this.sortString) {
                this._toggleSortOrder();
            } else {
                this.$emit("sortByUpdated", this.sortString);
                this.$emit("sortOrderUpdated", "asc");
            }
        },

        _toggleSortOrder() {
            if (this.sortOrder == "asc") {
                this.$emit("sortOrderUpdated", "desc");
            } else {
                this.$emit("sortOrderUpdated", "asc");
            }
        }
    }
});
