import type { IDisplayDiagnosis } from "../vue-interfaces/i-diagnosis";
import type { IPhoneNumber } from "../vue-interfaces/i-phone-number";
import type { IGetPatientTag } from "../vue-interfaces/i-providers-service";
import { Address } from "./address";

export class Referral {
    public id: string;
    public patientId: string;
    // locationId is branchId
    public locationId: string = null;
    public providerId: string = null;
    /*------------------ Start of Referral Source --------------------------*/
    public lineOfService: number = 2;
    public sourceOfReferral: number = 2;
    public facilityReferralSourceId: string;
    public facilityReferralSourceContactName: string;
    public facilityReferralSourceName: string;
    public physicianReferralSourceId: string;
    public physicianReferralSourceContactName: string;
    public physicianReferralSourceName: string;
    public otherReferralSourceRelationship: string;
    public otherReferralSourceRelationshipDescription: string;
    public otherReferralSourceName: string;
    public communityLiaisonId: string;
    public communityLiaisonName: string;
    public internalReferralSourceId: string;
    public internalReferralSourceName: string;
    public internalReferralSource: number;
    public referralDate: string;
    public patientCurrentLocation: string;
    public comments: string = "";
    public sameAsAttendingPhysician: boolean;
    /*------------------ End of Referral Source --------------------------*/

    /*------------------ Start of Demographics --------------------------*/
    public firstName: string = "";
    public middleInitial: string = "";
    public lastName: string = "";
    public suffix: string = "";
    // enums default value should be 0 as they are non nullable
    public gender: number = null;
    public dateOfBirth: string = "";
    public socialSecurityNumber: string = "";
    public veteran: number[] = [];
    public maritalStatus: number = 0;
    public phoneNumbers: IPhoneNumber[] = [
        {
            number: "",
            extension: "",
            type: 0,
            country: null,
            isPrimary: true
        },
        {
            number: "",
            extension: "",
            type: 0,
            country: null,
            isPrimary: false
        }
    ];
    public email: string = null;
    public primaryAddress: Address = new Address();
    public visitAddress: Address = new Address();
    public mailingAddress: Address = new Address();
    public previousAddress: Address = new Address();
    public isMailingAddressSameAsPrimary: boolean = false;
    public isVisitAddressSameAsPrimary: boolean = true;
    public isPreviousAddress: boolean = false;
    public primaryAddressFacilityTypeId: string;
    public visitAddressFacilityTypeId: string;
    public mailingAddressFacilityTypeId: string;
    public previousAddressFacilityTypeId: string;
    public hasPreviousAddress: boolean = false;
    public ethnicity: number[] = [];
    public race: number[] = [];
    public ethnicityOnly: number = 0;
    public tagIds: string[] = [];
    public tags: IGetPatientTag[] = [];
    /*------------------ End of Demographics --------------------------*/

    /*------------------ Start of Payor Information --------------------------*/
    public paymentSource: number[] = [];
    public medicareBeneficiaryIdentification: string;
    public medicareBeneficiaryIdentificationNA: boolean = false;
    public medicaidNumber: string;
    public notAMedicaid: boolean = false;
    public medicaidPending: boolean = false;
    /*------------------ End of Payor Information --------------------------*/

    /*------------------ Start of Clinical Information --------------------------*/
    public attendingPhysicianName: string; // additional
    public attendingPhysicianId: string;
    public careTeamId: string;
    public referringDiagnosis: IDisplayDiagnosis;
    public diagnoses: IDisplayDiagnosis[] = [];
    public clinicalManagerId: string;
    public tolerablePainLevel: number;
    /*------------------ End of Clinical Information --------------------------*/

    /*------------------ Start of Referral Readiness --------------------------*/
    public serviceCurrentlyReceiving: number[] = [];
    public specifyService: string = "";
    public emergencyPreparednessPriority: number = null;
    public createdBy: string = "";
    public hasFamilyPortalAccess: boolean = false;
    public referralReason: string = "";
}
