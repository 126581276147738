import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum RecurrenceTypeEnum {
    None = "0" as any,
    Daily = "1" as any,
    Weekly = "2" as any,
    Monthly = "3" as any,
    Flexible = "4" as any,
    SixtyDays = "5" as any
}

export class RecurrenceTypeEnumClass extends EnumWithDescription<RecurrenceTypeEnum> {
    public readonly enum = RecurrenceTypeEnum;
    public readonly enumName: string = "RecurrenceType";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<RecurrenceTypeEnum>> = new Map([
        [
            "None",
            {
                value: RecurrenceTypeEnum.None,
                name: "None"
            }
        ],
        [
            "Daily",
            {
                value: RecurrenceTypeEnum.Daily,
                name: "Daily"
            }
        ],
        [
            "Weekly",
            {
                value: RecurrenceTypeEnum.Weekly,
                name: "Weekly"
            }
        ],
        [
            "Monthly",
            {
                value: RecurrenceTypeEnum.Monthly,
                name: "Monthly"
            }
        ],
        [
            "Flexible",
            {
                value: RecurrenceTypeEnum.Flexible,
                name: "Flexible"
            }
        ],
        [
            "SixtyDays",
            {
                value: RecurrenceTypeEnum.SixtyDays,
                name: "60 days"
            }
        ]
    ]);
}

export const RecurrenceType = new RecurrenceTypeEnumClass();
