import { autoinject } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";
import { UserManager, UserManagerSettings } from "oidc-client";
import config from "./config";
import Infrastructure from "./infrastructure";
import { OidcAuthorizeStep } from "./oidc/oidc-authorize-step";
import { OidcEventHandler } from "./oidc/oidc-event-handler";
const localGlobal = window as any;
const identityUrl = "https://identity.test.axxess.tech/";
const _authorityUrl: string =
    localGlobal._authorityUrl?.startsWith("#") || localGlobal._authorityUrl == null
        ? identityUrl
        : localGlobal._authorityUrl;

@autoinject
export class OidcConfiguration {
    private _oidcAuthorizeStep: OidcAuthorizeStep;
    private _oidcEventHandler: OidcEventHandler;
    private _userManager: UserManager;

    public constructor(oidcAuthorizeStep: OidcAuthorizeStep, oidcEventHandler: OidcEventHandler) {
        this._oidcAuthorizeStep = oidcAuthorizeStep;
        this._oidcEventHandler = oidcEventHandler;

        this._userManager = new UserManager(this.configureOidc());
    }

    public createUserManager() {
        this._oidcEventHandler.attach(this._userManager);
        return this._userManager;
    }

    private configureOidc(): UserManagerSettings {
        return {
            redirect_uri: this.getUrlFromLocation("/oidc/sign-in-redirect"),
            silent_redirect_uri: this.getUrlFromLocation("/oidc/sign-in-silent"),
            silentRequestTimeout: config.identityTimeOut,
            automaticSilentRenew: true,
            authority: _authorityUrl + ".well-known/openid-configuration",
            client_id: "Axxess-Router-Frontend",
            response_type: "id_token token",
            scope: "openid profile email",
            filterProtocolClaims: false,
            monitorSession: Infrastructure.isProdEnvironment
        };
    }

    public configureRouter(routerConfig: RouterConfiguration, router: Router) {
        routerConfig.addAuthorizeStep(this._oidcAuthorizeStep);
    }

    private getUrlFromLocation(subPath: string) {
        return window.location.protocol + "//" + window.location.host + subPath;
    }
}
