import { valueConverter } from "aurelia-framework";

import { ApplicationEnum } from "../../enums/enums";
import { ProductService } from "../../services/product.service";

@valueConverter("getApplicationId")
export class ProductConverter {
    public toView(id: ApplicationEnum): ApplicationEnum {
        let productService: ProductService = new ProductService();
        return productService.getApplicationId(id);
    }
}
