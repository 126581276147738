import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapActions, mapState } from "pinia";

import { AuthorizedContact } from "../../../../../resources-vue/vue-models/authorized-contact";
import { ToastrService } from "../../../../../services/toastr.service";
import { IContactsOrder, IContactsOrderQueryParam } from "../../../../../resources-vue/vue-interfaces/i-get-patient";
import {
    CAHPSSurveyMethod,
    ContactRoles,
    Relationships,
    HttpStatusCodeEnum,
    ApplicationEnum,
    CAHPSSurveyMethodEnum
} from "../../../../../enums/enums";
import { ReferralService } from "../../../../../services/referral-service";
import {
    IApplicationAndAccountQueryParam,
    IdeleteAuthorizedContact
} from "../../../../../resources-vue/vue-interfaces/i-get-referral";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import PatientAuthorizedContactsForm from "./PatientAuthorizedContactsForm/PatientAuthorizedContactsForm.vue";
import PatientAuthorizedContactsList from "./PatientAuthorizedContactsList/PatientAuthorizedContactsList.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection,
        PatientAuthorizedContactsForm,
        PatientAuthorizedContactsList
    },

    props: {
        patientPrimaryAddress: {
            type: Object,
            default: null
        },

        isPalliative: {
            type: Boolean,
            default: false
        },
        patientId: {
            type: String,
            default: ""
        },
        application: {
            type: Number
        },
        accountId: {
            type: String
        },
        referredContact: {
            type: Object,
            default: null
        }
    },

    data(vm) {
        return {
            _toastrService: null,
            _referralService: null,
            cahpsSurveyRecipientIndex: -1,
            totalContacts: 0,
            contactLimit: 10,
            showContactForm: false,
            isReOrderRequested: false,
            isLoading: false,
            isSavingReOrder: false,
            cachedContact: [],
            relationshipsEnum: Array.from(Relationships.enumWithDescriptions.values()),
            contactRolesEnum: Array.from(ContactRoles.enumWithDescriptions.values()),
            cahpsSurveyContactEnum: Array.from(CAHPSSurveyMethod.enumWithDescriptions.values()),
            contact: new AuthorizedContact()
        };
    },

    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
    },
    async mounted() {
        if (this.referredContact?.PrimaryEmergencyContact?.FirstName) {
            this.openAddContact();
            this.contact.firstName = this.referredContact.PrimaryEmergencyContact.FirstName;
            this.contact.lastName = this.referredContact.PrimaryEmergencyContact.LastName;
            this.contact.relationship = Number(this.referredContact.PrimaryEmergencyContact.RelationOption);
            this.contact.otherRelationshipDescription = this.referredContact.PrimaryEmergencyContact.Relationship;
            this.contact.isUsingPrimaryAddress = this.referredContact.PrimaryEmergencyContact.SameAsPatientAddress;
            this.contact.address.addressLine1 = this.referredContact.PrimaryEmergencyContact.Address.AddressLine1;
            this.contact.address.addressLine2 = this.referredContact.PrimaryEmergencyContact.Address.AddressLine2;
            this.contact.address.city = this.referredContact.PrimaryEmergencyContact.Address.City;
            this.contact.address.county = this.referredContact.PrimaryEmergencyContact.Address.County;
            this.contact.address.state = this.referredContact.PrimaryEmergencyContact.Address.State;
            this.contact.address.zipCode = this.referredContact.PrimaryEmergencyContact.Address.Zip;
            let phoneNumbersHH = [
                {
                    number: this.referredContact.PrimaryEmergencyContact.PrimaryPhone,
                    extension: "",
                    type: 0,
                    country: null as any,
                    isPrimary: true
                },
                {
                    number: this.referredContact.PrimaryEmergencyContact.AlternatePhone,
                    extension: "",
                    type: 0,
                    country: null as any,
                    isPrimary: false
                }
            ];
            this.contact.phoneNumbers = phoneNumbersHH;
            this.contact.email = this.referredContact.PrimaryEmergencyContact.EmailAddress;
            if (this.referredContact.PrimaryCahpsContact.IsPrimary) {
                this.contact.cahpsSurvey = CAHPSSurveyMethodEnum.ReceiveCAHPSSurvey;
            }
            this.contact.isEmergencyContact = true;
            this.contact.hasFamilyPortalAccess = this.referredContact.PrimaryEmergencyContact.FamilyPortalAccess;
            await this.addContact();
        }
    },
    computed: {
        isApplicationPalliative(): boolean {
            return this.application === ApplicationEnum.AxxessPalliative;
        }
    },

    methods: {
        openAddContact() {
            this.showContactForm = true;
            this.contact.contactOrder = this.totalContacts;
            this.contact.application = this.application;
        },
        closeAddContact() {
            this.isLoading = false;
            this.showContactForm = false;
            this.contact = new AuthorizedContact();
        },
        async addContact() {
            const validated = await (this.$refs.addContact as any).v$.$validate();
            if (this.isEmptyContact(this.contact)) {
                this._toastrService.info({
                    title: `Empty Contact`,
                    message: `Please add the necessary information to save the contact.`
                });
                return;
            }
            if (!validated) {
                return;
            }
            try {
                this.isLoading = true;
                this.contact = this.addressAndPhoneNumberCheck(this.contact);
                if (this.totalContacts === 0) {
                    this.contact.isPrimary = true;
                }
                this.contact.accountId = this.accountId;
                this.contact.agencyId = this.accountId;
                this.contact.application = this.application;
                const response = await this._referralService.addContact(this.patientId, this.contact);
                if (response.data.isSuccessful) {
                    this._toastrService.success({
                        title: "Success",
                        message: "Contact has been successfully added!"
                    });
                } else {
                    this._toastrService.error({
                        title: `Error`,
                        message: "There was a problem while adding a contact. Please try again."
                    });
                }
                this.closeAddContact();
                (this.$refs.contactList as any)?.getContacts?.();
            } catch (e) {
                console.error(e);
                this._toastrService.error({
                    title: `Error`,
                    message: "There was a problem while adding a contact. Please try again."
                });
            } finally {
                this.isLoading = false;
            }
        },
        requestReOrder() {
            this.cachedContact =
                (this.$refs.contactList as any)?.contacts.length > 0 ? [(this.$refs.contactList as any)?.contacts] : [];
            this.isReOrderRequested = true;
        },
        async confirmReOrder() {
            try {
                this.isSavingReOrder = true;
                let contactsOrder: IContactsOrder[] = (this.$refs.contactList as any)?.contacts.map(
                    (contact: AuthorizedContact) => {
                        return {
                            contactId: contact.id,
                            contactOrder: contact.contactOrder
                        };
                    }
                );
                await this.reorderContacts(contactsOrder);
                (this.$refs.contactList as any)?.getContacts();
            } catch (e) {
                if (e.status === HttpStatusCodeEnum.NotModified) {
                    this._toastrService.info({
                        title: "No Changes Detected",
                        message: "There were no changes made to the contacts order."
                    });
                } else {
                    console.error(e);
                    this._toastrService.error({
                        title: `Error`,
                        message: "There was a problem while re-ordering the contacts. Please try again."
                    });
                }
            } finally {
                this.isReOrderRequested = false;
                this.isSavingReOrder = false;
            }
        },
        deleteAuthorizedContact(contactId: string) {
            const payload: IdeleteAuthorizedContact = {
                id: contactId,
                application: this.application,
                agencyId: this.accountId
            };
            return this._referralService.deleteContact(payload);
        },
        updateAuthorizedContact(contact: AuthorizedContact) {
            contact.referralId = this.patientId as string;
            contact.accountId = this.accountId;
            contact.application = this.application;
            contact.agencyId = this.accountId;
            return this._referralService.updateContact(contact);
        },
        getAuthorizedContacts(id: string) {
            return this.getContacts(id);
        },
        cancelReOrder() {
            if (this.$refs.contactList) {
                (this.$refs.contactList as any).contacts = this.cachedContact;
            }
            (this.$refs.contactList as any)?.updateIndices();
            this.isReOrderRequested = false;
        },
        isEmptyContact(contact: AuthorizedContact) {
            let isContactEmpty = false;
            isContactEmpty = Object.entries(contact).every((contactProperty) => {
                let isEmpty = true;
                let checkNotRequired = ["id", "isPrimary", "contactOrder", "address"];
                if (!checkNotRequired.includes(contactProperty[0])) {
                    if (Array.isArray(contactProperty[1])) {
                        isEmpty = contactProperty[1].length === 0;
                    } else {
                        isEmpty = !contactProperty[1];
                    }
                } else if (contactProperty[0] === "address" && !contactProperty[1].addressLine1) {
                    isEmpty = true;
                }
                return isEmpty;
            });
            return isContactEmpty;
        },

        addressAndPhoneNumberCheck(contact: AuthorizedContact) {
            if (!contact?.address?.addressLine1) {
                contact.address = null;
            }
            if (!contact?.phoneNumbers[0]?.number && !contact?.phoneNumbers[1]?.number) {
                contact.phoneNumbers = [];
            }
            return contact;
        },
        reorderContacts(contactOrder: IContactsOrder[]) {
            const contactsOrderQuery: IContactsOrderQueryParam = {
                contactsOrder: contactOrder,
                application: this.application,
                agencyId: this.accountId,
                accountId: this.accountId
            };
            return this._referralService.reorderContacts(contactsOrderQuery);
        },
        getContacts(id: string) {
            const payload: IApplicationAndAccountQueryParam = {
                application: this.application,
                agencyId: this.accountId
            };
            return this._referralService.getContacts(id, payload);
        }
    }
});
