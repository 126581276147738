import { defineComponent } from "vue";

import type { ICheckClientConflictResponse } from "../../../resources-vue/vue-interfaces/i-get-referral";
import { PatientStatus } from "../../../enums/enums";
import { formatDate } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            options: {} as ICheckClientConflictResponse,
            PatientStatuses: PatientStatus,
            dialogReference: this.dialogRef as any,
            formatDate
        };
    },
    beforeMount() {
        this.options = this.dialogReference.data.clientConflictStatus;
    },
    methods: {
        closeDialog() {
            this.dialogReference.close(this.options);
        },
        ok() {
            this.dialogReference.close(this.options);
        }
    }
});
