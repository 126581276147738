import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { paymentOptions } from "./Constants";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    data(vm) {
        return {
            v$: useVuelidate(),
            paymentOptions
        };
    },

    validations() {
        return {
            referral: {
                Demographics: {
                    PaymentSources: { required }
                }
            }
        };
    },

    computed: {
        ...mapState(useEditReferralHH, { application: "currentApplication", referral: "referral", errors: "errors" })
    },

    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        handlePaymentSource(value: string) {
            if (this.referral.Demographics.PaymentSources.includes(value)) {
                this.referral.Demographics.PaymentSources = this.referral.Demographics.PaymentSources.filter(
                    (val: string) => val !== value
                );
                let temp = this.referral.Demographics.PaymentSource.split(";").filter((val: string) => val !== value);
                let temp2 = temp.join(";");
                this.referral.Demographics.PaymentSource = temp2;
            } else {
                this.referral.Demographics.PaymentSources.push(value);
                if (this.referral.Demographics.PaymentSource === null) {
                    this.referral.Demographics.PaymentSource = "";
                    this.referral.Demographics.PaymentSource = this.referral.Demographics.PaymentSource.concat(value);
                } else {
                    this.referral.Demographics.PaymentSource = this.referral.Demographics.PaymentSource.concat(
                        `;${value}`
                    );
                }
                if (
                    this.referral.Demographics.PaymentSources.length &&
                    this.referral.Demographics.PaymentSource.length
                ) {
                    const newErrors = [...new Set(this.errors.Demographics)].filter(
                        (val: any) => val !== "PaymentSources"
                    );
                    const payload = {
                        property: "Demographics",
                        value: newErrors
                    };
                    this.setErrors(payload);
                }
            }
        },
        showOption(option: any) {
            const paymentSources = this.referral.Demographics.PaymentSources;
            const noneChecked = paymentSources.indexOf("0") === -1 ? false : true;
            const unknownChecked = paymentSources.indexOf("11") === -1 ? false : true;
            if (!noneChecked && !unknownChecked) {
                return true;
            } else if ((noneChecked && option.value === "0") || (unknownChecked && option.value === "11")) {
                return true;
            } else {
                return false;
            }
        }
    }
});
