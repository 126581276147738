import "./tags-input.scss";

import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    observable
} from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";

import nameOf from "../../../common/name-of";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { TypeaheadInput } from "../typeahead-input/typeahead-input";
import type { ITypeaheadOptions } from "./../../../interfaces/i-typeahead";
import type { TypeaheadComponentData } from "./../../../interfaces/i-typeahead";

@autoinject
@containerless
@customElement("tags-input")
export class TagsInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public data: ITypeaheadOptions[] | TypeaheadComponentData;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public tags: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public closeOnSelection: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @observable({
        changeHandler: nameOf<TagsInput>("valueChanged")
    })
    public value: ITypeaheadOptions = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public openOnFocus: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public classes: string = "";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public placeholder: string = "Start Typing...";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public showTagsByValue: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showRemoveTag: boolean = true;
    // Use Slots to Control Input, Tags Component switches to Display only mode
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public displayOnly: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public resultsLimit: number = 30;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showPill: boolean = false;
    public typeaheadInputRef: TypeaheadInput;
    public tagsWrapperMarkupRef: HTMLElement;
    public tagsLength: number;

    @computedFrom(
        `${nameOf<TagsInput>("tags")}.length`,
        nameOf<TagsInput>("data"),
        `${nameOf<TagsInput>("data")}.length`
    )
    public get tagsData(): TypeaheadComponentData {
        let tagsValues = this.tags.map((item) => item?.value);
        if (Array.isArray(this.data)) {
            return this.data.filter((item: ITypeaheadOptions) => {
                return !tagsValues.find((value) => {
                    return value == item.value;
                });
            });
        } else {
            return this.data;
        }
    }

    // tslint:disable-next-line: no-empty
    public set tagsData(data: TypeaheadComponentData) {}

    public attached() {
        if (this.validation?.required) {
            this.initValidation();
        }
    }

    public valueChanged() {
        if (this.value) {
            this.addTag(this.value);
            this.typeaheadInputRef.clearFilter();
            this.typeaheadInputRef.resetFilter();
        }
    }

    private addTag(tag: ITypeaheadOptions) {
        if (!this.tags.find((item: ITypeaheadOptions) => item.value == tag.value)) {
            this.tags = this.tags.concat([this.value]);
            this.tagsLength = this.tags.length || null;
        }
    }

    public removeTag(tag: ITypeaheadOptions) {
        this.tags = this.tags.filter((item) => item.value !== tag.value);
        this.tagsLength = this.tags.length || null;
    }

    public handleWrapperClick(event: MouseEvent) {
        if ((event.target as Element).className.includes("tags-wrapper") && this.typeaheadInputRef) {
            this.typeaheadInputRef.focusInput();
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: TagsInput) => x.tags)
            .minItems(1)
            .withMessage(`${this.validation.message}`)
            .ensure((x: TagsInput) => x.tagsLength)
            .required()
            .withMessage(`${this.validation.message}`)
            .on(this);
    }
}
