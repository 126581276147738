import { defineComponent } from "vue";
import { mapState } from "pinia";

import { DmeNeeded } from "../../../../../enums/enums";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },
    props: {
        accountId: {
            type: String,
            default: ""
        },
        referralOptions: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            referralOptions: null,
            _referralService: null,
            dmeNeededEnum: DmeNeeded,
            enumToMap,
            servicesRequired: [],
            listOfService: [
                {
                    name: "SN",
                    value: "0",
                    fullForm: "Skilled Nursing"
                },
                {
                    name: "HHA",
                    value: "1",
                    fullForm: "Home Health Aide"
                },
                {
                    name: "PT",
                    value: "2",
                    fullForm: "Physical Therapy"
                },
                {
                    name: "OT",
                    value: "3",
                    fullForm: "Occupational Therapy"
                },
                {
                    name: "ST",
                    value: "4",
                    fullForm: "Speech Therapy"
                },
                {
                    name: "MSW",
                    value: "5",
                    fullForm: "Medical Social Work"
                }
            ]
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            application: "currentApplication",
            accountId: "accountId",
            referralOptions: "referralOptions",
            errors: "errors"
        })
    },
    mounted() {
        this.referral.Clinical.HeightMetric = 1;
        this.referral.Clinical.WeightMetric = 1;
    },

    methods: {
        selectedServices(event: any) {
            const value = event.target.value;

            if (this.referral.Clinical.ServicesRequiredCollection.includes(value)) {
                this.referral.Clinical.ServicesRequired = this.referral.Clinical.ServicesRequiredCollection.join(";");
                return;
            }
            this.referral.Clinical.ServicesRequired = this.referral.Clinical.ServicesRequiredCollection.join(";");
        },

        selectedDMEServices(event: any) {
            const value = event.target.value;

            if (this.referral.Clinical.DMECollection.includes(value)) {
                this.referral.Clinical.DME = this.referral.Clinical.DMECollection.join(";");
                return;
            }
            this.referral.Clinical.DME = this.referral.Clinical.DMECollection.join(";");
        },

        heightOnChange(event: any) {
            this.referral.Clinical.Height = Number(event.target.value);
        },

        weightOnChange(event: any) {
            this.referral.Clinical.Weight = Number(event.target.value);
        },

        onOtherDMEChange(event: any) {
            this.referral.Clinical.OtherDME = event.target.value;
        },

        medicationHistoryChange(event: any) {
            this.referral.Clinical.IsMedicationHistoryEnabled = event.target.checked;
        }
    }
});
