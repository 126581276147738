import { defineComponent } from "vue";

export interface IAlertOptions {
    title?: string;
    message: string;
    actionId?: string;
}

export default defineComponent({
    inject: ["dialogRef"],

    data() {
        return {
            dialogReference: this.dialogRef as any
        };
    },

    methods: {
        cancel() {
            this.dialogReference.close({ wasCancelled: true, remindMeLater: false });
        },
        ok() {
            this.dialogReference.close({ wasCancelled: false, remindMeLater: false });
        },
        remindMeLater() {
            this.dialogReference.close({ wasCancelled: true, remindMeLater: true });
        }
    }
});
