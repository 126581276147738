import { ApplicationEnum } from "../../enums/enums";
import { ValidationRules } from "aurelia-validation";
import { RecurrenceModel } from "./recurrence";
import { DateTimeModel } from "./date-time";

export interface IRecurrence {
    type: number;
    endDate: string;
    daysOfWeek: string;
    repeatBy: number;
    repeatNumber: number;
    flexibleDates: number;
}

export interface IDateTime {
    date: string;
    time: string;
}

export interface IHandleTaskReschuleIssue {
    [key: string]: "RejectAll" | "ScheduleAllAnyways" | "ScheduleOnlyValid";
}

export interface IScheduleVisitHCValidations {
    IsSuccessful: boolean;
    Message: string;
    ErrorMessage: string;
    ErrorDetails?: IAssignVisitErrorDetails;
}
interface IAssignVisitErrorDetails {
    HasAnyIssues: boolean;
    HasWeeklyRecurrenceDayOfWeekIssue: boolean;
    Plurality: number;
    AllTasksHaveErrors: boolean;
    WeeklyRecurrenceRequestedDaysOfWeek: number;
    WeeklyRecurrenceTemplateDayOfWeek: number;
    IsEmployeeTask: boolean;
    TaskIssues: IAssignVisitIssue[];
    HasEarlyExitExceptionalError: false;
}

interface IAssignVisitIssue {
    IssueType?: null;
    Title: string;
    Message?: string;
    Tooltip?: string;
    Action?: string;
    TaskIssueSurpressKey: string;
}

export class ScheduleVisitHomeCareModel {
    public agencyId: string;
    public application: ApplicationEnum;
    public userId: string;
    public episodeId: string;
    public patientId: string;
    public visitDates: string[];
    public startDateTime: DateTimeModel = new DateTimeModel();
    public endDateTime: DateTimeModel = new DateTimeModel();
    public customNoteId: string;
    public payorId: string;
    public disciplineTask: any;
    public locationId: string;
    public taskName: string;
    public isAllDay: boolean;
    public isEmployeeTask: boolean;
    public recurrence: RecurrenceModel = new RecurrenceModel();
    public comments: string;
    public lengthOfShift: number;
    public eventStartTime: string;
    public eventEndTime: string;
    public HandleTaskIssues?: IHandleTaskReschuleIssue;

    public constructor(data?: ScheduleVisitHomeCareModel) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    public initValidation() {
        ValidationRules.ensure((x: ScheduleVisitHomeCareModel) => x.startDateTime)
            .required()
            .withMessage("Start Time is required.")
            .ensure((x: ScheduleVisitHomeCareModel) => x.endDateTime)
            .required()
            .withMessage("End Time is required.")
            .ensure((x: ScheduleVisitHomeCareModel) => x.payorId)
            .required()
            .withMessage("Payment Source is required.")
            .ensure((x: ScheduleVisitHomeCareModel) => x.disciplineTask)
            .required()
            .withMessage("Task is required.")
            .on(this);
        this.recurrence.initValidation();
    }
}
