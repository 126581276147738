<template>
    <div>
        <div class="dashboard-header">
            <DashboardHeader />
        </div>
        <div class="clinician-level-two mt-2">
            <PlannerLevelTwo
                :router="router"
                :showIcons="true"
                v-model:show-patients="showPatients"
                v-model:show-employees="showEmployees"
            />
        </div>
        <router-view></router-view>
        <section v-if="showPatients || showEmployees">
            <Dialog
                v-model:visible="showEmployees"
                class="dashboard-dialog"
                position="bottomright"
                modal
                :style="{ height: '100vh' }"
                :show-header="false"
            >
                <div class="h-100">
                    <EmployeeList v-model:showEmployees="showEmployees" />
                </div>
            </Dialog>
            <Dialog
                v-model:visible="showPatients"
                class="dashboard-dialog"
                position="bottomright"
                modal
                :style="{ height: '100vh' }"
                :show-header="false"
            >
                <div class="h-100">
                    <PatientList v-model:showPatients="showPatients" />
                </div>
            </Dialog>
        </section>
    </div>
</template>

<style src="./AxxessHome.scss"></style>
<script src="./AxxessHome.ts"></script>
