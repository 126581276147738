import { IConversationNavOptions } from "../interfaces/messaging-service/i-message";
import { INotification } from "../interfaces/messaging-service/i-notification";

export enum NotificationsHubEvents {
    ReceiveMessage = "ReceiveMessage",
    LoadConversationMessages = "LoadConversationMessages",
    LoadNavbarNotifications = "LoadNavbarNotifications",
    LoadConversationList = "LoadConversationList"
}

export interface LoadNavbarNotificationsEvent {
    notification: INotification;
    conversationList: IConversationNavOptions[];
  }
