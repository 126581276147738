import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    data(vm) {
        return {
            advancedCareSelectOptions: [
                {
                    label: "Living Will",
                    value: "Living Will"
                },
                {
                    label: "DNR, Out of Hospital",
                    value: "DNR"
                },
                {
                    label: "Durable Power of Attorney for Health",
                    value: "Power of Attorney for Health"
                },
                {
                    label: "Physician Orders for Life-Sustaining Treatment (POLST)",
                    value: "POLST"
                },
                {
                    label: "Medical Orders for Life-Sustaining Treatment (MOLST)",
                    value: "MOLST"
                },
                {
                    label: "Other Legally Valid Documents Under State Law",
                    value: "Other Valid Documents"
                }
            ]
        };
    },

    computed: {
        ...mapState(useEditReferralHH, { application: "currentApplication", referral: "referral" })
    },
    methods: {
        handleCheckboxSelection(value: string) {
            if (this.referral.AdvancedDirectives.AdvancedDirectivesIntentsCollection.includes(value)) {
                this.referral.AdvancedDirectives.AdvancedDirectivesIntentsCollection =
                    this.referral.AdvancedDirectives.AdvancedDirectivesIntentsCollection.filter(
                        (val: string) => val !== value
                    );
            } else {
                this.referral.AdvancedDirectives.AdvancedDirectivesIntentsCollection.push(value);
            }
        }
    }
});
