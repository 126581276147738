import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { sanitizeMarkup } from "../../../common/vue-helpers/modifiers/value-modifier";
import { ProvidersService } from "../../../services/providers-service";
import { ToastrService } from "../../../services/toastr.service";

export default defineComponent({
    inject: ["dialogRef"],

    data(vm) {
        return {
            _toastrService: null,
            _providerService: null,
            dialogReference: this.dialogRef as any,
            tagName: null,
            tagDescription: null,
            tagsTooltip: `<strong>Title</strong>
                          <p>Creating and Managing Tags</p>
                          <hr>
                          <strong>Description</strong>
                          <p>Create tags to categorize patients and staff with specific characteristics. To create a tag, enter a name in the Name field. In the Text box, enter a description or keywords for the tag.
                          </p><hr>
                          <strong>Intent</strong>
                          <p>Once created, tags can be assigned to the appropriate patients and employees. An unlimited number of tags can be assigned to each patient/employee. For example, a patient can be assigned to the Team A tag and the Surgical Wound tag. If a user wants to view all patients in Team A with a surgical wound, the user can select Team A and Surgical Wound in the Tags filter to view patients who are assigned to both tags.
                          </p><hr>
                          <strong>Impact</strong>
                          <p>Tags enable users to easily locate patients and employees from anywhere that the Tags filter is available in the system (e.g., patient charts).</p>`,
            sanitizeMarkup,
            agencyId: "",
            userId: ""
        };
    },
    created() {
        this._providerService = Container.instance.get(ProvidersService);
        this._toastrService = Container.instance.get(ToastrService);
    },
    mounted() {
        this.userId = this.dialogReference.data.userId;
        this.agencyId = this.dialogReference.data.agencyId;
    },
    methods: {
        cancel() {
            this.dialogReference.close();
        },
        async ok() {
            try {
                const result = await this._providerService.addTag({
                    Name: this.tagName,
                    Description: this.tagDescription,
                    AgencyId: this.agencyId,
                    UserId: this.userId,
                    Category: 0
                });
                if (result.IsSuccessful) {
                    this._toastrService.success({
                        title: "Tag Added",
                        message: `${result.message}`
                    });
                }
                this.dialogReference.close("success");
            } catch (err: any) {
                console.log(err);
                this._toastrService.error({
                    title: "Failed adding tag",
                    message: `${err}`
                });
            }
        }
    }
});

export interface IPromptOptions {
    title?: string;
    message: string;
    additionalMessage?: string;
    okText?: string;
    cancelText?: string;
}
