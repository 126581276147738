import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { LookupService } from "../../../services/lookup-service";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";

import TypeaheadInput from "../TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        TypeaheadInput
    },
    props: {
        selectedState: {
            type: Object
        },
        updateSelectedState: {
            type: Function
        },
        prefillState: {
            type: String,
            default: ""
        },
        updateprefillState: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data(vm) {
        return {
            _lookupService: null,
            stateList: []
        };
    },
    created() {
        this._lookupService = Container.instance.get(LookupService);
    },
    async mounted() {
        await this.getStateList();
        if (this.prefillState) {
            let temp = this.stateList.find((state) => state.value === this.prefillState) as ITypeaheadOptions;
            this.$emit("update:selectedState", temp);
        }
    },
    watch: {
        prefillState(newValue: any) {
            let temp = { value: newValue, name: newValue };
            this.$emit("update:selectedState", temp);
        }
    },
    methods: {
        async getStateList() {
            if (this.stateList?.length) {
                return;
            }
            try {
                let stateList = await this._lookupService.getAmericanStateCodes();
                this.stateList = stateList.map((state: any) => ({ value: state.value, name: state.value }));
            } catch (e) {
                console.error(e);
            }
        },
        changeSelectedState(newValue: any) {
            this.$emit("update:selectedState", newValue);
        },
        emitBlur() {
            this.$emit("blurredInput");
        }
    }
});
