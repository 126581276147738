import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = {
  key: 1,
  id: "App__Header"
}
const _hoisted_4 = { id: "App__Main" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "h-100" }
const _hoisted_8 = { class: "h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNav = _resolveComponent("AppNav")!
  const _component_AgencyList = _resolveComponent("AgencyList")!
  const _component_CentralNav = _resolveComponent("CentralNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_EmployeeList = _resolveComponent("EmployeeList")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_PatientList = _resolveComponent("PatientList")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_DynamicDialog = _resolveComponent("DynamicDialog")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", { id: _ctx.appId }, [
      (_ctx.isLoggedIn && _ctx.showAside)
        ? (_openBlock(), _createElementBlock("aside", {
            key: 0,
            id: "App__Aside",
            class: _normalizeClass(`${_ctx.userOs == 'Windows' ? 'custom-scrollbar' : ''}`)
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_AppNav, { areRolesLoaded: _ctx.areRolesLoaded }, null, 8 /* PROPS */, ["areRolesLoaded"]),
              _createVNode(_component_AgencyList, {
                isLoading: _ctx.isLoading,
                groupedAgencies: _ctx.groupedAgencies,
                hasError: _ctx.hasError,
                onLoadAgencies: _ctx.loadAgencies
              }, null, 8 /* PROPS */, ["isLoading", "groupedAgencies", "hasError", "onLoadAgencies"])
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (_ctx.isLoggedIn && !_ctx.showAside)
        ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
            _createVNode(_component_CentralNav, {
              areRolesLoaded: _ctx.areRolesLoaded,
              showEmployees: _ctx.showEmployees,
              "onUpdate:showEmployees": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showEmployees) = $event)),
              showPatients: _ctx.showPatients,
              "onUpdate:showPatients": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPatients) = $event))
            }, null, 8 /* PROPS */, ["areRolesLoaded", "showEmployees", "showPatients"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("main", _hoisted_4, [
        (_ctx.startApp)
          ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
              _createVNode(_component_router_view),
              (_ctx.showPatients || _ctx.showEmployees)
                ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
                    _createVNode(_component_Dialog, {
                      visible: _ctx.showEmployees,
                      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showEmployees) = $event)),
                      class: "dashboard-dialog",
                      position: "bottomright",
                      modal: "",
                      "show-header": false,
                      style: { height: '100vh' }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_EmployeeList, {
                            showEmployees: _ctx.showEmployees,
                            "onUpdate:showEmployees": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showEmployees) = $event))
                          }, null, 8 /* PROPS */, ["showEmployees"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["visible"]),
                    _createVNode(_component_Dialog, {
                      visible: _ctx.showPatients,
                      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showPatients) = $event)),
                      class: "dashboard-dialog",
                      position: "bottomright",
                      modal: "",
                      "show-header": false,
                      style: { height: '100vh' }
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_PatientList, {
                            showPatients: _ctx.showPatients,
                            "onUpdate:showPatients": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showPatients) = $event))
                          }, null, 8 /* PROPS */, ["showPatients"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["visible"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ], 8 /* PROPS */, _hoisted_1),
    _createVNode(_component_LoadingIndicator),
    _createVNode(_component_DynamicDialog),
    _createVNode(_component_Notification)
  ], 64 /* STABLE_FRAGMENT */))
}