<template>
    <div class="dropdown grouped-multi-select" ref="dropDownElement">
        <button
            class="btn input-border bg-white dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="isDisabled"
        >
            <span>{{ getButtonText }}</span>
        </button>
        <div class="dropdown-menu" ref="dropDownMenu">
            <div class="input-group p-1">
                <input
                    type="text"
                    ref="searchInput"
                    class="form-control"
                    v-model="search"
                    :placeholder="getPlaceholderText"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <i class="fas fa-search"></i>
                    </div>
                </div>
            </div>
            <div class="dropdown-item p-0 pl-3 my-1" v-if="!search">
                <div class="form-check">
                    <label class="form-check-label">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            @change.prevent="toggleAllSelection()"
                            :checked="selectedOptions.length === getDropDownOptionsLength"
                        />
                        Select All
                    </label>
                </div>
            </div>
            <div class="dropdown-divider" v-if="!search"></div>
            <div ref="filterContainer" class="content">
                <div v-for="(groupName, index) of Object.keys(filteredOptions)">
                    <label
                        class="btn font-weight-bold d-flex justify-content-start align-items-start"
                        v-if="groupName && filteredOptions[groupName].length > 0"
                        :for="`group-${groupName}`"
                    >
                        <input
                            type="checkbox"
                            class="mt-1"
                            :id="`group-${groupName}`"
                            @change.prevent="toggleGroupSelected(groupName)"
                            :checked="allGroupElementsSelected(groupName)"
                        />
                        <span class="m-0 text-left ml-2">
                            {{ groupNameEnumMap.getEnumName(index + 1) }}
                        </span>
                    </label>
                    <div class="dropdown-item p-0 pl-4 my-1" v-for="option in filteredOptions[groupName]">
                        <div class="form-check ml-2">
                            <label class="form-check-label" :for="`option-${option}`">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :id="`option-${option}`"
                                    @change.prevent="toggleSingleSelection(option)"
                                    :checked="selectedOptions.includes(option)"
                                />
                                {{ optionEnumMap.getEnumName(option) }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dropdown-divider"></div>
            <div class="px-1">
                <button class="btn btn-outline-dark w-100" @click="closeDropDown()">Close</button>
            </div>
        </div>
    </div>
</template>

<style src="./GroupedMultiSelect.scss"></style>

<script src="./GroupedMultiSelect"></script>
