let isDev: boolean = document.domain === "localhost" && location.port === "8080";
let isStage: boolean =
    document.domain === "central.test.axxess.tech" || document.domain === "central.stage.axxess.tech";
const localGlobal = window as any;
const _hospiceUrl: string =
    localGlobal._hospiceUrl?.startsWith("#") || localGlobal._hospiceUrl == null
        ? "http://hospice.dev.axxess.tech"
        : localGlobal._hospiceUrl;
const _palliativeUrl: string =
    localGlobal._palliativeUrl?.startsWith("#") || localGlobal._palliativeUrl == null
        ? "http://palliativecare.dev.axxess.tech"
        : localGlobal._palliativeUrl;
const _nalaUrl: string =
    localGlobal._nalaUrl?.startsWith("#") || localGlobal._nalaUrl == null
        ? "http://nala.rcm.lan"
        : localGlobal._nalaUrl;
export default {
    googleMapsApiKey: "AIzaSyA9ZkE3uNo-75pnS-8YTKvKv7oGWB30GCo",
    hospiceUrl: _hospiceUrl, // isDev || isStage ? "http://hospice.test.axxess.tech" : "https://hospice.axxessweb.com",
    mushuUrl: /* isDev ? 'http://mushu.rcm.lan' :  */ "https://revenue.axxessweb.com",
    biApiUrl: "https://biapi.axxessweb.com",
    biUrl: "https://bi.axxessweb.com",
    certificationUrl: "https://certification.axxessweb.com",
    supportUrl: "https://www.axxess.com/support/",
    palliativeUrl: _palliativeUrl, // Info Purpose only: isDev || isStage ? "http://palliativecare.test.axxess.tech" : "https://palliativecare.axxessweb.com",
    nalaUrl: _nalaUrl,
    hospiceMarketingUrl: "http://www.axxess.com/hospice",
    covidMarketingUrl: "https://www.axxess.com/help/coronavirus-covid-19-resources/",
    weatherCacheKey: "axxess-user-weather",
    appCacheKey: "router-token",
    locationCacheKey: "axxess-user-location",
    defaultRoute: "help",
    isDev,
    isStage,
    hospiceMarketingLastDate: "12/05/2019",
    covidMarketingLastDate: "05/15/2020",
    linesOfServiceSurveyLastDate: "07/02/2020",
    locationSaveKey: "router-oidc-redirect",
    identityTimeOut: 3000,
    pageSize: 10,
    stateListCacheKey: "AxxessCentralStateListCacheKey",
    countryListCacheKey: "AxxessCentralCountryListCacheKey",
    countryPhoneCodeListCacheKey: "AxxessCentralCountryPhoneCodeListCacheKey",
    enumCacheKey: "AxxessCentralEnumCacheKey",
    hospiceAssetServerURL: "http://10.33.14.40:9085/",
    palliativeAssetServerURL: "http://palliativecare-asset-storage-api.test.axxess.tech/",
    messagingServiceServerURL: "https://messaging-api.test.axxess.tech/",
    AudioVideoServiceServerURL: "https://vcc1.test.axxess.tech/",
    axxessCentralUrl: "https://central.axxessweb.com",
    organizationId: "organization-id",
    organizationName: "organization-name"
};
