import { valueConverter } from "aurelia-binding";

@valueConverter("objectValues")
export class ObjectValues {
    public toView(obj: object) {
        let temp = [];
        for (let val of Object.values(obj)) {
            temp.push(val);
        }
        return temp;
    }
}

@valueConverter("objectKeys")
export class ObjectKeys {
    public toView(obj: object) {
        return obj ? Object.keys(obj) : [];
    }
}