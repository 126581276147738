import { ApplicationEnum, ApplicationNameAbbreviationEnum, ApplicationNameEnum } from "../../../enums/enums";
import { ProductService } from "../../../services/product.service";

const productService: ProductService = new ProductService();

export const getApplicationId = (id: ApplicationEnum): ApplicationEnum => {
    if (!id) {
        return null;
    }
    return productService.getApplicationId(id);
};

export const getProductPillClass = (id: number): string => {
    const className: string = productService.getProductMarkupClassName(id);
    if (className.length > 0) {
        return `pill-${className}`;
    }
    return "";
};

export const getProductColorClass = (applicationName: string): string => {
    const className: string = productService.getProductClassName(applicationName);
    if (className.length > 0) {
        return `color-${className}`;
    }
    return "";
};

export const getProductName = (id: number): string => {
    return ApplicationNameAbbreviationEnum[id];
};

export const getProductFullName = (id: number): string => {
    return ApplicationNameEnum[id];
};
