// Make sure moving forward FE and BE projects both have same Enum Values maintained.
export enum ApplicationEnum {
    AgencyCore = 1,
    HomeCare = 2,
    Therapy = 4,
    AxxessCAHPS = 8,
    AxxessDDE = 16,
    AxxessNala = 4096,
    DdeBlackScreen = 32,
    AxxessCARE = 64,
    AxxessEnterprise = 128,
    PatientFamilyPortal = 1024,
    HospiceCAHPS = 8192,
    AxxessRCM = -2,
    AxxessHospice = -3,
    AxxessBI = -4,
    AxxessPalliative = 65536,
    AxxessHospiceFE = 256 // Only used on FE side to check against -4 value of Axxess Hospice which is being used at few places
}

export enum ApplicationNameEnum {
    "Home Health" = 1,
    "Home Care" = 2,
    "Therapy" = 4,
    "Axxess CAHPS" = 8,
    "Axxess DDE" = 16,
    "DDE Black Screen" = 32,
    "Axxess CARE" = 64,
    "Axxess Enterprise" = 128,
    "Patient/Family Portal" = 1024,
    "Hospice CAHPS" = 8192,
    "Axxess RCM" = -2,
    "Hospice" = -3,
    "Axxess BI" = -4,
    "Palliative" = 65536
}

export enum ApplicationNameAbbreviationEnum {
    "HH" = 1,
    "HC" = 2,
    "Therapy" = 4,
    "Axxess CAHPS" = 8,
    "Axxess DDE" = 16,
    "DDE Black Screen" = 32,
    "Axxess CARE" = 64,
    "Axxess Enterprise" = 128,
    "Patient/Family Portal" = 1024,
    "Hospice CAHPS" = 8192,
    "Axxess RCM" = -2,
    "HOS" = -3,
    "Axxess BI" = -4,
    "PAL" = 65536
}
