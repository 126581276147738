import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import type { IGetPhysicianByName } from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { PhysiciansService } from "../../../../../services/physician-service";
import { ToastrService } from "../../../../../services/toastr.service";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import EditPhysicianRow from "./EditPhysicianRow.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput,
        ErrorMessageHandler,
        EditPhysicianRow
    },
    props: {
        referral: { type: HomeCareReferral, default: null },
        referralId: { type: String, default: null }
    },
    data() {
        return {
            isLoading: false,
            isError: false,
            hasData: false,
            loadingCount: 3,
            columns: 7,
            physicians: null as any,
            physicianId: null as string,
            physiciansData: null as ITypeaheadOptions,
            _physicianService: null,
            _toastrService: null
        };
    },
    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
        this._toastrService = Container.instance.get(ToastrService);
    },
    mounted() {
        this.getPhysician();
    },
    watch: {
        physiciansData(newValue, oldValue) {
            if (newValue) {
                this.physicianId = newValue.value;
            }
        }
    },
    methods: {
        changePhysiciansData(data: ITypeaheadOptions) {
            this.physiciansData = data;
        },
        async getPhysicianList(filter: string, limit: number) {
            let data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                application: this.referral.application,
                accountId: this.referral.accountId,
                providerIds: []
            });
            return data.items?.map((item: IGetPhysicianByName) => {
                return {
                    name: item.name,
                    value: item.id
                };
            });
        },
        async getPhysician() {
            try {
                this.isLoading = true;
                this.physicians = await this._physicianService.getPhysician({
                    application: this.referral.application,
                    referralId: this.referralId,
                    agencyId: this.referral.accountId
                });
                if (this.physicians.items.length > 0) {
                    this.hasData = true;
                } else {
                    this.hasData = false;
                }
            } catch (e) {
                console.error(e);
                this.isError = true;
            } finally {
                this.isLoading = false;
            }
        },
        async addPhysician() {
            if (this.physicianId == null) {
                this._toastrService.error({
                    title: "Empty Physician",
                    message: "Please select a Physician"
                });
                return;
            }
            try {
                let response = await this._physicianService.addPhysician({
                    application: this.referral.application,
                    referralId: this.referralId,
                    agencyId: this.referral.accountId,
                    physicianId: this.physicianId
                });

                if (response.data.IsSuccessful) {
                    this.getPhysician();
                    this._toastrService.success({
                        title: "Success",
                        message: "The Physician has been added successfully!"
                    });
                } else {
                    this._toastrService.error({
                        title: "Error",
                        message: "Please select the physician or this Physician is already assigned to this referral"
                    });
                }
            } catch (e) {
                this._toastrService.error({
                    title: "Error",
                    message: "There was a problem while adding a physician. Please try again."
                });
            }
        }
    }
});
