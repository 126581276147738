import { bindable, bindingMode, customElement, inject } from "aurelia-framework";

import { AgencyService } from "./../../services/agency.service";

@customElement("product-pill")
@inject(AgencyService)
export class DashboardHeader {
    private _agencyService: AgencyService;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public productId: number;

    public constructor(as: AgencyService) {
        this._agencyService = as;
    }

    public get hasMultipleClinicalProducts(): boolean {
        return this._agencyService.getHasMultipleClinicalProds();
    }
}
