<template>
    <div class="w-100 row no-gutters mb-2">
        <div class="col-12 col-lg-6 pr-lg-2 form-group">
            <label for="referral-address-line-2">
                Country
                <span class="text-danger">*</span>
            </label>
            <CountryTypeahead
                :selected-country-prop="selectedCountry"
                :updateSelectedCountry="updateSelectedCountry"
                v-model:prefill-country="address.country"
                :prefill-country-name="autoCompleteCountry"
                :disabled="disableInputs"
            />
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-6 pr-lg-2 form-group">
            <label for="referral-address-line-1">
                Address Line 1
                <span class="text-danger" v-if="enableValidation">*</span>
            </label>
            <PlacesAutocomplete
                v-model:street="address.addressLine1"
                v-model:city="address.city"
                v-model:state="address.state"
                v-model:zip="address.zipCode"
                v-model:county="address.county"
                v-model:latitude="address.latitude"
                v-model:longitude="address.longitude"
                v-model:country="autoCompleteCountry"
                :disabled="disableInputs"
                :show-validate-address="true"
                :places-changed-call-back="placesChangedCallBack"
            />
        </div>
        <div class="col-12 col-lg-6 pr-lg-2 form-group">
            <label for="referral-address-line-2"> Address Line 2 </label>
            <input
                class="form-control"
                maxlength="50"
                type="text"
                v-model="address.addressLine2"
                placeholder="Enter Address Line 2"
                :disabled="disableInputs"
            />
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-3 pr-lg-2 form-group">
            <label for="referral-city">
                City
                <span class="text-danger" v-if="enableValidation">*</span>
            </label>
            <input
                class="form-control"
                maxlength="50"
                type="text"
                v-model="address.city"
                placeholder="Enter City"
                :disabled="disableInputs"
                @blur="v$.address.city.$touch"
                :class="`${!v$.address.city.$error ? '' : 'field-invalid'}`"
            />
            <span v-if="v$.address.city.$error" class="field-invalid">City is required.</span>
        </div>
        <div class="col-12 col-lg-4 pr-lg-2 form-group">
            <label for="referral-state">
                {{ isUnitedStates ? "State" : "State / Province / Territory" }}
                <span class="text-danger" v-if="enableValidation">*</span>
            </label>
            <StateTypeahead
                v-model:selected-state="selectedState"
                v-model:prefill-state="address.state"
                :validation="stateValidation"
                :disabled="disableInputs"
                v-if="isUnitedStates"
            />
            <input
                class="form-control"
                maxlength="50"
                type="text"
                v-model="address.state"
                placeholder="Enter State / Province / Territory"
                :disabled="disableInputs"
                v-else
                @blur="v$.address.state.$touch"
                :class="`${!v$.address.state.$error ? '' : 'field-invalid'}`"
            />
            <span v-if="v$.address.state.$error" class="field-invalid">State is required.</span>
        </div>
        <div :class="`col-12 ${showCounty ? 'col-lg-2' : 'col-lg-4'} pr-lg-2 form-group`">
            <label for="referral-zip">
                {{ isUnitedStates ? "ZIP" : "Postal Code" }}
                <span class="text-danger" v-if="enableValidation">*</span>
            </label>
            <input
                class="form-control"
                maxlength="10"
                type="text"
                :value="formatZipCode(address.zipCode)"
                @input="(event) => (address.zipCode = event.target.value)"
                placeholder="Enter Zip"
                :disabled="disableInputs"
                v-if="isUnitedStates"
                @blur="v$.address.zipCode.$touch"
                :class="`${!v$.address.zipCode.$error ? '' : 'field-invalid'}`"
            />
            <input
                class="form-control"
                maxlength="50"
                type="text"
                v-model="address.zipCode"
                placeholder="Enter Postal Code"
                v-number-only
                :disabled="disableInputs"
                v-else
                @blur="v$.address.zipCode.$touch"
                :class="`${!v$.address.zipCode.$error ? '' : 'field-invalid'}`"
            />
            <span v-if="v$.address.zipCode.$error" class="field-invalid">{{
                isUnitedStates ? "ZipCode is required." : "Postal Code is required."
            }}</span>
        </div>
        <div class="col-12 col-lg-3 pr-lg-2 form-group" v-if="showCounty">
            <label for="referral-county">
                County
                <span class="text-danger" v-if="enableValidation && isUnitedStates">*</span>
            </label>
            <input
                class="form-control"
                maxlength="50"
                type="text"
                v-model="address.county"
                placeholder="Enter County"
                :disabled="disableInputs"
                @blur="v$.address.county.$touch"
                :class="`${!v$.address.county.$error ? '' : 'field-invalid'}`"
            />
            <span v-if="v$.address.county.$error" class="field-invalid">County is required.</span>
        </div>
    </div>
</template>

<script src="./AddressForm"></script>
