import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded shadow-lg modal-content-container" }
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "text-center font-weight-bold" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "mt-1 d-flex px-3 justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.options.title), 1 /* TEXT */),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.options.message), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ok())),
          type: "button",
          class: "btn btn-xs mr-2 btn-outline-primary py-2 px-4"
        }, "Ok")
      ])
    ])
  ]))
}