export const Options = [
    {
        label: "Create episode & schedule OASIS Start of Care visit after saving",
        value: 1
    },
    {
        label: "Create episode and schedule therapy evaluation (Checking this box will allow therapist to perform 'therapy only' admissions, and when an OASIS is required to be completed by an RN within the 5-day window, an OASIS will need to be scheduled.)",
        value: 2
    },
    {
        label: "Create episode & schedule Non-OASIS Start of Care visit after saving",
        value: 3
    },
    {
        label: "Create episode & schedule initial OASIS Recert in Axxess",
        value: 4
    },
    {
        label: "Create episode & schedule initial Non-OASIS Recert in Axxess",
        value: 5
    }
];

export const OasisDisciplineTypeOptions: any = {
    1: [
        {
            label: "OASIS Start of Care (SN)",
            value: "SN"
        },
        {
            label: "OASIS Start of Care (PT)",
            value: "PT"
        },
        {
            label: "OASIS Start of Care (ST)",
            value: "ST"
        },
        {
            label: "OASIS Start of Care (OT)",
            value: "OT"
        }
    ],
    2: [
        {
            label: "PT Assessment/Evaluation SOC",
            value: "PT"
        },
        {
            label: "ST Assessment/Evaluation SOC",
            value: "ST"
        },
        {
            label: "OT Assessment/Evaluation SOC",
            value: "OT"
        }
    ],

    3: [
        {
            label: "RN Assessment - Start of Care (Non-OASIS)",
            value: "SN"
        },
        {
            label: "PT Assessment - Start of Care (Non-OASIS)",
            value: "PT"
        },
        {
            label: "OT Assessment - Start of Care (Non-OASIS)",
            value: "OT"
        },
        {
            label: "ST Assessment - Start of Care (Non-OASIS)",
            value: "ST"
        }
    ],
    4: [
        {
            label: "OASIS Recertification (SN)",
            value: "SN"
        },
        {
            label: "OASIS Recertification (PT)",
            value: "PT"
        },
        {
            label: "OASIS Recertification (ST)",
            value: "ST"
        },
        {
            label: "OASIS Recertification (OT)",
            value: "OT"
        }
    ],
    5: [
        {
            label: "RN Assessment Recertification (Non-OASIS)",
            value: "SN"
        },
        {
            label: "PT Assessment Recertification (Non-OASIS)",
            value: "PT"
        },
        {
            label: "OT Assessment Recertification (Non-OASIS) ",
            value: "OT"
        },
        {
            label: "ST Assessment Recertification (Non-OASIS) ",
            value: "ST"
        }
    ]
};
