import { ApplicationEnum } from "../../enums/enums";
import { IPatientTask } from "../vue-interfaces/i-patient-task-hos-pal";

export class PatientTask implements IPatientTask {
    public taskId: string;
    public patientId: string;
    public userId: string;
    public metaId: string;
    public startDates: string[] = [];
    public endDates: string[] = [];
    public comments: string;
    public onCall: boolean;
    public prn: boolean;
    public patientPayerId: string;
    public agencyId: string;
    public application: ApplicationEnum;
}
