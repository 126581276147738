import { Ref, computed, defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, requiredIf, minValue } from "@vuelidate/validators";
import { mapState } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import {
    ApplicationEnum,
    Ethnicities,
    EthnicityPalliative,
    GenderIdentities,
    MaritalStatuses,
    RacesPalliative,
    SexualOrientation,
    Veterans,
    Gender,
    PhoneTypeEnum
} from "../../../../../enums/enums";
import { BranchesService } from "../../../../../services/branches-service";
import { IPhoneNumber } from "../../../../../resources-vue/vue-interfaces/i-phone-number";
import { Address } from "../../../../../resources-vue/vue-models/address";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import ValidateAddress from "../../../../../resources-vue/vue-dialogs/ValidateAddress/ValidateAddress.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import MultiSelectDropdown from "../../../../../resources-vue/vue-custom-elements/MultiSelectDropdown/MultiSelectDropdown.vue";
import PhoneNumber from "../../../../../resources-vue/vue-custom-elements/PhoneNumber/PhoneNumber.vue";
import TagsInput from "../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ReferralAddressForm from "../ReferralAddressForm/ReferralAddressForm.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        MultiSelectDropdown,
        PhoneNumber,
        TagsInput,
        ReferralAddressForm
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef),
            v: computed(() => this.v$)
        };
    },

    validations() {
        return {
            referral: {
                firstName: { required },
                lastName: { required },
                dateOfBirth: { required },
                gender: { minValue: minValue(1) },
                otherGenderIdentity: {
                    required: requiredIf(() => {
                        return this.referral.genderIdentity === 6;
                    })
                },
                otherSexualOrientation: {
                    required: requiredIf(() => {
                        return this.referral.sexualOrientation === 4;
                    })
                },
                phoneNumbers: [
                    {
                        number: { required },
                        type: { minValue: minValue(1) }
                    }
                ],
                locationId: { required },
                socialSecurityNumber: { minLength: minLength(9), maxLength: maxLength(9) },
                primaryAddress: {
                    country: { required },
                    addressLine1: { required },
                    city: { required },
                    state: {
                        required: requiredIf(() => {
                            return this.isUnitedStates;
                        })
                    },
                    zipCode: { required },
                    county: {
                        required: requiredIf(() => {
                            return this.isUnitedStates;
                        })
                    }
                },
                visitAddress: {
                    country: { required },
                    addressLine1: { required },
                    city: { required },
                    state: {
                        required: requiredIf(() => {
                            return this.isUnitedStates;
                        })
                    },
                    zipCode: { required },
                    county: {
                        required: requiredIf(() => {
                            return this.isUnitedStates;
                        })
                    }
                },
                mailingAddress: {
                    country: { required },
                    addressLine1: { required },
                    city: { required },
                    state: {
                        required: requiredIf(() => {
                            return this.isUnitedStates;
                        })
                    },
                    zipCode: { required },
                    county: {
                        required: requiredIf(() => {
                            return this.isUnitedStates;
                        })
                    }
                },
                previousAddress: {
                    country: {
                        required: requiredIf(() => {
                            return this.referral.hasPreviousAddress;
                        })
                    },
                    addressLine1: {
                        required: requiredIf(() => {
                            return this.referral.hasPreviousAddress;
                        })
                    },
                    city: {
                        required: requiredIf(() => {
                            return this.referral.hasPreviousAddress;
                        })
                    },
                    state: {
                        required: requiredIf(() => {
                            return this.isUnitedStates && this.referral.hasPreviousAddress;
                        })
                    },
                    zipCode: {
                        required: requiredIf(() => {
                            return this.referral.hasPreviousAddress;
                        })
                    },
                    county: {
                        required: requiredIf(() => {
                            return this.isUnitedStates && this.referral.hasPreviousAddress;
                        })
                    }
                }
            }
        };
    },

    props: {
        isHisSection: {
            type: Boolean,
            default: true
        },

        isPatient: {
            type: Boolean,
            default: true
        },
        providerId: {
            type: String,
            default: ""
        },
        isRefer: {
            type: Boolean,
            default: false
        }
    },
    data(vm) {
        return {
            dialogRef: null,
            _branchesService: null,
            _visitAddressCache: null,
            _visitAddressFacilityCache: null,
            _mailingAddressCache: null,
            _mailingAddressFacilityCache: null,
            mailingAddrSameAsPrimaryAddr: false,
            visitAddrSameAsPrimaryAddr: true,
            isVeteranSelected: false,
            selectedEthnicity: [],
            selectedRacesPalliative: [],
            isEthnicityDeclined: false,
            isRacesPalliativeDeclined: false,
            isEthnicityPalliativeDeclined: false,
            branches: null,
            phoneNumberTypeOptionsConfig: [PhoneTypeEnum.Home, PhoneTypeEnum.Mobile, PhoneTypeEnum.Facility],
            isAddressValidationInProgress: false,
            referralAddressFormRef: null,
            Genders: Array.from(Gender.enumWithDescriptions.values()),
            MaritalStatuses: enumToMap(MaritalStatuses),
            EthnicityPalliative: enumToMap(EthnicityPalliative),
            Ethnicities: Array.from(Ethnicities.enumWithDescriptions.values()),
            RacesPalliative: Array.from(RacesPalliative.enumWithDescriptions.values()),
            Veterans: Array.from(Veterans.enumWithDescriptions.values()),
            SexualOrientation: enumToMap(SexualOrientation),
            GenderIdentities: enumToMap(GenderIdentities),
            genderUnknownValue: Gender.enum.Unknown,
            formatDateInput,
            enumToMap,
            v$: useVuelidate(),
            tooltip:
                "For emergency preparedness, patient will be contacted at this number. This will also populate to the emergency preparedness report."
        };
    },
    created() {
        this._branchesService = Container.instance.get(BranchesService);
    },
    async mounted() {
        await this.initOptions();
        this.initFields();
    },
    computed: {
        ...mapState(useCreateReferralHosPal, ["referral"]),
        veteranOptions() {
            return this.Veterans?.map((option) => ({
                name: option.name,
                value: option.value
            }));
        },
        isApplicationPalliative(): boolean {
            return this.referral.application == ApplicationEnum.AxxessPalliative;
        },
        primaryNumber: {
            get() {
                return this.referral.phoneNumbers?.[this.primaryNumberIndex(this.referral.phoneNumbers)]?.number;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.primaryNumberIndex(this.referral.phoneNumbers)].number = value;
            }
        },
        primaryExtension: {
            get() {
                return this.referral.phoneNumbers?.[this.primaryNumberIndex(this.referral.phoneNumbers)]?.extension;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.primaryNumberIndex(this.referral.phoneNumbers)].extension = value;
            }
        },
        primaryType: {
            get() {
                const value = this.referral.phoneNumbers?.[this.primaryNumberIndex(this.referral.phoneNumbers)]?.type;
                return value ? value : this.isRefer ? 0 : 1;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.primaryNumberIndex(this.referral.phoneNumbers)].type = value;
            }
        },
        primaryCountry: {
            get() {
                return this.referral.phoneNumbers?.[this.primaryNumberIndex(this.referral.phoneNumbers)]?.country;
            },
            set(value: any) {
                return (this.referral.phoneNumbers[this.primaryNumberIndex(this.referral.phoneNumbers)].country =
                    value);
            }
        },
        alternateNumber: {
            get() {
                return this.referral.phoneNumbers?.[this.alternativeNumberIndex(this.referral.phoneNumbers)]?.number;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.alternativeNumberIndex(this.referral.phoneNumbers)].number = value;
            }
        },
        alternateExtension: {
            get() {
                return this.referral.phoneNumbers?.[this.alternativeNumberIndex(this.referral.phoneNumbers)]?.extension;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.alternativeNumberIndex(this.referral.phoneNumbers)].extension = value;
            }
        },
        alternateType: {
            get() {
                const value =
                    this.referral.phoneNumbers?.[this.alternativeNumberIndex(this.referral.phoneNumbers)]?.type;
                return value ? value : this.isRefer ? 0 : 1;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.alternativeNumberIndex(this.referral.phoneNumbers)].type = value;
            }
        },
        alternateCountry: {
            get() {
                return this.referral.phoneNumbers?.[this.alternativeNumberIndex(this.referral.phoneNumbers)]?.country;
            },
            set(value: any) {
                this.referral.phoneNumbers[this.alternativeNumberIndex(this.referral.phoneNumbers)].country = value;
            }
        },
        isUnitedStates() {
            return this.referral.primaryAddress.country === "840";
        }
    },
    watch: {
        mailingAddrSameAsPrimaryAddr(newValue: boolean) {
            this.mailingAddrSameAsPrimaryAddrChanged(newValue);
        },
        visitAddrSameAsPrimaryAddr(newValue: boolean) {
            this.visitAddrSameAsPrimaryAddrChanged(newValue);
        },
        isVeteranSelected(newValue: boolean) {
            if (!!this.referral && !newValue) {
                this.referral.veteran = [];
            }
        },
        selectedEthnicity() {
            if (!!this.selectedEthnicity && !!this.referral) {
                this.referral.ethnicity = this.selectedEthnicity.map((ethnicity) => Number(ethnicity.value));
            }
        },
        selectedRacesPalliative() {
            if (!!this.selectedRacesPalliative && !!this.referral) {
                this.referral.race = this.selectedRacesPalliative.map((race) => Number(race.value));
            }
        }
    },
    methods: {
        async initOptions() {
            const branches = await this._branchesService.getAllBranches();
            this.branches = branches.filter((branch: any) => branch.accountId == this.referral.accountId);
            if (this.isRefer) {
                this.phoneNumberTypeOptionsConfig.unshift(PhoneTypeEnum.None);
            }
        },
        initFields() {
            if (!!this.referral) {
                if (!this.referral.previousAddress) {
                    this.referral.previousAddress = new Address();
                } else {
                    this.referral.hasPreviousAddress = !!this.referral.previousAddress.addressLine1;
                }
                this._mailingAddressCache = new Address(this.referral.mailingAddress);
                this._mailingAddressFacilityCache = this.referral.mailingAddressFacilityTypeId;
                this.mailingAddrSameAsPrimaryAddr = this.referral.isMailingAddressSameAsPrimary;
                this._visitAddressCache = new Address(this.referral.visitAddress);
                this._visitAddressFacilityCache = this.referral.visitAddressFacilityTypeId;

                this.selectedEthnicity = this.Ethnicities.filter((ethnicity) => {
                    return this.referral.ethnicity?.some((item: any) => {
                        return ethnicity.value == item;
                    });
                }) as any;

                this.selectedRacesPalliative = this.RacesPalliative.filter((race) => {
                    return this.referral.race?.some((item: any) => {
                        return race.value == item;
                    });
                }) as any;
                if (
                    this.referral.isVisitAddressSameAsPrimary !== undefined &&
                    this.referral.isVisitAddressSameAsPrimary !== null
                ) {
                    this.visitAddrSameAsPrimaryAddr = this.referral.isVisitAddressSameAsPrimary;
                } else {
                    this.visitAddrSameAsPrimaryAddr =
                        this.referral.visitAddress?.addressLine1 === this.referral.primaryAddress?.addressLine1;
                }
                if (this.referral.veteran?.length > 0) {
                    this.isVeteranSelected = true;
                }
                if (this.primaryNumberIndex(this.referral.phoneNumbers) < 0) {
                    this.referral.phoneNumbers = this.referral.phoneNumbers.concat({
                        number: "",
                        extension: "",
                        type: 1,
                        country: null,
                        isPrimary: true
                    });
                }
                if (this.alternativeNumberIndex(this.referral.phoneNumbers) < 0) {
                    this.referral.phoneNumbers = this.referral.phoneNumbers.concat({
                        number: "",
                        extension: "",
                        type: 1,
                        country: null,
                        isPrimary: false
                    });
                }
                this.isEthnicityDeclined = this.referral.declineToSpecifyEthnicity;
                this.isRacesPalliativeDeclined = this.referral.declineToSpecifyRace;
                this.isEthnicityPalliativeDeclined = this.referral.declineToSpecifyEthnicity;
            }
        },
        hasPreviousAddressChanged() {
            if (!!this.referral && !this.referral.hasPreviousAddress) {
                this.referral.previousAddress = new Address();
                this.referral.previousAddressFacilityTypeId = null;
            }
        },
        handlePrimaryAddressChange() {
            if (this.mailingAddrSameAsPrimaryAddr) {
                this.referral.mailingAddress = new Address(this.referral.primaryAddress);
                this.referral.mailingAddressFacilityTypeId = this.referral.primaryAddressFacilityTypeId;
            }
            if (this.visitAddrSameAsPrimaryAddr) {
                this.referral.visitAddress = new Address(this.referral.primaryAddress);
                this.referral.visitAddressFacilityTypeId = this.referral.primaryAddressFacilityTypeId;
            }
        },

        ethnicityDeclined() {
            if (this.isEthnicityDeclined) {
                this.referral.ethnicity = [];
                this.selectedEthnicity = [];
            }
            if (!!this.referral) {
                this.referral.declineToSpecifyEthnicity = this.isEthnicityDeclined;
            }
        },
        racesPalliativeDeclined() {
            if (this.isRacesPalliativeDeclined) {
                this.referral.race = [];
                this.selectedRacesPalliative = [];
            }
            if (!!this.referral) {
                this.referral.declineToSpecifyRace = this.isRacesPalliativeDeclined;
            }
        },

        ethnicityPalliativeDeclined() {
            if (this.isEthnicityPalliativeDeclined) {
                this.referral.ethnicityOnly = null;
            }
            if (!!this.referral) {
                this.referral.declineToSpecifyEthnicity = this.isEthnicityPalliativeDeclined;
            }
        },

        primaryNumberIndex(phoneNumbers: IPhoneNumber[]) {
            if (!!phoneNumbers) {
                return phoneNumbers.findIndex((e) => e.isPrimary);
            }
            return 0;
        },
        alternativeNumberIndex(phoneNumbers: IPhoneNumber[]) {
            if (!!phoneNumbers) {
                return phoneNumbers.findIndex((e) => !e.isPrimary);
            }
            return 0;
        },
        resetSameAsPrimaryAddress() {
            if (this.referral.isVisitAddressSameAsPrimary) {
                this.visitAddrSameAsPrimaryAddrChanged(true);
            }
            if (this.referral.isMailingAddressSameAsPrimary) {
                this.mailingAddrSameAsPrimaryAddrChanged(true);
            }
        },
        mailingAddrSameAsPrimaryAddrChanged(newValue: boolean) {
            if (!!this.referral) {
                if (newValue === true) {
                    this.referral.isMailingAddressSameAsPrimary = true;
                    this.referral.mailingAddress = new Address(this.referral.primaryAddress);
                    this.referral.mailingAddressFacilityTypeId = this.referral.primaryAddressFacilityTypeId;
                } else {
                    this.referral.isMailingAddressSameAsPrimary = false;
                    if (this._mailingAddressCache) {
                        this.referral.mailingAddress = new Address(this._mailingAddressCache);
                        this.referral.mailingAddressFacilityTypeId = this._mailingAddressFacilityCache;
                    } else {
                        this.referral.mailingAddress = new Address();
                        this.referral.mailingAddressFacilityTypeId = null;
                    }
                }
            }
        },
        visitAddrSameAsPrimaryAddrChanged(newValue: boolean) {
            if (!!this.referral) {
                if (newValue === true) {
                    this.referral.isVisitAddressSameAsPrimary = true;
                    this.referral.visitAddress = new Address(this.referral.primaryAddress);
                    this.referral.visitAddressFacilityTypeId = this.referral.primaryAddressFacilityTypeId;
                } else {
                    this.referral.isVisitAddressSameAsPrimary = false;
                    if (this._visitAddressCache) {
                        this.referral.visitAddress = new Address(this._visitAddressCache);
                        this.referral.visitAddressFacilityTypeId = this._visitAddressFacilityCache;
                    } else {
                        this.referral.visitAddress = new Address();
                        this.referral.visitAddressFacilityTypeId = null;
                    }
                }
            }
        },
        getDate(date: any, name: string) {
            if (name === "dateOfBirth") {
                this.referral.dateOfBirth = date;
            }
        },
        handleVeteran() {
            this.isVeteranSelected = !this.isVeteranSelected;
        },
        selectedItemsUpdated(value: any) {
            if (this.referral.veteran.includes(value)) {
                const index = this.referral.veteran.indexOf(value);
                this.referral.veteran.splice(index, 1);
                return;
            }
            this.referral.veteran.push(value);
        },
        updateTags(value: any) {
            this.selectedEthnicity = value;
        },
        updateSelectedRaces(value: any) {
            this.selectedRacesPalliative = value;
        },
        async validateAddress() {
            let model = {
                originalAddress: {
                    addressLine1: this.referral.primaryAddress.addressLine1,
                    addressLine2: this.referral.primaryAddress.addressLine2,
                    city: this.referral.primaryAddress.city,
                    state: this.referral.primaryAddress.state,
                    zipCode: this.referral.primaryAddress.zipCode,
                    county: this.referral.primaryAddress.county,
                    country: this.referral.primaryAddress.country,
                    latitude: this.referral.primaryAddress.latitude,
                    longitude: this.referral.primaryAddress.longitude
                }
            };
            this.isAddressValidationInProgress = true;
            let updatedAddress = null;
            this.dialogRef = this.$dialog.open(ValidateAddress, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    model
                },
                onClose: (options) => {
                    if (options.data?.success) {
                        updatedAddress = options.data.updatedAddress;
                        this.referral.primaryAddress.addressLine1 = updatedAddress.addressLine1;
                        this.referral.primaryAddress.addressLine2 = updatedAddress.addressLine2;
                        this.referral.primaryAddress.city = updatedAddress.city;
                        this.referral.primaryAddress.state = updatedAddress.state;
                        this.referral.primaryAddress.zipCode = updatedAddress.zipCode;
                        this.referral.primaryAddress.county = updatedAddress.county;
                        this.referral.primaryAddress.latitude = updatedAddress.latitude;
                        this.referral.primaryAddress.longitude = updatedAddress.longitude;
                        this.referral.primaryAddress.isValidated = true;
                        this.referral.primaryAddress.isBypassed = false;
                        if (updatedAddress.country) {
                            this.referral.primaryAddress.country = updatedAddress.country;
                        }
                    } else {
                        this.referral.primaryAddress.isValidated = false;
                        this.referral.primaryAddress.isBypassed = true;
                    }
                }
            });
            this.isAddressValidationInProgress = false;
        }
    }
});
