import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import config from "../../../../../common/config";
import { EnumsService } from "../../../../../services/enums.service";
import { IOrganization } from "../../../../../resources-vue/vue-interfaces/i-calendar";
import { UserService } from "../../../../../services/user-service";
import { AgencyService, IGetAgency, IGetOrganization } from "../../../../../services/agency.service";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { IEnumResponse } from "../../../../../resources-vue/vue-interfaces/i-enum";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum,
    EmployeesScheduledFilter
} from "../../../../../enums/enums";
import { adjustDate } from "../../../../../common/adjust-date";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { IMultiSelectDropDownOption } from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect";

import MultiSelectFilter from "../../../../../resources-vue/vue-elements/MultiSelectFilter/MultiSelectFilter.vue";
import MultiSelect from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect.vue";

export default defineComponent({
    components: {
        MultiSelect,
        MultiSelectFilter
    },

    props: {
        startDate: { type: Date, default: null },
        endDate: { type: Date, default: null }
    },

    data() {
        return {
            _enumsService: null,
            _userService: null,
            _agencyService: null,
            _permissionManager: null,
            term: "" as string,
            selectedWeekdayRange: null as number,
            selectedOrganization: null as number,
            weekDayRangeEnums: [] as IEnumResponse[],
            ScheduledFilterEnums: EmployeesScheduledFilter,
            scheduleFilter: 0,
            agencyMultiSelectOptions: {} as IMultiSelectDropDownOption,
            selectedAgencies: [] as string[],
            selectedCredentials: [] as ITypeaheadOptions[],
            credentialsList: [] as string[],
            organizationsList: [] as IOrganization[],
            organizations: [] as IGetOrganization[],
            agencyList: [] as IGetAgency[],
            searchTimer: null,
            updatedStartDate: this.startDate as Date,
            updatedEndDate: this.endDate as Date,
            isWeekRangeChanged: false,
            organizationName: "",
            enumToMap
        };
    },

    created() {
        this._enumsService = Container.instance.get(EnumsService);
        this._agencyService = Container.instance.get(AgencyService);
        this._userService = Container.instance.get(UserService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },

    async mounted() {
        try {
            let weekDayRangeEnums = await this._enumsService.getWeekDayRange();
            this.weekDayRangeEnums = weekDayRangeEnums.getJson();
            this.credentialsList = await this._userService.getUserCredentials();
            this.organizations = await this._agencyService.getOrganizations();
            this.organizationName = window.localStorage.getItem(config.organizationName);
            this.agencyList = this.organizations.find(
                (organization: { name: string }) => organization.name === this.organizationName
            ).agencies;
            this.agencyList = this.agencyList.map((agency) => {
                let agencyAndApplicationIds = agency.id.split("_");
                let application = parseInt(agencyAndApplicationIds[1], 10);
                if (application == 256) {
                    application = ApplicationEnum.AxxessHospice;
                }
                return {
                    ...agency,
                    application: application
                };
            });
            this.selectedWeekdayRange = Number(this.weekDayRangeEnums[0].value);
            await this.setAgencyMultiSelect();
        } catch (e) {
            console.error(e);
        }
    },

    emits: ["employeeRefresh"],

    watch: {
        term: function (newValue, oldValue) {
            if (newValue !== oldValue) {
                clearTimeout(this.searchTimer);

                this.searchTimer = setTimeout(
                    async (term) => {
                        if (term?.length > 2 || term === "") {
                            await this.emitEmployeeRefresh();
                        }
                    },
                    500,
                    this.term
                );
            }
        },

        selectedWeekdayRange: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.isWeekRangeChanged = true;
                    let today = new Date(Date.now());
                    let dayRequested = newValue - 1;
                    if (dayRequested < 0) {
                        dayRequested = 0;
                    }

                    let todayDay = today.getDay();
                    let offset = dayRequested - todayDay;
                    if (offset > 0) {
                        offset -= 7;
                    }
                    this.updatedStartDate = adjustDate(today, offset);
                    this.updatedEndDate = adjustDate(this.updatedStartDate, 6);
                    this.emitEmployeeRefresh();
                }
                this.isWeekRangeChanged = false;
            },
            deep: true
        },
        scheduleFilter() {
            this.emitEmployeeRefresh();
        }
    },

    methods: {
        async setAgencyMultiSelect() {
            try {
                this.agencyMultiSelectOptions = {};
                this.selectedAgencies.length = 0;
                if (!this.agencyList || this.agencyList?.length === 0) {
                    return;
                }
                let allowedProducts = [
                    ApplicationEnum.AgencyCore,
                    ApplicationEnum.HomeCare,
                    ApplicationEnum.AxxessHospice,
                    ApplicationEnum.AxxessPalliative
                ];
                const schedulerViewPermissions = this._permissionManager.getPermmission(
                    ParentPermissionEnum.scheduler,
                    PermissionActionEnum.canView
                );
                let schedulerAgencies = schedulerViewPermissions
                    .filter((item: any) => {
                        if (!item) {
                            return false;
                        }
                        let application = item.application;
                        if (application === ApplicationEnum.AxxessHospiceFE) {
                            application = ApplicationEnum.AxxessHospice;
                        }
                        return allowedProducts.includes(application);
                    })
                    .map((x: any) => x.agencyId);
                let filterableProducts = this.agencyList.filter((agency) => {
                    let isAllowed = allowedProducts.includes(agency.application);
                    let agencyId = agency.id?.split("_")[0];
                    return isAllowed && schedulerAgencies.includes(agencyId);
                });
                filterableProducts?.forEach((agency) => {
                    let appName = ApplicationNameEnum[agency.application];

                    if (!this.agencyMultiSelectOptions[appName]) {
                        this.agencyMultiSelectOptions[appName] = [];
                    }

                    this.agencyMultiSelectOptions[appName].push({
                        displayText: agency.name,
                        value: agency.id
                    });
                });

                Object.values(this.agencyMultiSelectOptions).forEach((agency: any) => {
                    let values = agency.map((item: any) => item.value);
                    this.selectedAgencies.push(...values);
                });
                // For select agencies change
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },

        updateBranches(branches: any) {
            this.selectedAgencies.length = 0;
            this.selectedAgencies.push(...branches);
            this.emitEmployeeRefresh();
        },

        userCredentialsFetch(filter: string) {
            return this.credentialsList
                .map((credential, index) => ({
                    name: credential,
                    value: index.toString()
                }))
                .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
        },

        selectedItemsUpdated(options: any) {
            this.selectedCredentials = options;
            this.emitEmployeeRefresh();
        },

        async emitEmployeeRefresh() {
            this.$emit(
                "employeeRefresh",
                {
                    term: this.term,
                    agencies: this.selectedAgencies,
                    credentials: this.selectedCredentials?.map((credential) => credential.name),
                    scheduleFilter: this.scheduleFilter,
                    startDate: this.updatedStartDate,
                    endDate: this.updatedEndDate
                },
                this.isWeekRangeChanged
            );
        }
    }
});
