import { autoinject } from "aurelia-framework";
import { User, UserManager } from "oidc-client";
import type { IRequestHandler } from "./i-handlers";

@autoinject()
export class AuthTokenHandler implements IRequestHandler {
    private _userManager: UserManager;

    public constructor(userManager: UserManager) {
        this._userManager = userManager;
    }
    public async handle(request: Request): Promise<void> {
        let user: User = await this._userManager.getUser();

        if (user != null) {
            if (!request.headers.get("Authorization")) {
                request.headers.append("Authorization", `${user.token_type} ${user.access_token}`);
            }
        } else {
            console.log("User is not logged in, no Authorization set.");
        }
    }
}
