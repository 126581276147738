import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum AdmissionTypesEnum {
    Emergency = 1 as any,
    Urgent = 2 as any,
    Elective = 3 as any,
    Trauma = 5 as any,
    InformationNotAvailable = 9 as any
}
class AdmissionTypesClass extends EnumWithDescription<AdmissionTypesEnum> {
    public readonly enum = AdmissionTypesEnum;
    public readonly enumName: string = "AdmissionTypesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<AdmissionTypesEnum>> = new Map([
        [
            "Emergency",
            {
                value: AdmissionTypesEnum.Emergency,
                name: "1 Emergency"
            }
        ],
        [
            "Urgent",
            {
                value: AdmissionTypesEnum.Urgent,
                name: "2 Urgent"
            }
        ],
        [
            "Elective",
            {
                value: AdmissionTypesEnum.Elective,
                name: "3 Elective"
            }
        ],
        [
            "Trauma",
            {
                value: AdmissionTypesEnum.Trauma,
                name: "5 Trauma"
            }
        ],
        [
            "InformationNotAvailable",
            {
                value: AdmissionTypesEnum.InformationNotAvailable,
                name: "9 Information Not Available"
            }
        ]
    ]);
}

export const AdmissionTypes = new AdmissionTypesClass();
