import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum ConflictTypeEnum {
    NoConflict = 1,
    PartialConflict = 2,
    FullConflict = 3
}

export enum ConflictTypeNameEnum {
    "No Conflict" = ConflictTypeEnum.NoConflict,
    "Partial Conflict" = ConflictTypeEnum.PartialConflict,
    "Full Conflict" = ConflictTypeEnum.FullConflict
}

export class ConflictTypeEnumClass extends EnumWithDescription<ConflictTypeEnum> {
    public readonly enum = ConflictTypeEnum;
    public readonly enumName: string = "ConflictType";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<ConflictTypeEnum>> = new Map([
        [
            "NoConflict",
            {
                value: ConflictTypeEnum.NoConflict,
                name: "No Conflict"
            }
        ],
        [
            "PartialConflict",
            {
                value: ConflictTypeEnum.PartialConflict,
                name: "Partial Conflict"
            }
        ],
        [
            "FullConflict",
            {
                value: ConflictTypeEnum.FullConflict,
                name: "Full Conflict"
            }
        ]
    ]);
}

export const ConflictType = new ConflictTypeEnumClass();
