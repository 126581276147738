
import { defineComponent } from "vue";

import PayerInformation from "./PayerInformation/PayerInformation.vue";
import PayerComments from "./PayerComments/PayerComments.vue";
import AdditionalUBLocators from "./AdditionalUBLocators/AdditionalUBLocators.vue";
import AdditionalBillingInfo from "./AdditionalBillingInfo/AdditionalBillingInfo.vue";

export default defineComponent({
    components: {
        PayerInformation,
        PayerComments,
        AdditionalUBLocators,
        AdditionalBillingInfo
    }
});
