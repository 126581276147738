import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum VeteransEnum {
    Army = 1 as any,
    Navy = 2 as any,
    AirForce = 4 as any,
    Marines = 8 as any,
    CoastGuard = 16 as any,
    ReservistOrNationalGuard = 32 as any,
    MerchantMarines = 64 as any,
    Other = 128 as any
}
class VeteransClass extends EnumWithDescription<VeteransEnum> {
    public readonly enum = VeteransEnum;
    public readonly enumName: string = "VeteransEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<VeteransEnum>> = new Map([
        [
            "Army",
            {
                value: VeteransEnum.Army,
                name: "Army"
            }
        ],
        [
            "Navy",
            {
                value: VeteransEnum.Navy,
                name: "Navy"
            }
        ],
        [
            "AirForce",
            {
                value: VeteransEnum.AirForce,
                name: "Air Force"
            }
        ],
        [
            "Marines",
            {
                value: VeteransEnum.Marines,
                name: "Marines"
            }
        ],
        [
            "CoastGuard",
            {
                value: VeteransEnum.CoastGuard,
                name: "Coast Guard"
            }
        ],
        [
            "ReservistOrNationalGuard",
            {
                value: VeteransEnum.ReservistOrNationalGuard,
                name: "Reservist or National Guard"
            }
        ],
        [
            "MerchantMarines",
            {
                value: VeteransEnum.MerchantMarines,
                name: "Merchant Marines"
            }
        ],
        [
            "Other",
            {
                value: VeteransEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const Veterans = new VeteransClass();
