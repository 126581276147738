import { defineComponent } from "vue";

import { getApplicationId } from "../../../common/vue-helpers/modifiers/application-modifiers";
import { ApplicationEnum } from "../../../enums/enums";
import type { IFormSection } from "../../../interfaces/i-reassign-visit";

import ProductPill from "../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";

const formSectionConfigurationArray: IFormSection[] = [
    {
        application: ApplicationEnum.AxxessHospiceFE,
        backgroundClass: "form-section",
        borderClass: "border-hospice",
        titleClass: "section-title-hospice"
    },
    {
        application: ApplicationEnum.AxxessPalliative,
        backgroundClass: "form-palliative-section",
        borderClass: "border-hospice",
        titleClass: "section-title-hospice"
    },
    {
        application: ApplicationEnum.AgencyCore,
        backgroundClass: "background-home-health-form-section",
        borderClass: "border-home-health",
        titleClass: "section-title-home-health"
    },
    {
        application: ApplicationEnum.HomeCare,
        backgroundClass: "background-home-care-form-section",
        borderClass: "border-home-care",
        titleClass: "section-title-home-care"
    },
    {
        application: null,
        backgroundClass: "default-form-section",
        borderClass: "",
        titleClass: "section-title-default"
    }
];
const currentConfigurationObject: IFormSection = {
    application: ApplicationEnum.AxxessHospiceFE,
    backgroundClass: "form-section",
    borderClass: "border-hospice",
    titleClass: "section-title-hospice"
};

export default defineComponent({
    components: {
        ProductPill
    },
    props: {
        size: { type: String, default: "12" },
        title: { type: String, default: null },
        type: { type: String, default: "default-section" },
        popoverContent: { type: String, default: "" },
        popoverContainer: { type: String, default: "body" },
        fullHeight: { type: Boolean, default: true },
        applicationId: { typeof: ApplicationEnum, default: null },
        showProductPill: { type: Boolean, default: null },
        showBackground: { type: Boolean, default: true },
        useGrayTheme: { type: Boolean, default: false }
    },
    data() {
        return {
            formSectionConfiguration: formSectionConfigurationArray,
            currentConfiguration: currentConfigurationObject,
            getApplicationId
        };
    },
    created() {
        if (this.applicationId) {
            let configuration = null;
            if (this.useGrayTheme) {
                configuration = this.formSectionConfiguration[4];
                configuration.application = this.applicationId;
            } else {
                configuration = this.formSectionConfiguration.find(
                    (config) => config.application == this.applicationId
                );
            }
            if (!!configuration) {
                this.currentConfiguration = configuration;
            }
        }
    }
});
