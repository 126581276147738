
import { defineComponent } from "vue";
import { formatName } from "../../../common/format-name";

export default defineComponent({
    props: {
        firstName: { type: String, default: undefined },
        lastName: { type: String, default: undefined },
        middleInitial: { type: String, default: undefined },
        format: { type: String, default: undefined },
        suffix: { type: String, default: undefined }
    },
    computed: {
        fullName() {
            return formatName(this.firstName, this.lastName, this.format, "", this.middleInitial, this.suffix);
        }
    }
});
