import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { AgencyService, IGetOrganization } from "../../../services/agency.service";

export default defineComponent({
    inject: ["dialogRef"],

    data() {
        return {
            dialogReference: this.dialogRef as any,
            _agencyService: null,
            organizationsList: [],
            organizations: [] as IGetOrganization[],
            selectedOrganization: null
        };
    },

    created() {
        this._agencyService = Container.instance.get(AgencyService);
    },

    async mounted() {
        this.selectedOrganization = null;
        this.organizations = await this._agencyService.getOrganizations();
        this.organizationsList = this.organizations.map((organization, index) => {
            return {
                name: organization.name,
                id: organization.id,
                message: ""
            };
        });
    },

    methods: {
        cancel() {
            this.dialogReference.close("cancel");
        },
        ok() {
            this.selectedOrganization.message = "success";
            this.dialogReference.close(this.selectedOrganization);
        }
    }
});
