import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapActions } from "pinia";

import { LookupService } from "../../../services/lookup-service";
import { IGetCountryCode } from "../../../resources-vue/vue-interfaces/i-lookup";
import { useCreateReferralHosPal } from "../../../pages-vue/CreateReferral/CreateReferralHosPal/store/useCreateReferralHosPal";

import NewTypeahead from "../NewTypeahead/NewTypeahead.vue";

export default defineComponent({
    components: {
        NewTypeahead
    },

    props: {
        updateSelectedCountry: {
            type: Function
        },
        prefillCountry: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            _lookupService: null,
            country: null,
            list: []
        };
    },
    async created() {
        this._lookupService = Container.instance.get(LookupService);
    },
    async mounted() {
        await this.getCountryList();
        if (this.prefillCountry) {
            this.country = this.list.find((country) => country.value === this.prefillCountry) as IGetCountryCode;
        } else {
            let unitedStatesCode = await this._lookupService.getUnitedStatesCode();
            this.country = this.list.find((country) => country.value === unitedStatesCode);
        }
    },
    watch: {
        country(newValue: any, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.country = newValue;
                this.updateSelectedCountry(newValue);
            }
        },
        prefillCountry() {
            if (this.prefillCountry) {
                this.country = this.list.find((country) => country.value === this.prefillCountry) as IGetCountryCode;
            }
        }
    },
    methods: {
        ...mapActions(useCreateReferralHosPal, { setCountryList: "SET_COUNTRY_LIST" }),
        async getCountryList() {
            if (this.list?.length > 0) {
                return;
            }
            try {
                this.list = await this._lookupService.getCountryCodes();
                this.setCountryList(this.list);
            } catch (e) {
                console.error(e);
            }
        },
        emitBlur() {
            this.$emit("blurredInput");
        }
    }
});
