
import { defineComponent } from "vue";

import { formatDateInput } from "../../../../../../common/vue-helpers/modifiers/value-modifier";
import { PatientPayor } from "../../../../../../resources-vue/vue-models/patient-payor";

import DatePicker from "../../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        DatePicker
    },
    props: {
        payer: {
            type: PatientPayor
        },
        isApplicationPalliative: {
            type: Function
        },
        requireAuthorizationChanged: {
            type: Function
        },
        outOfPocketMaxMetChanged: {
            type: Function
        },
        getDate: {
            type: Function
        }
    },
    data() {
        return {
            formatDateInput
        };
    }
});
