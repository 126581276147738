import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { ApplicationEnum } from "../../../enums/enums";
import {
    IPatientDetailsAxxessCare,
    IPostAxxessCareResponse,
    ISpecialtyAxessCare,
    IVisitDetails
} from "../../../resources-vue/vue-interfaces/i-visit-details";
import { PostToAxessCareDetails } from "../../../models/task.model";
import { TaskService } from "../../../services/task.service";
import { ToastrService } from "../../../services/toastr.service";
import { formatDate } from "./../../../common/vue-helpers/modifiers/value-modifier";

import AddressModal from "../AddressModal/AddressModal.vue";
import PayrateModal from "../PayrateModal/PayrateModal.vue";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            dialogReference: this.dialogRef as any,
            options: {} as IVisitDetails,
            applicationEnum: ApplicationEnum,
            isLoading: false,
            isSaving: false,
            _taskService: null,
            _toastrService: null,
            dialogRef: null,
            patientAddressDetailsAxxessCare: {} as IPatientDetailsAxxessCare,
            postToAxxessCareDetails: {} as PostToAxessCareDetails,
            axxessCareSpecialities: [] as ISpecialtyAxessCare[],
            formatDate
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    async mounted() {
        this.postToAxxessCareDetails.SpecialtyId = null;
        this.options = Object.assign({}, this.dialogReference.data.visitDetails);
        const specialties: Promise<ISpecialtyAxessCare[]> = this._taskService.getAxxessCareSpecialties({
            agencyId: this.options.AgencyId
        });

        let details: Promise<IPatientDetailsAxxessCare>;
        let specialities: ISpecialtyAxessCare[];
        if (this.options.AgencyId && this.options.Id) {
            details = this._taskService.getAxxessCareDetails(this.options.Id, {
                agencyId: this.options.AgencyId,
                discipline: this.options.Discipline,
                disciplineTask: this.options.DisciplineTask
            });
        }

        try {
            [specialities, this.patientAddressDetailsAxxessCare] = await Promise.all([specialties, details]);
            this.assignValues(specialities);
        } catch (error) {
            this._toastrService.error({
                title: `Error - Posting to AxessCare`,
                message: `There was a problem while posting this task to AxxessCare</b>. Please try again.`
            });
            this.cancel();
        } finally {
            this.isLoading = false;
        }
    },

    methods: {
        cancel() {
            this.dialogReference.close();
        },
        ok() {
            this.dialogReference.close("success");
        },
        assignValues(specialities: ISpecialtyAxessCare[] = []) {
            if (specialities.length && this.options.Discipline) {
                this.axxessCareSpecialities = specialities.filter(
                    (speciality: ISpecialtyAxessCare) => speciality.disciplineId == this.options.Discipline
                );
            }

            Object.assign(
                this.postToAxxessCareDetails,
                JSON.parse(JSON.stringify(this.patientAddressDetailsAxxessCare))
            );
            this.postToAxxessCareDetails.AgencyId = this.options.AgencyId;
            this.postToAxxessCareDetails.EpisodeId = this.options.EpisodeId;
            this.postToAxxessCareDetails.PatientId = this.options.PatientId;
            this.postToAxxessCareDetails.EventId = this.options.Id;
            this.postToAxxessCareDetails.TaskType = this.options.TaskName;
            this.postToAxxessCareDetails.ScheduleDate = this.options.StartDate;
        },

        async postToAxxessCare() {
            const result: IPostAxxessCareResponse = await this._taskService.postTaskToAxxessCare(
                this.postToAxxessCareDetails
            );
            if (!result || !result.isSuccessful) {
                this._toastrService.error({
                    title: `Error - Posting to AxessCare`,
                    message: `There was a problem while posting this task to AxxessCare</b>. Please try again.`
                });
                this.cancel();
            } else {
                this._toastrService.success({
                    title: `Posted to AxxessCare`,
                    message: `The task was posted successfully to AxxessCare`
                });
                this.ok();
            }
        },

        async editAddress() {
            this.dialogRef = this.$dialog.open(AddressModal, {
                props: {
                    modal: true,
                    contentStyle: {
                        width: "400px",
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    address: this.patientAddressDetailsAxxessCare
                },
                onClose: (response) => {
                    if (response.data.Message == "success") {
                        Object.assign(this.postToAxxessCareDetails, response?.data);
                    }
                }
            });
        },

        async editPayRate() {
            this.dialogRef = this.$dialog.open(PayrateModal, {
                props: {
                    modal: true,
                    contentStyle: {
                        width: "400px",
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    payRates: this.patientAddressDetailsAxxessCare?.PayRates
                },
                onClose: (response) => {
                    if (response.data.Message == "success") {
                        Object.assign(this.postToAxxessCareDetails, response?.data);
                    }
                }
            });
        }
    }
});
