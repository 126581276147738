import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MessagesList = _resolveComponent("MessagesList")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      to: "/central-messaging/clinicians/conversation",
      class: _normalizeClass(`text-body ${_ctx.shakeMessageIcon ? 'shake-less' : ''}`),
      "data-toggle": "collapse",
      "data-target": "#App_Menu"
    }, {
      default: _withCtx(() => [
        _createElementVNode("i", {
          class: _normalizeClass(["fab fa-facebook-messenger", [_ctx.isAxxessPlannerHeader ? 'text-white' : 'text-dark']]),
          title: "Unread Messages"
        }, null, 2 /* CLASS */),
        _createElementVNode("sup", null, [
          (_ctx.unreadConversations > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.unreadConversations), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["class"]),
    (_ctx.conversations.length > 0)
      ? (_openBlock(), _createBlock(_component_Popover, {
          key: 0,
          placement: "bottom-middle"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MessagesList, {
              conversations: _ctx.conversations,
              "onUpdate:conversations": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.conversations) = $event))
            }, null, 8 /* PROPS */, ["conversations"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}