import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum RelationshipsEnum {
    Spouse = 1 as any,
    Daughter = 2 as any,
    Son = 3 as any,
    Parent = 4 as any,
    Sibling = 5 as any,
    Friend = 6 as any,
    InLaw = 7 as any,
    OtherRelative = 8 as any,
    CommittedPartner = 9 as any,
    CourtAppointedAdvocate = 10 as any,
    Other = 11 as any
}
class RelationshipsClass extends EnumWithDescription<RelationshipsEnum> {
    public readonly enum = RelationshipsEnum;
    public readonly enumName: string = "RelationshipsEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<RelationshipsEnum>> = new Map([
        [
            "Spouse",
            {
                value: RelationshipsEnum.Spouse,
                name: "Spouse"
            }
        ],
        [
            "Daughter",
            {
                value: RelationshipsEnum.Daughter,
                name: "Daughter"
            }
        ],
        [
            "Son",
            {
                value: RelationshipsEnum.Son,
                name: "Son"
            }
        ],
        [
            "Parent",
            {
                value: RelationshipsEnum.Parent,
                name: "Parent"
            }
        ],
        [
            "Sibling",
            {
                value: RelationshipsEnum.Sibling,
                name: "Sibling"
            }
        ],
        [
            "Friend",
            {
                value: RelationshipsEnum.Friend,
                name: "Friend"
            }
        ],
        [
            "InLaw",
            {
                value: RelationshipsEnum.InLaw,
                name: "In-Law"
            }
        ],
        [
            "OtherRelative",
            {
                value: RelationshipsEnum.OtherRelative,
                name: "Other Relative"
            }
        ],
        [
            "CommittedPartner",
            {
                value: RelationshipsEnum.CommittedPartner,
                name: "Committed Partner"
            }
        ],
        [
            "CourtAppointedAdvocate",
            {
                value: RelationshipsEnum.CourtAppointedAdvocate,
                name: "Court Appointed Advocate"
            }
        ],
        [
            "Other",
            {
                value: RelationshipsEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const Relationships = new RelationshipsClass();
