import { defineComponent } from "vue";
import { adjustDate } from "../../../../common/adjust-date";

export default defineComponent({
    props: {
        startDate: {
            default: null
        },
        endDate: {
            default: null
        }
    },

    emits: ["taskRefresh"],

    data() {
        return {
            todaysDate: new Date(Date.now()) as Date,
            _dateOptions: {
                month: "short",
                day: "numeric"
            } as object
        };
    },

    computed: {
        formattedDateRange() {
            let startDate = this.startDate?.toLocaleString("en-US", this._dateOptions) ?? "";
            let endDate = this.endDate?.toLocaleString("en-US", this._dateOptions) ?? "";
            return `${startDate} - ${endDate}`;
        }
    },

    mounted() {
        this.initDateRange();
    },

    methods: {
        async initDateRange() {
            if (!!this.startDate && !!this.endDate) {
                await this.emitTaskRefresh(this.startDate, this.endDate);
                return;
            }
            let todaysDay = this.todaysDate.getDay();
            let firstDateOfWeek = this.todaysDate.getDate() - todaysDay;
            let lastDateOfWeek = firstDateOfWeek + 6;
            let newDate = new Date();
            let sd = new Date(newDate.setDate(firstDateOfWeek));
            let ed = new Date(newDate.setDate(lastDateOfWeek));
            await this.emitTaskRefresh(sd, ed);
        },

        async emitTaskRefresh(startDate: Date, endDate: Date) {
            this.$emit("taskRefresh", { startDate, endDate });
        },

        async forwardNavigation() {
            await this.dateNavigation(7);
        },

        async backwardNavigation() {
            await this.dateNavigation(-7);
        },

        async dateNavigation(offset: number) {
            await this.emitTaskRefresh(adjustDate(this.startDate, offset), adjustDate(this.endDate, offset));
        }
    }
});
