import { defineComponent } from "vue";
import moment from "moment";

import { HospiceReferral } from "../../../../../resources-vue/vue-models/hospice-referral";
import { Patient } from "../../../../../resources-vue/vue-models/referral-patient";
import { formatDate, formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import EmptyTextHandler from "../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import Diagnosis from "../../../../../resources-vue/vue-custom-elements/Diagnosis/Diagnosis.vue";
import TablePrompt from "../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        Diagnosis,
        EmptyTextHandler,
        TablePrompt,
        CommentInput,
        DatePicker
    },
    props: {
        diag: {
            type: Object
        },
        updateDiag: {
            type: Function
        },
        numberOfDiagnosis: {
            type: Number
        },
        first: {
            type: Boolean
        },
        last: {
            type: Boolean
        },
        isAddRequested: {
            type: Boolean
        },
        id: {
            type: String
        },
        snapshotView: {
            type: Boolean,
            default: false
        },
        isNewTerminal: {
            type: Boolean,
            default: false
        },
        rowIndex: {
            type: Number
        },
        showDiagnosisMessage: {
            type: Function
        },
        deleteDiagnosis: {
            type: Function
        },
        makePrimary: {
            type: Function
        },
        cancelSaveNewDiagnosis: {
            type: Function
        },
        moveDiagnosisDown: {
            type: Function
        },
        resolveDiagnosis: {
            type: Function
        },
        moveDiagnosisUp: {
            type: Function
        },
        saveNewDiagnosis: {
            type: Function
        },
        referral: {
            type: HospiceReferral || Patient
        },
        isPalliative: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number
        }
    },
    data(vm) {
        return {
            _isRelatedSubscription: null,
            isEditRequested: false,
            isResolveRequested: false,
            columns: 0,
            originalDiag: null,
            selectedDiagnosis: null,
            isDeleteRequested: null,
            deleteMessage: `Do you want to delete this diagnosis?`,
            resolveDatePickerOptions: {
                endDate: moment().format("MM/DD/YYYY")
            },
            resolvedDate: "",
            formatDate,
            formatDateInput
        };
    },
    mounted() {
        this.originalDiag = { ...this.diag };
        this.columns = this.snapshotView ? 6 : 5;
    },
    watch: {
        diag(newValue: any) {
            this.isRelatedChanged(newValue.isRelated);
        }
    },
    methods: {
        openResolveDiagnosis() {
            this.isResolveRequested = true;
        },
        cancelResolveDiagnosis() {
            this.diag.resolvedDate = null;
            this.resolvedDate = null;
            this.isResolveRequested = false;
        },
        openEditDiagnosis() {
            if (this.diag.code !== null) {
                this.selectedDiagnosis = {
                    code: this.diag.code,
                    description: this.diag.description,
                    billable: this.diag.billable
                };
            }
            this.isEditRequested = true;
        },
        cancelEditDiagnosis() {
            this.isEditRequested = false;
            this.updateDiag?.({ ...this.originalDiag }, this.index);
            this.selectedDiagnosis = null;
        },
        async saveEditDiagnosis() {
            this.isEditRequested = false;
            this.originalDiag = { ...this.diag };
            this.selectedDiagnosis = null;
        },
        diagnosisChanged(newDiagnosis: any) {
            if (newDiagnosis) {
                this.diag.code = newDiagnosis.newDiagnosis.code;
                this.diag.description = newDiagnosis.newDiagnosis.description;
                this.diag.billable = newDiagnosis.newDiagnosis.billable;
                if (this.diag.order === 1) {
                    this.showDiagnosisMessage();
                }
            }
        },
        openDeleteDiagnosis() {
            this.isDeleteRequested = true;
        },
        cancelDeleteRequest() {
            this.isDeleteRequested = false;
        },
        isRelatedChanged(newValue: boolean) {
            if (newValue || newValue === null) {
                this.diag.notRelatedComments = null;
            }
        },
        async save() {
            this.$emit("isAddRequested", false);
            this.originalDiag = { ...this.diag };
            this.selectedDiagnosis = null;
            this.saveNewDiagnosis(this.index);
        },
        saveResolveDiagnosis(index: any) {
            this.isResolveRequested = false;
            this.diag.resolvedDate = this.resolvedDate;
            this.resolveDiagnosis(index);
        },
        deleteDiag() {
            this.isDeleteRequested = false;
            this.deleteDiagnosis(this.index);
        },
        handleDateChange(date: any, name: string) {
            if (name === "resolvedDate") {
                this.resolvedDate = date;
            }
            if (name === "startDate") {
                this.diag.startDate = date;
            }
        },
        handleDate(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.handleDateChange(value, name);
        }
    }
});
