export enum VisitStatusEnum {
    None = "None",
    NotYetDue = "Not Yet Due",
    NotStarted = "Not Yet Started",
    Saved = "Saved",
    SubmittedCoSignature = "Submitted Pending Co-Signature",
    SubmittedWithSignature = "Submitted with Signature", // Also known as pending QA
    ExportReady = "Export Ready",
    Exported = "Export Ready",
    ToBeSent = "To Be Sent",
    SentToPhysician = "Sent to Physician",
    ReturnedWithPhysicianSignature = "Returned with Physician Signature",
    Completed = "Completed",
    Returned = "Returned for Review",
    Reopened = "Reopened",
    NotExported = "Not Exported",
    Created = "Created",
    PendingAddendum = "Pending Addendum",
    ProcessingSubmission = "Processing",
    OrderReceived = "Order Received"
}
