// import Moment from 'moment';

const localGlobal = window as any;

const _releaseVersion: string = localGlobal._releaseVersion == null ? "" : localGlobal._releaseVersion; // Will be replaced by Octopus on deployment.
const _releaseEnvironment: string = localGlobal._releaseEnvironment == null ? "" : localGlobal._releaseEnvironment; // Will be replaced by Octopus on deployment.
const _releaseTimestamp: string = localGlobal._releaseTimestamp == null ? "" : localGlobal._releaseTimestamp; // Will be replaced by Octopus on deployment.
const _buildVersion: string = localGlobal._buildVersion == null ? "" : localGlobal._buildVersion; // Will be replaced by TeamCity on build.
const _hospiceAssetServerURL: string =
    localGlobal._hospiceAssetServerURL == null ? "" : localGlobal._hospiceAssetServerURL; // Will be replaced by Octopus on deployment.
const _palliativeAssetServerURL: string =
    localGlobal._palliativeAssetServerURL == null ? "" : localGlobal._palliativeAssetServerURL; // Will be replaced by Octopus on deployment.
const _messagingServiceServerURL: string =
    localGlobal._messagingServiceServerURL == null ? "" : localGlobal._messagingServiceServerURL; // Will be replaced by Octopus on deployment.

export enum ReleaseEnvironments {
    Production = "Production" as any,
    Staging = "Staging" as any,
    Development = "Development" as any,
    Local = "Local" as any
}

export default class Infrastructure {
    public static get releaseVersion(): string {
        return _releaseVersion.startsWith("#") ? "0.0.0.1" : _releaseVersion;
    }
    public static get releaseEnvironment(): string {
        return _releaseEnvironment.startsWith("#") ? "Local" : _releaseEnvironment;
    }
    public static get buildVersion(): string {
        return _buildVersion.startsWith("#") ? "0.0.0.2" : _buildVersion;
    }
    public static get releaseTimestamp(): string {
        return _releaseTimestamp;
    }
    public static get isProdEnvironment(): boolean {
        return _releaseEnvironment === ReleaseEnvironments.Production.toString();
    }
    public static get hospiceAssetServerUrl(): string {
        return _hospiceAssetServerURL;
    }
    public static get palliativeAssetServerUrl(): string {
        return _palliativeAssetServerURL;
    }
    public static get messagingServiceServerURL(): string {
        return _messagingServiceServerURL;
    }
}
