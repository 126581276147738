import { defineComponent, ref } from "vue";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";

import TypeaheadInput from "../TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        TypeaheadInput
    },

    props: {
        getData: {
            type: Function,
            default: null
        },
        data: {
            type: Array
        },
        tags: {
            type: Array,
            default: []
        },
        closeOnSelection: {
            type: Boolean,
            default: true
        },
        openOnFocus: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: "Start Typing..."
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showTagsByValue: {
            type: Boolean,
            default: false
        },
        showRemoveTag: {
            type: Boolean,
            default: true
        },
        displayOnly: {
            type: Boolean,
            default: false
        },
        resultsLimit: {
            type: Number,
            default: 30
        }
    },

    data() {
        return {
            value: null,
            tagsLength: 0,
            typeaheadInputRef: null
        };
    },

    mounted() {
        this.typeaheadInputRef = this.$refs.typeaheadInputRef;
    },

    computed: {
        tagsData() {
            let tagsValues = this.tags.map((item: any) => item?.value);
            if (Array.isArray(this.data)) {
                return this.data.filter((item: ITypeaheadOptions) => {
                    return !tagsValues.find((value) => {
                        return value == item.value;
                    });
                });
            } else {
                return this.data;
            }
        }
    },

    methods: {
        addTag(tag: ITypeaheadOptions) {
            if (!this.tags.find((item: ITypeaheadOptions) => item.value == tag.value)) {
                let temp = [...this.tags];
                temp.push(tag);
                this.$emit("updateTags", temp);
                this.tagsLength = this.tags.length || null;
                setTimeout(() => (this.$refs.typeaheadInputRef as any).clearFilter());
            }
        },

        removeTag(tag: ITypeaheadOptions) {
            let temp = [...this.tags];
            temp = temp.filter((item: any) => item.value !== tag.value);
            this.$emit("updateTags", temp);
            this.tagsLength = this.tags.length || null;
        },

        updateValue(newValue: any) {
            this.value = newValue;
            this.addTag(newValue);
            this.$emit("updateSelectedOption", newValue);
        },

        handleWrapperClick(event: MouseEvent) {
            if ((event.target as Element).className.includes("tags-wrapper") && this.typeaheadInputRef) {
                this.typeaheadInputRef.focusInput();
            }
        },
        emitBlur() {
            this.$emit("blurredInput");
        }
    }
});
