import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";


import { ReferralService } from "../../../services/referral-service";

export default defineComponent({
    emits: ["resultUpdated"],
    props: {
        templates: {
            type: Array<any>
        },
        accountId: {
            type: String
        },
        application: {
            type: String
        },
        result: {
            type: String
        }
    },

    data(vm) {
        return {
            selectedTemplate: null,
            shortErrorMessage: "Replace asterisks with individualized patient information.",
            hasErrors: false,
            maxLength: 2000,
            options: [],
            maxCharLimit: 3,
            _referralService: null,
            description: ""
        };
    },
    created() {
        this._referralService = Container.instance.get(ReferralService);
    },
    mounted() {
        this.description += this.result;
        this.options = this.templates.filter((val: any) => val.value !== "spacer");
    },
    watch: {
        async selectedTemplate(newValue, oldValue) {
            let result = await this._referralService.getTemplateData({
                agencyId: this.accountId,
                application: this.application,
                templateId: newValue.value
            });
            if (this.maxLength - this.description.length < 0) {
                return;
            } else {
                this.description += result.data.text;
                this.$emit("resultUpdated", this.description);
            }
        },
        result() {
            this.hasErrors = this.hasAsterisk(this.description);
        }
    },
    methods: {
        hasAsterisk(value: string) {
            return value && value.includes("*");
        }
    }
});
