import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";

import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { Options, OasisDisciplineTypeOptions } from "./Constants";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker
    },

    data() {
        return {
            formatDateInput,
            v$: useVuelidate(),
            Options,
            OasisDisciplineTypeOptions
        };
    },

    validations() {
        return {
            referral: {
                Demographics: {
                    CreateEpisodeAndScheduleVisit: {
                        minValue: minValue(1)
                    },
                    StartofCareDate: { required },
                    EpisodeStartDate: { required }
                }
            }
        };
    },

    created() {
        if (String(this.referral.Demographics.StartofCareDate).split("-").includes("0001")) {
            this.referral.Demographics.StartofCareDate = null;
            this.referral.Demographics.EpisodeStartDate = null;
        }
    },

    computed: {
        ...mapState(useEditReferralHH, { application: "currentApplication", referral: "referral", errors: "errors" })
    },
    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        handleDateChange(date: any, name: string) {
            if (name === "StartofCareDate") {
                this.referral.Demographics.StartofCareDate = formatDateInput(date);
            } else if (name === "EpisodeStartDate") {
                this.referral.Demographics.EpisodeStartDate = formatDateInput(date);
            } else {
                this.referral.Demographics.RecertificationDate = formatDateInput(date);
            }
        },
        isChecked(option: any) {
            return this.referral.Demographics.CreateEpisodeAndScheduleVisit === option.value ? true : false;
        },
        handleEpisodeChange(value: number) {
            if (this.referral.Demographics.CreateEpisodeAndScheduleVisit === value) {
                this.referral.Demographics.CreateEpisodeAndScheduleVisit = 0;
            } else {
                this.referral.Demographics.CreateEpisodeAndScheduleVisit = value;
                this.handleAllInput("CreateEpisodeAndScheduleVisit", value);
            }
        },
        handleOasisType(value: string) {
            this.referral.Demographics.OasisDisciplineType = value;
        },
        handleDateInputChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.handleDateChange(value, name);
            this.handleAllInput(name, value);
        },
        handleAllInput(name: any, value: any) {
            if (name === "CreateEpisodeAndScheduleVisit") {
                if (value > 0) {
                    const newErrors = [...new Set(this.errors.Demographics)].filter((val: any) => val !== name);
                    const payload = {
                        property: "Demographics",
                        value: newErrors
                    };
                    this.setErrors(payload);
                }
            } else {
                console.log(value, name);
                if (value.length) {
                    const newErrors = [...new Set(this.errors.Demographics)].filter((val: any) => val !== name);
                    console.log(newErrors);
                    const payload = {
                        property: "Demographics",
                        value: newErrors
                    };
                    this.setErrors(payload);
                }
            }
        }
    }
});
