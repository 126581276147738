import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "input-group phone-number d-flex" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "btn-group",
  id: "CountryDropdown",
  role: "group"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  class: "dropdown-menu",
  "aria-labelledby": "CountryButton"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "phone-code font-size-xs" }
const _hoisted_9 = ["value", "placeholder", "disabled"]
const _hoisted_10 = ["value", "placeholder", "disabled"]
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode(" TYPE "),
    (!_ctx.singleOption)
      ? _withDirectives((_openBlock(), _createElementBlock("select", {
          key: 0,
          class: "form-control px-1",
          disabled: _ctx.disabled,
          onChange: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateType && _ctx.updateType(...args)), ["prevent"])),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitBlur && _ctx.emitBlur(...args))),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.type) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneOptions, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option.value,
              key: option.value
            }, _toDisplayString(option.name), 9 /* TEXT, PROPS */, _hoisted_3))
          }), 128 /* KEYED_FRAGMENT */))
        ], 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2)), [
          [_vModelSelect, _ctx.type]
        ])
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" COUNTRY "),
    (_ctx.enableCountry && _ctx.countryList.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            id: "CountryButton",
            type: "button",
            "data-toggle": "dropdown",
            class: _normalizeClass(`btn dropdown-toggle border-top border-bottom ${_ctx.disabled ? 'disabled' : ''} ${
                    _ctx.singleOption ? 'border-left' : ''
                }`),
            disabled: _ctx.disabled,
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(`flag-icon fi-${_ctx.selectedCountry.twoLetterCode?.toLowerCase()} mr-1`)
            }, null, 2 /* CLASS */),
            _createElementVNode("span", null, _toDisplayString(_ctx.selectedCountry.phoneCode), 1 /* TEXT */)
          ], 10 /* CLASS, PROPS */, _hoisted_5),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(`dropdown-item ${country.value === _ctx.selectedCountry.value ? 'highlight-item' : ''}`),
                key: country.value,
                onClick: ($event: any) => (_ctx.handleCountrySelection(country))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(`flag-icon fi-${country.twoLetterCode?.toLowerCase()} mr-1`)
                }, null, 2 /* CLASS */),
                _createElementVNode("span", null, _toDisplayString(country.name), 1 /* TEXT */),
                _createElementVNode("span", _hoisted_8, _toDisplayString(country.phoneCode), 1 /* TEXT */)
              ], 10 /* CLASS, PROPS */, _hoisted_7))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" INPUT "),
    (!_ctx.enableCountry || _ctx.isUnitedStates)
      ? (_openBlock(), _createElementBlock("input", {
          key: 2,
          type: "text",
          class: "form-control phone-number-input",
          value: _ctx.formattedNumber,
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.valueUpdated(($event.target as any).value))),
          maxlength: "14",
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.emitBlur && _ctx.emitBlur(...args)))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_9))
      : (_openBlock(), _createElementBlock("input", {
          key: 3,
          type: "text",
          class: "form-control phone-number-input",
          value: _ctx.value,
          onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.valueUpdated(($event.target as any).value))),
          maxlength: "14",
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.emitBlur && _ctx.emitBlur(...args)))
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_10)),
    _createCommentVNode(" EXTENSION "),
    (_ctx.showExtension)
      ? (_openBlock(), _createElementBlock("input", {
          key: 4,
          type: "text",
          class: "form-control",
          onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateExtension(($event.target as any).value))),
          disabled: _ctx.disableExtensionLocal || _ctx.disabled,
          placeholder: "Extn.",
          maxlength: "5"
        }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_11))
      : _createCommentVNode("v-if", true)
  ]))
}