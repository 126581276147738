<template>
    <section class="live-login-modal">
        <div class="rounded shadow-lg modal-content-container">
            <div class="p-3">
                <div class="form-group text-center font-size-lg">
                    <b>Support Key:</b> <span class="blue-text">{{ code }}</span>
                    <p class="font-size-base mt-2">Please include this 6 digit number in the support ticket.</p>
                </div>
                <div class="mt-1 d-flex px-3 justify-content-center">
                    <button @click="ok()" type="button" class="btn btn-md btn-outline-primary mt-3 px-4 py-1">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script src="./GetLiveLoginToken.ts"></script>
