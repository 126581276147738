import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapState } from "pinia";

import { PatientService } from "../../../../../services/patient.service";
import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { IDiagnosisResponse } from "../../../../../resources-vue/vue-interfaces/i-diagnosis";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput
    },
    data() {
        return {
            primaryDiagnosis: { diagnosis: "", code: "" },
            otherDiagnosis: [] as IDiagnosisResponse[],
            _toastrService: null,
            _diagnosisService: null
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            application: "currentApplication",
            accountId: "accountId",
            referralOptions: "referralOptions"
        })
    },

    async created() {
        this._diagnosisService = Container.instance.get(PatientService);
        this.setOtherDiagnosis();
    },

    mounted() {
        if (this.referral.Clinical.PrimaryPatientDiagnosisCodes.DiagnosisCode) {
            this.primaryDiagnosis = {
                diagnosis: this.referral.Clinical.PrimaryPatientDiagnosisCodes.LongDescription,
                code: this.referral.Clinical.PrimaryPatientDiagnosisCodes.DiagnosisCode
            };
            this.referral.Clinical.PrimaryPatientDiagnosisCodes.Id = "";
        }
    },

    methods: {
        addDiagnosis() {
            this.otherDiagnosis.push({ diagnosis: "", code: "" });
            this.referral.Clinical.PatientDiagnosisCodes.push(this.referral.Clinical.PrimaryPatientDiagnosisCodes);
        },

        removeDiagnosis(index: number) {
            this.otherDiagnosis.splice(index, 1);
            this.referral.Clinical.PatientDiagnosisCodes.splice(index, 1);
        },

        async diagnosisFetchByDiagnosis(event: any) {
            if (event?.length > 2) {
                let result;
                try {
                    const data = await this._diagnosisService.patientDiagnosis({
                        descriptionSearch: event,
                        codeSearch: "",
                        agencyId: this.accountId,
                        application: this.application,
                        page: 1,
                        limit: 10
                    });

                    return data?.Diagnoses.map((item: any) => {
                        return {
                            name: item.Code,
                            value: item.Description
                        };
                    });
                } catch (error) {
                    this._toastrService.error({
                        title: `Error`,
                        message: `There was a problem while getting this referral. Please try again. If the problem persists, please report it.`
                    });
                }
                return result;
            }
        },

        async setOtherDiagnosis() {
            if (
                this.referral.Clinical.PatientDiagnosisCodes.length > 0 &&
                this.referral.Clinical.PatientDiagnosisCodes[0].DiagnosisCode
            ) {
                this.otherDiagnosis = await this.referral.Clinical.PatientDiagnosisCodes.map(
                    (item: any, index: any) => {
                        return {
                            diagnosis: item.LongDescription,
                            code: item.DiagnosisCode,
                            index: index
                        };
                    }
                );
            }
        },

        async diagnosisFetchByCode(event: any) {
            if (event.length > 2) {
                let result;
                try {
                    const data = await this._diagnosisService.patientDiagnosis({
                        descriptionSearch: "",
                        codeSearch: event,
                        agencyId: this.accountId,
                        application: this.application,
                        page: 1,
                        limit: 10
                    });

                    return data?.Diagnoses?.map((item: any) => {
                        return {
                            name: item.Code,
                            value: item.Description
                        };
                    });
                } catch (error) {
                    this._toastrService.error({
                        title: `Error`,
                        message: `There was a problem while getting this referral. Please try again. If the problem persists, please report it.`
                    });
                }
                return result;
            }
        },

        primaryDiagnosisChanged(newValue: any) {
            const desc = newValue.value.split(" ").slice(0, 3).join(" ");
            this.primaryDiagnosis = { diagnosis: newValue.value, code: newValue.name };
            this.referral.Clinical.PrimaryPatientDiagnosisCodes = {
                ...this.referral.Clinical.PrimaryPatientDiagnosisCodes,
                IsPrimary: true,
                Id: "",
                DiagnosisCode: newValue.name,
                ShortDescription: desc,
                LongDescription: newValue.value
            };
        },

        otherDiagnosisChanged(newValue: any, id: any) {
            const desc = newValue.value.split(" ").slice(0, 3).join(" ");
            this.otherDiagnosis[id] = { diagnosis: newValue.value, code: newValue.name };
            this.referral.Clinical.PatientDiagnosisCodes[id] = {
                ...this.referral.Clinical.PrimaryPatientDiagnosisCodes,
                IsPrimary: false,
                Id: "",
                DiagnosisCode: newValue.name,
                LongDescription: newValue.value,
                ShortDescription: desc
            };
        }
    }
});
