import { defineComponent, computed } from "vue";
import { mapState } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { LookupService } from "../../../../services/lookup-service";
import { sanitizeMarkup } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { UserService } from "../../../../services/user-service";
import {
    IPhysicianInfoCapitalize,
    IGetHomeHealthPhysicianByFiltersResult
} from "../../../../resources-vue/vue-interfaces/i-get-physician";
import { useEditReferralHH } from "../../store/useEditReferralHH";
import { AddPhysician } from "../../../../resources-vue/vue-models/add-physician";

import FormSection from "../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import NewPhysicianHH from "../../../../resources-vue/vue-dialogs/NewPhysicianHH/NewPhysicianHH.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId",
            errors: "errors"
        })
    },

    data() {
        return {
            dialogRef: null,
            _lookupService: null,
            attendingPhysician: { value: "", name: "" },
            _userService: null,
            physicianFilters: {
                agencyId: null,
                npi: null,
                physicianName: null,
                state: null,
                city: null
            },
            physicianInfo: {} as IPhysicianInfoCapitalize,
            referringPhysicianFilters: null,
            certifyingPhysicianFilters: null,
            physicianDataCache: [] as IGetHomeHealthPhysicianByFiltersResult[],
            referringPhysicianName: null,
            certifyingPhysicianName: null,
            additionalPhysicians: [],
            otherPhysicians: [],
            additionalPhysicianIndex: 0,
            attendingPhysicianTooltip: `<strong>Title</strong>
                                        <p>Attending Physician</p>
                                        <hr>
                                        <strong>Description</strong>
                                        <p>The Primary Physician is the physician that is providing Care Plan Oversight. This physician could be the same as the referring and/or the certifying physician.</p><hr>
                                        <strong>Intent</strong>
                                        <p>Medicare Chapter 7 identifies this physician in 30.3 – “Under the Care of a Physician- The patient must be under the care of a physician who is qualified to sign the physician certification and plan of care in accordance with 42 CFR 424.22. A patient is expected to be under the care of the physician who signs the plan of care. It is expected that in most instances, the physician who certifies the patient’s eligibility for Medicare home health services, in accordance with §30.5 will be the same physician who establishes and signs the plan of care. However, the rules listed 30.5.1 recognizes this is not always the case, especially as it relates to patient’s discharged from the hospital”.</p><hr>
                                        <strong>Impact</strong>
                                        <p>The Attending Physician is identified on the Plan of Care, subsequent plans of care and physician orders. This is the default physician listed for all subsequent orders throughout orders management.</p>`,
            attendingPhysicianContactedTooltip: `<strong>Title</strong>
                                                 <p>Attending Physician Contacted</p>
                                                 <hr>
                                                 <strong>Description</strong>
                                                 <p>In most cases, Medicare anticipates the certifying physician and the attending physician will be the same. In the case where the referring physician will not provide ongoing orders, the attending physician must be contacted in advance of care, and agree to provide ongoing orders attesting the patient is under their care. This would apply in both instances when a referring physician such as a hospitalist, agrees to sign (certify) or not to sign the Plan of Care.
                                                 </p><hr>
                                                 <strong>Intent</strong>
                                                 <p>A valid referral is considered to have been received when the agency has received adequate information about a patient (name, address/contact info, and diagnosis and/or general home care needs) and the agency has ensured that the referring physician, or another physician, will provide the plan of care and ongoing orders. In cases where home care is requested by a hospitalist who will not be providing an ongoing Plan of Care for the patient, the agency must contact an alternate, or attending physician, and upon agreement from this following physician for referral and/or further orders, the agency will note this as the referral date in M0104 (unless referral details are later updated or revised-OASIS C2 Guidance Manual-pg 140).
                                                 </p><hr>
                                                 <strong>Impact</strong>
                                                 <p>If the attending physician is not the referring physician, documentation is required by CMS to verify the requirement for a valid referral is fulfilled. This documentation will be in the Edit Patient Profile View and flow to the Patient Profile.`,
            signingPOCTooltip: `<strong>Title</strong>
                                <p>Certifying Physician</p>
                                <hr>
                                <strong>Description</strong>
                                <p>
                                   The Certifying Physician is the physician that certifies that a patient is eligible for Medicare home health services. The physician that establishes the Plan of Care, must sign and date the certification (POC). This physician may be the same as the referring and/or attending physician.
                                   <a style="text-decoration: underline;" href="https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf" target="_blank">https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf</a>
                                </p>
                                <hr>
                                <strong>Intent</strong>
                                <p>Medicare Chapter 7 identifies this physician as “the physician who is certifying that this patient meets the qualifications for home health care. 30.5.1: If the patient is starting home health directly after discharge from an acute/post-acute care setting where the physician, with privileges, that cared for the patient in that setting is certifying the patient’s eligibility for the home health benefit, but will not be following the patient after discharge, then the certifying physician must identify the community physician who will be following the patient after discharge (attending physician). One of the criteria that must be met for a patient to be considered eligible for the home health benefit is that the patient must be under the care of a physician”. </p>
                                <hr>
                                <strong>Impact</strong>
                                <p>The Certifying Physician is identified on the Plan of Care at SOC. At SOC, this is the physician that is sent through orders management and identified for billing. This field may be updated for subsequent Plans of Care if this physician changes after SOC. </p>`,

            refPhysicianTooltip: `<strong>Title</strong>
                                  <p>Referring Physician</p>
                                  <hr>
                                  <strong>Description</strong>
                                  <p>The Referring Physician or allowed non-physician practitioner (NPP) is the person that is primarily providing the face to face information for this admission. This physician may be the same as the physician signing (certifying) the POC as well as the attending physician. Commonly this person may be the hospitalist if the patient is being discharged from a facility.
                                  </p><hr>
                                  <strong>Intent</strong>
                                  <p>OASIS C2 2018 guidelines: “A valid referral is considered to have been received when the agency has received adequate information about a patient (name, address/contact info, and diagnosis and/or general home care needs) and the agency has ensured that referring physician, or another physician, will provide the plan of care and ongoing orders. In cases where home care is requested by a hospitalist who will not be providing an ongoing plan of care for the patient, the agency must contact an alternate, or attending physician, and upon agreement from this following physician for referral and/or further orders, the agency will note this as the referral date in M0104”.
                                  </p><hr>
                                  <strong>Impact</strong>
                                  <p>The Referring Physician is captured in the patient profile and reports. May also be identified by the admitting clinician as the physician contacted for additional information required/needed to complete the F2F documentation requirements.</p>`,
            sanitizeMarkup
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
        this.referringPhysicianFilters = JSON.parse(JSON.stringify(this.physicianFilters));
        this._userService = Container.instance.get(UserService);
    },

    async mounted() {
        if (this.referral.Physicians.PrimaryCarePhysicianDetails.FirstName) {
            this.attendingPhysician = {
                value: this.referral.Physicians.PrimaryCarePhysicianDetails.Id,
                name: this.referral.Physicians.PrimaryCarePhysicianDetails.DisplayName
            };
            this.physicianInfo = await this.getPhysicianInfo(this.attendingPhysician.value);
        }
    },

    methods: {
        async getReferringPhysicianListItem(filter: string, itemNumber: number) {
            this.referringPhysicianFilters.agencyId = this.referral?.AgencyId;
            let itemName: string;
            itemNumber == 1 ? (itemName = "city") : itemNumber == 2 ? (itemName = "state") : (itemName = "npi");
            this.referringPhysicianFilters[itemName] = filter;
            let data = await this._lookupService.getPhysiciansByFilters(this.referringPhysicianFilters);
            if (itemName === "npi") this.physicianDataCache = data.matchedPhysicians;
            return [...new Set(data.matchedPhysicians?.map((item: any) => item[itemName]))];
        },

        referringPhysicianFiltersCity(data: any) {
            this.referringPhysicianFilters.city = data;
        },

        referringPhysicianFiltersState(data: any) {
            this.referringPhysicianFilters.state = data;
        },

        npiChanged(type: string) {
            if (!type) return;
            setTimeout(() => {
                let physicanType = this.referringPhysicianFilters;
                if (type == "certifying") physicanType = this.certifyingPhysicianFilters;
                physicanType = JSON.parse(JSON.stringify(physicanType));
                let physicianSelected = this.physicianDataCache.find((item) => item.npi == physicanType.npi);
                if (!physicianSelected) return;
                if (type == "certifying") {
                    this.certifyingPhysicianName = physicianSelected.physicianName;
                    this.referral.ReferralInformation.CertifyingPhysician = physicianSelected?.physicianId || null;
                } else {
                    this.referringPhysicianName = physicianSelected.physicianName;
                    this.referral.ReferralInformation.ReferrerPhysician = physicianSelected?.physicianId || null;
                }
                physicanType.physicianName = physicianSelected?.physicianName?.toString() || null;
            }, 100);
        },

        async referringPhysicianFiltersNpiChange(npi: any, index: any = null) {
            this.referringPhysicianFilters.npi = npi;
            let physicianSelected = this.physicianDataCache.find((item) => item.npi == npi);
            let data = {
                name: physicianSelected.physicianName,
                value: physicianSelected.physicianId
            };
            if (index !== null) {
                this.otherPhysicianChanged(data, index);
                this.otherPhysicians[index] = data;
            } else {
                this.attendingPhysicianChanged(data);
            }
        },

        async getPhysicianList(filter: string) {
            let result;
            try {
                let data = await this._lookupService.getPhysiciansByName({
                    agencyId: this.accountId,
                    term: filter
                });
                return data?.map((item: any) => {
                    return {
                        name: item.text,
                        value: item.value
                    };
                });
            } catch (e) {
                console.error(e);
            }
            return result;
        },

        async attendingPhysicianChanged(newValue: any) {
            this.attendingPhysician = newValue;
            const infoResponse = await this.getPhysicianInfo(this.attendingPhysician.value);
            this.physicianInfo = { ...infoResponse };
            const { AgencyId, ...rest } = infoResponse;
            this.referral.Physicians.PrimaryCarePhysicianDetails = {
                ...rest,
                Primary: false,
                FaxNumberFormatted: ""
            };

            this.referral.Physicians.PrimaryCarePhysician = infoResponse.Id;
        },

        async otherPhysicianChanged(newValue: any, index: any) {
            const infoResponse = await this.getPhysicianInfo(newValue.value);
            this.additionalPhysicians.push(infoResponse);
            const { AgencyId, ...rest } = infoResponse;
            this.referral.Physicians.AgencyPhysicianDetails[index] = {
                ...rest,
                Primary: false,
                FaxNumberFormatted: ""
            };
        },

        async getPhysicianInfo(userId: string) {
            const infoResponse = await this._lookupService.getPhysicianInfo({
                userId: userId,
                agencyId: this.referral.AgencyId
            });

            let tempInfo = {} as IPhysicianInfoCapitalize;

            tempInfo.Id = infoResponse.id;
            tempInfo.FirstName = infoResponse.firstName;
            tempInfo.LastName = infoResponse.lastName;
            tempInfo.MiddleName = infoResponse.middleName;
            tempInfo.Credentials = infoResponse.credentials;
            tempInfo.NPI = infoResponse.npi;
            tempInfo.AddressLine1 = infoResponse.addressLine1;
            tempInfo.AddressLine2 = infoResponse.addressLine2;
            tempInfo.AddressCity = infoResponse.addressCity;
            tempInfo.AddressStateCode = infoResponse.addressStateCode;
            tempInfo.AddressZipCode = infoResponse.addressZipCode;
            tempInfo.AddressZipCodeFour = infoResponse.addressZipCodeFour;
            tempInfo.PhoneWork = infoResponse.phoneWork;
            tempInfo.FaxNumber = infoResponse.faxNumber;
            tempInfo.EmailAddress = infoResponse.emailAddress;
            tempInfo.DisplayName = this.getPhysicianDisplayName(infoResponse);

            return tempInfo;
        },

        getPhysicianDisplayName(data: any) {
            // adding displayName logic from FE because BE logic is not working
            let credentials = data.credentials || "";

            if (data.firstName && data.lastName) {
                return data.lastName + ", " + data.firstName + " " + credentials;
            } else if (data.firstName) {
                return data.firstName + " " + credentials;
            } else if (data.lastName) {
                return data.lastName + " " + credentials;
            } else {
                return "";
            }
        },

        openNewPhysicianDialog() {
            this.dialogRef = this.$dialog.open(NewPhysicianHH, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    agencyId: this.referral.AgencyId
                }
            });
        },

        addAdditionalPhysician() {
            if (this.additionalPhysicianIndex < 10) {
                let addPhysician: AddPhysician = new AddPhysician();
                this.additionalPhysicianIndex++;
                this.referral.Physicians.AgencyPhysicianDetails.push(addPhysician);
            }
        },

        removeAdditionalPhysician(index: number) {
            this.additionalPhysicianIndex--;
            this.additionalPhysicians.splice(index, 1);
            this.referral.Physicians.AgencyPhysicianDetails.splice(index, 1);
        }
    }
});
