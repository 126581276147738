import { defineComponent } from "vue";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            dialogReference: this.dialogRef as any,
            code: null
        };
    },

    mounted() {
        let data = this.dialogReference.data.response.data;
        this.code = data.code;
    },

    methods: {
        ok() {
            this.dialogReference.close();
        }
    }
});
