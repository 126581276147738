import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "PatientCalendar__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullCalendar = _resolveComponent("FullCalendar")!

  return (_ctx.patientsList.length > 0)
    ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
        _createVNode(_component_FullCalendar, { updatedOptions: _ctx.calendarOptions }, null, 8 /* PROPS */, ["updatedOptions"])
      ]))
    : _createCommentVNode("v-if", true)
}