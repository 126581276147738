import { defineComponent } from "vue";
import "jquery-timepicker/jquery.timepicker";
import moment from "moment";

import type { ITimePickerOptions } from "../../../resources-vue/vue-interfaces/i-time-picker";

export default defineComponent({
    data() {
        return {
            options: {
                timeFormat: "hh:mm p",
                interval: 15,
                dynamic: true,
                dropdown: true,
                scrollbar: true
            } as ITimePickerOptions,
            element: null,
            _timePicker: null,
            _self: null
        };
    },
    mounted() {
        this.element = jQuery(this.$el).find("input");
        if (!this._timePicker && this.element && this.element.toArray().length === 1) {
            let timePickerOptions = Object.assign(this.options, {
                change: (newTime: Date) => {
                    if (newTime) {
                        this.$emit("updateProps", moment(newTime).format("hh:mm A"));
                        let changeEvent = new CustomEvent("change", {
                            detail: {},
                            bubbles: true
                        });
                        // Target = input(s), input(s) was modified outside of dom events, need to notify the binder.
                        let inputs = jQuery("input", this.$el);
                        for (let input of inputs.toArray()) {
                            input.dispatchEvent(changeEvent);
                        }
                        // Anyone listening on the custom element.
                        this.$el.dispatchEvent(changeEvent);
                    }
                }
            });
            this._timePicker = (jQuery(this.element) as any).timepicker(timePickerOptions);
        } else {
            if (!this.element || this.element.toArray().length !== 1) {
                console.warn(`The time-picker custom element must contain one child of type input[type=text].
                             When using ranges, the input-group must follow standard bootstrap formats.`);
            }
        }
    },

    unmounted() {
        if (this._timePicker) {
            (jQuery(this._timePicker) as any).timepicker("destroy");
            this._timePicker = undefined;
        }
    }
});
