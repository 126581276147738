import { autoinject } from "aurelia-dependency-injection";
import { HttpClient } from "aurelia-fetch-client";

import { NotAuthenticatedException } from "../common/exceptions/not-authenticated-exception";
import { AuthTokenHandler } from "../common/http-handlers/auth-token-handler";
import { BadRequestHandler } from "../common/http-handlers/bad-request-handler";
import { ContentTypeHandler } from "../common/http-handlers/content-type-handler";
import type { IResponseHandler } from "../common/http-handlers/i-handlers";
import { TimeZoneHandler } from "../common/http-handlers/time-zone-handler";
import { UnauthorizedErrorHandler } from "../common/http-handlers/unauthorized-error-handler";
import type { IRequestHandler } from "../common/http-handlers/i-handlers";
import { AnalyticsService } from "./analytics.service";
import AuthService from "./auth.service";
import { AuthSubIdHandler } from "../common/http-handlers/auth-subid-handler";
import { OrganizationIdHandler } from "../common/http-handlers/organizationId-handler";

@autoinject
export class HttpService {
    private _http: HttpClient;
    private _authService: AuthService;
    private _analyticsService: AnalyticsService;

    private _requestHandlers: IRequestHandler[] = [];
    private _responseHandlers: IResponseHandler[] = [];

    private static fetchConfigured: boolean = false;

    public constructor(
        fetchClient: HttpClient,
        authService: AuthService,
        authHandler: AuthTokenHandler,
        analyticsService: AnalyticsService,

        timeZoneHandler: TimeZoneHandler,
        contentTypeHandler: ContentTypeHandler,
        unauthorizedErrorHandler: UnauthorizedErrorHandler,
        badRequestHandler: BadRequestHandler,
        authSubIdHandler: AuthSubIdHandler,
        organizationIdHandler: OrganizationIdHandler
    ) {
        this._http = fetchClient;
        this._authService = authService;
        this._analyticsService = analyticsService;

        // register request handlers
        this._requestHandlers.push(authHandler);
        this._requestHandlers.push(timeZoneHandler);
        this._requestHandlers.push(contentTypeHandler);
        this._requestHandlers.push(authSubIdHandler);
        this._requestHandlers.push(organizationIdHandler);

        // register response handlers
        this._responseHandlers.push(badRequestHandler);
        this._responseHandlers.push(unauthorizedErrorHandler);
    }

    public configureFetch() {
        if (HttpService.fetchConfigured) {
            return;
        }
        HttpService.fetchConfigured = true;

        let self: this = this;

        this._http.configure((httpConfig) => {
            httpConfig
                .withDefaults({
                    headers: {
                        credentials: "include"
                    }
                })
                .withInterceptor({
                    request: async (request: Request): Promise<Request> => {
                        let isLoggedIn = await self._authService.isLoggedIn();

                        if (isLoggedIn) {
                            for (let handler of self._requestHandlers) {
                                await handler.handle(request);
                            }
                            return request;
                        } else {
                            throw new NotAuthenticatedException();
                        }
                    },
                    response: async (response: Response) => {
                        if (!!response) {
                            for (let handler of self._responseHandlers) {
                                await handler.handle(response);
                            }
                        }
                        if (response.ok) {
                            return response;
                        }
                        self.logError(response.status, response.url);
                        throw response;
                    }
                });
        });
    }

    private logError(status: number, url: string) {
        let category = "Error";
        if (status >= 400 && status < 500) {
            category = "4XX-Error";
        } else if (status >= 500) {
            category = "5XX-Error";
        }
        this._analyticsService.logEvent({
            category,
            action: `${status}`,
            name: url
        });
    }
}
