import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PalliativeEthnicityEnum {
    HispanicorLatino = 8 as any,
    NotHispanicorLatino = 64 as any
}
class PalliativeEthnicityClass extends EnumWithDescription<PalliativeEthnicityEnum> {
    public readonly enum = PalliativeEthnicityEnum;
    public readonly enumName: string = "PalliativeEthnicityEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PalliativeEthnicityEnum>> = new Map([
        [
            "HispanicorLatino",
            {
                value: PalliativeEthnicityEnum.HispanicorLatino,
                name: "Hispanic or Latino"
            }
        ],
        [
            "NotHispanicorLatino",
            {
                value: PalliativeEthnicityEnum.NotHispanicorLatino,
                name: "Not Hispanic or Latino"
            }
        ]
    ]);
}

export const EthnicityPalliative = new PalliativeEthnicityClass();
