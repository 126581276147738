import "./weather-report.scss";

import { DialogService, DialogSettings } from "aurelia-dialog";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable, customElement, PLATFORM } from "aurelia-framework";
import $ from "jquery";

import config from "../../common/config";
import type { ILocation } from "../../resources-vue/vue-interfaces/i-location";
import type { IWeatherRequestParams } from "../../resources-vue/vue-interfaces/i-weather";
import { Weather } from "../../models/weather.model";
import { GeolocationService, GeoLocationEvents } from "../../services/geolocation.service";
import { WeatherService } from "../../services/weather.service";
import { GetZip, GetZipEvents } from "../dialogs/get-zip/get-zip";
import { AnalyticsService, AnalyticsCategory } from "../../services/analytics.service";

const UPDATE_LOCATION = "updateLocation";

PLATFORM.moduleName("../dialogs/get-zip/get-zip");
@customElement("weather-report")
@autoinject
export class WeatherReport {
    @bindable public duration: number = 5; // Weather report for N number of days
    @bindable public durationDateFormat: string = "ddd"; // Duration date format. Moment's date format
    private _ea: EventAggregator;
    private _gl: GeolocationService;
    private _weatherService: WeatherService;
    private _dialogService: DialogService;
    private _analyticsService: AnalyticsService;
    private _subscription: Subscription[] = [];
    private _isGettingLocation: boolean = false;
    private _locationKey: string = config.locationCacheKey;
    public locationData: ILocation = {};
    public locationChanged: boolean = false;
    public currentData: Weather;
    public forecastData: Weather[];
    public loading: boolean = false;
    public locationCacheKey: string = config.locationCacheKey;

    public constructor(
        ea: EventAggregator,
        gl: GeolocationService,
        ws: WeatherService,
        dialogService: DialogService,
        analyticsService: AnalyticsService
    ) {
        this._ea = ea;
        this._gl = gl;
        this._weatherService = ws;
        this._dialogService = dialogService;
        this._analyticsService = analyticsService;

        this.initSubscriptions();
    }

    private initSubscriptions() {
        this._subscription.push(
            this._ea.subscribe(GeoLocationEvents.Retrieved, async (data: ILocation) => {
                Object.assign(this.locationData, data);
                await this.init();
            })
        );

        this._subscription.push(
            this._ea.subscribe(GetZipEvents.Changed, (zip: string) => {
                this.locationChanged = true;
            })
        );
    }

    public async attached(): Promise<void> {
        this.locationChanged = true;
        // This condition is required.
        // On route navigation, we will check whether location has been received already.
        if (this._gl.hasGeo) {
            Object.assign(this.locationData, this._gl.locationData);
        } else {
            await this.getLocationFromUser();
        }

        await this.init();
    }

    public async getLocationFromUser(): Promise<void> {
        // https://geoip-db.com/json/ - Lat and Long based on IP address.
        // Alternate way to get location. Not accurate. Might get random countries.
        if (!this._isGettingLocation && !this._gl.hasGeo) {
            if ("geolocation" in navigator) {
                this._isGettingLocation = true;
                console.log("GEOLOCATION: Getting location");
                // FYI: getCurrentPosition() and watchPosition() no longer work on insecure origins.
                // To use this feature, you should consider switching your application to a secure origin, such as HTTPS.
                // See https://goo.gl/rStTGz for more details.
                navigator.geolocation.getCurrentPosition(
                    this.handlePosition.bind(this),
                    this.handlePositionError.bind(this)
                );
            } else {
                this.logEvent(AnalyticsCategory.GetZip, "No-Geolocation");
                await this.handleNoGeoLocation();
            }
        } else if (this._gl.hasGeo) {
            console.log(`GEOLOCATION: hasGeo is true. Why did you make a call? Culprit:`);
        }
    }

    private async handlePosition(position: any): Promise<void> {
        let lat: string = position.coords.latitude.toFixed(3);
        let lon: string = position.coords.longitude.toFixed(3);

        if (lat !== undefined && lon !== undefined) {
            let cacheString: string = localStorage.getItem(this._locationKey);
            let cache = cacheString && JSON.parse(cacheString);

            if (cache && cache.lat === lat && cache.lon === lon) {
                this.logEvent(AnalyticsCategory.GetZip, "Old-Location");
                Object.assign(this.locationData, cache);
                this._isGettingLocation = false;
                this._gl.announceLocationRetrieval(this.locationData);
            } else {
                this.logEvent(AnalyticsCategory.GetZip, "New-Location");
                await this._gl.getAddressFromCoords(lat, lon);
            }
        } else {
            this._analyticsService.logEvent({
                category: "Geolocation",
                action: "No-Lat-Long"
            });
            await this.handleNoGeoLocation();
        }
    }

    // This function will get triggered when the user blocks the geoLocation
    private async handlePositionError(): Promise<void> {
        this.logEvent(AnalyticsCategory.GeoLocation, "User-Blocked");
        await this.handleNoGeoLocation();
    }

    private async handleNoGeoLocation(): Promise<void> {
        let userLocation = localStorage.getItem("axxess-user-location");
        if (userLocation) {
            let locationJSON = JSON.parse(userLocation);
            let willingToShare = locationJSON.willingToShareLocation;
            let fromGeo = locationJSON.fromGeo;

            if (willingToShare) {
                // Check to see if User blocked after allowing geolocation
                if (!fromGeo) {
                    await this._gl.getCoordinatesByZip(locationJSON.zip);
                } else {
                    await this.getLocationUsingZip();
                }
            } else {
                this._ea.publish(GetZipEvents.Canceled);
            }
        } else {
            await this.getLocationUsingZip();
        }
    }

    private async getLocationUsingZip() {
        let dialogOptions: DialogSettings = {
            viewModel: GetZip,
            model: {
                zip: this.locationData.zip
            }
        };
        await this._dialogService.open(dialogOptions).whenClosed(() => {
            this.cacheUnwillingToShareLocation();
        });

        this._ea.subscribe(GetZipEvents.Canceled, () => {
            this.cacheUnwillingToShareLocation();
        });
    }

    private cacheUnwillingToShareLocation() {
        Object.assign(this.locationData, {
            willingToShareLocation: false,
            fromGeo: false
        });
        this._gl.cacheLocation();
    }

    public async init(): Promise<void> {
        let options = this.getWeatherRequestData();

        if (!!options) {
            this.loading = true;
            let forecastOptions = Object.assign(options, {
                count: 6
            });
            let currentWeatherReq = this._weatherService.getCurrentWeather(options, this.locationChanged);
            let forecastWeatherReq = this._weatherService.getWeatherForecast(forecastOptions, this.locationChanged);
            this.currentData = await currentWeatherReq;
            this.forecastData = await forecastWeatherReq;
            this.locationChanged = false;
            let todayForecast = this.forecastData.splice(0, 1)[0];
            this.currentData.updateHiLo(
                {
                    hi: todayForecast.hi,
                    lo: todayForecast.lo
                },
                false
            );
            this.loading = false;
        }
    }

    private getWeatherRequestData() {
        let locationKey: string = localStorage.getItem(this.locationCacheKey);
        if (locationKey) {
            this.locationData = JSON.parse(locationKey);
        }

        if (this.locationData && !$.isEmptyObject(this.locationData)) {
            let reqParams: IWeatherRequestParams = {
                units: "imperial" // Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit
            };

            if (this.locationData.lat && this.locationData.lon) {
                return Object.assign(reqParams, {
                    latitude: this.locationData.lat,
                    longitude: this.locationData.lon
                });
            } else if (this.locationData.zip) {
                return Object.assign(reqParams, {
                    zip: `${this.locationData.zip},us`
                });
            } else {
                console.warn("get weatherRequestData(): Latitude, longitude or zip was not set in locationData.");
            }
        } else {
            console.warn("get weatherRequestData(): locationData is empty or undefined.");
        }
        return null;
    }

    // This Method pops the dialog for the user to enter zipCode.
    // Once the zip code changes, need to get the coordinates of the entered zip code.
    // Once we get the coordinates of the zip code, we need to initialize the report again.
    public async updateLocation() {
        let dialogOptions: DialogSettings = {
            viewModel: GetZip,
            model: {
                zip: this.locationData.zip
            }
        };
        await this._dialogService.open(dialogOptions);
    }

    private logEvent(category: string, action: string) {
        this._analyticsService.logEvent({ category, action });
    }

    public detached(): void {
        this._subscription?.forEach((sub) => sub?.dispose());
    }
}
