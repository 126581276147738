import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HospiceReferral } from "../../../../../resources-vue/vue-models/hospice-referral";
import { Patient } from "../../../../../models/patient";
import { ReferralService } from "../../../../../services/referral-service";
import { PAGE_FAIL_RESPONSE } from "../../../../CentralIntake/Inquiry/CentralInquiry";
import { IPageCallbackResult } from "../../../../../resources-vue/vue-interfaces/i-page-container";

import PatientPreadmissionNotesForm from "./PatientPreadmissionNotesForm/PatientPreadmissionNotesForm.vue";
import PatientPreadmissionNotesRow from "./PatientPreadmissionNotesRow/PatientPreadmissionNotesRow.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import Pagination from "../../../../../resources-vue/vue-elements/Pagination/Pagination.vue";
import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";

export default defineComponent({
    components: {
        Pagination,
        FormSection,
        ErrorMessageHandler,
        PatientPreadmissionNotesForm,
        PatientPreadmissionNotesRow
    },

    props: {
        isReferral: {
            type: Boolean,
            default: false
        },
        referralId: {
            type: String,
            default: ""
        },
        application: {
            type: Number
        },
        referral: {
            type: HospiceReferral || Patient
        }
    },
    data(vm) {
        return {
            _referralService: null,
            isLoading: false,
            isError: false,
            columns: 4,
            notesList: null,
            page: null,
            showNoteForm: false
        };
    },
    computed: {
        loadingCount() {
            return this.page?.items?.length > 0 ? this.page.items.length() : 5;
        },
        hasData() {
            return this.page?.itemCount > 0;
        }
    },

    created() {
        this._referralService = Container.instance.get(ReferralService);
    },

    mounted() {
        this.notesList = this.$refs.notesList;
        this.resetList();
    },

    methods: {
        async pageChangedCallback(pageNumber: number, pageSize: number): Promise<IPageCallbackResult> {
            try {
                this.isError = false;
                let data = await this._referralService.getPreadmissionNotes(
                    {
                        referralId: this.referralId,
                        page: pageNumber,
                        pageLength: pageSize,
                        application: this.referral.application,
                        accountId: this.referral.accountId
                    },
                    this.referralId
                );
                this.page = data;
                return {
                    totalPageCount: data.pageCount,
                    success: data.itemCount > 0,
                    totalCount: data.itemCount
                };
            } catch (e) {
                console.error(e);
                this.isError = true;
                return PAGE_FAIL_RESPONSE;
            }
        },
        resetList() {
            this.notesList.reset();
        },
        openNoteForm() {
            this.showNoteForm = true;
        },
        closeNoteForm() {
            this.showNoteForm = false;
        }
    }
});
