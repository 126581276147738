import moment from "moment";
import { valueConverter } from "aurelia-framework";

@valueConverter("getWeatherIcon")
export class GetWeatherIcon {
    public toView(id: number): string {
        // https://openweathermap.org/weather-conditions
        if (id >= 200 && id < 300) {
            return "wi-thunderstorm";
        } else if (id >= 300 && id < 400) {
            return "wi-drizzle";
        } else if (id >= 500 && id < 600) {
            return "wi-rain";
        } else if (id >= 600 && id < 700) {
            return "wi-snow";
        } else if ((id >= 700 && id < 800) || id == 905) {
            return "wi-atmosphere";
        } else if (id == 800 || id == 904) {
            return "wi-sunny";
        } else if (id == 801) {
            return "wi-fewcloud";
        } else if (id >= 802 && id < 900) {
            return "wi-cloud";
        } else if (id == 900) {
            return "wi-tornado";
        } else if (id == 901 || id == 902 || id == 962) {
            return "wi-hurricane";
        } else if (id == 903) {
            return "wi-cold";
        } else if (id == 906) {
            return "wi-hail";
        } else if (id == 951) {
            return "wi-sunny";
        } else if (id >= 952 && id < 960) {
            return "wi-windy";
        } else if (id == 960 || id == 961) {
            return "wi-thunderstorm";
        }
        throw new Error("GetWeatherIcon toView(): Get weather icon value converter did not receive an id.");
    }
}
