import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-template" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      class: _normalizeClass(["header", _ctx.borderClass])
    }, [
      _renderSlot(_ctx.$slots, "header")
    ], 2 /* CLASS */),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ]))
}