<template>
    <div class="dropdown typeahead-dropdown typeahead" ref="dropdownRef">
        <input
            :class="`form form-control pr-4 ${isError ? 'border border-warning rounded p-2' : ''}`"
            :placeholder="placeholder"
            ref="inputRef"
            v-model="filter"
            :disabled="disabled"
            :id="id"
            autocomplete="off"
            @blur="emitBlur"
            @focus="focusInput()"
        />
        <template v-if="!hideIcon">
            <i class="fas fa-search mr-2" v-if="data.length === 0" @click="focusInput()"></i>
            <i class="fas fa-caret-down mr-2" v-else @click="openDropdown()"></i>
        </template>
        <div class="dropdown-menu" ref="dropdownMenuRef">
            <h6 class="dropdown-header text-center" v-if="displayData.length === 0 && !loading && isAuthorized">
                {{ noResultsText }}
            </h6>
            <h6 class="dropdown-header text-center" v-if="displayData.length === 0 && !loading && !isAuthorized">
                {{ notAuthorizedText }}
            </h6>
            <h6 class="dropdown-header text-center" v-if="loading">{{ loadingText }}</h6>
            <a
                v-else
                v-for="item of displayData"
                :class="`dropdown-item ${focusedItem === item ? 'active' : ''}`"
                @click="itemSelected(item)"
                :key="item"
            >
                <span v-html="substringHighlighter(getName(item), filter)"></span>
                <span v-if="shouldAppend" v-html="sanitizeMarkup(getAppendContent({ item }))"></span>
            </a>
        </div>
    </div>
</template>

<style src="./TypeaheadInput.scss"></style>

<script src="./TypeaheadInput.ts"></script>
