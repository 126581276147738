import "./dashboard-header.scss";

import { bindable, bindingMode, computedFrom, customElement, autoinject } from "aurelia-framework";
import { DialogService, DialogSettings } from "aurelia-dialog";

import nameOf from "../../common/name-of";
import { AnalyticsService } from "../../services/analytics.service";
import AuthService from "../../services/auth.service";
import { BranchesService } from "../../services/branches-service";
import { ParentPermissionEnum, PermissionActionEnum, TestingAgenciesEnum } from "../../enums/enums";
import { PermissionManager } from "../../common/utilities/permission-manager";
import type { IBranchResponse } from "../../interfaces/i-branch";
import { LiveLoginToken } from "../dialogs/live-login-token-model/live-login-token";
import { IdentityService } from "../../services/identity-service";
import type { IGetLiveLoginResponse } from "../../resources-vue/vue-interfaces/i-identity";

@customElement("dashboard-header")
@autoinject()
export class DashboardHeader {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public name: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showHelpMenu: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public showPatients: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public showEmployees: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showBorder: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showSearchIcons: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public showPatientSearch: boolean = false;
    private _authService: AuthService;
    private _permissionManager: PermissionManager;
    private _analyticsService: AnalyticsService;
    private _identityService: IdentityService;
    private _dialogService: DialogService;
    private readonly _branchService: BranchesService;
    private _logCategory: string = "Dashboard-Header";
    public showEmployeesMenu: boolean = false;
    public branches: IBranchResponse[] = [];
    public hasTestAgency: boolean = false;
    public showEmployeeSearch: boolean = false;
    public liveLoginData: IGetLiveLoginResponse;

    public constructor(
        authService: AuthService,
        Analytics: AnalyticsService,
        permissionManager: PermissionManager,
        branchService: BranchesService,
        identityService: IdentityService,
        dialogService: DialogService
    ) {
        this._authService = authService;
        this._analyticsService = Analytics;
        this._permissionManager = permissionManager;
        this._branchService = branchService;
        this._identityService = identityService;
        this._dialogService = dialogService;
    }

    @computedFrom(nameOf<DashboardHeader>("showPatients"))
    public get patientsTooltip() {
        return `Click to ${this.showPatients ? "hide" : "view"} your patients.`;
    }

    @computedFrom(nameOf<DashboardHeader>("showEmployees"))
    public get employeesTooltip() {
        return `Click to ${this.showEmployees ? "hide" : "view"} your employees.`;
    }

    public async attached() {
        const employeeInfoViewAcess =
            this._permissionManager.getPermmission(
                ParentPermissionEnum.employeeInformation,
                PermissionActionEnum.canView
            ) || [];
        if (employeeInfoViewAcess.length) {
            this.showEmployeesMenu = true;
        }
        this.branches = await this._branchService.getAllBranches();
        const testingAgencies = Object.values(TestingAgenciesEnum);
        this.hasTestAgency = this.branches.some((branch) => {
            return testingAgencies.includes(branch.accountId as TestingAgenciesEnum);
        });

        this.showEmployeeSearch = this.hasTestAgency;
        if (!this.showPatientSearch) {
            this.showPatientSearch = this.hasTestAgency;
        }

        this.liveLoginData = await this._identityService.getLiveLoginToken();
    }

    private logout(): void {
        this._authService.logout();
        this._analyticsService.logEvent({
            category: this._logCategory,
            action: "Log-Out",
            name: this.name
        });
    }

    private toggleEmployeesMenu() {
        this.showEmployees = !this.showEmployees;
        if (this.showPatients) {
            this.showPatients = false;
        }
    }

    private togglePatientsList(): void {
        this.showPatients = !this.showPatients;
        if (this.showEmployees) {
            this.showEmployees = false;
        }
        if (this.showPatients) {
            this._analyticsService.logEvent({
                category: this._logCategory,
                action: "Show-Patient-List",
                name: this.name
            });
        } else {
            this._analyticsService.logEvent({
                category: this._logCategory,
                action: "Hide-Patient-List",
                name: this.name
            });
        }
    }

    private displayLiveLoginToken() {
        let response = this.liveLoginData;
        if (response.statusCode == 200) {
            const options: DialogSettings = {
                viewModel: LiveLoginToken,
                model: {
                    response
                }
            };
            this._dialogService.open(options);
        }
    }
}
