import type { IPhoneNumber } from "../vue-interfaces/i-phone-number";

export class PhoneNumber implements IPhoneNumber {
    public id?: string;
    public number: string = "";
    public extension: string = "";
    public type: number = 0;
    public isPrimary: boolean = false;
    public country: string = "";

    public constructor(data?: IPhoneNumber) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
