import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PalliativeSourceOfReferralEnum {
    Facility = 1,
    Provider = 2,
    Other = 3,
    Internal = 4
}
class PalliativeSourceOfReferralClass extends EnumWithDescription<PalliativeSourceOfReferralEnum> {
    public readonly enum = PalliativeSourceOfReferralEnum;
    public readonly enumName: string = "PalliativeSourceOfReferralEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PalliativeSourceOfReferralEnum>> = new Map([
        [
            "Facility",
            {
                value: PalliativeSourceOfReferralEnum.Facility,
                name: "Facility"
            }
        ],
        [
            "Provider",
            {
                value: PalliativeSourceOfReferralEnum.Provider,
                name: "Provider"
            }
        ],
        [
            "Other",
            {
                value: PalliativeSourceOfReferralEnum.Other,
                name: "Other"
            }
        ],
        [
            "Internal",
            {
                value: PalliativeSourceOfReferralEnum.Internal,
                name: "Internal"
            }
        ]
    ]);
}

export const PalliativeSourceOfReferral = new PalliativeSourceOfReferralClass();
