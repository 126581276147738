import type { IRoles } from "../resources-vue/vue-interfaces/i-roles";
export class Role implements IRoles {
    public AgencyId: string;
    public LoginId: string;
    public UserId: string;
    public Application: number;
    public Roles: Array<number>;
    public isClinician: boolean = false;
    public isBiller: boolean = false;
    public isAdmin: boolean = false;
    public isScheduler: boolean = true; // TODO: need to get roles from product
    public canUseAxxessCare: boolean = false;
    public canUseSurvey: boolean = false;
    private _allowedAgencyCoreRolesForAxxessCare: Set<number> = new Set([
        1, // Administrator
        2, // Director of Nursing
        11, // Scheduler
        15 // Office Manager
    ]);

    public _allowedRolesForSurvey: Set<number> = new Set([
        1, // Administrator
        2, // Director of Nursing
        3, // Case Manager
        11, // Scheduler
        12, // Biller
        13, // Quality Assurance
        15 // Office Manager
    ]);

    public constructor(role: IRoles) {
        if (role) {
            Object.assign(this, role);
            this.identifyRole();
            this.checkSurveyRoles();
            this.initAxxessCareFlag();
        }
    }

    private initAxxessCareFlag() {
        if (this.Application === 1) {
            this.canUseAxxessCare = this.Roles.some((role) => {
                return this._allowedAgencyCoreRolesForAxxessCare.has(role);
            });
        }
    }

    private checkSurveyRoles() {
        this.canUseSurvey = this.Roles.some((role) => {
            return this._allowedRolesForSurvey.has(role);
        });
    }

    private identifyRole(): void {
        let roles: Array<number> = this.Roles;

        for (let role of roles) {
            switch (role) {
                case 1: // Administrator
                case 2: // Director of Nursing
                case 3: // Case Manager
                    this.isAdmin = true;
                    break;
                case 4: // Nursing
                // case 5: // Clerk
                case 6: // PT
                case 7: // OT
                case 8: // ST
                case 9: // MSW
                case 10: // HHA
                    this.isClinician = true;
                    break;
                // case 11: // Scheduler
                case 12: // Biller
                    this.isBiller = true;
                    break;
                // case 13: // Quality Assurance
                // case 14: // Physician
                // case 15: // Office Manager
                // case 16: // Community Liaison Officer
                // case 17: // External Referral Source
                // case 18: // Driver/Transporter
                // case 19: // Office Staff
                // case 20: // Auditor
            }
        }
    }
}

export const roleTranslationMap = new Map([
    [1, "Administrator"],
    [2, "Director of Nursing"],
    [3, "Case Manager"],
    [4, "Nursing"],
    [5, "Clerk"],
    [6, "PT"],
    [7, "OT"],
    [8, "ST"],
    [9, "MSW"],
    [10, "HHA"],
    [11, "Scheduler"],
    [12, "Biller"],
    [13, "Quality Assurance"],
    [14, "Physician"],
    [15, "Office Manager"],
    [16, "Community Liaison Officer"],
    [17, "External Referral Source"],
    [18, "Driver / Transporter"],
    [19, "Office Staff"],
    [20, "Auditor"]
]);
