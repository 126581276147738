import { FrameworkConfiguration } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";

export function configure(config: FrameworkConfiguration) {
    config.globalResources([
        // Custom Attributes
        PLATFORM.moduleName("./attributes/tooltip"),
        PLATFORM.moduleName("./attributes/number-only/number-only"),
        PLATFORM.moduleName("./attributes/popover/popover"),
        // Custom Elements
        PLATFORM.moduleName("./elements/card-template"),
        PLATFORM.moduleName("./elements/dashboard-header"),
        PLATFORM.moduleName("./elements/scroll-pagination"),
        PLATFORM.moduleName("./elements/pagination"),
        PLATFORM.moduleName("./elements/product-pill"),
        PLATFORM.moduleName("./elements/multi-select-filter"),
        PLATFORM.moduleName("./custom-elements/sortable-th/sortable-th"),
        PLATFORM.moduleName("./custom-elements/form-section/form-section"),
        PLATFORM.moduleName("./custom-elements/page-layout/page-layout"),
        PLATFORM.moduleName("./custom-elements/date-picker/date-picker"),
        PLATFORM.moduleName("./custom-elements/phone-number/phone-number"),
        PLATFORM.moduleName("./custom-elements/phone-numbers-component/phone-numbers-component"),
        PLATFORM.moduleName("./custom-elements/comment-input/comment-input"),
        PLATFORM.moduleName("./custom-elements/multiselect-dropdown/multiselect-dropdown"),
        PLATFORM.moduleName("./custom-elements/typeahead-input/typeahead-input"),
        PLATFORM.moduleName("./custom-elements/list-layout/list-layout"),
        PLATFORM.moduleName("./custom-elements/grouped-multi-select/grouped-multi-select"),
        PLATFORM.moduleName("./custom-elements/branch-default-filter/branch-default-filter"),
        PLATFORM.moduleName("./custom-elements/table-prompt/table-prompt"),
        PLATFORM.moduleName("./custom-elements/tabs-animated-header/tabs-animated-header"),
        PLATFORM.moduleName("./custom-elements/empty-text-handler/empty-text-handler"),
        PLATFORM.moduleName("./custom-elements/error-message-handler/error-message-handler"),
        PLATFORM.moduleName("./custom-elements/new-typeahead/new-typeahead"),
        PLATFORM.moduleName("./custom-elements/format-name/format-name"),
        PLATFORM.moduleName("./custom-elements/places-autocomplete/places-autocomplete"),
        PLATFORM.moduleName("./custom-elements/tags-input/tags-input"),
        PLATFORM.moduleName("./custom-elements/country-typeahead/country-typeahead"),
        PLATFORM.moduleName("./custom-elements/state-typeahead/state-typeahead"),
        PLATFORM.moduleName("./custom-elements/diagnosis/diagnosis"),
        PLATFORM.moduleName("./custom-elements/eligibility-check/eligibility-check"),
        PLATFORM.moduleName("./custom-elements/table-empty/table-empty"),
        PLATFORM.moduleName("./custom-elements/address-form/address-form"),
        PLATFORM.moduleName("./custom-elements/popover/popover"),
        PLATFORM.moduleName("./custom-elements/time-picker/time-picker"),
        PLATFORM.moduleName("./custom-elements/day-picker/day-picker"),
        PLATFORM.moduleName("./custom-elements/date-range-picker/date-range-picker"),
        PLATFORM.moduleName("./custom-elements/multi-date-picker/multi-date-picker"),
        PLATFORM.moduleName("./custom-elements/multi-date-picker-hh/multi-date-picker-hh"),

        // Value Converters
        PLATFORM.moduleName("./value-converters/format-date"),
        PLATFORM.moduleName("./value-converters/format-phone"),
        PLATFORM.moduleName("./value-converters/truncate-string"),
        PLATFORM.moduleName("./value-converters/get-product-name"),
        PLATFORM.moduleName("./value-converters/get-product-class"),
        PLATFORM.moduleName("./value-converters/object-converters"),
        PLATFORM.moduleName("./value-converters/sub-string-highlight"),
        PLATFORM.moduleName("./value-converters/product-converter"),
        PLATFORM.moduleName("./value-converters/format-ssn"),
        PLATFORM.moduleName("./value-converters/format-to-uppercase"),
        PLATFORM.moduleName("./value-converters/format-zip-code"),
        PLATFORM.moduleName("./value-converters/enums"),
        PLATFORM.moduleName("./value-converters/sanitize-markup"),
        PLATFORM.moduleName("./value-converters/format-time"),
        PLATFORM.moduleName("./value-converters/read-only-tasks"),
        PLATFORM.moduleName("./value-converters/messaging-service/markdown-to-html"),
        PLATFORM.moduleName("./value-converters/messaging-service/format-message"),

        // Global Forms
        PLATFORM.moduleName("./forms/inquiry-form/inquiry-form"),
        PLATFORM.moduleName("./forms/inquiry-form/home-care-inquiry-form"),
        PLATFORM.moduleName("./forms/reassign/employee-bulk-reassign"),
        PLATFORM.moduleName("./forms/reassign/patient-bulk-reassign")
    ]);
}
