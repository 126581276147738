import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";

import { useEditReferralHH } from "../../store/useEditReferralHH";

export default defineComponent({
    props: {
        index: {
            type: Number
        },
        address: {
            type: Object
        },
        showCode: {
            type: Boolean,
            default: false
        },
        addressType: {
            type: String,
            default: ""
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        isCountyRequired: {
            type: Boolean,
            default: false
        },
        showZipFourCode: {
            type: Boolean,
            default: true
        },
        stateClass: {
            type: String,
            default: ""
        },
        zipChanged: {
            type: Function
        },
        countyData:{
            type:Array<any>
        }
    },

    data(vm) {
        return {
            v$: useVuelidate(),

        };
    },

    validations() {
        return {
            address: {
                AddressLine1: {
                    required: helpers.withMessage(
                        this.addressType,
                        requiredIf(() => this.isRequired)
                    )
                },
                Zip: {
                    required: helpers.withMessage(
                        this.addressType,
                        requiredIf(() => this.isRequired)
                    )
                },
                County: {
                    required: helpers.withMessage(
                        this.addressType,
                        requiredIf(() => this.isCountyRequired)
                    )
                },
                State: {
                    required: helpers.withMessage(
                        this.addressType,
                        requiredIf(() => this.isRequired)
                    )
                },
                City: {
                    required: helpers.withMessage(
                        this.addressType,
                        requiredIf(() => this.isRequired)
                    )
                }
            }
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referralOptions: "referralOptions",
            errors: "errors",
        })
    },

    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        async handleZipChange() {
            if (
                this.addressType === "AdditionalEmergencyContacts0" ||
                this.addressType === "AdditionalEmergencyContacts1" ||
                this.addressType === "AdditionalEmergencyContacts2"
            ) {
                this.zipChanged(this.index);
            } else {
                this.zipChanged();
                this.handleAllInputChange("Zip", this.address.Zip);
            }
        },
        getErrors(field: string) {
            if (this.addressType === "Primary Address") {
                return this.errors.PrimaryAddress.includes(field) ? true : false;
            } else if (this.addressType === "Mailing Address") {
                return this.errors.MailingAddress.includes(field) ? true : false;
            } else {
                return false;
            }
        },
        handleAllInputChange(field: any, value: any) {
            if (value.length || value !== null) {
                const newErrors = [...new Set(this.errors[this.addressType])].filter((val: any) => val !== field);
                const payload = {
                    property: this.addressType,
                    value: newErrors
                };
                this.setErrors(payload);
            }
        }
    }
});
