export class PhysicianRequired {
    public physicians: string[];
    public isPrimary: boolean;
    [key: string]: any;

    public constructor(data?: PhysicianRequired) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
