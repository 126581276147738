
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        text: { type: String, default: "" },
        isError: { type: Boolean, default: false },
        exceptionMessage: { type: String, default: "Not Available" }
    }
});
