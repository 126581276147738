import "../../../resources/attributes/tooltip.scss";
import "popper.js";

function destroyTooltip(element: any) {
    if (!element) {
        return;
    }
    element.tooltip("dispose").off("mouseenter").off("mouseleave");
}

function titleChanged(element: any, title: any, showOnChange = false): void {
    if (!element) {
        return;
    }
    element.tooltip("hide");
    element.attr("data-original-title", title);
    if (showOnChange.toString() === "true") {
        element.tooltip("show");
    }
}

function attachToolTip(element: any, data: any) {
    if (!data?.title || !element) {
        return;
    }
    element = $(element);

    let options: any = {
        animation: data?.animation || true,
        container: data?.container || "body",
        delay: data?.delay || 0,
        html: data?.html || true,
        placement: data?.placement || "auto",
        title: data?.title,
        trigger: data?.trigger || "hover focus"
    };

    if (data?.className) {
        options.template = `<div class="tooltip ${data.className}" role="tooltip">
                                    <div class="arrow"></div>
                                    <div class="tooltip-inner"></div>
                                </div>`;
    }
    element.destroyTooltip?.();
    if (options.trigger === "manual") {
        element
            .tooltip(options)
            .tooltip("hide")
            .on("mouseenter", () => {
                element.tooltip("show");
                // If hidewait is set, hide after hidewait seconds
                // even if the mouse is hovering over the content
                let hideWait = data?.hideWait || 0;
                if (hideWait) {
                    let hideWaitTimer = setTimeout(() => {
                        element.tooltip("hide");
                        clearTimeout(hideWaitTimer);
                    }, hideWait);
                }
            })
            .on("mouseleave", () => {
                element.tooltip("hide");
            });
    } else {
        element.tooltip(options);
    }
}

export const tooltip = {
    mounted: (el: HTMLElement, binding: any) => {
        attachToolTip(el, binding.value);
    },

    updated: (el: HTMLElement, binding: any) => {
        if (binding.value?.title) {
            titleChanged($(el), binding.value?.title);
        }
    },

    unmounted: (el: HTMLElement, binding: any) => {
        destroyTooltip($(el));
    }
};
