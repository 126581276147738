import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum GenderIdentityEnum {
    Male = 1,
    Female = 2,
    FemaleToMaleTransgender = 3,
    MaleToFemaleTransgender = 4,
    Genderqueer = 5,
    AdditionalGender = 6,
    NotToDisclose = 7
}
class GenderIdentityClass extends EnumWithDescription<GenderIdentityEnum> {
    public readonly enum = GenderIdentityEnum;
    public readonly enumName: string = "GenderIdentityEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<GenderIdentityEnum>> = new Map([
        [
            "Male",
            {
                value: GenderIdentityEnum.Male,
                name: "Male"
            }
        ],
        [
            "Female",
            {
                value: GenderIdentityEnum.Female,
                name: "Female"
            }
        ],
        [
            "FemaleToMaleTransgender",
            {
                value: GenderIdentityEnum.FemaleToMaleTransgender,
                name: "Female-to-Male (FTM)/Transgender Male/Trans Man"
            }
        ],
        [
            "MaleToFemaleTransgender",
            {
                value: GenderIdentityEnum.MaleToFemaleTransgender,
                name: "Male-to-Female (MTF)/Transgender Female/Trans Woman"
            }
        ],
        [
            "Genderqueer",
            {
                value: GenderIdentityEnum.Genderqueer,
                name: "Genderqueer, neither exclusively male nor female"
            }
        ],
        [
            "AdditionalGender",
            {
                value: GenderIdentityEnum.AdditionalGender,
                name: "Additional gender category or other"
            }
        ],
        [
            "NotToDisclose",
            {
                value: GenderIdentityEnum.NotToDisclose,
                name: "Choose not to disclose"
            }
        ]
    ]);
}

export const GenderIdentities = new GenderIdentityClass();
