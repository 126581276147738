import { computed, defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";
import { UserManager } from "oidc-client";
import moment from "moment";

import { AccessService } from "../../services/access.service";
import AuthService from "../../services/auth.service";
import { HttpService } from "../../services/http.service";
import { RolesService } from "../../services/roles.service";
import { AgencyService } from "../../services/agency.service";
import IdleTimerService from "../../services-vue/idle-timer.service";
import { UserTitlesSurveyEnum } from "../../enums/UserTitlesSurveyEnum";

import type { IRoles } from "../../resources-vue/vue-interfaces/i-roles";
import { IAgency } from "../../resources-vue/vue-interfaces/i-agency";
import { GroupedAgencies } from "../../resources-vue/vue-models/agency";
import { Role } from "../../models/role.model";
import { OidcConfiguration } from "../../common/oidc-configuration";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { MarketingContent } from "../../pages/app/marketing-content";
import { AppCache } from "../../pages/app/app-cache";
import { checkPermission } from "../../common/vue-helpers/vue-router";
import eventBus from "../../utilities/eventBus";
import { useLoadingIndicator } from "../Common/LoadingIndicator/store/useLoadingIndicator";
import { AgencyEvents } from "./components/AgencyList/AgencyList";

import LoadingIndicator from "../../pages-vue/Common/LoadingIndicator/LoadingIndicator.vue";
import AppNav from "./components/AppNav/AppNav.vue";
import AgencyList from "./components/AgencyList/AgencyList.vue";
import CentralNav from "./components/CentralNav/CentralNav.vue";
import EmployeeList from "../Common/EmployeeList/EmployeeList.vue";
import PatientList from "../Common/PatientList/PatientList.vue";
import SurveyModal from "../../resources-vue/vue-dialogs/SurveyModal/SurveyModal.vue";
import Notification from './components/Notification/Notification.vue';
import { useApp } from "./useApp";
import { jitsiRoomHandler } from "../../utilities/jitsiRoomHandler";

export default defineComponent({
    components: {
        LoadingIndicator,
        AppNav,
        AgencyList,
        CentralNav,
        EmployeeList,
        PatientList,
        Notification
    },

    data(vm) {
        return {
            _rolesService: null,
            _authService: null,
            _idleTimerService: null,
            _httpService: null,
            _marketingContent: null,
            _oidcConfiguration: null,
            _accessService: null,
            _appCache: null,
            _permissionManager: null,
            _userManager: null,
            dialogRef: null,
            _branchesService: null,
            _agencyService: null,
            roles: [],
            titles: [],
            router: null,
            areRolesLoaded: false,
            isLoggedIn: false,
            startApp: false,
            checkAccess: null,
            showAside: false,
            showEmployees: false,
            showPatients: false,
            groupedAgencies: new Map<string, GroupedAgencies>(),
            hasError: false,
            isLoading: true,
            lisitingsCompleted: 0,
            totalListingCount: 1,
            appId: null,
            userOs: ""
        };
    },

    async created() {
        let os = navigator.userAgent;
        if (os.search("Windows") !== -1) {
            this.userOs = "Windows";
        }
        window.sessionStorage.setItem("userOs", this.userOs);
        this._rolesService = Container.instance.get(RolesService);
        this._authService = Container.instance.get(AuthService);
        this._httpService = Container.instance.get(HttpService);
        this._httpService.configureFetch();
        this._oidcConfiguration = Container.instance.get(OidcConfiguration);
        this._accessService = Container.instance.get(AccessService);
        this._marketingContent = Container.instance.get(MarketingContent);
        this._appCache = Container.instance.get(AppCache);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._userManager = Container.instance.get(UserManager);
        this._agencyService = Container.instance.get(AgencyService);
        this._idleTimerService = new IdleTimerService();
        await this.initUser();
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    watch: {
        async $route(newPath) {
            if (this.startApp) {
                if (this.checkAccess && !this.checkAccess(newPath)) {
                    this.$router.push("/help");
                }
            }
            this.$route.path.includes("/axxess-central") ? (this.showAside = false) : (this.showAside = true);
            this.$route.path.includes("/axxess-central")
                ? (this.appId = "central-app")
                : (this.appId = "central-planner");
        },

        areRolesLoaded() {
            this.areRolesLoadedChanged();
        }
    },
    computed: {
        showContainer() {
            return {
                "container-fluid": !this.$route.path.includes("/axxess-central")
            };
        }
    },
    async mounted() {
        try {
            this.showLoading(false);
            await this._idleTimerService.timer(this.$dialog);
            await this.startMarketingContent();
        } catch (error) {
            console.error(error);
        } finally {
            this.showLoading(false);
        }

        this.areRolesLoadedChanged();

        eventBus.on("joinMeeting", async (roomId: string) => {
            await jitsiRoomHandler(this, roomId)
        });
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        ...mapActions(useApp, { setRoles: "SET_ROLES", setCookie: "setCookie" }),
        async initUser() {
            let isUserLoggedIn = await this._authService.isLoggedIn();
            if (!isUserLoggedIn) {
                return;
            }
            await this._permissionManager.refreshUserPermissionData();
            this.isLoggedIn = true;
            this.roles = await this.getUserRoles();
            this.setRoles(this.roles);
            if (localStorage.getItem("axxess-user-location") != null) {
                if (Object.keys(JSON.parse(localStorage.getItem("axxess-user-location"))).length) {
                    this.setCookie();
                }
            }
            this.titles = await this._rolesService.getTitles();
            this._accessService.initAccessRoles(this.roles);
            let accessRoles = this._accessService.getAccessRoles();
            await this._appCache.restoreMissingCache();
            // Adding this property to pass to app-nav to know when roles are loaded
            this.areRolesLoaded = true;

            // this check will validate user's access to routes;
            this.checkAccess = checkPermission(this._permissionManager, await this._userManager.getUser());
            if (!this.checkAccess(this.$route)) {
                this.$router.push("/help");
            }
            const isAfter = moment("2024-05-27", "YYYY-MM-DD").isAfter(moment(), "day");
            let isBefore = moment().isBefore(moment("2024-05-14", "YYYY-MM-DD"), "day");
            let titleMatchedForSurvey = this.checkTitles(this.titles);
            const user = await this._userManager.getUser();
            const userEmail = user.profile.email;
            let isTestUser: boolean = false;
            /* the below if condition is implemented to help QA test this survey
            popup before the actual survey time starts */
            if (userEmail === "qatesteraxxess@yahoo.com" || userEmail === "ptale@axxess.com") {
                isTestUser = true;
                isBefore = moment().isBefore(moment("2024-04-16", "YYYY-MM-DD"), "day");
                titleMatchedForSurvey = true;
            }
            // Check if any titles exist in the backend response
            if (!isBefore && isAfter && (accessRoles.canUseSurvey || titleMatchedForSurvey)) {
                setTimeout(() => {
                    const surveyCancelCount = localStorage.getItem("surveyCancelCount-" + userEmail);
                    let surveyCancelCountParsed = surveyCancelCount ? JSON.parse(surveyCancelCount) : 0;
                    const surveyReopenTime = localStorage.getItem("surveyReopenTime-" + userEmail);
                    let surveyReopenTimeParsed = surveyReopenTime ? JSON.parse(surveyReopenTime) : null;
                    const canShowModal = surveyReopenTimeParsed
                        ? moment().isAfter(moment(surveyReopenTimeParsed))
                        : true;
                    if (surveyCancelCountParsed < 3 && canShowModal) {
                        this.dialogRef = this.$dialog.open(SurveyModal, {
                            props: {
                                modal: true,
                                closeOnEscape: false,
                                showHeader: false
                            },
                            onClose: async (options) => {
                                if (options.data.wasCancelled) {
                                    surveyReopenTimeParsed = moment().add(2, "days").toDate();
                                    if (isTestUser) {
                                        surveyReopenTimeParsed = moment().add(30, "minutes").toDate();
                                    }
                                    if (options.data.remindMeLater) {
                                        surveyCancelCountParsed = 0;
                                        surveyReopenTimeParsed = moment().add(1, "days").toDate();
                                        if (isTestUser) {
                                            surveyReopenTimeParsed = moment().add(15, "minutes").toDate();
                                        }
                                    }
                                    localStorage.setItem("surveyCancelCount-" + userEmail, "1");
                                    localStorage.setItem(
                                        "surveyReopenTime-" + userEmail,
                                        JSON.stringify(surveyReopenTimeParsed)
                                    );
                                } else {
                                    localStorage.setItem("surveyReopenTime-" + userEmail, null);
                                    localStorage.setItem("surveyCancelCount-" + userEmail, "3");
                                    window.open("https://www.surveymonkey.com/r/PNTZPMN", "_blank");
                                }
                            }
                        });
                    }
                }, 5000);
            }
            this.startApp = true;
        },

        titlesExistInEnum(titles: string[]): boolean {
            return titles.some((title) => Object.values(UserTitlesSurveyEnum).includes(title as UserTitlesSurveyEnum));
        },

        checkTitles(response: any[]): boolean {
            for (const entry of response) {
                if (this.titlesExistInEnum(entry.titles)) {
                    return true; // If match found, return true
                }
            }
            return false; // If no match found, return false
        },

        async getUserRoles() {
            try {
                let roles: Array<IRoles> = await this._rolesService.getRoles();
                return roles.map((role) => new Role(role));
            } catch (e) {
                // Roles endpoint should not be a bottle neck.
                // By returning an empty array, the user will at least see the help page.
                // Where they can select an agency.
                return [];
            }
        },

        async startMarketingContent() {
            await this._marketingContent?.start();
        },

        async areRolesLoadedChanged() {
            if (this.areRolesLoaded) {
                await this.loadAgencies();
            }
        },

        async loadAgencies(): Promise<void> {
            try {
                this.hasError = false;
                this.isLoading = true;
                this._agencyService
                    .getAgencyLisiting()
                    .then((res: IAgency[]) => {
                        this.lisitingsCompleted++;
                        this.groupedAgencies = new Map([...this._agencyService.getAgenciesByProduct(this.roles)]);
                        if (this.totalListingCount == this.lisitingsCompleted) {
                            this.handlePromisesCompletion();
                        }
                    })
                    .catch((err: Error) => {
                        console.warn(err);
                        this.lisitingsCompleted++;
                        if (this.totalListingCount == this.lisitingsCompleted) {
                            this.handlePromisesCompletion();
                        }
                    });

                const promises = await this._agencyService.getAccountsLisiting();
                this.totalListingCount += promises.length;
                promises.forEach((singlePromise: Promise<IAgency[]>) => {
                    singlePromise
                        .then((res) => {
                            this.groupedAgencies = new Map([...this._agencyService.getAgenciesByProduct(this.roles)]);
                            this.lisitingsCompleted++;
                            if (this.totalListingCount == this.lisitingsCompleted) {
                                this.handlePromisesCompletion();
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                            this.lisitingsCompleted++;
                            if (this.totalListingCount == this.lisitingsCompleted) {
                                this.handlePromisesCompletion();
                            }
                        });
                });
            } catch (e) {
                console.error(e);
                this.hasError = true;
            } finally {
                this.isLoading = false;
                this.showLoading(false);
            }
        },

        async handlePromisesCompletion() {
            if (!this.groupedAgencies.size) {
                this.hasError = true;
                this.$router.push("/help");
            } else {
                this._agencyService.getProducts(null);
                this._agencyService.setAgenciesLoaded(true);
                this._agencyService.setFlags();
                eventBus.emit(AgencyEvents.Loaded);
            }
        }
    }
});
