import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum EmployeesScheduledFilterEnum {
    AllEmployeess = 0,
    EmployeesWithSchedules = 1,
    EmployeesWithoutSchedules = 2
}

export class EmployeesScheduledFilterClass extends EnumWithDescription<EmployeesScheduledFilterEnum> {
    public readonly enum = EmployeesScheduledFilterEnum;
    public readonly enumName: string = "EmployeesScheduledFilterEnum";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<EmployeesScheduledFilterEnum>> = new Map([
        [
            "AllEmployeess",
            {
                value: EmployeesScheduledFilterEnum.AllEmployeess,
                name: "All Employees"
            }
        ],
        [
            "EmployeesWithSchedules",
            {
                value: EmployeesScheduledFilterEnum.EmployeesWithSchedules,
                name: "Employees with schedules"
            }
        ],
        [
            "EmployeesWithoutSchedules",
            {
                value: EmployeesScheduledFilterEnum.EmployeesWithoutSchedules,
                name: "Employees without schedules"
            }
        ]
    ]);
}

export const EmployeesScheduledFilter = new EmployeesScheduledFilterClass();
