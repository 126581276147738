import { computed, defineComponent, PropType } from "vue";
import { mapActions } from "pinia";
import moment from "moment";
import { Container } from "aurelia-dependency-injection";
import { CalendarOptions, EventContentArg } from "@fullcalendar/core";

import { IRescheduleValidations } from "../../../../../resources/dialogs/reschedule-validations/reschedule-validations";

import { TaskService, type ITaskByUserResponse } from "../../../../../services/task.service";
import { ToastrService } from "../../../../../services/toastr.service";
import { BranchesService } from "../../../../../services/branches-service";
import { NavigationService } from "../../../../../services/navigation.service";
import { LookupService } from "../../../../../services/lookup-service";

import {
    ApplicationEnum,
    ApplicationNameEnum,
    EmployeeStatusEnum,
    ParentPermissionEnum,
    PermissionActionEnum,
    TestingAgenciesEnum,
    VisitStatusEnum
} from "../../../../../enums/enums";

import { CalendarEmployeeModel } from "../../../../../pages/central-calendar/calendar-employee-model";
import { BulkReassign } from "../../../../../resources-vue/vue-models/bulk-reassign";
import { RescheduleTask } from "../../../../../models/task.model";

import {
    IVisitDetails,
    IRescheduleVisitHosPal,
    IReassignVisitHC,
    IHandleTaskReschuleIssue
} from "../../../../../resources-vue/vue-interfaces/i-visit-details";
import {
    IDisciplineTaskHH,
    IGetDisciplineTaskHHParams
} from "../../../../../resources-vue/vue-interfaces/i-discipline-task-hh";
import { IReassignVisitDetails } from "../../../../../resources-vue/vue-interfaces/i-reassign-visit";

import { adjustDate } from "../../../../../common/adjust-date";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { useLoadingIndicator } from "../../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { formatName } from "../../../../../common/format-name";
import config from "../../../../../common/config";
import { FormatPhoneNumber } from "../../../../../resources/value-converters/format-phone";
import { GetProductClass } from "../../../../../resources/value-converters/get-product-class";
import { ScheduleLegendState } from "../../../../../pages/central-calendar/central-calendar";
import { formatScheduledTime, formatTimeInOut } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import ScheduleVisitEmployee from "../../../../../resources-vue/vue-dialogs/ScheduleVisit/ScheduleVisitEmployee.vue";
import FullCalendar from "../../../../../resources-vue/vue-elements/FullCalendar/FullCalendar.vue";
import RescheduleValidation from "../../../../../resources-vue/vue-dialogs/RescheduleValidations/RescheduleValidation.vue";
import ReassignVisit from "../../../../../resources-vue/vue-dialogs/ReassignVisit/ReassignVisit.vue";
import VisitDetails from "../../../../../resources-vue/vue-dialogs/VisitDetails/VisitDetails.vue";

export default defineComponent({
    props: {
        startDate: { type: Date },
        endDate: { type: Date },
        usersList: { type: Array as PropType<CalendarEmployeeModel[]>, default: [] },
        tasksList: { type: Array, default: [] },
        tasksMap: { default: null },
        onCalendarRefresh: { type: Function }
    },

    components: {
        FullCalendar
    },

    data() {
        return {
            isLoading: false as boolean,
            _navigationService: null,
            _permissionManager: null,
            _taskService: null,
            _toastrService: null,
            _branchService: null,
            _lookupService: null,
            dialogRef: null,
            editable: true,
            disciplineTasks: [] as IDisciplineTaskHH[],
            // NOTE: eventLimit be one more than the required events, as to account for dummy event
            eventLimit: 4 as number,
            userNamesMap: null,
            isTestAgency: false as boolean,
            showEditBtn: false as boolean,
            isReassignAllowed: false as boolean,
            isEditVisitDetailsAllowed: false as boolean,
            employeeStatuses: Object.create(EmployeeStatusEnum)
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._permissionManager = Container.instance.get(PermissionManager);
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._branchService = Container.instance.get(BranchesService);
        this._navigationService = Container.instance.get(NavigationService);
        this._lookupService = Container.instance.get(LookupService);
    },

    mounted() {
        this.isReassignAllowed = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canReassign
        );
        this.isEditVisitDetailsAllowed = this._permissionManager.checkPermission(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canEdit
        );
        this.showEditBtn = this.isReassignAllowed || this.isEditVisitDetailsAllowed;
    },

    computed: {
        userDetails() {
            let userEntries: any[] = [];
            this.userNamesMap = new Map();
            this.usersList?.forEach((user) => {
                user.Agencies.forEach((op) => {
                    let userData = {
                        ...user,
                        ...op,
                        groupId: user.Id,
                        id: `${op.UserId}${op.Application}`
                    };
                    userEntries.push(userData);
                });
                this.userNamesMap.set(user.Id, formatName(user.FirstName, user.LastName));
            });
            return userEntries ?? [];
        },

        taskActions() {
            let tasksMap = new Map();
            this.tasksList.forEach((task: any) => {
                let startDate = new Date(Date.parse(task.StartDate));
                if (task.Application === 256) {
                    task.Application = ApplicationEnum.AxxessHospice;
                }
                let key = `${task.UserId}${startDate.toLocaleDateString()}`;
                if (!tasksMap.has(key)) {
                    tasksMap.set(key, [task]);
                } else {
                    let currentTasks = tasksMap.get(key);
                    tasksMap.set(key, [...currentTasks, task]);
                }
            });
            let tasksList: ITaskByUserResponse[] = [];
            tasksMap.forEach((value: any, key: any) => {
                tasksList.push(...value);
                // let list = value.slice(0, this.eventLimit);
                // tasksList = tasksList.concat([...list]);
                // if (value.length > this.eventLimit) {
                //     let extraTask = {
                //         Id: value[0].Id,
                //         UserId: value[0].UserId,
                //         StartDate: moment(value[0].StartDate).endOf("day").format(),
                //         EndDate: value[0].StartDate,
                //         Application: value[0].Application,
                //         isExtraEvent: true
                //     } as ITaskByUserResponse;
                //     tasksList.push(extraTask);
                // }
            });
            return (
                tasksList?.map((task) => ({
                    ...task,
                    id: task.Id,
                    start: task.StartDate,
                    end: moment(task.EndDate).endOf("day").format(),
                    resourceId: `${task.UserId}${task.Application}`
                })) ?? []
            );
        },

        calendarOptions(): CalendarOptions {
            return {
                resources: this.userDetails,
                resourceOrder: "LastName, FirstName",
                events: this.taskActions,
                resourceGroupField: "groupId",
                resourceAreaHeaderContent: "Employees",
                resourceAreaWidth: 220,
                droppable: true,
                eventDragStart: (event: any) => {
                    $('[role="tooltip"]')?.remove();
                },
                eventDrop: async (event: any) => {
                    $('[role="tooltip"]')?.remove();
                    const rowChanged = !(
                        JSON.stringify(event.oldEvent._def.resourceIds) == JSON.stringify(event.event._def.resourceIds)
                    );
                    if (rowChanged) {
                        event.revert();
                        return;
                    }
                    if (!event?.event?.extendedProps || !event?.event?.startStr) {
                        this._toastrService.error({
                            title: `Error - Rescheduling task`,
                            message: `There was an error rescheduling the visit. Please Try again later.`
                        });
                        this.$emit("calendarRefresh");
                        return;
                    }
                    const visitDetails = event.event.extendedProps;
                    const lastDay = moment(visitDetails.StartDate).endOf("week").format("YYYYMMDD");

                    if (
                        visitDetails.StartDate != visitDetails.EndDate &&
                        moment(event.event.start).format("YYYYMMDD") == lastDay
                    ) {
                        this._toastrService.error({
                            title: `Error - Rescheduling task`,
                            message: `Midnight split visit will not get reschedule to the last day of the week`
                        });
                        this.$emit("calendarRefresh");
                        return;
                    }

                    if (
                        [
                            ApplicationEnum.AxxessHospiceFE,
                            ApplicationEnum.AxxessHospice,
                            ApplicationEnum.AxxessPalliative
                        ].includes(visitDetails.Application)
                    ) {
                        let startTime = visitDetails.StartTime
                            ? moment(visitDetails.StartTime).format("HH:mm:ss")
                            : "00:00:00";
                        let endTime = visitDetails.EndTime
                            ? moment(visitDetails.EndTime).format("HH:mm:ss")
                            : "00:00:00";
                        this.rescheduleVisitHosPal(
                            visitDetails,
                            `${moment(event.event.start).format("MM/DD/YYYY")} ${startTime}`,
                            `${moment(event.event.end).format("MM/DD/YYYY")} ${endTime}`
                        );
                    } else if (visitDetails.Application == ApplicationEnum.HomeCare) {
                        let timeIn = visitDetails.StartTime || visitDetails.timeIn;
                        timeIn = timeIn ? moment(timeIn).format("HH:mm:ss") : "00:00:00";
                        let timeOut = visitDetails.EndTime || visitDetails.timeOut;
                        timeOut = timeOut ? moment(timeOut).format("HH:mm:ss") : "00:00:00";
                        const newStart = moment(event.event.start).format("YYYY-MM-DD");
                        const newEnd = moment(event.event.end).format("YYYY-MM-DD");
                        this.rescheduleVisitHomeCare(
                            event,
                            visitDetails,
                            `${newStart}T${timeIn}`,
                            `${newEnd}T${timeOut}`
                        );
                    } else {
                        let timeIn = visitDetails.StartTime || visitDetails.timeIn;
                        timeIn = timeIn ? moment(timeIn).format("HH:mm:ss") : "00:00:00";
                        let timeOut = visitDetails.EndTime || visitDetails.timeOut;
                        timeOut = timeOut ? moment(timeOut).format("HH:mm:ss") : "00:00:00";
                        const newStart = moment(event.event.start).format("YYYY-MM-DD");
                        const newEnd = moment(event.event.end).format("YYYY-MM-DD");
                        const status = moment().format("YYYY-MM-DD") < newStart ? "410" : "";
                        const customId = visitDetails.CustomId;
                        const taskName = visitDetails.TaskName;

                        this.rescheduleVisit(
                            visitDetails,
                            moment(event.event.start).format("MM/DD/YYYY"),
                            `${newStart}T${timeIn}`,
                            `${newEnd}T${timeOut}`,
                            status,
                            customId,
                            taskName
                        );
                    }
                },

                eventContent: (arg: EventContentArg) => {
                    const eventData = arg?.event?.extendedProps;
                    let extraEvents = 0;
                    if (![VisitStatusEnum.NotStarted, VisitStatusEnum.NotYetDue].includes(eventData.Status)) {
                        arg.event._def.ui.constraints = [false];
                    }
                    if (eventData?.isExtraEvent) {
                        let startDate = new Date(Date.parse(eventData?.StartDate));
                        let key = `${eventData.UserId}${startDate.toLocaleDateString()}`;
                        let currentTaskList = this.tasksMap.get(key);
                        extraEvents = currentTaskList.length - this.eventLimit;
                    }
                    return {
                        html: this.generateEvent(arg, extraEvents)
                    };
                },
                eventDidMount: (arg: any) => {
                    $('[role="tooltip"]')?.remove();
                    const self = this;
                    if (!!$(arg?.el).find(".more-btn").length) {
                        this.attachMorePopover(arg);
                        return;
                    }
                    if (!!$(arg?.el).find(".non-visit-bg").length) {
                        this.attachNonVisitTooltip(arg);
                        return;
                    }
                    this.attachTooltip(arg);

                    const scheduledEvent = arg?.event?.extendedProps;
                    const reassignBtn = `btn-reassign-${scheduledEvent.Id}`;
                    const visitDetailsBtn = `btn-visitdetails-${scheduledEvent.Id}`;

                    if (!!document.getElementById(reassignBtn)) {
                        document.getElementById(reassignBtn).addEventListener("click", () => {
                            // adding this click event listener manually because calling onclick event directly causes issue since DOM loads first before the JS.
                            self.scheduledVisit(arg);
                        });
                    }

                    if (!!document.getElementById(visitDetailsBtn)) {
                        document.getElementById(visitDetailsBtn).addEventListener("click", () => {
                            self.showVisitDetails(arg);
                        });
                    }

                    // click event listener for edit icon
                    const editButtonId = `edit-btn-${scheduledEvent.Id}`;
                    if (!!document.getElementById(editButtonId)) {
                        document.getElementById(editButtonId).addEventListener("click", () => {
                            $('[role="tooltip"]')?.remove();

                            self.tasksList.forEach((task: any) => {
                                const selectorElement = $(`#scheduled-event-${task.Id}`).closest("a")[0];
                                if (selectorElement) {
                                    if (task.Id != scheduledEvent.Id) {
                                        selectorElement.style.zIndex = "0";
                                    } else {
                                        selectorElement.style.zIndex = "5";
                                    }
                                }
                            });
                        });
                    }

                    // Permission Logic
                    const editBtnId = `edit-icon-${scheduledEvent.Id}`;
                    let showEditButtonForVisit: boolean = false;
                    let showReassignButtonForVisit: boolean = false;
                    showReassignButtonForVisit =
                        self.isReassignAllowed &&
                        (scheduledEvent.Status == VisitStatusEnum.NotStarted ||
                            scheduledEvent.Status == VisitStatusEnum.NotYetDue);
                    let showVisitDetailsButtonForVisit: boolean = false;
                    showVisitDetailsButtonForVisit =
                        self.isEditVisitDetailsAllowed &&
                        (scheduledEvent.Application == ApplicationEnum.AgencyCore ||
                            scheduledEvent.Application == ApplicationEnum.HomeCare ||
                            scheduledEvent.Application == ApplicationEnum.AxxessPalliative ||
                            scheduledEvent.Application == ApplicationEnum.AxxessHospice) &&
                        Object.values(TestingAgenciesEnum).includes(scheduledEvent.AgencyId);
                    showEditButtonForVisit =
                        self.showEditBtn && (showReassignButtonForVisit || showVisitDetailsButtonForVisit);
                    if (!!document.getElementById(editBtnId)) {
                        if (!showEditButtonForVisit) {
                            document.getElementById(editBtnId).style.display = "none";
                            return;
                        }
                        if (!!document.getElementById(reassignBtn) && !showReassignButtonForVisit) {
                            document.getElementById(reassignBtn).style.display = "none";
                        }
                        if (!!document.getElementById(visitDetailsBtn) && !showVisitDetailsButtonForVisit) {
                            document.getElementById(visitDetailsBtn).style.display = "none";
                        }
                    }
                },
                // NOTE: Uncomment when task navigation is needed
                // eventClick: (event: any) => {
                //     if (!$(event.el).find(".more-btn").length) {
                //         this.handleEventClick(event);
                //     }
                // },
                resourceLabelContent: (arg: any) => {
                    $('[role="tooltip"]')?.remove();
                    return {
                        html: this.generateResource(arg)
                    };
                },
                resourceLabelDidMount: (arg: any) => {
                    this.registerClick(arg);
                },
                resourceGroupLabelContent: (arg: any) => {
                    return {
                        html: this.generateGroupLabel(arg)
                    };
                },
                visibleRange: {
                    start: this.startDate,
                    end: adjustDate(this.endDate, 1)
                }
            };
        }
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        generateGroupLabel(arg: any) {
            let groupId = arg?.groupValue;
            let employeeName = this.userNamesMap?.get(groupId);
            let labelContent = "";
            labelContent = `<span class="text-dark font-weight-bold"> ${employeeName} </span>`;
            return labelContent;
        },

        async scheduledVisit(arg: any) {
            const taskDetails = arg?.event?.extendedProps;
            const userDetails = this.usersList.find((user) =>
                user.Agencies.some((Agency) => Agency.UserId == taskDetails.UserId)
            );
            const additionalInfo = {
                AgencyName: userDetails.Agencies[0].AgencyName,
                FirstName: taskDetails.PatientFirstName,
                LastName: taskDetails.PatientLastName,
                UserFirstName: userDetails.FirstName,
                UserLastName: userDetails.LastName
            };
            let visitDetails: IReassignVisitDetails = Object.assign({}, additionalInfo, taskDetails);
            visitDetails.Application = visitDetails.Application == -3 ? 256 : visitDetails.Application;

            this.dialogRef = this.$dialog.open(ReassignVisit, {
                props: { modal: true, showHeader: false },
                data: {
                    visitDetails
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        this.$emit("calendarRefresh");
                    }
                }
            });
        },

        async showVisitDetails(arg: any) {
            const taskDetails = arg?.event?.extendedProps as IVisitDetails;
            const visitDetails: IVisitDetails = Object.assign({}, taskDetails);
            visitDetails.isEmployeeCalendar = true;
            if (visitDetails.Application == ApplicationEnum.AxxessHospice) {
                visitDetails.Application = 256;
            }
            this.dialogRef = this.$dialog.open(VisitDetails, {
                props: {
                    modal: true
                },
                data: {
                    visitDetails: visitDetails
                },
                onClose: (response) => {
                    if (response.data === "success") {
                        this.$emit("calendarRefresh");
                    }
                }
            });
        },

        getStatusText(status: number): string {
            let result = this.employeeStatuses[status].toLowerCase();

            if (result.includes("pending")) {
                return "Pending";
            } else if (result.includes("terminate")) {
                return "Terminated";
            }
            return result;
        },

        async rescheduleVisit(
            event: IVisitDetails,
            date: string,
            StartDate: string,
            EndDate: string,
            status: string,
            customId: number,
            taskName: string
        ) {
            const visitDetails = event;
            const rescheduleVisitDetails = new RescheduleTask();
            rescheduleVisitDetails.fillRequiredDetails(visitDetails);
            rescheduleVisitDetails.EventDate = rescheduleVisitDetails.VisitDate = date || null;
            rescheduleVisitDetails.Status = status;
            rescheduleVisitDetails.TimeIn = StartDate;
            rescheduleVisitDetails.TimeOut = EndDate;
            await this.getDisciplineTasks(visitDetails);
            const disciplineData = this.disciplineTasks.filter(
                (item: any) => item.customId === customId && item.taskName === taskName
            )[0];
            rescheduleVisitDetails.Discipline = disciplineData.discipline;
            rescheduleVisitDetails.DisciplineTask = disciplineData.disciplineTask;
            rescheduleVisitDetails.CustomId = Number(disciplineData.customId);

            if (rescheduleVisitDetails.EventDate) {
                try {
                    await this._taskService.rescheduleTask(rescheduleVisitDetails);
                    this._toastrService.success({
                        title: `Success`,
                        message: `The visit has been successfully rescheduled.`
                    });
                } catch (error) {
                    this._toastrService.error({
                        title: `Error - Rescheduling task`,
                        message: `There was an error rescheduling the visit. Please Try again later.`
                    });
                } finally {
                    this.$emit("calendarRefresh");
                }
            } else {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: `There was an error rescheduling the visit. Please Try again later.`
                });

                this.$emit("calendarRefresh");
            }
        },

        generateResource(arg: any) {
            let resource = arg?.resource?.extendedProps;
            let userInfo = "";
            let userName = `<span class="font-weight-bold"> ${formatName(
                resource.FirstName,
                resource.LastName
            )} </span>`;
            const phoneNumber = new FormatPhoneNumber().toView(resource.PhoneNumber?.toString());
            this.isTestAgency = Object.values(TestingAgenciesEnum).includes(resource.AgencyId);
            const isHomeCareAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.HomeCare
            );
            const isHosAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            );
            const isPalAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            );
            const isHomeHealthAddAllowed = this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.scheduler,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AgencyCore
            );

            if (!!resource) {
                let productClass = new GetProductClass();
                let application = resource.Application;
                if (application === 256) {
                    application = ApplicationEnum.AxxessHospice;
                }
                if (application !== ApplicationEnum.AgencyCore) {
                    userName = `<a class="w-100 font-size-base text-truncate cursor-pointer user-link" id="${
                        resource.UserId
                    }">
                            ${formatName(resource.FirstName, resource.LastName)}
                        </a>`;
                }
                userInfo = `<div class="mx-2 d-flex flex-column align-items-start p-2">
                                <span class="font-weight-normal font-size-xs badge badge-pill pill mr-1 ${productClass.toView(
                                    application
                                )}">
                                    ${ApplicationNameEnum[application]}
                                </span>
                                <div class="position-absolute bulk-reassign-wrapper">
                                    ${
                                        this.isTestAgency
                                            ? `<button
                                            type="button"
                                            class="btn font-size-xs px-1 py-0 btn-outline-primary"
                                            data-toggle="dropdown"
                                            id="reassign-ellipsis-${resource.UserId}-${resource.AgencyId}"
                                            >
                                                <i class="fas fa-ellipsis-h"></i>
                                            </button>
                                            <div class="dropdown-menu py-1" aria-labelledby="reassign-ellipsis-${resource.UserId}-${resource.AgencyId}">
                                                <a
                                                    class="dropdown-item font-size-sm bulk-reassign"
                                                    href="javascript:void(0);"
                                                    id="task-manager-${resource.UserId}-${resource.AgencyId}"
                                                >
                                                    Task Manager
                                                </a>

                                            </div>`
                                            : ``
                                    }
                                </div>

                                <p class="mb-0 w-auto user-name">
                                    ${userName}
                                    <span class="font-size-xs">${resource.Credentials}</span>
                                </p>
                                ${
                                    phoneNumber
                                        ? `<a href="tel:${phoneNumber}" class="mb-0 font-size-xs w-auto">${phoneNumber}</a>`
                                        : ""
                                }
                                <p class="w-100 font-size-2xs text-truncate mb-0">${resource.AgencyName}</p>
                                <p class="font-weight-bold status-label mb-0 font-size-2xs ${this.getStatusText(
                                    resource.Status
                                ).toLowerCase()}">
                                    Status : <span class="text-capitalize"> ${this.getStatusText(
                                        resource.Status
                                    )} </span>
                                </p>

                                ${
                                    (application == ApplicationEnum.AxxessHospiceFE && isHosAddAllowed) ||
                                    (application == ApplicationEnum.AxxessHospice && isHosAddAllowed) ||
                                    (application == ApplicationEnum.AxxessPalliative && isPalAddAllowed) ||
                                    (application == ApplicationEnum.HomeCare && isHomeCareAddAllowed) ||
                                    (application == ApplicationEnum.AgencyCore && isHomeHealthAddAllowed)
                                        ? `<span class="cursor-pointer font-size-xs text-primary pt-2 pb-1" id="add-task-${resource.UserId}-${resource.AgencyId}">
                                                <i class="fa fa-plus-circle"></i> Add Task
                                            </span>`
                                        : ""
                                }
                            </div>`;
            }
            return userInfo;
        },

        generateEvent(arg: any, extraEvents: number) {
            let event = arg?.event?.extendedProps;
            let eventContent = "";
            const date = new Date(event.EndDate);
            const day = date.getDay();
            const saturday = 6;
            if (!event) {
                return eventContent;
            }
            let isNonPatientVisit = !event.PatientId;
            let patientName = "";
            if (!isNonPatientVisit && !!event) {
                patientName = `<span class="d-flex align-items-center font-size-2xs">
                <i class="fas fa-user mr-1"></i>
                <span>
                    ${this.getUserName(event.PatientFirstName, event.PatientLastName)}
                </span>
            </span>`;
            }
            eventContent = `<div class="ax-calendar-event ${ScheduleLegendState[event.State]}" id = "scheduled-event-${
                event.Id
            }">
            <span class = "w-100 d-flex">
                    <span class="font-weight-bold text-truncate">
                            ${event.TaskName}
                    </span>
                    <span class="btn-group table-btn-group ml-auto" id = "edit-icon-${event.Id}">
                        <button type="button" class="border-0 btn px-1 py-0 btn-outline-info dropdown-toggle" data-toggle="dropdown" data-display="static" id = "edit-btn-${
                            event.Id
                        }">
                            <i class="fas fa-edit"></i>
                        </button>
                        <div class="dropdown-menu ${
                            day === saturday ? "dropdown-transform-left" : "dropdown-transform-right"
                        }">
                            <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                id = "btn-reassign-${event.Id}"
                            >
                            Reassign
                            </a>
                            <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                id = "btn-visitdetails-${event.Id}"
                            >
                            Details
                            </a>
                        </div>
                    </span>
                </span>
            <span class="w-100 font-size-3xs text-truncate">
                ${formatTimeInOut(formatScheduledTime(event.StartTime), formatScheduledTime(event.EndTime))}
            </span>
            <span class="d-flex align-items-center font-size-2xs">
                <i class="fas fa-user mr-1"></i>
                <span>
                    ${this.getUserName(event.PatientFirstName, event.PatientLastName)}
                </span>
            </span>
            <span class="d-flex align-items-center font-size-2xs">
                <i class="fas fa-building mr-1"></i>
                <span>
                    ${event.BranchName || "Not Available"}
                </span>
            </span>
            <span class="w-100 font-size-3xs text-truncate">
                ${event.Status}
            </span>
        </div>`;
            if (!!event.isExtraEvent) {
                eventContent = `<a class="cursor-pointer text-dark more-btn" tabindex="0">+ ${extraEvents} more</a>`;
            }
            return eventContent;
        },

        registerClick(arg: any) {
            let element = arg?.el?.className.split(" ").join(".");
            let extendedProps = arg?.resource?.extendedProps;
            $(`.${element}`).on("click", `#${extendedProps?.UserId}-${extendedProps.AgencyId}`, (event: any) => {
                this.handleUserNameClick(extendedProps);
            });
            $(`.${element}`).on(
                "click",
                `#task-manager-${extendedProps?.UserId}-${extendedProps.AgencyId}`,
                (event: any) => {
                    this.openTaskManager(extendedProps);
                }
            );
            $(`.${element}`).on(
                "click",
                `#add-task-${extendedProps?.UserId}-${extendedProps.AgencyId}`,
                (event: any) => {
                    this.scheduleNewTasks(extendedProps);
                }
            );
        },

        async scheduleNewTasks(employee: BulkReassign) {
            // will be implemented later
            const employeeDetails = this.usersList.find((user: CalendarEmployeeModel) => employee.Id == user.Id);
            const employeeInfo = (({ FirstName, LastName }) => ({ FirstName, LastName }))(employeeDetails);
            const visitDetails: BulkReassign = Object.assign({}, employeeInfo, employee);
            if (!!visitDetails) {
                this.dialogRef = this.$dialog.open(ScheduleVisitEmployee, {
                    props: {
                        modal: true,
                        closeOnEscape: false,
                        showHeader: false
                    },
                    data: {
                        visitDetails
                    },
                    onClose: async (options) => {
                        if (options.data === "success") {
                            await this.onCalendarRefresh();
                        }
                        if (options.data === "reopen") {
                            await this.scheduleNewTasks(employee);
                        }
                    }
                });
            }
        },

        async openTaskManager(employee: BulkReassign) {
            // will be implemented later
            // This has to be done because backend is sending -3 for hospice solution.
            let application;
            if (employee.Application === ApplicationEnum.AxxessHospice) {
                application = ApplicationEnum.AxxessHospiceFE;
            } else {
                application = employee.Application;
            }
            this.$router.push({
                path: "/axxess-central/task-manager?",
                query: {
                    agencyId: employee.AgencyId,
                    fullname: `${employee.FirstName} ${employee.LastName} ${employee.Credentials}`,
                    application: application,
                    userId: employee.UserId
                }
            });
        },

        async getDisciplineTasks(visitDetails: IVisitDetails) {
            try {
                const payload: IGetDisciplineTaskHHParams = {
                    agencyId: visitDetails.AgencyId,
                    application: visitDetails.Application
                };
                this.disciplineTasks = await this._lookupService.getDisciplineTasks(payload);
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Discipline Task Failed`,
                    message: `Fetching Discipline Task Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async handleUserNameClick(resource: any) {
            let redirectResponse;
            let analyticsCategory = "Central-Calendar-Navigation";
            let action = "";
            if (resource.Application === ApplicationEnum.AxxessHospice) {
                redirectResponse = {
                    redirectUrl: `${config.hospiceUrl}/employees/employee-center/${resource.UserId}?accountId=${resource.AgencyId}`,
                    isSuccessful: true
                };
                action = "AxxessHospice";
            } else if (resource.Application === ApplicationEnum.AxxessPalliative) {
                redirectResponse = {
                    redirectUrl: `${config.palliativeUrl}/employees/employee-center/${resource.UserId}?accountId=${resource.AgencyId}`,
                    isSuccessful: true
                };
                action = "AxxessPalliative";
            } else {
                const isClinician = this._permissionManager.checkPermission(
                    ParentPermissionEnum.clinician,
                    PermissionActionEnum.canView
                );
                this.showLoading(true);
                redirectResponse = await this._navigationService.navigateToUser({
                    agencyId: resource.AgencyId,
                    applicationId: resource.Application,
                    isClinician: isClinician,
                    userId: resource.UserId
                });
                action = resource.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
                this.showLoading(false);
            }
            this._navigationService.redirect(redirectResponse, {
                category: analyticsCategory,
                action
            });
        },

        // NOTE: Uncomment when task navigation is needed
        // async handleEventClick(eventObjectInput: any | ITaskByPatientResponse) {
        //     let eventData = eventObjectInput?.event?.extendedProps;
        //     await this.navigateToTask(eventData);
        // },

        // async navigateToTask(eventData: ITaskByPatientResponse) {
        //     let redirectResponse;
        //     let analyticsCategory = "Calendar-Task-Navigation";
        //     let action = "";

        //     if (eventData.Application === 256) {
        //         redirectResponse = {
        //             redirectUrl: `${config.hospiceUrl}/note/${eventData.Id}?accountId=${eventData.AgencyId}`,
        //             isSuccessful: true
        //         };
        //         action = "AxxessHospice";
        //     } else {
        //         let accessRoles = await this._accessService.getAccessRoles();
        //         redirectResponse = await this._navigationService.navigateToTask({
        //             agencyId: eventData.AgencyId,
        //             applicationId: eventData.Application,
        //             userId: eventData.UserId,
        //             isClinician: accessRoles.isClinician,
        //             patientId: eventData.PatientId,
        //             taskId: eventData.Id,
        //             episodeId: eventData.EpisodeId
        //         });
        //         action = eventData.Application === ApplicationEnum.AgencyCore ? "AxxessHomeHealth" : "AxxessHomeCare";
        //     }

        //     this._navigationService.redirect(redirectResponse, {
        //         category: analyticsCategory,
        //         action
        //     });
        // },

        attachTooltip(arg: any) {
            let element = $(arg?.el);
            let content = this.getTooltipContent(arg?.event?.extendedProps);
            (element as any).popover({
                html: true,
                content: content,
                trigger: "hover",
                placement: "auto",
                container: "body"
            });
        },

        getTooltipContent(data: any) {
            let content = "";
            let evvContent = "";
            let scheduledTime = "";
            if (this.getEvvContent(data).length > 0) {
                evvContent = `<span>
                                <strong>EVV Time:</strong>
                                ${this.getEvvContent(data)}
                            </span>`;
            }
            if (!!data) {
                content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                                <span><strong>Payer:</strong> ${data.Payer}</span>
                                <span><strong>Billable:</strong> ${!!data.IsBillable ? "Yes" : "No"}</span>
                                <span><strong>Payable:</strong> ${!!data.IsPayable ? "Yes" : "No"}</span>
                                <span>
                                    <strong>Scheduled By:</strong>
                                    ${this.getUserName(data.ScheduledByFirstName, data.ScheduledByLastName)}
                                </span>
                                ${evvContent}
                            <div>`;
            }
            return content;
        },

        attachNonVisitTooltip(arg: any) {
            let element = $(arg?.el);
            let content = this.getNonVisitTooltipContent(arg?.event?.extendedProps);
            (element as any).popover({
                html: true,
                content: content,
                trigger: "hover",
                placement: "auto",
                container: "body"
            });
        },

        getNonVisitTooltipContent(data: any) {
            let content = "";
            let evvContent = "";
            if (this.getEvvContent(data).length > 0) {
                evvContent = `<span>
                                <strong>EVV Time</strong>
                                ${this.getEvvContent(data)}
                            </span>`;
            }
            if (!!data) {
                content = `<div class="d-flex flex-column align-items-start event-tooltip font-size-xs">
                                <span><strong>Mileage:</strong> ${data.Mileage || "Not Available"}</span>
                            <div>`;
            }
            return content;
        },

        formatEVVTime(startTime: Date | string, endTime: Date | string) {
            if (startTime || endTime) {
                if (startTime === "Not Collected" || endTime === "Not Collected") {
                    return `${
                        startTime === "Not Collected" ? "Not Collected" : moment(startTime).format("hh:mm A")
                    } - ${endTime === "Not Collected" ? "Not Collected" : moment(endTime).format("hh:mm A")}`;
                }
                if (moment(startTime).isBefore(endTime, "day")) {
                    return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("MM/DD/YYYY hh:mm A")}`;
                } else {
                    return `${moment(startTime).format("hh:mm A")} - ${moment(endTime).format("hh:mm A")}`;
                }
            } else return "";
        },

        getEvvContent(data: any) {
            let evvTime = "";
            if (data.Application === ApplicationEnum.AxxessHospiceFE) {
                return evvTime;
            } else if (!data.EvvTimeIn && !data.EvvTimeOut) {
                evvTime = "Not Yet Started";
            } else {
                const evvTimeIn = data.EvvTimeIn || "Not Collected";
                const evvTimeOut = data.EvvTimeOut || "Not Collected";
                evvTime = this.formatEVVTime(evvTimeIn, evvTimeOut);
            }
            return evvTime;
        },

        getUserName(firstName: string, lastName: string) {
            let userName = "";
            if (!firstName && !lastName) {
                userName = "Unassigned";
            } else {
                userName = formatName(firstName, lastName, "FN LN");
            }
            return userName;
        },

        async rescheduleVisitHomeCare(dropEvent: any, event: IVisitDetails, StartDate: string, EndDate: string) {
            const { Id, AgencyId, Application, UserId, EpisodeId, PatientId, TimeIn, TimeOut } = event;

            const rescheduleDetails: IReassignVisitHC = {
                AgencyId,
                PatientId,
                EventId: Id,
                EpisodeId,
                Application,
                UserId,
                TimeIn,
                TimeOut,
                VisitStartTime: StartDate,
                VisitEndTime: EndDate,
                isDragDrop: true
            };

            try {
                const firstResponse = await this._taskService.rescheduleTaskHC(rescheduleDetails);

                if (!firstResponse.isSuccessful && firstResponse.errorDetails) {
                    if (!firstResponse.errorDetails.taskIssues.length) {
                        throw new Error(firstResponse.errorMessage);
                    }

                    let validationList: IRescheduleValidations[] = firstResponse.errorDetails.taskIssues.map(
                        (item: any) => {
                            return {
                                title: item.title as string,
                                description: [item.message] as string[],
                                taskIssueSurpressKey: item.taskIssueSurpressKey as string
                            };
                        }
                    );

                    const hasError = firstResponse.errorDetails.allTasksHaveErrors;

                    this.dialogRef = this.$dialog.open(RescheduleValidation, {
                        props: {
                            modal: true,
                            closeOnEscape: false,
                            showHeader: false,
                            style: {
                                width: "60vw"
                            }
                        },

                        data: {
                            validations: validationList,
                            name: "Employee",
                            component: "Schedule-visit",
                            hasError
                        },

                        onClose: async (options) => {
                            if (options.data == "cancel") {
                                return;
                            } else {
                                const issuesResponseObj: IHandleTaskReschuleIssue = {};
                                for (let issue of validationList) {
                                    issuesResponseObj[issue.taskIssueSurpressKey] = "ScheduleAllAnyways";
                                }

                                rescheduleDetails.HandleTaskIssues = issuesResponseObj;
                                const secondResponse = await this._taskService.rescheduleTaskHC(rescheduleDetails);
                                if (!secondResponse.isSuccessful) {
                                    throw new Error(secondResponse.errorMessage);
                                }

                                if (secondResponse.isSuccessful) {
                                    this._toastrService.success({
                                        title: `Visit Scheduled`,
                                        message: `Visit has been successfully Scheduled.`
                                    });

                                    this.$emit("calendarRefresh");
                                }
                            }
                        }
                    });
                }

                if (firstResponse.isSuccessful && !firstResponse.errorDetails) {
                    this._toastrService.success({
                        title: `Visit Scheduled`,
                        message: `Visit has been successfully Scheduled.`
                    });
                    this.$emit("calendarRefresh");
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: error.message || `There was an error rescheduling the visit. Please Try again later.`
                });
            } finally {
                await this.onCalendarRefresh();
            }
        },

        async rescheduleVisitHosPal(event: IVisitDetails, StartDate: string, EndDate: string) {
            let application;
            if (event.Application === ApplicationEnum.AxxessHospice) {
                application = ApplicationEnum.AxxessHospiceFE;
            } else {
                application = event.Application;
            }
            const { Id, AgencyId, UserId } = event;
            const rescheduleDetails: IRescheduleVisitHosPal = {
                TaskId: Id,
                AccountId: AgencyId,
                StartDate,
                EndDate,
                UserId,
                Application: application
            };
            try {
                await this._taskService.rescheduleTaskHosPal(rescheduleDetails);
                this._toastrService.success({
                    title: `Success`,
                    message: `The visit has been successfully rescheduled.`
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Rescheduling task`,
                    message: `There was an error rescheduling the visit. Please Try again later.`
                });
            } finally {
                await this.onCalendarRefresh();
            }
        },

        attachMorePopover(arg: any) {
            let element = $(arg?.el).find(".more-btn");
            let extendedProps = arg?.event?.extendedProps;
            let startDate = new Date(Date.parse(extendedProps?.StartDate));
            let key = `${extendedProps.UserId}${startDate.toLocaleDateString()}`;
            let tasksList = this.tasksMap?.get(key)?.slice(this.eventLimit, this.tasksList?.length);
            let content = "";
            tasksList?.map((task: any) => {
                let isNonPatientVisit = !task.PatientId;
                let patientName = "";
                if (!isNonPatientVisit) {
                    patientName = `<span class="d-flex align-items-center font-size-2xs">
                    <i class="fas fa-user mr-1"></i>
                    <span>
                        ${this.getUserName(task.PatientFirstName, task.PatientLastName)}
                    </span>
                </span>`;
                }
                let taskContent = `<div class="ax-calendar-event ${
                    ScheduleLegendState[task.State]
                } mb-1 more-event" id="${task.Id}">
                    <span class="w-100 font-weight-bold text-truncate">
                        ${task.TaskName}
                    </span>
                    ${patientName}
                    <span class="w-100 font-size-3xs text-truncate">
                        ${task.Status}
                    </span>
                </div>`;
                content = content + taskContent;
                this.attachMoreEventsTooltip(task);
            });
            (element as any)
                .popover({
                    html: true,
                    content: content,
                    trigger: "click focus",
                    placement: "auto",
                    container: "body"
                })
                .on("mouseenter", () => {
                    let parentDiv = element.closest("td > div");
                    parentDiv.css("z-index", "auto");
                })
                .on("mouseleave", () => {
                    let parentDiv = element.closest("td > div");
                    parentDiv.css("z-index", "");
                });

            // $(".fc-timeline-event")
            //     .off("click")
            //     // tslint:disable-next-line: only-arrow-functions
            //     .on("click", ".more-event", async function (event: any) {
            //         event.stopPropagation();
            //         let taskId = $(this).prop("id");
            //         if (!!taskId) {
            //             let task = self.tasksList.find((item) => item.Id === taskId);
            //             if (!!task) {
            //                 await self.navigateToTask(task);
            //             }
            //         }
            //     });
        },

        attachMoreEventsTooltip(task: ITaskByUserResponse) {
            let isNonPatientVisit = !task.PatientId;
            let content = isNonPatientVisit ? this.getNonVisitTooltipContent(task) : this.getTooltipContent(task);
            $("body").on("mouseenter", `#${task.Id}`, () => {
                $(`#${task.Id}`)
                    .popover({
                        html: true,
                        content: content,
                        trigger: "hover",
                        placement: "auto",
                        container: "body"
                    })
                    .popover("show");
            });
        }
    }
});
