import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.hasMultipleClinicalProducts)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.getProductPillClass(_ctx.productId), "pill"])
        }, _toDisplayString(_ctx.getProductName(_ctx.productId)), 3 /* TEXT, CLASS */)
      ]))
    : _createCommentVNode("v-if", true)
}