import { ApplicationEnum } from "../../enums/enums";
import { Agency } from "./../agency.model";
import { ValidationRules } from "aurelia-validation";

export class ScheduleVisitHomeHealthModel {
    public agencyId: string;
    public application: ApplicationEnum;
    public userId: string;
    public timeZone: string;
    public episodeId: string;
    public patientId: string;
    public disciplineTask: number;
    public customId: number;
    public version: number;
    public visitDates: string[];
    public discipline: number;
    public postToAxxessCare?: boolean;

    public constructor(data?: ScheduleVisitHomeHealthModel) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: ScheduleVisitHomeHealthModel) => x.disciplineTask)
            .required()
            .withMessage("Visit Type is required.")
            .ensure((x: ScheduleVisitHomeHealthModel) => x.userId)
            .required()
            .withMessage("User is required.")
            .ensure((x: ScheduleVisitHomeHealthModel) => x.discipline)
            .required()
            .when((x: ScheduleVisitHomeHealthModel) => x.postToAxxessCare)
            .withMessage("Task Type is required")
            .on(this);
    }
}
