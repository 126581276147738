import { autoinject } from "aurelia-framework";

import { HttpStatusCodeEnum } from "../../enums/enums";
import { ToastrService } from "../../services/toastr.service";
import type { IResponseHandler } from "./i-handlers";

@autoinject()
export class BadRequestHandler implements IResponseHandler {
    private _toastrService: ToastrService;

    public constructor(toastrService: ToastrService) {
        this._toastrService = toastrService;
    }

    public async handle(response: Response): Promise<void> {
        if (response.status === HttpStatusCodeEnum.BadRequest) {
            let json = await response.json();
            let result = json as BadRequestResult;
            const message = result?.message || result?.Message || "Validation Failed."; // in case of multiple errors it will come as a single string with line breaks in between.
            this._toastrService.error({
                message: message,
                options: {
                    iconClass: "bg-danger"
                }
            });
        }
    }
}

export class BadRequestResult {
    public Message?: string;
    public message?: string;
}
