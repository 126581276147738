import { PropType, computed, defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { Container } from "aurelia-dependency-injection";
import { required } from "@vuelidate/validators";
import moment from "moment";

import { VisitDetails } from "../../../vue-models/visit-details";
import { ScheduleVisitHomeHealthModel } from "../../../vue-models/schedule-visit-home-health";
import { IEmployeeList, IReassignVisitDetails } from "../../../vue-interfaces/i-reassign-visit";
import { ITypeaheadOptions } from "../../../vue-interfaces/i-typeahead";
import { INewVisitDetailsResponse, ITaskDetailsPayload } from "../../../vue-interfaces/i-task-details";
import { IDisciplineTaskHH, IGetDisciplineTaskHHParams } from "../../../vue-interfaces/i-discipline-task-hh";
import { IGetPatientByBranchResponse, IPatientByAgencyRequest } from "../../../vue-interfaces/i-patient-service";
import {
    ICurrentEpisodeRequestParams,
    ICurrentEpisodeResponse,
    PatientService
} from "../../../../services/patient.service";
import { IFrequencyListRequestParams, IFrequencyListResponse, TaskService } from "../../../../services/task.service";
import { ToastrService } from "../../../../services/toastr.service";
import { LookupService } from "../../../../services/lookup-service";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import MultiDatePickerHH from "../../../vue-custom-elements/MultiDatePickerHH/MultiDatePickerHH.vue";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export interface IVisitOrders {
    discipline: string;
    frequency: string;
    effectiveDate: string;
}

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput,
        MultiDatePickerHH
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    props: {
        visitDetails: { type: Object as PropType<IReassignVisitDetails>, default: {} },
        isLoading: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        cancel: { type: Function, default: null },
        ok: { type: Function, default: null },
        addAnother: { type: Function, default: null }
    },

    data() {
        return {
            employeeList: [] as IEmployeeList[],
            showAllByDefault: true as boolean,
            _taskService: null,
            _toastrService: null,
            _lookupService: null,
            _patientService: null,
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string },
            taskDetails: {} as VisitDetails,
            isSavingReason: false as boolean,
            showAssignedToList: false as boolean,
            assignedToName: "" as string,
            newVisitDetails: new ScheduleVisitHomeHealthModel(),
            disciplineTasks: [] as IDisciplineTaskHH[],
            disciplineTask: null as IDisciplineTaskHH,
            patients: [] as IGetPatientByBranchResponse[],
            currentUserId: "" as string,
            currentEpisodeDetails: null as ICurrentEpisodeResponse,
            patientFrequencyList: [] as IFrequencyListResponse[],
            visitOrders: [] as IVisitOrders[],
            visitDates: "" as string,
            patientId: null as ITypeaheadOptions,
            dialogRef: null,
            SaveEnum,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            newVisitDetails: {
                disciplineTask: { required },
                patientId: { required }
            },
            visitDates: {
                required
            }
        };
    },

    watch: {
        visitDateChanged() {
            this.visitDatesChanged();
        }
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._lookupService = Container.instance.get(LookupService);
        this._patientService = Container.instance.get(PatientService);
    },

    async mounted() {
        Object.assign(this.visitDetails, {});
        try {
            this.$emit("loadingChange", true);
            const payload: ITaskDetailsPayload = {
                application: this.visitDetails.Application,
                agencyId: this.visitDetails.AgencyId,
                eventId: this.visitDetails.Id,
                episodeId: this.visitDetails.EpisodeId
            };
            const visitDetails = await this._taskService.getHHVisitDetails(payload);
            this.newVisitDetails.agencyId = visitDetails.AgencyId;
            this.newVisitDetails.application = visitDetails.Application;
            this.newVisitDetails.disciplineTask = null;
            await this.getDisciplineTasks();
            await this.getPatient("");
        } catch (error) {
            console.log(error);
        } finally {
            this.$emit("loadingChange", false);
        }
    },

    methods: {
        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : "";
        },

        patientIdChanged(newValue: ITypeaheadOptions) {
            if (newValue) {
                this.patientId = newValue;
                this.newVisitDetails.patientId = newValue.value;
                this.visitOrders = [];
                this.currentEpisodeDetails = null;
                this.visitDates = "";
                this.getCurrentEpisode();
            }
        },

        onVisitTypeChange(discipline: IDisciplineTaskHH) {
            this.newVisitDetails.disciplineTask = discipline?.disciplineTask;
        },

        updateResult(result: any) {
            this.visitDates = result;
        },

        async getDisciplineTasks() {
            try {
                const payload: IGetDisciplineTaskHHParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application
                };
                this.disciplineTasks = await this._lookupService.getDisciplineTasks(payload);
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Visit Type Failed`,
                    message: `Fetching Visit Type Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getCurrentEpisode() {
            try {
                const payload: ICurrentEpisodeRequestParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application,
                    patientId: this.newVisitDetails.patientId
                };
                this.currentEpisodeDetails = await this._patientService.getCurrentEpisode(payload);
                this.newVisitDetails.episodeId = this.currentEpisodeDetails.id;
                this.getPatientFrequencyList();
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Current Episode Failed`,
                    message: `Fetching Current Episode Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getPatientFrequencyList() {
            try {
                const payload: IFrequencyListRequestParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application,
                    patientId: this.newVisitDetails.patientId,
                    episodeId: this.currentEpisodeDetails?.id,
                    episodeStartDate: this.currentEpisodeDetails?.startDate
                };
                this.patientFrequencyList = await this._taskService.getFrequencyList(payload);

                this.patientFrequencyList.map((patientFrequency) => {
                    let visitOrder: IVisitOrders = null;
                    if (patientFrequency.frequencyHHA) {
                        visitOrder = {
                            discipline: "HHA",
                            frequency: patientFrequency.frequencyHHA,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyMSW) {
                        visitOrder = {
                            discipline: "MSW",
                            frequency: patientFrequency.frequencyMSW,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyNursing) {
                        visitOrder = {
                            discipline: "SN",
                            frequency: patientFrequency.frequencyNursing,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyOT) {
                        visitOrder = {
                            discipline: "OT",
                            frequency: patientFrequency.frequencyOT,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyPT) {
                        visitOrder = {
                            discipline: "PT",
                            frequency: patientFrequency.frequencyPT,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyST) {
                        visitOrder = {
                            discipline: "ST",
                            frequency: patientFrequency.frequencyST,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }

                    this.visitOrders = this.visitOrders.reduce((acc, curr) => {
                        const existingEntry = acc.find((item) => item.discipline === curr.discipline);
                        if (existingEntry) {
                            existingEntry.frequency += ` \n${curr.frequency}`;
                            existingEntry.effectiveDate += ` \n${curr.effectiveDate}`;
                        } else {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);

                    return this.visitOrders;
                });
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Frequency List Failed`,
                    message: `Fetching Frequency List Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getPatient(term: string) {
            const patientPayload: IPatientByAgencyRequest = {
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application,
                searchTerm: term,
                page: 1,
                pageLength: 20
            };
            this.patients = await this._patientService.getPatientsByAgency(patientPayload);

            return this.patients?.map((item) => {
                return {
                    name: `${item.PatientName.FirstName} ${item.PatientName.LastName}`,
                    value: item.Id
                };
            });
        },

        async scheduleVisit(saveType: string) {
            const valid = await this.v$.$validate();
            if (!valid) {
                return;
            }
            if (!!this.newVisitDetails) {
                try {
                    this.visitDatesChanged();
                    this.$emit("savingChange", true);
                    this.newVisitDetails.userId = this.visitDetails.UserId;
                    this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
                    this.newVisitDetails.application = this.visitDetails.Application;

                    const newVisitDetailsResp: INewVisitDetailsResponse = await this._taskService.scheduleVisitHH(
                        this.newVisitDetails
                    );
                    const isWarning = newVisitDetailsResp.Message.includes("Warning");
                    const respMessage = {
                        title: `Visit Scheduled`,
                        message: newVisitDetailsResp.Message
                    };
                    if (isWarning) {
                        this._toastrService.warning(respMessage);
                    } else {
                        this._toastrService.success(respMessage);
                    }

                    if (saveType == SaveEnum.SaveAndExit) {
                        this.ok();
                    } else if (saveType == SaveEnum.SaveAndAddAnother) {
                        this.addAnother();
                    } else {
                        this.newVisitDetails.disciplineTask = null;
                        this.newVisitDetails.visitDates = [];
                        this.newVisitDetails.patientId = null;
                        this.visitDates = "";
                        this.patientId = null;
                        this.disciplineTask = null;
                    }
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Scheduled Failed`,
                        message: `Scheduling Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.$emit("savingChange", false);
                }
            }
        },

        visitDatesChanged() {
            if (this.visitDates) {
                this.newVisitDetails.visitDates = this.visitDates.split(", ");
            } else {
                this.newVisitDetails.visitDates = [];
            }
        }
    }
});
