import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { TaskService } from "../../../services/task.service";
import { ToastrService } from "../../../services/toastr.service";
import { PhysiciansService } from "../../../services/physician-service";
import { BranchesService } from "../../../services/branches-service";
import { UserService } from "../../../services/user-service";
import {
    IStartMissedVisitHomeCare,
    IStartMissedVisitHomeHealth,
    IStartMissedVisitHospice,
    ITask
} from "../../vue-interfaces/i-task";
import { ITrackingReasonDetails, ITrackingTypeDetails } from "../../vue-interfaces/i-homecare-reassign";
import { ApplicationEnum, MissedVisitHospiceReasons, MissedVisitPalliativeReasons } from "../../../enums/enums";
import { formatName } from "../../../common/format-name";
import { getApplicationId } from "../../../common/vue-helpers/modifiers/application-modifiers";
import { formatDateInput } from "../../../common/vue-helpers/modifiers/value-modifier";
import { enumToMap } from "../../../common/vue-helpers/modifiers/enum-modifiers";

import ProductPill from "../../vue-elements/ProductPill/ProductPill.vue";
import DatePicker from "../../vue-custom-elements/DatePicker/DatePicker.vue";
import TypeaheadInput from "../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import TimePicker from "../../vue-custom-elements/TimePicker/TimePicker.vue";

const validateTimeFormat = helpers.regex(/((0[1-9]|1[0-2]):([0-5][0-9]) ?([AaPp][Mm]))/);

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        ProductPill,
        DatePicker,
        TypeaheadInput,
        TimePicker
    },

    validations() {
        return {
            startMissedVisit: {
                SignatureTime: {
                    required: helpers.withMessage(
                        "Signature Time is required.",
                        requiredIf(!this.isHomeHealthHomeCare())
                    ),
                    validateTimeFormat: helpers.withMessage("Enter valid Signature Time", validateTimeFormat)
                },
                SignatureDate: { required },
                Comments: {
                    required: requiredIf(this.commentsValidation())
                },
                Reason: { required: requiredIf(this.isHomeHealthHomeCare()) },
                Signature: { required: requiredIf(this.isHomeHealthHomeCare()) },
                VisitTracking: {
                    TypeId: { required: requiredIf(this.showHCFields as boolean) }
                },
                ReasonId: { required: requiredIf(!this.isHomeHealthHomeCare()) }
            }
        };
    },

    data() {
        return {
            physicians: null,
            users: null,
            _toastrService: null,
            _taskService: null,
            _physicianService: null,
            _branchesService: null,
            _userService: null,
            validationController: null,
            taskDetails: {} as ITask,
            dialogReference: this.dialogRef as any,
            startMissedVisit: {} as IStartMissedVisitHomeHealth | IStartMissedVisitHomeCare | IStartMissedVisitHospice,
            commentBox: null,
            trackingTypes: [] as Array<ITrackingTypeDetails>,
            missedVisitsReasonsData: [] as Array<ITrackingReasonDetails>,
            missedVisitsReasonsToShow: [] as Array<ITrackingReasonDetails>,
            attachmentError: false as boolean,
            isLoading: false as boolean,
            attachment1Ref: {} as FileList,
            attachment2Ref: {} as FileList,
            attachment3Ref: {} as FileList,
            showHCFields: false as null,
            providerId: "" as string,
            signature: false as boolean,
            MissedVisitHospiceReasons,
            MissedVisitPalliativeReasons,
            v$: useVuelidate(),
            missedVisitReasonsHH: [
                "Cancellation of Care",
                "Doctor - Clinic Appointment",
                "Family - Caregiver Able to Assist Patient",
                "No Answer to Locked Door",
                "No Answer to Phone Call",
                "Patient - Family Uncooperative",
                "Patient Hospitalized",
                "Patient Refused",
                "Patient Unable to Answer Door",
                "Therapy on Hold",
                "Other"
            ],
            getApplicationId,
            formatDateInput,
            enumToMap
        };
    },

    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._taskService = Container.instance.get(TaskService);
        this._physicianService = Container.instance.get(PhysiciansService);
        this._branchesService = Container.instance.get(BranchesService);
        this._userService = Container.instance.get(UserService);
    },

    mounted() {
        this.taskDetails = this.dialogReference.data.task;
        if (this.taskDetails.Application == ApplicationEnum.HomeCare) {
            this.getRequiredData();
            this.showHCFields = true;
        }
        if (this.taskDetails.Application == ApplicationEnum.AxxessHospiceFE) {
            this.getRequiredDataHos();
        }
        if (this.startMissedVisit && this.isHomeHealthHomeCare()) {
            this.fillValues();
        } else {
            this.fillValuesHosPal();
        }
    },

    methods: {
        commentsValidation() {
            if (this.startMissedVisit.Application === ApplicationEnum.AgencyCore) {
                const homeHealth = this.startMissedVisit as IStartMissedVisitHomeHealth;
                if (homeHealth.Reason === "Other") {
                    return true;
                }
            } else if (!this.isHomeHealthHomeCare()) {
                const missedVisitHosPal = this.startMissedVisit as IStartMissedVisitHospice;
                if (missedVisitHosPal.ReasonId === 9) {
                    return true;
                }
            }
            return false;
        },
        closeDialog() {
            this.dialogReference.close();
        },

        changeDate(date: any, name: string) {
            this.startMissedVisit.SignatureDate = date;
        },

        changeTime(time: any) {
            const missedVisitHosPal = this.startMissedVisit as IStartMissedVisitHospice;
            missedVisitHosPal.SignatureTime = time;
        },

        fillValues() {
            const missedVistHHHC = this.startMissedVisit as IStartMissedVisitHomeCare | IStartMissedVisitHomeHealth;
            const { Id, EpisodeId, PatientId, Date, AgencyId, Application, UserId } = this.taskDetails;
            missedVistHHHC.Id = Id;
            missedVistHHHC.PatientId = PatientId;
            missedVistHHHC.EpisodeId = EpisodeId;
            missedVistHHHC.SignatureDate = Date;
            missedVistHHHC.AgencyId = AgencyId;
            missedVistHHHC.Application = Application;
            missedVistHHHC.UserId = UserId;
            missedVistHHHC.Reason = null;
            missedVistHHHC.IsOrderGenerated = false;
            missedVistHHHC.IsPhysicianOfficeNotified = true;
            this.signature = true;
        },

        fillValuesHosPal() {
            const missedVisitHosPal = this.startMissedVisit as IStartMissedVisitHospice;
            const { Id, Date, AgencyId, Application } = this.taskDetails;
            missedVisitHosPal.Id = Id;
            missedVisitHosPal.SignatureDate = Date;
            missedVisitHosPal.AgencyId = AgencyId;
            missedVisitHosPal.Application = Application;
            missedVisitHosPal.ReasonId = null;
        },

        setReasonString() {
            const details = this.startMissedVisit as IStartMissedVisitHomeCare;
            const currentReason = this.missedVisitsReasonsToShow.find(
                (reason: ITrackingReasonDetails) => reason.Reason == details.Reason
            );
            details.VisitTracking.ReasonId = currentReason?.Id || "";
        },

        async getRequiredData() {
            this.isLoading = true;
            if (!this.taskDetails.AgencyId || !this.taskDetails.Application) {
                return;
            }
            const trackingPromise = this._taskService.getTrackingTypes({
                AgencyId: this.taskDetails.AgencyId,
                Application: this.taskDetails.Application,
                CategoryId: 2
            });
            const reasonsPromise = this._taskService.getTrackingReasons({
                AgencyId: this.taskDetails.AgencyId,
                Application: this.taskDetails.Application,
                CategoryId: 2
            });
            try {
                const [trackingTypes, trackingReasons] = await Promise.all([trackingPromise, reasonsPromise]);
                this.trackingTypes = trackingTypes;
                this.missedVisitsReasonsData = trackingReasons;
                const details = this.startMissedVisit as IStartMissedVisitHomeCare;
                details.VisitTracking = { TypeId: null, ReasonId: "" };
            } catch (error) {
                this.caughtError(
                    "There was an error while getting the reasons or tracking types for missed visit. Please Try again later.`"
                );
            } finally {
                this.isLoading = false;
            }
        },

        async getRequiredDataHos() {
            const patientBranchId = this.taskDetails.LocationId;
            const branches = await this._branchesService.getAllBranches();
            const patientBranch = branches.find((branch: any) => branch.id === patientBranchId);
            this.providerId = patientBranch?.providerId ?? "";
            if (!this.taskDetails.AgencyId || !this.taskDetails.Application) {
                return;
            }
        },

        caughtError(message?: string) {
            if (!message) {
                message = "There was an error. Please try again later.";
            }
            this._toastrService.error({
                title: `Error`,
                message
            });
            this.dialogReference.close();
        },

        getMissedVisitReasons() {
            if (!this.taskDetails?.Application) {
                return [];
            }
            const details = this.startMissedVisit as IStartMissedVisitHomeCare;
            if (!details.VisitTracking.TypeId) {
                return [];
            }

            return this.missedVisitsReasonsData.filter(
                (reason: ITrackingReasonDetails) => reason.TypeId == details.VisitTracking.TypeId && reason.Active
            );
        },

        updateReasons() {
            this.missedVisitsReasonsToShow = this.getMissedVisitReasons();
        },

        isHomeHealthHomeCare() {
            if (
                this.taskDetails.Application == ApplicationEnum.HomeCare ||
                this.taskDetails.Application == ApplicationEnum.AgencyCore
            ) {
                return true;
            }
            return false;
        },

        isHospice() {
            if (this.taskDetails.Application == ApplicationEnum.AxxessHospiceFE) {
                return true;
            }
            return false;
        },

        physicianValue(newValue: any) {
            const missedVisit = this.startMissedVisit as IStartMissedVisitHospice;
            if (newValue) {
                missedVisit.PhysicianId = newValue.value;
            }
        },

        async getPhysicianList(filter: string, limit: number) {
            const data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                application: this.taskDetails.Application,
                accountId: this.taskDetails.AgencyId,
                providerIds: [this.providerId]
            });
            return data.items?.map((item: any) => {
                return {
                    name: item.name,
                    value: item.id
                };
            });
        },

        usersValue(newValue: any) {
            const missedVisit = this.startMissedVisit as IStartMissedVisitHospice;
            if (newValue) {
                missedVisit.RnCaseManagerId = newValue.value;
            }
        },

        async usersFetch(filter: string) {
            let result;
            try {
                let users = await this._userService.getUsersLean({
                    page: 1,
                    pageLength: 10,
                    term: filter,
                    agencyId: this.taskDetails.AgencyId,
                    application: this.taskDetails.Application,
                    branchIds: [this.taskDetails.LocationId]
                });
                if (!!users) {
                    result = users.items.map((user: any) => ({
                        name: formatName(user.firstName, user.lastName),
                        value: user.id
                    }));
                }
            } catch (e) {
                console.error(e);
            }
            return result;
        },

        attachmentAdded($event: Event, key: string) {
            const target = $event.target as HTMLInputElement;
            if (!target.files) {
                return;
            }
            const self = this as any;
            self[key] = target.files;
            this.attachmentError = false;
            const sizeLimit = 10 * 1024 * 1024; // 10 mb;
            if (target.files[0].size > sizeLimit) {
                this.attachmentError = true;
                self[key] = null;
            }
        },

        async postMissedVisit() {
            const valid = await this.v$.$validate();
            if (valid) {
                try {
                    const formData = new FormData();
                    if (this.showHCFields) {
                        formData.append("attachment1", this.attachment1Ref?.[0]);
                        formData.append("attachment2", this.attachment2Ref?.[0]);
                        formData.append("attachment3", this.attachment3Ref?.[0]);
                    }
                    formData.append("request", JSON.stringify(this.startMissedVisit));
                    const result = (await this._taskService.startMissedVisit(formData)) || null;
                    if (result?.IsSuccessful) {
                        this._toastrService.success({
                            title: `Success`,
                            message: `Missed Visit successfully created.`
                        });
                        this.dialogReference.close("success");
                    } else {
                        this.caughtError(result?.Message);
                    }
                } catch (error) {
                    this.caughtError("There was an error starting the missed visit. Please Try again later.");
                }
            } else {
                this._toastrService.error({
                    title: `Validation Failed`,
                    message: `Fill all required fields.`
                });
            }
        },
        handleDateInputChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.changeDate(value, name);
        }
    }
});
