import "./form-section.scss";

import { bindable, bindingMode, containerless, customElement } from "aurelia-framework";

import { ApplicationEnum } from "../../../enums/enums";
import type { IFormSection } from "../../../interfaces/i-reassign-visit";

@containerless
@customElement("form-section")
export class FormSection {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public size: string = "12";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string;
    // TODO: Need enums rather than string for types
    @bindable({ defaultBindingMode: bindingMode.toView })
    public type: string = "default-section";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public popoverContent: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public popoverContainer: string = "body";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public fullHeight: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public applicationId: ApplicationEnum;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showProductPill: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showBackground: boolean = true;

    public formSectionConfiguration: IFormSection[] = [
        {
            application: ApplicationEnum.AxxessHospiceFE,
            backgroundClass: "form-section",
            borderClass: "border-hospice",
            titleClass: "section-title-hospice"
        },
        {
            application: ApplicationEnum.AxxessPalliative,
            backgroundClass: "form-palliative-section",
            borderClass: "border-hospice",
            titleClass: "section-title-hospice"
        },
        {
            application: ApplicationEnum.AgencyCore,
            backgroundClass: "background-home-health-form-section",
            borderClass: "border-home-health",
            titleClass: "section-title-home-health"
        },
        {
            application: ApplicationEnum.HomeCare,
            backgroundClass: "background-home-care-form-section",
            borderClass: "border-home-care",
            titleClass: "section-title-home-care"
        }
    ];

    public currentConfiguration: IFormSection = {
        application: ApplicationEnum.AxxessHospiceFE,
        backgroundClass: "form-section",
        borderClass: "border-hospice",
        titleClass: "section-title-hospice"
    };

    public attached() {
        if (this.applicationId) {
            let configuration = this.formSectionConfiguration.find(
                (config) => config.application == this.applicationId
            );
            if (!!configuration) {
                this.currentConfiguration = configuration;
            }
        }
    }
}
