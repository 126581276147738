import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, inlineView } from "aurelia-framework";
import { OidcSilentRenewCallback } from "../../common/oidc/oidc-event-handler";

@autoinject
@inlineView("<template></template>")
export class SignInSilent {
    private _ea: EventAggregator;

    public constructor(ea: EventAggregator) {
        this._ea = ea;
    }

    public async activate() {
        let location = window.location.toString();
        console.log(`Publishing silent sign-in using location:`, location);
        this._ea.publish(OidcSilentRenewCallback, location);
    }
}
