import moment from "moment";
import { defineStore } from "pinia";

const SET_ROLES = "SET_ROLES";
const GET_ROLES_STRING = "getRolesInString";
const GET_EXISTING_COOKIE = "getExistingCookie";
const SET_COOKIE = "setCookie";

export const useApp = defineStore("useApp", {
    state: () => ({
        roles: [],
        cookieValue: ""
    }),
    actions: {
        [SET_ROLES](payload: any) {
            this.roles = payload;
        },
        [GET_ROLES_STRING]() {
            const location = JSON.parse(localStorage.getItem("axxess-user-location"));
            let applicationRoles = {} as any;
            this.roles.forEach((role) => {
                const applications = Object.keys(applicationRoles);
                if (applications.includes(role.Application)) {
                    applicationRoles[role.Application] = [...applicationRoles[role.Application], ...role.Roles];
                } else {
                    applicationRoles[role.Application] = [...role.Roles];
                }
            });
            const applicationKeys = Object.keys(applicationRoles);
            let cookieValue = "";
            applicationKeys.map((application: any, appIndex: number) => {
                const roles = applicationRoles[application];
                cookieValue = cookieValue.concat(
                    `${application}:${roles.map((role: any, index: number) => {
                        if (index === roles.length - 1) {
                            if (appIndex === applicationKeys.length - 1) {
                                return `${role}`;
                            } else {
                                return `${role}-`;
                            }
                        } else {
                            return `${role}`;
                        }
                    })}`
                );
            });
            cookieValue = cookieValue.concat(`|${location?.city},${location?.state}`);
            this.cookieValue = cookieValue;
        },
        [GET_EXISTING_COOKIE](cookieName: string) {
            let name = cookieName + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        [SET_COOKIE]() {
            if (this.getExistingCookie("axxess.identity.meta") !== "") {
                document.cookie = `axxess.identity.meta=;path=/;expires=${moment().subtract(1, "days")}`;
            }
            this.getRolesInString();
            document.cookie = `axxess.identity.meta=${JSON.stringify(this.cookieValue)};path=/;domain=axxessweb.com`;
        }
    }
});
