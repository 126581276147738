import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { mapState } from "pinia";

import { SmokingStatusEnum, SmokingStatuses } from "../../../../../enums/enums";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker
    },

    validations() {
        return {
            patient: {
                smokingStartDate: {
                    required: requiredIf(() => {
                        return !(
                            this.patient.smokingStatus === 0 ||
                            this.patient.smokingStatus === this.smokingStatusEnum.enum.NeverSmoker ||
                            this.patient.smokingStatus === this.smokingStatusEnum.enum.UnknownIfEverSmoked
                        );
                    })
                },
                historicalSmokingStartDate: {
                    required: requiredIf(() => {
                        return this.checkHistoricalSmokingStatus();
                    })
                },
                historicalSmokingEndDate: {
                    required: requiredIf(() => {
                        return this.checkHistoricalSmokingStatus();
                    })
                }
            }
        };
    },

    data(vm) {
        return {
            smokingStatusEnum: SmokingStatuses,
            formatDateInput,
            enumToMap,
            v$: useVuelidate()
        };
    },
    computed: {
        ...mapState(useCreateReferralHosPal, { patient: "referral" })
    },
    methods: {
        smokingStatusChanged(smokingStatusChangedVal: number) {
            if (
                smokingStatusChangedVal === 0 ||
                smokingStatusChangedVal === SmokingStatusEnum.NeverSmoker ||
                smokingStatusChangedVal === SmokingStatusEnum.UnknownIfEverSmoked
            ) {
                this.patient.smokingStartDate = null;
                this.patient.smokingEndDate = null;
            }
        },
        historicalSmokingStatusChanged(historicalSmokingStatusChangedVal: number) {
            if (
                historicalSmokingStatusChangedVal === 0 ||
                historicalSmokingStatusChangedVal === SmokingStatusEnum.NeverSmoker ||
                historicalSmokingStatusChangedVal === SmokingStatusEnum.UnknownIfEverSmoked
            ) {
                this.patient.historicalSmokingStartDate = null;
                this.patient.historicalSmokingEndDate = null;
            }
        },
        smokingDateChanged(date: any, name: string) {
            if (name === "smokingStartDate") {
                this.patient.smokingStartDate = date;
            }
            if (name === "smokingEndDate") {
                this.patient.smokingEndDate = date;
            }
            if (name === "historicalSmokingStartDate") {
                this.patient.historicalSmokingStartDate = date;
            }
            if (name === "historicalSmokingEndDate") {
                this.patient.historicalSmokingEndDate = date;
            }
        },
        checkHistoricalSmokingStatus() {
            return !(
                this.patient.historicalSmokingStatus === 0 ||
                this.patient.historicalSmokingStatus === this.smokingStatusEnum.enum.NeverSmoker ||
                this.patient.historicalSmokingStatus === this.smokingStatusEnum.enum.UnknownIfEverSmoked
            );
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const date = e.target.value;
            this.smokingDateChanged(date, name);
        }
    }
});
