import { defineComponent } from "vue";

import PayrollDetailsRow from "../PayrollDetailsRow/PayrollDetailsRow.vue"
import TableEmpty from "../../vue-custom-elements/TableEmpty/TableEmpty.vue";

export default defineComponent({
    components: {
        PayrollDetailsRow,
        TableEmpty
    },

    props:{
        payrollDetails:{
            type:Array<any>,
            default:[]
        },
        hasPayrollDetails:{
            type:Boolean,
            default:true
        },
        id:{
            type:String
        },
        snapshotView:{
            type:Boolean,
            default:false
        },
        getPayrollDetails: {
            type: Function,
            default:null
        },
        payrollDetailsLoading:{
            type:Boolean,
            default:true
        },
    },

    data(vm) {
        return {
            newDetail: [],
            isNewTerminal: false,
            columns: 10,
            loadingCount:5
        };
    },
    methods: {
        saveNewDetail(index: number) {
            // Todo: add logic to save in next phase
        },

        cancelSaveNewDetail(index: number) {
            this.newDetail.splice(index, 1);
        }
    }
});
