import * as moment from "moment";

import type { IMessageUser } from "../../../resources-vue/vue-interfaces/messaging-service/i-message";

export const formatUserInitials = (value: IMessageUser) => {
    if (value) {
        let firstInitial = value.firstName == null ? "" : value.firstName.trim().substring(0, 1);
        let lastInitial = value.lastName == null ? "" : value.lastName.trim().substring(0, 1);
        let initials = `${firstInitial}${lastInitial}`;
        return initials;
    } else {
        return null;
    }
};

export const formatDisplayUsers = (value: IMessageUser[], limit: number = 30) => {
    if (value) {
        if (value.length > 1) {
            return multipleUsersLabel(value, limit);
        } else {
            return value[0].firstName + (value[0].lastName ? ` ${value[0].lastName}` : "");
        }
    } else {
        return null;
    }
};

export const formatMessageTime = (value: Date) => {
    if (value) {
        let today = moment();
        let messageTime = moment.utc(value).local();
        let numberOfDays = messageTime.diff(today, "days");
        let numberOfYears = messageTime.diff(today, "year");
        if (numberOfDays <= 0 || numberOfYears <= 0) {
            if (numberOfDays === 0) {
                return messageTime.format("hh:mm A");
            } else if (numberOfYears === 0) {
                return messageTime.format("MM/DD hh:mm A");
            } else {
                return messageTime.format("MM/DD/YY hh:mm A");
            }
        } else {
            // Server time error
            return "";
        }
    } else {
        return null;
    }
};

export const formatDisplayMessage = (value: string) => {
    if (value) {
        let div = document.createElement("div");
        div.innerHTML = value;
        let text = div.textContent;
        return text.substring(0, 40);
    } else {
        return null;
    }
};

const multipleUsersLabel = (value: IMessageUser[], limit: number) => {
    let userListIndexCount: number = 0;
    let userFirstNames: string[] = [];
    for (let user of value) {
        // Add 2 for space and commas
        userListIndexCount += user.firstName.length + 2;
        // Assuming max characters to be 30
        if (userListIndexCount > limit) {
            break;
        }
        userFirstNames.push(user.firstName);
    }
    // Other users count
    let additionalUsers: number = value.length - userFirstNames.length;
    if (additionalUsers > 0) {
        return userFirstNames.join(", ") + ` + ${additionalUsers}`;
    } else {
        return userFirstNames.join(", ");
    }
};
