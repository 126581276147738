import { ApplicationEnum } from "../../enums/enums";
import { DmeNeeded } from "./dme-needed";
import { PhysicianRequired } from "./physician-required";
import { Address } from "./address";
import type { IPhoneNumber } from "../vue-interfaces/i-phone-number";

export class HomeCareReferral {
    public id: string;
    public agencyId: string;
    public application: ApplicationEnum;
    public userId: string;
    public timeZone: string;
    public inquiryId: string;
    public accountId: string;
    public createdBy: string;
    public patientId: string;
    public admissionId: string;
    public locationId: string;
    public lineOfService: number;
    public additionalLineOfService: number;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public referralType: number;
    public referralSource: string;
    public referralSourceName: string;
    public referralDetails: string;
    public referralDate: string;
    public requestedStartOfCareDate: string;
    public admissionSource: string;
    public admissionType: string;
    public internalReferral: string;
    public dob: string;
    public isPatientConflictIgnored: boolean;
    public height: number;
    public weight: number;
    public heightMetric: number;
    public weightMetric: number;
    public agencyLocationId: string;
    public payRateMunicipalityId: string;
    public tags: string = "";

    public phoneNumbers: IPhoneNumber[] = [
        {
            number: "",
            extension: "",
            type: 1,
            country: null,
            isPrimary: true,
            ordinal: 1,
            ordinalNumber: 1
        }
    ];
    public emailAddress: string;
    public primaryAddress: Address = new Address();
    public gender: string;
    public genderPronounsArray: string[] = [];
    public genderPronounsOther: string;
    public medicaidNumber: string;
    public alternateMedicaidNumber: string;
    public ssn: string;
    public isDNR: boolean;
    public maritalStatus: number;
    public servicesRequiredCollection: string[] = [];
    public nonMedicalServicesRequiredCollection: string[] = [];
    public otherServiceRequired: string;
    public nonMedicalOtherServiceRequired: string;
    public hasOtherServicesRequired: boolean;
    public comments: string = "";
    public dmeNeeded: DmeNeeded[] = [];
    public otherDME: string;
    public physicianRequiredArray: PhysicianRequired[] = [];
    public isNonMedical: boolean = false;
    public physicians: string[] = [];
    public medicaidPending: boolean;
    public medicareBeneficiaryIdentification: string;
    public medicareBeneficiaryIdentificationNA: boolean;
    public medicareNumber: string;
    public medicareNumberNA: boolean;

    public constructor(data?: HomeCareReferral) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
