import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum CAHPSSurveyMethodEnum {
    ReceiveCAHPSSurvey = 1 as any,
    NoPublicity = 2 as any
}
class CAHPSSurveyMethodClass extends EnumWithDescription<CAHPSSurveyMethodEnum> {
    public readonly enum = CAHPSSurveyMethodEnum;
    public readonly enumName: string = "CAHPSSurveyMethodEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<CAHPSSurveyMethodEnum>> = new Map([
        [
            "ReceiveCAHPSSurvey",
            {
                value: CAHPSSurveyMethodEnum.ReceiveCAHPSSurvey,
                name: "Receive CAHPS Survey"
            }
        ],
        [
            "NoPublicity",
            {
                value: CAHPSSurveyMethodEnum.NoPublicity,
                name: "No Publicity"
            }
        ]
    ]);
}
export const CAHPSSurveyMethod = new CAHPSSurveyMethodClass();
