<template>
    <slot></slot>
    <nav v-show="!noData" class="d-flex align-items-center w-100 px-2 pagination-block" :class="classes">
        <ul v-if="!noData" class="pagination mb-0" style="cursor: pointer">
            <li class="page-item" :class="disablePrevious ? 'disabled cursor-disabled' : ''">
                <span class="page-link" @click="decrementPageNumber()">
                    <i class="fas fa-caret-left"></i>
                </span>
            </li>

            <li
                v-for="(page, index) in currentNavPages"
                class="page-item"
                :class="page === currentPage ? 'active font-weight-bold' : ''"
                :key="`navpage-${index}`"
            >
                <span @click="changePage(page)" class="page-link"> {{ page }} </span>
            </li>

            <li :class="disableNext ? 'disabled cursor-disabled' : ''" class="page-item">
                <span class="page-link" @click="incrementPageNumber()">
                    <i class="fas fa-caret-right"></i>
                </span>
            </li>
        </ul>
        <span v-if="!noData" class="ml-2 font-weight-bold"
            >Viewing {{ from }} - {{ to }} out of {{ totalDataCount }} total {{ entity }}</span
        >
        <slot name="legend"></slot>
        <span class="font-weight-bold ml-auto" v-if="showPageSizeOptions && !noData">
            Show
            <select class="form-control w-auto d-inline mx-1" v-model="pageOrder" @change="updatePageSize()">
                <option v-for="(option, index) in pageSizeOptions" :key="`size-option-${index}`">
                    {{ option }}
                </option>
            </select>
            entries
        </span>
    </nav>
</template>

<script src="./Pagination.ts"></script>
