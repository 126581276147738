import { ApplicationEnum } from "../../enums/enums";
import { ValidationRules } from "aurelia-validation";
import { IRecurrence } from "./schedule-visit-home-care";

export class RecurrenceModel {
    public type: string = "0";
    public endDate: string;
    public daysOfWeek: string = "";
    public repeatBy: number;
    public repeatNumber: number;
    public flexibleDates: string[] = [];

    public constructor(data?: RecurrenceModel) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    public initValidation() {
        ValidationRules.ensure((recurrence: IRecurrence) => recurrence.endDate)
            .required()
            .withMessage("Repeat Until Date is required.")
            .ensure((recurrence: IRecurrence) => recurrence.daysOfWeek)
            .required()
            .withMessage("Repeat On Date is required.")
            .on(this);
    }
}
