<template>
    <section>
        <FormSection
            :full-height="false"
            :title="visitDetails.TaskName"
            :application-id="visitDetails.Application"
            :show-product-pill="true"
        >
            <template #content>
                <template v-if="isLoading">
                    <div class="p-5 d-flex flex-column text-center text-primary">
                        <i class="fa fa-spinner fa-spin font-size-5xl mr-2 animated slow flash infinite"></i>
                        <strong class="mt-1">
                            <b>Loading...</b>
                        </strong>
                    </div>
                </template>
                <template v-else>
                    <div class="task-details">
                        <div class="dialog container row m-0 p-1">
                            <div v-if="visitDetails.isEmployeeCalendar" class="col-12 my-1 pl-3">
                                <b
                                    >Patient Name: {{ visitDetails.PatientFirstName }}
                                    {{ visitDetails.PatientLastName }}</b
                                >
                            </div>
                            <div class="form-group col-6 mt-2">
                                <label for="employee-from"> Status: </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    :value="taskDetails.scheduleEvent.status"
                                    placeholder=""
                                    disabled
                                />
                            </div>
                            <div class="form-group col-6 mt-2">
                                <label for="schedule-date"> Schedule Date: <span class="text-danger">*</span></label>
                                <DatePicker :getDate="handleDateChange">
                                    <div class="input-group date datepickerInput">
                                        <input
                                            name="eventDate"
                                            type="text"
                                            class="form-control"
                                            :value="formatDateInput(taskDetails.scheduleEvent.eventDate)"
                                            @change="(e) => handleDateChange(e.target.value, 'eventDate')"
                                            :disabled="disableScheduleDate"
                                        />
                                        <div class="input-group-addon input-group-append">
                                            <div class="input-group-text">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </DatePicker>
                            </div>
                            <div class="form-group col-6">
                                <label for="assigned-to"> Assigned To: </label>
                                <input type="text" class="form-control" :value="assignedToName" disabled />
                            </div>
                            <div class="form-group col-6">
                                <label for="payers"> Payer: </label>
                                <select id="payers" class="form-control" v-model="taskDetails.scheduleEvent.payer">
                                    <option v-for="payer of payers" :value="payer.id">{{ payer.name }}</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label for="visit-start-time">
                                    Visit Start Time: <span class="text-danger">*</span>
                                </label>
                                <TimePicker @update-props="changetimeIn">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :value="taskDetails.scheduleEvent.timeIn"
                                        autocomplete="off"
                                        maxlength="8"
                                        placeholder="Enter Time"
                                    />
                                </TimePicker>
                            </div>
                            <div class="form-group col-6">
                                <label for="visit-end-time"> Visit End Time: <span class="text-danger">*</span> </label>
                                <TimePicker @update-props="changetimeOut">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :value="taskDetails.scheduleEvent.timeOut"
                                        placeholder="Enter Time"
                                        autocomplete="off"
                                        maxlength="8"
                                        :disabled="disableVisitEndTime"
                                    />
                                </TimePicker>
                            </div>
                            <div class="form-group col-6">
                                <label for="travel-start-time"> Travel Start Time: </label>
                                <TimePicker @update-props="changeTravelTimeIn">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :value="taskDetails.scheduleEvent.travelTimeIn"
                                        placeholder="Enter Time"
                                        autocomplete="off"
                                        maxlength="8"
                                        :disabled="disable"
                                    />
                                </TimePicker>
                            </div>
                            <div class="form-group col-6">
                                <label for="travel-end-time"> Travel End Time: </label>
                                <TimePicker @update-props="changeTravelTimeOut">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :value="taskDetails.scheduleEvent.travelTimeOut"
                                        placeholder="Enter Time"
                                        autocomplete="off"
                                        maxlength="8"
                                        :disabled="disable"
                                    />
                                </TimePicker>
                            </div>
                            <div class="form-group col-4" v-if="isPalliative">
                                <label for="documentation-time"> Documentation Time(Minutes): </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.documentationTime"
                                    placeholder="Enter Time"
                                    :disabled="disable"
                                />
                            </div>
                            <div :class="`form-group col-4 ${isPalliative ? 'col-4' : 'col-6'}`">
                                <label for="associated-mileage"> Associated Mileage: </label>
                                <input
                                    type="text"
                                    v-model="taskDetails.scheduleEvent.associatedMileage"
                                    class="form-control"
                                    placeholder="Enter Mileage"
                                    :disabled="disable"
                                />
                            </div>
                            <div :class="`form-group col-4 ${isPalliative ? 'col-4' : 'col-6'}`">
                                <label for="surcharge"> Surcharge: </label>
                                <a
                                    tabindex="0"
                                    href="javascript:void(0);"
                                    role="button"
                                    v-custom-popover="{ content: sanitizeMarkup(surchargeTooltip) }"
                                >
                                    <i class="fas fa-info-circle text-primary font-size-xs"></i>
                                </a>
                                <input
                                    type="text"
                                    v-model="taskDetails.scheduleEvent.surcharge"
                                    class="form-control"
                                    placeholder="Enter Amount"
                                    :disabled="disable"
                                />
                            </div>
                            <div class="custom-control custom-checkbox col-2 pl-5 mt-2">
                                <input
                                    type="checkbox"
                                    id="billable"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.isBillable"
                                />
                                <label class="custom-control-label" for="billable"> Billable </label>
                            </div>
                            <div class="custom-control custom-checkbox col-2 mt-2">
                                <input
                                    type="checkbox"
                                    id="payable"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.isPayable"
                                />
                                <label class="custom-control-label" for="payable"> Payable </label>
                            </div>
                            <div class="custom-control custom-checkbox col-2 mt-2" v-if="!isPalliative">
                                <input
                                    type="checkbox"
                                    id="on-call-visit"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.isCallVisit"
                                />
                                <label class="custom-control-label" for="on-call-visit"> On-Call Visit </label>
                            </div>
                            <div class="custom-control custom-radio col-2 mt-2" v-if="isPalliative">
                                <input
                                    type="radio"
                                    id="on-call"
                                    name="on-call-prn"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.isCallVisit"
                                />
                                <label class="custom-control-label" for="on-call">
                                    <span class="mr-1">On-Call</span>
                                </label>
                            </div>
                            <div class="custom-control custom-radio col-2 mt-2" v-if="isPalliative">
                                <input
                                    type="radio"
                                    id="prn"
                                    name="on-call-prn"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.isPRNVisit"
                                />
                                <label class="custom-control-label" for="prn">
                                    <span class="mr-1">PRN</span>
                                </label>
                            </div>
                            <div class="custom-control custom-checkbox col-2 mt-2" v-if="isPalliative">
                                <input
                                    type="checkbox"
                                    id="visit-confirmed"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.isVisitVerified"
                                />
                                <label class="custom-control-label" for="visit-confirmed"> Visit Confirmed </label>
                            </div>
                            <div class="custom-control custom-checkbox col-2 mt-2" v-if="isPalliative">
                                <input
                                    type="checkbox"
                                    id="called-patient-left-message"
                                    class="custom-control-input"
                                    v-model="taskDetails.scheduleEvent.patientNotified"
                                />
                                <label class="custom-control-label" for="called-patient-left-message">
                                    Called Patient, Left Message
                                </label>
                            </div>
                            <div :class="`form-group ${isPalliative ? 'col-6' : 'col-3'}`">
                                <label for="auto-calculated-travel-time"> Auto-Calculated Travel Time: </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.autoCalculatedTravelTime"
                                    disabled
                                />
                            </div>
                            <div :class="`form-group ${isPalliative ? 'col-6' : 'col-3'}`">
                                <label for="auto-calculated-mileage"> Auto-Calculated Mileage </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.autoCalculatedMileage"
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mb-3 mt-3" v-if="!isLoading">
                            <span v-if="isSaving">
                                <button class="btn btn-primary">
                                    <i class="fa fa-spinner fa-spin animated slow flash infinite"></i>
                                </button>
                            </span>
                            <span v-else>
                                <button class="btn btn-primary" @click="saveVisit">Save</button>
                                <button type="button" class="ml-1 btn btn-md btn-outline-secondary" @click="cancel">
                                    Cancel
                                </button>
                            </span>
                        </div>
                    </div>
                </template>
            </template>
        </FormSection>
    </section>
</template>

<script src="./VisitDetailsHosPal.ts"></script>
