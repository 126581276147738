import CentralDashboard from "./CentralDashboard/CentralDashboard.vue";

const routes = [
    {
        path: "/axxess-central/dashboard",
        component: CentralDashboard,
        name: "My dashboard",
        meta: {
            menuName: "My Dashboard",
            navItem: "tabMyDashboard"
        }
    }
];

export default routes;
