import type { IPatientCalendarResponse } from "../../../services/patient.service";
import { ApplicationEnum } from "../../../enums/enums";

export class PatientCalendarModel implements IPatientCalendarResponse {
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public Mrn: string;
    public AgencyId: string;
    public AgencyName: string;
    public Application: number;
    public PhoneNumber: string = "";
    public IsEmployee: boolean = false;

    public constructor(data: IPatientCalendarResponse) {
        if (data) {
            Object.assign(this, data);
        }

        if (this.Application === 256) {
            this.Application = ApplicationEnum.AxxessHospice;
        }
    }
}
