import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum ReferralTypesEnum {
    Physician = 1 as any,
    Self = 2 as any,
    Family = 3 as any,
    Friend = 4 as any,
    PowerOfAttorney = 5 as any,
    CommunityProgram = 6 as any,
    Facility = 7 as any,
    Other = 8 as any
}
class ReferralTypesClass extends EnumWithDescription<ReferralTypesEnum> {
    public readonly enum = ReferralTypesEnum;
    public readonly enumName: string = "ReferralTypesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<ReferralTypesEnum>> = new Map([
        [
            "Physician",
            {
                value: ReferralTypesEnum.Physician,
                name: "Physician"
            }
        ],
        [
            "Self",
            {
                value: ReferralTypesEnum.Self,
                name: "Self"
            }
        ],
        [
            "Family",
            {
                value: ReferralTypesEnum.Family,
                name: "Family"
            }
        ],
        [
            "Friend",
            {
                value: ReferralTypesEnum.Friend,
                name: "Friend"
            }
        ],
        [
            "PowerOfAttorney",
            {
                value: ReferralTypesEnum.PowerOfAttorney,
                name: "Power Of Attorney"
            }
        ],
        [
            "CommunityProgram",
            {
                value: ReferralTypesEnum.CommunityProgram,
                name: "Community Program"
            }
        ],
        [
            "Facility",
            {
                value: ReferralTypesEnum.Facility,
                name: "Facility"
            }
        ],
        [
            "Other",
            {
                value: ReferralTypesEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const ReferralTypes = new ReferralTypesClass();
