import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { IAdditionEmergencyContact } from "../../../../../resources-vue/vue-interfaces/i-homehealth-referral";
import { LookupService } from "../../../../../services/lookup-service";
import { IGetCityStateByZipcodeResponse } from "../../../../../resources-vue/vue-interfaces/i-get-physician";

import ReferralAddressForm from "../../ReferralAddressForm/ReferralAddressForm.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection,
        ReferralAddressForm
    },

    data(vm) {
        return {
            v$: useVuelidate(),
            _lookupService: null
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
        this.getAdditionalCountyData();
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referralOptions: "referralOptions",
            referral: "referral",
            accountId: "accountId",
            primaryCountyData: "primaryCountyData",
            additional1: "additionalEmergency1",
            additional2: "additionalEmergency2",
            additional3: "additionalEmergency3"
        })
    },

    validations() {
        return {
            referral: {
                Contacts: {
                    AdditionalEmergencyContacts: {
                        $each: helpers.forEach({
                            FirstName: { required },
                            LastName: { required },
                            PrimaryPhone: { required }
                        })
                    }
                }
            }
        };
    },

    watch: {},

    methods: {
        ...mapActions(useEditReferralHH, {
            setAdditionalContactErrors: "SET_ADDITIONAL_CONTACT_ERROR",
            setAdditionalCounty1: "SET_ADDITION_COUNTY_1",
            setAdditionalCounty2: "SET_ADDITION_COUNTY_2",
            setAdditionalCounty3: "SET_ADDITION_COUNTY_3"
        }),

        async getAdditionalCountyData() {
            this.referral.Contacts.AdditionalEmergencyContacts.map(async (address: any, index: number) => {
                if (address.Address.SameAsPatientAddress) {
                    if (index === 0) this.setAdditionalCounty1(this.primaryCountyData);
                    if (index === 1) this.setAdditionalCounty2(this.primaryCountyData);
                    if (index === 2) this.setAdditionalCounty3(this.primaryCountyData);
                } else {
                    if (address.Address.Zip) {
                        const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                            zipCode: address.Zip,
                            agencyId: this.accountId,
                            application: this.application
                        });
                        const county: string[] = await this._lookupService.getCountyByZipcode(
                            address.Zip,
                            this.accountId
                        );
                        if (index === 0) this.setAdditionalCounty1(county);
                        if (index === 1) this.setAdditionalCounty2(county);
                        if (index === 2) this.setAdditionalCounty3(county);
                        this.referral.Contacts.AdditionalEmergencyContacts[index].Address.County = county
                            ? county[0]
                            : null;
                        this.referral.Contacts.AdditionalEmergencyContacts[index].Address.State = result.state
                            ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)
                                  ?.value
                            : null;
                        this.referral.Contacts.AdditionalEmergencyContacts[index].Address.City = result.city
                            ? result.city
                            : null;
                    }
                }
            });
        },
        addEmergencyContact() {
            if (this.referral.Contacts.AdditionalEmergencyContacts.length < 3) {
                let address = {
                    FirstName: "",
                    LastName: "",
                    PrimaryPhone: "",
                    AlternatePhone: "",
                    Relationship: "",
                    EmailAddress: "",
                    Address: {
                        AddressLine1: "",
                        AddressLine2: "",
                        City: "",
                        State: "",
                        Zip: "",
                        County: ""
                    },
                    SameAsPatientAddress: false,
                    Representative: 0,
                    RelationOption: "",
                    FamilyPortalAccess: false,
                    FamilyPortalDocumentPermission: false
                };
                this.referral.Contacts.AdditionalEmergencyContacts.push(address);
            } else {
                return;
            }
        },
        removeEmergencyContact(index: number) {
            this.referral.Contacts.AdditionalEmergencyContacts.splice(index, 1);
        },
        async makeEmergencyAsPrimary(contact: IAdditionEmergencyContact, index: number) {
            if (contact.SameAsPatientAddress) {
                if (index === 0) this.setAdditionalCounty1(this.primaryCountyData);
                if (index === 1) this.setAdditionalCounty2(this.primaryCountyData);
                if (index === 2) this.setAdditionalCounty3(this.primaryCountyData);
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.AddressLine1 =
                    this.referral.Demographics.PrimaryAddress.AddressLine1;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.AddressLine2 =
                    this.referral.Demographics.PrimaryAddress.AddressLine2;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.City =
                    this.referral.Demographics.PrimaryAddress.City;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.County =
                    this.referral.Demographics.PrimaryAddress.County;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.State =
                    this.referral.Demographics.PrimaryAddress.State;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.Zip =
                    this.referral.Demographics.PrimaryAddress.Zip;
            } else {
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.AddressLine1 = null;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.AddressLine2 = null;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.City = null;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.County = null;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.State = null;
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.Zip = null;
                if (index === 0) this.setAdditionalCounty1([]);
                if (index === 1) this.setAdditionalCounty2([]);
                if (index === 2) this.setAdditionalCounty3([]);
            }
        },
        async handleZipChange(index: number) {
            this.referral.Contacts.AdditionalEmergencyContacts[index].SameAsPatientAddress = false;
            const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                zipCode: this.referral.Contacts.AdditionalEmergencyContacts[index].Address.Zip,
                agencyId: this.accountId,
                application: this.application
            });
            const county: string[] = await this._lookupService.getCountyByZipcode(
                this.referral.Contacts.AdditionalEmergencyContacts[index].Address.Zip,
                this.accountId
            );
            if (index === 0) this.setAdditionalCounty1(county);
            if (index === 1) this.setAdditionalCounty2(county);
            if (index === 2) this.setAdditionalCounty3(county);
            this.referral.Contacts.AdditionalEmergencyContacts[index].Address.County = county ? county[0] : null;
            this.referral.Contacts.AdditionalEmergencyContacts[index].Address.State = result.state
                ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                : null;
            this.referral.Contacts.AdditionalEmergencyContacts[index].Address.City = result.city ? result.city : null;
        }
    }
});
