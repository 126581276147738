import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { mapState, mapActions } from "pinia";
import { minValue, required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { LookupService } from "../../../../services/lookup-service";
import { PhysiciansService } from "../../../../services/physician-service";
import {
    IGetHomeHealthPhysiciansByFilters,
    IGetHomeHealthPhysicianByFiltersResult
} from "../../../../resources-vue/vue-interfaces/i-get-physician";
import { ApplicationEnum } from "../../../../enums/enums";
import { formatDateInput, sanitizeMarkup } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { useEditReferralHH } from "../../store/useEditReferralHH";

import TypeaheadInput from "../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import DatePicker from "../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import FormSection from "../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        TypeaheadInput,
        DatePicker,
        FormSection
    },

    validations() {
        return {
            referral: {
                ReferralInformation: {
                    AdmissionSource: {
                        minValueValue: minValue(1)
                    },
                    ReferralDate: { required },
                    ReferrerPhysician: { required },
                    CertifyingPhysician: { required },
                    FaceToFaceInformationNA: { required: requiredIf(() => this.faceToFaceEvaluation == null) },
                    DateOfFaceToFaceVisit: { required: requiredIf(() => this.faceToFaceEvaluation) },
                    FaceToFaceToBeCompletedWithin30Days: { required: requiredIf(() => !this.faceToFaceEvaluation) }
                },
                Insurance: {
                    AdditionalBillingInfo: {
                        AdmissionType: {
                            minValue: minValue(1)
                        }
                    }
                }
            }
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId",
            errors: "errors"
        }),

        faceToFaceEvaluationError() {
            return this.errors.ReferralInformation.includes("FaceToFaceInformationNA") ||
                this.errors.ReferralInformation.includes("DateOfFaceToFaceVisit") ||
                this.errors.ReferralInformation.includes("FaceToFaceToBeCompletedWithin30Days")
                ? "border border-warning rounded p-2"
                : "";
        }
    },

    data() {
        return {
            referralOption: null,
            isreferralOption: false,
            certifyRef: null,
            faceToFaceEvaluation: null,
            referringPhysicianName: null,
            certifyingPhysicianName: null,
            showCertifyPhysicianFilters: false,
            _lookupService: null,
            _physicianService: null,
            cachePhysician: null,
            issetPhysicians: false,
            isSetCertifyingPhysician: false,
            physicianDataCache: [] as IGetHomeHealthPhysicianByFiltersResult[],
            attendingPhysicianTooltip: `<strong>Title</strong>
                                        <p>Attending Physician</p>
                                        <hr>
                                        <strong>Description</strong>
                                        <p>The Primary Physician is the physician that is providing Care Plan Oversight. This physician could be the same as the referring and/or the certifying physician.</p><hr>
                                        <strong>Intent</strong>
                                        <p>Medicare Chapter 7 identifies this physician in 30.3 – “Under the Care of a Physician- The patient must be under the care of a physician who is qualified to sign the physician certification and plan of care in accordance with 42 CFR 424.22. A patient is expected to be under the care of the physician who signs the plan of care. It is expected that in most instances, the physician who certifies the patient’s eligibility for Medicare home health services, in accordance with §30.5 will be the same physician who establishes and signs the plan of care. However, the rules listed 30.5.1 recognizes this is not always the case, especially as it relates to patient’s discharged from the hospital”.</p><hr>
                                        <strong>Impact</strong>
                                        <p>The Attending Physician is identified on the Plan of Care, subsequent plans of care and physician orders. This is the default physician listed for all subsequent orders throughout orders management.</p>`,

            certifyingPhysicianTooltip: `<strong>Title</strong>
                                         <p>Certifying Physician</p>
                                         <hr>
                                         <strong>Description</strong>
                                         <p>The Certifying Physician is the physician that certifies that a patient is eligible for Medicare home health services. The physician that establishes the Plan of Care, must sign and date the certification (POC). This physician may be the same as the referring and/or attending physician.<a href='https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf'> https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf</a></p><hr>
                                         <strong>Intent</strong>
                                         <p>Medicare Chapter 7 identifies this physician as “the physician who is certifying that this patient meets the qualifications for home health care. 30.5.1: If the patient is starting home health directly after discharge from an acute/post-acute care setting where the physician, with privileges, that cared for the patient in that setting is certifying the patient’s eligibility for the home health benefit, but will not be following the patient after discharge, then the certifying physician must identify the community physician who will be following the patient after discharge (attending physician). One of the criteria that must be met for a patient to be considered eligible for the home health benefit is that the patient must be under the care of a physician”.</p><hr>
                                         <strong>Impact</strong>
                                         <p>The Certifying Physician is identified on the Plan of Care at SOC. At SOC, this is the physician that is sent through orders management and identified for billing. This field may be updated for subsequent Plans of Care if this physician changes after SOC.</p>`,

            refPhysicianTooltip: `<strong>Title</strong>
                                  <p>Referring Physician</p>
                                  <hr>
                                  <strong>Description</strong>
                                  <p>The Referring Physician or allowed non-physician practitioner (NPP) is the person that is primarily providing the face to face information for this admission. This physician may be the same as the physician signing (certifying) the POC as well as the attending physician. Commonly this person may be the hospitalist if the patient is being discharged from a facility.
                                  </p><hr>
                                  <strong>Intent</strong>
                                  <p>OASIS C2 2018 guidelines: “A valid referral is considered to have been received when the agency has received adequate information about a patient (name, address/contact info, and diagnosis and/or general home care needs) and the agency has ensured that referring physician, or another physician, will provide the plan of care and ongoing orders. In cases where home care is requested by a hospitalist who will not be providing an ongoing plan of care for the patient, the agency must contact an alternate, or attending physician, and upon agreement from this following physician for referral and/or further orders, the agency will note this as the referral date in M0104”.
                                  </p><hr>
                                  <strong>Impact</strong>
                                  <p>The Referring Physician is captured in the patient profile and reports. May also be identified by the admitting clinician as the physician contacted for additional information required/needed to complete the F2F documentation requirements.</p>`,

            signingPOCTooltip: `<strong>Title</strong>
                                <p>Certifying Physician</p>
                                <hr>
                                <strong>Description</strong>
                                <p>
                                   The Certifying Physician is the physician that certifies that a patient is eligible for Medicare home health services. The physician that establishes the Plan of Care, must sign and date the certification (POC). This physician may be the same as the referring and/or attending physician.
                                   <a style="text-decoration: underline;" href="https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf" target="_blank">https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf</a>
                                </p>
                                <hr>
                                <strong>Intent</strong>
                                <p>Medicare Chapter 7 identifies this physician as “the physician who is certifying that this patient meets the qualifications for home health care. 30.5.1: If the patient is starting home health directly after discharge from an acute/post-acute care setting where the physician, with privileges, that cared for the patient in that setting is certifying the patient’s eligibility for the home health benefit, but will not be following the patient after discharge, then the certifying physician must identify the community physician who will be following the patient after discharge (attending physician). One of the criteria that must be met for a patient to be considered eligible for the home health benefit is that the patient must be under the care of a physician”. </p>
                                <hr>
                                <strong>Impact</strong>
                                <p>The Certifying Physician is identified on the Plan of Care at SOC. At SOC, this is the physician that is sent through orders management and identified for billing. This field may be updated for subsequent Plans of Care if this physician changes after SOC. </p>`,

            inpatientCodeTooltip: `<strong> Title </strong>
                                   <p> Type of Admission/Visit Code </p>
                                   <hr>
                                   <strong> Description </strong>
                                   <p> These codes identify the type and priority of an inpatient admission associated with service on an intermediary-submitted claim.</p>
                                   <hr>
                                   <strong>Intent </strong>
                                   <p> <strong> Emergency:</strong> The patient required immediate medical intervention as a result of severe, life-threatening, or potentially disabling conditions. In general, patients in this category are admitted through the emergency room.</p>
                                   <p> <strong> Urgent: </strong> The patient required immediate attention for the care and treatment of a physical or mental disorder. In general, patients in this category are admitted to the first available and suitable accommodation.</p>
                                   <p> <strong>  Elective: </strong> The patient's condition permitted adequate time to schedule according to availability of suitable accommodations. </p>
                                   <p> <strong> Newborn: </strong> This code necessitates the use of special codes in the source of admission. </p>
                                   <p> <strong> Trauma Center:</strong> The patient required visits to a trauma center/hospital involving a trauma activation. Visits to a trauma center/hospital were designated or licensed by the state or local government authority or verified by the American College of Surgeons. </p>
                                   <p> <strong> Unknown: </strong> Information not available. </p>
                                   <hr>
                                   <strong>Impact</strong>
                                   <p>These codes automatically flow to the appropriate claims and are required for home health billing compliance.</p>`,
            faceToFaceTooltip: `<strong> Title </strong>
                                <p> Home Health Face to Face Requirements </p>
                                <hr>
                                <strong> Description </strong>
                                <p> For Medicare beneficiaries, a face-to-face visit must be documented for every completed start of care OASIS assessment.</p>
                                <hr>
                                <strong>Intent </strong>
                                <p>Face-to-Face considerations:</p>
                                <ul class="list-style-disc">
                                <li>Documentation directly from the certifying physician’s and/or facilities medical
                                record e.g., discharge summary, history and physical, clinical notes, and office
                                visit notes.</li>
                                <li> Visit note documentation must include the primary diagnosis/reason for care. </li>
                                <li> Need for skilled services and the homebound status are added to the plan of care and
                                F2F addendum in Axxess for the physician to sign as corroboration for patient
                                certification. </li>
                                <li> At SOC, enter the date of the F2F which will flow to the plan of care automatically
                                and then upload the document to the chart.</li>
                                <li> If the F2F information is not available, select F2F to be completed within 30 days
                                option and then track F2F under order to be sent screen by checking “Track
                                Certification” under Demographics>Episode Management. </li>
                                <li> Select N/A if not a Medicare/Medicaid beneficiary. </li>
                                </ul>
                                <hr>
                                <strong>Impact</strong>
                                <p>F2F remains one of the most frequent causes of claim denial. Primary diagnosis missing from the F2F is the number one reason for rejected claims. F2F must be obtained no later than 30 days into the episode. No F2F equals no PAYMENT.</p>`,
            physicianValidation: {
                required: true,
                displayName: "Referring Physician"
            },
            CertifyingphysicianValidation: {
                required: true,
                displayName: "Certifying Physician"
            },
            physicianFilters: {
                agencyId: null,
                npi: null,
                physicianName: null,
                state: null,
                city: null
            },
            refName: "edit-homehealth-ref",
            referringPhysicianFilters: null,
            certifyingPhysicianFilters: null,
            formatDateInput,
            sanitizeMarkup,
            v$: useVuelidate()
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
        this._physicianService = Container.instance.get(PhysiciansService);
        this.cachePhysician = {};
        this.referringPhysicianFilters = JSON.parse(JSON.stringify(this.physicianFilters));
        this.certifyingPhysicianFilters = JSON.parse(JSON.stringify(this.physicianFilters));
        this.certifyRef = HTMLElement;
        if (this.referral.ReferralInformation.IsSigningPlanOfCare) {
            this.isSetCertifyingPhysician = true;
        }
        this.setInitialValues();
    },

    watch: {
        referringPhysicianName(newValue: any) {
            let name = newValue;
            if (typeof newValue == "object") {
                name = newValue?.name?.toString();
                this.referral.ReferralInformation.ReferrerPhysician = newValue?.value;
                this.referral.ReferralInformation.ReferrerPhysicianName = name;
            }
            this.referringPhysicianFilters.physicianName = name || null;
            if (this.referral.ReferralInformation.IsSigningPlanOfCare && !this.issetPhysicians) {
                this.referral.ReferralInformation.IsSigningPlanOfCare = false;
            }
            this.issetPhysicians = false;
        },

        certifyingPhysicianName(newValue: any) {
            if (!newValue) {
                this.referral.ReferralInformation.IsSigningPlanOfCare = false;
                this.certifyingPhysicianFilters.physicianName = null;
                return;
            }
            let name = newValue;
            if (typeof newValue == "object") {
                name = newValue?.name?.toString();
                this.referral.ReferralInformation.CertifyingPhysician = newValue?.value;
                this.referral.ReferralInformation.CertifyingPhysicianName = name;
                if (
                    this.referral.ReferralInformation.CertifyingPhysician !=
                    this.referral.ReferralInformation.ReferrerPhysician
                ) {
                    this.referral.ReferralInformation.IsSigningPlanOfCare = false;
                }
            }
            this.certifyingPhysicianFilters.physicianName = name || null;
            this.referral.ReferralInformation.CertifyingPhysicianName = name;
        },

        showCertifyPhysicianFilters() {
            if (!this.certifyingPhysicianFilters) return;
            this.certifyingPhysicianFilters.state = null;
            this.certifyingPhysicianFilters.city = null;
            this.certifyingPhysicianFilters.npi = null;
        },

        faceToFaceEvaluation(value: boolean | null) {
            if (!this.referral) return;
            if (value == null) {
                this.referral.ReferralInformation.FaceToFaceInformationNA = true;
                this.referral.ReferralInformation.DateOfFaceToFaceVisit = null;
                this.referral.ReferralInformation.FaceToFaceToBeCompletedWithin30Days = false;
            } else if (value == false) {
                this.referral.ReferralInformation.FaceToFaceInformationNA = false;
                this.referral.ReferralInformation.DateOfFaceToFaceVisit = null;
                this.referral.ReferralInformation.FaceToFaceToBeCompletedWithin30Days = true;
            } else if (value == true) {
                this.referral.ReferralInformation.FaceToFaceInformationNA = false;
                this.referral.ReferralInformation.FaceToFaceToBeCompletedWithin30Days = false;
            }
        }
    },

    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        setInitialValues() {
            if (this.referral?.ReferralInformation?.FaceToFaceInformationNA) {
                this.faceToFaceEvaluation = null;
            } else if (this.referral?.ReferralInformation?.FaceToFaceToBeCompletedWithin30Days) {
                this.faceToFaceEvaluation = false;
            } else {
                this.faceToFaceEvaluation = true;
            }
            if (
                ![null, "", "00000000-0000-0000-0000-000000000000"].includes(
                    this.referral.ReferralInformation.ReferrerPhysician
                )
            ) {
                this.setPhysicians();
            }
        },

        async setPhysicians() {
            if (this.referral.ReferralInformation.IsSigningPlanOfCare) this.issetPhysicians = true;
            let obj = {
                agencyId: this.referral.AgencyId,
                application: this.application || ApplicationEnum.AgencyCore,
                physicianId: this.referral?.ReferralInformation?.ReferrerPhysician
            };
            const result = await this.getPhysicianNameById(obj);
            if (result) {
                this.referringPhysicianName = result;
                this.cachePhysician = { name: result, value: this.referral?.ReferralInformation.ReferrerPhysician };
            }
            if (this.isSetCertifyingPhysician) {
                this.certifyingPhysicianName = this.cachePhysician;
            } else if (this.referral.ReferralInformation.CertifyingPhysician) {
                obj.physicianId = this.referral?.ReferralInformation?.CertifyingPhysician;
                const results = await this.getPhysicianNameById(obj);
                if (results) {
                    this.certifyingPhysicianName = results;
                }
            }
        },

        signingPOCChanged() {
            let checked = this.referral?.ReferralInformation?.IsSigningPlanOfCare;
            if (checked) {
                this.certifyingPhysicianName = this.referral.ReferralInformation.ReferrerPhysicianName;
                this.referral.ReferralInformation.CertifyingPhysician =
                    this.referral.ReferralInformation.ReferrerPhysician;
                this.referral.ReferralInformation.CertifyingPhysicianName =
                    this.referral.ReferralInformation.ReferrerPhysicianName;
            } else {
                if (this.certifyingPhysicianName) {
                    this.certifyingPhysicianName = null;
                }
                this.referral.ReferralInformation.CertifyingPhysician = null;
                this.referral.ReferralInformation.CertifyingPhysicianName = null;
            }
        },

        npiChanged(type: string) {
            if (!type) return;
            setTimeout(() => {
                let physicanType = this.referringPhysicianFilters;
                if (type == "certifying") physicanType = this.certifyingPhysicianFilters;
                physicanType = JSON.parse(JSON.stringify(physicanType));
                let physicianSelected = this.physicianDataCache.find((item) => item.npi == physicanType.npi);
                if (!physicianSelected) return;
                if (type == "certifying") {
                    this.certifyingPhysicianName = physicianSelected.physicianName;
                    this.referral.ReferralInformation.CertifyingPhysician = physicianSelected?.physicianId || null;
                } else {
                    this.referringPhysicianName = physicianSelected.physicianName;
                    this.referral.ReferralInformation.ReferrerPhysician = physicianSelected?.physicianId || null;
                }
                physicanType.physicianName = physicianSelected?.physicianName?.toString() || null;
            }, 100);
        },

        async getPhysicianNameById(queryData: { agencyId: string; application: ApplicationEnum; physicianId: string }) {
            if (queryData.physicianId && queryData.physicianId != "00000000-0000-0000-0000-000000000000") {
                const result = await this._physicianService.getPhysicianNameById(queryData);
                return result || null;
            }
        },

        async getPhysicianData(queryData: IGetHomeHealthPhysiciansByFilters) {
            let data = await this._lookupService.getPhysiciansByFilters(queryData);
            return data.matchedPhysicians?.map((item: any) => ({ value: item.physicianId, name: item.physicianName }));
        },

        async getReferringPhysicianList(filter: string, limit: number) {
            this.referringPhysicianFilters.agencyId = this.referral.AgencyId;
            this.referringPhysicianFilters.physicianName = filter.toString();
            return await this.getPhysicianData(this.referringPhysicianFilters);
        },
        async getCertifyingPhysicianList(filter: string, limit: number) {
            this.certifyingPhysicianFilters.agencyId = this.referral.AgencyId;
            this.certifyingPhysicianFilters.physicianName = filter.toString();
            return await this.getPhysicianData(this.certifyingPhysicianFilters);
        },

        async getReferringPhysicianListItem(filter: string, resultsLimit: number) {
            this.referringPhysicianFilters.agencyId = this.referral?.AgencyId;
            let itemName: string;
            resultsLimit == 1 ? (itemName = "city") : resultsLimit == 2 ? (itemName = "state") : (itemName = "npi");
            this.referringPhysicianFilters[itemName] = filter;
            let data = await this._lookupService.getPhysiciansByFilters(this.referringPhysicianFilters);
            if (itemName === "npi") this.physicianDataCache = data.matchedPhysicians;
            return [...new Set(data.matchedPhysicians?.map((item: any) => item[itemName]))];
        },

        async getCertifyingPhysicianListItem(filter: string, resultsLimit: number) {
            this.certifyingPhysicianFilters.agencyId = this.referral.AgencyId;
            let itemName: string;
            resultsLimit == 1 ? (itemName = "city") : resultsLimit == 2 ? (itemName = "state") : (itemName = "npi");
            this.certifyingPhysicianFilters[itemName] = filter;
            let data = await this._lookupService.getPhysiciansByFilters(this.certifyingPhysicianFilters);
            if (itemName === "npi") this.physicianDataCache = data.matchedPhysicians;
            return [...new Set(data.matchedPhysicians?.map((item: any) => item[itemName]))];
        },

        onDateChange(date: any, name: string) {
            if (name == "referral-date-edit") {
                this.referral.ReferralInformation.ReferralDate = date;
            }
            if (name == "evaluation-date-edit") {
                this.referral.ReferralInformation.DateOfFaceToFaceVisit = date;
            }
        },

        referringPhysicianFiltersCity(data: any) {
            if (data) this.referringPhysicianFilters.city = data;
        },

        referringPhysicianFiltersState(data: any) {
            this.referringPhysicianFilters.state = data;
        },

        referringPhysicianNameChange(data: any) {
            this.referringPhysicianName = data;
            if (data) this.certifyingPhysicianName = data;
            if (typeof data === "object") {
                this.handleAllInputChange("ReferrerPhysician", data.value);
            } else {
                this.handleAllInputChange("ReferrerPhysician", data);
            }
        },

        referringPhysicianFiltersNpiChange(data: any) {
            this.referringPhysicianFilters.npi = data;
        },

        certifyingPhysicianFiltersCityChange(data: any) {
            if (data) this.certifyingPhysicianFilters.city = data;
        },

        certifyingPhysicianFiltersStateChange(data: any) {
            this.certifyingPhysicianFilters.state = data;
        },

        certifyingPhysicianNameChange(data: any) {
            if (data) this.certifyingPhysicianName = data;
            if (typeof data === "object") {
                this.handleAllInputChange("CertifyingPhysician", data.value);
            } else {
                this.handleAllInputChange("CertifyingPhysician", data);
            }
        },

        certifyingPhysicianFiltersNpiChange(data: any) {
            this.certifyingPhysicianFilters.npi = data;
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
            this.handleAllInputChange(name, value);
        },
        handleAllInputChange(field: string, value: any) {
            if (value.length || value !== "0") {
                const newErrors = [...new Set(this.errors.ReferralInformation)].filter((val: any) => val !== field);
                const payload = {
                    property: "ReferralInformation",
                    value: newErrors
                };
                this.setErrors(payload);
            }
        },
        handleBillingInfo(field: string) {
            if (this.referral.Insurance.AdditionalBillingInfo.AdmissionType > 0) {
                const newErrors = [...new Set(this.errors.AdditionalBillingInfo)].filter((val: any) => val !== field);
                const payload = {
                    property: "AdditionalBillingInfo",
                    value: newErrors
                };
                this.setErrors(payload);
            }
        }
    }
});
