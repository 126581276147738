import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";
import { LookupService } from "../../../../../services/lookup-service";
import { PhysiciansService } from "../../../../../services/physician-service";
import {
    IGetHomeHealthPhysiciansByFilters,
    IGetHomeHealthPhysicianByFiltersResult
} from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { ApplicationEnum } from "../../../../../enums/enums";
import { formatDateInput, sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        TypeaheadInput,
        DatePicker,
        FormSection
    },

    validations() {
        return {
            referral: {
                ReferralExtension: {
                    DateOfFaceToFaceVisit: this.faceToFaceEvaluation ? { required } : { required: false }
                },
                Referral: {
                    AdmissionSource: {
                        minValueValue: minValue(1)
                    },
                    AdditionalBillingInfo: {
                        AdmissionType: {
                            minValueValue: minValue(1)
                        }
                    },
                    ReferralDate: { required }
                }
            },
            referringPhysicianName: !this.referringPhysicianName ? { required } : { required: false }
        };
    },

    props: {
        isEditing: {
            type: Boolean,
            default: false
        },
        referral: {
            type: HomeHealthReferral,
            default: null
        },
        referralOptions: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            referralOption: null,
            isreferralOption: false,
            certifyRef: null,
            faceToFaceEvaluation: null,
            referringPhysicianName: null,
            certifyingPhysicianName: null,
            showCertifyPhysicianFilters: false,
            _lookupService: null,
            _physicianService: null,
            cachePhysician: null,
            physicianDataCache: [] as IGetHomeHealthPhysicianByFiltersResult[],
            attendingPhysicianTooltip: `<strong>Title</strong>
                                        <p>Attending Physician</p>
                                        <hr>
                                        <strong>Description</strong>
                                        <p>The Primary Physician is the physician that is providing Care Plan Oversight. This physician could be the same as the referring and/or the certifying physician.</p><hr>
                                        <strong>Intent</strong>
                                        <p>Medicare Chapter 7 identifies this physician in 30.3 – “Under the Care of a Physician- The patient must be under the care of a physician who is qualified to sign the physician certification and plan of care in accordance with 42 CFR 424.22. A patient is expected to be under the care of the physician who signs the plan of care. It is expected that in most instances, the physician who certifies the patient’s eligibility for Medicare home health services, in accordance with §30.5 will be the same physician who establishes and signs the plan of care. However, the rules listed 30.5.1 recognizes this is not always the case, especially as it relates to patient’s discharged from the hospital”.</p><hr>
                                        <strong>Impact</strong>
                                        <p>The Attending Physician is identified on the Plan of Care, subsequent plans of care and physician orders. This is the default physician listed for all subsequent orders throughout orders management.</p>`,

            certifyingPhysicianTooltip: `<strong>Title</strong>
                                         <p>Certifying Physician</p>
                                         <hr>
                                         <strong>Description</strong>
                                         <p>The Certifying Physician is the physician that certifies that a patient is eligible for Medicare home health services. The physician that establishes the Plan of Care, must sign and date the certification (POC). This physician may be the same as the referring and/or attending physician.<a href='https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf'> https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf</a></p><hr>
                                         <strong>Intent</strong>
                                         <p>Medicare Chapter 7 identifies this physician as “the physician who is certifying that this patient meets the qualifications for home health care. 30.5.1: If the patient is starting home health directly after discharge from an acute/post-acute care setting where the physician, with privileges, that cared for the patient in that setting is certifying the patient’s eligibility for the home health benefit, but will not be following the patient after discharge, then the certifying physician must identify the community physician who will be following the patient after discharge (attending physician). One of the criteria that must be met for a patient to be considered eligible for the home health benefit is that the patient must be under the care of a physician”.</p><hr>
                                         <strong>Impact</strong>
                                         <p>The Certifying Physician is identified on the Plan of Care at SOC. At SOC, this is the physician that is sent through orders management and identified for billing. This field may be updated for subsequent Plans of Care if this physician changes after SOC.</p>`,

            refPhysicianTooltip: `<strong>Title</strong>
                                  <p>Referring Physician</p>
                                  <hr>
                                  <strong>Description</strong>
                                  <p>The Referring Physician or allowed non-physician practitioner (NPP) is the person that is primarily providing the face to face information for this admission. This physician may be the same as the physician signing (certifying) the POC as well as the attending physician. Commonly this person may be the hospitalist if the patient is being discharged from a facility.
                                  </p><hr>
                                  <strong>Intent</strong>
                                  <p>OASIS C2 2018 guidelines: “A valid referral is considered to have been received when the agency has received adequate information about a patient (name, address/contact info, and diagnosis and/or general home care needs) and the agency has ensured that referring physician, or another physician, will provide the plan of care and ongoing orders. In cases where home care is requested by a hospitalist who will not be providing an ongoing plan of care for the patient, the agency must contact an alternate, or attending physician, and upon agreement from this following physician for referral and/or further orders, the agency will note this as the referral date in M0104”.
                                  </p><hr>
                                  <strong>Impact</strong>
                                  <p>The Referring Physician is captured in the patient profile and reports. May also be identified by the admitting clinician as the physician contacted for additional information required/needed to complete the F2F documentation requirements.</p>`,

            signingPOCTooltip: `<strong>Title</strong>
                                <p>Certifying Physician</p>
                                <hr>
                                <strong>Description</strong>
                                <p>
                                   The Certifying Physician is the physician that certifies that a patient is eligible for Medicare home health services. The physician that establishes the Plan of Care, must sign and date the certification (POC). This physician may be the same as the referring and/or attending physician.
                                   <a style="text-decoration: underline;" href="https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf" target="_blank">https://www.cms.gov/Outreach-and-Education/Outreach/NPC/Downloads/2014-12-16-HHBenefit-HL.pdf</a>
                                </p>
                                <hr>
                                <strong>Intent</strong>
                                <p>Medicare Chapter 7 identifies this physician as “the physician who is certifying that this patient meets the qualifications for home health care. 30.5.1: If the patient is starting home health directly after discharge from an acute/post-acute care setting where the physician, with privileges, that cared for the patient in that setting is certifying the patient’s eligibility for the home health benefit, but will not be following the patient after discharge, then the certifying physician must identify the community physician who will be following the patient after discharge (attending physician). One of the criteria that must be met for a patient to be considered eligible for the home health benefit is that the patient must be under the care of a physician”. </p>
                                <hr>
                                <strong>Impact</strong>
                                <p>The Certifying Physician is identified on the Plan of Care at SOC. At SOC, this is the physician that is sent through orders management and identified for billing. This field may be updated for subsequent Plans of Care if this physician changes after SOC. </p>`,

            inpatientCodeTooltip: `<strong> Title </strong>
                                   <p> Type of Admission/Visit Code </p>
                                   <hr>
                                   <strong> Description </strong>
                                   <p> These codes identify the type and priority of an inpatient admission associated with service on an intermediary-submitted claim.</p>
                                   <hr>
                                   <strong>Intent </strong>
                                   <p> <strong> Emergency:</strong> The patient required immediate medical intervention as a result of severe, life-threatening, or potentially disabling conditions. In general, patients in this category are admitted through the emergency room.</p>
                                   <p> <strong> Urgent: </strong> The patient required immediate attention for the care and treatment of a physical or mental disorder. In general, patients in this category are admitted to the first available and suitable accommodation.</p>
                                   <p> <strong>  Elective: </strong> The patient's condition permitted adequate time to schedule according to availability of suitable accommodations. </p>
                                   <p> <strong> Newborn: </strong> This code necessitates the use of special codes in the source of admission. </p>
                                   <p> <strong> Trauma Center:</strong> The patient required visits to a trauma center/hospital involving a trauma activation. Visits to a trauma center/hospital were designated or licensed by the state or local government authority or verified by the American College of Surgeons. </p>
                                   <p> <strong> Unknown: </strong> Information not available. </p>
                                   <hr>
                                   <strong>Impact</strong>
                                   <p>These codes automatically flow to the appropriate claims and are required for home health billing compliance.</p>`,
            physicianValidation: {
                required: true,
                displayName: "Referring Physician"
            },
            CertifyingphysicianValidation: {
                required: true,
                displayName: "Certifying Physician"
            },
            physicianFilters: {
                agencyId: null,
                npi: null,
                physicianName: null,
                state: null,
                city: null
            },
            referringPhysicianFilters: null,
            certifyingPhysicianFilters: null,
            formatDateInput,
            sanitizeMarkup,
            v$: useVuelidate()
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
        this._physicianService = Container.instance.get(PhysiciansService);
        this.cachePhysician = {};
        this.referringPhysicianFilters = JSON.parse(JSON.stringify(this.physicianFilters));
        this.certifyingPhysicianFilters = JSON.parse(JSON.stringify(this.physicianFilters));
        this.certifyRef = HTMLElement;
        this.bind();
    },

    watch: {
        referringPhysicianName: {
            handler(newValue: any) {
                let name = newValue;
                if (typeof newValue == "object") {
                    this.cachePhysician = newValue;
                    name = newValue?.name?.toString();
                    this.referral.Referral.ReferrerPhysician = newValue?.value;
                }
                this.referringPhysicianFilters.physicianName = name || null;
                if (this.referral.ReferralExtension.IsSigningPlanOfCare) {
                    this.referral.ReferralExtension.IsSigningPlanOfCare = false;
                }
            }
        },

        certifyingPhysicianName(newValue: any) {
            if (!newValue) {
                this.referral.ReferralExtension.IsSigningPlanOfCare = false;
                this.certifyingPhysicianFilters.physicianName = null;
                return;
            }
            let name = newValue;
            if (typeof newValue == "object") {
                name = newValue?.name?.toString();
                this.referral.ReferralExtension.CertifyingPhysician = newValue?.value;
                this.referral.ReferralExtension.CertifyingPhysicianName = name;
                if (this.referral.ReferralExtension.CertifyingPhysician != this.referral.Referral.ReferrerPhysician) {
                    this.referral.ReferralExtension.IsSigningPlanOfCare = false;
                }
            }
            this.certifyingPhysicianFilters.physicianName = name || null;
        },

        showCertifyPhysicianFilters() {
            if (!this.certifyingPhysicianFilters) return;
            this.certifyingPhysicianFilters.state = null;
            this.certifyingPhysicianFilters.city = null;
            this.certifyingPhysicianFilters.npi = null;
        },

        faceToFaceEvaluation(value: boolean | null) {
            if (!this.referral) return;
            if (value == null) {
                this.referral.ReferralExtension.FaceToFaceInformationNA = true;
                this.referral.ReferralExtension.DateOfFaceToFaceVisit = null;
                this.referral.ReferralExtension.FaceToFaceToBeCompletedWithin30Days = false;
            } else if (value == false) {
                this.referral.ReferralExtension.FaceToFaceInformationNA = false;
                this.referral.ReferralExtension.DateOfFaceToFaceVisit = null;
                this.referral.ReferralExtension.FaceToFaceToBeCompletedWithin30Days = true;
            } else if (value == true) {
                this.referral.ReferralExtension.FaceToFaceInformationNA = false;
                this.referral.ReferralExtension.FaceToFaceToBeCompletedWithin30Days = false;
            }
        }
    },

    methods: {
        async bind() {
            if (this.isEditing) {
                this.setInitialValues();
            }
        },

        async setInitialValues() {
            if (this.referral?.ReferralExtension?.FaceToFaceInformationNA) {
                this.faceToFaceEvaluation = null;
            } else if (this.referral?.ReferralExtension?.FaceToFaceToBeCompletedWithin30Days) {
                this.faceToFaceEvaluation = false;
            } else if (this.referral?.ReferralExtension?.DateOfFaceToFaceVisit) {
                this.faceToFaceEvaluation = true;
            }
            if (
                ![null, "", "00000000-0000-0000-0000-000000000000"].includes(this.referral.Referral.ReferrerPhysician)
            ) {
                this.setPhysicians();
            }
        },

        async setPhysicians() {
            let IsSigningPlanOfCare = this.referral?.ReferralExtension.IsSigningPlanOfCare;
            let obj = {
                agencyId: this.referral.agencyId,
                application: this.referral.application || ApplicationEnum.AgencyCore,
                physicianId: this.referral?.Referral?.ReferrerPhysician
            };
            const result = await this.getPhysicianNameById(obj);
            if (result) {
                this.referringPhysicianName = result;
                this.cachePhysician = { name: result, value: this.referral?.Referral.ReferrerPhysician };
            }
            this.certifyingPhysicianName = this.referral?.ReferralExtension.CertifyingPhysicianName;
            this.referral.ReferralExtension.IsSigningPlanOfCare = IsSigningPlanOfCare;
        },

        signingPOCChanged() {
            let checked = this.referral?.ReferralExtension?.IsSigningPlanOfCare;
            if (checked && this.cachePhysician?.value) {
                this.certifyingPhysicianName = this.cachePhysician?.name;
                this.referral.ReferralExtension.CertifyingPhysician = this.cachePhysician?.value;
                this.referral.ReferralExtension.CertifyingPhysicianName = this.cachePhysician?.name;
            } else {
                if (this.certifyingPhysicianName) {
                    this.certifyingPhysicianName = null;
                }
                this.referral.ReferralExtension.CertifyingPhysician = null;
                this.referral.ReferralExtension.CertifyingPhysicianName = null;
            }
        },

        npiChanged(type: string) {
            if (!type) return;
            setTimeout(() => {
                let physicanType = this.referringPhysicianFilters;
                if (type == "certifying") physicanType = this.certifyingPhysicianFilters;
                physicanType = JSON.parse(JSON.stringify(physicanType));
                let physicianSelected = this.physicianDataCache.find((item) => item.npi == physicanType.npi);
                if (!physicianSelected) return;
                if (type == "certifying") {
                    this.certifyingPhysicianName = physicianSelected.physicianName;
                    this.referral.ReferralExtension.CertifyingPhysician = physicianSelected?.physicianId || null;
                } else {
                    this.referringPhysicianName = physicianSelected.physicianName;
                    this.referral.Referral.ReferrerPhysician = physicianSelected?.physicianId || null;
                }
                physicanType.physicianName = physicianSelected?.physicianName?.toString() || null;
            }, 100);
        },

        async getPhysicianNameById(queryData: { agencyId: string; application: ApplicationEnum; physicianId: string }) {
            const result = await this._physicianService.getPhysicianNameById(queryData);
            return result || null;
        },

        async getPhysicianData(queryData: IGetHomeHealthPhysiciansByFilters) {
            let data = await this._lookupService.getPhysiciansByFilters(queryData);
            return data.matchedPhysicians?.map((item: any) => ({ value: item.physicianId, name: item.physicianName }));
        },

        async getReferringPhysicianList(filter: string, limit: number) {
            this.referringPhysicianFilters.agencyId = this.referral.agencyId;
            this.referringPhysicianFilters.physicianName = filter.toString();
            return await this.getPhysicianData(this.referringPhysicianFilters);
        },
        async getCertifyingPhysicianList(filter: string, limit: number) {
            this.certifyingPhysicianFilters.agencyId = this.referral.agencyId;
            this.certifyingPhysicianFilters.physicianName = filter.toString();
            return await this.getPhysicianData(this.certifyingPhysicianFilters);
        },

        async getReferringPhysicianListItem(filter: string, itemNumber: number) {
            this.referringPhysicianFilters.agencyId = this.referral?.agencyId;
            let itemName: string;
            itemNumber == 1 ? (itemName = "city") : itemNumber == 2 ? (itemName = "state") : (itemName = "npi");
            this.referringPhysicianFilters[itemName] = filter;
            let data = await this._lookupService.getPhysiciansByFilters(this.referringPhysicianFilters);
            if (itemName === "npi") this.physicianDataCache = data.matchedPhysicians;
            return [...new Set(data.matchedPhysicians?.map((item: any) => item[itemName]))];
        },

        async getCertifyingPhysicianListItem(filter: string, itemNumber: number) {
            this.certifyingPhysicianFilters.agencyId = this.referral.agencyId;
            let itemName: string;
            itemNumber == 1 ? (itemName = "city") : itemNumber == 2 ? (itemName = "state") : (itemName = "npi");
            this.certifyingPhysicianFilters[itemName] = filter;
            let data = await this._lookupService.getPhysiciansByFilters(this.certifyingPhysicianFilters);
            if (itemName === "npi") this.physicianDataCache = data.matchedPhysicians;
            return [...new Set(data.matchedPhysicians?.map((item: any) => item[itemName]))];
        },

        onDateChange(date: any, name: string) {
            if (name == "referral-date") {
                this.referral.Referral.ReferralDate = date;
            }
            if (name == "evaluation-date") {
                this.referral.ReferralExtension.DateOfFaceToFaceVisit = date;
            }
        },

        referringPhysicianFiltersCity(data: any) {
            this.referringPhysicianFilters.city = data;
        },

        referringPhysicianFiltersState(data: any) {
            this.referringPhysicianFilters.state = data;
        },

        referringPhysicianNameChange(data: any) {
            this.referringPhysicianName = data;
        },

        referringPhysicianFiltersNpiChange(data: any) {
            this.referringPhysicianFilters.npi = data;
        },

        certifyingPhysicianFiltersCityChange(data: any) {
            this.certifyingPhysicianFilters.city = data;
        },

        certifyingPhysicianFiltersStateChange(data: any) {
            this.certifyingPhysicianFilters.state = data;
        },

        certifyingPhysicianNameChange(data: any) {
            this.certifyingPhysicianName = data;
        },

        certifyingPhysicianFiltersNpiChange(data: any) {
            this.certifyingPhysicianFilters.npi = data;
        },

        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
        }
    }
});
