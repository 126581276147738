import { defineStore } from "pinia";
import { SET_LOADING } from "./types";

export const useLoadingIndicator = defineStore("loading-indicator", {
    state: () => ({
        loading: false
    }),
    actions: {
        [SET_LOADING](payload: boolean) {
            this.loading = payload;
        }
    }
});
