import { autoinject } from "aurelia-framework";
import type { IRequestHandler } from "./i-handlers";
import { User, UserManager } from "oidc-client";
import config from "../config";

@autoinject()
export class OrganizationIdHandler implements IRequestHandler {
    private _userManager: UserManager;

    public constructor(userManager: UserManager) {
        this._userManager = userManager;
    }
    public async handle(request: Request): Promise<void> {
        let user: User = await this._userManager.getUser();

        if (user != null) {
            if (window.location.href.includes("/axxess-central")) {
                const organizationId = window.localStorage.getItem(config.organizationId);
                if (request.url.includes("/api/v1/meta/organizations")) {
                    request.headers.delete("X-OrganizationId");
                    return;
                }

                if (organizationId != null) {
                    if (!request.headers.get("X-OrganizationId")) {
                        request.headers.append("X-OrganizationId", `${organizationId}`);
                    }
                } else {
                    console.log("Organization is not set, no X-OrganizationId set.");
                }
            } else {
                return;
            }
        } else {
            console.log("User is not logged in, no Authorization set.");
        }
    }
}
