import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";
import { EmergencyContact } from "../../../../../resources-vue/vue-models/emergency-contact";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { LookupService } from "../../../../../services/lookup-service";
import { PhysiciansService } from "../../../../../services/physician-service";
import { Address } from "../../../../../resources-vue/vue-models/address";
import { ApplicationEnum } from "../../../../../enums/enums";
import { formatPhone, sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import eventBus from "../../../../../utilities/eventBus";

import TagsInput from "../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import ReferralAddressForm from "../ReferralAddressForm/ReferralAddressForm.vue";

export default defineComponent({
    components: {
        TagsInput,
        ReferralAddressForm,
        TypeaheadInput,
        FormSection
    },

    props: {
        isEditing: {
            type: Boolean,
            default: false
        },
        referral: {
            type: HomeHealthReferral,
            default: null
        },
        referralOptions: {
            type: Object,
            default: null
        },
        isRefer: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedServicesRequired: [] as ITypeaheadOptions[],
            _lookupService: null,
            _physicianService: null,
            attendingPhysician: null,
            emergencyContactAddress: new Address(),
            servicesRequired: [] as any[],
            patientPrimaryAddress: null,
            isEditRequested: true,
            IsPhysicianContacted: false,
            emergencyContactIndex: 0,
            addressKeys: {
                AddressLine1: "",
                AddressLine2: "",
                AddressCity: "",
                AddressStateCode: "",
                AddressZipCode: "",
                AddressZipCodeFour: "",
                AddressCounty: ""
            },
            emergencyContactFields: null,
            primaryEmergencyCounties: [] as string[],
            emergencyContactKeys: {
                primaryEmergency: {
                    AddressLine1: "AddressLine1",
                    AddressLine2: "AddressLine2",
                    AddressCity: "AddressCity",
                    AddressStateCode: "AddressStateCode",
                    AddressZipCode: "AddressZipCode",
                    AddressZipCodeFour: "AddressZipCodeFour",
                    AddressCounty: "AddressCounty"
                }
            },
            additionalEmergency: null,
            attendingPhysicianTooltip: `<strong>Title</strong>
                                        <p>Attending Physician</p>
                                        <hr>
                                        <strong>Description</strong>
                                        <p>The Primary Physician is the physician that is providing Care Plan Oversight. This physician could be the same as the referring and/or the certifying physician.
                                        </p><hr>
                                        <strong>Intent</strong>
                                        <p>Medicare Chapter 7 identifies this physician in 30.3 â€“ â€œUnder the Care of a Physician- The patient must be under the care of a physician who is qualified to sign the physician certification and plan of care in accordance with 42 CFR 424.22. A patient is expected to be under the care of the physician who signs the plan of care. It is expected that in most instances, the physician who certifies the patientâ€™s eligibility for Medicare home health services, in accordance with Â§30.5 will be the same physician who establishes and signs the plan of care. However, the rules listed 30.5.1 recognizes this is not always the case, especially as it relates to patientâ€™s discharged from the hospitalâ€.
                                        </p><hr>
                                        <strong>Impact</strong>
                                        <p>The Attending Physician is identified on the Plan of Care, subsequent plans of care and physician orders. This is the default physician listed for all subsequent orders throughout orders management.</p>`,
            attendingPhysicianContactedTooltip: `<strong>Title</strong>
                                                 <p>Attending Physician Contacted</p>
                                                 <hr>
                                                 <strong>Description</strong>
                                                 <p>In most cases, Medicare anticipates the certifying physician and the attending physician will be the same. In the case where the referring physician will not provide ongoing orders, the attending physician must be contacted in advance of care, and agree to provide ongoing orders attesting the patient is under their care. This would apply in both instances when a referring physician such as a hospitalist, agrees to sign (certify) or not to sign the Plan of Care.
                                                 </p><hr>
                                                 <strong>Intent</strong>
                                                 <p>A valid referral is considered to have been received when the agency has received adequate information about a patient (name, address/contact info, and diagnosis and/or general home care needs) and the agency has ensured that the referring physician, or another physician, will provide the plan of care and ongoing orders. In cases where home care is requested by a hospitalist who will not be providing an ongoing Plan of Care for the patient, the agency must contact an alternate, or attending physician, and upon agreement from this following physician for referral and/or further orders, the agency will note this as the referral date in M0104 (unless referral details are later updated or revised-OASIS C2 Guidance Manual-pg 140).
                                                 </p><hr>
                                                 <strong>Impact</strong>
                                                 <p>If the attending physician is not the referring physician, documentation is required by CMS to verify the requirement for a valid referral is fulfilled. This documentation will be in the Edit Patient Profile View and flow to the Patient Profile.`,
            sanitizeMarkup,
            formatPhone
        };
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
        this._physicianService = Container.instance.get(PhysiciansService);
        this.emergencyContactFields = {
            primaryEmergency: JSON.parse(JSON.stringify(this.addressKeys))
        };
        this.referral.Referral.EmergencyContact.IsPrimary = true;
        const servicesRequired = this.referralOptions.serviceRequired;
        this.servicesRequired = servicesRequired?.map((option: any) => ({
            name: option.text,
            value: option.value
        }));
        if (this.isEditing || this.isRefer) {
            this.setReferralValues();
        }
        eventBus.on("addressChangedforPrimary", () => {
            this.referral.Referral.EmergencyContact.SameAsPatientAddress = false;
            if (this.additionalEmergency) {
                this.referral.ReferralExtension.AdditionalEmergencyContact.forEach((item) => {
                    item.SameAsPatientAddress = false;
                });
            }
        });
    },

    watch: {
        attendingPhysician(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions): void {
            if (newValue !== oldValue && !!this.referral) {
                this.referral.ReferralExtension.PrimaryCarePhysician = newValue?.value;
                this.attendingPhysician.name = newValue?.name;
            }
        },

        selectedServicesRequired() {
            if (!!this.selectedServicesRequired && !!this.referral) {
                this.referral.Referral.ServicesRequiredCollection = this.selectedServicesRequired?.map(
                    (ethnicity) => ethnicity?.value
                );
            }
        }
    },

    methods: {
        setReferralValues() {
            if (this.referral.Referral.EmergencyContact) {
                this.referral.Referral.EmergencyContact = new EmergencyContact(this.referral.Referral.EmergencyContact);
            }
            if (this.referral.Referral.ServicesRequired) {
                this.selectedServicesRequired = this.servicesRequired.filter((item: ITypeaheadOptions) =>
                    this.referral.Referral.ServicesRequired.includes(item.value)
                );
            }
            this.primaryEmergencyCounties =
                this.referral.CountyOptions[this.referral.Referral.EmergencyContact.AddressZipCode] || [];
            this.referral.Referral.EmergencyContact.PrimaryPhone += "";
            this.referral.Referral.EmergencyContact.AlternatePhone += "";
            if (
                ![null, "", "00000000-0000-0000-0000-000000000000"].includes(
                    this.referral.ReferralExtension.PrimaryCarePhysician
                )
            ) {
                this.setAttendingPhysician();
            }
            Object.keys(this.emergencyContactKeys.primaryEmergency).forEach((key1: string) => {
                this.emergencyContactFields.primaryEmergency[key1] =
                    this.referral.Referral.EmergencyContact[key1] || "";
            });
            if (this.referral.ReferralExtension.AdditionalEmergencyContact.length) {
                this.setAdditionalContacts();
            }
        },

        async setAttendingPhysician() {
            const queryData = {
                agencyId: this.referral.agencyId,
                application: this.referral.application || ApplicationEnum.AgencyCore,
                physicianId: this.referral.ReferralExtension.PrimaryCarePhysician
            };
            const result = await this._physicianService.getPhysicianNameById(queryData);
            if (result) {
                this.attendingPhysician = { name: result, value: this.referral.ReferralExtension.PrimaryCarePhysician };
            }
        },

        setAdditionalContacts() {
            this.referral.ReferralExtension.AdditionalEmergencyContact.forEach(
                (item: EmergencyContact, index: number) => {
                    let address: { [key: string]: string } = {};
                    item.Relationship += "";
                    Object.keys(this.addressKeys)?.forEach((key: string) => {
                        address[key] = item[key];
                    });
                    if (index) {
                        this.additionalEmergency.push({
                            emergency: address
                        });
                    } else {
                        this.additionalEmergency = [
                            {
                                emergency: address
                            }
                        ];
                    }
                }
            );
        },

        sameAddressChangedForAdditional(index: number) {
            if (!this.additionalEmergency[Number(index)]) return;
            if (this.referral.ReferralExtension.AdditionalEmergencyContact[Number(index)].SameAsPatientAddress) {
                for (let key of Object.keys(this.emergencyContactKeys.primaryEmergency)) {
                    this.additionalEmergency[Number(index)].emergency[key] = this.referral.Referral[key];
                    this.referral.ReferralExtension.AdditionalEmergencyContact[Number(index)][key] =
                        this.referral.Referral[key];
                }
            } else {
                for (let key of Object.keys(this.emergencyContactFields.primaryEmergency)) {
                    this.additionalEmergency[Number(index)].emergency[key] = null;
                    this.referral.ReferralExtension.AdditionalEmergencyContact[Number(index)][key] = null;
                }
            }
        },

        addressFieldsChanged(type: string, fieldNames: string[], index: number = null) {
            if (!type) return;
            let refType = null;
            let setUsed = null;
            if (type == "primaryEmergency") {
                refType = this.referral.Referral.EmergencyContact || null;
                setUsed = this.emergencyContactFields.primaryEmergency || null;
            } else if (index >= 0) {
                if (this.additionalEmergency) {
                    refType = this.referral?.ReferralExtension?.AdditionalEmergencyContact[Number(index)] || null;
                    setUsed = this.additionalEmergency[Number(index)]?.emergency || null;
                }
            }
            if (!refType && !setUsed) return;
            refType.SameAsPatientAddress = false;
            for (let name of fieldNames) {
                refType[name] = setUsed[name];
            }
        },

        async getPhysicianList(filter: string, limit: number) {
            let result;
            try {
                let data = await this._lookupService.getPhysiciansByName({
                    agencyId: this.referral.accountId,
                    term: filter
                });
                return data?.map((item: any) => {
                    return {
                        name: item.text,
                        value: item.value
                    };
                });
            } catch (e) {
                console.error(e);
            }
            return result;
        },

        representationChanged(value: number, contactType: EmergencyContact, index: number) {
            if (index === -1) {
                if (!(this.$refs.legal as any).checked && !(this.$refs.patient as any).checked) {
                    contactType.Representative = 0;
                } else {
                    contactType.Representative = value;
                }
            } else {
                if (!(this.$refs.legals as any)[index].checked && !(this.$refs.patients as any)[index].checked) {
                    contactType.Representative = 0;
                } else {
                    contactType.Representative = value;
                }
            }
        },

        addEmergencyContact() {
            let emergencyContact: EmergencyContact = new EmergencyContact();
            this.emergencyContactIndex++;
            this.referral.ReferralExtension.AdditionalEmergencyContact.push(emergencyContact);
            if (this.additionalEmergency == null) {
                this.additionalEmergency = [{ emergency: JSON.parse(JSON.stringify(this.addressKeys)) }];
            } else {
                this.additionalEmergency.push({
                    emergency: JSON.parse(JSON.stringify(this.addressKeys))
                });
            }
        },

        removeEmergencyContact(index: number) {
            this.emergencyContactIndex--;
            this.referral.ReferralExtension.AdditionalEmergencyContact.splice(index, 1);
            this.additionalEmergency.splice(index, 1);
        },

        isOtherOptionEmergencyContact() {
            if (this.referral.Referral.EmergencyContact.Relation != 11) {
                this.referral.Referral.EmergencyContact.Relationship = "";
            }
        },

        isOtherOptionAdditionalContacts(index: number) {
            if (this.referral.ReferralExtension.AdditionalEmergencyContact[index].Relation != 11) {
                this.referral.ReferralExtension.AdditionalEmergencyContact[index].Relationship = "";
            }
        },

        sameAsPatientAddressChangedPrimary() {
            if (this.referral.Referral.EmergencyContact.SameAsPatientAddress == null) return;
            if (this.referral.Referral.EmergencyContact.SameAsPatientAddress) {
                for (let key of Object.keys(this.emergencyContactKeys.primaryEmergency)) {
                    this.emergencyContactFields.primaryEmergency[key] = this.referral.Referral[key];
                    this.referral.Referral.EmergencyContact[key] = this.referral.Referral[key];
                }
                this.primaryEmergencyCounties =
                    this.referral.CountyOptions[this.referral.Referral.EmergencyContact.AddressZipCode] || [];
            } else {
                for (let key of Object.keys(this.emergencyContactFields.primaryEmergency)) {
                    this.emergencyContactFields.primaryEmergency[key] = null;
                    this.referral.Referral.EmergencyContact[key] = null;
                }
                this.primaryEmergencyCounties = [];
            }
        },

        updateTags(newValue: any) {
            this.selectedServicesRequired = newValue;
        },

        updatecountyData(value: any) {
            this.primaryEmergencyCounties = value.county;
        },

        attendingPhysicianChanged(newValue: object) {
            this.attendingPhysician = newValue;
        },

        setEmPrimaryPhone(value: any) {
            this.referral.Referral.EmergencyContact.PrimaryPhone = value.toString();
        },

        setEmAlternatePhone(value: any) {
            this.referral.Referral.EmergencyContact.AlternatePhone = value.toString();
        },

        setPrimaryPhone(value: any, index: number) {
            this.referral.ReferralExtension.AdditionalEmergencyContact[index].PrimaryPhone = value.toString();
        },

        setAlternatePhone(value: any, index: number) {
            this.referral.ReferralExtension.AdditionalEmergencyContact[index].AlternatePhone = value.toString();
        }
    }
});
