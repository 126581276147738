import { defineComponent } from "vue";

import CentralRouter from "./CentralRouter";
import LevelTwo from "../LevelTwo/LevelTwo.vue";

export default defineComponent({
    components: {
        LevelTwo
    },
    data() {
        return {
            router: null
        };
    },
    mounted() {
        this.router = CentralRouter;
    }
});
