
import { defineComponent } from "vue";

import EmergencyContactPrimary from "./EmergencyContactPrimary/EmergencyContactPrimary.vue";
import EmergencyContactAdditional from "./EmergencyContactAdditional/EmergencyContactAdditional.vue";
import PatientRepresentativeCordination from "./PatientRepresentativeCordination/PatientRepresentativeCordination.vue";
import ChapsSurvey from "./ChapsSurvey/ChapsSurvey.vue";
import ContactsComments from "./ContactsComments/ContactsComments.vue";

export default defineComponent({
    components: {
        EmergencyContactPrimary,
        EmergencyContactAdditional,
        PatientRepresentativeCordination,
        ChapsSurvey,
        ContactsComments
    }
});
