import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import {
    IEmployeeDetails,
    ITrackingReasonDetails,
    ITrackingTypeDetails
} from "../../../vue-interfaces/i-homecare-reassign";
import { TaskService } from "../../../../services/task.service";
import { ToastrService } from "../../../../services/toastr.service";
import { ApplicationEnum, ConflictType, ConflictTypeEnum, ConflictTypeNameEnum } from "../../../../enums/enums";
import { formatDateFromDate } from "../../../../common/vue-helpers/modifiers/value-modifier";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import MultiSelectDropdown from "../../../vue-custom-elements/MultiSelectDropdown/MultiSelectDropdown.vue";

export default defineComponent({
    components: {
        FormSection,
        MultiSelectDropdown
    },

    props: {
        visitDetails: { type: Object, default: {} },
        isSaving: { type: Boolean, default: false },
        cancel: { type: Function, default: null },
        reassign: { type: Function, default: null }
    },

    data() {
        return {
            _toastrService: null,
            _taskService: null,
            employeeList: [] as IEmployeeDetails[],
            isLoading: false as boolean,
            selectedConflictTypes: [ConflictTypeEnum.NoConflict] as number[],
            trackingTypes: [] as ITrackingTypeDetails[],
            trackingReason: [] as ITrackingReasonDetails[],
            cpyTrackingReason: [] as ITrackingReasonDetails[],
            isEmployeeListLoading: false as boolean,
            conflictTypes: [
                {
                    name: ConflictTypeNameEnum[ConflictTypeEnum.NoConflict],
                    value: ConflictTypeEnum.NoConflict,
                    type: "conflict-type"
                },
                {
                    name: ConflictTypeNameEnum[ConflictTypeEnum.PartialConflict],
                    value: ConflictTypeEnum.PartialConflict,
                    type: "conflict-type"
                },
                {
                    name: ConflictTypeNameEnum[ConflictTypeEnum.FullConflict],
                    value: ConflictTypeEnum.FullConflict,
                    type: "conflict-type"
                }
            ],
            formatDateFromDate,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            visitDetails: {
                TypeId: { required },
                ReasonId: { required }
            }
        };
    },

    watch: {
        selectedConflictTypes: {
            handler() {
                this.visitDetails.EmpId = null;
                this.getEmployeeList();
            },
            deep: true
        }
    },

    async created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        Object.assign(this.visitDetails, {});
        this.visitDetails.EmpId = null;
        this.visitDetails.TypeId = null;
        this.visitDetails.ReasonId = null;
        try {
            this.isLoading = true;
            await this.getEmployeeList();
            this.trackingReason = await this._taskService.getTrackingReasons({
                AgencyId: this.visitDetails.AgencyId,
                Application: this.visitDetails.Application,
                CategoryId: 1
            });
            this.trackingTypes = await this._taskService.getTrackingTypes({
                AgencyId: this.visitDetails.AgencyId,
                Application: this.visitDetails.Application,
                CategoryId: 1
            });
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: `Error Occurred`,
                message: `Some Error Occurred, Please contact Axxess.`
            });
            this.cancel();
        } finally {
            this.isLoading = false;
        }
    },

    methods: {
        async getEmployeeList() {
            try {
                this.isEmployeeListLoading = true;
                this.employeeList = await this._taskService.homecareEmployeePreference({
                    PatientId: this.visitDetails.PatientId,
                    AgencyId: this.visitDetails.AgencyId,
                    LocationId: this.visitDetails.LocationId,
                    StartDate: this.visitDetails.StartDate,
                    EndDate: this.visitDetails.EndDate,
                    SchedulerConflictTypes: this.selectedConflictTypes,
                    Application: ApplicationEnum.HomeCare
                });
                this.employeeList = this.employeeList.map((emp) => {
                    if (emp.SchedulerConflictType == ConflictType.enum.NoConflict) {
                        emp.Icon = `\uf058`;
                    } else if (emp.SchedulerConflictType == ConflictType.enum.PartialConflict) {
                        emp.Icon = `\uf06a`;
                    } else if (emp.SchedulerConflictType == ConflictType.enum.FullConflict) {
                        emp.Icon = `\uf057`;
                    } else {
                        emp.Icon = "";
                    }
                    return emp;
                });
            } catch (e) {
                throw e;
            } finally {
                this.isEmployeeListLoading = false;
            }
        },

        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : "";
        },

        selectedType() {
            if (!!this.visitDetails.TypeId) {
                this.cpyTrackingReason = this.trackingReason.filter((reasonType) => {
                    return reasonType.TypeId == (this.visitDetails.TypeId as any);
                });
            } else {
                this.visitDetails.ReasonId = null;
                this.cpyTrackingReason = [];
            }
        },

        selectedItemsUpdated(value: any) {
            if (this.selectedConflictTypes.includes(value)) {
                const index = this.selectedConflictTypes.indexOf(value);
                this.selectedConflictTypes.splice(index, 1);
                return;
            }
            this.selectedConflictTypes.push(value);
        },

        async reassignVisit() {
            const valid = await this.v$.$validate();
            if (!valid) {
                return;
            }
            let reassignDetails = Object.assign({}, this.visitDetails);
            this.reassign(reassignDetails);
        }
    }
});
