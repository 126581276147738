export class RecurrenceModel {
    public type: any = "0";
    public endDate: string;
    public daysOfWeek: string = "";
    public repeatBy: number;
    public repeatNumber: number;
    public flexibleDates: string[] = [];

    public constructor(data?: RecurrenceModel) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
