import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import { IAddPharmacy, IGetPharmacies } from "../resources-vue/vue-interfaces/i-pharmacy";

@autoinject()
export class PharmacyService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/pharmacy";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getPharmacies(queryParam: IGetPharmacies) {
        return await this._httpClient.build(`${this._baseUrl}/get-pharmacies`).useQueryString(queryParam).fetch();
    }

    public async addPharmacy(payload: IAddPharmacy) {
        return await this._httpClient.build(`${this._baseUrl}/add-pharmacy`).useMethod("POST").useBody(payload).fetch();
    }
}
