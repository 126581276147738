import { customAttribute, inject } from "aurelia-framework";
import { Key } from "ts-keycode-enum";

import { NumberOnlyEnum } from "../../../enums/enums";

@customAttribute("number-only")
@inject(Element)
export class NumberOnly {
    private _element: JQuery;
    public value: NumberOnlyEnum = NumberOnlyEnum.Integer;

    public constructor(el: Element) {
        this._element = $(el) as JQuery;
    }

    public attached(): void {
        this._element.on("keydown", (evt: JQueryEventObject) => {
            if (this.value === NumberOnlyEnum.Decimal) {
                return this.checkIfEnteredKeyIsDecimal(evt);
            } else {
                return this.checkIfEnteredKeyIsNumber(evt);
            }
        });
    }

    public checkIfEnteredKeyIsNumber(evt: JQueryEventObject) {
        let charCode: number = evt.which ? evt.which : evt.keyCode;
        let cutCopyPaste = evt.ctrlKey && (evt.keyCode == Key.C || evt.keyCode == Key.V || evt.keyCode == Key.X);
        if (cutCopyPaste) {
            return true;
        }
        let isKeyPad = evt.keyCode >= Key.Numpad0 && evt.keyCode <= Key.Numpad9;
        if ((charCode > Key.Nine && !isKeyPad) || evt.shiftKey || evt.keyCode == Key.Space) {
            return false;
        }
        return true;
    }

    public checkIfEnteredKeyIsDecimal(evt: JQueryEventObject) {
        let checkKey = this.checkIfEnteredKeyIsNumber(evt);
        let isDecimal = evt.keyCode == Key.Period || evt.keyCode == Key.DecimalPoint;
        if (!checkKey && !isDecimal) {
            return false;
        }
        let str = this._element.val()?.toString();
        let hasTwoDecimals = isDecimal && str?.includes(".");
        let startsWithDecimal = isDecimal && str?.length === 0;
        if (hasTwoDecimals || startsWithDecimal) {
            return false;
        }
        return true;
    }
}
