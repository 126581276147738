import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { helperTestingAgency } from "../../../../common/vue-helpers/testing-agency-check";
import { IdentityService } from "../../../../services/identity-service";

import TabsAnimatedHeader from "../../../../resources-vue/vue-custom-elements/TabsAnimatedHeader/TabsAnimatedHeader.vue";
import MessagesPreview from "../../../../resources-vue/vue-custom-elements/MessagingService/MessagesPreview/MessagesPreview.vue";
import GetLiveLoginToken from "../../../../resources-vue/vue-dialogs/GetLiveLoginToken/GetLiveLoginToken.vue";

type IRoute = {
    path: string;
    name: string;
    meta: {
        menuName: string;
        navItem: string;
    };
};
type ItemRef = {
    [key: string]: Element;
};

export default defineComponent({
    components: {
        TabsAnimatedHeader,
        MessagesPreview
    },

    props: {
        router: {},
        showIcons: {
            type: Boolean,
            default: false
        },
        showPatients: {
            type: Boolean,
            default: false
        },
        showEmployees: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            itemRefs: {} as ItemRef,
            showEmployeeSearch: false,
            showPatientSearch: false,
            _permissionManager: null,
            _identityService: null,
            isAxxessPlannerHeader: false,
            showAxxessChat: false,
            dialogRef: null,
            liveLoginData: null,
            isTestAgency: false
        };
    },

    computed: {
        getActiveItem() {
            return this.itemRefs[this.$route.meta.navItem as string] as HTMLElement;
        }
    },

    async created() {
        this._permissionManager = Container.instance.get(PermissionManager);
        this._identityService = Container.instance.get(IdentityService);
        this.isTestAgency = await helperTestingAgency.checkHasTestingAgency();
    },

    async mounted() {
        const employeeInfoViewAcess =
            this._permissionManager.getPermmission(
                ParentPermissionEnum.employeeInformation,
                PermissionActionEnum.canView
            ) || [];
        const hasTestAgency = await helperTestingAgency.checkHasTestingAgency();
        this.showEmployeeSearch = hasTestAgency && employeeInfoViewAcess?.length;
        this.showPatientSearch = hasTestAgency;
        this.showAxxessChat = hasTestAgency;

        this.liveLoginData = await this._identityService.getLiveLoginToken();
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    methods: {
        addItemRefs(el: Element, route: IRoute) {
            this.itemRefs[route.meta.navItem] = el;
        },

        isActivePath(path: string) {
            if (path === "/central-messaging" && window.location.pathname.split("/").includes("central-messaging")) {
                return true;
            } else if (path === window.location.pathname) {
                return true;
            } else {
                return false;
            }
        },

        goTo(path: string) {
            this.$router.push(path);
        },

        toggleEmployeesMenus() {
            this.$emit("update:showEmployees", !this.showEmployees);
            if (this.showPatients) {
                this.$emit("update:showPatients", false);
            }
        },
        togglePatientsMenu(): void {
            this.$emit("update:showPatients", !this.showPatients);
            if (this.showEmployees) {
                this.$emit("update:showEmployees", false);
            }
        },

        async displayLiveLoginToken() {
            let response = this.liveLoginData;
            this.dialogRef = this.$dialog.open(GetLiveLoginToken, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    response
                }
            });
        }
    }
});
