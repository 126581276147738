import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum RelationshipToInsuredEnum {
    Spouse = 1 as any,
    Self = 2 as any,
    Child = 3 as any,
    Employee = 4 as any,
    Unknown = 5 as any,
    OrganDonor = 6 as any,
    CadaverDonor = 7 as any,
    LifePartner = 8 as any,
    Other = 9 as any
}
class RelationshipToInsuredClass extends EnumWithDescription<RelationshipToInsuredEnum> {
    public readonly enum = RelationshipToInsuredEnum;
    public readonly enumName: string = "RelationshipToInsuredEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<RelationshipToInsuredEnum>> = new Map([
        [
            "Spouse",
            {
                value: RelationshipToInsuredEnum.Spouse,
                name: "01-Spouse"
            }
        ],
        [
            "Self",
            {
                value: RelationshipToInsuredEnum.Self,
                name: "18-Self"
            }
        ],
        [
            "Child",
            {
                value: RelationshipToInsuredEnum.Child,
                name: "19-Child"
            }
        ],
        [
            "Employee",
            {
                value: RelationshipToInsuredEnum.Employee,
                name: "20-Employee"
            }
        ],
        [
            "Unknown",
            {
                value: RelationshipToInsuredEnum.Unknown,
                name: "21-Unknown"
            }
        ],
        [
            "OrganDonor",
            {
                value: RelationshipToInsuredEnum.OrganDonor,
                name: "39-Organ donor"
            }
        ],
        [
            "CadaverDonor",
            {
                value: RelationshipToInsuredEnum.CadaverDonor,
                name: "40-Cadaver donor"
            }
        ],
        [
            "LifePartner",
            {
                value: RelationshipToInsuredEnum.LifePartner,
                name: "53-Life partner"
            }
        ],
        [
            "Other",
            {
                value: RelationshipToInsuredEnum.Other,
                name: "G8-Other"
            }
        ]
    ]);
}

export const RelationshipToInsured = new RelationshipToInsuredClass();
