import "./full-calendar.scss";

import { Calendar, type CalendarOptions } from "@fullcalendar/core";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { bindable, bindingMode, observable, autoinject } from "aurelia-framework";

import nameOf from "../../common/name-of";

@autoinject()
export class FullCalendar {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameOf<FullCalendar>("calendarOptionsChanged") })
    public calendarOptions: CalendarOptions;
    @bindable public editable: boolean = false;
    public calendarElement: HTMLElement;
    public calendar: Calendar;

    public async attached() {
        this.initCalendar();
    }

    public initCalendar() {
        if (!this.calendarElement) {
            console.warn("The calendar custom element must contain one child with class calendar-wrapper.");
            return;
        }

        this.destroyCalendar();

        let defaultOptions: CalendarOptions = {
            headerToolbar: false,
            plugins: [resourceTimelinePlugin, interactionPlugin],
            schedulerLicenseKey: "0329665787-fcs-1602782908",
            initialView: "resourceTimeline",
            editable: this.editable,
            eventDurationEditable: false,
            eventResize: (event: any) => {
                event.revert();
            },
            slotDuration: {
                days: 1
            },
            dayMaxEvents: 2,
            dayMaxEventRows: 2,
            // views: {
            //     timeGrid: {
            //         dayMaxEventRows: 5,
            //         dayMaxEvents: 5
            //     }
            // },
            resourceAreaWidth: 200,
            slotLabelFormat: [{ weekday: "short", month: "short", day: "numeric" }]
        };

        let options: CalendarOptions = {
            ...defaultOptions,
            ...this.calendarOptions
        };

        this.calendar = new Calendar(this.calendarElement, options);
        this.calendar.render();
    }

    public calendarOptionsChanged() {
        this.initCalendar();
    }

    public destroyCalendar() {
        this.calendar?.destroy();
    }
}
