import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapState } from "pinia";

import { formatName } from "../../../../../common/format-name";
import { ApplicationEnum, TolerablePainLevels } from "../../../../../enums/enums";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { IUserResult } from "../../../../../resources-vue/vue-interfaces/i-user";
import { PhysiciansService } from "../../../../../services/physician-service";
import { TeamsService } from "../../../../../services/teams.service";
import { UserService } from "../../../../../services/user-service";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";
import DiagnosisTable from "../DiagnosisTable/DiagnosisTable.vue";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import Diagnosis from "../../../../../resources-vue/vue-custom-elements/Diagnosis/Diagnosis.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput,
        DatePicker,
        Diagnosis,
        DiagnosisTable
    },

    props: {
        providerId: {
            type: String,
            default: ""
        },
        referringPatientDiagnosis: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            _physicianService: null,
            _teamsService: null,
            _userService: null,
            attendingPhysician: null,
            teams: [],
            showPreAdmissionEvaluation: true,
            selectedUser: null,
            TolerablePainLevels: TolerablePainLevels,
            referralDiagnosis: null,
            enumToMap,
            formatDateInput
        };
    },
    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
        this._teamsService = Container.instance.get(TeamsService);
        this._userService = Container.instance.get(UserService);
    },
    async mounted() {
        this.getTeams();
        // Uncomment this condition once api is fixed
        // if (!!this.referral.preadmissionEvaludationClinicianId) {
        //     let userInfo = await this._userService.getUser(this.referral.preadmissionEvaludationClinicianId);
        //     if (!!userInfo) {
        //         this.selectedUser = {
        //             name: formatName(userInfo.firstName, userInfo.lastName),
        //             value: userInfo.userId
        //         };
        //     }
        // }
        if (this.referral.attendingPhysicianId && this.referral.attendingPhysicianName) {
            this.attendingPhysician = {
                name: this.referral.attendingPhysicianName,
                value: this.referral.attendingPhysicianId
            };
        }

        if (this.referringPatientDiagnosis) {
            this.referralDiagnosisChanged({
                newDiagnosis: {
                    description: this.referringPatientDiagnosis.ShortDescription,
                    code: this.referringPatientDiagnosis.DiagnosisCode,
                    billable: true
                }
            });
        }

        if (this.referral.referringDiagnosis) {
            this.referralDiagnosis = this.referral.referringDiagnosis;
        }
    },
    computed: {
        isApplicationPalliative(): boolean {
            return this.referral.application == ApplicationEnum.AxxessPalliative;
        },
        ...mapState(useCreateReferralHosPal, ["referral"])
    },
    methods: {
        async getPhysicianList(filter: string, limit: number) {
            let result;
            try {
                let data = await this._physicianService.getPhysiciansByName({
                    name: filter,
                    page: 1,
                    pageLength: limit,
                    providerIds: [this.providerId],
                    application: this.referral.application,
                    accountId: this.referral.accountId
                });
                result = data.items?.map((item: any) => {
                    let splitName = item.name.split(" ");
                    return {
                        name: formatName(splitName[0], splitName[1]) + (item.npi ? ` - NPI: ${item.npi}` : ""),
                        value: item.id
                    };
                });
            } catch (e) {
                console.log(e);
            }
            return result;
        },

        attendingPhysicianChanged(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions): void {
            if (newValue !== oldValue && !!this.referral) {
                this.referral.attendingPhysicianId = newValue?.value;
                let npiIndex = newValue?.name?.indexOf(" - NPI");
                if (npiIndex > 0 && newValue?.name) {
                    let name = newValue.name?.slice(0, npiIndex + 1);
                    this.referral.attendingPhysicianName = name;
                } else {
                    this.referral.attendingPhysicianName = newValue?.name;
                }
            }
        },

        async getTeams() {
            try {
                if (!!this.referral) {
                    const payload = {
                        providerIds: [this.providerId],
                        locationIds: [this.referral?.locationId],
                        agencyId: this.referral.accountId,
                        accountId: this.referral.accountId,
                        application: this.referral.application
                    };
                    this.teams = await this._teamsService.getTeamsLean(payload);
                }
            } catch (e) {
                console.error(e);
            }
        },

        async usersFetch(filter: string) {
            let result;
            try {
                let users = await this._userService.getUsersLean({
                    page: 1,
                    pageLength: 25,
                    term: filter,
                    agencyId: this.referral.accountId,
                    application: this.referral.application,
                    branchIds: [this.referral.locationId],
                    teamIds: [this.referral.careTeamId]
                });
                if (!!users) {
                    result = users.items.map((user: any) => ({
                        name: formatName(user.firstName, user.lastName),
                        value: user.id
                    }));
                }
            } catch (e) {
                console.error(e);
            }
            return result;
        },

        selectedUserChanged(user: IUserResult): void {
            if (user) {
                this.referral.preadmissionEvaludationClinicianId = user.value as string;
            }
        },

        referralDiagnosisChanged(diag: any) {
            this.referralDiagnosis = diag;
            this.referral.referringDiagnosis = diag?.newDiagnosis;
        },
        updateDiagnosis(value: any) {
            this.referral.diagnoses = value;
        },
        handleDateChange(date: any, name: string) {
            if (name === "preadmissionEvaluationDate") {
                this.referral.preadmissionEvaluationDate = date;
            }
        }
    }
});
