import { defineComponent } from "vue";
import moment from "moment";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { GroupedAgencies } from "../../../../resources-vue/vue-models/agency";
import config from "../../../../common/config";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { NavigationService } from "../../../../services/navigation.service";
import { AnalyticsCategory, AnalyticsService } from "../../../../services/analytics.service";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { IAgency } from "../../../../resources-vue/vue-interfaces/i-agency";
import { INavigateRequestParams } from "../../../../resources-vue/vue-interfaces/i-navigation";
import { ILogOptions } from "../../../../resources-vue/vue-interfaces/i-analytics";
import { useLoadingIndicator } from "../../../Common/LoadingIndicator/store/useLoadingIndicator";
import { getProductColorClass } from "../../../../common/vue-helpers/modifiers/application-modifiers";

export enum AgencyEvents {
    Loaded = "app:agency:loaded"
}

export default defineComponent({
    props: {
        groupedAgencies: { default: null },
        isLoading: { type: Boolean, default: true },
        hasError: { type: Boolean, default: false }
    },

    emits: ["loadAgencies"],

    data() {
        return {
            _navService: null,
            _analyticsService: null,
            _logCategory: "Agency-List",
            _permissionManager: null,
            isBeforeHosMarketingLastDate: moment().isBefore(moment(config.hospiceMarketingLastDate)),
            isClinician: false,
            getProductColorClass
        };
    },

    created() {
        this._navService = Container.instance.get(NavigationService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },

    mounted() {
        this.isClinician = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canView
        );
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        reloadAgencies() {
            this.$emit("loadAgencies");
        },

        showAgency(list: GroupedAgencies): void {
            list.show = !list.show;
            if (list.show) {
                this._analyticsService.logEvent({
                    category: "Agency-List",
                    action: "Accordion-Expand"
                });
            } else {
                this._analyticsService.logEvent({
                    category: "Agency-List",
                    action: "Accordion-Collapse"
                });
            }
        },

        async enterAgency(agency: IAgency): Promise<void> {
            try {
                this.showLoading(true);
                let navParams: INavigateRequestParams = {
                    applicationId: agency.Application,
                    agencyId: agency.AgencyId,
                    userId: agency.UserId,
                    isClinician: this.isClinician,
                    redirectUrl:
                        ApplicationEnum.AxxessBI == agency.Application && agency.Url ? agency.Url : agency.redirectUrl
                };
                let response: any = await this._navService.navigateToAgency(navParams);
                let analyticsOptions: ILogOptions = {
                    category: this._logCategory,
                    action: `Enter-${agency.applicationName}`
                };
                this._navService.redirect(response, analyticsOptions);
            } catch (e) {
                this.showLoading(false);
            }
        },

        openHospiceMarketingLink() {
            this._analyticsService.logEvent({
                category: AnalyticsCategory.HospiceMarketing,
                action: "Opened-Link-From-Nav"
            });
            window.open(config.hospiceMarketingUrl);
        }
    }
});
