import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum AgencyRolesEnum {
    None = 0 as any,
    Administrator = 1 as any,
    DirectorOfNursing = 2 as any,
    CaseManager = 3 as any,
    Nurse = 4 as any,
    Clerk = 5 as any,
    PhysicalTherapist = 6 as any,
    OccupationalTherapist = 7 as any,
    SpeechTherapist = 8 as any,
    MedicalSocialWorker = 9 as any,
    HomeHealthAide = 10 as any,
    Scheduler = 11 as any,
    Biller = 12 as any,
    QualityAssurance = 13 as any,
    Physician = 14 as any,
    OfficeManager = 15 as any,
    CommunityLiaisonOfficer = 16 as any,
    ExternalReferralSource = 17 as any,
    DriverTransporter = 18 as any,
    OfficeStaff = 19 as any,
    Auditor = 20 as any,
    ClinicalManager = 21 as any
}
class AgencyRolesClass extends EnumWithDescription<AgencyRolesEnum> {
    public readonly enum = AgencyRolesEnum;
    public readonly enumName: string = "AgencyRolesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<AgencyRolesEnum>> = new Map([
        [
            "None",
            {
                value: AgencyRolesEnum.None,
                name: "None"
            }
        ],
        [
            "Administrator",
            {
                value: AgencyRolesEnum.Administrator,
                name: "Administrator"
            }
        ],
        [
            "DirectorOfNursing",
            {
                value: AgencyRolesEnum.DirectorOfNursing,
                name: "DirectorOfNursing"
            }
        ],
        [
            "CaseManager",
            {
                value: AgencyRolesEnum.CaseManager,
                name: "CaseManager"
            }
        ],
        [
            "Nurse",
            {
                value: AgencyRolesEnum.Nurse,
                name: "Nurse"
            }
        ],
        [
            "Clerk",
            {
                value: AgencyRolesEnum.Clerk,
                name: "Clerk"
            }
        ],
        [
            "PhysicalTherapist",
            {
                value: AgencyRolesEnum.PhysicalTherapist,
                name: "Physical Therapist"
            }
        ],
        [
            "OccupationalTherapist",
            {
                value: AgencyRolesEnum.OccupationalTherapist,
                name: "Occupational Therapist"
            }
        ],
        [
            "SpeechTherapist",
            {
                value: AgencyRolesEnum.SpeechTherapist,
                name: "Speech Therapist"
            }
        ],
        [
            "MedicalSocialWorker",
            {
                value: AgencyRolesEnum.MedicalSocialWorker,
                name: "Medical Social Worker"
            }
        ],
        [
            "HomeHealthAide",
            {
                value: AgencyRolesEnum.HomeHealthAide,
                name: "Home Health Aide"
            }
        ],

        [
            "Scheduler",
            {
                value: AgencyRolesEnum.Scheduler,
                name: "Scheduler"
            }
        ],
        [
            "Biller",
            {
                value: AgencyRolesEnum.Biller,
                name: "Biller"
            }
        ],
        [
            "QualityAssurance",
            {
                value: AgencyRolesEnum.QualityAssurance,
                name: "QualityAssurance"
            }
        ],
        [
            "Physician",
            {
                value: AgencyRolesEnum.Physician,
                name: "Physician"
            }
        ],
        [
            "OfficeManager",
            {
                value: AgencyRolesEnum.OfficeManager,
                name: "OfficeManager"
            }
        ],
        [
            "CommunityLiaisonOfficer",
            {
                value: AgencyRolesEnum.CommunityLiaisonOfficer,
                name: "Community Liaison Officer"
            }
        ],
        [
            "ExternalReferralSource",
            {
                value: AgencyRolesEnum.ExternalReferralSource,
                name: "External Referral Source"
            }
        ],
        [
            "DriverTransporter",
            {
                value: AgencyRolesEnum.DriverTransporter,
                name: "Driver/Transporter"
            }
        ],
        [
            "OfficeStaff",
            {
                value: AgencyRolesEnum.OfficeStaff,
                name: "OfficeStaff"
            }
        ],
        [
            "Auditor",
            {
                value: AgencyRolesEnum.Auditor,
                name: "Auditor"
            }
        ],
        [
            "ClinicalManager",
            {
                value: AgencyRolesEnum.ClinicalManager,
                name: "Clinical Manager"
            }
        ]
    ]);
}

export const AgencyRoles = new AgencyRolesClass();
