import { defineComponent } from "vue";
import { mapState } from "pinia";

import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions",
            accountId: "accountId"
        })
    },
    data() {
        return {
            HCFAAdditionalBillingInfo: {
                employment: null,
                autoAccident: null,
                otherAccident: null
            },
            formatDateInput
        };
    },

    methods: {
        onDateChange(date: string, name: string) {
            if (name == "hospitalization-start-date") {
                this.referral.Insurance.AdditionalBillingInfo.HCFAAdditionalBillingInfo[
                    "<HospitalizationBegin>k__BackingField"
                ] = date;
            }
            if (name == "hospitalization-end-date") {
                this.referral.Insurance.AdditionalBillingInfo.HCFAAdditionalBillingInfo[
                    "<HospitalizationEnd>k__BackingField"
                ] = date;
            }
            if (name == "patient-unable-to") {
                this.referral.Insurance.AdditionalBillingInfo.HCFAAdditionalBillingInfo[
                    "<UnableToWorkTo>k__BackingField"
                ] = date;
            }
            if (name == "patient-unable-from") {
                this.referral.Insurance.AdditionalBillingInfo.HCFAAdditionalBillingInfo[
                    "<UnableToWorkFrom>k__BackingField"
                ] = date;
            }
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.onDateChange(value, name);
        }
    }
});
