import { createApp } from "vue";
import { createPinia } from "pinia";
import { tooltip } from "./directives/tooltip-directive";
import { inputDirectives } from "./directives/input-directives";
import { customPopover } from "./directives/popover/popover-directive";
import { numberOnlyDirectives } from "../../resources-vue/vue-directives/number-only-vue";
import VueRouter from "./vue-router";

import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import DynamicDialog from "primevue/dynamicdialog";

import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";

const directivesAdded: string[] = [];

const directiveExists = (name: string) => {
    return directivesAdded.includes(name);
};

const addDirective = (
    vueInstance: any = null,
    name: string,
    lifeCycles: { [key: string]: (element: HTMLElement, bindings: any) => void }
) => {
    if (!vueInstance) {
        return;
    }
    if (directiveExists(name)) {
        console.warn(`Directive with name - ${name} already exists`);
        return;
    }
    vueInstance.directive(name, lifeCycles);
};

function createDirectives(Vue: any) {
    addDirective(Vue, "tooltip", tooltip);
    addDirective(Vue, "custom-popover", customPopover);
    Object.entries(inputDirectives).forEach(([name, methods]) => {
        addDirective(Vue, name, methods);
    });
    Object.entries(numberOnlyDirectives).forEach(([name, methods]) => {
        addDirective(Vue, name, methods);
    });
}

function createAppVue() {
    return (app: any, props: any = {}) => {
        const vueApp = createApp(app, props)
            .use(createDirectives)
            .use(VueRouter)
            .use(createPinia())
            .use(PrimeVue, { ripple: true })
            .use(DialogService)
            .component("DynamicDialog", DynamicDialog)
            .component("Dialog", Dialog);

        vueApp.config.unwrapInjectedRef = true;
        return vueApp;
    };
}

export const createVueApp = createAppVue();
