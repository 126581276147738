import "./new-typeahead.scss";

import { bindable, bindingMode, customElement, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { Key } from "ts-keycode-enum";

import nameOf from "../../../common/name-of";
import { HttpStatusCodeEnum } from "../../../enums/enums";
import type { IAddNewOptionEvent, IFetchOptionsEvent, ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

@customElement("new-typeahead")
export class NewTypeahead {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "Start Typing...";
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameOf<NewTypeahead>("dataChanged") })
    public data: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameOf<NewTypeahead>("selectedOptionChanged") })
    public selectedOption: ITypeaheadOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public searchCharLimit: number = 0;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public errorMessage: string = "Error Fetching Results.";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public noResultsMessage: string = "No Results Found.";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideIcon: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public loadingMessage: string = "Loading...";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public customAddMessage: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public entity: string = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isCustomAddEnabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    // INFO: initialize to false, when selectedOption is initialized on load
    public clearOnBlur: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isAuthorized: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public inputClasses: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public fetchOptions: (event: IFetchOptionsEvent) => Promise<ITypeaheadOptions[]>;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public addNewOption: (event: IAddNewOptionEvent) => ITypeaheadOptions;
    @observable({
        changeHandler: nameOf<NewTypeahead>("searchKeyChanged")
    })
    public searchKey: string = "";
    public isLoading: boolean = false;
    public highlightedOption: ITypeaheadOptions;
    public showMenu: boolean = false;
    public noMatchesFound: boolean = false;
    public isNewHighlightedOption: boolean = false;
    public searchResults: ITypeaheadOptions[] = [];
    public dropdownRef: Element;
    public dropdownMenuRef: HTMLElement;
    public optionRefs: Element[] = [];
    public customAddElementRef: HTMLElement;
    public searchInputRef: HTMLElement;
    public isError: boolean = false;
    public characterLimitMessage: string = "";
    public notAuthorizedText: string = "Contact your administrator to request permission to view this data.";

    public dataChanged() {
        if (this.data.length > 0 && !this.fetchOptions) {
            this.searchResults = this.filterFromData();
            this.selectFirstOption();
        }
    }

    public attached() {
        this.registerDocumentClick();
        if (this.validation) {
            let displayName = this.validation.displayName;
            let message = this.validation.message;
            ValidationRules.ensure((x: NewTypeahead) => x.searchKey)
                .displayName(displayName)
                .required()
                .when((typeahead: NewTypeahead) => typeahead.validation.required)
                .withMessage(message ? message : `${displayName} is required.`)
                .on(this);
        }
    }

    public registerDocumentClick() {
        document.addEventListener("click", (evt: MouseEvent) => {
            if (!this.showMenu) {
                return;
            }

            setTimeout(() => {
                if (!!this.dropdownRef && !this.dropdownRef.contains(evt.target as Element)) {
                    this.handleEscape();
                }
            }, 150);
        });
    }

    public handleInputKeydown(event: KeyboardEvent) {
        if (this.disabled) {
            return false;
        }
        setTimeout(() => {
            if (event.keyCode === Key.UpArrow) {
                this.handleUpArrowPress();
            } else if (event.keyCode === Key.DownArrow) {
                this.handleDownArrowPress();
            } else if (event.keyCode === Key.Enter) {
                this.handleEnterPress();
            } else if (event.keyCode === Key.Escape) {
                this.handleEscape();
            } else if (event.keyCode === Key.Tab) {
                this.handleEscape();
            }
        }, 150);
        // https://kabaehr.de/blog/Aurelia-keyboard-event-binding/
        return true;
    }

    private handleUpArrowPress() {
        let selectedIndex = 0;
        if (!!this.highlightedOption) {
            selectedIndex = this.searchResults.findIndex((item) => {
                return item === this.highlightedOption;
            });
            if (selectedIndex <= 0) {
                if (this.isCustomAddEnabled && this.noMatchesFound) {
                    this.isNewHighlightedOption = true;
                    selectedIndex = null;
                } else {
                    selectedIndex = this.searchResults.length - 1;
                }
            } else {
                selectedIndex--;
            }
        } else {
            this.isNewHighlightedOption = false;
            selectedIndex = this.searchResults.length - 1;
        }
        this.handleKeyPressNavigation(selectedIndex);
    }

    private handleDownArrowPress() {
        let selectedIndex = 0;
        if (!!this.highlightedOption) {
            selectedIndex = this.searchResults.findIndex((item) => {
                return item === this.highlightedOption;
            });
            if (selectedIndex >= this.searchResults.length - 1) {
                if (this.isCustomAddEnabled && this.noMatchesFound) {
                    this.isNewHighlightedOption = true;
                    selectedIndex = null;
                } else {
                    selectedIndex = 0;
                }
            } else {
                selectedIndex++;
            }
        } else {
            this.isNewHighlightedOption = false;
        }
        this.handleKeyPressNavigation(selectedIndex);
    }

    private handleKeyPressNavigation(selectedIndex: number) {
        if (Number.isInteger(selectedIndex) && selectedIndex >= 0) {
            this.highlightedOption = this.searchResults[selectedIndex];
            let selectedOptionEl: HTMLElement = this.optionRefs[selectedIndex] as HTMLElement;
            if (selectedOptionEl && !this.isScrolledIntoView(selectedOptionEl, selectedIndex)) {
                this.dropdownMenuRef.scrollTop = selectedOptionEl.offsetTop;
            }
        } else {
            this.highlightedOption = null;
            this.dropdownMenuRef.scrollTop = this.customAddElementRef.offsetTop;
        }
    }

    private handleEnterPress() {
        if (!!this.highlightedOption) {
            this.handleMenuSelection(this.highlightedOption);
        } else if (this.isCustomAddEnabled && this.searchKey?.trim().length > 0) {
            this.isNewHighlightedOption = false;
            this.addCustomOption();
        }
    }

    private handleMenuSelection(option: ITypeaheadOptions) {
        this.selectedOption = Object.assign({}, option);
        this.searchKey = this.selectedOption.name;
        this.showMenu = false;
        this.searchInputRef.blur();
    }

    private handleEscape() {
        if (this.showMenu) {
            if (this.clearOnBlur || this.searchKey !== this.selectedOption?.name) {
                this.searchKey = "";
            }
            this.showMenu = false;
            this.searchInputRef.blur();
        }
    }

    public isScrolledIntoView(el: HTMLElement, selectedIndex: number) {
        let elemHeight = el.clientHeight;
        let dropdownHeight = this.dropdownMenuRef.clientHeight;
        let dropdownScrollTop = this.dropdownMenuRef.scrollTop;
        let hiddenOptions = Math.round(dropdownScrollTop / elemHeight);
        let visibleOptions = Math.round(dropdownHeight / elemHeight);
        let isVisible = true;
        if (selectedIndex > hiddenOptions && selectedIndex < hiddenOptions + visibleOptions) {
            isVisible = true;
        } else {
            isVisible = false;
        }
        return isVisible;
    }

    public async searchKeyChanged(newKey: string, oldKey: string) {
        newKey = newKey?.trim();
        oldKey = oldKey?.trim();
        if (newKey !== oldKey) {
            if (!!newKey && newKey.length >= this.searchCharLimit && newKey !== this.selectedOption?.name) {
                this.characterLimitMessage = "";
                if (!!this.fetchOptions) {
                    try {
                        // Fetch Options through API
                        this.isError = false;
                        this.isAuthorized = true;
                        this.isNewHighlightedOption = false;
                        this.highlightedOption = null;
                        this.showMenu = true;
                        this.isLoading = true;
                        this.searchResults = await this.fetchOptions({
                            term: newKey // to prevent extra spaces in the API request
                        });
                        this.selectFirstOption();
                        if (this.isCustomAddEnabled) {
                            let matchesFound = this.searchResults.filter(
                                (op) => op.name.toLowerCase() === newKey.toLowerCase()
                            );
                            this.noMatchesFound = newKey.length !== 0 && matchesFound.length === 0;
                        }
                    } catch (e) {
                        console.error(e);
                        this.isError = true;
                        if (e.status === HttpStatusCodeEnum.Forbidden) {
                            this.isAuthorized = false;
                        }
                    } finally {
                        this.isLoading = false;
                    }
                } else if (this.data.length > 0) {
                    // Fetch Options through data binding
                    this.searchResults = this.filterFromData();
                    this.selectFirstOption();
                } else {
                    // no options or no search key
                    this.showMenu = false;
                }
            } else if (!newKey) {
                this.refreshDataList();
            } else {
                this.refreshDataList();
                this.showMenu = true;
            }
        }
        if (newKey?.length == 0) {
            this.selectedOption = null;
            this.refreshDataList();
        }
    }

    private refreshDataList() {
        if (this.data.length > 0) {
            this.searchResults = this.filterFromData();
            this.selectFirstOption();
        } else {
            this.searchResults = [];
        }
        this.characterLimitMessage = `Please enter at least ${this.searchCharLimit} characters.`;
    }

    private filterFromData() {
        if (this.data.length > 0 && !!this.searchKey) {
            return this.data.filter((item) => {
                return item.name.toLocaleLowerCase().includes(this.searchKey?.trim().toLocaleLowerCase());
            });
        }
        return Array.from(this.data);
    }

    public handleInputFocus() {
        if (!this.fetchOptions && this.data.length > 0) {
            this.showMenu = true;
        }
    }

    public selectedOptionChanged(newValue: ITypeaheadOptions) {
        if (!newValue) {
            this.searchKey = null;
            return;
        }
        if (newValue && newValue.name != this.searchKey) {
            this.searchKey = newValue.name;
            this.showMenu = false;
        }
    }

    private selectFirstOption() {
        if (this.searchResults.length > 0) {
            this.highlightedOption = this.searchResults[0];
        } else {
            this.isNewHighlightedOption = true;
        }
    }

    public async addCustomOption() {
        if (this.addNewOption && !!this.searchKey) {
            this.showMenu = false;
            await this.addNewOption({
                term: this.searchKey
            });
        }
    }
}
