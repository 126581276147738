import "./get-zip.scss";

import { DialogController } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";

import { AnalyticsCategory, AnalyticsService } from "../../../services/analytics.service";
import { GeolocationService } from "../../../services/geolocation.service";

export enum GetZipEvents {
    Changed = "app:zip:changed",
    Canceled = "app:zip:canceled"
}

export interface IGetZipOptions {
    zip: string;
}

@autoinject
export class GetZip {
    private _ea: EventAggregator;
    private _analytics: AnalyticsService;
    private _geolocationService: GeolocationService;
    private _isChange: boolean = false;
    public controller: DialogController;
    public zipError: boolean = false;
    public options: IGetZipOptions = {
        zip: ""
    };
    public zip: string = "";

    public constructor(
        controller: DialogController,
        ea: EventAggregator,
        analyticsService: AnalyticsService,
        geolocationService: GeolocationService
    ) {
        this.controller = controller;
        this._ea = ea;
        this._analytics = analyticsService;
        this._geolocationService = geolocationService;
    }

    public activate(options: IGetZipOptions) {
        if (!!options) {
            Object.assign(this.options, options);
        }
        this.zip = this.options.zip;
        this._isChange = !!this.options?.zip;
    }

    public clearZip(): void {
        this.zip = "";
    }

    public async isValidZip() {
        let newValue: string = this.zip.toString().trim();
        if (newValue) {
            if (newValue.length === 5 && /^\d+$/.test(newValue)) {
                this.zipError = false;
                this._ea.publish(GetZipEvents.Changed, this.zip);
                await this._geolocationService.getCoordinatesByZip(this.zip);
                this._analytics.logEvent({
                    category: AnalyticsCategory.GetZip,
                    action: "Submitted"
                });
                this.controller.cancel();
            } else {
                this.zip = "";
                this.zipError = true;
            }
        }
    }

    public zipNotEntered(): void {
        this.zipError = false;
        this._analytics.logEvent({
            category: AnalyticsCategory.GetZip,
            action: "Canceled"
        });
        this._ea.publish(GetZipEvents.Canceled, this._isChange);
        this.controller.cancel(this._isChange);
    }
}
