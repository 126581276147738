import * as moment from "moment";

import { ApplicationEnum } from "../../enums/enums";
import type { IPhoneNumber } from "../vue-interfaces/i-phone-number";

export class Inquiry {
    public id: string = "";
    public date: string;
    public followUpDate: string;
    public firstName: string;
    public middleInitial: string;
    public lastName: string;
    public phoneNumbers: IPhoneNumber[];
    public contactFirstName: string;
    public contactMiddleInitial: string;
    public contactLastName: string;
    public contactPhoneNumbers: IPhoneNumber[];
    public locationId: string = null;
    public assignedUserId: string;
    public assignedUser: string;
    public createdBy: string;
    public createdOn: string;
    public comment: string;
    public lastUpdated: string;
    public contactRelationshipToPatient: string;
    public currentLocation: string;
    public servicesRequested: number[] = [];
    public application: ApplicationEnum;
    public accountId: string;
    public canDelete: boolean;
    public canEdit: boolean;
    public branchName?: string;

    public constructor(application: ApplicationEnum, data?: Inquiry, hideValidation: boolean = false) {
        this.phoneNumbers = [
            {
                number: "",
                extension: "",
                type: 1,
                country: "",
                isPrimary: true
            }
        ];
        this.contactPhoneNumbers = [
            {
                number: "",
                extension: "",
                type: 1,
                country: "",
                isPrimary: true
            }
        ];
        this.date = moment().format("MM/DD/YYYY");

        if (data) {
            Object.assign(this, data);
        }
        if (hideValidation) {
            return;
        }
    }

    public get isPalliative(): boolean {
        return this.application ? this.application === ApplicationEnum.AxxessPalliative : false;
    }
}
