import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum TolerablePainLevelsEnum {
    NoPain = 0 as any,
    VeryMild = 1 as any,
    Discomforting = 2 as any,
    Tolerable = 3 as any,
    Distressing = 4 as any,
    VeryDistressing = 5 as any,
    Intense = 6 as any,
    VeryIntense = 7 as any,
    Severe = 8 as any,
    VerySevere = 9 as any,
    WorstPainPossible = 10 as any
}
class TolerablePainLevelsClass extends EnumWithDescription<TolerablePainLevelsEnum> {
    public readonly enum = TolerablePainLevelsEnum;
    public readonly enumName: string = "TolerablePainLevelsEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<TolerablePainLevelsEnum>> = new Map([
        [
            "NoPain",
            {
                value: TolerablePainLevelsEnum.NoPain,
                name: "No Pain"
            }
        ],
        [
            "VeryMild",
            {
                value: TolerablePainLevelsEnum.VeryMild,
                name: "Very Mild"
            }
        ],
        [
            "Discomforting",
            {
                value: TolerablePainLevelsEnum.Discomforting,
                name: "Discomforting"
            }
        ],
        [
            "Tolerable",
            {
                value: TolerablePainLevelsEnum.Tolerable,
                name: "Tolerable"
            }
        ],
        [
            "Distressing",
            {
                value: TolerablePainLevelsEnum.Distressing,
                name: "Distressing"
            }
        ],
        [
            "VeryDistressing",
            {
                value: TolerablePainLevelsEnum.VeryDistressing,
                name: "Very Distressing"
            }
        ],
        [
            "Intense",
            {
                value: TolerablePainLevelsEnum.Intense,
                name: "Intense"
            }
        ],
        [
            "VeryIntense",
            {
                value: TolerablePainLevelsEnum.VeryIntense,
                name: "Very Intense"
            }
        ],
        [
            "Severe",
            {
                value: TolerablePainLevelsEnum.Severe,
                name: "Severe"
            }
        ],
        [
            "VerySevere",
            {
                value: TolerablePainLevelsEnum.VerySevere,
                name: "Very Severe"
            }
        ],
        [
            "WorstPainPossible",
            {
                value: TolerablePainLevelsEnum.WorstPainPossible,
                name: "Worst Pain Possible"
            }
        ]
    ]);
}

export const TolerablePainLevels = new TolerablePainLevelsClass();
