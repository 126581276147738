import { defineComponent, computed } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { AnalyticsService } from "../../../../services/analytics.service";
import { NavigationService } from "../../../../services/navigation.service";
import { TaskService } from "../../../../services/task.service";
import type { ILogOptions } from "../../../../resources-vue/vue-interfaces/i-analytics";
import type { INavigateRequestParams, INavigateResponse } from "../../../../resources-vue/vue-interfaces/i-navigation";
import type { ITask } from "../../../../resources-vue/vue-interfaces/i-task";
import type { Task } from "../../../../models/task.model";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { getApplicationId } from "../../../../common/vue-helpers/modifiers/application-modifiers";
import { formatDate } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { useLoadingIndicator } from "../../../Common/LoadingIndicator/store/useLoadingIndicator";

import CardTemplate from "../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";
import ProductPill from "../../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";
import MissedVisit from "../../../../resources-vue/vue-dialogs/MissedVisit/MissedVisit.vue";

export default defineComponent({
    components: {
        CardTemplate,
        ProductPill
    },

    data() {
        return {
            _navigate: null,
            _taskService: null,
            _analyticsService: null,
            _permissionManager: null,
            dialogRef: null,
            tasksList: [] as Task[],
            isAscending: true,
            isLoading: false,
            hasError: false,
            isCompleteMissedVisit: true,
            logCategory: "Past-Due",
            bulletSymbol: "&bull;",
            tooltip: `This section displays visits within the past 21 days that have not been completed and signed.`,
            userOs: "",
            formatDate,
            getApplicationId
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._navigate = Container.instance.get(NavigationService);
        this._analyticsService = Container.instance.get(AnalyticsService);
    },

    async mounted() {
        this.userOs = window.sessionStorage.getItem("userOs");
        await this.getPastDueTasks();

        this.isCompleteMissedVisit = this._permissionManager.checkPermissionByProduct(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canCompleteMissedVisit,
            ApplicationEnum.HomeCare
        );
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        async getPastDueTasks() {
            try {
                this.hasError = false;
                this.isLoading = true;
                this.tasksList = await this._taskService.getPastDue();
            } catch (e) {
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        async openMissedVisitDialog(task: Task) {
            this.dialogRef = this.$dialog.open(MissedVisit, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    task
                },
                onClose: (options) => {
                    if (options.data === "success") {
                        this.getPastDueTasks();
                    }
                }
            });
        },

        sortByScheduleDate(): void {
            this.isAscending = !this.isAscending;
            this.tasksList = this._taskService.sortTasks(this.tasksList, "Date", "date", !this.isAscending);
            this._analyticsService.logEvent({
                category: this.logCategory,
                action: `${this.isAscending ? "Ascending" : "Descending"}-Sort`
            });
        },

        async viewPatient(task: Task) {
            const isClinician = this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView,
                task.AgencyId
            );
            const analyticsOptions: ILogOptions = {
                category: this.logCategory,
                action: "View-Patient-Chart"
            };
            const navParams: INavigateRequestParams = {
                applicationId: task.Application,
                agencyId: task.AgencyId,
                userId: task.UserId,
                patientId: task.PatientId,
                isClinician
            };

            try {
                this.showLoading(true);
                const response: INavigateResponse = await this._navigate.navigateToPatient(navParams);
                this._navigate.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        },

        async startTask(task: ITask) {
            const isClinician = this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView,
                task.AgencyId
            );
            const analyticsOptions = {
                category: this.logCategory,
                action: "Open-Past-Due-Visit"
            };
            const navParams: INavigateRequestParams = {
                agencyId: task.AgencyId,
                userId: task.UserId,
                applicationId: task.Application,
                patientId: task.PatientId,
                taskId: task.Id,
                episodeId: task.EpisodeId,
                locationId: task.LocationId,
                status: task.PatientStatus ? Number(task.PatientStatus) : null,
                payerId: task.PayerId,
                isClinician
            };

            try {
                this.showLoading(true);
                let response: INavigateResponse = await this._navigate.navigateToTask(navParams);
                this._navigate.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        }
    }
});
