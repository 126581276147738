import {
    IApplicationAndAccountQueryParam,
    ICheckClientConflictRequest,
    ICheckClientConflictResponse,
    ICreateInterimReferralPayload,
    IDeleteDocumentQueryParam,
    IGetMunicipalityPayRates,
    IGetReferralQuery,
    IGetReferralResult,
    IReferralDocument,
    IUploadReferralDocumentResult,
    IGetInsuranceQuery,
    INonAdmitPatientQuery,
    IUploadReferralDocumentQuery
} from "../interfaces/i-get-referral";
import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";
import type {
    IDeleteInquiryQuery,
    IDeleteUpdateInquiryResult,
    IGetInquiryQuery,
    IGetInquiryResult,
    IIntakeCountRequest,
    IIntakeCountResponse,
    IGetInquiry
} from "../resources-vue/vue-interfaces/i-get-inquiry";
import {
    IGetMedicareIds,
    IGetMedicareIdsQuery,
    IGetPayorDeleteResult,
    IGetPayorSearchByNameResult
} from "../resources-vue/vue-interfaces/i-get-payor";
import {
    ICreatePatientPayerResult,
    IGetCurrentPatientPayerQuery,
    IGetPatientPayerQuery,
    IGetPatientPayerResult,
    IGetPayorByNameQuery,
    IUpdateReferralPayorResponse,
    IGetPayorByBranchID
} from "../resources-vue/vue-interfaces/i-patient-payor";
import type { ICreateResponse } from "../resources-vue/vue-interfaces/i-response";
import type { Inquiry } from "../models/inquiry";
import type { IGetNonAdmitReasons } from "../resources-vue/vue-interfaces/i-non-admit-reason";
import type { INonAdmitReasonQuery } from "../interfaces/i-branch";
import { PatientPayor } from "../models/patient-payor";
import type { IDeleteRugLevelQuery, IGetRugLevelsQuery, IRugLevel } from "../resources-vue/vue-interfaces/i-rug-level";
import type { IPaginationNew } from "../interfaces/i-pagination";
import { ApplicationEnum } from "./../enums/enums";
import {
    IGetHHReferralDocuments,
    IGetHHReferralDocumentsResponse,
    IUploadHHDocument
} from "../resources-vue/vue-interfaces/i-get-referral";
import { ISuccessResponse } from "../resources-vue/vue-interfaces/i-common";

@noView()
@autoinject
export class CentralIntakeService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = `/api/v1`;

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async createInquiry(newInquiry: Inquiry) {
        return await this._fetchClient
            .build(`${this._baseUrl}/inquiries`)
            .useMethod("POST")
            .useQueryString({ agencyId: newInquiry.accountId, application: newInquiry.application })
            .useBody(newInquiry)
            .fetch<ICreateResponse>();
    }

    public async inquiries(query: IGetInquiryQuery) {
        let inquiries = await this._fetchClient
            .build(`${this._baseUrl}/inquiries/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetInquiryResult>();
        return inquiries;
    }

    public async getInquiryById(inquiryId: string, inquiryByIdParams: IApplicationAndAccountQueryParam) {
        return await this._fetchClient
            .build(`${this._baseUrl}/inquiries/${inquiryId}`)
            .useQueryString(inquiryByIdParams)
            .fetch<IGetInquiry>();
    }

    public async deleteInquiry(inquiryId: string, query: IDeleteInquiryQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/inquiries/${inquiryId}`)
            .useQueryString(query)
            .useMethod("DELETE")
            .fetch<IDeleteUpdateInquiryResult>();
    }

    public async editInquiry(inquiryId: string, inquiry: Inquiry) {
        return await this._fetchClient
            .build(`${this._baseUrl}/inquiries/${inquiryId}`)
            .useMethod("PUT")
            .useBody(inquiry)
            .fetch<IDeleteUpdateInquiryResult>();
    }

    public async referrals(query: IGetReferralQuery) {
        let referrals = await this._fetchClient
            .build(`${this._baseUrl}/referrals/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetReferralResult>();
        return referrals;
    }

    public async getNonAdmitReasons(query: INonAdmitReasonQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/non-admit-reasons/slim`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetNonAdmitReasons>();
    }

    public async updateReferralToNonAdmit(id: string, query: any) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${id}/status/non-admit`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }
    public async getReferralDocuments(referralId: string, getReferralDocumentQuery: IApplicationAndAccountQueryParam) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${referralId}/documents`)
            .useQueryString(getReferralDocumentQuery)
            .fetch<IReferralDocument[]>();
    }

    public async uploadDocument(referralId: string, query: IUploadReferralDocumentQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${referralId}/documents`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IUploadReferralDocumentResult>();
    }

    public async deleteDocument(deleteDocumentQuery: IDeleteDocumentQueryParam, documentId: string) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/documents/${documentId}`)
            .useMethod("DELETE")
            .useBody(deleteDocumentQuery)
            .fetchNoContent();
    }

    public async getHHDocuments(query: IGetHHReferralDocuments) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/hh-documents`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetHHReferralDocumentsResponse>();
    }

    public async uploadHHDocuments(payload: FormData) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/documents`)
            .useMethod("POST")
            .useFormData(payload)
            .fetch<IUploadReferralDocumentResult>();
    }

    public async editHHDocument(id: string, payload: IUploadHHDocument) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/documents/${id}`)
            .useMethod("PUT")
            .useBody(payload)
            .fetch<ISuccessResponse>();
    }

    public async createReferralPayors(referralId: string, patientPayer: PatientPayor) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${referralId}/payors`)
            .useMethod("POST")
            .useBody(patientPayer)
            .fetch<ICreateResponse>();
    }

    public async updateReferralPayors(payerId: string, patientPayer: PatientPayor) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${payerId}/payors`)
            .useMethod("PUT")
            .useBody(patientPayer)
            .fetch<IUpdateReferralPayorResponse>();
    }

    public async getCurrentReferralPayors(referralId: string, query: IGetCurrentPatientPayerQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${referralId}/payors/current`)
            .useQueryString(query)
            .fetch<IGetPatientPayerResult>();
    }

    public async getReferralPayors(referralId: string, query: IGetPatientPayerQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${referralId}/payors`)
            .useQueryString(query)
            .fetch<IGetPatientPayerResult>();
    }

    public async deleteReferralPayors(payerId: string, query: IApplicationAndAccountQueryParam) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/${payerId}/payors`)
            .useMethod("DELETE")
            .useBody(query)
            .fetch<IGetPayorDeleteResult>();
    }

    public async getPayorsByName(query: IGetPayorByNameQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/payors`)
            .useQueryString(query)
            .fetch<IGetPayorSearchByNameResult[]>();
    }

    public async getMedicareIds(getMedicareIdQuery: IGetMedicareIdsQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/medicare`)
            .useQueryString(getMedicareIdQuery)
            .fetch<IGetMedicareIds[]>();
    }

    public async getRugLevelsByPage(patientId: string, query: IGetRugLevelsQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/patients/${patientId}/ruglevels`)
            .useQueryString(query)
            .fetch<IPaginationNew<IRugLevel>>();
    }

    public async createRugLevel(patientId: string, rugLevel: IRugLevel) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${patientId}/ruglevels`)
            .useMethod("POST")
            .useBody(rugLevel)
            .fetch<ICreatePatientPayerResult>();
    }

    public async updateRugLevel(patientId: string, rugLevelId: string, rugLevel: IRugLevel) {
        return await this._fetchClient
            .build(`${this._baseUrl}/patients/${patientId}/ruglevel/${rugLevelId}`)
            .useMethod("PUT")
            .useBody(rugLevel)
            .fetchNoContent();
    }

    public async deleteRugLevel(patientId: string, rugLevelId: string, queryParam: IDeleteRugLevelQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/patients/${patientId}/ruglevel/${rugLevelId}`)
            .useMethod("DELETE")
            .useBody(queryParam)
            .fetchNoContent();
    }

    public async validateRoomAndBoard(patientId: string, queryParams: { effectiveDate: string; accountId: string }) {
        return await this._fetchClient
            .build(`${this._baseUrl}/patients/${patientId}/has-room-and-board`)
            .useQueryString(queryParams)
            .fetch<boolean>();
    }

    public async getRugLevels(vendorId: string, effectiveDate: string) {
        return await this._fetchClient
            .build(`${this._baseUrl}/patients/${vendorId}/ruglevels`)
            .useQueryString({ effectiveDate })
            .fetch<string[]>();
    }

    public async getReferralPayorsByBranchId(query: IGetPayorByBranchID) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/payor-by-branch`)
            .useQueryString(query)
            .fetch<IGetInsuranceQuery[]>();
    }

    public async getMunicipalityPayRate(queryParams: { agencyId: string; application: ApplicationEnum }) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/agency-municipality`)
            .useQueryString(queryParams)
            .fetch<IGetMunicipalityPayRates[]>();
    }

    public async checkClientConflict(query: ICheckClientConflictRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/find-conflict`)
            .useQueryString(query)
            .fetch<ICheckClientConflictResponse>();
    }

    public async convertToReferral(inquiryId: string, payload: ICreateInterimReferralPayload) {
        return await this._fetchClient
            .build(`${this._baseUrl}/inquiries/${inquiryId}/convert`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<ICreateResponse>();
    }

    public async getInquiryCount(payload: IIntakeCountRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/inquiries/count`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IIntakeCountResponse[]>();
    }
    public async getReferralCount(payload: IIntakeCountRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/referrals/count`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IIntakeCountResponse[]>();
    }
}
