import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { requiredIf, maxLength } from "@vuelidate/validators";

import { useEditReferralHH } from "../../../../store/useEditReferralHH";

export default defineComponent({
    props: {
        type: { type: String },
        options: { type: Object }
    },
    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            errors: "errors"
        })
    },
    data() {
        return {
            medicareInsurance: ["1", "2", "3", "4", "5"],
            v$: useVuelidate()
        };
    },
    validations() {
        return {
            referral: {
                Insurance: {
                    PrimaryHealthPlanId: {
                        required: requiredIf(
                            () =>
                                !this.medicareInsurance.includes(this.referral.Insurance.PrimaryInsurance) &&
                                this.referral.Insurance.PrimaryInsurance != null
                        ),
                        maxLength: maxLength(50)
                    },
                    PrimaryRelationship: {
                        required: requiredIf(
                            () =>
                                !this.medicareInsurance.includes(this.referral.Insurance.PrimaryInsurance) &&
                                this.referral.Insurance.PrimaryInsurance != null
                        ),
                        maxLength: maxLength(50)
                    },
                    SecondaryHealthPlanId: {
                        required: requiredIf(
                            () =>
                                !this.medicareInsurance.includes(this.referral.Insurance.SecondaryInsurance) &&
                                this.referral.Insurance.SecondaryInsurance != null
                        ),
                        maxLength: maxLength(50)
                    },
                    SecondaryRelationship: {
                        required: requiredIf(
                            () =>
                                !this.medicareInsurance.includes(this.referral.Insurance.SecondaryInsurance) &&
                                this.referral.Insurance.SecondaryInsurance != null
                        ),
                        maxLength: maxLength(50)
                    },
                    TertiaryHealthPlanId: {
                        required: requiredIf(
                            () =>
                                !this.medicareInsurance.includes(this.referral.Insurance.TertiaryInsurance) &&
                                this.referral.Insurance.TertiaryInsurance != null
                        ),
                        maxLength: maxLength(50)
                    },
                    TertiaryRelationship: {
                        required: requiredIf(
                            () =>
                                !this.medicareInsurance.includes(this.referral.Insurance.TertiaryInsurance) &&
                                this.referral.Insurance.TertiaryInsurance != null
                        ),
                        maxLength: maxLength(50)
                    },
                    AdditionalBillingInfo: {
                        PrimaryInsuredDetails: {
                            FirstName: {
                                required: requiredIf(
                                    () =>
                                        this.referral.Insurance.PrimaryRelationship != "2" &&
                                        this.referral.Insurance.PrimaryRelationship != null
                                )
                            },
                            LastName: {
                                required: requiredIf(
                                    () =>
                                        this.referral.Insurance.PrimaryRelationship != "2" &&
                                        this.referral.Insurance.PrimaryRelationship != null
                                )
                            }
                        },
                        SecondaryInsuredDetails: {
                            FirstName: {
                                required: requiredIf(
                                    () =>
                                        this.referral.Insurance.SecondaryRelationship != "2" &&
                                        this.referral.Insurance.SecondaryRelationship != null
                                )
                            },
                            LastName: {
                                required: requiredIf(
                                    () =>
                                        this.referral.Insurance.SecondaryRelationship != "2" &&
                                        this.referral.Insurance.SecondaryRelationship != null
                                )
                            }
                        },
                        TertiaryInsuredDetails: {
                            FirstName: {
                                required: requiredIf(
                                    () =>
                                        this.referral.Insurance.TertiaryRelationship != "2" &&
                                        this.referral.Insurance.TertiaryRelationship != null
                                )
                            },
                            LastName: {
                                required: requiredIf(
                                    () =>
                                        this.referral.Insurance.TertiaryRelationship != "2" &&
                                        this.referral.Insurance.TertiaryRelationship != null
                                )
                            }
                        }
                    }
                }
            }
        };
    },
    methods: {
        ...mapActions(useEditReferralHH, { setErrors: "SET_NEW_ERRORS" }),
        handleAllInput(field: string, value: any, errorName: string) {
            if (value.length || value !== null) {
                const newErrors = [...new Set(this.errors[errorName])].filter((val: any) => val !== field);
                const payload = {
                    property: errorName,
                    value: newErrors
                };
                this.setErrors(payload);
            }
        }
    }
});
