import { ValidationRules } from "aurelia-validation";

import { ApplicationEnum, SmokingStatusEnum } from "../enums/enums";
import type { IHospiceReferral } from "../interfaces/i-hospice-referral";
import type { IPhoneNumber } from "../interfaces/i-phone-number";
import type { IPriorHospice } from "../interfaces/i-prior-hospice";
import { Address } from "./address";
import { PriorHospice } from "./prior-hospice";
import { Referral } from "./referral";
export class HospiceReferral extends Referral implements IHospiceReferral {
    public isReAdmit: boolean = false;
    /*------------------ Start of Payor Information --------------------------*/
    public isTransfer: boolean = false;
    /*------------------ End of Payor Information --------------------------*/

    /*------------------ Start of Admission Information --------------------------*/
    public admissionType: number = null;
    public admissionSource: number = null;
    public benefitPeriodNumber: string = null;
    public application: ApplicationEnum;
    public accountId: string;
    public agencyId: string;
    public priorHospice: IPriorHospice = new PriorHospice();
    /*------------------ End of Admission Information --------------------------*/

    /*------------------ Start of Clinical Information --------------------------*/
    public preadmissionEvaluationDate: string;
    public preadmissionEvaludationClinicianId: string;
    public isPreadmissionRequired: boolean;
    public tolerablePainLevel: number;
    /*------------------ End of Clinical Information --------------------------*/

    /*------------------ Start of Referral Readiness --------------------------*/
    public referralReadiness: number[] = [];
    public completedBy: string;
    public completedDate: string;
    public isCopyObtained: boolean = false;
    public contactPersonFirstName: string = "";
    public contactPersonLastName: string = "";
    public contactPersonPhoneNumber: IPhoneNumber = {
        number: "",
        extension: "",
        type: 0,
        country: null,
        isPrimary: true
    };
    /*------------------ End of Referral Readiness --------------------------*/

    public genderIdentity: number = 0;
    public otherGenderIdentity: string = "";
    public sexualOrientation: number = 0;
    public otherSexualOrientation: string = "";
    public declineToSpecifyEthnicity: boolean = false;
    public declineToSpecifyRace: boolean = false;
    /*------------------ Start of Health History ----------------------------*/
    public smokingStatus: number = 0;
    public smokingStartDate: string = "";
    public smokingEndDate: string = "";
    public historicalSmokingStatus: number = 0;
    public historicalSmokingStartDate: string = "";
    public historicalSmokingEndDate: string = "";
    /*------------------ End of Health History ----------------------------*/
    public palliativeReferralReadiness: number[] = [];
    public palliativeCareOrderOptions: number;

    public constructor(application: ApplicationEnum, data?: IHospiceReferral) {
        super();
        if (!!data) {
            Object.assign(this, data);
            this.primaryAddress = new Address(data.primaryAddress);
            this.mailingAddress = new Address(data.mailingAddress);
            this.visitAddress = new Address(data.visitAddress);
            if (this.phoneNumbers.length === 0) {
                this.phoneNumbers = [
                    {
                        number: "",
                        extension: "",
                        type: 0,
                        country: null,
                        isPrimary: true
                    },
                    {
                        number: "",
                        extension: "",
                        type: 0,
                        country: null,
                        isPrimary: false
                    }
                ];
            }
        }
        if (application == ApplicationEnum.AxxessHospiceFE) {
            this.priorHospice = new PriorHospice(data?.priorHospice);
            this.initHospiceValidations();
        } else if (application == ApplicationEnum.AxxessPalliative) {
            if (!this.palliativeReferralReadiness) {
                this.palliativeReferralReadiness = [];
            }
            this.initPalliativeValidations();
        }
    }

    private initHospiceValidations() {
        let primary = this.phoneNumbers.findIndex((number) => number.isPrimary);
        if (primary > -1) {
            ValidationRules.ensure((mobilePhoneNumber: IPhoneNumber) => mobilePhoneNumber.number)
                .matches(/^\d{10}$/)
                .withMessage("Invalid Mobile Number.")
                .then()
                .required()
                .withMessage("Mobile Number is required.")
                .on(this.phoneNumbers[primary]);
        }
        ValidationRules.ensure((referral: HospiceReferral) => referral.medicareBeneficiaryIdentification)
            .required()
            .when((referral: HospiceReferral) => !referral.medicareBeneficiaryIdentificationNA)
            .withMessage("Medicare Beneficiary Identifier is required unless N/A is checked.")
            .ensure((referral: HospiceReferral) => referral.lineOfService)
            .required()
            .withMessage("Line of Service is required.")
            .ensure((referral: HospiceReferral) => referral.referralDate)
            .required()
            .withMessage("Referral Date is required.")
            .ensure((referral: HospiceReferral) => referral.sourceOfReferral)
            .required()
            .withMessage("Source of Referral is required.")
            .ensure((referral: HospiceReferral) => referral.firstName)
            .required()
            .withMessage("First Name is required.")
            .ensure((referral: HospiceReferral) => referral.lastName)
            .required()
            .withMessage("Last Name is required.")
            .ensure((referral: HospiceReferral) => referral.gender)
            .required()
            .withMessage("Gender is required.")
            .ensure((referral: HospiceReferral) => referral.locationId)
            .required()
            .withMessage("Agency Branch is required.")
            .ensure((referral: HospiceReferral) => referral.dateOfBirth)
            .required()
            .withMessage("Date of Birth is required.")
            .ensure((referral: HospiceReferral) => referral.email)
            .email()
            .withMessage("Invalid Email Address.")
            .ensure((referral: HospiceReferral) => referral.admissionSource)
            .required()
            .withMessage("Admission Source is required.")
            .ensure((referral: HospiceReferral) => referral.admissionType)
            .required()
            .withMessage("Admission Type is required.")
            .ensure((referral: HospiceReferral) => referral.benefitPeriodNumber)
            .required()
            .withMessage("Benefit Period Number is required.")
            .ensure((referral: HospiceReferral) => referral.benefitPeriodNumber)
            .satisfies((val, referral: HospiceReferral) => {
                return val == "" || val == null || val == undefined || (val <= 99 && val >= 1);
            })
            .withMessage("Benefit Period Number is invalid.")
            .on(this);
        this.primaryAddress?.initValidations();
        this.mailingAddress?.initValidations();
        this.visitAddress?.initValidations();
        this.priorHospice?.initValidations();
    }

    private initPalliativeValidations() {
        let primary = this.phoneNumbers.findIndex((number) => number.isPrimary);
        if (primary > -1) {
            ValidationRules.ensure((mobilePhoneNumber: IPhoneNumber) => mobilePhoneNumber.number)
                .matches(/^\d{10}$/)
                .withMessage("Invalid Mobile Number.")
                .then()
                .required()
                .withMessage("Mobile Number is required.")
                .on(this.phoneNumbers[primary]);
        }
        ValidationRules.ensure((referral: Referral) => referral.medicareBeneficiaryIdentification)
            .required()
            .when((referral: Referral) => !referral.medicareBeneficiaryIdentificationNA)
            .withMessage("Medicare Beneficiary Identifier is required unless N/A is checked.")
            .ensure((referral: Referral) => referral.lineOfService)
            .required()
            .withMessage("Line of Service is required.")
            .ensure((referral: Referral) => referral.referralDate)
            .required()
            .withMessage("Referral Date is required.")
            .ensure((referral: Referral) => referral.sourceOfReferral)
            .required()
            .withMessage("Source of Referral is required.")
            .ensure((referral: Referral) => referral.firstName)
            .required()
            .withMessage("First Name is required.")
            .ensure((referral: Referral) => referral.lastName)
            .required()
            .withMessage("Last Name is required.")
            .ensure((referral: Referral) => referral.gender)
            .required()
            .withMessage("Gender is required.")
            .ensure((referral: Referral) => referral.locationId)
            .required()
            .withMessage("Agency Branch is required.")
            .ensure((referral: Referral) => referral.dateOfBirth)
            .required()
            .withMessage("Date of Birth is required.")
            .ensure((referral: Referral) => referral.email)
            .email()
            .withMessage("Invalid Email Address.")
            .ensure((referral: HospiceReferral) => referral.smokingStartDate)
            .required()
            .when(
                (referral: HospiceReferral) =>
                    !(
                        referral.smokingStatus === 0 ||
                        referral.smokingStatus === SmokingStatusEnum.NeverSmoker ||
                        referral.smokingStatus === SmokingStatusEnum.UnknownIfEverSmoked
                    )
            )
            .withMessage("Smoking start date is required.")
            .ensure((referral: HospiceReferral) => referral.historicalSmokingStartDate)
            .required()
            .when(
                (referral: HospiceReferral) =>
                    !(
                        referral.historicalSmokingStatus === 0 ||
                        referral.historicalSmokingStatus === SmokingStatusEnum.NeverSmoker ||
                        referral.historicalSmokingStatus === SmokingStatusEnum.UnknownIfEverSmoked
                    )
            )
            .withMessage("Historical smoking start date is required.")
            .ensure((referral: HospiceReferral) => referral.historicalSmokingEndDate)
            .required()
            .when(
                (referral: HospiceReferral) =>
                    !(
                        referral.historicalSmokingStatus === 0 ||
                        referral.historicalSmokingStatus === SmokingStatusEnum.NeverSmoker ||
                        referral.historicalSmokingStatus === SmokingStatusEnum.UnknownIfEverSmoked
                    )
            )
            .withMessage("Historical smoking end date is required.")
            .on(this);
        this.primaryAddress?.initValidations();
        this.mailingAddress?.initValidations();
        this.visitAddress?.initValidations();
    }
}
