export interface ICustomEnumValue<T> {
    value: T;
    name: string;
    longDescription?: string;
}

export abstract class EnumWithDescription<T> {
    public abstract readonly enumName: string;
    public abstract readonly enum: any;
    public abstract readonly enumWithDescriptions: Map<string, ICustomEnumValue<T>>;

    public getValueUsingKey(key: string) {
        if (!key) {
            throw ReferenceError(`Key: "${key}" (typeof ${typeof key}) cannot be null, undefined or empty string.`);
        }
        if (typeof key != "string") {
            throw TypeError(`Key: "${key}" (typeof ${typeof key}) must be of type string`);
        }

        if (this.enum.hasOwnProperty(key)) {
            let value: T = this.enum[key];

            if (value != null || value != undefined) {
                return value;
            } else {
                throw Error(
                    `Key: "${key}" does exist on ${
                        this.enumName
                    } but value cannot be null or empty string. Value: "${value} typeof ${typeof value}"`
                );
            }
        } else {
            throw Error(`Key: "${key}" does not exist on ${this.enumName}`);
        }
    }

    public getDescriptionUsingKey(key: string): string {
        if (!key) {
            throw ReferenceError(`Key: "${key}" (typeof ${typeof key}) cannot be null, undefined or empty string.`);
        }
        if (typeof key != "string") {
            throw TypeError(`Key: "${key}" (typeof ${typeof key}) must be of type string`);
        }

        if (this.enumWithDescriptions.has(key)) {
            let name: string = this.enumWithDescriptions.get(key).name;

            if (name) {
                return name;
            } else {
                throw Error(
                    `Key: "${key}" exists on ${this.enumName} but name cannot be null or empty string. Check enum-descriptions.json.`
                );
            }
        } else {
            throw Error(`Key: "${key}" does not exist on ${this.enumName}`);
        }
    }

    public getKeyUsingValue(enumValue: T): string {
        if (enumValue === null || enumValue === undefined || enumValue.toString() === "") {
            throw ReferenceError(
                `EnumValue: ${enumValue} (typeof ${typeof enumValue}) cannot be null, undefined or empty string.`
            );
        }

        if (this.enum.hasOwnProperty(enumValue)) {
            let key: string = this.enum[enumValue];

            if (key) {
                return key;
            } else {
                throw Error(
                    `This should honestly never be possible but what the hell. Value: ${enumValue} does exist on ${this.enumName} but Key is null or empty string`
                );
            }
        } else {
            throw Error(`Value: ${enumValue} does not exist on ${this.enumName}`);
        }
    }

    public getDescriptionUsingValue(enumValue: T): string {
        if (enumValue === null || enumValue === undefined || enumValue.toString() === "") {
            throw ReferenceError(
                `EnumValue: ${enumValue} (typeof ${typeof enumValue}) cannot be null, undefined or empty string.`
            );
        }

        try {
            let key: string = this.getKeyUsingValue(enumValue);

            try {
                let name: string = this.getDescriptionUsingKey(key);
                return name;
            } catch (error) {
                throw error as Error;
            }
        } catch (error) {
            throw error as Error;
        }
    }

    public rawValueToEnumMember(enumValue: string | number): T {
        if (enumValue === null || enumValue === undefined || enumValue.toString() === "") {
            throw ReferenceError(
                `EnumValue: ${enumValue} (typeof ${typeof enumValue}) cannot be null, undefined or empty string.`
            );
        }

        if (this.enum.hasOwnProperty(enumValue)) {
            let key: string = this.enum[enumValue];
            return this.enum[key];
        } else {
            throw Error(`Value: ${enumValue} does not exist on ${this.enumName}`);
        }
    }

    public toMap(): Map<string, ICustomEnumValue<T>> {
        return this.enumWithDescriptions;
    }
}

export function getDescriptionFor<enumT>(enumValue: enumT, enumClass: EnumWithDescription<enumT>): string {
    if (enumValue === null || enumValue === undefined) {
        return "";
    } else {
        return enumValue ? enumClass.getDescriptionUsingValue(enumValue) : "";
    }
}

export function getValueAndDescriptionFor<enumT>(enumValue: enumT, enumClass: EnumWithDescription<enumT>): string {
    if (enumValue === null || enumValue === undefined) {
        return "";
    } else {
        return `${enumValue} - ${enumClass.getDescriptionUsingValue(enumValue)}`;
    }
}
