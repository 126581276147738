import type { IPriorHospice } from "../interfaces/i-prior-hospice";
import type { IAddress } from "../interfaces/i-address";
import type { IPhoneNumber } from "../interfaces/i-phone-number";
import { Address } from "../models/address";
import { PhoneNumber } from "../models/phone-number";
import { ValidationRules } from "aurelia-validation";

export class PriorHospice implements IPriorHospice {
    public name: string = "";
    public admissionDate: string = "";
    public transferDate: string = "";
    public benefitPeriodStartDate: string = "";
    public benefitPeriodEndDate: string = "";
    public daysUsed: number = null;
    public faceToFaceObtained: boolean = false;
    public transferHospiceAddress: IAddress = new Address();
    public contactPersonFirstName: string = "";
    public contactPersonLastName: string = "";
    public contactPersonPhoneNumber: IPhoneNumber = new PhoneNumber();

    public constructor(data?: IPriorHospice) {
        if (!!data) {
            Object.assign(this, data);
            if (!!data.transferHospiceAddress) {
                this.transferHospiceAddress = new Address(data.transferHospiceAddress);
            }
            if (!!data.contactPersonPhoneNumber) {
                this.contactPersonPhoneNumber = new PhoneNumber(data.contactPersonPhoneNumber);
            }
        }
    }

    public initValidations() {
        ValidationRules.ensure((priorHospice: IPriorHospice) => priorHospice.name)
            .required()
            .withMessage("Transferring Hospice is required.")
            .ensure((priorHospice: IPriorHospice) => priorHospice.benefitPeriodStartDate)
            .required()
            .withMessage("Current Benefit Period Start Date is required.")
            .on(this);
    }
}
