import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum SexualOrientationEnum {
    LesbianGayHomoSexual = 1,
    StraightHeterosexual = 2,
    Bisexual = 3,
    SomethingElse = 4,
    NotKnown = 5,
    NotToDisclose = 6
}
class SexualOrientationClass extends EnumWithDescription<SexualOrientationEnum> {
    public readonly enum = SexualOrientationEnum;
    public readonly enumName: string = "SexualOrientationEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<SexualOrientationEnum>> = new Map([
        [
            "LesbianGayHomoSexual",
            {
                value: SexualOrientationEnum.LesbianGayHomoSexual,
                name: "Lesbian, gay or homosexual"
            }
        ],
        [
            "StraightHeterosexual",
            {
                value: SexualOrientationEnum.StraightHeterosexual,
                name: "Straight or heterosexual"
            }
        ],
        [
            "Bisexual",
            {
                value: SexualOrientationEnum.Bisexual,
                name: "Bisexual"
            }
        ],
        [
            "SomethingElse",
            {
                value: SexualOrientationEnum.SomethingElse,
                name: "Something else"
            }
        ],
        [
            "NotKnown",
            {
                value: SexualOrientationEnum.NotKnown,
                name: "Don't Know"
            }
        ],
        [
            "NotToDisclose",
            {
                value: SexualOrientationEnum.NotToDisclose,
                name: "Choose not to disclose"
            }
        ]
    ]);
}

export const SexualOrientation = new SexualOrientationClass();
