/***************************************************************
 * *
 * Aurelia Bootstrap with Bugs Fixed and specific to Bootstrap 4
 * https://tochoromero.github.io/aurelia-bootstrap/#/typeahead
 * *
 ***************************************************************/
import "./typeahead-input.scss";

import {
    autoinject,
    bindable,
    BindingEngine,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    Disposable,
    observable
} from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { Key } from "ts-keycode-enum";

import nameOf from "../../../common/name-of";
import { HttpStatusCodeEnum } from "../../../enums/enums";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import type { TypeaheadData, TypeaheadComponentData } from "../../../interfaces/i-typeahead";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

@autoinject
@containerless
@customElement("typeahead-input")
export class TypeaheadInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public data: TypeaheadComponentData;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public value: TypeaheadData;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public initValue: TypeaheadData;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public key = "name";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public id = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public customEntry: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public resultsLimit: number = 30;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public debounce: number = 300;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public characterLimit: number = 0;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public onSelect: (params: { item: TypeaheadData }) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public instantCleanEmpty: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public openOnFocus: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public closeOnSelection: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public focusFirst: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isFocus: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public selectSingleResult: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public autoComplete: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public loadingText: string = "Loading...";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public inputClass: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public wrapperClass: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "Start Typing...";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public noResultsText: string = "No matches found";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideIcon: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public keyUpdated: (id: string) => void = null;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public shouldAppend: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showAllByDefault: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public getAppendContent: (params: { item: TypeaheadData }) => "";
    @observable({
        changeHandler: nameOf<TypeaheadInput>("filterChanged")
    })
    public filter: string = "";
    public isAuthorized: boolean = true;
    public displayData: TypeaheadData[] = [];
    public loading: boolean = false;
    public dropdownRef: Element;
    public inputRef: HTMLInputElement;
    public dropdownMenuRef: Element;
    public focusedItem: TypeaheadData = null;
    public notAuthorizedText: string = "Contact your administrator to request permission to view this data.";
    private _promiseQueue: Promise<TypeaheadData[]>[] = [];
    private _focusedIndex: number = -1;
    private _showClass: string = "show";
    private _ignoreChange: boolean;
    private _dataObserver: Disposable;
    private _subscriptions: Disposable[] = [];
    private _bindingEngine: BindingEngine;
    private _openDropdownListener: () => void;
    private _outsideClickListener: (evt: MouseEvent) => void;
    private _inputKeyDownListener: (evt: KeyboardEvent) => void;

    @computedFrom(nameOf<TypeaheadInput>("data"))
    public get typeOfData() {
        return typeof this.data;
    }

    public constructor(bindingEngine: BindingEngine) {
        this._bindingEngine = bindingEngine;
    }

    public bind() {
        if (Array.isArray(this.data)) {
            this.initDataObserver();
        }
        this._subscriptions.push(
            this._bindingEngine.propertyObserver(this, "initValue").subscribe((newValue, oldValue) => {
                this.initValueChanged();
            })
        );
        this._subscriptions.push(
            this._bindingEngine.propertyObserver(this, "value").subscribe((newValue, oldValue) => {
                this.valueChanged();
            })
        );
        this.checkCustomEntry();
    }

    public attached() {
        this._openDropdownListener = () => this.openDropdown();
        this._outsideClickListener = (evt: MouseEvent) => this.handleBlur(evt);
        this._inputKeyDownListener = (evt: KeyboardEvent) => this.onKeyDown(evt);

        if (typeof this.onSelect !== "function") {
            this.openOnFocus = true;
        }
        if (this.openOnFocus && !!this.inputRef) {
            this.inputRef.addEventListener("focus", this._openDropdownListener);
            this.inputRef.addEventListener("click", this._openDropdownListener);
        }
        this.initValueChanged();

        document?.addEventListener("click", this._outsideClickListener);
        this.inputRef?.addEventListener("keydown", this._inputKeyDownListener);

        if (this.validation) {
            let displayName = this.validation.displayName;
            let message = this.validation.message;
            ValidationRules.ensure((x: TypeaheadInput) => x.filter)
                .displayName(displayName)
                .required()
                .when((typeahead: TypeaheadInput) => typeahead.validation.required)
                .withMessage(message ? message : `${displayName} is required.`)
                .on(this);
        }
    }

    public detached() {
        // this.value = null;
        if (this._dataObserver) {
            this._dataObserver.dispose();
        }
        if (this._subscriptions.length > 0) {
            this._subscriptions.forEach((subscription) => {
                subscription.dispose();
            });
        }

        document?.removeEventListener("click", this._outsideClickListener);
        this.inputRef?.removeEventListener("keydown", this._inputKeyDownListener);

        if (this.openOnFocus && !!this.inputRef) {
            this.inputRef.removeEventListener("focus", this._openDropdownListener);
            this.inputRef.removeEventListener("click", this._openDropdownListener);
        }
    }

    private initDataObserver() {
        if (Array.isArray(this.data)) {
            this._dataObserver = this._bindingEngine.collectionObserver(this.data).subscribe(async () => {
                this.checkCustomEntry();
                await this.applyPlugins();
            });
        }
    }

    public clearFilter() {
        this._ignoreChange = false;
        this.filter = "";
    }

    public async filterChanged() {
        if (this._ignoreChange) {
            this._ignoreChange = false;
            return;
        }

        await this.applyPlugins();
        // if (this.instantCleanEmpty && this.filter.length === 0) {
        //     this.value = null;
        //     console.log("Filter Changed: ", this.value);

        //     if (typeof this.onSelect === "function") {
        //         this.onSelect({ item: null });
        //     }
        // } else
        if (this.customEntry) {
            this.value = this.filter;

            if (typeof this.onSelect === "function") {
                this.onSelect({ item: this.value });
            }
        } else if (this.selectSingleResult && this.displayData.length === 1) {
            this.itemSelected(this.displayData[0]);
        }
    }

    private onKeyDown(evt: KeyboardEvent) {
        setTimeout(async () => {
            this.dropdownMenuRef?.classList.add(this._showClass);
            if (this.filter.length >= this.characterLimit) {
                if (this.dropdownMenuRef && this.dropdownMenuRef.classList.contains(this._showClass)) {
                    this.switchKeyCode(evt.keyCode);
                    return;
                }
            }
        }, 150);
    }

    public async dataChanged() {
        if (this._dataObserver) {
            this._dataObserver.dispose();
        }

        if (Array.isArray(this.data)) {
            this.initDataObserver();
            await this.applyPlugins();
        }
    }

    public valueChanged() {
        let newFilter = this.getName(this.value);
        if (newFilter !== this.filter) {
            this._ignoreChange = true;
            this.filter = newFilter;
        }
    }

    public initValueChanged() {
        if (this.initValue) {
            let newFilter = this.getName(this.initValue);
            if (newFilter) {
                this._ignoreChange = true;
                this.filter = newFilter;
            }
        }
    }

    public focusInput() {
        if (this.showAllByDefault) {
            this.openDropdown();
            return;
        }
        this.isFocus = true;
        this.inputRef?.focus();
    }

    private async openDropdown() {
        if (this.openOnFocus && !this.disabled) {
            if (this.dropdownMenuRef && this.dropdownMenuRef.classList.contains(this._showClass)) {
                return;
            }

            this.dropdownMenuRef.classList.add(this._showClass);
            this.focusNone();
            await this.applyPlugins();
        }
    }

    private doFocusFirst() {
        if (this.focusFirst && this.displayData.length > 0) {
            this._focusedIndex = 0;
            this.focusedItem = this.displayData[0];
        }
    }

    private checkCustomEntry() {
        if (Array.isArray(this.data) && this.data.length > 0 && typeof this.data[0] !== "string") {
            this.customEntry = false;
        }
    }

    private focusNone() {
        this.focusedItem = null;
        this._focusedIndex = -1;
    }

    private doFilter(toFilter: ITypeaheadOptions[]) {
        let filteredSearch = toFilter.filter((item: ITypeaheadOptions) => {
            let searchKey = this.filter.toLowerCase();
            return item && this.getName(item).toLowerCase().indexOf(searchKey) > -1;
        });

        let allDataExceptFilteredSearch = toFilter.filter((task) => {
            return !filteredSearch.includes(task);
        });

        return [...filteredSearch, ...allDataExceptFilteredSearch];
    }

    private getName(item: TypeaheadData) {
        if (!item) {
            return "";
        }

        if (typeof item === "object") {
            return !!item[this.key] ? item[this.key].toString() : "";
        }

        return item.toString();
    }

    public resetFilter() {
        if (this.filter.length === 0) {
            this.value = null;
        }

        let newFilter;
        if (this.value) {
            newFilter = this.getName(this.value);
        } else {
            newFilter = "";
        }

        if (newFilter !== this.filter) {
            this._ignoreChange = true;
            this.filter = newFilter;
        }
    }

    private handleBlur(evt: MouseEvent) {
        if (this.dropdownMenuRef && !this.dropdownMenuRef.classList.contains(this._showClass)) {
            return;
        }

        setTimeout(() => {
            if (this.dropdownRef && !this.dropdownRef.contains(evt.target as Element)) {
                this.handleEscape();
            }
        }, this.debounce);
    }

    private itemSelected(item: TypeaheadData) {
        this.value = item;
        if (this.closeOnSelection) {
            this.dropdownMenuRef.classList.remove(this._showClass);
        }

        let newFilter = this.getName(this.value);
        if (newFilter !== this.filter) {
            this._ignoreChange = true;
            this.filter = newFilter;
        }

        if (typeof this.onSelect === "function") {
            this.onSelect({ item });
        }
        if (this.keyUpdated && this.value) this.keyUpdated(this.value.toString());
    }

    private switchKeyCode(keyCode: number) {
        switch (keyCode) {
            case Key.Tab:
            case Key.Enter:
                return this.handleEnter();
            case Key.Escape:
                return this.handleEscape();
            case Key.UpArrow:
                return this.handleUp();
            case Key.DownArrow:
                return this.handleDown();
            default:
                return;
        }
    }

    private handleDown() {
        if (this._focusedIndex >= this.displayData.length - 1) {
            return;
        }

        this._focusedIndex++;
        this.focusedItem = this.displayData[this._focusedIndex];
    }

    private handleUp() {
        if (this._focusedIndex === 0) {
            return;
        }

        this._focusedIndex--;
        this.focusedItem = this.displayData[this._focusedIndex];
    }

    private handleEnter() {
        if (
            this.displayData.length === 0 ||
            this._focusedIndex < 0 ||
            (this.dropdownMenuRef && !this.dropdownMenuRef.classList.contains(this._showClass))
        ) {
            return;
        }

        this.itemSelected(this.displayData[this._focusedIndex]);
    }

    private handleEscape() {
        this.dropdownMenuRef.classList.remove(this._showClass);
        this.focusNone();
        this.resetFilter();
    }

    private async applyPlugins() {
        this.focusNone();
        let localData;
        if (typeof this.data === "function") {
            if (this.showAllByDefault || (this.filter !== "" && this.filter.length >= this.characterLimit)) {
                this.displayData = [];
                this.loading = true;
                this.isAuthorized = true;
                try {
                    let promisedData = this.data({
                        filter: this.filter,
                        limit: this.resultsLimit
                    });
                    let data = await promisedData;
                    if (this._promiseQueue.length > 1) {
                        this._promiseQueue.splice(0, 1);
                        return new Promise((resolve) => resolve(null));
                    }
                    if (Array.isArray(data)) {
                        this.displayData = data;
                        this.doFocusFirst();
                        this._promiseQueue.splice(0, 1);
                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.displayData = [];
                        throw Error;
                    }
                    this._promiseQueue.push(promisedData);
                    return promisedData;
                } catch (error) {
                    this.loading = false;
                    this.displayData = [];
                    if (error.status === HttpStatusCodeEnum.Forbidden) {
                        this.isAuthorized = false;
                    }
                    throw error;
                }
            } else {
                this.handleEscape();
                this.loading = false;
                this.displayData = [];
                return new Promise((resolve) => resolve(null));
            }
        }

        localData = [].concat(this.data);
        if (this.filter && this.filter.length > 0) {
            localData = this.doFilter(localData);
        }
        if (this.resultsLimit !== undefined && this.resultsLimit !== null && !isNaN(this.resultsLimit)) {
            localData = localData.slice(0, this.resultsLimit);
        }

        this.displayData = localData;
        this.doFocusFirst();

        return Promise.resolve({});
    }
}
