import { defineComponent } from "vue";

import CentralCalendarRouter from "./CentralCalendarRouter";

import LevelTwo from "../LevelTwo/LevelTwo.vue";

export default defineComponent({
    components: {
        LevelTwo
    },
    data() {
        return {
            showPatients: false,
            showEmployees: false,
            showBorder: true,
            activeItem: null,
            router: null
        };
    },
    mounted() {
        this.router = CentralCalendarRouter;
    }
});
