import { BindingEngine, bindingMode, Disposable, observable } from "aurelia-binding";
import { autoinject, bindable, customElement } from "aurelia-framework";
import { EnumMap } from "../../../common/enum-map";
import nameOf from "../../../common/name-of";
import type { IBranchResponse } from "../../../interfaces/i-branch";
import type { IMultiSelectDropDownOption } from "../../../resources-vue/vue-interfaces/i-grouped-multiselect";
import type { IGetProviderItem } from "../../../interfaces/i-providers";
import { ProvidersService } from "../../../services/providers-service";
import { BranchesService } from "../../../services/branches-service";
import { ApplicationEnum } from "../../../enums/enums";

@customElement("branch-default-filter")
@autoinject
export class BranchDefaultFilter {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public labelText: string = "Branches";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public searchText: string = "Branches";
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameOf<BranchDefaultFilter>("lineOfServiceChanged")
    })
    public lineOfService: number = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedBranches: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public notAllowedSolutions: ApplicationEnum[] = null;
    private _bindingEngine: BindingEngine;
    private _branchSubscription: Disposable;
    private _providersService: ProvidersService;
    private _branchService: BranchesService;
    public branches: IBranchResponse[] = [];
    public providerIds: string[] = [];
    public providerEnumMap: EnumMap;
    public branchEnumMap: EnumMap;
    public checkAllByDefault: boolean = true;
    public dropDownOptions: IMultiSelectDropDownOption;
    @observable({
        changeHandler: nameOf<BranchDefaultFilter>("filterBranchesChanged")
    })
    public filterBranches: string[] = [];
    public isBranchLoading: boolean = false;
    public providers: IGetProviderItem[] = [];
    public isRequestPending: boolean = false;
    public constructor(
        bindingEngine: BindingEngine,
        providersService: ProvidersService,
        branchService: BranchesService
    ) {
        this._bindingEngine = bindingEngine;
        this._providersService = providersService;
        this._branchService = branchService;
    }

    public async attached() {
        await this.initFilters();
    }

    public async initFilters() {
        try {
            this.isRequestPending = true;
            this.branches = await this._branchService.getAllBranches();
            if (this.notAllowedSolutions?.length) {
                this.branches = this.branches.filter(
                    (branch) => !this.notAllowedSolutions.includes(branch.application)
                );
            }
            this.providers = await this._providersService.getProvider();
            this.providerIds = this.providers.map((provider) => provider.id);

            // this check will work if we again switch to showing only certain branches by default.
            if (!this.checkAllByDefault) {
                let defaultBranches: IBranchResponse[] = this.getBranchDefaults();
                this.filterBranches =
                    this.selectedBranches?.length > 0
                        ? Array.from(this.selectedBranches)
                        : defaultBranches.map((branch) => branch.id);
            }
            let providerEnum = this.createProviderEnum();
            let branchEnum = this.createBranchEnum();
            this.providerEnumMap = new EnumMap(providerEnum);
            this.branchEnumMap = new EnumMap(branchEnum);
            this.dropDownOptions = this.getDropDownOptions();
            this.initSubscription();
        } catch (e) {
            console.error(e);
        } finally {
            this.isRequestPending = false;
        }
    }

    private initSubscription() {
        this._branchSubscription = this._bindingEngine
            .collectionObserver(this.filterBranches)
            .subscribe(() => this.filterBranchesChanged());
    }

    public filterBranchesChanged() {
        // Using splice and push to modify selectedBranches.
        // CollectionObserver will not work if selectedBranches is reassigned.
        if (this.selectedBranches.length > 0) {
            let branchLength = this.selectedBranches.length;
            this.selectedBranches.splice(0, branchLength);
        }
        if (this.filterBranches?.length > 0) {
            this.filterBranches.forEach((branch) => this.selectedBranches.push(branch));
        } else {
            this.branches.forEach((branch) => this.selectedBranches.push(branch.id));
        }
    }

    public async lineOfServiceChanged() {
        this.isBranchLoading = true;
        await this.initFilters();
        if (this.selectedBranches?.length > 0) {
            let branchLength = this.selectedBranches.length;
            this.selectedBranches.splice(0, branchLength);
        }
        this.isBranchLoading = false;
    }

    public getBranchDefaults() {
        return this.branches.filter((branch) => this.providerIds.includes(branch.providerId));
    }

    public getAllProviderBranchIds(provider: string) {
        let branchIds: string[] = [];
        this.branches.forEach((branch) => {
            if (branch.providerId === provider) {
                branchIds.push(branch.id);
            }
        });
        return branchIds;
    }

    public getDropDownOptions() {
        let branchOptions: IMultiSelectDropDownOption = {};
        let providers = [...new Set(this.branches.map((branch) => branch.providerId))];
        providers.forEach((providerId) => {
            branchOptions[providerId] = this.getAllProviderBranchIds(providerId);
        });
        return branchOptions;
    }

    public createProviderEnum() {
        let providers = [...new Set(this.branches.map((branch) => branch.providerName))];
        let providerEnum = providers.map((name, index) => {
            return {
                name: name,
                value: index + 1
            };
        });
        return providerEnum;
    }

    public createBranchEnum() {
        return this.branches.map((branch) => ({
            name: branch.name,
            value: branch.id
        }));
    }

    public detached() {
        if (this._branchSubscription) {
            this._branchSubscription.dispose();
        }
    }
}
