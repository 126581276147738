import type { IBulkReassignVisitDetails } from "../vue-interfaces/i-bulk-reassign-visit";
import { ApplicationEnum } from "../../enums/enums";

export class BulkReassign implements IBulkReassignVisitDetails {
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public AgencyId: string;
    public AgencyName: string;
    public Mrn: string;
    public UserId: string;
    public PatientId: string;
    public Credentials: string;
    public Application: ApplicationEnum;
    public StartDate: string;
    public EndDate: string;
    public EmployeeTo: string;
    public EmployeeFrom: string;
    public TypeId: string;
    public ReasonId: string;
    public LocationId?: string;

    public constructor(data?: IBulkReassignVisitDetails) {
        if (!!data) {
            Object.assign(this, data);
        }
    }
}
