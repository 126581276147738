import { defineComponent, PropType } from "vue";
import moment from "moment";
import { Container } from "aurelia-dependency-injection";

import { Inquiry } from "../../../resources-vue/vue-models/inquiry";
import { CentralIntakeService } from "../../../services/central-intake.service";
import { ToastrService } from "../../../services/toastr.service";
import { formatDateFromDate } from "../../../common/vue-helpers/modifiers/value-modifier";
import { formatPhone } from "../../../common/vue-helpers/modifiers/value-modifier";
import { EnumMap } from "../../../common/enum-map";
import { ApplicationEnum, ActionButtonTooltipEnum, ApplicationNames } from "../../../enums/enums";
import { helperTestingAgency } from "../../../common/vue-helpers/testing-agency-check";
import { IDeleteInquiryQuery } from "../../../resources-vue/vue-interfaces/i-get-inquiry";
import { IBranchResponse } from "../../../resources-vue/vue-interfaces/i-branch";
import type { IEnumResponse } from "../../../resources-vue/vue-interfaces/i-enum";

import EmptyTextHandler from "../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import TablePrompt from "../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import FormatName from "../../../resources-vue/vue-custom-elements/FormatName/FormatName.vue";
import InquiryForm from "../../../resources-vue/vue-forms/InquiryForm/InquiryForm.vue";
import InquiryFormHomecareHomehealth from "../../../resources-vue/vue-forms/InquiryFormHomecareHomehealth/InquiryFormHomecareHomehealth.vue";

export default defineComponent({
    components: {
        EmptyTextHandler,
        TablePrompt,
        FormatName,
        InquiryForm,
        InquiryFormHomecareHomehealth
    },

    props: {
        inquiry: {
            type: Object,
            default: null
        },
        lineOfServiceEnumMap: {
            type: EnumMap,
            default: new EnumMap([])
        },
        referralsAddable: { default: [], type: Array },
        resetList: { type: Function, default: null },
        isEditSaveInProgress: { type: Boolean, default: null },
        serviceTypeEnums: { type: Array as PropType<IEnumResponse[]>, default: [] },
        branches: { type: Array as PropType<IBranchResponse[]>, default: null },
        columns: null,
        newInquiryId: null
    },

    data() {
        return {
            isEditRequested: false,
            isDeleteRequested: false,
            cachedData: null,
            isTestAgency: true,
            _router: null,
            inquiryApplication: null,
            ApplicationNamesEnum: ApplicationNames,
            isDeleteInProgress: false,
            _centralIntakeService: null,
            _toastrService: null,
            editInProgressId: null,
            hasEditPermission: true,
            hasDeletePermission: true,
            isConvertAllowed: false,
            actionButtonTooltipEnum: ActionButtonTooltipEnum,
            formatDateFromDate,
            formatPhone
        };
    },
    computed: {
        isApplicationHospice() {
            return this.inquiry.application == ApplicationEnum.AxxessHospiceFE;
        },
        isApplicationPalliative() {
            return this.inquiry.application == ApplicationEnum.AxxessPalliative;
        },
        isApplicationHomeCare() {
            return this.inquiry.application == ApplicationEnum.HomeCare;
        },
        isApplicationHomehealth() {
            return this.inquiry.application == ApplicationEnum.AgencyCore;
        }
    },
    emits: ["updateBranch", "update:isEditSaveInProgress"],
    async created() {
        this.hasEditPermission = !!this.inquiry.canEdit;
        this.hasDeletePermission = !!this.inquiry.canDelete;
        this.isTestAgency = await helperTestingAgency.checkIsTestingAgencyId(this.inquiry.accountId);
        // this._router = Container.instance.get(Router);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._toastrService = Container.instance.get(ToastrService);
        this.inquiryApplication = this.ApplicationNamesEnum.getDescriptionUsingValue(Number(this.inquiry.application));
    },
    async mounted() {
        this.isConvertAllowed =
            this.inquiry.accountId &&
            this.referralsAddable.some((item: IBranchResponse) => item.accountId == this.inquiry.accountId);
    },
    methods: {
        getLineOfService(lineOfService: number[]) {
            let resultArr: string[] = [];
            if (!!this.lineOfServiceEnumMap) {
                lineOfService?.forEach((service) =>
                    resultArr.push(this.lineOfServiceEnumMap.getEnumName(service) as string)
                );
                return resultArr.join(", ");
            }
            return "";
        },

        openEditInquiry(applicationId: ApplicationEnum) {
            this.isEditRequested = true;
            this.editInProgressId = this.inquiry.id;
            let cachedData = Object.assign({}, this.inquiry) as Inquiry;
            cachedData.servicesRequested =
                this.inquiry && this.inquiry.servicesRequested ? [...this.inquiry?.servicesRequested] : [];
            this.cachedData = new Inquiry(applicationId, cachedData, true);
            if (this.cachedData.date) this.cachedData.date = moment(this.cachedData.date).format("MM/DD/YYYY");
            if (this.cachedData.followUpDate) {
                this.cachedData.followUpDate = moment(this.cachedData.followUpDate).format("MM/DD/YYYY");
            }
        },

        navigateToReferralPage() {
            this.$router.push(
                `/axxess-central/create/interim/referral?inquiryId=${this.inquiry.id}&application=${this.inquiry.application}&accountId=${this.inquiry.accountId}`
            );
        },

        confirmDeleteInquiry() {
            this.isDeleteRequested = true;
        },

        cancelDeleteInquiry() {
            this.isDeleteRequested = false;
        },

        async deleteInquiry() {
            try {
                this.isDeleteInProgress = true;
                let deleteQuery: IDeleteInquiryQuery = {
                    agencyId: this.inquiry.accountId,
                    application: this.inquiry.application
                };
                await this._centralIntakeService.deleteInquiry(this.inquiry.id, deleteQuery);
                this.resetList?.();
                this._toastrService.success({
                    message: `Inquiry has been successfully deleted.`,
                    title: `Inquiry Deleted`
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Delete Inquiry`,
                    message: `There was a problem while deleting the inquiry. Please try again.`
                });
                this.isDeleteInProgress = false;
                this.confirmDeleteInquiry();
            }
        },
        updateEditBranch(applicationId: ApplicationEnum) {
            this.$emit("updateBranch", applicationId);
        },
        async updateInquiry() {
            try {
                this.$emit("update:isEditSaveInProgress", true);
                await this._centralIntakeService.editInquiry(this.inquiry.id, this.cachedData);
                Object.assign(this.inquiry, this.cachedData);
                this.$emit("update:isEditSaveInProgress", false);
                this.closeEditInquiry();
                this.resetList?.();
                this._toastrService.success({
                    title: `Inquiry Edited`,
                    message: `Inquiry has been successfully updated.`
                });
            } catch (err) {
                this.$emit("update:isEditSaveInProgress", false);
                this.openEditInquiry(this.inquiry.application);
            }
        },
        closeEditInquiry() {
            this.isEditRequested = false;
            this.cachedData = undefined;
            this.editInProgressId = undefined;
        }
    }
});
