import "./tabs-animated-header.scss";

import { autoinject, bindable, bindingMode, computedFrom, containerless, customElement } from "aurelia-framework";

@customElement("tabs-animated-header")
@autoinject
@containerless
export class TabsAnimatedHeader {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public activeItem: HTMLElement;
    // To change color of the bar
    @bindable({ defaultBindingMode: bindingMode.toView })
    public outlineStyle: string;

    @computedFrom("activeItem.offsetWidth")
    public get sliderWidth(): number {
        let item = this.activeItem;
        return item ? item.offsetWidth : null;
    }

    @computedFrom("activeItem.offsetLeft")
    public get sliderLeft(): number {
        let item = this.activeItem;
        return item ? item.offsetLeft : null;
    }

    @computedFrom("activeItem.offsetTop", "activeItem.offsetHeight")
    public get sliderBottom(): number {
        let item = this.activeItem;
        // 5 = height of the underline.
        return item ? item.offsetTop + item.offsetHeight - 5 : null;
    }

    @computedFrom("sliderLeft", "sliderWidth", "sliderBottom")
    public get underlineStyle() {
        let left = `left: ${this.sliderLeft + this.sliderWidth / 2 - 400 / 2}px;`;
        let top = `top: ${this.sliderBottom}px;`;
        let paddingLeft = `padding-left: ${(400 - this.sliderWidth) / 2}px;`;
        let paddingRight = `padding-right: ${(400 - this.sliderWidth) / 2}px;`;
        return left + top + paddingLeft + paddingRight;
    }
}
