import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { formatPhone } from "../../../common/vue-helpers/modifiers/value-modifier";
import { PharmacyService } from "../../../services/pharmacy.service";
import { ToastrService } from "../../../services/toastr.service";

export default defineComponent({
    inject: ["dialogRef"],

    data() {
        return {
            _pharmacyService: null,
            _toastrService: null,

            dialogReference: this.dialogRef as any,
            phone: "",
            fax: "",
            newPharmacy: {
                AgencyId: "",
                Name: "",
                AddressLine1: "",
                AddressLine2: "",
                AddressCity: "",
                AddressStateCode: "",
                AddressZipCode: "",
                ContactPersonFirstName: "",
                ContactPersonLastName: "",
                EmailAddress: null,
                Comment: "",
                PhoneArray: [],
                FaxNumberArray: []
            },
            addressStateCode: [] as any,
            formatPhone
        };
    },

    created() {
        this._pharmacyService = Container.instance.get(PharmacyService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    mounted() {
        this.addressStateCode = this.dialogReference.data.data.addressStateCode;
        this.newPharmacy.AgencyId = this.dialogReference.data.data.agencyId;
    },

    methods: {
        async addNewPharmacy() {
            if (this.newPharmacy.Name.length === 0) {
                this._toastrService.error({
                    title: `Pharmacy Name is required`
                });
            } else if (this.phone.length === 0) {
                this._toastrService.error({
                    title: `Primary Phone is required`
                });
            } else {
                let payload = { agencyPharmacy: { ...this.newPharmacy } };
                if (this.phone.length > 0) {
                    payload.agencyPharmacy.PhoneArray.push(this.phone.slice(0, 3));
                    payload.agencyPharmacy.PhoneArray.push(this.phone.slice(3, 6));
                    payload.agencyPharmacy.PhoneArray.push(this.phone.slice(6, 10));
                }
                if (this.fax.length > 0) {
                    payload.agencyPharmacy.FaxNumberArray.push(this.fax.slice(0, 3));
                    payload.agencyPharmacy.FaxNumberArray.push(this.fax.slice(3, 6));
                    payload.agencyPharmacy.FaxNumberArray.push(this.fax.slice(6, 10));
                }

                if (!payload.agencyPharmacy.EmailAddress) {
                    payload.agencyPharmacy.EmailAddress = null;
                }

                let result = await this._pharmacyService.addPharmacy(payload);
                if (result.isSuccessful) {
                    this._toastrService.success({
                        title: `${result.otherMessage}`
                    });
                    this.dialogReference.close();
                } else {
                    this._toastrService.error({
                        title: `${result.errorMessage}`
                    });
                }
            }
        },

        closeDialog() {
            this.dialogReference.close();
        },

        setPhoneHome(value: any) {
            this.phone = value.toString();
        },

        setFaxNumber(value: any) {
            this.fax = value.toString();
        }
    }
});
