import { autoinject } from "aurelia-framework";
import { User, UserManager } from "oidc-client";
import type { IRequestHandler } from "./i-handlers";
import config from "../config";

@autoinject()
export class AuthSubIdHandler implements IRequestHandler {
    private _userManager: UserManager;

    public constructor(userManager: UserManager) {
        this._userManager = userManager;
    }
    public async handle(request: Request): Promise<void> {
        if (request.url.indexOf(config.messagingServiceServerURL) == -1) return;
        let user: User = await this._userManager.getUser();
        if (user != null) {
            if (!request.headers.get("X-Chat-User")) {
                request.headers.append("X-Chat-User", `${user.profile.sub}`);
            }
        } else {
            console.log("User is not logged in, no X-Chat-User set.");
        }
    }
}
