import { defineComponent, PropType } from "vue";
import Popper from "popper.js";

export default defineComponent({
    props: {
        title: { type: String, default: null },
        placement: { type: String as PropType<Popper.Placement>, default: "auto" },
        className: { type: String, default: "" }
    },
    data() {
        return {
            showPopover: false,
            mouseOutTimeOut: null as NodeJS.Timer,
            _element: null as HTMLElement
        };
    },
    mounted() {
        this._element = this.$refs?.popoverElementRef as HTMLElement;

        let parent = this._element.parentElement;

        if (!parent) {
            throw Error("Could not find parent element");
        }

        let options: Popper.PopperOptions = {
            placement: this.placement
        };

        let popper = new Popper(parent, this._element, options);

        parent.onmouseover = () => this.mouseOver(popper);
        parent.onmouseout = () => this.mouseOut(popper);
    },
    methods: {
        mouseOver(popper: Popper) {
            if (this.mouseOutTimeOut) {
                clearTimeout(this.mouseOutTimeOut);
            }
            this.showPopover = true;
            popper.scheduleUpdate();
        },
        mouseOut(popper: Popper) {
            this.mouseOutTimeOut = setTimeout(() => {
                this.showPopover = false;
            }, 150);
        }
    }
});
