import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { GoogleMapsService } from "../../../services/google-maps.service";

export default defineComponent({
    props: {
        zip: {
            type: String,
            default: ""
        },
        street: {
            type: String,
            default: ""
        },
        state: {
            type: String,
            default: ""
        },
        county: {
            type: String,
            default: ""
        },
        country: {
            type: String,
            default: ""
        },
        city: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Start Typing..."
        },
        latitude: {
            type: Number
        },
        longitude: {
            type: Number
        },
        placesChangedCallBack: {
            type: Function
        },
        showValidateAddress: {
            type: Boolean,
            default: false
        }
    },

    data(vm) {
        return {
            _googleMapsWebService: null,
            _googleMapsWebApi: null,
            _autocomplete: null,
            _placeChangedListener: null,
            _placesAutocompleteCount: 0,
            autocompleteField: null
        };
    },
    created() {
        this._googleMapsWebService = Container.instance.get(GoogleMapsService);
    },
    async mounted() {
        this.autocompleteField = this.$refs.autocompleteField;
        this._placesAutocompleteCount++;
        try {
            this._googleMapsWebApi = await this._googleMapsWebService.getMapsApi();
            if (!!this.autocompleteField) {
                this._autocomplete = new this._googleMapsWebApi.places.Autocomplete(this.autocompleteField, {
                    types: ["geocode"]
                });
                this.getUsersLocation();
                this._placeChangedListener = this._autocomplete.addListener(
                    "place_changed",
                    this.handleAutocompleteResult
                );
            }
        } catch (e) {
            console.error("Error initializing Places Autocomplete", e);
        }
    },
    unmounted() {
        this._placesAutocompleteCount--;
        let placesElements = document.querySelectorAll(".pac-container");
        if (this._placeChangedListener) {
            google.maps.event.removeListener(this._placeChangedListener);
        }
        if (this._autocomplete) {
            google.maps.event.clearInstanceListeners(this._autocomplete);
        }
        if (!this._placesAutocompleteCount) {
            Array.from(placesElements).forEach((ele: Element) => ele.remove());
        }
    },
    methods: {
        getUsersLocation() {
            // TODO: Move this to a separate service. Getting users location
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    let circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                    this._autocomplete.setBounds(circle.getBounds());
                });
            }
        },
        handleAutocompleteResult() {
            let results: google.maps.places.PlaceResult = this._autocomplete.getPlace();
            if (!results) {
                return;
            }
            let address: google.maps.GeocoderAddressComponent[] = results.address_components;
            let street = "";
            let zip = "";
            let city = "";
            let county = "";
            let country = "";
            let state = "";
            let latitude = null;
            let longitude = null;
            if (address?.length > 0) {
                for (let [i, v] of address?.entries()) {
                    let type: string = address[i].types[0];
                    switch (type) {
                        case "street_number":
                            street += address[i].short_name;
                            break;
                        case "route":
                            street += ` ${address[i].long_name}`;
                            break;
                        case "country":
                            country = address[i].short_name;
                            break;
                        case "postal_code":
                            zip = address[i].short_name;
                            break;
                        case "postal_code_suffix":
                            zip += `${address[i].short_name}`;
                            break;
                        case "locality":
                            city = address[i].short_name;
                            break;
                        case "administrative_area_level_1":
                            state = address[i].short_name;
                            break;
                        case "administrative_area_level_2":
                            county = address[i].short_name.replace(" County", "");
                            break;
                    }
                }
            }
            if (results?.geometry?.location) {
                latitude = results?.geometry?.location?.lat();
                longitude = results?.geometry?.location?.lng();
            }
            if (this.showValidateAddress) {
                let updatedAddressParams = {
                    addressLine1: street,
                    state: state,
                    city: city,
                    zipCode: zip,
                    county: county,
                    latitude: latitude,
                    longitude: longitude,
                    isValidated: true
                };
                this.placesChangedCallBack(updatedAddressParams);
                this.$emit("update:country", country);
            } else {
                this.$emit("update:state", state);
                this.$emit("update:street", street);
                this.$emit("update:zip", zip);
                this.$emit("update:city", city);
                this.$emit("update:county", county);
                this.$emit("update:country", country);
                this.$emit("update:latitude", latitude);
                this.$emit("update:longitude", longitude);
            }
            this.autocompleteField.value = this.street;
        },
        emitBlur() {
            this.$emit("blurredInput");
        }
    }
});
