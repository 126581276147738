
import { defineComponent } from "vue";

import PatientInformation from "./PatientInformation/PatientInformation.vue";
import RaceEthnicity from "./RaceEthnicity/RaceEthnicity.vue";
import PatientLanguage from "./PatientLanguage/PatientLanguage.vue";
import PaymentSource from "./PaymentSource/PaymentSource.vue";
import AdmissionSource from "./AdmissionSource/AdmissionSource.vue";
import EpisodeManagement from "./EpisodeManagement/EpisodeManagement.vue";
import DemographicsTags from "./Tags/DemographicsTags.vue";

export default defineComponent({
    props: {
        branchId: {
            type: String
        },
        isEditing: {
            type: Boolean
        }
    },
    components: {
        PatientInformation,
        RaceEthnicity,
        PatientLanguage,
        PaymentSource,
        AdmissionSource,
        EpisodeManagement,
        DemographicsTags
    }
});
