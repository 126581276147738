import "./sortable-th.scss";

import { bindable, bindingMode, computedFrom, customElement, inject } from "aurelia-framework";

import nameOf from "../../../common/name-of";

@inject(Element)
@customElement("sortable-th")
export class SortableTh {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public sortBy: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public sortOrder: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public sortString: string;
    private _mouseupListener: EventListener;

    @computedFrom(nameOf<SortableTh>("sortBy"), nameOf<SortableTh>("sortOrder"), nameOf<SortableTh>("sortString"))
    public get iconClass() {
        if (this.sortBy == this.sortString) {
            return this.sortOrder == "asc" ? "fa-sort-up" : "fa-sort-down";
        } else {
            return "fa-sort";
        }
    }

    public constructor(private element: any) {
        this._mouseupListener = (e) => this._handleMouseUp(e);
    }

    public attached() {
        this.element.addEventListener("click", this._mouseupListener);
    }

    public detached() {
        this.element.removeEventListener("click", this._mouseupListener);
        document.removeEventListener("mouseup", this._mouseupListener);
    }

    private _handleMouseUp(e: Event) {
        if (this.sortBy == this.sortString) {
            this._toggleSortOrder();
        } else {
            this.sortBy = this.sortString;
            this.sortOrder = "asc";
        }
    }

    private _toggleSortOrder() {
        if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
        } else {
            this.sortOrder = "asc";
        }
    }
}
