<template>
    <div id="Visit_Details">
        <VisitDetailsHomeHealth
            v-if="options.Application == applicationEnum.AgencyCore"
            :visit-details="options"
            :is-saving="isSaving"
            :reassign="reassignVisit"
            :cancel="cancel"
            :ok="ok"
        />
        <VisitDetailsHomeCare
            v-if="options.Application == applicationEnum.HomeCare"
            :visit-details="options"
            v-model:is-saving="isSaving"
            :reassign="reassignVisit"
            :cancel="cancel"
            :ok="ok"
        />
        <VisitDetailsHosPal
            v-if="
                options.Application == applicationEnum.AxxessHospiceFE ||
                options.Application == applicationEnum.AxxessPalliative
            "
            :visit-details="options"
            v-model:is-saving="isSaving"
            :employee-list="employeeList"
            :reassign="reassignVisit"
            :cancel="cancel"
            :ok="ok"
        />
    </div>
</template>

<script src="./VisitDetails"></script>
