import "./multiselect-dropdown.scss";

import { Disposable } from "aurelia-binding";
import { autoinject, bindable, BindingEngine, bindingMode, customElement, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import jquery from "jquery";

import nameOf from "../../../common/name-of";
import { ConflictTypeEnum } from "../../../enums/enums";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

@customElement("multiselect-dropdown")
@autoinject
export class MultiselectDropdown {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    // Message shown when no option selected
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "Select Options";
    // String appended to message when multiple options selected
    @bindable({ defaultBindingMode: bindingMode.toView })
    public name: string = "Options";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasActions: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasCustomView: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public activeClasses: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameOf<MultiselectDropdown>("optionsChanged")
    })
    public options: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameOf<MultiselectDropdown>("selectedItemsChanged")
    })
    public selectedItems: string[] | number[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showIcons: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public id: string;
    private _bindingEngine: BindingEngine;
    public dropdownElement: HTMLElement;
    public dropdown: HTMLElement;
    public selectedItemsSubscription: Disposable;
    public message: string = "";
    public isActive: boolean = false;

    public constructor(bindingEngine: BindingEngine) {
        this._bindingEngine = bindingEngine;
    }

    public async attached() {
        this.initSelectedItemsSubscription();
        jquery(this.dropdownElement).on("click.bs.dropdown", (e) => {
            if (e.target.nodeName != "BUTTON") {
                e.stopPropagation();
            }
        });
        jquery(this.dropdown).on("hide.bs.dropdown", (e) => {
            this.toggleActiveClasses(false);
        });

        if (this.validation?.required) {
            this.initValidation();
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: MultiselectDropdown) => x.selectedItems)
            .minItems(1)
            .withMessage(`${this.validation.message}`)
            .on(this);
    }

    public getView(rowItem: ITypeaheadOptions) {
        let result: string = "";
        switch (rowItem.type) {
            case "conflict-type":
                if (Number(rowItem.value) == ConflictTypeEnum.NoConflict) {
                    result = `<i class="fa fa-check-circle icon-green"></i>`;
                } else if (Number(rowItem.value) == ConflictTypeEnum.PartialConflict) {
                    result = `<i class="fa fa-exclamation-circle icon-gold"></i>`;
                } else if (Number(rowItem.value) == ConflictTypeEnum.FullConflict) {
                    result = `<i class="fa fa-times-circle icon-red"></i>`;
                }
                break;
        }
        return result;
    }

    private initSelectedItemsSubscription() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.dispose();
        }
        this.selectedItemsSubscription = this._bindingEngine
            .collectionObserver(this.selectedItems)
            .subscribe(() => this.updateMessage());
    }

    private updateMessage() {
        if (!this.selectedItems || this.selectedItems.length === 0) {
            this.message = this.placeholder;
        } else if (this.selectedItems.length === 1) {
            let selectedOption = this.options.find((option) => option.value === this.selectedItems[0]);
            this.message = selectedOption?.name;
        } else if (this.selectedItems.length > 1) {
            // since name is the unique id for the component, don't show in label
            this.message = `${this.selectedItems.length} Selected`;
        }
    }

    public toggleActiveClasses(value: boolean) {
        this.isActive = value;
    }

    public selectedItemsChanged() {
        // Subscription is lost after binding
        this.initSelectedItemsSubscription();
        this.updateMessage();
    }

    public optionsChanged() {
        this.updateMessage();
    }

    public selectAll() {
        this.selectedItems.length = 0;
        (this.selectedItems as any[]).push(...this.options.map((option) => option.value));
    }

    public deselectAll() {
        this.selectedItems.splice(0, this.selectedItems.length);
    }

    public detached() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.dispose();
        }
    }
}
