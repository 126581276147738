import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { Task } from "../../../../models/task.model";
import { ITask } from "../../../../resources-vue/vue-interfaces/i-task";
import { TaskService } from "../../../../services/task.service";
import { AnalyticsService } from "../../../../services/analytics.service";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { NavigationService } from "../../../../services/navigation.service";
import { getApplicationId } from "../../../../common/vue-helpers/modifiers/application-modifiers";
import { formatDate } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import { useLoadingIndicator } from "../../../Common/LoadingIndicator/store/useLoadingIndicator";

import CardTemplate from "../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";
import ProductPill from "../../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";

export default defineComponent({
    components: {
        CardTemplate,
        ProductPill
    },
    props: {
        label: {
            default: "",
            type: String
        }
    },

    data() {
        return {
            _taskService: null,
            _permissionManager: null,
            _analyticsService: null,
            _navigate: null,
            tasksList: [] as Task[],
            isLoading: false,
            hasError: false,
            tooltip: `This section displays all visits and tasks returned by QA.`,
            logCategory: "Task-Alerts",
            userOs: "",
            isAscending: true as boolean,
            formatDate,
            getApplicationId
        };
    },
    created() {
        this._taskService = Container.instance.get(TaskService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._navigate = Container.instance.get(NavigationService);
        this._analyticsService = Container.instance.get(AnalyticsService);
    },
    async mounted() {
        this.userOs = window.sessionStorage.getItem("userOs");
        await this.getReturnedByQATasks();
    },
    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        async getReturnedByQATasks() {
            try {
                this.hasError = false;
                this.isLoading = true;
                this.tasksList = await this._taskService.getAlerts();

                let incidentsTaskList: any[] = await this._taskService.getIncidentAlerts();
                let mappedIncidentTaskList: any[] = incidentsTaskList.map((incidentTask) => ({
                    ...incidentTask,
                    patientFullName: incidentTask.PatientName,
                    BranchName: incidentTask.AgencyName,
                    VisitName: incidentTask.Data.IncidentType,
                    IsIncidentLog: true
                }));

                this.tasksList = this.tasksList.concat(mappedIncidentTaskList);
                this.tasksList = this._taskService.sortTasks(this.tasksList, "Date", "date");
            } catch (e) {
                console.error(e);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        async viewPatient(task: ITask) {
            const isClinician = await this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView,
                task.AgencyId
            );
            const analyticsOptions = {
                category: this.logCategory,
                action: "View-Patient-Chart"
            };
            const navParams = {
                applicationId: task.Application,
                agencyId: task.AgencyId,
                userId: task.UserId,
                patientId: task.PatientId,
                isClinician
            };

            try {
                this.showLoading(true);
                const response = await this._navigate.navigateToPatient(navParams);
                this._navigate.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        },

        async startTask(task: ITask) {
            try {
                this.showLoading(true);
                if (task.IsIncidentLog) {
                    const analyticsOptions = {
                        category: this.logCategory,
                        action: "Open-Incident-Log-Visit"
                    };
                    const navParams = {
                        agencyId: task.AgencyId,
                        userId: task.UserId,
                        applicationId: task.Application,
                        patientId: task.PatientId,
                        id: task.Id
                    };

                    let response = await this._navigate.navigateToIncidentLog(navParams);
                    this._navigate.redirect(response, analyticsOptions);
                } else {
                    const analyticsOptions = {
                        category: this.logCategory,
                        action: "Open-Alerts-Visit"
                    };
                    const navParams = {
                        agencyId: task.AgencyId,
                        userId: task.UserId,
                        applicationId: task.Application,
                        isClinician: true,
                        patientId: task.PatientId,
                        taskId: task.Id,
                        episodeId: task.EpisodeId,
                        locationId: task.LocationId,
                        status: task.PatientStatus ? Number(task.PatientStatus) : null,
                        payerId: task.PayerId,
                        Id: task.Id
                    };

                    let response = await this._navigate.navigateToTask(navParams);
                    this._navigate.redirect(response, analyticsOptions);
                }
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        },

        sortByScheduleDate(): void {
            this.isAscending = !this.isAscending;
            this.tasksList = this._taskService.sortTasks(this.tasksList, "Date", "date", !this.isAscending);
            this._analyticsService.logEvent({
                category: this.logCategory,
                action: `${this.isAscending ? "Ascending" : "Descending"}-Sort`
            });
        }
    }
});
