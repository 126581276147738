import { defineComponent, PropType } from "vue";

import type { IMessageItem } from "../../../../../resources-vue/vue-interfaces/messaging-service/i-message";
import { formatMessageTime } from "../../../../../common/vue-helpers/modifiers/format-message";
import { markdownToHtml } from "../../../../../common/vue-helpers/modifiers/markdown-to-html-modifier";
import { sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import eventBus from "../../../../../utilities/eventBus";

export default defineComponent({
    props: {
        message: { type: Object as PropType<IMessageItem>, default: {} as IMessageItem },
        renderAsset: { type: Function },
        retryMessage: { type: Function }
    },
    data() {
        return {
            formatMessageTime,
            markdownToHtml,
            sanitizeMarkup
        };
    },

    methods: {
        joinRoom(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (target.tagName === "BUTTON" && target.dataset.id) {
                eventBus.emit("joinRoomClicked", target.dataset.id);
            }
        }
    }
});
