import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";

import { Patient } from "../../../models/patient.model";
import { PatientService } from "../../../services/patient.service";
import { NavigationService } from "../../../services/navigation.service";
import { formatPhone, formatDate } from "../../../common/vue-helpers/modifiers/value-modifier";
import { getApplicationId } from "../../../common/vue-helpers/modifiers/application-modifiers";
import { useLoadingIndicator } from "../LoadingIndicator/store/useLoadingIndicator";

import ScrollPagination from "../../../resources-vue/vue-elements/ScrollPagination/ScrollPagination.vue";
import ProductPill from "../../../resources-vue/vue-elements/ProductPill/ProductPill.vue";

export default defineComponent({
    components: {
        ScrollPagination,
        ProductPill
    },
    props: {
        showPatients: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            _navigate: null,
            _patientService: null,
            patientsList: [] as Array<Patient>,
            pageSize: 20,
            isLoading: true,
            pageNumber: 1,
            allPagesLoaded: false,
            logCategory: "Patient-List",
            hasError: false,
            page: null,
            userOs: "",
            formatPhone,
            formatDate,
            getApplicationId,
            tooltip: `<p>This section displays all patients currently assigned to you.</p>
                <ul>
                    <li>Click on 'View Chart' to view more patient details.</li>
                </ul>`
        };
    },

    created() {
        this._navigate = Container.instance.get(NavigationService);
        this._patientService = Container.instance.get(PatientService);
    },
    async mounted() {
        this.userOs = window.sessionStorage.getItem("userOs");
        await this.getData();
    },
    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        getNewPatientsList(newPatients: any) {
            let visiblePatients = this.patientsList?.map((patient: any) => patient.Id);
            let uniqueNewPatients = newPatients?.filter((patient: any) => !visiblePatients?.includes(patient.Id));
            return uniqueNewPatients.length > 0 ? this.patientsList.concat(uniqueNewPatients) : this.patientsList;
        },
        async getData() {
            try {
                this.hasError = false;
                this.isLoading = true;
                let receivedList = await this._patientService.findAll({
                    page: this.pageNumber,
                    pageSize: 10
                });
                this.page = receivedList;
                if (receivedList.items.length > 0) {
                    this.patientsList = this.getNewPatientsList(receivedList.items);
                    this.patientsList = this.patientsList.map((item: any) => new Patient(item));
                }
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },
        closePatients() {
            this.$emit("update:showPatients", false);
        },
        pageNumberUpdated(pageToLoad: number) {
            if (!pageToLoad) {
                return;
            }
            if (pageToLoad != this.pageNumber) {
                this.pageNumber = pageToLoad;
            } else {
                return;
            }
            this.allPagesLoaded = this.pageNumber > this.page?.pageCount;
            if (!this.allPagesLoaded) {
                this.getData();
            }
        },

        async viewPatient(patient: any) {
            let analyticsOptions = {
                category: this.logCategory,
                action: "View-Patient-Chart-From-Patient-List"
            };
            let navParams = {
                agencyId: patient.AgencyId,
                applicationId: patient.Application,
                userId: patient.UserId,
                isClinician: true,
                patientId: patient.Id
            };
            try {
                this.showLoading(true);
                let response = await this._navigate.navigateToPatient(navParams);
                this._navigate.redirect(response, analyticsOptions);
            } catch (e) {
                console.error(e);
                this.showLoading(false);
            }
        }
    }
});
