import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { PatientPayor } from "../../../../../../resources-vue/vue-models/patient-payor";
import { ApplicationEnum, PayerSequenceCodes, PayerTypes, RelationshipToInsured } from "../../../../../../enums/enums";
import { CentralIntakeService } from "../../../../../../services/central-intake.service";
import { ITypeaheadOptions } from "../../../../../../interfaces/i-typeahead";
import { formatDateInput } from "../../../../../../common/vue-helpers/modifiers/value-modifier";
import { enumToMap } from "../../../../../../common/vue-helpers/modifiers/enum-modifiers";

import FormSection from "../../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import DatePicker from "../../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import NonMedicarePayor from "../NonMedicarePayor/NonMedicarePayor.vue";
import MedicarePayor from "../MedicarePayor/MedicarePayor.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput,
        DatePicker,
        NonMedicarePayor,
        MedicarePayor
    },

    validations() {
        return {
            payer: {
                agencyPayorName: { required },
                effectiveDate: { required },
                type: { required }
            }
        };
    },
    props: {
        providerId: {
            type: String
        },
        payer: {
            type: PatientPayor,
            default: new PatientPayor()
        },
        isLoading: {
            type: Boolean
        },
        referral: {
            type: Object
        },
        isAddForm: {
            type: Boolean,
            default: false
        },
        medicareIds: {
            type: Array
        }
    },

    data(vm) {
        return {
            _centralIntakeService: null,
            selectedPayor: null,
            initialValue: null,
            charityId: "",
            defaultMedicareIds: [],
            roomAndBoardPayors: [],
            showRoomAndBoardWarning: false,
            payerSequenceCodeEnum: PayerSequenceCodes,
            relationshipToInsuredEnum: RelationshipToInsured,
            payorTypeEnum: PayerTypes,
            roomAndBoardTypeValue: PayerTypes.enum.RoomAndBoard,
            formatDateInput,
            enumToMap,
            v$: useVuelidate()
        };
    },

    created() {
        if (!this.isAddForm) {
            this.initialValue = {
                name: this.payer.agencyPayorName,
                value: this.payer.agencyPayorId
            };
        }
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
    },
    computed: {
        isApplicationPalliative(): boolean {
            return this.referral.application == ApplicationEnum.AxxessPalliative;
        },
        showSubscriberName() {
            if (!this.relationshipToInsuredEnum || !this.payer?.relationshipToInsured) {
                return false;
            }
            let isRelationshipSelf = this.relationshipToInsuredEnum.enum.Self == this.payer.relationshipToInsured;
            if (!this.payer.isMedicare && !isRelationshipSelf) {
                return true;
            }
            return false;
        }
    },

    watch: {
        selectedPayor(newValue, oldValue) {
            this.selectedPayorChanged(newValue, oldValue);
        },
        payer: {
            handler() {
                this.payerChanged();
            },
            deep: true
        }
    },

    methods: {
        async getPayors(filter: string, limit: number) {
            let response = await this._centralIntakeService.getPayorsByName({
                name: filter,
                includeCharityPayor: true,
                providerIds: [this.providerId],
                application: this.referral.application,
                agencyId: this.referral.accountId,
                accountId: this.referral.accountId
            });
            let charityPayor = response.find((payor: any) => payor.isCharityPayor);
            let medicarePayers = response.filter((payor: any) => payor.isMedicarePayor);
            this.roomAndBoardPayors = response.filter((payor: any) => !!payor.roomAndBoardBillAsType);
            if (charityPayor) {
                this.charityId = charityPayor.id;
            }
            if (medicarePayers?.length > 0) {
                this.defaultMedicareIds = medicarePayers.map((payer: any) => payer.id);
            }
            if (response) {
                return response.map((payor: any) => ({ name: payor.name, value: payor.id }));
            } else {
                console.error("Unable to Fetch");
                return [];
            }
        },
        payerChanged() {
            if (this.payer?.isCharity) {
                this.charityId = this.payer.agencyPayorId;
            }
            if (this.payer?.isMedicare) {
                this.defaultMedicareIds = [this.payer.agencyPayorId];
            }
            if (!!this.payer?.billAsType) {
                this.roomAndBoardPayors = [
                    {
                        name: this.payer.agencyPayorName,
                        id: this.payer.agencyPayorId,
                        isMedicarePayor: this.payer.isMedicare,
                        isCharityPayor: this.payer.isMedicare,
                        roomAndBoardBillAsType: this.payer.billAsType
                    }
                ];
            }
            if (this.payer?.agencyPayorId) {
                this.selectedPayor = {
                    name: this.payer.agencyPayorName,
                    value: this.payer.agencyPayorId
                };
            } else {
                this.selectedPayor = {
                    name: "",
                    value: null
                };
            }
        },
        selectedPayorChanged(newPayor: ITypeaheadOptions, oldPayor: ITypeaheadOptions): void {
            if (!this.payer) {
                return;
            }
            if (newPayor) {
                this.payer.agencyPayorName = newPayor.name;
                this.payer.agencyPayorId = newPayor.value;
            } else {
                this.payer.agencyPayorName = "";
                this.payer.agencyPayorId = "";
            }
            this.payer.isCharity = !!this.payer.agencyPayorId ? this.payer.agencyPayorId === this.charityId : false;
            this.updatePayor();
            this.checkRoomAndBoard();
        },
        updatePayor() {
            // TODO: remove medicare ID
            this.payer.isMedicare = this.medicareIds.indexOf(this.payer.agencyPayorId) > -1;
            let selectedRoomAndBoard = this.roomAndBoardPayors.find((payor) => payor.id === this.payer.agencyPayorId);
            if (this.payer.type === this.roomAndBoardTypeValue && !!selectedRoomAndBoard) {
                this.payer.billAsType = selectedRoomAndBoard.roomAndBoardBillAsType;
            }
            if (this.payer.isMedicare) {
                this.payer.relationshipToInsured = 2;
            }
        },
        checkRoomAndBoard() {
            let roomAndBoardPayorIds = this.roomAndBoardPayors.map((payor) => payor.id);
            if (!this.selectedPayor?.value) {
                this.showRoomAndBoardWarning = false;
                return;
            }
            if (
                this.payer.type === this.roomAndBoardTypeValue &&
                roomAndBoardPayorIds.indexOf(this.payer.agencyPayorId) == -1
            ) {
                this.showRoomAndBoardWarning = true;
            } else {
                this.showRoomAndBoardWarning = false;
            }
        },
        payerTypeUpdated() {
            // Set time out to let the binding of payer type available
            setTimeout(() => {
                this.updatePayor();
                this.checkRoomAndBoard();
            }, 0);
        },
        hasDeductibleMetChanged(value: boolean) {
            this.payer.deductibleMet = value;
        },
        outOfPocketMaxMetChanged(value: boolean) {
            this.payer.isOutOfPocketMaxMet = value;
        },
        requireAuthorizationChanged(value: boolean) {
            this.payer.requirePriorAuthorization = value;
        },
        selectedPayorChangedd(data: any) {
            this.selectedPayor = data;
        },
        changeEffectiveDate(date: any) {
            this.payer.effectiveDate = date;
        },
        medicareDateChange(date: any) {
            this.payer.medicarePartAEffectiveDate = date;
        },
        handleDateChange(e: any) {
            this.payer.effectiveDate = e.target.value;
        }
    }
});
