<template>
    <div class="input-group">
        <slot></slot>
        <div class="input-group-addon input-group-append">
            <div class="input-group-text">
                <i class="fa fa-clock"></i>
            </div>
        </div>
    </div>
</template>

<style src="./TimePicker.scss"></style>
<script src="./TimePicker.ts"></script>
