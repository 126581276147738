import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum GenderEnum {
    Male = 1 as any,
    Female = 2 as any,
    Unknown = 3 as any
}
class GenderClass extends EnumWithDescription<GenderEnum> {
    public readonly enum = GenderEnum;
    public readonly enumName: string = "GenderEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<GenderEnum>> = new Map([
        [
            "Male",
            {
                value: GenderEnum.Male,
                name: "Male"
            }
        ],
        [
            "Female",
            {
                value: GenderEnum.Female,
                name: "Female"
            }
        ],
        [
            "Unknown",
            {
                value: GenderEnum.Unknown,
                name: "Unknown"
            }
        ]
    ]);
}

export const Gender = new GenderClass();
