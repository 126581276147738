import { autoinject, bindable, bindingMode, containerless, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";

import nameOf from "../../../common/name-of";
import { ApplicationEnum } from "../../../enums/enums";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { Template } from "../../../models/template";
import { TemplateService } from "../../../services/template-service";

@autoinject
@containerless
export class CommentInput {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public maxLength: number = 65535;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public inputName: string = "comments";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public customValidation: IValidateCustomElement;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public application: ApplicationEnum;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public showTemplateTypeahead: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public showTemplateDropdown: boolean = false;
    @observable({ changeHandler: nameOf<CommentInput>("selectedTemplateChanged") })
    public selectedTemplate: ITypeaheadOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public classList: { input: string; textarea: string } = { input: "w-75", textarea: "" };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public commentNonAdmitValidation: IValidateCustomElement = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public commentRequired: boolean = false;
    private _templateService: TemplateService;
    public isError: boolean = false;
    public isLoadingTemplate: boolean = false;
    public maxCharLimit: number = 65535;
    public templates: Template[] = [];

    public constructor(templatesService: TemplateService) {
        this._templateService = templatesService;
    }

    public attached() {
        if (!this.commentNonAdmitValidation) {
            this.initValidation();
        } else {
            ValidationRules.ensure((x: CommentInput) => x.result)
                .required()
                .when(() => this.commentRequired)
                .withMessage(this.commentNonAdmitValidation.message)
                .on(this);
        }
    }

    private initValidation() {
        if (this.customValidation?.required) {
            ValidationRules.ensure((x: CommentInput) => x.result)
                .satisfies((value, object) => {
                    return !/\*+/.test(value);
                })
                .withMessage("Replace asterisks with individualized patient information.")
                .required()
                .withMessage(this.customValidation.message)
                .on(this);
        } else {
            ValidationRules.ensure((x: CommentInput) => x.result)
                .satisfies((value, object) => {
                    return !/\*+/.test(value);
                })
                .withMessage("Replace asterisks with individualized patient information.")
                .on(this);
        }
        if (!(this.validation?.length > 0)) {
            return;
        }
        // INFO: Yes you need that string interpolation! You remove it you break the application
        // Aurelia validation expects a string for message (not a variable)
        this.validation.forEach((rule: any) => {
            if (rule.required) {
                ValidationRules.ensure((x: CommentInput) => x.result)
                    .required()
                    .withMessage(`${rule.message}`)
                    .on(this);
            }
            if (rule.matches) {
                ValidationRules.ensure((x: CommentInput) => x.result)
                    .matches(new RegExp(rule.satisfies))
                    .withMessage(`${rule.message}`)
                    .on(this);
            }
        });
    }

    public async bind() {
        this.getTemplateList("", 10);
    }

    public async getTemplateList(term: string, limit: number = 10) {
        try {
            let templates = await this._templateService.getTemplateByPage({
                term: term,
                page: 1,
                pageLength: limit,
                application: this.application
            });
            this.templates = templates.items;
            return this.templates;
        } catch (e) {
            throw e;
        }
    }

    public async selectedTemplateChanged(newValue: Template) {
        let selectedTemplate = newValue;
        if (selectedTemplate?.id) {
            let template = this.templates.find((item) => item.id == selectedTemplate.id);
            if (this.result?.length > 0) {
                this.result += `\n\n${template.description}`;
            } else {
                this.result = template.description;
            }
            this.selectedTemplate = undefined;
        }
    }
}
