import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { Ethnicity, Race } from "./enum";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    computed: {
        ...mapState(useEditReferralHH, ["referral", "referralOptions", "currentApplication"])
    },

    methods: {
        handleEthnicity(value: string) {
            if (this.referral.Demographics.EthnicitiesOnlyCollection.includes(value)) {
                this.referral.Demographics.EthnicitiesOnlyCollection =
                    this.referral.Demographics.EthnicitiesOnlyCollection.filter((val: any) => val !== value);
                let temp = this.referral.Demographics.EthnicitiesOnly.split(";").filter((val: string) => val !== value);
                let temp2 = temp.join(";");
                this.referral.Demographics.EthnicitiesOnly = temp2;
            } else {
                this.referral.Demographics.EthnicitiesOnlyCollection.push(value);
                if (this.referral.Demographics.EthnicitiesOnly === null) {
                    this.referral.Demographics.EthnicitiesOnly = "";
                    this.referral.Demographics.EthnicitiesOnly =
                        this.referral.Demographics.EthnicitiesOnly.concat(value);
                } else {
                    this.referral.Demographics.EthnicitiesOnly.concat(`;${value}`);
                }
            }
        },
        isEthnicityDisabled(value: string) {
            if (value === "0") {
                return (
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Mexican) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Puerto) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Cuban) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Another) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Declines)
                );
            } else if (value === "1") {
                return (
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Hispanic) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Declines)
                );
            } else if (value === "2") {
                return (
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Hispanic) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Declines)
                );
            } else if (value === "3") {
                return (
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Hispanic) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Declines)
                );
            } else if (value === "4") {
                return (
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Hispanic) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Declines)
                );
            } else if (value === "5") {
                return this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Declines);
            } else if (value === "6") {
                return (
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Hispanic) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Mexican) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Puerto) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Cuban) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Another) ||
                    this.referral.Demographics.EthnicitiesOnlyCollection.includes(Ethnicity.Unable)
                );
            } else {
                return true;
            }
        },
        handleRace(value: string) {
            if (this.referral.Demographics.RacesCollection.includes(value)) {
                this.referral.Demographics.RacesCollection = this.referral.Demographics.RacesCollection.filter(
                    (val: string) => val !== value
                );
                let temp = this.referral.Demographics.Races.split(";").filter((val: string) => val !== value);
                let temp2 = temp.join(";");
                this.referral.Demographics.Races = temp2;
            } else {
                this.referral.Demographics.RacesCollection.push(value);
                if (this.referral.Demographics.Races === null) {
                    this.referral.Demographics.Races = "";
                    this.referral.Demographics.Races = this.referral.Demographics.Races.concat(value);
                } else {
                    this.referral.Demographics.Races.concat(`;${value}`);
                }
            }
        },
        isRaceDisabled(value: string) {
            if (value === Race.Unable) {
                return this.referral.Demographics.RacesCollection.includes(Race.Declines);
            } else if (value === Race.Declines) {
                return (
                    this.referral.Demographics.RacesCollection.includes(Race.White) ||
                    this.referral.Demographics.RacesCollection.includes(Race.AfricanAmerican) ||
                    this.referral.Demographics.RacesCollection.includes(Race.AmericanIndianAlaskaNative) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Asian_Indian) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Chinese) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Filipino) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Japanese) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Korean) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Vietnamese) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Other_Asian) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Native_Hawaiian) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Guamanian_Chamorro) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Samoan) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Other) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Unable) ||
                    this.referral.Demographics.RacesCollection.includes(Race.None)
                );
            } else if (value === Race.None) {
                return (
                    this.referral.Demographics.RacesCollection.includes(Race.White) ||
                    this.referral.Demographics.RacesCollection.includes(Race.AfricanAmerican) ||
                    this.referral.Demographics.RacesCollection.includes(Race.AmericanIndianAlaskaNative) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Asian_Indian) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Chinese) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Filipino) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Japanese) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Korean) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Vietnamese) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Other_Asian) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Native_Hawaiian) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Guamanian_Chamorro) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Samoan) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Other) ||
                    this.referral.Demographics.RacesCollection.includes(Race.Declines)
                );
            } else {
                return (
                    this.referral.Demographics.RacesCollection.includes(Race.Declines) ||
                    this.referral.Demographics.RacesCollection.includes(Race.None)
                );
            }
        }
    }
});
