import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";

import type {
    IDeleteReturnReason,
    IGetReturnReasons,
    IGetTaskPayrollDetails,
    IReasonCode,
    ITaskPayor,
    ITaskPayrollDetails
} from "../../../../../resources-vue/vue-interfaces/i-task";
import { TaskService } from "../../../../../services/task.service";
import { ToastrService } from "../../../../../services/toastr.service";
import { PhysiciansService } from "../../../../../services/physician-service";
import { ITaskDetailsPayload } from "../../../../../resources-vue/vue-interfaces/i-task-details";
import type { IEmployeeDetails } from "../../../../../resources-vue/vue-interfaces/i-homecare-reassign";
import { ApplicationEnum } from "../../../../../enums/enums";
import { formatDateInput, formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import TimePicker from "../../../../../resources-vue/vue-custom-elements/TimePicker/TimePicker.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import MidnightSplitTable from "../../../../../resources-vue/vue-elements/MidnightSplitTable/MidnightSplitTable.vue";
import PayrollDetailsTable from "../../../../../resources-vue/vue-elements/PayrollDetailsTable/PayrollDetailsTable.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        TimePicker,
        TypeaheadInput,
        MidnightSplitTable,
        PayrollDetailsTable,
        CommentInput
    },

    props: {
        visitDetails: {
            type: Object
        },
        isSaving: {
            type: Boolean,
            default: false
        },
        reassign: {
            type: Function
        },
        cancel: {
            type: Function
        },
        ok: {
            type: Function
        }
    },

    data(vm) {
        return {
            payorList: [] as ITaskPayor[],
            _taskService: null,
            _toastrService: null,
            _physicianService: null,
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string },
            taskDetails: null,
            isDeleteRequested: false as boolean,
            isDeleteInProgress: false as boolean,
            returnReasons: [] as IReasonCode[],
            payrollDetails: [] as ITaskPayrollDetails[],
            isSavingReason: false as boolean,
            showPhyicianField: false as boolean,
            showAssignedToList: false as boolean,
            assignedToName: "" as string,
            scheduledStartTime: "" as string,
            scheduledEndTime: "" as string,
            visitTimeIn: "" as string,
            visitTimeOut: "" as string,
            isepisodeSelectionChecked: false as boolean,
            physician: null,
            hasPayrollDetails: true as boolean,
            payrollDetailsLoading: true as boolean,
            isLoading: false as boolean,
            employeeList: [] as Array<IEmployeeDetails>,
            formatDateInput,
            formatDate
        };
    },

    async created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._physicianService = Container.instance.get(PhysiciansService);
        Object.assign(this.visitDetails, {});
        try {
            this.isLoading = true;
            const payload: ITaskDetailsPayload = {
                application: this.visitDetails.Application,
                agencyId: this.visitDetails.AgencyId,
                eventId: this.visitDetails.Id,
                episodeId: this.visitDetails.EpisodeId,
                patientId: this.visitDetails.PatientId
            };
            this.taskDetails = await this._taskService.getHHVisitDetails(payload);
            this.scheduledStartTime = moment(this.taskDetails.scheduleEvent.eventStartTime).format("hh:mm A");
            this.scheduledEndTime = moment(this.taskDetails.scheduleEvent.eventEndTime).format("hh:mm A");
            this.visitTimeIn = moment(this.taskDetails.scheduleEvent.visitStartTime).format("hh:mm A");
            this.visitTimeOut = moment(this.taskDetails.scheduleEvent.visitEndTime).format("hh:mm A");
            this.assignedToName = this.taskDetails.scheduleEvent.userName;
            if (this.taskDetails.scheduleEvent.isOrder || this.taskDetails.scheduleEvent.sendAsOrder) {
                this.showPhyicianField = true;
                this.physician = {
                    name: this.taskDetails.scheduleEvent.physicianName,
                    value: this.taskDetails.scheduleEvent.physicianId
                };
            }
            if (
                this.visitDetails.Status === "Reopened" ||
                this.visitDetails.Status === "Not Yet Started" ||
                this.visitDetails.Status === "Saved"
            ) {
                const employees = await this._taskService.homecareEmployeePreference({
                    PatientId: this.visitDetails.PatientId,
                    AgencyId: this.visitDetails.AgencyId,
                    LocationId: this.visitDetails.LocationId,
                    StartDate: this.visitDetails.StartDate,
                    EndDate: this.visitDetails.EndDate,
                    Application: ApplicationEnum.HomeCare
                });
                this.employeeList = employees;
                this.showAssignedToList = true;
            }
            const payors = await this._taskService.getTaskPayorsList({
                patientId: this.visitDetails.PatientId,
                agencyId: this.visitDetails.AgencyId,
                startDate: this.visitDetails.StartDate,
                endDate: this.visitDetails.EndDate,
                application: ApplicationEnum.HomeCare
            });
            this.payorList = payors.data;
            await this.getReturnReasonList();
            await this.getPayrollDetails();
        } catch (error) {
            console.log(error);
        } finally {
            this.isLoading = false;
        }
    },

    computed: {
        getCarePeriod(): string {
            let startDate = "";
            let endDate = "";
            if (this.taskDetails && this.taskDetails.scheduleEvent) {
                startDate = moment(this.taskDetails.scheduleEvent.startDate).format("MM/DD/YYYY");
                const endDateYear = moment(this.taskDetails.scheduleEvent.endDate).year();
                if (endDateYear == 9999) {
                    endDate = "Open";
                } else {
                    endDate = moment(this.taskDetails.scheduleEvent.endDate).format("MM/DD/YYYY");
                }
            }
            const carePeriod = startDate + " - " + endDate;
            return carePeriod;
        }
    },

    watch: {
        scheduledStartTime(newValue) {
            this.scheduledStartTimeChanged(newValue);
        },
        scheduledEndTime(newValue) {
            this.scheduledEndTimeChanged(newValue);
        },
        visitTimeIn(newValue) {
            this.visitTimeInChanged(newValue);
        },
        visitTimeOut(newValue) {
            this.visitTimeOutChanged(newValue);
        }
    },
    methods: {
        handleDateChange(date: any, name: string) {
            if (name === "eventStartTime") {
                this.taskDetails.scheduleEvent.eventStartTime = date;
            }
            if (name === "eventEndTime") {
                this.taskDetails.scheduleEvent.eventEndTime = date;
            }
            if (name === "visitStartTime") {
                this.taskDetails.scheduleEvent.visitStartTime = date;
            }
            if (name === "visitEndTime") {
                this.taskDetails.scheduleEvent.visitEndTime = date;
            }
        },
        changeVisitTimeOut(time: any) {
            this.visitTimeOut = time;
        },
        changeVisitTimeIn(time: any) {
            this.visitTimeOut = time;
        },
        changeScheduleEndTime(time: any) {
            this.scheduledEndTime = time;
        },
        changeIsAllDay(time: any) {
            this.scheduledStartTime = time;
        },
        resultUpdatedAppended(value: string) {
            this.taskDetails.scheduleEvent.comments += value;
        },
        resultUpdated(value: string) {
            this.taskDetails.scheduleEvent.comments = value;
        },
        async getPhysicianList(filter: string, limit: number) {
            const data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                providerIds: [],
                application: this.visitDetails.Application,
                accountId: this.taskDetails.scheduleEvent.agencyId
            });
            return data.items?.map((item: any) => ({ value: item.id, name: item.name }));
        },
        physicianChanged(newValue: any) {
            if (newValue != null) {
                this.taskDetails.scheduleEvent.physicianId = newValue.value;
                this.taskDetails.scheduleEvent.physicianName = newValue.name;
            }
        },

        scheduledStartTimeChanged(newValue: string) {
            if (!!newValue && this.taskDetails) {
                const eventStartTime = moment(this.taskDetails.scheduleEvent.eventStartTime);
                this.taskDetails.scheduleEvent.eventStartTime = this.updateTime(newValue, eventStartTime);
            }
        },

        scheduledEndTimeChanged(newValue: string) {
            if (!!newValue && this.taskDetails) {
                const eventEndTime = moment(this.taskDetails.scheduleEvent.eventEndTime);
                this.taskDetails.scheduleEvent.eventEndTime = this.updateTime(newValue, eventEndTime);
            }
        },

        visitTimeInChanged(newValue: string) {
            if (!!newValue && this.taskDetails) {
                const visitStartTime = moment(this.taskDetails.scheduleEvent.visitStartTime);
                this.taskDetails.scheduleEvent.visitStartTime = this.updateTime(newValue, visitStartTime);
            }
        },

        visitTimeOutChanged(newValue: string) {
            if (!!newValue && this.taskDetails) {
                const visitEndTime = moment(this.taskDetails.scheduleEvent.visitEndTime);
                this.taskDetails.scheduleEvent.visitEndTime = this.updateTime(newValue, visitEndTime);
            }
        },

        updateTime(newTime: string, dateToUpdate: any) {
            const time24hour = moment(newTime, "hh:mm A").format("HH:mm");
            const hour = Number(time24hour.split(":")[0]);
            const minutes = Number(time24hour.split(":")[1]);
            const updatedDate = dateToUpdate
                .startOf("day")
                .set("hour", hour)
                .set("minute", minutes)
                .format("YYYY-MM-DDTHH:mm:ss");
            return updatedDate;
        },

        selectedEmployee(employeeId: string) {
            if (!!employeeId) {
                const employeeDetails = this.employeeList.find((emp) => {
                    return emp.Id == employeeId;
                });
                if (employeeDetails) {
                    this.taskDetails.scheduleEvent.userId = employeeDetails.Id;
                    this.taskDetails.scheduleEvent.userFirstName = employeeDetails.FirstName;
                    this.taskDetails.scheduleEvent.userLastName = employeeDetails.LastName;
                    this.taskDetails.scheduleEvent.userCredentials = [
                        employeeDetails.Credentials,
                        employeeDetails.CredentialsOther
                    ];
                }
            }
        },

        selectedPayor(payorId: string) {
            if (!!payorId) {
                const payorDetails = this.payorList.find((emp) => {
                    return emp.insuranceId == payorId;
                });
                if (payorDetails) {
                    this.taskDetails.scheduleEvent.payorId = payorDetails.insuranceId;
                    this.taskDetails.scheduleEvent.payer = payorDetails.name;
                }
            }
        },

        confirmDeleteReason() {
            this.isDeleteRequested = true;
        },

        cancelDeleteReason() {
            this.isDeleteRequested = false;
        },

        async deleteReason(id: number) {
            try {
                this.isDeleteInProgress = true;
                const deleteQuery: IDeleteReturnReason = {
                    application: this.visitDetails.Application,
                    id: id
                };
                await this._taskService.deleteReturnReasonHC(deleteQuery);
                this.getReturnReasonList();
                this._toastrService.success({
                    message: `Return Reason has been successfully deleted.`,
                    title: `Return Reason Deleted`
                });
            } catch (error) {
                console.log(error);
                this._toastrService.error({
                    title: `Error - Delete Return Reason`,
                    message: `There was a problem while deleting the Return Reason. Please try again.`
                });
                this.isDeleteInProgress = false;
            }
        },

        async getReturnReasonList() {
            const payload: IGetReturnReasons = {
                application: this.visitDetails.Application,
                agencyId: this.visitDetails.AgencyId,
                eventId: this.visitDetails.Id,
                episodeId: this.visitDetails.EpisodeId,
                patientId: this.visitDetails.PatientId,
                userId: this.visitDetails.UserId
            };
            const returnReasons = await this._taskService.getReturnReasonList(payload);
            this.returnReasons = returnReasons.data;
        },

        async getPayrollDetails() {
            this.payrollDetailsLoading = true;
            const payload: IGetTaskPayrollDetails = {
                application: this.visitDetails.Application,
                agencyId: this.visitDetails.AgencyId,
                taskId: this.taskDetails.scheduleEvent.eventId
            };
            const returnReasons = await this._taskService.getTaskPayrollDetails(payload);
            this.payrollDetails = returnReasons.data;
            if (returnReasons.isSuccess == false) {
                this.hasPayrollDetails = false;
            }
            this.payrollDetailsLoading = false;
        },

        async addReturnReason() {
            // TODO - To be done later
            // const payload: IGetReturnReasons = {
            //     application: this.visitDetails.Application,
            //     taskId: this.taskDetails.scheduleEvent.eventId
            // };
            // this.returnReasons = await this._taskService.getReturnReasonList(payload);
        },

        async updateVisitDetails() {
            if (!!this.taskDetails) {
                try {
                    this.$emit("update:isSaving", true);
                    this.taskDetails.scheduleEvent.application = this.visitDetails.Application;
                    this.taskDetails.scheduleEvent.eventDate = this.taskDetails.scheduleEvent.eventDate
                        ? moment(this.taskDetails.scheduleEvent.eventDate).format("MM/DD/YYYY")
                        : null;
                    this.taskDetails.scheduleEvent.visitDate = this.taskDetails.scheduleEvent.visitDate
                        ? moment(this.taskDetails.scheduleEvent.visitDate).format("MM/DD/YYYY")
                        : null;

                    this.taskDetails.scheduleEvent.status = this.taskDetails.scheduleEvent.scheduleStatus;
                    await this._taskService.updateHHVisitDetails(this.taskDetails.scheduleEvent);
                    this._toastrService.success({
                        title: `Visit Updated`,
                        message: `Visit has been successfully Updated.`
                    });
                    this.ok();
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Update Failed`,
                        message: `Updating Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.$emit("update:isSaving", false);
                }
            }
        }
    }
});
