
import { defineComponent } from "vue";
import { formatPhoneNumber } from "../../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    props: {
        eligibility: {
            type: Object
        }
    },
    data() {
        return {
            formatPhoneNumber
        };
    }
});
