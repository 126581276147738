import { defineComponent } from "vue";

import { PhysicianRequired } from "../../../../../resources-vue/vue-models/physician-required";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import CreatePhysicianRow from "./CreatePhysicianRow.vue";

export default defineComponent({
    components: {
        FormSection,
        CreatePhysicianRow
    },
    props: {
        referral: { type: Object, default: null }
    },
    mounted() {
        this.addPhysician();
    },
    methods: {
        addPhysician() {
            if (this.referral.physicianRequiredArray.length <= 4) {
                const physicianRequired: PhysicianRequired = new PhysicianRequired();
                this.referral.physicianRequiredArray.push(physicianRequired);
            }
        },
        removePhysician(index: number) {
            this.referral.physicianRequiredArray.splice(index, 1);
        },
        radioChanged(index: number) {
            let ref = (this.$refs.radio as any)[index];

            if (ref.checked == true) {
                this.referral.physicianRequiredArray.forEach((data: any, indexNumber: number) => {
                    (this.$refs.radio as any)[indexNumber].checked = false;
                    this.referral.physicianRequiredArray[indexNumber].isPrimary = false;
                });
                ref.checked = true;
                this.referral.physicianRequiredArray[index].isPrimary = true;
            }
        }
    }
});
