import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum HomecareAdmissionSourcesEnum {
    NonHealthCareFacility = 1 as any,
    ClinicOrPhysiciansOffice = 2 as any,
    TransferFromHospital = 4 as any,
    TransferFromSNF = 5 as any,
    TransferFromAnotherHealthcareFacility = 6 as any,
    CourtLawEnforcement = 8 as any,
    InformationNotAvailable = 9 as any,
    EmergencyRoom = 7 as any
}
class HomecareAdmissionSourcesClass extends EnumWithDescription<HomecareAdmissionSourcesEnum> {
    public readonly enum = HomecareAdmissionSourcesEnum;
    public readonly enumName: string = "HomecareAdmissionSourcesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<HomecareAdmissionSourcesEnum>> = new Map([
        [
            "NonHealthCareFacility",
            {
                value: HomecareAdmissionSourcesEnum.NonHealthCareFacility,
                name: "(1) Non-Health Care Facility Point of Origin"
            }
        ],
        [
            "ClinicOrPhysiciansOffice",
            {
                value: HomecareAdmissionSourcesEnum.ClinicOrPhysiciansOffice,
                name: "(2) Clinic or Physician's Office"
            }
        ],
        [
            "TransferFromHospital",
            {
                value: HomecareAdmissionSourcesEnum.TransferFromHospital,
                name: "(4) Transfer from Hospital"
            }
        ],
        [
            "TransferFromSNF",
            {
                value: HomecareAdmissionSourcesEnum.TransferFromSNF,
                name: "(5) Transfer from SNF"
            }
        ],
        [
            "TransferFromAnotherHealthcareFacility",
            {
                value: HomecareAdmissionSourcesEnum.TransferFromAnotherHealthcareFacility,
                name: "(6) Transfer from another Health Care Facility"
            }
        ],
        [
            "CourtLawEnforcement",
            {
                value: HomecareAdmissionSourcesEnum.CourtLawEnforcement,
                name: "(8) Court/Law Enforcement"
            }
        ],
        [
            "InformationNotAvailable",
            {
                value: HomecareAdmissionSourcesEnum.InformationNotAvailable,
                name: "(9) Information Not Available"
            }
        ],
        [
            "EmergencyRoom",
            {
                value: HomecareAdmissionSourcesEnum.EmergencyRoom,
                name: "(7) Emergency Room"
            }
        ]
    ]);
}

export const HomecareAdmissionSources = new HomecareAdmissionSourcesClass();
