import { ApplicationEnum } from "../enums/enums";
import type { IDisplayDiagnosis } from "../resources-vue/vue-interfaces/i-diagnosis";
import type { IPatientDme } from "../interfaces/i-get-dme";
import type { IPatientSupply } from "../resources-vue/vue-interfaces/i-get-supply";
import type { IPhoneNumber } from "../interfaces/i-phone-number";
import type { IGetPatientTag } from "../interfaces/i-providers-service";
import { Address } from "./address";
import { FuneralHome } from "./funeral-home";
import { PatientPayor } from "./patient-payor";

export class Patient {
    public id: string = null;
    public associatedTaskId?: string;
    public isReferral: boolean = null;
    public status: number = 0;
    public referralId: string = null;
    public application: ApplicationEnum;
    public accountId: string;
    /*------------------ Start of Demographics --------------------------*/
    public medicalRecordNumber: string = "";
    public firstName: string = "";
    public middleInitial: string = "";
    public lastName: string = "";
    public suffix: string = "";
    // enums default value should be 0 as they are non nullable
    public gender: number = 0;
    public dateOfBirth: string = "";
    public socialSecurityNumber: string = "";
    public veteran: number[] = [];
    public maritalStatus: number = 0;
    // locationId is branchId
    public locationId: string = null;
    public phoneNumbers: IPhoneNumber[] = [
        {
            number: "",
            extension: "",
            type: 0,
            country: null,
            isPrimary: true
        },
        {
            number: "",
            extension: "",
            type: 0,
            country: null,
            isPrimary: false
        }
    ];
    public email: string = "";
    public primaryAddress: Address = new Address();
    public visitAddress: Address = new Address();
    public mailingAddress: Address = new Address();
    public previousAddress: Address = new Address();
    public isMailingAddressSameAsPrimary: boolean = false;
    public isVisitAddressSameAsPrimary: boolean = true;
    public isPreviousAddress: boolean = true;
    public primaryAddressFacilityTypeId: string;
    public visitAddressFacilityTypeId: string;
    public mailingAddressFacilityTypeId: string;
    public previousAddressFacilityTypeId: string;
    public hasPreviousAddress: boolean = false;
    public ethnicity: number[] = [];
    public race: number[] = [];
    public ethnicityOnly: number = 0;
    public declineToSpecifyEthnicity: boolean = false;
    public declineToSpecifyRace: boolean = false;
    /* Language Part */
    public primaryLanguage: string;
    public languages: string[] = [];
    public auxiliaryAids: string;
    public otherInterpreter: string;
    public interpreter: number[] = [];
    public tagIds: string[] = [];
    public tags: IGetPatientTag[] = [];
    public locationOfPatient: string = "";
    public patientFuneralHome: FuneralHome = new FuneralHome();
    // TODO: Not used. Confirm and delete
    public isDoNotResuscitate: boolean = null;
    public faceSheetComments: string = "";
    /*------------------ End of Demographics --------------------------*/

    /*------------------ Start of Clinical Information ----------------------------*/
    public careTeamId: string = "";
    public caseManagerId: string = "";
    public clinicalManagerId: string = "";
    public admittingNurseId: string = "";
    public referringDiagnosis: IDisplayDiagnosis;
    public diagnoses: IDisplayDiagnosis[] = [];
    public spiritualCounselorId: string = "";
    public socialWorkerId: string = "";
    public hospiceAideId: string = "";
    public volunteerCoordinatorId: string = "";
    public bereavementCoordinatorId: string = "";
    public tolerablePainLevel: number;
    /*------------------ End of Clinical Information ----------------------------*/
    /*------------------ Start of Referral Information --------------------------*/
    public referralDate: string = "";
    public communityLiaisonId: string;
    public comments: string = "";
    public lineOfService: number = 1;
    public sourceOfReferral: number = 0;
    public facilityReferralSourceId: string;
    public facilityReferralSourceName: string;
    public facilityReferralSourceContactName: string;
    public physicianReferralSourceId: string;
    public physicianReferralSourceName: string;
    public physicianReferralSourceContactName: string;
    public sameAsAttendingPhysician: boolean;
    public otherReferralSourceRelationship: string;
    public otherReferralSourceRelationshipDescription: string;
    public otherReferralSourceName: string;
    public communityLiaisonName: string;
    public internalReferralSourceId: string;
    public internalReferralSourceName: string;
    public internalReferralSource: number;
    public admissionType: number;
    public pendingReason: number = null;
    public pendingReasonComment: string = "";
    public prevDischargeDate: string;
    public currentAdmissionDate: string;
    /*------------------ End of Referral Information ----------------------------*/

    /*------------------ Start of Payor Information --------------------------*/
    public medicareNumber: string;
    public medicareNumberNA: boolean = false;
    public medicareBeneficiaryIdentification: string;
    public medicareBeneficiaryIdentificationNA: boolean = false;
    public medicaidNumber: string;
    public notAMedicaid: boolean = false;
    public medicaidPending: boolean = false;
    public paymentSource: number[] = [];
    public paymentSourcePleaseSpecify: string;
    public payors: PatientPayor[] = [];
    public hasRoomAndBoard: boolean = true;

    /*------------------ End of Payor Information ----------------------------*/

    /*------------------ Start of Medical Information Information --------------------------*/
    public serviceCurrentlyReceiving: number[] = [];
    public specifyService: string;
    public advancedDirectives: number[] = [];
    public advancedDirectivesCopyObtained: number[] = [];
    public dmEs: IPatientDme[] = [];
    public supplies: IPatientSupply[] = [];
    public attendingPhysicianId: string;
    public attendingPhysicianName: string;
    public hospicePhysicianId: string;
    public physicianOrderReceived: boolean;
    public pharmacies: string[] = [null];
    public dmeVendors: string[] = [null];
    /*------------------ End of Medical Information Information ----------------------------*/

    /*------------------ Start of Emergency Preparedness --------------------------*/

    public emergencyPreparednessPriority: number;
    public emergencyPreparednessInformation: number[];
    public additionalEmergencyInformation: string;
    public evacuationZone: number;
    public evacuationPrimaryPhoneNumber: IPhoneNumber = {
        number: "",
        extension: "",
        type: 1,
        country: null,
        isPrimary: true
    };
    public evacuationAlternatePhoneNumber: IPhoneNumber = {
        number: "",
        extension: "",
        type: 1,
        country: null,
        isPrimary: true
    };
    public isEvacuationAddressSameAsEmergencyContact: boolean;
    public evacuationAddress: Address = new Address();
    /*------------------ End of Emergency Preparedness --------------------------*/

    /*------------------ Start of Physicians --------------------------*/
    public certifyingPhysicianId: string;
    public additionalPhysicians: string[] = [];
    /*------------------ End of Physicians --------------------------*/

    /*------------------ Start of Advance Directives --------------------------*/
    public advancedDirective: IAdvancedDirective = {
        hasLegalDocumentation: false,
        isCarePlanDocumented: false,
        isSurrogateDocumented: false,
        advancedCarePlanDirectives: [],
        other: "",
        attorneyName: "",
        attorneyRelationship: "",
        medicalPreferences: "",
        mentalPreferences: "",
        culturalPreferences: "",
        spiritualPreferences: "",
        surrogateDecisionMaker: ""
    };
    /*------------------ End of Advance Directives --------------------------*/
}

interface IAdvancedDirective {
    hasLegalDocumentation: boolean;
    isCarePlanDocumented: boolean;
    isSurrogateDocumented: boolean;
    advancedCarePlanDirectives: number[];
    other: string;
    attorneyName: string;
    attorneyRelationship: string;
    medicalPreferences: string;
    mentalPreferences: string;
    culturalPreferences: string;
    spiritualPreferences: string;
    surrogateDecisionMaker: string;
}
