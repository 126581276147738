<template>
    <slot></slot>
    <span class="underline animate" :style="underlineStyle()">
        <span class="outline-inside" :style="outlineStyle"></span>
    </span>
</template>

<style src="./TabsAnimatedHeader.scss"></style>

<script src="./TabsAnimatedHeader.ts"></script>
