import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HospiceReferral } from "../../../../../resources-vue/vue-models/hospice-referral";
import { Patient } from "../../../../../resources-vue/vue-models/referral-patient";
import { CurrentUserService } from "../../../../../services/current-user-service";
import { ToastrService } from "../../../../../services/toastr.service";
import { ReferralService } from "../../../../../services/referral-service";
import { formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import TablePrompt from "../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import HHPreadmissionNotesForm from "./HHPreadmissionForm.vue";

export default defineComponent({
    components: {
        HHPreadmissionNotesForm,
        TablePrompt
    },

    props: {
        resetList: {
            type: Function
        },
        referralId: {
            type: String,
            default: ""
        },
        columns: {
            type: Number
        },
        isReferral: {
            type: Boolean,
            default: false
        },
        note: {
            type: Object,
            default: null
        },
        referral: {
            type: HospiceReferral || Patient
        },
        accountId: {
            type: String
        },
        application: {
            type: Number
        }
    },
    data(vm) {
        return {
            _currentUsersService: null,
            _referralService: null,
            _toastrService: null,
            currentUser: null,
            isDeleteRequested: false,
            isEditRequested: false,
            isViewRequested: false,
            isDeleteInProgress: false,
            formatDate
        };
    },

    created() {
        this._currentUsersService = Container.instance.get(CurrentUserService);
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
    },
    async mounted() {
        this.currentUser = await this._currentUsersService.getUser();
    },
    methods: {
        openEditNote() {
            this.isEditRequested = true;
        },
        closeEditNote() {
            this.isEditRequested = false;
        },
        openViewNote() {
            this.isViewRequested = true;
        },
        closeViewNote() {
            this.isViewRequested = false;
        },
        confirmDeleteNote() {
            this.isDeleteRequested = true;
        },

        async deleteNote() {
            try {
                this.isDeleteInProgress = true;
                let queryParam = { accountId: this.referral.accountId, application: this.referral.application };
                await this._referralService.deletePreadmissionNotes(this.referralId, this.note.id, queryParam);
                this.resetList();
                this._toastrService.success({
                    title: "Success",
                    message: "Note has been successfully deleted!"
                });
            } catch (err) {
                console.error(err);
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while deleting the note. Please try again.`
                });
            } finally {
                this.isDeleteInProgress = false;
                this.isDeleteRequested = false;
            }
        },
        cancelDeleteNote() {
            this.isDeleteRequested = false;
        }
    }
});
