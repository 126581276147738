import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PatientInformation = _resolveComponent("PatientInformation")!
  const _component_RaceEthnicity = _resolveComponent("RaceEthnicity")!
  const _component_PatientLanguage = _resolveComponent("PatientLanguage")!
  const _component_PaymentSource = _resolveComponent("PaymentSource")!
  const _component_AdmissionSource = _resolveComponent("AdmissionSource")!
  const _component_EpisodeManagement = _resolveComponent("EpisodeManagement")!
  const _component_DemographicsTags = _resolveComponent("DemographicsTags")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PatientInformation, {
      "branch-id": _ctx.branchId,
      "is-editing": !_ctx.isEditing
    }, null, 8 /* PROPS */, ["branch-id", "is-editing"]),
    _createVNode(_component_RaceEthnicity),
    _createVNode(_component_PatientLanguage),
    _createVNode(_component_PaymentSource),
    _createVNode(_component_AdmissionSource),
    _createVNode(_component_EpisodeManagement),
    _createVNode(_component_DemographicsTags)
  ]))
}