
import { defineComponent } from "vue";
import { useLoadingIndicator } from "./store/useLoadingIndicator";
import { mapState } from "pinia";

export default defineComponent({
    computed: {
        ...mapState(useLoadingIndicator, ["loading"])
    }
});
