import { autoinject } from "aurelia-dependency-injection";
import { DialogService, DialogSettings } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { noView } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import { ApplicationEnum } from "../enums/enums";
import type { ILogOptions } from "../resources-vue/vue-interfaces/i-analytics";
import type { INavigateRequestParams, INavigateResponse } from "../resources-vue/vue-interfaces/i-navigation";
import { Alert } from "../resources/dialogs/alert/alert";
import { AnalyticsService } from "./analytics.service";

@noView()
@autoinject()
export class NavigationService {
    private _fetchClient: FetchClient;
    public _analyticsService: AnalyticsService;
    public _ea: EventAggregator;
    private _dialogService: DialogService;
    private _baseUrl: string = "/api/v1/navigate";

    /**
     * Creates an instance of Navigate.
     *
     * @param {HttpClient} httpClient
     * @param {AuthService} authService
     *
     * @memberOf Navigate
     */
    public constructor(
        fetchClient: FetchClient,
        as: AnalyticsService,
        ea: EventAggregator,
        dialogService: DialogService
    ) {
        this._fetchClient = fetchClient;
        this._analyticsService = as;
        this._ea = ea;
        this._dialogService = dialogService;
    }

    private async getRedirectUrl(url: string, params: INavigateRequestParams): Promise<INavigateResponse> {
        return await this._fetchClient.build(url).useQueryString(params).fetch<INavigateResponse>();
    }

    public async navigateToAgency(options: INavigateRequestParams): Promise<INavigateResponse> {
        // TODO HACK
        // I hate services sometimes...
        if (
            options.applicationId < 0 ||
            options.applicationId == ApplicationEnum.AxxessPalliative ||
            options.applicationId == ApplicationEnum.AxxessNala
        ) {
            return {
                isSuccessful: true,
                errorMessage: "because we like sending a 200 success on failure /s",
                loginId: "because this is needed for some reason",
                redirectUrl: options.redirectUrl
            };
        }
        try {
            let url = `${this._baseUrl}/agency`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async navigateToPatient(options: INavigateRequestParams): Promise<INavigateResponse> {
        try {
            let url = `${this._baseUrl}/patient`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async navigateToEmployee(options: INavigateRequestParams): Promise<INavigateResponse> {
        try {
            const url = `${this._baseUrl}/employee`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async navigateToUser(options: INavigateRequestParams): Promise<INavigateResponse> {
        try {
            let url = `${this._baseUrl}/user`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async navigateToTask(options: INavigateRequestParams): Promise<INavigateResponse> {
        try {
            let url = `${this._baseUrl}/task`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async navigateToIncidentLog(options: INavigateRequestParams): Promise<INavigateResponse> {
        try {
            let url = `${this._baseUrl}/incident`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async navigateToMessage(options: INavigateRequestParams): Promise<INavigateResponse> {
        try {
            let url = `${this._baseUrl}/message`;
            return await this.getRedirectUrl(url, options);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public redirect(response: INavigateResponse, analyticsOptions: ILogOptions): void {
        if (response?.isSuccessful) {
            let redirectUrl = response.redirectUrl;
            if (redirectUrl?.length > 0) {
                this._analyticsService.logEvent({
                    category: analyticsOptions.category,
                    action: analyticsOptions.action
                });
                window.location.href = redirectUrl;
            } else {
                this.handleRedirectError(response, analyticsOptions);
            }
        } else {
            this.handleRedirectError(response, analyticsOptions);
        }
    }

    private async handleRedirectError(response: INavigateResponse, analyticsOptions: ILogOptions) {
        let defaultMessage = `Oops! There was a problem while processing your request.
        Please try again or contact <a href="http://axxess.com/support" target="_blank">customer support</a>.`;

        this.logError(analyticsOptions.action);
        let options: DialogSettings = {
            viewModel: Alert,
            model: { title: "Error", message: response.errorMessage || defaultMessage }
        };
        await this._dialogService.open(options);
    }

    private logError(action: string): void {
        this._analyticsService.logEvent({
            category: "Navigation-Error",
            action
        });
    }
}
