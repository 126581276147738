import { ApplicationEnum } from "../../../enums/enums";
import type { IAgency, IEmployeeCalendarResponse } from "../../../services/user-service";

export class EmployeeCalendarModel implements IEmployeeCalendarResponse {
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public Agencies: IAgency[];
    public PhoneNumber: string = "";
    public IsEmployee: boolean = true;

    public constructor(data: IEmployeeCalendarResponse) {
        if (data) {
            Object.assign(this, data);
        }

        this.Agencies.forEach((agency) => {
            if (agency.Application === 256) {
                agency.Application = ApplicationEnum.AxxessHospice;
            }
        });
    }
}
