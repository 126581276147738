import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { mapState } from "pinia";

import { formatPhone, sanitizeMarkup } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { AdditionalPharmacy } from "../../../../resources-vue/vue-models/additional-pharmacy";
import { useEditReferralHH } from "../../store/useEditReferralHH";
import { PharmacyService } from "../../../../services/pharmacy.service";

import TypeaheadInput from "../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import FormSection from "../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import AddPharmacy from "../../../../resources-vue/vue-dialogs/AddPharmacy/AddPharmacy.vue";

export default defineComponent({
    components: {
        TypeaheadInput,
        FormSection
    },

    data() {
        return {
            _pharmacyServies: null,
            attendingPharmacy: null,
            additionalPharmacy: [] as AdditionalPharmacy[],
            dialogRef: null,
            attendingPharmacyTooltip: `To enter a pharmacy, begin typing the pharmacy name to select a pharmacy.
                                       As you type, the list will filter to display pharmacies that match your search.
                                       Pharmacies that have not been added in the system will not populate and will not
                                       be available for the medication profile and the patient profile. To add a new pharmacy,
                                       click New Pharmacy.,`,
            sanitizeMarkup,
            formatPhone
        };
    },

    created() {
        this._pharmacyServies = Container.instance.get(PharmacyService);
        this.attendingPharmacy = this.referral.Pharmacy.PharmacyName;
        this.additionalPharmacy = this.referral.Pharmacy.AdditionalPharmacies;
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            referralOptions: "referralOptions",
            application: "currentApplication",
            accountId: "accountId"
        })
    },

    methods: {
        async getPharmacyList(filter: string, limit: number) {
            let result;
            try {
                let data = await this._pharmacyServies.getPharmacies({
                    agencyId: this.accountId,
                    term: filter
                });

                return data?.data?.map((item: any) => {
                    return {
                        name: item.displayName,
                        value: item.pharmacyId
                    };
                });
            } catch (e) {
                console.error(e);
            }
            return result;
        },

        addPharmacy() {
            this.additionalPharmacy.push({} as AdditionalPharmacy);
        },

        removePharmacyContact(index: number) {
            this.referral.Pharmacy.AdditionalPharmacies.splice(index, 1);
        },

        attendingPharmacyChanged(newValue: { name: string; value: string }) {
            const { name, value } = newValue;

            this.referral.Pharmacy.PrimaryPharmacyId = value;
            this.referral.Pharmacy.PharmacyName = name;
        },

        additonalPharmacyChanged(newValue: any, id: number) {
            this.referral.Pharmacy.AdditionalPharmacies[id] = {
                ...this.referral.Pharmacy.AdditionalPharmacies[id],
                Name: newValue.name,
                PharmacyId: newValue.value
            };
        },

        newPharmacy() {
            const data = {
                addressStateCode: this.referralOptions?.addressStateCode,
                agencyId: this.accountId,
                application: this.application
            };
            this.dialogRef = this.$dialog.open(AddPharmacy, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    data
                }
            });
        }
    }
});
