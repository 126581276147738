import { PropType, defineComponent } from "vue";

import { IConversationNavOptions } from "../../../resources-vue/vue-interfaces/messaging-service/i-message";
import {
    formatDisplayMessage,
    formatDisplayUsers,
    formatMessageTime,
    formatUserInitials
} from "../../../common/vue-helpers/modifiers/format-message";
import { markdownToHtml } from "../../../common/vue-helpers/modifiers/markdown-to-html-modifier";
import { sanitizeMarkup } from "../../../common/vue-helpers/modifiers/value-modifier";
import eventBus from "../../../utilities/eventBus";
import { INotification } from "../../../interfaces/messaging-service/i-notification";
import { NotificationsHubEvents } from "../../../enums/NotificationsHubEvents";

export default defineComponent({
    props: {
        newConversation: {
            type: Boolean,
            default: false
        },
        conversationUsersType: {
            type: String,
            default: "clinicians"
        },
        messageNavOptions: {
            type: Array as PropType<IConversationNavOptions[]>,
            default: []
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectedConversationId: "" as string,
            init: true as boolean,
            messageNavOptionsFiltered: [] as IConversationNavOptions[],
            term: "" as string,
            // modifiers
            formatUserInitials,
            formatDisplayUsers,
            formatMessageTime,
            markdownToHtml,
            sanitizeMarkup,
            formatDisplayMessage
        };
    },

    computed: {
        selectedConversationClass() {
            const conversationId = (this.$route.params?.id as string) || this.messageNavOptionsFiltered[0].id;
            return (index: number) => {
                return {
                    active: !this.newConversation && conversationId === this.messageNavOptionsFiltered[index].id
                };
            };
        }
    },

    watch: {
        messageNavOptions(newValue) {
            this.messageNavOptionsChanged(newValue);
        },
        term: function (newValue) {
            this.termChanged(newValue);
        }
    },

    created() {
        this.$watch(
            () => this.$route.params,
            (
                toParams: any,

                previousParams: any
            ) => {
                if (
                    previousParams !== toParams &&
                    this.$router.currentRoute.value.fullPath.indexOf("clinicians/conversation") != -1
                ) {
                    this.initConversations();
                }
                if (this.$router.currentRoute.value.fullPath.indexOf("new-conversation") > -1) {
                    return;
                }
            },

            { immediate: true }
        );
        eventBus.on(NotificationsHubEvents.LoadConversationList, (notification: INotification) => {
            this.moveNotificationToTop(notification);
        });
    },

    methods: {
        moveNotificationToTop(notification: INotification) {
            const index = this.messageNavOptionsFiltered.findIndex(
                (messageNavOption) => messageNavOption.id === notification.conversationId
            );
            if (index === -1) return;
            const currentDate = new Date();

            const [conversation] = this.messageNavOptionsFiltered.splice(index, 1);
            conversation.text = notification.messageBody;
            conversation.modifiedOn = currentDate;

            if (this.selectedConversationId !== notification.conversationId) {
                conversation.unread = conversation.unread + 1;
            }

            this.messageNavOptionsFiltered.unshift(conversation);
            const eventOptions = { notification, conversationList: this.messageNavOptionsFiltered };
            eventBus.emit(NotificationsHubEvents.LoadNavbarNotifications, eventOptions);
        },
        messageNavOptionsChanged(splices: any) {
            if ((this.init || this.newConversation) && splices) {
                this.messageNavOptionsFiltered = this.messageNavOptions.slice();
                this.initConversations();
                this.init = false;
            }
        },

        initConversations() {
            this.selectedConversationId = this.$route.params.id as string;
            if (this.messageNavOptions && this.messageNavOptions?.length > 0) {
                if (!this.selectedConversationId) {
                    if (screen.width >= 768) {
                        this.$router.push(
                            `/central-messaging/${this.conversationUsersType}/conversation/${this.messageNavOptions[0].id}`
                        );
                        this.messageNavOptions[0].unread = 0;
                        this.selectedConversationId = this.messageNavOptions[0].id;
                        // TODO: to update in vue before pushing to master
                        eventBus.emit("conversationSelected", this.messageNavOptions[0]);
                    }
                } else {
                    let selectedConversation = this.messageNavOptions.filter(
                        (conversation) => conversation.id == this.selectedConversationId
                    );
                    if (selectedConversation) {
                        let index = this.messageNavOptions.findIndex((mes) => mes.id == this.selectedConversationId);
                        if (index > -1) {
                            this.messageNavOptions[index].unread = 0;

                            // TODO: to update in vue before pushing to master
                            eventBus.emit("loadConversation", selectedConversation[0]);
                        }
                    }
                }
            }
        },

        handleMenuSelection(message: IConversationNavOptions) {
            if (this.newConversation) {
                this.$router.push({
                    path: `/central-messaging/${this.conversationUsersType}/conversation/${message.id}`,
                    replace: true,
                    force: true
                });
                this.$emit("updateNewConversation", false);
            }
            this.$router.push(`/central-messaging/${this.conversationUsersType}/conversation/${message.id}`);
            let index = this.messageNavOptions.findIndex((mes) => mes.id == message.id);
            this.messageNavOptions[index].unread = 0;
            // TODO: to update in vue before pushing to master
            eventBus.emit("conversationSelected", message);
            this.selectedConversationId = message.id;
        },

        navigateToNewConversation() {
            this.$emit("updateNewConversation", true);
            this.$router.push({
                path: `/central-messaging/${this.conversationUsersType}/new-conversation`,
                replace: true,
                force: true
            });
        },

        cancelNewConversation() {
            this.$emit("updateNewConversation", false);
            this.$router.push(
                `/central-messaging/${this.conversationUsersType}/conversation/${this.selectedConversationId}`
            );
        },

        termChanged(newValue: string) {
            if (newValue === "") {
                this.messageNavOptionsFiltered = this.messageNavOptions.slice();
            } else {
                this.messageNavOptionsFiltered = this.search(newValue);
            }
        },

        search(searchTerm: string) {
            return this.messageNavOptions.filter((messageNavOption) => {
                // Check if the title contains the term
                const titleMatch = messageNavOption.title?.toLowerCase().includes(searchTerm.toLowerCase());

                // Check if any user's firstname or lastname contains the term
                const userMatch = messageNavOption.users.some(
                    (user) =>
                        user.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        user.lastName?.toLowerCase().includes(searchTerm.toLowerCase())
                );
                // Return true if either title or userMatch is true
                return titleMatch || userMatch;
            });
        }
    }
});
