import type { IAddress } from "../vue-interfaces/i-address";

export class Address implements IAddress {
    public country?: string;
    public addressLine1: string = "";
    public addressLine2: string = "";
    public city: string = "";
    public state: string = "";
    public zipCode: string = "";
    public county: string = "";
    public latitude: number;
    public longitude: number;
    public isValidated: boolean = false;
    public isBypassed: boolean = false;
    public moveInDate?: string;
    public moveOutDate?: string;

    public constructor(data?: IAddress) {
        if (!!data) {
            Object.assign(this, data);
        }
    }
}
