import { defineComponent } from "vue";

import { ApplicationEnum } from "../../../enums/enums";
import { IReassignVisitDetails } from "../../../interfaces/i-reassign-visit";

import ScheduleVisitHomeCare from "../../vue-forms/ScheduleVisitEmployee/ScheduleVisitHomeCare/ScheduleVisitHomeCare.vue";
import ScheduleVisitHosPal from "../../vue-forms/ScheduleVisitEmployee/ScheduleVisitHosPal/ScheduleVisitHosPal.vue";
import ScheduleVisitHomeHealth from "../../vue-forms/ScheduleVisitEmployee/ScheduleVisitHomeHealth/ScheduleVisitHomeHealth.vue";

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        ScheduleVisitHosPal,
        ScheduleVisitHomeCare,
        ScheduleVisitHomeHealth
    },

    data() {
        return {
            dialogReference: this.dialogRef as any,
            options: {} as IReassignVisitDetails,
            applicationEnum: ApplicationEnum,
            isLoading: false,
            isSaving: false
        };
    },

    mounted() {
        this.options = Object.assign({}, this.dialogReference.data.visitDetails);
    },

    methods: {
        handleLoadingChange(value: boolean) {
            this.isLoading = value;
        },
        handleSavingChange(value: boolean) {
            this.isSaving = value;
        },

        cancel() {
            this.dialogReference.close();
        },
        ok() {
            this.dialogReference.close("success");
        },
        addAnother() {
            this.dialogReference.close("reopen");
        }
    }
});
