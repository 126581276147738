import { defineComponent } from "vue";
import { RouterLink } from "vue-router";
import { Container } from "aurelia-dependency-injection";

import { MessageService } from "../../../../services/message-service";
import { IConversationNavOptions, IConversationUser } from "../../../vue-interfaces/messaging-service/i-message";
import eventBus from "../../../../utilities/eventBus";
import { LoadNavbarNotificationsEvent, NotificationsHubEvents } from "../../../../enums/NotificationsHubEvents";

import MessagesList from "./MessagesList.vue";
import Popover from "../../popover/popover.vue";


export default defineComponent({
    components: {
        RouterLink,
        Popover,
        MessagesList
    },

    props: {
        isAxxessPlannerHeader: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            shakeMessageIcon: false,
            isLoading: true,
            unreadConversations: 0,
            pageNumber: 1,
            pageLength: 5,
            conversations: [] as IConversationNavOptions[],
            titleChangeInterval: null as NodeJS.Timer,
            _messageService: null
        };
    },
    watch: {
        conversations: function (newValue, oldValue) {
            this.unreadConversations = newValue.length;
        },
        '$route.params.id'(newConversationId, oldConversationId) {
            if (newConversationId !== oldConversationId) {
                const conversationIndex = this.conversations.findIndex(conversation => conversation.id === newConversationId);
                if(conversationIndex > -1) {
                    this.conversations.splice(conversationIndex, 1)
                    this.unreadConversations = this.unreadConversations - 1
                }
            }
        }
    },
    created() {
        this._messageService = Container.instance.get(MessageService);

        eventBus.on(NotificationsHubEvents.LoadNavbarNotifications, (eventPayload: LoadNavbarNotificationsEvent) => {
            const { notification, conversationList } = eventPayload;

            const conversationIndex = this.conversations.findIndex(conversation => conversation.id === notification.conversationId);
            const conversationParticipants = conversationList.find(conversation => conversation.id === notification.conversationId)?.users || []
            const routeConversationId = this.$route.params.id

            if (routeConversationId !== notification.conversationId) {
                if (conversationIndex > -1) {
                    this.conversations[conversationIndex].unread += 1;
                    this.conversations[conversationIndex].text = notification.messageBody;
                    const [conversation] = this.conversations.splice(conversationIndex, 1)
                    this.conversations.unshift(conversation)
                } else {
                    this.conversations.unshift({
                        id: notification.conversationId,
                        title: null,
                        text: notification.messageBody,
                        unread: 1,
                        createdOn: new Date(notification.createdAt),
                        modifiedOn: new Date(),
                        isAxxessConversation: false,
                        users: conversationParticipants as IConversationUser[]
                    });
                }
            }
            this.unreadConversations = this.conversations.length
        });

    },
    mounted() {
        this.getUnreadConversations();
    },
    methods: {
        async getUnreadConversations() {
            this.isLoading = true;
            let result = await this._messageService.getUnreadConversations({
                page: this.pageNumber,
                pageLength: this.pageLength
            });
            this.isLoading = false;
            this.conversations = result.items ? result.items : [];
            this.unreadConversations = result.itemCount;
            if (this.unreadConversations > 0) {
                this.shakeMessageIcon = true;
                let showMessage = true;
                /* this.titleChangeInterval = setInterval(() => {
                document.title = showMessage ? `(${this.unreadConversations}) New Messages!` : this.getCurrentTitle();
                showMessage = !showMessage;
            }, 1000); */
            } else {
                if (this.titleChangeInterval) {
                    // clearInterval(this.titleChangeInterval);
                }
            }
            setTimeout(() => (this.shakeMessageIcon = false), 1000);
        }
    }
});
