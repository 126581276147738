import { defineComponent } from "vue";
import { mapState } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { PhysiciansService } from "../../../services/physician-service";
import { LookupService } from "../../../services/lookup-service";
import { ToastrService } from "../../../services/toastr.service";
import { IGetNpi, IGetNpiResponse, IAddNewPhysician } from "../../vue-interfaces/i-get-physician";
import { useEditReferralHH } from "../../../pages-vue/EditReferralHomeHealth/store/useEditReferralHH";

import TypeaheadInput from "../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        TypeaheadInput
    },

    validations() {
        return {
            newPhysician: {
                FirstName: { required },
                LastName: { required },
                NPI: { required },
                AddressLine1: { required },
                AddressCity: { required },
                AddressZipCode: { required },
                PhoneWork: { required },
                DeliveryType: { required },
                ElectronicDeliveryType: { required: requiredIf(() => this.newPhysician.DeliveryType == 5) },
                FaxNumber: { required: requiredIf(() => this.newPhysician.DeliveryType == 2) },
                EmailAddress: { required: requiredIf(() => this.newPhysician.DeliveryType == 1) }
            }
        };
    },

    data() {
        return {
            _physicianService: null,
            _lookupService: null,
            _toastrService: null,
            dialogReference: this.dialogRef as any,
            newPhysician: {} as IAddNewPhysician,
            isPecosVerified: "Not Checked",
            npiSearch: null,
            npiList: [],
            agencyId: null,
            v$: useVuelidate()
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referralOptions: "referralOptions"
        })
    },

    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
        this._lookupService = Container.instance.get(LookupService);
        this._toastrService = Container.instance.get(ToastrService);
        this.newPhysician.AddressStateCode = null;
        this.newPhysician.DeliveryType = null;
        this.newPhysician.ElectronicDeliveryType = null;
        this.agencyId = this.dialogReference.data.agencyId;
    },

    methods: {
        async getNpi(filter: string) {
            let query = {
                agencyId: this.agencyId,
                term: filter,
                limit: 20
            } as IGetNpi;
            this.npiList = await this._lookupService.getNpi(query);

            return this.npiList?.map((item: any) => {
                return {
                    name: item.id + " - " + item.providerFirstName + " " + item.providerLastName,
                    value: item.id
                };
            });
        },

        updateAllFields(newValue: any) {
            let res: IGetNpiResponse = this.npiList.find((item) => item.id === newValue.value);
            this.newPhysician = {
                ...this.newPhysician,
                AgencyId: this.agencyId,
                NPI: res.id,
                FirstName: res.providerFirstName,
                LastName: res.providerLastName,
                MiddleName: res.providerMiddleName,
                Credentials: res.providerCredentialText,
                AddressLine1: res.providerFirstLineBusinessPracticeLocationAddress,
                AddressLine2: res.providerSecondLineBusinessPracticeLocationAddress,
                AddressCity: res.providerBusinessPracticeLocationAddressCityName,
                AddressStateCode: res.providerBusinessPracticeLocationAddressStateName,
                AddressZipCode: res.providerBusinessMailingAddressPostalCode,
                PhoneWork: res.providerBusinessPracticeLocationAddressTelephoneNumber,
                FaxNumber: res.providerBusinessPracticeLocationAddressFaxNumber
            };
            this.checkPecos();
        },

        async checkPecos() {
            if (this.newPhysician.NPI?.length > 0) {
                let query = { agencyId: this.agencyId, npi: this.newPhysician.NPI };
                let response = await this._lookupService.checkPecos(query);
                if (response) {
                    this.isPecosVerified = `<i class="fas fa-check text-success font-size-xl mt-1"></i>`;
                } else {
                    this.isPecosVerified = `<i class="fas fa-times text-danger font-size-xl mt-1"></i>`;
                }
            }
        },

        async updateZipAddress() {
            const res = await this._lookupService.getCityStateByZipcode({
                agencyId: this.agencyId,
                zipCode: this.newPhysician.AddressZipCode,
                application: this.application
            });
            this.newPhysician.AddressStateCode = res.state;
            this.newPhysician.AddressCity = res.city;
        },

        async addNewPhysician() {
            const valid = await this.v$.$validate();
            if (valid) {
                try {
                    this.newPhysician.AgencyId = this.agencyId;
                    await this._physicianService.addNewPhysicianHH(this.newPhysician);
                    this._toastrService.success({
                        title: `New Physician Added`,
                        message: `New physician successfully added`
                    });
                    this.dialogReference.close("success");
                } catch (error) {
                    this._toastrService.error({
                        title: `Error`,
                        message: `There was a problem while adding new physician. Please try again. If the problem persists, please report it.`
                    });
                }
            } else {
                this._toastrService.error({
                    title: `Validation Failed`,
                    message: `Fill all required fields.`
                });
            }
        },

        closeDialog() {
            this.dialogReference.close();
        }
    }
});
