import { defineComponent } from "vue";
import moment from "moment";
import { Container } from "aurelia-dependency-injection";

import { PatientCalendarModel } from "./PatientCalendar.model";
import { IPatientCalendarFilterOptions } from "../../../resources-vue/vue-interfaces/i-calendar";
import { ITaskByPatientRequest, ITaskByPatientResponse, TaskService } from "../../../services/task.service";
import { IPatientCalendarRequestParams, PatientService } from "../../../services/patient.service";
import { PAGE_FAIL_RESPONSE } from "../../../resources-vue/vue-elements/Pagination/Pagination";
import { IVisitMapDetails, IVisitMapRequestParams } from "../../../resources-vue/vue-interfaces/i-visit-map";

import PatientCalendarFilter from "./components/PatientCalendarFilter/PatientCalendarFilter.vue";
import PatientCalendarContent from "./components/PatientCalendarContent/PatientCalendarContent.vue";
import DailyDateNavigation from "../components/DailyDateNavigation/DailyDateNavigation.vue";
import WeeklyDateNavigation from "../components/WeeklyDateNavigation/WeeklyDateNavigation.vue";
import Pagination from "../../../resources-vue/vue-elements/Pagination/Pagination.vue";
import PatientVisitMap from "./components/PatientVisitMap/PatientVisitMap.vue";
import PatientVisitMapFilters from "./components/PatientVisitMapFilters/PatientVisitMapFilters.vue";
import { formatFullAddress } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    components: {
        DailyDateNavigation,
        WeeklyDateNavigation,
        PatientCalendarFilter,
        PatientCalendarContent,
        PatientVisitMap,
        PatientVisitMapFilters,
        Pagination
    },

    data() {
        return {
            isEmployeeSelected: false as boolean,
            startDate: null as Date,
            endDate: null as Date,
            currentDate: new Date(Date.now()).toISOString() as string,
            showPatients: false as boolean,
            showEmployees: false as boolean,
            patientFilterOptions: {
                pageLength: 15,
                page: 1,
                term: "",
                patientStatus: null,
                agencies: [],
                scheduleFilter: 0,
                startDate: "",
                endDate: ""
            } as IPatientCalendarRequestParams,
            taskFilterOptions: {
                start: null,
                end: null,
                patientIds: []
                // weekdayRange: 1
            } as ITaskByPatientRequest,
            pageNumber: 1 as number,
            pageSize: 15 as number,
            isError: false as boolean,
            patientCalendarPagination: null,
            patients: [] as PatientCalendarModel[],
            isPatientLoading: false as boolean,
            tasks: [] as ITaskByPatientResponse[],
            tasksMap: null,
            _taskService: null,
            _patientService: null,
            showVisitMap: false as boolean,
            mapVisitsList: [] as IVisitMapDetails[],
            todaysDate: new Date(Date.now()) as Date,
            mapFilterOptions: {
                visitStatus: "",
                date: new Date(Date.now()).toISOString()
            } as IVisitMapRequestParams,
            patientsList: {} as any,
            locationsList: {} as any,
            fetchUpdatedCount: 0,
            visitMapDate: null
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._patientService = Container.instance.get(PatientService);
        this.patientCalendarPagination = this.$refs?.patientCalendarPagination as typeof Pagination;
    },

    mounted() {
        this.patientCalendarPagination = this.$refs?.patientCalendarPagination as typeof Pagination;
    },

    computed: {
        getHasData() {
            return this.patients?.length > 0;
        }
    },

    methods: {
        async patientRefresh(options: IPatientCalendarFilterOptions, isWeekRangeChanged: boolean) {
            this.patientFilterOptions = {
                ...this.patientFilterOptions,
                ...options
            };
            this.updateDateRange(options, isWeekRangeChanged);
        },

        isEmployeeSelectedChanged(newValue: boolean) {
            this.isEmployeeSelected = newValue;
        },

        async pageChanged(pageNumber: number, pageSize: number = 15) {
            try {
                if (this.patientFilterOptions?.agencies?.length > 0) {
                    this.isError = false;
                    this.patientFilterOptions.page = pageNumber;
                    this.isPatientLoading = true;
                    let data = await this._patientService.findPatientsForCalendar(this.patientFilterOptions);
                    if (data) {
                        let dataItems = data.Items ?? [];
                        this.patients = dataItems.map((item: any) => new PatientCalendarModel(item));
                        this.taskFilterOptions.patientIds = this.patients.map((x) => x.Id);
                        await this.taskRefresh();
                        this.isPatientLoading = false;
                        return {
                            totalPageCount: data.PageCount,
                            success: data.ItemCount > 0,
                            totalCount: data.ItemCount
                        };
                    } else {
                        return PAGE_FAIL_RESPONSE;
                    }
                } else {
                    this.patients = [];
                    this.taskFilterOptions.patientIds = [];
                    return PAGE_FAIL_RESPONSE;
                }
            } catch (e) {
                console.error(e);
                this.isError = true;
                return PAGE_FAIL_RESPONSE;
            }
        },

        async updateDateRange(options: any, isWeekRangeChanged: boolean = true) {
            if (isWeekRangeChanged) {
                this.startDate = options.startDate;
                this.endDate = options.endDate;
            }
            this.patientFilterOptions.startDate = this.taskFilterOptions.start = this.formatDate(this.startDate);
            this.patientFilterOptions.endDate = this.taskFilterOptions.end = this.formatDate(this.endDate);
            await this.pageReset();
        },

        formatDate(input: Date) {
            return `${input.getMonth() + 1}/${input.getDate()}/${input.getFullYear()}`;
        },

        async taskRefresh() {
            try {
                if (this.taskFilterOptions?.patientIds?.length > 0) {
                    const tasks = await this._taskService.getTasksByPatients(this.taskFilterOptions);
                    tasks.sort((a: any, b: any) => {
                        return moment(a.StartTime).isAfter(moment(b.StartTime)) ? 1 : -1;
                    });
                    this.tasks = tasks;
                }
            } catch (e) {
                console.error(e);
            }
        },

        async pageReset() {
            this.isError = false;
            await this.patientCalendarPagination?.reset();
        },

        updatePageSizeChanged(value: number) {
            this.pageSize = value;
        },

        async handleVisitMapClick() {
            this.showVisitMap = !this.showVisitMap;
        },

        async mapRefresh(options?: any) {
            if (options) {
                this.mapFilterOptions = options;
                this.visitMapDate = options.date;
            }
            this.mapVisitsList = await this._taskService.getCalendarMapVisits(this.mapFilterOptions);
            this.patientsList = {};
            this.locationsList = {};
            for (let visit of this.mapVisitsList) {
                const name: any = visit.PatientName.FirstName + " " + visit.PatientName.LastName;
                const address: any = formatFullAddress(visit.PatientAddress);
                if (!this.patientsList[name]) {
                    this.patientsList[name] = visit.PatientId + "_" + address;
                }
                if (!this.locationsList[address]) {
                    this.locationsList[address] = visit.Id;
                }
            }
            this.fetchUpdatedCount++;
        }
    }
});
