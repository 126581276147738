import { autoinject, PLATFORM, useView } from "aurelia-framework";
import { UserManager } from "oidc-client";
import config from "../../common/config";

export interface ILocationSave {
    fragment: string;
    query: string;
}

@autoinject
@useView(PLATFORM.moduleName("./sign-in-view.html"))
export class SignInStartPage {
    private readonly _userManager: UserManager;
    public fragment: string;
    public query: string;

    public constructor(userManager: UserManager) {
        this._userManager = userManager;
    }

    public async activate(params: any) {
        console.log("User is not logged in, sign in start");
        params = params || {};
        this.fragment = params.fragment;
        this.query = params.query;
        let referrer: string = params.referrer;

        const parsedQuery = new URLSearchParams(this.query);
        let loginHint = parsedQuery.get("login_hint");

        if (loginHint) {
            parsedQuery.delete("login_hint");
            this.query = parsedQuery.toString();
        }

        let save: ILocationSave = {
            fragment: this.fragment,
            query: this.query
        };

        window.sessionStorage.setItem(config.locationSaveKey, JSON.stringify(save));
        console.log("Sign in start: ", { referrer });
        this._userManager.signinRedirect({ login_hint: loginHint });
    }
}
