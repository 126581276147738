import { PropType, computed, defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { formatDateInput } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { enumToMap } from "../../../../common/vue-helpers/modifiers/enum-modifiers";
import { IReassignVisitDetails } from "../../../vue-interfaces/i-reassign-visit";
import { IHandleTaskReschuleIssue } from "../../../vue-interfaces/i-visit-details";
import { IGetClientRequest, IGetClientResponse } from "../../../vue-interfaces/i-user";
import { IGetTaskPayorList, ITaskPayor } from "../../../vue-interfaces/i-task";
import { ToastrService } from "../../../../services/toastr.service";
import { IHomeCareTasksData, IHomeCareTasksParams, TaskService } from "../../../../services/task.service";
import { UserService } from "../../../../services/user-service";
import { ApplicationEnum, RecurrenceType, RecurrenceTypeEnum } from "../../../../enums/enums";
import moment from "moment";
import { IRescheduleValidations } from "../../../vue-dialogs/RescheduleValidations/RescheduleValidation";
import { ScheduleVisitHomeCareModel } from "../../../vue-models/schedule-visit-home-care";
import { ISelectDropDownOption } from "../../../vue-custom-elements/GroupDropdownSelect/GroupDropdownSelect";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../vue-custom-elements//DatePicker/DatePicker.vue";
import TimePicker from "../../../vue-custom-elements/TimePicker/TimePicker.vue";
import DayPicker from "../../../vue-custom-elements/DayPicker/DayPicker.vue";
import MultiDatePicker from "../../../vue-custom-elements/MultiDatePicker/MultiDatePicker.vue";
import GroupDropdownSelect from "../../../vue-custom-elements/GroupDropdownSelect/GroupDropdownSelect.vue";
import RescheduleValidation from "../../../vue-dialogs/RescheduleValidations/RescheduleValidation.vue";
import CommentInput from "../../../vue-custom-elements/CommentInput/CommentInput.vue";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        TimePicker,
        DayPicker,
        MultiDatePicker,
        GroupDropdownSelect,
        CommentInput
    },

    props: {
        visitDetails: { type: Object as PropType<IReassignVisitDetails>, default: {} },
        isLoading: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        cancel: { type: Function, default: null },
        ok: { type: Function, default: null },
        addAnother: { type: Function, default: null }
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            client: { required },
            newVisitDetails: {
                startDateTime: {
                    date: {
                        required
                    },
                    time: {
                        required: requiredIf(!this.scheduleWithoutTime)
                    }
                },
                payorId: { required },
                disciplineTask: { required },
                recurrence: {
                    endDate: {
                        required: requiredIf(() => {
                            return !(
                                this.newVisitDetails.recurrence.type === RecurrenceTypeEnum.Flexible ||
                                this.newVisitDetails.recurrence.type === RecurrenceTypeEnum.None
                            );
                        })
                    },
                    daysOfWeek: {
                        required: requiredIf(this.newVisitDetails.recurrence.type == RecurrenceTypeEnum.Weekly)
                    },
                    flexibleDates: {
                        required: requiredIf(this.newVisitDetails.recurrence.type == RecurrenceTypeEnum.Flexible)
                    }
                }
            }
        };
    },

    data() {
        return {
            _taskService: null,
            _toastrService: null,
            _userService: null,
            _subscriptions: null,
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string },
            isSavingReason: false as boolean,
            newVisitDetails: new ScheduleVisitHomeCareModel(),
            newVisitDetailsReset: ScheduleVisitHomeCareModel,
            disciplineTasks: [] as IHomeCareTasksData[],
            currentUserId: "" as string,
            clients: [] as IGetClientResponse[],
            recurrenceType: RecurrenceType,
            recurrenceTypeEnum: RecurrenceTypeEnum,
            taskDropdownSelectOptions: {} as ISelectDropDownOption,
            clientDropdownSelectOptions: {} as ISelectDropDownOption,
            selectedAgencies: [] as string[],
            isSingleDate: true as boolean,
            payorList: [] as ITaskPayor[],
            isPaymentSourceLoading: false as boolean,
            isTasksLoading: false as boolean,
            checkAllByDefault: false as boolean,
            visitDates: "" as string,
            recurrence: "" as string,
            shiftLength: 1 as number,
            startTime: "" as string,
            endTime: "" as string,
            scheduleWithoutTime: false as boolean,
            client: null as string,
            overrideShiftLength: false as boolean,
            dialogRef: null,
            formatDateInput,
            enumToMap,
            SaveEnum
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    watch: {
        visitDates() {
            this.visitDatesChanged();
        },

        shiftLength() {
            this.shiftLengthChanged();
        },

        startTime() {
            this.startTimeChanged();
        },

        endTime() {
            this.endTimeChanged();
        },

        scheduleWithoutTime() {
            this.scheduleWithoutTimeChanged();
        },

        client(newValue) {
            this.clientChanged(newValue);
        },

        overrideShift() {
            this.overrideShiftLengthChanged();
        },

        "newVisitDetails.startDateTime.date"() {
            this.startDateChanged();
        },

        "newVisitDetails.startDateTime.time"() {
            this.startTimeChanged();
        },

        "newVisitDetails.endDateTime.date"() {
            this.endDateChanged();
        },

        "newVisitDetails.endDateTime.time"() {
            this.endTimeChanged();
        }
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._userService = Container.instance.get(UserService);
    },

    async mounted() {
        Object.assign(this.visitDetails, {});
        try {
            this.$emit("loadingChange", true);
            await this.getDisciplineTasks();
            await this.getClient();
            this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
            this.newVisitDetails.application = this.visitDetails.Application;
            this.newVisitDetails.patientId = this.visitDetails.Id;
            this.newVisitDetails.payorId = null;
            this.newVisitDetailsReset = JSON.parse(JSON.stringify(this.newVisitDetails));
        } catch (error) {
            console.log(error);
        } finally {
            this.$emit("loadingChange", false);
        }
    },

    methods: {
        taskDateChanged(date: any, name: string) {
            if (name === "startDateTime") {
                this.newVisitDetails.startDateTime.date = date;
            } else if (name === "endDateTime") {
                this.newVisitDetails.endDateTime.date = date;
            } else if (name === "endDate") {
                this.newVisitDetails.recurrence.endDate = date;
            }
        },

        updateResult(result: any) {
            this.visitDates = result;
        },

        updateSelectFields(value: any, text: any, id: number) {
            this.newVisitDetails.customNoteId = value;
            this.newVisitDetails.taskName = text;
            this.newVisitDetails.disciplineTask = id;
        },

        handleRecurrenceChange(event: any) {
            const value = event.target.value;
            this.newVisitDetails.recurrence.type = value;
            this.newVisitDetails.recurrence.repeatBy = null;
            this.newVisitDetails.recurrence.repeatNumber = null;
            if (value == 3) {
                this.newVisitDetails.recurrence.repeatBy = 2;
                this.newVisitDetails.recurrence.repeatNumber = 1;
            } else if (value == 1 || value == 2) {
                this.newVisitDetails.recurrence.repeatNumber = 1;
            }
        },

        updateDaysOfWeek(value: string) {
            this.newVisitDetails.recurrence.daysOfWeek = value;
        },

        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : "";
        },

        changeStartTime(time: any) {
            this.newVisitDetails.startDateTime.time = time;
        },

        changeEndTime(time: any) {
            this.newVisitDetails.endDateTime.time = time;
        },

        async getDisciplineTasks() {
            try {
                const payload: IHomeCareTasksParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: ApplicationEnum.HomeCare
                };
                this.disciplineTasks = [];
                this.disciplineTasks = (await this._taskService.getHCTaskList(payload)).data.data;
                this.setDisciplineMultiSelect();
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Visit Type Failed`,
                    message: `Fetching Visit Type Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async setDisciplineMultiSelect() {
            try {
                this.taskDropdownSelectOptions = {};
                if (!this.disciplineTasks || this.disciplineTasks?.length === 0) {
                    return;
                }
                this.disciplineTasks.forEach((taskObject) => {
                    if (taskObject.label && Array.isArray(taskObject.children)) {
                        const labelKey = taskObject.label;
                        this.taskDropdownSelectOptions[labelKey] = taskObject.children.map((child) => {
                            return {
                                displayText: child.label,
                                value: child.id,
                                id: child.disciplineTaskId
                            };
                        });
                    }
                });
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },

        async getClient() {
            try {
                if (!this.visitDetails) return;
                this.isPaymentSourceLoading = true;
                const clientListPayload: IGetClientRequest = {
                    employeeId: this.visitDetails.Id,
                    agencyId: this.visitDetails.AgencyId,
                    application: ApplicationEnum.HomeCare
                };
                this.clients = await this._userService.getClients(clientListPayload);
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Client List Failed`,
                    message: `Fetching CLient List Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isPaymentSourceLoading = false;
            }
        },

        clientChanged(newValue: any) {
            if (newValue) {
                this.newVisitDetails.patientId = newValue;
                this.getPaymentSource();
            }
        },

        async getPaymentSource() {
            try {
                if (!this.visitDetails) return;
                this.isPaymentSourceLoading = true;
                const payorListPayload: IGetTaskPayorList = {
                    patientId: this.newVisitDetails.patientId,
                    agencyId: this.visitDetails.AgencyId,
                    startDate: this.newVisitDetails.startDateTime.date,
                    endDate: this.newVisitDetails.endDateTime.date,
                    application: ApplicationEnum.HomeCare
                };
                const payors = await this._taskService.getTaskPayorsList(payorListPayload);
                this.payorList = payors.data;
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Payor List Failed`,
                    message: `Fetching Payor List Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isPaymentSourceLoading = false;
            }
        },

        visitDatesChanged() {
            this.newVisitDetails.recurrence.flexibleDates = this.visitDates.split(", ");
        },

        startTimeChanged() {
            if (this.shiftLength && Number(this.shiftLength) > 0) {
                this.shiftLengthChanged();
            }
            if (
                this.newVisitDetails.startDateTime.time.indexOf("PM") != -1 &&
                this.newVisitDetails.endDateTime.time.indexOf("AM") != -1
            ) {
                this.isSingleDate = false;
            } else {
                this.isSingleDate = true;
            }
        },

        startDateChanged() {
            if (this.isSingleDate) {
                this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
            } else {
                this.newVisitDetails.endDateTime.date = moment(this.newVisitDetails.startDateTime.date, "MM/DD/YYYY")
                    .add(1, "day")
                    .format("MM/DD/YYYY");
            }
        },

        endDateChanged() {
            if (this.newVisitDetails.startDateTime.date && this.newVisitDetails.endDateTime.date) {
                this.getPaymentSource();
            }
        },

        endTimeChanged() {
            if (
                this.newVisitDetails.startDateTime.time.indexOf("PM") != -1 &&
                this.newVisitDetails.endDateTime.time.indexOf("AM") != -1
            ) {
                this.isSingleDate = false;
            } else {
                this.isSingleDate = true;
            }
            if (this.newVisitDetails.startDateTime.time && this.newVisitDetails.endDateTime.time) {
                if (this.isSingleDate) {
                    this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
                } else {
                    this.newVisitDetails.endDateTime.date = moment(
                        this.newVisitDetails.startDateTime.date,
                        "MM/DD/YYYY"
                    )
                        .add(1, "day")
                        .format("MM/DD/YYYY");
                }
            }
        },

        shiftLengthChanged() {
            this.newVisitDetails.lengthOfShift = this.shiftLength;
            if (this.newVisitDetails.startDateTime.time)
                this.newVisitDetails.endDateTime.time = moment(this.newVisitDetails.startDateTime.time, "hh:mm A")
                    .add(Number(this.shiftLength), "hours")
                    .format("hh:mm A");
        },

        overrideShiftLengthChanged() {
            if (!this.overrideShiftLength) this.shiftLengthChanged();
        },

        scheduleWithoutTimeChanged() {
            this.newVisitDetails.isAllDay = this.scheduleWithoutTime;
            if (!this.scheduleWithoutTime) {
                if (this.newVisitDetails.startDateTime.date && this.newVisitDetails.endDateTime.date) {
                    this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
                }
            }
        },

        resultUpdated(data: any) {
            this.newVisitDetails.comments = data;
        },

        async scheduleVisit(saveType: string) {
            const valid = await this.v$.$validate();
            if (!valid) {
                return;
            }
            if (!!this.newVisitDetails) {
                try {
                    this.$emit("savingChange", true);
                    if (this.scheduleWithoutTime) {
                        this.newVisitDetails.eventStartTime = moment(
                            this.newVisitDetails.startDateTime.date + " 00:00 AM",
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                        this.newVisitDetails.eventEndTime = moment(
                            this.newVisitDetails.endDateTime.date + " 00:00 AM",
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        this.newVisitDetails.eventStartTime = moment(
                            this.newVisitDetails.startDateTime.date + " " + this.newVisitDetails.startDateTime.time,
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                        this.newVisitDetails.eventEndTime = moment(
                            this.newVisitDetails.endDateTime.date + " " + this.newVisitDetails.endDateTime.time,
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    this.newVisitDetails.userId = this.visitDetails.UserId;
                    const firstResponse = await this._taskService.scheduleVisitHC(this.newVisitDetails);
                    if (!firstResponse.IsSuccessful && firstResponse.ErrorDetails) {
                        // if no error list, then simply show the error and end
                        if (!firstResponse.ErrorDetails.TaskIssues.length) {
                            throw new Error(firstResponse.ErrorMessage);
                        }

                        const validationList: IRescheduleValidations[] = firstResponse.ErrorDetails.TaskIssues.map(
                            (item: any) => {
                                return {
                                    title: item.Title as string,
                                    description: [item.Message] as string[],
                                    taskIssueSurpressKey: item.TaskIssueSurpressKey as string
                                };
                            }
                        );
                        const hasError = firstResponse.ErrorDetails.AllTasksHaveErrors;

                        this.dialogRef = this.$dialog.open(RescheduleValidation, {
                            props: {
                                modal: true,
                                closeOnEscape: false,
                                style: {
                                    width: "60vw"
                                },
                                showHeader: false
                            },

                            data: {
                                validations: validationList,
                                name: "Employee",
                                component: "Schedule-visit",
                                hasError
                            },

                            onClose: async (options) => {
                                if (options.data === "cancel") {
                                    return;
                                } else {
                                    const issuesResponseObj: IHandleTaskReschuleIssue = {};
                                    for (let issue of validationList) {
                                        issuesResponseObj[issue.taskIssueSurpressKey] = "ScheduleAllAnyways";
                                    }

                                    this.newVisitDetails.HandleTaskIssues = issuesResponseObj;
                                    const secondResponse = await this._taskService.scheduleVisitHC(
                                        this.newVisitDetails
                                    );
                                    if (!secondResponse.IsSuccessful) {
                                        throw new Error(secondResponse.ErrorMessage);
                                    }
                                    this._toastrService.success({
                                        title: `Visit Scheduled`,
                                        message: `Visit has been successfully Scheduled.`
                                    });

                                    if (saveType == SaveEnum.SaveAndExit) {
                                        // trigger callback
                                        this.ok();
                                    } else {
                                        this.addAnother();
                                    }
                                }
                            }
                        });
                    } else if (!firstResponse.IsSuccessful) {
                        throw new Error(firstResponse.ErrorMessage);
                    } else {
                        this._toastrService.success({
                            title: `Visit Scheduled`,
                            message: `Visit has been successfully Scheduled.`
                        });
                    }

                    if (saveType == SaveEnum.SaveAndExit) {
                        // trigger callback
                        this.ok();
                    } else {
                        this.addAnother();
                    }
                } catch (e) {
                    console.warn(e);
                    this._toastrService.error({
                        title: `Visit Scheduled Failed`,
                        message: e
                            ? e
                            : `Scheduling Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.$emit("savingChange", false);
                }
            }
        },
        handleDateInputChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.taskDateChanged(value, name);
        }
    }
});
