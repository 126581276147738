import { defineComponent, PropType } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import { ApplicationEnum, ConflictTypeEnum } from "../../../../enums/enums";
import type { IGetUserResponse } from "../../../vue-interfaces/i-user";
import {
    IEmployeeDetails,
    ITrackingReasonDetails,
    ITrackingTypeDetails
} from "../../../vue-interfaces/i-homecare-reassign";
import type { ITypeaheadOptions } from "../../../vue-interfaces/i-typeahead";
import type { IPatientBulkReassignVisitPayload } from "../../../vue-interfaces/i-bulk-reassign-visit";
import { BulkReassign } from "../../../vue-models/bulk-reassign";
import { UserService } from "../../../../services/user-service";
import { TaskService } from "../../../../services/task.service";
import { ToastrService } from "../../../../services/toastr.service";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    props: {
        application: { type: Number },
        agencyId: { type: String },
        patientId: { type: String },
        branchId: { type: String },
        selectedTaskId: { type: Array as PropType<string[]> },
        selectedUserId: { type: Array as PropType<string[]> },
        dateTo: { type: String },
        dateFrom: { type: String },
        closeReassignForm: { type: Function },
        pageReset: { type: Function }
    },

    emits: ["updateHosTask", "updatePalTask"],

    components: {
        FormSection,
        TypeaheadInput
    },

    data() {
        return {
            _userService: null,
            _taskService: null,
            _toastrService: null,
            isLoading: false as boolean,
            isSaving: false as boolean,
            isApplicationHomeCare: false as boolean,
            isEmployeeListLoading: false as boolean,
            showAllByDefault: true as boolean,
            employeeLists: [] as IEmployeeDetails[],
            employeeList: [] as IGetUserResponse[],
            cpyTrackingReason: [] as ITrackingReasonDetails[],
            trackingReasons: [] as ITrackingReasonDetails[],
            trackingTypes: [] as ITrackingTypeDetails[],
            selectedConflictTypes: [ConflictTypeEnum.NoConflict] as number[],
            visitDetails: null as BulkReassign,
            employee: {} as ITypeaheadOptions,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            visitDetails: {
                EmployeeTo: { required },
                ReasonId: { required: requiredIf(() => this.isApplicationHomeCare) },
                TypeId: { required: requiredIf(() => this.isApplicationHomeCare) }
            }
        };
    },

    created() {
        this._userService = Container.instance.get(UserService);
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },

    async mounted() {
        this.visitDetails = new BulkReassign();
        this.visitDetails.ReasonId = null;
        this.visitDetails.TypeId = null;

        if (this.application == ApplicationEnum.HomeCare) {
            this.getHomeCareEmployee();
        } else {
            this.getEmployee("");
        }

        if (this.application == ApplicationEnum.HomeCare) {
            this.isApplicationHomeCare = true;
        }

        if (this.isApplicationHomeCare) {
            this.trackingReasons = await this._taskService.getTrackingReasons({
                AgencyId: this.agencyId,
                Application: this.application,
                CategoryId: 1
            });

            this.trackingTypes = await this._taskService.getTrackingTypes({
                AgencyId: this.agencyId,
                Application: this.application,
                CategoryId: 1
            });
        }
    },
    methods: {
        selectedEmployeeChanged(newValue: ITypeaheadOptions) {
            if (newValue) {
                this.visitDetails.EmployeeTo = newValue.value;
            }
        },

        selectedType() {
            if (!!this.visitDetails.TypeId) {
                this.cpyTrackingReason = this.trackingReasons.filter((reasonType) => {
                    return reasonType.TypeId == (this.visitDetails.TypeId as any);
                });
            } else {
                this.visitDetails.ReasonId = null;
                this.cpyTrackingReason = [];
            }
        },

        async getEmployee(term: string) {
            this.employeeList = await this._userService.getUserByBranch({
                application: this.application,
                agencyId: this.agencyId,
                branchId: this.branchId,
                page: 1,
                pageSize: 40,
                term: term
            });
        },

        async getHomeCareEmployee() {
            try {
                this.isEmployeeListLoading = true;
                const employees = await this._taskService.homecareEmployeePreference({
                    PatientId: this.patientId,
                    AgencyId: this.agencyId,
                    SchedulerConflictTypes: this.selectedConflictTypes,
                    Application: ApplicationEnum.HomeCare,
                    LocationId: this.branchId,
                    ignoreConflictTypes: true
                });
                this.employeeLists = employees.filter((employee: any) => {
                    return employee.Id != this.patientId;
                });
            } catch (e) {
                throw e;
            } finally {
                this.isEmployeeListLoading = false;
            }
        },

        filterEmployee(filter: string) {
            if (filter) {
                if (this.application == ApplicationEnum.HomeCare) {
                    const filterHCEmployeeList = this.employeeLists.filter((employee) => {
                        const fullName = `${employee.FirstName} ${employee.LastName}`;
                        return fullName.toLowerCase().includes(filter.toLowerCase());
                    });
                    return filterHCEmployeeList?.map((item) => {
                        return {
                            name: `${item.FirstName} ${item.LastName}`,
                            value: item.Id
                        };
                    });
                } else {
                    this.getEmployee(filter);
                    const filterEmployeeFromList = this.employeeList.filter((employee) => {
                        const fullName = `${employee.firstName} ${employee.lastName}`;
                        return fullName.toLowerCase().includes(filter.toLowerCase());
                    });
                    return filterEmployeeFromList?.map((item) => {
                        return {
                            name: `${item.firstName} ${item.lastName}`,
                            value: item.id
                        };
                    });
                }
            }
            return [];
        },

        async reassignVisit() {
            if (!!this.visitDetails) {
                const validated = await this.v$.$validate();
                if (validated) {
                    try {
                        this.isSaving = true;
                        const taskIds = [] as string[];
                        const userIds = [] as string[];
                        let currentTasks = [] as any[];
                        this.selectedTaskId.map((task: any) => {
                            taskIds.push(task.Id);
                            userIds.push(task.UserId);
                            if (this.application == ApplicationEnum.AxxessHospiceFE) {
                                currentTasks.push({ TaskId: task.Id, EmployeeFrom: task.UserId });
                            }
                            if (this.application == ApplicationEnum.AxxessPalliative) {
                                currentTasks.push({ TaskId: task.Id, EmployeeFrom: task.UserId });
                            }
                        });

                        if (this.application == ApplicationEnum.AxxessHospiceFE) {
                            const HosPayload: IPatientBulkReassignVisitPayload = {
                                PatientId: this.patientId,
                                AgencyId: this.agencyId,
                                Application: this.application,
                                EmployeeTo: this.visitDetails.EmployeeTo,
                                HospiceTaskIds: currentTasks
                            };
                            await this._taskService.patientBulkReassign(HosPayload);
                        } else if (this.application == ApplicationEnum.AxxessPalliative) {
                            const PalPayload: IPatientBulkReassignVisitPayload = {
                                PatientId: this.patientId,
                                AgencyId: this.agencyId,
                                Application: this.application,
                                EmployeeTo: this.visitDetails.EmployeeTo,
                                PalliativeTaskIds: currentTasks
                            };
                            await this._taskService.patientBulkReassign(PalPayload);
                        } else if (this.application == ApplicationEnum.HomeCare) {
                            const homeCarePayload: IPatientBulkReassignVisitPayload = {
                                PatientId: this.patientId,
                                AgencyId: this.agencyId,
                                Application: this.application,
                                EmployeeTo: this.visitDetails.EmployeeTo,
                                StartDate: this.dateFrom,
                                EndDate: this.dateTo,
                                TaskIds: taskIds,
                                ReasonId: this.visitDetails.ReasonId,
                                ReasonMessage: ""
                            };
                            await this._taskService.patientBulkReassign(homeCarePayload);
                        } else {
                            const reassignPayload: IPatientBulkReassignVisitPayload = {
                                PatientId: this.patientId,
                                AgencyId: this.agencyId,
                                Application: this.application,
                                EmployeeFrom: userIds,
                                EmployeeTo: this.visitDetails.EmployeeTo,
                                StartDate: this.dateFrom,
                                EndDate: this.dateTo,
                                TaskIds: taskIds
                            };
                            await this._taskService.patientBulkReassign(reassignPayload);
                        }

                        this._toastrService.success({
                            title: `Visit Reassigned`,
                            message: `Visit has been successfully Reassigned.`
                        });
                        // trigger callback
                        this.cancel();
                        this.pageReset();
                    } catch (e) {
                        this._toastrService.error({
                            title: `Visit Reassigning Failed`,
                            message: `Reassigning Visit Operation Failed, Please Contact Axxess if issue still persists.`
                        });
                    } finally {
                        this.isSaving = false;
                    }
                }
            }
        },

        cancel() {
            this.closeReassignForm();
        }
    }
});
