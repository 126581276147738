import { PropType, defineComponent } from "vue";
import * as moment from "moment";
import { Container } from "aurelia-dependency-injection";
import { UserManager } from "oidc-client";

import type {
    IAsset,
    IConversationNavOptions,
    IGetMessageQuery,
    IMessageItem,
    ISendMessageQuery,
    ISendMessageResult
} from "../../../../../resources-vue/vue-interfaces/messaging-service/i-message";
import { formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { MessageService } from "../../../../../services/message-service";
import { MessageErrorEnum } from "../../../../../enums/enums";

import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import UserMessage from "../UserMessage/UserMessage.vue";
import OthersMessage from "../OthersMessage/OthersMessage.vue";
import eventBus from "../../../../../utilities/eventBus";
import { jitsiConferenceStatus } from "../../../../../enums/jitsiConferenceStatus";

export default defineComponent({
    components: {
        ErrorMessageHandler,
        UserMessage,
        OthersMessage
    },
    props: {
        isError: { type: Boolean, default: false },
        isLoading: { type: Boolean, default: true },
        pageCount: { type: Number, default: 0 },
        messages: { type: Array as PropType<IMessageItem[]>, default: [] },
        selectedMessageIds: { type: Array as PropType<string[]>, default: [] },
        pageChanged: { type: Function },
        newItemsLength: { type: Number, default: 0 },
        isViewDeletedMessagesRequested: { type: Boolean, default: false },
        selectedConversation: { type: Object as PropType<IConversationNavOptions> },
        isBulkDeleteRequested: { type: Boolean, default: false },
        selectAll: { type: Boolean, default: false },
        allMessagesSelected: { type: Boolean, default: false },
        addSelectedMessage: { type: Function, default: null },
        removeSelectedMessage: { type: Function, default: null }
    },
    emits: ["updateIsError", "updateMessages"],
    data() {
        return {
            pageNumber: 1,
            pageSize: 50,
            loadingCount: 2,
            messagesWrapper: null as HTMLElement,
            unReadMessagesBanner: null as HTMLElement,
            messageReferences: [] as HTMLElement[],
            _timeoutId: null as NodeJS.Timeout,
            _messageService: null,
            userOs: "",
            formatDate,
            jitsiDialogOpen: false,
            _userManager: null,
        };
    },
    watch: {
        isError(newValue: boolean) {
            if (typeof newValue == "boolean" && newValue) {
                this.reset();
            }
        },
        async pageNumber(newValue: number, oldValue: number) {
            if (newValue <= 1 || newValue > this.pageCount || !this.messages || this.allPagesLoaded) {
                return;
            }
            await this.pageChanged(this.pageNumber, this.pageSize);

            if (this.messageReferences?.length > 0 && this.newItemsLength > 0) {
                let index = this.newItemsLength - 1;
                this.scrollIndexToView(index);
            }
            this.handleJoinMeetingButtons()
        },
        isViewDeletedMessagesRequested() {
            this.pageNumber = 1;
            this.pageSize = 50;
        },
        $route(to, from) {
            if (to.params.id !== from.params.id) {
                this.handleJoinMeetingButtons();
            }
        }
    },
    created() {
        this._userManager = Container.instance.get(UserManager);
        this._messageService = Container.instance.get(MessageService);
        this.userOs = window.sessionStorage.getItem("userOs");
        eventBus.on("dialogOpened", this.handleJitsiDialogOpened);
        eventBus.on("messageSent", (messageSent: boolean) => {
            if (this.jitsiDialogOpen) {
                setTimeout(() => {
                    this.handleJitsiDialogOpened(messageSent)
                }, 300)
            }
        })
    },
    async mounted() {
        await this.render();
        this.handleScrolling();
    },
    computed: {
        allPagesLoaded() {
            return !!this.messages?.length && this.pageNumber > this.pageCount;
        },
        unReadMessages(): IMessageItem[] {
            return this.messages.filter((message) => !message.isRead);
        },
        readMessages(): IMessageItem[] {
            return this.messages.filter((message) => message.isRead);
        }
    },
    methods: {
        handleJoinMeetingButtons(jitsiDialogOpen: boolean = false) {
            const buttons = Array.from(document.querySelectorAll('.conversation-wrapper .jitsi-join-btn')).reverse() as HTMLButtonElement[];
            const lastButton = buttons[0]
            let jitsiBtnIndex = 1;

            if (buttons.length > 0) {
                for (const button of buttons.slice(1)) {
                    const roomId = button?.getAttribute('data-id');
                    if (roomId) {
                        button.disabled = true;
                    }
                    jitsiBtnIndex++;
                }

                if (jitsiDialogOpen) {
                    lastButton.disabled = true
                } else {
                    setTimeout(() => {
                        const roomId = lastButton?.getAttribute('data-id');
                        if (lastButton && roomId) this.fetchRoomStatus(roomId, lastButton);
                    }, 100)
                }
            }
        },
        async fetchRoomStatus(roomId: string, button: HTMLButtonElement) {
            try {
                const user = await this._userManager.getUser();
                if (!user || !user.profile || !user.profile.sub) {
                    console.error("User or user profile is not properly defined.");
                    button.disabled = true;
                    return;
                }

                const roomStatusResult = await this._messageService.getRoomStatus(roomId, user.profile.sub);
                if (!roomStatusResult || !roomStatusResult.conferenceStatus) {
                    console.error("Invalid room status result.");
                    button.disabled = true;
                    return;
                }

                button.disabled = roomStatusResult.conferenceStatus === jitsiConferenceStatus.NotStarted
                    || roomStatusResult.conferenceStatus === jitsiConferenceStatus.Ended;

            } catch (error) {
                console.error("Error fetching room status:", error);
                button.disabled = true;
            }
        },
        handleJitsiDialogOpened(dialogOpen: boolean) {
            this.jitsiDialogOpen = dialogOpen
            this.handleJoinMeetingButtons(dialogOpen)
        },
        async reset() {
            if (this.isError) {
                this.$emit("updateIsError", false);
            }
            this.pageNumber = 1;
            this.pageSize = 50;
            await this.render();
        },
        scrollIndexToView(index: number) {
            if (this._timeoutId) {
                clearTimeout(this._timeoutId);
            }
            this._timeoutId = setTimeout(() => {
                if (this.messageReferences[index]) {
                    this.messageReferences[index].scrollIntoView();
                }
            }, 0);
        },
        async render() {
            await this.pageChanged(this.pageNumber, this.pageSize);
            if (this.unReadMessages.length > 0) {
                (this.$refs.unReadMessagesBanner as HTMLElement).scrollIntoView();
                // this._ea.publish(MessageListEvent.HasUnreadMessage);
            } else if (!this.isViewDeletedMessagesRequested) {
                this.scrollToBottom();
            }
            this.handleJoinMeetingButtons();
        },
        scrollToBottom() {
            // The delay is set for rendering the messages in the UI
            // Attempts and info:
            // Aurelia does not have any life cycle hook to know when a view updated
            // ref's don't update in repeat.for. Ref's binding is one-time
            // scroll bindings aurelia have on an element does not have scrollHeight
            setTimeout(() => {
                if (this.$refs.messagesWrapper) {
                    this.messagesWrapper = this.$refs.messagesWrapper as HTMLElement;
                    this.messagesWrapper.scrollTop = (this.$refs.messagesWrapper as HTMLElement).scrollHeight;
                    console.log("Scroll top set to:", this.messagesWrapper.scrollTop);
                }
            }, 300);
        },
        showDateBanner(index: number) {
            let readMessages = this.readMessages;
            if (index === 0) {
                return true;
            } else if (readMessages.length > 1) {
                let dateFormat = "MM/DD/YYYY";
                let createdOn = moment.utc(readMessages[index].createdOn).local().format(dateFormat);
                let prevCreatedOn = moment
                    .utc(readMessages[index - 1].createdOn)
                    .local()
                    .format(dateFormat);

                return createdOn !== prevCreatedOn;
            } else {
                return false;
            }
        },
        renderAsset(asset: IAsset) {
            // TODO: Move this to HTML. Try not to have markup in TS files
            if (asset) {
                if (asset.mimeType.indexOf("image") > -1) {
                    return `<img src=${asset.url} />`;
                } else {
                    return (
                        `<a href=${asset.url} target="_blank" class="m-1 py-2 px-2 file-render text-light d-block">` +
                        `<i class="fas ${this.getFileIcon(asset.mimeType)} file-type"></i> ${asset.fileName}` +
                        "</a>"
                    );
                }
            } else {
                return "";
            }
        },
        getFileIcon(type: string) {
            if (type.includes("wordprocessing")) {
                return "fa-file-word";
            } else if (type.includes("spreadsheet")) {
                return "fa-file-excel";
            } else if (type.includes("pdf")) {
                return "fa-file-pdf";
            } else {
                return "fa-file";
            }
        },
        async retryMessage(message: IMessageItem) {
            const user = await this._userManager.getUser();
            let messageQuery: ISendMessageQuery = {
                message: message.text ? message.text : "",
                isImportant: message.isImportant,
                assetIds: [],
                senderDisplayFirstname: user.profile.given_name,
                SenderDisplayLastname: user.profile.family_name
            };
            let createdDateTime = new Date();
            this.updateErrorMessageAsLoading(message);
            let isError: boolean = false;
            for (const asset of message.assets) {
                if (asset.isError) {
                    try {
                        asset.assetId = await this._messageService.uploadFile(asset.file);
                        asset.isError = false;
                    } catch (e) {
                        console.error(e);
                        isError = true;
                    }
                }
            }
            if (isError) {
                this.markMessageError(message.createdOn, MessageErrorEnum.FileUploadError);
            } else {
                try {
                    messageQuery.assetIds = message.assets.map((asset) => asset.assetId);
                    let messagesResult = await this._messageService.sendMessage(
                        this.selectedConversation.id,
                        messageQuery
                    );
                    this.updateSentMessage(messagesResult, message.createdOn, createdDateTime);
                    if (message.assets.length > 0) {
                        await this.reset();
                    }
                } catch (e) {
                    console.error(e);
                    this.markMessageError(message.createdOn, MessageErrorEnum.MessageSendingError);
                }
            }
        },
        updateErrorMessageAsLoading(errorMessage: IMessageItem) {
            let messages = this.messages.slice(0);
            messages.forEach((message) => {
                if (message.isError && message.createdOn == errorMessage.createdOn) {
                    message.isLoading = true;
                }
            });
            this.$emit("updateMessages", messages);
            this.handleJoinMeetingButtons();
        },
        markMessageError(createdDateTime: Date, messageErrorType: MessageErrorEnum) {
            let messages = this.messages.slice(0);
            messages.forEach((message) => {
                if (message.isLoading && message.isError && message.createdOn == createdDateTime) {
                    message.isLoading = false;
                    message.isError = true;
                    message.errorMessage = messageErrorType;
                }
            });
            this.$emit("updateMessages", messages);
            this.handleJoinMeetingButtons();
        },
        updateSentMessage(messagesResult: ISendMessageResult, oldDateTime: Date, newDateTime: Date) {
            let messages = this.messages.slice(0);
            this.moveMessageToTop(oldDateTime);
            messages.forEach((message) => {
                if (message.isLoading && message.isError && message.createdOn == oldDateTime) {
                    message.isLoading = false;
                    message.isError = false;
                    message.createdOn = newDateTime;
                    message.id = messagesResult.id;
                }
            });
            this.$emit("updateMessages", messages);
        },
        moveMessageToTop(dateTime: Date) {
            let messages = this.messages.splice(0);
            let index = messages.findIndex((message) => message.createdOn == dateTime);
            messages.push(messages.splice(index, 1)[0]);
            this.$emit("updateMessages", messages);
        },
        updateSelectedIds(isSelected: boolean, messageId: string) {
            if (isSelected) {
                this.addSelectedMessage({ messageId });
            } else {
                this.removeSelectedMessage({ messageId });
            }
        },
        handleScrolling() {
            let scrollTimer: NodeJS.Timer = null;
            let $scrollableElement: JQuery<Element> = $(this.$refs.messagesWrapper as HTMLElement);
            let self = this;
            $scrollableElement.off("scroll").on("scroll", (e: any) => {
                if (scrollTimer) {
                    clearTimeout(scrollTimer); // clear any previous pending timer
                }
                scrollTimer = setTimeout(() => {
                    let st: number = $scrollableElement.scrollTop();
                    if (st === 0 && !self.isLoading && !self.allPagesLoaded) {
                        self.pageNumber++;
                    }
                }, 100); // set new timer
            });
        }
    },
    beforeDestroy() {
        eventBus.off("dialogOpened", this.handleJitsiDialogOpened);
    },
});
