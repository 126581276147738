import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { MessageService } from "../../../../../services/message-service";
import {
    IGetSharedAssetResult,
    ISharedAssetDialogOptions,
    IConversationUser
} from "../../../../../resources-vue/vue-interfaces/messaging-service/i-message";

import ConversationSharedFileRow from "../ConversationSharedFileRow/ConversationSharedFileRow.vue";
import SortableTh from "../../../../../resources-vue/vue-custom-elements/SortableTh/SortableTh.vue";

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        ConversationSharedFileRow,
        SortableTh
    },

    data() {
        return {
            _messageService: null,
            dialogReference: this.dialogRef as any,
            columns: 4 as number,
            isLoading: false as boolean,
            loadingCount: 5 as number,
            assets: [] as IGetSharedAssetResult[],
            options: {
                title: "",
                conversation: null
            } as ISharedAssetDialogOptions,
            sortOrder: "" as string,
            uploaderId: "" as string,
            sortBy: "" as string,
            sharedFilesUsers: [] as IConversationUser[]
        };
    },

    watch: {
        sortOrder() {
            this.retrieveFiles();
        },
        uploaderId() {
            this.retrieveFiles();
        },
        sortBy() {
            this.retrieveFiles();
        }
    },

    async created() {
        this._messageService = Container.instance.get(MessageService);
        this.options = Object.assign({}, this.dialogReference.data.sharedFilesDialogOptions);
        await this.retrieveFiles();
        if (this.assets.length) {
            let sharedFilesUsers = this.assets.map((asset) => ({
                firstName: asset.uploaderFirstName,
                lastName: asset.uploaderLastName,
                userId: asset.uploaderId
            }));
            this.sharedFilesUsers = sharedFilesUsers.filter(
                (user, pos, arr) => pos === arr.findIndex((current) => current.userId === user.userId)
            );
        }
    },

    methods: {
        async retrieveFiles() {
            this.isLoading = true;
            let assets;
            try {
                assets = await this._messageService.getSharedAssets(this.options.conversation.id, {
                    order: this.sortOrder,
                    uploaderId: this.uploaderId,
                    sortBy: this.sortBy
                });
                this.assets = assets;
                this.isLoading = false;
            } catch (e) {
                this.isLoading = false;
            }
        }
    }
});
