import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";


import { HospiceReferral } from "../../../../../resources-vue/vue-models/hospice-referral";
import { Patient } from "../../../../../resources-vue/vue-models/referral-patient";
import { ToastrService } from "../../../../../services/toastr.service";
import { ApplicationEnum } from "../../../../../enums/enums";

import DiagnosisTableRow from "./DiagnosisTableRow.vue";
import TableEmpty from "../../../../../resources-vue/vue-custom-elements/TableEmpty/TableEmpty.vue";

export default defineComponent({
    components: {
         TableEmpty,
         DiagnosisTableRow
    },
    props: {
        diagnosis: {
            type: Array<any>,
            default: []
        },
        id: {
            type: String
        },
        snapshotView: {
            type: Boolean,
            default: false
        },
        referral: {
            type: HospiceReferral || Patient
        },
        updateDiagnosis: {
            type: Function
        }
    },
    data(vm) {
        return {
            newDiagnosis: [],
            _toastrService: null,
            isNewTerminal: false,
            columns: 5,
            isAddRequested: true
        };
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
    },
    mounted() {
        if (this.diagnosis.length) {
            let temp = [
                ...this.diagnosis.filter((d: any) => !d.resolvedDate).sort((a: any, b: any) => a.order - b.order),
                ...this.diagnosis.filter((d: any) => d.resolvedDate)
            ];
            this.updateDiagnosis(temp);
        }
        if (this.diagnosis && this.diagnosis[0] && this.diagnosis[0].order != 1) {
            let temp = Array(this.diagnosis[0].order - 1)
                .fill(null)
                .concat(this.diagnosis);
            this.updateDiagnosis(temp);
        }
    },
    computed: {
        activeDiagnosis() {
            if (this.diagnosis.length > 0) {
                return !this.snapshotView
                    ? this.diagnosis
                          .filter((dia: any) => !dia.resolvedDate)
                          .map((diag: any, index) => {
                              diag.order = index + 1;
                              if (diag.order === 1) {
                                  diag.isRelated = null;
                                  diag.notRelatedComments = null;
                              }
                              return diag;
                          })
                    : this.diagnosis;
            }
            return [];
        },
        isApplicationPalliative(): boolean {
            return this.referral.application == ApplicationEnum.AxxessPalliative;
        }
    },
    methods: {
        addDiagnosis() {
            if (this.diagnosis.length == 0) {
                this.isNewTerminal = true;
            } else {
                this.isNewTerminal = false;
            }
            this.newDiagnosis.push({
                code: "",
                description: "",
                order: null,
                isRelated: null,
                notRelatedComments: null,
                startDate: null,
                resolvedDate: null,
                billable: null
            });
        },
        saveNewDiagnosis(index: number) {
            let arr = [...this.diagnosis];
            this.newDiagnosis[index].order = this.activeDiagnosis.length + 1;
            arr.push(this.newDiagnosis[index]);
            this.newDiagnosis.splice(index, 1);
            let temp = [...arr.filter((d: any) => !d.resolvedDate), ...arr.filter((d: any) => d.resolvedDate)];
            this.updateDiagnosis(temp);
        },
        cancelSaveNewDiagnosis(index: number) {
            this.newDiagnosis.splice(index, 1);
        },
        makePrimary(index: number) {
            let arr =[... this.diagnosis];
            let newPrimary: any = arr[index];
            newPrimary.isRelated = null;
            newPrimary.notRelatedComments = null;
            let oldPrimary = arr[0];
            arr[0] = newPrimary;
            arr[index] = oldPrimary;
            arr = arr.map((diagnosis: any, i) => {
                if (!diagnosis.resolvedDate) {
                    diagnosis.order = i + 1;
                }
                return diagnosis;
            });
            let temp = arr;
            this.updateDiagnosis(temp);
            this.showDiagnosisMessage();
        },
        moveDiagnosis(oldIndex: number, newIndex: number) {
            let arr = this.diagnosis;
            if (oldIndex >= arr.length || oldIndex < 0) {
                return;
            }
            if (newIndex >= arr.length || newIndex < 0) {
                return;
            }
            arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            arr = arr.map((diagnosis: any, index) => {
                if (!diagnosis.resolvedDate) {
                    diagnosis.order = index + 1;
                }
                return diagnosis;
            });
            let temp = arr;
            this.updateDiagnosis(temp);
            if (oldIndex === 0 || newIndex === 0) {
                this.showDiagnosisMessage();
            }
        },
        resolveDiagnosis(index: number) {
            const limit = this.isApplicationPalliative ? 0 : 1;
            if (this.diagnosis.length > limit && index < this.diagnosis.length) {
                let orderCount = 1;
                let temp = this.diagnosis.map((diagnosis: any) => {
                    if (!diagnosis.resolvedDate) {
                        diagnosis.order = orderCount;
                        orderCount++;
                    }
                    return diagnosis;
                });
                this.updateDiagnosis(temp);
            }
        },
        deleteDiagnosis(index: number) {
            this.diagnosis.splice(index, 1);
            let temp = this.diagnosis.map((diagnosis: any, diagnosisIndex) => {
                if (!diagnosis.resolvedDate) {
                    diagnosis.order = diagnosisIndex + 1;
                }
                return diagnosis;
            });
            this.updateDiagnosis(temp);
        },
        showDiagnosisMessage(): any {
            if (this.diagnosis.length > 1 && !this.isApplicationPalliative) {
                this._toastrService.info({
                    title: "New Terminal Diagnosis",
                    message:
                        "You have added a new Terminal Diagnosis. Please review all Comorbidities and update their relatedness status based on the new Terminal Diagnosis."
                });
            }
        },
        updateDiag(value: any, index: number) {
            let temp = [...this.newDiagnosis];
            temp = temp.map((el, idx) => {
                if (idx === index) {
                    return value;
                } else {
                    return el;
                }
            });
            this.newDiagnosis = temp;
        }
    }
});
