import { defineComponent } from "vue";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import { formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { ApplicationEnum, LineOfServices } from "../../../../../enums/enums";
import { Patient } from "../../../../../resources-vue/vue-models/referral-patient";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import NewReferralInformation from "../NewReferralInformation/NewReferralInformation.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        NewReferralInformation
    },

    setup() {
        const storeReferral = useCreateReferralHosPal();
        const referral: any = storeReferral.referral;
        return { referral };
    },

    validations() {
        return {
            referral: {
                referralDate: { required },
                referralReason: {
                    required: requiredIf(() => {
                        return this.referral.application !== this.hospiceApplicationId;
                    })
                }
            }
        };
    },

    props: {
        providerId: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            datePickerOptions: {} as DatepickerOptions,
            hospiceApplicationId: ApplicationEnum.AxxessHospiceFE,
            palliativeApplicationId: ApplicationEnum.AxxessPalliative,
            lineOfServices: Array.from(LineOfServices.enumWithDescriptions.values()),
            displayLineOfServices: null,
            formatDateInput,
            v$: useVuelidate()
        };
    },

    created() {
        if (!!(this.referral as Patient)?.prevDischargeDate) {
            this.datePickerOptions.startDate = moment((this.referral as Patient)?.prevDischargeDate).format(
                "MM/DD/YYYY"
            );
        }
        if (!!(this.referral as Patient)?.currentAdmissionDate) {
            this.datePickerOptions.endDate = moment((this.referral as Patient)?.currentAdmissionDate).format(
                "MM/DD/YYYY"
            );
        }
        this.displayLineOfServices = this.lineOfServices.filter(
            (lineOfService: any) => lineOfService.value != LineOfServices.getValueUsingKey("SeriouslyIllPopulation")
        );
    },

    computed: {
        lineOfServiceOptions() {
            return this.displayLineOfServices?.map((option: any) => ({
                name: option.name,
                value: option.value
            }));
        }
    },

    methods: {
        handleDateChange(date: any, name: string) {
            if (name === "referralDate") {
                this.referral.referralDate = date;
            }
        }
    }
});
