import { defineComponent, computed } from "vue";
import { Container } from "aurelia-dependency-injection";

import { LookupService } from "../../../../../services/lookup-service";
import { DmeNeeded } from "../../../../../resources-vue/vue-models/dme-needed";
import { type IPromptOptions } from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt";
import type { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";

import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import Prompt from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";

export default defineComponent({
    components: {
        TypeaheadInput
    },
    props: {
        referral: { type: Object, default: null },
        dmeIndex: { type: Number, default: null },
        dmeNeeded: { type: DmeNeeded, default: null }
    },
    data() {
        return {
            dmeData: null as ITypeaheadOptions,
            dialogRef: null,
            _lookupService: null
        };
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },
    watch: {
        dmeData(newValue, oldValue) {
            if (newValue) {
                this.dmeNeeded.dmeId = newValue.value;
            }
        }
    },
    created() {
        this._lookupService = Container.instance.get(LookupService);
    },
    methods: {
        async getDmesList(filter: string) {
            let data = await this._lookupService.getDmes({
                agencyId: this.referral.accountId,
                term: filter,
                application: this.referral.application
            });
            return data.map((item: any) => ({
                name: item.text,
                value: item.id
            }));
        },
        changeDmeData(data: any) {
            this.dmeData = data;
        },
        async removeEquipment(index: number) {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to delete this DME?",
                okText: "Yes",
                cancelText: "No"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: (options) => {
                    if (options.data === "success") {
                        this.referral.dmeNeeded[index].isDeleted = true;
                        this.referral.dmeNeeded.splice(index, 1);
                    }
                }
            });
        }
    }
});
