import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    data() {
        return {
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            referral: {
                Demographics: {
                    PreferredLanguage: { required },
                    NeedInterpreter: { required }
                }
            }
        };
    },

    computed: {
        ...mapState(useEditReferralHH, ["referralOptions", "referral", "currentApplication", "errors"])
    },

    methods: {
        ...mapActions(useEditReferralHH, { setError: "SET_NEW_ERRORS" }),
        handleInterpreterRequired(value: string) {
            if (this.referral.Demographics.InterpreterRequiredOptions.includes(value)) {
                this.referral.Demographics.InterpreterRequiredOptions =
                    this.referral.Demographics.InterpreterRequiredOptions.filter((val: string) => val !== value);
            } else {
                this.referral.Demographics.InterpreterRequiredOptions.push(value);
            }
        },
        showOther() {
            return this.referral.Demographics.InterpreterRequiredOptions.includes("3");
        },
        handleNeedInterpreterChange(value: string) {
            if (value === "0") {
                this.referral.Demographics.NeedInterpreter =
                    this.referral.Demographics.NeedInterpreter === "0" ? "" : value;
                this.handleAllInput(value, "NeedInterpreter");
            } else if (value === "1") {
                this.referral.Demographics.NeedInterpreter =
                    this.referral.Demographics.NeedInterpreter === "1" ? "" : value;
                this.handleAllInput(value, "NeedInterpreter");
            } else {
                this.referral.Demographics.NeedInterpreter =
                    this.referral.Demographics.NeedInterpreter === "2" ? "" : value;
                this.handleAllInput(value, "NeedInterpreter");
            }
        },
        handleAllInput(value: any, field: string) {
            if (value.length) {
                const newErrors = [...new Set(this.errors.Demographics)].filter((val: any) => val !== field);
                const payload = {
                    property: "Demographics",
                    value: newErrors
                };
                this.setError(payload);
            }
        }
    }
});
