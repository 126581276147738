import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";
import { TaskQueue } from "aurelia-framework";

import { LookupService } from "../../../../../services/lookup-service";
import type { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { formatZipCode } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { VendorsService } from "../../../../../services/vendor-service";
import type { IGetVendorByIdQuery } from "../../../../../resources-vue/vue-interfaces/i-vendor";
import type { IUpdatedPlacesAddress } from "../../../../../resources-vue/vue-interfaces/i-places-autocomplete";
import { HomeCareAddress } from "../../../../../resources-vue/vue-models/homecare-address";

import CountryTypeahead from "../../../../../resources-vue/vue-custom-elements/CountryTypeahead/CountryTypeahead.vue";
import PlacesAutocomplete from "../../../../../resources-vue/vue-custom-elements/PlacesAutocomplete/PlacesAutocomplete.vue";
import StateTypeahead from "../../../../../resources-vue/vue-custom-elements/StateTypeahead/StateTypeahead.vue";

export default defineComponent({
    components: {
        CountryTypeahead,
        PlacesAutocomplete,
        StateTypeahead
    },
    props: {
        address: { type: Object, default: null },
        facilityId: { type: String, default: "" },
        application: { type: Number, default: null },
        accountId: { type: String, default: null },
        isPrimaryAddress: { type: Boolean, default: false },
        providerId: { type: String, default: "" },
        showCode: { type: Boolean, default: false },
        disableInputs: { type: Boolean, default: false },
        resetSameAsPrimaryAddress: { type: Function, default: null },
        showMoveDate: { type: Boolean, default: false },
        isAddressValidationInProgress: { type: Boolean, default: false }
    },
    data() {
        return {
            ignorePrefillChange: null,
            selectedCountry: null as ITypeaheadOptions,
            selectedFacility: null,
            selectedState: null,
            autoCompleteCountry: null,
            unitedStatesCountryCode: null,
            _tq: null,
            _autoCompleteAddress: {},
            _vendorsService: null,
            _lookupService: null,
            v$: useVuelidate(),
            formatZipCode
        };
    },
    validations() {
        return {
            address: {
                addressLine1: { required },
                city: { required },
                state: { required },
                zipCode: { required }
            }
        };
    },
    computed: {
        isUnitedStates() {
            return this.selectedCountry?.name.toLowerCase() === "united states of america";
        },
        formattedZipCode() {
            return formatZipCode(this.address.zipCode);
        },
        stateValidation() {
            if (this.isUnitedStates) {
                if (!this.address.state) {
                    return true;
                } else {
                    return false;
                }
            } else return false;
        }
    },
    inject: ["v"],

    created() {
        this._vendorsService = Container.instance.get(VendorsService);
        this._lookupService = Container.instance.get(LookupService);
        this._tq = Container.instance.get(TaskQueue);
    },
    async mounted() {
        this._autoCompleteAddress = new HomeCareAddress();
        if (!this.facilityId) {
            this.selectedFacility = null;
        } else {
            const payload: IGetVendorByIdQuery = {
                application: this.application,
                accountId: this.accountId,
                id: this.facilityId
            };
            let selectedVendor = await this._vendorsService.getVendorById(payload);
            if (!!selectedVendor) {
                this.selectedFacility = {
                    name: selectedVendor.name,
                    value: selectedVendor.id
                };
            }
        }
        this.unitedStatesCountryCode = await this._lookupService.getUnitedStatesCode();
        if (!this.address.country) {
            this.address.country = this.unitedStatesCountryCode;
        }

        if (this.isPrimaryAddress) {
            if (!this.address.latitude || !this.address.longitude) {
                this.address.isValidated = false;
            }
            this._autoCompleteAddress = Object.assign({}, this.address);
        }
    },
    emits: [
        "updateAddressDetails",
        "updateAddressCountry",
        "updateAddressState",
        "update:facilityId",
        "update:address"
    ],
    watch: {
        async selectedFacility(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions) {
            if (newValue !== oldValue) {
                if (!!this.selectedFacility && this.selectedFacility.value !== this.facilityId) {
                    this.$emit("update:facilityId", this.selectedFacility.value);
                    const payload: IGetVendorByIdQuery = {
                        application: this.application,
                        accountId: this.accountId,
                        id: this.facilityId
                    };
                    let selectedVendor = await this._vendorsService.getVendorById(payload);
                    if (!!selectedVendor) {
                        Object.assign(this._autoCompleteAddress, selectedVendor.address);
                        this.$emit("updateAddressDetails", selectedVendor.address);
                    }
                } else if (!this.selectedFacility) {
                    this.$emit("update:facilityId", null);
                    this.$emit("update:address", new HomeCareAddress());
                }
                if (this.isPrimaryAddress) {
                    // this.resetSameAsPrimaryAddress();
                }
            }
        },
        selectedCountry() {
            if (!!this.selectedCountry) {
                this.$emit("updateAddressCountry", this.selectedCountry.value);
            } else {
                this.$emit("updateAddressCountry", "");
            }
            this.$emit("update:address", new HomeCareAddress(this.address as HomeCareAddress));
            if (this.isPrimaryAddress) {
                // this.resetSameAsPrimaryAddress();
            }
        },
        selectedState() {
            if (!!this.selectedState) {
                this.$emit("updateAddressState", this.selectedState.value);
            } else {
                this.$emit("updateAddressState", "");
            }
            if (this.isPrimaryAddress) {
                // this.resetSameAsPrimaryAddress();
            }
        }
    },
    methods: {
        placesChangedCallBack(params: IUpdatedPlacesAddress) {
            Object.assign(this._autoCompleteAddress, params);
            this.ignorePrefillChange = true;
            this.address.addressLine1 = params.addressLine1;
            this.address.city = params.city;
            this.address.county = params.county;
            this.address.state = params.state;
            this.address.zipCode = params.zipCode;
            this.address.latitude = params.latitude;
            this.address.longitude = params.longitude;
            this.address.isValidated = true;
            this.address.isBypassed = false;
            this._tq.queueMicroTask(() => (this.ignorePrefillChange = false));
        },
        updateSelectedCountry(value: any) {
            this.selectedCountry = value;
        },
        updateSelectedState(value: any) {
            this.selectedState = value;
        },
        handleZipcodeChange(e: any) {
            this.address.zipCode = e.target.value;
        }
    }
});
