import { valueConverter } from "aurelia-framework";

import { EnumWithDescription } from "../../enums/enums";

@valueConverter("enumKeyToValue")
export class EnumKeyToValue<T> {
    public toView(enumValue: T, EnumClass: EnumWithDescription<T>): string {
        if (enumValue) {
            return EnumClass.getKeyUsingValue(enumValue);
        } else {
            return null;
        }
    }

    public fromView(key: string, EnumClass: EnumWithDescription<T>): any {
        if (key) {
            return EnumClass.getValueUsingKey(key);
        } else {
            return null;
        }
    }
}

@valueConverter("enumValueToKey")
export class EnumValueToKey<T> {
    public toView(enumValue: any, EnumClass: EnumWithDescription<T>): string {
        if (enumValue) {
            return EnumClass.getKeyUsingValue(enumValue);
        } else {
            return null;
        }
    }
}

@valueConverter("enumValueToDescription")
export class EnumValueToDescription<T> {
    public toView(enumValue: any, EnumClass: EnumWithDescription<T>): string {
        // Checking enumValue is null or undefined because enumValue could be 0
        if (enumValue != undefined || enumValue != null) {
            return EnumClass.getDescriptionUsingValue(enumValue);
        } else {
            return null;
        }
    }
}

@valueConverter("enumToMap")
export class EnumToMap<T> {
    public toView(EnumClass: EnumWithDescription<T>) {
        return EnumClass.enumWithDescriptions;
    }
}
@valueConverter("enumToMapSorted")
export class EnumToMapSorted<T> {
    public toView(EnumClass: EnumWithDescription<T>) {
        return new Map([...EnumClass.enumWithDescriptions.entries()].reverse());
    }
}
