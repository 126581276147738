import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { IPageCallbackResult } from "../../../resources-vue/vue-interfaces/i-page-container";
import { CentralIntakeService } from "../../../services/central-intake.service";
import { IGetInquiryResult } from "../../../resources-vue/vue-interfaces/i-get-inquiry";
import type { ICreateResponse } from "../../../resources-vue/vue-interfaces/i-response";
import { Inquiry } from "../../../resources-vue/vue-models/inquiry";
import config from "../../../common/config";
import { formatName } from "../../../common/format-name";
import { EnumMap } from "../../../common/enum-map";
import { EnumsService } from "../../../services/enums.service";
import { BranchesService } from "../../../services/branches-service";
import { ToastrService } from "../../../services/toastr.service";
import { IBranchResponse } from "../../../resources-vue/vue-interfaces/i-branch";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../../enums/enums";
import { AgencyService, IGetOrganization } from "../../../services/agency.service";

import CentralInquiryRow from "./CentralInquiryRow.vue";
import ListLayout from "../../../resources-vue/vue-custom-elements/ListLayout/ListLayout.vue";
import SortableTh from "../../../resources-vue/vue-custom-elements/SortableTh/SortableTh.vue";
import ErrorMessageHandler from "../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import BranchDefaultFilter from "../../../resources-vue/vue-custom-elements/BranchDefaultFilter/BranchDefaultFilter.vue";
import InquiryForm from "../../../resources-vue/vue-forms/InquiryForm/InquiryForm.vue";
import InquiryFormHomecareHomehealth from "../../../resources-vue/vue-forms/InquiryFormHomecareHomehealth/InquiryFormHomecareHomehealth.vue";
import Pagination from "../../../resources-vue/vue-elements/Pagination/Pagination.vue";

export const PAGE_FAIL_RESPONSE: IPageCallbackResult = {
    totalPageCount: 0,
    success: false,
    totalCount: 0
};

export default defineComponent({
    components: {
        Pagination,
        CentralInquiryRow,
        ErrorMessageHandler,
        SortableTh,
        ListLayout,
        BranchDefaultFilter,
        InquiryForm,
        InquiryFormHomecareHomehealth
    },
    data() {
        return {
            _bindingEngine: null,
            _centralIntakeService: null,
            _toastrService: null,
            _enumsService: null,
            _branchService: null,
            _agencyService: null,
            _permissionManager: null,
            count: 0,
            pageSize: 10,
            isLoading: false,
            selectedBranches: [] as string[],
            responseHandler: { data: null, stamp: null, requests: [] },
            isError: false,
            sortBy: "date",
            sortOrder: "asc",
            term: "",
            page: null,
            branchRef: null,
            columns: 9,
            pageOrder: 10,
            hideTitle: false,
            isAddItemRequested: false,
            lineOfServiceEnum: [],
            lineOfServiceEnumMap: new EnumMap([]),
            referralsAddable: [],
            branches: [],
            paginationInstance: null,
            canAddHospiceInquiry: false,
            canAddPalliativeInquiry: false,
            canAddHomeCareInquiry: false,
            canAddHomeHealthInquiry: false,
            moveListDown: false,
            isSaveNewInProgress: false,
            isEditSaveInProgress: false,
            newInquiry: null,
            serviceTypeEnums: [],
            selectedBranchesLength: 0,
            currentAddBranches: [],
            hospiceBranches: [],
            palliativeBranches: [],
            homeCareBranches: [],
            homeHealthBranches: [],
            currentEditBranches: [],
            searchTimer: null,
            newInquiryId: "",
            showNewInquiryDropdown: true,
            organizations: [] as IGetOrganization[]
        };
    },
    async created() {
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._enumsService = Container.instance.get(EnumsService);
        this._branchService = Container.instance.get(BranchesService);
        this._toastrService = Container.instance.get(ToastrService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._agencyService = Container.instance.get(AgencyService);
        this.lineOfServiceEnum = await this._enumsService.getLineOfService();
        this.lineOfServiceEnumMap = new EnumMap(this.lineOfServiceEnum);
        this.serviceTypeEnums = await this._enumsService.getLineOfService();
        this.fillReferralsAddable();
        this.paginationInstance = this.$refs?.paginationInstance as typeof Pagination;
        const branches = await this._branchService.getAllBranches();
        this.hospiceBranches = this.branches.filter((branch) => branch.application == ApplicationEnum.AxxessHospiceFE);
        this.palliativeBranches = this.branches.filter(
            (branch) => branch.application == ApplicationEnum.AxxessPalliative
        );
        this.homeCareBranches = this.branches.filter((branch) => branch.application == ApplicationEnum.HomeCare);
        this.homeHealthBranches = this.branches.filter((branch) => branch.application == ApplicationEnum.AgencyCore);
    },

    computed: {
        hasData() {
            return this.page?.itemCount > 0;
        },

        loadingCount() {
            return this.page?.items?.length > 0 ? this.page.items.length : config.pageSize;
        },

        hospiceApplicationId() {
            return ApplicationEnum.AxxessHospiceFE;
        },

        palliativeApplicationId() {
            return ApplicationEnum.AxxessPalliative;
        },

        homeCareApplicationId() {
            return ApplicationEnum.HomeCare;
        },

        homeHealthApplicationId() {
            return ApplicationEnum.AgencyCore;
        },

        isApplicationHomeCare(): boolean {
            return this.newInquiry.application == ApplicationEnum.HomeCare;
        },

        isApplicationHomeHealth(): boolean {
            return this.newInquiry.application == ApplicationEnum.AgencyCore;
        }
    },

    async mounted() {
        this.organizations = await this._agencyService.getOrganizations();
        const organizationId = localStorage.getItem(config.organizationId);
        const selectOrganization = this.organizations.filter((organization) => organization.id == organizationId);

        const selectedAgencies = selectOrganization[0].agencies;
        const agencyList = selectedAgencies.map((agency) => {
            let agencyAndApplicationIds = agency.id.split("_");
            let application = parseInt(agencyAndApplicationIds[1], 10);

            return {
                ...agency,
                application: application
            };
        });

        const canAddHosInquiry = agencyList.some((el: any) => el.application === ApplicationEnum.AxxessHospiceFE);
        const canAddPalInquiry = agencyList.some((el: any) => el.application === ApplicationEnum.AxxessPalliative);
        const canAddHcInquiry = agencyList.some((el: any) => el.application === ApplicationEnum.HomeCare);
        const canAddHhInquiry = agencyList.some((el: any) => el.application === ApplicationEnum.AgencyCore);

        this.paginationInstance = this.$refs?.paginationInstance as typeof Pagination;
        this.canAddHospiceInquiry =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessHospiceFE
            ) && canAddHosInquiry;
        this.canAddPalliativeInquiry =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AxxessPalliative
            ) && canAddPalInquiry;
        this.canAddHomeCareInquiry =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.HomeCare
            ) && canAddHcInquiry;
        this.canAddHomeHealthInquiry =
            this._permissionManager.checkPermissionByProduct(
                ParentPermissionEnum.inquiries,
                PermissionActionEnum.canAdd,
                ApplicationEnum.AgencyCore
            ) && canAddHhInquiry;
    },

    watch: {
        term() {
            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(
                (term) => {
                    if (term?.length > 2 || term === "") {
                        this.search();
                    }
                },
                500,
                this.term
            );
        },
        selectedBranches: {
            handler() {
                this.pageReset();
            },
            deep: true
        }
    },

    methods: {
        sortByUpdated(value: string) {
            if (value != this.sortBy) {
                this.sortBy = value;
                this.reset();
            }
        },

        sortOrderUpdated(value: string) {
            if (value != this.sortOrder) {
                this.sortOrder = value;
                this.reset();
            }
        },

        updatePageSizeChanged(value: number) {
            this.pageSize = value;
        },

        reset() {
            this.paginationInstance?.reset();
        },

        async fillReferralsAddable() {
            this.branches = await this._branchService.getAllBranches();
            this.referralsAddable = this.branches.filter((branch: IBranchResponse) =>
                this.isReferralAddAllowed(branch)
            );
        },

        isReferralAddAllowed(branch: IBranchResponse): boolean {
            if (!branch.application || !branch.accountId) return false;
            return this._permissionManager.checkPermission(
                ParentPermissionEnum.referrals,
                PermissionActionEnum.canAdd,
                branch.accountId
            );
        },

        async pageChangedCallback(pageNumber: number, pageSize: number): Promise<IPageCallbackResult> {
            if (this.selectedBranches.length === 0) {
                return PAGE_FAIL_RESPONSE;
            }
            try {
                let callTime = Date.now();
                this.responseHandler.stamp = callTime;
                this.isLoading = true;
                this.responseHandler.data = null;
                this.isError = false;
                let payLoad = {
                    page: pageNumber,
                    pageLength: pageSize,
                    sortBy: this.sortBy,
                    order: this.sortOrder,
                    term: this.term,
                    locationIds: this.selectedBranches
                };
                let response = this._centralIntakeService.inquiries(payLoad);
                this.responseHandler.requests.push(response);
                let data: IGetInquiryResult = await response;
                if (this.responseHandler.stamp == callTime) {
                    this.responseHandler.data = data;
                } else {
                    data =
                        this.responseHandler.data ||
                        (await this.responseHandler.requests[this.responseHandler.requests.length - 1]);
                }
                this.page = data;
                this.page.items = this.page.items.map((item: Inquiry) => new Inquiry(item.application, item, true));
                return {
                    totalPageCount: data.pageCount,
                    success: data.itemCount > 0,
                    totalCount: data.itemCount
                };
            } catch (e) {
                this.isError = true;
                return PAGE_FAIL_RESPONSE;
            } finally {
                this.isLoading = false;
            }
        },

        search() {
            if (!this.isLoading) {
                this.pageReset();
            }
        },

        pageReset() {
            this.isError = false;
            this.reset();
        },

        updateBranches(branches: any) {
            this.selectedBranches.length = 0;
            this.selectedBranches.push(...branches);
        },

        initInquiry(application: ApplicationEnum) {
            return new Inquiry(application, null, true);
        },

        openAddInquiry(applicationId: ApplicationEnum) {
            this.isAddItemRequested = true;
            this.moveListDown = true;
            this.newInquiry = this.initInquiry(applicationId);
            this.newInquiry.application = applicationId;
            this.showNewInquiryDropdown = false;
        },

        closeAddInquiry(application: ApplicationEnum) {
            this.newInquiry = this.initInquiry(application);
            this.isAddItemRequested = false;
            this.moveListDown = false;
        },

        updateAddBranch(currentApplication: ApplicationEnum) {
            switch (currentApplication) {
                case ApplicationEnum.AxxessHospiceFE:
                    this.currentAddBranches = this.hospiceBranches;
                    break;
                case ApplicationEnum.AxxessPalliative:
                    this.currentAddBranches = this.palliativeBranches;
                    break;
                case ApplicationEnum.HomeCare:
                    this.currentAddBranches = this.homeCareBranches;
                    break;
                case ApplicationEnum.AgencyCore:
                    this.currentAddBranches = this.homeHealthBranches;
                    break;
                default:
                    this.currentAddBranches = [];
            }
        },

        updateEditBranch(applicationId: ApplicationEnum) {
            switch (applicationId) {
                case ApplicationEnum.AxxessHospiceFE:
                    this.currentEditBranches = this.hospiceBranches;
                    break;
                case ApplicationEnum.AxxessPalliative:
                    this.currentEditBranches = this.palliativeBranches;
                    break;
                case ApplicationEnum.HomeCare:
                    this.currentEditBranches = this.homeCareBranches;
                    break;
                case ApplicationEnum.AgencyCore:
                    this.currentEditBranches = this.homeHealthBranches;
                    break;
                default:
                    this.currentEditBranches = [];
            }
        },
        async saveNewInquiry() {
            try {
                let validated: boolean;
                if (this.isApplicationHomeCare || this.isApplicationHomeHealth) {
                    validated = await (this.$refs.homecareInquiryForm as any).v$.$validate();
                } else {
                    validated = await (this.$refs.inquiryForm as any).v$.$validate();
                }
                if (validated) {
                    this.isSaveNewInProgress = true;
                    let result: ICreateResponse = await this._centralIntakeService.createInquiry(this.newInquiry);
                    let patientName = formatName(this.newInquiry.firstName, this.newInquiry.lastName, "FN LN");
                    this.isSaveNewInProgress = false;
                    this.closeAddInquiry(this.newInquiry.application);
                    this.newInquiryId = result.id;
                    this.newInquiry = this.initInquiry(this.newInquiry.application);
                    this._toastrService.success({
                        title: `Inquiry Added`,
                        message: `Inquiry has been successfully added.`
                    });
                    await this.paginationInstance.reset();
                    this.emphasizeNewItem();
                } else {
                    this._toastrService.error({
                        title: `Validation Failed`,
                        message: `Fill all required fields.`
                    });
                }
            } catch (e) {
                this.isSaveNewInProgress = false;
            }
        },
        emphasizeNewItem() {
            let newTimer: number = 0;
            let newInquiryAnimationLimit = 3; // 5 seconds
            let intervalId = setInterval(() => {
                newTimer++;
                if (newTimer > newInquiryAnimationLimit) {
                    this.newInquiryId = "";
                    clearInterval(intervalId);
                }
            }, 1000);
        }
    }
});
