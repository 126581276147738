<template>
    <tr :class="`${isEditRequested || isResolveRequested || isAddRequested ? 'edit-row' : ''}`">
        <td :colspan="`${isEditRequested || isAddRequested ? '2' : '1'} align-top`">
            <div class="form-group mb-0" v-if="isEditRequested || isAddRequested">
                <Diagnosis
                    :selected-diagnosis="selectedDiagnosis"
                    :diagnosis-changed="diagnosisChanged"
                    :disabled="snapshotView"
                    :application="referral.application"
                />
            </div>
            <span v-else>
                <EmptyTextHandler :text="diag.code" />
                <span class="badge badge-pill badge-outline-info ml-2" v-if="diag.order === 1 && !isPalliative">
                    Terminal Diagnosis
                </span>
            </span>
        </td>
        <td v-if="!isEditRequested && !isAddRequested">
            <EmptyTextHandler :text="diag.description" />
        </td>
        <td class="align-top" v-if="!isPalliative">
            <template v-if="!first && !isNewTerminal">
                <div class="form-group mb-0">
                    <select
                        class="form-control"
                        name="related-diag"
                        v-model="diag.isRelated"
                        v-if="isEditRequested || isAddRequested"
                    >
                        <option :value="null">Select Related</option>
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                    </select>
                </div>
                <span v-if="!isEditRequested && !isAddRequested">
                    <span v-if="diag.isRelated !== null"> {{ diag.isRelated ? "Yes" : "No" }} </span>
                    <EmptyTextHandler :text="diag.isRelated" v-else />
                    <a
                        tabindex="0"
                        href="javascript:void(0);"
                        role="button"
                        v-if="diag.notRelatedComments && diag.notRelatedComments.length > 0"
                        v-custom-popover="{ content: diag.notRelatedComments }"
                    >
                        <i class="fas fa-info-circle text-primary ml-2"></i>
                    </a>
                </span>
            </template>
        </td>
        <td class="align-top">
            <div class="form-group mb-0" v-if="isEditRequested || isAddRequested">
                <DatePicker :getDate="handleDateChange">
                    <div class="input-group date datepickerInput">
                        <input
                            name="startDate"
                            type="text"
                            class="form-control"
                            placeholder="MM/DD/YYYY"
                            :value="formatDateInput(diag.startDate)"
                            @change="handleDate"
                            autocomplete="off"
                            :disabled="snapshotView"
                        />
                        <div class="input-group-addon input-group-append">
                            <div class="input-group-text">
                                <i class="fa fa-calendar"></i>
                            </div>
                        </div>
                    </div>
                </DatePicker>
            </div>
            <EmptyTextHandler :text="formatDate(diag.startDate)" v-else />
        </td>
        <td v-if="snapshotView">
            <span v-if="diag.resolvedDate">{{ formatDate(diag.resolvedDate) }}</span>
            <span v-else>Current</span>
        </td>
        <template v-if="!isPalliative || (isPalliative && !isDeleteRequested)">
            <td class="text-right" v-if="!isEditRequested && !isResolveRequested && !isAddRequested">
                <button
                    class="btn table-btn btn-outline-info mr-1"
                    @click="moveDiagnosisUp(index, index - 1)"
                    v-if="!first && !snapshotView"
                    v-tooltip="{ title: 'Move Diagnosis Up' }"
                >
                    <i class="fas fa-chevron-up"></i>
                </button>
                <button
                    class="btn table-btn btn-outline-info mr-1"
                    @click="moveDiagnosisDown(index, index + 1)"
                    v-if="!last && !snapshotView"
                    v-tooltip="{ title: 'Move Diagnosis Down' }"
                >
                    <i class="fas fa-chevron-down"></i>
                </button>
                <button
                    class="btn table-btn btn-outline-danger mr-1"
                    v-if="!first && !diag.id && !snapshotView && !isPalliative"
                    @click="deleteDiagnosis(index)"
                >
                    <i class="fas fa-trash-alt"></i>
                </button>
                <button class="btn table-btn btn-outline-info" @click="openEditDiagnosis()">
                    <i class="fas fa-edit"></i>
                </button>
                <div
                    class="btn-group table-btn-group"
                    v-if="isPalliative || (!isPalliative && !first && !snapshotView)"
                >
                    <button
                        type="button"
                        class="btn px-1 py-0 btn-outline-primary dropdown-toggle"
                        data-toggle="dropdown"
                        id="DiagnosisRow__Actions${diag.order}"
                    >
                        <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="DiagnosisRow__Action${diag.order}">
                        <a
                            class="dropdown-item"
                            v-if="!isPalliative"
                            href="javascript:void(0);"
                            @click="makePrimary(index)"
                        >
                            Make Primary
                        </a>
                        <a
                            class="dropdown-item"
                            href="javascript:void(0);"
                            @click="openResolveDiagnosis(index)"
                            v-if="id"
                        >
                            Resolve
                        </a>
                        <a
                            v-if="isPalliative"
                            class="dropdown-item"
                            href="javascript:void(0);"
                            @click="openDeleteDiagnosis(index)"
                        >
                            Delete
                        </a>
                    </div>
                </div>
            </td>
        </template>
        <td :colspan="2" class="p-2 edit" v-if="isDeleteRequested">
            <TablePrompt
                ok-text="Yes, Delete"
                name="delete"
                :ok="deleteDiag"
                :cancel="cancelDeleteRequest"
                :message="deleteMessage"
                cancel-text="No"
                :include-yes="false"
            />
        </td>
        <td class="text-right" v-if="isResolveRequested">
            <i>Diagnosis Resolution In Progress</i>
        </td>
        <td class="button-cell text-right" v-if="isEditRequested">
            <button type="button" class="btn table-btn btn-outline-success" @click="saveEditDiagnosis()">Add</button>
            <button type="button" class="btn table-btn btn-outline-secondary" @click="cancelEditDiagnosis()">
                Cancel
            </button>
        </td>
        <td class="button-cell text-right" v-if="isAddRequested">
            <button type="button" class="btn table-btn btn-outline-success" @click="save()">Add</button>
            <button type="button" class="btn table-btn btn-outline-secondary" @click="cancelSaveNewDiagnosis(index)">
                Cancel
            </button>
        </td>
    </tr>
    <tr v-if="isResolveRequested" class="row-no-hover edit-row">
        <td :colspan="columns" class="p-2 edit">
            <div class="d-flex flex-column align-items-center">
                <div class="form-group w-25 mb-2">
                    <label for="resolution-date"> Resolution Date </label>
                    <DatePicker :options="resolveDatePickerOptions" :getDate="handleDateChange">
                        <div class="input-group date datepickerInput">
                            <input
                                name="resolvedDate"
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="MM/DD/YYYY"
                                :value="formatDateInput(resolvedDate)"
                                @change="handleDate"
                                autocomplete="off"
                                date-format-only
                            />
                            <div class="input-group-addon input-group-append">
                                <div class="input-group-text">
                                    <i class="fa fa-calendar"></i>
                                </div>
                            </div>
                        </div>
                    </DatePicker>
                </div>
                <div>
                    <button class="btn btn-xs btn-outline-primary mr-1" @click="saveResolveDiagnosis(index)">
                        Save
                    </button>
                    <button class="btn btn-xs btn-outline-secondary" @click="cancelResolveDiagnosis(index)">
                        Cancel
                    </button>
                </div>
            </div>
        </td>
    </tr>
    <tr v-if="diag.isRelated == false" class="row-no-hover edit-row">
        <td :colspan="columns" class="p-2 edit">
            <div class="d-flex flex-column align-items-center">
                <div class="form-group w-50">
                    <label for="not-related-reason">Reason<span class="text-danger">*</span></label>
                    <CommentInput
                        input-name="not-related-comments"
                        :result="diag.notRelatedComments"
                        placeholder="Specify rationale and pertinent medical management information."
                        :max-length="2000"
                        :application="referral.application"
                    />
                </div>
            </div>
        </td>
    </tr>
</template>

<script src="./DiagnosisTableRow.ts"></script>
