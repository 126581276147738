
import { defineComponent } from "vue";
import { enumToMap } from "../../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { PatientPayor } from "../../../../../../resources-vue/vue-models/patient-payor";

export default defineComponent({
    props: {
        payer: {
            type: PatientPayor
        },
        relationshipToInsuredEnum: {
            default: null
        },
        payerSequenceCodeEnum: {
            default: null
        },
        hasDeductibleMetChanged: {
            type: Function
        },
        isApplicationPalliative: {
            type: Boolean
        },
        showSubscriberName: {
            type: Boolean
        },
        roomAndBoardTypeValue: {
            type: Number
        },
        palliativeCareTypeValue: {
            type: Number
        },
        requireAuthorizationChanged: {
            type: Function
        },
        outOfPocketMaxMetChanged: {
            type: Function
        }
    },
    data() {
        return {
            enumToMap,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            payer: {
                relationshipToInsured: { required },
                subscriberFirstName: {
                    required: requiredIf(() => {
                        return this.showSubscriberName;
                    })
                },
                subscriberLastName: {
                    required: requiredIf(() => {
                        return this.showSubscriberName;
                    })
                }
            }
        };
    }
});
