import { autoinject, lazy } from "aurelia-framework";
import { NavigationInstruction, Next, RedirectToRoute } from "aurelia-router";
import { UserManager } from "oidc-client";

@autoinject
export class OidcAuthorizeStep {
    private readonly _userManager: () => UserManager;

    public constructor(@lazy(UserManager) userManager: () => UserManager) {
        this._userManager = userManager;
    }

    public async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {
        let instructions = navigationInstruction.getAllInstructions();
        console.log(
            `Handing a navigation request for ${navigationInstruction.fragment}.`,
            navigationInstruction.config.name,
            navigationInstruction.config.moduleId,
            navigationInstruction,
            instructions
        );

        let allowsAnonymous = instructions.some((x) => x.config.settings?.anonymous || !x.config.name);
        let requiresAuth = !allowsAnonymous;

        console.log("This plan needs authentication:", requiresAuth);
        if (!requiresAuth) {
            console.log("No authentication needed, will unconditionally continue.");
            return await next();
        }

        let loginResult = await this.getLoginStatus();

        let isAuthenticated = loginResult.isAuthenticated;
        if (!isAuthenticated) {
            console.info(
                "Requested plan needs authentication, but the user is not authenticated, will begin sign-in process."
            );
            return next.cancel(
                new RedirectToRoute("oidc-sign-in", {
                    fragment: navigationInstruction.fragment,
                    query: navigationInstruction.queryString,
                    referrer: document.referrer
                })
            );
        }
        return await next();
    }

    // TODO: move this service or mediator
    public async getLoginStatus() {
        let user = await this._userManager().getUser();

        let result: LoginStatusResult;

        if (user && !user.expired) {
            let profile = user.profile || {};
            result = {
                isAuthenticated: true,
                token: user.access_token,
                tokenSchema: user.token_type,
                subjectId: user.profile.sub,
                profile: profile
            };
        } else {
            result = {
                isAuthenticated: false,
                token: null,
                tokenSchema: null,
                subjectId: null,
                profile: null
            };
        }

        return result;
    }
}

class LoginStatusResult {
    public isAuthenticated: boolean;
    public token: string;
    public tokenSchema: string;
    public subjectId: string;
    public profile: any;
}
