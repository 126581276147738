import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";

import { PatientPayor } from "../../../../../../resources-vue/vue-models/patient-payor";
import { HospiceReferral } from "../../../../../../resources-vue/vue-models/hospice-referral";
import { PayerSequenceCodes, PayerTypes } from "../../../../../../enums/enums";
import { ToastrService } from "../../../../../../services/toastr.service";
import { formatDate } from "../../../../../../common/vue-helpers/modifiers/value-modifier";

import TablePrompt from "../../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import EmptyTextHandler from "../../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import PayerForm from "../PayorForm/PayorForm.vue";

export default defineComponent({
    components: {
        PayerForm,
        TablePrompt,
        EmptyTextHandler
    },
    props: {
        payer: {
            type: PatientPayor
        },
        referral: {
            type: HospiceReferral
        },
        columns: {
            type: Number
        },
        payorTypeEnum: {
            default: PayerTypes
        },
        resetList: {
            type: Function
        },
        medicareIds: {
            type: Array,
            default: []
        },
        updatePatientPayor: {
            type: Function
        },
        deletePatientPayor: {
            type: Function
        }
    },

    data(vm) {
        return {
            _toastrService: null,
            cachedData: null,
            isEditRequested: false,
            isEditInProgress: false,
            isDeleteRequested: false,
            isDeleteInProgress: false,
            payerSequenceCodeEnum: PayerSequenceCodes,
            formatDate
        };
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
    },
    mounted() {
        this.cachedData = new PatientPayor(this.payer);
    },
    computed: {
        billAsTypeLabel() {
            if (!this.payerSequenceCodeEnum || !this.payer || !this.payer.billAsType) {
                return null;
            }
            return this.payerSequenceCodeEnum.getDescriptionUsingValue(this.payer.billAsType);
        },
        payorTypeLabel() {
            if (!this.payorTypeEnum || !this.payer) {
                return null;
            }
            return this.payorTypeEnum?.getDescriptionUsingValue(this.payer.type);
        },
        isUpcoming() {
            if (this.payer.effectiveDate) {
                return moment() < moment(this.payer.effectiveDate);
            } else {
                return false;
            }
        }
    },
    methods: {
        openEditPayer() {
            this.isEditRequested = true;
            this.cachedData = new PatientPayor(this.payer);
        },
        async updatePayer() {
            try {
                this.isEditInProgress = true;
                let updateResponse = await this.updatePatientPayor(this.payer.id, this.cachedData);
                this.payer.id = updateResponse.data.id;
                this.closeEditPayer();
                this._toastrService.success({
                    title: `Payer Updated`,
                    message: `The Payer <b>${this.payer.agencyPayorName}</b> has been successfully updated.`
                });
            } catch (err) {
                this.openEditPayer();
            } finally {
                this.isEditInProgress = false;
            }
        },
        closeEditPayer() {
            this.isEditRequested = false;
            this.cachedData = undefined;
        },
        confirmDeletePayer() {
            this.isDeleteRequested = true;
        },
        async deletePayer() {
            this.isDeleteInProgress = true;
            const delResult = await this.deletePatientPayor(this.payer.id);
            if (delResult.isSuccess) {
                this.isDeleteRequested = false;
                this.resetList();
                this._toastrService.success({
                    message: `The Payer ${this.payer.agencyPayorName} has been successfully deleted.`,
                    title: `Payer Deleted`
                });
            } else {
                this._toastrService.error({
                    title: "Failed to delete Payer",
                    message: `${JSON.parse(delResult.message).message}`
                });
                this.confirmDeletePayer();
                this.resetList();
            }
        },
        cancelDeletePayer() {
            this.isDeleteRequested = false;
        }
    }
});
