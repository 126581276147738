import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum PatientStatusEnum {
    active = 1,
    discharged = 2,
    pending = 3,
    nonadmitted = 4,
    hospitalized = 7,
    deceased = 8
}

export class PatientStatusEnumClass extends EnumWithDescription<PatientStatusEnum> {
    public readonly enum = PatientStatusEnum;
    public readonly enumName: string = "PatientStatus";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PatientStatusEnum>> = new Map([
        [
            "active",
            {
                value: PatientStatusEnum.active,
                name: "active"
            }
        ],
        [
            "discharged",
            {
                value: PatientStatusEnum.discharged,
                name: "discharged"
            }
        ],
        [
            "pending",
            {
                value: PatientStatusEnum.pending,
                name: "pending"
            }
        ],
        [
            "nonadmitted",
            {
                value: PatientStatusEnum.nonadmitted,
                name: "non-admitted"
            }
        ],
        [
            "hospitalized",
            {
                value: PatientStatusEnum.hospitalized,
                name: "hospitalized"
            }
        ],
        [
            "deceased",
            {
                value: PatientStatusEnum.deceased,
                name: "deceased"
            }
        ]
    ]);
}

export const PatientStatus = new PatientStatusEnumClass();
