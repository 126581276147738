import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PayerInformation = _resolveComponent("PayerInformation")!
  const _component_PayerComments = _resolveComponent("PayerComments")!
  const _component_AdditionalUBLocators = _resolveComponent("AdditionalUBLocators")!
  const _component_AdditionalBillingInfo = _resolveComponent("AdditionalBillingInfo")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PayerInformation),
    _createVNode(_component_PayerComments),
    _createVNode(_component_AdditionalUBLocators),
    _createVNode(_component_AdditionalBillingInfo)
  ]))
}