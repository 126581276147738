// directives to be used in tags, like attributes and should be written as v-directiveName

const directives = {
    uppercase: {
        update(element: HTMLInputElement) {
            if (!!element) {
                element.value = element.value.toUpperCase();
            }
        }
    },
    lowercase: {
        update(element: HTMLInputElement) {
            if (!!element) {
                element.value = element.value.toLowerCase();
            }
        }
    },
    "not-blank": {
        bind(element: HTMLInputElement) {
            if (!!element) {
                element.oninput = (e) => {
                    element.value = element.value.trimLeft();
                };
            }
        }
    }
};

export const inputDirectives = directives;
