import { lazy } from "aurelia-framework";
import { NavigationInstruction, Next, RedirectToRoute } from "aurelia-router";

import { PermissionManager } from "../../common/utilities/permission-manager";
import { ParentPermissionEnum, PermissionActionEnum } from "../../enums/enums";

export class AuthorizeStep {
    private _permissionManager: () => PermissionManager;

    public constructor(@lazy(PermissionManager) permissionManager: () => PermissionManager) {
        this._permissionManager = permissionManager;
    }

    public async run(navigationInstruction: NavigationInstruction, next: Next) {
        let instructions = navigationInstruction.getAllInstructions();
        let isInProgressPage = instructions.some((nav) => (nav.config.settings || {}).inProgressPage);
        // TODO: Need to set up Infrastrcture to acheive inprogress page for Other enviroments
        let isProdEnvironment = true;
        if (isInProgressPage && isProdEnvironment) {
            return next.cancel(new RedirectToRoute("home"));
        }
        if (!this.canAccessRoute(navigationInstruction)) {
            return next.cancel(new RedirectToRoute("home"));
        }
        return next();
    }

    private canAccessRoute(route: NavigationInstruction) {
        const isClinician = this._permissionManager().checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canView
        );
        const isScheduler = this._permissionManager().checkPermission(
            ParentPermissionEnum.scheduler,
            PermissionActionEnum.canView
        );

        if (!!route.config?.settings?.showAxxessPlanner) {
            return isClinician;
        } else if (!!route.config?.settings?.showIfScheduler) {
            return isScheduler;
        } else {
            return true;
        }
    }
}
