import { autoinject } from "aurelia-framework";

import config from "../common/config";
import { FetchClient } from "../common/fetch-client";
import Infrastructure from "../common/infrastructure";
import type {
    IAssetUrlResponse,
    ICreateAssetForMessagingResult,
    ICreateAssetResult,
    IFileDetails,
    IGetAssetResponse
} from "../resources-vue/vue-interfaces/i-asset";
import { ApplicationEnum } from "./../enums/enums";

@autoinject()
export class AssetsService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/assets";
    private _baseHospiceAssetUrl: string = `${config.hospiceAssetServerURL}api/v1/assets`;
    private _basePalliativeAssetUrl: string = `${config.palliativeAssetServerURL}api/v1/assets`;

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
        if (Infrastructure.hospiceAssetServerUrl && !Infrastructure.hospiceAssetServerUrl.includes("#")) {
            this._baseHospiceAssetUrl = `${Infrastructure.hospiceAssetServerUrl}api/v1/assets`;
        }
        if (Infrastructure.palliativeAssetServerUrl && !Infrastructure.palliativeAssetServerUrl.includes("#")) {
            this._basePalliativeAssetUrl = `${Infrastructure.palliativeAssetServerUrl}api/v1/assets`;
        }
    }

    public async uploadFile(file: File, accountId?: string, application?: ApplicationEnum) {
        let asset: ICreateAssetResult = await this.createAsset({
            fileName: file.name,
            fileSize: file.size,
            application: application,
            accountId: accountId
        });
        if (!asset) {
            throw new Error("Asset Creation Unsuccessful.");
        }
        await this.upload(asset.token, file, application);
        return asset.assetId;
    }

    private async createAsset(fileDetails: IFileDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(fileDetails)
            .fetch<ICreateAssetResult>();
    }

    public async upload(token: string, file: File, application?: ApplicationEnum) {
        let baseAssetUrl: string = null;
        switch (application) {
            case ApplicationEnum.AxxessHospiceFE:
                baseAssetUrl = this._baseHospiceAssetUrl;
                break;
            case ApplicationEnum.AxxessPalliative:
                baseAssetUrl = this._basePalliativeAssetUrl;
                break;
        }
        let formData = new FormData();
        formData.append("File", file);
        return await this._httpClient
            .build(`${baseAssetUrl}`)
            .useMode("cors")
            .useMethod("POST")
            .useAuthorization("Bearer " + token)
            .useFormData(formData)
            .fetchNoContent();
    }

    public async getUrlById(assetId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${assetId}/url`).fetch<IAssetUrlResponse>();
    }

    public async getAsset(assetId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${assetId}`).fetch<IGetAssetResponse>();
    }

    public async getAssetBlob(assetId: string) {
        let asset = await this.getAsset(assetId);
        let fileBlob = await fetch(asset.url);
        let blobFile = await fileBlob.blob();
        return {
            assetBlob: new Blob([blobFile], { type: asset.mimeType }),
            assetName: asset.name,
            type: asset.mimeType
        };
    }
}
