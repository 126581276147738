import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column px-3 py-3" }
const _hoisted_2 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HHPatientDocuments = _resolveComponent("HHPatientDocuments")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, {
    title: "Add Referral Documents",
    "full-height": true,
    "application-id": _ctx.referral.application
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_HHPatientDocuments, {
          "patient-id": _ctx.referral.patientId,
          application: _ctx.referral.application,
          "account-id": _ctx.referral.accountId,
          "referral-id": _ctx.referral.id,
          "upload-document": _ctx.uploadDocument,
          "delete-document": _ctx.deleteDocument,
          "get-documents": _ctx.getReferralDocuments
        }, null, 8 /* PROPS */, ["patient-id", "application", "account-id", "referral-id", "upload-document", "delete-document", "get-documents"]),
        _createElementVNode("footer", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeAddDocumentsDialog())),
            type: "button",
            class: "btn btn-xs mr-2 btn-outline-primary"
          }, " Close ")
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["application-id"]))
}