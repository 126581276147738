import "../../../visit-details/visit-details.scss";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { observable, computedFrom } from "aurelia-binding";
import { ValidationController, ValidationRules } from "aurelia-validation";

import type { IReassignVisitDetails } from "../../../../../interfaces/i-reassign-visit";
import { TaskService } from "../../../../../services/task.service";
import { ToastrService } from "../../../../../services/toastr.service";
import nameOf from "../../../../../common/name-of";
import moment from "moment";
import type { IDateRangePickerOptions } from "../../../../../interfaces/i-date-range-picker";
import { PayerTypes, TaskRepeat, ApplicationEnum, PayerTypesEnum, VisitTypeEnum } from "../../../../../enums/enums";
import { PatientTask } from "../../../../../models/patient-task-hos-pal";
import type {
    IAvailableTaskItems,
    IPayersByDateResponseData,
    ICurrentBenefitPeriodData
} from "../../../../../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import { PatientService } from "../../../../../services/patient.service";
import type { ITypeaheadOptions } from "../../../../../interfaces/i-typeahead";
import { EnumMap } from "../../../../../common/enum-map";
import type { IEnumResponse } from "../../../../../interfaces/i-enum";
import type { IValidateCustomElement } from "../../../../../interfaces/i-validate-custom-element";
import { TypeaheadInput } from "../../../../custom-elements/typeahead-input/typeahead-input";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

@autoinject
@customElement("schedule-visit-hos-pal")
export class ScheduleVisitHosPal {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public visitDetails: IReassignVisitDetails;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public cancel: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public ok: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isSaving: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showAllByDefault: boolean = true;
    private _taskService: TaskService;
    private _toastrService: ToastrService;
    private _patientService: PatientService;
    public newTask: PatientTask = new PatientTask();
    public readonly tasks = TaskRepeat;
    public isDateSelected: boolean = false;
    public isPatientSelected: boolean = false;
    public isPalliativeSolution: boolean;
    public availableTask: IAvailableTaskItems[];
    public patientPayers: IPayersByDateResponseData[] = [];
    public payerTypes: IEnumResponse[] = [];
    public payerTypeEnum: IEnumResponse[] = [];
    public payerTypeEnumMap: EnumMap = new EnumMap([]);
    public patientList: any = [];
    public payerTypeTest = Array.from(PayerTypes.enumWithDescriptions.values());

    public recurrencesEnum = Array.from(TaskRepeat.enumWithDescriptions.values());
    public recurrencesEnumMap: EnumMap = new EnumMap([]);
    public controller: ValidationController = null;
    public isOutsideFrequency: boolean = false;
    public patient: ICurrentBenefitPeriodData[];
    public shiftLength: number = 0;
    public taskListItems: IAvailableTaskItems;
    public isFrequencyWarningRequested: boolean;
    public patientName: string;
    public saveType: string;
    public typeAheadRefTask: TypeaheadInput;
    public typeAheadRefPatient: TypeaheadInput;
    public visitTypeEnum: typeof VisitTypeEnum = VisitTypeEnum;
    public visitType: VisitTypeEnum = null;
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("newTaskRepeatChanged") })
    public newTaskRepeat: number = 0;
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("newTaskSingleDateChanged") })
    public newTaskSingleDate: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("newTaskDateRangeChanged") })
    public newTaskDateRange: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("newTaskFlexDatesChanged") })
    public newTaskFlexDates: string = "";
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("availableTaskIdChanged") })
    public taskList: IAvailableTaskItems;
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("patientIdChanged") })
    public schedulePatientId: ITypeaheadOptions;
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("shiftStartTimeChanged") })
    public shiftStartTime: string;
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("shiftStartTimePalChanged") })
    public shiftStartTimePal: string;
    @observable({ changeHandler: nameOf<ScheduleVisitHosPal>("shiftEndTimeChanged") })
    public shiftEndTime: string;
    public newTaskRangePickerOptions: IDateRangePickerOptions = {
        dateLimit: {
            days: 90
        }
    };
    public isScheduling: boolean = false;

    @computedFrom(`${nameOf<ScheduleVisitHosPal>("newTask")}.${nameOf<PatientTask>("startDates")}`)
    public get newTaskStartDates() {
        return this.newTask?.startDates?.filter((startDate) => moment(startDate, "MM/DD/YYYY", true).isValid());
    }

    public dateValidation: IValidateCustomElement = {
        required: true,
        displayName: "Date",
        matches: true
    };

    public showSingleDateValidation: boolean = true;
    public showDateRangeValidation: boolean = false;

    public constructor(
        validationController: ValidationController,
        taskService: TaskService,
        toastrService: ToastrService,
        patientService: PatientService
    ) {
        this.controller = validationController;
        this._taskService = taskService;
        this._toastrService = toastrService;
        this._patientService = patientService;
    }

    public patientValidation: IValidateCustomElement = {
        required: true,
        displayName: "Patient"
    };

    public taskValidation: IValidateCustomElement = {
        required: true,
        displayName: "Task"
    };

    public async attached() {
        if (this.visitDetails.Application == ApplicationEnum.AxxessHospice) {
            this.visitDetails.Application = ApplicationEnum.AxxessHospiceFE;
        }
        if (this.visitDetails.Application == ApplicationEnum.AxxessPalliative) {
            this.isPalliativeSolution = true;
        }

        Object.assign(this.visitDetails, {});
    }

    private initValidation() {
        ValidationRules.ensure((x: ScheduleVisitHosPal) => x.newTaskSingleDate)
            .required()
            .when((x: ScheduleVisitHosPal) => !!x.showSingleDateValidation)
            .withMessage("Date is required.")
            .ensure((x: ScheduleVisitHosPal) => x.newTaskDateRange)
            .required()
            .when((x: ScheduleVisitHosPal) => !!x.showDateRangeValidation)
            .withMessage("Date is required.")
            .ensure((x: ScheduleVisitHosPal) => x.shiftEndTime)
            .matches(/((0[1-9]|1[0-2]):([0-5][0-9]) ?([AaPp][Mm]))/)
            .withMessage("Enter valid scheduled end time")
            .required()
            .when((x: ScheduleVisitHosPal) => !!x.shiftStartTimePal)
            .withMessage("Scheduled End Time is required.")
            .ensure((x: ScheduleVisitHosPal) => x.shiftStartTimePal)
            .matches(/((0[1-9]|1[0-2]):([0-5][0-9]) ?([AaPp][Mm]))/)
            .withMessage("Enter valid scheduled start time")
            .ensure((x: ScheduleVisitHosPal) => x.shiftStartTime)
            .matches(/((0[1-9]|1[0-2]):([0-5][0-9]) ?([AaPp][Mm]))/)
            .withMessage("Enter valid shift start time")
            .on(this);
    }

    public newTaskRepeatChanged(newValue: number, oldValue: number) {
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
        this.newTask.startDates = [];
        this.newTask.endDates = [];
        this.changeDateValidationStatus();
    }

    public newTaskSingleDateChanged(newValue: string) {
        this.newTask.startDates = [];
        if (!!newValue) {
            this.newTask.startDates.push(newValue);
        }
        if (this.taskDateChanged) {
            this.taskDateChanged();
        }
    }

    public newTaskDateRangeChanged(newValue: string) {
        this.newTask.startDates = [];
        const newTaskDates = newValue.split(" - ");
        const startDate = moment(newTaskDates[0]);
        const endDate = moment(newTaskDates[1]);
        let dateToAdd = startDate;
        while (dateToAdd <= endDate) {
            this.newTask.startDates.push(dateToAdd.format("MM/DD/YYYY"));
            if (this.newTaskRepeat == 4) {
                dateToAdd = dateToAdd.add(1, "month");
            } else {
                dateToAdd = dateToAdd.add(this.newTaskRepeat, "week");
            }
        }
        if (this.taskDateChanged) {
            this.taskDateChanged();
        }
    }

    public newTaskFlexDatesChanged(newValue: string) {
        this.newTask.startDates = [];
        if (!!newValue) {
            this.newTask.startDates = newValue.split(", ");
        }
        if (this.taskDateChanged) {
            this.taskDateChanged();
        }
    }

    public taskDateChanged() {
        if (this.newTask.startDates?.length > 0) {
            this.isDateSelected = true;
            if (this.patientName) {
                this.updateAvailablePayors();
            }
        } else {
            this.isDateSelected = false;
            this.resetShiftOnCall();
        }
    }

    private resetShiftOnCall() {
        this.newTask.onCall = false;
        this.shiftStartTime = "";
        this.shiftLength = 0;
    }

    public changeDateValidationStatus() {
        if (!this.dateValidation || !this.recurrencesEnumMap) {
            return;
        }
        let recurrenceName = TaskRepeat.getDescriptionUsingValue(this.newTaskRepeat) as string;
        if (recurrenceName.toLowerCase() === "flexible") {
            this.showDateRangeValidation = false;
            this.showSingleDateValidation = false;
            this.dateValidation.required = true;
        } else if (recurrenceName.toLowerCase() === "does not repeat") {
            this.showDateRangeValidation = false;
            this.showSingleDateValidation = true;
            this.dateValidation.required = false;
        } else {
            this.showDateRangeValidation = true;
            this.showSingleDateValidation = false;
            this.dateValidation.required = false;
        }
    }

    public availableTaskIdChanged(newValue: IAvailableTaskItems) {
        if (newValue) {
            this.newTask.taskId = newValue.id;
            this.taskListItems = newValue;
            this.updateAvailablePayors();
        }
    }

    public patientIdChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            this.newTask.patientId = newValue.value;
            this.patientName = newValue.name;
            const response = this.availableTasks();
            response
                .then(() => {
                    if (!this.isPalliativeSolution) {
                        this.getCurrentBenefitPeriod();
                    }
                    this.updateAvailablePayors();
                    this.isPatientSelected = true;
                })
                .catch(() => {
                    this.isPatientSelected = false;
                });
        }
    }

    public shiftStartTimeChanged() {
        if (
            this.shiftLength > 0 &&
            this.newTask.startDates &&
            this.newTask.startDates.length > 0 &&
            !this.isPalliativeSolution
        ) {
            this.newTask.startDates = this.newTask.startDates.map((item) => {
                const startDate = new Date(`${item} ${this.shiftStartTime}`).toISOString();
                return moment(startDate).format("MM/DD/YYYY HH:mm");
            });
            this.newTask.endDates = [];
            this.shiftEndTime = moment(this.shiftStartTime, "hh:mm A").add(this.shiftLength, "hours").format("hh:mm A");
            this.newTask.startDates.forEach((item) => {
                const endDate = moment(item).add(this.shiftLength, "hours").format("MM/DD/YYYY HH:mm");
                this.newTask.endDates.push(endDate);
            });
        }
    }

    public shiftStartTimePalChanged() {
        if (!this.shiftStartTimePal) {
            this.newTask.startDates = this.newTask.startDates.map((startDate) =>
                moment(startDate).format("MM/DD/YYYY")
            );
            this.shiftEndTime = "";
            this.newTask.endDates = [];
            return;
        }
        let startDates = this.newTask.startDates.map((startDate) => moment(startDate).format("MM/DD/YYYY"));
        if (startDates && startDates.length > 0) {
            this.newTask.startDates = startDates.map((item) => {
                let startDate = new Date(`${item} ${this.shiftStartTimePal}`).toISOString();
                return moment(startDate).format("MM/DD/YYYY HH:mm");
            });
        }
    }

    public shiftEndTimeChanged() {
        if (!this.shiftEndTime) {
            this.newTask.endDates = this.newTask.endDates.map((endDate) => moment(endDate).format("MM/DD/YYYY"));
            return;
        }
        let scheduledDates = this.newTask.startDates.map((startDate) => moment(startDate).format("MM/DD/YYYY"));
        if (scheduledDates && scheduledDates.length > 0 && this.isPalliativeSolution) {
            this.newTask.endDates = [];
            this.newTask.endDates = scheduledDates.map((item) => {
                let startDate = new Date(`${item} ${this.shiftEndTime}`).toISOString();
                return moment(startDate).format("MM/DD/YYYY HH:mm");
            });
        }
    }

    public async availableTasks() {
        if (this.newTask.startDates.length) {
            const data = await this._taskService.getAvailableTasks({
                patientId: this.newTask.patientId,
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application,
                startDates: this.newTask.startDates
            });
            return (this.availableTask = data.Data);
        } else {
            return false;
        }
    }

    public availableTasksFilter(filter: string) {
        if (filter) {
            const filterAvailableTasks = this.availableTask.filter((task) => {
                return task.name.toLowerCase().includes(filter.toLowerCase());
            });
            return filterAvailableTasks;
        } else {
            return this.availableTask;
        }
    }

    public async patientListFilter(filter: string) {
        if (filter) {
            const patient = await this._patientService.findPatientsForCalendar({
                pageLength: 15,
                page: 1,
                term: filter,
                patientStatus: 1,
                agencies: [`${this.visitDetails.AgencyId}_${this.visitDetails.Application}`]
            });
            const filterPatientList = patient.Items.filter((item: any) => {
                const fullName = `${item.FirstName} ${item.LastName}`;
                return fullName.toLowerCase().includes(filter.toLowerCase());
            });
            return filterPatientList?.map((item: any) => {
                return {
                    name: `${item.FirstName} ${item.LastName}`,
                    value: item.Id
                };
            });
        } else {
            return this.patientList?.map((item: any) => ({
                value: item.Id,
                name: `${item.FirstName} ${item.LastName}`
            }));
        }
    }

    public async updateAvailablePayors() {
        if (!this.newTask.startDates.length) {
            this.patientPayers = [];
            return;
        }
        const startDates = this.newTask.startDates.map((date) => moment(date));
        const startDate = moment.min(startDates).format("MM/DD/YYYY");
        const endDate = moment.max(startDates).format("MM/DD/YYYY");
        const response = await this._taskService.getPayersByDate({
            patientId: this.newTask.patientId,
            accountId: this.visitDetails.AgencyId,
            startDate: startDate,
            endDate: endDate,
            application: this.visitDetails.Application,
            includeCharityPayer: true
        });
        this.patientPayers = response.data;

        this.payerTypes = this.payerTypeTest.filter((type) =>
            this.patientPayers.some((patientPayor) => patientPayor.payerType === type.value)
        );

        const primaryPayer = this.patientPayers.find((payer) => payer.payerType === PayerTypesEnum.Primary);

        this.newTask.patientPayerId = primaryPayer?.id ?? null;
    }

    public async getCurrentBenefitPeriod() {
        const response = await this._patientService.getCurrentBenefitPeriod({
            patientId: this.newTask.patientId,
            agencyId: this.visitDetails.AgencyId,
            application: this.visitDetails.Application
        });
        this.patient = response.data;
    }

    public async showFrequencyWarning(saveTask: boolean = true) {
        this.initValidation();
        this.newTask.initValidation();
        const res = await this.controller.validate();
        if (!res.valid) {
            return;
        }
        this.isFrequencyWarningRequested = true;
        if (saveTask == true) {
            this.saveType = SaveEnum.SaveAndAddAnother;
        } else {
            this.saveType = SaveEnum.SaveAndExit;
        }
    }

    public closeFrequencyWarning() {
        this.isOutsideFrequency = false;
        this.isFrequencyWarningRequested = false;
    }

    public async saveAndAddAnother(saveType: string) {
        await this.createTask(saveType);
    }

    public async createTask(saveType: string) {
        this.newTask.initValidation();
        this.initValidation();
        const res = await this.controller.validate();
        if (!res.valid) {
            return;
        }
        if (!!this.newTask) {
            try {
                this.isSaving = true;
                this.isScheduling = true;
                this.newTask.metaId = "";
                this.newTask.comments = "";
                this.newTask.agencyId = this.visitDetails.AgencyId;
                this.newTask.application = this.visitDetails.Application;
                this.newTask.userId = this.visitDetails.UserId;

                await this._taskService.createTask(this.newTask);
                this._toastrService.success({
                    title: `New Task Created`,
                    message: `The Task has been successfully created.`
                });
                this.closeFrequencyWarning();
                if (saveType == SaveEnum.SaveAndExit) {
                    // trigger callback
                    this.ok();
                } else {
                    this.typeAheadRefTask?.clearFilter();
                    this.typeAheadRefPatient?.clearFilter();
                    setTimeout(() => {
                        this.newTask = new PatientTask();
                        this.newTaskSingleDate = "";
                        this.newTaskDateRange = "";
                        this.newTaskFlexDates = "";
                        this.schedulePatientId = null;
                        this.taskList = null;
                        this.taskListItems = null;
                        this.patient = null;
                        this.patientName = null;
                    });
                }
            } catch (e) {
                this._toastrService.error({
                    title: `Creating Task Failed`,
                    message: `Creating Task Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isSaving = false;
                this.isScheduling = false;
            }
        }
    }

    public visitTypeRadioClick(value: VisitTypeEnum) {
        this.visitType = this.visitType === value ? null : value;
        if (!this.visitType) {
            // If none is selected
            this.newTask.onCall = false;
            this.newTask.prn = false;
        } else if (this.visitType === VisitTypeEnum.OnCall) {
            // If on call visit type
            this.newTask.onCall = true;
            this.newTask.prn = false;
        } else if (this.visitType === VisitTypeEnum.Prn) {
            // If prn visit type
            this.newTask.onCall = false;
            this.newTask.prn = true;
        }
        return true;
    }
}
