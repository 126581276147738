import { defineComponent } from "vue";
import { adjustDate } from "../../../../common/adjust-date";

export default defineComponent({
    props: {
        startDate: {
            default: null
        },
        endDate: {
            default: null
        }
    },

    emits: ["taskRefresh"],

    data() {
        return {
            todaysDate: new Date(Date.now()) as Date,
            _dateOptions: {
                month: "long",
                day: "numeric"
            } as object
        };
    },

    computed: {
        formattedDateRange() {
            return `${this.startDate?.toLocaleString("en-US", this._dateOptions) ?? ""}`;
        }
    },

    mounted() {
        this.initDateRange();
    },

    methods: {
        async initDateRange() {
            if (!!this.startDate && !!this.endDate) {
                await this.emitTaskRefresh(this.startDate, this.endDate);
                return;
            }

            await this.emitTaskRefresh(this.todaysDate, this.todaysDate);
        },

        async emitTaskRefresh(startDate: Date, endDate: Date) {
            this.$emit("taskRefresh", { startDate, endDate });
        },

        async forwardNavigation() {
            await this.dateNavigation(1);
        },

        async backwardNavigation() {
            await this.dateNavigation(-1);
        },

        async dateNavigation(offset: number) {
            await this.emitTaskRefresh(adjustDate(this.startDate, offset), adjustDate(this.endDate, offset));
        }
    }
});
