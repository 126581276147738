import { defineComponent } from "vue";
import { ITaskShift } from "../../../models/visit-details";

import TableEmpty from "../../vue-custom-elements/TableEmpty/TableEmpty.vue";
import MidnightSplitRow from "../MidnightSplitRow/MidnightSplitRow.vue";


export default defineComponent({
    components:{
       TableEmpty,
       MidnightSplitRow
    },
props:{
    taskShifts:{
        type:Array<ITaskShift>,
        default:[]
    },
    id:{
        type:String
    },
    snapshotView:{
        type:Boolean,
        default:false
    }
},

data(){
    return{
        newDetail:[] as ITaskShift[],
        isNewTerminal:false as boolean,
        columns:10 as number
    }
},
methods:{
    saveNewDetail(index: number) {
        // Todo: add logic to save in next phase
    },

     cancelSaveNewDetail(index: number) {
        this.newDetail.splice(index, 1);
    }
}
})