import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum MaritalStatusesEnum {
    Married = 1 as any,
    Divorced = 2 as any,
    Widowed = 3 as any,
    Single = 4 as any,
    Separated = 5 as any,
    CommittedPartner = 6 as any,
    Unknown = 7 as any
}
class MaritalStatusesClass extends EnumWithDescription<MaritalStatusesEnum> {
    public readonly enum = MaritalStatusesEnum;
    public readonly enumName: string = "MaritalStatusesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<MaritalStatusesEnum>> = new Map([
        [
            "Married",
            {
                value: MaritalStatusesEnum.Married,
                name: "Married"
            }
        ],
        [
            "Divorced",
            {
                value: MaritalStatusesEnum.Divorced,
                name: "Divorced"
            }
        ],
        [
            "Widowed",
            {
                value: MaritalStatusesEnum.Widowed,
                name: "Widowed"
            }
        ],
        [
            "Single",
            {
                value: MaritalStatusesEnum.Single,
                name: "Single"
            }
        ],
        [
            "Separated",
            {
                value: MaritalStatusesEnum.Separated,
                name: "Separated"
            }
        ],
        [
            "CommittedPartner",
            {
                value: MaritalStatusesEnum.CommittedPartner,
                name: "Committed Partner"
            }
        ],
        [
            "Unknown",
            {
                value: MaritalStatusesEnum.Unknown,
                name: "Unknown"
            }
        ]
    ]);
}

export const MaritalStatuses = new MaritalStatusesClass();
