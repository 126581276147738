import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import type { INewsRequest, INewsResponse } from "../resources-vue/vue-interfaces/i-news";

@autoinject()
export class NewsService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getNewsAndUpdates(query: INewsRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/news-and-updates`)
            .useQueryString(query)
            .fetch<INewsResponse[]>();
    }
}
