import { defineComponent, computed } from "vue";
import { mapState, mapActions } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { Container } from "aurelia-dependency-injection";

import { IPromptOptions } from "../../resources/dialogs/prompt/prompt";
import { ReferralService } from "../../services/referral-service";
import { ToastrService } from "../../services/toastr.service";
import { BranchesService } from "../../services/branches-service";
import { IBranchResponse } from "../../resources-vue/vue-interfaces/i-branch";
import { useEditReferralHH } from "./store/useEditReferralHH";
import { PatientService } from "../../services/patient.service";
import { HttpStatusCodeEnum } from "../../enums/enums";
import { useLoadingIndicator } from "../Common/LoadingIndicator/store/useLoadingIndicator";

import PageLayout from "../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import MciLayout from "../../resources-vue/vue-elements/MciLayout/Mcilayout.vue";
import Prompt from "../../resources-vue/vue-dialogs/Prompt/Prompt.vue";
import ReferralDemographics from "./components/Demographics/ReferralDemographics.vue";
import EmergencyPreparedness from "./components/EmergencyPreparedness/EmergencyPreparedness.vue";
import ReferralInformation from "./components/ReferralInformation/ReferralInformation.vue";
import ClinicalDiagnosis from "./components/ClinicalDiagnosis/ClinicalDiagnosis.vue";
import AdvanceDirectives from "./components/AdvanceDirectives/AdvanceDirectives.vue";
import ReferralContacts from "./components/Contacts/ReferralContacts.vue";
import ReferralPharmacy from "./components/Pharmacy/ReferralPharmacy.vue";
import ReferralPayers from "./components/Payers/ReferralPayers.vue";
import ReferralPhysicians from "./components/Physicians/ReferralPhysicians.vue";

export default defineComponent({
    components: {
        PageLayout,
        MciLayout,
        ReferralDemographics,
        EmergencyPreparedness,
        ReferralInformation,
        ClinicalDiagnosis,
        AdvanceDirectives,
        ReferralContacts,
        ReferralPharmacy,
        ReferralPayers,
        ReferralPhysicians
    },

    data() {
        return {
            _toastrService: null,
            _referralService: null,
            _branchesService: null,
            _patientService: null,
            branches: [] as IBranchResponse[],
            dialogRef: null,
            isFormLoading: false,
            isLoadError: false,
            isSaveInProgress: false,
            hasErrorOnSave: false,
            isCreate: false,
            notesSection: null,
            referralId: "",
            commentInputClass: { input: "w-35" },
            branchId: "",
            isPatientIdentifierEnabled: null,
            tabs: [
                { name: "Demographics", id: 1 },
                { name: "Payers", id: 2 },
                { name: "Physicians", id: 3 },
                { name: "Clinical/Diagnoses", id: 4 },
                { name: "Pharmacy", id: 5 },
                { name: "Contacts", id: 6 },
                { name: "Emergency Preparedness", id: 7 },
                { name: "Advance Directives", id: 8 },
                { name: "Referral Information", id: 9 }
            ],
            activeTab: 1,
            previousTab: 0,
            v$: useVuelidate(),
            isTabValidated: false,
            allErrors: [],
            previousTabs: []
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    async created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._branchesService = Container.instance.get(BranchesService);
        this._patientService = Container.instance.get(PatientService);

        if (this.$route.query?.locationId) {
            this.branchId = this.$route.query.locationId as string;
        }
        if (this.$route.params?.applicationId) {
            this.setApplication(this.$route.params.applicationId);
        }
        if (this.$route.params?.accountId) {
            this.setAccountId(this.$route.params.accountId);
        }
        if (this.$route.params?.id) {
            this.referralId = this.$route.params.id as string;
            await this.loadReferral();
        } else {
            this._toastrService.error({
                title: `Error`,
                message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
            });
            this.$router.push("/axxess-central/intake/referral-list");
        }
    },
    async mounted() {
        const branches = await this._branchesService.getAllBranches();
        this.branches = branches.filter((branch: any) => branch.accountId == this.accountId);
        const currentBranch = this.branches.find((branch) => branch.id === this.branchId);
        this.isPatientIdentifierEnabled = currentBranch?.isPatientIdentifierEnabled;
        this.notesSection = this.$refs.notesSection;
        let hash = window.location.hash;
        if (!!hash) {
            this.notesSection?.scrollIntoView();
        }
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            application: "currentApplication",
            accountId: "accountId",
            referralOptions: "referralOptions",
            errors: "errors",
            updated: "updated"
        })
    },

    methods: {
        ...mapActions(useEditReferralHH, {
            setReferral: "SET_HH_REFERRAL",
            setReferralOptions: "SET_REFERRAL_OPTIONS",
            setAccountId: "SET_ACCOUNT_ID",
            setApplication: "SET_CURRENT_APPLICATION",
            setErrors: "SET_ERRORS",
            setAdditionalContactError: "SET_ADDITIONAL_CONTACT_ERROR",
            removeErrors: "REMOVE_ERRORS",
            resetErrors: "RESET_ERRORS",
            setUpdated: "SET_UPDATED"
        }),
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),

        getNumberOfErrors(tab: string) {
            if (tab === "Demographics") {
                return (
                    [...new Set(this.errors.Demographics)].length +
                    [...new Set(this.errors.PrimaryAddress)].length +
                    [...new Set(this.errors.MailingAddress)].length
                );
            } else if (tab === "Contacts") {
                return [...new Set(this.errors.Contacts)].length;
            } else if (tab === "Referral Information") {
                return (
                    [...new Set(this.errors.ReferralInformation)].length +
                    [...new Set(this.errors.AdditionalBillingInfo)].length
                );
            } else if (tab === "Payers") {
                return (
                    [...new Set(this.errors.Insurance)].length +
                    [...new Set(this.errors.PrimaryInsuredDetails)].length +
                    [...new Set(this.errors.SecondaryInsuredDetails)].length +
                    [...new Set(this.errors.TertiaryInsuredDetails)].length
                );
            } else {
                return [...new Set(this.errors[tab])].length;
            }
        },

        async handleTabChange(tab: any) {
            this.previousTab = this.activeTab;
            this.activeTab = tab.id;
            this.resetErrors();
            this.removeErrors();
            await this.v$.$validate();
            this.v$.$errors.map((val) => {
                let property;
                if (val.$propertyPath.split(".").length === 5) {
                    property = val.$propertyPath.split(".")[4];
                    const payload = {
                        property: property,
                        error: val.$property
                    };
                    this.setErrors(payload);
                } else {
                    property =
                        val.$propertyPath.split(".").length === 3
                            ? val.$propertyPath.split(".")[1]
                            : val.$propertyPath.split(".").length === 2
                            ? val.$message
                            : val.$propertyPath.split(".")[1];
                    let payload = {
                        property: property,
                        error: val.$property
                    };
                    this.setErrors(payload);
                }
            });

            if (!this.previousTabs.includes(this.previousTab)) {
                this.previousTabs.push(this.previousTab);
            }
        },

        changeBranchId(newValue: string) {
            this.branchId = newValue;
        },

        async handleCancel() {
            let promptOptions: IPromptOptions = {
                message:
                    "Referral information has not been saved.Do you want to discard this data or return to continue editing the referral?",
                okText: "Return to Edit Referral",
                cancelText: "Discard"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: (options) => {
                    if (options.data !== "success") {
                        this.$router.push("/axxess-central/intake/referral-list");
                    }
                }
            });
        },
        async getReferralData() {
            try {
                const result = await this._patientService.getEditHHReferral({
                    referralId: this.referralId,
                    agencyId: this.accountId,
                    application: this.application
                });
                if (result) {
                    let tempReferral = result.data;
                    tempReferral.AgencyId = this.accountId;
                    return tempReferral;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this referral. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async loadReferral() {
            try {
                this.showLoading(true);
                this.isLoadError = false;
                this.isFormLoading = true;
                const options = await this.getReferralOptions();
                this.setReferralOptions(options);
                const referral = await this.getReferralData();
                this.setReferral(referral);
            } catch (e) {
                this.isLoadError = true;
                console.error(e);
            } finally {
                this.showLoading(false);
                this.isFormLoading = false;
            }
        },

        async getReferralOptions() {
            let tempReferralOptions = await this._referralService.getReferralOptions(this.accountId);
            tempReferralOptions.admissionSource =
                tempReferralOptions?.admissionSource.map((item: any) => ({
                    value: Number(item.value) || 0,
                    text: item.text || null,
                    type: item.type || null
                })) || null;
            tempReferralOptions.admissionType =
                tempReferralOptions?.admissionType.map((item: any) => ({
                    value: Number(item.value) || 0,
                    text: item.text || null,
                    type: item.type || null
                })) || null;
            return tempReferralOptions;
        },

        async update(type: string) {
            this.resetErrors();
            this.removeErrors();
            await this.v$.$validate();
            this.v$.$errors.map((val) => {
                let property;
                if (val.$propertyPath.split(".").length === 5) {
                    property = val.$propertyPath.split(".")[4];
                    const payload = {
                        property: property,
                        error: val.$property
                    };
                    this.setErrors(payload);
                } else {
                    property =
                        val.$propertyPath.split(".").length === 3
                            ? val.$propertyPath.split(".")[1]
                            : val.$propertyPath.split(".").length === 2
                            ? val.$message
                            : val.$propertyPath.split(".")[1];
                    let payload = {
                        property: property,
                        error: val.$property
                    };
                    this.setErrors(payload);
                }
            });
            if (!this.allErrors.includes(this.activeTab)) {
                this.allErrors.push(this.activeTab);
            }
            try {
                this.isSaveInProgress = true;
                await this.$nextTick();
                const payload = this.referral;
                const response = await this._referralService.newEditHHReferral(payload);
                if (response.isSuccessful && response.data) {
                    this.setReferral(JSON.parse(response.data));
                    this._toastrService.success({
                        title: "Referral Updated",
                        message: response.message
                    });
                    if (type === "save-and-exit") {
                        this.$router.push("/axxess-central/intake/referral-list");
                    } else {
                        this.activeTab = this.activeTab + 1;
                    }
                    this.setUpdated(true);
                } else {
                    this.setUpdated(false);
                    this._toastrService.error({
                        title: `Error`,
                        message: `${response.message}`
                    });
                }
            } catch (err) {
                console.log(err);
                if (err.status === HttpStatusCodeEnum.NotModified) {
                    this._toastrService.info({
                        title: `The referral <b>${this.referral.Demographics.FirstName} ${this.referral.Demographics.LastName}</b> is up to date`,
                        message: `There were no changes made in ${this.referral.Demographics.FirstName} ${this.referral.Demographics.LastName}'s profile.`
                    });
                } else {
                    this._toastrService.error({
                        title: `Error`,
                        message: `There was a problem while saving this referral. Please try again. If the problem persists, please report it.`
                    });
                }
            } finally {
                this.isSaveInProgress = false;
            }
        }
    }
});
