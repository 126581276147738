import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum GenderPronouns {
    HeHim = "0",
    SheHer = "1",
    TheyThem = "2",
    ZeZim = "3",
    HuHus = "4",
    Other = "5"
}

export class GenderPronounClass extends EnumWithDescription<GenderPronouns> {
    public readonly enum = GenderPronouns;
    public readonly enumName: string = "GenderPronouns";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<GenderPronouns>> = new Map([
        [
            "He/Him",
            {
                value: GenderPronouns.HeHim,
                name: "He/Him"
            }
        ],
        [
            "She/Her",
            {
                value: GenderPronouns.SheHer,
                name: "She/Her"
            }
        ],
        [
            "They/Them",
            {
                value: GenderPronouns.TheyThem,
                name: "They/Them"
            }
        ],
        [
            "Ze/Zim",
            {
                value: GenderPronouns.ZeZim,
                name: "Ze/Zim"
            }
        ],
        [
            "Hu/Hus",
            {
                value: GenderPronouns.HuHus,
                name: "Hu/Hus"
            }
        ],
        [
            "Other",
            {
                value: GenderPronouns.Other,
                name: "Other"
            }
        ]
    ]);
}

export const GenderPronoun = new GenderPronounClass();
