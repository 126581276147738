import { defineComponent } from "vue";

export interface IMultiSelectDropDownOption {
    [groupText: string]: IMultiSelectOption[];
}

export interface IMultiSelectOption {
    displayText: string;
    value: any;
}

export default defineComponent({
    props: {
        labelText: {
            type: String,
            default: ""
        },
        dropDownOptions: {
            type: Object,
            default: {}
        },
        selectedOptions: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            filteredOptions: {} as IMultiSelectDropDownOption,
            search: "" as string,
            selectedOptionsCopy: {},
            allOptions: []
        };
    },

    mounted() {
        this.filteredOptions = { ...this.dropDownOptions };
        $(this.$refs.dropDownMenu).on("click", (e) => {
            e.stopPropagation();
        });
        $(this.$refs.dropDownElement).on("shown.bs.dropdown", (e) => {
            (this.$refs.searchInput as HTMLInputElement)?.focus();
        });
    },

    watch: {
        search: function () {
            if (!!this.search) {
                for (let groupName of Object.keys(this.dropDownOptions)) {
                    let options = this.dropDownOptions[groupName];
                    this.filteredOptions[groupName] = options.filter((option: any) =>
                        option.displayText.toLowerCase().includes(this.search.toLowerCase())
                    );
                }
            } else {
                this.filteredOptions = { ...this.dropDownOptions };
            }
        }
    },

    computed: {
        getbuttonText(): string {
            if (this.selectedOptions.length === this.getdropDownOptionsLength) {
                return `All selected`;
            } else if (this.selectedOptions.length === 0) {
                return `None selected`;
            } else {
                return `${this.selectedOptions.length} selected`;
            }
        },

        getsearchPlaceholder(): string {
            return `Search for ${this.labelText}`;
        },

        getdropDownOptionsLength() {
            if (this.dropDownOptions) {
                this.filteredOptions = { ...this.dropDownOptions };
                return Object.values(this.dropDownOptions).reduce((len, option) => option.length + len, 0);
            }
        }
    },

    methods: {
        toggleAllSelected() {
            if (this.getdropDownOptionsLength === this.selectedOptions.length) {
                this.$emit("updateBranches", []);
            } else {
                this.allOptions.splice(0, this.allOptions.length);
                for (let groupName of Object.keys(this.dropDownOptions)) {
                    this.selectAllGroupOptions(groupName);
                }
            }
        },

        closeDropDown() {
            $(this.$refs.dropDownElement).dropdown("toggle");
        },

        toggleAllGroupOptions(groupName: string) {
            let options = this.dropDownOptions[groupName];
            let shouldSelectAll = false;
            for (let option of options) {
                let optionIndex = this.selectedOptions.indexOf(option.value);
                if (optionIndex === -1) {
                    shouldSelectAll = true;
                } else {
                    this.selectedOptions.splice(optionIndex, 1);
                }
            }
            if (shouldSelectAll) {
                this.selectAllGroupOptions(groupName);
            }
        },

        toggleSingleSelection(branchId: any) {
            let options = [...this.selectedOptions];
            if (options.includes(branchId)) {
                options = options.filter((item) => item != branchId);
            } else {
                options.push(branchId);
            }
            this.$emit("updateBranches", options);
        },

        selectAllGroupOptions(groupName: string) {
            for (let option of this.dropDownOptions[groupName]) {
                this.allOptions.push(option.value);
            }
            this.$emit("updateBranches", this.allOptions);
        }
    }
});
