import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PalliativeRacesEnum {
    AmericanIndianOrAlaskaNative = 1 as any,
    Asian = 2 as any,
    BlackOrAfricanAmerican = 4 as any,
    NativeHawaiianOrOtherPacificIslander = 16 as any,
    White = 32 as any,
    OtherRace = 256 as any
}
class PalliativeRacesClass extends EnumWithDescription<PalliativeRacesEnum> {
    public readonly enum = PalliativeRacesEnum;
    public readonly enumName: string = "PalliativeRacesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PalliativeRacesEnum>> = new Map([
        [
            "AmericanIndianOrAlaskaNative",
            {
                value: PalliativeRacesEnum.AmericanIndianOrAlaskaNative,
                name: "American Indian or Alaska Native"
            }
        ],
        [
            "Asian",
            {
                value: PalliativeRacesEnum.Asian,
                name: "Asian"
            }
        ],
        [
            "BlackOrAfricanAmerican",
            {
                value: PalliativeRacesEnum.BlackOrAfricanAmerican,
                name: "Black or African American"
            }
        ],
        [
            "NativeHawaiianOrOtherPacificIslander",
            {
                value: PalliativeRacesEnum.NativeHawaiianOrOtherPacificIslander,
                name: "Native Hawaiian or Other Pacific Islander"
            }
        ],
        [
            "White",
            {
                value: PalliativeRacesEnum.White,
                name: "White"
            }
        ],
        [
            "OtherRace",
            {
                value: PalliativeRacesEnum.OtherRace,
                name: "Other Race"
            }
        ]
    ]);
}

export const RacesPalliative = new PalliativeRacesClass();
