<template>
    <FormSection title="Contacts Comments" type="his-section" :full-height="false" :application-id="application">
        <template #content>
            <section class="d-flex flex-column px-3 py-2">
                <TemplateInput
                    :templates="templates"
                    :application="application"
                    :accountId="accountId"
                    :result="referral.Contacts.CaphsComments ? referral.Contacts.CaphsComments : ''"
                    v-on:resultUpdated="handleCommentsChanged"
                />
            </section>
        </template>
    </FormSection>
</template>

<script src="./ContactsComments.ts"></script>
