import moment, { Moment } from "moment";

import type { IName } from "../resources-vue/vue-interfaces/i-name";
import type { IAddressLongLat } from "../resources-vue/vue-interfaces/i-address";
import type { ITask } from "../resources-vue/vue-interfaces/i-task";
import {
    IRescheduleVisitDetails,
    IVisitDetails,
    IPostTaskToAxxessCareVisitDetails,
    IPayRate
} from "../resources-vue/vue-interfaces/i-visit-details";
import { ApplicationEnum } from "../enums/enums";

const STATUS_TYPE_ID_MAP: any = {
    primary: ["100", "105", "110", "200", "205", "210", "405", "410", "415", "500", "515"],
    success: ["135", "225", "420", "425", "455", "520", "525"],
    warning: [
        "115",
        "120",
        "125",
        "130",
        "140",
        "145",
        "150",
        "155",
        "215",
        "220",
        "230",
        "235",
        "240",
        "418",
        "430",
        "435",
        "440",
        "445",
        "450",
        "460",
        "465",
        "470",
        "530",
        "535"
    ],
    danger: ["400", "401", "402", "403"]
};

const STATUS_TYPE_ID: any = {
    "Start Visit": ["410", "NotStarted", "Not Yet Started", "Not Yet Due"],
    "Open Visit": ["415", "Saved"],
    "Reopen Visit": [
        "425",
        "Completed",
        "419",
        "Submitted Pending Co-Signature",
        "418",
        "Submitted (Pending Co-Signature)",
        "420",
        "520",
        "Submitted With Signature",
        "220",
        "Export Ready",
        "225",
        "Exported",
        "Sent to Provider",
        "430",
        "530",
        "Returned For Review",
        "465",
        "Returned for Clinician Signature",
        "402",
        "Missed Visit (Complete)",
        "403",
        "Missed Visit(Return for Review)",
        "125",
        "To Be Sent To Physician",
        "470",
        "Returned for Review by Physician"
    ]
};

const STATUS_TYPE_ID_HOS_PAL: any = {
    "Start Visit": ["410", "NotStarted", "Not Yet Started", "Not Yet Due"],
    "Open Visit": ["415", "Saved"],
    "Add Addendum": ["Completed"],
    "Open Addendum": ["Pending Addendum"],
    "Pending Approval": ["Submitted with Signature", "Submitted With Signature", "Submitted Pending Co-Signature"]
};

export class Task implements ITask {
    public Application: number;
    public AgencyId: string;
    public AgencyName: string;
    public BranchName: string;
    public Comments: string;
    public Dob: string | Moment;
    public Date: string;
    public StartDateTime: string;
    public EndDateTime: string;
    public EmergencyContactName: IName;
    public EmergencyContactPhoneNumber: string;
    public EpisodeId: string;
    public EpisodeNotes: string;
    public Id: string;
    public IsAxxessCarePatient: boolean;
    public PatientAddress: IAddressLongLat;
    public PatientId: string;
    public PatientName: IName;
    public PatientPhoneNumber: string;
    public ReturnComments: string;
    public Status: string;
    public StatusName: string;
    public UserId: string;
    public VisitName: string;
    public showDetails: boolean = false;

    public constructor(task: ITask) {
        Object.assign(this, task || {});
    }

    public get when(): string {
        return moment(this.Date).fromNow();
    }

    public get patientFullName(): string {
        return `${this.PatientName.FirstName} ${this.PatientName.LastName}`;
    }

    public get emergencyFullName(): string {
        return `${this.EmergencyContactName.FirstName} ${this.EmergencyContactName.LastName}`;
    }

    public get hasEmergencyContact(): boolean {
        let fn: string = this.EmergencyContactName.FirstName;
        let ln: string = this.EmergencyContactName.LastName;
        let pn: string = this.EmergencyContactPhoneNumber;
        return fn && fn.length > 0 && ln && ln.length > 0 && pn && pn.length > 0;
    }

    public get fullAddress(): string {
        let pa: IAddressLongLat = this.PatientAddress;
        let addressLine2: string = pa.AddressLine2 ? `${pa.AddressLine2}, ` : "";
        let addressZipCodeFour = pa.AddressZipCodeFour ? `- ${pa.AddressZipCodeFour}` : "";
        return `${pa.AddressLine1} ${addressLine2} ${pa.AddressCity}, ${pa.AddressStateCode} ${pa.AddressZipCodeFive} ${addressZipCodeFour}`;
    }

    public get isPatientBirthDate(): boolean {
        let dob: Moment = moment(this.Dob);
        let today: Moment = moment();
        return dob.date() === today.date() && dob.month() === today.month();
    }

    public get birthDateTooltip(): string {
        return `Today is ${this.patientFullName}'s birthday.`;
    }

    public get statusType(): string {
        let st: string = "primary";
        if (this.StatusName) {
            for (let type in STATUS_TYPE_ID_MAP) {
                if (STATUS_TYPE_ID_MAP[type].indexOf(this.StatusName) > -1) {
                    st = type;
                }
            }
        }
        return st;
    }

    public get statusNameType(): string {
        let st: string = "Reopen";
        if (this.Status) {
            for (let type of Object.keys(STATUS_TYPE_ID)) {
                if (STATUS_TYPE_ID[type].indexOf(this.Status) > -1) {
                    st = type;
                }
                if (
                    (this.Application == ApplicationEnum.AxxessHospiceFE ||
                        this.Application == ApplicationEnum.AxxessPalliative) &&
                    this.Status == "Completed"
                ) {
                    st = "Add Addendum";
                }
            }
        }

        return st;
    }
    public get statusNameHosPal(): string {
        let st;
        if (this.StatusName) {
            for (let type in STATUS_TYPE_ID_HOS_PAL) {
                if (STATUS_TYPE_ID_HOS_PAL[type].indexOf(this.StatusName) > -1) {
                    st = type;
                }
            }
        }

        return st;
    }

    public get buttonTooltip(): string {
        let st: string = this.statusType;
        return `Click to ${st === "normal" ? "start" : "open"} the visit.`;
    }

    public get agencyTooltip(): string {
        return `${this.AgencyName}`;
    }
}

export class RescheduleTask implements IRescheduleVisitDetails {
    public AgencyId: string = null;
    public EventId: string = null;
    public PatientId: string = null;
    public EpisodeId: string = null;
    public UserId: string = null;
    public DisciplineTask: number = null;
    public Discipline: string = null;
    public IsMissedVisit: boolean = null;
    public OrderHasSignedDates: boolean = null;
    public CustomId: number = null;
    public IsBillable: boolean = null;
    public IsPayable: boolean = null;
    public TravelTimeIn: string = "00:00:00";
    public TravelTimeOut: string = "00:00:00";
    public EventDate: string = null;
    public VisitDate: string = null;
    public TimeIn: string = "00:00:00";
    public TimeOut: string = "00:00:00";
    public Status: string = null;
    public ServiceLocation: string = null;
    public IsEpisodeReassiged: boolean = false;
    public Application: ApplicationEnum = null;
    [key: string]: any;

    public constructor(data?: IRescheduleVisitDetails) {
        if (data) {
            Object.assign(this, data);
        }
    }

    public fillRequiredDetails(source: IVisitDetails) {
        if (!source) {
            return;
        }
        const keysOfRescheduleTask = Object.keys(this);
        if (keysOfRescheduleTask.length) {
            for (let key of keysOfRescheduleTask) {
                if (source[key] != null) {
                    this[key] = source[key];
                }
            }
        }
        this.Discipline = source.DisciplineDesc || null;
        this.OrderHasSignedDates = source.SignatureDateTime ? true : false;
        this.Status = source.StatusEnum?.toString() ?? null;
        this.EventId = source.Id;
    }
}

export class PostToAxessCareDetails implements IPostTaskToAxxessCareVisitDetails {
    public AgencyId: string;
    public EpisodeId: string;
    public PatientId: string;
    public EventId: string;
    public DisciplineTaskId: number;
    public VisitType: string;
    public ScheduleDate: string;
    public TaskType: string;
    public SpecialtyId: number = 0;
    public PrivateJob: boolean = false;
    public VisitDetails: string;
    public HipaaInfo: string;
    public AddressLine1: string;
    public AddressLine2: string;
    public City: string;
    public ZipCode: string;
    public StateCode: string;
    public PayRates: IPayRate[] = [];
}
