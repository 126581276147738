import { defineComponent, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { mapActions } from "pinia";
import moment from "moment";

import { Container } from "aurelia-dependency-injection";

import { IBranchResponse } from "../../../resources-vue/vue-interfaces/i-branch";
import { IHospicePatient } from "../../../resources-vue/vue-interfaces/i-hospice-patient";
import {
    IApplicationAndAccountQueryParam,
    IHomeHealthReferralQueryParam
} from "../../../resources-vue/vue-interfaces/i-get-referral";
import { INonAdmitPatientQuery } from "../../../resources-vue/vue-interfaces/i-get-referral";
import { ApplicationEnum, HttpStatusCodeEnum } from "../../../enums/enums";
import { Gender, MaritalStatuses } from "../../../enums/enums";
import { HomeHealthReferral } from "../../../resources-vue/vue-models/homehealth-referral";
import { HomeCareReferral } from "../../../resources-vue/vue-models/homecare-referral";
import { EmergencyContact } from "../../../resources-vue/vue-models/homecare-emergency-contact";
import { EmergencyContact as EmergencyContactHH } from "../../../resources-vue/vue-models/emergency-contact";
import { IPromptOptions } from "../../../resources-vue/vue-dialogs/Prompt/Prompt";
import { ReferralService } from "../../../services/referral-service";
import { ToastrService } from "../../../services/toastr.service";
import { BranchesService } from "../../../services/branches-service";
import { PatientService } from "../../../services/patient.service";
import { CentralIntakeService } from "../../../services/central-intake.service";
import { UserService } from "../../../services/user-service";
import { scrollToFirstError } from "../../../common/utilities/first-error-scroll";
import { useLoadingIndicator } from "../../Common/LoadingIndicator/store/useLoadingIndicator";
import { HospiceReferral } from "../../../resources-vue/vue-models/hospice-referral";

import FormSection from "../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import CommentInput from "../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";
import PageLayout from "../../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import ReferralSourceInformation from "./components/ReferralSourceInformation/ReferralSourceInformation.vue";
import DemographicsInformation from "./components/DemographicsInformation/DemographicsInformation.vue";
import PatientTags from "./components/PatientTags/PatientTags.vue";
import ClinicalInformation from "./components/ClinicalInformation/ClinicalInformation.vue";
import PayorInformation from "./components/PayorInformation/PayorInformation.vue";
import Prompt from "../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";

export default defineComponent({
    components: {
        PageLayout,
        ReferralSourceInformation,
        DemographicsInformation,
        FormSection,
        PatientTags,
        CommentInput,
        ClinicalInformation,
        PayorInformation
    },

    data() {
        return {
            _toastrService: null,
            _referralService: null,
            _branchesService: null,
            _patientService: null,
            _centralIntakeService: null,
            _userService: null,
            branches: [] as IBranchResponse[],
            referral: new HomeHealthReferral(),
            dialogRef: null,
            isFormLoading: false,
            isLoadError: false,
            isSaveInProgress: false,
            hasErrorOnSave: false,
            isCreate: false,
            isRefer: false,
            isNonAdmit: false,
            isConvertToReferral: false,
            notesSection: null,
            nonAdmitReason: null,
            nonAdmitReasonId: null,
            nonAdmitDate: null,
            patientId: null,
            patientApplication: null,
            patientBranchId: null,
            patientData: null as IHospicePatient,
            accountId: "",
            referralId: "",
            inquiryId: "",
            inquiry: null,
            currentApplication: ApplicationEnum.AgencyCore,
            commentInputClass: { input: "w-35" },
            referralOptions: null,
            patientFirstName: "",
            firstName: "",
            patientLastName: "",
            lastName: "",
            branchId: "",
            hcEmergencyContacts: [] as EmergencyContact[],
            currentUserId: null as string,
            isPatientIdentifierEnabled: null,
            referralData: null as HospiceReferral,
            isPatient: false as boolean,
            v$: useVuelidate()
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    async created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._branchesService = Container.instance.get(BranchesService);
        this._patientService = Container.instance.get(PatientService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._userService = Container.instance.get(UserService);

        if (this.$route.query?.isCreate === "true") {
            this.isCreate = true;
        }
        if (this.$route.query?.isRefer === "true") {
            this.isRefer = true;
        }
        if (this.isRefer) {
            this.isNonAdmit = this.$route.query.isNonAdmit === "true" ? true : false;
            this.nonAdmitReason = this.$route.query.nonAdmitReason;
            this.nonAdmitDate = this.$route.query.nonAdmitDate;
            this.nonAdmitReasonId = this.$route.query.nonAdmitReasonId;
            this.patientId = this.$route.query.patientId;
            this.patientBranchId = this.$route.query.patientBranchId;
            this.patientApplication = this.$route.query.patientApplication;
            this.isPatient = this.$route.query.isPatient === "true" ? true : false;
        } else if (!this.isRefer && this.isCreate) {
            if (this.$route.query?.fName) {
                this.firstName = this.$route.query.fName as string;
            }
            if (this.$route.query?.lName) {
                this.lastName = this.$route.query.lName as string;
            }
        }
        if (this.$route.query?.locationId) {
            this.branchId = this.$route.query.locationId as string;
        }
        if (this.$route.query?.convertToReferral === "true") {
            this.isConvertToReferral = true;
        }
        if (this.$route.query.inquiryId) {
            this.inquiryId = this.$route.query.inquiryId as string;
        }
        if (this.$route.params?.applicationId) {
            this.referral.application = this.$route.params.applicationId as unknown as ApplicationEnum;
        }
        if (this.$route.params?.accountId) {
            this.accountId = this.$route.params.accountId as string;
            this.referral.accountId = this.$route.params.accountId as string;
            this.referral.agencyId = this.$route.params.accountId as string;
        }
        if (this.$route.params?.id) {
            this.referralId = this.$route.params.id as string;
            await this.loadReferral();
        } else {
            this._toastrService.error({
                title: `Error`,
                message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
            });
            this.$router.push("/axxess-central/intake/referral-list");
        }
    },

    async mounted() {
        const branches = await this._branchesService.getAllBranches();
        this.branches = branches.filter((branch: any) => branch.accountId == this.referral.accountId);
        const currentBranch = this.branches.find((branch) => branch.id === this.branchId);
        this.isPatientIdentifierEnabled = currentBranch?.isPatientIdentifierEnabled;
        this.notesSection = this.$refs.notesSection;
        let hash = window.location.hash;
        if (!!hash) {
            this.notesSection?.scrollIntoView();
        }
    },

    watch: {
        branchId() {
            this.referral.Referral.BranchId = this.branchId;
        }
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        async getReferralData() {
            try {
                const data =
                    (await this._referralService.getReferralData(
                        {
                            accountId: this.referral.accountId,
                            application: this.referral.application
                        },
                        this.referralId
                    )) || null;
                if (data) {
                    this.referral = new HomeHealthReferral(data);
                    this.branchId = data.Referral.BranchId;
                    this.referral.accountId = this.accountId;
                    this.referral.application = Number(this.currentApplication);
                    this.referral.agencyId = this.accountId;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this referral. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async loadReferral() {
            try {
                this.showLoading(true);
                this.isLoadError = false;
                this.isFormLoading = true;
                await this.getReferralOptions();
                if (this.isCreate) {
                    this.referral = new HomeHealthReferral();
                    this.referral.accountId = this.accountId;
                    this.referral.agencyId = this.accountId;
                    this.referral.Referral.FirstName = this.firstName;
                    this.referral.Referral.LastName = this.lastName;
                    this.referral.Referral.BranchId = this.branchId;
                    this.referral.application = this.currentApplication;
                    this.referral.InquiryId = this.inquiryId;
                    if (this.isRefer) {
                        await this.getCurrentUser();

                        if (this.patientApplication === ApplicationEnum.HomeCare.toString()) {
                            let referral = this.isPatient
                                ? await this.getHomeCarePatientData()
                                : await this.getHomeCareReferralData();

                            const filteredPhoneNumbers = referral.phoneNumbers.map((item: any) => {
                                if (this.isPatient) {
                                    if (item.ordinal == 1) {
                                        item = { ...item, isPrimary: true };
                                    }
                                    if (item.ordinal == 2) {
                                        item = { ...item, isPrimary: false };
                                    }
                                }
                                delete item.id;
                                return item;
                            });

                            this.referral.Referral.ReferralDate = String(moment().format("MM/DD/YYYY"));
                            this.referral.Referral.InternalReferral = this.currentUserId;
                            this.referral.Referral.FirstName = this.patientFirstName = referral.firstName;
                            this.referral.Referral.MiddleInitial = this.isPatient
                                ? referral.middleInitial
                                : referral.middleName;
                            this.referral.Referral.LastName = this.patientLastName = referral.lastName;
                            this.referral.Referral.DOB = String(moment(referral.dob).format("MM/DD/YYYY"));
                            this.referral.Referral.PhoneHome = filteredPhoneNumbers.length
                                ? filteredPhoneNumbers.find((phone: any) => phone.isPrimary).number
                                : "";
                            this.referral.Referral.PhoneMobile =
                                filteredPhoneNumbers.length > 1
                                    ? filteredPhoneNumbers.find((phone: any) => phone.isPrimary == false)?.number
                                    : "";
                            this.referral.Referral.Gender = this.isPatient
                                ? Gender.getDescriptionUsingKey(referral.gender)
                                : Gender.getDescriptionUsingValue(referral.gender);
                            this.referral.Referral.EmailAddress = referral.emailAddress;
                            this.referral.Referral.SSN = referral.ssn;
                            this.referral.Referral.MaritalStatus =
                                referral.maritalStatus == "0" || referral.maritalStatus == null
                                    ? null
                                    : this.isPatient
                                    ? MaritalStatuses.getDescriptionUsingKey(referral.maritalStatus)
                                    : referral.maritalStatus;

                            this.referral.ReferralExtension.SameAsPhysicalAddress =
                                referral.isMailingAddressSameAsPrimary;

                            if (this.isPatient) {
                                this.referral.Referral.AddressLine1 = referral.address.line1;
                                this.referral.Referral.AddressLine2 = referral.address.line2;
                                this.referral.Referral.AddressCity = referral.address.locality;
                                this.referral.Referral.AddressCounty = referral.address.subAdministrativeArea;
                                this.referral.Referral.AddressStateCode = referral.address.administrativeArea;
                                this.referral.Referral.AddressZipCode = this.checkZipcodeLength(
                                    referral.address.postalCode
                                );
                                if (referral.additionalAddress.length) {
                                    referral.additionalAddress.forEach((address: any) => {
                                        if (address.type == 5) {
                                            this.referral.ReferralExtension.MailingAddressLine1 = address.line1;
                                            this.referral.ReferralExtension.MailingAddressLine2 = address.line2;
                                            this.referral.ReferralExtension.MailingCity = address.locality;
                                            this.referral.ReferralExtension.MailingCounty =
                                                address.subAdministrativeArea;
                                            this.referral.ReferralExtension.MailingStateCode =
                                                address.administrativeArea;
                                            this.referral.ReferralExtension.MailingZipCode = this.checkZipcodeLength(
                                                address.postalCode
                                            );
                                        }
                                        if (address.isServiceAddress) {
                                            this.referral.ReferralExtension.VisitAddressLine1 = address.line1;
                                            this.referral.ReferralExtension.VisitAddressLine2 = address.line2;
                                            this.referral.ReferralExtension.VisitCity = address.locality;
                                            this.referral.ReferralExtension.VisitCounty = address.subAdministrativeArea;
                                            this.referral.ReferralExtension.VisitStateCode = address.administrativeArea;
                                            this.referral.ReferralExtension.VisitZipCode = this.checkZipcodeLength(
                                                address.postalCode
                                            );
                                        }
                                    });
                                }
                            }

                            if (!this.isPatient) {
                                this.referral.Referral.AddressLine1 = referral.primaryAddress.addressLine1;
                                this.referral.Referral.AddressLine2 = referral.primaryAddress.addressLine2;
                                this.referral.Referral.AddressCity = referral.primaryAddress.city;
                                this.referral.Referral.AddressCounty = referral.primaryAddress.county;
                                this.referral.Referral.AddressStateCode = referral.primaryAddress.state;
                                this.referral.Referral.AddressZipCode = this.checkZipcodeLength(
                                    referral.primaryAddress.zipCode
                                );
                            }

                            this.referral.Referral.MbiNumber = "";
                            this.referral.Referral.MedicaidNumber = referral.medicaidNumber;
                            this.referral.ReferralExtension.AlternateMedicaidNumber = referral.alternateMedicaidNumber;

                            this.hcEmergencyContacts = await this.getEmergencyContacts(this.patientId);
                            if (this.hcEmergencyContacts?.length) {
                                this.hcEmergencyContacts.forEach((contact) => {
                                    if (contact.isPrimary) {
                                        this.referral.Referral.EmergencyContact.FirstName = contact.firstName;
                                        this.referral.Referral.EmergencyContact.LastName = contact.lastName;
                                        this.referral.Referral.EmergencyContact.EmailAddress = contact.email;
                                        contact.phoneNumbers.forEach((number) => {
                                            if (number.isPrimary) {
                                                this.referral.Referral.EmergencyContact.PrimaryPhone =
                                                    number.number == null ? "" : number.number;
                                            } else {
                                                this.referral.Referral.EmergencyContact.AlternatePhone =
                                                    number.number == null ? "" : number.number;
                                            }
                                        });
                                        this.referral.Referral.EmergencyContact.SameAsPatientAddress =
                                            contact.isUsingPrimaryAddress;
                                        this.referral.Referral.EmergencyContact.AddressLine1 =
                                            contact.address.addressLine1;
                                        this.referral.Referral.EmergencyContact.AddressLine2 =
                                            contact.address.addressLine2;
                                        this.referral.Referral.EmergencyContact.AddressCity = contact.address.city;
                                        this.referral.Referral.EmergencyContact.AddressCounty =
                                            contact.address?.country;
                                        this.referral.Referral.EmergencyContact.AddressZipCode =
                                            this.checkZipcodeLength(contact.address.zipCode);
                                        this.referral.Referral.EmergencyContact.AddressStateCode =
                                            contact.address.state;
                                    } else {
                                        let emergencyContact: EmergencyContactHH = new EmergencyContactHH();
                                        emergencyContact.FirstName = contact.firstName;
                                        emergencyContact.LastName = contact.lastName;
                                        emergencyContact.EmailAddress = contact.email;
                                        contact.phoneNumbers.forEach((number) => {
                                            if (number.isPrimary) {
                                                emergencyContact.PrimaryPhone = number.number;
                                            } else {
                                                emergencyContact.AlternatePhone = number.number;
                                            }
                                        });
                                        emergencyContact.SameAsPatientAddress = contact.isUsingPrimaryAddress;
                                        emergencyContact.AddressLine1 = contact.address.addressLine1;
                                        emergencyContact.AddressLine2 = contact.address.addressLine2;
                                        emergencyContact.AddressCity = contact.address.city;
                                        emergencyContact.AddressCounty = contact.address?.country;
                                        emergencyContact.AddressZipCode = this.checkZipcodeLength(
                                            contact.address.zipCode
                                        );
                                        emergencyContact.AddressStateCode = contact.address.state;
                                        this.referral.ReferralExtension.AdditionalEmergencyContact.push(
                                            emergencyContact
                                        );
                                    }
                                });
                            }
                        }

                        if (this.isPatient) {
                            if (
                                this.patientApplication == ApplicationEnum.AxxessHospiceFE.toString() ||
                                this.patientApplication == ApplicationEnum.AxxessHospice.toString() ||
                                this.patientApplication == ApplicationEnum.AxxessPalliative.toString()
                            ) {
                                this.patientData = (await this.getHospicePatientData()).data.model;
                                this.referral.Referral.ReferralDate = moment().format("MM/DD/YYYY");
                                this.referral.Referral.FirstName = this.patientFirstName = this.patientData.firstName;
                                this.referral.Referral.MiddleInitial = this.patientData.middleInitial;
                                this.referral.Referral.LastName = this.patientLastName = this.patientData.lastName;
                                this.referral.Referral.DOB = this.patientData.dateOfBirth;
                                this.referral.Referral.Gender =
                                    this.patientData.gender === 0
                                        ? "0"
                                        : Gender.getDescriptionUsingValue(this.patientData.gender);
                                this.referral.Referral.SSN = this.patientData.socialSecurityNumber;
                                this.referral.Referral.PhoneHome = this.patientData.phoneNumbers.length
                                    ? this.patientData.phoneNumbers.find((phone: any) => phone.isPrimary).number
                                    : "";
                                this.referral.Referral.EmailAddress = this.patientData.email;
                                this.referral.Referral.MaritalStatus =
                                    this.patientData.maritalStatus == 0
                                        ? "0"
                                        : MaritalStatuses.getDescriptionUsingValue(this.patientData.maritalStatus);
                                this.referral.Referral.InternalReferral = this.patientData.internalReferralSourceId;
                                this.referral.Referral.AddressLine1 = this.patientData.primaryAddress.addressLine1;
                                this.referral.Referral.AddressLine2 = this.patientData.primaryAddress.addressLine2;
                                this.referral.Referral.AddressCity = this.patientData.primaryAddress.city;
                                this.referral.Referral.AddressCounty = this.patientData.primaryAddress.county;
                                this.referral.Referral.AddressStateCode = this.patientData.primaryAddress.state;
                                this.referral.Referral.AddressZipCode = this.checkZipcodeLength(
                                    this.patientData.primaryAddress.zipCode
                                );
                                this.referral.ReferralExtension.VisitAddressLine1 =
                                    this.patientData.visitAddress.addressLine1;
                                this.referral.ReferralExtension.VisitAddressLine2 =
                                    this.patientData.visitAddress.addressLine2;
                                this.referral.ReferralExtension.VisitCity = this.patientData.visitAddress.city;
                                this.referral.ReferralExtension.VisitCounty = this.patientData.visitAddress.county;
                                this.referral.ReferralExtension.VisitStateCode = this.patientData.visitAddress.state;
                                this.referral.ReferralExtension.VisitZipCode = this.checkZipcodeLength(
                                    this.patientData.visitAddress.zipCode
                                );
                                this.referral.ReferralExtension.MailingAddressLine1 =
                                    this.patientData.mailingAddress.addressLine1;
                                this.referral.ReferralExtension.MailingAddressLine2 =
                                    this.patientData.mailingAddress.addressLine2;
                                this.referral.ReferralExtension.MailingCity = this.patientData.mailingAddress.city;
                                this.referral.ReferralExtension.MailingCounty = this.patientData.mailingAddress.county;
                                this.referral.ReferralExtension.MailingStateCode =
                                    this.patientData.mailingAddress.state;
                                this.referral.ReferralExtension.MailingZipCode = this.checkZipcodeLength(
                                    this.patientData.mailingAddress.zipCode
                                );
                                this.referral.Referral.MbiNumber = this.patientData.medicareBeneficiaryIdentification;
                                this.referral.Referral.MedicaidNumber = this.patientData.medicaidNumber;
                                this.referral.Referral.IsMedicareNA =
                                    this.patientData.medicareBeneficiaryIdentificationNA;
                                this.referral.Referral.IsMedicaidNA = this.patientData.notAMedicaid;
                            }
                        } else if (!this.isPatient) {
                            if (
                                this.patientApplication == ApplicationEnum.AxxessHospiceFE.toString() ||
                                this.patientApplication == ApplicationEnum.AxxessHospice.toString() ||
                                this.patientApplication == ApplicationEnum.AxxessPalliative.toString()
                            ) {
                                this.referralData = (await this.getHosPalReferralData()) as any;
                                this.referral.Referral.ReferralDate = moment().format("MM/DD/YYYY");
                                this.referral.Referral.FirstName = this.referralData.firstName;
                                this.referral.Referral.MiddleInitial = this.referralData.middleInitial;
                                this.referral.Referral.LastName = this.referralData.lastName;
                                this.referral.Referral.DOB = this.referralData.dateOfBirth;
                                this.referral.Referral.Gender =
                                    this.referralData.gender === 0
                                        ? "0"
                                        : Gender.getDescriptionUsingValue(this.referralData.gender);
                                this.referral.Referral.SSN = this.referralData.socialSecurityNumber;
                                this.referral.Referral.PhoneHome = this.referralData.phoneNumbers.length
                                    ? this.referralData.phoneNumbers.find((phone: any) => phone.isPrimary).number
                                    : "";
                                this.referral.Referral.EmailAddress = this.referralData.email;
                                this.referral.Referral.MaritalStatus =
                                    this.referralData.maritalStatus == 0
                                        ? "0"
                                        : MaritalStatuses.getDescriptionUsingValue(this.referralData.maritalStatus);
                                this.referral.Referral.InternalReferral = this.referralData.internalReferralSourceId;
                                this.referral.Referral.AddressLine1 = this.referralData.primaryAddress?.addressLine1;
                                this.referral.Referral.AddressLine2 = this.referralData.primaryAddress?.addressLine2;
                                this.referral.Referral.AddressCity = this.referralData.primaryAddress?.city;
                                this.referral.Referral.AddressCounty = this.referralData.primaryAddress?.county;
                                this.referral.Referral.AddressStateCode = this.referralData.primaryAddress?.state;
                                this.referral.Referral.AddressZipCode = this.checkZipcodeLength(
                                    this.referralData.primaryAddress?.zipCode
                                );
                                this.referral.ReferralExtension.VisitAddressLine1 =
                                    this.referralData.visitAddress?.addressLine1;
                                this.referral.ReferralExtension.VisitAddressLine2 =
                                    this.referralData.visitAddress?.addressLine2;
                                this.referral.ReferralExtension.VisitCity = this.referralData.visitAddress?.city;
                                this.referral.ReferralExtension.VisitCounty = this.referralData.visitAddress?.county;
                                this.referral.ReferralExtension.VisitStateCode = this.referralData.visitAddress?.state;
                                this.referral.ReferralExtension.VisitZipCode = this.checkZipcodeLength(
                                    this.referralData.visitAddress?.zipCode
                                );
                                this.referral.ReferralExtension.MailingAddressLine1 =
                                    this.referralData.mailingAddress?.addressLine1;
                                this.referral.ReferralExtension.MailingAddressLine2 =
                                    this.referralData.mailingAddress?.addressLine2;
                                this.referral.ReferralExtension.MailingCity = this.referralData.mailingAddress?.city;
                                this.referral.ReferralExtension.MailingCounty =
                                    this.referralData.mailingAddress?.county;
                                this.referral.ReferralExtension.MailingStateCode =
                                    this.referralData.mailingAddress?.state;
                                this.referral.ReferralExtension.MailingZipCode = this.checkZipcodeLength(
                                    this.referralData.mailingAddress?.zipCode
                                );
                                this.referral.Referral.MbiNumber = this.referralData.medicareBeneficiaryIdentification;
                                this.referral.Referral.MedicaidNumber = this.referralData.medicaidNumber;
                                this.referral.Referral.IsMedicareNA =
                                    this.referralData.medicareBeneficiaryIdentificationNA;
                                this.referral.Referral.IsMedicaidNA = this.referralData.notAMedicaid;
                            }
                        }
                    }
                    if (this.isConvertToReferral && this.inquiryId) {
                        const inquiryByIdParams = {
                            application: this.currentApplication,
                            agencyId: this.accountId
                        };
                        this.inquiry = await this._centralIntakeService.getInquiryById(
                            this.inquiryId,
                            inquiryByIdParams
                        );
                        this.referral.Referral.OtherReferralSource = `${this.inquiry.contactFirstName} ${this.inquiry.contactLastNameOrFacilitySource}  ${this.inquiry.contactPhoneNumber}`;
                        this.referral.Referral.Comments = this.inquiry.comments;
                    }
                } else {
                    await this.getReferralData();
                }
            } catch (e) {
                this.isLoadError = true;
                console.error(e);
            } finally {
                this.showLoading(false);
                this.isFormLoading = false;
            }
        },

        async getReferralOptions() {
            this.referralOptions = await this._referralService.getReferralOptions(this.accountId);
            this.referralOptions.admissionSource =
                this.referralOptions?.admissionSource.map((item: any) => ({
                    value: Number(item.value) || 0,
                    text: item.text || null,
                    type: item.type || null
                })) || null;
            this.referralOptions.admissionType =
                this.referralOptions?.admissionType.map((item: any) => ({
                    value: Number(item.value) || 0,
                    text: item.text || null,
                    type: item.type || null
                })) || null;
        },

        async getHosPalReferralData() {
            const queryParam = {
                application: this.patientApplication,
                accountId: this.patientBranchId,
                id: this.patientId
            };
            let referral = (await this._referralService.getReferral(this.patientId, queryParam)) as any;
            return referral;
        },

        async getHHPaitentData() {
            try {
                const result = await this._patientService.getHHPatientAllData({
                    agencyId: this.$route.params?.patientBranchId,
                    patientId: this.$route.params?.patientId
                });
                if (result) {
                    return result.data;
                }
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Home Health patient. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async getHomeCarePatientData() {
            const queryParam = {
                application: this.patientApplication,
                agencyId: this.patientBranchId,
                patientId: this.patientId
            };

            let referral = (await this._patientService.getHCPatientById(queryParam)).data;
            return referral;
        },

        async getHomeCareReferralData() {
            const queryParam = {
                application: this.patientApplication,
                accountId: this.patientBranchId,
                id: this.patientId
            };

            let referral = (await this._referralService.getHomeCareReferral(
                this.referralId,
                queryParam
            )) as HomeCareReferral;
            return referral;
        },

        getEmergencyContacts(id: string) {
            const payload: IApplicationAndAccountQueryParam = {
                application: this.patientApplication,
                agencyId: this.patientBranchId
            };
            return this._referralService.getHCEmergencyContacts(id, payload);
        },

        async getCurrentUser() {
            const agencyId = this.accountId;

            try {
                let response = await this._userService.getCurrentUserId({ agencyId: agencyId });
                this.currentUserId = response.userId;
            } catch (err) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this User Id. Please try again. If the problem persists, please report it.`
                });
            }
        },

        async create() {
            let isModelValid = await this.v$.$validate();
            if (isModelValid) {
                try {
                    this.hasErrorOnSave = false;
                    this.isSaveInProgress = true;
                    await this.createReferral();
                    this._toastrService.success({
                        title: `Referral Created`,
                        message: `The referral <b>${this.referral.Referral.LastName} ${this.referral.Referral.FirstName}</b> has been successfully created.`
                    });
                    if (this.$route.query?.isRefer === "true" && this.$route.query?.isNonAdmit === "true") {
                        this.doNonAdmit();
                    }
                    this.$router.push("/axxess-central/intake/referral-list");
                } catch (err) {
                    this._toastrService.error({
                        title: `Error`,
                        message: `There was a problem while creating this referral. Please try again. If the problem persists, please report it.`
                    });
                } finally {
                    this.isSaveInProgress = false;
                }
            } else {
                scrollToFirstError();
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fix the validation errors in the form.`
                });
            }
        },

        async createReferral() {
            try {
                this.referral.agencyId = this.accountId;
                this.referral.Referral.AgencyId = this.accountId;
                this.referral.Referral.AdditionalBillingInfo.AgencyId = this.accountId;
                this.referral.ReferralExtension.AgencyId = this.accountId;
                const payload: IHomeHealthReferralQueryParam = {
                    Application: this.currentApplication,
                    AgencyId: this.accountId,
                    AccountId: this.accountId,
                    CreateHHReferralPayload: this.referral
                };
                await this._referralService.createHomeHealthReferral(payload);
            } catch (e) {
                console.error(e);
                throw e;
            }
        },

        async update() {
            let isModelValid = await this.v$.$validate();
            if (isModelValid) {
                try {
                    this.isSaveInProgress = true;
                    const payload: IHomeHealthReferralQueryParam = {
                        Application: this.currentApplication,
                        AgencyId: this.accountId,
                        AccountId: this.accountId,
                        CreateHHReferralPayload: this.referral
                    };
                    await this._referralService.editHomeHealthReferral(payload, this.referral.Referral.Id);
                    this._toastrService.success({
                        title: `Referral Updated`,
                        message: `The referral <b>${this.referral.Referral.LastName} ${this.referral.Referral.FirstName}</b> has been successfully updated.`
                    });
                } catch (err) {
                    console.error(err);
                    if (err.status === HttpStatusCodeEnum.NotModified) {
                        this._toastrService.info({
                            title: `The referral <b>${this.referral.Referral.FirstName} ${this.referral.Referral.LastName}</b> is up to date`,
                            message: `There were no changes made in ${this.referral.Referral.FirstName} ${this.referral.Referral.LastName}'s profile.`
                        });
                    } else {
                        this._toastrService.error({
                            title: `Error`,
                            message: `There was a problem while saving this referral. Please try again. If the problem persists, please report it.`
                        });
                    }
                } finally {
                    this.isSaveInProgress = false;
                }
            } else {
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fix the validation errors in the form.`
                });
            }
        },

        async goToReferralList() {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to leave the page? Please make sure you saved your work.",
                okText: "Yes, I'm Sure",
                cancelText: "Never Mind"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: (options) => {
                    if (options.data === "success") {
                        this.$router.push("/axxess-central/intake/referral-list");
                    }
                }
            });
        },

        resultUpdatedAppended(value: string) {
            this.referral.Referral.Comments = this.referral.Referral.Comments + value;
        },
        resultUpdated(value: string) {
            this.referral.Referral.Comments = value;
        },
        changeBranchId(newValue: string) {
            this.branchId = newValue;
        },
        async getHospicePatientData() {
            try {
                const queryParam = {
                    application: this.patientApplication,
                    patientId: this.patientId,
                    agencyId: this.patientBranchId
                };
                const referral = await this._patientService.getHospicePatientById(queryParam);
                return referral;
            } catch (error) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while getting this Hospice patient. Please try again. If the problem persists, please report it.`
                });
            }
        },
        checkZipcodeLength(zipcode: string) {
            if (zipcode?.length > 5 && this.patientApplication === ApplicationEnum.HomeCare.toString()) {
                return zipcode.substring(0, 5);
            }
            if (zipcode?.length > 5) {
                return "";
            }
            return zipcode;
        },
        doNonAdmit() {
            let nonAdmitDate = String(this.$route.query.nonAdmitDate);
            nonAdmitDate = moment(nonAdmitDate, "MM-DD-YYYY").format("MM/DD/YYYY");
            const nonAdmitReasonId = String(this.$route.query.nonAdmitReasonId);
            const nonAdmitReason = String(this.$route.query.nonAdmitReason);
            const patientAgencyId = this.$route.query.patientBranchId;
            const patientApplication = this.$route.query.patientApplication;
            const patientId = this.$route.query.patientId;
            let promptOptions = {
                message: "Are you sure you want to update this patient to Non-Admit?",
                okText: "Yes, I'm Sure",
                cancelText: "No"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            if (this.isPatient) {
                                if (
                                    patientApplication == ApplicationEnum.AxxessHospiceFE.toString() ||
                                    patientApplication == ApplicationEnum.AxxessPalliative.toString()
                                ) {
                                    let newNonAdmitData: INonAdmitPatientQuery = {
                                        nonAdmitDate: nonAdmitDate,
                                        nonAdmitReasonId: nonAdmitReasonId,
                                        nonAdmitComments: ""
                                    };

                                    let queryObj = JSON.parse(JSON.stringify(newNonAdmitData));
                                    queryObj.agencyId = patientAgencyId;
                                    queryObj.application = patientApplication;
                                    await this._patientService.updatePatientToNonAdmit(patientId, queryObj);
                                }
                                if (patientApplication === ApplicationEnum.HomeCare.toString()) {
                                    const queryObj = {
                                        AgencyId: patientAgencyId.toString(),
                                        NonAdmitDate: nonAdmitDate,
                                        Reason: nonAdmitReason,
                                        Application: ApplicationEnum.HomeCare,
                                        ReasonId: nonAdmitReasonId
                                    };
                                    await this._patientService.updatePatientToNonAdmitHC(patientId, queryObj);
                                }
                            } else {
                                const queryObj = {
                                    accountId: patientAgencyId.toString(),
                                    nonAdmitDate: nonAdmitDate,
                                    nonAdmitReasonId: nonAdmitReasonId,
                                    nonAdmitReason: [nonAdmitReason],
                                    application: Number(this.patientApplication)
                                };
                                await this._centralIntakeService.updateReferralToNonAdmit(patientId, queryObj);
                            }
                            this._toastrService.success({
                                title: `${this.isPatient ? "Patient" : "Referral"} Converted to Non-Admit`,
                                message: `The ${this.isPatient ? "patient" : "referral"} <b>${this.patientLastName}, ${
                                    this.patientFirstName
                                }</b> has been converted to Non-Admit.`
                            });
                        } catch (error) {
                            this._toastrService.error({
                                title: `Error -${this.isPatient ? "Patient" : "Referral"} convert to Non-Admit`,
                                message: `There was a problem while converting the ${
                                    this.isPatient ? "patient" : "referral"
                                } <b>${this.patientLastName}, ${this.patientFirstName}</b>. Please try again.`
                            });
                        }
                    }
                }
            });
        }
    }
});
