import { defineComponent } from "vue";
import { IPageCallbackResult } from "../../vue-interfaces/i-page-container";

export const PAGE_FAIL_RESPONSE: IPageCallbackResult = {
    totalPageCount: 0,
    success: false,
    totalCount: 0
};

export default defineComponent({
    props: {
        numberOfPagesToShow: {
            default: 9,
            type: Number
        },
        pageSize: {
            default: 10,
            type: Number
        },
        isLoading: {
            default: false,
            type: Boolean
        },
        pageChanged: {
            type: Function,
            default: null
        },
        pageSizeOptions: {
            default: [10, 25],
            type: Array
        },
        showPageSizeOptions: { default: true },
        entity: { default: "results" },
        classes: { default: "" }
    },

    emits: ["updatePageSizeChanged", "updateNumberOfPagesToShow"],

    data(vm) {
        return {
            currentPage: 1,
            totalPageCount: 0,
            totalDataCount: 0,
            noData: false,
            currentNavPages: [] as number[],
            from: null,
            to: null,
            pageOrder: 10
        };
    },

    created() {
        this.pageOrder = this.pageSize;
    },

    computed: {
        disableNext(): boolean {
            return this.currentPage === this.totalPageCount;
        },
        disablePrevious(): boolean {
            return this.currentPage === 1;
        }
    },

    methods: {
        updateNumOfPages(value: number) {
            this.$emit("updateNumberOfPagesToShow", value);
        },

        async updatePageSize() {
            this.$emit("updatePageSizeChanged", this.pageOrder);
            this.reset();
        },

        async getData(): Promise<void> {
            if (this.pageChanged != null) {
                try {
                    let data = await this.pageChanged(this.currentPage, this.pageOrder);
                    if (!data) {
                        this.noData = true;
                        throw new Error(`Error fetching ${this.entity} list`);
                    }
                    this.noData = !data.success || data.totalPageCount == 0;
                    this.totalPageCount = data.totalPageCount;
                    this.totalDataCount = data.totalCount;
                    this.generateNavPages();
                    this.updateRange();
                } catch (error) {
                    console.error(error?.message);
                }
            }
        },

        generateNavPages() {
            let total = this.totalPageCount;
            let show = this.numberOfPagesToShow;
            let current = this.currentPage;
            let navPages = [];
            let startPage = current - Math.floor(show / 2) <= 1 ? 1 : current - Math.floor(show / 2);
            let endPage = current + Math.floor(show / 2) >= total ? total : current + Math.floor(show / 2);

            if (total <= show) {
                for (let i = 1; i <= total; i++) {
                    navPages.push(i);
                }
                this.currentNavPages = navPages;
                return;
            }

            if (startPage === 1) {
                for (let i = 1; i <= show; i++) {
                    navPages.push(i);
                }
            } else if (endPage === total) {
                for (let i = 0; i < show; i++) {
                    navPages.unshift(total - i);
                }
            } else {
                for (let i = startPage; i <= endPage; i++) {
                    navPages.push(i);
                }
            }
            this.currentNavPages = navPages;
        },

        updateRange() {
            this.from = (this.currentPage - 1) * Number(this.pageOrder) + 1;
            let to = this.from + Number(this.pageOrder) - 1;
            if (to > this.totalDataCount) {
                to = this.totalDataCount;
            }

            this.to = to;
        },

        async changePage(pageNum: number = -1): Promise<void> {
            if (pageNum !== -1) {
                this.currentPage = pageNum;
            }
            this.getData();
        },

        async incrementPageNumber(): Promise<void> {
            if (this.currentPage !== this.totalPageCount) {
                this.currentPage++;
                this.changePage();
            }
        },

        async decrementPageNumber(): Promise<void> {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.changePage();
            }
        },

        async reset() {
            this.changePage(1);
        }
    }
});
