import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { UserManager } from "oidc-client";
import { mapActions } from "pinia";

import { AnalyticsService } from "../../../services/analytics.service";
import AuthService from "../../../services/auth.service";
import { BranchesService } from "../../../services/branches-service";
import { ToastrService } from "../../../services/toastr.service";
import type { IBranchResponse } from "../../../interfaces/i-branch";
import { IGetuserDetailResponse, IGetuserDetailsRequest, UserService } from "../../../services/user-service";

import DateTime from "../DateTime/DateTime.vue";
import WeatherReport from "../WeatherReport/WeatherReport.vue";
import { useLoadingIndicator } from "../../../pages-vue/Common/LoadingIndicator/store/useLoadingIndicator";

export default defineComponent({
    components: {
        DateTime,
        WeatherReport
    },

    props: {
        name: {
            type: String,
            default: ""
        },
        pageTitle: {
            type: String,
            default: ""
        },
        showBorder: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            _authService: null,
            _userService: null,
            _toastrService: null,
            _permissionManager: null,
            _analyticsService: null,
            _branchService: null,
            _logCategory: "Dashboard-Header",
            branches: [] as IBranchResponse[],
            _user: null,
            _userManager: null,
            userName: "",
            hasBetaTogglePermission: false
        };
    },

    created() {
        this._authService = Container.instance.get(AuthService);
        this._userService = Container.instance.get(UserService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this._branchService = Container.instance.get(BranchesService);
        this._userManager = Container.instance.get(UserManager);
        this._toastrService = Container.instance.get(ToastrService);
    },

    async mounted() {
        this.loadUser();
        const userbetaInfo: IGetuserDetailResponse = await this._userService.getUserDetails();
        if (userbetaInfo.Data) this.hasBetaTogglePermission = true;
    },

    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        logout(): void {
            this._authService.logout();
            this._analyticsService.logEvent({
                category: this._logCategory,
                action: "Log-Out",
                name: this.name
            });
        },

        goToHelp() {
            this.$router.push("/help");
        },

        async loadUser() {
            this._user = await this._userManager.getUser();
            if (this._user) {
                this.userName = this._user.profile.given_name;
            }
        },

        async navigateToOldCentral() {
            try {
                this.showLoading(true);
                const isBetaOpted = false;
                const updateResponse: IGetuserDetailResponse = await this._userService.updateUserBetaDetails(
                    isBetaOpted
                );
                if (updateResponse.Data) {
                    sessionStorage.setItem("isBetaOpted", isBetaOpted.toString());
                }
                const originURL = window.location.origin;
                window.location.href = originURL;
            } catch (e) {
                this.showLoading(false);
                this._toastrService.error({
                    title: `Error - Switching to Central`,
                    message: `There was an error switching to Central. Please Try again later.`
                });
            }
        }
    }
});
