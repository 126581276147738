import { computed, defineComponent, PropType } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment, { Moment } from "moment";
import $ from "jquery";

import type { IMarkerOptions } from "../../../../resources-vue/vue-interfaces/i-marker";
import type { ITask } from "../../../../resources-vue/vue-interfaces/i-task";
import type { ILocation } from "./../../../../resources-vue/vue-interfaces/i-location";
import { AnalyticsService } from "../../../../services/analytics.service";
import { AgencyService } from "./../../../../services/agency.service";
import { GoogleMapsService } from "./../../../../services/google-maps.service";
import { GeolocationService, GeoLocationEvents } from "../../../../services-vue/geolocation.service";
import { formatFullAddress } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { Marker } from "../../../../resources-vue/vue-models/marker.model";
import eventBus from "../../../../utilities/eventBus";
import { GetZipEvents } from "../../../../resources-vue/vue-dialogs/GetZip/GetZip";
import GoogleMaps = google.maps;

import CardTemplate from "../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";
import getZip from "../../../../resources-vue/vue-dialogs/GetZip/GetZip.vue";

export default defineComponent({
    components: {
        CardTemplate
    },

    props: {
        myTaskDate: {
            type: Object as PropType<Moment>,
            default: moment()
        },
        taskList: {
            type: Array as PropType<ITask[]>,
            default: []
        },
        selectedTask: {
            type: Object as PropType<ITask>,
            default: {}
        },

        listUpdate: {
            type: Number
        }
    },

    data() {
        return {
            _myMarker: null as GoogleMaps.Marker,
            _bounds: null as GoogleMaps.LatLngBounds,
            _map: null as GoogleMaps.Map,
            _googleMapsService: null,
            _geolocationService: null as GeolocationService,
            _agencyService: null, // TODO: remove this after marker service is created.
            _locationData: [] as ILocation,
            _locations: {} as any, // TODO: Define type here
            _createdMarkerCount: 0,
            _totalLocations: 0,
            _analyticsService: null as AnalyticsService,
            zipNotEntered: false,
            _analytics: null as AnalyticsService,
            dialogRef: null,
            allLocationsCoords: [],
            formatFullAddress
        };
    },

    created() {
        this._analytics = Container.instance.get(AnalyticsService);
        this._geolocationService = Container.instance.get(GeolocationService);
        this._googleMapsService = Container.instance.get(GoogleMapsService);
        this._agencyService = Container.instance.get(AgencyService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this.initSubscriptions();
    },

    async mounted() {
        // This condition is required.
        // On route navigation, we will check whether location has been received already.
        if (this._geolocationService.hasGeo) {
            Object.assign(this._locationData, this._geolocationService.locationData);
            await this._init();
        }
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    computed: {
        isPresent(): boolean {
            let today = moment().format("YYYYMMDD");
            let tasksDate = moment(this.myTaskDate).format("YYYYMMDD");
            return today === tasksDate;
        },
        isFuture(): boolean {
            return moment(this.myTaskDate).isAfter(moment());
        }
    },

    watch: {
        taskList: {
            handler() {
                this._clearMarkers();
                this._init();
            }
        },
        selectedTask: {
            handler(newValue: ITask) {
                this.selectedTaskChanged(newValue);
            },
            deep: true
        },

        listUpdate() {
            this._clearMarkers();
            this._init();
        }
    },

    methods: {
        initSubscriptions() {
            eventBus.on(GeoLocationEvents.Retrieved, async (data: ILocation) => {
                Object.assign(this._locationData, data);
                await this._init();
            });

            eventBus.on(GetZipEvents.Changed, (zip: string) => {
                this.zipNotEntered = false;
            });

            eventBus.on(GetZipEvents.Canceled, (isChange: boolean) => {
                if (!isChange) {
                    this.zipNotEntered = true;
                }
            });

            eventBus.on("marker:created", (markerInstance: Marker) => {
                this.initMarkerEvents(markerInstance);
            });

            eventBus.on("marker:task:changed", (task: ITask) => {
                this.$emit("selectedTaskChanged", task);
            });
        },

        async _init(): Promise<void> {
            await this._googleMapsService.loadMapsApi();
            this._markUser();
            if (this.taskList.length > 0) {
                this.allLocationsCoords = [];
                this._groupTasksByLocation();
                await this._markLocations();
            }
        },

        _markUser(): void {
            try {
                // let data: ILocation = this._locationData;
                let lat: number = +this._locationData?.lat;
                let lon: number = +this._locationData?.lon;
                this._bounds = this._googleMapsService.getBounds();
                if (lat && lon) {
                    this._map = this._googleMapsService.getMap(document.getElementById("Tasks__Map"), {
                        center: {
                            lat: lat,
                            lng: lon
                        },
                        zoom: 20, // Higher is zoom in and lower is zoom out
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: google.maps.ControlPosition.RIGHT_BOTTOM
                        }
                    });
                    this._myMarker = this._googleMapsService.addMarker({
                        position: {
                            lat: lat,
                            lng: lon
                        },
                        map: this._map,
                        animationType: "drop",
                        type: "user"
                    });
                    let info = this._googleMapsService.addInfoWindow({
                        content: "<b>Your Location</b>"
                    });
                    // Marker Events
                    this._myMarker.addListener("mouseover", () => {
                        info.open(this._map, this._myMarker);
                    });
                    this._myMarker.addListener("mouseout", () => {
                        info.close();
                    });
                    this._bounds.extend(this._myMarker.getPosition());
                }
            } catch (error) {
                console.error(error?.message);
            }
        },

        _groupTasksByLocation(): void {
            let taskList = this.taskList;
            for (let task of taskList) {
                let address: string = task.fullAddress;

                if (!this._locations[address]) {
                    this._locations[address] = [];
                    this._totalLocations++;
                }
                if (!this._locations[address].some((existingtasks: ITask) => existingtasks.Id == task.Id)) {
                    this._locations[address].push(task);
                }
            }
        },

        async _markLocations(): Promise<void> {
            let locations: any = this._locations;
            if (this._map && locations && !$.isEmptyObject(locations)) {
                for (let address of Object.keys(locations)) {
                    let options: IMarkerOptions = {
                        map: this._map,
                        gm: this._googleMapsService, // TODO: Don't pass service to model. Sh*t code. Need Marker Service.
                        agencyService: this._agencyService, // TODO: Don't pass service to model. Sh*t code. Need Marker Service.
                        bounds: this._bounds,
                        address: address,
                        tasks: locations[address]
                    };
                    let marker = new Marker(options);
                    let latitude = options.tasks[0].PatientAddress.AddressLatitude;
                    let longitude = options.tasks[0].PatientAddress.AddressLongitude;
                    let taskKey = options.tasks[0].key;
                    // Back end is sending 0 instead of null,
                    // when coords are not retrieved from Google maps and stored in AgencyCore
                    if (!!latitude && !!longitude) {
                        marker.getLocationFromCoords(
                            {
                                lat: latitude,
                                lng: longitude
                            },
                            taskKey,
                            this.allLocationsCoords
                        );
                        this._analyticsService.logEvent({
                            category: "Create-Marker",
                            action: "Coordinates"
                        });
                    } else {
                        await marker.getLocationFromAddress(taskKey, this.allLocationsCoords);
                        this._analyticsService.logEvent({
                            category: "Create-Marker",
                            action: "Address"
                        });
                    }
                    this._locations[address].marker = marker;
                }
            }
        },

        initMarkerEvents(markerInstance: Marker) {
            if (markerInstance) {
                markerInstance.info.addListener("closeclick", () => {
                    markerInstance.closeInfoWindow();
                    this._analyticsService.logEvent({
                        category: "Marker-Info-Window",
                        action: "Close"
                    });
                });
                markerInstance.marker.addListener("click", () => {
                    markerInstance.toggleInfoWindow();
                    this._analyticsService.logEvent({
                        category: "Marker-Info-Window",
                        action: "Marker-Clicked"
                    });
                });
            }
            this._createdMarkerCount++;
            if (this._createdMarkerCount === this._totalLocations) {
                this._map.fitBounds(this._bounds);
            }
        },

        _clearMarkers(): void {
            let locations = this._locations;

            for (let address of Object.keys(locations)) {
                locations[address].marker?.deleteMarker();
                delete locations[address];
            }
            this._totalLocations = 0;
            this._createdMarkerCount = 0;
        },

        async enterLocation() {
            await this.openGetZipDialog();
        },

        // This Method pops the modal for the user to enter zip code.
        // Once the zip code changes, need to get the coordinates of the entered zip code.
        // Once we get the coordinates of the zip code, we need to update the weather-report.
        async updateLocation() {
            await this.openGetZipDialog(this._locationData?.zip?.toString());
        },

        async openGetZipDialog(zip: string = "") {
            let zipOptions = {
                zip: zip
            };
            this.dialogRef = this.$dialog.open(getZip, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    zipOptions
                }
            });
        },

        async taskListChanged(newValue: Array<ITask>): Promise<void> {
            this._clearMarkers();
            if (newValue) {
                if (newValue.length > 0 && this._geolocationService.hasGeo) {
                    this._groupTasksByLocation();
                    await this._markLocations();
                }
            }
        },

        selectedTaskChanged(newValue: ITask): void {
            // Close other info window
            newValue.fullAddress = formatFullAddress(newValue.PatientAddress);
            for (let address of Object.keys(this._locations)) {
                let marker = this._locations[address].marker;
                marker.closeInfoWindow();
            }
            if (newValue) {
                let address: string = newValue.fullAddress;
                let selectedMarker: Marker = this._locations[address].marker;
                selectedMarker.showTask(newValue);
                if (selectedMarker.tasks.length > 1) {
                    this.registerMarkerInfoNavEvents(selectedMarker);
                }
                this._analyticsService.logEvent({
                    category: "Marker-Info-Window",
                    action: "Open"
                });
            }
        },

        registerMarkerInfoNavEvents(markerInstance: Marker) {
            $(`#${markerInstance.id}`)
                .on("click", ".fa-chevron-circle-left", () => {
                    markerInstance.handleBwdNavigation();
                    this._analyticsService.logEvent({
                        category: "Marker-Info-Window",
                        action: "Backward-Navigation"
                    });
                })
                .on("click", ".fa-chevron-circle-right", () => {
                    markerInstance.handleFwdNavigation();
                    this._analyticsService.logEvent({
                        category: "Marker-Info-Window",
                        action: "Forward-Navigation"
                    });
                });
        }
    }
});
