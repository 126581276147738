import { ValidationRules } from "aurelia-validation";

export class DateTimeModel {
    public date: string = "";
    public time: string = "";

    public constructor(data?: DateTimeModel) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    public initValidation() {
        ValidationRules.ensure((dateTIme: DateTimeModel) => dateTIme.date)
            .required()
            .withMessage("Date is required.")
            .ensure((dateTIme: DateTimeModel) => dateTIme.time)
            .required()
            .withMessage("Time is required.")
            .on(this);
    }
}
