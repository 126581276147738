import "./employee-bulk-reassign.scss";

import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import { DialogController } from "aurelia-dialog";

import nameOf from "../../../common/name-of";
import type { IEmployeeBulkReassignVisitPayload } from "../../../interfaces/i-bulk-reassign-visit";
import { ApplicationEnum, ConflictTypeEnum } from "../../../enums/enums";
import { ToastrService } from "../../../services/toastr.service";
import { UserService } from "../../../services/user-service";
import type { IGetAllUsersResponse } from "./../../../interfaces/i-user";
import { TaskService, ITaskByUserResponse } from "../../../services/task.service";
import { BulkReassign } from "../../../models/bulk-reassign";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import {
    IEmployeeDetails,
    ITrackingReasonDetails,
    ITrackingTypeDetails
} from "../../../interfaces/i-home-care-reassign";

@autoinject
@customElement("employee-bulk-reassign")
export class EmployeeBulkReassign {
    public visitDetails: BulkReassign = new BulkReassign();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public application: ApplicationEnum;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public agencyId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public userId: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedTaskId: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public closeReassignForm: () => void;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public pageReset: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public dateFrom: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public dateTo: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameOf<EmployeeBulkReassign>("branchIdChanged") })
    public branchId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showAllByDefault: boolean = true;
    private _toastrService: ToastrService;
    private _userService: UserService;
    private _taskService: TaskService;
    public employeeList: IGetAllUsersResponse[] = [];
    public employeeLists: IEmployeeDetails[] = [];
    public isLoading: boolean = false;
    public isSaving: boolean = false;
    public dialogController: DialogController;
    public controller: ValidationController = null;
    public trackingTypes: ITrackingTypeDetails[] = [];
    public trackingReasons: ITrackingReasonDetails[] = [];
    public cpyTrackingReason: ITrackingReasonDetails[] = [];
    public selectedConflictTypes: number[] = [ConflictTypeEnum.NoConflict];
    public isEmployeeListLoading: boolean = false;
    public isApplicationHomeCare: boolean = false;
    @observable({ changeHandler: nameOf<EmployeeBulkReassign>("selectedEmployee") })
    public selectedEmployeeTo: ITypeaheadOptions;
    public employeeToValidation: IValidateCustomElement = {
        required: true,
        displayName: "Employee"
    };

    public constructor(
        validationController: ValidationController,
        toastrService: ToastrService,
        userService: UserService,
        dialogController: DialogController,
        tasksService: TaskService
    ) {
        this.controller = validationController;
        this._toastrService = toastrService;
        this._userService = userService;
        this.dialogController = dialogController;
        this._toastrService = toastrService;
        this._taskService = tasksService;
    }

    public async attached() {
        if (this.application == ApplicationEnum.HomeCare) {
            this.getHomeCareEmployee();
        } else {
            this.getEmployee("");
        }
    }

    public async bind() {
        if (this.application == ApplicationEnum.HomeCare) {
            this.isApplicationHomeCare = true;
        }

        if (this.isApplicationHomeCare) {
            this.trackingReasons = await this._taskService.getTrackingReasons({
                AgencyId: this.agencyId,
                Application: this.application,
                CategoryId: 1
            });

            this.trackingTypes = await this._taskService.getTrackingTypes({
                AgencyId: this.agencyId,
                Application: this.application,
                CategoryId: 1
            });
        }
    }

    public selectedType() {
        if (!!this.visitDetails.TypeId) {
            this.cpyTrackingReason = this.trackingReasons.filter((reasonType) => {
                return reasonType.TypeId == (this.visitDetails.TypeId as any);
            });
        } else {
            this.visitDetails.ReasonId = null;
            this.cpyTrackingReason = [];
        }
    }

    public branchIdChanged() {
        if (this.application == ApplicationEnum.HomeCare) {
            this.getHomeCareEmployee();
        }
    }

    public selectedEmployee(newValue: ITypeaheadOptions) {
        if (newValue) {
            this.visitDetails.EmployeeTo = newValue.value;
        }
    }

    public async getEmployee(term: string) {
        const employees = await this._userService.getAllUsers({
            agencyId: this.agencyId,
            application: this.application,
            userId: this.userId,
            branchIds: [],
            term: term
        });
        this.employeeList = employees.filter((employee) => {
            return employee.id != this.userId;
        });
    }

    public async getHomeCareEmployee() {
        try {
            this.isEmployeeListLoading = true;
            const employees = await this._taskService.homecareEmployeePreference({
                PatientId: this.userId,
                AgencyId: this.agencyId,
                SchedulerConflictTypes: this.selectedConflictTypes,
                Application: ApplicationEnum.HomeCare,
                LocationId: this.branchId,
                ignoreConflictTypes: true
            });
            this.employeeLists = employees.filter((employee) => {
                return employee.Id != this.userId;
            });
        } catch (e) {
            throw e;
        } finally {
            this.isEmployeeListLoading = false;
        }
    }

    public filterEmployee(filter: string) {
        if (filter) {
            if (this.application == ApplicationEnum.HomeCare) {
                const filterEmployeeList = this.employeeLists.filter((employee) => {
                    const fullName = `${employee.FirstName} ${employee.LastName}`;
                    return fullName.toLowerCase().includes(filter.toLowerCase());
                });

                return filterEmployeeList?.map((item) => {
                    return {
                        name: `${item.FirstName} ${item.LastName} ${item.Credentials}`,
                        value: item.Id
                    };
                });
            } else {
                this.getEmployee(filter);
                const filterEmployeeList = this.employeeList.filter((employee) => {
                    const fullName = `${employee.firstName} ${employee.lastName}`;
                    return fullName.toLowerCase().includes(filter.toLowerCase());
                });

                return filterEmployeeList?.map((item) => {
                    return {
                        name: `${item.firstName} ${item.lastName} ${item.credential}`,
                        value: item.id
                    };
                });
            }
        } else {
            if (this.application == ApplicationEnum.HomeCare) {
                return this.employeeLists?.map((item) => {
                    return {
                        name: `${item.FirstName} ${item.LastName} ${item.Credentials}`,
                        value: item.Id
                    };
                });
            } else {
                this.getEmployee("");
                return this.employeeList?.map((item) => {
                    return {
                        name: `${item.firstName} ${item.lastName} ${item.credential}`,
                        value: item.id
                    };
                });
            }
        }
    }

    public cancel() {
        this.closeReassignForm();
    }

    public async reassignVisit() {
        this.visitDetails.initValidations();
        const res = await this.controller.validate();
        if (!res.valid) {
            return;
        }
        if (!!this.visitDetails) {
            try {
                this.isSaving = true;
                // if(this.application == ApplicationEnum.AgencyCore) {
                //     this.selectedTaskId = []
                // }
                const reassignPayload: IEmployeeBulkReassignVisitPayload = {
                    AgencyId: this.agencyId,
                    Application: this.application,
                    EmployeeFrom: this.userId,
                    EmployeeTo: this.visitDetails.EmployeeTo,
                    StartDate: this.dateFrom,
                    EndDate: this.dateTo,
                    TaskIds: this.selectedTaskId,
                    ReasonId: this.visitDetails.ReasonId,
                    TypeId: this.visitDetails.TypeId
                };
                await this._taskService.employeeBulkReassign(reassignPayload);
                this._toastrService.success({
                    title: `Visit Reassigned`,
                    message: `Visit has been successfully Reassigned.`
                });
                this.cancel();
                this.pageReset();
            } catch (e) {
                this._toastrService.error({
                    title: `Visit Reassigning Failed`,
                    message: `Reassigning Visit Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isSaving = false;
            }
        }
    }
}
