import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import {
    IDeleteDocumentQueryParam,
    IGetHHReferralDocuments
} from "../../../../../resources-vue/vue-interfaces/i-get-referral";
import { CentralIntakeService } from "../../../../../services/central-intake.service";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import HHPatientDocuments from "./HHPatientDocuments.vue";

export default defineComponent({
    inject: ["dialogRef"],

    components: {
        FormSection,
        HHPatientDocuments
    },

    data() {
        return {
            dialogReference: this.dialogRef as any,
            referral: null,
            _centralIntakeService: null
        };
    },

    created() {
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this.referral = this.dialogReference.data.referral;
    },

    methods: {
        uploadDocument(document: FormData) {
            return this._centralIntakeService.uploadHHDocuments(document);
        },

        deleteDocument(documentId: string) {
            const deleteDocumentQuery: IDeleteDocumentQueryParam = {
                referralId: this.referral.id,
                application: this.referral.application,
                accountId: this.referral.accountId
            };
            return this._centralIntakeService.deleteDocument(deleteDocumentQuery, documentId);
        },

        getReferralDocuments() {
            const getHHReferralDocumentQuery: IGetHHReferralDocuments = {
                referralId: this.referral.id,
                application: this.referral.application,
                agencyId: this.referral.accountId
            };
            return this._centralIntakeService.getHHDocuments(getHHReferralDocumentQuery);
        },

        closeAddDocumentsDialog() {
            this.dialogReference.close();
        }
    }
});
