import { defineComponent } from "vue";
import { Moment } from "moment";

import { ITask } from "../../resources-vue/vue-interfaces/i-task";

import TaskList from "./components/VisitSchedule/TaskList.vue";
import PastDue from "./components/PastDue/PastDue.vue";
import TaskAlerts from "./components/TaskAlerts/TaskAlerts.vue";
import TasksMap from "./components/TasksMap/TasksMap.vue";
import EmployeeList from "../Common/EmployeeList/EmployeeList.vue";
import PatientList from "../Common/PatientList/PatientList.vue";
import PersonalMessageList from "../Personal/components/PersonalMessageList/PersonalMessageList.vue";

export default defineComponent({
    components: {
        TaskList,
        PastDue,
        TaskAlerts,
        TasksMap,
        PersonalMessageList,
        EmployeeList,
        PatientList
    },
    data() {
        return {
            myTasksDate: {} as Moment,
            taskList: [] as ITask[],
            selectedTask: {} as ITask,
            router: null,
            listUpdate: 0
        };
    },

    methods: {
        updateParentProps(updatedTaskList: ITask[], updatedMyTaskDate: Moment, updatedSelectedTask: ITask) {
            this.taskList = updatedTaskList;
            this.myTasksDate = updatedMyTaskDate;
            this.selectedTask = updatedSelectedTask;
        },

        updateTaskList(updatedTaskList: ITask[]) {
            this.taskList = updatedTaskList;
            this.listUpdate++;
        },
        selectedTaskChanged(task: ITask) {
            Object.assign(this.selectedTask, task);
        }
    }
});
