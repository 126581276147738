import { defineComponent } from "vue";

import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import CardTemplate from "../../../../../resources-vue/vue-elements/CardTemplate/CardTemplate.vue";

export default defineComponent({
    components: {
        ErrorMessageHandler,
        CardTemplate
    },
    props: {
        title: { type: String, default: undefined },
        isError: { type: Boolean, default: false },
        isAuthorized: { type: Boolean, default: true }
    }
});
