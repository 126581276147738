import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { AnalyticsCategory, AnalyticsService } from "../../../services/analytics.service";
import { GeolocationService } from "../../../services-vue/geolocation.service";
import eventBus from "../../../utilities/eventBus";

export enum GetZipEvents {
    Changed = "app:zip:changed",
    Canceled = "app:zip:canceled"
}

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            _analytics: null,
            _geolocationService: null,
            _isChange: false,
            dialogReference: this.dialogRef as any,
            zipError: false,
            options: {
                zip: ""
            },
            zip: ""
        };
    },

    created() {
        this._analytics = Container.instance.get(AnalyticsService);
        this._geolocationService = Container.instance.get(GeolocationService);
    },
    mounted() {
        this.options = this.dialogReference.data.zipOptions;
        this.zip = this.options.zip;
        this._isChange = !!this.options?.zip;
    },

    methods: {
        clearZip(): void {
            this.zip = "";
        },
        async isValidZip() {
            let newValue: string = this.zip.toString().trim();
            if (newValue) {
                if (newValue.length === 5 && /^\d+$/.test(newValue)) {
                    this.zipError = false;
                    const data = {
                        zipChanged: true,
                        zip: this.zip
                    };
                    eventBus.emit(GetZipEvents.Changed, this.zip);
                    await this._geolocationService.getCoordinatesByZip(this.zip);
                    this._analytics.logEvent({
                        category: AnalyticsCategory.GetZip,
                        action: "Submitted"
                    });
                    this.dialogReference.close("success");
                } else {
                    this.zip = "";
                    this.zipError = true;
                }
            }
        },
        zipNotEntered(): void {
            this.zipError = false;
            this._analytics.logEvent({
                category: AnalyticsCategory.GetZip,
                action: "Canceled"
            });
            eventBus.emit(GetZipEvents.Canceled, this._isChange);
            this.dialogReference.close();
        }
    }
});
