import { defineComponent } from "vue";
import { IPatientDetailsAxxessCare } from "../../../resources-vue/vue-interfaces/i-visit-details";

export default defineComponent({
    inject: ["dialogRef"],
    data() {
        return {
            dialogReference: this.dialogRef as any,
            addressDetails: {} as IPatientDetailsAxxessCare
        };
    },

    async mounted() {
        this.addressDetails = Object.assign({}, this.dialogReference.data.address);
    },

    methods: {
        isAddressInvalid() {
            const { AddressLine1, City, StateCode, ZipCode } = this.addressDetails || null;
            if (!AddressLine1 || !City || !StateCode || !ZipCode) {
                return true;
            } else {
                return false;
            }
        },

        saveAddress() {
            this.addressDetails.Message = "success";
            this.dialogReference.close(this.addressDetails);
        },

        cancel() {
            this.dialogReference.close("cancel");
        }
    }
});
