import { defineComponent } from "vue";
import moment from "moment";
import { Container } from "aurelia-dependency-injection";

import { EmployeeCalendarModel } from "./EmployeeCalendar.model";
import { IEmployeeCalendarRequestParams, UserService } from "../../../services/user-service";
import { type ITaskByUserRequest, type ITaskByUserResponse, TaskService } from "../../../services/task.service";
import type { IEmployeeCalendarFilterOptions } from "../../../resources-vue/vue-interfaces/i-calendar";
import { PAGE_FAIL_RESPONSE } from "../../../resources-vue/vue-elements/Pagination/Pagination";
import { IVisitMapRequestParams } from "../../../resources-vue/vue-interfaces/i-visit-map";
import { formatFullAddress } from "../../../common/vue-helpers/modifiers/value-modifier";

import EmployeeCalendarContent from "./components/EmployeeCalendarContent/EmployeeCalendarContent.vue";
import EmployeeCalendarFilter from "./components/EmployeeCalendarFilter/EmployeeCalendarFilter.vue";
import DailyCalendarFilter from "./components/DailyCalendarFilter/DailyCalendarFilter.vue";
import DailyCalendarContent from "./components/DailyCalendarContent/DailyCalendarContent.vue";
import DailyDateNavigation from "../components/DailyDateNavigation/DailyDateNavigation.vue";
import WeeklyDateNavigation from "../components/WeeklyDateNavigation/WeeklyDateNavigation.vue";
import EmployeeVisitMap from "./components/EmployeeVisitMap/EmployeeVisitMap.vue";
import EmployeeVisitMapFilters from "./components/EmployeeVisitMapFilters/EmployeeVisitMapFilters.vue";
import Pagination from "../../../resources-vue/vue-elements/Pagination/Pagination.vue";

export default defineComponent({
    components: {
        EmployeeCalendarContent,
        EmployeeCalendarFilter,
        DailyDateNavigation,
        WeeklyDateNavigation,
        DailyCalendarFilter,
        DailyCalendarContent,
        EmployeeVisitMap,
        EmployeeVisitMapFilters,
        Pagination
    },
    data() {
        return {
            isEmployeeSelected: false as boolean,
            startDate: null as Date,
            endDate: null as Date,
            todaysDate: new Date(Date.now()) as Date,
            showPatients: false as boolean,
            showEmployees: false as boolean,
            employeeFilterOptions: {
                pageLength: 15,
                page: 1,
                term: "",
                agencies: [],
                scheduleFilter: 0,
                startDate: "",
                endDate: ""
            } as IEmployeeCalendarRequestParams,
            taskFilterOptions: {
                start: null,
                end: null,
                userIds: [],
                agencyIds: []
                // weekdayRange: 1
            } as ITaskByUserRequest,
            pageNumber: 1,
            pageSize: 15,
            isError: false as boolean,
            employeeCalendarPagination: null,
            employees: [] as EmployeeCalendarModel[],
            isEmployeeLoading: false as boolean,
            _userService: null,
            _taskService: null,
            tasks: [] as ITaskByUserResponse[],
            tasksMap: null,
            listView: "Weekly" as string,
            showVisitMap: false as boolean,
            mapVisitsList: [],
            mapFilterOptions: {
                visitStatus: "",
                date: new Date(Date.now()).toISOString()
            } as IVisitMapRequestParams,
            employeesList: {} as any,
            fetchUpdatedCount: 0,
            visitMapDate: null
        };
    },

    computed: {
        hasData() {
            return this.employees?.length > 0;
        }
    },

    watch: {
        listView() {
            this.startDate = this.todaysDate;
            this.endDate = null;
        }
    },

    created() {
        this._userService = Container.instance.get(UserService);
        this._taskService = Container.instance.get(TaskService);
        this.employeeCalendarPagination = this.$refs?.employeeCalendarPagination as typeof Pagination;
    },

    mounted() {
        this.employeeCalendarPagination = this.$refs?.employeeCalendarPagination as typeof Pagination;
    },

    methods: {
        async employeeRefresh(options: IEmployeeCalendarFilterOptions, isWeekRangeChanged: boolean) {
            this.taskFilterOptions.agencyIds = [...options.agencies];
            this.employeeFilterOptions = {
                ...this.employeeFilterOptions,
                ...options
            };
            this.updateDateRange(options, isWeekRangeChanged);
        },

        isEmployeeSelectedChanged(newValue: boolean) {
            this.isEmployeeSelected = newValue;
        },

        async pageChanged(pageNumber: number, pageSize: number = 15) {
            try {
                if (this.employeeFilterOptions?.agencies?.length > 0) {
                    this.isError = false;
                    this.employeeFilterOptions.page = pageNumber;
                    this.isEmployeeLoading = true;
                    let data = await this._userService.getUsersForCalendar(this.employeeFilterOptions);
                    this.isEmployeeLoading = false;
                    if (data) {
                        this.taskFilterOptions.userIds = [];
                        let dataItems = data.Items ?? [];
                        this.employees = dataItems.map((item: any) => new EmployeeCalendarModel(item));
                        this.employees?.forEach((user) => {
                            user.Agencies.forEach((op) => {
                                this.taskFilterOptions.userIds.push(op.UserId);
                            });
                        });
                        await this.taskRefresh();
                        return {
                            totalPageCount: data.PageCount,
                            success: data.ItemCount > 0,
                            totalCount: data.ItemCount
                        };
                    } else {
                        return PAGE_FAIL_RESPONSE;
                    }
                } else {
                    this.employees = [];
                    this.taskFilterOptions.userIds = [];
                    return PAGE_FAIL_RESPONSE;
                }
            } catch (e) {
                console.error(e);
                this.isError = true;
                return PAGE_FAIL_RESPONSE;
            }
        },

        async updateDateRange(options: any, isWeekRangeChanged: boolean = true) {
            if (isWeekRangeChanged) {
                this.startDate = options.startDate;
                this.endDate = options.endDate;
            }
            this.employeeFilterOptions.startDate = this.taskFilterOptions.start = this.formatDate(this.startDate);
            this.employeeFilterOptions.endDate = this.taskFilterOptions.end = this.formatDate(this.endDate);
            await this.pageReset();
        },

        formatDate(input: Date) {
            return `${input.getMonth() + 1}/${input.getDate()}/${input.getFullYear()}`;
        },

        async taskRefresh() {
            try {
                if (this.taskFilterOptions?.userIds?.length > 0) {
                    const tasks = await this._taskService.getTasksByUsers(this.taskFilterOptions);
                    tasks.sort((a: any, b: any) => {
                        let aCriteria: "TimeIn" | "StartTime";
                        let bCriteria: "TimeIn" | "StartTime";

                        if (a.State === 4) {
                            aCriteria = "TimeIn";
                        } else {
                            aCriteria = "StartTime";
                        }

                        if (b.State === 4) {
                            bCriteria = "TimeIn";
                        } else {
                            bCriteria = "StartTime";
                        }

                        return moment(a[aCriteria]).isAfter(moment(b[bCriteria]))
                            ? 1
                            : moment(a[aCriteria]).isSame(moment(b[bCriteria]))
                            ? 0
                            : -1;
                    });
                    this.tasks = tasks;
                }
            } catch (e) {
                console.error(e);
            }
        },

        async pageReset() {
            this.isError = false;
            await this.employeeCalendarPagination?.reset();
        },

        updatePageSizeChanged(value: number) {
            this.pageSize = value;
        },

        handleVisitMapClick(view?: string) {
            this.startDate = this.todaysDate;
            this.endDate = null;
            this.listView = view;
            this.showVisitMap = !this.showVisitMap;
        },

        async mapRefresh(options?: any) {
            if (options) {
                this.mapFilterOptions = options;
                this.visitMapDate = options.date;
            }
            this.mapVisitsList = await this._taskService.getCalendarMapVisits(this.mapFilterOptions);
            this.employeesList = {};
            for (let visit of this.mapVisitsList) {
                let name: any = visit.EmployeeName?.FirstName + " " + visit.EmployeeName?.LastName;
                name = name != " " ? name : "Unassigned";
                if (!this.employeesList[name]) {
                    this.employeesList[name] = visit.Id;
                }
            }
            this.fetchUpdatedCount++;
        }
    }
});
