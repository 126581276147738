import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { ApplicationEnum } from "../../../enums/enums";
import { Template } from "../../../models/template";
import { TemplateService } from "../../../services/template-service";

import NewTypeahead from "../NewTypeahead/NewTypeahead.vue";

export default defineComponent({
    components: {
        NewTypeahead
    },
    props: {
        classList: { type: Object, default: { input: "w-75", textarea: "" } },
        showTemplateTypeahead: { type: Boolean, default: true },
        showTemplateDropdown: { type: Boolean, default: false },
        inputName: { type: String, default: "comments" },
        result: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        disabled: { type: Boolean, default: false },
        maxLength: { type: Number, default: 65535 },
        application: { typeof: ApplicationEnum, default: null },
        commentRequired: { type: Boolean, default: false }
    },
    data() {
        return {
            selectedTemplate: null as ITypeaheadOptions,
            templates: [] as Template[],
            maxCharLimit: 65535,
            _templateService: null
        };
    },
    created() {
        this._templateService = Container.instance.get(TemplateService);
    },
    mounted() {
        this.getTemplateList("", 10);
    },
    watch: {
        async selectedTemplate(newValue, oldValue) {
            let selectedTemplate = newValue;
            if (selectedTemplate?.id) {
                let template = this.templates.find((item) => item.id == selectedTemplate.id);
                if (this.result?.length > 0) {
                    this.$emit("resultUpdatedAppended", `\n\n${template.description}`);
                } else {
                    this.$emit("resultUpdated", template.description);
                }
            }
        }
    },
    methods: {
        async getTemplateList(term: string, limit: number = 10) {
            try {
                let templates = await this._templateService.getTemplateByPage({
                    term: term,
                    page: 1,
                    pageLength: limit,
                    application: this.application
                });
                this.templates = templates.items;
                return this.templates;
            } catch (e) {
                throw e;
            }
        },

        changeselectedTemplate(value: any) {
            this.selectedTemplate = value;
        },

        updateResult(value: string) {
            this.$emit("resultUpdated", value);
        }
    }
});
