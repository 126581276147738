import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "text-default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.text && !_ctx.isError)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1 /* TEXT */))
    : (_openBlock(), _createElementBlock("small", _hoisted_2, [
        _createElementVNode("i", _hoisted_3, _toDisplayString(_ctx.exceptionMessage), 1 /* TEXT */)
      ]))
}