<template>
    <section class="p-3">
        <FormSection
            :full-height="false"
            :title="visitDetails.TaskName"
            :application-id="visitDetails.Application"
            :show-product-pill="true"
        >
            <template #content>
                <template v-if="isLoading">
                    <div
                        class="p-5 d-flex flex-column align-items-center justify-content-center text-center text-primary"
                    >
                        <i class="fa fa-spinner fa-spin font-size-5xl mr-2 animated slow flash infinite"></i>
                        <strong class="mt-1">
                            <b>Loading...</b>
                        </strong>
                    </div>
                </template>
                <template class="dialog" v-else>
                    <div class="container row m-0 p-1">
                        <div class="col-sm-12 col-md-6 mt-2">
                            <div class="form-group">
                                <label for="client-name"> Client </label>
                                <input
                                    id="client-name"
                                    type="text"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.patientName"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label for="mrn"> MRN </label>
                                <input
                                    id="mrn"
                                    type="text"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.patientIdNumber"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label for="task-name"> Task: </label>
                                <select
                                    id="task-name"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.customNoteId"
                                >
                                    <option v-for="task of taskDetails.disciplineTasks" :value="task.value">
                                        {{ task.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="travel-start-time"> Scheduled Start Time: </label>
                                <div class="d-flex gx-1">
                                    <DatePicker class="flex-fill" :getDate="handleDateChange">
                                        <div class="input-group date datepickerInput">
                                            <input
                                                name="eventStartTime"
                                                id="schedule-date"
                                                type="text"
                                                class="form-control"
                                                :value="formatDateInput(taskDetails.scheduleEvent.eventStartTime)"
                                            />
                                            <div class="input-group-addon input-group-append">
                                                <div class="input-group-text">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </DatePicker>
                                    <TimePicker
                                        @update-props="changeIsAllDay"
                                        class="flex-fill ml-1"
                                        v-if="!taskDetails.scheduleEvent.isAllDay"
                                    >
                                        <input
                                            id="travel-start-time"
                                            type="text"
                                            class="form-control"
                                            :value="scheduledStartTime"
                                            autocomplete="off"
                                            :maxlength="8"
                                        />
                                    </TimePicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="travel-end-time"> Scheduled End Time: </label>
                                <div class="d-flex gx-1">
                                    <DatePicker class="flex-fill" :getDate="handleDateChange">
                                        <div class="input-group date datepickerInput">
                                            <input
                                                name="eventEndTime"
                                                id="schedule-date"
                                                type="text"
                                                class="form-control"
                                                :value="formatDateInput(taskDetails.scheduleEvent.eventEndTime)"
                                            />
                                            <div class="input-group-addon input-group-append">
                                                <div class="input-group-text">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </DatePicker>
                                    <TimePicker
                                        @update-props="changeScheduleEndTime"
                                        class="flex-fill ml-1"
                                        v-if="!taskDetails.scheduleEvent.isAllDay"
                                    >
                                        <input
                                            id="travel-end-time"
                                            type="text"
                                            class="form-control"
                                            :value="scheduledEndTime"
                                            autocomplete="off"
                                            :maxlength="8"
                                        />
                                    </TimePicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="actual-visit-date"> Actual Visit Date/Time In: </label>
                                <div class="d-flex gx-1">
                                    <DatePicker class="flex-fill" :getDate="handleDateChange">
                                        <div class="input-group date datepickerInput">
                                            <input
                                                name="visitStartTime"
                                                id="actual-visit-date"
                                                type="text"
                                                class="form-control"
                                                :value="formatDateInput(taskDetails.scheduleEvent.visitStartTime)"
                                            />
                                            <div class="input-group-addon input-group-append">
                                                <div class="input-group-text">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </DatePicker>
                                    <TimePicker
                                        @update-props="changeVisitTimeIn"
                                        class="flex-fill ml-1"
                                        v-if="!taskDetails.scheduleEvent.isAllDay"
                                    >
                                        <input
                                            id="travel-end-time"
                                            type="text"
                                            class="form-control timepicker"
                                            :value="visitTimeIn"
                                            autocomplete="off"
                                            :maxlength="8"
                                        />
                                    </TimePicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="visit-start-time"> Actual Visit Date/Time Out: </label>
                                <div class="d-flex gx-1">
                                    <DatePicker class="flex-fill" :getDate="handleDateChange">
                                        <div class="input-group date datepickerInput">
                                            <input
                                                name="visitEndTime"
                                                id="actual-visit-date"
                                                type="text"
                                                class="form-control"
                                                :value="formatDateInput(taskDetails.scheduleEvent.visitEndTime)"
                                            />
                                            <div class="input-group-addon input-group-append">
                                                <div class="input-group-text">
                                                    <i class="fa fa-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </DatePicker>
                                    <TimePicker
                                        @update-props="changeVisitTimeOut"
                                        class="flex-fill ml-1"
                                        v-if="!taskDetails.scheduleEvent.isAllDay"
                                    >
                                        <input
                                            id="visit-start-time"
                                            type="text"
                                            class="form-control"
                                            :value="visitTimeOut"
                                            autocomplete="off"
                                            :maxlength="8"
                                        />
                                    </TimePicker>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-group custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="schedule-without-time"
                                        class="custom-control-input"
                                        v-model="taskDetails.scheduleEvent.isAllDay"
                                    />
                                    <label class="custom-control-label" for="schedule-without-time">
                                        Schedule Without Time
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-group custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="billable"
                                        class="custom-control-input"
                                        v-model="taskDetails.scheduleEvent.isBillable"
                                    />
                                    <label class="custom-control-label" for="billable"> Billable </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 mt-2">
                            <div class="form-group">
                                <label for="client-name"> Care Period </label>
                                <input
                                    id="client-name"
                                    type="text"
                                    class="form-control"
                                    :value="getCarePeriod"
                                    disabled
                                />
                            </div>
                            <div class="form-group">
                                <label for="status"> Status: </label>
                                <select
                                    class="form-control"
                                    id="status"
                                    v-model="taskDetails.scheduleEvent.scheduleStatus"
                                >
                                    <option v-for="status of taskDetails.statuses" :value="status.value">
                                        {{ status.text }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group" v-if="!showAssignedToList">
                                <label for="assigned-to"> Assigned To: </label>
                                <input
                                    type="text"
                                    id="assigned-to"
                                    class="form-control"
                                    v-model="assignedToName"
                                    disabled
                                />
                            </div>
                            <div class="form-group" v-else>
                                <label for="assigned-to-list"> Assigned To: </label>
                                <select
                                    id="assigned-to-list"
                                    class="form-control"
                                    v-model="taskDetails.scheduleEvent.userId"
                                    @change="selectedEmployee(taskDetails.scheduleEvent.userId)"
                                >
                                    <option :value="null">Select User</option>
                                    <option v-for="employee of employeeList" :value="employee.Id">
                                        {{ employee.FirstName }} {{ employee.LastName }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="surcharge"> Surcharge: </label>
                                <input
                                    id="surcharge"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Amount"
                                    v-number-only
                                    :maxlength="7"
                                    v-model="taskDetails.scheduleEvent.surcharge"
                                />
                            </div>
                            <div class="form-group">
                                <label for="associated-mileage"> Associated Mileage: </label>
                                <input
                                    id="associated-mileage"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter Amount"
                                    v-number-only
                                    :maxlength="7"
                                    v-model="taskDetails.scheduleEvent.associatedMileage"
                                />
                            </div>
                            <div class="form-group">
                                <label for="travel-time"> Travel Time (mins): </label>
                                <input
                                    id="travel-time"
                                    type="text"
                                    class="form-control"
                                    placeholder="Enter time"
                                    v-number-only
                                    :maxlength="7"
                                    v-model="taskDetails.scheduleEvent.travelTime"
                                />
                            </div>
                            <div class="form-group" v-if="showPhyicianField">
                                <label for="physician-name"> Physician </label>
                                <TypeaheadInput
                                    id="physician-name"
                                    placeholder="Start Typing"
                                    :getData="getPhysicianList"
                                    :value="physician"
                                    v-on:valueChanged="physicianChanged"
                                />
                            </div>
                            <div class="form-group" v-if="isReturnedWithSignature">
                                <label for="received-date"> Received Date: </label>
                                <DatePicker>
                                    <div class="input-group date datepickerInput">
                                        <input
                                            id="received-date"
                                            type="text"
                                            class="form-control"
                                            :value="formatDateInput(taskDetails.scheduleEvent.orderReceivedDate)"
                                        />
                                        <div class="input-group-addon input-group-append">
                                            <div class="input-group-text">
                                                <i class="fa fa-calendar"></i>
                                            </div>
                                        </div>
                                    </div>
                                </DatePicker>
                            </div>
                            <div class="form-group">
                                <label for="payer-list"> Payer: </label>
                                <select
                                    id="payer-list"
                                    class="form-control"
                                    :value="taskDetails.scheduleEvent.payorId"
                                    @change="selectedPayor(taskDetails.scheduleEvent.payorId)"
                                >
                                    <option :value="null">-- Select Payment Source --</option>
                                    <option v-for="payor of payorList" :value="payor.insuranceId">
                                        {{ payor.name }} ({{ payor.typeName }})
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <h6 class="mt-2 d-flex align-items-center">
                                <b class="mr-2">Gross Profit Forecast</b>
                            </h6>
                            <div class="row mt-2">
                                <div class="form-group col-4">
                                    <label for="billing-amount"> Billing Amount: </label>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text rate-icon">$</span>
                                        </div>
                                        <input
                                            id="billing-amount"
                                            type="text"
                                            class="form-control"
                                            maxlength="7"
                                            v-model="taskDetails.scheduleEvent.billingAmount"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="form-group col-4">
                                    <label for="payroll-amount"> Payroll Amount: </label>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text rate-icon">$</span>
                                        </div>
                                        <input
                                            id="payroll-amount"
                                            type="text"
                                            class="form-control"
                                            maxlength="7"
                                            v-model="taskDetails.scheduleEvent.payrollAmount"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <h6 class="mt-2 d-flex align-items-center">
                                        <b class="mr-2">Gross Profit Forecast</b>
                                    </h6>
                                    <div class="row mt-2">
                                        <div class="form-group col-4">
                                            <label for="billing-amount"> Billing Amount: </label>
                                            <div class="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text rate-icon">$</span>
                                                </div>
                                                <input
                                                    id="billing-amount"
                                                    type="text"
                                                    class="form-control"
                                                    :maxlength="7"
                                                    v-model="taskDetails.scheduleEvent.billingAmount"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group col-4">
                                            <label for="payroll-amount"> Payroll Amount: </label>
                                            <div class="input-group input-group-sm">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text rate-icon">$</span>
                                                </div>
                                                <input
                                                    id="payroll-amount"
                                                    type="text"
                                                    class="form-control"
                                                    :maxlength="7"
                                                    v-model="taskDetails.scheduleEvent.payrollAmount"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group col-4">
                                            <label for="gross-profit"> Gross Profit Percentage: </label>
                                            <div class="input-group input-group-sm">
                                                <input
                                                    id="gross-profit"
                                                    type="text"
                                                    class="form-control"
                                                    :maxlength="7"
                                                    v-model="taskDetails.scheduleEvent.grossProfit"
                                                    disabled
                                                />

                                                <div class="input-group-append">
                                                    <span class="input-group-text px-2">
                                                        <small><i class="fas fa-percent text-secondary"></i></small>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="form-group col-12"
                                    v-if="taskDetails && taskDetails.scheduleEvent.taskShifts.length > 0"
                                >
                                    <h6 class="mt-2 d-flex align-items-center">
                                        <b class="mr-2">Midnight Split</b>
                                    </h6>
                                    <MidnightSplitTable :task-shifts="taskDetails.scheduleEvent.taskShifts" />
                                </div>
                                <div class="form-group col-12">
                                    <h6 class="mt-2 d-flex align-items-center">
                                        <b class="mr-2">Payroll Details</b>
                                    </h6>
                                    <PayrollDetailsTable
                                        :has-payroll-details="hasPayrollDetails"
                                        :payroll-details="payrollDetails"
                                        :get-payroll-details="getPayrollDetails"
                                        :payroll-details-loading="payrollDetailsLoading"
                                    />
                                </div>
                                <section class="d-flex flex-column col-12 px-3 py-2">
                                    <div class="form-group">
                                        <label for="comments">
                                            Comments <i class="ml-2 fas fa-sticky-note yellow-note"></i> (Yellow Sticky
                                            Note)
                                        </label>
                                        <CommentInput
                                            id="comments"
                                            input-name="referral-comments"
                                            placeholder="Enter Comments"
                                            :class-list="commentInputClass"
                                            :application="visitDetails.Application"
                                            :show-template-typeahead="false"
                                            :show-template-dropdown="false"
                                            :result="taskDetails.scheduleEvent.comments"
                                            v-on:resultUpdatedAppended="resultUpdatedAppended"
                                            v-on:resultUpdated="resultUpdated"
                                        />
                                    </div>
                                </section>
                                <section class="d-flex flex-column col-12 px-3 py-2" slot="content">
                                    <div class="form-group">
                                        <label for="return-reason">
                                            Return Reason <i class="ml-2 fas fa-sticky-note red-note"></i> (Orange
                                            Sticky Note)
                                        </label>
                                        <div
                                            v-if="returnReasons.length > 0"
                                            :class="`${
                                                isDeleteRequested
                                                    ? 'p-2 mb-2 bg-white rounded border border-secondary edit-row'
                                                    : 'p-2 mb-2 bg-white rounded border border-secondary'
                                            }`"
                                            v-for="reasonCode of returnReasons"
                                        >
                                            <div class="d-flex justify-content-between">
                                                <div class="font-weight-bold">
                                                    {{ reasonCode.user }} &nbsp;
                                                    <sup
                                                        ><i>{{ formatDate(reasonCode.date) }}</i></sup
                                                    >
                                                </div>
                                            </div>
                                            <div>{{ reasonCode.reason }}</div>
                                        </div>
                                        <div class="p-2 mb-2 bg-white rounded border border-secondary" else>
                                            <div class="text-center font-italic">No Return Reason Found</div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div class="d-flex justify-content-center mb-3" v-if="!isLoading">
                                <span v-if="isSaving">
                                    <button class="btn btn-primary">
                                        <i class="fa fa-spinner fa-spin animated slow flash infinite"></i>
                                    </button>
                                </span>
                                <span v-else>
                                    <button class="btn btn-primary" @click="updateVisitDetails()">Save</button>
                                    <button
                                        type="button"
                                        class="ml-1 btn btn-md btn-outline-secondary"
                                        @click="cancel()"
                                    >
                                        Cancel
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </FormSection>
    </section>
</template>

<script src="./VisitDetailsHomeCare.ts"></script>
