import { defineComponent } from "vue";

export interface IRescheduleValidations {
    title: string;
    description: string[];
    taskIssueSurpressKey: string;
}

export default defineComponent({
    inject: ["dialogRef"],

    data() {
        return {
            name: "" as string,
            enableContinue: false as boolean,
            collapsionList: [] as boolean[],
            hasError: false as boolean,
            isScheduleVisitComponent: false as boolean,
            dialogReference1: this.dialogRef as any,
            dialogData: null as any,
            validations: [] as IRescheduleValidations[],
            validationsAccepted: [] as string[]
        };
    },

    mounted() {
        this.dialogData = this.dialogReference1.data;
        if (this.dialogData.component == "Schedule-visit") {
            this.isScheduleVisitComponent = true;
        }
        if (this.dialogData?.validations) {
            this.validations = this.dialogData.validations;
            this.name = this.dialogData.name;
            this.hasError = this.dialogData.hasError;
        } else {
            this.dialogReference1.close("cancel");
        }
    },

    methods: {
        validationSelected() {
            this.enableContinue = this.validationsAccepted.length === this.validations.length;
        },

        ok() {
            this.dialogReference1.close();
        },

        cancel() {
            this.dialogReference1.close("cancel");
        }
    }
});
