export class AddPhysician {
    public Id: string;
    public FirstName: string = "";
    public LastName: string = "";
    public MiddleName: string = "";
    public NPI: string = "";
    public AddressLine1: string = "";
    public AddressLine2: string = "";
    public AddressCity: string = "";
    public AddressStateCode: string = "";
    public AddressZipCode: string = "";
    public AddressZipCodeFour: string = "";
    public FaxNumber: string = "";
    public EmailAddress: string = "";
    public PhoneWork: string = "";
    public Credentials: string = "";
    public DisplayName: string = "";
    public FaxNumberFormatted: string = "";
    public Primary: boolean = false;

    public constructor(data?: AddPhysician) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
