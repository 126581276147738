// @ts-ignore
import { JitsiMeeting } from "@jitsi/vue-sdk";
import { defineComponent, ref, computed, onMounted, inject, watch } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useRoute } from 'vue-router';

import {
    IConversationUser,
    Participant,
    Room,
    Rooms,
    IncomingMessage,
    OutgoingMessage,
} from "../../resources-vue/vue-interfaces/messaging-service/i-message";
import eventBus from "../../utilities/eventBus";
import { capitalizeFirstLetter } from "../../utilities/capitalizeFirstLetter";
import moment from "moment";
import { ToastrService } from "../../services/toastr.service";
import { NotificationsHubEvents } from "../../enums/NotificationsHubEvents";
import { UserManager } from "oidc-client";

export enum MettingTypeEnum {
    Audio = "Audio",
    Video = "Video"
}

export default defineComponent({
    props: {
        users: {
            type: Array as () => IConversationUser[],
            default: []
        }
    },
    components: {
        JitsiMeeting
    },

    setup(props) {
        const dialogRef = inject("dialogRef") as any;
        const meetingTimer = ref("");
        const domain = ref("vcppoc1-service.test.axxess.tech");
        const dialogReference = ref(dialogRef);
        const apiRef = ref<any>(null);
        const participants = ref<Participant[]>([]);
        const iframeRef = ref();
        const participantsPaneOpen = ref<boolean>(false);
        const otherParticipantsOpen = ref<boolean>(false);
        const toastrService = ref<ToastrService | null>(null)
        const userManager = ref<UserManager | null>(null)
        const cachedConversationId = ref<string>("")
        let timerInterval: NodeJS.Timeout;

        const route = useRoute();

        const configOverwrite = computed(() => {
            const isVideoMeeting = dialogReference.value.data.meetingType === MettingTypeEnum.Video;
            return {
                startWithAudioMuted: false,
                startWithVideoMuted: isVideoMeeting ? false : true,
                breakoutRooms: {
                    hideAddRoomButton: true
                },
                prejoinConfig: {
                    enabled: false
                }
            };
        });

        const timer = computed(() => meetingTimer.value);
        const roomInfo = computed(() => dialogReference.value.data.roomInfo);
        const jwt = computed(() => dialogReference.value.data.roomInfo?.token);
        const meetingParticipantsExceptAuthUser = computed(() => dialogReference.value.data.users);
        const loading = computed(() => dialogReference.value.data.loading);

        const invitedParticipants = computed(() => {
            const meetingParticipants = meetingParticipantsExceptAuthUser.value.map(
                (participant: IConversationUser) => ({
                    role: "",
                    displayName: `${capitalizeFirstLetter(`${participant.firstName} ${participant.lastName}`)}`,
                    avatarUrl: "",
                    id: ""
                })
            );
            const joinedParticipants = participants.value;

            return meetingParticipants.filter((participant: Participant) => {
                return !joinedParticipants.some(
                    (joinedParticipant) =>
                        joinedParticipant.displayName.toLowerCase() === participant.displayName.toLowerCase()
                );
            });
        });

        const jitsiOptions = computed(() => ({
            startAudioOnly: false,
            configOverwrite: configOverwrite.value,
            interfaceConfigOverwrite: {
                TOOLBAR_BUTTONS: [
                    "microphone",
                    "camera",
                    "desktop",
                    "tileview",
                    "fullscreen",
                    "raisehand",
                    "settings",
                    "hangup",
                    "participants-pane",
                    "chat"
                ]
            },
            userInfo: {
                displayName: "John Doe",
                email: "john.doe@example.com"
            },
            parentNode: document.querySelector(".jitsi-video")
        }));

        const handleJitsiMeetJSLoaded = () => {
            console.log("Jitsi Meet JS loaded");
        };

        const handleConferenceJoined = () => {
            updateParticipants();
        };

        const handleConferenceLeft = () => {
            dialogReference.value.close();
        };

        const handleError = (error: any) => {
            console.error("Jitsi Meet error:", error);
        };

        const getPariticipantsPaneStatus = ({ open }: { open: boolean }) => {
            participantsPaneOpen.value = open;
            if (open) {
                otherParticipantsOpen.value = true;
            } else {
                otherParticipantsOpen.value = false;
            }
        };

        
        const handleIncomingMessage = (event: IncomingMessage) => {
            const { message } = event
            const conversationId = route.params.id as string

            const notification = {
                senderId: "",
                conversationId:conversationId,
                messageId: "",
                createdAt: new Date().toISOString(),
                messageBody: message,
                messageAssets: [] as string[],
                senderName: event.nick
            }

            eventBus.emit("jitsiMessage", notification)
        }

        const sendMessage = async (message: string) => {
            const conversationId = route.params.id as string

            const chatWarning = {
                title: `Chat update`,
                message: "An axxess teams conversation needs to be opened to be able to sync message with axxess teams chat",
                timeout: 10000
            };

            if (!conversationId) {
                toastrService.value.warning(chatWarning);
            }
            
            if(cachedConversationId.value === conversationId) {
                eventBus.emit("newMessage", message)
            }else {
                const user = await userManager.value.getUser();
                const senderFullName = `${user.profile.given_name} ${user.profile.family_name}`
                
                const notification = {
                    senderId: user.profile.sub,
                    conversationId: cachedConversationId.value,
                    messageId: "",
                    createdAt: new Date().toISOString(),
                    messageBody: message,
                    messageAssets: [] as string[],
                    senderName: senderFullName,
                    clicked: false
                }
                eventBus.emit(NotificationsHubEvents.LoadConversationList, notification);
            }
        };


        const handleOutgoingMessage = async (event: OutgoingMessage) => {
            const { message } = event
            await sendMessage(message)
        }

        const handleApiReady = (externalApi: any) => {
            apiRef.value = externalApi;
            apiRef.value.addEventListener("participantJoined", updateParticipants);
            apiRef.value.addEventListener("participantLeft", updateParticipants);
            apiRef.value.addEventListener("videoConferenceJoined", () => {
                updateParticipants();
                startTimer();
            });
            apiRef.value.addEventListener("videoConferenceLeft", () => {
                clearInterval(timerInterval);
                updateParticipants();
            });
            apiRef.value.addEventListener("participantsPaneToggled", getPariticipantsPaneStatus);

            const iframe = apiRef.value.getIFrame();
            iframeRef.value = iframe;

            apiRef.value.addEventListener('incomingMessage', handleIncomingMessage);
            apiRef.value.addEventListener('outgoingMessage', handleOutgoingMessage)
        };

        const startTimer = () => {
            let startTime = moment();
            timerInterval = setInterval(() => {
                let currentTime = moment();
                let duration = moment.duration(currentTime.diff(startTime));
                let hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
                let minutes = Math.floor(duration.asMinutes() % 60)
                    .toString()
                    .padStart(2, "0");
                let seconds = Math.floor(duration.asSeconds() % 60)
                    .toString()
                    .padStart(2, "0");
                meetingTimer.value = Number(hours) ? hours + ":" + minutes + ":" + seconds : minutes + ":" + seconds;
            }, 1000);
        };

        const updateParticipants = () => {
            if (apiRef.value) {
                apiRef.value
                    .getRoomsInfo()
                    .then((roomsInfo: Rooms) => {
                        if (roomsInfo.rooms[0]?.id) {
                            const currentRoom = roomsInfo.rooms.find(
                                (room: Room) => room.id.split("@")[0] === roomInfo.value.roomName
                            );
                            if (currentRoom) {
                                participants.value = currentRoom.participants;
                            }
                        }
                    })
                    .catch((error: unknown) => {
                        console.error("Error fetching room info:", error);
                    });
            }
        };

        const toggleOtherParticipants = () => {
            otherParticipantsOpen.value = !otherParticipantsOpen.value;
        };

        const cancel = () => {
            eventBus.emit("dialogOpened", false);
            dialogReference.value.close();
            apiRef.value.executeCommand("hangup");
        };

        watch(
            () => roomInfo,
            (newRoomInfo) => {
                if (newRoomInfo) {
                    updateParticipants();
                }
            }
        );

        onMounted(() => {
            cachedConversationId.value = route.params.id as string
            toastrService.value = Container.instance.get(ToastrService);
            userManager.value = Container.instance.get(UserManager);
        });

        return {
            domain,
            loading,
            configOverwrite,
            jwt,
            handleJitsiMeetJSLoaded,
            handleConferenceJoined,
            handleConferenceLeft,
            handleError,
            handleApiReady,
            toggleOtherParticipants,
            cancel,
            jitsiOptions,
            participants,
            invitedParticipants,
            participantsPaneOpen,
            otherParticipantsOpen,
            roomInfo,
            timer
        };
    }
});
