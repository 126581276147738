import "./pagination.scss";

import { bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";

import nameOf from "../../common/name-of";
import type { IPageCallbackResult, IPageChangedEvent } from "../../resources-vue/vue-interfaces/i-page-container";

export const PAGE_FAIL_RESPONSE: IPageCallbackResult = {
    totalPageCount: 0,
    success: false,
    totalCount: 0
};

export enum PageContainerStyle {
    simple = "simple" as any,
    ellipsis = "ellipsis" as any,
    input = "input" as any
}

@customElement("pagination")
export class Pagination {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public numberOfPagesToShow: number = 9;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameOf<Pagination>("pageSizeChanged") })
    public pageSize: number = 10;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public pageChanged: (event: IPageChangedEvent) => IPageCallbackResult;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showPageSizeOptions: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageSizeOptions: number[] = [10, 25];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public entity: string = "results";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public classes: string = "";
    public currentPage: number = 1;
    public totalPageCount: number = 0;
    public totalDataCount: number = 0;
    public noData: boolean = false;
    public currentNavPages: number[] = [];
    public lastPageNumberLoaded: number = -1;
    public from: number;
    public to: number;

    @computedFrom(nameOf<Pagination>("currentPage"))
    public get disablePrevious(): boolean {
        return this.currentPage === 1;
    }

    @computedFrom(nameOf<Pagination>("currentPage"), nameOf<Pagination>("totalPageCount"))
    public get disableNext(): boolean {
        return this.currentPage === this.totalPageCount;
    }

    public async attached() {
        await this.changePage();
    }

    public async changePage(pageNum: number = -1): Promise<void> {
        if (pageNum !== -1) {
            this.currentPage = pageNum;
        }
        this.isLoading = false;
        await this.getData();
    }

    private async getData(): Promise<void> {
        if (!this.isLoading /* && this.lastPageNumberLoaded !== this.currentPage */ && this.pageChanged) {
            this.isLoading = true;
            let data = await this.pageChanged({
                pageNumber: this.currentPage,
                pageSize: this.pageSize
            });
            /* if (data.success) {
                this.lastPageNumberLoaded = this.currentPage;
            } */
            this.isLoading = false;
            this.noData = !data.success || data.totalPageCount == 0;
            this.totalPageCount = data.totalPageCount;
            this.totalDataCount = data.totalCount;
            this.generateNavPages();
            this.updateRange();
        }
    }

    private generateNavPages() {
        let total = this.totalPageCount;
        let show = this.numberOfPagesToShow;
        let current = this.currentPage;
        let navPages = [];
        let startPage = current - Math.floor(show / 2) <= 1 ? 1 : current - Math.floor(show / 2);
        let endPage = current + Math.floor(show / 2) >= total ? total : current + Math.floor(show / 2);

        if (total <= show) {
            for (let i = 1; i <= total; i++) {
                navPages.push(i);
            }
            this.currentNavPages = navPages;
            return;
        }

        if (startPage === 1) {
            for (let i = 1; i <= show; i++) {
                navPages.push(i);
            }
        } else if (endPage === total) {
            for (let i = 0; i < show; i++) {
                navPages.unshift(total - i);
            }
        } else {
            for (let i = startPage; i <= endPage; i++) {
                navPages.push(i);
            }
        }
        this.currentNavPages = navPages;
    }

    private updateRange() {
        this.from = (this.currentPage - 1) * Number(this.pageSize) + 1;
        let to = this.from + Number(this.pageSize) - 1;

        if (to > this.totalDataCount) {
            to = this.totalDataCount;
        }

        this.to = to;
    }

    public async incrementPageNumber(): Promise<void> {
        if (this.currentPage !== this.totalPageCount) {
            this.currentPage++;
            await this.changePage();
        }
    }

    public async decrementPageNumber(): Promise<void> {
        if (this.currentPage > 1) {
            this.currentPage--;
            await this.changePage();
        }
    }

    public async pageSizeChanged(newValue: number, oldValue: number) {
        if (newValue && oldValue && newValue.toString() !== oldValue.toString()) {
            await this.reset();
        }
    }

    public async reset() {
        await this.changePage(1);
    }
}
