import type { IRoles } from "../resources-vue/vue-interfaces/i-roles";

export interface IAppAccessRoles {
    canUseAxxessCare: boolean;
    canUseSurvey: boolean;
}
export class AccessService {
    private _roles: IAppAccessRoles = {
        canUseAxxessCare: false,
        canUseSurvey: false
    };
    public areRolesLoaded: boolean = false;

    public initAccessRoles(roles: IRoles[]) {
        this.initRoles(roles);
        this.areRolesLoaded = true;
        return this._roles;
    }

    public initRoles(roles: IRoles[]) {
        if (roles?.length === 0) {
            return;
        }
        this._roles.canUseAxxessCare = roles.some((role) => role.canUseAxxessCare);
        this._roles.canUseSurvey = roles.some((role) => role.canUseSurvey);
    }

    public getAccessRoles() {
        return this._roles;
    }
}
