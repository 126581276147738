import { defineComponent } from "vue";
import * as $ from "jquery";

import { EnumMap } from "../../../common/enum-map";

export default defineComponent({
    props: {
        groupNameEnumMap: {
            type: EnumMap,
            default: null
        },
        optionEnumMap: {
            type: EnumMap,
            default: null
        },
        labelText: {
            type: String,
            default: ""
        },
        searchText: {
            type: String,
            default: ""
        },
        dropDownOptions: {
            type: Object,
            default: []
        },
        selectedOptions: {
            type: Array,
            default: []
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            buttonText: "",
            searchPlaceholder: "",
            search: "",
            filteredOptions: {} as { [key: string]: any },
            dropDownMenu: null,
            dropDownElement: null,
            allOptions: []
        };
    },
    mounted() {
        this.filteredOptions = JSON.parse(JSON.stringify(this.dropDownOptions));
        $(this.$refs.dropDownMenu).on("click", (e) => {
            e.stopPropagation();
        });
        $(this.$refs.dropDownElement).on("shown.bs.dropdown", (e) => {
            (this.$refs.searchInput as HTMLInputElement)?.focus();
        });
        for (let group in this.filteredOptions) {
            if (this.filteredOptions.hasOwnProperty(group)) {
                this.allOptions.push(...this.filteredOptions[group]);
            }
        }
    },
    watch: {
        search: function () {
            if (!!this.search) {
                for (let groupName of Object.keys(this.dropDownOptions)) {
                    let options: any = this.dropDownOptions[groupName];
                    this.filteredOptions[groupName] = options.filter((option: string | number) => {
                        let displayText = this.optionEnumMap.getEnumName(option) as string;
                        return displayText.toLowerCase().includes(this.search.toLowerCase());
                    });
                }
            } else {
                this.filteredOptions = { ...this.dropDownOptions };
            }
        }
    },
    computed: {
        getDropDownOptionsLength() {
            if (!this.dropDownOptions) {
                return 0;
            }
            return Object.values(this.dropDownOptions).reduce((len, option) => option.length + len, 0);
        },

        getPlaceholderText() {
            return this.searchText ? `Search for ${this.searchText}` : `Search for ${this.labelText}`;
        },

        getButtonText() {
            if (this.selectedOptions?.length === this.getDropDownOptionsLength) {
                return `All ${this.labelText}`;
            } else if (this.selectedOptions?.length === 0) {
                return `All ${this.labelText}`;
            } else {
                return `${this.selectedOptions?.length} ${this.labelText} selected`;
            }
        }
    },

    methods: {
        toggleGroupSelected(groupName: string) {
            let options = [...this.selectedOptions];
            if (this.allGroupElementsSelected(groupName)) {
                options = options.filter((item) => !this.filteredOptions[groupName].includes(item));
            } else {
                options = [...new Set([...options, ...this.filteredOptions[groupName]])];
            }
            this.$emit("updateFilterBranches", options);
        },

        toggleAllSelection() {
            if (this.getDropDownOptionsLength === this.selectedOptions.length) {
                this.$emit("updateFilterBranches", []);
            } else {
                this.$emit("updateFilterBranches", this.allOptions || []);
            }
        },

        toggleSingleSelection(branchId: string) {
            let options = [...this.selectedOptions];
            if (options.includes(branchId)) {
                options = options.filter((item) => item != branchId);
            } else {
                options.push(branchId);
            }
            this.$emit("updateFilterBranches", options);
        },

        allGroupElementsSelected(groupName: string) {
            const isAllIn = this.filteredOptions[groupName]?.every((branchId: string) => {
                return this.selectedOptions.includes(branchId);
            });
            return isAllIn;
        },

        closeDropDown() {
            $(this.$refs.dropDownElement).dropdown("toggle");
        }
    }
});
