import { autoinject } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";
import $ from "jquery";
import config from "../common/config";
import type { ILocation } from "../resources-vue/vue-interfaces/i-location";
import type { IGeoCodeResponse } from "../resources-vue/vue-interfaces/i-map";
import { GoogleMapsService } from "../services/google-maps.service";

export enum GeoLocationEvents {
    Retrieved = "app:location:success"
}

@autoinject
export class GeolocationService {
    private _googleMapsService: GoogleMapsService;
    private _ea: EventAggregator;
    private _locationKey: string = config.locationCacheKey;
    public locationData: ILocation = {};

    // TODO: Add computedFrom for this getter
    public get hasGeo() {
        return !$.isEmptyObject(this.locationData);
    }

    public constructor(ea: EventAggregator, GoogleMaps: GoogleMapsService) {
        this._ea = ea;
        this._googleMapsService = GoogleMaps;
    }

    public async getCoordinatesByZip(zip: string): Promise<void> {
        await this._googleMapsService.getCoordsFromAddress(zip, (result: IGeoCodeResponse) => {
            this.updateLocationData({ ...result, postal_code: zip }, false);
        });
    }

    public async getAddressFromCoords(lat: string, lng: string): Promise<void> {
        await this._googleMapsService.getAddressFromCoords(
            {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            },
            (result: IGeoCodeResponse) => {
                this.updateLocationData({ ...result, lat, lng }, true);
            }
        );
    }

    private updateLocationData(result: IGeoCodeResponse, fromGeo: boolean) {
        if (result) {
            Object.assign(this.locationData, {
                lat: result.lat,
                lon: result.lng,
                zip: result.postal_code,
                city: result.city,
                state: result.state,
                willingToShareLocation: true,
                fromGeo
            });
            this.cacheLocation();
            this.announceLocationRetrieval();
        } else if (result.hasError) {
            console.log(result.message);
        }
    }

    public cacheLocation() {
        localStorage.setItem(this._locationKey, JSON.stringify(this.locationData));
    }

    public announceLocationRetrieval(locationData?: ILocation) {
        if (!!locationData) {
            Object.assign(this.locationData, locationData);
        }

        console.log(`GEOLOCATION: Publishing location success.`, this.locationData);
        this._ea.publish(GeoLocationEvents.Retrieved, this.locationData);
    }
}
