import "./grouped-multi-select.scss";

import { bindingMode, computedFrom, observable } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import * as $ from "jquery";

import { EnumMap } from "../../../common/enum-map";
import nameOf from "../../../common/name-of";
import type { IMultiSelectDropDownOption } from "../../../resources-vue/vue-interfaces/i-grouped-multiselect";

@customElement("grouped-multi-select")
export class GroupedMultiSelect {
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public searchText: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public checkAllByDefault: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public groupNameEnumMap: EnumMap;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public optionEnumMap: EnumMap;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public labelText: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isDisabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameOf<GroupedMultiSelect>("dropDownOptionsChanged")
    })
    public dropDownOptions: IMultiSelectDropDownOption;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedOptions: any[] = [];
    public filteredOptions: IMultiSelectDropDownOption;
    @observable({ changeHandler: "searchChanged" })
    public search: string;
    public dropDownMenu: HTMLElement;
    public dropDownElement: HTMLElement;
    public searchInput: HTMLElement;

    @computedFrom(nameOf<GroupedMultiSelect>("labelText"))
    public get searchPlaceholder(): string {
        return this.searchText ? `Search for ${this.searchText}` : `Search for ${this.labelText}`;
    }

    @computedFrom(nameOf<GroupedMultiSelect>("dropDownOptions"))
    public get dropDownOptionsLength() {
        if (!this.dropDownOptions) {
            return 0;
        }
        return Object.values(this.dropDownOptions).reduce((len, option) => option.length + len, 0);
    }

    @computedFrom(
        `${nameOf<GroupedMultiSelect>("selectedOptions")}.length`,
        nameOf<GroupedMultiSelect>("dropDownOptionsLength")
    )
    public get buttonText(): string {
        if (this.selectedOptions?.length === this.dropDownOptionsLength) {
            return `All ${this.labelText}`;
        } else if (this.selectedOptions?.length === 0) {
            return `All ${this.labelText}`;
        } else {
            return `${this.selectedOptions?.length} ${this.labelText} selected`;
        }
    }

    public attached() {
        this.filteredOptions = { ...this.dropDownOptions };
        $(this.dropDownMenu).on("click", (e) => {
            e.stopPropagation();
        });
        $(this.dropDownElement).on("shown.bs.dropdown", (e) => {
            this.searchInput.focus();
        });
        if (this.checkAllByDefault) {
            this.toggleAllSelected();
        }
    }

    public toggleAllSelected(element?: HTMLDivElement) {
        const shouldAddAll = this.dropDownOptionsLength !== this.selectedOptions?.length;
        // emptying array
        this.selectedOptions?.splice(0, this.selectedOptions?.length);
        let inputs = null;
        if (element) {
            inputs = element.querySelectorAll("[ref='inputElement'");
        }
        if (shouldAddAll) {
            for (let groupName of Object.keys(this.dropDownOptions)) {
                this.selectAllGroupOptions(groupName);
            }
            if (inputs) {
                inputs.forEach((item: HTMLInputElement) => (item.checked = true));
            }
        } else if (inputs) {
            inputs.forEach((item: HTMLInputElement) => (item.checked = false));
        }
    }

    public closeDropDown() {
        $(this.dropDownElement).dropdown("toggle");
    }

    public searchChanged() {
        if (!!this.search) {
            for (let groupName of Object.keys(this.dropDownOptions)) {
                let options: any = this.dropDownOptions[groupName];
                this.filteredOptions[groupName] = options.filter((option: string | number) => {
                    let displayText = this.optionEnumMap.getEnumName(option) as string;
                    return displayText.toLowerCase().includes(this.search.toLowerCase());
                });
            }
        } else {
            this.filteredOptions = { ...this.dropDownOptions };
        }
    }

    public toggleAllGroupOptions(groupName: string, checkBox?: HTMLInputElement) {
        let options = this.dropDownOptions[groupName];
        let shouldSelectAll = false;
        for (let option of options) {
            let optionIndex = this.selectedOptions.indexOf(option);
            if (optionIndex === -1) {
                shouldSelectAll = true;
            } else {
                this.selectedOptions.splice(optionIndex, 1);
            }
        }
        if (shouldSelectAll) {
            this.selectAllGroupOptions(groupName);
        }
        setTimeout(() => {
            checkBox.checked = this.selectionChanged(this.dropDownOptions[groupName]);
        });
    }

    public dropDownOptionsChanged() {
        this.filteredOptions = { ...this.dropDownOptions };
    }

    private selectAllGroupOptions(groupName: string) {
        for (let option of this.dropDownOptions[groupName]) {
            this.selectedOptions.push(option);
        }
    }

    public selectionChanged(selectionsForSingleGroup: any[], checkBoxProvider?: HTMLInputElement) {
        const result = selectionsForSingleGroup.every((item: any) => this.selectedOptions.includes(item)) || false;
        if (checkBoxProvider) checkBoxProvider.checked = result;
        return result;
    }
}
