import type { IAddress } from "../vue-interfaces/i-address";
import type { IPhoneNumber } from "../vue-interfaces/i-phone-number";
import { ApplicationEnum } from ".././../enums/enums";

export class AuthorizedContact {
    public id: string;
    public patientId?: string; // is used for update endpoint.
    public referralId?: string; // is used for update endpoint.4
    public agencyId?: string;
    public accountId?: string;
    public application?: ApplicationEnum;
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public isPrimary: boolean = false;
    public isUsingPrimaryAddress: boolean = false;
    public isLegalRepresentative: boolean = false;
    public isPatientSelectedRepresentative: boolean = false;
    public cahpsSurvey: number = 0;
    public address: IAddress = {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        county: "",
        country: ""
    };
    public relationship: number = null;
    public contactRoles: number[] = [];
    public otherSpecify?: string = "";
    public otherRelationshipDescription: string = "";
    public phoneNumbers: IPhoneNumber[] = [
        {
            number: "",
            extension: "",
            type: 0,
            isPrimary: true,
            country: null
        },
        {
            number: "",
            extension: "",
            type: 0,
            isPrimary: false,
            country: null
        }
    ];
    public isEmergencyContact: boolean = false;
    public isBereavedContact: boolean = false;
    public isCahpsSurveyRecipient: boolean = false;
    public hasFamilyPortalAccess?: boolean = false;
    public contactOrder: number = null;

    public constructor(data?: AuthorizedContact) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
