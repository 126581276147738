import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column px-3 py-2" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-content-end"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex justify-content-end"
}
const _hoisted_4 = {
  key: 0,
  class: "fa fa-spinner fa-spin"
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PatientAuthorizedContactsForm = _resolveComponent("PatientAuthorizedContactsForm")!
  const _component_PatientAuthorizedContactsList = _resolveComponent("PatientAuthorizedContactsList")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, {
    title: "Authorized Contacts",
    "full-height": false,
    "application-id": _ctx.application
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isReOrderRequested && !_ctx.showContactForm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.totalContacts > 1)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-primary mb-2",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.requestReOrder()))
                  }, " Reorder Contacts "))
                : _createCommentVNode("v-if", true),
              (_ctx.totalContacts < _ctx.contactLimit)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn-primary ml-2 mb-2",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openAddContact()))
                  }, " Add Contact "))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.isReOrderRequested)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.totalContacts > 1)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-primary mb-2",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmReOrder()))
                  }, [
                    (_ctx.isSavingReOrder)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                      : _createCommentVNode("v-if", true),
                    _createTextVNode(" " + _toDisplayString(!_ctx.isSavingReOrder ? "Save Reorder" : "Saving Reorder"), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("button", {
                class: "btn btn-outline-primary ml-2 mb-2",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancelReOrder()))
              }, "Cancel")
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.showContactForm)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(`material-card my-2 animated ${_ctx.showContactForm ? 'faster fadeInDown' : 'slow fadeOutUp'}`)
            }, [
              _createVNode(_component_PatientAuthorizedContactsForm, {
                "cahps-survey-recipient-index": _ctx.cahpsSurveyRecipientIndex,
                "is-palliative": _ctx.isApplicationPalliative,
                "patient-primary-address": _ctx.patientPrimaryAddress,
                "relationships-enum": _ctx.relationshipsEnum,
                "contact-roles-enum": _ctx.contactRolesEnum,
                "cahps-survey-contact-enum": _ctx.cahpsSurveyContactEnum,
                contact: _ctx.contact,
                "is-editable": true,
                "total-contacts": _ctx.totalContacts,
                "is-loading": _ctx.isLoading,
                ref: "addContact"
              }, null, 8 /* PROPS */, ["cahps-survey-recipient-index", "is-palliative", "patient-primary-address", "relationships-enum", "contact-roles-enum", "cahps-survey-contact-enum", "contact", "total-contacts", "is-loading"]),
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("footer", _hoisted_5, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-primary mr-2 mb-2",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addContact()))
                    }, " Add Contact "),
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn btn-outline-primary mb-2",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeAddContact()))
                    }, " Cancel ")
                  ]))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_PatientAuthorizedContactsList, {
          ref: "contactList",
          "is-re-order-requested": _ctx.isReOrderRequested,
          "patient-primary-address": _ctx.patientPrimaryAddress,
          "total-contacts": _ctx.totalContacts,
          "patient-id": _ctx.patientId,
          "is-palliative": _ctx.isApplicationPalliative,
          "cahps-survey-recipient-index": _ctx.cahpsSurveyRecipientIndex,
          "onUpdate:cahps-survey-recipient-index": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cahpsSurveyRecipientIndex) = $event)),
          "relationships-enum": _ctx.relationshipsEnum,
          "contact-roles-enum": _ctx.contactRolesEnum,
          "cahps-survey-contact-enum": _ctx.cahpsSurveyContactEnum,
          "update-contact": _ctx.updateAuthorizedContact,
          "delete-contact": _ctx.deleteAuthorizedContact,
          "get-authorized-contacts": _ctx.getAuthorizedContacts
        }, null, 8 /* PROPS */, ["is-re-order-requested", "patient-primary-address", "total-contacts", "patient-id", "is-palliative", "cahps-survey-recipient-index", "relationships-enum", "contact-roles-enum", "cahps-survey-contact-enum", "update-contact", "delete-contact", "get-authorized-contacts"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["application-id"]))
}