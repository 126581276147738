import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column px-3 py-2" }
const _hoisted_2 = { class: "form-group col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommentInput = _resolveComponent("CommentInput")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, {
    title: "Comments",
    "full-height": false,
    "application-id": _ctx.referral.application
  }, {
    content: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CommentInput, {
            "input-name": "referral-comments",
            result: _ctx.referral.comments,
            placeholder: "Enter Comments",
            "class-list": _ctx.commentInputClass,
            application: _ctx.referral.application,
            "max-length": 5000,
            "show-template-typeahead": false,
            "show-template-dropdown": true,
            onResultUpdatedAppended: _ctx.resultUpdatedAppended,
            onResultUpdated: _ctx.resultUpdated
          }, null, 8 /* PROPS */, ["result", "class-list", "application", "onResultUpdatedAppended", "onResultUpdated"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["application-id"]))
}