import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { mapState } from "pinia";

import { OtherMedicaidEnum, PaymentSources } from "../../../../../enums/enums";
import { CentralIntakeService } from "../../../../../services/central-intake.service";
import { PatientPayor } from "../../../../../resources-vue/vue-models/patient-payor";
import {
    IGetCurrentPatientPayerQuery,
    IGetPatientPayerQuery
} from "../../../../../resources-vue/vue-interfaces/i-patient-payor";
import { IApplicationAndAccountQueryParam } from "../../../../../resources-vue/vue-interfaces/i-get-referral";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { formatToUppercase, sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import EligibilityCheck from "../../../../../resources-vue/vue-custom-elements/EligibilityCheck/EligibilityCheck.vue";
import PayorTable from "../PatientPayorInformation/PayorTable.vue";

export default defineComponent({
    components: {
        FormSection,
        EligibilityCheck,
        PayorTable
    },

    validations() {
        return {
            referral: {
                medicareBeneficiaryIdentification: {
                    required: requiredIf(() => {
                        return !this.referral.medicareBeneficiaryIdentificationNA;
                    })
                }
            }
        };
    },

    props: {
        providerId: {
            type: String,
            default: ""
        },

        isHisSection: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            _centralIntakeService: null,
            otherMedicaid: null,
            otherMedicaidEnum: OtherMedicaidEnum,
            paymentSourcesEnum: null,
            paymentSourcesEnumMap: null,
            isCheckingEligibility: false,
            eligibility: null,
            eligibilityQuery: null,
            paymentSources: enumToMap(PaymentSources),
            eligibilityMissingUrl: {
                locationId: "#Referral_Agency_Branch_Label",
                firstName: "#Referral_First_Name_Label",
                lastName: "#Referral_Last_Name_Label",
                gender: "#Referral_Gender_Label",
                dateOfBirth: "#Referral_Date_Of_Birth_Label",
                medicareNumber: "#Referral_Medicare_Number_Label"
            },
            sanitizeMarkup,
            mbiTooltip: `<strong>The Medicare Beneficiary Identifier (MBI) must comply with the following format specifications:</strong>
            <ul> <li>The MBI must be eleven characters in length.</li>
            <li>The MBI is case-sensitive and must include capital letters.</li>
            <li>The first character must be numeric, excluding zero (0).</li>
            <li>The second, fifth, eighth and ninth characters must be alphabetic (excluding the following letters: S, L, O, I, B, Z).</li>
            <li>The fourth, seventh, tenth and eleventh characters must be numeric.</li>
            <li>The third and sixth characters must be numeric or alphabetic (excluding the following letters: S, L, O, I, B, Z).</li>
            </ul>`,
            payerSourceTooltip: `<h6>Item-Specific Instructions</h6>
            <p><strong>A1400. Payor Information</strong></p>
            <p>Check the box(es)that best correspond(s) to the patient’s current existing payment sources. Check all that apply.</p>
            <p>-This item is intended to identify all payors that the patient has, regardless of whether or not the payor is expected or likely to provide reimbursement for any services, supplies, medications, etc. that the patient
            may receive during the hospice episode of care. </p>
            <p>-Although Item A1400 is intended to reflect all current, existing payor sources, pending payor sources should not be
            included in A1400 (i.e., do not report payment source(s) which have been applied for but not yet received).</p>
            <ul><li>Item A1400 is intended to reflect all current existing payor sources, even if the payor is not expected or likely to provide reimbursement. For example, if a Medicare beneficiary was a Medicare Advantage patient prior
            to electing the hospice benefit, the hospice should still select “B. Medicare (managed care/Part C/Medicare Advantage)” as one of the existing payment sources for
            A1400 even though Medicare Advantage may not reimburse the hospice directly for any services.</li>
            <li>It is recommended that providers take efforts to validate existing pay sources (e.g., ask patients to present their Medicare card); however, for the purposes of completing Item A1400, eligibility for or existence of pay sources can be
            based on patient/caregiver report. </li>
            <li>For the purposes of completing Item A1400, existence of pay sources can be based on patient/caregiver report, and gathering
            additional supplementary financial information is not likely to be necessary. It is possible to collect the information during the referral/intake/admission process
            when verifying insurance sources, or during preadmission/admission discussions of what the hospice benefit will cover and what the patient may be responsible for.
            These conversations may take place as part of an overall assessment of the patient’s ability to pay for such items that may not traditionally be paid for by the Hospice Benefit. </li>
            <li><strong>Response A, Medicare (traditional fee-for-service)</strong> should be selected if the patient is a traditional fee-for-service Medicare patient. This includes if the patient has Medicare Part D.
            <li><strong>Response B, Medicare (managed care/Part C/Medicare Advantage)</strong> should be selected if the patient has Medicare HMO/managed care, another Medicare Advantage Plan, or Medicare Part C. If the patient was a Medicare Advantage patient prior to enrolling in hospice, select response option B even though Medicare Advantage may not reimburse the hospice directly for any services. If the patient was Part C/Medicare Advantage prior to enrolling in hospice, select the response options for BOTH part C and traditional fee-for-service, as applicable.</li>
            <li><strong>Response C, Medicaid (traditional fee-for-service)</strong> should be selected if the patient has traditional fee-for-service Medicaid.</li>
            <li><strong>Response D, Medicaid (managed care)</strong> should be selected if the patient has Medicaid managed care.</li>
            <li><strong>Response G, Other government (e.g., TRICARE, VA, etc.)</strong> should be selected if the patient has a government plan besides Medicare and/or Medicaid. This would include TRICARE, VA, etc. HIS Manual Chapter 2: Section A V2.01 Effective November 15, 2018 Page 2A-15 Item-Specific Tips (continued)</li>
            <li><strong>Response H, Private Insurance/Medigap</strong> should be selected if the patient has available any private insurance. This would include commercial plans irrespective of how they were purchased (i.e., regardless of whether they are provided through an employer or purchased individually by the patient or through a health insurance exchange, etc.). This response option should also be used for prescription drug coverage from a private insurer in addition to/other than Medicare Part D coverage.</li>
            <li><strong>Response I, Private Managed Care</strong> should be selected if the patient has available any private insurance that is a managed care plan. This would include commercial or privately purchased managed care plans (e.g., commercial HMO or PPO plans) irrespective of how they were purchased (i.e., regardless of whether they are provided through an employer or purchased individually by the patient or through a health insurance exchange, etc.).</li>
            <li><strong>Response J, Self-pay</strong> should be selected if the patient has any amount of personal funds available to contribute to healthcare expenses (services, supplies, medications, etc.) during the hospice episode of care. For purposes of completing Item A1400, the minimum threshold for a “self-pay” patient would be ability to pay for any low-cost medication, supply or service (e.g., medication co-pay or overthe-counter medication). Based on this definition of self-pay, for certain providers, a large majority of patients may be identified as self-pay; this is acceptable. SelfPay should be chosen even if the patient is not actively paying for anything, but could pay for something or has the funds if needed. Additionally, the intent of the self-pay response option is not to assess patients’ ability to self-pay, but rather to determine availability of funds to cover costs of care. Selecting the self-pay response option obligates neither the hospice nor the patient to use those funds to pay for care, should a need to self-pay arise. In this sense, collection of data to complete Item A1400 should not influence the delivery of hospice services based on the patient’s ability to self-pay for care, or based on availability of other pay sources the patient may have.</li>
            <li><strong>Response K, No payor source</strong> should be selected if the patient does not have any of the payor sources in response options A-I available, nor do they have any personal funds available (response option J, Self-pay) to contribute to healthcare expenses (services, supplies, medications, etc.) during the hospice episode of care.</li>
            <li><strong>Response X, Unknown</strong> should be selected if the patient is not confirmed to have any of the above payor sources in response options A-K available to contribute to healthcare expenses (services, supplies, medications, etc.) during the hospice episode of care.</li>
            <li><strong>Response Y, Other</strong> should be selected if the patient has available one or more payor sources that is not listed in responses options A-K above to contribute to healthcare expenses (services, supplies, medications, etc.) during the hospice episode of care.</li>
            <li>For charity patients: For the purposes of completing the HIS, there are two types of charity care patients: patients that will receive funds from a funded charity care program and patients that have available no other pay source and are not part of a funded charity care program. Select response “Y, Other” if the patient has available as a pay source a funded charity program. Select response “K, No payor source” if a patient does not have available any of the pay sources listed in A-J, nor will they be part of a funded charity program.</li>
            <li>For classifying individual commercial plans, providers should use their best judgement or follow-up with the appropriate commercial or private contact to classify individual commercial plans. For state-specific plans (other than traditional Medicaid) or other government plans (e.g., Tricare or other VA plans), providers should follow-up with the appropriate state or government contacts for advice on classifying these plans.</li>
            </ul>`,
            formatToUppercase,
            v$: useVuelidate()
        };
    },
    created() {
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
    },
    mounted() {
        if (this.referral.paymentSource === null) {
            this.referral.paymentSource = [];
        }

        if (this.referral.medicaidPending) {
            this.otherMedicaid = OtherMedicaidEnum.MedicaidPending;
        }
        if (this.referral.notAMedicaid) {
            this.otherMedicaid = OtherMedicaidEnum.NotAMedicaid;
        }
    },
    computed: {
        isNoPaymentSourceChecked() {
            let noPaymentSource = PaymentSources.enum.NoPayerSource;
            return this.referral.paymentSource?.includes(noPaymentSource);
        },
        ...mapState(useCreateReferralHosPal, ["referral"])
    },
    watch: {
        otherMedicaid(newValue: any) {
            if (newValue) {
                if (newValue == OtherMedicaidEnum.MedicaidPending) {
                    this.referral.notAMedicaid = false;
                    this.referral.medicaidPending = true;
                } else {
                    this.referral.notAMedicaid = true;
                    this.referral.medicaidPending = false;
                }
            }
        }
    },
    methods: {
        handleNoPayerSourceSelection() {
            if (this.isNoPaymentSourceChecked) {
                this.otherMedicaid = OtherMedicaidEnum.NotAMedicaid;
                this.referral.notAMedicaid = true;
                this.referral.medicaidPending = false;
                this.referral.medicareBeneficiaryIdentificationNA = true;
            } else {
                this.otherMedicaid = null;
                this.referral.medicareBeneficiaryIdentificationNA = false;
            }
        },
        otherMedicaidRadioClick(value: OtherMedicaidEnum) {
            if (this.otherMedicaid == value) {
                this.otherMedicaid = null;
            } else {
                this.otherMedicaid = value;
            }
        },
        addNewPayer(payor: PatientPayor) {
            return this._centralIntakeService.createReferralPayors(this.referral.id, payor);
        },
        getCurrentPatientPayors(query: IGetCurrentPatientPayerQuery) {
            query.accountId = this.referral.accountId;
            query.agencyId = this.referral.accountId;
            query.application = this.referral.application;
            return this._centralIntakeService.getCurrentReferralPayors(this.referral.id, query);
        },
        getPatientPayors(query: IGetPatientPayerQuery) {
            return this._centralIntakeService.getReferralPayors(this.referral.id, query);
        },
        updatePatientPayor(payorId: string, payor: PatientPayor) {
            payor.accountId = this.referral.accountId;
            payor.agencyId = this.referral.accountId;
            payor.application = this.referral.application;
            return this._centralIntakeService.updateReferralPayors(payorId, payor);
        },
        deletePatientPayor(payorId: string) {
            const query: IApplicationAndAccountQueryParam = {
                accountId: this.referral.accountId,
                agencyId: this.referral.accountId,
                application: Number(this.referral.application)
            };
            return this._centralIntakeService.deleteReferralPayors(payorId, query);
        },
        addPaymentSource(value: number) {
            if (this.referral.paymentSource.includes(value)) {
                let temp = [...this.referral.paymentSource];
                temp = temp.filter((val) => val !== value);
                this.referral.paymentSource = temp;
            } else {
                this.referral.paymentSource.push(value);
            }
            this.handleNoPayerSourceSelection();
        },
        handleMedicareNumber(e: any) {
            this.referral.medicareBeneficiaryIdentification = this.formatToUppercase(e.target.value);
        }
    }
});
