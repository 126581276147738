import "./day-picker.scss";
import "custom-event-polyfill";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";

import { Days } from "./../../../enums/enums";

@autoinject
@customElement("days-picker")
export class DaysPicker {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedDays: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedDaysString: string = "";
    public readonly Days = Days;

    public handleDaySelect(value: string, event: any) {
        if (this.selectedDays.includes(value)) {
            const index = this.selectedDays.indexOf(value);
            this.selectedDays.splice(index, 1);
            event.target.classList.remove("selected-day");
        } else {
            this.selectedDays.push(value);
            event.target.classList.add("selected-day");
        }
        this.selectedDaysString = this.selectedDays.toString();
    }
}
