import "./reschedule-validations.scss";
import { DialogController } from "aurelia-dialog";
import { autoinject, PLATFORM } from "aurelia-framework";

export interface IRescheduleValidations {
    title: string;
    description: string[];
    taskIssueSurpressKey: string;
}

PLATFORM.moduleName("./reschedule-validations");
@autoinject()
export class RescheduleValidations {
    private dialogController: DialogController;
    private validations: IRescheduleValidations[] = [];
    private validationsAccpeted: string[] = [];
    private name: string = "";
    private enableContinue: boolean = false;
    private collapsionList: boolean[] = [];
    private hasError: boolean = false;
    private isScheduleVisitComponent: boolean = false;

    public constructor(dialogController: DialogController) {
        this.dialogController = dialogController;
    }

    public activate(model: {
        validations: IRescheduleValidations[];
        name: string;
        hasError: boolean;
        component: string;
    }) {
        if (model.component == "Schedule-visit") {
            this.isScheduleVisitComponent = true;
        }
        if (model?.validations) {
            this.validations = model.validations;
            this.name = model.name;
            this.hasError = model.hasError;
        } else {
            this.dialogController.cancel();
        }
    }

    private validationSelected() {
        this.enableContinue = this.validationsAccpeted.length === this.validations.length;
    }
}
