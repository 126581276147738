import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { DiagnosisQueryTypeEnum, LookupService } from "../../../services/lookup-service";
import { IDiagnosisDetails } from "../../vue-interfaces/i-diagnosis";

export default defineComponent({
    props: {
        selectedDiagnosis: {
            type: Object,
            default: null
        },
        debounce: {
            type: Number,
            default: 200
        },
        noResultsText: {
            type: String,
            default: "No matches found"
        },
        loadingText: {
            type: String,
            default: "Loading..."
        },
        classes: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        diagnosisChanged: {
            type: Function
        },
        codeValidation: {
            type: Boolean,
            default: false
        },
        descValidation: {
            type: Boolean,
            default: false
        },
        application: {
            type: Number
        }
    },
    data() {
        return {
            descFilter: "",
            codeFilter: "",
            currentDiagnosisLevel: 0,
            parentDiagnosis: [],
            ignoreCodeFilterUpdate: false,
            ignoreDescFilterUpdate: false,
            ignoreSelectedDiagnosisUpdate: false,
            _lookUpService: null,
            currentDiagnosisList: [],
            diagnosisList: [],
            loading: false,
            diagnosisListComponent: null,
            _outsideClickListener: null
        };
    },
    created() {
        this._lookUpService = Container.instance.get(LookupService);
        this._outsideClickListener = (evt: MouseEvent) => this.handleBlur(evt);
    },
    mounted() {
        document.addEventListener("click", this._outsideClickListener);
    },
    unmounted() {
        document.removeEventListener("click", this._outsideClickListener);
    },
    watch: {
        selectedDiagnosis(newValue: any) {
            if (this.ignoreSelectedDiagnosisUpdate) {
                this.ignoreSelectedDiagnosisUpdate = false;
                return;
            }
            if (newValue) {
                this.ignoreCodeFilterUpdate = true;
                this.codeFilter = newValue.code;
                this.ignoreDescFilterUpdate = true;
                this.descFilter = newValue.description;
            }
        },
        codeFilter(newValue: string, oldValue: string) {
            if (this.ignoreCodeFilterUpdate) {
                this.ignoreCodeFilterUpdate = false;
                return;
            }
            if (newValue == "") {
                this.resetDiagnosis();
            }
            if (newValue && newValue.length > 2) {
                setTimeout(async () => {
                    this.loading = true;
                    let diagnosisResult = await this._lookUpService.getIcd10Codes({
                        term: newValue,
                        type: DiagnosisQueryTypeEnum.code,
                        application: this.application
                    });
                    this.ignoreDescFilterUpdate = true;
                    this.descFilter = "";
                    this.loading = false;
                    this.updateDiagnosisList(diagnosisResult);
                }, this.debounce);
            }
        },
        descFilter(newValue: string, oldValue: string) {
            if (this.ignoreDescFilterUpdate) {
                this.ignoreDescFilterUpdate = false;
                return;
            }
            if (newValue == "") {
                this.resetDiagnosis();
            }
            if (newValue && newValue.length > 2) {
                setTimeout(async () => {
                    this.loading = true;
                    let diagnosisResult = await this._lookUpService.getIcd10Codes({
                        term: newValue,
                        type: DiagnosisQueryTypeEnum.desc,
                        application: this.application
                    });
                    this.ignoreCodeFilterUpdate = true;
                    this.codeFilter = "";
                    this.loading = false;
                    this.updateDiagnosisList(diagnosisResult);
                }, this.debounce);
            }
        }
    },
    methods: {
        getImmediateParentDiag() {
            if (this.parentDiagnosis.length > 0) {
                return this.parentDiagnosis[this.parentDiagnosis.length - 1];
            }
            return null;
        },
        updateDiagnosisList(diagnosisResultList: IDiagnosisDetails[]) {
            this.diagnosisList = diagnosisResultList;
            this.parentDiagnosis = [];
            if (diagnosisResultList && diagnosisResultList.length > 0) {
                this.currentDiagnosisLevel = Math.min.apply(
                    null,
                    this.diagnosisList.map((diagnosis: IDiagnosisDetails) => diagnosis.levels)
                );
            } else {
                this.currentDiagnosisLevel = 0;
            }
            this.renderDiagnosis();
        },
        renderDiagnosis() {
            if (this.diagnosisList && this.diagnosisList.length > 0) {
                this.currentDiagnosisList = this.diagnosisList.filter((diagnosis: any) => {
                    let diagnosisLevel = diagnosis.levels == this.currentDiagnosisLevel;
                    let diagnosisParent = this.getImmediateParentDiag()
                        ? diagnosis.upperLevel == this.getImmediateParentDiag().code
                        : true;
                    return diagnosisLevel && diagnosisParent;
                });
            } else {
                this.currentDiagnosisList = [];
            }
        },
        selectDiagnosis(diagnosis: any) {
            if (!diagnosis.billable) {
                this.currentDiagnosisLevel = diagnosis.levels + 1;
                this.parentDiagnosis.push(diagnosis);
                this.renderDiagnosis();
            } else {
                this.ignoreCodeFilterUpdate = true;
                this.codeFilter = diagnosis.formatCode;
                this.ignoreDescFilterUpdate = true;
                this.descFilter = diagnosis.shortDescription;
                this.ignoreSelectedDiagnosisUpdate = true;
                if (typeof this.diagnosisChanged === "function") {
                    this.diagnosisChanged({
                        newDiagnosis: {
                            code: diagnosis.formatCode,
                            description: diagnosis.shortDescription,
                            billable: diagnosis.billable
                        }
                    });
                }
                this.currentDiagnosisList = [];
            }
        },
        handleBlur(evt: Event) {
            let source = evt.target as Element;
            if ($(this.diagnosisListComponent).find(source).length != 0 || this.currentDiagnosisList.length != 0) {
                return;
            }
            this.updateDiagnosisList([]);
        },
        goInitState() {
            this.updateDiagnosisList(this.diagnosisList);
        },
        goToDiagList(index: number, diagnosis: any) {
            this.parentDiagnosis.splice(index, this.parentDiagnosis.length);
            this.selectDiagnosis(diagnosis);
        },
        resetDiagnosis() {
            this.codeFilter = "";
            this.descFilter = "";
            this.ignoreSelectedDiagnosisUpdate = true;
            this.updateDiagnosisList([]);
            if (this.diagnosisChanged) {
                this.diagnosisChanged(null);
            }
        }
    }
});
