export class EmergencyContact {
    public Id: string;
    public ReferralId?: string;
    public AgencyId?: string;
    public FirstName: string = "";
    public LastName: string = "";
    public EmailAddress: string = "";
    public IsPrimary: boolean = false;
    public SameAsPatientAddress: boolean = false;
    public AddressLine1: string = "";
    public AddressLine2: string = "";
    public AddressCity: string = "";
    public AddressStateCode: string = "";
    public AddressZipCode: string = "";
    public AddressZipCodeFour: string = "";
    public AddressCounty: string = "";
    public Relationship: string = "";
    public PrimaryPhone: string = "";
    public AlternatePhone: string = "";
    public Representative: number = 0;
    public Relation: number = 0;
    public RelationOption: number = 0;
    [key: string]: any;

    public constructor(data?: EmergencyContact) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
