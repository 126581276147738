import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum MissedVisitPalliativeReasonsEnum {
    PatientCaregiverDeclined = 1 as any,
    InclementWeather = 2 as any,
    PatientHospitalized = 3 as any,
    PatientNotHome = 5 as any,
    PatientCanceled = 6 as any,
    PatientNoShow = 7 as any,
    Other = 9 as any
}

class MissedVisitPalliativeReasonsClass extends EnumWithDescription<MissedVisitPalliativeReasonsEnum> {
    public readonly enum = MissedVisitPalliativeReasonsEnum;
    public readonly enumName: string = "MissedVisitHospiceReasonsEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<MissedVisitPalliativeReasonsEnum>> = new Map([
        [
            "PatientCaregiverDeclined",
            {
                value: MissedVisitPalliativeReasonsEnum.PatientCaregiverDeclined,
                name: "Patient/Caregiver Declined"
            }
        ],
        [
            "InclementWeather",
            {
                value: MissedVisitPalliativeReasonsEnum.InclementWeather,
                name: "Inclement Weather"
            }
        ],
        [
            "PatientHospitalized",
            {
                value: MissedVisitPalliativeReasonsEnum.PatientHospitalized,
                name: "Patient Hospitalized"
            }
        ],
        [
            "PatientNotHome",
            {
                value: MissedVisitPalliativeReasonsEnum.PatientNotHome,
                name: "Patient Not Home"
            }
        ],
        [
            "PatientCanceled",
            {
                value: MissedVisitPalliativeReasonsEnum.PatientNotHome,
                name: "Patient Canceled"
            }
        ],
        [
            "PatientNoShow",
            {
                value: MissedVisitPalliativeReasonsEnum.PatientNotHome,
                name: "Patient No Show"
            }
        ],
        [
            "Other",
            {
                value: MissedVisitPalliativeReasonsEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const MissedVisitPalliativeReasons = new MissedVisitPalliativeReasonsClass();
