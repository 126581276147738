export const SET_HH_REFERRAL = "SET_HH_REFERRAL";
export const SET_REFERRAL_OPTIONS = "SET_REFERRAL_OPTIONS";
export const SET_CURRENT_APPLICATION = "SET_CURRENT_APPLICATION";
export const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";
export const SET_ERRORS = "SET_ERRORS";
export const SET_ADDITIONAL_CONTACT_ERROR = "SET_ADDITIONAL_CONTACT_ERROR";
export const REMOVE_ERRORS = "REMOVE_ERRORS";
export const RESET_ERRORS = "RESET_ERRORS";
export const SET_UPDATED = "SET_UPDATED";
export const SET_COUNTY_DATA = "SET_COUNTY_DATA";
export const SET_MAILING_COUNTY = "SET_MAILING_COUNTY";
export const SET_VISIT_COUNTY = "SET_VISIT_COUNTY";
export const SET_PRIMARY_EMERGENCY = "SET_PRIMARY_EMERGENCY";
export const SET_CHAPS_COUNTY = "SET_CHAPS_COUNTY";
export const SET_ADDITION_COUNTY_1 = "SET_ADDITION_COUNTY_1";
export const SET_ADDITION_COUNTY_2 = "SET_ADDITION_COUNTY_2";
export const SET_ADDITION_COUNTY_3 = "SET_ADDITION_COUNTY_3";
export const SET_NEW_ERRORS = "SET_NEW_ERRORS";
