import { defineComponent } from "vue";

export default defineComponent({
    props: {
        activeItem: {
            type: HTMLElement
        },
        outlineStyle: {
            type: String
        }
    },

    data() {
        return {
            sliderWidth: null,
            sliderLeft: null,
            sliderBottom: null
        };
    },
    watch: {
        activeItem() {
            this.sliderWidth = this.activeItem ? this.activeItem.offsetWidth : null;
            this.sliderLeft = this.activeItem ? this.activeItem.offsetLeft : null;
            this.sliderBottom = this.activeItem ? this.activeItem.offsetTop + this.activeItem.offsetHeight - 5 : null;
        }
    },
    methods: {
        underlineStyle() {
            let left = `left: ${this.sliderLeft + this.sliderWidth / 2 - 400 / 2}px;`;
            let top = `top: ${this.sliderBottom}px;`;
            let paddingLeft = `padding-left: ${(400 - this.sliderWidth) / 2}px;`;
            let paddingRight = `padding-right: ${(400 - this.sliderWidth) / 2}px;`;
            return left + top + paddingLeft + paddingRight;
        }
    }
});
