import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { TaskService } from "../../../../../services/task.service";
import { ToastrService } from "../../../../../services/toastr.service";
import { PatientService } from "../../../../../services/patient.service";
import { ApplicationEnum } from "../../../../../enums/enums";
import { formatDateInput, sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import TimePicker from "../../../../../resources-vue/vue-custom-elements/TimePicker/TimePicker.vue";

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        TimePicker
    },
    props: {
        visitDetails: {
            type: Object
        },
        cancel: {
            type: Function
        },
        isSaving: {
            type: Boolean,
            default: false
        },
        ok: {
            type: Function
        }
    },
    data(vm) {
        return {
            _taskService: null,
            _toastrService: null,
            _patientService: null,
            taskDetails: null,
            disable: false,
            disableScheduleDate: false,
            disableVisitEndTime: true,
            assignedToName: "",
            isPalliative: true,
            payers: null,
            isLoading: false as boolean,
            surchargeTooltip: "Can be used in instances such as: parking, tolls, etc.",
            formatDateInput,
            sanitizeMarkup
        };
    },
    async created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._patientService = Container.instance.get(PatientService);
        this.getPayers();
        if (this.visitDetails.Status == "Not Yet Due" || this.visitDetails.Status == "Not Yet Started") {
            this.disable = true;
        }
        this.disableScheduleDate = this.visitDetails.Status == "Completed";
        this.isPalliative = this.visitDetails.Application == ApplicationEnum.AxxessPalliative;

        try {
            this.isLoading = true;
            this.taskDetails = await this._taskService.getTasksDetails({
                agencyId: this.visitDetails.AgencyId,
                application: this.visitDetails.Application,
                eventId: this.visitDetails.Id,
                patientId: this.visitDetails.PatientId
            });
            this.assignedToName =
                this.taskDetails.scheduleEvent.userFirstName + " " + this.taskDetails.scheduleEvent.userLastName;
            if (this.taskDetails.scheduleEvent.timeIn) {
                this.disableVisitEndTime = false;
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.isLoading = false;
        }
    },

    methods: {
        async getPayers() {
            this.payers = await this._patientService.getPayersByPatientId({
                patientId: this.visitDetails.PatientId,
                applications: this.visitDetails.Application,
                taskId: this.visitDetails.Id,
                accountId: this.visitDetails.AgencyId
            });
        },
        async saveVisit() {
            if (!!this.taskDetails) {
                try {
                    this.$emit("update:isSaving", true);
                    this.taskDetails.scheduleEvent.application = this.visitDetails.Application;
                    await this._taskService.updateTasksDetails(this.taskDetails.scheduleEvent);
                    this._toastrService.success({
                        title: `Visit Updated`,
                        message: `Visit has been successfully Updated.`
                    });
                    this.ok();
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Update Failed`,
                        message: `Updating Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.$emit("update:isSaving", false);
                }
            }
        },
        handleDateChange(date: any, name: string) {
            this.taskDetails.scheduleEvent.eventDate = date;
        },
        changetimeIn(time: any) {
            this.taskDetails.scheduleEvent.timeIn = time;
            this.taskDetails.scheduleEvent.timeIn
                ? (this.disableVisitEndTime = false)
                : (this.disableVisitEndTime = true);
        },
        changetimeOut(time: any) {
            this.taskDetails.scheduleEvent.timeOut = time;
        },
        changeTravelTimeIn(time: any) {
            this.taskDetails.scheduleEvent.travelTimeIn = time;
        },
        changeTravelTimeOut(time: any) {
            this.taskDetails.scheduleEvent.travelTimeOut = time;
        }
    }
});
