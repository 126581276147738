import "bootstrap";
import "whatwg-fetch";

import { DialogConfiguration } from "aurelia-dialog";
import { Aurelia, PLATFORM } from "aurelia-framework";
import { UserManager } from "oidc-client";
import { BootstrapFormRenderer } from "./common/bootstrap-form-renderer";

import Infrastructure from "./common/infrastructure";
import { OidcConfiguration } from "./common/oidc-configuration";

import { helperTestingAgency } from "./common/vue-helpers/testing-agency-check";

/// <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
// we want font-awesome to load as soon as possible to show the fa-spinner
export async function configure(aurelia: Aurelia) {
    aurelia.use
        .standardConfiguration()
        .developmentLogging()
        .feature(PLATFORM.moduleName("resources/index"))
        .plugin(PLATFORM.moduleName("aurelia-validation"))
        .plugin(PLATFORM.moduleName("bcx-aurelia-reorderable-repeat"))
        .plugin(PLATFORM.moduleName("aurelia-dialog"), (config: DialogConfiguration) => {
            config.useDefaults();
            config.settings.lock = true;
            config.settings.centerHorizontalOnly = false;
            config.settings.startingZIndex = 9999;
        });

    let oidcConfig: OidcConfiguration = aurelia.container.get(OidcConfiguration);
    let userManager = oidcConfig.createUserManager();
    aurelia.container.registerInstance(UserManager, userManager);
    aurelia.container.registerHandler("bootstrap-form-renderer", (x) => x.get(BootstrapFormRenderer));
    const isBetaOpted = sessionStorage.getItem("isBetaOpted");
    if (isBetaOpted == "true") {
        const isMounted = await helperTestingAgency.mountVueApp();
        if (isMounted) {
            logVersion();
            return;
        }
    }
    await aurelia.start();
    await aurelia.setRoot(PLATFORM.moduleName("pages/app/app"));
    logVersion();
}

function logVersion(): void {
    console.log(`Running release version '${Infrastructure.releaseVersion}'.`);
    console.log(`Running release environment '${Infrastructure.releaseEnvironment}'.`);
    console.log(`Running build version '${Infrastructure.buildVersion}'.`);
}
