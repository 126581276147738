import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../../assets/images/favicon-96x96.png'


const _hoisted_1 = {
  class: "nav list-group list-group-flush pl-0",
  id: "Messages_List"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "ava-initials mr-1" }
const _hoisted_5 = {
  key: 0,
  src: _imports_0,
  width: "32px"
}
const _hoisted_6 = {
  key: 0,
  class: "fas fa-users"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "ml-2 flex-grow-1" }
const _hoisted_9 = { class: "d-flex align-items-start" }
const _hoisted_10 = { class: "mr-auto mb-1 flex-grow-1" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "text-muted" }
const _hoisted_14 = { class: "d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conversations, (message) => {
      return (_openBlock(), _createElementBlock("li", {
        key: message.id,
        class: "nav-item list-group-item message-list-item m-0",
        onClick: ($event: any) => (_ctx.handleMenuSelection(message))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (message.isAxxessConversation)
              ? (_openBlock(), _createElementBlock("img", _hoisted_5))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (message.users.length > 1)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formatUserInitials(message.users[0])), 1 /* TEXT */))
                ], 64 /* STABLE_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h6", _hoisted_10, [
                (message?.title?.length > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(message.title), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.formatDisplayUsers(message.users)), 1 /* TEXT */))
              ]),
              _createElementVNode("small", _hoisted_13, _toDisplayString(_ctx.formatMessageTime(message.modifiedOn)), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", {
                class: _normalizeClass(`${
                                message.unread > 0 ? 'font-weight-bold' : 'text-muted'
                            } mr-auto flex-grow-1 align-self-start`)
              }, _toDisplayString(_ctx.formatDisplayMessage(_ctx.sanitizeMarkup(_ctx.markdownToHtml(message.text)))), 3 /* TEXT, CLASS */),
              _createElementVNode("b", {
                class: _normalizeClass(`unread-count ${message.unread > 0 ? '' : 'd-none'}`)
              }, _toDisplayString(message.unread), 3 /* TEXT, CLASS */)
            ])
          ])
        ])
      ], 8 /* PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}