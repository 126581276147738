import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { IEmployeeList, IReassignVisitDetails } from "../../../vue-interfaces/i-reassign-visit";
import { formatDateFromDate } from "../../../../common/vue-helpers/modifiers/value-modifier";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    props: {
        visitDetails: { type: Object, default: {} as IReassignVisitDetails },
        employeeList: { type: Object, default: [] as IEmployeeList[] },
        isLoading: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        cancel: { type: Function },
        reassign: { type: Function }
    },

    components: {
        FormSection
    },

    created() {
        Object.assign(this.visitDetails, {});
        this.visitDetails.EmpId = null;
    },

    data() {
        return {
            formatDateFromDate,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            visitDetails: {
                EmpId: { required }
            }
        };
    },

    methods: {
        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : new Date(this.visitDetails.StartDate).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit"
                  });
        },

        selectedEmployee(employeeId: string) {
            if (!!employeeId) {
                const employeeDetails = this.employeeList.find((emp: any) => {
                    return emp.id == employeeId;
                });
                this.visitDetails.Employee = employeeDetails;
            }
        },

        async reassignVisit() {
            const valid = await this.v$.$validate();
            if (!valid) {
                return;
            }
            let reassignDetails = Object.assign({}, this.visitDetails);
            this.reassign(reassignDetails);
        }
    }
});
