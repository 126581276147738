import { HomeCareReferral } from "./../models/homecare-referral";
import { autoinject } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";
import type { IGetDocumentTypeLean, IGetDocumentTypeLeanQuery } from "../resources-vue/vue-interfaces/i-document-type";
import type { IContactsOrderQueryParam } from "../interfaces/i-get-patient";
import {
    IApplicationAndAccountQueryParam,
    ICreateInterimReferralIdResult,
    ICreateInterimReferralPayload,
    ICreatePreadmissionNoteResponse,
    ICreateReferralResult,
    IdeleteAuthorizedContact,
    IGetPreadmissionNotes,
    IGetPreadmissionNotesQuery,
    IGetReferralQueryParam,
    IDeleteReferralQuery,
    IHomeHealthReferralQueryParam,
    IdeleteEmergencyContact,
    IGetServicesRequired,
    IGetServicesRequiredResult,
    IGetTagsRequest,
    IGetTagsResponse,
    IUpdateTagRequest,
    ITagResponse
} from "../interfaces/i-get-referral";
import type { IHospiceReferral } from "../interfaces/i-hospice-referral";
import type { IPaginationNew } from "../interfaces/i-pagination";
import { AuthorizedContact } from "../models/authorized-contact";
import { HospiceReferral } from "../models/hospice-referral";
import { PreadmissionNote } from "../models/preadmission-note-model";
import type { ICreateResponse } from "./../resources-vue/vue-interfaces/i-response";
import { ApplicationEnum } from "../enums/enums";
import { EmergencyContact } from "../models/homecare-emergency-contact";
import { IHomeHealthReferralNew } from "../resources-vue/vue-interfaces/i-homehealth-referral";
import { IGetEditHHReferral } from "./patient.service";

@autoinject()
export class ReferralService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/referrals";

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async createInterimReferral(payload: ICreateInterimReferralPayload) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<ICreateInterimReferralIdResult>();
    }

    public async getReferral(referralId: string, queryParam: IGetReferralQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}`)
            .useQueryString(queryParam)
            .fetch<IHospiceReferral>();
    }

    public async getHomeCareReferral(referralId: string, queryParam: IGetReferralQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}`)
            .useQueryString(queryParam)
            .fetch<HomeCareReferral>();
    }

    public async getServicesRequired(queryParam: IGetServicesRequired) {
        return await this._httpClient
            .build(`${this._baseUrl}/services`)
            .useQueryString(queryParam)
            .fetch<IGetServicesRequiredResult[]>();
    }

    public async createHospiceReferral(referral: HospiceReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/hospice`)
            .useMethod("POST")
            .useBody(referral)
            .fetch<ICreateReferralResult>();
    }

    public async createHomeHealthReferral(referral: IHomeHealthReferralQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/homehealth`)
            .useMethod("POST")
            .useBody(referral)
            .fetch<ICreateReferralResult>();
    }

    public async createHomeCareReferral(referral: HomeCareReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(referral)
            .fetch<ICreateReferralResult>();
    }

    public async editHomeHealthReferral(referral: IHomeHealthReferralQueryParam, id: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/homehealth/${id}`)
            .useMethod("PUT")
            .useBody(referral)
            .fetchNoContent();
    }

    public async newEditHHReferral(payload: IGetEditHHReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/homehealth/new`)
            .useMethod("POST")
            .useBody(payload)
            .fetch();
    }

    public async createPalliativeReferral(referral: HospiceReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care`)
            .useMethod("POST")
            .useBody(referral)
            .fetch<ICreateReferralResult>();
    }

    public async editHospiceReferral(referral: HospiceReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/hospice/${referral.id}`)
            .useMethod("PUT")
            .useBody(referral)
            .fetchNoContent();
    }

    public async editPalliativeReferral(referral: HospiceReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care/${referral.id}`)
            .useMethod("PUT")
            .useBody(referral)
            .fetchNoContent();
    }

    public async editHomeCareReferral(referral: HomeCareReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/homecare/${referral.id}`)
            .useMethod("PUT")
            .useBody(referral)
            .fetch();
    }

    public async addContact(referralId: string, contact: AuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/authorized-contact`)
            .useMethod("POST")
            .useBody(contact)
            .fetch<ICreateResponse>();
    }

    public async updateContact(contactInformation: AuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${contactInformation.id}`)
            .useMethod("PUT")
            .useBody(contactInformation)
            .fetchNoContent();
    }

    public async deleteContact(payload: IdeleteAuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${payload.id}`)
            .useMethod("DELETE")
            .useBody(payload)
            .fetchNoContent();
    }

    public async reorderContacts(contactsOrderQuery: IContactsOrderQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/order-contacts`)
            .useMethod("PUT")
            .useBody(contactsOrderQuery)
            .fetchNoContent();
    }

    public async getContacts(referralId: string, payload: IApplicationAndAccountQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/authorized-contact`)
            .useQueryString(payload)
            .fetch<AuthorizedContact[]>();
    }

    public async getPreadmissionNotes(query: IGetPreadmissionNotesQuery, referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetPreadmissionNotes>>();
    }

    public async createPreadmissionNotes(referralId: string, note: PreadmissionNote) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes`)
            .useMethod("POST")
            .useBody(note)
            .fetch<ICreatePreadmissionNoteResponse>();
    }

    public async updatePreadmissionNotes(referralId: string, noteId: string, note: PreadmissionNote) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes/${noteId}`)
            .useMethod("PUT")
            .useBody(note)
            .fetchNoContent();
    }

    public async deletePreadmissionNotes(
        referralId: string,
        noteId: string,
        query?: { accountId: string; application: ApplicationEnum }
    ) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes/${noteId}`)
            .useMethod("Delete")
            .useBody(query)
            .fetchNoContent();
    }

    public async getDocumentTypesList(query: IGetDocumentTypeLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/document-types`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetDocumentTypeLean[]>();
    }

    public async deleteReferral(referralId: string, query: IDeleteReferralQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}`)
            .useQueryString(query)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getReferralOptions(agencyId: string) {
        const payload = { agencyId };
        return await this._httpClient.build(`${this._baseUrl}/options`).useQueryString(payload).fetch<any>();
    }

    // Home care Emergency Contacts Endpoints
    public async addHCEmergencyContact(referralId: string, contact: EmergencyContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/authorized-contact`)
            .useMethod("POST")
            .useBody(contact)
            .fetch<ICreateResponse>();
    }

    public async updateHCEmergencyContact(contactInformation: EmergencyContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${contactInformation.id}`)
            .useMethod("PUT")
            .useBody(contactInformation)
            .fetch();
    }

    public async deleteHCEmergencyContact(payload: IdeleteEmergencyContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${payload.id}`)
            .useMethod("DELETE")
            .useBody(payload)
            .fetchNoContent();
    }

    public async reorderHCEmergencyContacts(contactsOrderQuery: IContactsOrderQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/order-contacts`)
            .useMethod("PUT")
            .useBody(contactsOrderQuery)
            .fetchNoContent();
    }

    public async getHCEmergencyContacts(referralId: string, payload: IApplicationAndAccountQueryParam) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/authorized-contact`)
            .useQueryString(payload)
            .fetch<EmergencyContact[]>();
    }

    public async makeHCEmergencyContactPrimary(
        referralId: string,
        contactId: string,
        payload: IApplicationAndAccountQueryParam
    ) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/contact/${contactId}/primary`)
            .useMethod("POST")
            .useBody(payload)
            .fetchNoContent();
    }

    public async getReferralData(payload: IApplicationAndAccountQueryParam, id: string) {
        return await this._httpClient.build(`${this._baseUrl}/homehealth/${id}`).useQueryString(payload).fetch<any>();
    }

    public async newgetReferralData(payload: IApplicationAndAccountQueryParam, id: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/homehealth/${id}`)
            .useQueryString(payload)
            .fetch<IHomeHealthReferralNew>();
    }

    public async getHCReferralTags(payload: IGetTagsRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/tags`)
            .useQueryString(payload)
            .fetch<IGetTagsResponse[]>();
    }

    public async addHCReferralTags(payload: IUpdateTagRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/tag`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<ITagResponse>();
    }

    public async deleteHCReferralTags(payload: IUpdateTagRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/tag`)
            .useMethod("DELETE")
            .useBody(payload)
            .fetch<ITagResponse>();
    }

    public async getTemplateData(query: any) {
        return await this._httpClient.build(`${this._baseUrl}/get-template`).useQueryString(query).fetch();
    }

    public async getEvacuationZone(query: any) {
        return await this._httpClient.build(`${this._baseUrl}/evacuation-zones`).useQueryString(query).fetch();
    }
}
