import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import centralIntakeRoutes from "./central-messaging-routes";
import {
    IConversationItem,
    IConversationNavOptions
} from "../../resources-vue/vue-interfaces/messaging-service/i-message";
import { INotificationResponse } from "../../interfaces/i-notification";
import { MessageService } from "../../services/message-service";
import { SignalrEvent } from "../../enums/SignalrEventEnum";
import eventBus from "../../utilities/eventBus";

import PageLayout from "../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import ScrollPagination from "../../resources-vue/vue-elements/ScrollPagination/ScrollPagination.vue";
import ConversationNav from "./ConversationNav/ConversationNav.vue";

export default defineComponent({
    components: {
        PageLayout,
        ConversationNav,
        ScrollPagination
    },

    data() {
        return {
            router: null,
            _messageService: null,
            routes: null,
            pageNumber: 1 as number,
            pageLength: 20 as number,
            allPagesLoaded: false as boolean,
            isLoading: false as boolean,
            listOptions: [] as IConversationNavOptions[],
            conversations: [] as IConversationItem[],
            hideNav: false as boolean,
            isMobile: false as boolean,
            hasError: false,
            _newMessageSubscription: {} as any,
            conversationUsersType: "clinicians" as string,
            newConversation: false as boolean,
            userOs: "" as string
        };
    },

    async mounted() {
        this.router = centralIntakeRoutes;
    },

    async created() {
        this.$watch(
            () => this.$route.params,
            async () => {
                this.conversationUsersType = String(this.$route.meta.menuName).toLowerCase();
                this.hideNav = this.$route.path?.length > 12;
                if (this.$router.currentRoute.value.fullPath.indexOf("new-conversation") > -1) {
                    this.newConversation = true;
                } else {
                    if (this.newConversation) {
                        this.pageNumber = 1;
                        await this.getConversations();
                        this.newConversation = false;
                    }
                }
            },
            { immediate: true }
        );
        this._messageService = Container.instance.get(MessageService);
        this.conversationType();

        eventBus.on(SignalrEvent.NewMessage, async (response: INotificationResponse) => {
            return this.unReadMessagesUpdate(response.message);
        });

        this.userOs = window.sessionStorage.getItem("userOs");
    },

    methods: {
        setListOptions() {
            this.listOptions = this.conversations.map((conversation) => ({
                title: conversation.title,
                text: conversation.text,
                unread: conversation.unread,
                createdOn: conversation.createdOn,
                modifiedOn: conversation.modifiedOn,
                users: conversation.users,
                isAxxessConversation: conversation.isAxxessConversation,
                id: conversation.id
            }));
        },

        pageNumberUpdated(pageToLoad: number) {
            if (!pageToLoad) {
                return;
            }
            if (pageToLoad != this.pageNumber) {
                this.pageNumber = pageToLoad;
            } else {
                return;
            }
            this.allPagesLoaded = this.pageNumber > this.pageNumber;
            if (!this.allPagesLoaded) {
                this.getConversations();
            }
        },

        async getConversations() {
            try {
                this.isLoading = true;
                // TODO -  replace later with API call
                let result = await this._messageService.getConversations({
                    page: this.pageNumber,
                    pageLength: this.pageLength
                });
                this.conversations = result.items ? result.items : [];
                this.setListOptions();
            } catch (error) {
                console.error(error);
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        },

        async updateConversations() {
            this.isLoading = true;
            let result = await this._messageService.getConversations({
                page: this.pageNumber,
                pageLength: this.pageLength
            });
            this.isLoading = false;
            this.conversations = result.items ? this.conversations.concat(result.items) : this.conversations;
            this.setListOptions();
        },

        async conversationType() {
            await this.getConversations();

            // let conversationUsersType;
            // if (this.$router.currentRoute.value.meta.title == "Clinicians") {
            //     conversationUsersType = "clinicians";
            // } else {
            //     conversationUsersType = "patients";
            // }
        },

        unReadMessagesUpdate(conversationId: string) {
            let selectedConversationId = this.router.currentInstruction.params.id;
            if (conversationId != selectedConversationId) {
                this.conversations = this.conversations.map((conversation: IConversationItem) => {
                    if (conversation.id == conversationId) {
                        conversation.unread++;
                    }
                    return conversation;
                });
                this.setListOptions();
            }
        },

        updateNewConversation(value: boolean) {
            this.newConversation = value;
        },

        async pageNumberChanged(newValue: number, oldValue: number) {
            if (newValue > 1) {
                await this.updateConversations();
            }
        }
    }
});
