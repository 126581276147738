import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { IGetCityStateByZipcodeResponse } from "../../../../../resources-vue/vue-interfaces/i-get-physician";
import { LookupService } from "../../../../../services/lookup-service";

import ReferralAddressForm from "../../ReferralAddressForm/ReferralAddressForm.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection,
        ReferralAddressForm
    },

    data(vm) {
        return {
            _lookupService: null,
            v$: useVuelidate()
        };
    },

    validations() {
        return {
            referral: {
                Contacts: {
                    PrimaryEmergencyContact: {
                        FirstName: {
                            required: requiredIf(() =>
                                this.referral.Contacts.PrimaryEmergencyContact.FirstName?.length ? true : false
                            )
                        },
                        LastName: {
                            required: requiredIf(() =>
                                this.referral.Contacts.PrimaryEmergencyContact.FirstName?.length ? true : false
                            )
                        },
                        PrimaryPhone: {
                            required: requiredIf(() =>
                                this.referral.Contacts.PrimaryEmergencyContact.FirstName?.length ? true : false
                            )
                        }
                    }
                }
            }
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referralOptions: "referralOptions",
            accountId: "accountId",
            referral: "referral",
            errors: "errors",
            primaryCountyData: "primaryCountyData",
            primaryEmergency: "primaryEmergencyCountyData"
        }),
        isFieldRequired() {
            return this.referral.Contacts.PrimaryEmergencyContact.FirstName ? true : false;
        }
    },

    created() {
        this._lookupService = Container.instance.get(LookupService);
    },
    mounted() {
        if (!this.referral.Contacts.SameAsPatientAddress) {
            this.getPrimaryCountyData();
        }
    },

    watch: {
        primaryCountyData() {
            if (
                this.referral.Contacts.SameAsPatientAddress &&
                this.referral.Demographics.PrimaryAddress.Zip ===
                    this.referral.Contacts.PrimaryEmergencyContact.Address.Zip
            ) {
                this.setPrimaryEmergency(this.primaryCountyData);
            }
        }
    },

    methods: {
        ...mapActions(useEditReferralHH, { setPrimaryEmergency: "SET_PRIMARY_EMERGENCY", setErrors: "SET_NEW_ERRORS" }),
        async getPrimaryCountyData() {
            if (this.referral.Contacts.PrimaryEmergencyContact.Address.Zip) {
                const result: IGetCityStateByZipcodeResponse = await this._lookupService.getCityStateByZipcode({
                    zipCode: this.referral.Contacts.PrimaryEmergencyContact.Address.Zip,
                    agencyId: this.accountId,
                    application: this.application
                });
                const county: string[] = await this._lookupService.getCountyByZipcode(
                    this.referral.Contacts.PrimaryEmergencyContact.Address.Zip,
                    this.accountId
                );
                this.setPrimaryEmergency(county);
                this.referral.Contacts.PrimaryEmergencyContact.Address.County = county ? county[0] : null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.State = result.state
                    ? this.referralOptions.addressStateCode.find((state: any) => state.value === result.state)?.value
                    : null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.City = result.city ? result.city : null;
            }
        },
        async setSameAsPatient() {
            if (this.referral.Contacts.SameAsPatientAddress) {
                this.setPrimaryEmergency(this.primaryCountyData);
                this.referral.Contacts.PrimaryEmergencyContact.Address.AddressLine1 =
                    this.referral.Demographics.PrimaryAddress.AddressLine1;
                this.referral.Contacts.PrimaryEmergencyContact.Address.AddressLine2 =
                    this.referral.Demographics.PrimaryAddress.AddressLine2;
                this.referral.Contacts.PrimaryEmergencyContact.Address.City =
                    this.referral.Demographics.PrimaryAddress.City;
                this.referral.Contacts.PrimaryEmergencyContact.Address.County =
                    this.referral.Demographics.PrimaryAddress.County;
                this.referral.Contacts.PrimaryEmergencyContact.Address.State =
                    this.referral.Demographics.PrimaryAddress.State;
                this.referral.Contacts.PrimaryEmergencyContact.Address.Zip =
                    this.referral.Demographics.PrimaryAddress.Zip;
                this.referral.Contacts.PrimaryEmergencyContact.Address.ZipCodeFour =
                    this.referral.Demographics.PrimaryAddress.ZipCodeFour;
            } else {
                this.setPrimaryEmergency([]);
                this.referral.Contacts.PrimaryEmergencyContact.Address.AddressLine1 = null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.AddressLine2 = null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.City = null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.County = null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.State = null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.Zip = null;
                this.referral.Contacts.PrimaryEmergencyContact.Address.ZipCodeFour = null;
            }
        },
        handleZipChange() {
            this.referral.Contacts.SameAsPatientAddress = false;
            this.getPrimaryCountyData();
        },
        handleAllInputChange(field: string, value: any) {
            if (value.length) {
                const newErrors = [...new Set(this.errors.Contacts)].filter((val: any) => val !== field);
                const payload = {
                    property: "Contacts",
                    value: newErrors
                };
                this.setErrors(payload);
            }
        }
    }
});
