import { PropType, defineComponent } from "vue";

import { Container } from "aurelia-dependency-injection";

import { IReassignVisitDetails } from "../../vue-interfaces/i-reassign-visit";
import { IAvailableTaskItems, IGetTaskFrequencyResponse } from "../../vue-interfaces/i-patient-task-hos-pal";
import { TaskService } from "../../../services/task.service";
import moment from "moment";
import { Discipline } from "../../../enums/enums";
import { formatDate } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    props: {
        visitDetails: { type: Object as PropType<IReassignVisitDetails>, default: {} },
        visitDates: { type: Array, default: [] },
        selectedTask: { type: Object as PropType<IAvailableTaskItems>, default: {} },
        isOutsideFrequency: { type: Boolean, default: false }
    },

    emits: ["updateVisitDates", "updateOutsideFrequency"],

    data() {
        return {
            _taskService: null,
            availableFrequencies: [] as IGetTaskFrequencyResponse[],
            isError: false as boolean,
            isLoading: false as boolean,
            disciplineName: "" as string,
            formatDate
        };
    },

    async created() {
        this._taskService = Container.instance.get(TaskService);
        await this.checkFrequencyCompliance();
    },

    watch: {
        selectedTask: {
            async handler() {
                if (!this.selectedTask) {
                    return;
                }
                if (this.selectedTask.discipline) {
                    this.disciplineName = Discipline.getDescriptionUsingValue(this.selectedTask.discipline);
                } else {
                    this.disciplineName = "";
                }
                await this.checkFrequencyCompliance();
            },
            deep: true
        },

        visitDates: {
            async handler() {
                await this.checkFrequencyCompliance();
            },
            deep: true
        }
    },

    methods: {
        async checkFrequencyCompliance() {
            try {
                this.isError = false;
                this.isLoading = true;
                const hasStartDates = this.visitDates && this.visitDates.length > 0;
                const isTaskIncludedInFreq = this.selectedTask && this.selectedTask.isIncludedInFrequency;
                if (isTaskIncludedInFreq && hasStartDates) {
                    const response = await this._taskService.getTaskFrequency({
                        patientId: this.visitDetails.Id,
                        agencyId: this.visitDetails.AgencyId,
                        isPrn: this.selectedTask.isPrn,
                        discipline: this.selectedTask.discipline,
                        visitDates: this.visitDates,
                        application: this.visitDetails.Application
                    });
                    const withInFreqByDate = new Map<string, IGetTaskFrequencyResponse>();
                    const withinFreqDates: string[] = [];
                    response.forEach((item: any) => {
                        const mVisitDate = moment(item.visitDate).format("MM/DD/YYYY");
                        withInFreqByDate.set(mVisitDate, item);
                        withinFreqDates.push(mVisitDate);
                    });
                    this.availableFrequencies = this.visitDates.map((visitDate: any) => {
                        const visitOutsideFreq: IGetTaskFrequencyResponse = {
                            visitDate: null,
                            frequency: null,
                            frequencyId: null,
                            startDate: null,
                            endDate: null
                        };
                        if (withinFreqDates.includes(visitDate)) {
                            return withInFreqByDate.get(visitDate);
                        } else {
                            return Object.assign(visitOutsideFreq, { visitDate });
                        }
                    });
                    const updatedOutsideFrequency = this.availableFrequencies.some(
                        (availableFrequency) => !availableFrequency.frequency
                    );
                    this.$emit("updateOutsideFrequency", updatedOutsideFrequency);
                }
            } catch (e) {
                console.error(e);
                this.isError = true;
            } finally {
                this.isLoading = false;
            }
        },

        deleteVisitDate(visitDate: string) {
            const updatedDates = this.visitDates.filter((item) => item !== visitDate);
            this.$emit("updateVisitDates", updatedDates);
        }
    }
});
