import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";

import { EligibilityService } from "../../../services/eligibility-service";

import EligibilityPatientInfo from "./components/EligibilityPatientInfo.vue";
import EligiblityPayers from "./components/EligibilityPayers.vue";
import EligibilityHomeHealth from "./components/EligibilityHomeHealth.vue";
import EligibilityHospice from "./components/EligibilityHospice.vue";

export default defineComponent({
    components: {
        EligibilityPatientInfo,
        EligiblityPayers,
        EligibilityHomeHealth,
        EligibilityHospice
    },

    props: {
        firstName: {
            type: String,
            default: ""
        },
        lastName: {
            type: String,
            default: ""
        },
        gender: {
            type: Number
        },
        dateOfBirth: {
            type: String
        },
        locationId: {
            type: String
        },
        medicareNumber: {
            type: String
        },
        mbiNumber: {
            type: String
        },
        mbiNumberNa: {
            type: Boolean
        },
        medicareNumberNa: {
            type: Boolean
        },
        application: {
            type: Number
        },
        accountId: {
            type: String
        },
        missingUrl: {
            type: Object
        }
    },

    data(vm) {
        return {
            _eligibilityService: null,
            eligibilityNumber: "",
            eligibilityNA: false,
            isCheckingEligibility: false,
            eligibility: null,
            eligibilityQuery: null,
            isReportExpanded: true
        };
    },

    created() {
        this._eligibilityService = Container.instance.get(EligibilityService);
    },

    mounted() {
        this.eligibilityNumber = this.mbiNumber ? this.mbiNumber : this.medicareNumber;
        this.checkEligibility();
    },

    watch: {
        firstName() {
            if (this.eligibilityQuery && this.firstName != this.eligibilityQuery.firstName) {
                this.eligibility = null;
            }
        },
        lastName() {
            if (this.eligibilityQuery && this.lastName != this.eligibilityQuery.lastName) {
                this.eligibility = null;
            }
        },

        dateOfBirth() {
            if (this.eligibilityQuery && this.dateOfBirth != this.eligibilityQuery.dateOfBirth) {
                this.eligibility = null;
            }
        },

        locationId() {
            if (this.eligibilityQuery && this.locationId != this.eligibilityQuery.locationId) {
                this.eligibility = null;
            }
        },

        mbiNumber() {
            this.setEligibilityNumber();
        },

        medicareNumber() {
            this.setEligibilityNumber();
        },

        medicareNumberNa() {
            this.setEligibilityNumber();
        },

        mbiNumberNa() {
            this.setEligibilityNumber();
        }
    },

    methods: {
        async checkEligibility() {
            if (
                !(
                    this.firstName &&
                    this.lastName &&
                    this.gender &&
                    this.locationId &&
                    this.dateOfBirth &&
                    this.eligibilityNumber
                )
            ) {
                return;
            }
            try {
                this.isCheckingEligibility = true;
                this.eligibilityQuery = {
                    locationId: this.locationId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    gender: this.gender,
                    dateOfBirth: this.dateOfBirth,
                    medicareNumber: this.eligibilityNumber,
                    application: this.application,
                    accountId: this.accountId,
                    agencyId: this.accountId
                };
                this.eligibility = await this._eligibilityService.check(this.eligibilityQuery);
                this.sortBenefitPeriods();
                this.sortEpisodes();
                this.isCheckingEligibility = false;
            } catch (e) {
                this.isCheckingEligibility = false;
                this.eligibility = {
                    request_Result: {
                        success: "No",
                        response: "Server Error"
                    }
                };
            }
        },

        sortEpisodes() {
            if (this.eligibility) {
                if (this.eligibility.episode.success == "Yes") {
                    if (this.eligibility.episode.episodes && this.eligibility.episode.episodes.length > 0) {
                        this.eligibility.episode.episodes.sort((a: any, b: any) =>
                            moment(a.period_date_range?.split("-")[0]) < moment(b.period_date_range?.split("-")[0])
                                ? 1
                                : -1
                        );
                    }
                }
            }
        },

        sortBenefitPeriods() {
            if (this.eligibility) {
                if (this.eligibility.hospice.success == "Yes") {
                    if (this.eligibility.hospice.periods && this.eligibility.hospice.periods.length > 0) {
                        this.eligibility.hospice.periods.sort((a: any, b: any) =>
                            moment(a.period_date_range?.split("-")[0]) < moment(b.period_date_range?.split("-")[0])
                                ? 1
                                : -1
                        );
                    }
                }
            }
        },

        showReport() {
            this.isReportExpanded = true;
        },

        hideReport() {
            this.isReportExpanded = false;
        },

        setEligibilityNumber() {
            let result = null;
            this.eligibilityNA = false;
            if (this.medicareNumberNa && this.mbiNumberNa) {
                this.eligibilityQuery = null;
                this.eligibility = null;
                this.eligibilityNA = true;
                result = null;
            } else if (this.medicareNumberNa && !!this.mbiNumber) {
                result = this.mbiNumber;
            } else if (this.mbiNumberNa && !!this.medicareNumber) {
                result = this.medicareNumber;
            } else if (!this.medicareNumberNa && !this.mbiNumberNa) {
                result = !!this.mbiNumber ? this.mbiNumber : this.medicareNumber;
            }

            if (!!this.eligibilityQuery && this.eligibilityQuery.medicareNumber !== result) {
                this.eligibilityQuery = null;
                this.eligibility = null;
            }
            this.eligibilityNumber = result;
        }
    }
});
