import MessageCenter from "./MessageCenter.vue";
import ConversationListRoutes from "./message-center-routes";
import { RouteRecordRaw } from "vue-router";

const axxessMessagingLandingPage: Array<RouteRecordRaw> = [
    {
        path: "/central-messaging",
        component: MessageCenter,
        meta: {
            title: "Clinicians",
            nav: true,
            menuName: "Clinicians",
            navItem: "tabMessaging"
        },
        children: ConversationListRoutes
    }
];

export default axxessMessagingLandingPage;
