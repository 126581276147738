import { autoinject } from "aurelia-framework";

import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../enums/enums";
import type { IAgencyPermission } from "../../resources-vue/vue-interfaces/i-permissions";
import { PermissionsService } from "../../services/permissions.service";

@autoinject
export class PermissionManager {
    private _permissionService: PermissionsService;
    private _permissions: Map<string, Map<string, IAgencyPermission[]>> = new Map();

    public constructor(permissionService: PermissionsService) {
        this._permissionService = permissionService;
    }

    public get isPermissionManagerReady(): boolean {
        return this._permissions.size > 0;
    }

    public async refreshUserPermissionData() {
        try {
            const permission = await this._permissionService.getPermission();
            const parentPermissionMap: Map<string, Map<string, IAgencyPermission[]>> = new Map();
            for (const [key, value] of Object.entries(permission)) {
                let permissionActionMap: Map<string, IAgencyPermission[]> = new Map(Object.entries(value));
                parentPermissionMap.set(key, permissionActionMap);
            }
            this._permissions = parentPermissionMap;
            return this._permissions;
        } catch (e) {
            throw e;
        }
    }

    public checkPermission(resource: ParentPermissionEnum, action: PermissionActionEnum, agencyId?: string) {
        if (this.isPermissionManagerReady && this._permissions.has(resource)) {
            let permissionAvailable =
                this._permissions.get(resource).has(action) && this._permissions.get(resource).get(action).length > 0;
            if (!!agencyId && permissionAvailable) {
                permissionAvailable = this._permissions
                    .get(resource)
                    .get(action)
                    .some((agencyPermission: IAgencyPermission) => agencyPermission.agencyId === agencyId);
            }
            return permissionAvailable;
        }
        return false;
    }

    public checkPermissionByProduct(
        resource: ParentPermissionEnum,
        action: PermissionActionEnum,
        applicationId: ApplicationEnum,
        agencyId?: string
    ) {
        if (this.isPermissionManagerReady && this._permissions.has(resource)) {
            let permissionAvailable =
                this._permissions.get(resource).has(action) && this._permissions.get(resource).get(action).length > 0;
            if (!!agencyId) {
                if (permissionAvailable) {
                    permissionAvailable = this._permissions
                        .get(resource)
                        .get(action)
                        .some((agency: IAgencyPermission) => {
                            return agency.application == applicationId && agency.agencyId == agencyId;
                        });
                }
            } else {
                if (permissionAvailable) {
                    permissionAvailable = !!this._permissions
                        .get(resource)
                        .get(action)
                        .some((agency: IAgencyPermission) => {
                            return agency.application == applicationId;
                        });
                }
            }
            return permissionAvailable;
        }
        return false;
    }

    public getPermmission(resource: ParentPermissionEnum, action: PermissionActionEnum): IAgencyPermission[] {
        if (
            this.isPermissionManagerReady &&
            this._permissions.has(resource) &&
            this._permissions.get(resource).has(action)
        ) {
            return this._permissions.get(resource).get(action);
        }
        return [];
    }

    public checkHasAnyPermission(resource: ParentPermissionEnum) {
        if (!this.isPermissionManagerReady) {
            return false;
        }
        if (!this._permissions.has(resource)) {
            return false;
        }
        const allActions = Object.values(PermissionActionEnum);
        const hasAnyPermission = allActions.some((action) => {
            const hasAction = this._permissions.get(resource).get(action);
            if (hasAction) {
                return hasAction.length;
            }
            return true;
        });
        if (hasAnyPermission) {
            return hasAnyPermission;
        }
        return false;
    }
}
