import { inject } from "aurelia-dependency-injection";

import type { ILogOptions } from "../resources-vue/vue-interfaces/i-analytics";
import { AgencyService } from "../services/agency.service";

// TODO: Move all categories under this enum
export enum AnalyticsCategory {
    GetZip = "Get-Zip",
    GeoLocation = "Geolocation",
    HospiceMarketing = "Hospice-Marketing-Banner",
    CovidMarketing = "Covid-Marketing",
    LineOfServiceSurvey = "Line-of-Service-Survey"
}

@inject(AgencyService)
export class AnalyticsService {
    public agencyService: AgencyService;
    public handle: any = (window as any)._paq;

    public constructor(agencyService: AgencyService) {
        this.agencyService = agencyService;
    }

    public logEvent(options: ILogOptions): void {
        try {
            if (!this.agencyService.getIsAgencyCoreTestingUser() && !this.agencyService.getIsHomeCareTestingUser()) {
                let eventOptions = ["trackEvent", options.category, options.action];
                if (options.name) {
                    eventOptions.push(options.name);
                }
                if (options.value) {
                    eventOptions.push(options.value);
                }
                this.handle.push(eventOptions);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
