import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HospiceReferral } from "../../../../../resources-vue/vue-models/hospice-referral";
import { Patient } from "../../../../../resources-vue/vue-models/referral-patient";
import { ProvidersService } from "../../../../../services/providers-service";
import { IApplicationAndAccountQueryParam } from "../../../../../resources-vue/vue-interfaces/i-get-referral";
import { IPatientTags } from "../../../../../resources-vue/vue-interfaces/i-patient-tags";
import { IGetPatientTag } from "../../../../../resources-vue/vue-interfaces/i-providers-service";
import { sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";

import TagsInput from "../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";

export default defineComponent({
    components: {
        TagsInput
    },
    props: {
        patient: {
            type: HospiceReferral || Patient
        },
        providerId: {
            type: String,
            default: ""
        }
    },
    data(vm) {
        return {
            _providersService: null,
            selectedTags: [],
            patientTags: [],
            isTagsEnabled: false,
            tagsTooltip: `<strong>Title</strong>
            <p>Tags</p>
            <hr>
            <strong>Description</strong>
            <p>Add tags to categorize patients with specific characteristics. To add a tag, use the drop-down menu or search function.
            </p><hr>
            <strong>Intent</strong>
            <p>An unlimited number of tags can be assigned to each patient. For example, a patient can be assigned to the Team A tag and the Surgical Wound tag. If a user wants to view all patients in Team A with a surgical wound, the user can select Team A and Surgical Wound in the Tags filter to view patients who are assigned to both tags.
            </p><hr>
            <strong>Impact</strong>
            <p>Tags enable users to easily locate patients from anywhere that the Tags filter is available in the system (e.g., patient charts).</p>`,
            sanitizeMarkup
        };
    },
    created() {
        this._providersService = Container.instance.get(ProvidersService);
    },
    async mounted() {
        const operationSettingsQuery: IApplicationAndAccountQueryParam = {
            application: this.patient.application,
            accountId: this.patient.accountId
        };
        this.isTagsEnabled = true;
        const patientTagsQuery: IPatientTags = {
            providerIds: [this.providerId],
            isActive: true,
            application: this.patient.application,
            accountId: this.patient.accountId
        };
        let patientTags = await this._providersService.getActivePatientTags(patientTagsQuery);
        this.patientTags = patientTags.map((tag: IGetPatientTag) => ({
            name: tag.name,
            value: tag.id
        }));
        this.selectedTags = this.patient.tags?.map((tag) => ({ name: tag.name, value: tag.id }));
    },
    watch: {
        selectedTags() {
            if (!!this.patient) {
                this.patient.tagIds = this.selectedTags?.map((tags) => tags.value);
            }
        }
    },
    methods: {
        updateTags(newValue: any) {
            this.selectedTags = newValue;
        }
    }
});
