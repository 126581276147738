import { autoinject } from "aurelia-dependency-injection";
import { FetchClient } from "../common/fetch-client";
import type { IRoles } from "../resources-vue/vue-interfaces/i-roles";
import { ITitles } from "../resources-vue/vue-interfaces/i-titles";

@autoinject
export class RolesService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/meta";

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async getRoles(): Promise<Array<IRoles>> {
        return await this._fetchClient.build(`${this._baseUrl}/roles`).fetch<IRoles[]>();
    }

    public async getTitles(): Promise<Array<ITitles>> {
        return await this._fetchClient.build(`${this._baseUrl}/titles`).useMethod("POST").fetch<ITitles[]>();
    }
}
