import * as moment from "moment";
import { Moment } from "moment";

class MomentValueConverterClass {
    public dateOrStringToMoment(date: Date | string | Moment, format: string = "MM/DD/YYYY") {
        if (date) {
            if (moment.isMoment(date)) {
                return date;
            } else {
                return moment(date as any);
            }
        } else {
            return undefined;
        }
    }
}
export const MomentValueConverter = new MomentValueConverterClass();
