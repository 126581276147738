<template>
    <FormSection
        title="Additional Emergency Preparedness Information"
        type="his-section"
        :full-height="false"
        :application-id="Number(application)"
    >
        <template #content>
            <section class="d-flex flex-column px-3 py-2">
                <div class="d-flex flex-row">
                    <b>Template</b>
                    <a class="ml-auto" href="https://www.cdc.gov/coronavirus/2019-ncov/index.html/" target="_blank"
                        >COVID-19: CDC Resource Page</a
                    >
                </div>
                <TemplateInput
                    :templates="templates"
                    :application="application"
                    :accountId="accountId"
                    :result="
                        referral.EmergencyPreparedness.EmergencyPreparednessInfo
                            ? referral.EmergencyPreparedness.EmergencyPreparednessInfo
                            : ''
                    "
                    v-on:resultUpdated="handleResultUpdated"
                />
            </section>
        </template>
    </FormSection>
</template>

<script src="./AdditionalPrepInfo.ts"></script>
