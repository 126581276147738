<template>
    <div :class="`dropdown ${classes} mb-2`" ref="diagnosisListComponent">
        <div class="input-group">
            <input
                class="form-control"
                placeholder="ICD-10 Code"
                v-model="codeFilter"
                :disabled="disabled"
                v-if="codeValidation.required"
            />
            <input class="form-control" placeholder="ICD-10 Code" v-model="codeFilter" :disabled="disabled" v-else />
            <input
                class="form-control w-50"
                placeholder="ICD-10 Diagnosis Description"
                v-model="descFilter"
                :disabled="disabled"
                v-if="descValidation.required"
            />
            <input
                class="form-control w-50"
                placeholder="ICD-10 Diagnosis Description"
                v-model="descFilter"
                :disabled="disabled"
                v-else
            />
        </div>
        <div class="dropdown-menu list-group p-0 diagnosis-list" v-if="currentDiagnosisList.length > 0 || loading">
            <h6 class="dropdown-header" v-if="currentDiagnosisList.length == 0 && !loading">{{ noResultsText }}</h6>
            <h6 class="dropdown-header" v-if="loading">{{ loadingText }}</h6>
            <h6 v-if="parentDiagnosis.length > 0 && !loading" class="m-0 p-3">
                <a href="javascript:void(0)" @click="goInitState()"><i class="fas fa-home"></i></a>
                <i class="fas fa-angle-right"></i>
                <span v-for="(diagnosis, index) of parentDiagnosis">
                    <a href="javascript:void(0)" @click="goToDiagList($index, diagnosis)">{{ diagnosis.code }}</a>
                    <i class="fas fa-angle-right" v-if="!index === parentDiagnosis.length - 1"></i>
                </span>
            </h6>
            <a
                href="javascript:void(0)"
                v-for="diagnosis of currentDiagnosisList"
                class="diagnosis-item list-group-item dropdown-item d-flex"
                v-if="!loading"
                @click="selectDiagnosis(diagnosis)"
            >
                <span class="mr-auto">{{ diagnosis.formatCode }} {{ diagnosis.shortDescription }}</span>
                <i class="fas fa-angle-right mt-1 ml-2" v-if="!diagnosis.billable"></i>
            </a>
        </div>
    </div>
</template>

<style src="./Diagnosis.scss"></style>
<script src="./Diagnosis"></script>
