import { bindingMode, Disposable } from "aurelia-binding";
import { autoinject, bindable, BindingEngine, computedFrom, customElement } from "aurelia-framework";
import jquery from "jquery";
import nameOf from "../../common/name-of";
import "./multi-select-filter.scss";
import type { ITypeaheadOptions } from "../../interfaces/i-typeahead";

@customElement("multi-select-filter")
@autoinject
export class MultiSelectFilter {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public name: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public characterLimit: number = 0;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public fetch: (args: { filter: string }) => Promise<ITypeaheadOptions[]>;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public search: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedItems: ITypeaheadOptions[] = [];
    private _bindingEngine: BindingEngine;
    public dropdown: HTMLElement;
    public dropdownElement: HTMLElement;
    public fetchedItems: ITypeaheadOptions[] = [];
    public displayItems: ITypeaheadOptions[] = [];
    public suggestedItems: ITypeaheadOptions[] = [];
    public open: boolean = false;
    public selectedItemsSubscription: Disposable;
    public fetchedItemMatcher = (x: ITypeaheadOptions, y: ITypeaheadOptions) => x.value == y.value && x.name == y.name;
    public message: string = "";

    @computedFrom("search")
    public get isSearching() {
        return this.search;
    }

    @computedFrom(nameOf<MultiSelectFilter>("name"), nameOf<MultiSelectFilter>("placeholder"))
    public get inputPlaceholder() {
        if (this.placeholder) {
            return this.placeholder;
        } else {
            return `Search for ${this.name}...`;
        }
    }

    public constructor(bindingEngine: BindingEngine) {
        this._bindingEngine = bindingEngine;
    }

    public async attached() {
        this.initSelectedItemsSubscription();
        // Prevents the drop down from closing on inside click
        // But allow the close button to bleed the original handler.
        jquery(this.dropdownElement).on("click.bs.dropdown", (e) => {
            if (e.target.nodeName != "BUTTON") {
                e.stopPropagation();
            }
        });

        jquery(this.dropdown).on("show.bs.dropdown", (e) => {
            this.onDropdownOpen();
            this.open = true;
        });
        jquery(this.dropdown).on("hide.bs.dropdown", (e) => {
            this.open = false;
        });

        await this.onFetch();
    }

    public detached() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.dispose();
        }
    }

    public toggleSelection() {
        while (this.selectedItems.length > 0) {
            this.selectedItems.pop();
        }
    }

    public async onSearch(): Promise<void> {
        await this.onFetch();
    }

    private initSelectedItemsSubscription() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.dispose();
        }
        this.selectedItemsSubscription = this._bindingEngine
            .collectionObserver(this.selectedItems)
            .subscribe(() => this.selectedListUpdated());
    }

    private async onDropdownOpen() {
        this.search = "";
        this.displayItems = this.selectedItems.filter(() => true); // Copies array.
        this.suggestedItems = this.fetchedItems.filter(
            (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
        );

        await this.onFetch();
    }

    private async onFetch(): Promise<void> {
        if (this.characterLimit > 0 && this.search.length < this.characterLimit) {
            this.fetchedItems = [];
            this.suggestedItems = [];
        } else {
            let results = await this.fetch({
                filter: this.search
            });
            this.fetchedItems = results;
            this.suggestedItems = results.filter(
                (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
            );
        }
    }

    private selectedListUpdated() {
        // update displayItems and suggestedItems list if user unselect an item.
        if (this.displayItems.length > this.selectedItems.length) {
            let diffItems = this.displayItems.filter(
                (x) => this.selectedItems.find((y) => y.value == x.value && y.name == x.name) == null
            );
            this.displayItems = this.displayItems.filter(
                (x) => diffItems.find((y) => y.value == x.value && y.name == x.name) == null
            );
            for (let item of diffItems) {
                this.suggestedItems.push(item);
            }
        } else {
            let newItems = this.selectedItems.filter(
                (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
            );
            for (let item of newItems) {
                this.displayItems.push(item);
            }
        }
        this.suggestedItems = this.suggestedItems.filter(
            (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
        );
        this.updateMessage();
    }
    private updateMessage() {
        if (!this.selectedItems || this.selectedItems.length == 0) {
            this.message = "All Selected";
        }
        if (this.selectedItems.length == 1) {
            this.message = this.selectedItems[0].name;
        }
        if (this.selectedItems.length > 1) {
            this.message = `${this.selectedItems.length} Selected`;
        }
    }

    private selectedItemsChanged() {
        // Subscription is lost after binding
        this.initSelectedItemsSubscription();
        this.updateMessage();
    }
}
