import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum SmokingStatusEnum {
    CurrentEveryDaySmoker = 1,
    CurrentSomeDaySmoker = 2,
    FormerSmoker = 3,
    NeverSmoker = 4,
    SmokerCurrentStatusUnknown = 5,
    UnknownIfEverSmoked = 6,
    HeavyTobaccoSmoker = 7,
    LightTobaccoSmoker = 8
}
class SmokingStatusClass extends EnumWithDescription<SmokingStatusEnum> {
    public readonly enum = SmokingStatusEnum;
    public readonly enumName: string = "SmokingStatusEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<SmokingStatusEnum>> = new Map([
        [
            "CurrentEveryDaySmoker",
            {
                value: SmokingStatusEnum.CurrentEveryDaySmoker,
                name: "Current Every Day Smoker"
            }
        ],
        [
            "CurrentSomeDaySmoker",
            {
                value: SmokingStatusEnum.CurrentSomeDaySmoker,
                name: "Current Some Day Smoker"
            }
        ],
        [
            "FormerSmoker",
            {
                value: SmokingStatusEnum.FormerSmoker,
                name: "Former Smoker"
            }
        ],
        [
            "NeverSmoker",
            {
                value: SmokingStatusEnum.NeverSmoker,
                name: "Never Smoker"
            }
        ],
        [
            "SmokerCurrentStatusUnknown",
            {
                value: SmokingStatusEnum.SmokerCurrentStatusUnknown,
                name: "Smoker, Current Status Unknown"
            }
        ],
        [
            "UnknownIfEverSmoked",
            {
                value: SmokingStatusEnum.UnknownIfEverSmoked,
                name: "Unknown If Ever Smoked"
            }
        ],
        [
            "HeavyTobaccoSmoker",
            {
                value: SmokingStatusEnum.HeavyTobaccoSmoker,
                name: "Heavy Tobacco Smoker"
            }
        ],
        [
            "LightTobaccoSmoker",
            {
                value: SmokingStatusEnum.LightTobaccoSmoker,
                name: "Light Tobacco Smoker"
            }
        ]
    ]);
}

export const SmokingStatuses = new SmokingStatusClass();
