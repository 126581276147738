import { defineComponent, PropType } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { Container } from "aurelia-dependency-injection";

import { formatDateInput } from "../../../common/vue-helpers/modifiers/value-modifier";
import type { IBranchResponse } from "../../vue-interfaces/i-branch";
import type { IEnumResponse } from "../../vue-interfaces/i-enum";
import type { IUserResult } from "../../vue-interfaces/i-user";
import { UserService } from "../../../services/user-service";

import FormSection from "../../vue-custom-elements/FormSection/FormSection.vue";
import PhoneNumber from "../../vue-custom-elements/PhoneNumber/PhoneNumber.vue";
import MultiSelectDropdown from "../../vue-custom-elements/MultiSelectDropdown/MultiSelectDropdown.vue";
import DatePicker from "../../vue-custom-elements/DatePicker/DatePicker.vue";
import TypeaheadInput from "../../vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import CommentInput from "../../vue-custom-elements/CommentInput/CommentInput.vue";

export default defineComponent({
    components: {
        FormSection,
        PhoneNumber,
        MultiSelectDropdown,
        DatePicker,
        TypeaheadInput,
        CommentInput
    },

    validations() {
        return {
            inquiry: {
                firstName: { required },
                lastName: { required },
                phoneNumbers: [
                    {
                        number: { required }
                    }
                ],
                locationId: { required }
            }
        };
    },
    props: {
        inquiry: { type: Object, default: null },
        isLoading: { type: Boolean, default: false },
        branches: { type: Array as PropType<IBranchResponse[]>, default: null },
        serviceTypeEnums: { type: Array as PropType<IEnumResponse[]>, default: [] }
    },
    data() {
        return {
            formatDateInput,
            isPhoneNumberInValid: false,
            _userService: null,
            teamMember: null,
            data: [],
            openOnFocus: true,
            createComplete: false,
            v$: useVuelidate()
        };
    },
    async created() {
        if (this.inquiry?.assignedUser && this.inquiry?.assignedUserId) {
            this.teamMember = {
                name: this.inquiry.assignedUser,
                value: this.inquiry.assignedUserId
            };
        }
        this._userService = Container.instance.get(UserService);
        await this.$nextTick();
        this.createComplete = true;
    },
    watch: {
        teamMember: {
            handler(newValue) {
                if (newValue && this.createComplete) {
                    this.inquiry.assignedUserId = newValue.value as string;
                    this.inquiry.assignedUser = newValue.name as string;
                }
            },
            deep: true
        }
    },
    mounted() {
        this.$emit("updateBranch", this.inquiry.application);
    },
    methods: {
        countryUpdated(value: string[]) {
            let name = value[0];
            (this.inquiry as any)[name][0].country = value[1];
        },
        selectedBranch(branchId: string) {
            let branchDetail: IBranchResponse = null;
            if (this.branches.length > 0) {
                branchDetail = this.branches.find((branch) => branch.id === branchId);
            }
            this.inquiry.accountId = branchDetail.accountId;
        },
        selectedItemsUpdated(value: number) {
            if (this.inquiry.servicesRequested.includes(value)) {
                const index = this.inquiry.servicesRequested.indexOf(value);
                this.inquiry.servicesRequested.splice(index, 1);
                return;
            }
            this.inquiry.servicesRequested.push(value);
        },
        valueUpdated(value: string[]) {
            let name = value[0];
            (this.inquiry as any)[name][0].number = Number(value[1]);
        },
        typeUpdated(value: number[]) {
            let name = value[0];
            (this.inquiry as any)[name][0].type = Number(value[1]);
        },
        extensionUpdated(value: number[]) {
            let name = value[0];
            (this.inquiry as any)[name][0].extension = Number(value[1]);
        },
        resultUpdatedAppended(value: string) {
            this.inquiry.comment += value;
        },
        resultUpdated(value: string) {
            this.inquiry.comment = value;
        },
        async getTeamList(filter: string, limit: number) {
            return this.userToTypeahead(
                await this._userService.getUsersByRole({
                    include: true,
                    term: filter,
                    application: this.inquiry?.application
                })
            );
        },
        userToTypeahead(users: IUserResult[]) {
            return users.map((user) => ({
                name: `${user.lastName}, ${user.firstName}`,
                value: user.id
            }));
        },
        changeTeamMember(data: any) {
            this.teamMember = data;
        },
        changeOpenOnFocus(value: boolean) {
            this.openOnFocus = value;
        },
        changeDate(date: any, name: string) {
            this.inquiry[name] = date;
        }
    }
});
