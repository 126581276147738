import { PropType, computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import moment from "moment";

import type { IEmployeeList, IReassignVisitDetails } from "../../../vue-interfaces/i-reassign-visit";
import { VisitDetails } from "../../../../models/visit-details";
import { IFrequencyListRequestParams, IFrequencyListResponse, TaskService } from "../../../../services/task.service";
import type { ITaskDetailsPayload, INewVisitDetailsResponse } from "../../../vue-interfaces/i-task-details";
import { ToastrService } from "../../../../services/toastr.service";
import { ScheduleVisitHomeHealthModel } from "../../../vue-models/schedule-visit-home-health";
import { IDisciplineTaskHH, IGetDisciplineTaskHHParams } from "../../../vue-interfaces/i-discipline-task-hh";
import { LookupService } from "../../../../services/lookup-service";
import { IApplicationAndAccountQueryParam } from "../../../vue-interfaces/i-get-referral";
import { UserService } from "../../../../services/user-service";
import { IGetUsersByAgency } from "../../../vue-interfaces/i-user";
import { UserManager } from "oidc-client";
import { ICurrentEpisodeRequestParams, PatientService } from "../../../../services/patient.service";
import { ISpecialtyAxessCare } from "../../../vue-interfaces/i-visit-details";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import MultiDatePickerHH from "../../../vue-custom-elements/MultiDatePickerHH/MultiDatePickerHH.vue";
import PostAxxesscare from "../../../vue-dialogs/MultipleVisitAxxesscare/MultipleVisitAxxesscare.vue";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export interface IVisitOrders {
    discipline: string;
    frequency: string;
    effectiveDate: string;
}

export default defineComponent({
    components: {
        FormSection,
        MultiDatePickerHH
    },

    props: {
        visitDetails: { type: Object as PropType<IReassignVisitDetails>, default: {} },
        isLoading: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        cancel: { type: Function, default: null },
        ok: { type: Function, default: null },
        addAnother: { type: Function, default: null }
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            newVisitDetails: {
                disciplineTask: { required },
                discipline: { required: requiredIf(() => this.newVisitDetails.postToAxxessCare == true) },
                userId: { required }
            },
            visitDates: {
                required
            }
        };
    },

    data() {
        return {
            employeeList: [] as IEmployeeList[],
            _taskService: null,
            _toastrService: null,
            _userService: null,
            _lookupService: null,
            _patientService: null,
            _userManager: null,
            dialogRef: null,
            dialogReference: this.dialogRef as any,
            _user: null,
            taskDetails: {} as VisitDetails,
            isSavingReason: false as boolean,
            showAssignedToList: false as boolean,
            assignedToName: "",
            newVisitDetails: new ScheduleVisitHomeHealthModel(),
            disciplineTasks: [] as IDisciplineTaskHH[],
            disciplineTask: null as IDisciplineTaskHH,
            usersList: [] as IGetUsersByAgency[],
            SaveEnum: SaveEnum,
            currentUserId: "",
            currentEpisodeDetails: null,
            patientFrequencyList: [] as IFrequencyListResponse[],
            visitOrders: [] as IVisitOrders[],
            visitDates: "",
            specialties: [] as ISpecialtyAxessCare[],
            postToAxxessCare: false as boolean
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this._userService = Container.instance.get(UserService);
        this._lookupService = Container.instance.get(LookupService);
        this._patientService = Container.instance.get(PatientService);
        this._userManager = Container.instance.get(UserManager);
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    mounted() {
        this.getVisitDetails();
    },

    watch: {
        visitDateChanged() {
            this.visitDatesChanged();
        }
    },

    methods: {
        async getVisitDetails() {
            Object.assign(this.visitDetails, {});
            try {
                this.$emit("loadingChange", true);
                const payload: ITaskDetailsPayload = {
                    application: this.visitDetails.Application,
                    agencyId: this.visitDetails.AgencyId,
                    eventId: this.visitDetails.Id,
                    episodeId: this.visitDetails.EpisodeId,
                    patientId: this.visitDetails.PatientId
                };
                const visitDetails = await this._taskService.getHHVisitDetails(payload);
                this.newVisitDetails.agencyId = visitDetails.AgencyId;
                this.newVisitDetails.application = visitDetails.Application;
                this.newVisitDetails.disciplineTask = null;
                this.newVisitDetails.userId = null;

                await this.getDisciplineTasks();
                await this.getUsersList();
                await this.getCurrentEpisode();
                await this.getPatientFrequencyList();
                await this.getSpeciality();
                this.currentUserId = (
                    await this._userService.getCurrentUserId({ agencyId: this.visitDetails.AgencyId })
                ).userId;
            } catch (error) {
                console.log(error);
            } finally {
                this.$emit("loadingChange", false);
            }
        },

        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : "";
        },

        async assignToMe() {
            if (!!this.currentUserId) this.newVisitDetails.userId = this.currentUserId;
        },

        async employeeChanged(userId: string) {
            this.newVisitDetails.userId = userId;
        },

        async getSpeciality() {
            try {
                this.specialties = await this._taskService.getAxxessCareSpecialties({
                    agencyId: this.visitDetails.AgencyId
                });
            } catch {
                this._toastrService.error({
                    title: `Fetching Task Type Failed`,
                    message: `Fetching Task Type Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getDisciplineTasks() {
            try {
                const payload: IGetDisciplineTaskHHParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application
                };
                this.disciplineTasks = await this._lookupService.getDisciplineTasks(payload);
            } catch (e) {
                this._toastrService.error({
                    title: `Fetching Visit Type Failed`,
                    message: `Fetching Visit Type Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        onVisitTypeChange(discipline: IDisciplineTaskHH) {
            this.newVisitDetails.customId = discipline.customId;
            this.newVisitDetails.disciplineTask = discipline.disciplineTask;
        },

        onTaskTypeChange(discipline: any) {
            this.newVisitDetails.discipline = discipline;
        },

        async getPatientFrequencyList() {
            try {
                const payload: IFrequencyListRequestParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application,
                    patientId: this.visitDetails.Id,
                    episodeId: this.currentEpisodeDetails?.id,
                    episodeStartDate: this.currentEpisodeDetails?.startDate
                };
                this.patientFrequencyList = await this._taskService.getFrequencyList(payload);

                this.patientFrequencyList.map((patientFrequency) => {
                    let visitOrder: IVisitOrders = null;
                    if (patientFrequency.frequencyHHA) {
                        visitOrder = {
                            discipline: "HHA",
                            frequency: patientFrequency.frequencyHHA,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyMSW) {
                        visitOrder = {
                            discipline: "MSW",
                            frequency: patientFrequency.frequencyMSW,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyNursing) {
                        visitOrder = {
                            discipline: "SN",
                            frequency: patientFrequency.frequencyNursing,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyOT) {
                        visitOrder = {
                            discipline: "OT",
                            frequency: patientFrequency.frequencyOT,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyPT) {
                        visitOrder = {
                            discipline: "PT",
                            frequency: patientFrequency.frequencyPT,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }
                    if (patientFrequency.frequencyST) {
                        visitOrder = {
                            discipline: "ST",
                            frequency: patientFrequency.frequencyST,
                            effectiveDate: moment(patientFrequency.startDate).format("MM/DD/YYYY")
                        };
                        this.visitOrders.push(visitOrder);
                    }

                    this.visitOrders = this.visitOrders.reduce((acc, curr) => {
                        const existingEntry = acc.find((item) => item.discipline === curr.discipline);
                        if (existingEntry) {
                            existingEntry.frequency += ` \n${curr.frequency}`;
                            existingEntry.effectiveDate += ` \n${curr.effectiveDate}`;
                        } else {
                            acc.push(curr);
                        }
                        return acc;
                    }, []);

                    return this.visitOrders;
                });
            } catch (e) {
                this._toastrService.error({
                    title: `Fetching Frequency List Failed`,
                    message: `Fetching Frequency List Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getCurrentEpisode() {
            try {
                const payload: ICurrentEpisodeRequestParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application,
                    patientId: this.visitDetails.Id
                };
                this.currentEpisodeDetails = await this._patientService.getCurrentEpisode(payload);
                this.newVisitDetails.episodeId = this.currentEpisodeDetails.id;
            } catch (e) {
                this._toastrService.error({
                    title: `Fetching Current Episode Failed`,
                    message: `Fetching Current Episode Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getUsersList() {
            try {
                const payload: IApplicationAndAccountQueryParam = {
                    agencyId: this.visitDetails.AgencyId,
                    application: this.visitDetails.Application
                };
                this.usersList = await this._userService.getUsersByAgency(payload);
            } catch (e) {
                this._toastrService.error({
                    title: `Fetching Users List Failed`,
                    message: `Fetching Users List Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        visitDatesChanged() {
            if (this.visitDates) {
                this.newVisitDetails.visitDates = this.visitDates.split(", ");
            } else {
                this.newVisitDetails.visitDates = [];
            }
        },

        onPostChange(e: any) {
            const val = e.target.checked;
            this.newVisitDetails.postToAxxessCare = val;
        },

        updateResult(result: any) {
            this.visitDates = result;
        },

        async scheduleVisit(saveType: string) {
            const valid = await this.v$.$validate();
            if (!valid) {
                return;
            }
            if (this.newVisitDetails.postToAxxessCare) {
                this.visitDatesChanged();
                let temp: any = { ...this.newVisitDetails };
                temp.PatientId = this.visitDetails.Id;
                let disciplineTask = this.disciplineTasks.find(
                    (task: any) =>
                        task.customId === this.newVisitDetails.customId &&
                        task.disciplineTask === this.newVisitDetails.disciplineTask
                );
                temp.visitType = disciplineTask.taskName;
                temp.customId = this.newVisitDetails.customId;
                temp.DisciplineTaskId = disciplineTask.disciplineTask;
                temp.discipline = disciplineTask.discipline;
                temp.agencyId = this.visitDetails.AgencyId;
                temp.application = this.visitDetails.Application;
                temp.specialityName = this.specialties.find(
                    (task: any) => task.id === this.newVisitDetails.discipline
                ).name;
                temp.specialityId = this.newVisitDetails.discipline;
                temp.eventId = this.visitDetails.Id;
                temp.episodeId = this.newVisitDetails.episodeId;
                temp.id = this.visitDetails.Id;
                temp.visitType = this.disciplineTasks.find(
                    (task: any) =>
                        task.customId === this.newVisitDetails.customId &&
                        task.disciplineTask === this.newVisitDetails.disciplineTask
                ).taskName;

                let data = {
                    visitDetails: temp,
                    taskDetails: this.taskDetails
                };

                this.dialogRef = await this.$dialog.open(PostAxxesscare, {
                    props: {
                        modal: true,
                        showHeader: false
                    },

                    data: {
                        visitDetails: data.visitDetails,
                        taskDetails: data.taskDetails
                    },

                    onClose: (options) => {
                        if (options.data == "cancel") {
                            return;
                        } else {
                            if (saveType == SaveEnum.SaveAndAddAnother) {
                                this.newVisitDetails.disciplineTask = null;
                                this.disciplineTask = null;
                                this.newVisitDetails.userId = null;
                                this.newVisitDetails.visitDates = [];
                                this.newVisitDetails.discipline = null;
                                this.visitDates = "";
                            }

                            if (saveType == SaveEnum.SaveAndExit) {
                                this.ok();
                            }
                        }
                    }
                });
            } else {
                if (!!this.newVisitDetails) {
                    try {
                        this.visitDatesChanged();
                        this.$emit("savingChange", true);
                        this.newVisitDetails.patientId = this.visitDetails.Id;
                        this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
                        this.newVisitDetails.application = this.visitDetails.Application;
                        const newVisitDetailsResp: INewVisitDetailsResponse = await this._taskService.scheduleVisitHH(
                            this.newVisitDetails
                        );
                        const isWarning = newVisitDetailsResp.Message.includes("Warning");
                        const respMessage = {
                            title: `Visit Scheduled`,
                            message: newVisitDetailsResp.Message
                        };
                        if (isWarning) {
                            this._toastrService.warning(respMessage);
                        } else {
                            this._toastrService.success(respMessage);
                        }
                        if (saveType == SaveEnum.SaveAndExit) {
                            this.ok();
                        } else {
                            this.newVisitDetails.disciplineTask = null;
                            this.disciplineTask = null;
                            this.newVisitDetails.userId = null;
                            this.newVisitDetails.visitDates = [];
                            this.visitDates = "";
                        }
                    } catch (e) {
                        this._toastrService.error({
                            title: `Visit Scheduled Failed`,
                            message: `Scheduling Visit Operation Failed, Please Contact Axxess if issue still persists.`
                        });
                    } finally {
                        this.$emit("savingChange", false);
                    }
                }
            }
        }
    }
});
