import { defineComponent, computed } from "vue";
import { Container } from "aurelia-dependency-injection";

import { PhysiciansService } from "../../../../../services/physician-service";
import { ToastrService } from "../../../../../services/toastr.service";
import { PhysicianRequired } from "../../../../../resources-vue/vue-models/physician-required";
import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import { type IPromptOptions } from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt";

import EmptyTextHandler from "../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import Prompt from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";

export default defineComponent({
    components: {
        EmptyTextHandler
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },
    props: {
        physician: { type: Object, default: new PhysicianRequired() },
        referral: { type: HomeCareReferral, default: null },
        referralId: { type: String, default: null },
        refreshList: { type: Function, default: null }
    },
    data() {
        return {
            dialogRef: null,
            _physicianService: null,
            _toastrService: null
        };
    },
    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
        this._toastrService = Container.instance.get(ToastrService);
    },
    methods: {
        async deletePhysician(physicianId: string) {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to remove this physician?",
                okText: "Yes",
                cancelText: "No"
            };

            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            let response = await this._physicianService.deletePhysician({
                                application: this.referral.application,
                                referralId: this.referralId,
                                agencyId: this.referral.accountId,
                                physicianId: physicianId
                            });
                            if (response.data.IsSuccessful) {
                                this.refreshList();
                                this._toastrService.success({
                                    title: "Success",
                                    message: "The Physician was removed successfully."
                                });
                            } else {
                                this._toastrService.error({
                                    title: "Error",
                                    message:
                                        "Since the physician is primary unable to delete physician. Mark other physician as primary then delete this one."
                                });
                            }
                        } catch (e) {
                            this._toastrService.error({
                                title: "Error",
                                message: "There was a problem while deleting a physician. Please try again."
                            });
                        }
                    }
                }
            });
        },
        async makePrimary(physicianId: string) {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to set this physician as primary?",
                okText: "Yes",
                cancelText: "No"
            };

            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            await this._physicianService.setPrimaryPhysician({
                                application: this.referral.application,
                                referralId: this.referralId,
                                agencyId: this.referral.accountId,
                                physicianId: physicianId
                            });
                            this.refreshList();
                            this._toastrService.success({
                                title: "Success",
                                message: "The Physician was successfully set as primary."
                            });
                        } catch (e) {
                            this._toastrService.error({
                                title: "Error",
                                message: "There was a problem while a physician as primary. Please try again."
                            });
                        }
                    }
                }
            });
        },
        async makeReferral(physicianId: string) {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to set this physician as referral?",
                okText: "Yes",
                cancelText: "No"
            };
            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            await this._physicianService.setPrimaryReferral({
                                application: this.referral.application,
                                referralId: this.referralId,
                                agencyId: this.referral.accountId,
                                physicianId: physicianId
                            });
                            this.refreshList();
                            this._toastrService.success({
                                title: "Success",
                                message: "The Physician was successfully set as referral"
                            });
                        } catch (e) {
                            this._toastrService.error({
                                title: "Error",
                                message: "There was a problem while making a physician as referral. Please try again."
                            });
                        }
                    }
                }
            });
        }
    }
});
