import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    data() {
        return {
            Options: [
                {
                    label: "N/A (no legal/patient-selected representative)",
                    value: 1
                },
                {
                    label: "Legal Representative contacted regarding admission",
                    value: 2
                },
                {
                    label: "Patient request to decline notice of rights to Patient- Selected Representative",
                    value: 3
                },
                {
                    label: "Patient-Selected Representative contacted regarding admission",
                    value: 4
                },
                {
                    label: "Legal Representative not in agreement with admission",
                    value: 5
                }
            ],
            LegalReprentativeOptions: [
                {
                    label: "Contacted and will be available for admission visit to receive written notice in advance of care",
                    value: 1
                },
                {
                    label: "In agreement with need for care, but not available for admission visit (if this person has healthcare decision making authority, the HHA must provide notice of the patient's rights prior to initiating care. May obtain electronic or digital signature)",
                    value: 2
                },
                {
                    label: "Other",
                    value: 3
                }
            ],
            PatientSelectedRepresentativeOptions: [
                {
                    label: "Contacted and will be available for admission visit to receive written notice in advance of care",
                    value: 1
                },
                {
                    label: "Sent copy, as requested, of notice of rights, transfer and DC policies provided by mail or other electronic means (to be received within 4 days)",
                    value: 2
                },
                {
                    label: "Other",
                    value: 3
                }
            ],
            LegalRepresentativeNotInAgreementOptions: [
                {
                    label: "Physician notified",
                    value: 1
                },
                {
                    label: "Patient notified",
                    value: 2
                }
            ]
        };
    },

    computed: {
        ...mapState(useEditReferralHH, {
            application: "currentApplication",
            referral: "referral",
            referralOptions: "referralOptions"
        })
    },

    methods: {
        handlePatientRepresentative(value: number) {
            if (this.referral.Contacts.PatientRepresentative === value) {
                this.referral.Contacts.PatientRepresentative = 0;
            } else {
                this.referral.Contacts.PatientRepresentative = value;
            }

            if (
                this.referral.Contacts.PatientRepresentative !== 2 &&
                this.referral.Contacts.LegalRepresentative !== 0
            ) {
                this.referral.Contacts.LegalRepresentative = 0;
            }

            if (
                this.referral.Contacts.PatientRepresentative !== 4 &&
                this.referral.Contacts.PatientSelectedRepresentative !== 0
            ) {
                this.referral.Contacts.PatientSelectedRepresentative = 0;
            }
            if (
                this.referral.Contacts.PatientRepresentative !== 5 &&
                this.referral.Contacts.LegalRepresentativeNotInAgreement !== 0
            ) {
                this.referral.Contacts.LegalRepresentativeNotInAgreement = 0;
            }
        },
        handleLegalRepresentative(value: number) {
            this.referral.Contacts.LegalRepresentative = value;
        },
        handlePatientSelectedRepresentative(value: number) {
            this.referral.Contacts.PatientSelectedRepresentative = value;
        },
        handleNotInAgreement(value: number) {
            this.referral.Contacts.LegalRepresentativeNotInAgreement = value;
        }
    }
});
