import { TestingAgenciesEnum } from "../../enums/enums";
import { IBranchResponse } from "../../interfaces/i-branch";
import { BranchesService } from "../../services/branches-service";
import { Container } from "aurelia-framework";
import { createVueApp } from "./vue-create-app";
import App from "../../pages-vue/App/App.vue";
import { UserManager } from "oidc-client";

const vueTestingUserEmails = [
    "psingh@axxess.com",
    "ptale@axxess.com",
    "ryeole@axxess.com",
    // "sgupta@axxess.com",
    "skamareddi@axxess.com",
    "didumu@axxess.com",
    "tpn3k@yahoo.com",
    "asrivastav@axxess.com",
    // "tpn3k@msn.com",
    "asawant@axxess.com",
    "opote@axxess.com",
    "rreddy@axxess.com",
    "qatesterfp@gmail.com",
    "draut@axxess.com",
    "qatesteraxxess@yahoo.com",
    "skipfk2@gmail.com",
    "qatesterfp@gmail.com",
    "eigbinoba@axxess.com",
    // "sinamdar@axxess.com",
    "tejaswini.badgujar@yahoo.com",
    "axxesscentraltester@gmail.com",
    "axxesscareacc@gmail.com"
];

export class TestingAgencyHelper {
    private testingAgecnyIdList: string[] = Object.values(TestingAgenciesEnum);
    private userBranches: IBranchResponse[] = [];
    private _branchService: BranchesService;
    private isTestingCheckEnabled: boolean = true;
    private static hasTestingAgency: boolean = null;
    public static isVueAppMounted: boolean = false;

    public constructor() {
        this._branchService = Container.instance.get(BranchesService);
    }

    private async getBranches() {
        try {
            this.userBranches = await this._branchService.getAllBranches();
        } catch (error) {
            console.warn(error?.message);
            this.userBranches = [];
        }
    }

    public async checkHasTestingAgency(): Promise<boolean> {
        try {
            if (!this.isTestingCheckEnabled) {
                return true;
            }
            if (TestingAgencyHelper.hasTestingAgency != null) {
                return TestingAgencyHelper.hasTestingAgency;
            }
            if (!this.testingAgecnyIdList.length) {
                console.warn("No testing Agency Id found");
                return true;
            }
            if (!this.userBranches.length) {
                await this.getBranches();
            }

            TestingAgencyHelper.hasTestingAgency = this.userBranches.some((branch) =>
                this.testingAgecnyIdList.includes(branch.accountId)
            );
            return TestingAgencyHelper.hasTestingAgency || false;
        } catch (error) {
            console.warn(error?.message);
            return false;
        }
    }

    public async checkIsTestingAgencyId(agencyId: string): Promise<boolean> {
        if (!this.isTestingCheckEnabled) {
            return true;
        }
        if (!this.testingAgecnyIdList.length) {
            console.warn("No testing Agency Id found");
            return true;
        }
        return this.testingAgecnyIdList.includes(agencyId);
    }

    public async mountVueApp(): Promise<boolean> {
        if (TestingAgencyHelper.isVueAppMounted) {
            return false;
        }
        try {
            const vueAppBox = document.querySelector("#vue-app");
            if (vueAppBox) {
                createVueApp(App).mount(vueAppBox);
                TestingAgencyHelper.isVueAppMounted = true;
                console.info("Vue App Mpounted");
                return true;
            } else {
                throw new Error("Vue App Container not found");
            }
        } catch (error) {
            console.error(error?.message, "error while starting vue app");
            return false;
        }
    }

    public async shouldMountVue() {
        const _userManager = Container.instance.get(UserManager);
        const user = await _userManager.getUser();

        if (
            !user?.expired &&
            user?.profile?.email &&
            vueTestingUserEmails?.length &&
            vueTestingUserEmails.includes(user.profile.email)
        ) {
            return true;
        } else {
            if (!user?.profile?.email) {
                console.info("user was not loaded");
            }
            return false;
        }
    }
}

export const helperTestingAgency = new TestingAgencyHelper();
