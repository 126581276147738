export const mockConversations = {
    itemCount: 3,
    pageLength: 20,
    currentPage: 1,
    pageCount: 1,
    items: [
        {
            id: "3a03c204-0ce3-abc7-d222-f2df80459d4b",
            title: null,
            text: "Schedule Change",
            unread: 0,
            createdOn: "2023-09-18T18:37:17" as any,
            modifiedOn: "2023-09-18T18:37:17" as any,
            users: [
                {
                    userId: "811fca15-21d0-11ea-aa24-f8f21e215640",
                    firstName: "Axxess",
                    lastName: null
                }
            ],
            isAxxessConversation: true
        },
        {
            id: "3a0dab07-0e29-8494-0284-b4897248a2aa",
            title: "",
            text: ".",
            unread: 0,
            createdOn: "2023-09-15T12:40:51" as any,
            modifiedOn: "2023-09-15T12:40:51" as any,
            users: [
                {
                    userId: "3a064e79-2f70-d97d-ed51-6e9bbc4895bb",
                    firstName: "Rahul",
                    lastName: "Sharma"
                }
            ],
            isAxxessConversation: false
        },
        {
            id: "3a04b84b-f255-f6c1-ba13-faf1264fb0fd",
            title: null,
            text: "Test",
            unread: 0,
            createdOn: "2023-01-13T04:37:27" as any,
            modifiedOn: "2023-01-13T04:37:27" as any,
            users: [
                {
                    userId: "3a047539-7e8a-1ac6-1bbf-545b764eab80",
                    firstName: "Amanda",
                    lastName: "Powell"
                }
            ],
            isAxxessConversation: false
        }
    ]
};
export const mockCurrentUser = {
    id: "3a03c1fe-5385-55ff-531d-34c57d48809a",
    firstName: "Tim_Msn",
    lastName: "Nguyen",
    email: "tpn3k@msn.com",
    isSupportUser: false
};

export const mockMessages = {
    itemCount: 2,
    pageLength: 50,
    currentPage: 1,
    pageCount: 1,
    items: [
        {
            id: "3a08bb97-f2ac-0abf-b571-8ab8aa43abf1",
            text: "Test",
            isRead: true,
            isFromSelf: false,
            isImportant: false,
            user: {
                userId: "3a047539-7e8a-1ac6-1bbf-545b764eab80",
                firstName: "Amanda",
                lastName: "Powell"
            },
            assets: [] as any[],
            createdOn: "2023-01-13T04:37:27" as any,
            modifiedOn: "2023-01-13T04:37:27" as any
        },
        {
            id: "3a04b84b-f255-b869-fb14-72e85247af47",
            text: "1.  **Regarding [Hospice Aide Visit](/note/3a045ca8-3a18-c3aa-2e91-fcd75440b7c4) for [Admission, Test](/patients/patient-chart/3a008dad-5bd6-cc68-da83-2f04974fbb57?status=1)**, Why are you playing in Hospice lol just kidding",
            isRead: true,
            isFromSelf: false,
            isImportant: false,
            user: {
                userId: "3a047539-7e8a-1ac6-1bbf-545b764eab80",
                firstName: "Amanda",
                lastName: "Powell"
            },
            createdOn: "2022-06-27T17:04:25" as any,
            modifiedOn: "2022-06-27T17:04:25" as any
        }
    ]
};

export const mockDeletedMessages = {
    itemCount: 1,
    pageLength: 50,
    currentPage: 1,
    pageCount: 1,
    items: [
        {
            id: "3a0dbbc1-f58e-93c3-7181-a2daaa4296a6",
            text: "A new task has been assigned to you. To view the task, select the task name below. To view the patient's chart, select the patient's name. <br /><br />Patient Name: <a href='/patients/patient-chart/3a003c75-bff9-82a6-6262-de81de4da4c3?branchIds=81e63c0c-2ded-4fd0-a685-349d0b010e6a' target='_self'>AArdvark, Kalu</a><br />Task: <a href='/note/3a0dbbc1-f485-f231-8be7-cb8b7a47894f' target='_self'>Care Coordination Note</a><br />Task Date: 09/18/2023<br /><br />The Axxess Team",
            isRead: true,
            isFromSelf: false,
            isImportant: false,
            user: {
                userId: "811fca15-21d0-11ea-aa24-f8f21e215640",
                firstName: "Axxess",
                lastName: ""
            },
            createdOn: "2023-09-18T18:37:17" as any,
            modifiedOn: "2023-09-18T18:37:17" as any
        }
    ]
};

export const mockUsersList = {
    itemCount: 18,
    pageLength: 10,
    currentPage: 1,
    pageCount: 2,
    items: [
        {
            id: "39f8821a-18a6-0494-25e4-720fff4c99c0",
            firstName: "Abdul",
            lastName: "MD Test",
            title: 8,
            credentials: ["2", "16"],
            assetId: "3a022cf3-0b31-db9e-5284-7bedd4498623",
            emailAddress: "abaute@axxess.com",
            userType: 1,
            status: "1"
        },
        {
            id: "39f3e706-0c33-7716-f8fa-8f6b144a8e1f",
            firstName: "Axxess",
            lastName: "Test",
            title: 4,
            credentials: ["32", "64", "4611686018427387904"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "axxessuser@gmail.com",
            userType: 2,
            status: "1"
        },
        {
            id: "39f840b1-00a7-3275-3f2e-21c8844e8331",
            firstName: "Axxess",
            lastName: "Test5",
            title: 31,
            credentials: ["2"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "axxesstest5@gmail.com",
            userType: 1,
            status: "1"
        },
        {
            id: "39fb0783-0590-5133-3e81-cea5b145b75a",
            firstName: "Axxessvendor",
            lastName: "Test",
            title: 31,
            credentials: ["2"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "Axxess.vendor@heartofhospice.net",
            userType: 1,
            status: " 1"
        },
        {
            id: "39f93b29-d67c-4e34-6a80-cdd07c4e8a03",
            firstName: "CC",
            lastName: "Test",
            title: 31,
            credentials: ["2"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "melva.porteous@utsouthwestern.edu",
            userType: 1,
            status: "1"
        },
        {
            id: "39f8c006-5c05-66af-7b68-fca0e148a81b",
            firstName: "CHARLES",
            lastName: "WREN",
            title: 8,
            credentials: ["16"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "absqatest@mailinator.com",
            userType: 1,
            status: "1"
        },
        {
            id: "39f07798-b30b-442a-2660-f21c134dae3a",
            firstName: "Identityonly",
            lastName: "Test",
            title: 4,
            credentials: [],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "1d3nt1tyRCMT3st@gmail.com",
            userType: 1,
            status: "1"
        },
        {
            id: "3a00dc72-90b2-8130-74de-4c6f8241a7d0",
            firstName: "Infrastructure Testing Rn",
            lastName: "Testing",
            title: 4,
            credentials: ["2"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "infrastructure@axxess.com",
            userType: 1,
            status: "1"
        },
        {
            id: "39fc2478-d88b-2530-10a1-c9023d47917e",
            firstName: "Jenny",
            lastName: "Ortiz",
            title: 2,
            credentials: ["4"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "qaprodtest@mailinator.com",
            userType: 1,
            status: "1"
        },
        {
            id: "39f3d307-6596-709a-e8ac-a7ae0546967f",
            firstName: "PhysicianFirstTest",
            lastName: "PhysicianLastTest",
            title: 4,
            credentials: ["2", "16"],
            assetId: "00000000-0000-0000-0000-000000000000",
            emailAddress: "kodufuye@axxess.com",
            userType: 1,
            status: "1"
        }
    ]
};
