import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import type {
    IGetServicesRequired,
    IGetServicesRequiredResult
} from "../../../../../resources-vue/vue-interfaces/i-get-referral";
import { ReferralService } from "../../../../../services/referral-service";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },
    props: {
        referral: { type: HomeCareReferral, default: null }
    },
    data() {
        return {
            servicesRequired: [] as IGetServicesRequiredResult[],
            isMedical: false,
            _referralService: null,
            isLoading: true
        };
    },
    created() {
        this._referralService = Container.instance.get(ReferralService);
    },
    async mounted() {
        const serviceRequiredQuery: IGetServicesRequired = {
            application: this.referral.application,
            agencyId: this.referral.accountId,
            type: this.referral.isNonMedical ? 2 : 1
        };
        this.isLoading = true;
        this.servicesRequired = await this._referralService.getServicesRequired(serviceRequiredQuery);
        if (this.servicesRequired) {
            this.isLoading = false;
        }
    },
    computed: {
        serviceRequiredCollection() {
            return !this.referral.isNonMedical
                ? this.referral.servicesRequiredCollection
                : this.referral.nonMedicalServicesRequiredCollection;
        },
        hasOtherServiceRequired() {
            return this.referral[
                !this.referral.isNonMedical ? "otherServiceRequired" : "nonMedicalOtherServiceRequired"
            ];
        }
    },
    methods: {
        async clearOtherServices(event: any) {
            let value;
            if (event.target.value == "false") {
                value = false;
            } else {
                value = true;
            }
            this.$emit("updateHasOtherServicesRequired", value);
            await this.$nextTick();
            if (!value) {
                this.referral.isNonMedical
                    ? this.$emit("updateNonMedicalOtherServiceRequired", null)
                    : this.$emit("updateOtherServiceRequired", null);
            }
        },
        updateServiceRequiredCollection(event: any) {
            const value = event.target.value;
            !this.referral.isNonMedical
                ? this.$emit("updateServicesRequiredCollection", value)
                : this.$emit("updateNonMedicalServicesRequiredCollection", value);
        },
        handleOtherServiceRequired(event: any) {
            const value = event.target.value;
            if (!this.referral.isNonMedical) {
                this.referral.otherServiceRequired = value;
            } else {
                this.referral.nonMedicalOtherServiceRequired = value;
            }
        }
    }
});
