import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum TaskRepeatEnum {
    DoesNotRepeat = 0,
    Weekly = 1,
    Biweekly = 2,
    Flexible = 3,
    Monthly = 4
}

export class TaskRepeatEnumClass extends EnumWithDescription<TaskRepeatEnum> {
    public readonly enum = TaskRepeatEnum;
    public readonly enumName: string = "TaskRepeat";
    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<TaskRepeatEnum>> = new Map([
        [
            "DoesNotRepeat",
            {
                value: TaskRepeatEnum.DoesNotRepeat,
                name: "Does Not Repeat"
            }
        ],
        [
            "Weekly",
            {
                value: TaskRepeatEnum.Weekly,
                name: "Weekly"
            }
        ],
        [
            "Biweekly",
            {
                value: TaskRepeatEnum.Biweekly,
                name: "Biweekly"
            }
        ],
        [
            "Flexible",
            {
                value: TaskRepeatEnum.Flexible,
                name: "Flexible"
            }
        ],
        [
            "Monthly",
            {
                value: TaskRepeatEnum.Monthly,
                name: "Monthly"
            }
        ]
    ]);
}

export const TaskRepeat = new TaskRepeatEnumClass();
