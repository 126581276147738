import { valueConverter } from "aurelia-framework";

@valueConverter("truncate")
export class TruncateString {
    public toView(value: string, limit: number = 50): string {
        if (value.length > limit) {
            return `${value.slice(0, limit)}...`;
        } else {
            return value;
        }
    }
}
