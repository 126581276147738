import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum EthnicitiesEnum {
    None = 0 as any,
    AmericanIndianOrAlaskaNative = 1 as any,
    Asian = 2 as any,
    BlackOrAfricanAmerican = 4 as any,
    HispanicOrLatino = 8 as any,
    NativeHawaiianOrOtherPacificIslander = 16 as any,
    White = 32 as any
}
class EthnicitiesClass extends EnumWithDescription<EthnicitiesEnum> {
    public readonly enum = EthnicitiesEnum;
    public readonly enumName: string = "EthnicitiesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<EthnicitiesEnum>> = new Map([
        [
            "None",
            {
                value: EthnicitiesEnum.None,
                name: "None"
            }
        ],
        [
            "AmericanIndianOrAlaskaNative",
            {
                value: EthnicitiesEnum.AmericanIndianOrAlaskaNative,
                name: "American Indian or Alaska Native"
            }
        ],
        [
            "Asian",
            {
                value: EthnicitiesEnum.Asian,
                name: "Asian"
            }
        ],
        [
            "BlackOrAfricanAmerican",
            {
                value: EthnicitiesEnum.BlackOrAfricanAmerican,
                name: "Black or African American"
            }
        ],
        [
            "HispanicOrLatino",
            {
                value: EthnicitiesEnum.HispanicOrLatino,
                name: "Hispanic or Latino"
            }
        ],
        [
            "NativeHawaiianOrOtherPacificIslander",
            {
                value: EthnicitiesEnum.NativeHawaiianOrOtherPacificIslander,
                name: "Native Hawaiian or Other Pacific Islander"
            }
        ],
        [
            "White",
            {
                value: EthnicitiesEnum.White,
                name: "White"
            }
        ]
    ]);
}

export const Ethnicities = new EthnicitiesClass();
