import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { UserManager } from "oidc-client";

import config from "../../../../common/config";
import { formatName } from "../../../../common/format-name";
import { PermissionManager } from "../../../../common/utilities/permission-manager";
import { ParentPermissionEnum, PermissionActionEnum } from "../../../../enums/enums";
import AuthService from "../../../../services/auth.service";
import { AgencyService, IGetOrganization } from "../../../../services/agency.service";
import { IOrganization } from "../../../../resources-vue/vue-interfaces/i-calendar";

export default defineComponent({
    props: {
        areRolesLoaded: { type: Boolean, default: false }
    },

    data(vm) {
        return {
            _agencyService: null,
            _user: null,
            _routeProcessSubscription: null,
            _userManager: null,
            _authService: null,
            _permissionManager: null,
            hasCertificationAccess: false,
            displayRoutes: [],
            defaultRoute: config.defaultRoute,
            userName: "",
            certificationUrl: `${config.certificationUrl}/auth/axxess_sso/login.php`,
            isLoggedIn: false,
            dialogRef: null,
            organizations: [] as IGetOrganization[],
            organizationsList: [] as IOrganization[],
            isLoading: false
        };
    },

    created() {
        this._authService = Container.instance.get(AuthService);
        this._permissionManager = Container.instance.get(PermissionManager);
        this._agencyService = Container.instance.get(AgencyService);
        this._userManager = Container.instance.get(UserManager);
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    watch: {
        areRolesLoaded() {
            this.areRolesLoadedChanged();
        }
    },

    mounted() {
        this.loadUser();
        this.getCertificationAccess();
        this.areRolesLoadedChanged();
    },

    methods: {
        areRolesLoadedChanged() {
            const isClinician = this._permissionManager.checkPermission(
                ParentPermissionEnum.clinician,
                PermissionActionEnum.canView
            );

            const routes = this.getAllActualRoutes();

            // Filtering routes to display tabs based on roles
            this.displayRoutes = routes?.filter((route: any) => {
                if (!!route.meta?.showAxxessPlanner) {
                    return isClinician;
                } else if (!!route.meta?.showOther) {
                    return true;
                } else {
                    return false;
                }
            });
        },

        isActivePath(path: string) {
            return this.$route.matched?.some((route) => route.path == path);
        },

        async getCertificationAccess() {
            try {
                this.hasCertificationAccess = await this._agencyService.getHasCertificationAccess();
            } catch (e) {
                console.error(e);
            }
        },

        getAllActualRoutes() {
            return this.$router.options.routes?.filter((route: any) => route.meta?.nav) || [];
        },

        async loadUser() {
            this._user = await this._userManager.getUser();
            if (this._user) {
                this.isLoggedIn = await this._authService.isLoggedIn();
                this.userName = formatName(this._user.profile.given_name, this._user.profile.family_name, "FN LN");
            }
        },

        async goToRoute(path: string) {
            if (path == "/axxess-central") {
                this.$router.push(`/axxess-central/organizationLoading/?&name=My dashboard`);
            } else {
                this.$router.push(path);
            }
        }
    },

    unmounted() {
        this._routeProcessSubscription?.dispose();
    }
});
