import { PropType, defineComponent } from "vue";

import jquery from "jquery";

import { ITypeaheadOptions } from "../../vue-interfaces/i-typeahead";

export default defineComponent({
    props: {
        disabled: { type: Boolean, default: false },
        name: { type: String, default: "" },
        selectedItems: { type: Array as PropType<ITypeaheadOptions[]>, default: [] },
        onFetch: { type: Function },
        fetchUpdatedCount: { type: Number, default: 0 },
        placeholder: { type: String, default: null }
    },

    emits: ["selectedItemsUpdated"],

    watch: {
        selectedItems() {
            this.selectedListUpdated();
        },

        fetchUpdatedCount() {
            // Added this parameter to catch the changes in dropdown options
            if (this.fetchUpdatedCount > 0) {
                this.selectedOptions = [];
                this.onCredentialsFetch();
                this.updateMessage();
            }
        }
    },

    computed: {
        getIsSearching() {
            return this.search;
        },

        getInputPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            } else {
                return `Search for ${this.name}...`;
            }
        }
    },

    data() {
        return {
            fetchedItems: [] as ITypeaheadOptions[],
            displayItems: [] as ITypeaheadOptions[],
            suggestedItems: [] as ITypeaheadOptions[],
            selectedOptions: [],
            open: false as boolean,
            message: "" as string,
            search: "" as string,
            characterLimit: 0 as number
        };
    },

    async mounted() {
        // Prevents the drop down from closing on inside click
        // But allow the close button to bleed the original handler.
        jquery(this.$refs.dropdownElement).on("click.bs.dropdown", (e: any) => {
            if (e.target.nodeName != "BUTTON") {
                e.stopPropagation();
            }
        });

        jquery(this.$refs.dropdown).on("show.bs.dropdown", (e) => {
            this.onDropdownOpen();
            this.open = true;
        });

        jquery(this.$refs.dropdown).on("hide.bs.dropdown", (e) => {
            this.open = false;
        });
        this.onCredentialsFetch();
        this.updateMessage();
    },

    methods: {
        toggleSelection() {
            while (this.selectedOptions.length > 0) {
                this.selectedOptions.pop();
            }
            this.$emit("selectedItemsUpdated", this.selectedOptions);
            this.selectedListUpdated();
        },

        async onDropdownOpen() {
            this.search = "";
            this.displayItems = this.selectedItems.filter(() => true); // Copies array.
            this.suggestedItems = this.fetchedItems.filter(
                (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
            );

            this.onCredentialsFetch();
        },

        onCredentialsFetch() {
            if (this.characterLimit > 0 && this.search.length < this.characterLimit) {
                this.fetchedItems = [];
                this.suggestedItems = [];
            } else {
                if (this.onFetch && typeof this.onFetch === "function") {
                    let results = this.onFetch(this.search);
                    this.fetchedItems = results;
                    this.suggestedItems = results.filter(
                        (x: any) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
                    );
                }
            }
        },

        updateSelectedItems() {
            this.$emit("selectedItemsUpdated", this.selectedOptions);
        },

        selectedListUpdated() {
            // update displayItems and suggestedItems list if user unselect an item.
            if (this.displayItems.length > this.selectedItems.length) {
                let diffItems = this.displayItems.filter(
                    (x) => this.selectedItems.find((y) => y.value == x.value && y.name == x.name) == null
                );
                this.displayItems = this.displayItems.filter(
                    (x) => diffItems.find((y) => y.value == x.value && y.name == x.name) == null
                );
                for (let item of diffItems) {
                    this.suggestedItems.push(item);
                }
            } else {
                let newItems = this.selectedItems.filter(
                    (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
                );
                for (let item of newItems) {
                    this.displayItems.push(item);
                }
            }
            this.suggestedItems = this.suggestedItems.filter(
                (x) => this.displayItems.find((y) => y.value == x.value && y.name == x.name) == null
            );
            this.updateMessage();
        },

        updateMessage() {
            if (!this.selectedItems || this.selectedItems.length == 0) {
                this.message = "All Selected";
            }
            if (this.selectedItems.length == 1) {
                this.message = this.selectedItems[0].name;
            }
            if (this.selectedItems.length > 1) {
                this.message = `${this.selectedItems.length} Selected`;
            }
        }
    }
});
