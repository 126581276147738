<template>
    <FormSection title="Comments" type="his-section" :full-height="false" :application-id="Number(application)">
        <template #content>
            <section class="d-flex flex-column px-3 py-2">
                <div class="d-flex flex-row">
                    <b>Template</b>
                </div>
                <TemplateInput
                    :templates="templates"
                    :application="application"
                    :accountId="accountId"
                    :result="
                        referral.EmergencyPreparedness.EmergencyPrepareComments
                            ? referral.EmergencyPreparedness.EmergencyPrepareComments
                            : ''
                    "
                    v-on:resultUpdated="handleResultUpdated"
                />
            </section>
        </template>
    </FormSection>
</template>

<script src="./EmergencyComments.ts"></script>
