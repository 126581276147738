import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { AuthorizedContact } from "../../../../../../resources-vue/vue-models/authorized-contact";
import { ContactRoles, Relationships, RelationshipsEnum } from "../../../../../../enums/enums";
import { ToastrService } from "../../../../../../services/toastr.service";
import { formatPhoneNumber } from "../../../../../../common/vue-helpers/modifiers/value-modifier";
import { HttpStatusCodeEnum } from "../../../../../../common/http-status-code-enum";

import EmptyTextHandler from "../../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import TablePrompt from "../../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import PatientAuthorizedContactsForm from "../PatientAuthorizedContactsForm/PatientAuthorizedContactsForm.vue";

export default defineComponent({
    components: {
        EmptyTextHandler,
        TablePrompt,
        PatientAuthorizedContactsForm
    },
    props: {
        contact: {
            default: new AuthorizedContact()
        },
        contactRolesEnum: {
            type: Array,
            default: []
        },
        relationshipsEnum: {
            type: Array,
            default: []
        },
        cahpsSurveyContactEnum: {
            type: Array,
            default: []
        },
        isPalliative: {
            type: Boolean,
            default: false
        },
        patientId: {
            type: String
        },
        patientPrimaryAddress: {
            type: Object
        },
        columns: {
            type: Number
        },
        cahpsSurveyRecipientIndex: {
            type: Number,
            default: -1
        },
        refreshList: {
            type: Function
        },
        moveUp: {
            type: Function
        },
        moveDown: {
            type: Function
        },
        totalContacts: {
            type: Number
        },
        updateContact: {
            type: Function
        },
        deleteAuthorizedContact: {
            type: Function
        },
        isReOrderRequested: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            _toastrService: null,
            contactCopy: null,
            isDeleteInProgress: false,
            isDeleteRequested: false,
            isEditRequested: false,
            isContactFormOpen: false,
            isSaving: false,
            ContactRoles: ContactRoles,
            Relationships: Relationships
        };
    },

    created() {
        this._toastrService = Container.instance.get(ToastrService);
    },

    computed: {
        roleValues() {
            let roles: string[] = [];
            this.contact?.contactRoles?.forEach((op) => {
                roles.push(this.ContactRoles.getDescriptionUsingValue(op));
            });
            return roles.join(",");
        },
        relationshipValue() {
            let otherValue = RelationshipsEnum.Other as number;
            let otherRelativeValue = RelationshipsEnum.OtherRelative as number;
            if (this.contact?.relationship == otherValue || this.contact?.relationship == otherRelativeValue) {
                return this.contact?.otherRelationshipDescription;
            }
            if (this.contact?.relationship) {
                return this.Relationships.getDescriptionUsingValue(this.contact.relationship);
            } else {
                return "";
            }
        },
        primaryPhoneNumber() {
            let number = this.contact?.phoneNumbers?.find((op) => op.isPrimary)?.number;
            return formatPhoneNumber(number?.toString());
        }
    },

    methods: {
        showContactForm(isEditable: boolean) {
            this.contactCopy = new AuthorizedContact(this.contact);
            this.isEditRequested = isEditable;
            this.isContactFormOpen = true;
        },
        closeContactForm() {
            this.contactCopy = new AuthorizedContact();
            this.isEditRequested = false;
            this.isContactFormOpen = false;
        },
        async saveContact() {
            if (this.isEmptyContact(this.contactCopy)) {
                this._toastrService.info({
                    title: `Empty Contact`,
                    message: `Please add the necessary information to save the contact.`
                });
                return;
            }

            try {
                this.isSaving = true;
                this.contactCopy = this.addressAndPhoneNumberCheck(this.contactCopy);
                await this.updateContact(this.contactCopy);
                this.closeContactForm();
                this.refreshList();
                this._toastrService.success({
                    title: "Success",
                    message: "Contact has been successfully saved!"
                });
            } catch (e) {
                if (e.status === HttpStatusCodeEnum.NotModified) {
                    this._toastrService.info({
                        title: "No Changes Detected",
                        message: "There were no changes made to the contacts."
                    });
                } else {
                    console.error(e);
                    this._toastrService.error({
                        title: "Error",
                        message: "There was a problem while saving contact. Please try again."
                    });
                }
            } finally {
                await this.$nextTick();
                this.isSaving = false;
            }
        },
        requestDeleteContact() {
            this.isDeleteRequested = true;
        },
        cancelDeleteContact() {
            this.isDeleteRequested = false;
        },
        async deleteContact() {
            try {
                this.isDeleteInProgress = true;
                await this.deleteAuthorizedContact(this.contact.id);
                this._toastrService.success({
                    title: "Success",
                    message: "Contact has been successfully deleted!"
                });
                this.isDeleteRequested = false;
                this.refreshList();
            } catch (e) {
                console.error(e);
                this._toastrService.error({
                    title: "Error",
                    message: "There was a problem while deleting the contact. Please try again."
                });
            } finally {
                this.isDeleteInProgress = false;
            }
        },
        isReOrderRequestedChanged() {
            this.closeContactForm();
            this.cancelDeleteContact();
        },
        isEmptyContact(contact: AuthorizedContact) {
            let isContactEmpty = false;
            isContactEmpty = Object.entries(contact).every((contactProperty) => {
                let isEmpty = true;
                let checkNotRequired = ["id", "isPrimary", "contactOrder", "address"];
                if (!checkNotRequired.includes(contactProperty[0])) {
                    if (Array.isArray(contactProperty[1])) {
                        isEmpty = contactProperty[1].length === 0;
                    } else {
                        isEmpty = !contactProperty[1];
                    }
                } else if (contactProperty[0] === "address" && !contactProperty[1].addressLine1) {
                    isEmpty = true;
                }
                return isEmpty;
            });
            return isContactEmpty;
        },

        addressAndPhoneNumberCheck(contact: AuthorizedContact) {
            if (!contact?.address?.addressLine1) {
                contact.address = null;
            }
            if (!contact?.phoneNumbers[0]?.number && !contact?.phoneNumbers[1]?.number) {
                contact.phoneNumbers = [];
            }
            return contact;
        }
    }
});
