import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row px-2 my-2" }
const _hoisted_2 = { class: "col-12 offset-lg-1 col-lg-10 row no-gutters" }
const _hoisted_3 = { class: "w-100 referral-documents-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PatientDocuments = _resolveComponent("PatientDocuments")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, {
    title: "Documents",
    "full-height": false,
    "application-id": _ctx.referral.application
  }, {
    content: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_PatientDocuments, {
              "patient-id": _ctx.referral.id,
              application: _ctx.referral.application,
              "provider-id": _ctx.providerId,
              "account-id": _ctx.referral.accountId,
              "upload-document": _ctx.uploadDocument,
              "delete-document": _ctx.deleteDocument,
              "get-documents": _ctx.getReferralDocuments,
              "is-palliative": _ctx.isPalliative,
              "is-referral-page": true
            }, null, 8 /* PROPS */, ["patient-id", "application", "provider-id", "account-id", "upload-document", "delete-document", "get-documents", "is-palliative"])
          ])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["application-id"]))
}