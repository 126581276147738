import { defineComponent } from "vue";
import { useEditReferralHH } from "../../../store/useEditReferralHH";
import { mapState } from "pinia";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TemplateInput from "../../../../../resources-vue/vue-custom-elements/TemplateInput/TemplateInput.vue";

export default defineComponent({
    components: {
        FormSection,
        TemplateInput
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            referralOptions: "referralOptions",
            application: "currentApplication",
            accountId: "accountId"
        })
    },

    methods: {
        onCommentSelect(newValue: any) {
            this.referral.Clinical.Comments = newValue;
        }
    }
});
