import { autoinject } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";

import { ApplicationEnum } from "../enums/enums";
import type {
    IGetPatientsByNameResponse,
    IGetPatientByBranchRequest,
    IGetPatientByBranchResponse,
    IPatientRequest,
    IPatientByAgencyRequest,
    IPatientCountPayload,
    IPatientCountResponse,
    IHCPatientResponse,
    INonAdmitHcResponse
} from "../interfaces/i-patient-service";
import type { IPatientPayersRequest, IPatientPayersResponse } from "../resources-vue/vue-interfaces/i-task-details";
import type { IPaginationNew as ImportedIPagination } from "../interfaces/i-pagination";
import type {
    IPatient,
    IPatientRequestParams,
    IPatientValidation,
    IPatientValidationResponse
} from "../interfaces/patient.interface";
import type {
    IGetPatientDme,
    IAddPatientDme,
    IDeletePatientDme,
    IDmeResponse
} from "../resources-vue/vue-interfaces/i-patient-dme";
import { DmeNeeded } from "./../models/dme-needed";
import type {
    ICurrentBenefitPeriodRequest,
    ICurrentBenefitPeriodResponse
} from "../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import { HomeHealthPatient } from "../resources-vue/vue-interfaces/i-homeHealth-patient";
import { INonAdmitPatientQuery } from "../resources-vue/vue-interfaces/i-get-referral";
import { INonAdmitHHPatientQuery } from "../interfaces/i-get-referral";

@autoinject
export class PatientService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/patients";
    private _baseUrl2: string = "/api/v1/patient";
    private _baseUrl3: string = "/api/v1";

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async findAll(params: IPatientRequestParams) {
        return await this._fetchClient
            .build(this._baseUrl)
            .useQueryString(params)
            .fetch<ImportedIPagination<IPatient>>();
    }

    public async findPatientsForCalendar(options: IPatientCalendarRequestParams) {
        return await this._fetchClient
            .build(`/api/v1/patients-for-calendar`)
            .useQueryString(options)
            .fetch<IPaginationNew<IPatientCalendarResponse>>();
    }

    // Patients Dme End Points
    public async getPatientsDme(params: IGetPatientDme) {
        return await this._fetchClient
            .build(`${this._baseUrl}/get-patient-DME`)
            .useQueryString(params)
            .fetch<DmeNeeded[]>();
    }

    public async addPatientsDme(dme: IAddPatientDme) {
        return await this._fetchClient
            .build(`${this._baseUrl}/add-patient-DME`)
            .useMethod("POST")
            .useBody(dme)
            .fetch<IDmeResponse>();
    }

    public async updatePatientsDme(dmeInfo: DmeNeeded) {
        return await this._fetchClient
            .build(`${this._baseUrl}/update-patient-DME`)
            .useMethod("PUT")
            .useBody(dmeInfo)
            .fetch<IDmeResponse>();
    }

    public async deletePatientsDme(payload: IDeletePatientDme) {
        return await this._fetchClient
            .build(`${this._baseUrl}/delete-patient-DME`)
            .useMethod("DELETE")
            .useBody(payload)
            .fetch<IDmeResponse>();
    }

    public async getPatientsByName(payload: IGetPatientsByNameQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-name`)
            .useQueryString(payload)
            .fetch<IGetPatientsByNameResponse>();
    }

    public async getPayersByPatientId(payload: IPatientPayersRequest) {
        const queryParam = { taskId: payload.taskId, accountId: payload.accountId, applications: payload.applications };
        return await this._fetchClient
            .build(`/api/v1/${payload.patientId}/payers/by-visit`)
            .useQueryString(queryParam)
            .fetch<IPatientPayersResponse>();
    }

    public async getPatientByBranch(payload: IGetPatientByBranchRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-branch`)
            .useQueryString(payload)
            .fetch<IGetPatientByBranchResponse[]>();
    }

    public async checkDuplicates(payload: IPatientValidation) {
        return await this._fetchClient
            .build(`${this._baseUrl}/duplicate-check`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IPatientValidationResponse>();
    }

    public async getPatients(payload: IPatientRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/lean`)
            .useQueryString(payload)
            .fetch<IGetPatientsByNameResponse>();
    }

    public async getCurrentBenefitPeriod(payload: ICurrentBenefitPeriodRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/${payload.patientId}/slim`)
            .useQueryString(payload)
            .fetch<ICurrentBenefitPeriodResponse>();
    }
    public async getCurrentEpisode(payload: ICurrentEpisodeRequestParams) {
        return await this._fetchClient
            .build(`${this._baseUrl}/episode/current`)
            .useQueryString(payload)
            .fetch<ICurrentEpisodeResponse>();
    }

    public async getPatientsByAgency(payload: IPatientByAgencyRequest) {
        return await this._fetchClient
            .build(`${this._baseUrl}/by-agency`)
            .useQueryString(payload)
            .fetch<IGetPatientByBranchResponse[]>();
    }

    public async getHospicePatientById(payload: IGetHospicePatientData) {
        return await this._fetchClient
            .build(`${this._baseUrl}/hospice/${payload.patientId}`)
            .useQueryString(payload)
            .fetch<any>();
    }

    public async getHCPatientById(payload: IGetHCPatientData) {
        return await this._fetchClient
            .build(`${this._baseUrl3}/hcpatients/data`)
            .useQueryString(payload)
            .fetch<IHCPatientResponse>();
    }

    public async getEditHHReferral(payload: IGetEditHHReferral) {
        return await this._fetchClient.build(`${this._baseUrl2}/referral/data`).useQueryString(payload).fetch<any>();
    }

    public async getHHPatientAllData(payload: IGetHHPatientAllDataParam) {
        return await this._fetchClient
            .build(`${this._baseUrl}/data`)
            .useQueryString(payload)
            .fetch<HomeHealthPatient>();
    }

    public async updatePatientToNonAdmit(id: string, query: INonAdmitPatientQuery | INonAdmitHHPatientQuery) {
        return await this._fetchClient
            .build(`${this._baseUrl3}/${id}/status/non-admit`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async updatePatientToNonAdmitHC(id: string, query: any) {
        return await this._fetchClient
            .build(`${this._baseUrl3}/${id}/status/non-admit-hc`)
            .useMethod("PUT")
            .useBody(query)
            .fetch<INonAdmitHcResponse>();
    }

    public async patientDiagnosis(payload: IPatientDiagnosis) {
        return await this._fetchClient
            .build(`${this._baseUrl3}/referrals/PdgmIntakeModeling/Diagnoses`)
            .useQueryString(payload)
            .fetch();
    }

    public async getPatientsCount(payload: IPatientCountPayload) {
        return await this._fetchClient
            .build(`${this._baseUrl3}/count`)
            .useMethod("POST")
            .useBody(payload)
            .fetch<IPatientCountResponse>();
    }
}

export interface IGetHospicePatientData {
    agencyId: string;
    patientId: string;
    application: ApplicationEnum;
}

export interface IGetEditHHReferral {
    referralId: string;
    agencyId: string;
    application: number;
}

export interface IGetHHPatientAllDataParam {
    agencyId: string;
    patientId: string;
}

export interface IGetHCPatientData {
    agencyId: string;
    patientId: string;
    application: ApplicationEnum;
}

export interface IPatientDiagnosis {
    agencyId: string;
    application: number;
    codeSearch: string;
    descriptionSearch: string;
}

export interface IPatientCalendarRequestParams {
    pageLength: number;
    page: number;
    term?: string;
    patientStatus?: number;
    agencies?: string[];
    scheduleFilter?: number;
    startDate?: Date | string;
    endDate?: Date | string;
}

export interface IPaginationNew<T> {
    ItemCount: number;
    PageLength: number;
    CurrentPage: number;
    PageCount: number;
    Items: T[];
}

export interface IPatientCalendarResponse {
    Id: string;
    FirstName: string;
    LastName: string;
    Mrn: string;
    AgencyId: string;
    AgencyName: string;
    Application: number;
    PhoneNumber: string;
}

export interface IGetPatientsByNameQuery {
    sortBy: string;
    order: string;
    page: number;
    pageSize: number;
    term: string;
    locationIds?: string[];
}

export interface ICurrentEpisodeRequestParams {
    agencyId: string;
    patientId: string;
    application: ApplicationEnum;
}

export interface ICurrentEpisodeResponse {
    startDate: string;
    endDate: string;
    id: string;
    patientId: string;
    patientStatus: number;
    assessmentId: string;
    assessmentType: string;
    isActive: boolean;
    startOfCareDate: string;
}

export interface IGetCommentTemplates {
    id: string;
}
