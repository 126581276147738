import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PalliativeReferralReadinessEnum {
    ConsentsCompleted = 1 as any,
    PalliativeCareOrderReceived = 2 as any
}
class PalliativeReferralReadinessClass extends EnumWithDescription<PalliativeReferralReadinessEnum> {
    public readonly enum = PalliativeReferralReadinessEnum;
    public readonly enumName: string = "PalliativeReferralReadinessEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PalliativeReferralReadinessEnum>> = new Map([
        [
            "ConsentsCompleted",
            {
                value: PalliativeReferralReadinessEnum.ConsentsCompleted,
                name: "Consents Completed"
            }
        ],
        [
            "PalliativeCareOrderReceived",
            {
                value: PalliativeReferralReadinessEnum.PalliativeCareOrderReceived,
                name: "Palliative Care Order Received"
            }
        ]
    ]);
}
export const PalliativeReferralReadiness = new PalliativeReferralReadinessClass();
