import "./patient-list.scss";

import { computedFrom, observable } from "aurelia-binding";
import { EventAggregator } from "aurelia-event-aggregator";
import { bindable, customElement, inject } from "aurelia-framework";

import nameOf from "../../common/name-of";
import type { IPaginationNew } from "../../interfaces/i-pagination";
import { Patient } from "../../models/patient.model";
import { AnalyticsService } from "../../services/analytics.service";
import { NavigationService } from "../../services/navigation.service";
import { PatientService } from "../../services/patient.service";
import { PageTakeOverLoading } from "../app/loading-indicator";
import type { ILogOptions } from "../../resources-vue/vue-interfaces/i-analytics";
import type { INavigateRequestParams, INavigateResponse } from "./../../resources-vue/vue-interfaces/i-navigation";
import type { IPatient } from "./../../interfaces/patient.interface";

@customElement("patient-list")
@inject(EventAggregator, NavigationService, PatientService, AnalyticsService, Element)
export class PatientList {
    private _ea: EventAggregator;
    private _navigate: NavigationService;
    private _patientService: PatientService;
    private _analytics: AnalyticsService;
    public patientsList: Array<IPatient> = [];
    @observable({
        changeHandler: nameOf<PatientList>("pageNumberChanged")
    })
    public pageNumber: number = 1;
    @bindable public pageSize: number = 20;
    @bindable public visible: boolean = false;
    public isLoading: boolean = true;
    private logCategory: string = "Patient-List";
    public hasError: boolean = false;
    public page: IPaginationNew<IPatient>;
    public tooltip: string = `<p>This section displays all patients currently assigned to you.</p>
                <ul>
                    <li>Click on 'View Chart' to view more patient details.</li>
                </ul>`;

    /**
     * Creates an instance of PatientList.
     *
     * @param {EventAggregator} ea
     * @param {NavigationService} navigate
     * @param {PatientService} patients
     *
     * @memberOf PatientList
     */
    @computedFrom(
        nameOf<PatientList>("pageNumber"),
        `${nameOf<PatientList>("page")}.${nameOf<IPaginationNew<IPatient>>("pageCount")}`
    )
    public get allPagesLoaded() {
        return this.pageNumber > this.page?.pageCount;
    }

    public constructor(
        ea: EventAggregator,
        navigate: NavigationService,
        patients: PatientService,
        analytics: AnalyticsService
    ) {
        this._ea = ea;
        this._navigate = navigate;
        this._patientService = patients;
        this._analytics = analytics;
    }

    public async attached(): Promise<void> {
        await this.getData();
    }

    private async getData() {
        if (this.pageNumber > this.page?.pageCount) {
            return;
        }
        try {
            this.hasError = false;
            this.isLoading = true;
            let receivedList = await this._patientService.findAll({
                page: this.pageNumber,
                pageSize: 10
            });
            this.page = receivedList;
            if (receivedList.items.length > 0) {
                this.patientsList = this.getNewPatientsList(receivedList.items);
                this.patientsList = this.patientsList.map((item) => new Patient(item));
            }
        } catch (error) {
            console.error(error);
            this.hasError = true;
        } finally {
            this.isLoading = false;
        }
    }

    private getNewPatientsList(newPatients: IPatient[]) {
        let visiblePatients = this.patientsList?.map((patient) => patient.Id);
        let uniqueNewPatients = newPatients?.filter((patient) => !visiblePatients?.includes(patient.Id));
        return uniqueNewPatients.length > 0 ? this.patientsList.concat(uniqueNewPatients) : this.patientsList;
    }

    public closePatients(): void {
        this.visible = false;
        this._analytics.logEvent({
            category: this.logCategory,
            action: "Close-Patients-List"
        });
    }

    public async pageNumberChanged(newValue: number): Promise<void> {
        if (newValue > 1 && this.page && !this.allPagesLoaded) {
            if (newValue <= this.page.pageCount) {
                await this.getData();
            }
        }
    }

    public async viewPatient(patient: IPatient): Promise<void> {
        let analyticsOptions: ILogOptions = {
            category: this.logCategory,
            action: "View-Patient-Chart-From-Patient-List"
        };
        let navParams: INavigateRequestParams = {
            agencyId: patient.AgencyId,
            applicationId: patient.Application,
            userId: patient.UserId,
            isClinician: true,
            patientId: patient.Id
        };
        try {
            this._ea.publish(PageTakeOverLoading.Show);
            let response: INavigateResponse = await this._navigate.navigateToPatient(navParams);
            this._navigate.redirect(response, analyticsOptions);
        } catch (e) {
            console.error(e);
            this._ea.publish(PageTakeOverLoading.Hide);
        }
    }
}
