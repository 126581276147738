import { autoinject } from "aurelia-framework";

import config from "../common/config";
import { FetchClient } from "../common/fetch-client";
import { ApplicationEnum, PhoneTypeEnum } from "../enums/enums";
import type { IGetAmericanStateCode } from "../resources-vue/vue-interfaces/i-american-state-code";
import type { IDiagnosisDetails, IGetDiagnosisQuery } from "../resources-vue/vue-interfaces/i-diagnosis";
import {
    IGetHomeHealthPhysicianByName,
    IGetHomeHealthPhysicianByNameQuery,
    IGetHomeHealthPhysiciansByFilters,
    IGetHomeHealthPhysiciansByFiltersResponse,
    IGetCityStateByZipcode,
    IGetCityStateByZipcodeResponse
} from "../interfaces/i-get-physician";
import type { IPhoneNumberOption } from "../resources-vue/vue-interfaces/i-phone-number";
import type { IGetCountryCode, IGetCountryPhoneCode } from "../resources-vue/vue-interfaces/i-lookup";
import type { IGetDmes, IGetDmesResponse } from "../interfaces/i-get-dmes";
import { IDisciplineTaskHH, IGetDisciplineTaskHHParams } from "../resources-vue/vue-interfaces/i-discipline-task-hh";
import {
    ICheckPecos,
    IGetNpi,
    IGetNpiResponse,
    IGetPhysicianInfo,
    IPhysicianInfoResponse
} from "../resources-vue/vue-interfaces/i-get-physician";

export enum DiagnosisQueryTypeEnum {
    code = "icd10code" as any,
    desc = "icd10diagnosis" as any
}
@autoinject()
export class LookupService {
    private _httpClient: FetchClient;
    private _baseUrl: string = "/api/v1/lookup";
    public stateListCacheKey = config.stateListCacheKey;
    public countryListCacheKey = config.countryListCacheKey;
    public countryPhoneCodeListCacheKey = config.countryPhoneCodeListCacheKey;
    private phoneTypeOptionsPromise: Promise<IPhoneNumberOption[]>;

    public constructor(httpClient: FetchClient) {
        this._httpClient = httpClient;
    }

    public async getIcd10Codes(query: IGetDiagnosisQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/icd10codes`)
            .useQueryString(query)
            .fetch<IDiagnosisDetails[]>();
    }

    public async getCountryCodes(): Promise<IGetCountryCode[]> {
        let cachedData = JSON.parse(localStorage.getItem(this.countryListCacheKey));
        if (cachedData) {
            return cachedData;
        }
        let countryList = await this._httpClient.build(`${this._baseUrl}/countries`).fetch<IGetCountryCode[]>();
        localStorage.setItem(config.countryListCacheKey, JSON.stringify(countryList));
        return countryList;
    }

    public async getUnitedStatesCode(): Promise<string> {
        let countryList = await this.getCountryCodes();
        let unitedStates = countryList.find((country) => country.name.toLowerCase() === "united states of america");
        return unitedStates?.value;
    }

    public async getPhoneTypeOptions() {
        if (!this.phoneTypeOptionsPromise) {
            this.phoneTypeOptionsPromise = this._httpClient
                .build(`${this._baseUrl}/phone-number-type`)
                .fetch<IPhoneNumberOption[]>();
            //temporary fix, BE will update this API soon with below value
            (await this.phoneTypeOptionsPromise).unshift({ name: PhoneTypeEnum.None, value: 0, hasExtension: false });
        }
        return await this.phoneTypeOptionsPromise;
    }

    public async getCountryPhoneCodes(): Promise<IGetCountryPhoneCode[]> {
        let cachedData = JSON.parse(localStorage.getItem(this.countryPhoneCodeListCacheKey));
        if (cachedData) {
            return cachedData;
        }
        let countryPhoneCodeList = await this._httpClient
            .build(`${this._baseUrl}/country-phone-codes`)
            .fetch<IGetCountryPhoneCode[]>();
        localStorage.setItem(config.countryPhoneCodeListCacheKey, JSON.stringify(countryPhoneCodeList));
        return countryPhoneCodeList;
    }

    public async getAmericanStateCodes(): Promise<IGetAmericanStateCode[]> {
        let cachedData = JSON.parse(localStorage.getItem(this.stateListCacheKey));
        if (cachedData) {
            return cachedData;
        }

        let stateList = await this._httpClient
            .build(`${this._baseUrl}/americanstatecodes`)
            .fetch<IGetAmericanStateCode[]>();
        localStorage.setItem(config.stateListCacheKey, JSON.stringify(stateList));
        return stateList ?? [];
    }

    public async getPhysiciansByName(query: IGetHomeHealthPhysicianByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/physicians`)
            .useQueryString(query)
            .fetch<IGetHomeHealthPhysicianByName[]>();
    }

    public async getPhysiciansByFilters(query: IGetHomeHealthPhysiciansByFilters) {
        return await this._httpClient
            .build(`${this._baseUrl}/matched-physicians`)
            .useQueryString(query)
            .fetch<IGetHomeHealthPhysiciansByFiltersResponse>();
    }

    public async getPhysicianInfo(query: IGetPhysicianInfo) {
        return await this._httpClient
            .build(`${this._baseUrl}/physician-info`)
            .useMethod("POST")
            .useQueryString(query)
            .fetch<IPhysicianInfoResponse>();
    }

    public async getCityStateByZipcode(query: IGetCityStateByZipcode) {
        return await this._httpClient
            .build(`${this._baseUrl}/city-state-by-zipcode`)
            .useQueryString(query)
            .fetch<IGetCityStateByZipcodeResponse>();
    }

    public async getNpi(query: IGetNpi) {
        return await this._httpClient
            .build(`${this._baseUrl}/get-npis`)
            .useMethod("POST")
            .useQueryString(query)
            .fetch<IGetNpiResponse[]>();
    }

    public async checkPecos(query: ICheckPecos) {
        return await this._httpClient
            .build(`${this._baseUrl}/check-pecos`)
            .useMethod("POST")
            .useQueryString(query)
            .fetch<boolean>();
    }

    public async getDmes(query: IGetDmes) {
        return await this._httpClient.build(`${this._baseUrl}/dmes`).useQueryString(query).fetch<IGetDmesResponse>();
    }

    public async getCountyByZipcode(zipCode: string, agencyId: string) {
        const query: IGetCityStateByZipcode = { zipCode, agencyId };
        return await this._httpClient.build(`${this._baseUrl}/county`).useQueryString(query).fetch<string[]>();
    }

    public async getDisciplineTasks(query: IGetDisciplineTaskHHParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/schedule/discipline-tasks`)
            .useQueryString(query)
            .fetch<IDisciplineTaskHH[]>();
    }
}
