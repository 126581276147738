import { defineComponent, PropType } from "vue";

export interface ISelectDropDownOption {
    [groupText: string]: IDropdownSelectOption[];
}

export interface IDropdownSelectOption {
    displayText: string;
    value: any;
    id?: string;
    icons?: string;
}

export default defineComponent({
    props: {
        dropDownOptions: { type: Object as PropType<ISelectDropDownOption>, default: {} },
        selectedOption: { type: String, default: "" },
        selectedText: { type: String, default: "" },
        selectedValue: { type: String, default: "" },
        labelText: { type: String, default: "" },
        selectedId: { type: Number },
        showIcons: { type: Boolean, default: false }
    },

    emits: ["updateSelectFields"],

    data() {
        return {
            filteredOptions: {} as ISelectDropDownOption,
            search: "" as string
        };
    },

    mounted() {
        this.filteredOptions = { ...this.dropDownOptions };
        this.$emit("updateSelectFields", "", "", null);
        $(this.$refs.dropDownMenu).on("click", (e) => {
            e.stopPropagation();
        });
        $(this.$refs.dropDownElement).on("shown.bs.dropdown", (e) => {
            (this.$refs.searchInput as HTMLInputElement).focus();
        });
    },

    computed: {
        getButtonText(): string {
            if (!this.selectedText) {
                return `Select ${this.labelText}`;
            } else {
                return `${this.selectedText}`;
            }
        },

        getSearchPlaceholder(): string {
            return `Search for ${this.labelText}`;
        },

        getDropDownOptionsLength() {
            this.filteredOptions = { ...this.dropDownOptions };
            return Object.values(this.dropDownOptions).reduce((len, option) => option.length + len, 0);
        }
    },

    watch: {
        search() {
            if (this.search) {
                for (let groupName of Object.keys(this.dropDownOptions)) {
                    let options = this.dropDownOptions[groupName];
                    this.filteredOptions[groupName] = options.filter((option) =>
                        option.displayText.toLowerCase().includes(this.search.toLowerCase())
                    );
                }
            } else {
                this.filteredOptions = { ...this.dropDownOptions };
            }
        }
    },

    methods: {
        closeDropDown(option?: IDropdownSelectOption) {
            this.$emit("updateSelectFields", option?.value, option?.displayText, option?.id);
            $(this.$refs.dropDownElement).dropdown("toggle");
        }
    }
});
