import "./group-dropdown-select.scss";

import { bindingMode, computedFrom, observable } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import * as $ from "jquery";

export interface ISelectDropDownOption {
    [groupText: string]: IDropdownSelectOption[];
}

export interface IDropdownSelectOption {
    displayText: string;
    value: any;
    id?: string;
    icons?: string;
}

@customElement("group-dropdown-select")
export class MultiSelectCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public labelText: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public dropDownOptions: ISelectDropDownOption;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedOption: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showIcons: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedText: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedValue: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedId: string = "";
    public filteredOptions: ISelectDropDownOption;
    @observable({ changeHandler: "searchChanged" })
    public search: string;
    public dropDownMenu: HTMLElement;
    public dropDownElement: HTMLElement;
    public searchInput: HTMLElement;

    public attached() {
        this.filteredOptions = { ...this.dropDownOptions };
        this.selectedText = "";
        this.selectedValue = "";
        this.selectedId = "";
        $(this.dropDownMenu).on("click", (e) => {
            e.stopPropagation();
        });
        $(this.dropDownElement).on("shown.bs.dropdown", (e) => {
            this.searchInput.focus();
        });
    }

    public closeDropDown(option?: IDropdownSelectOption) {
        if (option) {
            this.selectedText = option.displayText;
            this.selectedValue = option.value;
            this.selectedId = option.id;
        }
        $(this.dropDownElement).dropdown("toggle");
    }

    public searchChanged() {
        if (this.search) {
            for (let groupName of Object.keys(this.dropDownOptions)) {
                let options = this.dropDownOptions[groupName];
                this.filteredOptions[groupName] = options.filter((option) =>
                    option.displayText.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        } else {
            this.filteredOptions = { ...this.dropDownOptions };
        }
    }

    @computedFrom("selectedText", "dropDownOptionsLength")
    public get buttonText(): string {
        if (!this.selectedText) {
            return `Select ${this.labelText}`;
        } else {
            return `${this.selectedText}`;
        }
    }

    @computedFrom("labelText")
    public get searchPlaceholder(): string {
        return `Search for ${this.labelText}`;
    }

    @computedFrom("dropDownOptions")
    public get dropDownOptionsLength() {
        this.filteredOptions = { ...this.dropDownOptions };
        return Object.values(this.dropDownOptions).reduce((len, option) => option.length + len, 0);
    }
}
