import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "page-header" }
const _hoisted_3 = { class: "row-span-2 h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardHeader = _resolveComponent("DashboardHeader")!
  const _component_PersonalHelp = _resolveComponent("PersonalHelp")!
  const _component_PersonalMessageList = _resolveComponent("PersonalMessageList")!
  const _component_PersonalTraining = _resolveComponent("PersonalTraining")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_DashboardHeader)
    ]),
    _createElementVNode("section", {
      class: _normalizeClass(`page-content mx-3 px-0 ${!_ctx.hasAgencyCore && !_ctx.hasHomeCare ? 'no-message-access' : ''} App__Personal`)
    }, [
      _createVNode(_component_PersonalHelp),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PersonalMessageList)
      ]),
      _createVNode(_component_PersonalTraining)
    ], 2 /* CLASS */)
  ]))
}