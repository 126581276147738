import { defineComponent } from "vue";

import { AuthorizedContact } from "../../../../../../models/authorized-contact";

import ErrorMessageHandler from "../../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import PatientAuthorizedContactsRow from "../PatientAuthorizedContactsRow/PatientAuthorizedContactsRow.vue";

export default defineComponent({
    components: {
        ErrorMessageHandler,
        PatientAuthorizedContactsRow
    },
    props: {
        totalContacts: {
            type: Number
        },
        updateContact: {
            type: Function
        },
        deleteContact: {
            type: Function
        },
        getAuthorizedContacts: {
            type: Function
        },
        isReOrderRequested: {
            type: Boolean,
            default: false
        },
        contactRolesEnum: {
            type: Array,
            default: []
        },
        relationshipsEnum: {
            type: Array,
            default: []
        },
        cahpsSurveyContactEnum: {
            type: Array,
            default: []
        },
        patientId: {
            type: String
        },
        patientPrimaryAddress: {
            type: Object
        },
        cahpsSurveyRecipientIndex: {
            type: Number,
            default: -1
        },
        isPalliative: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contacts: null,
            isLoading: false,
            isError: false,
            loadingCount: 3,
            columns: 9
        };
    },

    mounted() {
        this.getContacts();
    },

    watch: {
        contacts() {
            if (this.contacts?.length > 0) {
                this.$emit(
                    "update:cahpsSurveyRecipientIndex",
                    this.contacts.findIndex((contact: any) => contact.cahpsSurvey > 0)
                );
            }
        }
    },

    computed: {
        hasData() {
            return this.contacts?.length > 0;
        }
    },
    methods: {
        async getContacts() {
            try {
                this.isLoading = true;
                this.contacts = await this.getAuthorizedContacts(this.patientId);
                // this.totalContacts = this.contacts?.length();
            } catch (e) {
                console.error(e);
                this.isError = true;
            } finally {
                this.isLoading = false;
            }
        },
        moveUp(currentPosition: number) {
            let newPosition = currentPosition - 1;
            this.moveContact(currentPosition, newPosition);
        },
        moveDown(currentPosition: number) {
            let newPosition = currentPosition + 1;
            this.moveContact(currentPosition, newPosition);
        },
        moveContact(currentPosition: number, newPosition: number) {
            if (!!this.contacts) {
                if (currentPosition >= this.contacts.length || currentPosition < 0) {
                    return;
                }

                if (newPosition >= this.contacts.length || newPosition < 0) {
                    return;
                }

                this.contacts.splice(newPosition, 0, this.contacts.splice(currentPosition, 1)[0]);
                this.updateIndices();
            }
        },
        updateIndices() {
            this.contacts = this.contacts?.map((contact: AuthorizedContact, index: number) => {
                contact.isPrimary = index === 0;
                contact.contactOrder = index;
                return contact;
            });
        },
        async deleteAuthorizedContact(contactId: string) {
            await this.deleteContact(contactId);
        },
        async updateAuthorizedContact(contact: AuthorizedContact) {
            await this.updateContact(contact);
        }
    }
});
