import { ApplicationEnum } from "../../enums/enums";

export class DmeNeeded {
    public id: string;
    public agencyId?: string;
    public patientId?: string;
    public accountId?: string;
    public dmeId: string;
    public provider: string;
    public serialNo: string;
    public modelNo: string;
    public dmeName: string;
    public application: ApplicationEnum;
    public isDeleted: boolean = false;
    public otherDME?: string;

    [key: string]: any;
    public constructor(data?: DmeNeeded) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
