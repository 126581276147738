import { defineComponent } from "vue";

import type { IGetSharedAssetResult } from "../../../../../resources-vue/vue-interfaces/messaging-service/i-message";
import { formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    props: {
        asset: {
            type: Object,
            default: {} as IGetSharedAssetResult
        }
    },

    data() {
        return {
            formatDate
        };
    },

    methods: {
        getFileIcon(mimeType: string) {
            if (mimeType.includes("wordprocessing")) {
                return "fa-file-word";
            } else if (mimeType.includes("image")) {
                return "fa-file-image";
            } else if (mimeType.includes("spreadsheet")) {
                return "fa-file-excel";
            } else if (mimeType.includes("pdf")) {
                return "fa-file-pdf";
            } else {
                return "fa-file-alt";
            }
        }
    }
});
