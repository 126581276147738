import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12 pt-1 row no-gutters" }
const _hoisted_2 = { class: "form-group col-12" }
const _hoisted_3 = { for: "primary-physician" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TypeaheadInput = _resolveComponent("TypeaheadInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.physicianIndex == 0 ? "Primary Physician" : "Additional Physician"), 1 /* TEXT */),
        _createVNode(_component_TypeaheadInput, {
          placeholder: "Start Typing",
          getData: _ctx.getPhysicianList,
          value: _ctx.physicians,
          onValueChanged: _ctx.changePhysician
        }, null, 8 /* PROPS */, ["getData", "value", "onValueChanged"])
      ])
    ])
  ]))
}