import { defineComponent, PropType, computed } from "vue";
import { Container } from "aurelia-dependency-injection";

import { EmergencyContact } from "../../../../../../resources-vue/vue-models/homecare-emergency-contact";
import type { IEnumResponse } from "../../../../../../resources-vue/vue-interfaces/i-enum";
import type { IAddress } from "../../../../../../resources-vue/vue-interfaces/i-address";
import { IPhoneNumber } from "../../../../../../resources-vue/vue-interfaces/i-phone-number";
import { type IPromptOptions } from "../../../../../../resources-vue/vue-dialogs/Prompt/Prompt";
import { ToastrService } from "../../../../../../services/toastr.service";
import { formatPhoneNumber } from "../../../../../../common/vue-helpers/modifiers/value-modifier";
import { RelationToReferral, HttpStatusCodeEnum } from "../../../../../../enums/enums";
import { HomeCareReferral } from "../../../../../../resources-vue/vue-models/homecare-referral";

import EmptyTextHandler from "../../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import Prompt from "../../../../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";
import TablePrompt from "../../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import EmergencyContactsForm from "../EmergencyContactsForm/EmergencyContactsForm.vue";

export default defineComponent({
    components: {
        EmptyTextHandler,
        TablePrompt,
        EmergencyContactsForm
    },
    props: {
        contact: { default: null },
        referral: { type: HomeCareReferral, default: null },
        isReOrderRequested: { type: Boolean, default: null },
        isPalliative: { type: Boolean, default: false },
        isEditing: { type: Boolean, default: false },
        patientId: { type: String, default: null },
        totalContacts: { type: Number, default: null },
        columns: { type: Number, default: null },
        cahpsSurveyRecipientIndex: { type: Number, default: -1 },
        index: { type: Number, default: -1 },
        makeContactPrimary: { type: Function, default: null },
        refreshList: { type: Function, default: null },
        moveUp: { type: Function, default: null },
        moveDown: { type: Function, default: null },
        deleteEmergencyContact: { type: Function, default: null },
        updateContact: { type: Function, default: null },
        patientPrimaryAddress: { type: Object as PropType<IAddress>, default: [] },
        relationshipsEnum: { type: Array as PropType<IEnumResponse[]>, default: [] },
        contactRolesEnum: { type: Array as PropType<IEnumResponse[]>, default: [] },
        cahpsSurveyContactEnum: { type: Array as PropType<IEnumResponse[]>, default: [] }
    },
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },
    data() {
        return {
            isContactFormOpen: false,
            isDeleteRequested: false,
            isEditRequested: false,
            isDeleteInProgress: false,
            isSaving: false,
            contactCopy: {} as EmergencyContact,
            dialogRef: null,
            _toastrService: null,
            Relationships: RelationToReferral,
            formatPhoneNumber
        };
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
    },
    watch: {
        isReOrderRequested() {
            this.closeContactForm();
            this.cancelDeleteContact();
        }
    },
    emits: ["updateIsEditing"],
    computed: {
        primaryPhoneNumber() {
            let number = this.contact?.phoneNumbers?.find((op: IPhoneNumber) => op.isPrimary)?.number;
            return formatPhoneNumber(number?.toString());
        },
        relationshipValue() {
            return this.Relationships.getDescriptionUsingValue(this.contact?.relationship);
        }
    },
    methods: {
        closeContactForm() {
            this.contactCopy = new EmergencyContact();
            this.isEditRequested = false;
            this.isContactFormOpen = false;
        },
        cancelDeleteContact() {
            this.isDeleteRequested = false;
        },
        showContactForm(isEditable: boolean) {
            this.contactCopy = JSON.parse(JSON.stringify(this.contact));
            this.isEditRequested = isEditable;
            this.isContactFormOpen = true;
        },
        requestDeleteContact() {
            this.isDeleteRequested = true;
        },
        async deleteContact() {
            try {
                this.isDeleteInProgress = true;
                await this.deleteEmergencyContact(this.contact.id);
                this._toastrService.success({
                    title: "Success",
                    message: "Contact has been successfully deleted!"
                });
                this.isDeleteRequested = false;
                this.refreshList();
            } catch (e) {
                console.error(e);
                this._toastrService.error({
                    title: "Error",
                    message: "There was a problem while deleting the contact. Please try again."
                });
            } finally {
                this.isDeleteInProgress = false;
            }
        },
        async makeEmergencyContactPrimary() {
            const promptOptions: IPromptOptions = {
                message: "Are you sure you want to set this contact as primary?",
                okText: "Yes",
                cancelText: "No"
            };

            this.dialogRef = this.$dialog.open(Prompt, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false
                },
                data: {
                    promptOptions
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        try {
                            await this.makeContactPrimary(this.contact.id);
                            this._toastrService.success({
                                title: "Success",
                                message: "Client contact has been successfully set to primary."
                            });
                            this.refreshList();
                        } catch (e) {
                            this._toastrService.error({
                                title: "Error",
                                message: "There was a problem while making the contact primary. Please try again."
                            });
                        }
                    }
                }
            });
        },

        async saveContact() {
            const validated = await (this.$refs.contactForm as any).v$.$validate();
            if (validated) {
                try {
                    this.isSaving = true;
                    await this.updateContact(this.contactCopy);
                    this.closeContactForm();
                    this.refreshList();
                    this._toastrService.success({
                        title: "Success",
                        message: "Contact has been successfully saved!"
                    });
                } catch (e) {
                    if (e.status === HttpStatusCodeEnum.NotModified) {
                        this._toastrService.info({
                            title: "No Changes Detected",
                            message: "There were no changes made to the contacts."
                        });
                    } else {
                        console.error(e);
                        this._toastrService.error({
                            title: "Error",
                            message: "There was a problem while saving contact. Please try again."
                        });
                    }
                } finally {
                    this.isSaving = false;
                    this.$emit("updateIsEditing", false);
                }
            } else {
                this._toastrService.error({
                    title: `Validation Failed`,
                    message: `Fill all required fields.`
                });
            }
        },

        updateIsEditing() {
            this.$emit("updateIsEditing", true);
        }
    }
});
