import { computed, defineComponent, PropType, toRaw } from "vue";
import { Container } from "aurelia-dependency-injection";
import $ from "jquery";
import GoogleMaps = google.maps;
import moment from "moment";

import eventBus from "../../../../../utilities/eventBus";
import { AnalyticsService } from "../../../../../services/analytics.service";
import { AgencyService } from "../../../../../services/agency.service";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum
} from "../../../../../enums/enums";
import { ProductService } from "../../../../../services/product.service";
import { IVisitMapDetails } from "../../../../../resources-vue/vue-interfaces/i-visit-map";
import { CalendarMapService } from "../../../../../services-vue/calendar-map-service";
import { IAddCalendarMarkerOptions } from "../../../../../resources-vue/vue-interfaces/i-calendar-map";
import { nonEvvVisitStatus } from "../DailyCalendarContent/DailyCalendarContent";
import {
    formatFullAddress,
    formatScheduledTime,
    formatTimeInOut
} from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { TaskService } from "../../../../../services/task.service";
import { IReassignVisitDetails } from "../../../../../interfaces/i-reassign-visit";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { getProductPillClass, getProductName } from "../../../../../common/vue-helpers/modifiers/application-modifiers";

import ReassignVisit from "../../../../../resources-vue/vue-dialogs/ReassignVisit/ReassignVisit.vue";

export default defineComponent({
    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    props: {
        mapVisitsList: { type: Array as PropType<IVisitMapDetails[]>, default: [] },
        date: { type: Date, default: new Date(Date.now()) }
    },

    emits: ["mapRefresh"],

    data() {
        return {
            _myMarker: null as GoogleMaps.Marker,
            _bounds: null as GoogleMaps.LatLngBounds,
            _map: null as GoogleMaps.Map,
            _agencyService: null as AgencyService,
            _prodService: null as ProductService,
            _analyticsService: null as AnalyticsService,
            _calendarMapService: null as CalendarMapService,
            _taskService: null as TaskService,
            _permissionManager: null as PermissionManager,
            _locationData: null,
            _createdMarkerCount: 0,
            _totalLocations: 0,
            locationsFromCoords: {} as any,
            locationsFromAddress: {} as any,
            currentInfoWindow: null,
            allLocationCoords: [],
            highlightEmployeesList: [],
            dialogRef: null,
            isReassignAllowed: false as boolean
        };
    },

    async created() {
        this._agencyService = Container.instance.get(AgencyService);
        this._prodService = Container.instance.get(ProductService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this._calendarMapService = Container.instance.get(CalendarMapService);
        this._taskService = Container.instance.get(TaskService);
        this._permissionManager = Container.instance.get(PermissionManager);
        await this._init();
        eventBus.on("highlightEmployeeMarker", (selectedEmployees: any) => {
            this.highlightEmployeesList = selectedEmployees.map((emp: any) => emp.name);
            this._clearLocationMarkers(this.locationsFromCoords);
            this._clearLocationMarkers(this.locationsFromAddress);
            this.allLocationCoords = [];
            this._markLocations(this.locationsFromCoords);
            this._markLocations(this.locationsFromAddress);
        });
    },

    watch: {
        mapVisitsList: {
            handler() {
                this._clearAllLocations(this.locationsFromCoords);
                this._clearAllLocations(this.locationsFromAddress);
                this.highlightEmployeesList = [];
                this._init();
            },
            deep: true
        }
    },

    async mounted() {
        this.isReassignAllowed = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canReassign
        );
        this._locationData = JSON.parse(localStorage.getItem("axxess-user-location"));
        this._init();
    },

    methods: {
        async _init() {
            await this._calendarMapService.loadMapsApi();
            this._markUser();
            if (this.mapVisitsList.length > 0) {
                this.allLocationCoords = [];
                await this._groupVisitsByLocation();
                this._markLocations(this.locationsFromCoords);
            }
        },

        _markUser(): void {
            try {
                let lat: number = +this._locationData?.lat;
                let lon: number = +this._locationData?.lon;
                this._bounds = this._calendarMapService.getBounds();
                this._map = this._calendarMapService.getMap(document.getElementById("Employee__VisitMap"), {
                    center: {
                        lat: lat,
                        lng: lon
                    },
                    zoom: 15, // Higher is zoom in and lower is zoom out
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false
                });
                this._myMarker = this._calendarMapService.addMarker({
                    position: {
                        lat: lat,
                        lng: lon
                    },
                    map: this._map,
                    animationType: "drop",
                    type: "user"
                });
                let info = this._calendarMapService.addInfoWindow({
                    content: "<div class='text-center font-weight-normal py-1'>Your Location</div>"
                });
                // Marker Events
                this._myMarker.addListener("mouseover", () => {
                    info.open(this._map, this._myMarker);
                });
                this._myMarker.addListener("mouseout", () => {
                    info.close();
                });

                this._bounds.extend(this._myMarker.getPosition());
            } catch (error) {
                console.error(error?.message);
            }
        },

        getUpdatedLatLng(lat: any, lng: any): any {
            let latLngStr = lat + "," + lng;
            if (!this.allLocationCoords.includes(latLngStr)) {
                return { lat, lng };
            }
            return this.getUpdatedLatLng(lat, lng + 0.0001);
        },

        updateLocationsList(locations: any, coords: any, empName: string, visit: any) {
            let latLngStr = coords.lat + "," + coords.lng;
            if (Object.keys(locations).includes(empName)) {
                if (Object.keys(locations[empName]).includes(latLngStr)) {
                    locations[empName][latLngStr].push(visit);
                } else {
                    locations[empName][latLngStr] = [visit];
                    this._totalLocations++;
                }
            } else {
                locations[empName] = { [latLngStr]: [visit] };
                this._totalLocations++;
            }
        },

        async _groupVisitsByLocation(): Promise<void> {
            for (let visit of this.mapVisitsList) {
                let currentEmpName = visit.EmployeeName?.FirstName + " " + visit.EmployeeName?.LastName;
                currentEmpName = currentEmpName != " " ? currentEmpName : "Unassigned";
                let coords = {
                    lat: visit.PatientAddress.AddressLatitude,
                    lng: visit.PatientAddress.AddressLongitude
                };

                if (coords.lat && coords.lng) {
                    this.updateLocationsList(this.locationsFromCoords, coords, currentEmpName, visit);
                } else {
                    await this._calendarMapService.getCoordsFromAddress(
                        formatFullAddress(visit.PatientAddress),
                        (newcoords) => {
                            this.updateLocationsList(this.locationsFromAddress, newcoords, currentEmpName, visit);
                            this._clearLocationMarkers(this.locationsFromAddress);
                            this._markLocations(this.locationsFromAddress);
                        }
                    );
                }
            }
        },

        _markLocations(locations: any): void {
            if (this._map && locations && !$.isEmptyObject(locations)) {
                for (let empName of Object.keys(locations)) {
                    for (let locs of Object.keys(locations[empName])) {
                        let empVisits = locations[empName][locs];
                        const image = this.getMarkerImage(empVisits[0]);
                        let highlightPin: boolean = this.highlightEmployeesList.includes(empName);
                        let icon = highlightPin
                            ? { url: image, scaledSize: new google.maps.Size(60, 86) }
                            : { url: image };

                        let [lat, lng] = locs.split(",");

                        let coords = {
                            lat: Number(lat),
                            lng: Number(lng)
                        };
                        let marker = null;
                        let infoWindow = null;

                        let content = this.getEmployeeInfoWindowContent(empVisits);
                        if (!!coords.lat && !!coords.lng) {
                            try {
                                if (this.allLocationCoords.includes(coords.lat + "," + coords.lng)) {
                                    // To display multiple markers with same coords, modifying marker coords by small value.
                                    const res = this.getUpdatedLatLng(coords.lat, coords.lng + 0.0001);
                                    coords = {
                                        lat: res.lat,
                                        lng: res.lng
                                    };
                                }
                                let options: IAddCalendarMarkerOptions = {
                                    position: coords,
                                    map: this._map,
                                    animationType: "drop",
                                    icon: icon
                                };
                                this._bounds.extend(coords);
                                marker = this._calendarMapService.addMarker(options);
                                infoWindow = this._calendarMapService.createInfoWindow(content);
                                // keeps the track of all the marked locations
                                this.allLocationCoords.push(coords.lat + "," + coords.lng);
                                this._analyticsService.logEvent({
                                    category: "Create-Marker",
                                    action: "Coordinates"
                                });
                            } catch (error) {
                                console.error(error);
                            }
                        }

                        locations[empName][locs].marker = marker;
                        this.initMarkerEvents(marker, infoWindow, locations[empName][locs]);
                    }
                }
            }
        },

        getMarkerImage(visit: any) {
            const name = visit.EmployeeName;
            const empName = name?.FirstName ? name?.FirstName + "" + name?.LastName : null;
            const credential = visit?.EmployeeCredential?.split(",")[0];
            let image = "";
            if (empName) {
                if (!credential) {
                    image = "None";
                } else {
                    image = credential;
                }
            } else {
                image = "Unassigned";
            }
            return require(`../../../../../assets/images/EmployeeVisitMap/${image}.svg`);
        },

        initMarkerEvents(markerInstance: GoogleMaps.Marker, infoWindow: any, visits: any) {
            if (markerInstance) {
                markerInstance.addListener("click", () => {
                    if (this.currentInfoWindow != null) {
                        this.currentInfoWindow.close();
                        this._analyticsService.logEvent({
                            category: "Marker-Info-Window",
                            action: "Close"
                        });
                    }
                    infoWindow.open(this._map, markerInstance);
                    this.currentInfoWindow = infoWindow;
                    this._analyticsService.logEvent({
                        category: "Marker-Info-Window",
                        action: "Open"
                    });
                });
                // storing the reference, as reference changes in google map events
                const thisReference = this;

                google.maps.event.addListener(infoWindow, "domready", (x) => {
                    document.querySelectorAll(".dropdown-reassign").forEach((el) => {
                        el.addEventListener("click", () => {
                            let visitId = el.id.split("_")[2];
                            let currentVisitDetails = visits.find((v: any) => v.Id === visitId);
                            thisReference.scheduledVisit(currentVisitDetails);
                        });
                    });
                });

                this._createdMarkerCount++;
                if (this._createdMarkerCount === this._totalLocations) {
                    this._map.fitBounds(this._bounds);
                }
            }
        },

        _clearLocationMarkers(locations: any) {
            for (let empName of Object.keys(locations)) {
                for (let locs of Object.keys(locations[empName])) {
                    let currentMarker = locations[empName][locs].marker;
                    if (currentMarker) {
                        toRaw(currentMarker).setMap(null);
                    }
                }
            }
        },

        _clearAllLocations(locations: any): void {
            for (let empName of Object.keys(locations)) {
                for (let locs of Object.keys(locations[empName])) {
                    let currentMarker = locations[empName][locs].marker;
                    if (currentMarker) {
                        toRaw(currentMarker).setMap(null);
                    }
                }
                delete locations[empName];
            }
            this._totalLocations = 0;
            this._createdMarkerCount = 0;
        },

        getEmployeeInfoWindowContent(visits: any): string {
            let inProgressVisits: string = "";
            let completedVisits: string = "";
            let notYetStartedVisits: string = "";

            for (let v of visits) {
                let status = v.Status.toLowerCase();
                if (nonEvvVisitStatus.notYetStarted.includes(status)) {
                    notYetStartedVisits += this.getVisitDetails(v, true);
                } else if (nonEvvVisitStatus.inProgress.includes(status)) {
                    inProgressVisits += this.getVisitDetails(v);
                } else {
                    completedVisits += this.getVisitDetails(v);
                }
            }

            let name = visits[0].EmployeeName;
            let empName = name?.FirstName && name?.LastName ? `${name.FirstName} ${name.LastName}` : "Unassigned";
            let tempApplicationId: ApplicationEnum;
            if (visits[0].Application === 256) {
                tempApplicationId = ApplicationEnum.AxxessHospice;
            } else {
                tempApplicationId = visits[0].Application;
            }

            return ` <div id="${visits[0].UserId}" class="visit-marker-details">
                    <span class="${getProductPillClass(tempApplicationId)} pill">
                        ${getProductName(tempApplicationId)}
                    </span>
                <big class="info-header"><b>${empName}${
                empName == "Unassigned" ? "" : ", " + visits[0].EmployeeCredential
            }</b></big>

                ${
                    notYetStartedVisits !== ""
                        ? `<div class="visit-type">Not Yet Started</div>${notYetStartedVisits}`
                        : ""
                }
                ${inProgressVisits !== "" ? `<div class="visit-type">In Progress</div>${inProgressVisits}` : ""}
                ${completedVisits !== "" ? `<div class="visit-type">Completed</div>${completedVisits}` : ""}
                <div class="visit-address">${formatFullAddress(visits[0].PatientAddress)}</div>
                </div>`;
        },

        getVisitDetails(visit: any, showReassignBtn: boolean = false) {
            let visitTime = formatTimeInOut(
                formatScheduledTime(visit.StartDateTime),
                formatScheduledTime(visit.EndDateTime)
            );
            let patientName = visit.PatientName.FirstName + " " + visit.PatientName.LastName;

            return `<div class="visit-details">
                <div class="font-weight-normal">${visit.VisitName}</div>
                <div>${visitTime}</div>
                <div>Scheduled to patient ${patientName}</div>
                ${
                    this.isReassignAllowed && showReassignBtn
                        ? `<span class="table-btn-group  ml-auto btn-reassign" id = "edit-icon-${visit.Id}">
                <button type="button" class="border-0 btn px-1 py-0 btn-outline-info dropdown-toggle" data-toggle="dropdown" id = "edit-btn-${visit.Id}">
                    <i class="fas fa-edit"></i>
                </button>
                <div class="dropdown-menu">
                    <a
                        class="dropdown-item dropdown-reassign"
                        href="javascript:void(0);"
                        id = "btn_reassign_${visit.Id}"
                    >
                    Reassign
                    </a>
                </div>
            </span>`
                        : ""
                }
                </div>`;
        },

        async scheduledVisit(visitDetails: any) {
            visitDetails.TaskName = visitDetails.VisitName;
            visitDetails.UserFirstName = visitDetails.EmployeeName.FirstName;
            visitDetails.UserLastName = visitDetails.EmployeeName.LastName;
            visitDetails.FirstName = visitDetails.PatientName.FirstName;
            visitDetails.LastName = visitDetails.PatientName.LastName;

            this.dialogRef = this.$dialog.open(ReassignVisit, {
                props: { modal: true, showHeader: false },
                data: {
                    visitDetails
                },
                onClose: async (options) => {
                    if (options.data === "success") {
                        this.$emit("mapRefresh");
                    }
                }
            });
        }
    }
});
