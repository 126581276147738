import mapsapi from "google-maps-api";

import config from "../common/config";
import type { IAddMarkerOptions, IGeoCodeResponse, IMarkerIconOptions } from "./../resources-vue/vue-interfaces/i-map";

import GoogleMaps = google.maps;

// HOW TO GET THE PATH?
// https://stackoverflow.com/a/45807677/1043431
// import { icon } from "@fortawesome/fontawesome-svg-core";
// import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
// console.log(icon(faMapMarkerAlt).html[0])
// <path d="THIS IS THE PATH"></path>
const MAP_MARKER_ALT_SVG_PATH = `M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535
13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z`;
const AXXESSCARE_BG = getColor("--axxesscare-primary");
const AXXESSCARE_BORDER = getColor("--axxesscare-secondary");
const USER_LOCATION_BG = getColor("--user-location");
const AXXESS_RED = getColor("--app-primary");
const LOCATION_SELECTED = getColor("--location-selected");
const MARKER_ICON: IMarkerIconOptions = {
    path: MAP_MARKER_ALT_SVG_PATH,
    scale: 0.05,
    strokeWeight: 0.2,
    strokeOpacity: 1,
    fillOpacity: 0.9
};

function getColor(colorName: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(colorName);
}

export class GoogleMapsService {
    private _activeMap: GoogleMaps.Map;
    private _maps: any;
    private _api: any;

    public constructor() {
        this._api = new mapsapi(config.googleMapsApiKey, ["places"])();
        console.log("GOOGLE MAPS: instantiated");
    }

    public async getMapsApi() {
        if (!this._maps) {
            await this.loadMapsApi();
        }
        return this._maps;
    }

    public async loadMapsApi(): Promise<void> {
        this._maps = await this._api;
    }

    public getMap(el: HTMLElement, options: GoogleMaps.MapOptions): GoogleMaps.Map {
        if (this._maps) {
            this._activeMap = new this._maps.Map(el, options);
            return this._activeMap;
        }

        throw new Error("getMap(): Maps is null or undefined");
    }

    public addMarker(options: IAddMarkerOptions): GoogleMaps.Marker {
        let type = options.type;
        let icon = this._setMarkerColorByType(type);

        if (type) {
            delete options.type;
        }

        if (type === "visit" || type === "selected") {
            options = options;
        } else {
            options.icon = icon;
        }

        if (this._maps) {
            if (options.animationType) {
                let animationType = options.animationType.toLowerCase();
                if (animationType === "drop") {
                    options.animation = this._maps.Animation.DROP;
                } else if (animationType === "bounce") {
                    options.animation = this._maps.Animation.BOUNCE;
                }
            }
            return new this._maps.Marker(options);
        }

        throw new Error("addMarker(): Maps is null or undefined");
    }
    // TODO:  This method should be in marker.model
    // or marker.service once it is created
    private _setMarkerColorByType(type: string): IMarkerIconOptions {
        let icon: IMarkerIconOptions = MARKER_ICON;
        let colors = {
            fillColor: AXXESS_RED,
            strokeColor: AXXESS_RED
        };

        if (type) {
            if (type === "user") {
                colors.fillColor = USER_LOCATION_BG;
                colors.strokeColor = USER_LOCATION_BG;
            } else if (type === "axxesscare") {
                colors.fillColor = AXXESSCARE_BG;
                colors.strokeColor = AXXESSCARE_BORDER;
            } else if (type === "selected") {
                colors.fillColor = LOCATION_SELECTED;
                colors.strokeColor = LOCATION_SELECTED;
            }
        }

        return Object.assign(icon, colors);
    }

    public changeMarkerIcon(marker: GoogleMaps.Marker, type?: string) {
        let icon = this._setMarkerColorByType(type);
        marker.setIcon("");
    }

    public addInfoWindow(options: GoogleMaps.InfoWindowOptions) {
        if (this._maps) {
            return new this._maps.InfoWindow(options);
        }
    }

    public isInfoWindowOpen(infoWindow: any): boolean {
        let map = infoWindow.getMap();
        return map !== null && typeof map !== "undefined";
    }

    public getBounds(): GoogleMaps.LatLngBounds {
        if (this._maps) {
            return new this._maps.LatLngBounds();
        }

        throw new Error("getBounds(): Maps is null or undefined");
    }

    public async getCoordsFromAddress(address: any, callBack: (info: IGeoCodeResponse) => void): Promise<void> {
        await this.loadMapsApi();
        let maps: any = this._maps;
        if (maps && address && callBack && typeof callBack === "function") {
            let gc: GoogleMaps.Geocoder = new maps.Geocoder();
            gc.geocode(
                {
                    address: address
                },
                (results: Array<GoogleMaps.GeocoderResult>, status: GoogleMaps.GeocoderStatus) => {
                    let info = this.mapGeoCodeResponse(results, status);
                    if (!info.hasError) {
                        callBack(info);
                    }
                }
            );
        }
    }

    public async getAddressFromCoords(location: any, callBack: (info: IGeoCodeResponse) => void): Promise<void> {
        await this.loadMapsApi();
        let maps = this._maps;
        if (maps && location && callBack && typeof callBack === "function") {
            let gc: GoogleMaps.Geocoder = new maps.Geocoder();
            gc.geocode(
                {
                    location: location
                },
                (results: Array<GoogleMaps.GeocoderResult>, status: GoogleMaps.GeocoderStatus) => {
                    let info = this.mapGeoCodeResponse(results, status);
                    callBack(info);
                }
            );
        }
    }

    public mapGeoCodeResponse(
        results: Array<GoogleMaps.GeocoderResult>,
        status: GoogleMaps.GeocoderStatus
    ): IGeoCodeResponse {
        let maps = this._maps;
        if (status === maps.GeocoderStatus.OK) {
            let location: GoogleMaps.LatLng = results[0].geometry.location;
            let lat: string = location.lat().toFixed(3);
            let lng: string = location.lng().toFixed(3);
            let info: IGeoCodeResponse = {
                lat: lat,
                lng: lng,
                location: location
            };
            // Address Structure:
            // https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
            let addressComponents: Array<GoogleMaps.GeocoderAddressComponent> = results[0].address_components;
            for (let address of addressComponents) {
                let type: string = address.types[0];
                switch (type) {
                    case "street_number":
                    case "route":
                    case "country":
                    case "postal_code":
                    case "postal_code_suffix":
                        break;
                    case "locality":
                        info.city = address.short_name;
                        break;
                    case "administrative_area_level_1":
                        info.state = address.short_name;
                        break;
                    case "administrative_area_level_2":
                        info.county = address.short_name;
                        break;
                    case "administrative_area_level_3":
                        info.city = address.short_name;
                        break;
                }
            }
            return info;
        } else {
            return {
                hasError: true,
                message: "ERROR: Could not get lat long from zip code."
            };
        }
    }
}
