<template>
    <section class="payrate-modal">
        <div class="rounded shadow-lg modal-content-container">
            <div class="p-3">
                <div class="form-group">
                    <label for="addendum" class="control-label"
                        >Reason for addendum <span class="text-danger">*</span></label
                    >
                    <textarea
                        v-model="reason"
                        id="addendum"
                        required="required"
                        class="form-control form-control-sm"
                        placeholder="Enter reason for Addendum"
                        maxlength="2000"
                    >
                    </textarea>
                </div>
                <div class="mt-1 d-flex px-3 justify-content-center">
                    <button @click="startAddendum()" type="button" class="btn btn-xs mr-2 btn-outline-primary">
                        Start Addendum
                    </button>
                    <button @click="cancelAddAddendum()" type="button" class="btn btn-xs btn-primary">Cancel</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script src="./AddAddendum.ts"></script>
