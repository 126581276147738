import { EnumWithDescription, ICustomEnumValue } from "./EnumWithDescription";

export enum MissedVisitHospiceReasonsEnum {
    PatientCaregiverDeclined = 1 as any,
    InclementWeather = 2 as any,
    PatientHospitalized = 3 as any,
    PatientNotHome = 5 as any,
    Other = 9 as any
}

class MissedVisitHospiceReasonsClass extends EnumWithDescription<MissedVisitHospiceReasonsEnum> {
    public readonly enum = MissedVisitHospiceReasonsEnum;
    public readonly enumName: string = "MissedVisitHospiceReasonsEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<MissedVisitHospiceReasonsEnum>> = new Map([
        [
            "PatientCaregiverDeclined",
            {
                value: MissedVisitHospiceReasonsEnum.PatientCaregiverDeclined,
                name: "Patient/Caregiver Declined"
            }
        ],
        [
            "InclementWeather",
            {
                value: MissedVisitHospiceReasonsEnum.InclementWeather,
                name: "Inclement Weather"
            }
        ],
        [
            "PatientHospitalized",
            {
                value: MissedVisitHospiceReasonsEnum.PatientHospitalized,
                name: "Patient Hospitalized"
            }
        ],
        [
            "PatientNotHome",
            {
                value: MissedVisitHospiceReasonsEnum.PatientNotHome,
                name: "Patient Not Home"
            }
        ],
        [
            "Other",
            {
                value: MissedVisitHospiceReasonsEnum.Other,
                name: "Other"
            }
        ]
    ]);
}

export const MissedVisitHospiceReasons = new MissedVisitHospiceReasonsClass();
