import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import moment from "moment";
import config from "../../common/config";
import { ApplicationEnum } from "../../enums/enums";
import type { IModalOptions } from "../../resources-vue/vue-interfaces/i-modal";
import type { IRoles } from "../../resources-vue/vue-interfaces/i-roles";
import type { IGetSurveyResponse } from "../../resources-vue/vue-interfaces/i-survey";
import { AnalyticsCategory, AnalyticsService } from "../../services/analytics.service";
import { MarketingService } from "../../services/marketing.service";
import { RolesService } from "../../services/roles.service";
import { SurveyService } from "../../services/survey.service";

@autoinject
export class MarketingContent {
    private readonly _surveyService: SurveyService;
    private readonly _marketingService: MarketingService;
    private readonly _rolesService: RolesService;
    private readonly _analyticsService: AnalyticsService;
    private _ea: EventAggregator;

    public constructor(
        surveyService: SurveyService,
        marketingService: MarketingService,
        ea: EventAggregator,
        rolesService: RolesService,
        analyticsService: AnalyticsService
    ) {
        this._surveyService = surveyService;
        this._marketingService = marketingService;
        this._ea = ea;
        this._analyticsService = analyticsService;
        this._rolesService = rolesService;
    }

    public async start() {
        await this.initSurvey();
        // await this.initCovidMarketing();
        // await this.initHospiceMarketing();
    }

    /* private async initCovidMarketing() {
        let lastDay = moment(config.covidMarketingLastDate);
        let isBeforeLastDay = moment().isBefore(lastDay);
        // TODO: Add try and catch block here
        let interactionResult = await this._marketingService.getHospiceInteraction();

        if (isBeforeLastDay && interactionResult && !interactionResult.Interacted) {
            let options: IModalOptions = {
                position: 'center',
                viewModel: 'covid-19',
                model: {},
                size: 'lg'
            };
            this._ea.publish(ModalEvents.ShowModal, options);
        }
    }

    private async initHospiceMarketing() {
        let lastDay = moment(config.hospiceMarketingLastDate);
        let isBeforeLastDay = moment().isBefore(lastDay);
        // TODO: Add try and catch block here
        let interactionResult = await this._marketingService.getHospiceInteraction();

        if (isBeforeLastDay && interactionResult && !interactionResult.Interacted) {
            let options: IModalOptions = {
                position: 'center',
                viewModel: 'hospice-marketing',
                model: {},
                size: 'lg'
            };
            this._ea.publish(ModalEvents.ShowModal, options);
        }
    } */

    private async initSurvey() {
        let lastDay = moment(config.linesOfServiceSurveyLastDate, "MM/DD/YYYY");
        let isBeforeLastDay = moment().isBefore(lastDay);

        if (!isBeforeLastDay) {
            return;
        }

        let canShowSurvey = await this.getUserSurveyEligibility();
        if (canShowSurvey) {
            try {
                let surveyResult: IGetSurveyResponse = await this._surveyService.getSurveySubmission();

                if (surveyResult && !surveyResult.IsCompleted) {
                    let options: IModalOptions = {
                        position: "center",
                        viewModel: "survey",
                        size: "lg",
                        model: {}
                    };
                    this._ea.publish("modal:show", options);
                    this._analyticsService.logEvent({
                        category: AnalyticsCategory.LineOfServiceSurvey,
                        action: "Displayed"
                    });
                }
            } catch (e) {
                console.error(e);
                // Silent Fail
            }
        }
    }

    private async getUserSurveyEligibility() {
        try {
            let userRoles: IRoles[] = await this._rolesService.getRoles();
            let allowedProducts = [
                ApplicationEnum.AgencyCore,
                ApplicationEnum.HomeCare
                // ApplicationEnum.AxxessHospice TODO
            ];
            let allowedRoles = new Set([
                1, // Administrator
                2, // DON
                15 // Office Manager
            ]);

            return userRoles.some((role) => {
                return allowedProducts.includes(role.Application) && role.Roles.some((item) => allowedRoles.has(item));
            });
        } catch (e) {
            console.error(e);
            return false;
        }
    }
}
