import { computed, defineComponent } from "vue";
import { mapState, mapActions } from "pinia";

import { Container } from "aurelia-dependency-injection";

import config from "../../../common/config";
import { ILocation } from "../../vue-interfaces/i-location";
import { IWeatherRequestParams } from "../../vue-interfaces/i-weather";
import { Weather } from "../../../models/weather.model";
import { GeolocationService, GeoLocationEvents } from "../../../services-vue/geolocation.service";
import { WeatherService } from "../../../services/weather.service";
import { AnalyticsService, AnalyticsCategory } from "../../../services/analytics.service";
import { weatherClass, formatingDateOrTime } from "../../../common/vue-helpers/modifiers/value-modifier";
import getZip from "../../vue-dialogs/GetZip/GetZip.vue";
import eventBus from "../../../../src/utilities/eventBus";
import { GetZipEvents } from "../../vue-dialogs/GetZip/GetZip";

export default defineComponent({
    data() {
        return {
            duration: 5, // Weather report for N number of days
            durationDateFormat: "ddd", // Duration date format. Moment's date format
            _gl: null,
            _weatherService: null,
            _analyticsService: null,
            _isGettingLocation: false,
            _locationKey: config.locationCacheKey,
            locationData: null,
            locationChanged: false,
            currentData: null,
            forecastData: [] as Array<Weather>,
            loading: false,
            locationCacheKey: config.locationCacheKey,
            weatherClass,
            formatingDateOrTime,
            dialogRef: null,
            showWeatherForecast: false
        };
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    created() {
        this._gl = Container.instance.get(GeolocationService);
        this._weatherService = Container.instance.get(WeatherService);
        this._analyticsService = Container.instance.get(AnalyticsService);
        this.initSubscriptions();
        this.currentData = Weather;
        this.locationData = {};
    },

    async mounted() {
        this.locationChanged = true;
        // This condition is required.
        // On route navigation, we will check whether location has been received already.
        if (this._gl.hasGeo) {
            Object.assign(this.locationData, this._gl.locationData);
        } else {
            await this.getLocationFromUser();
        }

        await this.init();
    },

    computed: {
        getClassOfCurrentWeather(): string {
            if (this.currentData.id) {
                return this.weatherClass(this.currentData?.id);
            } else {
                return "wi";
            }
        }
    },

    methods: {
        initSubscriptions() {
            eventBus.on(GeoLocationEvents.Retrieved, async (data: ILocation) => {
                Object.assign(this.locationData, data);
                await this.init();
            });

            eventBus.on(GetZipEvents.Changed, (zip: string) => {
                this.locationChanged = true;
            });
        },

        async getLocationFromUser(): Promise<void> {
            // https://geoip-db.com/json/ - Lat and Long based on IP address.
            // Alternate way to get location. Not accurate. Might get random countries.
            if (!this._isGettingLocation && !this._gl.hasGeo) {
                if ("geolocation" in navigator) {
                    this._isGettingLocation = true;
                    console.log("GEOLOCATION: Getting location");
                    // FYI: getCurrentPosition() and watchPosition() no longer work on insecure origins.
                    // To use this feature, you should consider switching your application to a secure origin, such as HTTPS.
                    // See https://goo.gl/rStTGz for more details.
                    navigator.geolocation.getCurrentPosition(
                        this.handlePosition.bind(this),
                        this.handlePositionError.bind(this)
                    );
                } else {
                    this.logEvent(AnalyticsCategory.GetZip, "No-Geolocation");
                    await this.handleNoGeoLocation();
                }
            } else if (this._gl.hasGeo) {
                console.log(`GEOLOCATION: hasGeo is true. Why did you make a call? Culprit:`);
            }
        },

        async handlePosition(position: any): Promise<void> {
            const lat: string = position.coords.latitude.toFixed(3);
            const lon: string = position.coords.longitude.toFixed(3);

            if (lat !== undefined && lon !== undefined) {
                const cacheString: string = localStorage.getItem(this._locationKey);
                const cache = cacheString && JSON.parse(cacheString);

                if (cache && cache.lat === lat && cache.lon === lon) {
                    this.logEvent(AnalyticsCategory.GetZip, "Old-Location");
                    Object.assign(this.locationData, cache);
                    this._isGettingLocation = false;
                    this._gl.announceLocationRetrieval(this.locationData);
                } else {
                    this.logEvent(AnalyticsCategory.GetZip, "New-Location");
                    await this._gl.getAddressFromCoords(lat, lon);
                }
            } else {
                this._analyticsService.logEvent({
                    category: "Geolocation",
                    action: "No-Lat-Long"
                });
                await this.handleNoGeoLocation();
            }
        },

        // This function will get triggered when the user blocks the geoLocation
        async handlePositionError(): Promise<void> {
            this.logEvent(AnalyticsCategory.GeoLocation, "User-Blocked");
            await this.handleNoGeoLocation();
        },

        async handleNoGeoLocation(): Promise<void> {
            const userLocation = localStorage.getItem("axxess-user-location");
            if (userLocation) {
                const locationJSON = JSON.parse(userLocation);
                const willingToShare = locationJSON.willingToShareLocation;
                const fromGeo = locationJSON.fromGeo;

                if (willingToShare) {
                    // Check to see if User blocked after allowing geolocation
                    if (!fromGeo) {
                        await this._gl.getCoordinatesByZip(locationJSON.zip);
                    } else {
                        await this.getLocationUsingZip();
                    }
                } else {
                    eventBus.emit(GetZipEvents.Canceled);
                }
            } else {
                await this.getLocationUsingZip();
            }
        },

        async getLocationUsingZip() {
            let zipOptions = {
                zip: this.locationData.zip
            };

            this.dialogRef = this.$dialog.open(getZip, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    zipOptions
                },
                onClose: (response: any) => {
                    if (response.data !== "success") {
                        this.cacheUnwillingToShareLocation();
                    }
                }
            });
            eventBus.on(GetZipEvents.Canceled, () => {
                this.cacheUnwillingToShareLocation();
            });
        },

        cacheUnwillingToShareLocation() {
            Object.assign(this.locationData, {
                willingToShareLocation: false,
                fromGeo: false
            });
            this._gl.cacheLocation();
        },

        async init(): Promise<void> {
            const options = this.getWeatherRequestData();

            if (!!options) {
                this.loading = true;
                const forecastOptions = Object.assign(options, {
                    count: 6
                });
                const currentWeatherReq = await this._weatherService.getCurrentWeather(options, this.locationChanged);
                const forecastWeatherReq = await this._weatherService.getWeatherForecast(
                    forecastOptions,
                    this.locationChanged
                );
                this.currentData = currentWeatherReq;
                this.forecastData = forecastWeatherReq;
                this.locationChanged = false;
                const todayForecast = this.forecastData.splice(0, 1)[0];
                this.currentData.updateHiLo(
                    {
                        hi: todayForecast.hi,
                        lo: todayForecast.lo
                    },
                    false
                );
                this.loading = false;
            }
        },

        getWeatherRequestData() {
            const locationKey: string = localStorage.getItem(this.locationCacheKey);
            if (locationKey) {
                this.locationData = JSON.parse(locationKey);
            }

            if (this.locationData && !$.isEmptyObject(this.locationData)) {
                const reqParams: IWeatherRequestParams = {
                    units: "imperial" // Default: Kelvin, Metric: Celsius, Imperial: Fahrenheit
                };

                if (this.locationData.lat && this.locationData.lon) {
                    return Object.assign(reqParams, {
                        latitude: this.locationData.lat,
                        longitude: this.locationData.lon
                    });
                } else if (this.locationData.zip) {
                    return Object.assign(reqParams, {
                        zip: `${this.locationData.zip},us`
                    });
                } else {
                    console.warn("get weatherRequestData(): Latitude, longitude or zip was not set in locationData.");
                }
            } else {
                console.warn("get weatherRequestData(): locationData is empty or undefined.");
            }
            return null;
        },

        // This Method pops the dialog for the user to enter zipCode.
        // Once the zip code changes, need to get the coordinates of the entered zip code.
        // Once we get the coordinates of the zip code, we need to initialize the report again.
        async updateLocation() {
            let zipOptions = {
                zip: this.locationData.zip
            };

            this.dialogRef = this.$dialog.open(getZip, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    zipOptions
                },
                onClose: (response: any) => {
                    if (response.data !== "success") {
                        this.cacheUnwillingToShareLocation();
                    }
                }
            });
        },

        logEvent(category: string, action: string) {
            this._analyticsService.logEvent({ category, action });
        },

        toggleWeatherForecast(): void {
            this.showWeatherForecast = !this.showWeatherForecast;
        }
    }
});
