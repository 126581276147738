import { PropType, computed, defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import moment from "moment";
import { Container } from "aurelia-dependency-injection";

import {
    ApplicationEnum,
    ConflictType,
    ConflictTypeEnum,
    ConflictTypeNameEnum,
    RecurrenceType,
    RecurrenceTypeEnum
} from "../../../../enums/enums";
import { formatDateInput } from "../../../../common/vue-helpers/modifiers/value-modifier";
import { enumToMap } from "../../../../common/vue-helpers/modifiers/enum-modifiers";
import { IReassignVisitDetails } from "../../../../interfaces/i-reassign-visit";
import {
    IHomeCareTasksData,
    IHomeCareTasksParams,
    IScheduleMatchRequest,
    TaskService
} from "../../../../services/task.service";
import { ISelectDropDownOption } from "../../../vue-custom-elements/GroupDropdownSelect/GroupDropdownSelect";
import { IGetTaskPayorList, ITaskPayor } from "../../../../resources-vue/vue-interfaces/i-task";
import { ToastrService } from "../../../../services/toastr.service";
import { IRescheduleValidations } from "../../../../resources/dialogs/reschedule-validations/reschedule-validations";
import { IHandleTaskReschuleIssue } from "../../../../resources-vue/vue-interfaces/i-visit-details";
import { IEmployeeDetails } from "../../../vue-interfaces/i-homecare-reassign";
import { ScheduleVisitHomeCareModel } from "../../../vue-models/schedule-visit-home-care";

import FormSection from "../../../vue-custom-elements/FormSection/FormSection.vue";
import DatePicker from "../../../vue-custom-elements/DatePicker/DatePicker.vue";
import TimePicker from "../../../vue-custom-elements/TimePicker/TimePicker.vue";
import DayPicker from "../../../vue-custom-elements/DayPicker/DayPicker.vue";
import MultiDatePicker from "../../../vue-custom-elements/MultiDatePicker/MultiDatePicker.vue";
import GroupDropdownSelect from "../../../vue-custom-elements/GroupDropdownSelect/GroupDropdownSelect.vue";
import CommentInput from "../../../vue-custom-elements/CommentInput/CommentInput.vue";
import MultiSelectDropdown from "../../../vue-custom-elements/MultiSelectDropdown/MultiSelectDropdown.vue";
import RescheduleValidation from "../../../vue-dialogs/RescheduleValidations/RescheduleValidation.vue";
import FindAMatch from "../../../vue-dialogs/FindAMatch/FindAMatch.vue";

export enum SaveEnum {
    SaveAndExit = "1",
    SaveAndAddAnother = "2"
}

export default defineComponent({
    components: {
        FormSection,
        DatePicker,
        TimePicker,
        DayPicker,
        MultiDatePicker,
        GroupDropdownSelect,
        CommentInput,
        MultiSelectDropdown
    },

    props: {
        visitDetails: { type: Object as PropType<IReassignVisitDetails>, default: {} },
        isLoading: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        cancel: { type: Function, default: null },
        ok: { type: Function, default: null },
        addAnother: { type: Function, default: null }
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            newVisitDetails: {
                startDateTime: {
                    date: {
                        required
                    },
                    time: {
                        required: requiredIf(!this.scheduleWithoutTime)
                    }
                },
                payorId: { required },
                disciplineTask: { required },
                recurrence: {
                    endDate: {
                        required: requiredIf(() => {
                            return !(
                                this.newVisitDetails.recurrence.type === RecurrenceTypeEnum.Flexible ||
                                this.newVisitDetails.recurrence.type === RecurrenceTypeEnum.None
                            );
                        })
                    },
                    daysOfWeek: {
                        required: requiredIf(this.newVisitDetails.recurrence.type == RecurrenceTypeEnum.Weekly)
                    },
                    flexibleDates: {
                        required: requiredIf(this.newVisitDetails.recurrence.type == RecurrenceTypeEnum.Flexible)
                    }
                }
            }
        };
    },

    data() {
        return {
            _taskService: null,
            _toastrService: null,
            _subscriptions: null,
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string },
            isSavingReason: false as boolean,
            newVisitDetails: new ScheduleVisitHomeCareModel(),
            newVisitDetailsReset: ScheduleVisitHomeCareModel,
            disciplineTasks: [] as IHomeCareTasksData[],
            currentUserId: "" as string,
            employeeList: [] as IEmployeeDetails[],
            selectedConflictTypes: [ConflictTypeEnum.NoConflict] as number[],
            recurrenceType: RecurrenceType,
            recurrenceTypeEnum: RecurrenceTypeEnum,
            taskDropdownSelectOptions: {} as ISelectDropDownOption,
            employeeDropdownSelectOptions: {} as ISelectDropDownOption,
            selectedAgencies: [] as string[],
            careGivTooltip: `This filter compares employee schedules to the date and time
                                     being scheduled and sorts them into No Conflict, Partial Conflict
                                     and Full Conflict groups. No Conflict includes employees who
                                     are available at the time of the new visit. Partial Conflict includes
                                     employees who are assigned to another visit during a portion of
                                     the new visit or to tasks with no time scheduled. Full Conflict
                                     includes employees who are assigned to another visit during the
                                     entire duration of the new visit.`,
            conflictTypes: [
                {
                    name: ConflictTypeNameEnum[ConflictTypeEnum.NoConflict],
                    value: ConflictTypeEnum.NoConflict,
                    type: "conflict-type"
                },
                {
                    name: ConflictTypeNameEnum[ConflictTypeEnum.PartialConflict],
                    value: ConflictTypeEnum.PartialConflict,
                    type: "conflict-type"
                },
                {
                    name: ConflictTypeNameEnum[ConflictTypeEnum.FullConflict],
                    value: ConflictTypeEnum.FullConflict,
                    type: "conflict-type"
                }
            ],
            isEmployeeListLoading: false,
            isSingleDate: true as boolean,
            payorList: [] as ITaskPayor[],
            isPaymentSourceLoading: false as boolean,
            isTasksLoading: false as boolean,
            checkAllByDefault: false as boolean,
            visitDates: "" as string,
            recurrence: "" as string,
            shiftLength: 1 as number,
            startTime: "" as string,
            endTime: "" as string,
            scheduleWithoutTime: false as boolean,
            displayName: null as string,
            overrideShiftLength: false as boolean,
            dialogRef: null,
            formatDateInput,
            enumToMap,
            SaveEnum
        };
    },

    created() {
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
    },
    async mounted() {
        try {
            this.$emit("loadingChange", true);
            await this.getDisciplineTasks();
            this.newVisitDetails.application = this.visitDetails.Application;
            this.newVisitDetails.agencyId = this.visitDetails.AgencyId;
            this.newVisitDetails.patientId = this.visitDetails.Id;
            this.newVisitDetails.payorId = null;
            this.newVisitDetailsReset = JSON.parse(JSON.stringify(this.newVisitDetails));
        } catch (error) {
            console.log(error);
        } finally {
            this.$emit("loadingChange", false);
        }
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    watch: {
        visitDates() {
            this.newVisitDetails.recurrence.flexibleDates = this.visitDates.split(", ");
        },

        startTime() {
            this.startDateChanged();
        },

        endTime() {
            this.endTimeChanged();
        },

        shiftLength() {
            this.shiftLengthChanged();
        },

        overrideShiftLength() {
            if (!this.overrideShiftLength) this.shiftLengthChanged();
        },

        scheduleWithoutTime() {
            this.newVisitDetails.isAllDay = this.scheduleWithoutTime;
            if (!this.scheduleWithoutTime) {
                if (this.newVisitDetails.startDateTime.date && this.newVisitDetails.endDateTime.date) {
                    this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
                }
            }
        },
        "newVisitDetails.startDateTime.date"() {
            this.startDateChanged();
        },

        "newVisitDetails.startDateTime.time"() {
            this.startTimeChanged();
        },

        "newVisitDetails.endDateTime.date"() {
            this.getEmployeeList();
            this.endDateChanged();
        },

        "newVisitDetails.endDateTime.time"() {
            this.endTimeChanged();
        }
    },

    methods: {
        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : "";
        },

        startTimeChanged() {
            if (this.shiftLength && Number(this.shiftLength) > 0) {
                this.shiftLengthChanged();
            }
            if (
                this.newVisitDetails.startDateTime.time.indexOf("PM") != -1 &&
                this.newVisitDetails.endDateTime.time.indexOf("AM") != -1
            ) {
                this.isSingleDate = false;
            } else {
                this.isSingleDate = true;
            }
        },

        endTimeChanged() {
            if (
                this.newVisitDetails.startDateTime.time.indexOf("PM") != -1 &&
                this.newVisitDetails.endDateTime.time.indexOf("AM") != -1
            ) {
                this.isSingleDate = false;
            } else {
                this.isSingleDate = true;
            }
            if (this.newVisitDetails.startDateTime.time && this.newVisitDetails.endDateTime.time) {
                if (this.isSingleDate) {
                    this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
                } else {
                    this.newVisitDetails.endDateTime.date = moment(
                        this.newVisitDetails.startDateTime.date,
                        "MM/DD/YYYY"
                    )
                        .add(1, "day")
                        .format("MM/DD/YYYY");
                }
            }
        },

        shiftLengthChanged() {
            this.newVisitDetails.lengthOfShift = this.shiftLength;
            if (this.newVisitDetails.startDateTime.time)
                this.newVisitDetails.endDateTime.time = moment(this.newVisitDetails.startDateTime.time, "hh:mm A")
                    .add(Number(this.shiftLength), "hours")
                    .format("hh:mm A");
        },

        startDateChanged() {
            if (this.isSingleDate) {
                this.newVisitDetails.endDateTime.date = this.newVisitDetails.startDateTime.date;
            } else {
                this.newVisitDetails.endDateTime.date = moment(this.newVisitDetails.startDateTime.date, "MM/DD/YYYY")
                    .add(1, "day")
                    .format("MM/DD/YYYY");
            }
        },

        endDateChanged() {
            if (this.newVisitDetails.startDateTime.date && this.newVisitDetails.endDateTime.date) {
                this.getEmployeeList();
                this.getPaymentSource();
            }
        },

        async getDisciplineTasks() {
            try {
                const payload: IHomeCareTasksParams = {
                    agencyId: this.visitDetails.AgencyId,
                    application: ApplicationEnum.HomeCare
                };
                this.disciplineTasks = [];
                this.disciplineTasks = (await this._taskService.getHCTaskList(payload)).data.data;
                this.setDisciplineMultiSelect();
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Visit Type Failed`,
                    message: `Fetching Visit Type Operation Failed, Please Contact Axxess if issue still persists.`
                });
            }
        },

        async getEmployeeList() {
            try {
                if (!this.visitDetails) return;
                this.isEmployeeListLoading = true;
                this.employeeList = await this._taskService.homecareEmployeePreference({
                    PatientId: this.visitDetails.Id,
                    AgencyId: this.visitDetails.AgencyId,
                    StartDate: this.newVisitDetails.startDateTime.date ? this.newVisitDetails.startDateTime.date : "",
                    EndDate: this.newVisitDetails.endDateTime.date ? this.newVisitDetails.endDateTime.date : "",
                    SchedulerConflictTypes: this.selectedConflictTypes,
                    Application: ApplicationEnum.HomeCare,
                    LocationId: this.visitDetails.LocationId
                });
                this.employeeList = this.employeeList.map((emp) => {
                    if (emp.SchedulerConflictType == ConflictType.enum.NoConflict) {
                        emp.Icon = `<i class="fa fa-check-circle icon-green"></i>`;
                    } else if (emp.SchedulerConflictType == ConflictType.enum.PartialConflict) {
                        emp.Icon = `<i class="fa fa-exclamation-circle icon-gold"></i>`;
                    } else if (emp.SchedulerConflictType == ConflictType.enum.FullConflict) {
                        emp.Icon = `<i class="fa fa-times-circle icon-red"></i>`;
                    } else {
                        emp.Icon = "";
                    }
                    return emp;
                });
                this.setEmployeeMultiSelect();
            } catch (e) {
                throw e;
            } finally {
                this.isEmployeeListLoading = false;
            }
        },

        async setEmployeeMultiSelect() {
            try {
                this.employeeDropdownSelectOptions = {};
                if (!this.employeeList || this.employeeList?.length === 0) {
                    return;
                }
                this.employeeList.forEach((employee) => {
                    const labelKey = ConflictType.getDescriptionUsingValue(employee.SchedulerConflictType);
                    this.employeeDropdownSelectOptions[labelKey] = this.employeeList
                        .filter((ele) => {
                            return ConflictType.getDescriptionUsingValue(ele.SchedulerConflictType) == labelKey;
                        })
                        .map((child) => {
                            return {
                                displayText: child.DisplayName,
                                value: child.Id,
                                icons: child.Icon
                            };
                        });
                });
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },
        async setDisciplineMultiSelect() {
            try {
                this.taskDropdownSelectOptions = {};
                if (!this.disciplineTasks || this.disciplineTasks?.length === 0) {
                    return;
                }
                this.disciplineTasks.forEach((taskObject) => {
                    if (taskObject.label && Array.isArray(taskObject.children)) {
                        const labelKey = taskObject.label;
                        this.taskDropdownSelectOptions[labelKey] = taskObject.children.map((child) => {
                            return {
                                displayText: child.label,
                                value: child.id,
                                id: child.disciplineTaskId
                            };
                        });
                    }
                });
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },

        async getPaymentSource() {
            try {
                if (!this.visitDetails) return;
                this.isPaymentSourceLoading = true;
                const payorListPayload: IGetTaskPayorList = {
                    patientId: this.visitDetails.Id,
                    agencyId: this.visitDetails.AgencyId,
                    startDate: this.newVisitDetails.startDateTime.date,
                    endDate: this.newVisitDetails.endDateTime.date,
                    application: ApplicationEnum.HomeCare
                };
                const payors = await this._taskService.getTaskPayorsList(payorListPayload);
                this.payorList = payors.data;
            } catch (e) {
                console.log(e);
                this._toastrService.error({
                    title: `Fetching Payor List Failed`,
                    message: `Fetching Payor List Operation Failed, Please Contact Axxess if issue still persists.`
                });
            } finally {
                this.isPaymentSourceLoading = false;
            }
        },

        selectedItemsUpdated(value: number | Array<number>) {
            if (typeof value === "number") {
                if (this.selectedConflictTypes.includes(value)) {
                    const index = this.selectedConflictTypes.indexOf(value);
                    this.selectedConflictTypes.splice(index, 1);
                    this.getEmployeeList();
                    return;
                }
                this.selectedConflictTypes.push(value);
            } else {
                this.selectedConflictTypes = value;
            }
            this.getEmployeeList();
        },

        changeStartTime(time: any) {
            this.newVisitDetails.startDateTime.time = time;
        },

        changeEndTime(time: any) {
            this.newVisitDetails.endDateTime.time = time;
        },

        resultUpdated(data: any) {
            this.newVisitDetails.comments = data;
        },

        updateSelectFields(value: any, text: any, id: number) {
            this.newVisitDetails.customNoteId = value;
            this.newVisitDetails.taskName = text;
            this.newVisitDetails.disciplineTask = id;
        },

        updateSelectEmpFields(value: any, text: any, id: number) {
            this.newVisitDetails.userId = value;
            this.displayName = text;
        },

        updateResult(result: any) {
            this.visitDates = result;
        },

        handleRecurrenceChange(event: any) {
            const value = event.target.value;
            this.newVisitDetails.recurrence.type = value;
            this.newVisitDetails.recurrence.repeatBy = null;
            this.newVisitDetails.recurrence.repeatNumber = null;
            if (value == 3) {
                this.newVisitDetails.recurrence.repeatBy = 2;
                this.newVisitDetails.recurrence.repeatNumber = 1;
            } else if (value == 1 || value == 2) {
                this.newVisitDetails.recurrence.repeatNumber = 1;
            }
        },

        taskDateChanged(date: any, name: string) {
            if (name === "startDateTime") {
                this.newVisitDetails.startDateTime.date = date;
            } else if (name === "endDateTime") {
                this.newVisitDetails.endDateTime.date = date;
            } else if (name === "endDate") {
                this.newVisitDetails.recurrence.endDate = date;
            }
        },

        updateDaysOfWeek(value: string) {
            this.newVisitDetails.recurrence.daysOfWeek = value;
        },

        async scheduleVisit(saveType: string) {
            const valid = await this.v$.$validate();
            if (!valid) {
                return;
            }
            if (!!this.newVisitDetails) {
                try {
                    this.$emit("savingChange", true);
                    if (this.scheduleWithoutTime) {
                        this.newVisitDetails.eventStartTime = moment(
                            this.newVisitDetails.startDateTime.date + " 00:00 AM",
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                        this.newVisitDetails.eventEndTime = moment(
                            this.newVisitDetails.endDateTime.date + " 00:00 AM",
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        this.newVisitDetails.eventStartTime = moment(
                            this.newVisitDetails.startDateTime.date + " " + this.newVisitDetails.startDateTime.time,
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                        this.newVisitDetails.eventEndTime = moment(
                            this.newVisitDetails.endDateTime.date + " " + this.newVisitDetails.endDateTime.time,
                            "MM/DD/YYYY hh:mm A"
                        ).format("YYYY-MM-DDTHH:mm:ss");
                    }
                    const firstResponse = await this._taskService.scheduleVisitHC(this.newVisitDetails);
                    if (!firstResponse.IsSuccessful && firstResponse.ErrorDetails) {
                        // if no error list, then simply show the error and end
                        if (!firstResponse.ErrorDetails.TaskIssues.length) {
                            throw new Error(firstResponse.ErrorMessage);
                        }

                        const validationList: IRescheduleValidations[] = firstResponse.ErrorDetails.TaskIssues.map(
                            (item: any) => {
                                return {
                                    title: item.Title as string,
                                    description: [item.Message] as string[],
                                    taskIssueSurpressKey: item.TaskIssueSurpressKey as string
                                };
                            }
                        );
                        const hasError = firstResponse.ErrorDetails.AllTasksHaveErrors;

                        this.dialogRef = this.$dialog.open(RescheduleValidation, {
                            props: {
                                modal: true,
                                closeOnEscape: false,
                                style: {
                                    width: "60vw"
                                },
                                showHeader: false
                            },

                            data: {
                                validations: validationList,
                                name: "Employee",
                                component: "Schedule-visit",
                                hasError
                            },

                            onClose: async (options) => {
                                if (options.data === "cancel") {
                                    return;
                                } else {
                                    const issuesResponseObj: IHandleTaskReschuleIssue = {};
                                    for (let issue of validationList) {
                                        issuesResponseObj[issue.taskIssueSurpressKey] = "ScheduleAllAnyways";
                                    }

                                    this.newVisitDetails.HandleTaskIssues = issuesResponseObj;
                                    const secondResponse = await this._taskService.scheduleVisitHC(
                                        this.newVisitDetails
                                    );
                                    if (!secondResponse.IsSuccessful) {
                                        throw new Error(secondResponse.ErrorMessage);
                                    }
                                    this._toastrService.success({
                                        title: `Visit Scheduled`,
                                        message: `Visit has been successfully Scheduled.`
                                    });

                                    if (saveType == SaveEnum.SaveAndExit) {
                                        // trigger callback
                                        this.ok();
                                    } else {
                                        this.addAnother();
                                    }
                                }
                            }
                        });
                    } else if (!firstResponse.IsSuccessful) {
                        throw new Error(firstResponse.ErrorMessage);
                    } else {
                        this._toastrService.success({
                            title: `Visit Scheduled`,
                            message: `Visit has been successfully Scheduled.`
                        });
                    }

                    if (saveType == SaveEnum.SaveAndExit) {
                        // trigger callback
                        this.ok();
                    } else {
                        this.addAnother();
                    }
                } catch (e) {
                    console.warn(e);
                    this._toastrService.error({
                        title: `Visit Scheduled Failed`,
                        message: e
                            ? e
                            : `Scheduling Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.$emit("savingChange", false);
                }
            }
        },

        async openFindAMatch(dropEvent: any) {
            if (this.newVisitDetails.startDateTime.time && this.newVisitDetails.endDateTime.time) {
                const payload: IScheduleMatchRequest = {
                    application: this.newVisitDetails.application,
                    agencyId: this.newVisitDetails.agencyId,
                    clientName: `${this.visitDetails.FirstName},${this.visitDetails.LastName}`,
                    endDate: moment(
                        this.newVisitDetails.endDateTime.date + " " + this.newVisitDetails.endDateTime.time,
                        "MM/DD/YYYY hh:mm A"
                    ).format("YYYY-MM-DDTHH:mm:ss"),
                    startDate: moment(
                        this.newVisitDetails.startDateTime.date + " " + this.newVisitDetails.startDateTime.time,
                        "MM/DD/YYYY hh:mm A"
                    ).format("YYYY-MM-DDTHH:mm:ss"),
                    isAllDay: this.newVisitDetails.isAllDay,
                    locationId: this.visitDetails.LocationId,
                    patientId: this.visitDetails.Id,
                    payor: this.newVisitDetails.payorId,
                    task: this.newVisitDetails.taskName
                };

                this.dialogRef = this.$dialog.open(FindAMatch, {
                    props: {
                        modal: true,
                        closeOnEscape: false,
                        style: {
                            width: "82vw"
                        },
                        showHeader: false
                    },

                    data: {
                        payload
                    },

                    onClose: async (options) => {
                        if (options.data) {
                            this.newVisitDetails.userId = options.data.userId;
                            this.displayName = options.data.displayName;
                        }
                    }
                });
            } else {
                this._toastrService.error({
                    title: `Find a Match Failed`,
                    message: `Please select start time and end time in order to continue to find a match.`
                });
            }
        },

        detached() {
            this._subscriptions?.forEach((sub: any) => sub.dispose());
        }
    }
});
