import { RelationshipToInsured, ApplicationEnum } from "../../enums/enums";
import { PriorHospice } from "./prior-hospice";
export class PatientPayor {
    public id?: string = "";
    public agencyId?: string = "";
    public application?: ApplicationEnum;
    public accountId?: string = "";
    public createdBy: string = "";
    public agencyPayorId: string = "";
    public type: number;
    public medicarePartAEffectiveDate: string;
    public effectiveDate: string;
    public memberId: string;
    public groupName: string;
    public groupId: string;
    public employerName: string;
    public deductibleAmount: string;
    public deductibleMet: boolean;
    public copayAmount: string;
    public caseManager: string;
    public comments: string;
    public isMedicare: boolean = null;
    public isCharity: boolean = null;
    public isOtherPayor: boolean = null;
    public relationshipToInsured: number = null;
    public billAsType: number = null;
    public subscriberFirstName: string;
    public subscriberLastName: string;
    public requirePriorAuthorization?: boolean;
    public coinsuranceAmount?: number;
    public outOfPocketMaxAmount?: number;
    public isOutOfPocketMaxMet?: boolean;
    public isBenefitVerified?: boolean;
    public agencyPayorName: string;

    public constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
