import { defineComponent } from "vue";
import { mapState } from "pinia";

import { useEditReferralHH } from "../../../store/useEditReferralHH";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        FormSection
    },

    computed: {
        ...mapState(useEditReferralHH, {
            referral: "referral",
            application: "currentApplication"
        }),

        fetchMedicalNeedsCharLeft() {
            if (this.referral.EmergencyPreparedness.MedicalNeeds) {
                return this.medicalNeedsMaxLength - this.referral.EmergencyPreparedness.MedicalNeeds.length;
            } else {
                return this.medicalNeedsMaxLength;
            }
        }
    },
    data() {
        return {
            medicalNeedsMaxLength: 2000,
            emergencyTriageOptions: [
                {
                    label: "1. Life-threatening (or potential) and requires ongoing medical treatment. When necessary, appropriate arrangements for evacuation to an acute care facility will be made.",
                    value: 1
                },
                {
                    label: "2. Not life-threatening but would suffer severe adverse effects from interruption of services (i.e., daily insulin, IV medications, sterile wound care of a wound with a large amount of drainage).",
                    value: 2
                },
                {
                    label: "3. Visits could be postponed 24-48 hours without adverse effects (i.e., new insulin dependent diabetic able to self inject, sterile wound care with a minimal amount to no drainage).",
                    value: 3
                },
                {
                    label: "4. Visits could be postponed 72-96 hours without adverse effects (i.e., post-op with no open wound, anticipated discharge within the next 10-14 days, routine catheter changes).",
                    value: 4
                }
            ],
            additionalInformationOptionsEnum: {
                NeedsAssistance: {
                    label: "Needs assistance during an emergency",
                    value: "1"
                },
                ContactMadeWith: {
                    label: "Contact made with local/state emergency preparedness officials regarding patient in need of help during an evacuation",
                    value: "2"
                },
                MedicalNeedsEquipment: {
                    label: "Medical Needs/Equipment (i.e., bedbound, oxygen, vent, IV cardiac meds other DME)",
                    value: "3"
                }
            }
        };
    },
    methods: {
        isAddInformationOptions(option: string) {
            return this.referral.EmergencyPreparedness.EmergencyPreparedInfo.indexOf(option) > -1 ? true : false;
        },
        handleAddInformationOptions(value: string) {
            const index = this.referral.EmergencyPreparedness.EmergencyPreparedInfo.indexOf(value);
            if (index === -1) {
                this.referral.EmergencyPreparedness.EmergencyPreparedInfo.push(value);
            } else {
                this.referral.EmergencyPreparedness.EmergencyPreparedInfo.splice(index, 1);
            }
        }
    }
});
