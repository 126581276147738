import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { LookupService } from "../../../../../services/lookup-service";
import { ToastrService } from "../../../../../services/toastr.service";
import { PatientService } from "../../../../../services/patient.service";
import { DmeNeeded } from "../../../../../resources-vue/vue-models/dme-needed";
import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import type { IGetDmesResponse } from "../../../../../resources-vue/vue-interfaces/i-get-dmes";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";
import ErrorMessageHandler from "../../../../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";
import EditDmeNeededRow from "./EditDmeNeededRow.vue";

export default defineComponent({
    components: {
        FormSection,
        TypeaheadInput,
        ErrorMessageHandler,
        EditDmeNeededRow,
        CommentInput
    },
    props: {
        referral: { type: HomeCareReferral, default: null },
        patientId: { type: String, default: null }
    },
    data() {
        return {
            isLoading: false,
            isError: false,
            hasData: false,
            showTemplate: false,
            loadingCount: 3,
            columns: 5,
            dmeId: null as string,
            dme: null as DmeNeeded,
            dmes: null as DmeNeeded[],
            _lookupService: null,
            _toastrService: null,
            _patientService: null,
            commentInputClass: { input: "w-35" } as { input: string; textarea?: string }
        };
    },
    created() {
        this._lookupService = Container.instance.get(LookupService);
        this._toastrService = Container.instance.get(ToastrService);
        this._patientService = Container.instance.get(PatientService);
    },
    mounted() {
        this.dme = new DmeNeeded();
        this.getDme();
    },
    watch: {
        dmeId(newValue, oldValue) {
            if (newValue) {
                this.dme.dmeId = newValue.value;
            }
        }
    },
    methods: {
        changeDmeId(data: string) {
            this.dmeId = data;
        },
        resultUpdatedAppended(value: string) {
            this.referral.otherDME += value;
        },
        resultUpdated(value: string) {
            this.referral.otherDME = value;
        },
        async getDmesList(filter: string) {
            const data = await this._lookupService.getDmes({
                agencyId: this.referral.accountId,
                term: filter,
                application: this.referral.application
            });
            return data.map((item: IGetDmesResponse) => ({
                name: item.text,
                value: item.id
            }));
        },
        async addDme() {
            if (!this.dme.dmeId) {
                this._toastrService.error({
                    title: "Empty DME",
                    message: "Please select a DME"
                });
                return;
            }
            try {
                const response = await this._patientService.addPatientsDme({
                    dmeId: this.dme.dmeId,
                    patientId: this.patientId,
                    accountId: this.referral.accountId,
                    application: this.referral.application
                });
                if (response.isSuccessful) {
                    this.getDme();
                    this._toastrService.success({
                        title: "Success",
                        message: "DME has been successfully added!"
                    });
                } else {
                    this._toastrService.error({
                        title: "Error",
                        message: "Please select the DME or Selected DME is already assigned to this Client."
                    });
                }
            } catch (e) {
                this._toastrService.error({
                    title: "Error",
                    message: "There was a problem while adding a DME. Please try again."
                });
            }
        },
        async getDme() {
            try {
                this.isLoading = true;
                this.dmes = await this._patientService.getPatientsDme({
                    patientId: this.patientId,
                    accountId: this.referral.accountId,
                    application: this.referral.application
                });
                if (this.dmes.length > 0) {
                    this.hasData = true;
                } else {
                    this.hasData = false;
                }
            } catch (e) {
                this.isError = true;
            } finally {
                this.isLoading = false;
            }
        }
    }
});
