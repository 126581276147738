<template>
    <div>
        <input type="hidden" v-model="tagsLength" />
        <div ref="tagsWrapper" :class="`tags-wrapper ${classes} ${disabled ? 'disabled' : ''}`">
            <span class="tags-item" v-for="tag of tags">
                <template v-if="!showTagsByValue"> {{ tag.name }}</template>
                <template v-else>{{ tag.value }} </template>
                <i class="fas fa-times-circle tags-close" @click="removeTag(tag)" v-if="!disabled && showRemoveTag"></i>
            </span>
            <section class="tags-input">
                <TypeaheadInput
                    :open-on-focus="openOnFocus"
                    ref="typeaheadInputRef"
                    :getData="getData"
                    :data="tagsData"
                    :value="value"
                    v-on:valueChanged="updateValue"
                    :placeholder="placeholder"
                    :close-on-selection="closeOnSelection"
                    :disabled="disabled"
                    :hide-icon="true"
                    :results-limit="resultsLimit"
                    :showAllByDefault="true"
                    v-if="!displayOnly"
                    v-on:blurredInput="emitBlur"
                />
                <slot></slot>
            </section>
        </div>
    </div>
</template>

<style src="./TagsInput.scss"></style>
<script src="./TagsInput"></script>
