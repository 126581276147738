import { bindable, bindingMode, child, computedFrom, customElement, inject } from "aurelia-framework";

import nameOf from "../../common/name-of";

// Libraries
@customElement("scroll-pagination")
@inject(Element)
export class ScrollPagination {
    @child(".scroll-paginate")
    public scrollElement: Element;
    private el: Element;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public pageNumber: number = 1;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public nextPageOffset: number = 50;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public allPagesLoaded: boolean = false;

    public constructor(Element: Element) {
        this.el = Element;
    }

    @computedFrom(nameOf<ScrollPagination>("scrollElement"), nameOf<ScrollPagination>("pageNumber"))
    public get scrollableElement() {
        if (this.scrollElement) {
            return this.scrollElement;
        }
        return this.el;
    }

    public attached(): void {
        setTimeout(() => {
            this.handleScrolling();
        }, 100);
    }

    public pageNumberChanged(newValue: number, oldValue: number) {
        // console.log("Scroll pagination: ", { newValue, oldValue }, this.allPagesLoaded);
        // If newValue is less than old value and if the newValue is 1 then that means the page has been refreshed
        if (newValue < oldValue && newValue === 1) {
            this.handleScrolling();
            $(this.scrollableElement).scrollTop(0);
        }
    }

    public handleScrolling() {
        let scrollTimer: NodeJS.Timer = null;
        let $scrollableElement: JQuery<Element> = $(this.scrollableElement);
        let self: this = this;
        $scrollableElement.off("scroll").on("scroll", (e: any) => {
            if (scrollTimer) {
                clearTimeout(scrollTimer); // clear any previous pending timer
            }
            scrollTimer = setTimeout(() => {
                // console.log("Scroll Pagination: Page Scrolled", scrollTimer);
                let st: number = $scrollableElement.scrollTop();
                let sh: number = $scrollableElement[0].scrollHeight;
                let ht: number = $scrollableElement.height();
                let nextCallHt: number = sh - self.nextPageOffset;

                if (st + ht >= nextCallHt && !self.isLoading && !self.allPagesLoaded) {
                    // console.log(
                    //     "Scroll Pagination: Load next page",
                    //     st + ht >= nextCallHt,
                    //     !self.isLoading,
                    //     !self.allPagesLoaded
                    // );
                    self.pageNumber++;
                }
            }, 100); // set new timer
        });
    }

    public detached() {
        $(this.scrollableElement).off("scroll");
    }
}
