import { defineComponent } from "vue";
import { Router } from "aurelia-router";

export default defineComponent({
    props: {
        formTitle: { type: String, default: "no" },
        hasSideNav: { type: Boolean, default: false },
        hasButtons: { type: Boolean, default: false },
        hasTitle: { type: Boolean, default: false },
        router: { type: Router, default: null },
        pageTitle: { type: String, default: "" },
        contentClass: { type: String, default: "" },
        hasPageScroll: { type: Boolean, default: false },
        hasListScroll: { type: Boolean, default: false }
    },

    computed: {
        activeRouteName() {
            if (this.router && this.router.navigation.filter((nav) => nav.isActive).length > 0) {
                return this.router.navigation.filter((nav) => nav.isActive)[0].config.settings.menuName;
            } else {
                return "Menu";
            }
        },

        isFullPage() {
            if (this.router) {
                return this.router.parent == null;
            } else {
                return true;
            }
        }
    }
});
