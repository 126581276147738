import { defineComponent } from "vue";
import type { ITaskPayrollDetails } from "../../vue-interfaces/i-task";
import { formatDate } from "../../../common/vue-helpers/modifiers/value-modifier";
import EmptyTextHandler from "../../vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";

export default defineComponent({
    components: {
        EmptyTextHandler
    },
    props: {
        detail: {
            type: Object
        },
        isAddRequested: {
            type: Boolean
        },
        id: {
            type: String
        },
        snapshotView: {
            type: Boolean,
            default: false
        },
        isNewTerminal: {
            type: Boolean,
            default: false
        },
        cancelSaveNewDetail: {
            type: Function
        },
        saveNewDetail: {
            type: Function
        }
    },
    data(vm) {
        return {
            isEditRequested: false as boolean,
            columns: 0 as number,
            originalDetail: {} as ITaskPayrollDetails,
            selectedDetail: null as ITaskPayrollDetails,
            formatDate
        };
    },
    mounted() {
        this.originalDetail = { ...this.detail } as any;
        this.columns = this.snapshotView ? 9 : 8;
    },
    methods: {
        openEditDetail() {
            if (this.detail.id !== null) {
                this.selectedDetail = {
                    ...this.detail
                } as any;
            }
            this.isEditRequested = true;
        },
        cancelEditDetail() {
            this.isEditRequested = false;
            this.selectedDetail = null;
        },
        async saveEditDetail() {
            this.isEditRequested = false;
            this.originalDetail = { ...this.detail } as any;
            this.selectedDetail = null;
        },
        async save() {
            // this.isAddRequested = false;
            this.originalDetail = { ...this.detail } as any;
            this.selectedDetail = null;
            this.saveNewDetail();
        }
    }
});
