import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import moment from "moment";
import { mapActions } from "pinia";

import config from "../../common/config";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { useLoadingIndicator } from "../Common/LoadingIndicator/store/useLoadingIndicator";
import { ApplicationEnum } from "../../enums/enums";
import type { ITypeaheadOptions } from "../../resources-vue/vue-interfaces/i-typeahead";
import type { IGetPatientByBranchResponse } from "../../resources-vue/vue-interfaces/i-patient-service";
import type { IBranchResponse } from "../../resources-vue/vue-interfaces/i-branch";
import { type ITaskByUserRequest, type ITaskByUserResponse, TaskService } from "../../services/task.service";
import { PatientService } from "../../services/patient.service";
import { BranchesService } from "../../services/branches-service";
import { formatDateInput, formatDate } from "../../common/vue-helpers/modifiers/value-modifier";
import { IsReadOnlyTaskByName } from "../../common/vue-helpers/modifiers/read-only-tasks";

import DashboardHeader from "../../resources-vue/vue-elements/DashboardHeader/DashboardHeader.vue";
import ListLayout from "../../resources-vue/vue-custom-elements/ListLayout/ListLayout.vue";
import DatePicker from "../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import ErrorMessageHandler from "../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import MultiSelectFilter from "../../resources-vue/vue-elements/MultiSelectFilter/MultiSelectFilter.vue";
import EmployeeBulkReassign from "../../resources-vue/vue-forms/ReassignForms/EmployeeBulkReassign/EmployeeBulkReassign.vue";
import EmptyTextHandler from "../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";

export default defineComponent({
    components: {
        DashboardHeader,
        ListLayout,
        EmployeeBulkReassign,
        MultiSelectFilter,
        DatePicker,
        ErrorMessageHandler,
        EmptyTextHandler
    },
    data() {
        return {
            showPatients: false,
            showEmployees: false,
            hideTitle: false,
            isAddItemRequested: false,
            isError: false,
            isLoading: false,
            moveListDown: false,
            selectAll: false,
            isSelected: false,
            columns: 6,
            userId: "" as string | string[],
            employeeName: "" as string | string[],
            term: "" as string | string[],
            agencyId: "" as string | string[],
            routeTerm: "" as string | string[],
            selectedTaskId: [] as string[],
            selectedClients: [] as ITypeaheadOptions[],
            clients: [] as IGetPatientByBranchResponse[],
            tasks: [] as ITaskByUserResponse[],
            filteredTasks: [] as ITaskByUserResponse[],
            branchList: [] as IBranchResponse[],
            selectedTasks: [] as ITaskByUserResponse[],
            hospiceBranches: [] as IBranchResponse[],
            palliativeBranches: [] as IBranchResponse[],
            homeCareBranches: [] as IBranchResponse[],
            branches: [] as IBranchResponse[],
            selectedFilteredTasks: [],
            dateFrom: null as string,
            dateTo: null as string,
            branchId: null as string,
            application: null,
            branchRef: null,
            fullName: null,
            visitTime: null,
            _taskService: null,
            _patientService: null,
            _branchService: null,
            _permissionManager: null,
            taskFilterOptions: {
                start: null,
                end: null,
                userIds: [],
                agencyIds: []
                // weekdayRange: 1
            } as ITaskByUserRequest,
            formatDateInput,
            formatDate
        };
    },
    created() {
        this._taskService = Container.instance.get(TaskService);
        this._patientService = Container.instance.get(PatientService);
        this._branchService = Container.instance.get(BranchesService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },
    async beforeMount() {
        const params = this.$route.query;
        try {
            this.showLoading(true);
            this.routeTerm = params.term ? params.term : "";
            this.term = this.routeTerm;
            if (params?.agencyId) {
                this.agencyId = params.agencyId;
            }
            if (params?.fullname) {
                this.employeeName = params.fullname;
            }
            if (params?.application) {
                this.application = Number(params.application);
            }
            if (params?.userId) {
                this.userId = params.userId;
            }
            this.hideTitle = true;
            this.branches = await this._branchService.getAllBranches();
            this.branchList = this.branches.filter((branch) => branch.accountId == this.agencyId);
            this.hospiceBranches = this.branches.filter(
                (branch) => branch.application == ApplicationEnum.AxxessHospiceFE
            );
            this.palliativeBranches = this.branches.filter(
                (branch) => branch.application == ApplicationEnum.AxxessPalliative
            );
            this.homeCareBranches = this.branches.filter((branch) => branch.application == ApplicationEnum.HomeCare);
            this.dateFrom = moment().startOf("isoWeek").format("MM/DD/YYYY");
            this.dateTo = moment().startOf("isoWeek").add(6, "weeks").format("MM/DD/YYYY");
            this.branchId = this.branchList[0].id;
            this.taskRefresh();
        } catch (e) {
            console.error(e);
        } finally {
            this.showLoading(false);
        }
    },
    computed: {
        hasData() {
            return this.filteredTasks?.length > 0;
        },
        loadingCount() {
            return this.tasks?.length > 0 ? this.tasks.length : config.pageSize;
        }
    },
    watch: {
        async dateFrom(newValue, oldVaue) {
            this.taskFilterOptions = {
                ...this.taskFilterOptions,
                start: newValue
            };
            await this.taskRefresh();
            await this.getClient();
        },
        async branchId(newValue, oldValue) {
            if (newValue) {
                this.branchId = newValue;
            }
            await this.getClient();
            await this.taskRefresh();
        },
        async dateTo(newValue, oldValue) {
            this.taskFilterOptions = {
                ...this.taskFilterOptions,
                end: newValue
            };
            await this.taskRefresh();
            await this.getClient();
        },
        term(newValue, oldValue) {
            if (newValue.length > 2 || (newValue === "" && !!oldValue)) {
                const term = newValue.toLowerCase();
                this.filteredTasks = this.tasks.filter((task) => {
                    return (
                        task.PatientFirstName.toLowerCase().indexOf(term) != -1 ||
                        task.PatientLastName.toLowerCase().indexOf(term) != -1 ||
                        task.TaskName.toLowerCase().indexOf(term) != -1
                    );
                });
                return true;
            }
            this.filteredTasks = this.tasks;
            return true;
        },
        isLoading() {
            this.selectedTasks = [];
            this.filteredTasks = [];
            this.selectedTaskId = [];
            this.selectAll = false;
        }
    },
    methods: {
        ...mapActions(useLoadingIndicator, { showLoading: "SET_LOADING" }),
        closeReassignForm() {
            this.isAddItemRequested = false;
            this.moveListDown = false;
        },
        async pageReset() {
            this.isError = false;
            await this.taskRefresh();
        },
        async taskRefresh() {
            try {
                // this.isLoading = true;
                const patientIds = this.selectedClients.map((client) => client.value);
                const tasks = await this._taskService.getTasksByUsers({
                    start: this.dateFrom,
                    end: this.dateTo,
                    agencyIds: [this.agencyId + "_" + this.application],
                    userIds: [this.userId],
                    locationId: this.branchId,
                    patientId: patientIds
                });
                this.tasks = tasks;
                this.term = "";
                this.filteredTasks = this.tasks;
                this.selectAll = false;
                this.selectedTaskId = [];
            } catch (e) {
                console.error(e);
            }
        },
        async getClient() {
            const res = await this._patientService.getPatientByBranch({
                application: this.application,
                branchId: this.branchId,
                agencyId: this.agencyId,
                employeeId: this.userId,
                startDate: this.dateFrom,
                endDate: this.dateTo
            });
            this.clients = res;
        },
        selectedItemsUpdated(options: ITypeaheadOptions[]) {
            this.selectedClients = options;
            this.taskRefresh();
        },
        async userCredentialsFetch(filter: string): Promise<ITypeaheadOptions[]> {
            return this.clients
                .map((client, index) => ({
                    name: client.PatientName.FirstName + " " + client.PatientName.LastName,
                    value: client.Id
                }))
                .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
        },
        openReassignForm() {
            this.isAddItemRequested = true;
            this.moveListDown = true;
        },
        redirectToEmployeeList() {
            this.$router.push("/axxess-central/calendar/employee");
        },
        changeAllSelection() {
            if (!this.selectAll) {
                this.selectedTaskId = [];
            } else {
                this.selectedTaskId = this.filteredTasks
                    .filter((task) => !IsReadOnlyTaskByName(task.Status))
                    .map((task) => task.Id);
            }
        },
        selectedTasksChanged() {
            if (this.selectedTaskId.length === this.filteredTasks.length) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
        },
        changeDate(date: string, name: string) {
            if (name == "dateFrom") {
                this.dateFrom = date;
            }
            if (name == "dateTo") {
                this.dateTo = date;
            }
        },
        taskChanged(event: Event) {
            if (event) {
                this.isSelected = (event.target as HTMLInputElement)?.checked;
            }

            this.selectedTasksChanged();
        },
        getVisitTime(task: ITaskByUserResponse) {
            if (task.StartTime && task.EndTime) {
                const startTime = moment(task.StartTime).format("hh:mm A");
                const endTime = moment(task.EndTime).format("hh:mm A");
                return (this.visitTime = startTime + " - " + endTime);
            }
            return "Not Available";
        },
        getFullName(task: ITaskByUserResponse) {
            return (this.fullName = task.PatientFirstName + " " + task.PatientLastName);
        },
        isReadOnlyTask(task: ITaskByUserResponse) {
            if (!!task.Status) {
                return IsReadOnlyTaskByName(task.Status);
            } else {
                return true;
            }
        },
        handleDateInputChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.changeDate(value, name);
        }
    }
});
