import { defineComponent, computed } from "vue";
import { Container } from "aurelia-dependency-injection";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

import { AssetsService } from "../../../../../services/assets-service";
import { ToastrService } from "../../../../../services/toastr.service";
import { DocumentTypeCategory, ParentPermissionEnum, PermissionActionEnum } from "../../../../../enums/enums";
import { IGetDocumentTypeLean } from "../../../../../resources-vue/vue-interfaces/i-document-type";
import { ReferralService } from "../../../../../services/referral-service";
import { IPromptOptions } from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt";
import { formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import { IGetHHReferralDocumentsResponse } from "../../../../../resources-vue/vue-interfaces/i-get-referral";

import Prompt from "../../../../../resources-vue/vue-dialogs/Prompt/Prompt.vue";
import TableEmpty from "../../../../../resources-vue/vue-custom-elements/TableEmpty/TableEmpty.vue";
import EditHHDocument from "../../../../../resources-vue/vue-dialogs/EditHHDocument/EditHHDocument.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        TableEmpty,
        TypeaheadInput
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    props: {
        title: { type: String },
        patientId: { type: String },
        accountId: { type: String },
        application: { type: Number },
        referralId: { type: String },
        uploadDocument: { type: Function },
        getDocuments: { type: Function },
        editDocument: { type: Function },
        deleteDocument: { type: Function }
    },

    validations() {
        return {
            fileDetails: this.fileDetails.map((detail) => ({
                uploadTypeId: { required }
            }))
        };
    },

    data() {
        return {
            fileDetails: [],
            uploading: false,
            _assetsService: null,
            _toastrService: null,
            loadingDocuments: false,
            patientDocuments: [],
            addPatientDocumentsPermission: false,
            editPatientDocumentsPermission: false,
            deletePatientDocumentsPermission: false,
            searchedTypes: [],
            DocumentTypeCategoryEnum: DocumentTypeCategory,
            _referralService: null,
            _permissionManager: null,
            loadingCount: 5,
            dialogRef: null,
            selectedUploadType: undefined,
            v$: useVuelidate(),
            formatDate
        };
    },

    created() {
        this._assetsService = Container.instance.get(AssetsService);
        this._toastrService = Container.instance.get(ToastrService);
        this._referralService = Container.instance.get(ReferralService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },

    async mounted() {
        await this.loadSearchedTypes();
        this.addPatientDocumentsPermission = this._permissionManager.checkPermission(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canAdd,
            this.accountId
        );
        this.deletePatientDocumentsPermission = this._permissionManager.checkPermission(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canDelete,
            this.accountId
        );
        this.editPatientDocumentsPermission = this._permissionManager.checkPermission(
            ParentPermissionEnum.referrals,
            PermissionActionEnum.canEdit,
            this.accountId
        );
        this.loadFilesList();
    },

    methods: {
        async loadSearchedTypes() {
            if (!this.patientId) {
                this.searchedTypes = [];
            }
            let category = Number(this.DocumentTypeCategoryEnum.enum.Patient);
            let searchedTypes: IGetDocumentTypeLean[] = await this._referralService.getDocumentTypesList({
                providerIds: [],
                application: this.application,
                accountId: this.accountId,
                category
            });
            if (searchedTypes) {
                this.searchedTypes = searchedTypes.map((documentType: IGetDocumentTypeLean) => ({
                    value: documentType.id,
                    name: documentType.type
                }));
            } else {
                this.searchedTypes = [];
            }
        },

        updateTypeId(index: any, id: string) {
            this.fileDetails[index].uploadTypeId = id;
        },

        removeFile(index: number) {
            this.fileDetails.splice(index, 1);
        },

        removeAllFiles() {
            this.fileDetails = [];
        },

        uploadFilesChanged(event: any) {
            this.fileDetails = [...this.fileDetails, { file: event.target.files[0], uploadTypeId: null }];
        },

        async editSelectedDocument(document: IGetHHReferralDocumentsResponse) {
            this.dialogRef = this.$dialog.open(EditHHDocument, {
                props: {
                    modal: true,
                    contentStyle: {
                        overflowX: "hidden"
                    },
                    showHeader: false,
                    style: "width: 35vw"
                },
                data: {
                    document: document,
                    referralId: this.referralId,
                    application: this.application,
                    agencyId: this.accountId,
                    searchedTypes: this.searchedTypes
                },
                onClose: async (options) => {
                    if (options.data == "success") {
                        this.loadFilesList();
                        this._toastrService.success({
                            message: `The document has been successfully Edited.`,
                            title: `Document Edited`
                        });
                    } else if (options.data == "error") {
                        this._toastrService.error({
                            title: `Error - Document`,
                            message: `There was a problem while editing the document. Please try again.`
                        });
                    }
                }
            });
        },

        async deleteSelectedDocument(documentId: string) {
            try {
                let promptOptions: IPromptOptions = {
                    message: "Are you sure you want to delete?",
                    okText: "Yes",
                    cancelText: "No"
                };

                this.dialogRef = this.$dialog.open(Prompt, {
                    props: {
                        modal: true,
                        contentStyle: {
                            overflowX: "hidden"
                        },
                        showHeader: false
                    },
                    data: {
                        promptOptions
                    },
                    onClose: async (confirm) => {
                        if (confirm.data) {
                            await this.deleteDocument(documentId);
                            this.loadFilesList();
                            this._toastrService.success({
                                message: `The document has been successfully deleted.`,
                                title: `Document Deleted`
                            });
                        }
                    }
                });
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Document`,
                    message: `There was a problem while deleting the document. Please try again.`
                });
            }
        },

        getFileIcon(type: string) {
            if (!type) {
                return "fa-file";
            }
            if (type.match("image.*")) {
                return "fa-file-image";
            } else if (type.includes("wordprocessing")) {
                return "fa-file-word";
            } else if (type.includes("spreadsheet")) {
                return "fa-file-excel";
            } else if (type.includes("pdf")) {
                return "fa-file-pdf";
            } else {
                return "fa-file";
            }
        },

        async uploadDocuments() {
            const validated = await this.v$.$validate();
            if (validated) {
                try {
                    this.uploading = true;
                    for (let i = 0; i < this.fileDetails?.length; i++) {
                        const formData = new FormData();
                        formData.append(`attachment${i}`, this.fileDetails[i].file);
                        let payload = {
                            agencyId: this.accountId,
                            application: this.application,
                            referralId: this.referralId,
                            uploadTypeId: this.fileDetails[i].uploadTypeId,
                            fileName: this.fileDetails[i].file.name
                        };
                        formData.append("request", JSON.stringify(payload));
                        await this.uploadDocument(formData);
                    }
                    this.removeAllFiles();
                    this.loadFilesList();
                    this._toastrService.success({
                        title: `Upload Successful`,
                        message: `The files have been successfully uploaded`
                    });
                } catch (error) {
                    await this.showUploadError();
                }
                this.uploading = false;
            } else {
                this._toastrService.error({
                    title: `Error`,
                    message: `Please fill required Fields`
                });
            }
        },

        async loadFilesList() {
            this.loadingDocuments = true;
            this.patientDocuments = await this.getDocuments();
            this.loadingDocuments = false;
        },

        async showUploadError() {
            this._toastrService.error({
                title: `Error`,
                message: `There was a problem while uploading files. Please try again.`
            });
        },

        isFieldRequired(index: number) {
            return this.v$.$errors.find((error) =>
                error.$propertyPath === `fileDetails.${index}.uploadTypeId` ? true : false
            );
        }
    }
});
