import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import moment from "moment";

import { ApplicationNames, LineOfServices, ApplicationEnum } from "../../enums/enums";
import { ProvidersService } from "../../services/providers-service";
import { BranchesService } from "../../services/branches-service";
import { CentralIntakeService } from "../../services/central-intake.service";
import { ReferralService } from "../../services/referral-service";
import { IGetProviderItem } from "../../resources-vue/vue-interfaces/i-providers-service";
import { ICreateInterimReferralPayload } from "../../resources-vue/vue-interfaces/i-get-referral";
import {
    INonAdmitReasonQuery,
    INonAdmitReasonsListItem,
    IBranchResponse
} from "../../resources-vue/vue-interfaces/i-branch";
import { IAgencyServices } from "../../resources-vue/vue-interfaces/i-agency";
import { formatPhone, formatDateFromDate } from "../../common/vue-helpers/modifiers/value-modifier";
import { ToastrService } from "../../services/toastr.service";

import PageLayout from "../../resources-vue/vue-custom-elements/PageLayout/PageLayout.vue";
import FormSection from "../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import Pagination from "../../resources-vue/vue-elements/Pagination/Pagination.vue";
import ErrorMessageHandler from "../../resources-vue/vue-custom-elements/ErrorMessageHandler/ErrorMessageHandler.vue";
import FormatName from "../../resources-vue/vue-custom-elements/FormatName/FormatName.vue";
import EmptyTextHandler from "../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import NewTypeahead from "../../resources-vue/vue-custom-elements/NewTypeahead/NewTypeahead.vue";
import DatePicker from "../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";


export default defineComponent({
    components: {
        NewTypeahead,
        DatePicker,
        FormatName,
        EmptyTextHandler,
        FormSection,
        PageLayout,
        Pagination,
        ErrorMessageHandler
    },

    props: {
        patient: { type: Object },
        agencyServices: { type: Array<IAgencyServices> },
        checkProductPermission: { type: Object }
    },

    validations() {
        return {
           referTo:{required},
           selectedBranch:{required:requiredIf(this.referTo!==null)},
           nonAdmitDate:{required:requiredIf(this.isNonAdmit===true)},
           selectedNonAdmitReason:{required:requiredIf(this.isNonAdmit==true)}
        };
    },

    data() {
        return {
            _toastrService: null,
            showTransferForm: false,
            isNonAdmit: false,
            nonAdmitDate: "",
            nonAdmitReason: "",
            nonAdmitReasonId: null,
            selectedNonAdmitReason: "",
            ApplicationNamesEnum: ApplicationNames,
            lineOfServiceEnumMap: LineOfServices,
            ApplicationEnum,
            providerList: [],
            allProviderList: [],
            allBranchList: [],
            nonAdmitReasons: [],
            referTo: null as IAgencyServices,
            referableAgencyServices:null,
            selectedBranch: null,
            locationId: null,
            accountId: null,
            _providersService: null,
            _branchesService: null,
            _centralIntakeService: null,
            _referralService: null,
            v$: useVuelidate(),
            listOfStatuses: [
                "Active",
                "Discharge",
                "Discharged",
                "Pending",
                "Non-Admitted",
                "NonAdmit",
                "Non-Admit",
                "Hospitalized",
                "NonAdmission",
                "None",
                "Non-Admit",
                "Hospitalized",
                null
            ],
            formatPhone,
            formatDateFromDate
        };
    },

    watch: {
        selectedBranch(newValue: any) {
            if (!newValue) {
                this.locationId = "";
                return;
            }
            this.locationId = this.selectedBranch.id;
            this.accountId = this.selectedBranch.accountId;
        },
        selectedNonAdmitReason(newValue: any) {
            if (newValue) {
                this.nonAdmitReasonId = newValue.value;
                this.nonAdmitReason = newValue.name;
            } else {
                this.nonAdmitReasonId = null;
                this.nonAdmitReason = "";
            }
        }
    },
    async created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._providersService = Container.instance.get(ProvidersService);
        this._branchesService = Container.instance.get(BranchesService);
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._referralService = Container.instance.get(ReferralService);
        const providers = await this._providersService.getProvider();
        this.allBranchList = await this._branchesService.getAllBranches();
        this.allProviderList = providers.filter((provider: any) => provider.branches.length > 0);
        this.referableAgencyServices = this.agencyServices.filter((agency) => agency.id !== this.patient.application)
        if(this.patient.status === "NonAdmission"){
            this.patient.status = "Non-Admitted"
        }
    },
    methods: {
        toggleShowTransferForm() {
            this.showTransferForm = !this.showTransferForm;
            this.getNonAdmitReasons();
        },
        updateBranches(application: number) {
            this.providerList = [];

            if ([ApplicationEnum.AxxessHospiceFE, ApplicationEnum.AxxessPalliative].includes(application)) {
                this.providerList = this.allProviderList.filter(
                    (provider) => provider.branches[0].application == application
                );
            } else {
                let providers: { [key: string]: string } = {};
                this.allBranchList.forEach((item) => {
                    if (item.providerId && item.application == application) {
                        providers[item.providerId] = item.providerName;
                    }
                });
                for (let providerItem of Object.keys(providers)) {
                    let obj: IGetProviderItem = { id: null, name: null, branches: null };
                    let branch: IBranchResponse[] = [];
                    this.allBranchList.forEach((item) => {
                        if (providerItem == item.providerId) branch.push(item);
                    });
                    obj.id = providerItem;
                    obj.branches = branch;
                    obj.name = providers[providerItem];
                    this.providerList.push(obj);
                }
            }
        },
        async getNonAdmitReasons() {
            let providerIds: string[] = [];
            this.allBranchList.forEach((branch: any) => {
                if (branch.accountId == this.patient.accountId && !providerIds?.includes(branch.providerId)) {
                    providerIds.push(branch.providerId);
                }
            });
            const objQuery: INonAdmitReasonQuery = {
                accountId: this.patient.accountId,
                providerIds: providerIds,
                application: this.patient.application
            };
            const nonAdmitReasonsList = await this._centralIntakeService.getNonAdmitReasons(objQuery);
            if (nonAdmitReasonsList.data) {
                this.nonAdmitReasons = nonAdmitReasonsList.data?.map((reason: INonAdmitReasonsListItem) => ({
                    value: reason.id,
                    name: reason.reason,
                    commentRequired: reason.isCommentRequired || false
                }));
            }
        },

        closeNonAdmitForm() {
            this.showTransferForm = !this.showTransferForm;
        },

        async convertToRefer() {
            const validated=await this.v$.$validate();
            if(validated){
            const isRefer = true;
            this.nonAdmitDate = moment(this.nonAdmitDate, "MM/DD/YYYY").format("MM-DD-YYYY");
            
            //temporary fix, BE will fix it later on their side
            if(this.patient.application === ApplicationEnum.AxxessPalliative && this.patient.isPatient){
                this.patient.id =this.patient.patientId
            }
   
            if (
                this.referTo.id == ApplicationEnum.AxxessHospiceFE ||
                this.referTo.id == ApplicationEnum.AxxessPalliative
            ) {
                let lineOfService: number;
                if (this.referTo.id == ApplicationEnum.AxxessHospiceFE) {
                    lineOfService = this.lineOfServiceEnumMap.enum.Hospice;
                } else {
                    lineOfService = this.lineOfServiceEnumMap.enum.PalliativeCare;
                }
                const payload: ICreateInterimReferralPayload = {
                    accountId: this.accountId,
                    agencyId: this.accountId,
                    application: this.referTo.id,
                    firstName: this.patient?.patientName?.firstName,
                    lastName: this.patient?.patientName?.lastName,
                    lineOfService: lineOfService,
                    locationId: this.locationId
                };
                const response = await this._referralService.createInterimReferral(payload);
                let referralId = response.referralId;
                let generatedPatientId = response.patientId;

                if (this.isNonAdmit) {
                    this.$router.push(
                        `/axxess-central/create/referral/${referralId}/account/${this.accountId}/application/${this.referTo.id}/isRefer/${isRefer}/isNonAdmit/${this.isNonAdmit}/nonAdmitReason/${this.nonAdmitReason}/nonAdmitReasonId/${this.nonAdmitReasonId}/nonAdmitDate/${this.nonAdmitDate}/patientId/${this.patient.id}/patientApplication/${this.patient.application}/patientBranchId/${this.patient.accountId}/newPatientId/${generatedPatientId}/locationId/${this.locationId}/isPatient/${this.patient.isPatient}/?isCreate=true`
                    );
                } else {
                    this.$router.push(
                        `/axxess-central/create/referral/${referralId}/account/${this.accountId}/application/${this.referTo.id}/isRefer/${isRefer}/isNonAdmit/${this.isNonAdmit}/patientId/${this.patient.id}/patientApplication/${this.patient.application}/patientBranchId/${this.patient.accountId}/newPatientId/${generatedPatientId}/locationId/${this.locationId}/isPatient/${this.patient.isPatient}/?isCreate=true`
                    );
                }
            } else if (this.referTo.id == ApplicationEnum.AgencyCore) {
                if (this.isNonAdmit) {
                    this.$router.push(
                        `/axxess-central/create/homehealth-referral/0000000-0000-0000-0000-0000000000000/account/${this.accountId}/application/${this.referTo.id}/?isCreate=true&isRefer=${isRefer}&isNonAdmit=${this.isNonAdmit}&nonAdmitReason=${this.nonAdmitReason}&nonAdmitReasonId=${this.nonAdmitReasonId}&nonAdmitDate=${this.nonAdmitDate}&patientId=${this.patient.id}&patientApplication=${this.patient.application}&patientBranchId=${this.patient.accountId}&locationId=${this.locationId}&isPatient=${this.patient.isPatient}`
                    );
                } else {
                    this.$router.push(
                        `/axxess-central/create/homehealth-referral/0000000-0000-0000-0000-0000000000000/account/${this.accountId}/application/${this.referTo.id}/?isCreate=true&isRefer=${isRefer}&isNonAdmit=${this.isNonAdmit}&patientId=${this.patient.id}&patientApplication=${this.patient.application}&patientBranchId=${this.patient.accountId}&locationId=${this.locationId}&isPatient=${this.patient.isPatient}`
                    );
                }
            } else if (this.referTo.id == ApplicationEnum.HomeCare) {
                if (this.isNonAdmit) {
                    this.$router.push({
                        path: "/axxess-central/create/homecare-referral",
                        query: {
                            account: this.accountId,
                            isNonMedical: String(this.referTo.isNonMedical),
                            isCreate: "true",
                            locationId: this.locationId,
                            isRefer: String(isRefer),
                            isNonAdmit: String(this.isNonAdmit),
                            patientId: this.patient.id,
                            patientApplication: this.patient.application,
                            patientAgencyId: this.patient.accountId,
                            patientAgencyName: this.patient.agencyName,
                            isPatient: String(this.patient.isPatient),
                            nonAdmitReason: this.nonAdmitReason,
                            nonAdmitReasonId: this.nonAdmitReasonId,
                            nonAdmitDate: this.nonAdmitDate
                        }
                    });
                } else {
                    this.$router.push({
                        path: "/axxess-central/create/homecare-referral",
                        query: {
                            account: this.accountId,
                            isNonMedical: String(this.referTo.isNonMedical),
                            isCreate: "true",
                            locationId: this.locationId,
                            isRefer: String(isRefer),
                            isNonAdmit: String(this.isNonAdmit),
                            patientId: this.patient.id,
                            patientApplication: this.patient.application,
                            patientAgencyId: this.patient.accountId,
                            patientAgencyName: this.patient.agencyName,
                            isPatient: String(this.patient.isPatient)
                        }
                    });
                }
            } else {
                this.$router.push(
                    `/axxess-central/create/homehealth-referral/0000000-0000-0000-0000-0000000000000/account/${this.accountId}/application/${this.referTo.id}/?isCreate=true`
                );
            }
        }else{
            this._toastrService.error({
                title: `Validation Error`,
                message:'Please fill required fields'
               
            });
        }
        },

        updateNonAdmitDate(date: string) {
            this.nonAdmitDate = date;
        },

        isNonAdmitDisabled() {
            return (
                this.patient.status === "Discharged" ||
                this.patient.status === "Discharge" ||
                this.patient.status === "Active" ||
                this.patient.status === "NonAdmission" ||
                this.patient.status === "NonAdmit" ||
                this.patient.status === "Non-Admitted" ||
                this.patient.status==="Non-Admit" ||
                this.patient.status==="Hospitalized"
            );
        }
    }
});
