import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "sortable",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx._handleMouseUp && _ctx._handleMouseUp(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("i", {
      class: _normalizeClass(["ml-1 fas", _ctx.iconClass])
    }, null, 2 /* CLASS */)
  ]))
}