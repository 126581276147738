<template>
    <NewTypeahead
        :data="list"
        v-model:selected-option="country"
        placeholder="Type to Search Country..."
        :disabled="disabled"
        v-on:blurredInput="emitBlur"
    >
        <template replace-part="label">
            <span :class="`flag-icon fi-${twoLetterCode.toLowerCase()} mr-1`"></span>
        </template>
    </NewTypeahead>
</template>

<script src="./CountryTypeahead"></script>
