import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

const CommonKeywords = ["create", "enter", "add"];

export const CreateRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "interim-referral"
    },
    {
        name: "interim-referral",
        route: "interim/referral",
        moduleId: PLATFORM.moduleName("./create-interim-referral/create-interim-referral", "create-interim-referral"),
        href: "interim/referral",
        nav: true,
        title: "Referral",
        settings: {
            menuName: "Referral",
            keywords: CommonKeywords,
            permission: true
        }
    },
    {
        name: "referral",
        route: "referral/:id?/account/:accountId?/application/:applicationId?",
        moduleId: PLATFORM.moduleName("./create-referral/create-referral", "create-referral"),
        href: "create/referral",
        nav: true,
        settings: {
            permission: false
        }
    },
    {
        name: "homehealth-referral",
        route: "homehealth-referral/:id?/account/:accountId?/application/:applicationId?",
        moduleId: PLATFORM.moduleName(
            "./create-referral/home-health-referral/create-homehealth-referral",
            "create-referral"
        ),
        href: "create/homehealth-referral",
        nav: true,
        settings: {
            permission: false
        }
    },
    {
        name: "homecare-referral",
        route: "homecare-referral",
        moduleId: PLATFORM.moduleName(
            "./create-referral/home-care-referral/create-homecare-referral",
            "create-referral"
        ),
        href: "create/homecare-referral",
        nav: true,
        settings: {
            permission: false
        }
    }
];
