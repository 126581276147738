import { ValidationRules } from "aurelia-validation";

import type { IBulkReassignVisitDetails } from "../interfaces/i-bulk-reassign-visit";
import { ApplicationEnum } from "../enums/enums";

export class BulkReassign implements IBulkReassignVisitDetails {
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public AgencyId: string;
    public AgencyName: string;
    public Mrn: string;
    public UserId: string;
    public PatientId: string;
    public Credentials: string;
    public Application: ApplicationEnum;
    public StartDate: string;
    public EndDate: string;
    public EmployeeTo: string;
    public EmployeeFrom: string;
    public TypeId: number;
    public ReasonId: string;
    public LocationId?: string;

    public constructor(data?: IBulkReassignVisitDetails) {
        if (!!data) {
            Object.assign(this, data);
        }
    }

    public initValidations() {
        ValidationRules.ensure((x: BulkReassign) => x.EmployeeFrom)
            .required()
            .withMessage("Employee From is required.")
            .ensure((x: BulkReassign) => x.EmployeeTo)
            .required()
            .withMessage("Employee To is required.")
            .ensure((x: BulkReassign) => x.StartDate)
            .required()
            .withMessage("Date From is required.")
            .ensure((x: BulkReassign) => x.EndDate)
            .required()
            .withMessage("Date To is required.")
            .satisfies((value) => {
                return value >= this.StartDate;
            })
            .withMessage("Date To should be greater than or equal to Date From")
            .ensure((x: BulkReassign) => x.ReasonId)
            .required()
            .withMessage("Reason is required.")
            .ensure((x: BulkReassign) => x.TypeId)
            .required()
            .withMessage("Type is required.")
            .on(this);
    }
}
