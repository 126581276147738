import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import config from "../../../../../common/config";
import { AgencyService } from "../../../../../services/agency.service";
import { UserService } from "../../../../../services/user-service";
import { PermissionManager } from "../../../../../common/utilities/permission-manager";
import {
    ApplicationEnum,
    ApplicationNameEnum,
    ParentPermissionEnum,
    PermissionActionEnum,
    EmployeesScheduledFilter
} from "../../../../../enums/enums";
import { enumToMap } from "../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { IMultiSelectDropDownOption } from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect";

import MultiSelect from "../../../../../resources-vue/vue-elements/MultiSelect/MultiSelect.vue";
import MultiSelectFilter from "../../../../../resources-vue/vue-elements/MultiSelectFilter/MultiSelectFilter.vue";

export default defineComponent({
    components: {
        MultiSelect,
        MultiSelectFilter
    },

    props: {
        startDate: { type: Date, default: null },
        endDate: { type: Date, default: null }
    },

    data(vm) {
        return {
            _userService: null,
            _agencyService: null,
            _permissionManager: null,
            agencyMultiSelectOptions: {} as IMultiSelectDropDownOption,
            selectedAgencies: [],
            selectedCredentials: [],
            credentialsList: [],
            organizationsList: [],
            organizations: [],
            agencyList: [],
            term: "",
            ScheduledFilterEnums: EmployeesScheduledFilter,
            scheduleFilter: 0,
            selectedOrganization: null,
            searchTimer: null,
            organizationName: "",
            enumToMap
        };
    },
    created() {
        this._agencyService = Container.instance.get(AgencyService);
        this._userService = Container.instance.get(UserService);
        this._permissionManager = Container.instance.get(PermissionManager);
    },
    async mounted() {
        try {
            this.credentialsList = await this._userService.getUserCredentials();
            this.organizations = await this._agencyService.getOrganizations();
            this.organizationName = window.localStorage.getItem(config.organizationName);
            this.agencyList = this.organizations.find(
                (organization: { name: string }) => organization.name === this.organizationName
            ).agencies;
            this.agencyList = this.agencyList.map((agency) => {
                let agencyAndApplicationIds = agency.id.split("_");
                let application = parseInt(agencyAndApplicationIds[1], 10);
                if (application == 256) {
                    application = ApplicationEnum.AxxessHospice;
                }
                return {
                    ...agency,
                    Application: application
                };
            });
            await this.setAgencyMultiSelect();
        } catch (e) {
            console.error(e);
        }
    },
    emits: ["employeeRefresh"],
    watch: {
        term: function (newValue, oldValue) {
            this.termChanged(newValue, oldValue);
        },
        scheduleFilter() {
            this.emitEmployeeRefresh();
        }
    },
    methods: {
        async setAgencyMultiSelect() {
            try {
                this.agencyMultiSelectOptions = {};
                this.selectedAgencies.length = 0;
                if (!this.agencyList || this.agencyList?.length === 0) {
                    return;
                }
                let allowedProducts = [
                    ApplicationEnum.AgencyCore,
                    ApplicationEnum.HomeCare,
                    ApplicationEnum.AxxessHospice,
                    ApplicationEnum.AxxessPalliative
                ];
                const schedulerViewPermissions = this._permissionManager.getPermmission(
                    ParentPermissionEnum.scheduler,
                    PermissionActionEnum.canView
                );
                let schedulerAgencies = schedulerViewPermissions
                    .filter((item: any) => {
                        if (!item) {
                            return false;
                        }
                        let application = item.application;
                        if (application === ApplicationEnum.AxxessHospiceFE) {
                            application = ApplicationEnum.AxxessHospice;
                        }
                        return allowedProducts.includes(application);
                    })
                    .map((x: any) => x.agencyId);
                let filterableProducts = this.agencyList.filter((agency) => {
                    let isAllowed = allowedProducts.includes(agency.Application);
                    let agencyId = agency.id?.split("_")[0];
                    return isAllowed && schedulerAgencies.includes(agencyId);
                });
                filterableProducts?.forEach((agency) => {
                    let appName = ApplicationNameEnum[agency.Application];

                    if (!this.agencyMultiSelectOptions[appName]) {
                        this.agencyMultiSelectOptions[appName] = [];
                    }

                    this.agencyMultiSelectOptions[appName].push({
                        displayText: agency.name,
                        value: agency.id
                    });
                });

                Object.values(this.agencyMultiSelectOptions).forEach((agency: any) => {
                    let values = agency.map((item: any) => item.value);
                    this.selectedAgencies.push(...values);
                });
                // For select agencies change
                await this.emitEmployeeRefresh();
            } catch (e) {
                console.warn(e);
                // Silent fail
            }
        },
        async emitEmployeeRefresh() {
            this.$emit("employeeRefresh", {
                term: this.term,
                agencies: this.selectedAgencies,
                credentials: this.selectedCredentials?.map((credential) => credential.name),
                scheduleFilter: this.scheduleFilter,
                startDate: this.startDate,
                endDate: this.endDate
            });
        },
        async termChanged(newValue: string, oldValue: string) {
            if (newValue !== oldValue) {
                clearTimeout(this.searchTimer);

                this.searchTimer = setTimeout(
                    async (term) => {
                        if (term?.length > 2 || term === "") {
                            await this.emitEmployeeRefresh();
                        }
                    },
                    500,
                    this.term
                );
            }
        },
        userCredentialsFetch(filter: string) {
            return this.credentialsList
                .map((credential, index) => ({
                    name: credential,
                    value: index.toString()
                }))
                .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
        },
        updateBranches(branches: any) {
            this.selectedAgencies.length = 0;
            this.selectedAgencies.push(...branches);
            this.emitEmployeeRefresh();
        },
        selectedItemsUpdated(options: any) {
            this.selectedCredentials = options;
            this.emitEmployeeRefresh();
        }
    }
});
