import { bindable, bindingMode, computedFrom, customElement } from "aurelia-framework";
import { Moment } from "moment";
import * as moment from "moment-timezone";

import nameOf from "../../common/name-of";

@customElement("date-time")
export class DateTime {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public dateFormat: string = "dddd, MMM DD, YYYY";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public timeFormat: string = "hh:mm A";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public today: moment.Moment = moment();
    private _interval: NodeJS.Timer;

    @computedFrom(nameOf<DateTime>("today"))
    public get timeZone() {
        return moment(this.today).tz(moment.tz.guess()).format("z");
    }

    public attached(): void {
        this._interval = setInterval(() => {
            this.update();
        }, 1000);
    }

    public update(): void {
        this.today = moment();
    }

    public detached(): void {
        clearInterval(this._interval);
    }
}
