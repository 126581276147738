import { autoinject } from "aurelia-framework";

import { FetchClient } from "../common/fetch-client";
import { ApplicationEnum } from "../enums/enums";
import type { IGetTemplateQuery, IGetTemplateResult } from "../resources-vue/vue-interfaces/i-get-template";
import { Template } from "../models/template";

@autoinject()
export class TemplateService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/Template/templates";

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async getTemplateByPage(query: IGetTemplateQuery) {
        return await this._fetchClient.build(`${this._baseUrl}`).useQueryString(query).fetch<IGetTemplateResult>();
    }

    public async getTemplateById(templateId: string, query?: { accountId: string; application: ApplicationEnum }) {
        return await this._fetchClient.build(`${this._baseUrl}/${templateId}`).useQueryString(query).fetch<Template>();
    }
}
