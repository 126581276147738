import { autoinject, bindable, bindingMode, containerless, observable } from "aurelia-framework";
import moment from "moment";

import nameOf from "../../../../../common/name-of";
import type { IAvailableTaskItems } from "../../../../../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import type { IGetTaskFrequencyResponse } from "../../../../../resources-vue/vue-interfaces/i-patient-task-hos-pal";
import { TaskService } from "../../../../../services/task.service";
import type { IReassignVisitDetails } from "../../../../../interfaces/i-reassign-visit";
import { Discipline } from "../../../../../enums/enums";
@containerless
@autoinject
export class FrequencyCheck {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public VisitDetails: IReassignVisitDetails;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isOutsideFrequency: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameOf<FrequencyCheck>("selectedTaskChanged") })
    public selectedTask: IAvailableTaskItems;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameOf<FrequencyCheck>("visitDatesChanged") })
    public visitDates: string[] = [];
    private _taskService: TaskService;
    public availableFrequencies: IGetTaskFrequencyResponse[] = [];
    public isError: boolean = false;
    public isLoading: boolean = false;
    public disciplineName: string;

    public constructor(taskService: TaskService) {
        this._taskService = taskService;
    }

    public async selectedTaskChanged() {
        if (!this.selectedTask) {
            return;
        }
        if (this.selectedTask.discipline) {
            this.disciplineName = Discipline.getDescriptionUsingValue(this.selectedTask.discipline);
        } else {
            this.disciplineName = "";
        }
        await this.checkFrequencyCompliance();
    }

    public async visitDatesChanged() {
        await this.checkFrequencyCompliance();
    }

    private async checkFrequencyCompliance() {
        try {
            this.isError = false;
            this.isLoading = true;
            const hasStartDates = this.visitDates && this.visitDates.length > 0;
            const isTaskIncludedInFreq = this.selectedTask && this.selectedTask.isIncludedInFrequency;
            if (isTaskIncludedInFreq && hasStartDates) {
                const response = await this._taskService.getTaskFrequency({
                    patientId: this.VisitDetails.Id,
                    agencyId: this.VisitDetails.AgencyId,
                    isPrn: this.selectedTask.isPrn,
                    discipline: this.selectedTask.discipline,
                    visitDates: this.visitDates,
                    application: this.VisitDetails.Application
                });
                const withInFreqByDate = new Map<string, IGetTaskFrequencyResponse>();
                const withinFreqDates: string[] = [];
                response.forEach((item) => {
                    const mVisitDate = moment(item.visitDate).format("MM/DD/YYYY");
                    withInFreqByDate.set(mVisitDate, item);
                    withinFreqDates.push(mVisitDate);
                });
                this.availableFrequencies = this.visitDates.map((visitDate) => {
                    const visitOutsideFreq: IGetTaskFrequencyResponse = {
                        visitDate: null,
                        frequency: null,
                        frequencyId: null,
                        startDate: null,
                        endDate: null
                    };
                    if (withinFreqDates.includes(visitDate)) {
                        return withInFreqByDate.get(visitDate);
                    } else {
                        return Object.assign(visitOutsideFreq, { visitDate });
                    }
                });
                this.isOutsideFrequency = this.availableFrequencies.some(
                    (availableFrequency) => !availableFrequency.frequency
                );
            }
        } catch (e) {
            console.error(e);
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    public deleteVisitDate(visitDate: string) {
        this.visitDates = this.visitDates.filter((item) => item !== visitDate);
    }
}
