import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-813b857e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-0" }
const _hoisted_2 = { class: "h-100 d-flex flex-column" }
const _hoisted_3 = { class: "card-body mx-lg-5 py-2" }
const _hoisted_4 = { class: "text-capitalize" }
const _hoisted_5 = { class: "d-flex flex-row align-items-center mb-1 card p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    "has-title": _ctx.hasTitle,
    "form-title": _ctx.formTitle,
    "has-list-scroll": _ctx.hasListScroll
  }, _createSlots({
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode(" CREATE FORM "),
        _withDirectives(_createElementVNode("section", {
          id: "ListLayout__Content",
          class: _normalizeClass(["card px-0 material-card mb-2 animated", _ctx.showForm ? 'faster fadeInDown' : 'slow fadeOutUp'])
        }, [
          _createElementVNode("section", _hoisted_3, [
            _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.formTitle), 1 /* TEXT */),
            _renderSlot(_ctx.$slots, "list-form", {}, undefined, true)
          ])
        ], 2 /* CLASS */), [
          [_vShow, _ctx.showForm]
        ]),
        _withDirectives(_createElementVNode("header", _hoisted_5, [
          _renderSlot(_ctx.$slots, "list-header", {}, undefined, true),
          _renderSlot(_ctx.$slots, "list-header-drop", {}, undefined, true),
          _renderSlot(_ctx.$slots, "list-header-new", {}, undefined, true)
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.hasHeader]
        ]),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 2 /* DYNAMIC */
  }, [
    (_ctx.pageTitle)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.pageTitle), 1 /* TEXT */)
          ])
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["has-title", "form-title", "has-list-scroll"]))
}