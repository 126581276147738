import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum InternalReferralSourceEnum {
    Hospice = 1 as number,
    Palliative = 2 as number,
    HomeHealth = 3 as number,
    HomeCare = 4 as number
}
class InternalReferralSourceClass extends EnumWithDescription<InternalReferralSourceEnum> {
    public readonly enum = InternalReferralSourceEnum;
    public readonly enumName: string = "InternalReferralSourceEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<InternalReferralSourceEnum>> = new Map([
        [
            "Hospice",
            {
                value: InternalReferralSourceEnum.Hospice,
                name: "Hospice"
            }
        ],
        [
            "Palliative",
            {
                value: InternalReferralSourceEnum.Palliative,
                name: "Palliative"
            }
        ],
        [
            "HomeHealth",
            {
                value: InternalReferralSourceEnum.HomeHealth,
                name: "Home Health"
            }
        ],
        [
            "HomeCare",
            {
                value: InternalReferralSourceEnum.HomeCare,
                name: "Home Care"
            }
        ]
    ]);
}

export const InternalReferralSource = new InternalReferralSourceClass();
