import "./agency-list.scss";

import { DialogService, DialogSettings } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";
import moment from "moment";

import config from "../../common/config";
import nameOf from "../../common/name-of";
import { PermissionManager } from "../../common/utilities/permission-manager";
import { ApplicationEnum, ParentPermissionEnum, PermissionActionEnum } from "../../enums/enums";
import type { IAgency } from "../../resources-vue/vue-interfaces/i-agency";
import type { IRoles } from "../../resources-vue/vue-interfaces/i-roles";
import { GroupedAgencies } from "../../models/agency.model";
import { Alert } from "../../resources/dialogs/alert/alert";
import { AgencyService } from "../../services/agency.service";
import { AnalyticsCategory, AnalyticsService } from "../../services/analytics.service";
import AuthService from "../../services/auth.service";
import { NavigationService } from "../../services/navigation.service";
import type { ILogOptions } from "../../resources-vue/vue-interfaces/i-analytics";
import type { INavigateRequestParams } from "./../../resources-vue/vue-interfaces/i-navigation";
import { PageTakeOverLoading } from "./loading-indicator";

export enum AgencyEvents {
    Loaded = "app:agency:loaded",
    NoAgency = "app:agency:noagency"
}

@customElement("agency-list")
@autoinject()
export class AgencyList {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameOf<AgencyList>("areRolesLoadedChanged") })
    public areRolesLoaded: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public roles: IRoles[] = [];
    private _ea: EventAggregator;
    private _authService: AuthService;
    private _agencyService: AgencyService;
    private _navService: NavigationService;
    private _analyticsService: AnalyticsService;
    private _dialogService: DialogService;
    private _logCategory = "Agency-List";
    private _permissionManager: PermissionManager;

    public agencyList: Array<IAgency> = [];
    public groupedAgencies: Map<string, GroupedAgencies> = new Map<string, GroupedAgencies>();
    public hasError: boolean = false;
    public isLoading: boolean = true;
    public isBeforeHosMarketingLastDate = moment().isBefore(moment(config.hospiceMarketingLastDate));
    public isClinician: boolean = false;
    private lisitingsCompleted: number = 0;
    private totalListingCount: number = 1;

    public constructor(
        ea: EventAggregator,
        authService: AuthService,
        agencyService: AgencyService,
        navService: NavigationService,
        analyticsService: AnalyticsService,
        dialogService: DialogService,
        permissionManager: PermissionManager
    ) {
        this._ea = ea;
        this._authService = authService;
        this._agencyService = agencyService;
        this._navService = navService;
        this._analyticsService = analyticsService;
        this._dialogService = dialogService;
        this._permissionManager = permissionManager;
    }

    public async attached() {
        this.isClinician = this._permissionManager.checkPermission(
            ParentPermissionEnum.clinician,
            PermissionActionEnum.canView
        );
    }

    public async areRolesLoadedChanged() {
        if (this.areRolesLoaded) {
            await this.loadAgencies();
        }
    }

    private async loadAgencies(): Promise<void> {
        try {
            this.hasError = false;
            this.isLoading = true;
            this._agencyService
                .getAgencyLisiting()
                .then((res) => {
                    this.lisitingsCompleted++;
                    this.groupedAgencies = new Map([...this._agencyService.getAgenciesByProduct(this.roles)]);
                    if (this.totalListingCount == this.lisitingsCompleted) {
                        this.handlePromisesCompletion();
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    this.lisitingsCompleted++;
                    if (this.totalListingCount == this.lisitingsCompleted) {
                        this.handlePromisesCompletion();
                    }
                });

            const promises = await this._agencyService.getAccountsLisiting();
            this.totalListingCount += promises.length;
            promises.forEach((singlePromise) => {
                singlePromise
                    .then((res) => {
                        this.groupedAgencies = new Map([...this._agencyService.getAgenciesByProduct(this.roles)]);
                        this.lisitingsCompleted++;
                        if (this.totalListingCount == this.lisitingsCompleted) {
                            this.handlePromisesCompletion();
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        this.lisitingsCompleted++;
                        if (this.totalListingCount == this.lisitingsCompleted) {
                            this.handlePromisesCompletion();
                        }
                    });
            });
        } catch (e) {
            console.error(e);
            this.hasError = true;
        } finally {
            this.isLoading = false;
            this._ea.publish(PageTakeOverLoading.Hide);
        }
    }

    public async handlePromisesCompletion() {
        if (!this.groupedAgencies.size) {
            // await this.handleNoAgencyList();
            this.hasError = true;
            this._ea.publish(AgencyEvents.NoAgency);
        } else {
            this._agencyService.getProducts(null);
            this._agencyService.setAgenciesLoaded(true);
            this._agencyService.setFlags();
            this._ea.publish(AgencyEvents.Loaded);
        }
    }

    private async handleNoAgencyList() {
        let defaultMessage: string = `There are no agencies associated with your account. Please contact your administrator. <br>
        If this is an error, please contact <a href=\"http://axxess.com/support\" target=\"_blank\">customer support</a>.`;
        let options: DialogSettings = {
            viewModel: Alert,
            model: {
                title: "Error",
                message: defaultMessage
            }
        };
        await this._dialogService.open(options).whenClosed(() => {
            this._authService.logout();
        });
    }

    public showAgency(list: GroupedAgencies): void {
        list.show = !list.show;
        if (list.show) {
            this._analyticsService.logEvent({
                category: "Agency-List",
                action: "Accordion-Expand"
            });
        } else {
            this._analyticsService.logEvent({
                category: "Agency-List",
                action: "Accordion-Collapse"
            });
        }
    }

    public async enterAgency(agency: IAgency): Promise<void> {
        try {
            this._ea.publish(PageTakeOverLoading.Show);
            let navParams: INavigateRequestParams = {
                applicationId: agency.Application,
                agencyId: agency.AgencyId,
                userId: agency.UserId,
                isClinician: this.isClinician,
                redirectUrl:
                    ApplicationEnum.AxxessBI == agency.Application && agency.Url ? agency.Url : agency.redirectUrl
            };
            let response: any = await this._navService.navigateToAgency(navParams);
            let analyticsOptions: ILogOptions = {
                category: this._logCategory,
                action: `Enter-${agency.applicationName}`
            };
            this._navService.redirect(response, analyticsOptions);
        } catch (e) {
            this._ea.publish(PageTakeOverLoading.Hide);
        }
    }

    public openHospiceMarketingLink() {
        this._analyticsService.logEvent({
            category: AnalyticsCategory.HospiceMarketing,
            action: "Opened-Link-From-Nav"
        });
        window.open(config.hospiceMarketingUrl);
    }
}
