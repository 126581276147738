
import { ComponentPublicInstance, reactive } from 'vue';
import { MessageService } from '../services/message-service';
import { Container } from "aurelia-dependency-injection";

import eventBus from './eventBus';
import { IConversationUser } from '../resources-vue/vue-interfaces/i-message';
import { SET_LOADING } from "../pages-vue/Common/LoadingIndicator/store/types";
import { MeetingTypeEnum } from '../pages-vue/MessageCenter/Conversation/components/AudioVideoConferencing/AudioVideoConferencing';
import { useLoadingIndicator } from '../pages-vue/Common/LoadingIndicator/store/useLoadingIndicator';

import JitsiPage from '../pages-vue/JitsiPage/JitsiPage.vue';



export async function jitsiRoomHandler($vInstance: ComponentPublicInstance , roomId: string, users: IConversationUser[] = []) {
    const messageService = Container.instance.get(MessageService);
    const loadingIndicator = useLoadingIndicator();

    const dialogData = reactive({
        roomInfo: null,
        users,
        meetingType: MeetingTypeEnum.Audio,
        loading: true
    });

    eventBus.emit("dialogOpened", true);
    loadingIndicator[SET_LOADING](true);

    const result = await messageService.joinRoom(roomId);

    loadingIndicator[SET_LOADING](false);
    dialogData.roomInfo = result;
    dialogData.loading = false;

    $vInstance.$dialog.open(JitsiPage, {
        props: {
            modal: false,
            closeOnEscape: false,
            contentStyle: {
                overflowX: "hidden",
                minWidth: "800px",
                minHeight: "600px"
            },
            showHeader: false
        },
        data: dialogData,
        onClose: () => {
            eventBus.emit("dialogOpened", false);
        }
    });
}
