import { BindingEngine, Disposable } from "aurelia-binding";
import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    observable
} from "aurelia-framework";

import nameOf from "../../../common/name-of";
import { Address } from "../../../models/address";
import { FormatZipCode } from "../../value-converters/format-zip-code";
import type { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { LookupService } from "../../../services/lookup-service";
import type { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import type { IUpdatedPlacesAddress } from "../../../resources-vue/vue-interfaces/i-places-autocomplete";

@autoinject
@containerless
@customElement("address-form")
export class AddressForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameOf<AddressForm>("addressChanged") })
    public address: Address = new Address();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disableInputs: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showCounty: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public enableValidation: boolean = false;
    @observable({ changeHandler: nameOf<AddressForm>("selectedStateChanged") })
    public selectedState: ITypeaheadOptions;
    @observable({ changeHandler: nameOf<AddressForm>("selectedCountryChanged") })
    public selectedCountry: ITypeaheadOptions;
    private readonly _lookupService: LookupService;
    private _bindingEngine: BindingEngine;
    private _addressChangedSubscription: Disposable[] = [];
    private _addressChangedObservables: string[] = [
        `${nameOf<Address>("city")}`,
        `${nameOf<Address>("zipCode")}`,
        `${nameOf<Address>("state")}`,
        `${nameOf<Address>("county")}`,
        `${nameOf<Address>("addressLine1")}`,
        `${nameOf<Address>("country")}`
    ];
    private _autoCompleteAddress: Address = new Address();
    public unitedStatesCountryCode: string;
    public autoCompleteCountry: string;
    public zipCodeFormatter: FormatZipCode = new FormatZipCode();
    public addressValidation: IValidateCustomElement = null;
    public stateValidation: IValidateCustomElement = null;
    public countryValidation: IValidateCustomElement = {
        required: true,
        displayName: "Country"
    };

    @computedFrom(nameOf<AddressForm>("selectedCountry"))
    public get isUnitedStates() {
        return this.selectedCountry?.name.toLowerCase() === "united states of america";
    }

    public constructor(lookupService: LookupService, bindingEngine: BindingEngine) {
        this._lookupService = lookupService;
        this._bindingEngine = bindingEngine;
    }

    public async bind() {
        await this.initCountry();
        if (this.enableValidation) {
            this.addressValidation = {
                required: true,
                displayName: "Address Line 1",
                matches: true
            };
            this.stateValidation = {
                required: true,
                displayName: "State"
            };
            this.address = new Address(this.address);
            this.address.initValidations();
        }
    }

    public async initCountry() {
        this.unitedStatesCountryCode = await this._lookupService.getUnitedStatesCode();
        if (!this.address.country) {
            this.address.country = this.unitedStatesCountryCode;
        }
    }

    public selectedCountryChanged() {
        if (!!this.selectedCountry) {
            this.address.country = this.selectedCountry.value;
        } else {
            this.address.country = "";
        }
        if (!this.enableValidation) {
            return;
        }
        this.address = new Address(this.address);
        if (this.isUnitedStates) {
            this.address.initValidations();
        } else {
            this.address.initInternationalValidation();
        }
    }

    public compareAddressChanged(oldValue: string, newValue: string, key: string) {
        if (this.hasAddressChanged()) {
            if (key === `${nameOf<Address>("zipCode")}`) {
                let formatOldValue = this.zipCodeFormatter.toView(oldValue).split("-");
                let formatNewValue = this.zipCodeFormatter.toView(newValue).split("-");
                if (formatNewValue[0] !== formatOldValue[0]) {
                    this.address.longitude = null;
                    this.address.latitude = null;
                    this.address.isValidated = false;
                    this.address.isBypassed = false;
                }
            } else {
                this.address.latitude = null;
                this.address.longitude = null;
                this.address.isValidated = false;
                this.address.isBypassed = false;
            }
        } else if (this._autoCompleteAddress?.addressLine1) {
            this.address.latitude = this._autoCompleteAddress.latitude;
            this.address.longitude = this._autoCompleteAddress.longitude;
            this.address.isValidated = this._autoCompleteAddress.isValidated;
            this.address.isBypassed = this._autoCompleteAddress.isBypassed;
        }
    }

    public placesChangedCallBack(params: IUpdatedPlacesAddress) {
        Object.assign(this._autoCompleteAddress, params);
        this.address.addressLine1 = params.addressLine1;
        this.address.city = params.city;
        this.address.county = params.county;
        this.address.state = params.state;
        this.address.zipCode = params.zipCode;
        this.address.latitude = params.latitude;
        this.address.longitude = params.longitude;
        this.address.isValidated = true;
        this.address.isBypassed = false;
    }

    public addressChanged() {
        this._autoCompleteAddress = Object.assign({}, this.address);
        this._addressChangedObservables.forEach((addressKey: string) => {
            let subscription = this._bindingEngine
                .propertyObserver(this.address, addressKey)
                .subscribe((newValue: string, oldValue: string) =>
                    this.compareAddressChanged(newValue, oldValue, addressKey)
                );
            this._addressChangedSubscription.push(subscription);
        });
    }

    private hasAddressChanged() {
        let autoCompleteAddressMap = new Map(Object.entries(this._autoCompleteAddress));
        let addressMap = new Map(Object.entries(this.address));
        return this._addressChangedObservables.some((key) => autoCompleteAddressMap?.get(key) !== addressMap.get(key));
    }

    public selectedStateChanged() {
        if (!!this.selectedState) {
            this.address.state = this.selectedState.value;
        } else {
            this.address.state = "";
        }
    }

    public detached() {
        if (this._addressChangedSubscription?.length > 0) {
            this._addressChangedSubscription.forEach((sub) => sub.dispose());
        }
    }
}
