import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { CentralIntakeService } from "../../../../../services/central-intake.service";
import {
    IApplicationAndAccountQueryParam,
    IDeleteDocumentQueryParam,
    IUploadReferralDocumentQuery
} from "../../../../../resources-vue/vue-interfaces/i-get-referral";

import PatientDocuments from "../PatientDocuments/PatientDocuments.vue";
import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";

export default defineComponent({
    components: {
        PatientDocuments,
        FormSection
    },

    props: {
        referral: { type: Object, default: {} },
        providerId: { type: String },
        isPalliative: { type: Boolean, default: false }
    },

    data(vm) {
        return {
            _centralIntake: null
        };
    },

    created() {
        this._centralIntake = Container.instance.get(CentralIntakeService);
    },

    methods: {
        uploadDocument(referralId: string, newDocument: IUploadReferralDocumentQuery) {
            return this._centralIntake.uploadDocument(referralId, newDocument);
        },

        deleteDocument(referralId: string, documentId: string) {
            const deleteDocumentQuery: IDeleteDocumentQueryParam = {
                referralId: referralId,
                application: this.referral.application,
                accountId: this.referral.accountId
            };
            return this._centralIntake.deleteDocument(deleteDocumentQuery, documentId);
        },

        getReferralDocuments(referralId: string) {
            const getReferralDocumentQuery: IApplicationAndAccountQueryParam = {
                application: this.referral.application,
                agencyId: this.referral.accountId
            };
            return this._centralIntake.getReferralDocuments(referralId, getReferralDocumentQuery);
        }
    }
});
