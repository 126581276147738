import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { BranchesService } from "../../../services/branches-service";
import { IBranchResponse } from "../../vue-interfaces/i-branch";
import { EnumMap } from "../../../common/enum-map";
import { ProvidersService } from "../../../services/providers-service";

import GroupedMultiSelect from "../GroupedMultiSelect/GroupedMultiSelect.vue";

export default defineComponent({
    components: {
        GroupedMultiSelect
    },
    props: {
        selectedBranches: {
            type: Array,
            default: []
        },
        checkAllByDefault: {
            type: Boolean,
            default: false
        },
        notAllowedSolutions: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            _providersService: null,
            _branchservice: null,
            dropDownOptions: null,
            branches: [],
            providerIds: [],
            filterBranches: [],
            providerEnumMap: null,
            branchEnumMap: null,
            labelText: "Branches",
            searchText: "Branches",
            isBranchLoading: false,
            providers: [],
            isRequestPending: false
        };
    },

    watch: {
        filterBranches: {
            handler() {
                this.filterBranchesChanged();
            },
            deep: true
        }
    },

    created() {
        this._branchservice = Container.instance.get(BranchesService);
        this._providersService = Container.instance.get(ProvidersService);
    },

    mounted() {
        this.initFilters();
    },

    methods: {
        async initFilters() {
            this.branches = await this._branchservice.getBranchDetails();
            if (this.notAllowedSolutions?.length) {
                this.branches = this.branches.filter(
                    (branch) => !this.notAllowedSolutions.includes(branch.application)
                );
            }
            this.providers = await this._providersService.getProvider();
            this.providerIds = this.providers.map((provider) => provider.id);
            this.dropDownOptions = this.getDropDownOptions();

            if (!this.checkAllByDefault) {
                this.isRequestPending = true;
                let defaultBranches: IBranchResponse[] = this.getBranchDefaults();
                this.filterBranches =
                    this.selectedBranches?.length > 0
                        ? Array.from(this.selectedBranches)
                        : defaultBranches.map((branch) => branch.id);
            } else {
                this.filterBranches = this.branches.map((branch) => branch.id);
            }
            let providerEnum = this.createProviderEnum();
            let branchEnum = this.createBranchEnum();
            this.providerEnumMap = new EnumMap(providerEnum);
            this.branchEnumMap = new EnumMap(branchEnum);
        },

        filterBranchesChanged() {
            if (this.filterBranches?.length > 0) {
                this.$emit("updateBranches", this.filterBranches);
            } else {
                let branchIds = this.branches.map((branch) => branch.id);
                this.$emit("updateBranches", branchIds);
            }
        },

        getAllProviderBranchIds(provider: string) {
            let branchIds: string[] = [];
            this.branches.forEach((branch) => {
                if (branch.providerId === provider) {
                    branchIds.push(branch.id);
                }
            });
            return branchIds;
        },
        getDropDownOptions() {
            let branchOptions: { [key: string]: any } = {};
            let providers = [...new Set(this.branches.map((branch) => branch.providerId))] as string[];
            providers.forEach((providerId: string) => {
                branchOptions[providerId] = this.getAllProviderBranchIds(providerId);
            });
            return branchOptions;
        },
        getBranchDefaults() {
            return this.branches.filter((branch) => this.providerIds.includes(branch.providerId));
        },
        createProviderEnum() {
            let providers = [...new Set(this.branches.map((branch) => branch.providerName))];
            let providerEnum = providers.map((name, index) => {
                return {
                    name: name,
                    value: index + 1
                };
            });
            return providerEnum;
        },
        createBranchEnum() {
            return this.branches.map((branch) => ({
                name: branch.name,
                value: branch.id
            }));
        },
        updateFilterBranches(branches: any) {
            this.filterBranches.length = 0;
            this.filterBranches.push(...branches);
        }
    }
});
