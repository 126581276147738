<template>
    <div class="card p-4">
        <div>
            <h4 class="mb-0">Zip Code</h4>
        </div>
        <div>
            <p>You have decided to deny permission to access your location.</p>
            <p>
                That is alright. Please provide your current location's zip code. We will use it to get your location's
                weather and show your visits' locations.
            </p>
            <div class="zip-input-group">
                <input
                    :class="`form-control ${zipError ? 'border border-danger' : ''}`"
                    v-model="zip"
                    placeholder="Zip Code (Ex: 12345)"
                />
                <i v-if="zip" class="fas fa-times clear-search" @click="clearZip()"></i>
                <div v-if="zipError" class="invalid-feedback d-block">Enter a Valid Zip Code</div>
            </div>
            <div class="footer">
                <button type="button" class="btn btn-light" @click="zipNotEntered()">Cancel</button>
                <button type="button" class="btn btn-primary" :disabled="!zip" @click="isValidZip()">Submit</button>
            </div>
        </div>
    </div>
</template>

<style src="./GetZip.scss"></style>
<script src="./GetZip.ts"></script>
