import { defineComponent, PropType } from "vue";

import type { IMessageItem } from "../../../../../resources-vue/vue-interfaces/messaging-service/i-message";
import { formatUserInitials, formatMessageTime } from "../../../../../common/vue-helpers/modifiers/format-message";
import { markdownToHtml } from "../../../../../common/vue-helpers/modifiers/markdown-to-html-modifier";
import { sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import eventBus from "../../../../../utilities/eventBus";

export default defineComponent({
    props: {
        message: { type: Object as PropType<IMessageItem>, default: {} as IMessageItem },
        isViewDeletedMessagesRequested: { type: Boolean, default: false },
        selectAll: { type: Boolean, default: null },
        allMessagesSelected: { type: Boolean, default: null },
        updateSelectedIds: { type: Function },
        renderAsset: { type: Function },
        isBulkDeleteRequested: { type: Boolean, default: false },
        selectedMessageIds: { type: Array as PropType<string[]>, default: [] }
    },
    emits: ["selectAllUpdated"],
    data() {
        return {
            axxessUserName: "Axxess",
            isSelected: false,
            formatUserInitials,
            formatMessageTime,
            markdownToHtml,
            sanitizeMarkup
        };
    },
    watch: {
        isSelected(newValue: boolean) {
            if (!this?.message?.id) {
                return;
            }
            this.updateSelectedIds({ isSelected: newValue, messageId: this.message.id });
            if (!newValue) {
                this.$emit("selectAllUpdated", false);
            }
        },
        selectAll() {
            if (this.selectAll) {
                this.isSelected = true;
            } else if (this.allMessagesSelected) {
                this.isSelected = false;
            }
        }
    },

    methods: {
        joinRoom(event: MouseEvent) {
            const target = event.target as HTMLElement;
            if (target.tagName === "BUTTON" && target.dataset.id) {
                eventBus.emit("joinRoomClicked", target.dataset.id);
            }
        }
    }
});
