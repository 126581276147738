import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { ToastrService } from "../../../../../services/toastr.service";
import { PatientService } from "../../../../../services/patient.service";
import { LookupService } from "../../../../../services/lookup-service";
import { HomeCareReferral } from "../../../../../resources-vue/vue-models/homecare-referral";
import type { IGetDmesResponse } from "../../../../../resources-vue/vue-interfaces/i-get-dmes";

import EmptyTextHandler from "../../../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import TablePrompt from "../../../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";

export default defineComponent({
    components: {
        EmptyTextHandler,
        TablePrompt
    },
    props: {
        dme: { type: Object, default: null },
        patientId: { type: String, default: null },
        referral: { type: HomeCareReferral, default: null },
        refreshList: { type: Function, default: null },
        columns: { type: Number, default: null }
    },
    data() {
        return {
            isDmeNeededFormOpen: false,
            isDeleteRequested: false,
            isEditRequested: false,
            isDeleteInProgress: false,
            isSaving: false,
            data: null,
            _toastrService: null,
            _patientService: null,
            _lookupService: null
        };
    },
    created() {
        this._toastrService = Container.instance.get(ToastrService);
        this._patientService = Container.instance.get(PatientService);
        this._lookupService = Container.instance.get(LookupService);
    },
    methods: {
        showDmeNeededForm(isEditable: boolean) {
            this.isEditRequested = isEditable;
            this.isDmeNeededFormOpen = true;
        },
        requestDeleteContact() {
            this.isDeleteRequested = true;
        },
        cancelDeleteContact() {
            this.isDeleteRequested = false;
        },
        closeDmeNeededForm() {
            this.isEditRequested = false;
            this.isDmeNeededFormOpen = false;
        },
        async deleteDmeNeeded(id: string) {
            try {
                this.isDeleteInProgress = true;
                const response = await this._patientService.deletePatientsDme({
                    id: id,
                    patientId: this.patientId,
                    accountId: this.referral.accountId,
                    application: this.referral.application
                });
                if (response.isSuccessful) {
                    this._toastrService.success({
                        title: "Success",
                        message: "The selected DME has been deleted successfully."
                    });
                    this.isDeleteRequested = false;
                    this.refreshList();
                } else {
                    this._toastrService.error({
                        title: "Error",
                        message: "The selected DME could not be deleted. Try Again."
                    });
                }
            } catch (e) {
                this._toastrService.error({
                    title: "Error",
                    message: "The selected DME could not be deleted. Try Again."
                });
            } finally {
                this.isDeleteInProgress = false;
            }
        },
        async saveDme(id: string) {
            try {
                if (this.dme.dmeName) {
                    const data = await this._lookupService.getDmes({
                        agencyId: this.referral.accountId,
                        term: this.dme.dmeName,
                        application: this.referral.application
                    });
                    this.data = data.map((item: IGetDmesResponse) => ({
                        id: item.id
                    }));
                }
                this.isSaving = true;
                this.dme.agencyId = this.referral.accountId;
                this.dme.accountId = this.referral.accountId;
                this.dme.application = this.referral.application;
                this.dme.dmeId = this.data[0].id;
                let response = await this._patientService.updatePatientsDme(this.dme);
                this.closeDmeNeededForm();
                if (response.isSuccessful) {
                    this.refreshList();
                    this._toastrService.success({
                        title: "Success",
                        message: "The Client DME is updated successfully.!"
                    });
                } else {
                    this._toastrService.error({
                        title: "Error",
                        message: "There was a problem while saving DME. Please try again."
                    });
                }
            } catch (e) {
                this._toastrService.error({
                    title: "Error",
                    message: "There was a problem while saving DME. Please try again."
                });
            } finally {
                this.isSaving = false;
            }
        }
    }
});
