import { defineComponent, toHandlers } from "vue";
import { mapState } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";




import { enumToMap } from "../../../../../../common/vue-helpers/modifiers/enum-modifiers";
import { CAHPSSurveyMethod, Relationships, PhoneTypeEnum } from "../../../../../../enums/enums";
import { Address } from "../../../../../../resources-vue/vue-models/address";
import { IPhoneNumber } from "../../../../../../resources-vue/vue-interfaces/i-phone-number";
import { useCreateReferralHosPal } from "../../../store/useCreateReferralHosPal";

import FormSection from "../../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TagsInput from "../../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import PhoneNumber from "../../../../../../resources-vue/vue-custom-elements/PhoneNumber/PhoneNumber.vue";
import AddressForm from "../../../../../../resources-vue/vue-custom-elements/AddressForm/AddressForm.vue";


export default defineComponent({
    components: {
        FormSection,
        TagsInput,
        PhoneNumber,
        AddressForm
    },


    props: {
        isEditable: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isPalliative: {
            type: Boolean,
            default: false
        },
        patientPrimaryAddress: {
            default: null
        },
        contactRolesEnum: {
            type: Array,
            default: []
        },
        relationshipsEnum: {
            type: Array<any>,
            default: []
        },
        cahpsSurveyContactEnum: {
            type: Array<any>,
            default: []
        },
        cahpsSurveyRecipientIndex: {
            default: -1
        },
        contact:{
            type:Object
        }
    },
    validations() {
        return {
            contact: {
                firstName: { required },
                lastName: { required },
                relationship: { required }
            }
        };
    },
    data(vm) {
        return {
            addrSameAsPatientAddr: false,
            selectedContactRoles: [],
            cahpsSurveyChecked: false,
            showRelationshipDescription: false,
            phoneNumberOptions: [PhoneTypeEnum.Home, PhoneTypeEnum.Mobile, PhoneTypeEnum.Work],
            Relationships: enumToMap(Relationships),
            v$: useVuelidate()
        };
    },
    created() {
        this.init();
    },
    computed: {
        primaryType: {
            get() {               
                return this.contact.phoneNumbers[this.primaryNumberIndex(this.contact.phoneNumbers)].type || 1;
            },
            set(value: any) {
                this.contact.phoneNumbers[this.primaryNumberIndex(this.contact.phoneNumbers)].type = value;
            }
        },
        secondaryType: {
            get() {
                return this.contact.phoneNumbers[this.alternativeNumberIndex(this.contact.phoneNumbers)].type || 1;
            },
            set(value: any) {
                return (this.contact.phoneNumbers[this.alternativeNumberIndex(this.contact.phoneNumbers)].type =
                    value);
            }
        },
        isCahpsContact() {
            return this.cahpsSurveyRecipientIndex === this.contact.contactOrder || this.cahpsSurveyRecipientIndex == -1;
        },
        
    },

    unmounted() {
        this.addrSameAsPatientAddr = false;
    },
    watch: {
        addrSameAsPatientAddr(newValue: any) {
            this.addrSameAsPatientAddrChanged(newValue);
        },
        selectedContactRoles() {
            this.selectedContactRolesChanged();
        },
        cahpsSurveyChecked() {
            this.cahpsSurveyCheckedChanged();
        }
    },
    methods: {
        init() {
            if (!!this.contact) {
                this.handleRelationshipSelection();
                if (this.contact.contactRoles) {
                    this.selectedContactRoles = this.contactRolesEnum.filter((role: any) => {
                        return this.contact.contactRoles.some((item: any) => {
                            return role.value == item;
                        });
                    }) as any;
                }

                if (!this.contact.address) {
                    this.contact.address = new Address();
                } else if (this.contact.isUsingPrimaryAddress) {
                    this.addrSameAsPatientAddr = this.contact.isUsingPrimaryAddress;
                }

                if (this.primaryNumberIndex(this.contact.phoneNumbers) < 0) {
                    this.contact.phoneNumbers = this.contact.phoneNumbers.concat({
                        number: "",
                        extension: "",
                        type: 1,
                        isPrimary: true,
                        country: null
                    });
                }

                if (this.alternativeNumberIndex(this.contact.phoneNumbers) < 0) {
                    this.contact.phoneNumbers = this.contact.phoneNumbers.concat({
                        number: "",
                        extension: "",
                        type: 0,
                        isPrimary: false,
                        country: null
                    });
                }
                if (this.contact.cahpsSurvey > 0) {
                    this.cahpsSurveyChecked = true;
                }
            }
        },
        primaryNumberIndex(phoneNumbers: IPhoneNumber[]) {
            if (phoneNumbers) {
                return phoneNumbers.findIndex((e) => e.isPrimary);
            }
            return 0;
        },
        alternativeNumberIndex(phoneNumbers: IPhoneNumber[]) {
            if (phoneNumbers) {
                return phoneNumbers.findIndex((e) => !e.isPrimary);
            }
            return 0;
        },
        patientPrimaryAddressChanged() {
            if (!!this.patientPrimaryAddress && !!this.contact && this.contact.isUsingPrimaryAddress) {
                this.setAddressToPrimaryAddress();
            }
        },
        addrSameAsPatientAddrChanged(newValue: boolean) {
            if (newValue === true) {
                this.setAddressToPrimaryAddress();
                this.contact.isUsingPrimaryAddress = true;
            } else {
                this.contact.address = new Address();
                this.contact.isUsingPrimaryAddress = false;
            }
        },
        setAddressToPrimaryAddress() {
            if (!!this.patientPrimaryAddress && !!this.contact) {
                this.contact.address = Object.assign({}, this.patientPrimaryAddress);
            }
        },
        selectedContactRolesChanged() {
            if (this.selectedContactRoles) {
                this.contact.contactRoles = this.selectedContactRoles.map((role) => Number(role.value));
            }
        },
        cahpsSurveyCheckedChanged() {
            if (this.isPalliative) {
                this.contact.cahpsSurvey = this.cahpsSurveyChecked
                    ? Number(CAHPSSurveyMethod.enum.ReceiveCAHPSSurvey)
                    : 0;
            }
        },
        handleRelationshipSelection() {
            if (!!this.contact.relationship) {
                const isRelationshipOther: boolean = Relationships.enum.Other == this.contact.relationship;
                const isRelationshipOtherRelative: boolean =
                    Relationships.enum.OtherRelative == this.contact.relationship;
                this.showRelationshipDescription = isRelationshipOther || isRelationshipOtherRelative;
            } else {
                this.showRelationshipDescription = false;
            }
        },
        selectedContactRolesUpdated(newValue: any) {
            this.selectedContactRoles = newValue;
        },
        updateAddress(value: any) {
            this.contact.address = value;
        }
    }
});
