import { defineComponent } from "vue";
import * as moment from "moment-timezone";

import { formatingDateOrTime } from "../../../common/vue-helpers/modifiers/value-modifier";

export default defineComponent({
    data() {
        return {
            dateFormat: "dddd, MMM DD, YYYY",
            today: moment(),
            formatingDateOrTime
        };
    },

});
