import { computed, defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { EnumMap } from "../../../common/enum-map";
import { ApplicationEnum, ApplicationNames, ActionButtonTooltipEnum } from "../../../enums/enums";
import { formatDateFromDate } from "../../../common/vue-helpers/modifiers/value-modifier";
import { formatPhone } from "../../../common/vue-helpers/modifiers/value-modifier";
import { CentralIntakeService } from "../../../services/central-intake.service";
import { ReferralService } from "../../../services/referral-service";
import { ToastrService } from "../../../services/toastr.service";
import { INonAdmitReasonQuery, INonAdmitReasonsListItem } from "../../../resources-vue/vue-interfaces/i-branch";
import { IDeleteReferralQuery } from "../../../resources-vue/vue-interfaces/i-get-referral";
import { helperTestingAgency } from "../../../common/vue-helpers/testing-agency-check";

import FormatName from "../../../resources-vue/vue-custom-elements/FormatName/FormatName.vue";
import EmptyTextHandler from "../../../resources-vue/vue-custom-elements/EmptyTextHandler/EmptyTextHandler.vue";
import TablePrompt from "../../../resources-vue/vue-custom-elements/TablePrompt/TablePrompt.vue";
import NewTypeahead from "../../../resources-vue/vue-custom-elements/NewTypeahead/NewTypeahead.vue";
import TagsInput from "../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";
import DatePicker from "../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import CommentInput from "../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";
import HHReferralDocuments from "./components/HHReferralDocuments/HHReferralDocuments.vue";
import PreadmissionNote from "./components/HHPreadmissionNotes/HHPreadmissionNotes.vue";

export default defineComponent({
    components: {
        FormatName,
        EmptyTextHandler,
        TablePrompt,
        NewTypeahead,
        DatePicker,
        TagsInput,
        CommentInput
    },

    provide() {
        return {
            dialogRef: computed(() => this.dialogRef)
        };
    },

    props: {
        resetList: {
            type: Function
        },
        referral: {
            type: Object
        },
        columns: {
            type: Number
        },
        getGenderTypes: {
            type: Function
        },
        lineOfServiceEnumMap: {
            type: EnumMap,
            default: new EnumMap([])
        },
        branches: {
            type: Array,
            default: []
        },
        referralsEditable: {
            type: Array,
            default: []
        },
        referralsDeleteable: {
            type: Array,
            default: []
        },
        nonAdmitReasonsData: {
            type: Object,
            default: {}
        },
        updateNonAdmitReasonsData: {
            type: Function
        }
    },

    data() {
        return {
            _centralIntakeService: null,
            _referralService: null,
            _toastrService: null,
            selectedNonAdmitReason: null,
            getRequiredBranch: null,
            isNonAdmitRequested: false,
            ApplicationEnum: ApplicationEnum,
            isTestAgency: false,
            isEditAllowed: false,
            isDeleteAllowed: false,
            isDeleteRequested: false,
            isDeleteInProgress: false,
            showCommentBox: false,
            showTemplate: false,
            nonAdmitReasons: [],
            ApplicationNamesEnum: ApplicationNames,
            referralApplication: "",
            showMultiSelect: false,
            commentRequired: false,
            mulitpleNonAdmitReasons: [],
            newNonAdmitData: {
                nonAdmitDate: "",
                nonAdmitReasonId: "",
                nonAdmitComments: "",
                nonAdmitReason: []
            },
            showNoteForm: false,
            dialogRef: null,
            formatDateFromDate,
            formatPhone,
            actionButtonTooltipEnum: ActionButtonTooltipEnum
        };
    },

    async created() {
        this._centralIntakeService = Container.instance.get(CentralIntakeService);
        this._referralService = Container.instance.get(ReferralService);
        this._toastrService = Container.instance.get(ToastrService);
        this.referralApplication = this.ApplicationNamesEnum.getDescriptionUsingValue(
            Number(this.referral.application)
        );
        this.isEditAllowed =
            this.referral.accountId &&
            this.referralsEditable.some((item: any) => item.accountId == this.referral.accountId);
        this.isDeleteAllowed =
            this.referral.accountId &&
            this.referralsDeleteable.some((item: any) => item.accountId == this.referral.accountId);
        this.isTestAgency = await helperTestingAgency.checkIsTestingAgencyId(this.referral.accountId);
    },

    mounted() {
        this.referralApplication = this.ApplicationNamesEnum.getDescriptionUsingValue(
            Number(this.referral.application)
        );
        if ([ApplicationEnum.HomeCare, ApplicationEnum.AgencyCore].includes(this.referral.application)) {
            this.showCommentBox = true;
        }
        if (this.referral.application == ApplicationEnum.AgencyCore) {
            this.showMultiSelect = true;
        }
    },

    watch: {
        selectedNonAdmitReason(newValue: any) {
            if (!!newValue) {
                this.newNonAdmitData.nonAdmitReasonId = newValue.value;
                if (this.referral.application == ApplicationEnum.HomeCare) {
                    this.newNonAdmitData.nonAdmitReason.push(newValue.name);
                }
            } else {
                this.newNonAdmitData.nonAdmitReasonId = null;
                this.newNonAdmitData.nonAdmitReason = [];
            }
        },

        mulitpleNonAdmitReasons: {
            handler(newValue: any) {
                this.commentRequired = newValue.some((item: any) => item.commentRequired) || false;
            },
            deep: true
        }
    },

    methods: {
        isHomeHealthAgency() {
            return this.referral.application === ApplicationEnum.AgencyCore;
        },
        openAddDocumentModal() {
            this.dialogRef = this.$dialog.open(HHReferralDocuments, {
                props: {
                    modal: true,
                    showHeader: false,
                    style: "width: 55vw"
                },
                data: {
                    referral: this.referral
                }
            });
        },
        openPreadmissionModal() {
            this.dialogRef = this.$dialog.open(PreadmissionNote, {
                props: {
                    modal: true,
                    showHeader: false
                },
                data: {
                    referral: this.referral
                }
            });
        },
        async openNonAdmitForm(toOpen: boolean) {
            if (!this.nonAdmitReasons.length) await this.getNonAdmitReasons();
            this.isNonAdmitRequested = toOpen;
        },

        async getNonAdmitReasons() {
            if (this.nonAdmitReasonsData[this.referral.accountId]) {
                this.nonAdmitReasons = this.nonAdmitReasonsData[this.referral.accountId];
                return;
            }

            let providerIds: string[] = [];
            this.branches.forEach((branch: any) => {
                if (branch.accountId == this.referral.accountId && !providerIds.includes(branch.providerId)) {
                    providerIds.push(branch.providerId);
                }
            });
            const objQuery: INonAdmitReasonQuery = {
                accountId: this.referral.accountId,
                providerIds: providerIds,
                application: this.referral.application
            };
            const nonAdmitReasonsList = await this._centralIntakeService.getNonAdmitReasons(objQuery);
            if (nonAdmitReasonsList.data) {
                this.nonAdmitReasons = nonAdmitReasonsList.data?.map((reason: INonAdmitReasonsListItem) => ({
                    value: reason.id,
                    name: reason.reason,
                    commentRequired: reason.isCommentRequired || false
                }));
                this.updateNonAdmitReasonsData(this.nonAdmitReasons, this.referral);
            }
        },

        closeNonAdmitForm() {
            this.newNonAdmitData.nonAdmitComments = "";
            this.newNonAdmitData.nonAdmitReasonId = null;
            this.newNonAdmitData.nonAdmitDate = "";
            this.newNonAdmitData.nonAdmitReason = [];
            this.isNonAdmitRequested = false;
        },

        async convertToNonAdmit() {
            if (this.referral.application == ApplicationEnum.AgencyCore) {
                this.newNonAdmitData.nonAdmitReason = this.mulitpleNonAdmitReasons.map((item) => item.name);
            }
            let queryObj = JSON.parse(JSON.stringify(this.newNonAdmitData));
            queryObj.accountId = this.referral.accountId;
            queryObj.application = this.referral.application;
            try {
                await this._centralIntakeService.updateReferralToNonAdmit(this.referral.id, queryObj);
                this._toastrService.success({
                    message: `The referral for ${this.referral.lastName}, ${this.referral.firstName} has been converted to Non-Admit.`,
                    title: `Referral Converted to Non-Admit`
                });
                this.resetList();
            } catch (error) {
                this._toastrService.error({
                    title: `Error - Referral convert to Non-Admit`,
                    message: `There was a problem while converting the referral <b>${this.referral.lastName}, ${this.referral.firstName}</b>. Please try again.`
                });
            }
        },

        confirmDeleteReferral() {
            this.isDeleteRequested = true;
        },

        async deleteReferral() {
            try {
                this.isDeleteInProgress = true;
                let deleteQuery: IDeleteReferralQuery = {
                    application: this.referral.application,
                    agencyId: this.referral.accountId
                };
                await this._referralService.deleteReferral(this.referral.id, deleteQuery);
                this.resetList();
                this._toastrService.success({
                    message: `Referral has been successfully deleted.`,
                    title: `Referral Deleted`
                });
            } catch (error) {
                console.log(error);
                this._toastrService.error({
                    title: `Error - Delete Referral`,
                    message: `There was a problem while deleting the Referral. Please try again.`
                });
                this.isDeleteInProgress = false;
            }
        },

        cancelDeleteReferral() {
            this.isDeleteRequested = false;
        },

        getNewNonAdmitDate(date: string) {
            let temp = this.newNonAdmitData;
            temp.nonAdmitDate = date;
            this.newNonAdmitData = temp;
        },

        updateSelectedNonAdmitReason(newValue: any) {
            this.selectedNonAdmitReason = newValue;
        },

        updateTags(newValue: any) {
            this.mulitpleNonAdmitReasons = newValue;
        },

        editReferralForSelectedPatient() {
            if (this.referral.application == ApplicationEnum.AgencyCore) {
                this.$router.push(
                    `/axxess-central/create/edit-home-health-referral/${this.referral.id}/account/${this.referral.accountId}/application/${this.referral.application}/?isCreate=false`
                );
            } else if (this.referral.application == ApplicationEnum.HomeCare) {
                this.$router.push(
                    `/axxess-central/create/homecare-referral/?id=${this.referral.id}&account=${this.referral.accountId}&isNonMedical=${this.referral.isNonMedical}&isCreate=false`
                );
            } else {
                this.$router.push(
                    `/axxess-central/create/referral/${this.referral.id}/account/${this.referral.accountId}/application/${this.referral.application}/?isCreate=false`
                );
            }
        },
        resultUpdatedAppended(value: string) {
            this.newNonAdmitData.nonAdmitComments += value;
        },
        resultUpdated(value: string) {
            this.newNonAdmitData.nonAdmitComments = value;
        }
    }
});
