import { bindable, bindingMode, computedFrom, containerless } from "aurelia-framework";

import { formatName } from "../../../common/format-name";
import nameOf from "../../../common/name-of";

@containerless
export class FormatName {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public firstName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public lastName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public format: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public middleInitial: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public suffix: string;

    @computedFrom(
        `${nameOf<FormatName>("firstName")}`,
        `${nameOf<FormatName>("lastName")}`,
        `${nameOf<FormatName>("format")}`,
        `${nameOf<FormatName>("middleInitial")}`,
        `${nameOf<FormatName>("suffix")}`
    )
    public get fullName() {
        return formatName(this.firstName, this.lastName, this.format, null, this.middleInitial, this.suffix);
    }
}
