import { defineComponent } from "vue";
import { Container } from "aurelia-dependency-injection";

import { IEnumResponse } from "../../../../../resources-vue/vue-interfaces/i-enum";
import { IPatientTags } from "../../../../../resources-vue/vue-interfaces/i-patient-tags";
import { IGetPatientTag } from "../../../../../resources-vue/vue-interfaces/i-providers-service";
import { ITypeaheadOptions } from "../../../../../resources-vue/vue-interfaces/i-typeahead";
import { HomeHealthReferral } from "../../../../../resources-vue/vue-models/homehealth-referral";
import { sanitizeMarkup } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { ProvidersService } from "../../../../../services/providers-service";
import { ApplicationEnum } from "./../../../../../enums/enums";

import TagsInput from "../../../../../resources-vue/vue-custom-elements/TagsInput/TagsInput.vue";

export default defineComponent({
    components: {
        TagsInput
    },

    props: {
        patient: {
            type: HomeHealthReferral,
            default: null
        }
    },

    data() {
        return {
            _providersService: null,
            selectedTags: [] as ITypeaheadOptions[],
            patientTags: [] as IEnumResponse[],
            isTagsEnabled: false,
            tagsTooltip: `<strong>Title</strong>
                          <p>Tags</p>
                          <hr>
                          <strong>Description</strong>
                          <p>Add tags to categorize patients with specific characteristics. To add a tag, use the drop-down menu or search function.
                          </p><hr>
                          <strong>Intent</strong>
                          <p>An unlimited number of tags can be assigned to each patient. For example, a patient can be assigned to the Team A tag and the Surgical Wound tag. If a user wants to view all patients in Team A with a surgical wound, the user can select Team A and Surgical Wound in the Tags filter to view patients who are assigned to both tags.
                          </p><hr>
                          <strong>Impact</strong>
                          <p>Tags enable users to easily locate patients from anywhere that the Tags filter is available in the system (e.g., patient charts).</p>`,
            sanitizeMarkup
        };
    },

    created() {
        this._providersService = Container.instance.get(ProvidersService);
        this.bind();
    },

    watch: {
        selectedTags() {
            if (!!this.patient) {
                this.patient.Referral.TagCollection = this.selectedTags?.map((tags) => tags.value);
            }
        }
    },

    methods: {
        async bind() {
            this.isTagsEnabled = true;
            const patientTagsQuery: IPatientTags = {
                isActive: true,
                application: ApplicationEnum.AgencyCore,
                accountId: this.patient.accountId
            };
            let patientTags = await this._providersService.getActivePatientTags(patientTagsQuery);
            this.patientTags = patientTags.map((tag: IGetPatientTag) => ({
                name: tag.name,
                value: tag.id
            }));
            this.selectedTags = this.patientTags.filter((tag) => {
                return this.patient.Referral.TagCollection.includes(String(tag.value));
            }) as ITypeaheadOptions[];
        },

        updateTags(newValue: any) {
            this.selectedTags = newValue;
        }
    }
});
