import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import { UserManager } from "oidc-client";

import { CancellationException } from "../common/cancellation-exception";
import config from "../common/config";
import { FetchClient } from "../common/fetch-client";
import { ApplicationEnum, ApplicationNameEnum } from "../enums/enums";
import type { IRoles } from "../resources-vue/vue-interfaces/i-roles";
import { Agency, GroupedAgencies } from "../models/agency.model";
import type { IAgency } from "../resources-vue/vue-interfaces/i-agency";
import { AccessService } from "./access.service";

@noView()
@autoinject()
export class AgencyService {
    private _accessService: AccessService;
    private _usersAgencies: Array<IAgency> = [];
    private _hasAgencyCore: boolean = false;
    private _hasHomeCare: boolean = false;
    private _hasHospice: boolean = false;
    private _hasPalliative: boolean = false;
    private _hasFamilyPortal: boolean = false;
    private _hasMultipleClinicalProds: boolean = false;
    private _isAgencyCoreTestingUser: boolean = false;
    private _isHomeCareTestingUser: boolean = false;
    private _agenciesLoaded: boolean = false;
    private _fetchClient: FetchClient;
    private _userManager: UserManager;
    private _applicationOrder: number[] = [
        ApplicationEnum.AxxessBI,
        ApplicationEnum.AgencyCore,
        ApplicationEnum.HomeCare,
        ApplicationEnum.AxxessHospice,
        ApplicationEnum.AxxessPalliative,
        ApplicationEnum.PatientFamilyPortal,
        ApplicationEnum.AxxessCARE,
        ApplicationEnum.AxxessDDE,
        ApplicationEnum.DdeBlackScreen,
        ApplicationEnum.AxxessRCM,
        ApplicationEnum.AxxessCAHPS,
        ApplicationEnum.HospiceCAHPS,
        ApplicationEnum.AxxessEnterprise,
        ApplicationEnum.Therapy
    ];
    private _orderedAgencyList: Map<string, GroupedAgencies> = new Map<string, GroupedAgencies>();
    private productSet = new Set();
    private _organizations: IGetOrganization[] = [];
    private agencyLisiting = {
        agency: "/api/v1/meta/agencies",
        applications: [
            {
                url: `${config.mushuUrl}/api/v1/accounts`,
                redirectUrl: config.mushuUrl,
                application: ApplicationEnum.AxxessRCM
            },
            {
                url: `${config.hospiceUrl}/api/v1/accounts`,
                redirectUrl: config.hospiceUrl,
                application: ApplicationEnum.AxxessHospice
            },
            {
                url: `${config.biApiUrl}/api/v1/accounts`,
                redirectUrl: config.biUrl,
                application: ApplicationEnum.AxxessBI
            },
            {
                url: `${config.palliativeUrl}/api/v1/accounts`,
                redirectUrl: config.palliativeUrl,
                application: ApplicationEnum.AxxessPalliative
            },
            {
                url: `${config.nalaUrl}/api/v1/accounts`,
                redirectUrl: config.nalaUrl,
                application: ApplicationEnum.AxxessNala
            }
        ]
    };

    public constructor(accessService: AccessService, fetchClient: FetchClient, userManager: UserManager) {
        // This needs to be lazy due to a problem with resolving geolocation too early for some reason. Someone (front end team) should fix this...
        this._accessService = accessService;
        this._fetchClient = fetchClient;
        this._userManager = userManager;
    }

    public getAgenciesLoaded() {
        return this._agenciesLoaded;
    }

    public setAgenciesLoaded(value: boolean) {
        this._agenciesLoaded = value;
    }

    public async getOrganizations() {
        return await this._fetchClient.build(`/api/v1/meta/organizations`).fetch<IGetOrganization[]>();
    }

    public async getAgencyLisiting() {
        this._usersAgencies = [];
        const result = await this._fetchClient.build(this.agencyLisiting.agency).fetch<IAgency[]>();
        if (result.length) {
            this._usersAgencies = [...this._usersAgencies, ...result];
        }
        return result;
    }

    public async getAccountsLisiting() {
        return this.agencyLisiting.applications.map((x) => {
            return this.allIdentityProducts(x.url, x.redirectUrl, x.application);
        });
    }

    private async allIdentityProducts(url: string, redirectUrl: string, application: number): Promise<IAgency[]> {
        const agencies = [];
        try {
            const result = await this._fetchClient.build(url).fetch<IGetAccountsResponse>();
            if (!result) {
                return [];
            }
            const mappedAccounts = result.items?.map((x) => {
                return {
                    AgencyId: x.id,
                    Application: application,
                    Cluster: -1,
                    Created: null,
                    Feature: null,
                    LoginId: null,
                    Name: x.name,
                    Roles: [],
                    TitleType: null,
                    UserId: null,
                    redirectUrl: `${redirectUrl}?accountId=${x.id}`,
                    Url: x.hasOwnProperty("url") && x.url ? x.url : ""
                };
            });
            agencies.push(...(mappedAccounts as any));
        } catch (e) {
            if (e instanceof Response && e.status == 401) {
                console.log(`No allowed access to ${e.url}, this is expected, assuming conditional success.`);
            } else {
                return [];
            }
        }
        if (agencies.length) {
            this._usersAgencies = [...this._usersAgencies, ...agencies];
        }
        return agencies;
    }

    public async getAgencies(): Promise<Array<IAgency>> {
        if (!this._agenciesLoaded) {
            let agencies = await this._fetchClient.build(this.agencyLisiting.agency).fetch<IAgency[]>();
            let data: Array<IAgency> = [];
            if (agencies?.length > 0) {
                data.push(...agencies);
            }

            let identityAgencies = await this.identityProductsFindAll(true);
            data.push(...identityAgencies);
            this._usersAgencies = data;
            this._agenciesLoaded = true;
        }
        return this._usersAgencies;
    }

    public getProducts(dataList: IAgency[]) {
        if (!dataList) {
            dataList = this._usersAgencies;
        }
        // creates a set of different products user has access to.
        if (dataList.length) {
            let mappedData = dataList.map((x) => {
                return {
                    application: x.Application,
                    applicationName: ApplicationNameEnum[x.Application]
                };
            });

            mappedData.forEach((ele) => {
                this.productSet.add(JSON.stringify(ele));
            });
            return this.productSet;
        }
        return {};
    }

    public getCachedAgencies() {
        return this._usersAgencies;
    }

    public async identityProductsFindAll(hasLocalHandler?: boolean): Promise<Array<IAgency>> {
        let resultPromises = this.agencyLisiting.applications.map((x) => {
            return this.allIdentityProducts(x.url, x.redirectUrl, x.application);
        });
        let agencies: Array<IAgency> = [];

        try {
            let resultPromisesResolved = await Promise.all(resultPromises);
            let result = resultPromisesResolved.reduce((pre, curr) => {
                pre.push(...curr);
                return pre;
            }, []);
            agencies.push(...result);
        } catch (error) {
            if (error instanceof CancellationException) {
                console.warn(
                    "A timeout occurred while trying to gather agencies. This was handled, but should not normally happen.",
                    error
                );
            } else {
                throw error;
            }
        }

        return agencies;
    }

    public getAgenciesByProduct(roles?: IRoles[]): Map<string, GroupedAgencies> {
        let groupedAgencies: Map<string, GroupedAgencies> = new Map<string, GroupedAgencies>();
        let hasAxxessDDE: boolean = false;
        let accessRoles = this._accessService.getAccessRoles();

        if (!this._usersAgencies || this._usersAgencies?.length === 0) {
            return this._orderedAgencyList;
        }

        let agencies = this._usersAgencies.map((agency) => new Agency(agency));

        // Group by Application
        for (let agency of agencies) {
            let groupKey: string =
                agency.Application == ApplicationEnum.AxxessNala
                    ? ApplicationNameEnum[ApplicationEnum.AxxessDDE]
                    : agency.applicationName;
            let appId: number = +agency.Application;
            let isAxxessCare = appId === (ApplicationEnum.AxxessCARE as number);

            if (appId === ApplicationEnum.AxxessDDE || appId === ApplicationEnum.AxxessNala) {
                hasAxxessDDE = true;
            }

            // If user has Therapy, then skip.
            // If user has AxxessDDE, then skip Black Screen. Assumes, AxxessDDE comes before DDE Black screen
            // Might be better to remove black screen outside the loop if AxxessDDE is true
            if (appId === ApplicationEnum.Therapy || (hasAxxessDDE && appId === ApplicationEnum.DdeBlackScreen)) {
                continue;
            }

            if (!isAxxessCare || (isAxxessCare && accessRoles.canUseAxxessCare)) {
                let hasAgencyAccess = true;

                if (isAxxessCare) {
                    if (roles?.length > 0) {
                        hasAgencyAccess = roles.some((role) => {
                            return (
                                role.AgencyId === agency.AgencyId &&
                                role.Application === ApplicationEnum.AgencyCore &&
                                role.canUseAxxessCare
                            );
                        });
                    } else {
                        hasAgencyAccess = false;
                    }
                }

                if (hasAgencyAccess) {
                    if (!groupedAgencies.has(groupKey)) {
                        groupedAgencies.set(
                            groupKey,
                            new GroupedAgencies({
                                applicationName: groupKey
                            })
                        );
                    }

                    groupedAgencies.get(groupKey).addData(agency);
                }
            }
        }
        this._orderedAgencyList = new Map();
        this._applicationOrder.forEach((appId) => {
            let appName = ApplicationNameEnum[appId];
            if (groupedAgencies.has(appName)) {
                this._orderedAgencyList.set(appName, groupedAgencies.get(appName));
            }
        });
        return this._orderedAgencyList;
    }

    public getIsAgencyCoreTestingUser(): boolean {
        return this._isAgencyCoreTestingUser;
    }

    public getIsHomeCareTestingUser(): boolean {
        return this._isHomeCareTestingUser;
    }

    public getHasAgencyCore(): boolean {
        return this._hasAgencyCore;
    }

    public getHasHomeCore(): boolean {
        return this._hasHomeCare;
    }

    public getIsFamilyMember(): boolean {
        return this._usersAgencies.length === 1 && this._hasFamilyPortal;
    }

    public getHasHospice(): boolean {
        return this._hasHospice;
    }

    public getHasPalliative(): boolean {
        return this._hasPalliative;
    }

    public getHasMultipleClinicalProds(): boolean {
        return this._hasMultipleClinicalProds;
    }

    public async getHasCertificationAccess() {
        let user = await this._userManager.getUser();
        if (!user) {
            return false;
        }
        let result = await this._fetchClient
            .build(`${config.certificationUrl}/webservice/axxess_account_check/server.php`)
            .fetch<IGetHasCertificationResponse>();
        return !!result?.exists;
    }

    public setFlags() {
        let agenciesList: Array<IAgency> = this._usersAgencies;

        for (let agency of agenciesList) {
            this.setApplicationFlags(agency.Application);
            this.setTestingAccountFlags(agency.AgencyId);
        }
        if (this.productSet.size >= 2) {
            // if the user has access to more than 2 products then the pill would come only.
            this._hasMultipleClinicalProds = true;
        }
    }

    private setApplicationFlags(applicationId: number) {
        if (applicationId === ApplicationEnum.AgencyCore) {
            this._hasAgencyCore = true;
        }
        if (applicationId === ApplicationEnum.HomeCare) {
            this._hasHomeCare = true;
        }
        if (applicationId === ApplicationEnum.AxxessHospice) {
            this._hasHospice = true;
        }
        if (applicationId === ApplicationEnum.PatientFamilyPortal) {
            this._hasFamilyPortal = true;
        }
        if (applicationId === ApplicationEnum.AxxessPalliative) {
            this._hasPalliative = true;
        }
    }

    private setTestingAccountFlags(agencyId: string) {
        if (agencyId === "57a96547-e749-4bc2-927a-0fd46e0a0a24") {
            // Testing Home Health Agency Inc.
            this._isAgencyCoreTestingUser = true;
        }
        if (agencyId === "122ec20a-705b-4974-9437-8f6233a6e953") {
            // HomeCare Testing Agency
            this._isHomeCareTestingUser = true;
        }
    }
}

export interface IGetAccountsResponse {
    currentPage: number;
    itemCount: number;
    items: IAccountItem[];
    pageCount: number;
    pageLength: number;
}

export interface IAccountItem {
    createdOn: string;
    disabled: boolean;
    id: string;
    modeifiedOn: string;
    name: string;
    url?: string;
}

export interface IGetOrganization {
    name: string;
    id: string;
    agencies: IGetAgency[];
}

export interface IGetAgency {
    name: string;
    id: string;
    application: number;
}

export interface IGetHasCertificationResponse {
    exists: boolean;
}
