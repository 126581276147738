import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum PalliativeCareOrderOptionsEnum {
    AssessTreatPatient = 1 as any,
    AssessRecommendPatientTreatment = 2 as any
}
class PalliativeCareOrderOptionsClass extends EnumWithDescription<PalliativeCareOrderOptionsEnum> {
    public readonly enum = PalliativeCareOrderOptionsEnum;
    public readonly enumName: string = "PalliativeCareOrderOptionsEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<PalliativeCareOrderOptionsEnum>> = new Map([
        [
            "AssessTreatPatient",
            {
                value: PalliativeCareOrderOptionsEnum.AssessTreatPatient,
                name: "Assess & Treat Patient"
            }
        ],
        [
            "AssessRecommendPatientTreatment",
            {
                value: PalliativeCareOrderOptionsEnum.AssessRecommendPatientTreatment,
                name: "Assess & Recommend Patient Treatment"
            }
        ]
    ]);
}
export const PalliativeCareOrder = new PalliativeCareOrderOptionsClass();
