import { defineComponent } from "vue";
import moment from "moment";
import { ValidationController } from "aurelia-validation";
import { Container } from "aurelia-dependency-injection";

import type { ITaskDetailsPayload } from "../../../../../resources-vue/vue-interfaces/i-task-details";
import { IReassignVisitDetails } from "../../../../../resources-vue/vue-interfaces/i-reassign-visit";
import { IDeleteReturnReason, IGetReturnReasons } from "../../../../../resources-vue/vue-interfaces/i-task";
import { TaskService } from "../../../../../services/task.service";
import { PhysiciansService } from "../../../../../services/physician-service";
import { ToastrService } from "../../../../../services/toastr.service";
import { VisitDetails } from "../../../../../models/visit-details";
import { formatDate } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { IHHScheduleEvent } from "../../../../../resources-vue/vue-interfaces/i-hh-schedule-event";

import FormSection from "../../../../../resources-vue/vue-custom-elements/FormSection/FormSection.vue";
import TimePicker from "../../../../../resources-vue/vue-custom-elements/TimePicker/TimePicker.vue";
import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import DayPicker from "../../../../../resources-vue/vue-custom-elements/DayPicker/DayPicker.vue";
import MultiDatePicker from "../../../../../resources-vue/vue-custom-elements/MultiDatePicker/MultiDatePicker.vue";
import GroupDropdownSelect from "../../../../../resources-vue/vue-custom-elements/GroupDropdownSelect/GroupDropdownSelect.vue";
import CommentInput from "../../../../../resources-vue/vue-custom-elements/CommentInput/CommentInput.vue";

export default defineComponent({
    inject: ["dialogRef"],
    components: {
        FormSection,
        TimePicker,
        DatePicker,
        DayPicker,
        MultiDatePicker,
        GroupDropdownSelect,
        CommentInput
    },

    props: {
        visitDetails: { type: Object, default: {} as IReassignVisitDetails },
        cancel: { type: Function, default: null },
        ok: { type: Function, default: null },
        isSaving: { type: Boolean, default: false },
        reassign: { type: Function }
    },

    data(vm) {
        return {
            _taskService: null,
            _toastrService: null,
            _physicianService: null,
            reassign: null,
            _dialogRef: this.dialogRef as any,
            controller: ValidationController as object,
            taskDetails: {} as VisitDetails,
            isDeleteRequested: false as boolean,
            isDeleteInProgress: false as boolean,
            returnReasons: [],
            isSavingReason: false as boolean,
            showPhyicianField: false as boolean,
            showAssignedToList: false as boolean,
            assignedToName: "",
            isEpisodeSelectionChecked: false as boolean,
            isLoading: false as boolean,
            isSaving: false as boolean,
            disciplineTaskType: "",
            visitDetails: {} as IReassignVisitDetails,
            physician: {},
            employeeList: [],
            commentInputClass: { input: "w-35" },
            scheduleEvent: {} as IHHScheduleEvent,
            formatDate
        };
    },

    created() {
        this._physicianService = Container.instance.get(PhysiciansService);
        this._taskService = Container.instance.get(TaskService);
        this._toastrService = Container.instance.get(ToastrService);
        this.visitDetails = this._dialogRef.data.visitDetails;
        this.controller = Container.instance.get(ValidationController);
    },

    mounted() {
        this.getTaskDetails();
    },

    methods: {
        isReturnedWithSignature() {
            return this.taskDetails?.scheduleEvent?.scheduleStatus == 135;
        },

        async getTaskDetails() {
            Object.assign(this.visitDetails, {});
            try {
                this.isLoading = true;
                const payload: ITaskDetailsPayload = {
                    application: this.visitDetails.Application,
                    agencyId: this.visitDetails.AgencyId,
                    eventId: this.visitDetails.Id,
                    episodeId: this.visitDetails.EpisodeId,
                    patientId: this.visitDetails.PatientId
                };

                const visitDetails = await this._taskService.getHHVisitDetails(payload);
                this.taskDetails = visitDetails;
                this.scheduleEvent = visitDetails?.scheduleEvent
                    ? visitDetails.scheduleEvent
                    : ({} as IHHScheduleEvent);
                this.disciplineTaskType = `${String(visitDetails.scheduleEvent.disciplineTask)}|${String(
                    visitDetails.scheduleEvent.customId
                )}`;

                this.assignedToName =
                    this.taskDetails.scheduleEvent.userFirstName +
                    " " +
                    this.taskDetails.scheduleEvent.userLastName +
                    " " +
                    this.taskDetails.scheduleEvent.userCredentials.join(", ");
                if (
                    this.visitDetails.Status === "Submitted (Pending QA Review)" ||
                    this.visitDetails.Status === "Returned w/ Physician Signature"
                ) {
                    this.showPhyicianField = true;
                    this.physician = {
                        name: this.taskDetails.scheduleEvent.physicianName,
                        value: this.taskDetails.scheduleEvent.physicianId
                    };
                }
                if (
                    this.visitDetails.Status === "Reopened" ||
                    this.visitDetails.Status === "Not Yet Started" ||
                    this.visitDetails.Status === "Saved"
                ) {
                    const employees = await this._taskService.assignToList({
                        CustomTaskId: this.visitDetails.Id,
                        AgencyId: this.visitDetails.AgencyId,
                        Application: this.visitDetails.Application
                    });
                    this.employeeList = employees;
                    this.showAssignedToList = true;
                }
                this.getReturnReasonList();
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },

        async getPhysicianList(filter: string, limit: number) {
            const data = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                providerIds: [],
                application: this.visitDetails.Application,
                accountId: this.taskDetails.scheduleEvent.agencyId
            });
            return data.items?.map((item: any) => ({ value: item.id, name: item.name }));
        },

        physicianChanged(newValue: any) {
            if (newValue != null) {
                this.scheduleEvent.physicianId = newValue.value;
            }
        },

        changeVisitTimeIn(time: any) {
            this.scheduleEvent.timeIn = time;
        },

        changeVisitTimeOut(time: any) {
            this.scheduleEvent.timeOut = time;
        },

        disciplineTaskTypeChanged(newValue: string) {
            if (newValue) {
                this.scheduleEvent.disciplineTask = Number(newValue.split("|")[0]);
                this.scheduleEvent.customId = Number(newValue.split("|")[1]);
            }
        },

        serviceLocationChange(newValue: string) {
            this.scheduleEvent.serviceLocation = newValue;
        },

        onEpisodeListChange(Id: string) {
            this.scheduleEvent.episodeId = Id;
        },

        changeTravelStartTime(time: any) {
            this.scheduleEvent.travelTimeIn = time;
        },

        changeTravelEndTime(time: any) {
            this.scheduleEvent.travelTimeOut = time;
        },

        cancel() {
            this._dialogRef.close();
        },

        formatTime(dateTime: Date) {
            return dateTime
                ? new Date(dateTime).toLocaleTimeString("en-us", {
                      hour: "2-digit",
                      minute: "2-digit"
                  })
                : "";
        },

        selectedEmployee(employeeId: string) {
            if (!!employeeId) {
                const employeeDetails = this.employeeList.find((emp) => {
                    return emp.id == employeeId;
                });
                this.scheduleEvent.userId = employeeDetails.id;
                this.scheduleEvent.userFirstName = employeeDetails.firstName;
                this.scheduleEvent.userLastName = employeeDetails.lastName;
                this.scheduleEvent.userCredentials = employeeDetails.credentials;
            }
        },

        confirmDeleteReason() {
            this.isDeleteRequested = true;
        },

        taskDateChanged(date: any, name: string) {
            if (name === "startDateTime") {
                this.visitDetails.StartDate = date;
            } else if (name === "endDateTime") {
                this.visitDetails.EndDate = date;
            }
        },

        handleChangeStatus(status: string) {
            this.scheduleEvent.status = status;
            this.scheduleEvent.scheduleStatus = Number(status);
        },

        cancelDeleteReason() {
            this.isDeleteRequested = false;
        },

        async deleteReason(id: number) {
            try {
                this.isDeleteInProgress = true;
                let deleteQuery: IDeleteReturnReason = {
                    application: this.visitDetails.Application,
                    id: id
                };
                await this._taskService.deleteReturnReason(deleteQuery);
                this.getReturnReasonList();
                this._toastrService.success({
                    message: `Return Reason has been successfully deleted.`,
                    title: `Return Reason Deleted`
                });
            } catch (error) {
                console.log(error);
                this._toastrService.error({
                    title: `Error - Delete Return Reason`,
                    message: `There was a problem while deleting the Return Reason. Please try again.`
                });
                this.isDeleteInProgress = false;
            }
        },

        async getReturnReasonList() {
            const payload: IGetReturnReasons = {
                application: this.visitDetails.Application,
                agencyId: this.visitDetails.AgencyId,
                eventId: this.visitDetails.Id,
                episodeId: this.visitDetails.EpisodeId,
                patientId: this.visitDetails.PatientId,
                userId: this.visitDetails.UserId
            };
            const returnReasons = await this._taskService.getReturnReasonList(payload);
            this.returnReasons = returnReasons.data;
        },

        resultUpdatedAppended(value: string) {
            this.scheduleEvent.comments += value;
        },
        resultUpdated(value: string) {
            this.scheduleEvent.comments = value;
        },

        async updateVisitDetails() {
            if (!!this.taskDetails) {
                try {
                    this.isSaving = true;
                    this.scheduleEvent.application = this.visitDetails.Application;
                    this.scheduleEvent.eventDate = moment(this.scheduleEvent.eventDate).format("MM/DD/YYYY");
                    this.scheduleEvent.visitDate = moment(this.scheduleEvent.visitDate).format("MM/DD/YYYY");
                    this.scheduleEvent.status = this.scheduleEvent.status;

                    await this._taskService.updateHHVisitDetails(this.scheduleEvent);
                    this._toastrService.success({
                        title: `Visit Updated`,
                        message: `Visit has been successfully Updated.`
                    });
                    // trigger callback
                    this.ok("success");
                } catch (e) {
                    this._toastrService.error({
                        title: `Visit Update Failed`,
                        message: `Updating Visit Operation Failed, Please Contact Axxess if issue still persists.`
                    });
                } finally {
                    this.isSaving = false;
                }
            }
        },
        handleDateChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            if (name === "scheduleDateTime") {
                this.visitDetails.StartDate = value;
            }
            if (name === "endDateTime") {
                this.visitDetails.EndDate = value;
            }
        }
    }
});
