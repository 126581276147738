import { ApplicationEnum } from "../../enums/enums";

export class LineOfService {
    public locationId: string = null;
    public firstName: string = "";
    public lastName: string = "";
    public lineOfService: number = null;
    public application?: ApplicationEnum;

    public constructor(application: ApplicationEnum, data?: LineOfService) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
