import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";

import { PermissionManager } from "../utilities/permission-manager";
import { User } from "../../models/user";
import { ParentPermissionEnum } from "../../enums/enums";

import PersonalCenter from "../../pages-vue/Personal/PersonalCenter.vue";
import CentralCalendar from "../../pages-vue/CentralCalendar/CentralCalendar.vue";
import CreateInterimReferral from "../../pages-vue/CreateInterimReferral/CreateInterimReferral.vue";
import CreateReferralHosPal from "../../pages-vue/CreateReferral/CreateReferralHosPal/CreateReferralHosPal.vue";
import CreateReferralHomeHealth from "../../pages-vue/CreateReferral/CreateReferralHomeHealth/CreateReferralHomeHealth.vue";
import CreateReferralHomeCare from "../../pages-vue/CreateReferral/CreateReferralHomeCare/CreateReferralHomeCare.vue";
import EditReferralHomeHealth from "../../pages-vue/EditReferralHomeHealth/EditReferralHomeHealth.vue";
import AxxessCentral from "../../pages-vue/AxxessCentral/CentralApp.vue";
import taskManager from "../../pages-vue/TaskManagerEmployee/TaskManager.vue";
import taskManagerPatient from "../../pages-vue/TaskManagerPatient/TaskManager.vue";
import SelectOrganization from "../../pages-vue/SelectOrganization/SelectOrganization.vue";
import CentralIntake from "../../pages-vue/CentralIntake/CentralIntake.vue";
import CentralIntakeRouter from "../../pages-vue/CentralIntake/CentralIntakeRouter";
import CentralCalendarRouter from "../../pages-vue/CentralCalendar/CentralCalendarRouter";
import CentralRouter from "../../pages-vue/AxxessCentral/CentralRouter";
import AxxessHomeRouter from "../../pages-vue/AxxessHome/AxxessHomeRouter";
import AxxessHome from "../../pages-vue/AxxessHome/AxxessHome.vue";

export enum RouteNames {
    Home = "home",
    Help = "help",
    Clinician = "clinician",
    Calendar = "calendar",
    OidcSignIn = "oidc-sign-in",
    OidcSignInRedirect = "oidc-sign-in-redirect",
    OidcSignInSilent = "oidc-sign-in-silent",
    Intake = "intake",
    Create = "create",
    Central = "central",
    TaskManager = "task-manager",
    TaskManagerPatient = "task-manager-patient"
}

const title: string = "Axxess - Complete Home Health Care Software";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/clinician",
        meta: {
            nav: false
        }
    },
    {
        path: "/clinician",
        name: RouteNames.Clinician,
        component: AxxessHome,
        meta: {
            title: title,
            nav: true,
            menuName: "Axxess Home",
            showAxxessPlanner: true
        },
        children: AxxessHomeRouter
    },
    {
        path: "/axxess-central",
        name: RouteNames.Central,
        redirect: "/axxess-central/dashboard",
        meta: {
            title: title,
            nav: true,
            menuName: "Axxess Central",
            centralNav: false,
            showOther: true
        }
    },

    {
        path: "/axxess-central/dashboard",
        name: "Home",
        component: AxxessCentral,
        meta: {
            title: "Home",
            nav: true,
            menuName: "Home",
            centralNav: true,
            iconClass: "fas fa-home",
            navItem: "tabHome"
        },
        children: CentralRouter
    },
    {
        path: "/axxess-central/intake",
        name: RouteNames.Intake,
        component: CentralIntake,
        redirect: "/axxess-central/intake/inquiry-list",
        meta: {
            nav: true,
            title: title,
            menuName: "Central Intake",
            showCentralIntake: true,
            hideTitle: true,
            centralNav: true,
            iconClass: "fa fa-user-plus"
        },
        children: CentralIntakeRouter
    },
    {
        path: "/axxess-central/create",
        name: RouteNames.Create,
        // redirect: "/create/interim/referral",
        children: [
            {
                path: "/axxess-central/create/interim/referral",
                component: CreateInterimReferral,
                name: "create-interim-referral"
            },
            {
                path: "/axxess-central/create/referral/:id?/account/:accountId?/application/:applicationId?",
                component: CreateReferralHosPal,
                name: "hos-pal-referral"
            },
            {
                path: "/axxess-central/create/referral/:id?/account/:accountId?/application/:applicationId?/isRefer/:isRefer?/isNonAdmit/:isNonAdmit?/nonAdmitReason/:nonAdmitReason?/nonAdmitReasonId/:nonAdmitReasonId?/nonAdmitDate/:nonAdmitDate?/patientId/:patientId?/patientApplication/:patientApplication?/patientBranchId/:patientBranchId/newPatientId/:newPatientId/locationId/:locationId?/isPatient/:isPatient?",
                component: CreateReferralHosPal,
                name: "hos-pal-referral-refer-non-admit"
            },
            {
                path: "/axxess-central/create/referral/:id?/account/:accountId?/application/:applicationId?/isRefer/:isRefer?/isNonAdmit/:isNonAdmit?/patientId/:patientId?/patientApplication/:patientApplication?/patientBranchId/:patientBranchId/newPatientId/:newPatientId/locationId/:locationId?/isPatient/:isPatient?",
                component: CreateReferralHosPal,
                name: "hos-pal-referral-refer"
            },
            {
                path: "/axxess-central/create/homehealth-referral/:id?/account/:accountId?/application/:applicationId?",
                component: CreateReferralHomeHealth,
                name: "homehealth-referral"
            },
            {
                path: "/axxess-central/create/edit-home-health-referral/:id?/account/:accountId?/application/:applicationId?",
                component: EditReferralHomeHealth,
                name: "edit-home-health-referral"
            },
            {
                path: "/axxess-central/create/homecare-referral/:id?",
                component: CreateReferralHomeCare,
                name: "homecare-referral"
            }
        ]
    },
    {
        path: "/axxess-central/calendar",
        name: RouteNames.Calendar,
        component: CentralCalendar,
        meta: {
            nav: true,
            title: title,
            menuName: "Central Calendar",
            showIfScheduler: true,
            centralNav: true,
            iconClass: "far fa-calendar-alt"
        },
        children: CentralCalendarRouter
    },
    {
        path: "/axxess-central/task-manager",
        name: RouteNames.TaskManager,
        component: taskManager,
        meta: {
            nav: true,
            title: title,
            menuName: "Task Manager",
            showIfScheduler: true
        }
    },
    {
        path: "/axxess-central/task-manager-patient",
        name: RouteNames.TaskManagerPatient,
        component: taskManagerPatient,
        meta: {
            nav: true,
            title: title,
            menuName: "Task Manager",
            showIfScheduler: true
        }
    },
    {
        path: "/help",
        name: RouteNames.Help,
        component: PersonalCenter,
        meta: {
            nav: false,
            title: title,
            menuName: "Axxess Help Center",
            showOther: true
        }
    },
    {
        path: "/axxess-central/organizationLoading",
        component: SelectOrganization,
        name: "Organization Loading",
        meta: {
            menuName: "",
            navItem: "tabLoading"
        }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/clinician"
    }
];

export function checkPermission(permissionManager: PermissionManager, user: User) {
    if (!permissionManager || !user) {
        return null;
    }

    const permissionsToViewPaths: { [key: string]: boolean } = {
        clinician: permissionManager.checkHasAnyPermission(ParentPermissionEnum.clinician)
    };

    return (route: RouteRecordRaw) => {
        const pathName = route.name as string;
        if (pathName in permissionsToViewPaths) {
            return permissionsToViewPaths[pathName];
        }
        return true;
    };
}

const VueRouter = createRouter({
    history: createWebHistory(),
    routes: routes
});

export default VueRouter;
