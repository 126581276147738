import { defineComponent, PropType } from "vue";
import jquery from "jquery";

import type { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";

export default defineComponent({
    props: {
        selectedItems: { type: Array, default: [] },
        options: { type: Array as PropType<ITypeaheadOptions[]>, default: [] },
        placeholder: { type: String, default: "All Selected" }
    },

    emits: ["selectedItemsUpdated"],

    data() {
        return {
            message: ""
        };
    },

    watch: {
        options() {
            this.updateMessage();
        },

        selectedItems() {
            this.updateMessage();
        }
    },

    mounted() {
        this.updateMessage();
        jquery(this.$refs.dropdownElement).on("click.bs.dropdown", (e: any) => {
            if (e.target.nodeName != "BUTTON") {
                e.stopPropagation();
            }
        });
    },
    methods: {
        updateSelectedItems(value: any, event: any) {
            let options = [...this.selectedItems];
            if (options.includes(value)) {
                options = options.filter((item) => item != value);
            } else {
                options.push(value);
            }
            this.$emit("selectedItemsUpdated", options);
            this.updateMessage();
        },

        updateMessage() {
            if (!this.selectedItems || this.selectedItems.length === 0) {
                this.message = this.placeholder;
            } else if (this.selectedItems.length === this.options.length) {
                this.message = "All Selected";
            } else {
                this.message = `${this.selectedItems.length} Selected`;
            }
        },

        toggleAllSelected() {
            if (this.options.length === this.selectedItems.length) {
                this.$emit("selectedItemsUpdated", []);
            } else {
                this.$emit(
                    "selectedItemsUpdated",
                    this.options.map((option) => option.value)
                );
            }
        }
    }
});
