import { defineComponent } from "vue";
import { mapState } from "pinia";
import { Container } from "aurelia-dependency-injection";
import { TaskQueue } from "aurelia-framework";
import moment from "moment";

import { Address } from "../../../../../resources-vue/vue-models/address";
import { VendorsService } from "../../../../../services/vendor-service";
import { LookupService } from "../../../../../services/lookup-service";
import { IGetVendorByIdQuery } from "../../../../../resources-vue/vue-interfaces/i-vendor";
import { IUpdatedPlacesAddress } from "../../../../../resources-vue/vue-interfaces/i-places-autocomplete";
import { formatZipCode, formatDateInput } from "../../../../../common/vue-helpers/modifiers/value-modifier";
import { useCreateReferralHosPal } from "../../store/useCreateReferralHosPal";

import DatePicker from "../../../../../resources-vue/vue-custom-elements/DatePicker/DatePicker.vue";
import StateTypeahead from "../../../../../resources-vue/vue-custom-elements/StateTypeahead/StateTypeahead.vue";
import PlacesAutocomplete from "../../../../../resources-vue/vue-custom-elements/PlacesAutocomplete/PlacesAutocomplete.vue";
import CountryTypeahead from "../../../../../resources-vue/vue-custom-elements/CountryTypeahead/CountryTypeahead.vue";
import TypeaheadInput from "../../../../../resources-vue/vue-custom-elements/TypeaheadInput/TypeaheadInput.vue";

export default defineComponent({
    components: {
        DatePicker,
        StateTypeahead,
        PlacesAutocomplete,
        CountryTypeahead,
        TypeaheadInput
    },

    inject: ["v"],
    props: {
        addressType: {
            type: String
        },
        providerId: {
            type: String,
            default: ""
        },
        address: {
            type: Object
        },
        facilityId: {
            type: String
        },
        application: {
            type: Number
        },
        accountId: {
            type: String
        },
        showCode: {
            type: Boolean,
            default: false
        },
        disableInputs: {
            type: Boolean,
            default: false
        },
        isPrimaryAddress: {
            type: Boolean,
            default: false
        },
        isAddressValidationInProgress: {
            type: Boolean,
            default: false
        },
        resetSameAsPrimaryAddress: {
            type: Function
        },
        showMoveDate: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            _vendorsService: null,
            _lookupService: null,
            _tq: null,
            _autoCompleteAddress: null,
            selectedFacility: null,
            selectedState: null,
            selectedCountry: null,
            ignorePrefillChange: false,
            autoCompleteCountry: "",
            unitedStatesCountryCode: "",
            datePickerOptions: {
                endDate: moment().format("MM/DD/YYYY"),
                autoclose: true
            },
            v$: this.v,
            formatZipCode,
            formatDateInput
        };
    },
    computed: {
        isUnitedStates() {
            return this.selectedCountry?.name.toLowerCase() === "united states of america";
        },
        ...mapState(useCreateReferralHosPal, ["countryList"])
    },
    created() {
        this._vendorsService = Container.instance.get(VendorsService);
        this._lookupService = Container.instance.get(LookupService);
        this._tq = Container.instance.get(TaskQueue);
    },
    async beforeMount() {
        if (!this.facilityId) {
            this.selectedFacility = null;
        } else {
            const payload: IGetVendorByIdQuery = {
                application: this.application,
                accountId: this.accountId,
                id: this.facilityId
            };
            let selectedVendor = await this._vendorsService.getVendorById(payload);
            if (!!selectedVendor) {
                this.selectedFacility = {
                    name: selectedVendor.name,
                    value: selectedVendor.id
                };
            }
        }
        this.unitedStatesCountryCode = await this._lookupService.getUnitedStatesCode();
        if (!this.address.country) {
            this.address.country = this.unitedStatesCountryCode;
        }
        if (this.isPrimaryAddress) {
            if (!this.address.latitude || !this.address.longitude) {
                this.address.isValidated = false;
            }
        }
    },

    watch: {
        selectedFacility: {
            async handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (!!this.selectedFacility && this.selectedFacility.value !== this.facilityId) {
                        this.$emit("update:facilityId", this.selectedFacility.value);
                        const payload: IGetVendorByIdQuery = {
                            application: this.application,
                            accountId: this.accountId,
                            id: this.facilityId
                        };
                        let selectedVendor = await this._vendorsService.getVendorById(payload);
                        if (!!selectedVendor) {
                            this.address.addressLine1 = selectedVendor.address.addressLine1;
                            this.address.addressLine2 = selectedVendor.address.addressLine2;
                            this.address.city = selectedVendor.address.city;
                            this.address.state = selectedVendor.address.state;
                            this.address.zipCode = selectedVendor.address.zipCode;
                            this.address.county = selectedVendor.address.county as string;
                            this.address.country = selectedVendor.address.country;
                            this.address.isValidated = selectedVendor.address.isValidated;
                            this.address.latitude = selectedVendor.address.latitude;
                            this.address.longitude = selectedVendor.address.longitude;
                        }
                    } else if (!this.selectedFacility) {
                        this.$emit("update:facilityId", null);
                        this.$emit("update:address", new Address());
                        if (this.isUnitedStates) {
                            this.address.initValidations();
                        } else {
                            this.address.initInternationalValidation();
                        }
                    }
                    if (this.isPrimaryAddress) {
                        this.resetSameAsPrimaryAddress();
                    }
                }
            },
            deep: true
        },
        autoCompleteCountry() {
            const country = this.countryList.find((data) => data.twoLetterCode === this.autoCompleteCountry);
            let temp = { ...this.address };
            temp.country = country.value;
            this.$emit("update:address", temp);
        },
        selectedState() {
            const temp = { ...this.address };
            temp.state = this.selectedState.value;
            this.$emit("update:address", temp);
        }
    },
    methods: {
        async getVendorList(filter: string, limit: number) {
            const payload = {
                accountId: this.accountId,
                name: filter,
                providerIds: [this.providerId],
                application: this.application
            };
            let facilityList = await this._vendorsService.getVendorByTerm(payload);
            return facilityList.map((facility: any) => ({
                name: facility.name,
                value: facility.id
            }));
        },
        placesChangedCallBack(params: IUpdatedPlacesAddress) {
            if (!params) {
                return;
            }
            Object.assign(this._autoCompleteAddress, params);
            this.address.addressLine1 = params.addressLine1;
            this.address.city = params.city;
            this.address.county = params.county;
            this.address.state = params.state;
            this.address.zipCode = params.zipCode;
            this.address.latitude = params.latitude;
            this.address.longitude = params.longitude;
            this.address.isValidated = true;
            this.address.isBypassed = false;
        },
        updateFacility(value: any) {
            this.selectedFacility = value;
        },
        updateSelectedCountry(value: any) {
            this.selectedCountry = value;
            let temp = { ...this.address };
            if (!!this.selectedCountry) {
                temp.country = this.selectedCountry.value;
            } else {
                temp.country = "";
            }
            this.$emit("update:address", temp);
            if (this.isPrimaryAddress) {
                this.resetSameAsPrimaryAddress();
            }
        },
        handleZipcodeChange(e: any) {
            this.address.zipCode = e.target.value;
        },
        handleDateChange(date: any, name: any) {
            if (name === "moveInDate") {
                this.address.moveInDate = date;
            }
            if (name === "moveOutDate") {
                this.address.moveOutDate = date;
            }
        },
        onDateInputChange(e: any) {
            const name = e.target.name;
            const value = e.target.value;
            this.handleDateChange(value, name);
        }
    }
});
