import moment, { Moment } from "moment";

import type { IName } from "../interfaces/name.interface";
import type { IPatient } from "../interfaces/patient.interface";

export class Patient implements IPatient {
    public Application: number;
    public AgencyId: string;
    public AgencyName: string;
    public Dob: string;
    public Id: string;
    public IsAxxessCARE: boolean;
    public PatientName: IName;
    public UserId: string;
    public PhoneNumber: string;

    public constructor(patient: IPatient) {
        if (patient) {
            Object.assign(this, patient);
        }
    }

    public get birthDateTooltip(): string {
        return `Today is ${this.PatientName.FirstName} ${this.PatientName.LastName}'s birthday.`;
    }

    public get patientFullName(): string {
        return `${this.PatientName.LastName}, ${this.PatientName.FirstName}`;
    }

    public get isBirthDate(): boolean {
        let dob: Moment = moment(this.Dob);
        let today: Moment = moment();
        return dob.date() === today.date() && dob.month() === today.month();
    }
}
