import { EnumWithDescription, ICustomEnumValue } from "../EnumWithDescription";

export enum LineOfServicesEnum {
    Hospice = 1 as any,
    PalliativeCare = 2 as any,
    SeriouslyIllPopulation = 4 as any
}
class LineOfServicesClass extends EnumWithDescription<LineOfServicesEnum> {
    public readonly enum = LineOfServicesEnum;
    public readonly enumName: string = "LineOfServicesEnum";

    public readonly enumWithDescriptions: Map<string, ICustomEnumValue<LineOfServicesEnum>> = new Map([
        [
            "Hospice",
            {
                value: LineOfServicesEnum.Hospice,
                name: "Hospice"
            }
        ],
        [
            "PalliativeCare",
            {
                value: LineOfServicesEnum.PalliativeCare,
                name: "Palliative Care"
            }
        ],
        [
            "SeriouslyIllPopulation",
            {
                value: LineOfServicesEnum.SeriouslyIllPopulation,
                name: "Seriously Ill Population (SIP)"
            }
        ]
    ]);
}
export const LineOfServices = new LineOfServicesClass();
