import { defineComponent, nextTick } from "vue";

import { HttpStatusCodeEnum } from "../../../enums/enums";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";

export default defineComponent({
    props: {
        placeholder: {
            type: String,
            default: "Start Typing..."
        },
        data: {
            type: Array,
            default: []
        },
        selectedOption: {
            type: Object,
            default: null
        },
        fetchOptions: {
            type: Function
        },
        addNewOption: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        },
        searchCharLimit: {
            type: Number,
            default: 0
        },
        clearOnBlur: {
            type: Boolean,
            default: true
        },
        errorMessage: {
            type: String,
            default: "Error Fetching Results."
        },
        noResultsMessage: {
            type: String,
            default: "No Results Found."
        },
        hideIcon: {
            type: Boolean,
            default: false
        },
        loadingMessage: {
            type: String,
            default: "Loading..."
        },
        customAddMessage: {
            type: String,
            default: ""
        },
        entity: {
            type: String,
            default: null
        },
        isCustomAddEnabled: {
            type: Boolean,
            default: false
        },
        validation: {
            type: Object,
            default: null
        },
        isAuthorized: {
            type: Boolean,
            default: true
        },
        inputClasses: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            dataProp: null,
            searchKey: "",
            isLoading: false,
            highlightedOption: null,
            showMenu: false,
            noMatchesFound: false,
            isNewHighlightedOption: false,
            searchResults: [] as Array<ITypeaheadOptions>,
            customAddElementRef: null,
            isError: false,
            characterLimitMessage: "",
            notAuthorizedText: "Contact your administrator to request permission to view this data."
        };
    },

    watch: {
        data: {
            handler() {
                if (this.data.length > 0 && !this.fetchOptions) {
                    this.searchResults = this.filterFromData() as ITypeaheadOptions[];
                    this.selectFirstOption();
                }
            },
            deep: true
        },

        async selectedOption(newValue: ITypeaheadOptions) {
            if (!newValue) {
                this.searchKey = null;
                return;
            }
            if (newValue && newValue.name != this.searchKey) {
                this.searchKey = newValue.name;
                await this.$nextTick();
                this.showMenu = false;
            }
        },

        async searchKey(newKey: string, oldKey: string) {
            newKey = newKey?.trim();
            oldKey = oldKey?.trim();
            if (newKey !== oldKey) {
                if (!!newKey && newKey.length >= this.searchCharLimit && newKey !== this.selectedOption?.name) {
                    this.characterLimitMessage = "";
                    if (!!this.fetchOptions) {
                        try {
                            // Fetch Options through API
                            this.isError = false;
                            this.$emit("update:isAuthorized", true);
                            this.isNewHighlightedOption = false;
                            this.highlightedOption = null;
                            this.showMenu = true;
                            this.isLoading = true;
                            this.searchResults = await this.fetchOptions({
                                term: newKey // to prevent extra spaces in the API request
                            });
                            this.selectFirstOption();
                            if (this.isCustomAddEnabled) {
                                let matchesFound = this.searchResults.filter(
                                    (op) => op.name.toLowerCase() === newKey.toLowerCase()
                                );
                                this.noMatchesFound = newKey.length !== 0 && matchesFound.length === 0;
                            }
                        } catch (e) {
                            console.error(e);
                            this.isError = true;
                            if (e.status === HttpStatusCodeEnum.Forbidden) {
                                this.$emit("update:isAuthorized", false);
                            }
                        } finally {
                            this.isLoading = false;
                        }
                    } else if (this.data.length > 0) {
                        // Fetch Options through data binding
                        this.searchResults = this.filterFromData() as ITypeaheadOptions[];
                        this.selectFirstOption();
                    } else {
                        // no options or no search key
                        this.showMenu = false;
                    }
                } else if (!newKey) {
                    this.refreshDataList();
                } else {
                    this.refreshDataList();
                    this.showMenu = true;
                }
            }
            if (newKey?.length == 0) {
                this.$emit("update:selectedOption", null);
                this.refreshDataList();
            }
        }
    },

    mounted() {
        this.registerDocumentClick();
    },

    methods: {
        registerDocumentClick() {
            (this.$refs.dropdownRef as HTMLElement)?.addEventListener("click", (evt: MouseEvent) => {
                if (!this.showMenu) {
                    return;
                }

                setTimeout(() => {
                    if (
                        !!this.$refs.dropdownRef &&
                        !(this.$refs.dropdownRef as HTMLElement).contains(evt.target as Element)
                    ) {
                        this.handleEscape();
                    }
                }, 150);
            });
        },

        filterFromData() {
            if (this.data.length > 0 && !!this.searchKey) {
                return this.data.filter((item: ITypeaheadOptions) => {
                    return item.name.toLocaleLowerCase().includes(this.searchKey?.trim().toLocaleLowerCase());
                });
            }
            return Array.from(this.data);
        },

        selectFirstOption() {
            if (this.searchResults.length > 0) {
                this.highlightedOption = this.searchResults[0];
            } else {
                this.isNewHighlightedOption = true;
            }
        },

        refreshDataList() {
            if (this.data.length > 0) {
                this.searchResults = this.filterFromData() as ITypeaheadOptions[];
                this.selectFirstOption();
            } else {
                this.searchResults = [];
            }
            this.characterLimitMessage = `Please enter at least ${this.searchCharLimit} characters.`;
        },

        handleEscape() {
            if (this.showMenu) {
                if (this.clearOnBlur || this.searchKey !== this.selectedOption?.name) {
                    this.searchKey = "";
                }
                this.showMenu = false;
                (this.$refs.searchInputRef as HTMLInputElement).blur();
            }
        },

        handleInputKeydown(event: KeyboardEvent) {
            if (this.disabled) {
                return false;
            }
            setTimeout(() => {
                if (event.key === "ArrowUp") {
                    this.handleUpArrowPress();
                } else if (event.key === "ArrowDown") {
                    this.handleDownArrowPress();
                } else if (event.key === "Enter") {
                    this.handleEnterPress();
                } else if (event.key === "Escape") {
                    this.handleEscape();
                } else if (event.key === "Tab") {
                    this.handleEscape();
                }
            }, 150);
            // https://kabaehr.de/blog/Aurelia-keyboard-event-binding/
            return true;
        },

        handleUpArrowPress() {
            let selectedIndex = 0;
            if (!!this.highlightedOption) {
                selectedIndex = this.searchResults.findIndex((item) => {
                    return item === this.highlightedOption;
                });
                if (selectedIndex <= 0) {
                    if (this.isCustomAddEnabled && this.noMatchesFound) {
                        this.isNewHighlightedOption = true;
                        selectedIndex = null;
                    } else {
                        selectedIndex = this.searchResults.length - 1;
                    }
                } else {
                    selectedIndex--;
                }
            } else {
                this.isNewHighlightedOption = false;
                selectedIndex = this.searchResults.length - 1;
            }
            this.handleKeyPressNavigation(selectedIndex);
        },

        handleDownArrowPress() {
            let selectedIndex = 0;
            if (!!this.highlightedOption) {
                selectedIndex = this.searchResults.findIndex((item) => {
                    return item === this.highlightedOption;
                });
                if (selectedIndex >= this.searchResults.length - 1) {
                    if (this.isCustomAddEnabled && this.noMatchesFound) {
                        this.isNewHighlightedOption = true;
                        selectedIndex = null;
                    } else {
                        selectedIndex = 0;
                    }
                } else {
                    selectedIndex++;
                }
            } else {
                this.isNewHighlightedOption = false;
            }
            this.handleKeyPressNavigation(selectedIndex);
        },

        handleKeyPressNavigation(selectedIndex: number) {
            if (Number.isInteger(selectedIndex) && selectedIndex >= 0) {
                this.highlightedOption = this.searchResults[selectedIndex];
                let selectedOptionEl: HTMLElement = this.$refs[`optionRefs-${selectedIndex}]`] as HTMLElement;
                if (selectedOptionEl && !this.isScrolledIntoView(selectedOptionEl, selectedIndex)) {
                    (this.$refs.dropdownMenuRef as HTMLElement).scrollTop = selectedOptionEl.offsetTop;
                }
            } else {
                this.highlightedOption = null;
                (this.$refs.dropdownMenuRef as HTMLElement).scrollTop = this.customAddElementRef.offsetTop;
            }
        },

        handleEnterPress() {
            if (!!this.highlightedOption) {
                this.handleMenuSelection(this.highlightedOption);
            } else if (this.isCustomAddEnabled && this.searchKey?.trim().length > 0) {
                this.isNewHighlightedOption = false;
                this.addCustomOption();
            }
        },
        async addCustomOption() {
            if (this.addNewOption && !!this.searchKey) {
                this.showMenu = false;
                await this.addNewOption({
                    term: this.searchKey
                });
            }
        },

        async handleMenuSelection(option: ITypeaheadOptions) {
            this.$emit("update:selectedOption", Object.assign({}, option));
            await this.$nextTick();
            this.searchKey = this.selectedOption.name;
            this.showMenu = false;
            (this.$refs.searchInputRef as HTMLInputElement).blur();
        },

        isScrolledIntoView(el: HTMLElement, selectedIndex: number) {
            let elemHeight = el.clientHeight;
            let dropdownHeight = (this.$refs.dropdownMenuRef as HTMLElement).clientHeight;
            let dropdownScrollTop = (this.$refs.dropdownMenuRef as HTMLElement).scrollTop;
            let hiddenOptions = Math.round(dropdownScrollTop / elemHeight);
            let visibleOptions = Math.round(dropdownHeight / elemHeight);
            let isVisible = true;
            if (selectedIndex > hiddenOptions && selectedIndex < hiddenOptions + visibleOptions) {
                isVisible = true;
            } else {
                isVisible = false;
            }
            return isVisible;
        },

        handleInputFocus() {
            if (!this.fetchOptions && this.data.length > 0) {
                this.showMenu = true;
            }
        },
        emitBlur() {
            this.$emit("blurredInput");
        }
    }
});
