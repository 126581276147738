import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "Message_Center" }
const _hoisted_2 = {
  class: "row mx-0 h-100",
  id: "MessageCenter__List"
}
const _hoisted_3 = { class: "col px-0 h-100 overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConversationNav = _resolveComponent("ConversationNav")!
  const _component_ScrollPagination = _resolveComponent("ScrollPagination")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_PageLayout, {
      "has-title": false,
      "content-class": "p-0"
    }, {
      content: _withCtx(() => [
        _createElementVNode("section", _hoisted_2, [
          _withDirectives(_createElementVNode("nav", {
            class: _normalizeClass(["message-list overflow-auto", `${_ctx.userOs == 'Windows' ? 'custom-scrollbar' : ''}`])
          }, [
            _createVNode(_component_ScrollPagination, {
              class: "content-y h-100",
              "page-number": _ctx.pageNumber,
              "all-pages-loaded": _ctx.allPagesLoaded,
              "is-loading": _ctx.isLoading,
              "has-error": _ctx.hasError,
              onPageNumberUpdate: _ctx.pageNumberUpdated
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ConversationNav, {
                  "message-nav-options": _ctx.listOptions,
                  "is-loading": _ctx.isLoading,
                  "new-conversation": _ctx.newConversation,
                  "conversation-users-type": _ctx.conversationUsersType,
                  onUpdateNewConversation: _ctx.updateNewConversation
                }, null, 8 /* PROPS */, ["message-nav-options", "is-loading", "new-conversation", "conversation-users-type", "onUpdateNewConversation"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["page-number", "all-pages-loaded", "is-loading", "has-error", "onPageNumberUpdate"])
          ], 2 /* CLASS */), [
            [_vShow, !_ctx.isMobile || !_ctx.hideNav]
          ]),
          _withDirectives(_createElementVNode("section", _hoisted_3, [
            _createVNode(_component_router_view)
          ], 512 /* NEED_PATCH */), [
            [_vShow, !_ctx.isMobile || _ctx.hideNav]
          ])
        ])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}