import { defineComponent } from "vue";
import "bootstrap-datepicker";
import $ from "jquery";

export default defineComponent({
    props: {
        options: {
            type: Object
        },
        getDate: {
            type: Function
        }
    },

    mounted() {
        $(".datepickerInput")
            .datepicker({
                ...this.options,
                autoclose: true
            })
            .on("changeDate", (e) => {
                this.getDate?.(e.format(), (e.target.childNodes[0] as any)?.name);
            });
    }
});
