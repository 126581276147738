import { autoinject } from "aurelia-dependency-injection";
import { noView } from "aurelia-framework";
import { FetchClient } from "../common/fetch-client";
import type { IMessage, IMessageRequestParams } from "../resources-vue/vue-interfaces/i-message";

@noView()
@autoinject
export class MessageService {
    private _fetchClient: FetchClient;
    private _baseUrl: string = "/api/v1/messages";

    public constructor(fetchClient: FetchClient) {
        this._fetchClient = fetchClient;
    }

    public async findAll(options: IMessageRequestParams): Promise<Array<IMessage>> {
        let messages = await this._fetchClient.build(this._baseUrl).useQueryString(options).fetch<IMessage[]>();
        return messages ?? [];
    }

    public async getTotal(): Promise<number> {
        let totalMessages = await this._fetchClient.build(`${this._baseUrl}/total`).fetch<any>();
        return totalMessages ?? 0;
    }
}
